import React from "react";
import { ListGroup } from "react-bootstrap"


const ListGroupItemDiv = props => {
	const handleEnter = (e) => {
		if (e.key === "Enter") 
			props.onClick()
	}
	return (
		<>
		{props.onClick &&
			<ListGroup.Item as="div" role="button" action className={`${props.className ? props.className : ""} cursor-pointer`} tabIndex={0} onClick={e => {props.onClick()}} 
			onKeyDown={e => {handleEnter(e)}}>
				{props.children}
			</ListGroup.Item>
		}
		</>
	)
}


export default ListGroupItemDiv
