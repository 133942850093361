import React, { Suspense } from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { getField, isPending, getItems } from "../../reducers";
import { Tabs, Tab, Container, Spinner } from "react-bootstrap"
import ResultsList from './ResultsList'
import { setField } from '../../actions/forms'
import * as actions from '../../actions'
import {
    TAXONDB_SEARCH_MAP_DATA_REQUEST_ID,
    TAXONDB_SEARCH_MAP_DATA_REQUEST_URL,
    SAMPLESDB_SEARCH_MAP_DATA_REQUEST_ID,
    SAMPLESDB_SEARCH_MAP_DATA_REQUEST_URL,
    SAMPLESDB_DETAILS_REQUEST_URL
} from '../../settings'
import MapContainer from '../map/MapContainer'
import Loader from '../../utils/loader'

const ResultsContainer = props => {
    const { t } = useTranslation([ 'taxondb', 'taxondb-groups' ])

    return (
        <Container className={(!props.loading && !props.items) ? "mt-2 d-none" : "mt-2"}>
            <Suspense fallback={<Loader />}>
                <div className="search-results">{t('common:search-results')}</div>
                <Tabs defaultActiveKey={props.activeTab} id={props.searchRequestId} activeKey={props.activeTab} onSelect={props.setActiveTab}>
                    <Tab eventKey="0" title={t('samplesdb:samples')}>
                        {props.activeTab === "0" &&
                            <Container className="d-flex flex-column mt-2">
                                {props.loading &&
                                    <div className="align-self-center">
                                        <Spinner animation="border" role="status" />
                                    </div>
                                }
                                {!props.loading &&
                                    <ResultsList
                                        requestId={props.searchRequestId}
                                    ></ResultsList>
                                }
                            </Container>
                        }
                    </Tab>
                    <Tab eventKey="4" title={t('samplesdb:map')}>
                        {props.activeTab === "4" &&
                            <MapContainer
                                requestId={props.searchRequestId}
                                searchId={SAMPLESDB_SEARCH_MAP_DATA_REQUEST_ID}
                                searchUrl={SAMPLESDB_SEARCH_MAP_DATA_REQUEST_URL}
                                detailsDispatch={props.getDetails}
                            ></MapContainer>
                        }

                    </Tab>
                </Tabs>
            </Suspense>
        </Container>
    )
}

const mapStateToProps = (state, ownProps) => ({
    items: getItems(ownProps.searchRequestId, state),
    activeTab: (getField(ownProps.tabId, ownProps.tabId, state) || '0'),
    loading: isPending(ownProps.searchRequestId, state),
})


const mapDispatchToProps = (dispatch, ownProps) => ({
    setActiveTab: value => dispatch(setField(ownProps.tabId, ownProps.tabId, value)),
    searchMapData: data => dispatch(actions.postDataAnc(TAXONDB_SEARCH_MAP_DATA_REQUEST_ID, TAXONDB_SEARCH_MAP_DATA_REQUEST_URL, data)),
    getDetails: recordId => dispatch(actions.postDataAnc('popup', SAMPLESDB_DETAILS_REQUEST_URL, {id: recordId} )),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResultsContainer)

