import React, { useState } from "react"
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { isPending } from "../../reducers"
import { Spinner, Form } from "react-bootstrap"
import { Button } from "react-bootstrap"
import * as actions from '../../actions'
import * as notify from '../../utils/notify'
import {
    API_FILTER_CHANGE_NAME_ID,
    API_FILTER_CHANGE_NAME_URL,
    API_ALBUM_GET_ITEMS_ID_PREFIX,
} from '../../settings'

const ChangeFilterName = props => {
    const { t } = useTranslation(['common', 'forms', 'taxondb', 'icondb', 'profile'])

    const [newFilterName, setNewFilterName] = useState(props.name)

    const onNewFilterNameChanged = e => {
        setNewFilterName(e.target.value)
    }

    return (
        <div className="d-flex flex-wrap mt-2">
            {props.querying &&
                <div className="align-self-center m-1">
                    <Spinner animation="border" role="status" />
                </div>
            }
            <div className="m-1">{t('profile:new-name')}:</div>
            <div className="m-1">
                <Form.Control size="sm" value={newFilterName} onChange={onNewFilterNameChanged} disabled={props.querying}></Form.Control>
            </div>
            <div className="m-1">
                <Button size="sm" disabled={
                    !newFilterName || props.querying
                } onClick={e =>
                    props.changeFilterName(newFilterName, t, props.state)
                }>{t('profile:change-filter-name')}</Button>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    querying: isPending(API_FILTER_CHANGE_NAME_ID, state),
    state: state,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    changeFilterName: (newFilterName, t, state) => {
        dispatch(actions.postDataApi(API_FILTER_CHANGE_NAME_ID, API_FILTER_CHANGE_NAME_URL, {
            filter_id: ownProps.filterId,
            new_name: newFilterName
        })).then(() => {
            for (let request in state.requests) {
                if (request.startsWith(API_ALBUM_GET_ITEMS_ID_PREFIX + 'album')) {
                    const newData = {
                        ...state.requests[request].output, items: state.requests[request].output.items.map(obj => {
                            if (obj.reference_id === ownProps.filterId && obj.kind === 'filter') {
                                return { ...obj, name: newFilterName }
                            } else {
                                return obj
                            }
                        })
                    }
                    dispatch(actions.requestSuccess(request, newData))
                }
            }

            notify.success(t('profile:filter-name-changed'))
            ownProps.onDone && ownProps.onDone(newFilterName)
        }).catch(res => {
            notify.error(t('profile:cannot-change-filter-name'), res.result)
        })
    },
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChangeFilterName)
