import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Form } from  "react-bootstrap";
import { setVisibleToolScale, setVisibleToolZoomslider, setVisibleToolMagnifierGlass, setVisibleToolImageExport, setVisibleToolBasemaps, setVisibleToolDrawingTools } from '../../actions/map'

const Toolkit  = forwardRef((props, ref) => {
    const { t } = useTranslation(['map'])
    const { setVisibleToolScale, setVisibleToolZoomslider, setVisibleToolMagnifierGlass, setVisibleToolImageExport, setVisibleToolBasemaps, setVisibleToolDrawingTools, mapScaleVisible, zoomSliderVisible, magnifierGlassVisible, imageExportVisible, basemapsVisible, drawingToolsVisible } = {...props}

    const checkIfAllLayersVisible = () => {
        if (mapScaleVisible && zoomSliderVisible && magnifierGlassVisible && imageExportVisible && basemapsVisible && drawingToolsVisible){
            return true
        }
        else {
            return false
        }
    }

    return (
            <div>
                {props.preferences &&
                    <div>
                        <Form.Group controlId="allLayers">
                            <Form.Check 
                                custom
                                className="toolkit-item"
                                type="checkbox"
                                label={t('map:toolkit-all-tools')}
                                checked={checkIfAllLayersVisible()}
                                onChange={(e) => {
                                    if(e.target.checked){
                                        try{
                                            props.preferences.scale.addTo(ref.mapRef.current)
                                            ref.mapRef.current.addControl(props.preferences.zoomSlider)
                                            ref.mapRef.current.addControl(props.preferences.zoomGlass)
                                            props.preferences.baseMaps.addTo(ref.mapRef.current)
                                            ref.mapRef.current.addControl(ref.drawRef.current);
                                            setVisibleToolScale(true)
                                            setVisibleToolZoomslider(true)
                                            setVisibleToolMagnifierGlass(true)
                                            setVisibleToolImageExport(true)
                                            setVisibleToolBasemaps(true)
                                            setVisibleToolDrawingTools(true)
                                            let classNamesOfLayer = ["custom-content", "leaflet-draw-section", "leaflet-draw-toolbar", "leaflet-draw-edit-edit", "leaflet-draw-edit-remove", "leaflet-control-layers", "leaflet-control-zoomslider"]
                                            let hideClassesArray = ref.exportImageLayer.current.options.hideClasses
                                            classNamesOfLayer.forEach(item => hideClassesArray.push(item))
                                            ref.exportImageLayer.current.options.hideClasses = hideClassesArray
                                        }
                                        catch(e){
                                            //some layers undefined
                                        }
                                    }
                                    else{
                                        try{
                                            ref.mapRef.current.removeControl(props.preferences.scale)
                                            ref.mapRef.current.removeControl(props.preferences.zoomSlider) 
                                            ref.mapRef.current.removeControl(props.preferences.zoomGlass)
                                            ref.mapRef.current.removeControl(props.preferences.baseMaps)
                                            ref.mapRef.current.removeControl(ref.drawRef.current)
                                            setVisibleToolScale(false)
                                            setVisibleToolZoomslider(false)
                                            setVisibleToolMagnifierGlass(false)
                                            setVisibleToolImageExport(false)
                                            setVisibleToolBasemaps(false)
                                            setVisibleToolDrawingTools(false)
                                            let classNamesOfLayer = ["custom-content", "leaflet-draw-section", "leaflet-draw-toolbar", "leaflet-draw-edit-edit", "leaflet-draw-edit-remove", "leaflet-control-layers", "leaflet-control-zoomslider"]
                                            let hideClassesArray = ref.exportImageLayer.current.options.hideClasses
                                            hideClassesArray = hideClassesArray.filter(item => !classNamesOfLayer.includes(item))
                                            ref.exportImageLayer.current.options.hideClasses = hideClassesArray
                                        }
                                        catch(e){
                                            //some layers undefined
                                        }
                                    }
                                }}
                            />
                        </Form.Group>
                        <Form.Group controlId="mapScale">
                            <Form.Check
                                custom
                                className="toolkit-item"
                                type="checkbox" 
                                label={t('map:toolkit-scale')}
                                checked={mapScaleVisible}
                                onChange={(e) => {
                                    if(e.target.checked){
                                        props.preferences.scale.addTo(ref.mapRef.current)
                                        setVisibleToolScale(true)
                                    }
                                    else{
                                        ref.mapRef.current.removeControl(props.preferences.scale)
                                        setVisibleToolScale(false)
                                    }
                                }}
                            />
                        </Form.Group>
                        <Form.Group controlId="zoomSlider">
                            <Form.Check 
                                custom
                                className="toolkit-item"
                                type="checkbox" 
                                label={t('map:toolkit-zoom-slider')}
                                checked={zoomSliderVisible}
                                onChange={(e) => {
                                    if(e.target.checked){
                                        ref.mapRef.current.addControl(props.preferences.zoomSlider)
                                        setVisibleToolZoomslider(true)
                                        let classNameOfLayer = "leaflet-control-zoomslider"
                                        let hideClassesArray = ref.exportImageLayer.current.options.hideClasses
                                        hideClassesArray.push(classNameOfLayer)
                                        ref.exportImageLayer.current.options.hideClasses = hideClassesArray
                                    }
                                    else{
                                        ref.mapRef.current.removeControl(props.preferences.zoomSlider) 
                                        setVisibleToolZoomslider(false)
                                        let classNameOfLayer = "leaflet-control-zoomslider"
                                        let hideClassesArray = ref.exportImageLayer.current.options.hideClasses
                                        hideClassesArray = hideClassesArray.filter(item => item !== classNameOfLayer)
                                        ref.exportImageLayer.current.options.hideClasses = hideClassesArray
                                    }
                                }}
                            />
                        </Form.Group>
                        <Form.Group controlId="zoomGlass">
                            <Form.Check 
                                custom
                                className="toolkit-item"
                                type="checkbox" 
                                label={t('map:toolkit-glass')}
                                checked={magnifierGlassVisible}
                                onChange={(e) => {
                                    if(e.target.checked){
                                        let classNameOfLayer = "custom-content"
                                        ref.mapRef.current.addControl(props.preferences.zoomGlass)
                                        let hideClassesArray = ref.exportImageLayer.current.options.hideClasses
                                        hideClassesArray.push(classNameOfLayer)
                                        ref.exportImageLayer.current.options.hideClasses = hideClassesArray
                                        setVisibleToolMagnifierGlass(true)
                                    }
                                    else{
                                        let classNameOfLayer = "custom-content"
                                        ref.mapRef.current.removeControl(props.preferences.zoomGlass)
                                        let hideClassesArray = ref.exportImageLayer.current.options.hideClasses
                                        hideClassesArray = hideClassesArray.filter(item => item !== classNameOfLayer)
                                        ref.exportImageLayer.current.options.hideClasses = hideClassesArray
                                        setVisibleToolMagnifierGlass(false)
                                    }
                                }}
                            />
                        </Form.Group>
                        <Form.Group controlId="baseMaps">
                            <Form.Check 
                                custom
                                className="toolkit-item"
                                type="checkbox" 
                                label={ t('map:toolkit-basemaps')}
                                checked={basemapsVisible}
                                onChange={(e) => {
                                    if(e.target.checked){
                                        props.preferences.baseMaps.addTo(ref.mapRef.current)
                                        setVisibleToolBasemaps(true)
                                        let classNameOfLayer = "leaflet-control-layers"
                                        let hideClassesArray = ref.exportImageLayer.current.options.hideClasses
                                        hideClassesArray.push(classNameOfLayer)
                                        ref.exportImageLayer.current.options.hideClasses = hideClassesArray
                                    }
                                    else{
                                        ref.mapRef.current.removeControl(props.preferences.baseMaps) 
                                        setVisibleToolBasemaps(false)
                                        let classNameOfLayer = "leaflet-control-layers"
                                        let hideClassesArray = ref.exportImageLayer.current.options.hideClasses
                                        hideClassesArray = hideClassesArray.filter(item => item !== classNameOfLayer)
                                        ref.exportImageLayer.current.options.hideClasses = hideClassesArray

                                    }
                                }}
                            />
                        </Form.Group>
                        <Form.Group controlId="drawRef">
                            <Form.Check 
                                custom
                                className="toolkit-item"
                                type="checkbox" 
                                label={ t('map:toolkit-drawings')}
                                checked={drawingToolsVisible}
                                onChange={(e) => {
                                    if(e.target.checked){
                                        try{
                                            ref.mapRef.current.addControl(ref.drawRef.current);
                                            setVisibleToolDrawingTools(true)
                                            let classNamesOfLayer = ["leaflet-draw-section", "leaflet-draw-toolbar", "leaflet-draw-edit-edit", "leaflet-draw-edit-remove"]
                                            let hideClassesArray = ref.exportImageLayer.current.options.hideClasses
                                            classNamesOfLayer.forEach(item => hideClassesArray.push(item))
                                            ref.exportImageLayer.current.options.hideClasses = hideClassesArray
                                        }
                                        catch(e){
                                            //draw layer undefined
                                        }
                                    }
                                    else{
                                        try{
                                            ref.mapRef.current.removeControl(ref.drawRef.current)
                                            setVisibleToolDrawingTools(false)
                                            let classNamesOfLayer = ["leaflet-draw-section", "leaflet-draw-toolbar", "leaflet-draw-edit-edit", "leaflet-draw-edit-remove",  ]
                                            let hideClassesArray = ref.exportImageLayer.current.options.hideClasses
                                            hideClassesArray = hideClassesArray.filter(item => !classNamesOfLayer.includes(item))
                                            ref.exportImageLayer.current.options.hideClasses = hideClassesArray
                                        }
                                        catch(e){
                                            //draw layer undefined
                                        }
                                    }
                                }}
                            />
                        </Form.Group>
                    </div>
                }
            </div>
    )

})


const mapStateToProps = state => ({
    mapScaleVisible: state.map.mapScaleVisible,
    zoomSliderVisible: state.map.zoomSliderVisible,
    magnifierGlassVisible: state.map.magnifierGlassVisible,
    imageExportVisible: state.map.imageExportVisible,
    basemapsVisible: state.map.basemapsVisible,
    drawingToolsVisible: state.map.drawingToolsVisible,
})

const mapDispatchToProps = dispatch => ({
    setVisibleToolScale: data => dispatch(setVisibleToolScale(data)),
    setVisibleToolZoomslider: data => dispatch(setVisibleToolZoomslider(data)), 
    setVisibleToolMagnifierGlass: data => dispatch(setVisibleToolMagnifierGlass(data)), 
    setVisibleToolImageExport: data => dispatch(setVisibleToolImageExport(data)), 
    setVisibleToolBasemaps: data => dispatch(setVisibleToolBasemaps(data)),
    setVisibleToolDrawingTools: data => dispatch(setVisibleToolDrawingTools(data)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(Toolkit)
