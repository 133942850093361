import React, { useState } from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import * as actions from '../../actions'
import {
    TAXONDB_DETAILS_REQUEST_ID_PREFIX,
    TAXONDB_SEARCH_REQUEST_URL,
    TAXONDB_DETAILS_REQUEST_URL,
    ANC_URL_POSTFIX,
} from '../../settings'
import { getItems, getPagination, getFilter, isPending } from "../../reducers";
import ConnectedPagination from "../ConnectedPagination"
import { GENUS_ATTRIBUTE_NAME, SPECIES_ATTRIBUTE_NAME } from '../taxonDB/attributesStructure'
import { ListGroup, Collapse, Spinner, Button } from "react-bootstrap"
import TaxonDBRecordDetailsContainer from "../taxonDB/RecordDetailsContainer";
import ListGroupItemDiv from "../ListGroupItemDiv";

const FiltersetItemsList = props => {
    const { t } = useTranslation([ 'common', 'forms', 'taxondb' ])

    const [ open, setOpen ] = useState({})

    const toggleOpen = (recordId, id) => {
        const temp = { ...open }
        temp[ id ] = !temp[ id ]
        setOpen(temp)
        if (temp[ id ]) {
            props.getDetails(recordId, id)
        }
    }

    return (
        <div>
            <div className="d-flex flex-column ml-4">
                {props.loading &&
                    <div className="align-self-center">
                        <Spinner animation="border" role="status" />
                    </div>
                }
                {!props.loading &&
                    <div>
                        {props.items && props.items.length > 0 &&
                            <div>
                                <div>
                                    <ConnectedPagination requestId={props.requestId} url={ANC_URL_POSTFIX + TAXONDB_SEARCH_REQUEST_URL}></ConnectedPagination>
                                </div>
                                <ListGroup>
                                    {props.items.map(val => {
                                        const requestId = props.requestId + TAXONDB_DETAILS_REQUEST_ID_PREFIX + val.kolekcjanumerokazu
                                        return (
                                            <div key={requestId}>
                                                <ListGroupItemDiv className="d-flex flex-row album-element align-items-center"
                                                    onClick={() => toggleOpen(val.kolekcjanumerokazu, requestId)}
                                                    aria-controls={requestId}
                                                    aria-expanded={open[ requestId ]}>
                                                    <div className="flex-grow-1">
                                                        <span className="pr-3">
                                                            <img src="/images/amuZasób 5@4x-100.jpg" alt={t('a11y:icon-taxon')} width="31px" />
                                                        </span>
                                                        {val.kolekcjanumerokazu}: <i>{val[ GENUS_ATTRIBUTE_NAME ]} {val[ SPECIES_ATTRIBUTE_NAME ]}</i> {val.autorgatunku}
                                                    </div>
                                                    <div className="d-flex">
                                                        <Button
                                                            variant="secondary"
                                                            aria-controls={requestId}
                                                            aria-expanded={open[ requestId ]}
                                                            size="sm"
                                                            className="align-self-center mr-2"
                                                        >
                                                            {!open[ requestId ] &&
                                                                <span>{t('common:show_details')}</span>
                                                            }
                                                            {open[ requestId ] &&
                                                                <span>{t('common:hide_details')}</span>
                                                            }
                                                        </Button>
                                                    </div>
                                                </ListGroupItemDiv>
                                                <Collapse in={open[ requestId ]} id={requestId}>
                                                    <div>
                                                        <TaxonDBRecordDetailsContainer requestId={requestId} recordId={val.kolekcjanumerokazu}></TaxonDBRecordDetailsContainer>
                                                    </div>
                                                </Collapse>
                                            </div>
                                        )
                                    })}
                                </ListGroup>
                                <div>
                                    <ConnectedPagination last requestId={props.requestId} url={ANC_URL_POSTFIX + TAXONDB_SEARCH_REQUEST_URL}></ConnectedPagination>
                                </div>
                            </div>
                        }
                        {props.items && !props.items.length > 0 &&
                            <p>{t('no-records-found')}</p>
                        }
                    </div>
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    items: getItems(ownProps.requestId, state),
    loading: isPending(ownProps.requestId, state),
    filter: getFilter(ownProps.genusRequestId, state),
    pagination: getPagination(ownProps.requestId, state),
    user: state.user ? state.user.data : null,
})

const mapDispatchToProps = dispatch => ({
    getDetails: (recordId, requestId) => dispatch(actions.getDataAnc(requestId, TAXONDB_DETAILS_REQUEST_URL + recordId + '/')),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FiltersetItemsList)
