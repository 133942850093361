import React, { Suspense } from "react";
import { connect } from 'react-redux'
import { isPending, getOutput } from "../../reducers";
import { Spinner } from "react-bootstrap"
import Loader from '../../utils/loader'
import RecordDetails from './RecordDetails'

const RecordDetailsContainer = props => {
  return (<div>
    <Suspense fallback={<Loader />}>
      <div className="d-flex flex-column pt-2 common-panel-background">
        {props.loading &&
          <div className="align-self-center m-3">
            <Spinner animation="border" role="status" />
          </div>
        }
        {!props.loading && props.data &&
          <RecordDetails {...props}></RecordDetails>
        }
      </div>
    </Suspense>
  </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  loading: isPending(ownProps.requestId, state),
  data: getOutput(ownProps.requestId, state),
})

const mapDispatchToProps = dispatch => ({
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecordDetailsContainer)
