import React, { useEffect, useState } from "react";
import { Suspense } from "react";
import { Button, Container, Jumbotron } from "react-bootstrap"
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Redirect, useLocation } from "react-router";
import { getOutput, isPending } from "../reducers";
import { TAXONDB_COUNT_REQUEST_ID } from "../settings";
import Loader from "../utils/loader";
import SEO from "./SEO";

const NotFound = props => {
    const { t, i18n } = useTranslation(["404"])
    const MAX_NUM = 10000000
    const MIN_NUM = 1000000
    const [rand, setRand] = useState(MIN_NUM)

    const location = useLocation()
    const [redirect, setRedirect] = useState(false)

    const randomize = () => Math.floor(Math.random()*(MAX_NUM-MIN_NUM))+MIN_NUM
    
    const removeSlashes = (url) => {
      if(location.pathname.slice(-1)==='/')
        url = url.slice(0,-1)
      return url.replace("/", "")
    }

    useEffect(()=>{
      if(props.loadingCount){
        const interval = setInterval(()=>{
          setRand(randomize())
        }, 300)
        return () => clearInterval(interval);
      }
    }, [props.loadingCount])

    const goto = url => {
      window.scrollTo(0, 0)
      setRedirect(url)
    }
  
  return(
    <Jumbotron fluid>
      <SEO title="404" />
      {redirect &&
          <Redirect push to={redirect} ></Redirect>
      }
      <Container className="not-found">
        <h1>404</h1>
        <Suspense fallback={<Loader />}>
          <h2>{t('line-1-1')}{removeSlashes(location.pathname)}{t('line-1-2')}</h2>
          <p>{t('line-2-1')}
          <b>
            {props.loadingCount &&
                rand
            }
            {props.count && !props.loadingCount &&
                props.count.taxons.TAXON.toLocaleString(i18n.languages[0])
            }
          </b>
          {t('line-2-2')}
          </p>
          <p>{t('line-3')}</p>
          <Button className="see-more-button" onClick={e => { goto("/") }}>{t('go-back')}</Button>
          <Button className="see-more-button" onClick={e => { goto(t("paths:site-map")) }}>{t('go-to-map')}</Button>
        </Suspense>
      </Container>
    </Jumbotron>
  )
}

const mapStateToProps = state => ({
  loadingCount: isPending(TAXONDB_COUNT_REQUEST_ID, state),
  count: getOutput(TAXONDB_COUNT_REQUEST_ID, state),
})


export default connect(
  mapStateToProps
)(NotFound)
