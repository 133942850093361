import { getDataAnc, postDataAnc, requestRemoveStartsWith } from '../actions'
import {
    TAXONDB_SEARCH_REQUEST_URL,
    TAXONDB_SEARCH_GENUS_REQUEST_URL,
    TAXONDB_SEARCH_SPECIES_REQUEST_URL,
    TAXONDB_DETAILS_REQUEST_ID_PREFIX,
    COLLECTION_TYPES_REQUEST_ID,
    COLLECTION_TYPES_REQUEST_URL,
    DICTIONARY_COLLECTION_TYPES,
    COMMON_DICTIONARIES,
    SAMPLESDB_DETAILS_REQUEST_ID_PREFIX,
    SAMPLESDB_SEARCH_REQUEST_URL,
    BIBLIODB_DETAILS_REQUEST_ID_PREFIX,
    BIBLIODB_SEARCH_REQUEST_URL,
    TAXONDB_SEARCH_WITH_AGGREGATION_REQUEST_URL,
    MAP_DATA_REQUEST_TIMEOUT,
    TAXON_MAP_GROUPING_SHAPE_ARRAY,
    TAXON_MAP_GROUPING_COLOR_ARRAY,
    TAXON_MAP_GROUPING_NUMBER_OF_ITEMS_COLOR_LEGEND,
    TAXON_MAP_GROUPING_NUMBER_OF_ITEMS_SHAPE_LEGEND,
    TAXON_MAP_GROUPING_COLOR_FIELD_CONST,
    TAXON_MAP_GROUPING_SHAPE_FIELD_CONST,
    TAXONDB_SEARCH_WITH_AGGREGATION_REQUEST_ID,
    TAXONDB_SEARCH_MAP_DATA_REQUEST_URL,
    GENERAL_DEFAULT_REQUEST_TIMEOUT,
    GEOJSON_EXTENDED_REQUEST_TIMEOUT,
    TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID,
    TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID,
    TAXONDB_SEARCH_GENUS_REQUEST_ID,
} from '../settings'
import { setFilter } from '../actions'
import { getPagination, prepareFilter } from "../reducers"
import { GENUS_ATTRIBUTE_NAME, SPECIES_ATTRIBUTE_NAME, BOTANIKAZOOLOGIA_FIELD_NAME, ROK_ZBIORU_FIELD_NAME } from '../components/taxonDB/attributesStructure'

export const SET_FIELDS = 'SET_FIELDS'
export const SET_FIELD = 'SET_FIELD'
export const SET_FIELD_WITH_PATH = 'SET_FIELD_WITH_PATH'
export const DELETE_FIELD = 'DELETE_FIELD'
export const DELETE_ALL_FIELDS = 'DELETE_ALL_FIELDS'
export const SET_SEARCH_AREA_MAPREF = 'SET_SEARCH_AREA_MAPREF'
export const SET_SEARCH_AREA_GEOJSON = 'SET_SEARCH_AREA_GEOJSON'
export const SET_MAP_ICONS_DATA = 'MAP_ICONS_DATA'


export const setFields = (id, fields) => ({
    type: SET_FIELDS,
    id: id,
    fields: fields,
})

export const setField = (id, fieldId, value) => ({
    type: SET_FIELD,
    id: id,
    fieldId: fieldId,
    value: value,
})

export const setSearchAreaGeojson = (value) => ({
    type: SET_SEARCH_AREA_GEOJSON,
    value: value
})

export const setSearchAreaMapRef = (mapRef) => ({
    type: SET_SEARCH_AREA_MAPREF,
    value: mapRef
})

export const deleteField = (id, fieldId) => ({
    type: DELETE_FIELD,
    id: id,
    fieldId: fieldId,
})

export const deleteAllFields = () => ({
    type: DELETE_ALL_FIELDS,
})

export const setMapsData = (value) => ({
    type: SET_MAP_ICONS_DATA,
    value: value
})

export const runSearchFlow = (genusRequestId, speciesRequestId, requestId, tabId, path, geoJson) => (
    (dispatch, getState) => {
        let timeout = Array.isArray(geoJson) ? GEOJSON_EXTENDED_REQUEST_TIMEOUT : GENERAL_DEFAULT_REQUEST_TIMEOUT
        const filter = prepareFilter(genusRequestId, getState(), path)
        dispatch(setFilter(genusRequestId, filter))
        const newPaginationGenus = { ...(getPagination(genusRequestId, getState())) }
        newPaginationGenus.currentPage = 1
        // remove previous item details to get updated version from server on demand
        dispatch(requestRemoveStartsWith(TAXONDB_DETAILS_REQUEST_ID_PREFIX))

        dispatch(setField(tabId, tabId, '0'))
        return dispatch(postDataAnc(genusRequestId, TAXONDB_SEARCH_GENUS_REQUEST_URL,
            {
                filter: filter,
                pagination: newPaginationGenus,
            }, timeout))
            .then(genus => {
                if (genus.items.length === 1) {
                    dispatch(setField(tabId, tabId, '1'))
                    filter.rodzaj = genus.items[ 0 ][ GENUS_ATTRIBUTE_NAME ]
                }
                dispatch(setFilter(speciesRequestId, filter))
                const newPaginationSpecies = { ...(getPagination(speciesRequestId, getState())) }
                newPaginationSpecies.currentPage = 1
                return dispatch(postDataAnc(speciesRequestId, TAXONDB_SEARCH_SPECIES_REQUEST_URL,
                    {
                        filter: filter,
                        pagination: newPaginationSpecies,
                    }, timeout))
                    .then(species => {
                        if (species.items.length === 1) {
                            dispatch(setField(tabId, tabId, '2'))
                            filter.gatunek = species.items[ 0 ][ SPECIES_ATTRIBUTE_NAME ]
                        }
                        dispatch(setFilter(requestId, filter))
                        const newPagination = { ...(getPagination(requestId, getState())) }
                        newPagination.currentPage = 1
                        return dispatch(postDataAnc(requestId, TAXONDB_SEARCH_REQUEST_URL,
                            {
                                filter: filter,
                                pagination: newPagination,
                            }, timeout))
                    })
            })
    }
)

export const runSearchFlowSamples = (requestId, tabId, path) => (
    (dispatch, getState) => {
        const filter = prepareFilter(requestId, getState(), path)
        dispatch(setFilter(requestId, filter))
        const newPagination = { ...(getPagination(requestId, getState())) }
        newPagination.currentPage = 1
        // remove previous item details to get updated version from server on demand
        dispatch(requestRemoveStartsWith(SAMPLESDB_DETAILS_REQUEST_ID_PREFIX))

        dispatch(setField(tabId, tabId, '0'))
        return dispatch(postDataAnc(requestId, SAMPLESDB_SEARCH_REQUEST_URL,
            {
                filter: filter,
                pagination: newPagination,
            }))
    }
)

export const runSearchFlowBiblio = (requestId) => (
    (dispatch, getState) => {
        const filter = prepareFilter(requestId, getState())
        dispatch(setFilter(requestId, filter))
        const newPagination = { ...(getPagination(requestId, getState())) }
        newPagination.currentPage = 1
        // remove previous item details to get updated version from server on demand
        dispatch(requestRemoveStartsWith(BIBLIODB_DETAILS_REQUEST_ID_PREFIX))

        return dispatch(postDataAnc(requestId, BIBLIODB_SEARCH_REQUEST_URL,
            {
                filter: filter,
                pagination: newPagination,
            }))
    }
)

export const runSearchFlowStats = (requestId, path) => (
    (dispatch, getState) => {
        const filter = prepareFilter(requestId, getState(), path)
        dispatch(setFilter(requestId, filter))
        const newPagination = { ...(getPagination(requestId, getState())) }
        newPagination.currentPage = 1

        return dispatch(postDataAnc(requestId, TAXONDB_SEARCH_REQUEST_URL,
            {
                filter: filter,
                pagination: newPagination,
            }))
    }
)

export const runMapGroupingSearch = (requestId, aggFields) => (
    (dispatch, getState) => {
        let searchFilter
        const filter = prepareFilter(requestId, getState())
        if (requestId === TAXONDB_SEARCH_GENUS_REQUEST_ID) {
            searchFilter = filter
            dispatch(setFilter(requestId, filter))
        }
        else if (requestId === TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID || requestId === TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID) {
            searchFilter = JSON.parse(filter[ requestId ])
            dispatch(setFilter(requestId, filter[ requestId ]))
        }
        let itemsByShapeMap = new Map()
        let itemsByColorMap = new Map()
        return dispatch(postDataAnc(requestId,
            TAXONDB_SEARCH_WITH_AGGREGATION_REQUEST_URL, {
            filter: searchFilter,
            aggregation_field: aggFields[ 0 ].value,
            pagination: { perPage: aggFields[ 0 ].type === TAXON_MAP_GROUPING_SHAPE_FIELD_CONST ? TAXON_MAP_GROUPING_NUMBER_OF_ITEMS_SHAPE_LEGEND : TAXON_MAP_GROUPING_NUMBER_OF_ITEMS_COLOR_LEGEND },
        },
            MAP_DATA_REQUEST_TIMEOUT
        ))
            .then(response1 => {
                response1.items.forEach((item, index) => {
                    if (aggFields[ 0 ].type === TAXON_MAP_GROUPING_COLOR_FIELD_CONST) {
                        itemsByColorMap.set(item[ aggFields[ 0 ].value ], TAXON_MAP_GROUPING_COLOR_ARRAY[ index ])
                    }
                    if (aggFields[ 0 ].type === TAXON_MAP_GROUPING_SHAPE_FIELD_CONST) {
                        itemsByShapeMap.set(item[ aggFields[ 0 ].value ], TAXON_MAP_GROUPING_SHAPE_ARRAY[ index ])
                    }
                })
                if (aggFields[ 1 ]) {
                    return dispatch(postDataAnc(requestId,
                        TAXONDB_SEARCH_WITH_AGGREGATION_REQUEST_URL, {
                        filter: searchFilter,
                        aggregation_field: aggFields[ 1 ].value,
                        pagination: { perPage: aggFields[ 1 ].type === TAXON_MAP_GROUPING_COLOR_FIELD_CONST ? TAXON_MAP_GROUPING_NUMBER_OF_ITEMS_COLOR_LEGEND : TAXON_MAP_GROUPING_NUMBER_OF_ITEMS_SHAPE_LEGEND },
                    },
                        MAP_DATA_REQUEST_TIMEOUT
                    ))
                        .then(response2 => {
                            response2.items.forEach((item, index) => {
                                if (aggFields[ 1 ].type === TAXON_MAP_GROUPING_COLOR_FIELD_CONST) {
                                    itemsByColorMap.set(item[ aggFields[ 1 ].value ], TAXON_MAP_GROUPING_COLOR_ARRAY[ index ])
                                }
                                if (aggFields[ 1 ].type === TAXON_MAP_GROUPING_SHAPE_FIELD_CONST) {
                                    itemsByShapeMap.set(item[ aggFields[ 1 ].value ], TAXON_MAP_GROUPING_SHAPE_ARRAY[ index ])
                                }
                            })
                            return dispatch(setMapsData({
                                shapeItems: itemsByShapeMap,
                                colorItems: itemsByColorMap,
                                filter: searchFilter
                            }))
                        })
                }
                else {
                    return dispatch(setMapsData({
                        shapeItems: itemsByShapeMap,
                        colorItems: itemsByColorMap,
                        filter: searchFilter
                    }))
                }
            })
    }
)

export const runCartogramSearch = (requestId, field) => (
    (dispatch, getState) => {
        let searchFilter
        const filter = prepareFilter(requestId, getState())
        if (requestId === TAXONDB_SEARCH_GENUS_REQUEST_ID) {
            searchFilter = filter
            dispatch(setFilter(requestId, filter))
        }
        else if (requestId === TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID || requestId === TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID) {
            searchFilter = JSON.parse(filter[ requestId ])
            dispatch(setFilter(requestId, filter[ requestId ]))
        }

        return dispatch(postDataAnc(TAXONDB_SEARCH_WITH_AGGREGATION_REQUEST_ID,
            TAXONDB_SEARCH_WITH_AGGREGATION_REQUEST_URL, {
            filter: searchFilter,
            aggregation_field: field,
            pagination: { perPage: 5000 },
        },
            MAP_DATA_REQUEST_TIMEOUT
        ))
    }
)

export const runSearchMap = (requestId, timeout) => (
    (dispatch, getState) => {
        let searchFilter
        const filter = prepareFilter(requestId, getState())
        if (requestId === TAXONDB_SEARCH_GENUS_REQUEST_ID) {
            searchFilter = filter
            dispatch(setFilter(requestId, filter))
        }
        else if (requestId === TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID || requestId === TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID) {
            searchFilter = JSON.parse(filter[ requestId ])
            dispatch(setFilter(requestId, filter[ requestId ]))
        }

        return dispatch(postDataAnc(requestId, TAXONDB_SEARCH_MAP_DATA_REQUEST_URL, { filter: searchFilter }, timeout))
    }
)

export const newRunSearchMap = (requestId, filter, timeout) => (
    (dispatch, getState) => {
        return dispatch(postDataAnc(requestId, TAXONDB_SEARCH_MAP_DATA_REQUEST_URL, { filter: filter }, timeout))
    }
)

export const runHistoricalAnalysis = (requestId, filter, displayTaxons) => (
    (dispatch, getState) => {
        const PAGINATION_LIMIT = 1000;
        return dispatch(postDataAnc(
            requestId,
            TAXONDB_SEARCH_WITH_AGGREGATION_REQUEST_URL, 
            {
                filter: filter,
                aggregation_field: ROK_ZBIORU_FIELD_NAME,
                pagination: {perPage: PAGINATION_LIMIT},
            },
            MAP_DATA_REQUEST_TIMEOUT
        ))
    }
)

const preBOT = [
    {
        value: '',
        en: 'All',
        pl: 'Wszystkie'
    },
    {
        value: 'NHC-BOT',
        en: 'Botanical and mycological',
        pl: 'Botaniczne i mykologiczne'
    },
]

const preZOO = [
    {
        value: 'NHC-ZOO',
        en: 'Zoological',
        pl: 'Zoologiczne'
    },
]

export const loadCollectionTypes = () =>
    dispatch => dispatch(getDataAnc(COLLECTION_TYPES_REQUEST_ID, COLLECTION_TYPES_REQUEST_URL))
        .then(res => {
            const output = [
                ...preBOT,
                ...(res.filter(i => i.rodzaj_kolekcji === 'NHC-BOT').map(obj => (
                    { prefix: '· ', value: obj[ BOTANIKAZOOLOGIA_FIELD_NAME ], en: obj.opis_eng, pl: obj.opis_pl, liczba_okazow: obj.liczba_okazow, liczba_zdjec: obj.liczba_zdjec }
                ))),
                ...preZOO,
                ...(res.filter(i => i.rodzaj_kolekcji === 'NHC-ZOO').map(obj => (
                    { prefix: '· ', value: obj[ BOTANIKAZOOLOGIA_FIELD_NAME ], en: obj.opis_eng, pl: obj.opis_pl, liczba_okazow: obj.liczba_okazow, liczba_zdjec: obj.liczba_zdjec }
                ))),
            ]
            dispatch(setField(COMMON_DICTIONARIES, DICTIONARY_COLLECTION_TYPES, output))
        })

