import React from "react"
import {
    TAXONDB_SEARCH_GENUS_REQUEST_ID,
    TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID,
    TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID,
} from "../../../../settings"
import L from 'leaflet';
import ReactDOMServer from 'react-dom/server';
import * as notify from '../../../../utils/notify'
import { 
    generate_cartogram_of_countries, 
    generateCartogramOfVoivodeships, 
    generateCartogramOfCommunes, 
    generateCartogramOfDistricts, 
    generateCartogramOfNationalParks, 
    generateCartogramOfLandscapeParks,
    generateCartogramOfAtpolGrid,
    generateCartogramOfUTMGridPoland,
    generateCartogramOfLocationPhysiographicDivision,
} from "../CartogramFunctions"


export const arrangementSearchItems = (ref, props, t) => {

    const { closeSlidebar, cartogramBordersColor, cartogramBordersWidth, cartogramOpacity, cartogramAdministrativeDivision, cartogramFirstAreaColor, cartogramSecondAreaColor } = { ...props }

    /* Clear Cartogram */
    if (ref.cartogramRef.current){
        ref.cartogramRef.current.eachLayer(function(layer){
            ref.cartogramRef.current.removeLayer(layer);
        });
    }

    const removeCartogramLegend = () => {
        if (ref.cartogramLegendDiv.current){
            ref.cartogramLegendDiv.current.remove()
        }
        ref.cartogramLegendDiv.current = L.control({ position: "bottomleft" })   
    }
   
    const getFilterId = () => {
        let id
        if (props.isDynamic === "0"){
            id = TAXONDB_SEARCH_GENUS_REQUEST_ID
        }
        if (props.isDynamic === "2"){
            id = TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID
        }
        if (props.isDynamic === "1"){
            id = TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID
        }
        return id
    }

    const prepareCartogramLegend = (areaWithTaxons, areaWithoutTaxons) => {    
        ref.cartogramLegendDiv.current.onAdd = function() {
            var div = L.DomUtil.create("div", "legend");
            div.style.backgroundColor = 'white';
            div.style.padding = '10px';
            div.style.width = '260px'
            div.innerHTML = ReactDOMServer.renderToString(
                <div style={{fontSize: 10, float: 'center'}}>
                    <div style={{width:'300px'}}>
                        {prepareLegendColorBar(areaWithTaxons)}
                        {prepareLegendText(t("map:with-sites"))}
                    </div>
                    <div style={{width:'300px'}}>
                        {prepareLegendColorBar(areaWithoutTaxons)}
                        {prepareLegendText(t("map:no-sites"))}
                    </div>
                </div>
            )
            return div
        }
        ref.cartogramLegendDiv.current.addTo(ref.mapRef.current)  
    }

    const prepareLegendColorBar = (color) => {
        return <span style={{height:'20px', backgroundColor: color, paddingRight:'95px', border: 'ridge' }} />
    }

    const prepareLegendText = (text) => {
        return(
            <span style={{ backgroundColor: 'white', width:'90px', marginLeft:'5px' }} >
               {text}
            </span>
        )
    }

    const prepareCartogramCallback = (items, AREA_WITHOUT_TAXONS, loader) => {
        return Promise.resolve(
            prepareCartogram(items, AREA_WITHOUT_TAXONS, loader)
        )
    }

    const prepareCartogram = (items, AREA_WITHOUT_TAXONS, loader) => {
        
        let cartogramParams = {
            areaWithoutTaxons: AREA_WITHOUT_TAXONS, 
            areaFillOpacity: cartogramOpacity, 
            borderOpacity: cartogramBordersWidth, 
            administrativeBorderColor: cartogramBordersColor
        }

        switch(cartogramAdministrativeDivision){
            case "panstwo":
                generate_cartogram_of_countries(items, ref.cartogramRef.current, renderPopup, cartogramParams, loader, t)
                break
            case "wojewodztwo":
                generateCartogramOfVoivodeships(items, ref.cartogramRef.current, renderPopup, cartogramParams, loader, t)
                break
            case "powiat":
                generateCartogramOfDistricts(items, ref.cartogramRef.current, renderPopup, cartogramParams, loader, t)
                break
            case "gmina":
                generateCartogramOfCommunes(items, ref.cartogramRef.current, renderPopup, cartogramParams, loader, t)
                break
            case "parknarodowy":
                generateCartogramOfNationalParks(items, ref.cartogramRef.current, renderPopup, cartogramParams, loader, t)
                break
            case "parkkrajobrazowy":
                generateCartogramOfLandscapeParks(items, ref.cartogramRef.current, renderPopup, cartogramParams, loader, t)
                break
            case "wspolrzedneatpol":
                generateCartogramOfAtpolGrid(items, ref.cartogramRef.current, renderPopup, cartogramParams, loader, t)
                break
            case "wspolrzedneutm":
                generateCartogramOfUTMGridPoland(items, ref.cartogramRef.current, renderPopup, cartogramParams, loader, t)
                break
            case "polozeniewpodzialefizjograficznym":
                generateCartogramOfLocationPhysiographicDivision(items, ref.cartogramRef.current, renderPopup, cartogramParams, loader, t)
                break
            default:
                notify.error(t('map:administrative-division-undefined'))
                break
        }
    }

    const renderPopup = (text, taxons) => {
        return ReactDOMServer.renderToString(
            <div>
                <div>{t(`taxondb-attributes:${cartogramAdministrativeDivision}`)}: {text}</div>
                <div>{t('map:number-of-taxons-popup')} {taxons}</div>
            </div>
        )
    }

    closeSlidebar()
    let loader = L.control.loader()
    loader.addTo(ref.mapRef.current);
    removeCartogramLegend()
    let id = getFilterId()
    
    return props.runCartogramSearch(id, cartogramAdministrativeDivision)
    .then(res => {
        if (res.items && res.items.length){
            let items = new Map()
            res.items.forEach((item) => {  
                items.set(item[cartogramAdministrativeDivision], {
                    color: cartogramSecondAreaColor,
                    numberOfTaxons: item.liczba_okazow
                })
            })
            return {
                items : items, 
                areaWithoutTaxons: cartogramFirstAreaColor,
                areaWithTaxons: cartogramSecondAreaColor,
            }
        }
        else {
            return "NO_TAXONS"
        }
    })
    .then((response) => {
        if (response.items && response.items.size){
            prepareCartogramCallback(response.items, response.areaWithoutTaxons, loader)
            .then(() => {
                prepareCartogramLegend(response.areaWithTaxons, response.areaWithoutTaxons)
            })
        }
        else{
            notify.error(t("map:cartogram-error-msg"))
            loader.hide();
        }
    })
    .catch(
        function (error) {
            notify.error(t("map:cartogram-error-msg"))
            loader.hide();
            return Promise.reject(error)
        }
    )
}
