import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setFields } from '../actions/forms';

const useFieldRedux = (id, defaultValue) => {
  const [ initValue ] = useState(defaultValue);
  const value = useSelector(state => state?.forms?.[id]?.fields);
  const dispatch = useDispatch();

  useEffect(() => {
    if (value === undefined && initValue !== undefined) {
      dispatch(setFields(id, defaultValue));
    }
  }, [ value, dispatch, id, defaultValue, initValue ]);

  const setValue = useCallback(val => {
    dispatch(setFields(id, val));
  }, [ id, dispatch ]);

  return { value, setValue };
};
export default useFieldRedux;
