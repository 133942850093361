import React, { forwardRef, useEffect, useState } from "react"
import { connect } from 'react-redux'
import { Row, Spinner, Button, Col, Form, Collapse } from "react-bootstrap";
import {
    MAP_COLORPICKER_LIST,
    COLORS_PAIRS,
    ARRANGEMENT_TOOLS_ARRANGEMENT_PANEL,
    TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID,
    TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID,
    TAXONDB_SEARCH_GENUS_REQUEST_ID,
    PROJECTS_GET_OBSERVATIONS_DETAILS_ID,
    PROJECTS_GET_OBSERVATIONS_DETAILS_URL,
    MAP_OPENED_TOOL_VISUALIZATION_DETAILS,
} from "../../../../settings"
import { getField, isPending } from "../../../../reducers";
import * as actions from '../../../../actions'
import { CirclePicker } from 'react-color';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleRight, faMapMarked } from "@fortawesome/free-solid-svg-icons";
import { arrangementMapSearch } from "./ArrangementMapSearch"
import { setOpenedMapTool, setPixiMarkerColor, setPixiMarkerShape, setPixiMarkerSize, setPixiSelectedCirclecolor, setLoadObservationsOnInit } from "../../../../actions/map";
import L from 'leaflet';
import * as notify from '../../../../utils/notify'
import MarkerLabels from "../../MarkerLabels";
import useTranslationMemo from "../../../../hooks/useTranslationMemo";
import useMapRedux from "../../../../hooks/useMapRedux";
import { MAP_OBSERVATIONS } from '../../../../stateIds';
import useMapDefinition from '../../../../hooks/useMapDefinition';

const ArrangementMap = forwardRef((props, ref) => {
    const { setValue: setMapObservations } = useMapRedux(MAP_OBSERVATIONS, {});
    const { value: observationsIcon } = useMapRedux('observationsIcon', 'square');
    const { value: observationsColor } = useMapRedux('observationsColor', 'orange');
    const { value: observationsSize } = useMapRedux('observationsSize', '2x');
    const { t, i18n } = useTranslationMemo(['map', 'taxondb-attributes', 'taxondb-groups', 'common'])
    const { collapsed, openSlidebar, loadObservationsOnInit, getObservationDetails, observationsRedirectedId,
        setActiveMapTool, activeMapTool, setLoadObservationsOnInit } = { ...props, lang: t, langi18n: i18n }
    const [showLabels, setShowLabels] = useState(false)
    const {loadObservations} = useMapDefinition();

    useEffect(() => {
        if (loadObservationsOnInit) {
            setLoadObservationsOnInit(false)
            const loader = L.control.loader();
            setTimeout(() => { // delay to allow map to load
                loader.addTo(ref.mapRef.current);
            }, 50)
           getObservationDetails(observationsRedirectedId)
           .then(res => {
                const { cluster, noCoordsObservations, numberOfObservations } = loadObservations(ref.mapRef, ref.observationsRef, res.items.observations, observationsIcon, observationsColor, observationsSize);
                if (cluster.getLayers().length > 0) {
                    try{
                        ref.mapRef.current.fitBounds(ref.observationsRef.current.getBounds())
                    }
                    catch(e){
                        /* Map bounds undefined */
                    }
                    setMapObservations({
                        [observationsRedirectedId]: {
                            color: observationsColor,
                            shape: observationsIcon,
                            size: observationsSize,
                            leafletId: cluster._leaflet_id, 
                        }
                    })
                    if (noCoordsObservations === 0){
                        notify.success(t('map:added-project-notify'))
                    }
                    if (noCoordsObservations > 0 && noCoordsObservations < numberOfObservations){
                        let loadedTaxons = numberOfObservations - noCoordsObservations
                        notify.warning(t('map:some-observations-added-notify', {loaded: loadedTaxons, total: numberOfObservations}))
                    } 
                    if (noCoordsObservations > 0 && noCoordsObservations === numberOfObservations){
                        notify.error(t('map:cannot-add-project-notify'))
                    }
                }
                else {
                    let loadedTaxons = numberOfObservations - noCoordsObservations
                    notify.warning(t('map:some-observations-added-notify', {loaded: loadedTaxons, total: numberOfObservations}))
                }
                loader.hide()
            })
        }
    }, [loadObservationsOnInit, observationsRedirectedId, observationsColor, observationsIcon, getObservationDetails, ref.observationsRef, ref.mapRef, t,
        setMapObservations, loadObservations, setLoadObservationsOnInit, observationsSize])

    return(
        <div>
            {collapsed &&
                <div className="sidebar-single-button-container">
                    <button
                    title={t('map:visualisation-parameters') + ' - ' + t('menu:position-map')}
                    className="sidebar-button-collapsed"
                        onClick={() => {
                            openSlidebar()
                            setActiveMapTool(MAP_OPENED_TOOL_VISUALIZATION_DETAILS)
                        }} 
                    >
                    <FontAwesomeIcon icon={faMapMarked} color={"white"}/>
                    </button>
                </div>
            }
            {!collapsed &&
            <div>
                <div className="sidebar-opened-buttons-container">
                    <div className="sidebar-single-button-container">
                        <button
                            title={t('map:visualisation-parameters') + ' - ' + t('menu:position-map')}
                            className="sidebar-button-collapsed"
                            onClick={() => {activeMapTool === MAP_OPENED_TOOL_VISUALIZATION_DETAILS ? setActiveMapTool(null) : setActiveMapTool(MAP_OPENED_TOOL_VISUALIZATION_DETAILS)}}
                        >
                            <FontAwesomeIcon icon={faMapMarked} color={"white"}/>
                        </button>
                    </div>
                    <div
                        className="sidebar-text playContentOpacityAnimation changeContentOpacityAnimation"
                        onClick={() => {activeMapTool === MAP_OPENED_TOOL_VISUALIZATION_DETAILS ? setActiveMapTool(null) : setActiveMapTool(MAP_OPENED_TOOL_VISUALIZATION_DETAILS)}}
                    >  
                        {t('map:visualisation-parameters') + ' - ' + t('menu:position-map')} 
                    </div> 

                    <div className="sidebar-items-arrow changeOpacityAnimation playOpacityAnimation">
                        <FontAwesomeIcon icon={activeMapTool === MAP_OPENED_TOOL_VISUALIZATION_DETAILS ? faAngleDown : faAngleRight} color={"black"}/>
                    </div>
                </div>
                <Collapse in={activeMapTool === MAP_OPENED_TOOL_VISUALIZATION_DETAILS}>
                    <div className="ml-4 playContentOpacityAnimation changeContentOpacityAnimation"> 
                        {props.showArrangementPanel &&
                        <>  
                            <Row>
                                <Form.Group as={Col} sm={6} md={6}>
                                    <Form.Label htmlFor="marker-color-picker">
                                        {t("map:marker-color")} <span className="color-picker-tip" style={{backgroundColor: props.pixiSelectedCirclecolor}} />
                                    </Form.Label>
                                    <CirclePicker 
                                        id={"marker-color-picker"}
                                        onChange={color =>  {                                    
                                                props.setPixiMarkerColor(COLORS_PAIRS[color.hex])
                                                props.setPixiSelectedCirclecolor(color.hex)
                                            }
                                        }
                                        colors={MAP_COLORPICKER_LIST}
                                        width={"100%"}
                                        circleSize={24}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} sm={6} md={6}>
                                    <Form.Label htmlFor={'markerIcon'}>
                                        {t("map:select-icon-shape")}
                                    </Form.Label>
                                    <Form.Control as="select" size="sm" custom multiple 
                                        defaultValue={[props.pixiMarkerShape]}
                                        id={'markerIcon'} 
                                        name={'markerIcon'}
                                        className='fa form-control'
                                        disabled={props.loading1 || props.loading2 || props.loading3}
                                        onChange={(e) => {
                                                if (e.target.value){
                                                    props.setPixiMarkerShape(e.target.value)
                                                }
                                            }
                                        }
                                    >
                                        <option value="square_solid">&#xf0c8;</option>
                                        <option value="square_regular">&#xf096;</option>
                                        <option value="star_solid">&#xf005;</option>
                                        <option value="star_regular">&#xf006;</option>
                                        <option value="times_solid">&#xf00d;</option>
                                        <option value="thumbtack_solid">&#xf08d;</option>
                                        <option value="map_marker">&#xf041;</option>
                                        <option value="map_pin">&#xf276;</option>
                                        <option value="circle_solid">&#xf111;</option>
                                        <option value="circle_regular">&#xf10c;</option>
                                        <option value="cube_solid">&#xf1b2;</option>
                                        <option value="bookmark_solid">&#xf02e;</option>
                                        <option value="asterisk_solid">&#xf069;</option>
                                        <option value="plus_solid">&#xf067;</option>                               
                                    </Form.Control>
                                </Form.Group>
                            </Row>

                            <Row>
                                <Form.Group as={Col} sm={6} md={6}>
                                    <Form.Label htmlFor={'iconSize'}>
                                        {t("map:select-icon-size")}
                                    </Form.Label>
                                    <Form.Control as="select" size="sm" custom
                                        defaultValue={props.pixiMarkerSize}
                                        id={'iconSize'} 
                                        name={'iconSize'}
                                        className="form-control"
                                        disabled={props.loading1 || props.loading2 || props.loading3}
                                        onChange={(e) => {
                                                if (e.target.value){
                                                    props.setPixiMarkerSize(e.target.value)
                                                }
                                            }
                                        }
                                    >
                                        <option value={10}>{t("map:map-icon-small")}</option>
                                        <option value={8}>{t("map:map-icon-medium")}</option>
                                        <option value={6}>{t("map:map-icon-large")}</option>
                                    </Form.Control>
                                </Form.Group>

       
                                <Form.Group as={Col} sm={6} md={6}>
                                    <Form.Label htmlFor={'showLabels'}>
                                        <div>{t("map:edit-attributes-label")}</div>
                                    </Form.Label>
                                    <Form.Check
                                        type="checkbox" 
                                        id="showLabels"
                                        defaultChecked={showLabels}
                                        onChange={() => setShowLabels(!showLabels)}
                                        disabled={props.loading1 || props.loading2 || props.loading3}
                                        label={t("map:show-labels-panel")}
                                        name={"show-map-labels"}
                                    /> 
                                </Form.Group>

                            </Row>

                            <Row>
                                <Col>
                                    {showLabels &&
                                        <Form.Group as={Col}>
                                            <div className="mt-1 mb-1">
                                                {t("map:select-labels-info")}
                                            </div>
                                            <MarkerLabels/>
                                        </Form.Group>
                                    }
                                </Col>
                            </Row>


                            <Row>
                                <Col md={12} sm={12} className="d-flex align-items-end" >
                                    <div className="flex-grow-1" />
                                    {(props.loading1 || props.loading2 || props.loading3) &&
                                        <Spinner 
                                            animation="border"
                                            size="md"
                                            aria-hidden="true"
                                            className="mb-3"
                                        />
                                    }
                                    <Button
                                        className="mb-3 ml-2 mr-3"
                                        onClick={() => arrangementMapSearch(ref, props, t, i18n)}
                                        disabled={props.loading1 || props.loading2 || props.loading3}
                                    >
                                        {t("show-on-map")}
                                    </Button>
                                </Col>
                            </Row>
                        </>
                        }
                    </div>
                </Collapse>
            </div>
            }
        </div>
    )
})

const mapStateToProps = (state, ownProps) => ({
    loading1: isPending(TAXONDB_SEARCH_GENUS_REQUEST_ID, state),
    loading2: isPending(TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID, state),
    loading3: isPending(TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, state),
    showArrangementPanel: getField(ARRANGEMENT_TOOLS_ARRANGEMENT_PANEL, ARRANGEMENT_TOOLS_ARRANGEMENT_PANEL, state, true),
    pixiMarkerColor: state.map.pixiMarkerColor,
    pixiSelectedCirclecolor: state.map.pixiSelectedCirclecolor,
    pixiMarkerSize: state.map.pixiMarkerSize,
    pixiMarkerShape: state.map.pixiMarkerShape,
    loadObservationsOnInit: state.map.loadObservationsOnInit,
    observationsRedirectedId: state.map.observationsRedirectedId,
    observationsIcon: state.map.observationsIcon,
    observationsColor: state.map.observationsColor,
    activeMapTool: state.map.openedMapTool,
})

const mapDispatchToProps = dispatch => ({
    setPixiMarkerShape: data => dispatch(setPixiMarkerShape(data)),
    setPixiMarkerSize: data => dispatch(setPixiMarkerSize(data)),
    setPixiSelectedCirclecolor: data => dispatch(setPixiSelectedCirclecolor(data)),
    setPixiMarkerColor: data => dispatch(setPixiMarkerColor(data)),
    getObservationDetails: (observationId) => dispatch(actions.postDataApi(PROJECTS_GET_OBSERVATIONS_DETAILS_ID, `${PROJECTS_GET_OBSERVATIONS_DETAILS_URL}${observationId}/`)),
    setActiveMapTool: (value) => dispatch(setOpenedMapTool(value)),
	setLoadObservationsOnInit: value => dispatch(setLoadObservationsOnInit(value)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(ArrangementMap)
