import React, { useState, useEffect } from "react"
import { useForm, useFieldArray } from 'react-hook-form'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Tab, Tabs, Button, Form, Row, Col, Spinner } from "react-bootstrap"
import {
    GPS_COORDINATES_ATTRIBUTE_NAME,
    LATITUDE_ATTRIBUTE_NAME,
    LONGITUDE_ATTRIBUTE_NAME,
    GEOPRECISION_ATTRIBUTE_NAME,
    COORDINATES_PRECISION,
} from './attributesStructure'
import { postDataAnc } from '../../actions'
import {
    SAMPLESDB_CHANGE_REQUEST_ID_PREFIX,
    SAMPLESDB_CHANGE_REQUEST_URL,
    USER_GROUPS_EDITOR_CONST,
    GENERAL_EXTENDED_REQUEST_TIMEOUT
} from '../../settings'
import { decodeLatitude, decodeLongitude, formatStringToAltititude } from '../../utils/gps'
import MarkerMap from './MarkerMap'
import ValueFromDictionary, { GetValueFromDictionary } from "../dictionary/ValueFromDictionary"
import Confirm from '../Confirm'
import Citation from './Citation'
import { getOutput, isPending } from "../../reducers"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons'
import { diff } from 'deep-object-diff'
import * as notify from '../../utils/notify'
import ShareButton from "../iconDB/ShareButton"
import FormControlCountries from "../FormControlCountries"

const AttrValueSpecial = props => {
    const values = props.data[props.name].split(' ')

    return (
        <>
            <span>{values[0]} </span>
            <i>{values.slice(1).join(' ')}</i>
        </>
    )
}

const AttrValueArray = props =>
    <>
        {props.values.map((value, key) =>
            <span key={key}>{value} </span>
        )}
    </>

const AttrValue = props => {
  const { t } = useTranslation()
  return (
    <>
      {props.gps &&
        <span>
          {props.data[COORDINATES_PRECISION] > 0 &&
	  <>
          <span style={{ whiteSpace: 'nowrap' }}>
            {decodeLatitude(props.data[ LATITUDE_ATTRIBUTE_NAME ])}
          </span> <span style={{ whiteSpace: 'nowrap' }}>
            {decodeLongitude(props.data[ LONGITUDE_ATTRIBUTE_NAME ])}
          </span>
	  </>
	  }
          {props.data[ COORDINATES_PRECISION ] === 2 &&
            <span> ({t('precision-2')})</span>
          }
          {props.data[ COORDINATES_PRECISION ] === 1 &&
            <span> ({t('precision-1')})</span>
          }
          {props.data[ COORDINATES_PRECISION ] === 0 &&
            <span> {t('precision-0')}</span>
          }
        </span>
      }
      {!props.gps &&
        <>
          {props.data[ props.name ] === true &&
            <>{props.t('common:true')}</>
          }
          {props.data[ props.name ] === false &&
            <>{props.t('common:false')}</>
          }
          {props.data[ props.name ] !== true && props.data[ props.name ] !== false &&
            <>
              {Array.isArray(props.data[ props.name ]) &&
                <>
                  {props.data[ props.name ].map((item, indx) =>
                    <div key={indx}>
                      {props.directRender &&
                        <span>{GetValueFromDictionary((props.dictionary ? props.dictionary : props.name), item, props.i18n, props.collectionTypes)}</span>
                      }
                      {!props.directRender &&
                        <>
                          {item.values && <AttrValueArray values={item.values} {...props} />}
                          {!item.values && <ValueFromDictionary value={item} {...props} />}
                        </>
                      }
                    </div>
                  )}
                </>
              }
              {!Array.isArray(props.data[ props.name ]) &&
                <>
                  {props.special &&
                    <AttrValueSpecial {...props}></AttrValueSpecial>
                  }
                  {!props.special &&
                    <>
                      {props.directRender &&
                        <span>{GetValueFromDictionary((props.dictionary ? props.dictionary : props.name), props.data[ props.name ], props.i18n, props.collectionTypes)}</span>
                      }
                      {!props.directRender &&
                        <ValueFromDictionary value={props.data[ props.name ]} {...props}></ValueFromDictionary>
                      }
                    </>
                  }
                </>
              }
            </>
          }
          {props.meters &&
            <> m</>
          }
        </>
      }
    </>)
}

const EditAttrValue = props => {
    const { register } = props
    const isDisabled = ['numerokazu', 'rodzajgatunek', 'images', 'geodokladnosc', 'kolekcja'].includes(props.name)
    return (
        <Form.Group className="mb-0">
            {props.name === 'panstwo' &&
                <FormControlCountries 
                    name={props.name} 
                    size="sm" 
                    type="text" 
                    register={register}
                    set={props.setValue}
                    defaultValue={props.data[props.name]} 
                    isInvalid={props.errors[ props.name ]} 
                    autoComplete="off" />
            }
            {props.name !== 'panstwo' &&
                <Form.Control name={props.name} size="sm" type="text" ref={register} disabled={isDisabled} isInvalid={props.errors[props.name]} autoComplete="off" />
            }
            <Form.Control.Feedback type="invalid">
                {props.errors[props.name] ? props.errors[props.name][0] : ''}
            </Form.Control.Feedback>
        </Form.Group>
    )
}

const EditAttrValueArray = props => {
    const { register, control } = props
    const { fields, append, remove, insert } = useFieldArray({ control: control, name: props.name })
    return (<>
        {fields.length > 0 && fields.map((item, index) =>
            <Row key={item.id} className="mb-1" noGutters>
                <Col className="pr-1">
                    <Form.Control name={`${props.name}[${index}].values[0]`} defaultValue={item['values'][0]} size="sm" type="text" ref={register()} autoComplete="off" />
                </Col>
                <Col className="px-1">
                    <Form.Control name={`${props.name}[${index}].values[1]`} defaultValue={item['values'][1]} size="sm" type="text" ref={register()} autoComplete="off" />
                </Col>
                <Col className="px-1">
                    <Form.Control name={`${props.name}[${index}].values[2]`} defaultValue={item['values'][2]} size="sm" type="text" ref={register()} autoComplete="off" />
                </Col>
                <Col md="auto" className="pl-1">
                    <Button size="sm" onClick={() => insert(index + 1, { values: ['', '', ''] })} >
                        <FontAwesomeIcon icon={faPlus} title={props.t('common:append')} />
                    </Button>
                    <Button size="sm" className="ml-2" onClick={() => remove(index)}>
                        <FontAwesomeIcon icon={faTrash} title={props.t('common:delete')} />
                    </Button>
                </Col>
            </Row>
        )}
        {fields.length === 0 &&
            <Row className="mb-1" noGutters>
                {Array.from({ length: 3 }).map((_, i) =>
                    <Col className="px-1" key={i}>
                        <Form.Control size="sm" type="text" disabled />
                    </Col>
                )}
                <Col md="auto" className="pl-1">
                    <Button size="sm" onClick={() => append({ values: ['', '', ''] })} >
                        <FontAwesomeIcon icon={faPlus} title={props.t('common:append')} />
                    </Button>
                    <Button size="sm" className="ml-2" disabled>
                        <FontAwesomeIcon icon={faTrash} title={props.t('common:delete')} />
                    </Button>
                </Col>
            </Row>
        }
    </>)
}

const EditGpsValue = props => {
    const { register } = props

    return (<>{!props.array &&
        <Row noGutters>
            <Col>
                <Form.Group className="mb-0">
                    <Form.Control name={LATITUDE_ATTRIBUTE_NAME} size="sm" type="text" ref={register} isInvalid={props.errors[props.name]} autoComplete="off" />
                    <Form.Control.Feedback type="invalid">
                        {props.errors[props.name] ? props.errors[props.name][0] : ''}
                    </Form.Control.Feedback>
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className="mb-0">
                    <Form.Control name={LONGITUDE_ATTRIBUTE_NAME} size="sm" type="text" ref={register} isInvalid={props.errors[props.name]} autoComplete="off" />
                    <Form.Control.Feedback type="invalid">
                        {props.errors[props.name] ? props.errors[props.name][0] : ''}
                    </Form.Control.Feedback>
                </Form.Group>
            </Col>
        </Row>
    }</>)
}

const AttrName = props =>
    <b>
        {!props.label &&
            <>
                {props.t('samplesdb-attributes:' + props.name)}
            </>
        }
        {props.label &&
            <>
                {props.t('samplesdb-attributes:' + props.label)}
            </>
        }
    </b>

const DivRow = props =>
    <>
        {((!props.array && typeof(props.data[props.name]) != "undefined" && props.data[props.name] !== null) ||
            (props.array && props.data[props.name] && props.data[props.name].length > 0) ||
            props.editable) &&
            <div className="d-flex" style={{ borderTopStyle: "solid", borderTopColor: "#dee2e6", borderTopWidth: "1px" }}>
                <div className="w-50 p-1">
                    <AttrName {...props}></AttrName>
                </div>
                <div className="w-50 p-1">
                    {!props.editable &&
                        <AttrValue {...props}></AttrValue>
                    }
                    {props.editable && !props.gps && !props.array &&
                        <EditAttrValue {...props}></EditAttrValue>
                    }
                    {props.editable && !props.gps && props.array &&
                        <EditAttrValueArray {...props}></EditAttrValueArray>
                    }
                    {props.editable && props.gps &&
                        <EditGpsValue {...props}></EditGpsValue>
                    }
                </div>
            </div>
        }
    </>

const HiddenDivRows = props => {
    const [open, setOpen] = useState(false)
    useEffect(() => {
        setOpen(props.editable)
    }, [props.editable])

    return (
        <>
            {!open && ([
                "obszarchroniony",
                "parknarodowy",
                "parkkrajobrazowy",
                "rezerwatprzyrody",
                "obszarchronionegokrajobrazu",
                "uzytekekologiczny",
                "zespolprzyrodniczokrajobrazowy",
                "koditypobszarunatura2000",
                "nazwaobszarunatura2000",
                "polozeniewpodzialefizjograficznym",
                "polozeniewpodzialebiogeograficznymeuropy",
                "polozeniewpodzialegeograficznympotencjalnejroslinnoscinaturalne",
                "locationsite",
                "srodowisko",
                "habitat",
                "oryginalnyopis",
                "autorzbioru",
                "datazebrania",
                "sprawdzonegrupyzwierzat",
            ].reduce((acc, obj) => (
                acc || props.data[obj]
            ), false) || props.editable) && !props.directRender &&
                <div className="d-flex" style={{ borderTopStyle: "solid", borderTopColor: "#dee2e6", borderTopWidth: "1px" }}>
                    <div className="w-50 p-1">
                    </div>
                    <div className="w-50 p-1">
                        <Button
                            variant="secondary"
                            onClick={() => setOpen(!open)}
                            size="sm">{props.t('common:more')}</Button>
                    </div>
                </div>
            }
            {open &&
                <>
                    <DivRow name="obszarchroniony" {...props}></DivRow>
                    <DivRow name="parknarodowy" {...props}></DivRow>
                    <DivRow name="parkkrajobrazowy" {...props}></DivRow>
                    <DivRow name="rezerwatprzyrody" {...props}></DivRow>
                    <DivRow name="obszarchronionegokrajobrazu" {...props}></DivRow>
                    <DivRow name="uzytekekologiczny" {...props}></DivRow>
                    <DivRow name="zespolprzyrodniczokrajobrazowy" {...props}></DivRow>
                    <DivRow name="koditypobszarunatura2000" {...props}></DivRow>
                    <DivRow name="nazwaobszarunatura2000" {...props}></DivRow>
                    <DivRow name="polozeniewpodzialefizjograficznym" {...props}></DivRow>
                    <DivRow name="polozeniewpodzialebiogeograficznymeuropy" {...props}></DivRow>
                    <DivRow name="polozeniewpodzialegeograficznympotencjalnejroslinnoscinaturalne" special {...props}></DivRow>
                    <DivRow name="locationsite" {...props}></DivRow>
                    <DivRow name="srodowisko" {...props}></DivRow>
                    <DivRow name="habitat" {...props}></DivRow>
                    <DivRow name="oryginalnyopis" {...props}></DivRow>
                    <DivRow name="autorzbioru" {...props}></DivRow>
                    <DivRow name="datazebrania" {...props}></DivRow>
                    <DivRow name="sprawdzonegrupyzwierzat" {...props}></DivRow>
                </>
            }
        </>
    )
}

export const Tab2 = props =>
    <div>
        <DivRow name="lokalizacjastanowisko" {...props}></DivRow>
        <DivRow name="polozeniewzgledempoziomumorza" {...props} meters></DivRow>
        <DivRow name={COORDINATES_PRECISION} label={GPS_COORDINATES_ATTRIBUTE_NAME} gps {...props}></DivRow>
        <DivRow name="wspolrzedneutm" {...props}></DivRow>
        <DivRow name="wspolrzedneatpol" {...props}></DivRow>
        <DivRow name="georeferencjakomentarze" {...props}></DivRow>
        <DivRow name="lokalizacjakomentarze" {...props}></DivRow>
        <DivRow name="rodzajityprezerwatu" {...props}></DivRow>
        <DivRow name="kontynent" {...props}></DivRow>
        <DivRow name="panstwo" {...props}></DivRow>
        <DivRow name="wojewodztwo" {...props}></DivRow>
        <DivRow name="powiat" {...props}></DivRow>
        <DivRow name="gmina" {...props}></DivRow>
        <HiddenDivRows {...props}></HiddenDivRows>
    </div>


const Tab3 = props =>
    <div>
        <DivRow name="charakterproby" {...props}></DivRow>
        <DivRow name="sposobpobierania" {...props}></DivRow>
        <DivRow name="merocenoza" {...props}></DivRow>
        <DivRow name="merocenozatyp" {...props}></DivRow>
        <DivRow name="merocenozagospodarz" {...props}></DivRow>
        <DivRow name="merocenozamaterial" {...props}></DivRow>
        <DivRow name="merocenozapolozeniewzgledemgruntu" {...props}></DivRow>
        <DivRow name="merocenozawielkosckopcakretowiska" {...props}></DivRow>
        <DivRow name="merocenozatypgniazdaptaka" {...props}></DivRow>
        <DivRow name="merocenozamartwegodrewnastopienrozkladu" {...props}></DivRow>
        <DivRow name="merocenozasrednicadrzewa" {...props}></DivRow>
        <DivRow name="merocenozadlugoscdrzewa" {...props}></DivRow>
        <DivRow name="merocenozagatunekdrzewa" {...props}></DivRow>
        <DivRow name="wilgotnoscprobki" {...props}></DivRow>
        <DivRow name="dodatkowyopis" {...props}></DivRow>
    </div>

const Tab4 = props =>
    <div>
        <DivRow name="bibliografia" {...props}></DivRow>
    </div>

const AllTabs = props => {
    const { t, i18n } = useTranslation(['samplesdb-groups', 'samplesdb', 'common', 'samplesdb-attributes', 'citation', 'forms'])

    const [key, setKey] = useState('group-2')

    const [editable, setEditable] = useState(false)

    const { control, register, handleSubmit, errors, setError, setValue } = useForm({
        defaultValues: props.data
    });

    const onEditableChange = () => {
        setEditable(!editable)
    }

    const onSave = (fields) => {
        fields.polozeniewzgledempoziomumorza = formatStringToAltititude(fields.polozeniewzgledempoziomumorza)
        props.saveRecord(props.recordId, fields, props.data, t, setError)
    }

    const onRemoveSample = () => {
        // TODO
    }

    const onKeyDown = (e) => {
        if (['ArrowLeft', 'ArrowRight'].includes(e.key)){
            e.stopPropagation()
        }
    }

    return (
        <>
            {props.data &&
                <>
                    <Tabs className="citation-tab-pulled-right" defaultActiveKey={'group-1'} id={props.requestId} activeKey={key} onSelect={k => setKey(k)}>
                        <Tab eventKey="group-2" title={t('group-2')} onKeyDown={onKeyDown}>
                            <Tab2 {...props} editable={editable} register={register} control={control} errors={errors} t={t} i18n={i18n} setValue={setValue}></Tab2>
                        </Tab>
                        {([
                            "charakterproby",
                            "sposobpobierania",
                            "merocenoza",
                            "merocenozatyp",
                            "merocenozagospodarz",
                            "merocenozamaterial",
                            "merocenozapolozeniewzgledemgruntu",
                            "merocenozawielkosckopcakretowiska",
                            "merocenozatypgniazdaptaka",
                            "merocenozamartwegodrewnastopienrozkladu",
                            "merocenozasrednicadrzewa",
                            "merocenozadlugoscdrzewa",
                            "merocenozagatunekdrzewa",
                            "wilgotnoscprobki",
                            "dodatkowyopis",
                        ].reduce((acc, obj) => (
                            acc || props.data[obj]
                        ), false) || editable) &&
                            <Tab eventKey="group-3" title={t('group-3')} onKeyDown={onKeyDown}>
                                <Tab3 {...props} editable={editable} register={register} control={control} errors={errors} t={t} i18n={i18n}></Tab3>
                            </Tab>
                        }
                        {(props.data['bibliografia'] || editable) &&
                            <Tab eventKey="group-4" title={t('group-4')} onKeyDown={onKeyDown}>
                                <Tab4 {...props} editable={editable} register={register} control={control} errors={errors} t={t} i18n={i18n}></Tab4>
                            </Tab>
                        }
                        {props.data[LATITUDE_ATTRIBUTE_NAME] && props.data[LONGITUDE_ATTRIBUTE_NAME] &&
                            <Tab eventKey="map" title={t('samplesdb:map')} className="d-flex flex-column mt-2" onKeyDown={onKeyDown}>
                                {key === 'map' &&
                                    <MarkerMap lat={props.data[LATITUDE_ATTRIBUTE_NAME]} lon={props.data[LONGITUDE_ATTRIBUTE_NAME]} prec={props.data[GEOPRECISION_ATTRIBUTE_NAME]}></MarkerMap>
                                }
                            </Tab>
                        }
                        <Tab eventKey="citation" title={t('citation:how-to-cite')} tabClassName="citation-tab" onKeyDown={onKeyDown}>
                            <Citation t={t} i18n={i18n} {...props}></Citation>
                        </Tab>
                        <Tab eventKey="share" title={t('common:share')} tabClassName="share-button" onKeyDown={onKeyDown}>
                            <ShareButton noImage={true} {...props}></ShareButton>
                        </Tab>
                    </Tabs>
                    {props.user != null && props.user.groups != null && props.user.groups.includes(USER_GROUPS_EDITOR_CONST) &&
                        <div className="mb-3 pl-2 pr-2">
                            <Form.Check
                                className="d-inline"
                                type="switch"
                                id={`editable-switch-${props.requestId}`}
                                label={t('forms:edit')}
                                checked={editable}
                                onChange={onEditableChange}
                            />
                            {editable && <div className="float-right">
                                <span className="d-inline-block">
                                    <Confirm
                                        onConfirm={onRemoveSample}
                                        size="sm"
                                        buttonLabel={t('forms:remove')}
                                        confirmationText={t('samplesdb:confirm-remove-sample')}
                                        disabled={props.isSaving || true /* TODO: waiting for an endpoint */}
                                        className="ml-1 mr-1"
                                    ></Confirm>
                                </span>
                                <Button onClick={handleSubmit(onSave)} size="sm" disabled={props.isSaving} className="float-right">
                                    {t('forms:save')}
                                    {props.isSaving &&
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            className="ml-1"
                                        />
                                    }
                                </Button>
                            </div>}
                        </div>
                    }
                </>
            }
        </>
    )
}

const mapRecordDetailsOutput = (data) => {
    if (!data) {
        return data
    }

    let copy = { ...data }
    const toFixedPrecision = (x) => {
        return x === null ? null : parseFloat(x).toFixed(copy['geodokladnosc'] || 6)
    }

    copy[LATITUDE_ATTRIBUTE_NAME] = toFixedPrecision(copy[LATITUDE_ATTRIBUTE_NAME])
    copy[LONGITUDE_ATTRIBUTE_NAME] = toFixedPrecision(copy[LONGITUDE_ATTRIBUTE_NAME])
    return copy
}

const mapStateToProps = (state, ownProps) => ({
    user: state.user ? state.user.data : null,
    data: mapRecordDetailsOutput(getOutput(ownProps.requestId, state)),
    isSaving: isPending(SAMPLESDB_CHANGE_REQUEST_ID_PREFIX + ownProps.recordId, state)
})

const mapDispatchToProps = dispatch => ({
    saveRecord: (recordId, fields, oldFields, t, setError) => {
        const removeFields = (o, fields) => {
            fields.forEach(field => {
                delete o[field]
            })
        }

        const renameField = (o, oldName, newName) => {
            if (oldName in o) {
                o[newName] = o[oldName]
                delete o[oldName]
            }
        }

        const replaceEmptyStrings = (o) => {
            return JSON.parse(JSON.stringify(o, (k, v) => (typeof v === 'undefined' || v === '') ? null : v))
        }

        const setLatituteLongitude = (o) => {
            const toFixedPrecision = (x, y) => {
                let re = /[+-]?(\d+)(\.(\d+))?/gm
                let [a, b] = [[...(x || '').matchAll(re)], [...(y || '').matchAll(re)]]
                let alen = (a.length > 0 && a[0].length >= 4 && a[0][3]) ? a[0][3].length : 0
                let blen = (b.length > 0 && b[0].length >= 4 && b[0][3]) ? b[0][3].length : 0
                if (isNaN(parseFloat(x))) {
                    return x;
                } else {
                    return parseFloat(x).toFixed(Math.max(alen, blen))
                }
            }

            if (o['dlugoscgeograficzna']) {
                o['dlugoscgeograficzna'] = toFixedPrecision(o['dlugoscgeograficzna'], o['szerokoscgeograficzna'])
            }

            if (o['szerokoscgeograficzna']) {
                o['szerokoscgeograficzna'] = toFixedPrecision(o['szerokoscgeograficzna'], o['dlugoscgeograficzna'])
            }
        }

        let newFields = Object.assign({}, fields)
        let diffFields = diff(oldFields, replaceEmptyStrings(newFields))
        setLatituteLongitude(diffFields)

        removeFields(diffFields, ['numerproby', 'images', 'geodokladnosc'])
        renameField(diffFields, 'dlugoscgeograficzna', 'dlugoscgeograficzna1')
        renameField(diffFields, 'szerokoscgeograficzna', 'szerokoscgeograficzna1')
        dispatch(postDataAnc(
            SAMPLESDB_CHANGE_REQUEST_ID_PREFIX + recordId,
            SAMPLESDB_CHANGE_REQUEST_URL,
            { id: recordId, fields: diffFields },
            GENERAL_EXTENDED_REQUEST_TIMEOUT
        )).then(() => {
            notify.success(t('samplesdb:modified-sample'))
        }).catch(res => {
            const err = res?.response?.data?.error;
            if (err && err.sample) {
                Object.keys(err.sample).forEach(k => {
                    if (k === 'szerokośćgeograficzna') {
                        setError(LATITUDE_ATTRIBUTE_NAME, err.sample[k]);
                    } else if (k === 'długośćgeograficzna') {
                        setError(LONGITUDE_ATTRIBUTE_NAME, err.sample[k]);
                    } else {
                        setError(k, err.sample[k]);
                    }
                })
            }

            notify.error(t('samplesdb:cannot-modify-sample'), res?.response?.data?.error?.error || res?.response?.data)
        })
    }
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllTabs)
