import React, { Suspense } from "react";
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { isPending, getOutput } from "../../reducers";
import { Row, Col, Spinner } from "react-bootstrap"
import RecordDetails from './RecordDetails'
import Loader from '../../utils/loader'
import SingleDlibraImage from './DlibraImage'

const RecordDetailsContainer = props => {
    const { t } = useTranslation([ 'common', 'forms', 'bibliodb', 'profile' ])

    return (<div>
        <Suspense fallback={<Loader />}>
            <div className="d-flex flex-column pt-2 common-panel-background">
                {props.loading &&
                    <div className="align-self-center m-3">
                        <Spinner animation="border" role="status" />
                    </div>
                }
                <div>
                    <Row>
                        {!props.loading && props.data && props.data[ "images" ] && props.data.images.length > 0 &&
                            <Col md={6}>
                                {!props.loading && props.data &&
                                    <SingleDlibraImage requestId={props.requestId}></SingleDlibraImage>
                                }
                            </Col>
                        }
                        <Col md={(!props.loading && props.data && props.data[ "images" ] && props.data.images.length > 0) ? 6 : 12}>
                            {!props.loading && props.data &&
                                <RecordDetails requestId={props.requestId} recordId={props.recordId}></RecordDetails>
                            }
                            {!props.loading && !props.data &&
                                <div className="d-flex justify-content-center">
                                    <p>{t('no-records-found')}</p>
                                </div>
                            }
                        </Col>
                    </Row>
                </div>
            </div>
        </Suspense>
    </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    loading: isPending(ownProps.requestId, state),
    data: getOutput(ownProps.requestId, state),
})

const mapDispatchToProps = dispatch => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RecordDetailsContainer)
