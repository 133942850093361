import React, { Suspense } from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getFields, getPagination } from "../../reducers";
import { Container } from "react-bootstrap"
import NewReportList from '../taxonDBReport/MainContainer'
import Loader from '../../utils/loader'
import { removeEmptyElementsStructure } from '../../utils/search'

const ResultsContainer = props => {
    const { t, i18n } = useTranslation([ 'common', 'forms', 'taxondb', 'profile' ])

    return (
        <Container className="mt-2">
            <Suspense fallback={<Loader />}>
                <div className="mb-2 d-flex flex-row flex-wrap justify-content-center">
                    <div className="p-2">
                        {t('taxondb:nr_of_specimens_found')}: <b>{(props.pagination && props.pagination.totalCount) ? props.pagination.totalCount.toLocaleString(i18n.languages[0]) : '-'}</b>
                    </div>
                </div>
                <NewReportList
                    requestId={props.searchRequestId}
                    initialPanels={[ {
                        aggregation_field: 'rodzaj',
                        limit: "",
                        skip_null: false,
                        with_total: true,
                        filter: props.filter,
                    } ]}
                ></NewReportList>
            </Suspense>
        </Container>
    )
}

const mapStateToProps = (state, ownProps) => ({
    filter: removeEmptyElementsStructure(getFields(ownProps.searchRequestId, state)),
    pagination: getPagination(ownProps.searchRequestId, state),
})


const mapDispatchToProps = (dispatch, ownProps) => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResultsContainer)

