import React, { useState } from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { isPending, getItems, getPagination, getFilter, getOutput } from "../../reducers";
import { Spinner } from "react-bootstrap"
import SingleDlibraImage from './DlibraImage'
import RecordDetails from './RecordDetails'
import { Button } from "react-bootstrap"
import * as actions from '../../actions'
import { setField } from '../../actions/forms'
import {
    ICONDB_DETAILS_REQUEST_URL,
    ICONDB_DETAILS_REQUEST_ID_PREFIX,
    ICONDB_SEARCH_REQUEST_URL,
    ICON_RESULTS_ACTIVE_RECORD,
    ICONDB_SEARCH_REQUEST_ID,
    API_ALBUM_GET_ALL_ID,
    API_ALBUM_GET_ALL_URL,
    DLIBRA_IIIF_URL,
} from '../../settings'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faTimes } from '@fortawesome/free-solid-svg-icons'
import AddToAlbum from '../profile/AddToAlbum'
import { Redirect } from "react-router-dom"
import { addPictureOne, addPictureTwo, clearComparator } from '../../actions/comparator'

const RecordDetailsContainer = props => {
    const { t } = useTranslation([ 'common', 'forms', 'taxondb', 'icondb', 'profile', 'paths' ])

    const [ addAlbumOpen, setAddAlbumOpen ] = useState(false)
    const [ redirect, setRedirect ] = useState(false)
    const [ redirectToComparator, setRedirectToComparator ] = useState(false)

    if (redirect) {
        return <Redirect push to={t('paths:iconDB')} />
    }

    const goPrevious = () => {
        const currentIndx = props.items.findIndex((obj, indx, arr) => obj.edi_id === props.data.edi_id)
        if (currentIndx <= 0) {
            const newPagination = { ...props.pagination }
            newPagination.currentPage = props.pagination.currentPage - 1
            props.search({
                filter: props.filter,
                pagination: newPagination,
            })
                .then(data => {
                    if (data[ "items" ] && data.items.length > 0) {
                        const id = data.items[ data.items.length - 1 ].edi_id
                        props.setOpen(id)
                        props.getDetails(id)
                    }
                })
        } else {
            const id = props.items[ currentIndx - 1 ].edi_id
            props.setOpen(id)
            props.getDetails(id)
        }
    }

    const goNext = () => {
        const currentIndx = props.items.findIndex((obj, indx, arr) => obj.edi_id === props.data.edi_id)
        if (currentIndx >= props.items.length - 1) {
            const newPagination = { ...props.pagination }
            newPagination.currentPage = props.pagination.currentPage + 1
            props.search({
                filter: props.filter,
                pagination: newPagination,
            })
                .then(data => {
                    if (data[ "items" ] && data.items.length > 0) {
                        const id = data.items[ 0 ].edi_id
                        props.setOpen(id)
                        props.getDetails(id)
                    }
                })
        } else {
            const id = props.items[ currentIndx + 1 ].edi_id
            props.setOpen(id)
            props.getDetails(id)
        }
    }

    const addFirstPictureToComparator = (data) => {
        props.addPictureOneToComparator({
            edi_id1: data.edi_id,
            edi_ver_id1: data.edi_ver_id,
            external_id1: data.external_id,
            FullAuthorName1: data.FullAuthorName,
            requestUrl1: DLIBRA_IIIF_URL
        })
    }

    const addSecondPictureToComparator = (data) => {
        props.addPictureTwoToComparator({
            edi_id2: data.edi_id,
            edi_ver_id2: data.edi_ver_id,
            external_id2: data.external_id,
            FullAuthorName2: data.FullAuthorName,
            requestUrl2: DLIBRA_IIIF_URL
        })
        setRedirectToComparator(true)
    }

    const shareProps = {
        recordId: props.recordId,
        detailsUrl: '/iconDB/details/',
        addFirstPictureToComparator: addFirstPictureToComparator,
        addSecondPictureToComparator: addSecondPictureToComparator,
        setAddAlbumOpen: setAddAlbumOpen,
        setRedirect: setRedirect
    }

    return (
        <div className="d-flex flex-column pt-1 common-panel-background">
            <div className="d-flex">
                {!props.noheader &&
                    <Button variant="light" size="sm" disabled={props.loading} onClick={() => props.setOpen(null)}>
                        <FontAwesomeIcon icon={faTimes} title={t('close')} />
                    </Button>
                }
                <div className="d-flex flex-fill justify-content-center">
                </div>
                {!props.noheader &&
                    <>
                        <Button size="sm" variant="light" disabled={props.loading || !props.items || (
                            props.items.findIndex((obj, indx, arr) => obj.edi_id === props.data.edi_id) === 0 &&
                            props.pagination.currentPage <= 1)} onClick={goPrevious}>
                            <FontAwesomeIcon icon={faArrowLeft} title={t('PreviousPage')} />
                        </Button>
                        <Button size="sm" variant="light" disabled={props.loading || !props.items || (
                            props.items.findIndex((obj, indx, arr) => obj.edi_id === props.data.edi_id) === props.items.length - 1 &&
                            props.pagination.currentPage === props.pagination.totalPages)} onClick={goNext}>
                            <FontAwesomeIcon icon={faArrowRight} title={t('NextPage')} />
                        </Button>
                    </>
                }
            </div>
            <div className="d-flex flex-column pt-2 icon-container">
                {props.loading &&
                    <div className="align-self-center m-5">
                        <Spinner animation="border" role="status" />
                    </div>
                }
                {!props.loading && props.data &&
                    <div className="d-flex flex-column">
                        <SingleDlibraImage requestId={props.requestId}></SingleDlibraImage>
                    </div>
                }
                {addAlbumOpen &&
                    <AddToAlbum kind="icon" itemId={props.recordId} onDone={e => setAddAlbumOpen(false)}></AddToAlbum>
                }
                {!props.loading && props.data &&
                    <div className="d-flex flex-column">
                        <RecordDetails requestId={props.requestId} {...shareProps}></RecordDetails>
                    </div>
                }
                {!props.loading && !props.data &&
                    <div className="d-flex justify-content-center">
                        <p>{t('no-records-found')}</p>
                    </div>
                }
            </div>
            {redirectToComparator &&
                <Redirect push to={t('paths:comparator')} />
            }
        </div >

    )
}
const mapStateToProps = (state, ownProps) => ({
    items: getItems(ICONDB_SEARCH_REQUEST_ID, state),
    loading: isPending(ownProps.requestId, state),
    data: getOutput(ownProps.requestId, state),
    pagination: getPagination(ICONDB_SEARCH_REQUEST_ID, state),
    filter: getFilter(ICONDB_SEARCH_REQUEST_ID, state),
})

const mapDispatchToProps = dispatch => ({
    getDetails: (id) => dispatch(actions.getDataDlibra(ICONDB_DETAILS_REQUEST_ID_PREFIX, ICONDB_DETAILS_REQUEST_URL + id + '/')),
    setOpen: id => dispatch(setField(ICON_RESULTS_ACTIVE_RECORD, ICON_RESULTS_ACTIVE_RECORD, id)),
    search: data => dispatch(actions.postDataDlibra(ICONDB_SEARCH_REQUEST_ID, ICONDB_SEARCH_REQUEST_URL, data)),
    getAlbums: () => dispatch(actions.postDataApi(API_ALBUM_GET_ALL_ID, API_ALBUM_GET_ALL_URL)),
    addPictureOneToComparator: (data) => dispatch(addPictureOne(data)),
    addPictureTwoToComparator: (data) => dispatch(addPictureTwo(data)),
    clearComparator: () => dispatch(clearComparator())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RecordDetailsContainer)
