import React, { useEffect, useState, } from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getOutputOrDefault, isPending } from "../../reducers"
import { postDataApi, requestSuccess } from '../../actions'
import RedirectLoginWithReturn from "../access/RedirectLoginWithReturn"
import CreateTeam from "../team/CreateTeam"
import JoinTeam from "../team/JoinTeam"
import EditTeam from "../team/EditTeam"
import TeamDetails from "../team/TeamDetails"
import { Container, Row, Col, ListGroup, Button, Collapse, Spinner } from "react-bootstrap"
import Confirm from "../Confirm"
import {
    API_TEAM_GET_TEAMS_ID,
    API_TEAM_GET_TEAMS_URL,
    API_TEAM_DEL_TEAM_ID,
    API_TEAM_DEL_TEAM_URL,
    API_TEAM_DEL_USER_ID,
    API_TEAM_DEL_USER_URL,
    MEMBER_KIND_ADMINISTRATOR,
    MEMBER_KIND_NORMAL_USER,
    MEMBER_KIND_APPLICANT,
    MEMBER_KIND_MANAGER,
    TEAM_PAGE_URL_EN,
    TEAM_PAGE_URL_PL,
    API_URL_POSTFIX,
} from '../../settings'
import * as notify from '../../utils/notify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faShareAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import I18nPathTranslator from "../I18nPathTranslator";
import SEO from "../SEO";
import useUserRoles from "../../hooks/useUserRoles"
import ListGroupItemDiv from "../ListGroupItemDiv";
import ConnectedPagination from '../ConnectedPagination'

const isUserAdmin = (team) => {
    return team && team[ 'member_kind' ] && team[ 'member_kind' ] === MEMBER_KIND_ADMINISTRATOR
}

const isUserNormal = (team) => {
    return team && team[ 'member_kind' ] && team[ 'member_kind' ] === MEMBER_KIND_NORMAL_USER
}

const isUserApplicant = (team) => {
    return team && team[ 'member_kind' ] && team[ 'member_kind' ] === MEMBER_KIND_APPLICANT
}

const isUserManager = (team) => {
    return team && team[ 'member_kind' ] && team[ 'member_kind' ] === MEMBER_KIND_MANAGER
}

const TeamListItem = props => {
    const { t } = useTranslation([ 'team', 'a11y' ])
    const { team, parentProps } = props

    const [ isShowUsersOpen, setShowUsersOpen ] = useState(false)
    const [ isEditTeamOpen, setEditTeamOpen ] = useState(false)

    const toggleShowUsers = () => {
        setShowUsersOpen(!isShowUsersOpen)
    }

    const toggleEditTeam = e => {
        e.preventDefault()
        e.stopPropagation()
        setEditTeamOpen(!isEditTeamOpen)
    }

    return (
        <>
            <ListGroupItemDiv tabIndex="0" action onClick={() => toggleShowUsers()}>
                <div className="d-flex" style={{ cursor: 'pointer' }}>
                    <div className="d-flex">
                        <div>
                            {isShowUsersOpen &&
                                <img src="/images/list-open.svg" alt={t("a11y:list-opened")} />
                            }
                            {!isShowUsersOpen &&
                                <img src="/images/list-closed.svg" alt={t("a11y:list-closed")} />
                            }
                        </div>
                    </div>
                    <div className="w-50">
                        {team.name}
                    </div>
                    <div className="w-50">
                        {team.member_kind}
                    </div>
                    <div className="d-flex w-25 justify-content-end">
                        {(isUserAdmin(team) || isUserManager(team)) &&
                            <div className="btn btn-sm btn-secondary mr-1" tabIndex="0" onClick={e => toggleEditTeam(e)}>
                                <FontAwesomeIcon icon={faShareAlt} title={t('team:sharing')} />
                            </div>
                        }
                        {isUserManager(team) &&
                            <Confirm
                                onConfirm={e => parentProps.removeTeam(team.id, parentProps.requests, t)}
                                size="sm"
                                pending={parentProps.removingItems}
                                disabled={parentProps.removingItems}
                                buttonLabel={<FontAwesomeIcon icon={faTrash} title={t('team:remove-team')} />}
                                confirmButtonLabel={t('team:remove-team')}
                                confirmationText={t('team:confirm-remove-team')}
                            ></Confirm>
                        }
                        {(isUserApplicant(team) || isUserNormal(team) || isUserAdmin(team)) &&
                            <Confirm
                                onConfirm={e => parentProps.leaveTeam(parentProps.user.data.id, team.id, parentProps.requests, t)}
                                size="sm"
                                pending={parentProps.removingItems}
                                disabled={parentProps.removingItems}
                                buttonLabel={<FontAwesomeIcon icon={faSignOutAlt} title={t('team:leave-team')} />}
                                confirmButtonLabel={t('team:leave-team')}
                                confirmationText={t('team:confirm-leave-team')}
                            ></Confirm>
                        }
                    </div>
                </div>
            </ListGroupItemDiv>
            <Collapse in={isEditTeamOpen} id="editTeam">
                <div>
                    {isEditTeamOpen &&
                        <EditTeam team={team} />
                    }
                </div>
            </Collapse>
            <Collapse in={isShowUsersOpen}>
                <div>
                    {isShowUsersOpen &&
                        <TeamDetails team={team} />
                    }
                </div>
            </Collapse>
        </>
    )
}

const TeamContainer = props => {
    const { t } = useTranslation([ 'team', 'paths' ])
    const { getTeams } = props
    const [ isCreateTeamOpen, setCreateTeamOpen ] = useState(false)
    const [ isJoinTeamOpen, setJoinTeamOpen ] = useState(false)
    const { isLeader } = useUserRoles()

    const toggleCreateTeamOpen = () => {
        setCreateTeamOpen(!isCreateTeamOpen)
    }

    const toggleJoinTeamOpen = () => {
        setJoinTeamOpen(!isJoinTeamOpen)
    }

    useEffect(() => {
        getTeams()
    }, [getTeams])

    return (
        <>
            <I18nPathTranslator {...props} pathEN={TEAM_PAGE_URL_EN} pathPL={TEAM_PAGE_URL_PL} />
            <SEO title={t('menu:my-teams')} />
            {!props.loading && props.user && !props.user.data &&
                <RedirectLoginWithReturn lang={props.lang} returnUrl={props.lang ? t("paths:team", {lng: props.lang}) : t("paths:team")} />
            }
            <Container className="mt-2">
            <h1>{t('menu:my-teams')}</h1>
            {props.user && props.user.data &&
                <>
                    <Row>
                        <Col>
                            {isLeader &&
                                <Button className="mr-2" onClick={toggleCreateTeamOpen}>
                                    {t('team:create-team')}
                                </Button>
                            }
                            <Button className="mr-2" onClick={toggleJoinTeamOpen}>
                                {t('team:join-team')}
                            </Button>
                            <Collapse in={isCreateTeamOpen}>
                                <div>
                                    <CreateTeam onDone={() => { setCreateTeamOpen(false) }} />
                                </div>
                            </Collapse>
                            <Collapse in={isJoinTeamOpen}>
                                <div>
                                    <JoinTeam onDone={() => { setJoinTeamOpen(false) }} />
                                </div>
                            </Collapse>
                            <hr />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ConnectedPagination requestId={API_TEAM_GET_TEAMS_ID} url={API_URL_POSTFIX + API_TEAM_GET_TEAMS_URL}></ConnectedPagination>
                            <ListGroup>
                                <ListGroup.Item className="d-flex" style={{ paddingLeft: "53px" }}>
                                    <div className="w-50"><b>{t('column-name')}</b></div>
                                    <div className="w-50"><b>{t('column-role')}</b></div>
                                    <div className="w-25"></div>
                                </ListGroup.Item>
                                {props.teams.map((team, i) => {
                                    return (
                                        <TeamListItem team={team} key={i} parentProps={props} />
                                    )
                                })}
                            </ListGroup>
                            <ConnectedPagination last requestId={API_TEAM_GET_TEAMS_ID} url={API_URL_POSTFIX + API_TEAM_GET_TEAMS_URL}></ConnectedPagination>
                        </Col>
                    </Row>
                </>
            }
            {props.loading &&
                <Row className="ml-4 mt-4 mb-4">
                    <Col>
                        <div className="text-center">
                            <Spinner animation="border" role="status" />
                        </div>
                    </Col>
                </Row>
            }
            </Container>
        </>
    )
}

const mapStateToProps = state => ({
    user: state.user,
    loading: isPending('userInfo', state) || isPending(API_TEAM_GET_TEAMS_ID, state),
    removingItems: isPending(API_TEAM_DEL_TEAM_ID, state),
    teams: getOutputOrDefault(API_TEAM_GET_TEAMS_ID, state, { user_teams: [] })[ 'user_teams' ],
    requests: state.requests
})

const mapDispatchToProps = dispatch => ({
    getTeams: () => dispatch(postDataApi(API_TEAM_GET_TEAMS_ID, API_TEAM_GET_TEAMS_URL)),
    removeTeam: (teamId, requests, t) => dispatch(postDataApi(API_TEAM_DEL_TEAM_ID, API_TEAM_DEL_TEAM_URL, {
        team_id: teamId
    })).then(() => {
        notify.success(t('team:removed-team'))
        const oldData = requests[ API_TEAM_GET_TEAMS_ID ].output.user_teams
        const newData = oldData.filter((t) => t.id !== teamId)
        dispatch(requestSuccess(API_TEAM_GET_TEAMS_ID, { user_teams: newData }))
    }).catch((res) => {
        notify.error(t('team:couldnt-remove-team'), res.result)
    }),
    leaveTeam: (userId, teamId, requests, t) => {
        return dispatch(postDataApi(API_TEAM_DEL_USER_ID + teamId, API_TEAM_DEL_USER_URL, {
            team_id: teamId,
            user_id: userId
        })).then(() => {
            notify.success(t('team:left-team'))
            const oldData = requests[ API_TEAM_GET_TEAMS_ID ].output.user_teams
            const newData = oldData.filter((t) => t.id !== teamId)
            dispatch(requestSuccess(API_TEAM_GET_TEAMS_ID, { user_teams: newData }))
        }).catch((res) => {
            notify.error(t('team:couldnt-leave-team'), res.result)
        })
    },
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TeamContainer)
