import React from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import * as actions from '../../actions'
import { setField } from '../../actions/forms'
import {
    ICONDB_DETAILS_REQUEST_ID_PREFIX,
    ICONDB_SEARCH_REQUEST_URL,
    ICONDB_DETAILS_REQUEST_URL,
    DLIBRA_URL_POSTFIX,
    DLIBRA_IIIF_THUMBNAIL_URL,
    ICON_RESULTS_ACTIVE_RECORD,
} from '../../settings'
import { getItems, getPagination, getFilter, isPending, getField } from "../../reducers";
import ConnectedPagination from "../ConnectedPagination"
import { Container, Button, Col, Row } from "react-bootstrap"
import RecordDetailsContainer from "./RecordDetailsContainer"

const TaxonDBResultsList = props => {
    const { t, i18n } = useTranslation([ 'common', 'forms', 'taxondb', 'icondb' ])

    const toggleOpen = (recordId) => {
        props.setOpen(recordId)
        props.getDetails(recordId)
    }

    return (
        <div>
            <Container className="d-flex flex-column mt-2">
                {!props.loading &&
                    <div>
                        {!props.items &&
                            <p>{t('use-form-to-search')}</p>
                        }
                        {props.items && props.items.length > 0 &&
                            <div>
                                <div className="mb-2 d-flex flex-row justify-content-center">
                                    <div className="p-2">
                                        {t('icondb:nr_of_records_found')}: <b>{props.pagination.totalCount ? props.pagination.totalCount.toLocaleString(i18n.languages[0]) : '-'}</b>
                                    </div>
                                </div>
                                <div className={props.open ? "hide-on-mobile" : ""}>
                                    <ConnectedPagination requestId={props.requestId} url={DLIBRA_URL_POSTFIX + ICONDB_SEARCH_REQUEST_URL}></ConnectedPagination>
                                </div>
                                <Row className="d-flex flex-row">
                                    <Col xl={props.open ? 6 : 12} className={props.open ? "hide-on-mobile" : ""}>
                                        <div className="d-flex justify-content-center flex-wrap">
                                            {props.items.map((val, indx) =>
                                                <Button
                                                    variant="link"
                                                    onClick={() => toggleOpen(val.edi_id)}
                                                    disabled={props.loading}
                                                    className={"icondb-thumbnail" + (props.open === val.edi_id ? " active" : "")}
                                                    key={indx}
                                                    size="sm">
                                                    <div>
                                                        <img alt={val.edi_id} src={DLIBRA_IIIF_THUMBNAIL_URL + val.edi_id}></img>
                                                        <div className="text-center">{val.external_id}</div>
                                                        <div className="text-center">
                                                            {val.title &&
                                                                <>
                                                                    [{val.title}]
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                </Button>
                                            )}
                                        </div>
                                    </Col>
                                    {props.open &&
                                        <Col className="icondb-right-panel sticky-top" id="details-panel">
                                            <RecordDetailsContainer requestId={ICONDB_DETAILS_REQUEST_ID_PREFIX} recordId={props.open}></RecordDetailsContainer>
                                        </Col>
                                    }
                                </Row>
                                <div className={props.open ? "hide-on-mobile" : ""}>
                                    <ConnectedPagination last requestId={props.requestId} url={DLIBRA_URL_POSTFIX + ICONDB_SEARCH_REQUEST_URL}></ConnectedPagination>
                                </div>
                            </div>
                        }
                        {props.items && !props.items.length > 0 &&
                            <p>{t('no-records-found')}</p>
                        }
                    </div>
                }
            </Container>
        </div >
    )
}

const mapStateToProps = (state, ownProps) => ({
    items: getItems(ownProps.requestId, state),
    loading: isPending(ownProps.requestId, state),
    filter: getFilter(ownProps.genusRequestId, state),
    pagination: getPagination(ownProps.requestId, state),
    user: state.user ? state.user.data : null,
    open: getField(ICON_RESULTS_ACTIVE_RECORD, ICON_RESULTS_ACTIVE_RECORD, state),
})

const mapDispatchToProps = dispatch => ({
    getDetails: (recordId) => dispatch(actions.getDataDlibra(ICONDB_DETAILS_REQUEST_ID_PREFIX, ICONDB_DETAILS_REQUEST_URL + recordId + '/')),
    setOpen: value => dispatch(setField(ICON_RESULTS_ACTIVE_RECORD, ICON_RESULTS_ACTIVE_RECORD, value)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TaxonDBResultsList)
