export const fieldSampleMerocenosisDeadWood = [
	"bardzo silny",
	"silny",
	"umiarkowany",
	"słaby",
	"bardzo słaby"
]

export const fieldSampleMerocenosisHost = [
	"Lis",
	"Sroka",
	"Bocian biały",
	"Cikonia nigra",
	"Formica rufa",
	"Plagionothus detritus"
]

export const fieldSampleMerocenosisTreeSpecies = [
	"brzoza",
	"dąb",
	"sosna"
]

export const fieldSampleMerocenosisType = [
	"karpy",
	"wypróchniałe drzewo",
	"stojąca kłoda",
	"pniak",
	"leżąca kłoda",
	"odchody",
	"gniazdo wieloletnie",
	"budka",
	"jaskinia",
	"napływki"
]

export const fieldSampleMerocenosis = [
	"Ś:24 - mrowisko",
	"Ś:25 - gniazdo ssaka",
	"Ś:26 - gniazdo ptaka",
	"Ś:28 - martwe drewno",
	"Ś:29 - dziupla",
	"Odchody"
]

export const fieldSampleMethodTaken = [
	"Pułapka-Barbera",
	"Pułapka-Merica",
	"Pułapka-feromonowa",
	"Pułapka-świetlna",
	"Czerpak entomologiczny",
	"Sito entolomogiczne",
	"Wypatrywanie",
	"Biocenometr"
]

export const fieldSampleType = [
	"ilościowa",
	"jakościowa"
]

export const fieldSource = [
	"zebrany okaz",
	"zdjęcie",
	"notatka"
]

export const floraNames = [
	"Abutilon theophrasti",
	"Acer campestre",
	"Acer negundo",
	"Acer platanoides",
	"Acer pseudoplatanus",
	"Acer saccharinum",
	"Acer tataricum",
	"Achillea millefolium",
	"Achillea pannonica",
	"Achillea ptarmica",
	"Achillea salicifolia",
	"Acinos arvensis",
	"Acorus calamus",
	"Actaea spicata",
	"Adenophora lilifolia",
	"Adonis aestivalis",
	"Adonis vernalis",
	"Adoxa moschatellina",
	"Aegilops cylindrica",
	"Aegopodium podagraria",
	"Aesculus hippocastanum",
	"Aethusa cynapium",
	"Agrimonia eupatoria",
	"Agrimonia procera",
	"Agropyron cristatum",
	"Agrostemma githago",
	"Agrostis canina",
	"Agrostis capillaris",
	"Agrostis gigantea",
	"Agrostis stolonifera",
	"Ailanthus altissima",
	"Aira caryophyllea",
	"Aira praecox",
	"Ajuga genevensis",
	"Ajuga reptans",
	"Alcea rosea",
	"Alchemilla acutiloba",
	"Alchemilla gracilis",
	"Alchemilla monticola",
	"Aldrovanda vesiculosa",
	"Alisma lanceolatum",
	"Alisma plantago-aquatica",
	"Alliaria officinalis",
	"Allium angulosum",
	"Allium oleraceum",
	"Allium schoenoprasum",
	"Allium scorodoprasum",
	"Allium ursinum",
	"Allium vineale",
	"Alnus glutinosa",
	"Alnus incana",
	"Alopecurus aequalis",
	"Alopecurus geniculatus",
	"Alopecurus myosuroides",
	"Alopecurus pratensis",
	"Althaea officinalis",
	"Alyssum alyssoides",
	"Amaranthus albus",
	"Amaranthus blitoides",
	"Amaranthus caudatus",
	"Amaranthus chlorostachys",
	"Amaranthus lividus",
	"Amaranthus retroflexus",
	"Ambrosia artemisiifolia",
	"Amelanchier spicata",
	"Amorpha fruticosa",
	"Anagallis arvensis",
	"Anagallis foemina",
	"Anchusa arvensis",
	"Anchusa officinalis",
	"Andromeda polifolia",
	"Androsace septentrionalis",
	"Anemone nemorosa",
	"Anemone ranunculoides",
	"Anemone sylvestris",
	"Anethum graveolens",
	"Angelica archangelica",
	"Angelica sylvestris",
	"Antennaria dioica",
	"Anthemis arvensis",
	"Anthemis austriaca",
	"Anthemis cotula",
	"Anthemis ruthenica",
	"Anthemis tinctoria",
	"Anthericum ramosum",
	"Anthoxanthum aristatum",
	"Anthoxanthum odoratum",
	"Anthriscus vulgaris",
	"Anthriscus cerefolium",
	"Anthriscus sylvestris",
	"Anthyllis vulneraria",
	"Apera spica-venti",
	"Aphanes arvensis",
	"Apium repens",
	"Aquilegia vulgaris",
	"Arabidopsis thaliana",
	"Arabis glabra",
	"Arabis hirsuta",
	"Arabis planisiliqua",
	"Arctium lappa",
	"Arctium minus",
	"Arctium nemorosum",
	"Arctium tomentosum",
	"Arenaria serpyllifolia",
	"Armeria elongata",
	"Armoracia rusticana",
	"Arnoseris minima",
	"Arrhenatherum elatius",
	"Artemisia absinthium",
	"Artemisia austriaca",
	"Artemisia campestris",
	"Artemisia dracunculus",
	"Artemisia vulgaris",
	"Asarum europaeum",
	"Asparagus officinalis",
	"Asperugo procumbens",
	"Asperula tinctoria",
	"Asplenium ruta-muraria",
	"Aster amellus",
	"Aster lanceolatus",
	"Aster linosyris",
	"Aster tripolium",
	"Astragalus arenarius",
	"Astragalus cicer",
	"Astragalus glycyphyllos",
	"Astrantia maior",
	"Athyrium filix-femina",
	"Atriplex hastifolia",
	"Atriplex hortensis",
	"Atriplex nitens",
	"Atriplex oblongifolia",
	"Atriplex patula",
	"Atriplex rosea",
	"Atriplex tatarica",
	"Avena fatua",
	"Avena strigosa",
	"Avenula pratensis",
	"Avenula pubescens",
	"Ballota nigra",
	"Barbaraea stricta",
	"Barbaraea vulgaris",
	"Batrachium aquatile",
	"Batrachium circinatum",
	"Batrachium fluitans",
	"Batrachium trichophyllum",
	"Beckmannia eruciformis",
	"Bellis perennis",
	"Berberis vulgaris",
	"Berteroa incana",
	"Berula erecta",
	"Betonica officinalis",
	"Betula humilis",
	"Betula lenta",
	"Betula pendula",
	"Betula pubescens",
	"Bidens cernua",
	"Bidens frondosa",
	"Bidens tripartita",
	"Blysmus compressus",
	"Botrychium lunaria",
	"Botrychium matriariifolium",
	"Botrychium multifidum",
	"Brachypodium pinnatum",
	"Brachypodium sylvaticum",
	"Brassica nigra",
	"Briza media",
	"Bromus arvensis",
	"Bromus benekeni",
	"Bromus carinatus",
	"Bromus commutatus",
	"Bromus erectus",
	"Bromus hordeaceus",
	"Bromus inermis",
	"Bromus japonicus",
	"Bromus lanceolatus",
	"Bromus secalinus",
	"Bromus squarrosus",
	"Bromus sterilis",
	"Bromus tectorum",
	"Bryonia alba",
	"Bulboschoenus maritimus",
	"Bunias orientalis",
	"Butomus umbellatus",
	"Calamagrostis arundinacea",
	"Calamagrostis canescens",
	"Calamagrostis epigejos",
	"Calamagrostis stricta",
	"Calendula officinalis",
	"Calla palustris",
	"Callitriche cophocarpa",
	"Callitriche verna",
	"Calluna vulgaris",
	"Caltha palustris",
	"Calystegia sepium",
	"Camelina microcarpa",
	"Camelina sativa",
	"Campanula bononiensis",
	"Campanula cervicaria",
	"Campanula glomerata",
	"Campanula latifolia",
	"Campanula patula",
	"Campanula persicifolia",
	"Campanula rapunculoides",
	"Campanula rotundifolia",
	"Campanula sibirica",
	"Campanula trachelium",
	"Cannabis ruderalis",
	"Cannabis sativa",
	"Capsella bursa-pastoris",
	"Caragana arborescens",
	"Cardamine amara",
	"Cardamine hirsuta",
	"Cardamine pratensis",
	"Cardaminopsis arenosa",
	"Cardaria draba",
	"Carduus acanthoides",
	"Carduus crispus",
	"Carduus nutans",
	"Carex acutiformis",
	"Carex appropinquata",
	"Carex atherodes",
	"Carex bohemica",
	"Carex brizoides",
	"Carex buxbaumii",
	"Carex canescens",
	"Carex caryophyllea",
	"Carex cespitosa",
	"Carex chordorrhiza",
	"Carex cuprina",
	"Carex diandra",
	"Carex digitata",
	"Carex dioica",
	"Carex distans",
	"Carex disticha",
	"Carex echinata",
	"Carex elata",
	"Carex elongata",
	"Carex ericetorum",
	"Carex flacca",
	"Carex flava",
	"Carex gracilis",
	"Carex hartmanii",
	"Carex hirta",
	"Carex lasiocarpa",
	"Carex lepidocarpa",
	"Carex limosa",
	"Carex montana",
	"Carex nigra",
	"Carex ovalis",
	"Carex pairae",
	"Carex pallescens",
	"Carex panicea",
	"Carex paniculata",
	"Carex pilulifera",
	"Carex praecox",
	"Carex pseudocyperus",
	"Carex remota",
	"Carex riparia",
	"Carex rostrata",
	"Carex silvatica",
	"Carex spicata",
	"Carex tomentosa",
	"Carex vesicaria",
	"Carex viridula",
	"Carex vulpina",
	"Carlina acaulis",
	"Carlina vulgaris",
	"Carpinus betulus",
	"Carum carvi",
	"Catabrosa aquatica",
	"Centaurea cyanus",
	"Centaurea diffusa",
	"Centaurea jacea",
	"Centaurea rhenana",
	"Centaurea scabiosa",
	"Centaurium pulchellum",
	"Centaurium umbellatum",
	"Centunculus minimus",
	"Cephalanthera damasonium",
	"Cephalanthera rubra",
	"Cerastium arvense",
	"Cerastium macrocarpum",
	"Cerastium semidecandrum",
	"Cerastium viscosum",
	"Cerastium vulgatum",
	"Cerasus avium",
	"Cerasus fruticosa",
	"Cerasus mahaleb",
	"Cerasus vulgaris",
	"Ceratophyllum demersum",
	"Ceratophyllum submersum",
	"Chaenomeles japonica",
	"Chaenorhinum minus",
	"Chaerophyllum aromaticum",
	"Chaerophyllum aureum",
	"Chaerophyllum bulbosum",
	"Chaerophyllum temulum",
	"Chamaecytisus ruthenicus",
	"Chamaenerion angustifolium",
	"Chelidonium majus",
	"Chenopodium album",
	"Chenopodium bonus-henricus",
	"Chenopodium ficifolium",
	"Chenopodium glaucum",
	"Chenopodium hybridum",
	"Chenopodium murale",
	"Chenopodium polyspermum",
	"Chenopodium rubrum",
	"Chenopodium strictum",
	"Chenopodium suecicum",
	"Chenopodium urbicum",
	"Chenopodium vulvaria",
	"Chimaphila umbellata",
	"Chondrilla juncea",
	"Chrysosplenium alternifolium",
	"Cichorium intybus",
	"Cicuta virosa",
	"Circaea alpina",
	"Circaea lutetiana",
	"Cirsium acaule",
	"Cirsium arvense",
	"Cirsium canum",
	"Cirsium oleraceum",
	"Cirsium palustre",
	"Cirsium vulgare",
	"Cladium mariscus",
	"Clematis vitalba",
	"Clinopodium vulgare",
	"Cnidium dubium",
	"Cochlearia danica",
	"Colchicum autumnale",
	"Colutea arborescens",
	"Comarum palustre",
	"Conium maculatum",
	"Consolida ajacis",
	"Consolida regalis",
	"Convallaria majalis",
	"Convolvulus arvensis",
	"Conyza canadensis",
	"Coriandrum sativum",
	"Corispermum hyssopifolium",
	"Corispermum leptopterum",
	"Cornus alba",
	"Cornus mas",
	"Cornus sanguinea",
	"Coronilla varia",
	"Coronopus squamatus",
	"Corydalis cava",
	"Corydalis intermedia",
	"Corydalis pumila",
	"Corylus avellana",
	"Corynephorus canescens",
	"Cosmos bipinnatus",
	"Cotoneaster divaricatus",
	"Cotoneaster lucidus",
	"Crataegus laevigata",
	"Crataegus monogyna",
	"Crataegus pedicellata",
	"Crataegus rhipidophylla",
	"Crataegus x macrocarpa",
	"Crepis biennis",
	"Crepis capillaris",
	"Crepis paludosa",
	"Crepis praemorsa",
	"Crepis tectorum",
	"Cucubalus baccifer",
	"Cuscuta epilinum",
	"Cuscuta epithymum",
	"Cuscuta europaea",
	"Cuscuta lupuliformis",
	"Cynoglossum officinale",
	"Cynosurus cristatus",
	"Cyperus fuscus",
	"Cypripedium calceolus",
	"Cystopteris fragilis",
	"Dactylis glomerata",
	"Dactylis polygama",
	"Dactylorrhiza fuchsii",
	"Dactylorrhiza incarnata",
	"Dactylorrhiza majalis",
	"Danthonia decumbens",
	"Daphne mezereum",
	"Datura stramonium",
	"Daucus carota",
	"Deschampsia caespitosa",
	"Deschampsia flexuosa",
	"Descurainia sophia",
	"Dianthus arenarius",
	"Dianthus armeria",
	"Dianthus barbatus",
	"Dianthus carthusianorum",
	"Dianthus deltoides",
	"Dianthus superbus",
	"Dicentra spectabilis",
	"Digitalis grandiflora",
	"Digitalis purpurea",
	"Digitaria ischaemum",
	"Digitaria sanguinalis",
	"Diphasiastrum complanatum",
	"Diplotaxis muralis",
	"Diplotaxis tenuifolia",
	"Dipsacus sylvestris",
	"Draba nemorosa",
	"Drosera anglica",
	"Drosera rotundifolia",
	"Dryopteris carthusiana",
	"Dryopteris cristata",
	"Dryopteris dilatata",
	"Dryopteris filix-mas",
	"Echinochloa crus-galli",
	"Echinocystis lobata",
	"Echinops sphaerocephalus",
	"Echium vulgare",
	"Elatine alsinastrum",
	"Eleagnus angustifolia",
	"Eleagnus commutata",
	"Eleocharis acicularis",
	"Eleocharis palustris",
	"Eleocharis quinqueflora",
	"Eleocharis uniglumis",
	"Elodea canadensis",
	"Elsholtzia ciliata",
	"Elymus caninus",
	"Elymus repens",
	"Epilobium adnatum",
	"Epilobium ciliatum",
	"Epilobium hirsutum",
	"Epilobium lamyi",
	"Epilobium montanum",
	"Epilobium obscurum",
	"Epilobium palustre",
	"Epilobium parviflorum",
	"Epilobium roseum",
	"Epipactis helleborine",
	"Epipactis palustris",
	"Equisetum arvense",
	"Equisetum fluviatile",
	"Equisetum hyemale",
	"Equisetum palustre",
	"Equisetum pratense",
	"Equisetum ramosissimum",
	"Equisetum sylvaticum",
	"Equisetum telmateia",
	"Equisetum variegatum",
	"Eragrostis minor",
	"Erechtites hieracifolia",
	"Erigeron acris",
	"Erigeron annuus",
	"Eriophorum angustifolium",
	"Eriophorum gracile",
	"Eriophorum latifolium",
	"Eriophorum vaginatum",
	"Erodium cicutarium",
	"Erophila verna",
	"Erucastrum gallicum",
	"Eryngium planum",
	"Erysimum cheiranthoides",
	"Erysimum hieraciifolium",
	"Erysimum hieraciifolium",
	"Eupatorium cannabinum",
	"Euphorbia cyparissias",
	"Euphorbia esula",
	"Euphorbia exigua",
	"Euphorbia helioscopia",
	"Euphorbia lucida",
	"Euphorbia marginata",
	"Euphorbia palustris",
	"Euphorbia peplus",
	"Euphorbia pisiformis",
	"Euphorbia platyphyllos",
	"Euphrasia nemorosa",
	"Euphrasia rostkoviana",
	"Euphrasia stricta",
	"Evonymus europaea",
	"Evonymus verrucosa",
	"Fagus sylvatica",
	"Falcaria vulgaris",
	"Fallopia convolvulus",
	"Fallopia dumetorum",
	"Festuca altissima",
	"Festuca amethystina",
	"Festuca arundinacea",
	"Festuca gigantea",
	"Festuca heterophylla",
	"Festuca ovina",
	"Festuca pratensis",
	"Festuca psammophila",
	"Festuca rubra",
	"Festuca trachyphylla",
	"Ficaria verna",
	"Filago arvensis",
	"Filago minima",
	"Filipendula ulmaria",
	"Filipendula vulgaris",
	"Fragaria moschata",
	"Fragaria vesca",
	"Fragaria viridis",
	"Fragaria x ananassa",
	"Frangula alnus",
	"Fraxinus excelsior",
	"Fraxinus pennsylvanica",
	"Frittillaria meleagris",
	"Fumaria officinalis ssp. officinalis",
	"Fumaria vaillantii",
	"Gagea arvensis",
	"Gagea lutea",
	"Gagea minima",
	"Gagea pratensis",
	"Gagea spathacea",
	"Galanthus nivalis",
	"Galeobdolon luteum",
	"Galeopsis angustifolia",
	"Galeopsis bifida",
	"Galeopsis ladanum",
	"Galeopsis pubescens",
	"Galeopsis speciosa",
	"Galeopsis tetrahit",
	"Galinsoga ciliata",
	"Galinsoga parviflora",
	"Galium  glaucum",
	"Galium aparine",
	"Galium boreale",
	"Galium mollugo",
	"Galium odoratum",
	"Galium palustre",
	"Galium schultesii",
	"Galium spurium ssp. spurium",
	"Galium sylvaticum",
	"Galium uliginosum",
	"Galium verum",
	"Genista tinctoria",
	"Gentiana cruciata",
	"Gentiana pneumonanthe",
	"Gentianella uliginosa",
	"Geranium dissectum",
	"Geranium molle",
	"Geranium palustre",
	"Geranium pratense",
	"Geranium pusillum",
	"Geranium pyrenaicum",
	"Geranium robertianum",
	"Geranium sanguineum",
	"Geranium sylvaticum",
	"Geum rivale",
	"Geum urbanum",
	"Gladiolus imbricatus",
	"Glaux maritima",
	"Glechoma hederacea",
	"Gleditschia triacanthos",
	"Glyceria declinata",
	"Glyceria fluitans",
	"Glyceria maxima",
	"Glyceria notata",
	"Glyceria striata",
	"Gnaphalium luteo-album",
	"Gnaphalium sylvaticum",
	"Gnaphalium uliginosum",
	"Goodyera repens",
	"Gratiola officinalis",
	"Gymnadenia conopsea",
	"Gymnocarpium dryopteris",
	"Gypsophila fastigiata",
	"Gypsophila muralis",
	"Gypsophila paniculata",
	"Hedera helix",
	"Helianthemum nummularium",
	"Helianthus tuberosus",
	"Helichrysum arenarium",
	"Hemerocallis fulva",
	"Hepatica nobilis",
	"Heracleum mantegazzianum",
	"Heracleum sibiricum",
	"Herniaria glabra",
	"Hesperis matronalis",
	"Hieracium caespitosum",
	"Hieracium lachenalii",
	"Hieracium lactucella",
	"Hieracium laevigatum",
	"Hieracium murorum",
	"Hieracium pilosella",
	"Hieracium piloselloides",
	"Hieracium sabaudum",
	"Hieracium umbellatum",
	"Hierochloë australis",
	"Hierochloë odorata",
	"Hierochloë repens",
	"Hippophaë rhamnoides",
	"Hippuris vulgaris",
	"Holcus lanatus",
	"Holcus mollis",
	"Holosteum umbellatum",
	"Hordeum murinum",
	"Hottonia palustris",
	"Humulus lupulus",
	"Hydrocharis morsus-ranae",
	"Hydrocotyle vulgaris",
	"Hyoscyamus niger",
	"Hypericum humifusum",
	"Hypericum maculatum",
	"Hypericum montanum",
	"Hypericum perforatum",
	"Hypericum tetrapterum",
	"Hypochoeris glabra",
	"Hypochoeris maculata",
	"Hypochoeris radicata",
	"Iberis amara",
	"Impatiens glandulifera",
	"Impatiens noli-tangere",
	"Impatiens parviflora",
	"Inula britannica",
	"Inula helenium",
	"Inula hirta",
	"Inula salicina",
	"Iris germanica",
	"Iris pseudacorus",
	"Iris sibirica",
	"Isolepis setacea",
	"Iva xanthiifolia",
	"Jasione montana",
	"Jovibarba sobolifera",
	"Juglans regia",
	"Juncus alpino-articulatus",
	"Juncus articulatus",
	"Juncus atratus",
	"Juncus bufonius",
	"Juncus bulbosus",
	"Juncus capitatus",
	"Juncus compressus",
	"Juncus conglomeratus",
	"Juncus effusus",
	"Juncus gerardi",
	"Juncus inflexus",
	"Juncus ranarius",
	"Juncus squarrosus",
	"Juncus tanageia",
	"Juncus tenuis",
	"Juniperus communis",
	"Kickxia elatine",
	"Knautia arvensis",
	"Kochia scoparia",
	"Koeleria glauca",
	"Koeleria macrantha",
	"Lactuca serriola",
	"Lamium album",
	"Lamium amplexicaule",
	"Lamium maculatum",
	"Lamium purpureum",
	"Lappula squarrosa",
	"Lapsana communis",
	"Larix decidua",
	"Larix polonica",
	"Laserpitium latifolium",
	"Laserpitium prutenicum",
	"Lathraea squamaria",
	"Lathyrus aphaca",
	"Lathyrus montanus",
	"Lathyrus niger",
	"Lathyrus palustris",
	"Lathyrus pratensis",
	"Lathyrus sylvestris",
	"Lathyrus tuberosus",
	"Lathyrus vernus",
	"Lavatera thuringiaca",
	"Ledum palustre",
	"Leersia oryzoides",
	"Lembotropis nigricans",
	"Lemna gibba",
	"Lemna minor",
	"Lemna trisulca",
	"Leontodon autumnalis",
	"Leontodon hispidus",
	"Leontodon taraxacoides",
	"Leonurus cardiaca",
	"Lepidium campestre",
	"Lepidium densiflorum",
	"Lepidium latifolium",
	"Lepidium ruderale",
	"Lepidium virginicum",
	"Leucanthemum vulgare",
	"Levisticum officinale",
	"Leymus arenarius",
	"Libanotis pyrenaica",
	"Ligustrum vulgare",
	"Lilium bulbiferum",
	"Lilium martagon",
	"Limosella aquatica",
	"Linaria arvensis",
	"Linaria genistifolia",
	"Linaria vulgaris",
	"Linnaea borealis",
	"Linosyris vulgaris",
	"Linum austriacum",
	"Linum catharticum",
	"Liparis loeselii",
	"Listera ovata",
	"Lithospermum arvense",
	"Lithospermum officinale",
	"Lolium multiflorum",
	"Lolium perenne",
	"Lolium temulentum",
	"Lonicera caprifolium",
	"Lonicera involucrata",
	"Lonicera periclymenum",
	"Lonicera tatarica",
	"Lonicera xylosteum",
	"Lotus corniculatus",
	"Lotus tenuis",
	"Lotus uliginosus",
	"Lunaria annua",
	"Lupinus polyphyllus",
	"Luzula campestris",
	"Luzula multiflora",
	"Luzula nemorosa",
	"Luzula pallescens",
	"Luzula pilosa",
	"Lychnis flos-cuculi",
	"Lycium barbarum",
	"Lycopodium annotinum",
	"Lycopodium clavatum",
	"Lycopus europaeus",
	"Lysimachia nummularia",
	"Lysimachia thyrsiflora",
	"Lysimachia vulgaris",
	"Lythrum hyssopifolia",
	"Lythrum salicaria",
	"Mahonia aquifolium",
	"Maianthemum bifolium",
	"Malus domestica",
	"Malus sylvestris",
	"Malva alcea",
	"Malva neglecta",
	"Malva pusilla",
	"Malva sylvestris",
	"Malva verticillata",
	"Marrubium vulgare",
	"Matricaria chamomilla",
	"Matricaria discoidea",
	"Matricaria maritima",
	"Matteucia struthiopteris",
	"Matthiola longipetala",
	"Medicago falcata",
	"Medicago lupulina",
	"Medicago minima",
	"Medicago sativa",
	"Medicago x varia",
	"Melampyrum arvense",
	"Melampyrum cristatum",
	"Melampyrum nemorosum",
	"Melampyrum pratense",
	"Melandrium album",
	"Melandrium noctiflorum",
	"Melandrium rubrum",
	"Melica nutans",
	"Melica uniflora",
	"Melilotus alba",
	"Melilotus altissima",
	"Melilotus dentata",
	"Melilotus officinalis",
	"Melilotus wolgica",
	"Mentha aquatica",
	"Mentha arvensis",
	"Mentha longifolia",
	"Mentha rotundifolia",
	"Mentha spicata",
	"Mentha x citrata",
	"Mentha x niliaca",
	"Mentha x verticillata",
	"Menyanthes trifoliata",
	"Mercurialis perennis",
	"Milium effusum",
	"Misopates orontium",
	"Moehringia trinervia",
	"Molinia caerulea",
	"Moneses uniflora",
	"Monotropa hypopitys",
	"Morus alba",
	"Muscari botryoides",
	"Muscari comosum",
	"Mycelis muralis",
	"Myosotis arvensis",
	"Myosotis caespitosa",
	"Myosotis discolor",
	"Myosotis palustris",
	"Myosotis ramosissima",
	"Myosotis sparsiflora",
	"Myosotis stricta",
	"Myosotis sylvatica",
	"Myosoton aquaticum",
	"Myosurus minimus",
	"Myriophyllum spicatum",
	"Myriophyllum verticillatum",
	"Najas marina",
	"Najas minor",
	"Narcissus poëticus",
	"Nardus stricta",
	"Nasturtium officinale",
	"Neottia nidus-avis",
	"Nepeta cataria",
	"Neslia paniculata",
	"Nigella arvensis",
	"Nigella damascena",
	"Nuphar lutea",
	"Nymphaea alba",
	"Nymphaea candida",
	"Odontites serotina",
	"Odontites verna",
	"Oenanthe aquatica",
	"Oenanthe fistulosa",
	"Oenothera biennis",
	"Oenothera depressa",
	"Oenothera rubricaulis",
	"Onobrychis viciifolia",
	"Ononis arvensis",
	"Ononis spinosa",
	"Onopordum acanthium",
	"Ophioglossum vulgatum",
	"Orchis militaris",
	"Orchis morio",
	"Orchis palustris",
	"Origanum vulgare",
	"Ornithogalum collinum",
	"Ornithogalum nutans",
	"Ornithogalum umbellatum",
	"Ornithopus perpusillus",
	"Orobanche caryophyllacea",
	"Orobanche elatior",
	"Orobanche purpurea",
	"Orthilia secunda",
	"Ostericum palustre",
	"Oxalis acetosella",
	"Oxalis corniculata",
	"Oxalis fontana",
	"Oxycoccus palustris",
	"Oxytropis pilosa",
	"Padus avium",
	"Padus serotina",
	"Padus virginiana",
	"Paeonia officinalis",
	"Panicum capillare",
	"Papaver argemone",
	"Papaver dubium",
	"Papaver orientale",
	"Papaver rhoeas",
	"Papaver somniferum",
	"Papaver strigosum",
	"Paris quadrifolia",
	"Parnassia palustris",
	"Parthenocissus quinquaefolia",
	"Pastinaca sativa",
	"Pedicularis palustris",
	"Pedicularis sceptrum-carolinum",
	"Peplis portula",
	"Petasites hybridus",
	"Petrorhagia prolifera",
	"Peucedanum cervaria",
	"Peucedanum oreoselinum",
	"Peucedanum palustre",
	"Phacelia tanacetifolia",
	"Phalaris arundinacea",
	"Phegopteris connectilis",
	"Philadelphus coronarius",
	"Phleum phleoides",
	"Phleum pratense",
	"Phragmites australis",
	"Physalis alkekengi",
	"Physocarpus opulifolius",
	"Phyteuma spicatum",
	"Picea abies",
	"Picea pungens",
	"Picris hieracioides",
	"Pimpinella major",
	"Pimpinella nigra",
	"Pimpinella saxifraga",
	"Pinus banksiana",
	"Pinus mugo",
	"Pinus nigra",
	"Pinus strobus",
	"Pinus sylvestris",
	"Plantago arenaria",
	"Plantago intermedia",
	"Plantago lanceolata",
	"Plantago major",
	"Plantago media",
	"Platanthera bifolia",
	"Platanthera chlorantha",
	"Platanus x hispanica",
	"Poa angustifolia",
	"Poa annua",
	"Poa bulbosa",
	"Poa compressa",
	"Poa nemoralis",
	"Poa palustris",
	"Poa pratensis",
	"Poa remota",
	"Poa subcaerulea",
	"Poa trivialis",
	"Polycnemum arvense",
	"Polygala amarella",
	"Polygala comosa",
	"Polygala vulgaris",
	"Polygonatum multiflorum",
	"Polygonatum odoratum",
	"Polygonum amphibium",
	"Polygonum aviculare",
	"Polygonum bistorta",
	"Polygonum Brittingeri",
	"Polygonum hydropiper",
	"Polygonum minus",
	"Polygonum mite",
	"Polygonum nodosum",
	"Polygonum persicaria",
	"Polypodium vulgare",
	"Populus ‘NE 42’",
	"Populus alba",
	"Populus candicans",
	"Populus nigra",
	"Populus nigra L. ‘Italica’",
	"Populus tremula",
	"Populus x canadensis",
	"Populus x canescens",
	"Portulaca oleracea ssp. oleracea",
	"Potamogeton acutifolius",
	"Potamogeton alpinus",
	"Potamogeton compressus",
	"Potamogeton crispus",
	"Potamogeton friesii",
	"Potamogeton gramineus",
	"Potamogeton lucens",
	"Potamogeton natans",
	"Potamogeton nodosus",
	"Potamogeton obtusifolius",
	"Potamogeton pectinatus",
	"Potamogeton perfoliatus",
	"Potamogeton praelongus",
	"Potamogeton pusillus",
	"Potamogeton trichoides",
	"Potentilla alba",
	"Potentilla anglica",
	"Potentilla anserina",
	"Potentilla arenaria",
	"Potentilla argentea",
	"Potentilla collina",
	"Potentilla erecta",
	"Potentilla heptaphylla",
	"Potentilla inclinata",
	"Potentilla neumanniana",
	"Potentilla norvegica",
	"Potentilla recta",
	"Potentilla reptans",
	"Potentilla rupestris",
	"Potentilla supina",
	"Primula veris",
	"Prunella vulgaris",
	"Prunus armeniaca",
	"Prunus cerasifera",
	"Prunus domestica",
	"Prunus persica",
	"Prunus spinosa",
	"Pseudotsuga manziesii",
	"Ptelea trifoliata",
	"Pteridium aquilinum",
	"Puccinellia distans",
	"Pulicaria vulgaris",
	"Pulmonaria angustifolia",
	"Pulmonaria obscura",
	"Pulsatilla patens",
	"Pulsatilla pratensis",
	"Pyrola chlorantha",
	"Pyrola minor",
	"Pyrola rotundifolia",
	"Pyrus pyraste",
	"Quercus petraea",
	"Quercus robur",
	"Quercus rubra",
	"Radiola linoides",
	"Ranunculus acris",
	"Ranunculus arvensis",
	"Ranunculus auricomus",
	"Ranunculus bulbosus",
	"Ranunculus cassubicus",
	"Ranunculus flammula",
	"Ranunculus lanuginosus",
	"Ranunculus lingua",
	"Ranunculus polyanthemos",
	"Ranunculus repens",
	"Ranunculus sardous",
	"Ranunculus sceleratus",
	"Ranunculus strigulosus",
	"Raphanus raphanistrum",
	"Raphanus sativus",
	"Reseda lutea",
	"Reseda luteola",
	"Reynoutria japonica",
	"Reynoutria sachalinensis",
	"Rhamnus cathartica",
	"Rheum rhabarbarum",
	"Rhinanthus minor",
	"Rhinanthus serotinus",
	"Rhus typhina",
	"Ribes alpinum",
	"Ribes aureum",
	"Ribes nigrum",
	"Ribes spicatum",
	"Ribes uva-crispa",
	"Robinia pseudoacacia",
	"Robinia pseudomexicana",
	"Rorippa amphibia",
	"Rorippa palustris",
	"Rorippa sylvestris",
	"Rorippa x armoracioides",
	"Rosa canina",
	"Rosa dumalis",
	"Rosa glauca",
	"Rosa inodora",
	"Rosa jundzillii",
	"Rosa multiflora",
	"Rosa rubiginosa",
	"Rosa rugosa",
	"Rosa sherardii",
	"Rosa tomentosa",
	"Rosa villosa",
	"Rubus armeniacus",
	"Rubus caesius",
	"Rubus corylifolius",
	"Rubus fabrimontanus",
	"Rubus grabowskii",
	"Rubus gracilis",
	"Rubus idaeus",
	"Rubus koehleri",
	"Rubus laciniatus",
	"Rubus nessensis",
	"Rubus plicatus",
	"Rubus posnaniensis",
	"Rubus pyramidalis",
	"Rubus radula",
	"Rubus rudis",
	"Rubus saxatilis",
	"Rubus sprengelii",
	"Rudbeckia hirta",
	"Rudbeckia laciniata",
	"Rumex acetosa",
	"Rumex acetosella",
	"Rumex confertus",
	"Rumex conglomeratus",
	"Rumex crispus",
	"Rumex hydrolapathum",
	"Rumex maritimus",
	"Rumex obtusifolius",
	"Rumex palustris",
	"Rumex sanguineus",
	"Rumex stenophyllus",
	"Rumex thyrsiflorus",
	"Sagina nodosa",
	"Sagina procumbens",
	"Sagittaria sagittifolia",
	"Salicornia europaea",
	"Salix  rosmarinifolia",
	"Salix acutifolia",
	"Salix alba",
	"Salix aurita",
	"Salix caprea",
	"Salix cinerea",
	"Salix cordata",
	"Salix fragilis",
	"Salix myrsinifolia",
	"Salix myrtilloides",
	"Salix pentandra",
	"Salix purpurea",
	"Salix repens",
	"Salix triandra",
	"Salix viminalis",
	"Salix x dasyclados",
	"Salix x sepulcralis",
	"Salsola kali ssp. ruthenica",
	"Salvia glutinosa",
	"Salvia nemorosa",
	"Salvia pratensis",
	"Salvia verticillata",
	"Sambucus ebulus",
	"Sambucus nigra",
	"Sambucus racemosa",
	"Sanguisorba minor",
	"Sanguisorba officinalis",
	"Sanicula europaea",
	"Saponaria officinalis",
	"Sarothamnus scoparius",
	"Saxifraga granulata",
	"Saxifraga hirculus",
	"Saxifraga tridactylites",
	"Scabiosa canescens",
	"Scabiosa columbaria",
	"Scabiosa ochroleuca",
	"Scheuchzera palustris",
	"Schoenoplectus lacustris",
	"Schoenoplectus Tabernaemontani",
	"Scilla sibirica",
	"Scirpoides holoschoenus",
	"Scirpus radicans",
	"Scirpus supinus",
	"Scirpus sylvaticus",
	"Scleranthus annuus",
	"Scleranthus perennis",
	"Scolochloa festucacea",
	"Scorzonera humilis",
	"Scorzonera purpurea",
	"Scrophularia nodosa",
	"Scrophularia umbrosa",
	"Scutellaria galericulata",
	"Scutellaria hastifolia",
	"Sedum acre",
	"Sedum maximum",
	"Sedum reflexum",
	"Sedum sexangulare",
	"Sedum spurium",
	"Selinum carvifolia",
	"Senecio aquaticus",
	"Senecio barbaraeifolius",
	"Senecio congestus",
	"Senecio erucifolius",
	"Senecio inaequidens",
	"Senecio jacobaea",
	"Senecio nemorensis",
	"Senecio paludosus",
	"Senecio sylvaticus",
	"Senecio vernalis",
	"Senecio viscosus",
	"Senecio vulgaris",
	"Serratula tinctoria",
	"Seseli annuum",
	"Setaria pumila",
	"Setaria verticillata",
	"Setaria viridis",
	"Sherardia arvensis",
	"Sideritis montana",
	"Silaum silaus",
	"Silene conica",
	"Silene dichotoma",
	"Silene glabra",
	"Silene nutans",
	"Silene otites",
	"Silene tatarica",
	"Silene vulgaris",
	"Sinapis alba",
	"Sinapis arvensis",
	"Sisymbrium altissimum",
	"Sisymbrium loeselii",
	"Sisymbrium officinale",
	"Sium latifolium",
	"Solanum dulcamara",
	"Solanum nigrum",
	"Solidago canadensis",
	"Solidago gigantea",
	"Solidago graminifolia",
	"Solidago virgaurea",
	"Sonchus arvensis",
	"Sonchus asper",
	"Sonchus oleraceus",
	"Sonchus palustris",
	"Sorbaria sorbifolia",
	"Sorbus aria",
	"Sorbus aucuparia",
	"Sorbus intermedia",
	"Sorbus torminalis",
	"Sparganium emersum",
	"Sparganium emersum",
	"Sparganium erectum",
	"Sparganium minimum",
	"Spergula arvensis",
	"Spergula morisonii",
	"Spergularia rubra",
	"Spergularia salina",
	"Spiraea chamaedryfolia",
	"Spiraea media",
	"Spiraea salicifolia",
	"Spiraea x vanhouttei",
	"Spirodela polyrhiza",
	"Stachys annua",
	"Stachys germanica",
	"Stachys palustris",
	"Stachys recta",
	"Stachys sylvatica",
	"Stellaria crassifolia",
	"Stellaria graminea",
	"Stellaria holostea",
	"Stellaria media",
	"Stellaria neglecta",
	"Stellaria nemorum",
	"Stellaria pallida",
	"Stellaria palustris",
	"Stellaria uliginosa",
	"Stipa joannis",
	"Stratiotes aloides",
	"Succisa pratensis",
	"Succisella infexa",
	"Symphoricarpos albus",
	"Symphytum officinale",
	"Syringa vulgaris",
	"Tanacetum parthenium",
	"Tanacetum vulgare",
	"Taraxacum laevigatum",
	"Taraxacum officinale",
	"Taraxacum palustre",
	"Taxus baccata",
	"Teesdalea nudicaulis",
	"Tetragonolobus maritimus",
	"Teucrium scordium",
	"Teucrium scorodonia",
	"Thalictrum aquilegiifolium",
	"Thalictrum flavum",
	"Thalictrum lucidum",
	"Thalictrum minus",
	"Thelypteris palustris",
	"Thesium ebracteatum",
	"Thesium linophyllon",
	"Thladiantha dubia",
	"Thlaspi arvense",
	"Thuja occidentalis",
	"Thuja plicata",
	"Thymus pulegioides",
	"Thymus serpyllum",
	"Tilia cordata",
	"Tilia platyphyllos",
	"Tofieldia calyculata",
	"Torilis japonica",
	"Tragopogon dubius",
	"Tragopogon orientalis",
	"Tragopogon pratensis",
	"Trientalis europaea",
	"Trifolium alpestre",
	"Trifolium arvense",
	"Trifolium aureum",
	"Trifolium campestre",
	"Trifolium dubium",
	"Trifolium fragiferum",
	"Trifolium hybridum",
	"Trifolium incarnatum",
	"Trifolium medium",
	"Trifolium montanum",
	"Trifolium pratense",
	"Trifolium repens",
	"Trifolium rubens",
	"Triglochin maritimum",
	"Triglochin palustre",
	"Trisetum flavescens",
	"Trollius europaeus",
	"Tulipa gesneriana",
	"Tussilago farfara",
	"Typha angustifolia",
	"Typha latifolia",
	"Ulmus glabra",
	"Ulmus laevis",
	"Ulmus minor",
	"Urtica dioica",
	"Urtica urens",
	"Utricularia australis",
	"Utricularia intermedia",
	"Utricularia minor",
	"Utricularia vulgaris",
	"Vaccinium myrtillus",
	"Vaccinium uliginosum",
	"Vaccinium vitis-idaea",
	"Valeriana dioica",
	"Valeriana officinalis",
	"Valerianella dentata",
	"Valerianella locusta",
	"Valerianella rimosa",
	"Vallisneria spiralis",
	"Verbascum densiflorum",
	"Verbascum lychnitis",
	"Verbascum nigrum",
	"Verbascum phlomoides",
	"Verbascum phoeniceum",
	"Verbena officinalis",
	"Veronica agrestis",
	"Veronica anagallis-aquatica",
	"Veronica arvensis",
	"Veronica beccabunga",
	"Veronica catenata",
	"Veronica chamaedrys",
	"Veronica dillenii",
	"Veronica hederifolia",
	"Veronica longifolia",
	"Veronica officinalis",
	"Veronica opaca",
	"Veronica persica",
	"Veronica polita",
	"Veronica praecox",
	"Veronica scutellata",
	"Veronica serpyllifolia",
	"Veronica spicata",
	"Veronica teucrium",
	"Veronica triphyllos",
	"Veronica verna",
	"Viburnum opulus",
	"Vicia angustifolia",
	"Vicia cassubica",
	"Vicia cracca",
	"Vicia dasycarpa",
	"Vicia dumetorum",
	"Vicia grandiflora",
	"Vicia hirsuta",
	"Vicia lathyroides",
	"Vicia pannonica",
	"Vicia sativa",
	"Vicia sepium",
	"Vicia sylvatica",
	"Vicia tenuifolia",
	"Vicia tetrasperma",
	"Vicia villosa",
	"Vinca minor",
	"Vincetoxicum hirundinaria",
	"Viola arvensis",
	"Viola canina",
	"Viola elatior",
	"Viola hirta",
	"Viola mirabilis",
	"Viola odorata",
	"Viola palustris",
	"Viola pumila",
	"Viola reichenbachiana",
	"Viola riviniana",
	"Viola rupestris",
	"Viola stagnina",
	"Viola tricolor",
	"Virga piosa",
	"Viscaria vulgaris",
	"Viscum album",
	"Viscum laxum",
	"Vulpia bromoides",
	"Vulpia myuros",
	"Wolffia arrhiza",
	"x Festulolium adscendens",
	"Xanthium albinum",
	"Xanthium strumarium",
	"Zannichellia palustris"
]