import React from "react";
import { Container } from "react-bootstrap"
import { Tab1 } from "../iconDB/RecordDetails"

const MapPopupContent = props => {
    return(
    <Container className="d-flex flex-column mt-2">
        {props.manySpecies &&
            <div className="mb-3">
                {props.t('taxondb:map_to_many_labels_on_zoom')}
            </div>
        }
        <Tab1 {...props} directRender ></Tab1>
    </Container>
    )
}

export default MapPopupContent