import React, { useEffect, useState, useRef, useCallback } from "react";
import { Button } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from "react-i18next";

export default (names, el, onSetActiveMenu) => {

  const { t } = useTranslation(['a11y'])

  const [ activeMenu, setActiveMenu ] = useState(0);
  const scrollPosition = useRef(0)
  const active = useRef(1)
  const scrollInactive = useRef(false)
  const elements = useRef(el)
  const isDown = useRef(false)
  const firstElementOffset = useRef(0)
  const direction = useRef(null)
  const isTabKeyActive = useRef(false)
  const resizeFlag = useRef(true)

  const ANIMATION_STEPS = 50

  function easeOutQuint(x) {
    return (1 - Math.pow(1 - x, 4))
  }

  const setNewOffset = () => {
    let offset1 = 0
    let offset2 = 0
    let offset3 = 0
    if (window.document.getElementsByClassName('first-row-menu')[ 0 ].getBoundingClientRect().height > 0) {
      offset1 = 40
    }
    if (window.document.getElementsByClassName('main-logo')[ 0 ].getBoundingClientRect().height === 0) {
      if (window.document.getElementsByClassName('main-logo-2')[ 0 ].getBoundingClientRect().height > 0) {
        offset2 = 76
      }
    } else {
      if (active.current > 0) {
        offset1 = 0
        offset2 = 0
      } else {
        offset2 = 82
      }
    }
    offset3 = window.document.getElementsByClassName('search-form')[ 0 ].getBoundingClientRect().height
    firstElementOffset.current = offset1 + offset2 + offset3
  }

  const startAnim = useCallback((step, ref, factors, difference, init) => {
    if (active && elements && active.current < el.length && elements.current[ active.current ].current) {
      const target = elements.current[ active.current ].current.getBoundingClientRect()[ ref ]
      setNewOffset()
      const reference = (ref === 'top') ? firstElementOffset.current : window.innerHeight
      if (!step) {
        difference = (target > reference) ? target - reference : reference - target
        factors = [ ...Array(ANIMATION_STEPS + 1).keys() ].map(x => easeOutQuint(x / ANIMATION_STEPS) * difference)
        init = window.scrollY
      }
      if (target > reference && (!direction.current || direction.current === 'down')) {
        direction.current = 'down'
        window.scrollTo(0, init + factors[ step ])
        if (target > reference && step < ANIMATION_STEPS) {
          requestAnimationFrame(() => startAnim(step + 1, ref, factors, difference, init))
        } else {
          direction.current = null
          scrollPosition.current = window.scrollY
          scrollInactive.current = false
        }
      } else if (target < reference && (!direction.current || direction.current === 'up')) {
        direction.current = 'up'
        window.scrollTo(0, init - factors[ step ])
        if (target < reference && step < ANIMATION_STEPS) {
          requestAnimationFrame(() => startAnim(step + 1, ref, factors, difference, init))
        } else {
          direction.current = null
          scrollPosition.current = window.scrollY
          scrollInactive.current = false
        }
      } else {
        direction.current = null
        scrollPosition.current = window.scrollY
        scrollInactive.current = false
      }
    }
  }, [ el.length ])

  const goToPosition = useCallback(pos => {
    scrollInactive.current = true
    setActiveMenu(pos)
    onSetActiveMenu(pos)
    active.current = pos
    requestAnimationFrame(() => startAnim(0, 'top'))
  }, [ startAnim, onSetActiveMenu ])

  const goToPositionBottom = useCallback(pos => {
    scrollInactive.current = true
    setActiveMenu(pos)
    onSetActiveMenu(pos)
    active.current = pos
    requestAnimationFrame(() => startAnim(0, 'bottom'))
  }, [ startAnim, onSetActiveMenu ])

  useEffect(() => {
    const handleMouseUp = event => {
      if (isDown.current && elements.current[ active.current ].current) {
        elements.current.forEach((val, indx) => {
          if (!val.current) {
            return
          }
          if ((val.current.getBoundingClientRect().top > firstElementOffset.current &&
            val.current.getBoundingClientRect().top < window.innerHeight) ||
            (val.current.getBoundingClientRect().top < firstElementOffset.current &&
              val.current.getBoundingClientRect().bottom > window.innerHeight) ||
            (val.current.getBoundingClientRect().bottom > firstElementOffset.current &&
              val.current.getBoundingClientRect().bottom < window.innerHeight)) {
            active.current = indx
          }
        })
        if (elements.current[ active.current ].current.getBoundingClientRect().top > firstElementOffset.current ||
          (elements.current[ active.current ].current.getBoundingClientRect().bottom < window.innerHeight && active.current < elements.current.length - 1)) {
          if (elements.current[ active.current ].current.getBoundingClientRect().top > firstElementOffset.current + (window.innerHeight / 2)) {
            active.current = active.current - 1
            goToPositionBottom(active.current)
          } else {
            goToPosition(active.current)
          }
        }
        window.addEventListener('scroll', handleScroll);
      }
      isDown.current = false
    }
    const handleMouseDown = event => {
      if (event.clientX >= window.document.body.clientWidth) { // scrollbar only
        isDown.current = true
        window.removeEventListener('scroll', handleScroll);
      }
    }

    const scrollToProperPosition = onKey => {
      if (window.scrollY < scrollPosition.current) {
        // going up
        // find first visible slide
        let newActive = active.current
        elements.current.slice().reverse().forEach((val, indx) => {
          if (!val.current) {
            return
          }
          if ((val.current.getBoundingClientRect().top > firstElementOffset.current &&
            val.current.getBoundingClientRect().top < window.innerHeight) ||
            (val.current.getBoundingClientRect().top < firstElementOffset.current &&
              val.current.getBoundingClientRect().bottom > window.innerHeight) ||
            (val.current.getBoundingClientRect().bottom > firstElementOffset.current &&
              val.current.getBoundingClientRect().bottom < window.innerHeight)) {
            newActive = elements.current.length - 1 - indx
          }
        })
        if (newActive !== active.current) {
          goToPositionBottom(newActive)
        }
      } else if (window.scrollY > scrollPosition.current) {
        if (isTabKeyActive.current) {
          window.scrollBy(0, window.document.activeElement.getBoundingClientRect().bottom - window.innerHeight)
        }
        // going down
        // find last visible slide
        let newActive = active.current
        elements.current.forEach((val, indx) => {
          if (!val.current) {
            return
          }
          if ((val.current.getBoundingClientRect().top > firstElementOffset.current &&
            val.current.getBoundingClientRect().top < window.innerHeight) ||
            (val.current.getBoundingClientRect().top < firstElementOffset.current &&
              val.current.getBoundingClientRect().bottom > window.innerHeight) ||
            (val.current.getBoundingClientRect().bottom > firstElementOffset.current &&
              val.current.getBoundingClientRect().bottom < window.innerHeight)) {
            newActive = indx
          }
        })
        if (newActive !== active.current) {
          if (elements.current[ newActive ].current.getBoundingClientRect().top < firstElementOffset.current) {
            goToPositionBottom(newActive)
          } else {
            goToPosition(newActive)
          }
        }
      }

      scrollPosition.current = window.scrollY
    }

    const handleKeyUp = event => {
      if (event.keyCode === 9) { // TAB & Shift-TAB
        isTabKeyActive.current = false
        if (window.document.activeElement.getBoundingClientRect().top < firstElementOffset.current &&
          window.document.activeElement.getBoundingClientRect().bottom <= firstElementOffset.current &&
          active.current > 0) {
          window.scrollBy(0, -firstElementOffset.current)
        }
      }
    }

    const handleKeyDown = event => {
      if (event.keyCode === 9) { // TAB & Shift-TAB
        isTabKeyActive.current = true
      }
    }

    const handleScroll = event => {
      if (!scrollInactive.current) {
        scrollToProperPosition()
      }
    };

    const handleResize = event => {
      if (resizeFlag.current) {
          resizeFlag.current = false
          setTimeout(() => {
            goToPositionBottom(active.current)
            resizeFlag.current = true
          }, 200)
      }
    }

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('mousedown', handleMouseDown);

    window.addEventListener('keyup', handleKeyUp);
    window.addEventListener('keydown', handleKeyDown);

    setActiveMenu(0)
    onSetActiveMenu(0)
    active.current = 0

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener('keyup', handleKeyUp);
      window.removeEventListener('keydown', handleKeyDown);
      goToPosition(0)
    };
  }, [ goToPosition, elements, el.length, goToPositionBottom, onSetActiveMenu ])


  const MenuComponent = props =>
    <div className={activeMenu === 2 ? "scroll-menu white-version d-flex flex-column justify-content-center" : "scroll-menu d-flex flex-column justify-content-center"} id="side-menu">
      {names.map((val, indx) =>
        <Button key={indx} onClick={() => goToPosition(indx)} variant="link" className={activeMenu === indx ? 'active' : 'not-active'}>
          <span className="text">
            <span className="dot"></span>
            <span className="name">{val}</span>
          </span>
          <span className="text-hover flex-grow-1">{val}</span>
        </Button>
      )}
      <Button variant="link" onClick={() => goToPosition(0)} className={(activeMenu > 0) ? "back-top back-top-visible" : "back-top"}><FontAwesomeIcon icon={faAngleDoubleUp} size="lg" title={t('a11y:scroll-to-top')} aria-labelledby={undefined}></FontAwesomeIcon></Button>
    </div >

  return { MenuComponent, activeMenu };
};

