import React, { useState, useRef, Suspense, useEffect } from "react";
import { connect } from 'react-redux'
import { isPending, getRequest, getOutputOrDefault, getOutput, getPagination } from "../../reducers"
import { postData, getDataApi, postDataApi, getDataAnc, postDataAnc, getDataDlibra, requestSuccess } from '../../actions'
import { useTranslation } from 'react-i18next'
import { ListGroup, Collapse, Spinner, Button } from "react-bootstrap"
import {
    API_URL_POSTFIX,
    API_ALBUM_GET_ITEMS_ID_PREFIX,
    API_ALBUM_GET_ITEMS_URL,
    TAXONDB_DETAILS_REQUEST_URL,
    ICONDB_DETAILS_REQUEST_URL,
    PROJECTS_GET_OBSERVATION_URL,
    API_FILTER_GET_ITEMS_URL,
    API_ALBUM_DELETE_ITEM_ID,
    API_ALBUM_DELETE_ITEM_URL,
    API_ALBUM_PRESENTATION_URL,
    API_ALBUM_EDIT_ITEM_ID,
    API_ALBUM_EDIT_ITEM_URL,
    SAMPLESDB_DETAILS_REQUEST_URL
} from '../../settings'
import "../../styles/albums.scss"
import ConnectedPagination from '../ConnectedPagination'
import InnerItemsList from './ItemsList'
import TaxonDBRecordDetailsContainer from '../taxonDB/RecordDetailsContainer'
import ObservationRecordDetailsContainer from '../projects/RecordDetailsContainer'
import IconDBRecordDetailsContainer from '../iconDB/RecordDetailsContainer'
import FiltersetItemsList from './FiltersetItemsList'
import SampleRecordDetailsContainer from '../samplesDB/RecordDetailsContainer'
import EditItem from '../profile/EditItem'
import ChangeItemAlbum from "./ChangeItemAlbum";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV, faPencilAlt, faTrash, faTv, faExchangeAlt } from '@fortawesome/free-solid-svg-icons'
import * as notify from '../../utils/notify'
import Confirm from '../Confirm'
import { Link } from "react-router-dom"

import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import ListGroupItemDiv from "../ListGroupItemDiv";

const DragHandle = SortableHandle(() => <>
    <span className="drag-handle">
        <FontAwesomeIcon icon={faEllipsisV} size="lg" />
        <FontAwesomeIcon icon={faEllipsisV} size="lg" />
    </span>
</>);

const SortableItem = SortableElement(({ value, props }) => {
    const { t } = useTranslation([ 'profile', 'common', 'a11y' ])
    const requestId = API_ALBUM_GET_ITEMS_ID_PREFIX + value.kind + '-' + value.id

    const [ open, setOpen ] = useState(false)
    const [ editItemOpen, setEditItemOpen ] = useState(false)
    const [ changeItemAlbumOpen, setChangeItemAlbumOpen ] = useState(false)

    useEffect(() => {
        if (value.kind === 'album' && props.path && props.path.length > 0 && value.id === props.path[ 0 ]) {
            setOpen(true)
        }
    }, [ value, props ])

    const toggleOpen = () => {
        const tmp = !open
        setOpen(tmp)
        if (tmp) {
            switch (value.kind) {
                case 'album': props.getAlbumItems(value.id, requestId)
                    break
                case 'icon': props.getIconDetails(value.name, requestId)
                    break
                case 'taxon': props.getTaxonDetails(value.name, requestId)
                    break
                case 'observation': props.getObservationDetails(value.name, requestId)
                    break
                case 'filter': props.getFilterItems(value.reference_id, requestId, t)
                    break
                case 'sample': props.getSampleDetails(value.name, requestId, t)
                    break
                default:
                    break
            }
        } else {
            if (editItemOpen) {
                setEditItemOpen(false)
            }

            if (changeItemAlbumOpen) {
                setChangeItemAlbumOpen(false)
            }
        }
    }

    const toggleEditItemOpen = () => {
        setEditItemOpen(!editItemOpen)
    }

    const toggleChangeItemAlbumOpen = () => {
        setChangeItemAlbumOpen(!changeItemAlbumOpen)
    }

    return (
        <div key={requestId}>
            <ListGroupItemDiv className="album-element album-element-bar" onClick={e => { toggleOpen() }}>
                <div className="d-flex flex-row align-items-center">
                    <div className="d-flex flex-row align-items-center flex-grow-1 inner-panel" onClick={e => { toggleOpen() }}>
                        <div className="flex-grow-1"
                            aria-controls={requestId}
                            aria-expanded={open}>
                            {open &&
                                <img src="/images/list-open.svg" alt={t("a11y:list-opened")} />
                            }
                            {!open &&
                                <img src="/images/list-closed.svg" alt={t("a11y:list-closed")} />
                            }
                            {!props.viewedBy &&
                                <span className="pr-3">
                                    <DragHandle />
                                </span>
                            }
                            <span className="pr-3">
                                {value.kind === 'album' &&
                                    <img src="/images/amuZasób 4@4x-100.jpg" alt={t('a11y:icon-album')} width="21px" />
                                }
                                {value.kind === 'icon' &&
                                    <img src="/images/amuZasób 6@4x-100.jpg" alt={t('a11y:icon-dlibra')} width="21px" />
                                }
                                {value.kind === 'taxon' &&
                                    <>
                                        <img src="/images/amuZasób 5@4x-100.jpg" alt={t('a11y:icon-taxon')} width="31px" />
                                    </>
                                }
                                {value.kind === 'observation' &&
                                    <img src="/images/amuZasób 3@4x-100.jpg" alt={t('a11y:icon-observation')} width="21px" />
                                }
                                {value.kind === 'filter' &&
                                    <img src="/images/amuZasób 1@4x-100.jpg" alt={t('a11y:icon-filter')} width="21px" />
                                }
                                {value.kind === 'sample' &&
                                    <img src="/images/amuZasób 2@4x-100.jpg" alt={t('a11y:icon-sample')} width="21px" />
                                }
                            </span>
                            {value.name}
                            {value.custom_name && value.custom_name.length > 0 &&
                                <span className="itemlist-itemlabel">{' (' + value.custom_name + ')'}</span>
                            }
                        </div>
                    </div>
                    {!props.viewedBy &&
                        <Button variant="secondary" size="sm" className="mr-1" onClick={e => { toggleEditItemOpen() }}>
                            <FontAwesomeIcon icon={faPencilAlt} title={t('profile:edit-item')} />
                        </Button>
                    }
                    {!props.viewedBy &&
                        <Button variant="secondary" size="sm" className="mr-1" onClick={e => { toggleChangeItemAlbumOpen() }}>
                            <FontAwesomeIcon icon={faExchangeAlt} title={t('profile:change-item-album')} />
                        </Button>
                    }
                    {(value.kind === 'album') &&
                        <Link className="btn btn-sm btn-secondary mr-1" to={API_ALBUM_PRESENTATION_URL + value.id + (props.viewedBy ? ("/" + props.viewedBy) : "")}>
                            <FontAwesomeIcon icon={faTv} title={t('profile:presentation')} />
                        </Link>
                    }
                    {!props.viewedBy &&
                        <Confirm
                            onConfirm={e => {
                                props.removeItem(value, props.state, t)
                            }}
                            size="sm"
                            pending={props.removingItems}
                            disabled={props.loadingItems || props.removingItems}
                            buttonLabel={<FontAwesomeIcon icon={faTrash} title={t('profile:remove-from-album')} />}
                            confirmButtonLabel={t('profile:remove-from-album')}
                            confirmationText={t('profile:confirm-remove-from-album')}
                        ></Confirm>
                    }
                </div>
            </ListGroupItemDiv>
            <Suspense fallback={<div className="align-self-center m-3"><Spinner animation="border" role="status" /></div>}>
                {editItemOpen &&
                    <div className="d-flex flex-row ml-4">
                        <EditItem requestId={props.requestId} item={value} onDone={(hasReloadPage) => {
                            toggleEditItemOpen()
                            if (hasReloadPage) {
                                props.onListOrderChanged()
                            }
                        }}></EditItem>
                    </div>
                }
            </Suspense>
            <Suspense fallback={<div className="align-self-center m-3"><Spinner animation="border" role="status" /></div>}>
                {changeItemAlbumOpen &&
                    <div className="d-flex flex-row ml-4">
                        <ChangeItemAlbum requestId={props.requestId} item={value} onDone={(hasReloadPage) => {
                            toggleChangeItemAlbumOpen()
                            if (hasReloadPage) {
                                props.onListOrderChanged()
                            }
                        }}></ChangeItemAlbum>
                    </div>
                }
            </Suspense>
            <Collapse in={open} id={requestId}>
                <div>
                    <Suspense fallback={<div className="align-self-center m-3"><Spinner animation="border" role="status" /></div>}>
                        {value.kind === 'album' &&
                            <InnerItemsList requestId={requestId} albumId={value.id} path={props.path.filter((obj, indx) => indx !== 0)} viewedBy={props.viewedBy}></InnerItemsList>
                        }
                        {value.kind === 'icon' &&
                            <IconDBRecordDetailsContainer noheader requestId={requestId} recordId={value.name}></IconDBRecordDetailsContainer>
                        }
                        {value.kind === 'taxon' &&
                            <TaxonDBRecordDetailsContainer requestId={requestId} recordId={value.name}></TaxonDBRecordDetailsContainer>
                        }
                        {value.kind === 'observation' &&
                            <ObservationRecordDetailsContainer requestId={requestId} recordId={value.name}></ObservationRecordDetailsContainer>
                        }
                        {value.kind === 'filter' &&
                            <FiltersetItemsList requestId={requestId}></FiltersetItemsList>
                        }
                        {value.kind === 'sample' &&
                            <SampleRecordDetailsContainer requestId={requestId} recordId={value.name} />
                        }
                    </Suspense>
                </div>
            </Collapse>
        </div>
    )
})

const PrevPageItem = SortableElement(() => {
    return (
        <div><hr /></div>
    )
})

const NextPageItem = SortableElement(() => {
    return (
        <div><hr /></div>
    )
})

const SortableList = SortableContainer(({ props }) => {
    const hasPrevPage = () => {
        return props.pagination && props.pagination.currentPage > 1
    }

    const hasNextPage = () => {
        return props.pagination && (props.pagination.currentPage < props.pagination.totalPages)
    }

    return (
        <ListGroup>
            {hasPrevPage() &&
                <PrevPageItem index={0} />
            }
            {props.items.map((value, index) => (
                <SortableItem key={`item-${value.id}`} index={index + 1} value={value} props={props} />
            ))}
            {hasNextPage() &&
                <NextPageItem index={props.items.length + 1} />
            }
        </ListGroup>
    );
});

const ItemsList = props => {
    const { t } = useTranslation([ 'profile', 'common' ])

    const paginationRef = useRef()

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex === newIndex) {
            return
        }

        const item = props.items[ oldIndex - 1 ]
        const newSortOrder = newIndex - 1 < 0 ?
            props.items[ 0 ].sort_order - 1 :
            newIndex >= props.items.length ?
                props.items[ props.items.length - 1 ].sort_order + 1 :
                props.items[ newIndex - 1 ].sort_order
        Promise.all([
            props.moveItems(oldIndex - 1, newIndex - 1, props.state),
            props.editItem(item.id, item.custom_name, item.description, newSortOrder, item.is_public, t)
        ]).then(() => {
            if (newIndex - 1 < 0) {
                paginationRef.current.prevPage()
            } else if (newIndex >= props.items.length) {
                paginationRef.current.nextPage()
            } else {
                paginationRef.current.reloadPage()
            }
        })
    }

    const onListOrderChanged = () => {
        paginationRef.current.reloadPage()
    }

    return (
        <div className="d-flex flex-column">
            {props.loadingItems &&
                <div className="align-self-center m-5">
                    <Spinner animation="border" role="status" />
                </div>
            }
            {!props.loadingItems && props.items.length === 0 &&
                <div className="align-self-center p-3">
                    {t('album-is-empty')}
                </div>
            }
            {!props.loadingItems && props.items.length > 0 &&
                <div className={props.albumId ? 'ml-4' : ''}>
                    <ConnectedPagination requestId={props.requestId} url={API_URL_POSTFIX + API_ALBUM_GET_ITEMS_URL} customFilter={{ album_id: props.albumId }} ref={paginationRef}></ConnectedPagination>
                    <SortableList props={{ ...props, onListOrderChanged }} useDragHandle={true} onSortEnd={onSortEnd} lockAxis={"y"} />
                    <div>
                        <ConnectedPagination last requestId={props.requestId} url={API_URL_POSTFIX + API_ALBUM_GET_ITEMS_URL} customFilterName="album_id" customFilterValue={props.albumId}></ConnectedPagination>
                    </div>
                </div>
            }
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    loadingItems: isPending(ownProps.requestId, state),
    removingItems: isPending(API_ALBUM_DELETE_ITEM_ID, state),
    items: getOutputOrDefault(ownProps.requestId, state, { items: [] })[ 'items' ],
    pagination: getPagination(ownProps.requestId, state),
    state: state
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    getAlbumItems: (albumId, requestId) => dispatch(postDataApi(
        requestId,
        API_ALBUM_GET_ITEMS_URL,
        { album_id: albumId, someone_id: ownProps.viewedBy })),
    getIconDetails: (recordId, requestId) => dispatch(getDataDlibra(requestId, ICONDB_DETAILS_REQUEST_URL + recordId + '/')),
    getTaxonDetails: (recordId, requestId) => dispatch(getDataAnc(requestId, TAXONDB_DETAILS_REQUEST_URL + recordId + '/')),
    getSampleDetails: (recordId, requestId) => dispatch(postDataAnc(requestId, SAMPLESDB_DETAILS_REQUEST_URL, { id: recordId })),
    getObservationDetails: (recordId, requestId) => dispatch(getDataApi(requestId, `${PROJECTS_GET_OBSERVATION_URL}${recordId}/`)),
    getFilterItems: (recordId, requestId, t) => dispatch(postDataApi(
        requestId, API_FILTER_GET_ITEMS_URL, { filter_id: recordId }
    )).catch(() => {
        notify.error(t('profile:cannot-load-filterset-items-definition-removed'))
    }),
    removeItem: (item, state, t) => dispatch(postDataApi(
        API_ALBUM_DELETE_ITEM_ID,
        API_ALBUM_DELETE_ITEM_URL,
        { item_id: item.id }
    )).then(() => {
        const request = getRequest(ownProps.requestId, state)
        dispatch(postData(ownProps.requestId, request.url, request.data))
        notify.success(t('profile:item-removed-from-album'))
    }).catch((res) => {
        notify.error(t('profile:cannot-remove-item-from-album'), res.result)
    }),
    editItem: (id, customName, description, sortOrder, isPublic, t) => {
        return dispatch(postDataApi(API_ALBUM_EDIT_ITEM_ID, API_ALBUM_EDIT_ITEM_URL, {
            item_id: id,
            custom_name: customName,
            description: description,
            sort_order: sortOrder,
            is_public: isPublic,
        })).catch(res => {
            notify.error(t('profile:cannot-edit-item'), res.result)
        })
    },
    moveItems: (oldIndex, newIndex, state) => {
        const arrayMoveMutate = (array, from, to) => {
            const startIndex = to < 0 ? array.length + to : to
            const item = array.splice(from, 1)[ 0 ]
            array.splice(startIndex, 0, item)
        };

        const arrayMove = (array, from, to) => {
            array = array.slice();
            if (to < 0 || to >= array.length) {
                array.splice(from, 1)
            } else {
                arrayMoveMutate(array, from, to)
            }
            return array
        };

        const output = getOutput(ownProps.requestId, state)
        const items = arrayMove(output.items, oldIndex, newIndex)
        return dispatch(requestSuccess(ownProps.requestId, { ...output, items: items }))
    }
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ItemsList)
