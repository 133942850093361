import React, { useState } from "react"
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { isPending, getOutputOrDefault } from "../../reducers"
import { Form, Button, Col } from "react-bootstrap"
import {
    PROTECTION_SOURCES_GET_ALL_ID
} from '../../settings'

const SearchProtectedArea = props => {
    const { t } = useTranslation(['protectedArea', 'forms', 'common'])

    const [type, setType] = useState('all')

    const onTypeChanged = e => {
        setType(e.target.value)
    }

    const [name, setName] = useState('')

    const onNameChanged = e => {
        setName(e.target.value)
    }

    const [orderBy, setOrderBy] = useState('name')

    const onOrderByChanged = e => {
        setOrderBy(e.target.value)
    }

    const [ignoreDates, setIgnoreDates] = useState(false);

    const onIgnoreDatesChanged = (e) => {
        setIgnoreDates(e.target.checked);
    }

    const onClearForm = () => {
        setType('all')
        setName('')
        setOrderBy('name')
        setIgnoreDates(false)
    }

    const onSubmitForm = () => {
        props.onSearch && props.onSearch(
            type === 'all'? null : type,
            name.trim() === ''? null : name.trim(),
            orderBy, ignoreDates
        )
    }

    return (
        <Form className="mt-2 p-1 ml-4 mb-2" onSubmit={e => { e.preventDefault() }}>
            <Form.Row>
                <Col>
                    <Form.Group>
                        <Form.Label htmlFor="type">{t('protectedArea:type')}</Form.Label>
                        <Form.Control id="type" as="select" value={type} onChange={onTypeChanged} disabled={props.querying}>
                            <option value="all">{t('common:all')}</option>
                            <option value="rezerwat przyrody">{t('protectedArea:sanctuary')}</option>
                            <option value="park narodowy">{t('protectedArea:park')}</option>
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label htmlFor="name">{t('protectedArea:name')}</Form.Label>
                        <Form.Control id="name" value={name} onChange={onNameChanged} disabled={props.querying}></Form.Control>
                    </Form.Group>
                </Col>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} xs={6}>
                    <Form.Label htmlFor="orderBy">{t('protectedArea:orderBy')}</Form.Label>
                    <Form.Control id="orderBy" as="select" value={orderBy} onChange={onOrderByChanged} disabled={props.querying}>
                        <option value="name">{t('protectedArea:name')}</option>
                        <option value="id">{t('protectedArea:id')}</option>
                        <option value="count">{t('protectedArea:count')}</option>
                        <option value="date">{t('protectedArea:date')}</option>
                    </Form.Control>
                </Form.Group>
                <Form.Group as={Col} xs={3} className="d-flex align-items-center">
                    <Form.Check id="ignore-dates" type="checkbox" defaultChecked={ignoreDates} onChange={onIgnoreDatesChanged} label={t('protectedArea:ignore-dates')} className="ml-4 mt-4" />
                </Form.Group>
                <Form.Group as={Col} xs={3} className="d-flex align-self-end">
                    <div className="flex-grow-1" />
                    <Button variant="primary" onClick={onSubmitForm} disabled={props.querying}>
                        {t('common:search')}
                    </Button>
                    <Button variant="secondary" className="ml-2" onClick={onClearForm} disabled={props.querying} >
                        {t('common:clear')}
                    </Button>
                </Form.Group>
            </Form.Row>
        </Form>
    )
}

const mapStateToProps = state => ({
    querying: isPending(PROTECTION_SOURCES_GET_ALL_ID, state),
    protectionSources: getOutputOrDefault(PROTECTION_SOURCES_GET_ALL_ID, state, { items: []})['items']
})

const mapDispatchToProps = (dispatch, ownProps) => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchProtectedArea)