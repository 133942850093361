import React, { useState } from "react"
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { isPending } from "../../reducers"
import { postDataApi } from '../../actions'
import { Form, Button } from "react-bootstrap"
import {
    API_TEAM_ADD_TEAM_ID,
    API_TEAM_ADD_TEAM_URL,
    API_TEAM_GET_TEAMS_ID,
    API_TEAM_GET_TEAMS_URL
} from '../../settings'
import * as notify from '../../utils/notify'

const CreateTeam = props => {
    const { t } = useTranslation([ 'team' ])
    const { user } = props

    const [ newTeamName, setNewTeamName ] = useState('')

    const onNewTeamNameChange = e => {
        setNewTeamName(e.target.value)
    }

    const onSubmitForm = () => {
        props.createTeam(newTeamName, user.data.username, t).then(() => {
            setNewTeamName('')
            props.onDone && props.onDone()
        })
    }

    return (
        <Form className="mt-2 common-panel-background p-2" onSubmit={e => { e.preventDefault() }}>
            <Form.Group>
                <Form.Label htmlFor="newTeamName">{t('team:team-name')}</Form.Label>
                <Form.Control id="newTeamName" size="sm" value={newTeamName} onChange={onNewTeamNameChange} disabled={props.querying}></Form.Control>
            </Form.Group>
            <div className="text-right">
                <Button size="sm" onClick={onSubmitForm} disabled={props.querying || newTeamName.trim().length === 0}>
                    {t('team:create-team')}
                </Button>
            </div>
        </Form>
    )
}

const mapStateToProps = state => ({
    querying: isPending(API_TEAM_ADD_TEAM_ID, state),
    requests: state.requests,
    user: state.user
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    createTeam: (newTeamName, adminName, t) => {
        return dispatch(postDataApi(API_TEAM_ADD_TEAM_ID, API_TEAM_ADD_TEAM_URL, {
            team_name: newTeamName,
            admin_name: adminName
        })).then(() => {
            notify.success(t('team:created-team'))
            return dispatch(postDataApi(API_TEAM_GET_TEAMS_ID, API_TEAM_GET_TEAMS_URL))
        }).catch(res => {
            notify.error(t('team:cannot-create-team'), res.result)
        })
    }
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateTeam)
