import React, { useState } from "react";
import {Chart, Bar, Line} from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';

function CollectionChart(props){

    Chart.plugins.unregister([ChartDataLabels]);
    const { t } = useTranslation(['reports'])
    const [mobileDevice] = useState(window.screen.width < 700 ? true : false)

    const NHC_BOT_CONST = 'NHC-BOT'
    const NHC_ZOO_CONTS = 'NHC-ZOO'
    const TOTAL_IMPORTS_CONST = 'TOTAL-IMPORTS'
    const CHART_TYPE_LINE = 'line'

    const options = {
        maintainAspectRatio : true,
        responsive: true,
        title: {
            display: mobileDevice ? false : true,
            text: props.charts.chartType === CHART_TYPE_LINE ? t('collection-chart-accumulative') : t('collection-chart-differential'),
            fontSize: 20
        },
        elements: {
            line: {
                tension: 0, 
            }
        },
        scales: {
            xAxes: [{
                type: 'time',
                distribution: props.charts.timeDistribution,
                offset: true,
                ticks:{
                    autoSkip: true,
                    maxTicksLimit: mobileDevice ? 4 : 8,
                    source: 'data',
                },
                time: {
                unit: "week",
                displayFormats: {
                        hour: 'YYYY-MM-DD HH:MM:SS',
                        day: 'YYYY-MM-DD',
                        week: 'YYYY-MM-DD',
                        month: 'YYYY-MM',
                        year: 'YYYY'
                    }
                }
            }],
            yAxes: [{
                ticks:{
                    autoSkip: true,
                    maxTicksLimit: mobileDevice ? 8 : 15,
                },
                display: true,
                stacked: false
            }]
        }
    };

    var dataChart

    let labels = [ 
        { backgroundColor: '#798B2D', borderColor: '#6C7B28' }, 
        { backgroundColor: '#C76300', borderColor: '#cc6600' }, 
        { backgroundColor: '#3377FF', borderColor: '#000066' },
        { backgroundColor: '#7B6F6F', borderColor: '#5B5757' },
        { backgroundColor: '#008A2E', borderColor: '#009900' },
        { backgroundColor: '#85549C', borderColor: '#622F79' },
        { backgroundColor: '#9B596B', borderColor: '#71414E' },
        { backgroundColor: '#383635', borderColor: '#1c1b1b' },
        { backgroundColor: '#577794', borderColor: '#3A6182' }, 
        { backgroundColor: '#db2414', borderColor: '#ad190c' }
    ]

    let datasets = [];

    if (props.charts.displayedCollection && props.charts.displayedCollection === NHC_BOT_CONST && props.charts.botanicalCollectionNames){
        if (props.charts.NHC_BOT && props.charts.botanicalCollectionNames.size){    
            let arr = Array.from(props.charts.botanicalCollectionNames)
            arr.forEach((item, indx) => {
                
                let items = []
                let increments = []

                props.charts.NHC_BOT.get(item).forEach((itm) => {
                    items.push(itm[2])
                })

                items.forEach((item, index) => {
                    if (index > 0){
                        increments.push(item - items[index-1])
                    }
                })

                datasets.push({
                    steppedLine: props.charts.chartType === CHART_TYPE_LINE ? true : false,  
                    label: item,
                    backgroundColor: props.charts.chartType === CHART_TYPE_LINE ? "" : labels[indx]["backgroundColor"],
                    borderColor: labels[indx]["borderColor"],
                    data:  props.charts.chartType === CHART_TYPE_LINE ? items : increments,
                    fill: props.charts.chartType === CHART_TYPE_LINE ? false : true
                })
    
            })

            dataChart = {
                labels: props.charts.chartType === CHART_TYPE_LINE ? props.charts.dates_NHC_BOT : props.charts.dates_NHC_BOT.slice(1),
                datasets: datasets
            }
        }
        else {
            dataChart = {
                labels: [],
                datasets: [{
                    backgroundColor: 'gray',
                    borderColor: 'gray',
                    data: []
                }]
            }
        }
    }
    else if (props.charts.displayedCollection && props.charts.displayedCollection === NHC_ZOO_CONTS){
        if (props.charts.NHC_ZOO && props.charts.zoologicalCollectionNames.size){
            let arr = Array.from(props.charts.zoologicalCollectionNames)
            arr.forEach((item, indx) => {
                
                let items = []
                let increments = []

                props.charts.NHC_ZOO.get(item).forEach((itm) => {
                    items.push(itm[2])
                })
                
                items.forEach((item, index) => {
                    if (index > 0){
                        increments.push(item - items[index-1])
                    }
                })

                datasets.push({
                    steppedLine: props.charts.chartType === CHART_TYPE_LINE ? true : false, 
                    label: item,
                    backgroundColor: props.charts.chartType === CHART_TYPE_LINE ? "" : labels[indx]["backgroundColor"],
                    borderColor: labels[indx]["borderColor"],
                    data: props.charts.chartType === CHART_TYPE_LINE ? items : increments,
                    fill: props.charts.chartType === CHART_TYPE_LINE ? false : true
                })   

            })

            dataChart = {
                labels: props.charts.chartType === CHART_TYPE_LINE ? props.charts.dates_NHC_ZOO : props.charts.dates_NHC_ZOO.slice(1),
                datasets: datasets
            }
        }
        else {
            dataChart = {
                labels: [],
                datasets: [{
                    backgroundColor: 'gray',
                    borderColor: 'gray',
                    data: []
                }]
            }
        }
    }
    else if(props.charts.displayedCollection && props.charts.displayedCollection === TOTAL_IMPORTS_CONST){

        let bot = []
        let zoo = []
        let dates = new Set();

        if (props.charts.totalImports.NHC_ZOO_total && props.charts.totalImports.NHC_BOT_total){
            props.charts.totalImports.NHC_ZOO_total.forEach(item => {
                zoo.push(item[1])
                dates.add(item[0])
            })

            props.charts.totalImports.NHC_BOT_total.forEach(item => {
                bot.push(item[1])
                dates.add(item[0])
            })

            let botanicallIncrements = [];
            let zoologicalIncrements = [];

            bot.forEach((item, index) => {
                if (index > 0){
                    botanicallIncrements.push(item - bot[index-1])
                }
            })

            zoo.forEach((item, index) => {
                if (index > 0){
                    zoologicalIncrements.push(item - zoo[index-1])
                }
            })
        
            datasets.push(
            {
                steppedLine: props.charts.chartType === CHART_TYPE_LINE ? true : false,
                label: NHC_BOT_CONST,
                backgroundColor: props.charts.chartType === CHART_TYPE_LINE ? "" : labels[1]["backgroundColor"],
                borderColor: labels[1]["borderColor"],
                data: props.charts.chartType === CHART_TYPE_LINE ? bot :botanicallIncrements,
                fill: props.charts.chartType === CHART_TYPE_LINE ? false : true
            },
            { 
                steppedLine: props.charts.chartType === CHART_TYPE_LINE ? true : false,
                label: NHC_ZOO_CONTS,
                backgroundColor: props.charts.chartType === CHART_TYPE_LINE ? "" : labels[0]["backgroundColor"],
                borderColor: labels[0]["borderColor"],
                data: props.charts.chartType === CHART_TYPE_LINE ? zoo : zoologicalIncrements,
                fill: props.charts.chartType === CHART_TYPE_LINE ? false : true
            })   
            dataChart = {
                labels: props.charts.chartType === CHART_TYPE_LINE ? [...dates.values()] : [...dates.values()].slice(1),
                datasets: datasets
            }
        }
        else {
            dataChart = {
                labels: [],
                datasets: [{
                    backgroundColor: 'gray',
                    borderColor: 'gray',
                    data: []
                }]
            }
        }

    }
    else{
        dataChart = {
            labels: [],
            datasets: [{
                backgroundColor: 'gray',
                borderColor: 'gray',
                data: []
            }]
        }
    }

    let chart

    if(props.charts.chartType === CHART_TYPE_LINE){
        chart = <Line data={dataChart} options={options}/>
    }
    else {
        chart = <Bar data={dataChart} options={options}/> 
    }

    return(
        <div className="chart-container">
            {chart}
        </div>
    )
}

const mapStateToProps = state => ({
    charts : state.charts,
  })

export default connect(
    mapStateToProps
)(CollectionChart)