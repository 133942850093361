import React from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from 'react-i18next'
import { SITEMAP_PAGE_URL_EN, SITEMAP_PAGE_URL_PL } from '../settings'
import { MAIN_MENU, MAIN_MENU2, MY_AMUNATCOLL_MENU } from "./MenuContent";
import { Suspense } from "react";
import I18nPathTranslator from "./I18nPathTranslator";
import Loader from "../utils/loader";
import { Link } from "react-router-dom";
import SEO from "./SEO";


const SiteMap = props => {

    const { t } = useTranslation([ 'common', 'menu', 'paths' ])

    const tOrStr = (str) => {
        if(typeof str === "string")
            if(str.includes(':'))
                return t(str)
            else
                return str
    }

    const SEARCH_MENU = MAIN_MENU.searchEngines.submenu
    const ABOUT_MENU = MAIN_MENU.about.submenu
    const TOOLS_MENU = MAIN_MENU.tools.submenu
    

    return (
        <Suspense fallback={<Loader />}>
        <SEO title={t('common:site-map')} />
        <I18nPathTranslator {...props} pathEN={SITEMAP_PAGE_URL_EN} pathPL={SITEMAP_PAGE_URL_PL} />
        <div>
            <Container>
                <div>
                    <h1>{t('common:site-map')}</h1>
                    <ul className="site-map-list">
                        <li><Link to={tOrStr(MAIN_MENU.home.path)}>{t(MAIN_MENU.home.name)}</Link></li>
                        <li><Link to="#">{t(MAIN_MENU.searchEngines.name)}</Link>
                            <ul>
                                {SEARCH_MENU.map((item, key)=>
                                    <li key={key}><Link to={tOrStr(item.path)}>{t(item.name)}</Link></li>
                                )}
                            </ul>
                        </li>
                        <li><Link to="#">{t(MAIN_MENU.about.name)}</Link>
                            <ul>
                                {ABOUT_MENU.map((item, key)=>
                                    <li key={key}><Link to={tOrStr(item.path)}>{t(item.name)}</Link></li>
                                )}
                            </ul>
                        </li>
                        {TOOLS_MENU.map((arr, idx)=>
                        <li key={idx}>
                            <Link to="#">{t('menu:tools')+' - '+t(arr[0].name)}</Link>
                            <ul>                         
                            {arr.map((item, key)=>
                            key!==0 ? 
                            <li key={key}><Link to={tOrStr(item.path)}>{tOrStr(item.name)}</Link></li> : ""
                            )}
                            </ul>   
                        </li>
                        )}
                        <li><Link to={tOrStr(MAIN_MENU.howToUse.path)}>{t(MAIN_MENU.howToUse.name)}</Link></li>
                        <li><Link to={tOrStr(MAIN_MENU.contact.path)}>{t(MAIN_MENU.contact.name)}</Link></li>
                        <li><Link to="#">{t(MAIN_MENU2.myAmunatcoll.name)}</Link>
                            <ul>
                                {MY_AMUNATCOLL_MENU.map((item, key)=>
                                    <li key={key}><Link to={tOrStr(item.path)}>{t(item.name)}</Link></li>
                                )}
                            </ul>
                        </li>
                    </ul>
                </div>
            </Container>
        </div>
    </Suspense>
    )
}

export default SiteMap
