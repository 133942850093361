import React, { useState, Suspense } from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import * as actions from '../../actions'
import {
    SAMPLESDB_DETAILS_REQUEST_ID_PREFIX,
    SAMPLESDB_SEARCH_REQUEST_URL,
    SAMPLESDB_DETAILS_REQUEST_URL,
    ANC_URL_POSTFIX,
    SAMPLESDB_EXPORTFILE_REQUEST_URL,
    SAMPLESDB_EXPORTFILE_REQUEST_PREFIX,
    SAMPLESDB_EXPORTTEMPLATEFILE_REQUEST_PREFIX,
    SAMPLESDB_EXPORTTEMPLATEFILE_REQUEST_URL
} from '../../settings'
import { getItems, getPagination, isPending } from "../../reducers";
import ConnectedPagination from "../ConnectedPagination"
import { ListGroup, Collapse } from "react-bootstrap"
import RecordDetailsContainer from "./RecordDetailsContainer";
import ReportsExporter from "../ReportsExporter";
import SynonymsInfo from "./SynonymsInfo"
import Loader from '../../utils/loader'
import { NUMER_PROBY_ATTRIBUTE_NAME } from './attributesStructure'

const ResultsList = props => {
    const { t, i18n } = useTranslation([ 'common', 'forms', 'taxondb', 'profile', 'a11y' ])

    const [ open, setOpen ] = useState({})
    const [ isOpened, setIsOpened ] = useState({})
    const [ requestUrl ] = useState(props.requestUrl || ANC_URL_POSTFIX + SAMPLESDB_SEARCH_REQUEST_URL)

    const toggleOpen = (recordId, id) => {
        const temp = { ...open }
        temp[ id ] = !temp[ id ]
        setOpen(temp)
        if (temp[ id ]) {
            props.getDetails(recordId, id)
        }
    }

    return (
        <Suspense fallback={<Loader />}>
            <div>
                {!props.items &&
                    <p>{t('use-form-to-search')}</p>
                }
                {props.items && props.items.length > 0 &&
                    <div>
                        <div className="mb-2 d-flex flex-row flex-wrap justify-content-center">
                            <div className="p-2">
                                {t('samplesdb:nr_of_samples_found')}: <b>{props.pagination.totalCount ? props.pagination.totalCount.toLocaleString(i18n.languages[0]) : '-'}</b>
                            </div>
                        </div>
                        <div className="mb-2 d-flex flex-row flex-wrap">
                            <div className="ml-3">
                                <ReportsExporter
                                    prefix={SAMPLESDB_EXPORTFILE_REQUEST_PREFIX}
                                    templatePrefix={SAMPLESDB_EXPORTTEMPLATEFILE_REQUEST_PREFIX}
                                    requestId={props.requestId}
                                    url={SAMPLESDB_EXPORTFILE_REQUEST_URL}
                                    templateUrl={SAMPLESDB_EXPORTTEMPLATEFILE_REQUEST_URL}>
                                </ReportsExporter>
                            </div>
                        </div>
                        <ConnectedPagination requestId={props.requestId} url={requestUrl} requestInterceptor={props.requestInterceptor}></ConnectedPagination>
                        <ListGroup>
                            {props.items.map(val => {
                                const requestId = SAMPLESDB_DETAILS_REQUEST_ID_PREFIX + val[ NUMER_PROBY_ATTRIBUTE_NAME ]
                                return (
                                    <div key={requestId}>
                                        <ListGroup.Item tabIndex="0" action className="d-flex flex-row album-element align-items-center" onClick={() => toggleOpen(val[ NUMER_PROBY_ATTRIBUTE_NAME ], requestId)}>
                                            <div className="d-flex flex-grow-1 align-items-center">
                                                <div>
                                                    {open[ requestId ] &&
                                                        <img src="/images/list-open.svg" alt={t("a11y:list-opened")} />
                                                    }
                                                    {!open[ requestId ] &&
                                                        <img src="/images/list-closed.svg" alt={t("a11y:list-closed")} />
                                                    }
                                                </div>
                                                {val[ 'images' ] &&
                                                    <div className="mx-2">
                                                        <img src="/images/amuZasób 6@4x-100.jpg" alt={t('a11y:icon-dlibra')} width="21px" />
                                                    </div>
                                                }
                                                <div className="mr-1">{val[ NUMER_PROBY_ATTRIBUTE_NAME ]}</div>
                                            </div>
                                        </ListGroup.Item>
                                        <Collapse in={open[ requestId ]} id={requestId}
                                            onEntering={() => setIsOpened({ ...isOpened, [ requestId ]: true })}
                                            onExited={() => setIsOpened({ ...isOpened, [ requestId ]: false })}
                                        >
                                            <div>
                                                {(open[ requestId ] || isOpened[ requestId ]) &&
                                                    <div className="d-flex flex-column">
                                                        <RecordDetailsContainer requestId={requestId} recordId={val[ NUMER_PROBY_ATTRIBUTE_NAME ]}></RecordDetailsContainer>
                                                    </div>
                                                }
                                            </div>
                                        </Collapse>
                                    </div>
                                )
                            })}
                        </ListGroup>
                        <div>
                            <ConnectedPagination last requestId={props.requestId} url={requestUrl} requestInterceptor={props.requestInterceptor}></ConnectedPagination>
                        </div>
                    </div>
                }
                {props.items && !props.items.length > 0 &&
                    <>
                        <p>{t('no-records-found')}</p>
                        <SynonymsInfo></SynonymsInfo>
                    </>
                }
            </div>
        </Suspense>
    )
}

const mapStateToProps = (state, ownProps) => ({
    items: getItems(ownProps.requestId, state),
    loading: isPending(ownProps.requestId, state),
    pagination: getPagination(ownProps.requestId, state),
    user: state.user ? state.user.data : null,
})

const mapDispatchToProps = dispatch => ({
    getDetails: (recordId, requestId) => dispatch(actions.postDataAnc(requestId, SAMPLESDB_DETAILS_REQUEST_URL, { id: recordId })),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResultsList)
