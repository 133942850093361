import React from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { setFields, runSearchFlowBiblio } from '../../actions/forms'
import { requestRemove } from '../../actions'
import { isPending, getFields, prepareFilter } from "../../reducers"
import { Button } from "react-bootstrap"

const SearchButtons = props => {
    const { t } = useTranslation([ 'common', 'taxondb', 'taxondb-attributes' ])

    const clearAllFields = obj => {
        Object.keys(obj).forEach(val => {
            if (val.startsWith('or_')) {
                obj[ val ].forEach(val2 => {
                    clearAllFields(val2)
                })
            } else if (val.startsWith('not_')) {
                clearAllFields(obj[ val ])
            } else {
                obj[ val ] = ''
            }
        })
    }

    const clearForm = e => {
        const temp = { ...props.fields }
        clearAllFields(temp)
        props.setFields(temp)
        props.clearResponse()
    }

    const submitForm = e => {
        e.preventDefault()
        props.runSearchFlow()
        if (props.onSearch) {
            props.onSearch()
        }
    }

    return (
        <>
            <Button variant="primary" className="search-button" onClick={submitForm} disabled={props.loading/* || Object.keys(props.filter).length === 0*/}>
                {t('search')}
            </Button>
            <Button variant="secondary" className="ml-2 clear-button" onClick={clearForm} disabled={props.loading} >
                {t('clear')}
            </Button>
        </>
    )
}

const mapStateToProps = (state, ownProps) => ({
    loading: isPending(ownProps.requestId, state),
    fields: getFields(ownProps.requestId, state),
    filter: prepareFilter(ownProps.requestId, state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    setFields: val => dispatch(setFields(ownProps.requestId, val)),
    runSearchFlow: () => dispatch(runSearchFlowBiblio(ownProps.requestId)),
    clearResponse: () => dispatch(requestRemove(ownProps.requestId)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchButtons)
