import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux'
import { DLIBRA_IIIF_URL } from '../../settings'
import { Button, Dropdown, DropdownButton, ButtonToolbar } from "react-bootstrap"
import { useTranslation } from 'react-i18next'
import OpenSeadragon from 'openseadragon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo, faArrowLeft, faArrowRight, faSearchMinus, faSearchPlus, faDesktop } from '@fortawesome/free-solid-svg-icons'
import * as actions from '../../actions'
import { isPending, getOutput } from "../../reducers";
import { DOWNLOADIMAGE_REQUEST_ID, DLIBRA_IMAGE_JPG_MAX_WIDTH, API_URL_POSTFIX } from '../../settings'
import RedirectLoginWithReturn from '../access/RedirectLoginWithReturn'
import { get_axios } from '../../utils/ajax';
import * as notify from '../../utils/notify'

const SingleDlibraImage = props => {
    const { t, i18n } = useTranslation(['common'])
    const [currentImage, setCurrentImage] = useState(1)
    const { requestId, onImageChange } = props
    const images = useRef(props.data.images)
    const lastPage = useRef(null)

    useEffect(() => {
        if (images.current.length > 0) {
            const urls = images.map(val => DLIBRA_IIIF_URL + val[0] + '-' + val[1] + '/info.json')
            console.log("Image URLs:", urls)

            // see: https://openseadragon.github.io/docs/OpenSeadragon.html#.Options
            const osd = OpenSeadragon({
                id: 'iiif-' + requestId,
                prefixUrl: "/openseadragon/images/",
                showNavigator: true,
                preserveViewport: true,
                //visibilityRatio: 1,
                sequenceMode: true,
                crossOriginPolicy: false,
                ajaxWithCredentials: false,
                tileSources: urls.map(tileSource => ({
                    tileSource,
                    ajaxHeaders: { "Authorization": "Bearer " + localStorage.getItem('access') }
                })),
                homeButton: 'reset' + requestId,
                zoomInButton: 'zoom-in' + requestId,
                zoomOutButton: 'zoom-out' + requestId,
                fullPageButton: 'full-screen' + requestId,
                nextButton: "next-picture" + requestId,
                previousButton: "previous-picture" + requestId,
                loadTilesWithAjax: true,
            })
            osd.addHandler('page', function (event) {
                lastPage.current = event.page
                setCurrentImage(event.page + 1);
                onImageChange && onImageChange(event.page + 1);
            })
            osd.addHandler('open-failed', function (event) {
                if (lastPage.current !== null) {
                    const nextTry = lastPage.current
                    lastPage.current = null
                    get_axios(API_URL_POSTFIX).get('/current_user/').then(res => {
                        osd.open(osd.tileSources.map(val => ({ ...val, ajaxHeaders: { "Authorization": "Bearer " + localStorage.getItem('access') }})), nextTry)
                    }).catch(err => {
                        console.log('err', err)
                    })
                } else {
                    notify.error(i18n.t('common:cannot-load-image'))
                    osd.open(osd.tileSources.map(val => ({ ...val, ajaxHeaders: { "Authorization": "Bearer " + localStorage.getItem('access') }}))
                        .map(v => ((v.tileSource !== event.options.tileSource) ? v : {
                            type: 'image',
                            url: '/images/noimage.png'
                        })
                    ))
                }
            })
        }
    }, [requestId, onImageChange, i18n])

    const [isRedirectLoginWithReturn, setRedirectLoginWithReturn] = useState(false)

    return (
        <div className="mb-1">
            {isRedirectLoginWithReturn &&
                <RedirectLoginWithReturn lang={localStorage.lang} returnUrl={window.location.pathname} />
            }
            {props.data &&
                <div>
                    <h1 className="ml-2"><i>{props.data.rodzaj} {props.data.gatunek}</i></h1>
                    <div>
                        <ButtonToolbar>
                            <DropdownButton size="sm" className="ml-1" id="dropdown-basic-button" title={t('download_image')} disabled={props.downloading}>
                                <Dropdown.Item onClick={() =>
                                    props.user ?
                                        props.downloadImage(
                                            `${DLIBRA_IIIF_URL}${props.data.images[currentImage - 1][0]}-${props.data.images[currentImage - 1][1]}/full//${DLIBRA_IMAGE_JPG_MAX_WIDTH},/0/default.jpg`,
                                            "jpg", props.data.kolekcjanumerokazu
                                        ) : setRedirectLoginWithReturn(true)
                                }
                                >JPG</Dropdown.Item>
                                <Dropdown.Item onClick={() =>
                                    props.user ?
                                        props.downloadImage(
                                            `${DLIBRA_IIIF_URL}${props.data.images[currentImage - 1][0]}-${props.data.images[currentImage - 1][1]}/full//full/0/default.tif`,
                                            "tif", props.data.kolekcjanumerokazu
                                        ) : setRedirectLoginWithReturn(true)
                                }
                                >TIF</Dropdown.Item>
                            </DropdownButton>
                            <Button size="sm" className="ml-2" variant="dark" id={"previous-picture" + props.requestId} disabled={props.loading}>
                                <FontAwesomeIcon icon={faArrowLeft} title={t('PreviousPage')} />
                            </Button>
                            <Button size="sm" className="ml-1" variant="dark" id={"next-picture" + props.requestId} disabled={props.loading}>
                                <FontAwesomeIcon icon={faArrowRight} title={t('NextPage')} />
                            </Button>
                            <Button size="sm" className="ml-1" variant="dark" id={"reset" + props.requestId} disabled={props.loading}>
                                <FontAwesomeIcon icon={faUndo} title={t('Home')} />
                            </Button>
                            <Button size="sm" className="ml-1" variant="dark" id={"zoom-in" + props.requestId} disabled={props.loading}>
                                <FontAwesomeIcon icon={faSearchPlus} title={t('ZoomIn')} />
                            </Button>
                            <Button size="sm" className="ml-1" variant="dark" id={"zoom-out" + props.requestId} disabled={props.loading} >
                                <FontAwesomeIcon icon={faSearchMinus} title={t('ZoomOut')} />
                            </Button>
                            <Button size="sm" className="ml-1" variant="dark" id={"full-screen" + props.requestId} disabled={props.loading} >
                                <FontAwesomeIcon icon={faDesktop} title={t('FullPage')} />
                            </Button>
                        </ButtonToolbar>
                        <div className="ml-2">
                            {t('common:picture_number')} <b>{currentImage}</b>  {t('common:of')} <b>{props.data.images.length}</b>
                        </div>
                    </div>
                </div>
            }
            <div id={'iiif-' + props.requestId} className="openseadragon-container"></div>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    data: getOutput(ownProps.requestId, state),
    user: state.user ? state.user.data : null,
    downloading: isPending(DOWNLOADIMAGE_REQUEST_ID, state),
})


const mapDispatchToProps = dispatch => ({
    downloadImage: (link, imgType, picturename) => dispatch(actions.getDlibraImage(DOWNLOADIMAGE_REQUEST_ID, link, imgType, picturename))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SingleDlibraImage)
