import React, { useState } from "react";
import { connect } from 'react-redux'
import { Col, Badge } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { getDataApi } from "../../actions"
import EditionDetails from "./EditionDetails"
import {
    API_TASK_DETAILS_URL,
    API_TASK_REQUEST_URL,
    API_TASK_REQUEST_PREFIX,
    API_TASK_DETAILS_ID,
} from "../../settings"
import SeeMoreIcon from "../SeeMoreIcon";

const EditionListItem = props => {

    const { t } = useTranslation(['common', 'editions-panel'])
    const [buttonState, setButtonState] = useState({})

    const toggleDetails = (requestId) => {
        const temp = { ...buttonState }
        temp[requestId] = !temp[requestId]
        setButtonState(temp)
        if (temp[requestId]){
            props.getEditionDetails(props.edition.id)
        }
    }

    const renderBadgeForStatus = () => {
        const STATUS_WORKING = 'WORKING'
        const STATUS_INIT = 'INIT'
        const STATUS_QUEUE = 'QUEUE'
        const STATUS_DONE = 'DONE'
        const STATUS_FAILED = 'FAILED'

        let badgeVariant = 'secondary'
        let statusString = t('editions-panel:status-init')

        let variants = {
            STATUS_WORKING : 'primary',
            STATUS_INIT : 'secondary',
            STATUS_DONE : 'success',
            STATUS_QUEUE : 'warning',
            STATUS_FAILED: 'danger'
        }

        switch(props.edition.status){
            case STATUS_WORKING:
                badgeVariant = variants.STATUS_WORKING
                statusString = t('editions-panel:status-working')
                break
            case STATUS_INIT:
                badgeVariant = variants.STATUS_INIT
                statusString = t('editions-panel:status-init')
                break
            case STATUS_DONE:
                badgeVariant = variants.STATUS_DONE
                statusString = t('editions-panel:status-done')
                break
            case STATUS_QUEUE:
                badgeVariant = variants.STATUS_QUEUE
                statusString = t('editions-panel:status-queue')
                break
            case STATUS_FAILED:
                badgeVariant = variants.STATUS_FAILED
                statusString = t('editions-panel:status-failed')
                break
            default:
                badgeVariant = variants.STATUS_INIT
                statusString = t('editions-panel:status-init')
                break
        }

        return (
            <Badge pill className="p-2" style={{"width": "100px"}} variant={badgeVariant}>
                {statusString}
            </Badge>
        )
    }

    return (
        <>
            <div className="d-flex flex-row album-element align-items-center border p-3"
                tabIndex="0"
                onClick={e => toggleDetails(props.edition.id)}
                onKeyDown={e => {if(e.key === "Enter"){toggleDetails(props.edition.id)}}}
                aria-expanded={setButtonState[props.edition.id]}
                aria-controls={buttonState[props.edition.id]}
            >
                <Col xs={1}>
                    <SeeMoreIcon isOpen={buttonState[props.edition.id]} />
                </Col>
                <Col xs={2}>
                    {props.edition.date_created &&
                        props.edition.date_created.slice(0, 10)
                    }
                </Col>
                <Col xs={2}>
                    {props.edition.username}
                </Col>
                <Col xs={3}>
                    {props.edition.params && props.edition.params.field}
                </Col>
                <Col xs={2}>
                    {props.edition.total} 
                    <span style={{color: "red", marginLeft: "0.25em"}}>
                        ({props.edition.incorrect})
                    </span>
                </Col>
                <Col xs={2}>
                    {renderBadgeForStatus()}
                </Col>
            </div>
            {buttonState[props.edition.id] &&
                <EditionDetails edition={props.edition} requestId={props.edition.id} />
            }
        </>
    )
}


const mapStateToProps = state => ({

})
  
const mapDispatchToProps = dispatch => ({
    getEditionDetails: id => dispatch(getDataApi(`${API_TASK_REQUEST_PREFIX}${API_TASK_DETAILS_ID}${id}/`, `${API_TASK_REQUEST_URL}${API_TASK_DETAILS_URL}${id}/`))
})
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditionListItem)
