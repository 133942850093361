import React from "react"
import { connect } from 'react-redux'
import { Container, Tab, Tabs  } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import {
    DB_HISTORY_PAGE_URL_EN,
    DB_HISTORY_PAGE_URL_PL,
} from "../../settings"

import I18nPathTranslator from "../I18nPathTranslator";
import SEO from "../SEO";
import TaxonHistory from "./TaxonHistory";
import SamplesHistory from "./SamplesHistory";
import BiblioHistory from "./BiblioHistory";
import IconHistory from "./IconHistory";

const DBHistory = props => {

    const { t } = useTranslation(['forms', 'menu', 'db-history' ])


    return (
        <Container>
            <I18nPathTranslator {...props} pathEN={DB_HISTORY_PAGE_URL_EN} pathPL={DB_HISTORY_PAGE_URL_PL} />
            <SEO title={t("menu:db-history")} />
            <div className="mt-3 mb-4">
                <h1>
                    {t("menu:db-history")}
                </h1>
            </div>
            <Tabs defaultActiveKey={'taxon'} className="nav-tabs-padding-top">
                <Tab eventKey="taxon" title={t('db-history:taxon-history')}>
                    <TaxonHistory />
                </Tab>
                <Tab eventKey="icon" title={t('db-history:icon-history')}>
                    <IconHistory />
                </Tab>
                <Tab eventKey="samples" title={t('db-history:samples-history')}>
                    <SamplesHistory />
                </Tab>
                <Tab eventKey="biblio" title={t('db-history:biblio-history')}>
                    <BiblioHistory />
                </Tab>
            </Tabs>
        </Container>
    )
}

const mapStateToProps = state => ({
})
  
const mapDispatchToProps = dispatch => ({
})
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DBHistory)