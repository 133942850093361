import React, { Suspense } from "react";
import { connect } from 'react-redux'
import { Container } from "react-bootstrap"
import { useTranslation } from 'react-i18next'
import Loader from '../utils/loader'
import { MISSION_PAGE_URL_EN, MISSION_PAGE_URL_PL } from "../settings";
import I18nPathTranslator from "./I18nPathTranslator";
import SEO from "./SEO";

const MissionContainer = props => {
  const { t } = useTranslation('mission')

  return (
    <Suspense fallback={<Loader />}>
      <I18nPathTranslator {...props} pathEN={MISSION_PAGE_URL_EN} pathPL={MISSION_PAGE_URL_PL} />
      <SEO title="Misja" />
      <div className="mission-page">
        <Container>
          <div>
            <h1>{t('part-1')}</h1>
            <p>{t('part-2')}</p>
            <h2><i>{t('part-3')}</i></h2>
            <p>{t('part-4')}</p>
            <p>{t('part-5')}</p>
            <p>{t('part-6')}</p>
            <h2><i>{t('part-7')}</i></h2>
            <p>{t('part-8')}</p>
            <p>{t('part-9')}</p>
            <p>{t('part-10')}</p>
            <h2><i>{t('part-11')}</i></h2>
            <p>{t('part-12')}</p>
            <p>{t('part-13')}</p>
            <p>{t('part-14')}</p>
            <h2><i>{t('part-15')}</i></h2>
            <p>{t('part-16')}</p>
            <p>{t('part-17')}</p>
            <p>{t('part-18')}</p>
          </div>
        </Container>
      </div>
    </Suspense>
  )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => ({
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MissionContainer)
