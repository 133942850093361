import React, { Suspense, useRef, useState } from "react";
import { getDataApi } from "../../actions"
import { connect } from 'react-redux'
import { isPending } from "../../reducers";
import { useTranslation } from 'react-i18next';
import {
    API_GET_DOWNLOADS_STATS_PER_DAY_ID,
    API_GET_DOWNLOADS_STATS_PER_MONTH_ID,
    API_GET_DOWNLOADS_STATS_PER_DAY_URL,
    API_GET_DOWNLOADS_STATS_PER_MONTH_URL
} from '../../settings'
import { Row, Col, Button, Form, FormLabel, Spinner, Overlay, Popover } from "react-bootstrap";
import { 
    setChartType, 
    setPresentationType, 
    setDaysScope,
    setDisplayedData, 
    setInterval, 
    setMonthlyDownloadsData, 
    setDailyDownloadsData 
} from '../../actions/chart'
import Loader from '../../utils/loader'
import DailyDownloadsChart from "./DailyDownloadsChart";
import MonthlyDownloadsChart from "./MonthlyDownloadsChart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import Calendar from 'react-calendar';

function DownloadsReportsHandler(props) {

    const { t } = useTranslation(['reports'])

    const dateToMonth = date => {
        let formatedDate = date.getFullYear()
        formatedDate += "-"
        let month = date.getMonth()+1
        if(month < 10)
            month = "0"+month
        formatedDate += month
        return formatedDate
    }

    const monthToDate = (yyyyMM) => {
        const pattern = /^[0-9]{4}-[0-9]{2}$/
        if(!pattern.test(yyyyMM))
            return undefined
        const year = yyyyMM.slice(0,4)
        const month = yyyyMM.slice(5,7)-1
        return new Date(year, month)
    }

    const [monthFrom, setMonthFrom] = useState(props.charts.monthFrom ? props.charts.monthFrom : dateToMonth(new Date()) )
    const [monthTo, setMonthTo] = useState(props.charts.monthFrom ? props.charts.monthFrom : dateToMonth(new Date()) )
    const [showFrom, setShowFrom] = useState(false)
    const targetFrom = useRef()
    const [showTo, setShowTo] = useState(false)
    const targetTo = useRef()

    const onMonthFromChange = date => {
        setMonthFrom(dateToMonth(date))
    }

    const onMonthToChange = date => {
        setMonthTo(dateToMonth(date))
    }

    const MONTH_CONST = 'month'
    const DAY_CONST = 'day'
    const CHART_TYPE_NORMAL = 'normal'
    const CHART_TYPE_BAR = 'bar'
    const CHART_TYPE_LINE = 'line'

    const handleFormPresentationTypeChange = e => {
        props.setChartType(e.target.value)
    }

    const handleDateFetchMonthly = (event) => {
        event.preventDefault()
        fetchChartsDataMonths(event.target.elements.dateFrom.value, event.target.elements.dateTo.value)
        props.setInterval({ "interval": MONTH_CONST })
    }

    const handleDateFetchDaily = (event) => {
        event.preventDefault()
        fetchChartsData(event.target.elements.dateFrom.value, event.target.elements.dateTo.value)
        props.setInterval({ "interval": DAY_CONST })
    }

    const fetchChartsData = (dayFrom, dayTo, type) => {
        props.getDownloadsData(
            API_GET_DOWNLOADS_STATS_PER_DAY_ID, API_GET_DOWNLOADS_STATS_PER_DAY_URL +
            dayFrom + "/" + dayTo + "/"
        )
        .then((response) => {            
            let _users = []
            let _number_downloads = []
            let _taxons = []
            let _days = []

            for (let i = 0; i < response.length; i++) {
                _users.push(response[i].users)
                _number_downloads.push(response[i].number_downloads)
                _taxons.push(response[i].taxons)
                _days.push(response[i].day)
            }
            
            const data = {
                "users": _users,
                "number_downloads": _number_downloads,
                "taxons": _taxons,
                "days": _days,
                "interval": DAY_CONST,
                "presentationType": CHART_TYPE_NORMAL,
                "chartType": CHART_TYPE_BAR,
                "dayFrom": dayFrom,
                "dayTo": dayTo
            }
            props.setDailyDownloadsData(data)                
        })
    }

    const fetchChartsDataMonths = (monthFrom, monthTo, type) => {
        props.getDownloadsData(
            API_GET_DOWNLOADS_STATS_PER_MONTH_ID, API_GET_DOWNLOADS_STATS_PER_MONTH_URL +
            monthFrom + "/" + monthTo + "/"
        )
        .then( response => {
            let _users = []
            let _number_downloads = []
            let _taxons = []
            let _months = []

            for (let i = 0; i < response.length; i++) {
                _users.push(response[i].users)
                _number_downloads.push(response[i].number_downloads)
                _taxons.push(response[i].taxons)
                _months.push(response[i].month)
            }

            const data = {
                "users": _users,
                "number_downloads": _number_downloads,
                "taxons": _taxons,
                "months": _months,
                "interval": MONTH_CONST,
                "presentationType": CHART_TYPE_NORMAL,
                "chartType": CHART_TYPE_BAR,
                "monthFrom": monthFrom,
                "monthTo": monthTo
            }
            props.setMonthlyDownloadsData(data)
        })
    }

    return (
        <Suspense fallback={<Loader />}>
            <div className="ml-1">
                <Row>
                    <Col md={{ span: 12 }}>
                        {props.loadingDownloadsDaily &&
                            <Spinner
                                as="span"
                                animation="border"
                                size="lg"
                                role="status"
                                aria-hidden="true"
                                className="ml-3 mt-3 mb-3"
                            />
                        }
                        {!props.loadingDownloadsDaily && props.charts.interval === DAY_CONST &&
                            <DailyDownloadsChart />
                        }
                        {props.loadingDownloadsMonthly &&
                            <Spinner
                                as="span"
                                animation="border"
                                size="lg"
                                role="status"
                                aria-hidden="true"
                                className="ml-3 mt-3 mb-3"
                            />
                        }
                        {!props.loadingDownloadsMonthly && props.charts.interval === MONTH_CONST &&
                            <MonthlyDownloadsChart />
                        }
                    </Col>
                </Row>
                <Row>  
                    <Col md={{ span: 4 }}>
                        <Form onSubmit={handleDateFetchDaily}>
                            <div className="chart-form-container">
                                <div><h4>{t('daily-interval')}</h4></div>
                                <Form.Group>
                                    <FormLabel htmlFor={"downloadsDayFrom"}><b>{t('from-day')}</b></FormLabel>
                                    <span className="datepicker-toggle">
                                        <Form.Control id={"downloadsDayFrom"} type="date" name="dateFrom" defaultValue={props.charts.dayFrom} required />
                                    <FontAwesomeIcon icon={faCalendar} className="datepicker-toggle-button" alt=""/>
                                    </span>
                                </Form.Group>
                                <Form.Group>
                                    <FormLabel htmlFor={"downloadsDayTo"}><b>{t('to-day')}</b></FormLabel>
                                    <span className="datepicker-toggle">
                                        <Form.Control id={"downloadsDayTo"} type="date" name="dateTo" defaultValue={props.charts.dayTo} required />
                                    <FontAwesomeIcon icon={faCalendar} className="datepicker-toggle-button" alt=""/>
                                    </span>
                                </Form.Group>
                                <Button type="submit">
                                    {t('display-data')}
                                </Button>
                            </div>
                        </Form>
                    </Col>
                    <Col md={{ span: 4 }}>
                        <Form onSubmit={handleDateFetchMonthly}>
                            <div className="chart-form-container">
                                <div><h3>{t('monthly-interval')}</h3></div>
                                <Form.Group>
                                    <FormLabel htmlFor={"downloadsMonthFrom"}><b>{t('from-month')}</b></FormLabel>
                                    <span className="datepicker-toggle">
                                    <Form.Control id={"downloadsMonthFrom"} type="month" name="dateFrom"
                                        value={monthFrom}
                                        onChange={(e)=>{setMonthFrom(e.target.value)}}
                                        onClick={()=>{setShowFrom(true)}}
                                        onBlur={()=>{setShowFrom(false)}}
                                        ref={targetFrom}
                                        required />
                                        <FontAwesomeIcon icon={faCalendar} className="datepicker-toggle-button" alt=""/>
                                    </span>
                                </Form.Group>
                                <Overlay target={targetFrom.current} show={showFrom} placement="bottom" className="firefox-only">
                                    <Popover className="firefox-only">
                                        <Calendar value={monthToDate(monthFrom)} onChange={onMonthFromChange} maxDetail="year"/>
                                    </Popover>
                                </Overlay>
                                <Form.Group>
                                    <FormLabel htmlFor={"downloadsMonthTo"}><b>{t('to-month')}</b></FormLabel>
                                    <span className="datepicker-toggle">
                                    <Form.Control id={"downloadsMonthTo"} type="month" name="dateTo"
                                         value={monthTo}
                                         onChange={(e)=>{setMonthTo(e.target.value)}}
                                         onClick={()=>{setShowTo(true)}}
                                         onBlur={()=>{setShowTo(false)}}
                                         ref={targetTo}
                                         required  />
                                         <FontAwesomeIcon icon={faCalendar} className="datepicker-toggle-button" alt=""/>
                                    </span>
                                </Form.Group>
                                <Overlay target={targetTo.current} show={showTo} placement="bottom" className="firefox-only">
                                    <Popover className="firefox-only">
                                        <Calendar value={monthToDate(monthTo)} onChange={onMonthToChange} maxDetail="year"/>
                                    </Popover>
                                </Overlay>
                                <Button type="submit">
                                    {t('display-data')}
                                </Button>
                            </div>
                        </Form>
                    </Col>
                    <Col md={{ span: 4 }}>
                        <Form>
                            <div className="chart-form-container">
                                <Form.Group>
                                    <FormLabel htmlFor={"downloadsChartType"}><b>{t("chart-type")}</b></FormLabel>
                                    <Form.Control id={"downloadsChartType"} as="select" value={props.charts.chartType} onChange={handleFormPresentationTypeChange}>
                                        <option value={CHART_TYPE_BAR}>{t(CHART_TYPE_BAR)}</option>
                                        <option value={CHART_TYPE_LINE}>{t(CHART_TYPE_LINE)}</option>
                                    </Form.Control>
                                </Form.Group>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </div>
        </Suspense>
    )
}

const mapStateToProps = state => ({
    charts: state.charts,
    user: state.user ? state.user.data : null,
    loadingDownloadsDaily: isPending(API_GET_DOWNLOADS_STATS_PER_DAY_ID, state),
    loadingDownloadsMonthly: isPending(API_GET_DOWNLOADS_STATS_PER_MONTH_ID, state),
})

const mapDispatchToProps = dispatch => ({
    getVisitsData: (id, url) => dispatch(getDataApi(id, url)),
    setChartType: (data) => dispatch(setChartType(data)),
    setPresentationType: (data) => dispatch(setPresentationType(data)),
    setDaysScope: (days) => dispatch(setDaysScope(days)),
    setDisplayedData: (data) => dispatch(setDisplayedData(data)),
    setInterval: (data) => dispatch(setInterval(data)),
    setMonthlyDownloadsData: (data) => dispatch(setMonthlyDownloadsData(data)),
    setDailyDownloadsData: (data) => dispatch(setDailyDownloadsData(data)),
    getDownloadsData: (id, url) => dispatch(getDataApi(id, url))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DownloadsReportsHandler)