import React from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import * as actions from '../../actions'
import { setField } from '../../actions/forms'
import { setFilter } from '../../actions'
import {
    TAXONDB_SEARCH_REQUEST_URL,
    TAXONDB_SEARCH_GENUS_REQUEST_URL,
    TAXONDB_SEARCH_SPECIES_REQUEST_URL,
    ANC_URL_POSTFIX,
    AGGREGATION_REPORT_REQUEST_ID_PREFIX,
} from '../../settings'
import { isPending, getItems, getPagination, getFilter } from "../../reducers";
import ConnectedPagination from "../ConnectedPagination"
import { GENUS_ATTRIBUTE_NAME, SPECIES_ATTRIBUTE_NAME } from './attributesStructure'
import { Container, ListGroup, Spinner } from "react-bootstrap"
import SynonymsInfo from "./SynonymsInfo"
import ValueFromDictionary from "../dictionary/ValueFromDictionary"
import ListGroupItemDiv from "../ListGroupItemDiv";

const TaxonDBGenusList = props => {
    const { t, i18n } = useTranslation([ 'common', 'forms', 'a11y' ])

    const selectGenus = val => {
        props.clearReport();
        props.setActiveTab('1')
        const filter = { ...props.filter }
        filter.rodzaj = val
        props.saveFilterSpecies(filter)
        const newPaginationSpecies = { ...props.paginationSpecies }
        newPaginationSpecies.currentPage = 1
        props.searchSpecies({
            filter: filter,
            pagination: newPaginationSpecies,
        })
            .then(species => {
                if (species.items.length === 1) {
                    props.setActiveTab('2')
                    filter.gatunek = species.items[ 0 ][ SPECIES_ATTRIBUTE_NAME ]
                }
                props.saveFilter(filter)
                const newPagination = { ...props.pagination }
                newPagination.currentPage = 1
                props.search({
                    filter: filter,
                    pagination: newPagination,
                })
            })
    }

    return (
        <Container className="d-flex flex-column mt-2">
            {props.loading &&
                <div className="align-self-center">
                    <Spinner animation="border" role="status" />
                </div>
            }
            {!props.loading &&
                <div>
                    {!props.items &&
                        <p>{t('use-form-to-search')}</p>
                    }
                    {props.items && props.items.length > 0 &&
                        <div>
                            <div className="d-flex flex-row justify-content-center">
                                <div className="p-2">
                            {t('taxondb:nr_of_genus_found')}: <b>{props.paginationGenus.totalCount ? props.paginationGenus.totalCount.toLocaleString(i18n.languages[0]) : '-'}</b>
                                </div>
                            </div>
                            <ConnectedPagination requestId={props.requestId} url={ANC_URL_POSTFIX + TAXONDB_SEARCH_GENUS_REQUEST_URL}></ConnectedPagination>
                            <ListGroup>
                                {props.items.map((val, indx) => {
                                    return (
                                        <div key={indx}>
                                            <ListGroupItemDiv className="d-flex flex-row album-element align-items-center" onClick={() => selectGenus(val[ GENUS_ATTRIBUTE_NAME ])}>
                                                <div className="d-flex flex-grow-1">
                                                    <div>
                                                        <img src="/images/list-closed.svg" alt={t("a11y:list-closed")} />
                                                    </div>
                                                    <div className="mr-1">
                                                        <ValueFromDictionary value={val[ GENUS_ATTRIBUTE_NAME ]} name={GENUS_ATTRIBUTE_NAME}></ValueFromDictionary>
                                                    </div>
                                                    <div>
                                                        ({t('taxondb:nr_of_species')}: {val.liczba_gatunkow.toLocaleString(i18n.languages[0])}, {t('taxondb:nr_of_specimens')}: {val.liczba_okazow.toLocaleString(i18n.languages[0])})
                                                    </div>
                                                </div>
                                            </ListGroupItemDiv>
                                        </div>
                                    )
                                })}
                            </ListGroup>
                            <div>
                                <ConnectedPagination last requestId={props.requestId} url={ANC_URL_POSTFIX + TAXONDB_SEARCH_GENUS_REQUEST_URL}></ConnectedPagination>
                            </div>
                        </div>
                    }
                    {props.items && !props.items.length > 0 &&
                        <>
                            <p>{t('no-records-found')}</p>
                            <SynonymsInfo></SynonymsInfo>
                        </>
                    }
                </div>
            }
        </Container>
    )
}

const mapStateToProps = (state, ownProps) => ({
    loading: isPending(ownProps.requestId, state),
    items: getItems(ownProps.requestId, state),
    pagination: getPagination(ownProps.searchRequestId, state),
    paginationGenus: getPagination(ownProps.requestId, state),
    paginationSpecies: getPagination(ownProps.speciesRequestId, state),
    filter: getFilter(ownProps.requestId, state),
})


const mapDispatchToProps = (dispatch, ownProps) => ({
    setActiveTab: value => dispatch(setField(ownProps.tabId, ownProps.tabId, value)),
    searchSpecies: data => dispatch(actions.postDataAnc(ownProps.speciesRequestId, TAXONDB_SEARCH_SPECIES_REQUEST_URL, data)),
    search: data => dispatch(actions.postDataAnc(ownProps.searchRequestId, TAXONDB_SEARCH_REQUEST_URL, data)),
    saveFilter: filter => dispatch(setFilter(ownProps.searchRequestId, filter)),
    saveFilterSpecies: filter => dispatch(setFilter(ownProps.speciesRequestId, filter)),
    clearReport: () => dispatch(actions.requestRemoveStartsWith(AGGREGATION_REPORT_REQUEST_ID_PREFIX + ownProps.searchRequestId)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TaxonDBGenusList)

