import React, { Suspense } from "react";
import { Container, Row, Col, Carousel } from "react-bootstrap"
import { useTranslation } from 'react-i18next'
import { MOBILE_INFO_PAGE_URL_EN, MOBILE_INFO_PAGE_URL_PL } from "../settings";
import Loader from '../utils/loader'
import ExternalLinkPopover from "./ExternalLinkPopover";
import I18nPathTranslator from "./I18nPathTranslator";
import SEO from "./SEO";

const MobileInfoContainer = props => {
  const { t } = useTranslation(["mobile"])

  return (
    <Suspense fallback={<Loader />}>
      <I18nPathTranslator {...props} pathEN={MOBILE_INFO_PAGE_URL_EN} pathPL={MOBILE_INFO_PAGE_URL_PL} />
      <SEO title='Aplikacja mobilna' />
      <div>
        <Container>
          <div className="mobile-app-page">
            <h1>{t('title')}</h1>
            <p><strong>{t('section_1')}</strong></p>
            <p>{t('section_2')}</p>
            <p><img src="/images/single-portfolio3.jpg" alt={t("a11y:phone-with-map")} className="mx-auto w-100 d-block"></img></p>
            <p>{t('section_3')}</p>
            <Row className="mobile-table">
              <Col lg={6} xl={4}>
                <div className="d-flex item item-1">
                  <div><img src="/images/app-mobile-ico-1-2.png" alt={t("a11y:icon-app-mobile-1")}></img></div>
                  <div className="txt">{t('section_4')}</div>
                </div>
              </Col>
              <Col lg={6} xl={4}>
                <div className="d-flex item item-2">
                  <div><img src="/images/app-mobile-ico-3-2.png" alt={t("a11y:icon-app-mobile-3")}></img></div>
                  <div className="txt">{t('section_5')}</div>
                </div>
              </Col>
              <Col lg={6} xl={4}>
                <div className="d-flex item item-3">
                  <div><img src="/images/app-mobile-ico-5-2.png" alt={t("a11y:icon-app-mobile-5")}></img></div>
                  <div className="txt">{t('section_6')}</div>
                </div>
              </Col>
              <Col lg={6} xl={4}>
                <div className="d-flex item item-4">
                  <div><img src="/images/app-mobile-ico-2-2.png" alt={t("a11y:icon-app-mobile-2")}></img></div>
                  <div className="txt">{t('section_7')}</div>
                </div>
              </Col>
              <Col lg={6} xl={4}>
                <div className="d-flex item item-5">
                  <div><img src="/images/app-mobile-ico-4-2.png" alt={t("a11y:icon-app-mobile-4")}></img></div>
                  <div className="txt">{t('section_8')}</div>
                </div>
              </Col>
            </Row>

            <div className="d-flex slider-content">
              <Carousel indicators={false} prevLabel={t('common:previous')} nextLabel={t('common:next')}>
                <Carousel.Item>
                  <div className="d-flex slider-item">
                    <img src="/images/1.jpg" alt={t("a11y:app-slider-1")}></img>
                    <div className="d-flex flex-column text-container">
                      <h2>{t('section_16')}</h2>
                      <div className="desc">{t('section_9')}</div>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="d-flex slider-item">
                    <img src="/images/2.jpg" alt={t("a11y:app-slider-2")}></img>
                    <div className="d-flex flex-column text-container">
                      <h2>{t('section_16')}</h2>
                      <div className="desc">{t('section_10')}</div>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="d-flex slider-item">
                    <img src="/images/3.jpg" alt={t("a11y:app-slider-3")}></img>
                    <div className="d-flex flex-column text-container">
                      <h2>{t('section_16')}</h2>
                      <div className="desc">{t('section_11')}</div>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="d-flex slider-item">
                    <img src="/images/4.jpg" alt={t("a11y:app-slider-4")}></img>
                    <div className="d-flex flex-column text-container">
                      <h2>{t('section_16')}</h2>
                      <div className="desc">{t('section_12')}</div>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="d-flex slider-item">
                    <img src="/images/5.jpg" alt={t("a11y:app-slider-5")}></img>
                    <div className="d-flex flex-column text-container">
                      <h2>{t('section_16')}</h2>
                      <div className="desc">{t('section_13')}</div>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="d-flex slider-item">
                    <img src="/images/6.jpg" alt={t("a11y:app-slider-6")}></img>
                    <div className="d-flex flex-column text-container">
                      <h2>{t('section_16')}</h2>
                      <div className="desc">{t('section_14')}</div>
                    </div>
                  </div>
                </Carousel.Item>
              </Carousel>
            </div>
            <p className="last-par">{t('section_15')}</p>

            <div className="d-flex justify-content-center align-items-center store">
              <div><h2>{t('section_17')}</h2></div>
              <ExternalLinkPopover link='https://apps.apple.com/pl/app/amunatcoll/id1523442673' btnClass="p-1" placement="top" title={t("common:go-to")+"App Store"}><img src="/images/amu-apple-store-1.png" alt="App Store" className="store-icons" /></ExternalLinkPopover>
              <ExternalLinkPopover link='https://play.google.com/store/apps/details?id=pl.pcss.amunatcoll.mobile' btnClass="p-1" placement="top" title={t("common:go-to")+"Google Play"}><img src="/images/amu-google-play-2.png" alt="Google Play" className="store-icons" /></ExternalLinkPopover>
            </div>
          </div>
        </Container>
      </div>
    </Suspense>
  )
}

export default MobileInfoContainer
