import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import * as notify from '../../utils/notify'

export default (fields, onSubmitAction) => {
  const { t } = useTranslation([ 'hooks' ]);

  const onSubmit = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
    if (fields.reduce((previous, current) => (current() && previous), true)) {
      onSubmitAction();
    }
    else {
      notify.error(t('please-correct-data-in-form'));
    }
    return false;
  }, [ fields, onSubmitAction, t ]);

  return { onSubmit };
};

