import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Pagination, Dropdown, DropdownButton, Form, Spinner, Button } from 'react-bootstrap';

import { DEFAULT_PAGINATION_PER_PAGE, DEFAULT_PAGINATION_PER_PAGE_SET } from '../settings';
import { ARIAControlButton, ARIAPaginationItem } from '../components/ConnectedPagination';

const usePagination = (response, refetch, data) => {
  const [ currentPage, setCurrentPage ] = useState(1);
  const [ perPage, setPerPage ] = useState(DEFAULT_PAGINATION_PER_PAGE);
  const [ totalPages, setTotalPages ] = useState(1);

  useEffect(() => {
    if (response?.data?.pagination?.totalCount) {
      setPerPage(response.data.pagination.perPage);
      setTotalPages(response.data.pagination.totalPages);
      if (response.data.pagination.totalPages < currentPage) {
        setCurrentPage(1);
        refetch({ ...data, pagination: { currentPage: 1, perPage: response.data.pagination.perPage }});
      }
    }
  }, [ response, setTotalPages, currentPage, data, refetch ]);

  useEffect(() => {
    if (data?.pagination?.currentPage) {
      if (data.pagination.currentPage !== currentPage) {
        setCurrentPage(data.pagination.currentPage);
      }
    }
  }, [ data, currentPage ]);

  const PaginationComponent = props => {
    const { t } = useTranslation([ 'common', 'forms', 'a11y' ]);
    const [ formPagination, setFormPagination ] = useState(currentPage);

    const PAGINATION_SIZE = 2;
    const ELLIPSIS_THRESHOLD = 2;

    const changePage = page => {
      if (currentPage !== page) {
        setCurrentPage(page);
        refetch({ ...data, pagination: { currentPage: page, perPage } });
      }
    };

    const changePerPage = per => {
      const newPerPage = parseInt(per);
      if (perPage !== newPerPage) {
        setPerPage(newPerPage);
        setCurrentPage(1);
        refetch({ ...data, pagination: { currentPage: 1, perPage: newPerPage } });
      }
    };

    const items = [];
    if (totalPages > 1) {
      items.push(
        <ARIAPaginationItem key={1} page={1} changePage={changePage} currentPage={currentPage}>
            {1}
        </ARIAPaginationItem>
      );

      if (currentPage - PAGINATION_SIZE - 2 >= ELLIPSIS_THRESHOLD) {
        items.push(
          <ARIAControlButton key="ellipsis-before" type="ellipsis" changePage={changePage} page={parseInt(currentPage / 2)} />
        );
      }
      else if (totalPages >= 2) {
        items.push(
          <ARIAPaginationItem key={2} page={2} changePage={changePage} currentPage={currentPage}>
          {2}
          </ARIAPaginationItem>
        );
      }

      for (let page = Math.max(3, currentPage - PAGINATION_SIZE);
        page <= Math.min(currentPage + PAGINATION_SIZE, totalPages - 2);
        page++) {
        items.push(
          <ARIAPaginationItem key={page} page={page} changePage={changePage} currentPage={currentPage}>
          {page}
          </ARIAPaginationItem>
        );
      }

      if (totalPages - currentPage - PAGINATION_SIZE - 1 >= ELLIPSIS_THRESHOLD) {
        const step = parseInt((totalPages - currentPage) / 2);
        let page = currentPage + step;
        if (step <= PAGINATION_SIZE)
          page += 1;
        items.push(
          <ARIAControlButton key="ellipsis-after" type="ellipsis" changePage={changePage} page={page} />
        );
      }
      else if (totalPages >= 4) {
        items.push(
          <ARIAPaginationItem key={totalPages - 1} page={totalPages - 1} changePage={changePage} currentPage={currentPage}>
          {totalPages - 1}
          </ARIAPaginationItem>
        );
      }

      if (totalPages > 2) {
        items.push(
          <ARIAPaginationItem key={totalPages} page={totalPages} changePage={changePage} currentPage={currentPage}>
          {totalPages}
          </ARIAPaginationItem>
        );
      }
    }

    return (
      <div>
        {response?.data?.pagination?.totalCount &&
          <nav role="navigation" 
          aria-label={props.last ? t('a11y:pagination-msg')+totalPages : t('results_per_page')+perPage+","} 
          className={'d-flex flex-column flex-wrap new-pagination ' + (props.last ? 'mb-3' : 'mt-3')}>
            {props.last && totalPages > 1 &&
              <>
                <Pagination size="sm" className="flex-grow-1 justify-content-center">
                  {currentPage > 1 &&
                    <>
                      <ARIAControlButton type="first" changePage={changePage} page={1} />
                      <ARIAControlButton type="prev" changePage={changePage} page={currentPage - 1} />
                    </>
                  }
                  {items}
                  {currentPage < totalPages &&
                    <>
                      <ARIAControlButton type="next" changePage={changePage} page={currentPage + 1} />
                      <ARIAControlButton type="last" changePage={changePage} page={totalPages} />
                    </>
                  }
                </Pagination>
                {currentPage &&
                  <Form className="pagination-page-form">
                    <Form.Group controlId={`pagination-input-${props.id}`} className="pagination-input-group">
                      <Form.Label>{t('forms:page')}</Form.Label>
                      <Form.Control
                      onChange={e => {
                      const val = e.target.value
                        if(!val)
                          setFormPagination("")
                        if(val && val > 0 && val <= totalPages)
                          setFormPagination(parseInt(e.target.value))
                      }}
                      value={formPagination}
                      type="number"
                      autoComplete="off"
                      min={1}
                      max={totalPages}
                      className="pagination-input"
                      ></Form.Control>
                    </Form.Group>
                    <Button variant="primary" disabled={props.loading} type="submit"
                    onClick={e => {
                      e.preventDefault()
                      if(formPagination)
                        changePage(formPagination)
                      else
                        changePage(currentPage)
                    }}
                    >
                    {t('forms:go')}
                    {props.loading &&
                      <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="ml-1"
                      />
                    }
                    </Button>
                  </Form>
                }
              </>
            }
            {(totalPages > 1 || perPage > 20) && !props.last &&
              <div className="d-flex justify-content-end align-items-center mb-3">
                <div className="mr-1" >{t('results_per_page')}:</div>
                <div>
                  <DropdownButton title={perPage} size="sm" variant="light" id="per-page-select">
                    {DEFAULT_PAGINATION_PER_PAGE_SET.map((val, indx) =>
                      <Dropdown.Item key={indx} onSelect={changePerPage} eventKey={val}>{val}</Dropdown.Item>
                    )}
                  </DropdownButton>
                </div>
              </div>
            }
          </nav>
        }
      </div>
    )
  };

  return { Pagination: PaginationComponent, currentPage, perPage };
};
export default usePagination;
