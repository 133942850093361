import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { isPending, getItems } from "../../reducers"
import { postDataApi, postData } from '../../actions'
import { Form, Button, Spinner } from "react-bootstrap"
import {
    PROJECTS_GET_ALL_1000_ID,
    PROJECTS_GET_ALL_URL,
    TEAM_COPY_OBSERVATION_REQUEST_ID_PREFIX,
    TEAM_COPY_OBSERVATION_REQUEST_URL,
} from '../../settings'
import * as notify from '../../utils/notify'

const CopyObservation = props => {
    const { t } = useTranslation(['projects'])

    const { getProjects } = props

    useEffect(() => {
        getProjects().then(res => {
            if (res && res.items && res.items.length > 0) {
                setTargetProject(res.items[0].id)
            }
        })
    }, [getProjects])

    const [targetProject, setTargetProject] = useState('')

    const onTargetProjectChanged = e => {
        setTargetProject(e.target.value)
    }

    const onCopyObservation = () => {
        props.copyObservation(targetProject)
            .then(() => {
                notify.success(t('observation-copy-success'))
            })
            .catch(() => {
                notify.error(t('observation-copy-failed'))
            })
            .finally(() => {
                props.onDone()
            })
    }

    return (
        <Form className="mt-2 common-panel-background p-2" onSubmit={e => { e.preventDefault() }}>
            {props.loading &&
                <div className="d-flex justify-content-center">
                    <Spinner animation="border" role="status" />
                </div>
            }
            {!props.loading && props.projects &&
                <>
                    <Form.Group controlId="project">
                        <Form.Label>{t('copy-to')}</Form.Label>
                        <Form.Control as="select" value={targetProject} onChange={onTargetProjectChanged} disabled={props.saving}>
                            {props.projects && props.projects.map((p, i) =>
                                <option key={i} value={p.id}>{p.name}</option>
                            )};
                        </Form.Control>
                    </Form.Group>
                    <div className="text-right">
                        <Button size="sm"
                            disabled={props.saving}
                            onClick={onCopyObservation}
                    >
                        {t('copy-observation')}
                        {props.saving &&
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="ml-1"
                            />
                        }
                    </Button>
                    </div>
                </>
            }
            {!props.loading && !props.projects &&
                <div>{t('no-available-projects')}</div>
            }
        </Form>
    )
}

const mapStateToProps = (state, ownProps) => ({
    loading: isPending(PROJECTS_GET_ALL_1000_ID, state),
    projects: getItems(PROJECTS_GET_ALL_1000_ID, state),
    saving: isPending(TEAM_COPY_OBSERVATION_REQUEST_ID_PREFIX + ownProps.observationId, state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    getProjects: () => dispatch(postDataApi(PROJECTS_GET_ALL_1000_ID, PROJECTS_GET_ALL_URL, { pagination: { currentPage: 1, perPage: 1000 } })),
    copyObservation: project_id =>
        dispatch(postData(
            TEAM_COPY_OBSERVATION_REQUEST_ID_PREFIX + ownProps.observationId,
            TEAM_COPY_OBSERVATION_REQUEST_URL,
            {
                project_id,
                observation_id: ownProps.observationId
            }))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CopyObservation)
