import React from 'react';
import { connect } from 'react-redux'
import App from './App';
import ReduxToastr from 'react-redux-toastr';

const Main = props =>
  <>
    <App />
    <ReduxToastr />
  </>

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => ({
})


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Main)
