import React, { useCallback } from 'react';
import L, { FeatureGroup } from 'leaflet';
import useMapRedux from './useMapRedux';
import useFieldRedux from './useFieldRedux';
import useFieldsRedux from './useFieldsRedux';
import useApi from './useApi';
import {
  TAXON_SEARCH_IS_DYNAMIC,
  TAXONDB_SEARCH_GENUS_REQUEST_ID,
  TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID,
  TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID,
  MAP_TOOLS_ACTIVE_VISUALIZATION,
  MAP_TOOLS_VISUALIZATION_1,
  USER_DRAWINGS_POLYGONS_CONST,
  USER_DRAWINGS_LINES_CONST,
  USER_DRAWINGS_POINTS_CONST,
  MAP_MAX_ZOOM_LEVEL,
  MAP_CLUSTER_MARKER_COUNT_LIMIT,
} from "../settings";
import {
  MAP_DEFINITION,
  SHOW_MAP_LEGEND,
  MAP_OBSERVATIONS,
  MAP_LAYERS,
} from "../stateIds";
import ReactDOMServer from 'react-dom/server'
import renderIconHtml from "../utils/map/renderIconHtml"
import {
  URL_API_PROJECT_GET_OBSERVATIONS_DETAILS,
} from '../urls';
import useCoordinateLayers from './useCoordinateLayers';
import useShapefilesHandler from './useShapefilesHandler';
import usePredefinedWMS from './usePredefinedWMS';
import useMarkerShapeColorSelector from './useMarkerShapeColorSelector'

const useMapDefinition = () => {
  const { value: mapDefinition, setValue: setMapDefinition } = useMapRedux(MAP_DEFINITION, {});
  const { value: mapLegend, setValue: setMapLegend } = useMapRedux('mapLegend', {});
  const { value: mapObservations, setValue: setMapObservations } = useMapRedux(MAP_OBSERVATIONS, {});
  const { value: mapLayers, setValue: setMapLayers } = useMapRedux(MAP_LAYERS,
    {atpolGrid: {}, utmGrid: {}, shapefilesLayers: {}, wmsLayer: {}});
  const { value: isDynamic, setValue: setIsDynamic } = useFieldRedux(TAXON_SEARCH_IS_DYNAMIC, "0");
  const { value: activeVisualization, setValue: setActiveVisualization } = useFieldRedux(MAP_TOOLS_ACTIVE_VISUALIZATION, MAP_TOOLS_VISUALIZATION_1);
  const { value: filter1, setValue: setFilter1 } = useFieldsRedux(TAXONDB_SEARCH_GENUS_REQUEST_ID, null); // dynamic 0
  const { value: filter2, setValue: setFilter2 } = useFieldRedux(TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID, "null"); // dynamic 2
  const { value: filter3, setValue: setFilter3 } = useFieldRedux(TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, "null"); // dynamic 1
  const { value: historicalMarkerColor, setValue: setHistoricalMarkerColor } = useMapRedux('historicalMarkerColor', "#000000");
  const { value: historicalMarkerIcon, setValue: setHistoricalMarkerIcon } = useMapRedux('historicalMarkerIcon', "fa fa-map-marker");
  const { value: historicalMarkerSize, setValue: setHistoricalMarkerSize } = useMapRedux('historicalMarkerSize', "fa-lg");
  const { value: pixiMarkerColor, setValue: setPixiMarkerColor } = useMapRedux('pixiMarkerColor', 0x1A14D4);
  const { value: pixiMarkerShape, setValue: setPixiMarkerShape } = useMapRedux('pixiMarkerShape', "square_solid");
  const { value: pixiMarkerSize, setValue: setPixiMarkerSize } = useMapRedux('pixiMarkerSize', 6);
  const { value: pixiSelectedCirclecolor, setValue: setPixiSelectedCirclecolor } = useMapRedux('pixiSelectedCirclecolor', "blue");
  const { value: groupingShapeField, setValue: setGroupingShapeField } = useMapRedux('groupingShapeField', "rodzaj");
  const { value: groupingColorField, setValue: setGroupingColorField } = useMapRedux('groupingColorField', "gatunek");
  const { value: cartogramAdministrativeDivision, setValue: setCartogramAdministrativeDivision } = useMapRedux('cartogramAdministrativeDivision', "panstwo");
  const { value: cartogramBordersColor, setValue: setCartogramBordersColor } = useMapRedux('cartogramBordersColor', "white");
  const { value: cartogramBordersWidth, setValue: setCartogramBordersWidth } = useMapRedux('cartogramBordersWidth', 0.2);
  const { value: cartogramOpacity, setValue: setCartogramOpacity } = useMapRedux('cartogramOpacity', 1);
  const { value: numberOfColors, setValue: setCartogramNumberOfColors } = useMapRedux('numberOfColors', 10);
  const { value: sequenceType, setValue: setCartogramSequenceType } = useMapRedux('sequenceType', "SEQUENCE_TYPE_ARYTHMETIC");
  const { value: sequenceProduct, setValue: setCartogramSequenceProduct } = useMapRedux('sequenceProduct', 2);
  const { value: firstSequenceNumber, setValue: setCartogramFirstSequenceNumber } = useMapRedux('firstSequenceNumber', 2);
  const { value: cartogramFirstAreaColor, setValue: setCartogramFirstAreaColor } = useMapRedux('cartogramFirstAreaColor', "gray");
  const { value: cartogramSecondAreaColor, setValue: setCartogramSecondAreaColor } = useMapRedux('cartogramSecondAreaColor', "blue");
  const { value: cartodiagramGroupingField, setValue: setCartodiagramGroupingField } = useMapRedux('cartodiagramGroupingField', "rodzaj");
  const { value: numberOfChartColors, setValue: setCartodiagramNumberOfChartColors } = useMapRedux('numberOfChartColors', 2);
  const { value: isVisibleMapLegend, setValue: setIsVisibleMapLegend } = useMapRedux(SHOW_MAP_LEGEND, false);
  const { refetch: getObservationDetails } = useApi('post', URL_API_PROJECT_GET_OBSERVATIONS_DETAILS);
  const { handleAtpolGrid, handleUtmGrid, handleCoordinatesGrid } = useCoordinateLayers();
  const { handleShapefilesLayers } = useShapefilesHandler();
  const { handleLoadWms } = usePredefinedWMS()
  const { prepareBasicDivIcon } = useMarkerShapeColorSelector()

  const getUserDrawings = useCallback(drawingsLayer => {
    const geoJson = new FeatureGroup().toGeoJSON()
    if (drawingsLayer.current) {
      drawingsLayer.current.eachLayer(
        layer => {
          const newlayer = layer.toGeoJSON()
          newlayer.properties = {
            color: layer.options.color,
            fillColor: layer.options.fillColor,
            stroke: layer.options.stroke,
            opacity: layer.options.opacity,
            weight: layer.options.weight,
            fill: layer.options.fill,
            fillOpacity: layer.options.fillOpacity,
          }
          geoJson.features.push(newlayer)
        }
      )
    }
    return geoJson
  }, []);

  const setUserDrawings = useCallback((mapLayer, drawingsLayer) => {
    if (drawingsLayer.current){
      drawingsLayer.current.remove()
    }
    const layers = L.geoJSON(mapDefinition.userDrawings);
    let index = 0;
    for (const property in layers._layers) {
        if (layers._layers[property].feature.geometry.type !== 'Point'){
            if(layers._layers[property].feature.geometry.type === 'Polygon'){
                let polygonFeature = layers._layers[property]
                polygonFeature.name = USER_DRAWINGS_POLYGONS_CONST
                let itemProps = mapDefinition.userDrawings.features[index].properties
                if (itemProps){
                    polygonFeature.options.color = itemProps.color
                    polygonFeature.options.fillColor = itemProps.fillColor
                    polygonFeature.options.stroke = itemProps.stroke
                    polygonFeature.options.opacity = itemProps.opacity
                    polygonFeature.options.weight = itemProps.weight
                    polygonFeature.options.fill = itemProps.fill
                    polygonFeature.options.fillOpacity = itemProps.fillOpacity
                    polygonFeature.style = itemProps
                }
                polygonFeature.addTo(drawingsLayer.current);
            }
            else if(layers._layers[property].feature.geometry.type === 'LineString'){
                let polylineFeature = layers._layers[property]
                polylineFeature.name = USER_DRAWINGS_LINES_CONST
                let itemProps = mapDefinition.userDrawings.features[index].properties
                if (itemProps){
                    polylineFeature.options.color = itemProps.color
                    polylineFeature.options.fillColor = itemProps.fillColor
                    polylineFeature.options.stroke = itemProps.stroke
                    polylineFeature.options.opacity = itemProps.opacity
                    polylineFeature.options.weight = itemProps.weight
                    polylineFeature.options.fill = itemProps.fill
                    polylineFeature.options.fillOpacity = itemProps.fillOpacity
                    polylineFeature.style = itemProps
                }
                polylineFeature.addTo(drawingsLayer.current);                        
            }
        }
        index++;
    }
    if (mapDefinition.userDrawings) {
      mapDefinition.userDrawings.features.forEach(layer => {
          if (layer.geometry.type === 'Point'){
              L.geoJson(layer, {
                  onEachFeature: function (feature) {
                      let html = feature?.properties?.icon?.options?.html;
                      if (!html){
                          html = renderIconHtml('fa fa-square fa-3x', 'blue')                   
                      }
                      const fontAwesomeIcon = new L.divIcon({
                          html,
                          iconSize: [20, 20],
                          className: 'myDivIcon'
                      });
                      const marker = L.marker([feature.geometry.coordinates[1], feature.geometry.coordinates[0]], { icon: fontAwesomeIcon })
                      marker.name = USER_DRAWINGS_POINTS_CONST
                      marker.addTo(drawingsLayer.current)
                  }
              });
          }
      })
    }
    drawingsLayer.current.addTo(mapLayer.current).bringToFront()
  }, [ mapDefinition ])

  const iconCreateFunction = useCallback((cluster, s, c, x) => {
      var childCount = cluster.getChildCount();
      const html = ReactDOMServer.renderToString(
          <div className="d-flex align-items-center justify-content-center">
              <div className={`fa fa-${s} fa-${x}`} style={{ color: (c), position: 'absolute', opacity: '0.5' }}></div>
              <div style={{color: 'black', position: 'absolute', fontSize: '1.3rem'}}>{childCount}</div>
          </div>
      )
      return new L.DivIcon({ html, className: 'myDivIcon', iconSize: new L.Point(40, 40) });
  }, [])

  const loadObservations = useCallback((mapLayer, observationsLayer, obj, shape, color, size) => {
    const cluster = L.markerClusterGroup({
        chunkedLoading: true,
        spiderfyOnMaxZoom: false,
        iconCreateFunction: c => iconCreateFunction(c, shape, color, size),
    });
    let observations = Object.values(obj)
    let numberOfObservations = observations.length
    let noCoordsObservations = 0
    observations.forEach(item => {
        if(item.fields["field.latitude"] && item.fields["field.longitude"]){
            let LATITUDE = parseFloat(item.fields["field.latitude"])
            let LONGITUDE = parseFloat(item.fields["field.longitude"])
            let name = item.name
            let icon = prepareBasicDivIcon(shape, color, size)
            var marker = L.marker({lat: LATITUDE, lng: LONGITUDE}, { icon: icon })
            marker.name = name
            marker.bindPopup(name)
            cluster.addLayer(marker)
        }
        else{
            noCoordsObservations++
        }
    })
    if (cluster.getLayers().length > 0) {
        cluster.addTo(observationsLayer.current);
        cluster.on('clusterclick', e => {
            if (mapLayer.current.getZoom() >= MAP_MAX_ZOOM_LEVEL) {
                if (e.layer.getChildCount() <= MAP_CLUSTER_MARKER_COUNT_LIMIT) {
                    e.layer.spiderfy()
                }
            }
        })
    }
    return { cluster, noCoordsObservations, numberOfObservations }
  }, [iconCreateFunction, prepareBasicDivIcon])

  const reloadObservations = useCallback((mapLayer, observationsLayer) => {
    observationsLayer.current.eachLayer(layer => {
      observationsLayer.current.removeLayer(layer);
    });
    const newObservations = {...mapObservations}
    var mapLoadingBlur = L.control.loader()
    mapLoadingBlur.addTo(mapLayer.current);
    Object.keys(mapObservations || {}).filter(key => mapObservations[key].leafletId !== null)
      .forEach(key => {
        getObservationDetails(null, key)
          .then(res => {
            const shape = mapObservations[key].shape
            const color = mapObservations[key].color
            const size = mapObservations[key].size
            const { cluster } = loadObservations(mapLayer, observationsLayer, res.data.items.observations, shape, color, size);
            if (cluster.getLayers().length > 0) {
                newObservations[key] = {
                  color,
                  shape,
                  size,
                  leafletId: cluster._leaflet_id, 
                }
            }
            else {
              newObservations[key] = {
                color,
                shape,
                size,
                leafletId: null, 
              }
            }
          })
      })
    mapLoadingBlur.hide()
    setMapObservations(newObservations)
    }, [ mapObservations, setMapObservations, getObservationDetails, loadObservations ])

  const reloadLayers = useCallback(mapLayer => {
    if (mapLayers) {
      Object.keys(mapLayers.atpolGrid || {}).forEach(name => {
        handleAtpolGrid({name, checked: mapLayers.atpolGrid[name] }, mapLayer);
      })
      Object.keys(mapLayers.utmGrid || {}).forEach(name => {
        handleUtmGrid({name, checked: mapLayers.utmGrid[name] }, mapLayer);
      })
      Object.keys(mapLayers.shapefilesLayers || {}).forEach(name => {
        handleShapefilesLayers({name, checked: mapLayers.shapefilesLayers[name] }, mapLayer);
      })
      Object.keys(mapLayers.wmsLayer || {}).forEach(key => {
        const obj = mapLayers.wmsLayer[key]
        handleLoadWms(mapLayer, obj.url, obj.layer, obj.name, obj.id, true, obj.opacity);
      })
      if (mapLayers["coordinates-layer"] !== undefined) {
        handleCoordinatesGrid({ name: "coordinates-layer", checked: mapLayers["coordinates-layer"] }, mapLayer)
      }
    }
  }, [ handleAtpolGrid, handleCoordinatesGrid, handleUtmGrid, mapLayers, handleShapefilesLayers, handleLoadWms ])

  const getFilter = useCallback(() => {
    if (isDynamic === "0") {
      return filter1;
    }
    if (isDynamic === "2") {
      return JSON.parse(filter2);
    }
    if (isDynamic === "1") {
      return JSON.parse(filter3);
    }
    return null;
  }, [ filter1, filter2, filter3, isDynamic ]);

  const setFilter = useCallback((id, val) => {
    if (id === "2") {
      setFilter2(JSON.stringify(val))
    }
    else if (id === "1") {
      setFilter3(JSON.stringify(val))
    }
    else {
      setFilter1(val)
    }
  }, [ setFilter1, setFilter2, setFilter3 ]);

  const showMapLegend = useCallback((mapLayer, legendLayer) => {
    legendLayer.current = L.control({ position: mapDefinition.mapLegend.legendOrientation });
    legendLayer.current.onAdd = function() {
        var div = L.DomUtil.create("div", "legend");
        div.style.backgroundColor = 'white';
        div.style.padding = '10px';
        div.style.width = `${mapDefinition.mapLegend.mapLegendWidth}px`
        div.innerHTML += ReactDOMServer.renderToString(
            <div>
                <div style={{background: "white", fontSize: parseInt(mapDefinition.mapLegend.legendTitleFontSize), marginLeft: 5}} >
                    <b>{mapDefinition.mapLegend.mapTitle}</b>
                </div>
                <div style={{background: "white", fontSize: parseInt(mapDefinition.mapLegend.legendTitleFontSize), marginLeft: 5}} >
                    <i>{mapDefinition.mapLegend.authorName}</i>
                </div>
                <div style={{background: "white", fontSize: parseInt(mapDefinition.mapLegend.legendDescriptionFontSize), marginLeft: 5}} >
                    {mapDefinition.mapLegend.mapDescription}
                </div>
            </div>
        )
        return div
    }
    legendLayer.current.addTo(mapLayer.current)
  }, [ mapDefinition ]);

  const hideMapLegend = useCallback(legendLayer => {
    if (legendLayer.current){
      legendLayer.current.remove()
    }
  }, []);

  const setMapState = useCallback(val => {
    setMapDefinition(val);
    setIsDynamic(val.isDynamic);
    setFilter(val.isDynamic, val.filter);
    setActiveVisualization(val.activeVisualization);
    setHistoricalMarkerColor(val.mapProperties?.historicalMarkerColor);
    setHistoricalMarkerIcon(val.mapProperties?.historicalMarkerIcon);
    setHistoricalMarkerSize(val.mapProperties?.historicalMarkerSize);
    setPixiMarkerColor(val.mapProperties?.pixiMarkerColor);
    setPixiMarkerShape(val.mapProperties?.pixiMarkerShape);
    setPixiMarkerSize(val.mapProperties?.pixiMarkerSize);
    setPixiSelectedCirclecolor(val.mapProperties?.pixiSelectedCirclecolor);
    setGroupingShapeField(val.mapProperties?.groupingShapeField);
    setGroupingColorField(val.mapProperties?.groupingColorField);
    setCartogramAdministrativeDivision(val.mapProperties?.cartogramAdministrativeDivision);
    setCartogramBordersColor(val.mapProperties?.cartogramBordersColor);
    setCartogramBordersWidth(val.mapProperties?.cartogramBordersWidth);
    setCartogramOpacity(val.mapProperties?.cartogramOpacity);
    setCartogramNumberOfColors(val.mapProperties?.numberOfColors);
    setCartogramSequenceType(val.mapProperties?.sequenceType);
    setCartogramSequenceProduct(val.mapProperties?.sequenceProduct);
    setCartogramFirstSequenceNumber(val.mapProperties?.firstSequenceNumber);
    setCartogramFirstAreaColor(val.mapProperties?.cartogramFirstAreaColor);
    setCartogramSecondAreaColor(val.mapProperties?.cartogramSecondAreaColor);
    setCartodiagramGroupingField(val.mapProperties?.cartodiagramGroupingField);
    setCartodiagramNumberOfChartColors(val.mapProperties?.numberOfChartColors);
    setMapLegend(val.mapLegend);
    setMapObservations(val.mapObservations);
    setMapLayers(val.mapLayers);
    setIsVisibleMapLegend(val.isVisibleMapLegend);
  }, [
    setIsDynamic,
    setFilter,
    setActiveVisualization,
    setHistoricalMarkerColor,
    setHistoricalMarkerIcon,
    setHistoricalMarkerSize,
    setPixiMarkerColor,
    setPixiMarkerShape,
    setPixiMarkerSize,
    setPixiSelectedCirclecolor,
    setGroupingShapeField,
    setGroupingColorField,
    setCartogramAdministrativeDivision,
    setCartogramBordersColor,
    setCartogramBordersWidth,
    setCartogramOpacity,
    setCartogramNumberOfColors,
    setCartogramSequenceType,
    setCartogramSequenceProduct,
    setCartogramFirstSequenceNumber,
    setCartogramFirstAreaColor,
    setCartogramSecondAreaColor,
    setCartodiagramGroupingField,
    setCartodiagramNumberOfChartColors,
    setMapLegend,
    setMapObservations,
    setMapLayers,
    setIsVisibleMapLegend,
    setMapDefinition,
  ]);

  const initMap = useCallback((mapLayer, drawingsLayer, legendLayer, observationsLayer) => {
    reloadLayers(mapLayer);
    setUserDrawings(mapLayer, drawingsLayer);
    reloadObservations(mapLayer, observationsLayer);
    hideMapLegend(legendLayer);
    if (isVisibleMapLegend) {
      showMapLegend(mapLayer, legendLayer);
    }
  }, [
    showMapLegend,
    hideMapLegend,
    isVisibleMapLegend,
    setUserDrawings,
    reloadObservations,
    reloadLayers,
  ]);

  const getCurrentMapDefinition = useCallback((drawingsLayer) => ({
    isDynamic,
    filter: getFilter(),
    userDrawings: getUserDrawings(drawingsLayer),
    mapLegend,
    mapObservations,
    activeVisualization,
    mapProperties: {
      historicalMarkerColor,
      historicalMarkerIcon,
      historicalMarkerSize,
      pixiMarkerColor,
      pixiMarkerShape,
      pixiMarkerSize,
      pixiSelectedCirclecolor,
      groupingShapeField,
      groupingColorField,
      cartogramAdministrativeDivision,
      cartogramBordersColor,
      cartogramBordersWidth,
      cartogramOpacity,
      numberOfColors,
      sequenceType,
      sequenceProduct,
      firstSequenceNumber,
      cartogramFirstAreaColor,
      cartogramSecondAreaColor,
      cartodiagramGroupingField,
      numberOfChartColors,
    },
    mapLayers,
    isVisibleMapLegend,
  }), [
    mapLegend,
    mapObservations,
    mapLayers,
    activeVisualization,
    getUserDrawings,
    getFilter,
    isDynamic,
    historicalMarkerColor,
    historicalMarkerIcon,
    historicalMarkerSize,
    pixiMarkerColor,
    pixiMarkerShape,
    pixiMarkerSize,
    pixiSelectedCirclecolor,
    groupingShapeField,
    groupingColorField,
    cartogramAdministrativeDivision,
    cartogramBordersColor,
    cartogramBordersWidth,
    cartogramOpacity,
    numberOfColors,
    sequenceType,
    sequenceProduct,
    firstSequenceNumber,
    cartogramFirstAreaColor,
    cartogramSecondAreaColor,
    cartodiagramGroupingField,
    numberOfChartColors,
    isVisibleMapLegend,
  ]);

  return {
    getCurrentMapDefinition,
    initMap,
    setMapState,
    hideMapLegend,
    showMapLegend,
    iconCreateFunction,
    loadObservations,
  };
};
export default useMapDefinition;
