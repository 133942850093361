import React, { useState, forwardRef } from 'react';
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Button, Collapse } from  "react-bootstrap"; 
import LineStyleCustomizer from './LineStyleCustomizer';
import PolygonStyleCustomizer from './PolygonStyleCustomizer';
import AggregatedTaxonsItems from './AggregatedTaxonsIcons';
import MapIconsToolbar from './MapIconsToolbar';
import MapPopupContent from './MapPopupContent'
import ObservationsStyleToolbar from './ObservationsStyleToolbar'
import AdministrativeDivisionStyleCustomizer from './AdministrativeDivisionStyleCustomizer';

const IconStyleHandler  = forwardRef((props, ref) => {

    const { t } = useTranslation(['map', 'taxondb-attributes'])

    let toolbar1 = "TOOLBAR_1"
    let toolbar2 = "TOOLBAR_2"
    let toolbar3 = "TOOLBAR_3"
    let toolbar4 = "TOOLBAR_4"
    let toolbar5 = "TOOLBAR_5"
    let toolbar6 = "TOOLBAR_6"

    const [buttonState, setButtonState] = useState({})

    const toggleButton = (requestId) => {
        const temp = { ...buttonState }
        temp[requestId] = !temp[requestId]
        setButtonState(temp)
    }

    return(
            <>
                <div className="border">
                        {!props.hideFoundTaxonsCustomizer &&
                        <>
                            <div className="d-flex flex-row album-element align-items-center border p-3"
                                onClick={e => {
                                    toggleButton(toolbar1)        
                                }
                            }>
                                <div className="flex-grow-1"
                                    aria-controls={toolbar1}
                                    aria-expanded={setButtonState[toolbar1]}>
                                        <b>{t('map:map-icon-style-anc-markers')}</b>
                                </div>
                                <div className="d-flex">
                                    <Button
                                        variant="secondary"
                                        size="sm"
                                        className="align-self-center"
                                    >
                                        {!buttonState[toolbar1] &&
                                            <span>{t('map:more')}</span>
                                        }
                                        {buttonState[toolbar1] &&
                                            <span>{t('map:hide')}</span>
                                        }
                                    </Button>
                                </div>
                            </div>
                            <Collapse in={buttonState[toolbar1]} id={toolbar1}>
                                <div className="ml-2 p-2">
                                    <div className="border">
                                        <div className="ml-2 mb-3">
                                            <AggregatedTaxonsItems
                                                ref={{"mapRef" : ref.mapRef, "ancTaxonsWithLegend": ref.ancTaxonsWithLegend}}
                                                filter={props.filter}
                                                MapPopupContent={MapPopupContent}
                                                labels={props.mapLabelsToDisplayOnClick}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Collapse>
                        </>
                        }

                        <div className="d-flex flex-row album-element align-items-center border p-3"
                            onClick={e => {
                                toggleButton(toolbar2)        
                            }
                        }>
                            <div className="flex-grow-1"
                                aria-controls={toolbar2}
                                aria-expanded={setButtonState[toolbar2]}>
                                    <b>{t('map:map-icon-style-markers')}</b>
                            </div>
                            <div className="d-flex">
                                <Button
                                    variant="secondary"
                                    size="sm"
                                    className="align-self-center"
                                >
                                    {!buttonState[toolbar2] &&
                                        <span>{t('map:more')}</span>
                                    }
                                    {buttonState[toolbar2] &&
                                        <span>{t('map:hide')}</span>
                                    }
                                </Button>
                            </div>
                        </div>
                        <Collapse in={buttonState[toolbar2]} id={toolbar2}>
                            <div className="ml-2 p-2">
                                <div className="border">
                                    <div className="ml-2 mb-3">
                                        <MapIconsToolbar 
                                            ref={{"mapRef" : ref.mapRef, "userDrawingsRef" : ref.userDrawingsRef, "drawRef": ref.drawRef}} 
                                        />
                                    </div>
                                </div>
                            </div>
                        </Collapse>
                        <div className="d-flex flex-row album-element align-items-center border p-3"
                            onClick={e => {
                                toggleButton(toolbar3)        
                            }
                        }>
                            <div className="flex-grow-1"
                                aria-controls={toolbar3}
                                aria-expanded={setButtonState[toolbar3]}>
                                    <b>{t('map:map-icon-style-lines')}</b>
                            </div>
                            <div className="d-flex">
                                <Button
                                    variant="secondary"
                                    size="sm"
                                    className="align-self-center"
                                >
                                    {!buttonState[toolbar3] &&
                                        <span>{t('map:more')}</span>
                                    }
                                    {buttonState[toolbar3] &&
                                        <span>{t('map:hide')}</span>
                                    }
                                </Button>
                            </div>
                        </div>
                        <Collapse in={buttonState[toolbar3]} id={toolbar3}>
                            <div className="ml-2 p-2">
                                <div className="border">
                                    <div className="ml-2 mb-3">
                                        <LineStyleCustomizer />
                                    </div>
                                </div>
                            </div>
                        </Collapse>                                         
                        <div className="d-flex flex-row album-element align-items-center border p-3"
                            onClick={e => {
                                toggleButton(toolbar4)        
                            }
                        }>
                            <div className="flex-grow-1"
                                aria-controls={toolbar4}
                                aria-expanded={setButtonState[toolbar4]}>
                                    <b>{t('map:map-icon-style-polygons')}</b>
                            </div>
                            <div className="d-flex">
                                <Button
                                    variant="secondary"
                                    size="sm"
                                    className="align-self-center"
                                >
                                    {!buttonState[toolbar4] &&
                                        <span>{t('map:more')}</span>
                                    }
                                    {buttonState[toolbar4] &&
                                        <span>{t('map:hide')}</span>
                                    }
                                </Button>
                            </div>
                        </div>
                        <Collapse in={buttonState[toolbar4]} id={toolbar4}>
                            <div className="ml-2 p-2">
                                <div className="border">
                                    <div className="ml-2 mb-3">
                                        <PolygonStyleCustomizer />
                                    </div>
                                </div>
                            </div>
                        </Collapse>


                        <div className="d-flex flex-row album-element align-items-center border p-3"
                            onClick={e => {
                                toggleButton(toolbar5)        
                            }
                        }>
                            <div className="flex-grow-1"
                                aria-controls={toolbar5}
                                aria-expanded={setButtonState[toolbar5]}>
                                    <b>{t('map:observations-style')}</b>
                            </div>
                            <div className="d-flex">
                                <Button
                                    variant="secondary"
                                    size="sm"
                                    className="align-self-center"
                                >
                                    {!buttonState[toolbar5] &&
                                        <span>{t('map:more')}</span>
                                    }
                                    {buttonState[toolbar5] &&
                                        <span>{t('map:hide')}</span>
                                    }
                                </Button>
                            </div>
                        </div>
                        <Collapse in={buttonState[toolbar5]} id={toolbar5}>
                            <div className="ml-2 p-2">
                                <div className="border">
                                    <div className="ml-2 mb-3">                                    
                                        <ObservationsStyleToolbar />
                                    </div>
                                </div>
                            </div>
                        </Collapse>


                        <div className="d-flex flex-row album-element align-items-center border p-3"
                            onClick={e => {
                                toggleButton(toolbar6)        
                            }
                        }>
                            <div className="flex-grow-1"
                                aria-controls={toolbar6}
                                aria-expanded={setButtonState[toolbar6]}
                            >
                                <b>{t('map:administrative-division-style')}</b>
                            </div>
                            <div className="d-flex">
                                <Button
                                    variant="secondary"
                                    size="sm"
                                    className="align-self-center"
                                >
                                    {!buttonState[toolbar6] &&
                                        <span>{t('map:more')}</span>
                                    }
                                    {buttonState[toolbar6] &&
                                        <span>{t('map:hide')}</span>
                                    }
                                </Button>
                            </div>
                        </div>
                        <Collapse in={buttonState[toolbar6]} id={toolbar6}>
                            <div className="ml-2 p-2">
                                <div className="border">
                                    <div className="ml-2 mb-3">                                    
                                        
                                        <AdministrativeDivisionStyleCustomizer />

                                    </div>
                                </div>
                            </div>
                        </Collapse>



                    </div>
            </>
    )
})

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => ({
    
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(IconStyleHandler)