import { useState, useCallback } from 'react';
import query from 'query-string';

import { get_axios } from '../utils/ajax';
import { API_BASE_URL } from '../settings';

const useApi = (method, address) => {
  const [ payload, setPayload ] = useState({ response: {}, loading: false, error: false, data: {} });

  const refetch = useCallback((data, url = '') => {
    setPayload({ response: {}, loading: true, error: false, data: payload.data });
    const fullUrl = `${API_BASE_URL}${address}${url}`;
    let fullPath = fullUrl.split('?')[0];
    let fullParams = fullUrl.split('?')[1]; // can be undefined!
    if (!fullPath.endsWith('/')) {
      fullPath = `${fullPath}/`;
    }
    if (!fullParams) {
      fullParams = '';
      if (method === 'get' && data) {
        fullParams = `?${query.stringify(data, { arrayFormat: 'bracket' })}`;
      }
    }
    else {
      fullParams = `?${fullParams}`;
      if (method === 'get' && data) {
        fullParams = `${fullParams}&${query.stringify(data, { arrayFormat: 'bracket' })}`;
      }
    }
    // WARNING!!! String.prototype.replaceAll() is NOT implemented in Node.js thus not supported in jest tests!
    fullParams = fullParams.replace(/\[\]/g, ''); // param array support - names without []
    const callWithRetry = (axios, options, resolve, reject) =>
      axios(options)
        .then(res => {
          setPayload({ response: res, loading: false, error: false, data: payload.data });
          return resolve(res);
        })
        .catch(err => {
          if (err.code === 'ECONNABORTED' || (err.response && err.response.status === 408)) {
            if (!localStorage.getItem('show-retry-modal')) {
              document.activeElement.blur()
              localStorage.setItem('show-retry-modal', true)
              return new Promise(() => {
                const handler = setInterval(() => {
                  if (!localStorage.getItem('show-retry-modal')) {
                    clearInterval(handler)
                    if (localStorage.getItem('retry')) {
                      return callWithRetry(axios, options, resolve, reject);
                    } else {
                      setPayload({ response: err, loading: false, error: true, data: payload.data });
                      return reject(err);
                    }
                  }
                }, 500)
              })
            }
          } else {
            setPayload({ response: err, loading: false, error: true, data: payload.data });
            return reject(err);
          }
        });
    const prom = new Promise((resolve, reject) => 
      callWithRetry(get_axios(), { method, url: `${fullPath}${fullParams}`, data }, resolve, reject)
    );
    return prom
  }, [ address, method, payload.data ]);

  return { ...payload, refetch };
};
export default useApi;
