import React, { useEffect, useCallback, useState } from "react"
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { isPending, getOutput } from "../../reducers"
import { postDataApi } from '../../actions'
import RedirectLoginWithReturn from "../access/RedirectLoginWithReturn"
import { Container, Button, Card } from "react-bootstrap"
import {
    API_TEAM_GET_INVITATION_DETAILS_ID,
    API_TEAM_GET_INVITATION_DETAILS_URL,
    API_TEAM_ACCEPT_INVITATION_ID,
    API_TEAM_ACCEPT_INVITATION_URL,
    API_TEAM_REJECT_INVITATION_ID,
    API_TEAM_REJECT_INVITATION_URL
} from '../../settings'
import * as notify from '../../utils/notify'

const InvitationAccepted = props => {
    const { t } = useTranslation(['common', 'team', 'paths'])
    const [isRedirect, setIsRedirect] = useState(false)
    const dispatch = useDispatch()
    const invitationId = props.match?.params?.hash || ''

    const getInvitationDetailsCallback = useCallback((invitationId) => {
        return dispatch(postDataApi(API_TEAM_GET_INVITATION_DETAILS_ID, API_TEAM_GET_INVITATION_DETAILS_URL + invitationId + '/', {})).catch(res => {
            if (res?.response?.status !== 401) {
                notify.error(t('team:cannot-get-invitation-details'), res.result)
            }
        })
    }, [dispatch, t])

    useEffect(() => {
        if (invitationId !== '') {
            getInvitationDetailsCallback(invitationId)
        }
    }, [getInvitationDetailsCallback, invitationId]);

    const acceptInvitation = () => {
        props.acceptInvitation(invitationId, t)
    }

    const rejectInvitation = () => {
        props.rejectInvitation(invitationId, t)
    }

    const goToLogin = () => {
        setIsRedirect(true)
    }

    return (
        <Container>
            {isRedirect &&
                <RedirectLoginWithReturn returnUrl={'/team/invitation/' + invitationId} />
            }
            {!props.loading && (!props.user || !props.user.data) &&
                <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '50vh' }}>
                    <Card className="text-center">
                        <Card.Body>
                            <Card.Title>{t('team:you-received-invitation')}</Card.Title>
                            <Card.Text className="mb-4">
                                {t('team:login-or-register')}
                            </Card.Text>
                            <Button variant="primary" className="mr-2" onClick={goToLogin}>{t('common:ok')}</Button>
                        </Card.Body>
                    </Card>
                </div>
            }
            {!props.loading && props.user && props.user.data &&
                <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '50vh' }}>
                    <Card className="text-center">
                        <Card.Body>
                            <Card.Title>{t('team:you-received-invitation')}</Card.Title>
                            <Card.Text className="mb-4">
                                {t('team:user')} {props?.invitationDetails?.user} {t('team:has-sent-you-invitation')} {props?.invitationDetails?.team}
                            </Card.Text>
                            <Button variant="primary" className="mr-2" onClick={acceptInvitation}>{t('common:accept')}</Button>
                            <Button variant="primary" onClick={rejectInvitation}>{t('common:reject')}</Button>
                        </Card.Body>
                    </Card>
                </div>
            }
        </Container>
    )
}

const mapStateToProps = state => ({
    user: state.user,
    loading: isPending(API_TEAM_GET_INVITATION_DETAILS_ID, state) || isPending(API_TEAM_ACCEPT_INVITATION_ID, state) || isPending(API_TEAM_REJECT_INVITATION_ID, state),
    invitationDetails: getOutput(API_TEAM_GET_INVITATION_DETAILS_ID, state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    acceptInvitation: (invitationId, t) => {
        return dispatch(postDataApi(API_TEAM_ACCEPT_INVITATION_ID, API_TEAM_ACCEPT_INVITATION_URL + invitationId + '/', {
        })).then(() => {
            notify.success(t('team:invitation-accepted'))
            ownProps.history.push({ pathname: t('paths:team') })
        }).catch(res => {
            if (res?.response?.status === 409) {
                notify.error(t('team:already-a-member'), res.result)
            } else {
                notify.error(t('team:cannot-accept-invitation'), res.result)
            }
        })
    },
    rejectInvitation: (invitationId, t) => {
        return dispatch(postDataApi(API_TEAM_REJECT_INVITATION_ID, API_TEAM_REJECT_INVITATION_URL + invitationId + '/', {
        })).then(() => {
            notify.success(t('team:invitation-rejected'))
            ownProps.history.push({ pathname: t('paths:team') })
        }).catch(res => {
            notify.error(t('team:cannot-reject-invitation'), res.result)
        })
    }
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InvitationAccepted)