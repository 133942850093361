import { toastr } from 'react-redux-toastr';

const toastrCommonOptions = {
    timeOut: 5000,
    newestOnTop: true,
    preventDuplicates: true,
    removeOnHover: true,
    removeOnHoverTimeOut: 1000,
    position: "bottom-center",
    transitionIn: "fadeIn",
    transitionOut: "fadeOut",
    progressBar: false,
    closeOnToastrClick: true,
    showCloseButton: true
}

const toastrErrorOptions = {
    ...toastrCommonOptions,
    removeOnHoverTimeOut: 0,
};

export const error = (title, message) => {
    toastr.error(title, message, toastrErrorOptions)
};

const toastrSuccessOptions = {
    ...toastrCommonOptions,
};

export const success = (title, message) => {
    toastr.success(title, message, toastrSuccessOptions)
};

const toastrWarningOptions = {
    ...toastrCommonOptions,
};

export const warning = (title, message) => {
    toastr.warning(title, message, toastrWarningOptions)
};

const toastrInfoOptions = {
    ...toastrCommonOptions,
};

export const info = (title, message) => {
    toastr.info(title, message, toastrInfoOptions)
};
