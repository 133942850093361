export const ADD_ANCHOR = 'ADD_ANCHOR'
export const CLEAR_ANCHOR = 'CLEAR_ANCHOR'

export const addAnchor = data => ({
    type: ADD_ANCHOR,
    data: data
})

export const clearAnchor = () => ({
    type: CLEAR_ANCHOR
})