import React, { useState, useEffect, Suspense, useCallback } from "react";
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { requestRemove, postDataApi, requestRemoveStartsWith } from '../../actions'
import { setField } from '../../actions/forms'
import { isPending, getField, getOutputOrDefault } from "../../reducers";
import {
    TAXONDB_DYNAMIC2_SEARCH_REQUEST_ID,
    TAXONDB_DYNAMIC2_SEARCH_SPECIES_REQUEST_ID,
    TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID,
    TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID,
    TAXON_DYNAMIC2_RESULTS_ACTIVE_TAB_FIELD_NAME,
    TAXON_SEARCH_IS_DYNAMIC,
    API_FILTER_GET_ALL_ID,
    API_FILTER_GET_ALL_URL,
    API_FILTER_SET_ID,
    API_FILTER_SET_URL,
    API_ALBUM_GET_ALL_ID,
    API_ALBUM_GET_ALL_URL,
    API_FILTER_DELETE_ID,
    API_FILTER_DELETE_URL,
    AGGREGATION_REPORT_REQUEST_ID_PREFIX,
} from '../../settings'
import { Form, Container, Row, Col, Button, FormLabel, Spinner, Collapse } from "react-bootstrap"
import "../../styles/submenu.scss"
import AddToAlbum from '../profile/AddToAlbum'
import DynamicSearchFormPanel from './DynamicSearchFormPanel2'
import SearchButtons from './SearchButtons'
import Confirm from '../Confirm'
import { sortNot } from '../../utils/search'
import Loader from '../../utils/loader'

const DynamicSearchForm = props => {
    const { t } = useTranslation([ 'common', 'taxondb', 'taxondb-attributes', 'profile', 'forms' ])

    const isAdvancedFilter = obj => {
        const hasDeepOr = Object.keys(obj).filter(i => (i.startsWith('or_') && obj[ i ].filter(j => Object.keys(j).filter(k => (k.startsWith('or_') || k.startsWith('not_'))).length > 0).length)).length > 0

        const hasDeepNot = Object.keys(obj).filter(
            i => (i.startsWith('not_') && Object.keys(obj[ i ]).filter(
                j => (j.startsWith('or_') || j.startsWith('not_'))
            ).length > 0)
        ).length > 0

        const notPanelsWithWrongNumberOfElements = Object.keys(obj).filter(
            i => (i.startsWith('not_') && Object.keys(obj[ i ]).length !== 1)
        ).length > 0

        const orPanelsWithWrongNumberOfElements = Object.keys(obj).filter(
            i => (i.startsWith('or_') && obj[ i ].filter(j => Object.keys(j).length !== 1).length > 0)
        ).length > 0

        const orPanelWithDifferentKeys = Object.keys(obj).filter(
            i => (i.startsWith('or_') && obj[ i ].filter(j => Object.keys(j)[ 0 ] !== Object.keys(obj[ i ][ 0 ])[ 0 ]).length > 0)
        ).length > 0

        return hasDeepOr ||
            hasDeepNot ||
            notPanelsWithWrongNumberOfElements ||
            orPanelsWithWrongNumberOfElements ||
            orPanelWithDifferentKeys
    }

    const [ headerFields, setHeaderFields ] = useState({ filter_name: '', selected_filter_id: null, selected_filter_name: '' })
    const handleFormChange = e => {
        const temp = { ...headerFields }
        temp[ e.target.name ] = e.target.value
        setHeaderFields(temp)
    }

    const { filters } = props;
    useEffect(() => {
        setHeaderFields({
            filter_name: '',
            selected_filter_id: filters.length > 0 ? filters[ 0 ].id : null,
            selected_filter_name: filters.length > 0 ? filters[ 0 ].name : ''
        })
    }, [ filters ])

    const loadFilter = () => {
        const temp = filters.find(val => val.id.toString() === headerFields.selected_filter_id.toString()).definition
        if (isAdvancedFilter(temp)) {
            props.setAdvancedFields(temp)
            props.setIsDynamic()
        } else {
            props.setFields(temp)
        }
        props.clearResponse()
    }

    const dispatch = useDispatch()
    const getFilters = useCallback(() => dispatch(postDataApi(API_FILTER_GET_ALL_ID, API_FILTER_GET_ALL_URL)), [ dispatch ])
    const clearFilters = useCallback(() => dispatch(requestRemove(API_FILTER_GET_ALL_ID)), [ dispatch ])
    const saveFilter = () => {
        props.setFilter({
            name: headerFields.filter_name,
            definition: props.fields
        }).then(() => {
            getFilters()
        })
    }

    const [ attempt, setAttempt ] = useState(false)
    const removeFilter = () => {
        setAttempt(true)
        props.removeFilter({
            filter_id: headerFields.selected_filter_id
        }).then(() => {
            setAttempt(false)
            getFilters()
        });
    }

    useEffect(() => {
        getFilters()
        return clearFilters
    }, [ getFilters, clearFilters ])

    const [ formOpen, setFormOpen ] = useState(false)
    const [ addAlbumOpen, setAddAlbumOpen ] = useState(false)
    return (
        <Form>
            <Container className="mt-2">
                <Row className="dynamic-form-content d-flex flex-column">
                    <Container>
                        <div className="panel-border-bottom pb-2">
                            <DynamicSearchFormPanel {...props} path={[]} elementId="and"></DynamicSearchFormPanel>
                        </div>
                        {!props.hideButtons &&
                            <Form.Group as={Col} md={12} className="d-flex align-items-end mt-1">
                                <div className="flex-grow-1 mr-3 flex-fill">{t('taxondb:form-wildcard-info')}</div>
                            </Form.Group>
                        }
                    </Container>
                </Row>
                {!props.hideButtons &&
                    <Form.Group className="d-flex justify-content-center my-3">
                        <SearchButtons {...props} path requestId={TAXONDB_DYNAMIC2_SEARCH_REQUEST_ID} genusRequestId={TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID} speciesRequestId={TAXONDB_DYNAMIC2_SEARCH_SPECIES_REQUEST_ID} tabId={TAXON_DYNAMIC2_RESULTS_ACTIVE_TAB_FIELD_NAME}></SearchButtons>
                    </Form.Group>
                }
                <Row>
                    <Col>
                        <Button
                            variant="secondary"
                            onClick={() => setFormOpen(!formOpen)}
                            aria-controls="form-open"
                            aria-expanded={formOpen}>
                            {formOpen ? t('taxondb:hide_form_management_panel') : t('taxondb:show_form_management_panel')}
                        </Button>
                    </Col>
                </Row>
                <Suspense fallback={<Loader />}>
                    <Collapse in={formOpen}>
                        <Container id="form-open" className="mt-2">
                            <Row className="dynamic-form">
                                <Form.Group as={Col} md="auto" className="d-flex align-items-end">
                                    <div>
                                        <FormLabel htmlFor="filter_name">{t('taxondb:form_name')}</FormLabel>
                                        <div style={{ position: 'relative' }}>
                                            <Form.Control
                                                onChange={handleFormChange}
                                                value={headerFields.filter_name}
                                                name="filter_name"
                                                id="filter_name"
                                                type="text"
                                                autoComplete="off"
                                                disabled={props.loading ||
                                                    (props.loadingSave && !attempt) ||
                                                    !props.fields ||
                                                    Object.keys(props.fields).length === 0}></Form.Control>
                                            {props.loadingSave && !attempt &&
                                                <Spinner animation="border" role="status" size="sm" as="span" className="spinner-inside-input">
                                                </Spinner>
                                            }
                                        </div>
                                    </div>
                                    <Button variant="secondary" onClick={saveFilter}
                                        disabled={props.loading ||
                                            !headerFields.filter_name ||
                                            props.loadingSave ||
                                            props.loadingLoad ||
                                            props.filters.map(val => val.name).includes(headerFields.filter_name) ||
                                            !props.fields ||
                                            Object.keys(props.fields).length === 0
                                        }
                                        className="ml-1">
                                        {t('forms:save')}
                                    </Button>
                                </Form.Group>
                                <Form.Group as={Col} className="d-flex align-items-end">
                                    <div>
                                        <FormLabel htmlFor="selected_filter">{t('taxondb:saved_forms')}</FormLabel>
                                        <div style={{ position: 'relative' }}>
                                            <Form.Control as="select" name="selected_filter_id" id="selected_filter"
                                                disabled={props.loading || props.loadingLoad || attempt || props.filters.length === 0}
                                                value={headerFields.selected_filter_id || undefined} onChange={handleFormChange}>
                                                {props.filters.map((val, indx) =>
                                                    <option key={indx} value={val.id}>{val.name}</option>
                                                )}
                                            </Form.Control>
                                            {(props.loadingLoad || attempt) &&
                                                <Spinner animation="border" role="status" size="sm" as="span" className="spinner-inside-combo">
                                                </Spinner>
                                            }
                                        </div>
                                    </div>
                                    <Button variant="secondary" onClick={loadFilter}
                                        disabled={props.loading || props.loadingLoad || attempt || props.filters.length === 0} className="ml-1">
                                        {t('forms:load')}
                                    </Button>
                                    <Confirm
                                        onConfirm={removeFilter}
                                        size="md"
                                        buttonLabel={t('forms:remove')}
                                        confirmationText={t('forms:confirm-remove-filterset')}
                                        disabled={props.loading || props.loadingLoad || attempt || props.filters.length === 0}
                                        className="ml-1"
                                    ></Confirm>
                                    <Button variant="primary" onClick={e => {
                                        setAddAlbumOpen(true)
                                        props.getAlbums()
                                    }}
                                        disabled={props.loading || props.loadingLoad || attempt || props.filters.length === 0} className="ml-1">
                                        {t('profile:add-to-album')}
                                    </Button>
                                </Form.Group>
                            </Row>
                            {addAlbumOpen &&
                                <Row>
                                    <AddToAlbum kind="filter" itemId={headerFields.selected_filter_id} onDone={e => setAddAlbumOpen(false)}></AddToAlbum>
                                </Row>
                            }
                        </Container>
                    </Collapse>
                </Suspense>
            </Container>
        </Form >
    )
}

const mapStateToProps = (state, ownProps) => ({
    loading: isPending(ownProps.searchRequestId ? ownProps.searchRequestId : TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID, state),
    fields: JSON.parse(getField(ownProps.searchRequestId ? ownProps.searchRequestId : TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID, ownProps.searchRequestId ? ownProps.searchRequestId : TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID, state, "null")),
    loadingLoad: isPending(API_FILTER_GET_ALL_ID, state),
    loadingSave: isPending(API_FILTER_SET_ID, state),
    filters: getOutputOrDefault(API_FILTER_GET_ALL_ID, state, { "user_filters": [] })[ "user_filters" ]
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    setFields: fields => dispatch(setField(ownProps.searchRequestId ? ownProps.searchRequestId : TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID, ownProps.searchRequestId ? ownProps.searchRequestId : TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID, JSON.stringify(sortNot(fields)))),
    setAdvancedFields: fields => dispatch(setField(ownProps.searchRequestId ? ownProps.searchRequestId : TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, ownProps.searchRequestId ? ownProps.searchRequestId : TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, JSON.stringify(fields))),
    setIsDynamic: () => dispatch(setField(ownProps.isDynamicFlag ? ownProps.isDynamicFlag : TAXON_SEARCH_IS_DYNAMIC, ownProps.isDynamicFlag ? ownProps.isDynamicFlag : TAXON_SEARCH_IS_DYNAMIC, "1")),
    clearResponse: () => {
        if (ownProps.onClear) {
            dispatch(ownProps.onClear())
        } else {
            dispatch(requestRemove(TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID))
            dispatch(requestRemove(TAXONDB_DYNAMIC2_SEARCH_SPECIES_REQUEST_ID))
            dispatch(requestRemove(TAXONDB_DYNAMIC2_SEARCH_REQUEST_ID))
            dispatch(requestRemoveStartsWith(AGGREGATION_REPORT_REQUEST_ID_PREFIX + TAXONDB_DYNAMIC2_SEARCH_REQUEST_ID))
        }
    },
    setFilter: data => dispatch(postDataApi(API_FILTER_SET_ID, API_FILTER_SET_URL, data)),
    getAlbums: () => dispatch(postDataApi(API_ALBUM_GET_ALL_ID, API_ALBUM_GET_ALL_URL)),
    removeFilter: data => dispatch(postDataApi(API_FILTER_DELETE_ID, API_FILTER_DELETE_URL, data)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DynamicSearchForm)
