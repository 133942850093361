import React, { useState } from "react"
import { connect } from 'react-redux'
import { Container,  Spinner, Form, Button, Col } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import {
    ANC_TAXON_SOURCE_FILES_ID,
    ANC_TAXON_SOURCE_FILES_URL,
    ANC_URL_POSTFIX,
    TAXON_SOURCES_PAGE_URL_EN,
    TAXON_SOURCES_PAGE_URL_PL
} from "../../settings"
import { getPagination, isPending, getItems } from "../../reducers";
import { postDataAnc } from "../../actions"
import ConnectedPagination from '../ConnectedPagination'
import { Row } from "react-bootstrap";
import TaxonSourcesListItem from './TaxonSourcesListItem'
import I18nPathTranslator from "../I18nPathTranslator";
import SEO from "../SEO";

const TaxonSourcesContainer = props => {

    const { t } = useTranslation(['forms', 'menu', 'admin-tools'])
    
    const [taxonId, setTaxonId] = useState('')

    const onTaxonIdChange = e => {
        setTaxonId(e.target.value)
    }

    const onClearForm = () => {
        setTaxonId('')
    }

    const onSubmitForm = () => {
        let filter = {}
        if (taxonId){
            filter['id'] = taxonId
        }
        props.getFileSources(filter)
    }

    return (
        <Container>
            <I18nPathTranslator {...props} pathEN={TAXON_SOURCES_PAGE_URL_EN} pathPL={TAXON_SOURCES_PAGE_URL_PL} />
            <SEO title={t('menu:taxon_sources_files')} />
            <div className="mt-3 mb-4">
                <h1>
                    {t('menu:taxon_sources_files')}
                </h1>
            </div>
            <Row>
                <Col md={6}>
                    <Form.Group>
                        <Form.Label htmlFor="taxonId">{t('admin-tools:taxon-id')}</Form.Label>
                        <Form.Control id="taxonId" value={taxonId} onChange={onTaxonIdChange}></Form.Control>
                    </Form.Group>
                </Col>
                <Form.Group as={Col} xs={6} className="d-flex align-self-end">
                        <div className="flex-grow-1" />
                        <Button variant="primary" onClick={onSubmitForm} disabled={props.loadingSourceFiles}>
                            {t('common:search')}
                        </Button>
                        <Button variant="secondary" className="ml-2" onClick={onClearForm} disabled={props.loadingSourceFiles} >
                            {t('common:clear')}
                        </Button>
                </Form.Group>
            </Row>
        
            <div className="ml-4">
                {props.loadingSourceFiles &&
                    <Spinner animation="border" role="status" />
                }
                {props.fileSources && props.fileSources.length === 0 &&
                    <div>
                        {t('admin-tools:no-results')}
                    </div>
                }
                {props.fileSources && props.fileSources.length > 0 &&
                <div>
                    <ConnectedPagination requestId={ANC_TAXON_SOURCE_FILES_ID} url={ANC_URL_POSTFIX + ANC_TAXON_SOURCE_FILES_URL} customFilter={{id: taxonId}}></ConnectedPagination>
                    {props.fileSources.map((source) => {
                        return(
                                <TaxonSourcesListItem key={source.collectionspecimen_id} source={source} />
                            )
                        })
                    }
                    <ConnectedPagination last requestId={ANC_TAXON_SOURCE_FILES_ID} url={ANC_URL_POSTFIX + ANC_TAXON_SOURCE_FILES_URL} customFilter={{id: taxonId}}></ConnectedPagination>
                </div>
                }
            </div>

        </Container>
    )

}

const mapStateToProps = state => ({
    fileSources: getItems(ANC_TAXON_SOURCE_FILES_ID, state),
    loadingSourceFiles: isPending(ANC_TAXON_SOURCE_FILES_ID, state),
    pagination: getPagination(ANC_TAXON_SOURCE_FILES_ID, state),
})

const mapDispatchToProps = dispatch => ({
    getFileSources: id => dispatch(postDataAnc(ANC_TAXON_SOURCE_FILES_ID, ANC_TAXON_SOURCE_FILES_URL, id))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TaxonSourcesContainer)