import React from "react"
import { GENUS_ATTRIBUTE_NAME, RODZAJ_GATUNEK_ATTRIBUTE_NAME, SPECIES_ATTRIBUTE_NAME } from "../../taxonDB/attributesStructure";
import { GetValueFromDictionary } from "../../dictionary/ValueFromDictionary";

export const popupWithLabels = (mapLabels, data, t, collectionTypes, langi18n) => {
    return (
        <div>
        {mapLabels.map(label => {
            if (label === RODZAJ_GATUNEK_ATTRIBUTE_NAME){
                return(
                    <div className="d-flex" style={{ borderTopStyle: "solid", borderTopColor: "#dee2e6", borderTopWidth: "1px" }}>
                        <div className="w-50 p-1">
                            <b>
                                {t('taxondb-attributes:' + label)}
                            </b>:  
                        </div>
                        <div className="w-50 p-1">
                            {GetValueFromDictionary(GENUS_ATTRIBUTE_NAME, data[GENUS_ATTRIBUTE_NAME], langi18n, collectionTypes)}
                            &nbsp;
                            {GetValueFromDictionary(SPECIES_ATTRIBUTE_NAME, data[SPECIES_ATTRIBUTE_NAME], langi18n, collectionTypes)}
                        </div>
                    </div>
                )
            }
            return (
                <div className="d-flex" style={{ borderTopStyle: "solid", borderTopColor: "#dee2e6", borderTopWidth: "1px" }}>
                    <div className="w-50 p-1">
                        <b>{t('taxondb-attributes:' + label)}:</b>
                    </div>
                    <div className="w-50 p-1">
                        {GetValueFromDictionary(label, data[label], langi18n, collectionTypes)}
                    </div>
                </div>
            )    
        })}
        </div>
    )
}