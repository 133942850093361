import React from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { setField, runSearchFlowSamples } from '../../actions/forms'
import { requestRemove, requestRemoveStartsWith } from '../../actions'
import { isPending, getField } from "../../reducers"
import {
    SAMPLES_RESULTS_ACTIVE_TAB_FIELD_NAME,
    SAMPLESDB_SEARCH_REQUEST_ID,
    DICTIONARY_COLLECTION_TYPES,
    COMMON_DICTIONARIES,
    SAMPLES_AGGREGATION_REPORT_REQUEST_ID_PREFIX,
    SAMPLESDB_SUGGEST_REQUEST_PREFIX,
} from '../../settings'
import InputWithAsyncTypeahead from "../InputWithAsyncTypeahead"
import InputWithTypeahead from "../InputWithTypeahead"
import { Form, Button } from "react-bootstrap"
import { deleteElementStructure, getElementStructure, sortNot } from '../../utils/search'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEquals, faNotEqual, faTrashAlt } from '@fortawesome/free-solid-svg-icons'

const DynamicSearchFormElement = props => {
    const { t } = useTranslation([ 'common', 'taxondb', 'samplesdb-attributes' ])

    const removeDynamicField = () => {
        let temp = { ...props.fields }
        if (props.path.length > 1) {
            if (props.path[ 0 ].startsWith('not_')) {
                temp = deleteElementStructure([ props.path[ 0 ] ], temp)
            } else {
                if (temp[ props.path[ 0 ] ].length > 2) {
                    temp = deleteElementStructure([ props.path[ 0 ], props.path[ 1 ] ], temp)
                } else {
                    temp = deleteElementStructure([ props.path[ 0 ], props.path[ 1 ] ], temp)
                    let output = {}
                    Object.keys(temp).forEach(v => {
                        if (v !== props.path[ 0 ]) {
                            output[ v ] = temp[ v ]
                        } else {
                            output = { ...output, ...temp[ v ][ 0 ] }
                        }
                    })
                    temp = output
                }
            }
        } else {
            temp = deleteElementStructure(props.path, temp)
        }
        props.setFields(temp)
        props.clearResponse()
    }

    const handleOnKeyUp = e => {
        if (e.keyCode === 13) { // ENTER
            e.preventDefault()
            props.runSearchFlow()
        }
    }

    const toggleNegation = () => {
        let temp = { ...props.fields }
        const next = Object.keys(temp).reduce((acc, obj) => {
            if (obj.startsWith("or_")) {
                const n = parseInt(obj.substring(3))
                return Math.max(acc, n + 1)
            } else if (obj.startsWith("not_")) {
                const n = parseInt(obj.substring(4))
                return Math.max(acc, n + 1)
            }
            return acc
        }, 0)
        let output = {}
        let createOr = []
        let addToOr = []
        const temp2 = getElementStructure(props.path, temp)
        if (props.path[ 0 ].startsWith('not_')) {
            Object.keys(temp).forEach(v => {
                if (v !== props.path[ 0 ]) {
                    output[ v ] = temp[ v ]
                } else {
                    createOr = Object.keys(temp).filter(i => i === props.path[ 1 ])
                    addToOr = Object.keys(temp).filter(i => (i.startsWith("or_") && temp[ i ].find(j => (props.path[ 1 ] in j))))
                    if (createOr.length === 0 && addToOr.length === 0) {
                        output[ props.path[ 1 ] ] = temp2
                    }
                }
            })
            temp = output
            if (createOr.length) {
                output = {}
                Object.keys(temp).forEach(v => {
                    if (v !== props.path[ 1 ]) {
                        output[ v ] = temp[ v ]
                    } else {
                        output[ "or_" + next ] = [ { [ v ]: temp[ v ] }, { [ v ]: temp2 } ]
                    }
                })
            } else if (addToOr.length) {
                output = {}
                Object.keys(temp).forEach(v => {
                    if (v !== addToOr[ 0 ]) {
                        output[ v ] = temp[ v ]
                    } else {
                        output[ v ] = [ ...temp[ v ], { [ props.path[ 1 ] ]: temp2 } ]
                    }
                })
            }
        } else {
            if (props.path[ 0 ].startsWith('or_')) {
                Object.keys(temp).forEach(v => {
                    if (v !== props.path[ 0 ]) {
                        output[ v ] = temp[ v ]
                    } else {
                        const temp3 = []
                        temp[ v ].forEach((i, indx) => {
                            if (indx !== props.path[ 1 ]) {
                                temp3.push(i)
                            }
                        })
                        if (temp3.length > 1) {
                            output[ v ] = temp3
                        } else {
                            output[ props.path[ 2 ] ] = temp3[ 0 ][ props.path[ 2 ] ]
                        }
                        output[ "not_" + next ] = { [ props.path[ 2 ] ]: temp2 }
                    }
                })
            } else {
                Object.keys(temp).forEach(v => {
                    if (v !== props.path[ 0 ]) {
                        output[ v ] = temp[ v ]
                    } else {
                        output[ "not_" + next ] = { [ props.path[ 0 ] ]: temp2 }
                    }
                })
            }
        }
        props.setFields(output)
        props.clearResponse()
    }

    return (
        <Form.Group className={((!props.path[ 0 ].startsWith('or_') || props.path[ 1 ] === 0) && !props.previous) ? "mt-md-2 mt-sm-2" : "dsafas"}>
            {(!props.path[ 0 ].startsWith('or_') || props.path[ 1 ] === 0) && !props.previous &&
                <Form.Label className="mr-2" htmlFor={props.path.join('-')}>{t('samplesdb-attributes:' + props.elementId)}</Form.Label>
            }
            <div className="d-flex">
                <Button variant="light" onClick={toggleNegation} className="no-radius-right">
                    {props.path[ 0 ].startsWith('not_') &&
                        <FontAwesomeIcon icon={faNotEqual} size="lg" />
                    }
                    {!props.path[ 0 ].startsWith('not_') &&
                        <FontAwesomeIcon icon={faEquals} size="lg" />
                    }
                </Button>
                <div className="no-radius-left no-radius-right">
                    {props.elementId === 'panstwo' &&
                        <InputWithTypeahead
                            url={SAMPLESDB_SUGGEST_REQUEST_PREFIX + props.elementId + "/"}
                            id={props.path.join('-')}
                            disabled={props.loading}
                            formId={SAMPLESDB_SEARCH_REQUEST_ID}
                            onChange={e => props.clearResponse()}
                            onKeyUp={handleOnKeyUp}
                            path={props.path}
                        ></InputWithTypeahead>
                    }
                    {!([
                        'panstwo',
                    ].includes(props.elementId)) &&
                        <InputWithAsyncTypeahead
                            url={SAMPLESDB_SUGGEST_REQUEST_PREFIX + props.elementId + "/"}
                            id={props.path.join('-')}
                            disabled={props.loading}
                            formId={SAMPLESDB_SEARCH_REQUEST_ID}
                            onChange={e => props.clearResponse()}
                            onKeyUp={handleOnKeyUp}
                            path={props.path}
                        ></InputWithAsyncTypeahead>
                    }
                </div>
                <Button variant="light" onClick={removeDynamicField} disabled={props.loading} title={t('forms:remove')} className="no-radius-left">
                    <FontAwesomeIcon icon={faTrashAlt} size="lg" />
                </Button>
            </div>
        </Form.Group>
    )
}

const mapStateToProps = state => ({
    fields: JSON.parse(getField(SAMPLESDB_SEARCH_REQUEST_ID, SAMPLESDB_SEARCH_REQUEST_ID, state, "null")),
    loading: isPending(SAMPLESDB_SEARCH_REQUEST_ID, state),
    collectionTypes: getField(COMMON_DICTIONARIES, DICTIONARY_COLLECTION_TYPES, state, []),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    setFields: fields => dispatch(setField(SAMPLESDB_SEARCH_REQUEST_ID, SAMPLESDB_SEARCH_REQUEST_ID, JSON.stringify(sortNot(fields)))),
    runSearchFlow: () => dispatch(runSearchFlowSamples(SAMPLESDB_SEARCH_REQUEST_ID, SAMPLES_RESULTS_ACTIVE_TAB_FIELD_NAME, ownProps.path)),
    clearResponse: () => {
        dispatch(requestRemove(SAMPLESDB_SEARCH_REQUEST_ID))
        dispatch(requestRemoveStartsWith(SAMPLES_AGGREGATION_REPORT_REQUEST_ID_PREFIX + SAMPLESDB_SEARCH_REQUEST_ID))
    },
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DynamicSearchFormElement)
