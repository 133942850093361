import React, { useState, useEffect, Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import { Spinner, ListGroup, Button, Row, Col } from  "react-bootstrap"; 
import {
    ARRANGEMENT_TOOLS_SEARCH_ON_INIT,
} from '../../../settings';
import useApi from '../../../hooks/useApi';
import usePagination from '../../../hooks/usePagination';
import {
    URL_API_MAP_GET_ALL,
    URL_API_MAP_GET_DEFINITION,
} from "../../../urls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkedAlt } from "@fortawesome/free-solid-svg-icons";
import useMapRedux from '../../../hooks/useMapRedux';
import useFieldRedux from '../../../hooks/useFieldRedux';
import Loader from '../../../utils/loader'
import useMapDefinition from '../../../hooks/useMapDefinition';
import * as notify from '../../../utils/notify'

const LoadMapPanel = props => {
    const { t } = useTranslation(['map'])
    const { setValue: setMapId } = useMapRedux('mapId');
    const { setValue: setMapTitle } = useMapRedux('mapTitle');
    const { setValue: setSearchOnInit } = useFieldRedux(ARRANGEMENT_TOOLS_SEARCH_ON_INIT);
    const { response, refetch, data, loading } = useApi('post', URL_API_MAP_GET_ALL);
    const { Pagination, currentPage, perPage } = usePagination(response, refetch, data);
    const getFilter = () => ({
        pagination: { currentPage, perPage },
    });
    const [ filter ] = useState(getFilter());
    const { refetch: getDefinition, loading: loadingDefinition } = useApi('get', URL_API_MAP_GET_DEFINITION);
    const { setMapState } = useMapDefinition();

    useEffect(() => {
        refetch(filter);
    }, [ refetch, filter ]);

    const goToMap = (item) => {
      getDefinition({}, item.id)
        .then(res => {
          setMapState(res.data);
          setMapTitle(item.name)
          setMapId(item.id)
          setSearchOnInit(true)
        })
        .catch(err => {
          notify.error(t('map:cannot-load-map-definition'))
        })
    }

    return (
      <Suspense fallback={<Loader />}>
            <div>
                {(loading || loadingDefinition) &&
                    <div className="text-center p-5">
                        <Spinner animation="border" role="status" />
                    </div>
                }
                {!loading && !loadingDefinition &&
                    <>
                        <>
                            {response.data?.items?.length > 0 &&
                                <div>
                                    <Pagination></Pagination>
                                    <ListGroup>
                                    {
                                        response.data.items.map((item, index) => {
                                            return (
                                                <ListGroup.Item key={index}>
                                                    <Row>
                                                        <Col className="d-flex align-items-center">
                                                            <img src="/images/list-empty.svg" alt={t("a11y:list-opened")} />
                                                            {item.name}
                                                        </Col>
                                                        <Col sm="auto" className="d-flex flex-row align-self-center">
                                                            <>
                                                                <Button
                                                                    onClick={e => goToMap(item)}
                                                                    size="sm"
                                                                    variant="secondary"
                                                                    className="mr-1" 
                                                                >
                                                                    <FontAwesomeIcon icon={faMapMarkedAlt} title={t('map:load')} />
                                                                </Button>
                                                            </>
                                                        </Col>
                                                    </Row>
                                                </ListGroup.Item>
                                            )
                                        })
                                    }    
                                    </ListGroup>
                                    <Pagination last></Pagination>
                                </div>
                            }
                            {response.data?.items && response.data?.items?.length === 0 &&
                                <div>
                                    { t('map:no-saved-maps')}
                                </div>
                            }
                        </>
                    </>
                }
            </div>
      </Suspense>
    )
}

export default LoadMapPanel
