import React, { Suspense, useState } from "react";
import { useTranslation } from 'react-i18next'
import { Collapse } from "react-bootstrap"
import Loader from '../../utils/loader'
import ProjectsContainer from '../projects/ProjectsContainer'
import MyAlbumContainer from '../profile/MyAlbumContainer'
import ListGroupItemDiv from "../ListGroupItemDiv";

export default props => {
  const { t } = useTranslation([ 'team' ])

  const [ isProjectOpen, setIsProjectOpen ] = useState(false)
  const [ isAlbumOpen, setIsAlbumOpen ] = useState(false)

  const toggleProjectOpen = () => setIsProjectOpen(!isProjectOpen)
  const toggleAlbumOpen = () => setIsAlbumOpen(!isAlbumOpen)

  return (
    <div className="ml-3">
      <ListGroupItemDiv className="d-flex album-element" onClick={e=>{toggleProjectOpen()}}>
        <div>
          {isProjectOpen &&
            <img src="/images/list-open.svg" alt={t("a11y:list-opened")} />
          }
          {!isProjectOpen &&
            <img src="/images/list-closed.svg" alt={t("a11y:list-closed")} />
          }
        </div>
        <div className="w-50">{t('projects-panel')}</div>
      </ListGroupItemDiv>
      <Collapse in={isProjectOpen}>
        <div>
          <Suspense fallback={<Loader />}>
            {isProjectOpen &&
              <ProjectsContainer viewedBy={props.userId}></ProjectsContainer>
            }
          </Suspense>
        </div>
      </Collapse>
      <ListGroupItemDiv className="d-flex album-element" onClick={e=>{toggleAlbumOpen()}}>
        <div>
          {isAlbumOpen &&
            <img src="/images/list-open.svg" alt={t("a11y:list-opened")} />
          }
          {!isAlbumOpen &&
            <img src="/images/list-closed.svg" alt={t("a11y:list-closed")} />
          }
        </div>
        <div className="w-50">{t('albums-panel')}</div>
      </ListGroupItemDiv>
      <Collapse in={isAlbumOpen}>
        <div>
          <Suspense fallback={<Loader />}>
            {isAlbumOpen &&
              <MyAlbumContainer viewedBy={props.userId}></MyAlbumContainer>
            }
          </Suspense>
        </div>
      </Collapse>
    </div>
  )
}
