import React, { useState, useEffect } from "react";
import { connect } from 'react-redux'
import { Redirect } from "react-router-dom"
import * as actions from '../actions'
import * as qs from 'query-string'
import { loginSuccess, loginFailed } from '../actions/user'
import Loader from '../utils/loader'
import { useTranslation } from "react-i18next";


const GoogleCallback = props => {

  const { userLoginWithGoogle } = props
  const [ redirectUrl, setRedirectUrl ] = useState("")
  const { t}  = useTranslation('paths')

  const parsed = qs.parse(props.location.search)
  const { code, state } = parsed

  useEffect(() => {
    if (code) {
      localStorage.removeItem('access')
      localStorage.removeItem('refresh')
      localStorage.removeItem('user')
      userLoginWithGoogle({ code }).then(() => {
        setRedirectUrl(state ? state : "/")
        window.location.reload(true)
      })
    } else {
      window.location.href = t('paths:login')
    }
  }, [ code, state, userLoginWithGoogle, t ])

  return (
    <div className="d-flex justify-content-center">
      <Loader></Loader>
      {redirectUrl &&
        <Redirect push to={redirectUrl}></Redirect>
      }
    </div>
  )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => ({
  userLoginWithGoogle: data => dispatch(actions.postData('login-with-google', '/login_with_google/', data))
    .then(data => dispatch(loginSuccess(data, true)))
    .catch(err => dispatch(loginFailed(err))),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GoogleCallback)
