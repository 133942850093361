import shp from 'shpjs/dist/shp'
import L from 'leaflet';
import Panstwa_3857 from '../../../shapefiles/Panstwa_3857.zip'
import powiaty_3857 from '../../../shapefiles/powiaty_3857.zip'
import wojewodztwa_3857 from '../../../shapefiles/wojewodztwa_3857.zip'
import '../../../utils/map/leaflet.loader'
import * as notify from '../../../utils/notify'
import "leaflet.minichart"
import ReactDOMServer from 'react-dom/server';
import React from "react"
import {TAXON_MAP_CARTODIAGRAM_COLOR_ARRAY} from "../../../settings"


export function generateCartodiagramOfCountries(items, cartogramRef, renderPopup, cartogramParams, loader, t, mapRef, props, groupingField, numberOfChartColors, filter){
    let allPieCharts = L.layerGroup()
    shp(Panstwa_3857)
    .then(function(geojson){
        L.geoJSON(geojson, {
            onEachFeature: (feature, layer) => {
                const countryName = feature.properties.NAME_PL
                if (feature.properties && countryName) {
                    layer.bindPopup(
                        renderPopup(countryName, 0)
                    )
                    layer.setStyle({
                        fillColor : cartogramParams.areaWithoutTaxons,
                        fillOpacity: cartogramParams.areaFillOpacity,
                        opacity: cartogramParams.borderOpacity,
                        color: cartogramParams.administrativeBorderColor
                    }) 
                    if (items.has(countryName)){
                        layer.bindPopup(
                            renderPopup(countryName, items.get(countryName).numberOfTaxons)
                        )
                        layer.setStyle({
                            fillColor : items.get(countryName).color
                        }) 

                        let queryId = `cartodiagram-${countryName}`
                        const filterValue = { ...filter }
                        filterValue.group = {"panstwo": countryName}
                        props.searchWithAggregation(groupingField, filterValue, numberOfChartColors, queryId)
                        .then(res => {
                            let labels = []
                            let data = []
                            let displayedNumbers = 0
                            for (let i = 0; i < numberOfChartColors; i++){
                                if (res.items[i]){
                                    displayedNumbers += res.items[i].liczba_okazow
                                    data.push(res.items[i].liczba_okazow)
                                    labels.push(`${res.items[i][groupingField]}: ${res.items[i].liczba_okazow}`)
                                }
                            }
                            let numberOfUnclasifiedTaxons = res.total - displayedNumbers
                            if (numberOfUnclasifiedTaxons > 0){
                                data.push(numberOfUnclasifiedTaxons)
                                labels.push(`${t("map:others")}: ${numberOfUnclasifiedTaxons}`)
                            }
                            let geoJson = L.geoJSON(feature.geometry)
                            let center = geoJson.getBounds().getCenter()
                            var myBarChart = L.minichart(center, {
                                data: data, 
                                type: "pie",
                                width: zoomToWidthCountries(mapRef._zoom),
                                colors: TAXON_MAP_CARTODIAGRAM_COLOR_ARRAY,
                                labels:  "auto",
                                legend: true,
                                labelMinSize: 10,
                                transitionTime: 1000
                             });
                            myBarChart.bindPopup(legendPopup(countryName, labels))
                            allPieCharts.addLayer(myBarChart)
                        })                       
                    }
                }
            }
        }).addTo(cartogramRef).bringToBack();
    })
    .then(() => {
        notify.success(t("map:cartodiagram-on-success"))
        loader.hide();
        allPieCharts.setZIndex(1000)
        allPieCharts.addTo(cartogramRef)
        mapRef.on('zoom', e => {
            let width = zoomToWidthCountries(e.target._zoom)
            try{
                allPieCharts.eachLayer(layer => {
                    setChartWidth(layer, width)
                })
            }
            catch(e){
                console.log(e)
            }

        })
    })
    .catch(
        function (error) {
            notify.error(t("map:cartogram-error-msg"))
            loader.hide();
            return Promise.reject(error)
        }
    )
}



export function generateCartodiagramOfVoivodeships(items, cartogramRef, renderPopup, cartogramParams, loader, t, mapRef, props, groupingField, numberOfChartColors, filter){
    let allPieCharts = L.layerGroup()
    shp(wojewodztwa_3857)
    .then(function(geojson){
        L.geoJSON(geojson, {
            onEachFeature: (feature, layer) => {
                const areaName = feature.properties.jpt_nazwa_
                if (feature.properties && areaName) {
                    layer.bindPopup(
                        renderPopup(areaName, 0)
                    )
                    layer.setStyle({
                        fillColor : cartogramParams.areaWithoutTaxons,
                        fillOpacity: cartogramParams.areaFillOpacity,
                        opacity: cartogramParams.borderOpacity,
                        color: cartogramParams.administrativeBorderColor
                    }) 
                    if (items.has(areaName)){
                        layer.bindPopup(
                            renderPopup(areaName, items.get(areaName).numberOfTaxons)
                        )
                        layer.setStyle({
                            fillColor : items.get(areaName).color
                        }) 
                        let queryId = `cartodiagram-${areaName}`
                        const filterValue = { ...filter }
                        filterValue.group = {"wojewodztwo": areaName}

                        props.searchWithAggregation(groupingField, filterValue, numberOfChartColors, queryId)
                        .then(res => {
                            let labels = []
                            let data = []
                            let displayedNumbers = 0
                            for (let i = 0; i < numberOfChartColors; i++){
                                if (res.items[i]){
                                    displayedNumbers += res.items[i].liczba_okazow
                                    data.push(res.items[i].liczba_okazow)
                                    labels.push(`${res.items[i][groupingField]}: ${res.items[i].liczba_okazow}`)
                                }
                            }
                            let numberOfUnclasifiedTaxons = res.total - displayedNumbers
                            if (numberOfUnclasifiedTaxons > 0){
                                data.push(numberOfUnclasifiedTaxons)
                                labels.push(`${t("map:others")}: ${numberOfUnclasifiedTaxons}`)
                            }
                            let geoJson = L.geoJSON(feature.geometry)
                            let center = geoJson.getBounds().getCenter()
                            var myBarChart = L.minichart(center, {
                                data: data, 
                                type: "pie",
                                width: zoomToWidthVoivodeships(mapRef._zoom),
                                labels:  "auto",
                                colors: TAXON_MAP_CARTODIAGRAM_COLOR_ARRAY,
                                legend: true,
                                labelMinSize: 10,
                                transitionTime: 1000
                             });
                            myBarChart.bindPopup(legendPopup(areaName, labels))
                            allPieCharts.addLayer(myBarChart)
                        })
                    }
                }
            }
        }).addTo(cartogramRef).bringToBack();
    })
    .then(() => {
        notify.success(t("map:cartodiagram-on-success"))
        loader.hide();
        allPieCharts.setZIndex(1000)
        allPieCharts.addTo(cartogramRef)
        mapRef.on('zoom', e => {
            let width = zoomToWidthVoivodeships(e.target._zoom)
            try{
                allPieCharts.eachLayer(layer => {
                    setChartWidth(layer, width)
                })
            }
            catch(e){
                console.log(e)
            }
        })
    })
    .catch(
        function (error) {
            notify.error(t("map:cartogram-error-msg"))
            loader.hide();
            return Promise.reject(error)
        }
    )
}


export function generateCartodiagramOfDistricts(items, cartogramRef, renderPopup, cartogramParams, loader, t, mapRef, props, groupingField, numberOfChartColors, filter){

    let allPieCharts = L.layerGroup()

    shp(powiaty_3857)
    .then(function(geojson){
        L.geoJSON(geojson, {
            onEachFeature: (feature, layer) => {
                const areaName = feature.properties.jpt_nazwa_
                const districtNameString = "powiat " + areaName
                if (feature.properties && feature.properties.jpt_nazwa_) {
                    layer.bindPopup(
                        renderPopup(feature.properties.jpt_nazwa_, 0)
                    )
                    layer.setStyle({
                        fillColor : cartogramParams.areaWithoutTaxons,
                        fillOpacity: cartogramParams.areaFillOpacity,
                        opacity: cartogramParams.borderOpacity,
                        color: cartogramParams.administrativeBorderColor
                    }) 
                   
                    if (items.has(districtNameString)){
                        layer.bindPopup(
                            renderPopup(areaName, items.get(districtNameString).numberOfTaxons)
                        )
                        layer.setStyle({
                            fillColor : items.get(districtNameString).color
                        }) 
                        let queryId = `cartodiagram-${areaName}`
                        const filterValue = { ...filter }
                        filterValue.group = {"powiat": districtNameString}

                        props.searchWithAggregation(groupingField, filterValue, numberOfChartColors, queryId)
                        .then(res => {
                            let labels = []
                            let data = []
                            let displayedNumbers = 0
                            for (let i = 0; i < numberOfChartColors; i++){
                                if (res.items[i]){
                                    displayedNumbers += res.items[i].liczba_okazow
                                    data.push(res.items[i].liczba_okazow)
                                    labels.push(`${res.items[i][groupingField]}: ${res.items[i].liczba_okazow}`)
                                }
                            }
                            let numberOfUnclasifiedTaxons = res.total - displayedNumbers
                            if (numberOfUnclasifiedTaxons > 0){
                                data.push(numberOfUnclasifiedTaxons)
                                labels.push(`${t("map:others")}: ${numberOfUnclasifiedTaxons}`)
                            }
                            let geoJson = L.geoJSON(feature.geometry)
                            let center = geoJson.getBounds().getCenter()
                            var myBarChart = L.minichart(center, {
                                data: data, 
                                type: "pie",
                                width: zoomToWidthDistricts(mapRef._zoom),
                                labels:  "auto",
                                colors: TAXON_MAP_CARTODIAGRAM_COLOR_ARRAY,
                                legend: true,
                                labelMinSize: 10,
                                transitionTime: 1000
                             });
                            myBarChart.bindPopup(legendPopup(areaName, labels))
                            allPieCharts.addLayer(myBarChart)
                        })
                    }



                }
            }
        }).addTo(cartogramRef).bringToBack();
    })
    .then(() => {
        notify.success(t("map:cartodiagram-on-success"))
        loader.hide();
        allPieCharts.setZIndex(1000)
        allPieCharts.addTo(cartogramRef)
        mapRef.on('zoom', e => {
            let width = zoomToWidthDistricts(e.target._zoom)
            try{
                allPieCharts.eachLayer(layer => {
                    setChartWidth(layer, width)
                })
            }
            catch(e){
                console.log(e)
            }
        })
    })
    .catch(
        function (error) {
            notify.error(t("map:cartogram-error-msg"))
            loader.hide();
            return Promise.reject(error)
        }
    )
}



const legendPopup = (groupingFieldValue, taxons) => {
    return ReactDOMServer.renderToString(
        <div style={{"fontSize": "16px"}}>
            <div style={{"textAlign": "center", "paddingBottom": "5px"}}><b>{groupingFieldValue}</b></div>
            {taxons.map((taxon, index) => {
                return (<div key={`${groupingFieldValue}-${index}`}> <span style={{color: TAXON_MAP_CARTODIAGRAM_COLOR_ARRAY[index]}}>&#9724;</span>  {taxon}</div>)
            })}
        </div>
    )
}

const setChartWidth = (chart, width) => {
    chart.setOptions({
        width: width
    })
}

const zoomToWidthCountries = (zoom) => {
    let width = {
        "1": 20,
        "2": 20,
        "3": 25,
        "4": 35,
        "5": 50,
        "6": 75,
        "7": 90,
        "8": 130,
        "9": 180,
        "10": 225,
        "11": 300,
        "12": 500,
    }

    return width[zoom]
}

const zoomToWidthVoivodeships  = (zoom) => {
    let width = {
        "1": 10,
        "2": 10,
        "3": 10,
        "4": 15,
        "5": 35,
        "6": 50,
        "7": 70,
        "8": 100,
        "9": 150,
        "10": 175,
        "11": 200,
        "12": 300,
    }

    return width[zoom]
}

const zoomToWidthDistricts = (zoom) => {
    let width = {
        "1": 6,
        "2": 8,
        "3": 10,
        "4": 12,
        "5": 18,
        "6": 25,
        "7": 35,
        "8": 40,
        "9": 100,
        "10": 175,
        "11": 200,
        "12": 300,
    }

    return width[zoom]
}
