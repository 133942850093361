
export const CYTOWANIE_FIELD_NAME = 'cytowanie'
export const AUTORZY_FIELD_NAME = 'autorzy'
export const ROK_PUBLIKACJI_FIELD_NAME = 'rok_publikacji'
export const TYTUL_PUBLIKACJI_FIELD_NAME = 'tytul_publikacji'
export const SLOWA_KLUCZOWE_FIELD_NAME = 'slowa_kluczowe'
export const TYP_PUBLIKACJI_FIELD_NAME = 'typ_publikacji'
export const NUMER_PUBLIKACJI_FIELD_NAME = 'numer_publikacji'

export const TYP_PUBLIKACJI_VALUE_1 = 'czasopismo'
export const TYP_PUBLIKACJI_VALUE_2 = 'monografia'
export const TYP_PUBLIKACJI_VALUE_3 = 'rozdział'

export const SPECIMENS_FIELD_NAME = 'specimens'
export const SAMPLES_FIELD_NAME = 'samples'
