import React, { useState } from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import * as actions from '../../actions'
import {
    MAXIMUM_EXCEL_ROWS,
    TAXONDB_EXPORTFILE_REQUEST_PREFIX,
} from '../../settings'
import { getPagination, getFilter, isPending } from "../../reducers";
import { Button, Jumbotron, Row, Dropdown, DropdownButton, Modal, Form, Spinner, ButtonToolbar } from "react-bootstrap"
import { API_URL_POSTFIX } from "../../settings"

const ProjectsExporter = props => {

    const { t, i18n } = useTranslation([ 'common', 'forms', 'taxondb' ])
    const [ type, setType ] = useState({});
    const [ confirmShow, setConfirmShow ] = useState(false);
    const [ csvOptionsShow, setCsvOptionsShow ] = useState(false);
    const [ notLoggedInModalShow, setNotLoggedInModalShow ] = useState(false);

    /* CSV customization by user */
    const [ separator, setSeparator ] = useState(';')
    const [ decimalSeparator, setDecimalSeparator ] = useState('.')
    const [ codingType, setCodingType ] = useState('iso-8859-2')

    const handleExportFile = (reportType, options) => {
        let filter = {}
        filter.project_id = props.projectId
        filter.options = options
        filter.reportType = reportType
        filter.language = i18n.languages[0]
        props.exportFile(filter)
    }


    const confirmGenerateReportWithLimitedRowsNum = () => {
        handleExportFile(type)
        setType({})
    }

    const ConfirmReportModal = props => (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {t('taxondb:report-confirm-modal-title')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    {t('taxondb:report-confirm-modal-info-1', { MAXIMUM_EXCEL_ROWS: MAXIMUM_EXCEL_ROWS })}
                </div>
                <div>
                    {t('taxondb:report-confirm-modal-info-2')}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => {
                    confirmGenerateReportWithLimitedRowsNum()
                    setConfirmShow(false)
                }}>
                    {t('taxondb:download')}
                </Button>
                <Button onClick={props.onHide}>{t('close')}</Button>
            </Modal.Footer>
        </Modal>
    )

    const NotLoggedInModal = props => (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h6><b>{t('forms:requirelogin')}</b></h6>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {t('forms:notloggedinfo')}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>{t('close')}</Button>
            </Modal.Footer>
        </Modal>
    )

    const handleRadioButton = e => {
        const { name, value } = e.target;
        if (name === 'separator') setSeparator(value)
        if (name === 'coding') setCodingType(value)
        if (name === 'decimal-separator') setDecimalSeparator(value)
    };

    const CsvOptionsCustomizer = props => (
        <Jumbotron>
            <div className="ml-2 mb-1">
                <b>{t('common:customize_csv')}</b>
            </div>
            <div className="d-flex flex-column align-items-start">
                <Form>
                    <Form.Group as={Row} className="ml-2" required>
                        <Form.Label column sm={3}>
                            {t('common:encoding_format')}
                        </Form.Label>
                        <Form.Group className="d-flex flex-row align-self-center" sm={9} >
                            <Form.Check inline className="ml-3" type='radio' name='coding' value='iso-8859-2' label='ISO-8859-2' checked={codingType === 'iso-8859-2'} onChange={handleRadioButton} />
                            <Form.Check inline className="ml-3" type='radio' name='coding' value='utf-8' label='UTF-8' checked={codingType === 'utf-8'} onChange={handleRadioButton} />
                            <Form.Check inline className="ml-3" type='radio' name='coding' value='windows-1250' label='windows-1250' checked={codingType === 'windows-1250'} onChange={handleRadioButton} />
                        </Form.Group>
                    </Form.Group>
                    <Form.Group as={Row} className="ml-2" required>
                        <Form.Label column sm={3}>
                            {t('common:separator_type')}
                        </Form.Label>
                        <Form.Group className="d-flex flex-row align-self-center" sm={9} >
                            <Form.Check inline className="ml-3" name="separator" type='radio' value=';' label={'; ' + t('common:semicolon')} checked={separator === ';'} onChange={handleRadioButton} />
                            <Form.Check inline className="ml-3" name="separator" type='radio' value=',' label={', ' + t('common:comma')} checked={separator === ','} onChange={handleRadioButton} />
                        </Form.Group>
                    </Form.Group>
                    <Form.Group as={Row} className="ml-2" required>
                        <Form.Label column sm={3}>
                            {t('common:decimal_separator')}
                        </Form.Label>
                        <Form.Group className="d-flex flex-row align-self-center" sm={9} >
                            <Form.Check inline className="ml-3" name="decimal-separator" type='radio' value='.' label={'. ' + t('common:dot')} checked={decimalSeparator === '.'} onChange={handleRadioButton} />
                            <Form.Check inline className="ml-3" name="decimal-separator" type='radio' value=',' label={', ' + t('common:comma')} checked={decimalSeparator === ','} onChange={handleRadioButton} />
                        </Form.Group>
                    </Form.Group>
                    <Form.Group as={Row} className="ml-2" required>
                        <Button type="submit" method="post" variant="primary" className="ml-3" onClick={(event) => {
                            event.preventDefault()
                            const options = { "separator": separator, "coding": codingType, "decimalSeparator": decimalSeparator };
                            handleExportFile('csv', options)
                            setCsvOptionsShow(false)
                        }}>
                            {t('common:confirm_csv_form')}
                        </Button>
                        <Button
                            variant="secondary"
                            className="ml-2"
                            onClick={() => setCsvOptionsShow(false)}
                        >
                            {t('close')}
                        </Button>
                    </Form.Group>
                </Form>
            </div>
        </Jumbotron>
    )

    return (
        <div>
            {props.user &&
                <div>
                    <div className="d-flex align-items-center">
                        <DropdownButton id="dropdown-basic-button" title={t('exportfile')} disabled={props.downloading}>
                            <Dropdown.Item onClick={() => handleExportFile("xlsx")}>XLSX</Dropdown.Item>
                            <Dropdown.Item onClick={() => setCsvOptionsShow(true)}>CSV</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleExportFile("xls")}>XLS</Dropdown.Item>
                            <ConfirmReportModal show={confirmShow} onHide={() => setConfirmShow(false)} />
                        </DropdownButton>
                        {props.children}
                        {props.downloading &&
                            <Spinner
                                className="ml-2"
                                as="div"
                                animation="border"
                                size="md"
                                role="status"
                                aria-hidden="true"
                            />
                        }
                    </div>
                    {csvOptionsShow &&
                        <CsvOptionsCustomizer />
                    }
                </div>
            }
            {!props.user &&
                <ButtonToolbar>
                    <Button variant="primary" onClick={() => setNotLoggedInModalShow(true)}>
                        {t('exportfile')}
                    </Button>
                    <NotLoggedInModal show={notLoggedInModalShow} onHide={() => setNotLoggedInModalShow(false)} />
                </ButtonToolbar>
            }
        </div>
    )

}

const mapStateToProps = (state, ownProps) => ({
    downloading: isPending(TAXONDB_EXPORTFILE_REQUEST_PREFIX + ownProps.requestId, state),
    filter: getFilter(ownProps.requestId, state),
    pagination: getPagination(ownProps.requestId, state),
    user: state.user ? state.user.data : null,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    exportFile: data => dispatch(actions.postReportDataObservations(TAXONDB_EXPORTFILE_REQUEST_PREFIX + ownProps.requestId,
        '/project/export_file/' + data.reportType + '/',
        data,
        API_URL_POSTFIX))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectsExporter)
