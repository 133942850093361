import React, {useState, useRef} from "react";
import { useTranslation } from 'react-i18next'
import { Overlay, Popover, Button } from "react-bootstrap"

const InfoIconPopover = props => {
    const { t } = useTranslation([ 'common' ])
    const [show, setShow] = useState(false)
    const target = useRef()
    const ref = useRef()

    return(
        <div ref={ref} className="info-icon-popover-container" onBlur={e => {if(!props.hasFocusable){setShow(false)}}}>
            <Overlay 
                target={target.current} 
                show={show} 
                container={ref.current} containerPadding={20} 
                className="info-icon" 
                rootClose={true} trigger="click" 
                flip placement={props.placement ? props.placement : "auto"}
                onHide={e => setShow(false)}
            >
                <Popover onFocus={e => setShow(true)} onBlur={e => setShow(false)}>
                    {props.children}
                </Popover>
            </Overlay>
            <Button ref={target} variant="link" onClick={e => setShow(!show)} size="sm" className="ml-2" title={t('additional-info')}>
                <div className="search-form-info-icon">
                    <img src="/images/info-icon.png" alt={t('additional-info')} height="20"></img>
                </div>
            </Button>
        </div>
    )
}

export default InfoIconPopover
