import React, { useEffect, useState, useCallback } from "react"
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { isPending, getOutput } from "../../reducers"
import { postDataAnc } from '../../actions'
import { Form, Col, Button } from "react-bootstrap"
import {
    COLLECTION_TYPES_REQUEST_ID,
    COLLECTION_TYPES_EDIT_REQUEST_ID,
    COLLECTION_TYPES_EDIT_REQUEST_URL
} from '../../settings'
import * as notify from '../../utils/notify'

const EditTypes = props => {
    const { t } = useTranslation([ 'collections' ])
    const { collectionTypes, loadingCollectionTypes, typeId } = props
    const [ kind, setKind ] = useState('')
    const [ descriptionPl, setDescriptionPl ] = useState('')
    const [ descriptionEn, setDescriptionEn ] = useState('')

    const getKindForTypeCallback = useCallback((type) => {
        if ((collectionTypes === null || collectionTypes.length === 0) || type === '') {
            return ''
        } else {
            return collectionTypes.filter(t => t.typ_kolekcji === type)[ 0 ].rodzaj_kolekcji || ''
        }
    }, [collectionTypes]);

    const getDescriptionPlForTypeCallback = useCallback((type) => {
        if ((collectionTypes === null || collectionTypes.length === 0) || type === '') {
            return ''
        } else {
            return collectionTypes.filter(t => t.typ_kolekcji === type)[ 0 ].opis_pl || ''
        }
    }, [collectionTypes]);

    const getDescriptionEnForTypeCallback = useCallback((type) => {
        if ((collectionTypes === null || collectionTypes.length === 0) || type === '') {
            return ''
        } else {
            return collectionTypes.filter(t => t.typ_kolekcji === type)[ 0 ].opis_eng || ''
        }
    }, [collectionTypes]);

    useEffect(() => {
        if (collectionTypes !== null && collectionTypes.length > 0) {
            setKind(getKindForTypeCallback(typeId))
            setDescriptionPl(getDescriptionPlForTypeCallback(typeId))
            setDescriptionEn(getDescriptionEnForTypeCallback(typeId))
        }

    }, [ collectionTypes, loadingCollectionTypes, typeId, getKindForTypeCallback, getDescriptionPlForTypeCallback, getDescriptionEnForTypeCallback ])

    const onKindChange = e => {
        if (e.target.value !== 'brak' && e.target.value !== '') {
            setKind(e.target.value)
        }
    }

    const onDescriptionPlChange = e => {
        setDescriptionPl(e.target.value)
    }

    const onDescriptionEnChange = e => {
        setDescriptionEn(e.target.value)
    }

    const getCollectionKinds = () => {
        if (collectionTypes === null) {
            return []
        } else {
            return [ ...new Set(collectionTypes.map(t => t.rodzaj_kolekcji)) ].filter(k => k !== 'brak')
        }
    }

    const onSubmitForm = () => {
        props.editCollectionType(typeId, kind, descriptionPl, descriptionEn, t)
    }

    return (
        <Form className="mt-2 p-1 mb-2" onSubmit={e => { e.preventDefault() }}>
            <Form.Row>
                <Col xs={6}>
                    <Form.Group>
                        <Form.Label htmlFor="collectionTypeKind">{t('collections:kind')}</Form.Label>
                        <Form.Control id="collectionTypeKind" as="select" value={kind} onChange={onKindChange} disabled={props.querying}>
                            {getCollectionKinds().map((kind, i) =>
                                <option key={i} value={kind}>{kind}</option>
                            )}
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col xs={6}></Col>
            </Form.Row>
            <Form.Row>
                <Col>
                    <Form.Group>
                        <Form.Label htmlFor="collectionTypeDescriptionPl">{t('collections:description-pl')}</Form.Label>
                        <Form.Control id="collectionTypeDescriptionPl" value={descriptionPl} onChange={onDescriptionPlChange} disabled={props.querying}></Form.Control>
                    </Form.Group>
                </Col>
            </Form.Row>
            <Form.Row>
                <Col>
                    <Form.Group>
                        <Form.Label htmlFor="collectionTypeDescriptionEn">{t('collections:description-en')}</Form.Label>
                        <Form.Control id="collectionTypeDescriptionEn" value={descriptionEn} onChange={onDescriptionEnChange} disabled={props.querying}></Form.Control>
                    </Form.Group>
                </Col>
            </Form.Row>
            <Form.Row className="d-flex">
                <Col className="text-right">
                    <Button size="sm" disabled={props.querying} onClick={onSubmitForm}>{t('collections:save-collection-type')}</Button>
                </Col>
            </Form.Row>
        </Form>
    )
}

const mapStateToProps = state => ({
    collectionTypes: getOutput(COLLECTION_TYPES_REQUEST_ID, state),
    querying: isPending(COLLECTION_TYPES_REQUEST_ID, state) || isPending(COLLECTION_TYPES_EDIT_REQUEST_ID, state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    editCollectionType: (collectionType, collectionKind, descriptionPl, descriptionEn, t) => {
        return dispatch(postDataAnc(COLLECTION_TYPES_EDIT_REQUEST_ID, COLLECTION_TYPES_EDIT_REQUEST_URL, {
            collection_type: collectionType,
            collection_kind: collectionKind,
            description_pl: descriptionPl,
            description_eng: descriptionEn
        })).then(() => {
            ownProps.onDone && ownProps.onDone()
            notify.success(t('collections:edited-collection-type'))
        }).catch(res => {
            notify.error(t('collections:cannot-edit-collection-type'), res.result)
        })
    }
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditTypes)
