import React, { Suspense } from "react";
import { Container } from "react-bootstrap"
import { useTranslation } from 'react-i18next'
import { ABOUT_US_PAGE_URL_EN, ABOUT_US_PAGE_URL_PL } from "../settings";
import Loader from '../utils/loader'
import ExternalLinkPopover from "./ExternalLinkPopover";
import I18nPathTranslator from "./I18nPathTranslator";
import SEO from "./SEO";

const Regulamin = props => {
  const { t } = useTranslation(["about_us","menu"])

  return (
    <Suspense fallback={<Loader />}>
      <I18nPathTranslator {...props} pathEN={ABOUT_US_PAGE_URL_EN} pathPL={ABOUT_US_PAGE_URL_PL} />
      <SEO title={t('menu:about-us')} />
      <div className="about-us-page">
        <Container>
          <div>
            <h1>{t('menu:about-us')}</h1>
            <p><strong>{t('lead')}</strong></p>
	    <ul>
	    <li>{t('bullet-1')}</li>
	    <p>{t('bullet-1-a')}</p>
	    <li>{t('bullet-2')}</li>
	    <li>{t('bullet-3')}</li>
	    <li>{t('bullet-4')}</li>
	    <li>{t('bullet-5')}</li>
	    <p>{t('bullet-5-a')}</p>
	    <p>{t('bullet-5-b')}</p>
	    <p>{t('bullet-5-c')}</p>
	    </ul>
            <strong><ExternalLinkPopover btnClass="p-font-size" link={t('link-amunatcoll')} title={t('link-amunatcoll-name')} placement="bottom">{t('link-amunatcoll-name')}</ExternalLinkPopover></strong>
            <strong><ExternalLinkPopover btnClass="p-font-size" link={t('link-WB')} title={t('link-WB-name')} placement="bottom">{t('link-WB-name')}</ExternalLinkPopover></strong>
            <strong><ExternalLinkPopover btnClass="p-font-size" link={t('link-amu')} title={t('link-amu-name')} placement="bottom">{t('link-amu-name')}</ExternalLinkPopover></strong>
            <strong><ExternalLinkPopover btnClass="p-font-size" link={t('link-psnc')} title={t('link-psnc-name')} placement="bottom">{t('link-psnc-name')}</ExternalLinkPopover></strong>
          </div>
        </Container>
      </div >
    </Suspense >
  )
}

export default Regulamin
