import React, { Suspense, useRef, useState } from "react";
import { getDataApi } from "../../actions"
import { connect } from 'react-redux'
import { isPending } from "../../reducers";
import { useTranslation } from 'react-i18next';
import {
    API_GET_VISITS_PER_DAY_ID,
    API_GET_VISITS_PER_MONTH_ID,
    API_GET_VISITS_PER_DAY_URL,
    API_GET_VISITS_PER_MONTH_URL,
    API_GET_CUMULATIVE_VISITS_PER_DAY_ID,
    API_GET_CUMULATIVE_VISITS_PER_MONTH_ID,
    API_GET_CUMULATIVE_VISITS_PER_DAY_URL,
    API_GET_CUMULATIVE_VISITS_PER_MONTH_URL
} from '../../settings'
import { Row, Col, Button, Form, FormLabel, Spinner, Popover, Overlay } from "react-bootstrap";
import { setChartType, setPresentationType, calculateDaysBehind, setDaysScope, setDisplayedData, setInterval, setMonthlyData } from '../../actions/chart'
import VisitsChart from "./VisitsChart"
import MonthlyVisitChart from "./MonthlyVisitChart"
import Loader from '../../utils/loader'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import Calendar from "react-calendar";

function VisitsReportsHandler(props) {

    const { t } = useTranslation(['reports'])

        
    const dateToMonth = date => {
        let formatedDate = date.getFullYear()
        formatedDate += "-"
        let month = date.getMonth()+1
        if(month < 10)
            month = "0"+month
        formatedDate += month
        return formatedDate
    }

    const monthToDate = (yyyyMM) => {
        const pattern = /^[0-9]{4}-[0-9]{2}$/
        if(!pattern.test(yyyyMM))
            return undefined
        const year = yyyyMM.slice(0,4)
        const month = yyyyMM.slice(5,7)-1
        return new Date(year, month)
    }

    const [monthFrom, setMonthFrom] = useState(props.charts.monthFrom ? props.charts.monthFrom : dateToMonth(new Date()) )
    const [monthTo, setMonthTo] = useState(props.charts.monthFrom ? props.charts.monthFrom : dateToMonth(new Date()) )
    const [showFrom, setShowFrom] = useState(false)
    const targetFrom = useRef()
    const [showTo, setShowTo] = useState(false)
    const targetTo = useRef()

    const onMonthFromChange = date => {
        setMonthFrom(dateToMonth(date))
    }

    const onMonthToChange = date => {
        setMonthTo(dateToMonth(date))
    }


    const MONTH_CONST = 'month'
    const DAY_CONST = 'day'
    const CHART_TYPE_NORMAL = 'normal'
    const CHART_TYPE_ACCUMULATED = 'accumulated'
    const CHART_TYPE_BAR = 'bar'
    const CHART_TYPE_LINE = 'line'

    const handleFormPresentationTypeChange = e => {
        props.setChartType(e.target.value)
    }

    const handleAccumulate = e => {
        if (props.charts.interval === DAY_CONST)
            fetchChartsData(props.charts.dayFrom, props.charts.dayTo, e.target.value)
        else if (props.charts.interval === MONTH_CONST)
            fetchChartsDataMonths(props.charts.monthFrom, props.charts.monthTo, e.target.value)
        props.setPresentationType(e.target.value)
    }


    const handleDateFetchDaily = (event) => {
        event.preventDefault()

        let dateStart = event.target.elements.dateFrom.value;
        let dateEnd = event.target.elements.dateTo.value;

        let startDate = new Date(dateStart);
        let endDate = new Date(dateEnd);

        let endDateString = `${endDate.getFullYear()}-${('0' + (endDate.getMonth() + 1)).slice(-2)}-${('0' + endDate.getDate()).slice(-2)}`
        let startDateString = `${startDate.getFullYear()}-${('0' + (startDate.getMonth() + 1)).slice(-2)}-${('0' + startDate.getDate()).slice(-2)}`

        fetchChartsData(startDateString, endDateString)
        props.setDaysScope({ "dayFrom": startDateString, "dayTo": endDateString })

        /*
        // OPTIONAL FEATURE: PREVENT DISPLAY WITH DAILY INTERVAL FOR PERIOD LONGER THAN 3 MONTHS [TO FIX]
        let days_difference = (endDate - startDate)  / (1000 * 3600 * 24);
        if (days_difference <= 90 ){
            props.setInterval({"interval":DAY_CONST}) 
        }
        else {
            props.setInterval({"interval":MONTH_CONST})
        }
        */
    }

    const handleDateFetchMonthly = (event) => {
        event.preventDefault()
        fetchChartsDataMonths(event.target.elements.dateFrom.value, event.target.elements.dateTo.value)
        props.setInterval({ "interval": MONTH_CONST })
    }


    const fetchChartsData = (dayFrom, dayTo, type) => {
        let ID_VALUE, URL_VALUE;
        if (type === CHART_TYPE_NORMAL) {
            ID_VALUE = API_GET_VISITS_PER_DAY_ID
            URL_VALUE = API_GET_VISITS_PER_DAY_URL
        }
        else if (type === CHART_TYPE_ACCUMULATED) {
            ID_VALUE = API_GET_CUMULATIVE_VISITS_PER_DAY_ID
            URL_VALUE = API_GET_CUMULATIVE_VISITS_PER_DAY_URL
        }
        else if (props.charts.presentationType === CHART_TYPE_NORMAL) {
            ID_VALUE = API_GET_VISITS_PER_DAY_ID
            URL_VALUE = API_GET_VISITS_PER_DAY_URL
        }
        else if (props.charts.presentationType === CHART_TYPE_ACCUMULATED) {
            ID_VALUE = API_GET_CUMULATIVE_VISITS_PER_DAY_ID
            URL_VALUE = API_GET_CUMULATIVE_VISITS_PER_DAY_URL
        }
        props.getVisitsData(
            ID_VALUE, URL_VALUE +
            dayFrom + "/" + dayTo + "/"
        )
            .then((response) => {
                let _users = []
                let _visits = []
                let _visitors = []
                let _days = []

                for (let i = 0; i < response.length; i++) {
                    _users.push(response[i].users)
                    _visits.push(response[i].visits)
                    _visitors.push(response[i].visitors)
                    _days.push(response[i].day)
                }

                const data = {
                    "users": _users,
                    "visits": _visits,
                    "visitors": _visitors,
                    "days": _days,
                    "interval": DAY_CONST
                }

                props.setDisplayedData(data)
            }
            )
    }


    const fetchChartsDataMonths = (monthFrom, monthTo, type) => {
        let ID_VALUE, URL_VALUE;
        if (type === CHART_TYPE_NORMAL) {
            ID_VALUE = API_GET_VISITS_PER_MONTH_ID
            URL_VALUE = API_GET_VISITS_PER_MONTH_URL
        }
        else if (type === CHART_TYPE_ACCUMULATED) {
            ID_VALUE = API_GET_CUMULATIVE_VISITS_PER_MONTH_ID
            URL_VALUE = API_GET_CUMULATIVE_VISITS_PER_MONTH_URL
        }
        else if (props.charts.presentationType === CHART_TYPE_NORMAL) {
            ID_VALUE = API_GET_VISITS_PER_MONTH_ID
            URL_VALUE = API_GET_VISITS_PER_MONTH_URL
        }
        else if (props.charts.presentationType === CHART_TYPE_ACCUMULATED) {
            ID_VALUE = API_GET_CUMULATIVE_VISITS_PER_MONTH_ID
            URL_VALUE = API_GET_CUMULATIVE_VISITS_PER_MONTH_URL
        }
        props.getVisitsData(
            ID_VALUE, URL_VALUE +
            monthFrom + "/" + monthTo + "/"
        )
            .then((response) => {
                let _users = []
                let _visits = []
                let _visitors = []
                let _months = []

                for (let i = 0; i < response.length; i++) {
                    _users.push(response[i].users)
                    _visits.push(response[i].visits)
                    _visitors.push(response[i].visitors)
                    _months.push(response[i].month)
                }

                const data = {
                    "users": _users,
                    "visits": _visits,
                    "visitors": _visitors,
                    "months": _months,
                    "interval": MONTH_CONST,
                    "presentationType": CHART_TYPE_NORMAL,
                    "chartType": CHART_TYPE_BAR,
                    "monthFrom": monthFrom,
                    "monthTo": monthTo
                }
                props.setMonthlyData(data)
            }
            )
    }

    const getDataDaysBehind = (days) => {
        const daysBehind = calculateDaysBehind(days)
        props.setDaysScope(daysBehind)
        fetchChartsData(daysBehind.dayFrom, daysBehind.dayTo)
    }



    return (
        <Suspense fallback={<Loader />}>
            <div className="ml-1">
                <Row>
                    {props.chartLoading &&
                        <Spinner
                            as="span"
                            animation="border"
                            size="lg"
                            role="status"
                            aria-hidden="true"
                            className="ml-3 mb-3"
                        />
                    }
                    {!props.chartLoading &&
                        <Col md={{ span: 12 }}>
                            {props.charts.interval === DAY_CONST ? <VisitsChart /> : <MonthlyVisitChart />}
                        </Col>
                    }
                </Row>
                <div className="d-flex flex-row justify-content-around bd-highlight mb-2">
                    <Button variant="secondary" className="ml-1" onClick={() => getDataDaysBehind(6)}>{t('get-last-week-data')}</Button>
                    <Button variant="secondary" className="ml-1" onClick={() => getDataDaysBehind(13)}>{t('get-last-two-weeks-data')}</Button>
                    <Button variant="secondary" className="ml-1" onClick={() => getDataDaysBehind(31)}>{t('get-last-month-data')}</Button>
                </div>
                <Row>
                    <Col md={{ span: 4 }}>
                        <Form onSubmit={handleDateFetchDaily}>
                            <div className="chart-form-container">
                                <div><h3>{t('daily-interval')}</h3></div>
                                <Form.Group>
                                    <FormLabel htmlFor={"visitsDayFrom"}><b>{t('from-day')}</b></FormLabel>
                                    <span className="datepicker-toggle">
                                        <Form.Control id={"visitsDayFrom"} type="date" name="dateFrom" defaultValue={props.charts.dayFrom} required>
                                        </Form.Control>
                                        <FontAwesomeIcon icon={faCalendar} className="datepicker-toggle-button" alt=""/>
                                    </span>
                                </Form.Group>
                                <Form.Group>
                                    <FormLabel htmlFor={"visitsDayTo"}><b>{t('to-day')}</b></FormLabel>
                                    <span className="datepicker-toggle">
                                        <Form.Control id={"visitsDayTo"} type="date" name="dateTo" defaultValue={props.charts.dayTo} required>
                                        </Form.Control>
                                        <FontAwesomeIcon icon={faCalendar} className="datepicker-toggle-button" alt=""/>
                                    </span>
                                </Form.Group>
                                <Button type="submit">
                                    {t('display-data')}
                                </Button>
                            </div>
                        </Form>
                    </Col>
                    <Col md={{ span: 4 }}>
                        <Form onSubmit={handleDateFetchMonthly}>
                            <div className="chart-form-container">
                                <div><h3>{t('monthly-interval')}</h3></div>
                                <Form.Group>
                                    <FormLabel htmlFor={"visitsMonthFrom"}><b>{t('from-month')}</b></FormLabel>
                                    <span className="datepicker-toggle">
                                    <Form.Control id={"visitsMonthFrom"} type="month" name="dateFrom"
                                        value={monthFrom}
                                        onChange={(e)=>{setMonthFrom(e.target.value)}}
                                        onClick={()=>{setShowFrom(true)}}
                                        onBlur={()=>{setShowFrom(false)}}
                                        ref={targetFrom}
                                        required />
                                        <FontAwesomeIcon icon={faCalendar} className="datepicker-toggle-button" alt=""/>
                                    </span>
                                </Form.Group>
                                <Overlay target={targetFrom.current} show={showFrom} placement="bottom" className="firefox-only">
                                    <Popover className="firefox-only">
                                        <Calendar value={monthToDate(monthFrom)} onChange={onMonthFromChange} maxDetail="year"/>
                                    </Popover>
                                </Overlay>
                                <Form.Group>
                                    <FormLabel htmlFor={"visitsMonthTo"}><b>{t('to-month')}</b></FormLabel>
                                    <span className="datepicker-toggle">
                                    <Form.Control id={"visitsMonthTo"} type="month" name="dateTo"
                                         value={monthTo}
                                         onChange={(e)=>{setMonthTo(e.target.value)}}
                                         onClick={()=>{setShowTo(true)}}
                                         onBlur={()=>{setShowTo(false)}}
                                         ref={targetTo}
                                         required  />
                                         <FontAwesomeIcon icon={faCalendar} className="datepicker-toggle-button" alt=""/>
                                    </span>
                                </Form.Group>
                                <Overlay target={targetTo.current} show={showTo} placement="bottom" className="firefox-only">
                                    <Popover className="firefox-only">
                                        <Calendar value={monthToDate(monthTo)} onChange={onMonthToChange} maxDetail="year"/>
                                    </Popover>
                                </Overlay>
                                <Button type="submit">
                                    {t('display-data')}
                                </Button>
                            </div>
                        </Form>
                    </Col>
                    <Col md={{ span: 4 }}>
                        <Form>
                            <div className="chart-form-container">
                                <Form.Group>
                                    <FormLabel htmlFor={"visitsChartType"}><b>{t("chart-type")}</b></FormLabel>
                                    <Form.Control id={"visitsChartType"} as="select" value={props.charts.chartType} onChange={handleFormPresentationTypeChange}>
                                        <option value={CHART_TYPE_BAR}>{t(CHART_TYPE_BAR)}</option>
                                        <option value={CHART_TYPE_LINE}>{t(CHART_TYPE_LINE)}</option>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group>
                                    <FormLabel htmlFor={"visitsPresentationFrom"}><b>{t("data-presentation-form")}</b></FormLabel>
                                    <Form.Control id={"visitsPresentationFrom"} as="select" value={props.charts.presentationType} onChange={handleAccumulate}>
                                        <option value={CHART_TYPE_NORMAL}>{t(CHART_TYPE_NORMAL)}</option>
                                        <option value={CHART_TYPE_ACCUMULATED}>{t(CHART_TYPE_ACCUMULATED)}</option>
                                    </Form.Control>
                                </Form.Group>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </div>
        </Suspense>
    )
}

const mapStateToProps = state => ({
    charts: state.charts,
    user: state.user ? state.user.data : null,
    getVisitsPerDay: isPending(API_GET_VISITS_PER_DAY_ID, state),
    getVisitsPerMonth: isPending(API_GET_VISITS_PER_MONTH_ID, state),
    chartLoading: isPending(API_GET_VISITS_PER_DAY_ID, state),

})

const mapDispatchToProps = dispatch => ({
    getVisitsData: (id, url) => dispatch(getDataApi(id, url)),
    setChartType: (data) => dispatch(setChartType(data)),
    setPresentationType: (data) => dispatch(setPresentationType(data)),
    setDaysScope: (days) => dispatch(setDaysScope(days)),
    setDisplayedData: (data) => dispatch(setDisplayedData(data)),
    setInterval: (data) => dispatch(setInterval(data)),
    setMonthlyData: (data) => dispatch(setMonthlyData(data))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VisitsReportsHandler)