import React, { useEffect, useState } from "react"
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { isPending, getOutputOrDefault } from "../../reducers"
import { postDataApi, requestSuccess } from '../../actions'
import { Form, Button, Collapse, Row, Col, Spinner } from "react-bootstrap"
import InviteUser from "./InviteUser"
import {
    API_TEAM_GET_URL_ID,
    API_TEAM_GET_URL_URL,
    API_TEAM_REMOVE_PIN_ID,
    API_TEAM_REMOVE_PIN_URL,
    TEAM_APPLICATION_PAGE,
} from '../../settings'
import * as notify from '../../utils/notify'

const EditTeam = props => {
    const { t, i18n } = useTranslation([ 'team' ])
    const { team, generatePin } = props
    const [ showInviteUser, setShowInviteUser ] = useState(false)
    const [ pin, setPin ] = useState(null)
    
    const prepareUrl = res => {
        const temp = res.url.split('/')
        setPin(temp[ temp.length - 1 ])
    }

    useEffect(() => {
        generatePin(team.id)
            .then(res => {
                prepareUrl(res)
            })
            .catch(res => {
                notify.error(i18n.t('team:cannot-generate-pin'), res.result)
            })
    }, [ team, generatePin, i18n ])

    return (
        <Form className="m-4" onSubmit={e => { e.preventDefault() }}>
            {props.querying &&
                <Row className="ml-4 mt-4 mb-4">
                    <Col>
                        <div className="text-center">
                            <Spinner animation="border" role="status" />
                        </div>
                    </Col>
                </Row>
            }
            {!props.querying &&
                <>
                    <Form.Group>
                        <Form.Label htmlFor="shareLink">{t('team:share_link')}</Form.Label>
                        <Form.Control
                            id="shareLink"
                            size="sm"
                            value={props.shareLink ? `${window.location.origin}${TEAM_APPLICATION_PAGE}${team.id}/${pin}/` : ''}
                            disabled={props.querying}
                            readOnly
                        ></Form.Control>
                    </Form.Group>
                    <div className="text-right">
                        <Button size="sm" onClick={() => props.generatePin(team.id, t).then(res => prepareUrl(res))} disabled={props.querying || props.shareLink.length !== 0} className="mr-1">
                            {t('team:generate-link')}
                        </Button>
                        <Button size="sm" onClick={() => setShowInviteUser(!showInviteUser)} disabled={props.querying} className="mr-1">
                            {t('team:invite-user')}
                        </Button>
                        <Button size="sm" onClick={() => props.removePin(team.id, props.requests, t)} disabled={props.querying || props.shareLink.length === 0} className="mr-1">
                            {t('team:cancel-sharing')}
                        </Button>
                    </div>
                    <Collapse in={showInviteUser} id="editTeam">
                        <div>
                            {showInviteUser &&
                                <InviteUser team={team} />
                            }
                        </div>
                    </Collapse>
                </>
            }
        </Form>
    )
}

const mapStateToProps = (state, ownProps) => ({
    querying: isPending(API_TEAM_GET_URL_ID + ownProps.team.id, state) || isPending(API_TEAM_REMOVE_PIN_ID + ownProps.team.id, state),
    shareLink: getOutputOrDefault(API_TEAM_GET_URL_ID + ownProps.team.id, state, { url: '' })[ 'url' ],
    requests: state.requests
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    generatePin: team_id => dispatch(postDataApi(API_TEAM_GET_URL_ID + ownProps.team.id, API_TEAM_GET_URL_URL, { team_id })),
    removePin: (teamId, requests, t) => {
        return dispatch(postDataApi(API_TEAM_REMOVE_PIN_ID + ownProps.team.id, API_TEAM_REMOVE_PIN_URL, {
            team_id: teamId
        })).then(() => {
            notify.success(t('team:removed-pin'))
            const oldData = requests[ API_TEAM_GET_URL_ID + ownProps.team.id ].output
            dispatch(requestSuccess(API_TEAM_GET_URL_ID + ownProps.team.id, { ...oldData, url: '' }))
        }).catch(res => {
            notify.error(t('team:cannot-remove-pin'), res.result)
        })
    }
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditTeam)
