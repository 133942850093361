import React, { useEffect, useState, useRef, useCallback } from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getOutputOrDefault, getPagination, isPending } from "../../reducers"
import { postDataAnc, requestSuccess } from '../../actions'
import RedirectLoginWithReturn from "../access/RedirectLoginWithReturn"
import SearchProtectedArea from "../protectedArea/SearchProtectedArea"
import CreateProtectedArea from "../protectedArea/CreateProtectedArea"
import EditProtectedArea from "../protectedArea/EditProtectedArea"
import ResultsList from '../taxonDB/ResultsList'
import { Container, Row, Col, ListGroup, Button, Collapse, Spinner, Badge } from "react-bootstrap"
import ListGroupItemDiv from '../ListGroupItemDiv'
import ConnectedPagination from '../ConnectedPagination'
import Confirm from "../Confirm"
import {
    ANC_URL_POSTFIX,
    PROTECTED_AREAS_SEARCH_ID,
    PROTECTED_AREAS_SEARCH_URL,
    PROTECTED_AREAS_DELETE_ID,
    PROTECTED_AREAS_DELETE_URL,
    PROTECTED_AREAS_GET_SPECIMENS_ID,
    PROTECTED_AREAS_GET_SPECIMENS_URL,
    GENERAL_EXTENDED_REQUEST_TIMEOUT,
    PROTECTED_AREA_PAGE_URL_EN,
    PROTECTED_AREA_PAGE_URL_PL
} from '../../settings'
import I18nPathTranslator from "../I18nPathTranslator";
import SEO from "../SEO";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import SeeMoreIcon from "../SeeMoreIcon";

const ProtectedAreaListItem = props => {
    const { t, i18n } = useTranslation(['protectedArea', 'forms'])
    const { protectedArea, ignoreDates, parentProps } = props

    const [isShowDetailsOpen, setShowDetailsOpen] = useState(false)
    const toggleShowDetailsOpen = () => {
        setShowDetailsOpen(!isShowDetailsOpen)
        parentProps.getSpecimens(protectedArea.id, ignoreDates)
    }

    const [isEditProtectedAreaOpen, setEditProtectedAreaOpen] = useState(false)
    const toggleEditProtectedArea = () => {
        setEditProtectedAreaOpen(!isEditProtectedAreaOpen)
    }

    const requestInterceptor = (request) => {
        return {...request, ignore_dates: ignoreDates }
    }

    const onEditDone = () => {
        props.onListContentChanged && props.onListContentChanged()
    }

    return (
        <>
            <ListGroupItemDiv onClick={e => {if(protectedArea.liczba_okazow > 0){toggleShowDetailsOpen()}}}>
                <Row>
                    <div className="d-flex justify-content-center align-items-center" style={{width: '5%'}}>
                        {protectedArea.liczba_okazow > 0 && <SeeMoreIcon isOpen={isShowDetailsOpen} />}
                    </div>
                    <Col onClick={() => protectedArea.liczba_okazow > 0 && toggleShowDetailsOpen()}>
                        { protectedArea.nazwa }
                        &nbsp;
                        <Badge variant="secondary">
                            { protectedArea.typ_obszaru }
                        </Badge>
                        &nbsp;
                        {protectedArea.liczba_okazow > 0 &&
                            <Badge variant="secondary">
                                { protectedArea.liczba_okazow.toLocaleString(i18n.languages[0]) }
                            </Badge>
                        }
                        <br />
                        {(protectedArea.data_utworzenia) &&
                            <>
                                {protectedArea.id !== '' &&
                                    <span className="itemlist-itemlabel-plain-small">
                                        { protectedArea.id }
                                        &nbsp;
                                    </span>
                                }
                                <span className="itemlist-itemlabel-plain-small">
                                    { protectedArea.data_utworzenia }
                                </span>
                            </>
                        }
                    </Col>
                    <Col sm="auto" className="d-flex flex-row align-self-center">
                        <Button variant="secondary" title={t('forms:edit')} size="sm" className="mr-1" onClick={toggleEditProtectedArea}>
                            <FontAwesomeIcon icon={faPencilAlt} />
                        </Button>
                        <Confirm
                            onConfirm={e => parentProps.removeProtectedArea(protectedArea.id, parentProps.requests)}
                            size="sm"
                            pending={parentProps.removingItems}
                            disabled={parentProps.removingItems}
                            WCAGtext={t('forms:remove')}
                            buttonLabel={<FontAwesomeIcon icon={faTrash} />}
                            confirmationText={t('protectedArea:confirm-remove-protected-area')}
                        ></Confirm>
                    </Col>
                </Row>
            </ListGroupItemDiv>
            <Collapse in={isShowDetailsOpen} id="detailsProtectedArea">
                <Container>
                    <div className="mt-2 mb-2">
                        <ResultsList
                            requestId={PROTECTED_AREAS_GET_SPECIMENS_ID + protectedArea.id}
                            requestUrl={`${ANC_URL_POSTFIX}${PROTECTED_AREAS_GET_SPECIMENS_URL}${protectedArea.id}/`}
                            requestInterceptor={requestInterceptor}>
                        </ResultsList>
                    </div>
                </Container>
            </Collapse>
            <Collapse in={isEditProtectedAreaOpen} id="editProtectedArea">
                <div>
                    <EditProtectedArea protectedArea={protectedArea} onDone={onEditDone} />
                </div>
            </Collapse>
        </>
    )
}

const ProtectedAreaContainer = props => {
    const { t } = useTranslation(['protectedArea', 'paths'])

    const paginationRef = useRef()
    const [searchFilter, setSearchFilter] = useState({})
    const [searchParams, setSearchParams] = useState({})

    const [isCreateProtectedAreaOpen, setCreateProtectedAreaOpen] = useState(false)
    const toggleCreateProtectedAreaOpen = () => {
        setCreateProtectedAreaOpen(!isCreateProtectedAreaOpen)
    }

    const getProtectedAreasCallback = useCallback(props.getProtectedAreas, []);
    useEffect(() => {
        getProtectedAreasCallback()
    }, [getProtectedAreasCallback])

    const onSearch = (type, name, orderBy, ignoreDates) => {
        let filter = {
            ...props.filter,
            ...type && { typ_obszaru: type },
            ...name && { nazwa: name }
        }

        setSearchFilter({filter})
        setSearchParams({
            sort: orderBy,
            ignore_dates: ignoreDates
        })
        getProtectedAreasCallback({
            filter: filter,
            sort: orderBy,
            ignore_dates: ignoreDates,
            pagination: {}
        })
    }

    const onListContentChanged = () => {
        paginationRef.current.reloadPage()
    }

    return (
        <>
            <I18nPathTranslator {...props} pathEN={PROTECTED_AREA_PAGE_URL_EN} pathPL={PROTECTED_AREA_PAGE_URL_PL} />
            <SEO title={t('menu:protected-areas')} />
            {!props.loading && props.user && !props.user.data &&
                <RedirectLoginWithReturn lang={props.lang} returnUrl={props.lang ? t("paths:protected-area", {lng: props.lang}) : t("paths:protected-area")} />           
            }
            {props.user && props.user.data &&
                <Container className="mt-2">
                    <Row>
                        <Col>
                            <Button className="mr-2" onClick={toggleCreateProtectedAreaOpen}>
                                {t('protectedArea:add-protected-area')}
                            </Button>
                            <Collapse in={isCreateProtectedAreaOpen}>
                                <div>
                                    <CreateProtectedArea onDone={() => {setCreateProtectedAreaOpen(false)}} />
                                </div>
                            </Collapse>
                            <hr />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SearchProtectedArea onSearch={onSearch}></SearchProtectedArea>
                            {props.loading && !props.pagination &&
                                <div className="text-center">
                                    <Spinner animation="border" role="status" />
                                </div>
                            }
                            {!props.loading && props.pagination &&
                                <div>
                                    <div>
                                        <ConnectedPagination customFilter={searchFilter} customParams={searchParams} requestId={PROTECTED_AREAS_SEARCH_ID} url={ANC_URL_POSTFIX + PROTECTED_AREAS_SEARCH_URL} ref={paginationRef}></ConnectedPagination>
                                    </div>
                                    <ListGroup>
                                        {props.protectedAreas.map((protectedArea, i) => {
                                            return (
                                                <ProtectedAreaListItem protectedArea={protectedArea} ignoreDates={searchParams.ignore_dates || false} onListContentChanged={onListContentChanged} key={protectedArea.id || protectedArea.nazwa} parentProps={props} />
                                            )
                                        })}
                                    </ListGroup>
                                    <div>
                                        <ConnectedPagination customFilter={searchFilter} customParams={searchParams} last requestId={PROTECTED_AREAS_SEARCH_ID} url={ANC_URL_POSTFIX + PROTECTED_AREAS_SEARCH_URL}></ConnectedPagination>
                                    </div>
                                </div>
                            }
                        </Col>
                    </Row>
                </Container>
            }
        </>
    )
}

const mapStateToProps = (state, ownProps) => ({
    user: state.user,
    loading: isPending(PROTECTED_AREAS_SEARCH_ID, state),
    pagination: getPagination(PROTECTED_AREAS_SEARCH_ID, state),
    removingItems: isPending(PROTECTED_AREAS_DELETE_ID, state),
    protectedAreas: getOutputOrDefault(PROTECTED_AREAS_SEARCH_ID, state, { items: [] })['items'],
    requests: state.requests
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    getProtectedAreas: (data) => dispatch(postDataAnc(PROTECTED_AREAS_SEARCH_ID, PROTECTED_AREAS_SEARCH_URL, data, GENERAL_EXTENDED_REQUEST_TIMEOUT)),
    removeProtectedArea: (protectedAreaId, requests) => dispatch(postDataAnc(PROTECTED_AREAS_DELETE_ID, PROTECTED_AREAS_DELETE_URL, {
        id: protectedAreaId
    })).then(() => {
        const oldData = requests[PROTECTED_AREAS_SEARCH_ID].output
        const newData = {...oldData, items: oldData.items.filter((t) => t.id !== protectedAreaId) }
        dispatch(requestSuccess(PROTECTED_AREAS_SEARCH_ID, newData))
    }),
    getSpecimens: (id, isIgnoreDates) => dispatch(postDataAnc(PROTECTED_AREAS_GET_SPECIMENS_ID + id, `${PROTECTED_AREAS_GET_SPECIMENS_URL}${id}/`,  { ignore_dates: isIgnoreDates }))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProtectedAreaContainer)
