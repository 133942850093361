import React, { forwardRef, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button } from  "react-bootstrap"; 
import L from 'leaflet';
import ReactDOMServer from 'react-dom/server';
import {
    TAXONDB_SEARCH_WITH_AGGREGATION_REQUEST_URL,
    MAP_DATA_REQUEST_TIMEOUT,
    TAXONDB_SEARCH_MAP_DATA_REQUEST_ID,
    TAXONDB_SEARCH_MAP_DATA_REQUEST_URL,
    MAP_LAYER_ANIMATION_CONST,
    TAXONDB_SEARCH_GENUS_REQUEST_ID,
    TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID,
    TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID,
} from '../../../../settings'
import { isPending } from "../../../../reducers";
import { postDataAnc } from '../../../../actions'
import { setAnimationTimeout } from '../../../../actions/map'
import { ROK_ZBIORU_FIELD_NAME } from '../../../taxonDB/attributesStructure'
import { renderIconHtmlWithSize }  from '../../../../utils/map/renderIconHtml'
import { useTranslation } from 'react-i18next'

const Timeline  = forwardRef((props, ref) => {
    const { t } = useTranslation(['map'])
    const PAGINATION_LIMIT = 1000;
    const REQUEST_ID = 'HISTORICAL_ANALYSIS_CONST'
    const UNASSIGNED_DATE_STRING = 'bd'
    const [buttonBlocked, setButtonBlocked] = useState(false)

    useEffect(() => {
        return (() => {
            if (props.animationTimeouts && props.animationTimeouts.length){
                props.animationTimeouts.forEach(id => clearTimeout(id))
            }
        })
    }, [props.animationTimeouts])

    const beginTimelineShow = () => {
        prepareMapBeforeSearchRequest()
        if(props.filter){
            props.searchWithAggregation(ROK_ZBIORU_FIELD_NAME, props.filter, PAGINATION_LIMIT, REQUEST_ID)
            .then(res => {
                let arrayOfYears = []
                let mapOfTaxonsByYears = new Map()
                res.items.forEach(item => arrayOfYears.push(item[ROK_ZBIORU_FIELD_NAME]))
                arrayOfYears.sort()
                props.searchMapData({ 
                    filter: props.filter,
                    field1: ROK_ZBIORU_FIELD_NAME
                })
                .then(response => {
                    arrayOfYears.forEach(item => {
                        response.data.forEach(taxon => {
                            if (taxon[3] === item){
                                mapOfTaxonsByYears.get(item) ? mapOfTaxonsByYears.get(item).push(taxon) : mapOfTaxonsByYears.set(item, [taxon])
                            }
                        })
                    })
                    let dataToDisplay = Array.from(mapOfTaxonsByYears.values())
                    let icon = renderIconHtmlWithSize(props.markerIcon, props.markerColor, props.markerSize)
                    const fontAwesomeIcon = new L.divIcon({
                        html: icon,
                        iconSize: [20, 20],
                        className: 'myDivIcon'
                    });

                    let dateStart
                    let dateEnd 

                    if(props.animationYears){
                        dateStart = props.animationYears.START_YEAR
                        dateEnd = props.animationYears.END_YEAR
                    }
                    else{
                        dateStart = arrayOfYears[0]
                        dateEnd = arrayOfYears[arrayOfYears.length-1] === UNASSIGNED_DATE_STRING ? arrayOfYears[arrayOfYears.length-2] : arrayOfYears[arrayOfYears.length-1]   
                    }
                    
                    let dates = []
                    for (let i = Number(dateStart); i <= Number(dateEnd); i++){
                        dates.push(i)
                    }

                    ref.mapRef.current.addLayer(ref.animationLayerRef.current)
                    const timeouts = []
                    const animationInterval = 1000; // in milliseconds
                    dates.forEach((year, index) => {
                        let timerId = setTimeout(() => {
                            updateNextYearData(dataToDisplay, year, fontAwesomeIcon)
                        }, (animationInterval*(index+1)));
                        timeouts.push(timerId)
                        props.setAnimationTimeout(timeouts)
                    });

                })
                .catch(err => {
                    //ignore
                })
            })
            .then(res => 
                setButtonBlocked(false)
            )
            .catch(err => {
                //ignore
            })
        }
    }

    function updateNextYearData(dataToDisplay, year, fontAwesomeIcon){
        updateYearDiv(year)
        dataToDisplay.forEach(taxonByYear => {
            if (Number(taxonByYear[0][3]) === year){
                taxonByYear.forEach(item => {
                    const marker = L.marker([item[0], item[1]], { icon: fontAwesomeIcon })
                    marker['kolekcjanumerokazu'] = item[2]
                    marker.name = MAP_LAYER_ANIMATION_CONST
                    marker.bindPopup(`<b>${item[2]}</b> (${item[3]})`)
                    marker.addTo(ref.animationLayerRef.current)
                })
            }
        })
    }

    const updateYearDiv = (year) => {
        try{
            if (ref.animationDisplayedYearDiv.current){
                ref.animationDisplayedYearDiv.current.remove()
            }
        }
        catch(e){
            //ignore
        }
        try{
            ref.animationDisplayedYearDiv.current.onAdd = function() {
                var div = L.DomUtil.create("div", "legend");
                div.style.backgroundColor = 'white';
                div.style.padding = '15px';
                div.style.width = '130px'
                div.innerHTML = ReactDOMServer.renderToString(
                    <div style={{fontSize: 40, float: 'center'}}>
                        <b>{year}</b>
                    </div>
                )
                return div
            }
            ref.animationDisplayedYearDiv.current.addTo(ref.mapRef.current);
        }
        catch(e){
            console.log(e)
        }
    }

    const prepareMapBeforeSearchRequest = () => {
        setButtonBlocked(true)
        try {
            if (props.animationTimeouts && props.animationTimeouts.length){
                props.animationTimeouts.forEach(id => clearTimeout(id))
            }
            if (ref.animationDisplayedYearDiv.current){
                ref.animationDisplayedYearDiv.current.remove()
            }
            ref.animationLayerRef.current.eachLayer(function (layer) {
                ref.animationLayerRef.current.removeLayer(layer);
            })
        }
        catch(e){
            console.log(e)
        }
    }

    return(
        <>
            <Button 
                onClick={() => beginTimelineShow()}
                className="play-timelapse-animation-button"
                disabled={props.loading1 || props.loading2 || props.loading3 || buttonBlocked}
            >
                {t('map:run-animation')}
            </Button>
        </>
    )

})


const mapStateToProps = state => ({
    loading1: isPending(TAXONDB_SEARCH_GENUS_REQUEST_ID, state),
    loading2: isPending(TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID, state),
    loading3: isPending(TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, state),
    animationTimeouts: state.map.animationTimeouts,
    animationYears: state.map.animationYears,
    markerColor: state.map.historicalMarkerColor,
    markerIcon: state.map.historicalMarkerIcon,
    markerSize: state.map.historicalMarkerSize,
})

const mapDispatchToProps = dispatch => ({
    searchWithAggregation: (field, filter, items_per_page, request_id) => {
        return dispatch(postDataAnc(request_id,
            TAXONDB_SEARCH_WITH_AGGREGATION_REQUEST_URL, {
            filter: filter,
            aggregation_field: field,
            pagination: {perPage: items_per_page},
        },
        MAP_DATA_REQUEST_TIMEOUT
        ))
    },
    searchMapData: (data, source) => dispatch(postDataAnc(
        TAXONDB_SEARCH_MAP_DATA_REQUEST_ID,
        TAXONDB_SEARCH_MAP_DATA_REQUEST_URL,
        data,
        MAP_DATA_REQUEST_TIMEOUT,
        source)),
    setAnimationTimeout: (timeouts) => dispatch(setAnimationTimeout(timeouts))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(Timeline)
