import React, { Suspense, useState } from "react";
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { isPending, getOutput, getRequest } from "../../reducers";
import { Spinner } from "react-bootstrap"
import SingleDlibraImage from './DlibraImage'
import RecordDetails from './RecordDetails'
import {
    API_ALBUM_GET_ALL_ID,
    API_ALBUM_GET_ALL_URL,
    DLIBRA_IIIF2_URL
} from '../../settings'
import { postDataApi, getData } from '../../actions'
import { addPictureOne, addPictureTwo, clearComparator } from '../../actions/comparator'
import { Redirect } from "react-router-dom"

const RecordDetailsContainer = props => {
    const { t } = useTranslation()

    const [ redirectToComparator, setRedirectToComparator ] = useState(false)

    const onDone = () => {
        props.reloadImages(props.request)
    }

    const addFirstPictureToComparator = (data) => {
        props.addPictureOneToComparator({
            edi_id1: data.files[ 0 ].edi_id,
            edi_ver_id1: data.files[ 0 ].ver_id,
            external_id1: data.id,
            requestUrl1: DLIBRA_IIIF2_URL
        })
    }

    const addSecondPictureToComparator = (data) => {
        props.addPictureTwoToComparator({
            edi_id2: data.files[ 0 ].edi_id,
            edi_ver_id2: data.files[ 0 ].ver_id,
            external_id2: data.id,
            requestUrl2: DLIBRA_IIIF2_URL
        })
        setRedirectToComparator(true)
    }

    const shareProps = {
        recordId: props.recordId,
        addFirstPictureToComparator: addFirstPictureToComparator,
        addSecondPictureToComparator: addSecondPictureToComparator,
        setRedirect: setRedirectToComparator
    }

    return (
        <Suspense fallback={<div className="align-self-center m-3"><Spinner animation="border" role="status" /></div>}>
            <div className="d-flex flex-column pt-1 common-panel-background">
                {props.loading &&
                    <div className="align-self-center m-3">
                        <Spinner animation="border" role="status" />
                    </div>
                }
                <div className="d-flex flex-column pt-2">
                    {!props.loading && props.data &&
                        <div className="d-flex flex-column">
                            <SingleDlibraImage viewedBy={props.viewedBy} requestId={props.requestId} onDone={onDone}></SingleDlibraImage>
                            <RecordDetails viewedBy={props.viewedBy} requestId={props.requestId} {...shareProps}></RecordDetails>
                        </div>
                    }
                    {!props.loading && !props.data &&
                        <div className="d-flex justify-content-center">
                            <p>{t('no-records-found')}</p>
                        </div>
                    }
                </div>
            </div>
            {redirectToComparator &&
                <Redirect push to={t('paths:comparator')} />
            }
        </Suspense>
    )
}

const mapStateToProps = (state, ownProps) => ({
    loading: isPending(ownProps.requestId, state),
    data: getOutput(ownProps.requestId, state),
    request: getRequest(ownProps.requestId, state),
    comparator: state.comparator
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    getAlbums: () => dispatch(postDataApi(API_ALBUM_GET_ALL_ID, API_ALBUM_GET_ALL_URL)),
    reloadImages: (request) => {
        dispatch(getData(ownProps.requestId, request.url, request.data))
    },
    addPictureOneToComparator: (data) => dispatch(addPictureOne(data)),
    addPictureTwoToComparator: (data) => dispatch(addPictureTwo(data)),
    clearComparator: () => dispatch(clearComparator())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RecordDetailsContainer)
