/* 
Coordynates should be converted, as some geoinformatics use format [lon, lat] (for example GeoJSON and Mapbox)
and the others systems use format [lat, lon] (for example Leaflet and React Leaflet)
if coords are stored as array
*/

export const convertGeoJSONCoordsToLeaflet = (coords) => {

    let convertedCoords = []
    let nestedOutput = []

    coords[0].forEach(element => {
        convertedCoords.push([element[1], element[0]])
    });

    nestedOutput.push(convertedCoords)

    return nestedOutput
}