import React, { useState, forwardRef, Suspense, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Spinner, ListGroup, Container, Row, Col, DropdownButton } from  "react-bootstrap"; 
import { useTranslation } from 'react-i18next'
import {
    PROJECTS_GET_ALL_ID,
    PROJECTS_GET_ALL_URL,
    API_URL_POSTFIX,
} from '../../settings'
import { isPending, getOutputOrDefault } from "../../reducers";
import L from 'leaflet';
import * as notify from '../../utils/notify'
import ConnectedPagination from '../ConnectedPagination'
import useApi from '../../hooks/useApi';
import {
    URL_API_PROJECT_GET_OBSERVATIONS_DETAILS,
} from '../../urls';
import useMapRedux from '../../hooks/useMapRedux';
import { MAP_OBSERVATIONS } from '../../stateIds';
import Loader from '../../utils/loader'
import useMarkerShapeColorSelector from '../../hooks/useMarkerShapeColorSelector';
import useMapDefinition from '../../hooks/useMapDefinition';

const ProjectsListItem = forwardRef((props, ref) => {
    const { t } = useTranslation(['projects', 'forms', 'map'])
    const { value: mapObservations, setValue: setMapObservations } = useMapRedux(MAP_OBSERVATIONS, {});
    const [leafletId, setLeafletId] = useState(mapObservations?.[props.project.id]?.leafletId || null)
    const { value: observationsColor } = useMapRedux("observationsColor", "orange");
    const { value: observationsIcon } = useMapRedux("observationsIcon", "square");
    const { value: observationsSize } = useMapRedux("observationsSize", "2x");
    const [ color, setColor ] = useState(mapObservations?.[props.project.id]?.color || observationsColor)
    const [ shape, setShape ] = useState(mapObservations?.[props.project.id]?.shape || observationsIcon)
    const [ size, setSize ] = useState(mapObservations?.[props.project.id]?.size || observationsSize)
    const { refetch: getObservationDetails, loading } = useApi('post', URL_API_PROJECT_GET_OBSERVATIONS_DETAILS);
    const {iconCreateFunction, loadObservations} = useMapDefinition();

    useEffect(() => {
        setShape(mapObservations?.[props.project.id]?.shape || observationsIcon)
        setColor(mapObservations?.[props.project.id]?.color || observationsColor)
        setSize(mapObservations?.[props.project.id]?.size || observationsSize)
    }, [mapObservations, props.project, observationsIcon, observationsColor, observationsSize])
  
    const hideProject = () => {
        ref.observationsRef.current.eachLayer(layer => {
            if (layer._leaflet_id === leafletId) {
                ref.observationsRef.current.removeLayer(layer);
            }
        });
        setLeafletId(null)
        setMapObservations({
            ...mapObservations,
            [props.project.id]: {
                color,
                shape,
                size,
                leafletId: null, 
            }
        })
    }

    const updateColor = newColor => {
        setColor(newColor)
        ref.observationsRef.current.eachLayer(layer => {
            if (layer._leaflet_id === leafletId) {
                layer.options.iconCreateFunction = l => iconCreateFunction(l, shape, newColor, size)
                layer.refreshClusters()
                layer.eachLayer(marker => {
                    marker.setIcon(prepareBasicDivIcon(shape, newColor, size))
                })
            }
        });
        setMapObservations({
            ...mapObservations,
            [props.project.id]: {
                color: newColor,
                shape,
                size,
                leafletId, 
            }
        })
    }

    const updateShape = newShape => {
        setShape(newShape)
        ref.observationsRef.current.eachLayer(layer => {
            if (layer._leaflet_id === leafletId) {
                layer.options.iconCreateFunction = l => iconCreateFunction(l, newShape, color, size)
                layer.refreshClusters()
                layer.eachLayer(marker => {
                    marker.setIcon(prepareBasicDivIcon(newShape, color, size))
                })
            }
        });
        setMapObservations({
            ...mapObservations,
            [props.project.id]: {
                color,
                shape: newShape,
                size,
                leafletId, 
            }
        })
    }

    const updateSize = newSize => {
        setSize(newSize)
        ref.observationsRef.current.eachLayer(layer => {
            if (layer._leaflet_id === leafletId) {
                layer.options.iconCreateFunction = l => iconCreateFunction(l, shape, color, newSize)
                layer.refreshClusters()
                layer.eachLayer(marker => {
                    marker.setIcon(prepareBasicDivIcon(shape, color, newSize))
                })
            }
        });
        setMapObservations({
            ...mapObservations,
            [props.project.id]: {
                color,
                shape,
                size: newSize,
                leafletId, 
            }
        })
    }
    const {DropdownShapeItems, DropdownColorItems, DropdownSizeItems, prepareBasicDivIcon} = useMarkerShapeColorSelector(updateShape, updateColor, updateSize)

    const loadProject = () => {
        var mapLoadingBlur = L.control.loader()
        mapLoadingBlur.addTo(ref.mapRef.current);
        getObservationDetails(null, props.project.id)
        .then(res => {
            const { cluster, noCoordsObservations, numberOfObservations } = loadObservations(ref.mapRef, ref.observationsRef, res.data.items.observations, shape, color, size);
            if (cluster.getLayers().length > 0) {
                setLeafletId(cluster._leaflet_id)
                setMapObservations({
                    ...mapObservations,
                    [props.project.id]: {
                        color,
                        shape,
                        size,
                        leafletId: cluster._leaflet_id, 
                    }
                })
                if (noCoordsObservations === 0){
                    notify.success(t('map:added-project-notify'))
                }
                if (noCoordsObservations > 0 && noCoordsObservations < numberOfObservations){
                    let loadedTaxons = numberOfObservations - noCoordsObservations
                    notify.warning(t('map:some-observations-added-notify', {loaded: loadedTaxons, total: numberOfObservations}))
                } 
                if (noCoordsObservations > 0 && noCoordsObservations === numberOfObservations){
                    notify.error(t('map:cannot-add-project-notify'))
                }
            }
            else {
                let loadedTaxons = numberOfObservations - noCoordsObservations
                notify.warning(t('map:some-observations-added-notify', {loaded: loadedTaxons, total: numberOfObservations}))
            }
            mapLoadingBlur.hide()
        })
    }

    return (
        <Suspense fallback={<Loader />}>
            <ListGroup.Item as={Container}>
                <Row>
                    <Col>
                        { props.project.name }
                    </Col>
                    <Col>
                        <div className='d-flex'>
                            <div className='align-self-center' style={{width: "50px"}}>
                                <i className={`fa fa-${shape} fa-${size}`} 
                                    style={{
                                        color, position: "absolute",
                                        top: size === "3x" ? "-10px" : size === "2x" ? "-1px" : "6px",
                                        left: size === "3x" ? "10px" : size === "2x" ? "16px" : "22px",
                                    }} />
                            </div>
                            <DropdownButton
                                title={t("map:marker-icon-short")} 
                                disabled={loading}
                                size="sm"
                            >
                                <DropdownShapeItems></DropdownShapeItems>
                            </DropdownButton>
                            <DropdownButton
                                title={t("map:marker-color-short")}  
                                disabled={loading}
                                size="sm"
                                className='ml-2'
                            >
                                <DropdownColorItems></DropdownColorItems>
                            </DropdownButton>
                            <DropdownButton
                                title={t("map:marker-size-short")}  
                                disabled={loading}
                                size="sm"
                                className='ml-2'
                            >
                                <DropdownSizeItems></DropdownSizeItems>
                            </DropdownButton>
                        </div>
                    </Col>
                    <Col sm="auto" className="d-flex flex-row align-self-center" style={{width: "130px"}}>
                        {!leafletId &&
                            <Button variant="secondary" size="sm" className="mr-1" onClick={loadProject} disabled={loading}>
                                {t('map:load')}
                                {loading &&
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        className="ml-1"
                                    />
                                }
                            </Button>
                        }
                        {leafletId &&
                            <Button variant="secondary" size="sm" className="mr-1" onClick={hideProject} disabled={loading}>
                                {t('map:observations-hide')}
                            </Button>
                        }
                    </Col>
                </Row>
            </ListGroup.Item>
        </Suspense>
    )
})

const MapObservations  = forwardRef((props, ref) => {

    return(
        <Suspense fallback={<Loader />}>
            <div>
                {props.loadingProjects &&
                    <div className='d-flex justify-content-center'>
                        <Spinner 
                            className="ml-1 mt-2"
                            animation="border" 
                            role="status"
                        />
                    </div>
                }
                <div className="ml-2 p-3">
                    <ConnectedPagination requestId={PROJECTS_GET_ALL_ID} url={API_URL_POSTFIX + PROJECTS_GET_ALL_URL}></ConnectedPagination>
                    <ListGroup>
                        {props.projects.map((project, i) => {
                            return (
                                <ProjectsListItem project={project} key={i} ref={ref} />
                            )
                        })}
                    </ListGroup>
                    <ConnectedPagination last requestId={PROJECTS_GET_ALL_ID} url={API_URL_POSTFIX + PROJECTS_GET_ALL_URL}></ConnectedPagination>
                </div>
            </div>
        </Suspense>
    )
})

const mapStateToProps = state => ({
    user: state.user,
    loadingProjects: isPending(PROJECTS_GET_ALL_ID, state),
    projects: getOutputOrDefault(PROJECTS_GET_ALL_ID, state, { items: [] })['items'],
})

const mapDispatchToProps = dispatch => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(MapObservations)
