import React, { useRef } from 'react';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import {
    MAP_MAX_ZOOM_LEVEL,
} from '../../settings'
import { setSearchAreaGeojson, setField } from '../../actions/forms'
import { connect } from 'react-redux'
import { Map, TileLayer, FeatureGroup, Polygon } from "react-leaflet"
import { EditControl } from "react-leaflet-draw"
import L from 'leaflet';
import { convertGeoJSONCoordsToLeaflet } from "../../utils/map/areaSearchConvertion"
import { Button } from "react-bootstrap"
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

function AreaSearch(props) {

    const allLayers = useRef(null)
    const { t } = useTranslation(['map'])

    const addElement = (e) => {
        let geoJson = e.layer.toGeoJSON()
        geoJson.mapType = props.mapType
        let array = []
        let featureGroup = new L.FeatureGroup();
        featureGroup.features = []
        if(props.searchAreaGeoJson && props.searchAreaGeoJson.length){
            props.searchAreaGeoJson.forEach(element => {
                array.push(element)
                if (element.mapType === props.mapType){
                    featureGroup.features.push(element)
                }
            })
        }
        array.push(geoJson)
        featureGroup.features.push(geoJson)
        props.setSearchAreaGeojson(array)
        props.setField(props.requestId, "geo_json", JSON.stringify(featureGroup))
    }

    const handleClearLayers = () => {
        let array = []
        if(props.searchAreaGeoJson && props.searchAreaGeoJson.length){
            props.searchAreaGeoJson.forEach(element => {
                if (element.mapType !== props.mapType){
                    array.push(element)
                }
            })
        }
        props.setSearchAreaGeojson(array)
        props.setField(props.requestId, "geo_json", undefined)
        let layersContainer = allLayers.current.contextValue.layerContainer
        Object.values(layersContainer._layers).forEach(layer => {
            layersContainer.removeLayer(layer)
        })
    }

    return (
        <div>  
            <div style={{display: "flex"}}>
                <div style={{marginLeft: "auto", marginBottom: "auto", marginRight: "0.5em"}}>
                    {t('map:area-search-clear-button')}
                </div>
                <Button
                    variant="danger"
                    title={t('map:area-search-clear-button')}
                    style={{paddingLeft: "30px", paddingRight: "30px"}}
                    onClick={() => handleClearLayers()}
                >
                    <FontAwesomeIcon icon={faTrashAlt} className="user-icon ml-1" size="lg" />
                </Button>
            </div>
            {props.showAreaSearch &&
            <Map center={[0,0]} zoom={2} id="map-area-search" maxZoom={MAP_MAX_ZOOM_LEVEL}>
                <TileLayer
                    url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                    attribution="&copy; <a href=&quot;https://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                />
                <FeatureGroup ref={allLayers} >
                    <EditControl
                        position='topright'
                        onCreated={(e) => addElement(e)}
                        onDeleteStop={(e) => handleClearLayers(e)}
                        edit={{ edit: false, remove: false }}
                        draw={{
                            marker: false,
                            polyline: false,
                            circlemarker: false,
                            circle: false
                        }}
                    />
                    {props.searchAreaGeoJson && props.searchAreaGeoJson.length && (props.searchAreaGeoJson.length > 0) &&
                        props.searchAreaGeoJson.map((element, index) => {
                            let coords, convertedCoords;
                            if(element && element.geometry && element.geometry.coordinates && element.mapType === props.mapType){
                                coords = element.geometry.coordinates
                                convertedCoords = convertGeoJSONCoordsToLeaflet(coords)
                                return (
                                    <Polygon key={index} pathOptions={{ color: 'blue' }} positions={convertedCoords} />
                                )
                            }
                            return undefined
                        })
                    }   
                </FeatureGroup>
            </Map>
            }
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    searchAreaGeoJson: state.forms.searchAreaGeoJson,
    mapType: ownProps.requestId
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    setField: (form, field, value) => dispatch(setField(form, field, value)),
    setSearchAreaGeojson: (value) => dispatch(setSearchAreaGeojson(value)), 
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AreaSearch)