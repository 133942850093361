import React, { useState, useEffect, Suspense } from "react"
import { connect } from 'react-redux'
import { Form, Container, Row, Col, Button, Spinner, FormLabel } from "react-bootstrap"
import { useTranslation } from 'react-i18next'
import Loader from '../utils/loader'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { CONTACT_PAGE_URL_EN, CONTACT_PAGE_URL_PL } from '../settings'
import * as notify from '../utils/notify'
import * as actions from '../actions'
import { isPending, getOutput } from "../reducers"
import { Redirect } from "react-router-dom"
import I18nPathTranslator from "./I18nPathTranslator"
import SEO from "./SEO"
import useFieldRequiredValidation from '../hooks/validation/useFieldRequiredValidation';
import useFieldRequiredEmailValidation from '../hooks/validation/useFieldRequiredEmailValidation';
import useFormValidation from '../hooks/validation/useFormValidation';

const Contact = props => {
  const { t } = useTranslation('contact')
  const { executeRecaptcha } = useGoogleReCaptcha()

  const { clearResponse } = props
  useEffect(() => clearResponse, [ clearResponse ])

  const [ name, setName ] = useState('');
  const [ message, setMessage ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ subject, setSubject ] = useState('');
  const [ interestArea, setInterestArea ] = useState('');
  const [ redirect, setRedirect ] = useState(false)

  const { validProps: nameValidProps, isValidCallback: isNameValid, clearCallback: clearNameV } = useFieldRequiredValidation(name);
  const { validProps: messageValidProps, isValidCallback: isMessageValid, clearCallback: clearMessageV } = useFieldRequiredValidation(message);
  const { validProps: emailValidProps, isValidCallback: isEmailValid, clearCallback: clearEmailV } = useFieldRequiredEmailValidation(email);
  const { validProps: subjectValidProps, isValidCallback: isSubjectValid, clearCallback: clearSubjectV } = useFieldRequiredValidation(subject);
  const { validProps: interestAreaValidProps, isValidCallback: isInterestAreaValid, clearCallback: clearInterestAreaV } = useFieldRequiredValidation(interestArea);

  const handleSubmit = async () => {
    const recaptcha = await executeRecaptcha()
    props.contact({
      name,
      message,
      email,
      subject,
      interest_area: interestArea,
      recaptcha,
    }).then(res => {
      if (res.result === 0) {
        notify.success(t('message-sent-thank-you'))
      } else if (res.result === 1) {
        notify.error(t('operation-failed'), t('bad_recaptcha'))
      } else if (res.result === 2) {
        notify.error(t('operation-failed'), t('bad_parameters'))
      } else if (res.result === 3) {
        notify.error(t('operation-failed'), t('try_again'))
      } else {
        notify.error(t('operation-failed'))
      }
    });
  }

  const { onSubmit } = useFormValidation([ isNameValid, isMessageValid, isEmailValid, isSubjectValid, isInterestAreaValid ], handleSubmit);

  return (
      <Suspense fallback={<Loader />}>
        {redirect &&
          <Redirect push to="/" ></Redirect>
        }
        <I18nPathTranslator {...props} pathEN={CONTACT_PAGE_URL_EN} pathPL={CONTACT_PAGE_URL_PL} />
        <SEO title={t('menu:contact')} />
        <div className="contact-form">
          <Container className="main-form">
            <h1>{t('title')}</h1>
            {(!props.response || props.response.result !== 0) &&
              <Form onSubmit={onSubmit}>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="name">
                      <FormLabel>{t("name-and-surname")}</FormLabel>
                      <Form.Control
                        {...nameValidProps}
                        autoFocus
                        onChange={e => {
                          setName(e.target.value);
                          clearNameV();
                        }}
                        value={name}
                        type="text"
                        disabled={props.loading}
                        autoComplete="off"
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group controlId="email">
                      <FormLabel>{t("email-address")}</FormLabel>
                      <Form.Control
                        {...emailValidProps}
                        onChange={e => {
                          setEmail(e.target.value);
                          clearEmailV();
                        }}
                        value={email}
                        type="text"
                        disabled={props.loading}
                        autoComplete="off"
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group controlId="subject">
                      <FormLabel>{t("subject")}</FormLabel>
                      <Form.Control
                        {...subjectValidProps}
                        onChange={e => {
                          setSubject(e.target.value);
                          clearSubjectV();
                        }}
                        value={subject}
                        type="text"
                        disabled={props.loading}
                        autoComplete="off"
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group controlId="area">
                      <FormLabel>{t("area-of-interest")}</FormLabel>
                      <Form.Control
                        {...interestAreaValidProps}
                        onChange={e => {
                          setInterestArea(e.target.value);
                          clearInterestAreaV();
                        }}
                        value={interestArea}
                        disabled={props.loading}
                        autoComplete="off"
                        as="select"
                      >
                        <option value=""></option>
                        <option value={t('area-of-interest-option-1')}>{t('area-of-interest-option-1')}</option>
                        <option value={t('area-of-interest-option-2')}>{t('area-of-interest-option-2')}</option>
                        <option value={t('area-of-interest-option-3')}>{t('area-of-interest-option-3')}</option>
                        <option value={t('area-of-interest-option-4')}>{t('area-of-interest-option-4')}</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="message">
                      <FormLabel>{t("message-content")}</FormLabel>
                      <Form.Control
                        {...messageValidProps}
                        onChange={e => {
                          setMessage(e.target.value);
                          clearMessageV();
                        }}
                        value={message}
                        as="textarea"
                        rows={7}
                        disabled={props.loading}
                        autoComplete="off"
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-2 mt-md-5">
                  <Col md={2} className="d-flex justify-content-center justify-content-md-start mb-4">
                  </Col>
                  <Col md={10}>
                    <div className="d-flex justify-content-end">
                      <Button variant="primary" disabled={props.loading} type="submit">
                        {t('send')}
                        {props.loading &&
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="ml-1"
                          />
                        }
                      </Button>
                      <Button variant="default" onClick={e => { e.preventDefault(); setRedirect(true) }} disabled={props.loading}>
                        {t('cancel')}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            }
            {props.response && props.response.result === 0 &&
              <div className="d-flex justify-content-center">
                <div>{t('message-sent-thank-you')}</div>
              </div>
            }
          </Container>
          <Container className="mt-5 address-container">
            <h2>{t('title-2')}</h2>
            <div>
              <div className="contact-container">
                <h2>{t('contact-title')}</h2>
                <div className="d-flex address">
                  <div className="d-flex flex-column mr-4">
                    <div>{t('contact-name-line1')}</div>
                    <div>{t('contact-name-line2')}</div>
                  </div>
                  <div className="d-flex flex-column">
                    <div>{t('contact-address-line1')}</div>
                    <div>{t('contact-address-line2')}</div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </Suspense>
  )
}

const mapStateToProps = state => ({
  loading: isPending('contact', state),
  response: getOutput('contact', state)
})

const mapDispatchToProps = dispatch => ({
  contact: (data) => dispatch(actions.postDataApi('contact', '/send_contact_form/', data)),
  clearResponse: () => dispatch(actions.requestRemove('contact')),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Contact)
