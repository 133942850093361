import React, { useState } from "react"
import { connect } from 'react-redux'
import { Spinner, Col, Row, Accordion, Card, Button } from "react-bootstrap";
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getOutput, isPending } from "../../reducers";
import {
    API_TASK_REQUEST_PREFIX,
    API_TASK_DETAILS_ID,
    TAXONDB_DETAILS_PAGE_URL,
} from "../../settings"
import { useTranslation } from 'react-i18next';

const EditionDetails = props => {

    const { t } = useTranslation([ 'common', 'editions-panel' ])

    const [ detailsOpen, setDetailsOpen ] = useState(false)

    const toggleOpen = (taxonId) => {
        const temp = { ...detailsOpen }
        temp[ taxonId ] = !temp[ taxonId ]
        setDetailsOpen(temp)
    }

    return (
        <div className="m-3">
            <div>
                <Row>
                    <Col md={4}>
                        <b>{t('editions-panel:edition-id')}</b>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        {props.edition.id}
                    </Col>
                </Row>
            </div>
            <div className="mt-2">
                <Row>
                    <Col md={4}>
                        <b>{t('editions-panel:number-of-editions-total')}</b>
                    </Col>
                    <Col md={4}>
                        <b>{t('editions-panel:number-of-editions-done')}</b>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        {props.edition.total}
                    </Col>
                    <Col md={4}>
                        {props.edition.done}
                    </Col>
                </Row>
            </div>
            <div className="mt-2">
                <Row>
                    <Col md={4}>
                        <b>{t('editions-panel:number-of-editions-success')}</b>
                    </Col>
                    <Col md={4}>
                        <b>{t('editions-panel:number-of-editions-failed')}</b>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        {props.edition.correct}
                    </Col>
                    <Col md={4}>
                        {props.edition.incorrect}
                    </Col>
                </Row>
            </div>
            <div className="mt-3">
                <Row>
                    <Col md={4}>
                        <b>{t('editions-panel:edited-field')}</b>
                    </Col>
                    <Col md={4}>
                        <b>{t('editions-panel:old-value')}</b>
                    </Col>
                    <Col md={4}>
                        <b>{t('editions-panel:new-value')}</b>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        {props.edition.params.field}
                    </Col>
                    <Col md={4}>
                        {props.edition.params.old_value}
                    </Col>
                    <Col md={4}>
                        {props.edition.params.new_value}
                    </Col>
                </Row>
            </div>
            <div className="mt-3">
                <b>{t('editions-panel:filter')}</b>
                <pre className="mt-2">
                    {JSON.stringify(props.edition.params.filter, null, 2)}
                </pre>
            </div>
            <div className="mt-3">
                <b>{t('editions-panel:ip')}</b>
                <div className="mt-1 mb-2">
                    {props.edition.ip_address}
                </div>
            </div>
            {props.loadingDetails &&
                <div>
                    <Spinner
                        animation="border"
                        className="ml-2"
                    />
                </div>
            }
            <Accordion className="ml-2 mr-2">
                <Card>
                    <Accordion.Toggle as={Button} variant="secondary" eventKey="0" className="btn-block p-2">
                        {t('editions-panel:edited-taxons-details')}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            {props.editionDetails && props.editionDetails.result &&
                                <div className="mt-3">
                                    <Row>
                                        <Col md={3}>
                                            <div>
                                                <b>{t('editions-panel:correct')}</b>
                                            </div>
                                            {props.editionDetails.result.correct && props.editionDetails.result.correct.length > 0 &&
                                                <div className="ml-2">
                                                    {
                                                        props.editionDetails.result.correct.map(item => {
                                                            return (
                                                                <div key={item}>
                                                                    {item}
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            }
                                            {props.editionDetails.result.correct && props.editionDetails.result.correct.length === 0 &&
                                                <div>
                                                    {t('editions-panel:no-results')}
                                                </div>
                                            }
                                        </Col>
                                        <Col md={9}>
                                            <div>
                                                <b>{t('editions-panel:incorrect')}</b>
                                            </div>
                                            {props.editionDetails.result.incorrect && props.editionDetails.result.incorrect.length > 0 &&
                                                <div className="ml-2">
                                                    {
                                                        props.editionDetails.result.incorrect.map(item => {
                                                            return (
                                                                <div key={item.kolekcjanumerokazu} className="mb-1">
                                                                    {item.kolekcjanumerokazu}
                                                                    <Button
                                                                        variant="secondary"
                                                                        size="sm"
                                                                        className="ml-2"
                                                                        title={t('editions-panel:show-error-details')}
                                                                        onClick={() => toggleOpen(item.kolekcjanumerokazu)}
                                                                    >
                                                                        <FontAwesomeIcon icon={faInfoCircle} />
                                                                    </Button>
                                                                    <Button
                                                                        variant="secondary"
                                                                        size="sm"
                                                                        className="ml-2"
                                                                        title={t('editions-panel:redirect-to-taxon')}
                                                                        onClick={e => {
                                                                            window.open(
                                                                                window.location.origin + TAXONDB_DETAILS_PAGE_URL + item.kolekcjanumerokazu,
                                                                                new Date().valueOf()
                                                                            )
                                                                        }
                                                                        }
                                                                    >
                                                                        <img src="/images/amuZasób 5@4x-100.jpg" alt={t('a11y:icon-taxon')} width="31px" />
                                                                    </Button>
                                                                    {detailsOpen[ item.kolekcjanumerokazu ] &&
                                                                        <pre>
                                                                            {JSON.stringify(item, null, 2)}
                                                                        </pre>
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            }
                                            {props.editionDetails.result.incorrect && (props.editionDetails.result.incorrect.length === 0) &&
                                                <div>
                                                    {t('editions-panel:no-results')}
                                                </div>
                                            }
                                        </Col>
                                    </Row>
                                </div>
                            }
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    editionDetails: getOutput(`${API_TASK_REQUEST_PREFIX}${API_TASK_DETAILS_ID}${ownProps.requestId}/`, state),
    loadingDetails: isPending(`${API_TASK_REQUEST_PREFIX}${API_TASK_DETAILS_ID}${ownProps.requestId}/`, state),
})

const mapDispatchToProps = dispatch => ({

})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditionDetails)
