import React, { useEffect, Suspense, useState } from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getOutputOrDefault, isPending } from "../../reducers"
import { postDataApi, requestSuccess } from '../../actions'
import { Row, Col, Button, Spinner, ListGroup, Collapse } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt, faBan, faUserCog } from '@fortawesome/free-solid-svg-icons'
import Confirm from "../Confirm"
import Loader from '../../utils/loader'
import {
    API_TEAM_GET_USERS_ID,
    API_TEAM_GET_USERS_URL,
    API_TEAM_DEL_USER_ID,
    API_TEAM_DEL_USER_URL,
    API_TEAM_SET_ADMIN_ID,
    API_TEAM_SET_ADMIN_URL,
    API_TEAM_UNSET_ADMIN_ID,
    API_TEAM_UNSET_ADMIN_URL,
    API_TEAM_ACCEPT_USER_ID,
    API_TEAM_ACCEPT_USER_URL,
    MEMBER_KIND_ADMINISTRATOR,
    MEMBER_KIND_NORMAL_USER,
    MEMBER_KIND_APPLICANT,
    MEMBER_KIND_MANAGER,
} from '../../settings'
import * as notify from '../../utils/notify'
import UserInfoPanel from "./UserInfoPanel";
import ListGroupItemDiv from "../ListGroupItemDiv";

const isUserAdmin = (user) => {
    return user && user[ 'member_kind' ] && user[ 'member_kind' ] === MEMBER_KIND_ADMINISTRATOR
}

const isUserNormal = (user) => {
    return user && user[ 'member_kind' ] && user[ 'member_kind' ] === MEMBER_KIND_NORMAL_USER
}

const isUserApplicant = (user) => {
    return user && user[ 'member_kind' ] && user[ 'member_kind' ] === MEMBER_KIND_APPLICANT
}

const isUserManager = (team) => {
    return team && team[ 'member_kind' ] && team[ 'member_kind' ] === MEMBER_KIND_MANAGER
}


const TeamDetails = props => {
    const { t, i18n } = useTranslation([ 'team' ])
    const { team, getUsers } = props

    const [ isUserInfoPanelOpen, setIsUserInfoPanelOpen ] = useState({})

    useEffect(() => {
        getUsers(team.id, i18n)
    }, [getUsers, team.id, i18n])

    const toggleAdminRights = user => {
        if (isUserAdmin(user)) {
            props.unsetAdmin(user.id, props.team.id, props.requests, t)
        } else {
            props.setAdmin(user.id, props.team.id, props.requests, t)
        }
    }

    const acceptUser = user => {
        props.acceptUser(user.id, props.team.id, props.requests, t)
    }

    const removeUser = user => {
        props.removeUser(user.id, props.team.id, props.requests, t)
    }

    const toggleUserInfoPanel = userId => {
        const temp = { ...isUserInfoPanelOpen }
        if (temp[ userId ]) {
            temp[ userId ] = false
        } else {
            temp[ userId ] = true
        }
        setIsUserInfoPanelOpen(temp)
    }

    const canSeeUserInfo = (isUserAdmin(team) || isUserManager(team))

    return (
        <Suspense fallback={<Loader />}>
            {props.loading &&
                <Row className="ml-4 mt-4 mb-4">
                    <Col>
                        <div className="text-center">
                            <Spinner animation="border" role="status" />
                        </div>
                    </Col>
                </Row>
            }
            {!props.loading &&
                <div className="ml-4 mt-2 mb-4">
                    <ListGroup.Item className="d-flex">
                        <div className="w-50"><b>{t('column-first-name')}</b></div>
                        <div className="w-50"><b>{t('column-last-name')}</b></div>
                        <div className="w-50"><b>{t('column-role')}</b></div>
                        <div className="w-25"></div>
                    </ListGroup.Item>
                    {props.users.map((user, indx) =>
                        <React.Fragment key={indx}>
                            <ListGroupItemDiv className={canSeeUserInfo ? "d-flex album-element" : "d-flex"} onClick={() => toggleUserInfoPanel(user.id)}>
                                {canSeeUserInfo &&
                                    <div>
                                        {isUserInfoPanelOpen[ user.id ] &&
                                            <img src="/images/list-open.svg" alt={t("a11y:list-opened")} />
                                        }
                                        {!isUserInfoPanelOpen[ user.id ] &&
                                            <img src="/images/list-closed.svg" alt={t("a11y:list-closed")} />
                                        }
                                    </div>
                                }
                                <div className="w-50">{user.first_name}</div>
                                <div className="w-50">{user.last_name}</div>
                                <div className="w-50">{user.member_kind}</div>
                                <div className="w-25 d-flex justify-content-end">
                                    {(isUserAdmin(props.team) || isUserManager(props.team) ) && (isUserNormal(user) || isUserAdmin(user)) &&
                                        <Confirm
                                            onConfirm={() => toggleAdminRights(user)}
                                            size="sm"
                                            pending={props.querying}
                                            disabled={props.querying}
                                            className="mr-1"
                                            buttonLabel={<FontAwesomeIcon icon={isUserAdmin(user) ? faBan : faUserCog} title={isUserAdmin(user) ? t('remove-admin-rights') : t('add-admin-rights')} />}
                                            confirmButtonLabel={isUserAdmin(user) ? t('yes-remove-admin') : t('yes-add-admin')}
                                            confirmationText={isUserAdmin(user) ? t('confirm-remove-admin') : t('confirm-add-admin')}
                                        ></Confirm>
                                    }
                                    {(isUserAdmin(props.team) || isUserManager(props.team)) && isUserApplicant(user) &&
                                        <Button variant="secondary" size="sm" className="mr-1" onClick={() => acceptUser(user)} disabled={props.querying}>
                                            {t('accept-member')}
                                        </Button>
                                    }
                                    {(isUserAdmin(props.team) || isUserManager(props.team)) && user.id !== props.user.id &&
                                        <Confirm
                                            onConfirm={() => removeUser(user)}
                                            size="sm"
                                            pending={props.removingItems}
                                            disabled={props.removingItems}
                                            className="mr-1"
                                            buttonLabel={<FontAwesomeIcon icon={faSignOutAlt} title={t('remove-member')} />}
                                            confirmButtonLabel={t('remove-member')}
                                            confirmationText={t('confirm-remove-member')}
                                        ></Confirm>
                                    }
                                </div>
                            </ListGroupItemDiv>
                            {canSeeUserInfo &&
                                <Collapse in={isUserInfoPanelOpen[ user.id ]}>
                                    <div>
                                        <UserInfoPanel userId={user.id}></UserInfoPanel>
                                    </div>
                                </Collapse>
                            }
                        </React.Fragment>
                    )}
                </div>
            }
        </Suspense>
    )
}

const mapStateToProps = (state, ownProps) => ({
    user: state.user.data,
    loading: isPending(API_TEAM_GET_USERS_ID + ownProps.team.id, state),
    querying: isPending(API_TEAM_DEL_USER_ID + ownProps.team.id, state) ||
        isPending(API_TEAM_SET_ADMIN_ID + ownProps.team.id, state) ||
        isPending(API_TEAM_UNSET_ADMIN_ID + ownProps.team.id, state) ||
        isPending(API_TEAM_ACCEPT_USER_ID + ownProps.team.id, state),
    users: getOutputOrDefault(API_TEAM_GET_USERS_ID + ownProps.team.id, state, { team_users: [] })[ 'team_users' ],
    requests: state.requests
})

const mapDispatchToProps = dispatch => ({
    getUsers: (teamId, i18n) => dispatch(postDataApi(API_TEAM_GET_USERS_ID + teamId, API_TEAM_GET_USERS_URL, {
        team_id: teamId
    })).catch(res => {
        notify.error(i18n.t('common:not-allowed-info'), res.result)
    }),
    setAdmin: (userId, teamId, requests, t) => {
        return dispatch(postDataApi(API_TEAM_SET_ADMIN_ID + teamId, API_TEAM_SET_ADMIN_URL, {
            team_id: teamId,
            user_id: userId
        })).then(() => {
            const oldData = requests[ API_TEAM_GET_USERS_ID + teamId ].output
            const newData = oldData.team_users.map((u) => {
                if (u.id === userId) {
                    u.member_kind = MEMBER_KIND_ADMINISTRATOR
                }

                return u
            })

            notify.success(t('set-admin'))
            dispatch(requestSuccess(API_TEAM_GET_USERS_ID + teamId, { team_users: newData }))
        }).catch((res) => {
            notify.error(t('couldnt-set-admin'), res.result)
        })
    },
    unsetAdmin: (userId, teamId, requests, t) => {
        return dispatch(postDataApi(API_TEAM_UNSET_ADMIN_ID + teamId, API_TEAM_UNSET_ADMIN_URL, {
            team_id: teamId,
            user_id: userId
        })).then(() => {
            const oldData = requests[ API_TEAM_GET_USERS_ID + teamId ].output
            const newData = oldData.team_users.map((u) => {
                if (u.id === userId) {
                    u.member_kind = MEMBER_KIND_NORMAL_USER
                }

                return u
            })

            notify.success(t('unset-admin'))
            dispatch(requestSuccess(API_TEAM_GET_USERS_ID + teamId, { team_users: newData }))
        }).catch((res) => {
            notify.error(t('couldnt-unset-admin'), res.result)
        })
    },
    removeUser: (userId, teamId, requests, t) => {
        return dispatch(postDataApi(API_TEAM_DEL_USER_ID + teamId, API_TEAM_DEL_USER_URL, {
            team_id: teamId,
            user_id: userId
        })).then(() => {
            const oldData = requests[ API_TEAM_GET_USERS_ID + teamId ].output
            const newData = oldData.team_users.filter((u) => u.id !== userId)
            notify.success(t('removed-user'))
            dispatch(requestSuccess(API_TEAM_GET_USERS_ID + teamId, { team_users: newData }))
        }).catch((res) => {
            notify.error(t('couldnt-remove-user'), res.result)
        })
    },
    acceptUser: (userId, teamId, requests, t) => {
        return dispatch(postDataApi(API_TEAM_ACCEPT_USER_ID + teamId, API_TEAM_ACCEPT_USER_URL, {
            team_id: teamId,
            user_id: userId
        })).then(() => {
            const oldData = requests[ API_TEAM_GET_USERS_ID + teamId ].output
            const newData = oldData.team_users.map((u) => {
                if (u.id === userId) {
                    u.member_kind = MEMBER_KIND_NORMAL_USER
                }

                return u
            })

            notify.success(t('accepted-user'))
            dispatch(requestSuccess(API_TEAM_GET_USERS_ID + teamId, { team_users: newData }))
        }).catch((res) => {
            notify.error(t('couldnt-accept-user'), res.result)
        })
    },
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TeamDetails)
