import React, { forwardRef, useState } from 'react'
import { connect } from 'react-redux'
import { DropdownButton } from  "react-bootstrap"; 
import L from 'leaflet';
import { useTranslation } from 'react-i18next'
import { configureMapDrawings } from '../../utils/mapDrawings'
import { setMapMarkerColor, setMapMarkerIcon, setMapMarkerSize } from '../../actions/map'
import useMarkerShapeColorSelector from '../../hooks/useMarkerShapeColorSelector';
import useMapRedux from '../../hooks/useMapRedux';

const MapIconsToolbar  = forwardRef((props, ref) => {
    const { t } = useTranslation(['map', 'taxondb', 'taxondb-attributes'])
    const { value: markerColor } = useMapRedux("markerColor", "black");
    const { value: markerIcon } = useMapRedux("markerIcon", "map-marker");
    const { value: markerSize } = useMapRedux("markerSize", "2x");
    const [color, setColor] = useState(markerColor)
    const [iconShape, setIconShape] = useState(markerIcon)
    const [size, setSize] = useState(markerSize)

    const updateColor = newColor => {
        setColor(newColor)
        ref.drawRef.current.remove()
        let html = `<i class="fa fa-${iconShape} fa-${size}" style="color: ${newColor}"></i>`

        var myIcon = L.divIcon({
            html: html,
            className: 'myDivIcon',
            iconSize: [20, 40]
        });

        var drawingOptions = configureMapDrawings(L, t, ref.userDrawingsRef.current, myIcon)
        ref.drawRef.current = new L.Control.Draw(drawingOptions);
        ref.mapRef.current.addControl(ref.drawRef.current);
        props.setMarkerColor(newColor)
    }

    const updateShape = newShape => {
        setIconShape(newShape)
        ref.drawRef.current.remove()
        let html = `<i class="fa fa-${newShape} fa-${size}" style="color: ${color}"></i>`

        var myIcon = L.divIcon({
            html: html,
            className: 'myDivIcon',
            iconSize: [20, 40]
        });

        var drawingOptions = configureMapDrawings(L, t, ref.userDrawingsRef.current, myIcon)
        ref.drawRef.current = new L.Control.Draw(drawingOptions);
        ref.mapRef.current.addControl(ref.drawRef.current);
        
        props.setMarkerIcon(newShape)
    }

    const updateSize = newSize => {
        setSize(newSize)
        ref.drawRef.current.remove()
        let html = `<i class="fa fa-${iconShape} fa-${newSize}" style="color: ${color}"></i>`

        var myIcon = L.divIcon({
            html: html,
            className: 'myDivIcon',
            iconSize: [20, 40]
        });

        var drawingOptions = configureMapDrawings(L, t, ref.userDrawingsRef.current, myIcon)
        ref.drawRef.current = new L.Control.Draw(drawingOptions);
        ref.mapRef.current.addControl(ref.drawRef.current);
        
        props.setMarkerSize(newSize)
    }
    const {DropdownShapeItems, DropdownColorItems, DropdownSizeItems} = useMarkerShapeColorSelector(updateShape, updateColor, updateSize)

    return (
        <>
            <div className="mt-3">
                {t('map:userdrawings-style-title')}
            </div>
            <DropdownButton
                title={t("map:marker-icon")} 
                disabled={props.loading}
                className="mt-3"
                >
                    <DropdownShapeItems></DropdownShapeItems>
                </DropdownButton>
                <DropdownButton
                    title={t("map:marker-color")}  
                    disabled={props.loading}
                    className="mt-3"
                    >
                    <DropdownColorItems></DropdownColorItems>
                </DropdownButton>
                <DropdownButton
                    title={t("map:marker-size")}  
                    disabled={props.loading}
                    className="mt-3"
                    >
                    <DropdownSizeItems></DropdownSizeItems>
                </DropdownButton>
        </>
    )
})

const mapStateToProps = (state, ownProps) => ({

})

const mapDispatchToProps = dispatch => ({
    setMarkerIcon: icon => dispatch(setMapMarkerIcon( icon)),
    setMarkerColor: icon => dispatch(setMapMarkerColor(icon)),
    setMarkerSize: icon => dispatch(setMapMarkerSize(icon)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(MapIconsToolbar)
