import React from 'react';
import { useTranslation } from 'react-i18next';

const SeeMoreIcon = props => {
    const { t } = useTranslation(['a11y'])
    return(
        <div className={`d-flex justify-content-center align-items-center ${props.className ? props.className : ""}`}>
            {props.isOpen &&
                <img src="/images/list-open.svg" alt={t("a11y:list-opened")} width="24"/>
            }
            {!props.isOpen &&
                <img src="/images/list-closed.svg" alt={t("a11y:list-closed")} width="24"/>
            }
        </div>
    )
}

export default SeeMoreIcon