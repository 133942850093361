import React, { useEffect, useState, useCallback } from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getOutputOrDefault, isPending } from "../../reducers"
import { postDataApi } from '../../actions'
import RedirectLoginWithReturn from "../access/RedirectLoginWithReturn"
import { Container, Row, Col, ListGroup, Spinner, Form, FormLabel, Button, Collapse } from "react-bootstrap"
import ConnectedPagination from '../ConnectedPagination'
import {
    API_URL_POSTFIX,
    PROJECTS_CREATE_PROJECT_ID,
    PROJECTS_CREATE_PROJECT_URL,
    PROJECTS_GET_ALL_ID,
    PROJECTS_GET_ALL_URL,
    PROJECTS_GET_OBSERVATIONS_ID,
    PROJECTS_GET_OBSERVATIONS_URL,
    PROJECTS_SEARCH_OBSERVATIONS_URL,
    PROJECTS_SEARCH_OBSERVATIONS_REQUEST_ID,
    PROJECTS_PAGE_URL_EN,
    PROJECTS_PAGE_URL_PL,
    //PROJECTS_GET_PREFIX,
} from '../../settings'
import ProjectsListItem from './ProjectsListItem'
import ProjectsExporter from "./ProjectsExporter"
import CreateProject from './CreateProject'
import I18nPathTranslator from "../I18nPathTranslator";
import SEO from "../SEO";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import UserSpace from "./UserSpace";
import ListGroupItemDiv from "../ListGroupItemDiv";

const ProjectsContainer = props => {
    const { t } = useTranslation(['projects', 'paths', 'home'])

    const [open, setOpen] = useState(false)
    const [createProjectOpen, setCreateProjectOpen] = useState(false)
    const [currentFilter, setCurrentFilter] = useState('')
    const [searchText, setSearchText] = useState('')
    const [path, setPath] = useState([])
    const getProjectsCallback = useCallback(props.getProjects, [])

    useEffect(() => { //todo
        getProjectsCallback()
    }, [getProjectsCallback])

    const searchObservations = () => {
        setPath([])
        setOpen(true)
        setCurrentFilter(searchText)
        props.searchObservations(searchText)
    }

    const showOnTree = value => { //todo
        if (value.project_position > 20) {
            props.getProjects(Math.ceil((value.project_position || 1) / 20)).then(res => {
                setPath([value.project_id, value.id, value.found_by, value.observation_position])
                setOpen(false)
            })
        } else {
            setPath([value.project_id, value.id, value.found_by, value.observation_position])
            setOpen(false)
        }
    }

    const ElementText = props => {
        if (props.match.length > 0) {
            const indx = props.text.toLowerCase().indexOf(props.match.toLowerCase())
            if (indx >= 0) {
                return <span><span>{props.text.substring(0, indx)}</span><b>{props.text.substring(indx, indx + props.match.length)}</b><span>{props.text.substring(indx + props.match.length, props.text.length)}</span></span>
            } else {
                return <span>{props.text}</span>
            }
        } else {
            return <span>{props.text}</span>
        }
    }

    return (
        <>
            {!props.viewedBy &&
                <>
                    <I18nPathTranslator {...props} pathEN={PROJECTS_PAGE_URL_EN} pathPL={PROJECTS_PAGE_URL_PL} />
                    <SEO title={t('menu:my-projects')} />
                </>
            }
            {!props.loading && props.user && !props.user.data &&
                <RedirectLoginWithReturn lang={props.lang} returnUrl={props.lang ? t("paths:projects", {lng: props.lang}) : t("paths:projects")} />
            }
            {props.user && props.user.data &&
                <Container className="mt-2">
                    {!props.viewedBy && <h1>{t('menu:my-projects')}</h1>}
                    <Row>
                        <Col>
                            {props.loading &&
                                <div className="text-center">
                                    <Spinner animation="border" role="status" />
                                </div>
                            }
                            {!props.loading && props.projects &&
                                <div>
                                    {!props.viewedBy &&
                                    <Form onSubmit={e => { e.preventDefault() }} className="my-amunatcoll-search-form">
                                        <Row>
                                            <Col md={8} sm={9}>
                                                <Form.Group>
                                                    <Form.Control autoFocus onChange={e => setSearchText(e.target.value)} value={searchText} name="search" id="search" type="text" disabled={props.loading || props.searching} autoComplete="off" placeholder={t("home:search2")}></Form.Control>
                                                    <FormLabel htmlFor="search">{t('observation-search-in-info')}</FormLabel>
                                                </Form.Group>
                                            </Col>
                                            <Col md={4} sm={3} className="d-flex">
                                                <Form.Group>
                                                    <Button variant="primary" className="search-button" onClick={searchObservations} disabled={props.loading || props.searching} type="submit">
                                                        {t('common:search')}
                                                        {props.loading &&
                                                            <Spinner
                                                                as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                                className="ml-1"
                                                            />
                                                        }
                                                    </Button>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Form>
                                    }
                                    {props.searching &&
                                        <Row>
                                            <Col className="text-center">
                                                <Spinner animation="border" role="status" />
                                            </Col>
                                        </Row>
                                    }
                                    {!props.viewedBy && 
                                    <Row>
                                        <Col>
                                            <UserSpace />
                                        </Col>
                                    </Row>
                                    }
                                    <Collapse in={open}>
                                        <div>
                                            {!props.searching && props.observations && props.observations.length > 0 &&
                                                <ListGroup>
                                                    <ConnectedPagination requestId={PROJECTS_SEARCH_OBSERVATIONS_REQUEST_ID} url={API_URL_POSTFIX + PROJECTS_SEARCH_OBSERVATIONS_URL} customFilter={{ phrase: currentFilter }}></ConnectedPagination>
                                                    {props.observations.map((value, indx) => (
                                                        <ListGroupItemDiv key={indx} onClick={e=>showOnTree(value)} className="d-flex album-element">
                                                            <div className="flex-grow-1">
                                                                <ElementText text={value.name} match={currentFilter}></ElementText>
                                                                {value.found_by.find(obj => obj.includes('note')) &&
                                                                    <span className="pl-1">({t('found-in-note')})</span>
                                                                }
                                                                {value.found_by.find(obj => obj.includes('description')) &&
                                                                    <span className="pl-1">({t('found-in-description')})</span>
                                                                }
                                                            </div>
                                                            <div className="d-flex">
                                                                <Button
                                                                    variant="secondary"
                                                                    size="sm"
                                                                    className="align-self-center mr-1"
                                                                >
                                                                    <FontAwesomeIcon icon={faEye} title={t('common:show')} />
                                                                </Button>
                                                            </div>
                                                        </ListGroupItemDiv>
                                                    ))}
                                                    <ConnectedPagination last requestId={PROJECTS_SEARCH_OBSERVATIONS_REQUEST_ID} url={API_URL_POSTFIX + PROJECTS_SEARCH_OBSERVATIONS_URL} customFilter={{ phrase: currentFilter }}></ConnectedPagination>
                                                </ListGroup>
                                            }
                                            {!props.searching && props.observations && props.observations.length === 0 &&
                                                <Row>
                                                    <Col>
                                                        {t('no-items-found')}
                                                    </Col>
                                                </Row>
                                            }
                                        </div>
                                    </Collapse>
                                    <Collapse in={!open}>
                                        <Row>
                                            {!props.searching && props.projects && props.projects.length > 0 &&
                                                <Col>
                                                    <ProjectsExporter requestId={props.requestId} url={props.requestUrl}>
                                                        {!props.viewedBy &&
                                                            <Button variant="primary" onClick={() => setCreateProjectOpen(!createProjectOpen)} disabled={props.loading || props.searching || props.creating} type="submit" className="ml-2">
                                                                {t('projects:create-project')}
                                                                {props.creating &&
                                                                    <Spinner className="ml-2" as="div" animation="border" role="status" aria-hidden="true" />
                                                                }
                                                            </Button>
                                                        }
                                                    </ProjectsExporter>
                                                    {createProjectOpen &&
                                                        <CreateProject onDone={() => setCreateProjectOpen(false)} />
                                                    }
                                                    <ConnectedPagination requestId={PROJECTS_GET_ALL_ID} url={API_URL_POSTFIX + PROJECTS_GET_ALL_URL}></ConnectedPagination>
                                                    <ListGroup>
                                                        {props.projects.map((project, i) => <ProjectsListItem viewedBy={props.viewedBy} projectId={project.id} key={i} projectName={project.name} requestId={PROJECTS_GET_ALL_ID} path={path} />)}
                                                    </ListGroup>
                                                    <ConnectedPagination last requestId={PROJECTS_GET_ALL_ID} url={API_URL_POSTFIX + PROJECTS_GET_ALL_URL}></ConnectedPagination>
                                                </Col>
                                            }
                                            {!props.searching && props.projects && props.projects.length === 0 &&
                                                <Col>
                                                    {!props.viewedBy &&
                                                        <Button variant="primary" onClick={() => setCreateProjectOpen(!createProjectOpen)} disabled={props.loading || props.searching || props.creating} type="submit">
                                                            {t('projects:create-project')}
                                                            {props.creating &&
                                                                <Spinner className="ml-2" as="div" animation="border" role="status" aria-hidden="true" />
                                                            }
                                                        </Button>
                                                    }
                                                    {createProjectOpen &&
                                                        <CreateProject onDone={() => setCreateProjectOpen(false)} />
                                                    }
                                                    <div className="mt-3">
                                                        {t('no-items-found')}
                                                    </div>
                                                </Col>
                                            }
                                        </Row>
                                    </Collapse>
                                </div>
                            }
                        </Col>
                    </Row>
                </Container>
            }
        </>
    )
}

const mapStateToProps = (state, ownProps) => ({
    user: state.user,
    loading: isPending(PROJECTS_GET_ALL_ID, state),
    searching: isPending(PROJECTS_SEARCH_OBSERVATIONS_REQUEST_ID, state),
    creating: isPending(PROJECTS_CREATE_PROJECT_ID, state),
    projects: getOutputOrDefault(PROJECTS_GET_ALL_ID, state, [])[ 'items' ],
    observations: getOutputOrDefault(PROJECTS_SEARCH_OBSERVATIONS_REQUEST_ID, state, { items: [] })['items'],
    requests: state.requests
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    createProject: (name) => dispatch(postDataApi(PROJECTS_CREATE_PROJECT_ID, PROJECTS_CREATE_PROJECT_URL, {
        name: name
    })),
    getProjects: page => dispatch(postDataApi(PROJECTS_GET_ALL_ID, PROJECTS_GET_ALL_URL, page ? { someone_id: ownProps.viewedBy, pagination: { currentPage: page, perPage: 20 } } : { someone_id: ownProps.viewedBy })),
    getObservations: (id) => dispatch(postDataApi(PROJECTS_GET_OBSERVATIONS_ID + id, `${PROJECTS_GET_OBSERVATIONS_URL}${id}/`)),

    searchObservations: phrase => dispatch(postDataApi( //todo
        PROJECTS_SEARCH_OBSERVATIONS_REQUEST_ID,
        PROJECTS_SEARCH_OBSERVATIONS_URL,
        { phrase })),
    // getItems: obj => dispatch(postDataApi( //todo
    //     PROJECTS_GET_PREFIX + 'project',
    //     PROJECTS_GET_OBSERVATIONS_URL,
    //     obj ? obj : { album_id: 0 })),
    // getPathItems: obj => dispatch(postDataApi( //todo
    //     PROJECTS_GET_PREFIX + 'path',
    //     PROJECTS_GET_OBSERVATIONS_URL,
    //     obj)),
    // setRequest: (id, data) => dispatch(requestSuccess(id, data)), //todo
    // clearRequests: () => dispatch(requestRemoveStartsWith(PROJECTS_GET_PREFIX + 'project-')), //todo
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectsContainer)
