import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunkMiddleware from 'redux-thunk'
import Main from './Main';
//import * as serviceWorker from './serviceWorker';
import reducers from './reducers'
import { I18nextProvider } from 'react-i18next'
import i18n from './utils/i18n';

const store = createStore(reducers, applyMiddleware(thunkMiddleware))

document.getElementById('initial-content').remove()
const meta = document.getElementsByTagName('meta')
for (let i in meta) {
    if (meta[i].getAttribute && meta[i].getAttribute('name') === 'description') {
        meta[i].remove()
    }
}

ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <Provider store={store}>
            <Main />
        </Provider>
    </I18nextProvider>,
    document.getElementById('root')
)

// should be used in test environment only!
if (window.Cypress) {
    window.store = store
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
