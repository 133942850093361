import React, { forwardRef } from 'react'
import { connect } from 'react-redux'
import { Button, Row, Form, Col, Spinner, Collapse } from  "react-bootstrap"; 
import { useTranslation } from 'react-i18next'
import {
    TAXONDB_SEARCH_WITH_AGGREGATION_REQUEST_URL,
    MAP_DATA_REQUEST_TIMEOUT,
    TAXONDB_SEARCH_MAP_DATA_REQUEST_ID,
    TAXONDB_SEARCH_MAP_DATA_REQUEST_URL,
    MAP_COLORPICKER_LIST,
    TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID,
    TAXON_SEARCH_IS_DYNAMIC,
    TAXONDB_SEARCH_GENUS_REQUEST_ID,
    TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID,
    MAP_OPENED_TOOL_VISUALIZATION_DETAILS,
} from '../../../../settings'
import { getField, isPending, getFields } from "../../../../reducers";
import { setAnimationYears, setOpenedMapTool, setTaxonsByYear } from '../../../../actions/map'
import { postDataAnc } from '../../../../actions'
import { setHistoricalMarkerIcon, setHistoricalMarkerColor, setHistoricalMarkerSize, setArrayOfYears } from '../../../../actions/map'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleRight,faMapMarked } from '@fortawesome/free-solid-svg-icons'
import { setFields, runHistoricalAnalysis } from '../../../../actions/forms'
import * as actions from '../../../../actions'
import { CirclePicker } from 'react-color';
import { setTooManyTaxonsWarning, setTaxonsFoundNumber } from '../../../../actions/map'
import { ROK_ZBIORU_FIELD_NAME } from '../../../taxonDB/attributesStructure';
import { historicalMapSearch } from "./HistoricalSearch"

const HistoricalMap = forwardRef((props, ref) => {

    const { collapsed, openSlidebar, markerSize, markerIcon, markerColor, setActiveMapTool, activeMapTool } = { ...props }
    const { t } = useTranslation(['map', 'taxondb-attributes', 'taxondb-groups'])
 
    return(
        <div>
            {collapsed &&
                <div className="sidebar-single-button-container">
                    <button
                        title={t('map:visualisation-parameters') + ' - ' + t('menu:historical-analysis-map')}
                        className="sidebar-button-collapsed"
                        onClick={() => {
                            openSlidebar()
                            setActiveMapTool(MAP_OPENED_TOOL_VISUALIZATION_DETAILS)
                        }} 
                    >
                    <FontAwesomeIcon icon={faMapMarked} color={"white"}/>
                    </button>
                </div>
            }
            {!collapsed &&
                <div>
                    <div className="sidebar-opened-buttons-container">
                        <div className="sidebar-single-button-container">
                            <button
                                title={t('map:visualisation-parameters') + ' - ' + t('menu:historical-analysis-map')}
                                className="sidebar-button-collapsed"
                                onClick={() => {activeMapTool === MAP_OPENED_TOOL_VISUALIZATION_DETAILS ? setActiveMapTool(null) : setActiveMapTool(MAP_OPENED_TOOL_VISUALIZATION_DETAILS)}}
                            >
                                <FontAwesomeIcon icon={faMapMarked} color={"white"}/>
                            </button>
                        </div>
                        <div
                            className="sidebar-text playContentOpacityAnimation changeContentOpacityAnimation"
                            onClick={() => {activeMapTool === MAP_OPENED_TOOL_VISUALIZATION_DETAILS ? setActiveMapTool(null) : setActiveMapTool(MAP_OPENED_TOOL_VISUALIZATION_DETAILS)}}
                        >  
                            {t('map:visualisation-parameters') + ' - ' + t('menu:historical-analysis-map')}
                        </div> 
                    <div className="sidebar-items-arrow changeOpacityAnimation playOpacityAnimation">
                        <FontAwesomeIcon icon={activeMapTool === MAP_OPENED_TOOL_VISUALIZATION_DETAILS ? faAngleDown : faAngleRight} color={"black"}/>
                    </div>
                </div>
                <Collapse in={activeMapTool === MAP_OPENED_TOOL_VISUALIZATION_DETAILS}>
                    <div className="ml-4 playContentOpacityAnimation changeContentOpacityAnimation">
                        <Row>
                            <Form.Group as={Col} sm={6} md={6}>
                                <Form.Label htmlFor={'markerIcon'}>
                                    {t("map:select-icon-shape")}
                                </Form.Label>
                                <Form.Control as="select" size="sm" custom multiple
                                    id={'markerIcon'} 
                                    defaultValue={[markerIcon]}
                                    name={'markerIcon'}
                                    className='fa form-control'
                                    disabled={props.loading1 || props.loading2 || props.loading3 || props.loadingMap}
                                    onChange={(e) => {
                                            if (e.target.value){
                                                props.setMapMarkerIcon(e.target.value)
                                            }
                                        }
                                    }
                                >
                                    <option value="fa fa-map-marker">&#xf041;</option>
                                    <option value="fa fa-map-pin">&#xf276;</option>
                                    <option value="fa fa-thumb-tack">&#xf08d;</option>
                                    <option value="fa fa-star">&#xf005;</option>
                                    <option value="fa fa-star-o">&#xf006;</option>
                                    <option value="fa fa-circle">&#xf111;</option>
                                    <option value="fa fa-circle-o">&#xf10c;</option>
                                    <option value="fa fa-bullseye">&#xf140;</option>
                                    <option value="fa fa-square">&#xf0c8;</option>
                                    <option value="fa fa-square-o">&#xf096;</option>
                                    <option value="fa fa-cube">&#xf1b2;</option>
                                    <option value="fa fa-bookmark">&#xf02e;</option>
                                    <option value="fa fa-asterisk">&#xf069;</option>
                                    <option value="fa fa-times">&#xf00d;</option>
                                    <option value="fa fa-plus">&#xf067;</option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} sm={6} md={6}>
                                <Form.Label htmlFor="markerColor">
                                    {t("map:select-icon-color")} <span className="color-picker-tip" style={{backgroundColor: markerColor}} />
                                </Form.Label>
                                <CirclePicker 
                                    id={"markerColor"}
                                    name={"markerColor"}
                                    onChange={color => props.setMapMarkerColor(color.hex) }
                                    colors={MAP_COLORPICKER_LIST}
                                    width={"100%"}
                                    circleSize={24}
                                />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} sm={6} md={6}>
                                <Form.Label htmlFor={'iconSize'}>
                                    {t("map:select-icon-size")}
                                </Form.Label>
                                <Form.Control as="select" size="sm" custom  
                                    id={'iconSize'} 
                                    name={'iconSize'}
                                    defaultValue={markerSize}
                                    className="form-control"
                                    disabled={props.loading1 || props.loading2 || props.loading3 || props.loadingMap}
                                    onChange={(e) => {
                                            if (e.target.value){
                                                props.setMapMarkerSize(e.target.value)
                                            }
                                        }
                                    }
                                >
                                    <option value="fa-sm">{t("map:map-icon-small")}</option>
                                    <option value="fa-lg">{t("map:map-icon-medium")}</option>
                                    <option value="fa-2x">{t("map:map-icon-large")}</option>
                                </Form.Control>
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} md={12} sm={12} className="d-flex align-items-end" >
                                <div className="flex-grow-1" />
                                {(props.loading1 || props.loading2 || props.loading3 || props.loadingMap) &&
                                <Spinner 
                                    animation="border"
                                    size="sm"
                                    aria-hidden="true"
                                    className="mb-3 mr-2"
                                />
                                }
                                <Button 
                                    onClick={() => historicalMapSearch(ref, props, t)} 
                                    className="mb-3 ml-2 mr-3"
                                    disabled={props.loading1 || props.loading2 || props.loading3 || props.loadingMap} 
                                    variant={"primary"}
                                >
                                    {t("show-on-map")}
                                </Button>
                            </Form.Group>
                        </Row>
                    </div>
                </Collapse>
            </div>     
            }
        </div>
    )
})

const mapStateToProps = state => ({
    isDynamic: getField(TAXON_SEARCH_IS_DYNAMIC, TAXON_SEARCH_IS_DYNAMIC, state, "0"),
    filter1: getFields(TAXONDB_SEARCH_GENUS_REQUEST_ID, state),
    filter2: JSON.parse(getField(TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID, TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID, state, "null")),
    filter3: JSON.parse(getField(TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, state, "null")),
    loading1: isPending(TAXONDB_SEARCH_GENUS_REQUEST_ID, state),
    loading2: isPending(TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID, state),
    loading3: isPending(TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, state),
    loadingMap: isPending(TAXONDB_SEARCH_MAP_DATA_REQUEST_ID, state),
    fields1: getFields(TAXONDB_SEARCH_GENUS_REQUEST_ID, state),
    fields2: getFields(TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID, state),
    fields3: getFields(TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, state),
    animationYears: state.map.animationYears,
    taxonsByYear: state.map.taxonsByYear,
    animationTimeouts: state.map.animationTimeouts,
    markerColor: state.map.historicalMarkerColor,
    markerIcon: state.map.historicalMarkerIcon,
    markerSize: state.map.historicalMarkerSize,
    taxonsWarning: state.map.taxonsWarning,
    arrayOfYears: state.map.arrayOfYears,
    activeMapTool: state.map.openedMapTool,
})

const mapDispatchToProps = dispatch => ({
    searchWithAggregation: (field, filter, items_per_page, request_id) => {
        return dispatch(postDataAnc(request_id,
            TAXONDB_SEARCH_WITH_AGGREGATION_REQUEST_URL, {
            filter: filter,
            aggregation_field: field,
            pagination: {perPage: items_per_page},
        },
        MAP_DATA_REQUEST_TIMEOUT
        ))
    },
    searchMapData: (data, source) => dispatch(postDataAnc(
        TAXONDB_SEARCH_MAP_DATA_REQUEST_ID,
        TAXONDB_SEARCH_MAP_DATA_REQUEST_URL,
        data,
        MAP_DATA_REQUEST_TIMEOUT,
        source)),
    postDataAnc: (filter) => dispatch(
        postDataAnc(TAXONDB_SEARCH_MAP_DATA_REQUEST_ID, TAXONDB_SEARCH_MAP_DATA_REQUEST_URL, {
            filter: filter,
            field1: ROK_ZBIORU_FIELD_NAME
        },
        MAP_DATA_REQUEST_TIMEOUT)
    ),
    setAnimationYears: years => dispatch(setAnimationYears(years)),
    setTaxonsByYear: data => dispatch(setTaxonsByYear(data)),
    setMapMarkerIcon: data => dispatch(setHistoricalMarkerIcon(data)), 
    setMapMarkerColor: data => dispatch(setHistoricalMarkerColor(data)),
    setMapMarkerSize: data => dispatch(setHistoricalMarkerSize(data)),
    setFields: (id, val) => dispatch(setFields(id, val)),
    clearResponse: (id) => dispatch(actions.requestRemove(id)),
    setTooManyTaxonsWarning: data => dispatch(setTooManyTaxonsWarning(data)),
    setTaxonsFoundNumber: data => dispatch(setTaxonsFoundNumber(data)),
    setArrayOfYears: array => dispatch(setArrayOfYears(array)),
    runHistoricalAnalysis: (requestId, displayTaxonsFunc) => dispatch(runHistoricalAnalysis(requestId, displayTaxonsFunc)),
    setActiveMapTool: (value) => dispatch(setOpenedMapTool(value)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(HistoricalMap)
