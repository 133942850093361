import React from "react"
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { isPending } from "../../reducers"
import { postDataApi } from '../../actions'
import { Form, Button, Row, Col } from "react-bootstrap"
import {
    API_TEAM_CREATE_INVITATION_ID,
    API_TEAM_CREATE_INVITATION_URL,
    API_TEAM_SEND_INVITATION_ID,
    API_TEAM_SEND_INVITATION_URL
} from '../../settings'
import * as notify from '../../utils/notify'

const InviteUser = props => {
    const { t } = useTranslation([ 'team' ])
    const { team } = props

    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            kind: 'uczestnik',
            email: ''
        },
        resolver: yupResolver(yup.object().shape({
            kind: yup.string().required().trim(),
            email: yup.string().email().required().trim()
        }))
    });

    const onSubmitForm = (data) => {
        props.inviteUser(team.id, data.kind, data.email, t)
    }

    return (
        <div className="mt-3">
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>{t('team:member-kind')}</Form.Label>
                    <Form.Control name="kind" as="select" size="sm" ref={register({ required: true })}>
                        <option value='uczestnik'>{t('team:participant')}</option>
                        <option value='administrator'>{t('team:administrator')}</option>
                    </Form.Control>
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>{t('team:email')}</Form.Label>
                    <Form.Control name="email" size="sm" ref={register({ required: true })} isInvalid={!!errors.email}></Form.Control>
                </Form.Group>
            </Row>
            <Row>
                <Col className="text-right">
                    <Button size="sm" onClick={handleSubmit(onSubmitForm)} disabled={props.querying}>
                        {t('team:invite-user')}
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

const mapStateToProps = state => ({
    querying: isPending(API_TEAM_CREATE_INVITATION_ID, state) || isPending(API_TEAM_SEND_INVITATION_ID, state)
})

const mapDispatchToProps = (dispatch) => ({
    inviteUser: (teamId, memberKind, email, t) => {
        return dispatch(postDataApi(API_TEAM_CREATE_INVITATION_ID, API_TEAM_CREATE_INVITATION_URL, {
            team_id: teamId,
            member_kind: memberKind,
            email: email
        })).then(() => {
            return dispatch(postDataApi(API_TEAM_SEND_INVITATION_ID, API_TEAM_SEND_INVITATION_URL, {
                team_id: teamId,
                email: email
            })).then(() => {
                notify.success(t('team:invited-user'))
            }).catch(res => {
                notify.error(t('team:cannot-send-invitation'), res.result)
            })
        }).catch(res => {
            notify.error(t('team:cannot-create-invitation'), res.result)
        })
    }
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InviteUser)
