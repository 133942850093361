import React from "react"
import {
    TAXONDB_SEARCH_GENUS_REQUEST_ID,
    TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID,
    TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID,
} from "../../../../settings"
import L from 'leaflet';
import ReactDOMServer from 'react-dom/server';
import * as notify from '../../../../utils/notify'
import { 
   generateCartodiagramOfCountries,
   generateCartodiagramOfDistricts,
   generateCartodiagramOfVoivodeships
} from "../CartodiagramFunctions"


export const cartodiagramSearchItems = (ref, props, t) => {

    const { closeSlidebar, cartogramBordersColor, cartogramBordersWidth , cartogramOpacity, cartogramAdministrativeDivision, cartogramFirstAreaColor, cartogramSecondAreaColor, cartodiagramGroupingField, numberOfChartColors } = {...props}

    /* Clear Cartodiagram */
    if (ref.cartogramRef.current){
        ref.cartogramRef.current.eachLayer(function(layer){
            ref.cartogramRef.current.removeLayer(layer);
        });
    }

    const removeCartogramLegend = () => {
        try{
            if (ref.cartogramLegendDiv.current){
                ref.cartogramLegendDiv.current.remove()
            }
            ref.cartogramLegendDiv.current = L.control({ position: "bottomleft" })   
        }
        catch(e){
            console.log(e)
        }
    }

    const getFilterId = () => {
        let id
        if (props.isDynamic === "0"){
            id = TAXONDB_SEARCH_GENUS_REQUEST_ID
        }
        if (props.isDynamic === "2"){
            id = TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID
        }
        if (props.isDynamic === "1"){
            id = TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID
        }
        return id
    }

    const getCurrentFilter = () => {
        let filter 
        if (props.isDynamic === "0"){
            filter = props.filter1
        }
        if (props.isDynamic === "2"){
            filter = props.filter2
        }
        if (props.isDynamic === "1"){
            filter = props.filter3
        }
        return filter
    }

    const prepareCartogramCallback = (items, AREA_WITHOUT_TAXONS, loader) => {
        return Promise.resolve(
            prepareCartogram(items, AREA_WITHOUT_TAXONS, loader)
        )
    }

    const prepareCartogram = (items, AREA_WITHOUT_TAXONS, loader) => {
        
        let cartogramParams = {
            areaWithoutTaxons: AREA_WITHOUT_TAXONS, 
            areaFillOpacity: cartogramOpacity, 
            borderOpacity: cartogramBordersWidth, 
            administrativeBorderColor: cartogramBordersColor
        }
        let filter = getCurrentFilter()

        switch(cartogramAdministrativeDivision){
            case "panstwo":
                generateCartodiagramOfCountries(items, ref.cartogramRef.current, renderPopup, cartogramParams, loader, t, ref.mapRef.current, props, cartodiagramGroupingField, numberOfChartColors, filter)
                break
            case "wojewodztwo":
                generateCartodiagramOfVoivodeships(items, ref.cartogramRef.current, renderPopup, cartogramParams, loader, t, ref.mapRef.current, props, cartodiagramGroupingField, numberOfChartColors, filter)
                break
            case "powiat":
                generateCartodiagramOfDistricts(items, ref.cartogramRef.current, renderPopup, cartogramParams, loader, t, ref.mapRef.current, props, cartodiagramGroupingField, numberOfChartColors, filter)
                break
            default:
                notify.error(t('map:administrative-division-undefined'))
                break
        }
    }

    const renderPopup = (text, taxons) => {
        return ReactDOMServer.renderToString(
            <div>
                <div>{t(`taxondb-attributes:${cartogramAdministrativeDivision}`)}: {text}</div>
                <div>{t('map:number-of-taxons-popup')} {taxons}</div>
            </div>
        )
    }

    
        closeSlidebar()
        let loader = L.control.loader()
        loader.addTo(ref.mapRef.current);
        removeCartogramLegend()
        let id = getFilterId()

        return props.runCartogramSearch(id, cartogramAdministrativeDivision)
        .then(res => {
            if (res.items && res.items.length){
                let items = new Map()
                res.items.forEach((item) => {  
                    items.set(item[cartogramAdministrativeDivision], {
                        color: cartogramSecondAreaColor,
                        numberOfTaxons: item.liczba_okazow
                    })
                })
                return {
                    items : items, 
                    areaWithoutTaxons: cartogramFirstAreaColor,
                    areaWithTaxons: cartogramSecondAreaColor,
                }
            }
            else {
                return "NO_TAXONS"
            }
        })
        .then((response) => {
            if (response.items && response.items.size){
                prepareCartogramCallback(response.items, response.areaWithoutTaxons, loader)
                .then(() => {
                    //prepareCartogramLegend(response.colorsRanges, response.colors)
                })
            }
            else{
                notify.error(t("map:cartogram-error-msg"))
                loader.hide();
            }
        })
        .catch(
            function (error) {
                notify.error(t("map:cartogram-error-msg"))
                loader.hide();
                return Promise.reject(error)
            }
        )
    

}
