import React, { useEffect, useState } from "react";
import { connect } from 'react-redux'
import * as actions from '../../actions'
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import MarkerClusterGroup from 'react-leaflet-markercluster'
import L from 'leaflet';
import { useTranslation } from 'react-i18next'
import { getOutput, getFilter, isPending, getField } from "../../reducers"
import { Container, ProgressBar, Spinner } from "react-bootstrap"
import {
    ICONDB_SEARCH_MAP_DATA_REQUEST_ID,
    ICONDB_SEARCH_MAP_DATA_REQUEST_URL,
    MAP_DATA_REQUEST_TIMEOUT,
    MAP_MAX_ZOOM_LEVEL,
    MAP_CLUSTER_MARKER_COUNT_LIMIT,
    ICONDB_DETAILS_REQUEST_URL,
    COMMON_DICTIONARIES,
    DICTIONARY_COLLECTION_TYPES,
} from '../../settings'
import axios from "axios"
import ReactDOMServer from 'react-dom/server'
import MapPopupContent from './MapPopupContent'

const MultilabelMap = props => {
    const { t, i18n } = useTranslation(['common', 'taxondb', 'taxondb-attributes'])

    const [progress, setProgress] = useState(0)
    const { collectionTypes, searchMapData, removeMapData, getDetails, filter, lang, langi18n } = { ...props, lang: t, langi18n: i18n }

    useEffect(() => {
        const source = axios.CancelToken.source()

        if (filter) {
            const markerIcon = new L.Icon({
                iconUrl: icon,
                shadowUrl: iconShadow,
                iconSize: [25, 41],
                iconAnchor: [12, 39],
            })

            const points = []

            let map
            try {
                map = L.map('map',
                    {
                        center: [0.0, 0.0],
                        crs: L.CRS.EPSG3857,
                        zoom: 1.5,
                        maxZoom: MAP_MAX_ZOOM_LEVEL,
                        zoomControl: true,
                        preferCanvas: false,
                    })

                let OpenStreetMap = L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png")
                let Esri_WorldImagery = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
                    attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                });
            
                let baseMaps = {
                    "Open Street Map": OpenStreetMap,
                    "Esri World Imagery": Esri_WorldImagery
                }

                OpenStreetMap.addTo(map)

                L.control.scale({
                    maxWidth: 150,
                    metric: true,
                    imperial: false,
                }).addTo(map);

                if(filter.geo_json && map){
                    let geoJson = JSON.parse(filter.geo_json);
                    L.geoJSON(geoJson).addTo(map);
                }

                let bm = L.control.layers(baseMaps, {}, {position: 'bottomright'})
                bm.addTo(map)
            }
            catch (error) {
                //ignore
                console.log(error)
            }

            const markerOnClick = m => {
                const popupLoading = <Container className="d-flex flex-column mt-2">
                    <div className="align-self-center">
                        <Spinner animation="border" role="status" />
                    </div>
                </Container>
                
                const content = ReactDOMServer.renderToString(popupLoading)
                const popup = L.popup()
                    .setLatLng(m.target.getLatLng())
                    .setContent(content)
                    .openOn(map);
                getDetails(m.target.kolekcjanumerokazu)
                    .then(data => {
                        const temp = <MapPopupContent manySpecies={m.manySpecies} data={data} t={lang} i18n={langi18n} collectionTypes={collectionTypes}></MapPopupContent>
                        const content = ReactDOMServer.renderToString(temp)
                        popup.setContent(content)
                    })
            }

            const updateProgressBar = (processed, total, elapsed, layersArray) => {
                setProgress(processed * 100 / total)
                if (processed === total) {
                    setTimeout(applyMarkerClickEvents, 1000)
                }
            }

            const applyMarkerClickEvents = m => {
                let limit = MAP_CLUSTER_MARKER_COUNT_LIMIT
                for (let i = 0; i < points.length; i++) {
                    if (map.hasLayer(points[i]) && limit > 0) {
                        limit--
                        points[i].on('click', markerOnClick)
                    }
                }
            }

            const cluster = L.markerClusterGroup({
                chunkedLoading: true,
                spiderfyOnMaxZoom: false,
                chunkProgress: updateProgressBar,
            });

            searchMapData({ filter: filter }, source)
                .then(data => {
                    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png")
                        .addTo(map)

                        for (let i = 0; i < data.data.length; i++) {
                        const marker = L.marker([data.data[i][1], data.data[i][2]], { icon: markerIcon })
                        marker['kolekcjanumerokazu'] = data.data[i][0]
                        cluster.addLayer(marker)
                        points.push(marker)
                    }
                    map.flyToBounds(cluster.getBounds())
                    map.on('moveend zoomend', applyMarkerClickEvents)
                    map.addLayer(cluster)
                    cluster.on('spiderfied', applyMarkerClickEvents)
                    cluster.on('clusterclick', e => {
                        if (map.getZoom() >= MAP_MAX_ZOOM_LEVEL) {
                            if (e.layer.getChildCount() <= MAP_CLUSTER_MARKER_COUNT_LIMIT) {
                                e.layer.spiderfy()
                            } else {
                                const temp = e.layer.getAllChildMarkers()
                                markerOnClick({ target: temp[0], manySpecies: true })
                            }
                        }
                    })
                })
                .catch(error => null)
        }

        return () => {
            source.cancel()
            removeMapData()
        }
    }, [collectionTypes, searchMapData, removeMapData, getDetails, filter, lang, langi18n])

    return (
        <div className="mt-2">
            {false && /* a nice hack ;-) */
                <MarkerClusterGroup></MarkerClusterGroup>
            }
            {props.items && !props.items.data.length > 0 &&
                <p>{t('no-records-found')}</p>
            }
            {props.items && progress < 100 &&
                <ProgressBar now={progress}></ProgressBar>
            }
            <div id="map"></div>
        </div >
    )
}

const mapStateToProps = (state, ownProps) => ({
    loading: isPending(ICONDB_SEARCH_MAP_DATA_REQUEST_ID, state),
    items: getOutput(ICONDB_SEARCH_MAP_DATA_REQUEST_ID, state),
    filter: getFilter(ownProps.requestId, state),
    details: getOutput('popup', state),
    collectionTypes: getField(COMMON_DICTIONARIES, DICTIONARY_COLLECTION_TYPES, state, []),
})

const mapDispatchToProps = dispatch => ({
    removeMapData: () => dispatch(actions.requestRemove(ICONDB_SEARCH_MAP_DATA_REQUEST_ID)),
    searchMapData: (data, source) => dispatch(actions.postDataDlibra(
        ICONDB_SEARCH_MAP_DATA_REQUEST_ID,
        ICONDB_SEARCH_MAP_DATA_REQUEST_URL,
        data,
        MAP_DATA_REQUEST_TIMEOUT,
        source)),
    getDetails: recordId => dispatch(actions.getDataDlibra('popup', ICONDB_DETAILS_REQUEST_URL + recordId + '/')),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MultilabelMap)
