import React, { forwardRef, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Form, Button, Col, Row, FormLabel } from  "react-bootstrap"; 
import L from 'leaflet';
import ReactDOMServer from 'react-dom/server'
import { setMapLegend } from '../../actions/map';
import useMapRedux from '../../hooks/useMapRedux';
import { SHOW_MAP_LEGEND } from '../../stateIds';

const UserLegendCustomizer  = forwardRef((props, ref) => {
    const { t } = useTranslation(['map'])
    const [ mapTitle, setMapTitle ] = useState(props.mapLegend?.mapTitle || '')
    const { value: showMapLegend, setValue: setShowMapLegend } = useMapRedux(SHOW_MAP_LEGEND, false);

    const checkDefaultValue = (field, defaultValue) => {
        let value = defaultValue
        if (props.mapLegend && props.mapLegend[field] !== undefined){
            value = props.mapLegend[field]
        }
        return value
    }

    const authorNameRef = useRef(null)
    const mapDescriptionRef = useRef(null)
    const legendOrientationRef = useRef(null)
    const legendDescriptionFontSizeRef = useRef(null)
    const legendTitleFontSizeRef = useRef(null)
    const mapLegendWidthRef = useRef(null)

    const updateLegendChanges = () => {      
        props.setMapLegend({
            mapTitle,
            authorName: authorNameRef.current.value,
            mapDescription: mapDescriptionRef.current.value,
            legendOrientation: legendOrientationRef.current.value,
            legendDescriptionFontSize: legendDescriptionFontSizeRef.current.value,
            legendTitleFontSize: legendTitleFontSizeRef.current.value,
            mapLegendWidth: mapLegendWidthRef.current.value
        })
    }

    const destroyUserLegend = () => {
        if (ref.userMapDescriptionRef.current){
            ref.userMapDescriptionRef.current.remove()
        }
    }

    const prepareUserLegend = () => {
        if (ref.userMapDescriptionRef.current){
            ref.userMapDescriptionRef.current.remove()
        }
        ref.userMapDescriptionRef.current = L.control({ position: legendOrientationRef.current.value });
        ref.userMapDescriptionRef.current.onAdd = function() {
            var div = L.DomUtil.create("div", "legend");
            div.style.backgroundColor = 'white';
            div.style.padding = '10px';
            div.style.width = `${mapLegendWidthRef.current.value}px`
            div.innerHTML += ReactDOMServer.renderToString(
                <div>
                    <div style={{background: "white", fontSize: parseInt(legendTitleFontSizeRef.current.value), marginLeft: 5}} >
                        <b>{mapTitle}</b>
                    </div>
                    <div style={{background: "white", fontSize: parseInt(legendTitleFontSizeRef.current.value), marginLeft: 5}} >
                        <i>{authorNameRef.current.value}</i>
                    </div>
                    <div style={{background: "white", fontSize: parseInt(legendDescriptionFontSizeRef.current.value), marginLeft: 5}} >
                        {mapDescriptionRef.current.value}
                    </div>
                </div>
            )
            return div
        }
        ref.userMapDescriptionRef.current.addTo(ref.mapRef.current)
    }

    return (
        <div>
            <Row>
                <Form.Group as={Col} md={12} xl={6} >
                    <FormLabel htmlFor={"mapNameField"}> {t('map:customizer-map-title')} </FormLabel>
                    <Form.Control
                        id={"mapNameField"} 
                        name="mapNameField"
                        onChange={ e => setMapTitle(e.target.value)}
                        value={mapTitle}
                    >
                    </Form.Control>
                </Form.Group>
                <Form.Group as={Col} md={12} xl={6} >
                    <FormLabel htmlFor={"mapAuthorField"}> {t('map:customizer-map-author')} </FormLabel>
                    <Form.Control
                        id={"mapAuthorField"} 
                        name="mapAuthorField"
                        ref={authorNameRef}
                        defaultValue={checkDefaultValue("authorName", "")}
                    >
                    </Form.Control>
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} md={12} xl={12} >
                    <FormLabel htmlFor={"mapDescriptionField"}> {t('map:customizer-map-description')} </FormLabel>
                    <Form.Control
                        as="textarea"
                        id={"mapDescriptionField"} 
                        name="mapDescriptionField"
                        ref={mapDescriptionRef}
                        defaultValue={checkDefaultValue("mapDescription", "")}
                    >
                    </Form.Control>
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} md={12} xl={6} >
                    <FormLabel htmlFor={"descriptionFontField"}> {t('map:customizer-map-description-fontsize')} </FormLabel>
                    <Form.Control
                        type="number"
                        id={"descriptionFontField"} 
                        name="descriptionFontField"
                        ref={legendDescriptionFontSizeRef}
                        defaultValue={checkDefaultValue("legendDescriptionFontSize", 12)}
                    >
                    </Form.Control>
                </Form.Group>
                <Form.Group as={Col} md={12} xl={6} >
                    <FormLabel htmlFor={"titleFontField"}> {t('map:customizer-map-titles-fontsize')} </FormLabel>
                    <Form.Control
                        type="number"
                        id={"titleFontField"} 
                        name="titleFontField"
                        ref={legendTitleFontSizeRef}
                        defaultValue={checkDefaultValue("legendTitleFontSize", 12)}
                    >
                    </Form.Control>
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} md={12} xl={6} >
                    <Form.Label htmlFor="legendOrientationField"> {t('map:customizer-map-orientation')} </Form.Label>
                    <Form.Control 
                        id="legendOrientationField" 
                        as="select"
                        defaultValue={checkDefaultValue("legendOrientation", "bottomleft")} 
                        ref={legendOrientationRef}
                    >
                        <option value="bottomleft"> {t('map:customizer-map-orientation-bottomleft')}  </option>
                        <option value="bottomright"> {t('map:customizer-map-orientation-bottomright')}  </option>
                        <option value="topleft"> {t('map:customizer-map-orientation-topleft')}  </option>
                        <option value="topright"> {t('map:customizer-map-orientation-topright')}  </option>
                    </Form.Control>
                </Form.Group>
                <Form.Group as={Col} md={12} xl={6} >
                    <FormLabel htmlFor={"legendWidthField"}> {t('map:customizer-map-legend-width')} </FormLabel>
                    <Form.Control
                        type="number"
                        id={"legendWidthField"} 
                        name="legendWidthField"
                        ref={mapLegendWidthRef}
                        defaultValue={checkDefaultValue("mapLegendWidth", 200)}
                    >
                    </Form.Control>
                </Form.Group>

                
            </Row>
            <div className='d-flex justify-content-end mr-3'>
                <Button
                    className='mr-3'
                    onClick={() => {
                        updateLegendChanges()
                        if (showMapLegend) {
                            prepareUserLegend()
                        }
                        props.closeSlidebar()
                    }}
                >
                    {t('map:show-on-map')}
                </Button>
                {!showMapLegend &&
                    <Button
                        onClick={() => {
                            updateLegendChanges()
                            prepareUserLegend()
                            setShowMapLegend(true)
                            props.closeSlidebar()
                        }}
                    >
                        {t('map:customizer-map-legend-show')}
                    </Button>
                }
                {showMapLegend &&
                    <Button
                        onClick={() => {
                            destroyUserLegend()
                            setShowMapLegend(false)
                            props.closeSlidebar()
                        }}
                    >
                        {t('map:customizer-map-legend-hide')}
                    </Button>
                }
            </div>
        </div>
        
    )

})


const mapStateToProps = (state, ownProps) => ({
    mapLegend: state.map.mapLegend,
})

const mapDispatchToProps = dispatch => ({
    setMapLegend: (data) => dispatch(setMapLegend(data)),

})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(UserLegendCustomizer)
