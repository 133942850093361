import * as notify from '../utils/notify'
import i18n from '../utils/i18n'
import { deleteAllFields } from './forms'
import { requestRemoveAll, getData } from '../actions'
import {
    TAXONDB_COUNT_REQUEST_ID,
    COLLECTION_TYPES_REQUEST_ID,
} from '../settings'

export const SET_INFO = 'SET_INFO'
export const SET_RETURN = 'SET_RETURN'
export const SET_IS_LOGGING_OUT = 'SET_IS_LOGGING_OUT'

export const clearSession = () => (
    dispatch => {
        dispatch(deleteAllFields())
        dispatch(requestRemoveAll([
            TAXONDB_COUNT_REQUEST_ID,
            COLLECTION_TYPES_REQUEST_ID,
        ]))
        dispatch(setInfo(null))
    }
)

export const logoutSilent = () => (
    dispatch => {
        dispatch(setIsLoggingOut(true))
        dispatch(clearSession())
        return dispatch(getData('logout', '/logout/'))
            .then(() => {
                localStorage.removeItem('access')
                localStorage.removeItem('refresh')
                localStorage.removeItem('user')
            })
    }
)

export const logout = () => (
    dispatch => {
        return dispatch(logoutSilent())
            .then(() => {
                notify.success(i18n.t('logout_success'))
            })
    }
)

export const setInfo = data => ({
    type: SET_INFO,
    data: data
})

export const setReturn = url => ({
    type: SET_RETURN,
    url: url
})

export const setIsLoggingOut = flag => ({
    type: SET_IS_LOGGING_OUT,
    logout: flag
})

export const loginSuccess = (data, silent) => (
    dispatch => {
        localStorage.setItem('access', data.token.access)
        localStorage.setItem('refresh', data.token.refresh)
        localStorage.setItem('user', JSON.stringify(data.user))
        dispatch(setInfo(data.user))
        dispatch(setIsLoggingOut(false))
        if (!silent) {
            notify.success(i18n.t('login_success'))
        }
        if (localStorage.getItem('app_version')) {
            if (data[ 'app_version' ] !== localStorage.getItem('app_version')) {
                localStorage.setItem('app_version', data[ 'app_version' ])
                window.location.reload(true)
            }
        } else {
            localStorage.setItem('app_version', data[ 'app_version' ])
        }
    }
)

export const loginFailed = error => (
    dispatch => {
        localStorage.removeItem('access')
        localStorage.removeItem('refresh')
        localStorage.removeItem('user')
        dispatch(clearSession())
        notify.error(i18n.t('login_failed'), error)
    }
)
