import React from "react"
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { GOOGLE_RECAPTCHA_SITE_KEY } from '../settings'

const ReCaptchaContainer = props => 
  <GoogleReCaptchaProvider 
        reCaptchaKey={GOOGLE_RECAPTCHA_SITE_KEY} 
        scriptProps={{
          async: true, // optional, default to false,
          defer: false, // optional, default to false
          appendTo: 'head', // optional, default to "head", can be "head" or "body",
          nonce: undefined // optional, default undefined
      }}>{props.children}</GoogleReCaptchaProvider>

export default ReCaptchaContainer
