import React, { useRef, useEffect, useState } from 'react'
import Quill from 'quill'
import 'quill/dist/quill.snow.css'
import DOMPurify from 'dompurify'
import { Alert } from "react-bootstrap"
import { useTranslation } from 'react-i18next'

window.Quill = Quill
Quill.register('modules/ImageResize', require('quill-image-resize-module').default);

const HtmlEditor = (props) => {
    const { t } = useTranslation(['forms'])
    const quillElement = useRef(null)
    const quillInstance = useRef(null)
    const [showAlert, setShowAlert] = useState(false)
    const { defaultValue, onChange } = props
    useEffect(() => {
        quillInstance.current = new Quill(quillElement.current, {
            theme: 'snow',
            placeholder: '',
            modules: {
                toolbar: [
                    [{ 'header': 1 }, { 'header': 2 }, 'bold', 'italic', 'underline', { 'color': [] }],
                    ['image', 'link'],
                    [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                    ['blockquote', 'code-block'],
                    ['clean']
                ],
                ImageResize: {},
                keyboard:{
                    bindings:{
                        tab: {
                            key: 9,
                            handler: function() {
                                quillInstance.current.blur()
                                setShowAlert(false)
                                return true
                            }
                          },
                          shiftTab: {
                            key: 9,
                            shiftKey: true,
                            handler: function() {
                                quillInstance.current.blur()
                                setShowAlert(false)
                                return true
                            }
                          },
                          esc: {
                            key: 27,
                            handler: ()=>{
                                quillInstance.current.blur()
                            }
                          }
                    }
                }
            },
        })

        const quill = quillInstance.current
        quill.root.innerHTML = DOMPurify.sanitize(defaultValue)
        quill.on('text-change', (delta, oldDelta, source) => {
            if (source === 'user') {
                onChange && onChange(DOMPurify.sanitize(quill.root.innerHTML))
            }
        })
        quill.on('editor-change', (delta, oldDelta, source) => {
            setShowAlert(quill.hasFocus())
        })
    }, [defaultValue, onChange])

    return (
        <>
        <div ref={quillElement} />
        {showAlert && <Alert variant="secondary">{t('click')} <b>Esc</b>{t('html-editor-esc')}<br />{t('click')} <b>Tab</b>{t('html-editor-tab')}</Alert>}
        </>
    )
}

export default HtmlEditor