import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Button, FormLabel, Spinner } from  "react-bootstrap"; 
import * as notify from '../../../utils/notify'
import useApi from '../../../hooks/useApi';
import useMapRedux from '../../../hooks/useMapRedux';
import useMapDefinition from '../../../hooks/useMapDefinition';
import { URL_API_MAP_CHANGE_DEFINITION } from '../../../urls'

const SaveMapPanel = props => {
  const { t } = useTranslation(['map']);
  const { value: mapId } = useMapRedux('mapId');
  const { value: mapTitle } = useMapRedux('mapTitle');
  const { getCurrentMapDefinition } = useMapDefinition();
  const [ name, setName ] = useState('')
  const { refetch, loading } = useApi('post', URL_API_MAP_CHANGE_DEFINITION);

  useEffect(() => {
    setName(mapTitle || '')
  }, [mapTitle]);
  
  const saveMap = () => {
    refetch({
      map_id: mapId,
      definition: getCurrentMapDefinition(props.drawingsLayer),
    })
      .then(res => {
        notify.success(t('map:map-saved'))
      })
      .catch(err => {
        notify.error(t('map:cannot-save-map'))
      })
  }
  
  return(
    <>
      <Form.Group controlId='map-name'>
        <FormLabel>{t('map:map-name')}</FormLabel>
        <Form.Control
          value={name}
          onChange={e => setName(e.target.value)}
          disabled={true}
        ></Form.Control>
      </Form.Group>
      <Form.Group>
        <Button
          onClick={saveMap}
          disabled={loading || !mapId}
        >{t('map:save')}
          {loading &&
              <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="ml-1"
              />
          }
        </Button>
      </Form.Group>
    </>
  )
}
export default SaveMapPanel
