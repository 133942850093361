import React from 'react'
import { useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Table, Form, Button, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons'
import ValueFromDictionary from '../dictionary/ValueFromDictionary'

const REVISIONS_COLUMNS = [
    'rodzaj', 'gatunek', 'autorgatunku', 'autoroznaczenia', 'dataoznaczeniazbioru',
    'rangajednostkinadrzednej', 'jednostkanadrzedna'
]

const HIDDEN_REVISIONS_COLUMNS = [
    'kwalifikatorhybrydylubchimery', 'oznaczeniehybrydy', 'podrodzaj'
]

const LOWERTAXON_REVISIONS_COLUMN = 'lower_taxons'

const LOWERTAXON_REVISIONS_COLUMNS = [
    'rangataksonunizszegorzedu', 'taksonnizszegorzedu', 'autortaksonunizszegorzedu'
]

const EditAttrTable = props => {
    const { t } = useTranslation(['taxondb-attributes'])
    const { register, control, name, errors } = props
    const { fields, append, remove, insert } = useFieldArray({ control: control, name: name })

    const getError = (rowIndex, colIndex) => {
        return errors && errors[rowIndex] && errors[rowIndex].values[colIndex]?.message;
    }

    return (<>
        {fields.length > 0 && fields.map((item, index) =>
            <Row noGutters key={item.id} className="mb-1">
                <div className="revisions-row">
                    {item.values.map((_, colIndex) =>
                        <div key={colIndex} className="ml-1">
                            <Form.Group className="mb-0">
                                <div className="revisions-lower-taxons-row">
                                    <Form.Control name={`${name}[${index}].values[${colIndex}]`} className="revision-form" defaultValue={item.values[colIndex]} size="sm" type="text" ref={register()} autoComplete="off" />
                                    <Form.Control.Feedback type="invalid" className="d-block">
                                        {getError(index, colIndex)}
                                    </Form.Control.Feedback>
                                </div>
                            </Form.Group>
                        </div>
                    )}
                    <Button size="sm" className="ml-2 pr-1" onClick={() => insert(index + 1, { values: ['', '', ''] })} variant="link">
                        <FontAwesomeIcon icon={faPlus} title={t('common:append')} size="xs" />
                    </Button>
                    <Button size="sm" className="ml-1" onClick={() => remove(index)} variant="link">
                        <FontAwesomeIcon icon={faTrash} title={t('common:delete')} size="xs" />
                    </Button>
                </div>
            </Row>
        )}
        {fields.length === 0 &&
            <Row noGutters className="mb-1">
                <div className="revisions-lower-taxons-row">
                    {Array.from({ length: 3 }).map((_, colIndex) =>
                        <div key={colIndex} className="ml-1">
                            <Form.Control className="revision-form" size="sm" type="text" disabled />
                        </div>
                    )}
                    <Button size="sm" className="ml-2 pr-1" onClick={() => append({ values: ['', '', ''] })} variant="link">
                        <FontAwesomeIcon icon={faPlus} title={t('common:append')} size="xs" />
                    </Button>
                    <Button size="sm" className="ml-1" disabled variant="link">
                        <FontAwesomeIcon icon={faTrash} title={t('common:delete')} size="xs" />
                    </Button>
                </div>
            </Row>
        }
    </>)
}

const RevisionsTable = props => {
    const { t } = useTranslation(['taxondb-attributes'])
    const { control, name, register, errors } = props
    const { fields, append, remove, insert } = useFieldArray({ control: control, name: name })

    const createRow = () => {
        return [...REVISIONS_COLUMNS, ...HIDDEN_REVISIONS_COLUMNS].reduce((ret, key) => ({ ...ret, [key]: '' }), {
            [LOWERTAXON_REVISIONS_COLUMN]: { values: null }
        })
    }

    const isColumnNonEmptyOrEditable = (col) => {
        return (props.rev && props.rev.length > 0 && props.rev.reduce((acc, obj) => (acc || (obj[col] && obj[col].length > 0)), false)) || props.editable
    }

    const getError = (name, rowIndex, colIndex) => {
        return errors && errors[name] && errors[name][rowIndex] && errors[name][rowIndex][colIndex]?.message;
    }

    return (
    <div className="revision-table-wrapper">
        <Table striped bordered hover size="sm"> 
            <thead>
                <tr>
                    {props.editable && <th></th>}
                    {REVISIONS_COLUMNS.filter((col) => isColumnNonEmptyOrEditable(col)).map((col, indx) => {
                        return (
                            <th key={indx}>{t(col)}</th>
                        )
                    })}
                    {isColumnNonEmptyOrEditable(LOWERTAXON_REVISIONS_COLUMN) &&
                        <th>{t('taxondb-attributes:lower_taxons')}</th>
                    }
                </tr>
            </thead>
            <tbody>
                {!props.editable && props.rev.map((rev, i) =>
                    <tr key={i}>
                        {REVISIONS_COLUMNS.filter((col) => isColumnNonEmptyOrEditable(col)).map((col, indx) =>
                            <td key={indx}>
                                <ValueFromDictionary name={col} value={rev[col]} />
                            </td>
                        )}
                        {isColumnNonEmptyOrEditable(LOWERTAXON_REVISIONS_COLUMN) &&
                            <td style={{ width: '33%' }}>
                                {rev[LOWERTAXON_REVISIONS_COLUMN].map((ltrev, indx2) =>
                                    <Row key={indx2} noGutters>
                                        <div className="revisions-lower-taxons-row">
                                            {ltrev.values.map((value, indx3) =>
                                                <div key={indx3} className="revision-lower-taxon-item">
                                                        {indx3 !== 1 &&
                                                            <ValueFromDictionary name={LOWERTAXON_REVISIONS_COLUMNS[indx3]} value={value} />
                                                        }
                                                        {indx3 === 1 && <i>
                                                            <ValueFromDictionary name={LOWERTAXON_REVISIONS_COLUMNS[indx3]} value={value} />
                                                        </i>}
                                                </div>
                                            )}
                                        </div>
                                    </Row>
                                )}
                            </td>
                        }
                    </tr>
                )}
                {props.editable &&
                    <>
                        {fields.length > 0 && fields.map((item, index) =>
                            <tr key={item.id}>
                                <td style={{ width: '7%' }}>
                                    <div className="revisions-row">
                                        <Button size="sm" onClick={() => insert(index + 1, createRow())} >
                                            <FontAwesomeIcon icon={faPlus} title={t('common:append')} size="xs" />
                                        </Button>
                                        <Button size="sm" className="ml-2" onClick={() => remove(index)}>
                                            <FontAwesomeIcon icon={faTrash} title={t('common:delete')} size="xs" />
                                        </Button>
                                    </div>
                                </td>
                                {REVISIONS_COLUMNS.map((colName, colIndex) =>
                                    <td key={colIndex}>
                                        <Form.Group className="mb-0">
                                            <Form.Control name={`${name}[${index}].${colName}`} defaultValue={item[colName]} size="sm" type="text" ref={register()} autoComplete="off" />
                                            <Form.Control.Feedback type="invalid" className="d-block">
                                                {getError(name, index, colName)}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </td>
                                )}
                                {HIDDEN_REVISIONS_COLUMNS.map((colName, colIndex) =>
                                    <td key={colIndex} hidden>
                                        <Form.Control name={`${name}[${index}].${colName}`} defaultValue={item[colName]} size="sm" type="text" ref={register()} autoComplete="off" />
                                    </td>
                                )}
                                <td style={{ width: '33%' }}>
                                    <EditAttrTable name={`${name}[${index}].${LOWERTAXON_REVISIONS_COLUMN}`} register={register} control={control}
                                        errors={errors && errors[name] && errors[name][index] && errors[name][index][LOWERTAXON_REVISIONS_COLUMN]} />
                                </td>
                            </tr>
                        )}
                        {fields.length === 0 &&
                            <tr>
                                <td style={{ width: '7%' }}>
                                    <div className="revisions-row">
                                        <Button size="sm" onClick={() => append(createRow())} >
                                            <FontAwesomeIcon icon={faPlus} title={t('common:append')} size="xs" />
                                        </Button>
                                        <Button size="sm" className="ml-2" disabled>
                                            <FontAwesomeIcon icon={faTrash} title={t('common:delete')} size="xs" />
                                        </Button>
                                    </div>
                                </td>
                                {REVISIONS_COLUMNS.map((colName, colIndex) =>
                                    <td key={colIndex}>
                                        <Form.Control size="sm" type="text" disabled />
                                    </td>
                                )}
                                <td style={{ width: '33%' }}>
                                    <Row noGutters className="mb-1">
                                        <div className="revisions-lower-taxons-row">
                                            {Array.from({ length: 3 }).map((_, colIndex) =>
                                                <div key={colIndex} className="ml-1">
                                                    <Form.Control size="sm" className="revision-form" type="text" disabled />
                                                </div>
                                            )}
                                            <Button size="sm" className="ml-2 pr-1" variant="link" disabled>
                                                <FontAwesomeIcon icon={faPlus} title={t('common:append')} size="xs" />
                                            </Button>
                                            <Button size="sm" className="ml-1" disabled variant="link">
                                                <FontAwesomeIcon icon={faTrash} title={t('common:delete')} size="xs" />
                                            </Button>
                                        </div>
                                    </Row>
                                </td>
                            </tr>
                        }
                    </>
                }
            </tbody>
        </Table>
    </div>
    )
}

export default RevisionsTable