import React from "react";
import { connect } from 'react-redux'
import { Container, Tabs, Tab } from "react-bootstrap"
import { useTranslation } from 'react-i18next'
import SEO from "../SEO";
import ExcelPanel from "./ExcelPanel"
import {
  TASK_HISTORY_PAGE_URL_EN,
  TASK_HISTORY_PAGE_URL_PL,
} from '../../settings'
import I18nPathTranslator from "../I18nPathTranslator";
import GroupEditionPanel from "./GroupEditionPanel";

const TaskHistory = props => {
  const { t } = useTranslation([ 'editor-tools', 'editions-panel' ])

  return (
    <Container>
      <I18nPathTranslator {...props} pathEN={TASK_HISTORY_PAGE_URL_EN} pathPL={TASK_HISTORY_PAGE_URL_PL} />
      <SEO title={t('editions-panel:task-history')} />
      <div className="mt-3 mb-4">
          <h1>
              {t('editions-panel:task-history')}
          </h1>
      </div>
      <Tabs defaultActiveKey={'excel'} className="nav-tabs-padding-top">
        <Tab eventKey="excel" title={t('editions-panel:excel-edit')}>
          <ExcelPanel></ExcelPanel>
        </Tab>
        <Tab eventKey="group-edit" title={t('editions-panel:group-edit')}>
          <GroupEditionPanel></GroupEditionPanel>
        </Tab>
      </Tabs>
    </Container>
  )
}

const mapStateToProps = (state, ownProps) => ({
})

const mapDispatchToProps = dispatch => ({
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskHistory)
