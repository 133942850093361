import React from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { getOutput } from '../../reducers'
import { Tabs, Tab, Spinner, Dropdown, DropdownButton } from 'react-bootstrap'
import { getReportDataApi } from '../../actions'
import {
  API_EXCEL_TASK_DOWNLOAD_REQUEST_ID,
  API_EXCEL_TASK_DOWNLOAD_REQUEST_URL,
} from '../../settings'
import { isPending } from "../../reducers";
import * as notify from '../../utils/notify'

const RecordDetailsContainer = props => {
  const { t, i18n } = useTranslation([ 'editor-tools', 'common' ])

  const DocumentInfo = props => (
    <div className="p-2">
      {Array.isArray(props.content) &&
        <>
          {props.content.length > 0 &&
            <table border="1" cellPadding="2" className="my-2">
              <thead>
                <tr>
                  <th>{t('result-row')}</th>
                  <th>{t('result-column')}</th>
                  <th>{t('result-value')}</th>
                </tr>
              </thead>
              <tbody>
                {props.content.map((item, indx) => (
                  <React.Fragment key={indx}>
                    {item.cells.map((item2, indx2) => (
                      <React.Fragment key={`${indx}-${indx2}`}>
                        {item2.msgs.map((item3, indx3) => (
                          <tr key={`${indx}-${indx2}-${indx3}`}>
                            {indx2 === 0 && indx3 === 0 &&
                              <td rowSpan={item.cells.reduce((acc, val) => acc + val.msgs.length, 0)}>{item.row}</td>
                            }
                            {indx3 === 0 &&
                              <td rowSpan={item2.msgs.length}>{item2.field}</td>
                            }
                            <td>{item3}</td>
                          </tr>
                        ))}
                      </React.Fragment>
                    ))
                    }
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          }
          {props.content.length === 0 &&
            <div>{t('no-results')}</div>
          }
        </>
      }
    </div>
  )

  const InnerTabs = props => (
    <>
      {props.result.rows_found >= 0 &&
        <div className="p-2">{t('rows-found')}: {props.result.rows_found}</div>
      }
      {props.result.rows_correct >= 0 &&
        <div className="p-2">{t('rows-correct')}: {props.result.rows_correct}</div>
      }
      {props.result.rows_found >= 0 && props.result.rows_correct >= 0 &&
        <div className="p-2">{t('rows-error')}: {t('rows-error-prefix')} {props.result.rows_found - props.result.rows_correct}</div>
      }
      <Tabs defaultActiveKey={'errors'}>
        <Tab eventKey="errors" title={t('result-errors-tab')}>
          {props.result.errors &&
            <DocumentInfo content={props.result.errors}></DocumentInfo>
          }
        </Tab>
        <Tab eventKey="warnings" title={t('result-warnings-tab')}>
          {props.result.warnings &&
            <DocumentInfo content={props.result.warnings}></DocumentInfo>
          }
        </Tab>
        <Tab eventKey="infos" title={t('result-infos-tab')}>
          {props.result.infos &&
            <DocumentInfo content={props.result.infos}></DocumentInfo>
          }
        </Tab>
      </Tabs>
    </>
  )

  return (
    <div>
      {props.data && props.data.status &&
        <div className="p-2">{t('state')}: {props.data.status} {props.data.status === 'FAILED' ? `(${props.data.result.error})` : ''}</div>
      }
      {props.hasFile &&
        <div className={"d-flex align-items-center export-button " + i18n.languages[0]}>
          <DropdownButton id="dropdown-basic-button" title={t('common:exportfile')} disabled={props.downloading}>
            <Dropdown.Item onClick={() => props.downloadFileXlsx().catch(() => notify.error(t('error'), t('cannot-get-file')))}>XLSX</Dropdown.Item>
            <Dropdown.Item onClick={() => props.downloadFileXls().catch(() => notify.error(t('error'), t('cannot-get-file')))}>XLS</Dropdown.Item>
          </DropdownButton>
          {props.downloading &&
            <Spinner
              className="ml-2"
              as="div"
              animation="border"
              size="md"
              role="status"
              aria-hidden="true"
            />
          }
        </div>
      }
      {props.data && props.data.result &&
        <Tabs defaultActiveKey={'takson'}>
          <Tab eventKey="takson" title={t('result-taxon-tab')}>
            {props.data.result.takson &&
              <InnerTabs result={props.data.result.takson}></InnerTabs>
            }
          </Tab>
          <Tab eventKey="proba" title={t('result-sample-tab')}>
            {props.data.result.proba &&
              <InnerTabs result={props.data.result.proba}></InnerTabs>
            }
          </Tab>
          <Tab eventKey="bibliografia" title={t('result-biblio-tab')}>
            {props.data.result.bibliografia &&
              <InnerTabs result={props.data.result.bibliografia}></InnerTabs>
            }
          </Tab>
          {props.data.result.ikonografia &&
            <Tab eventKey="ikonografia" title={t('result-icon-tab')}>
              <InnerTabs result={props.data.result.ikonografia}></InnerTabs>
            </Tab>
          }
          {props.data.result.inne &&
            <Tab eventKey="inne" title={t('result-inne-tab')}>
              <InnerTabs result={props.data.result.inne}></InnerTabs>
            </Tab>
          }
        </Tabs>
      }
    </div >
  )
}

const mapStateToProps = (state, ownProps) => ({
  data: getOutput(ownProps.requestId, state),
  downloading: isPending(`${API_EXCEL_TASK_DOWNLOAD_REQUEST_ID}-${ownProps.recordId}`, state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  downloadFileXls: () => dispatch(getReportDataApi(`${API_EXCEL_TASK_DOWNLOAD_REQUEST_ID}-${ownProps.recordId}`, `${API_EXCEL_TASK_DOWNLOAD_REQUEST_URL}xls/${ownProps.recordId}/`)),
  downloadFileXlsx: () => dispatch(getReportDataApi(`${API_EXCEL_TASK_DOWNLOAD_REQUEST_ID}-${ownProps.recordId}`, `${API_EXCEL_TASK_DOWNLOAD_REQUEST_URL}xlsx/${ownProps.recordId}/`)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecordDetailsContainer)
