import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { DLIBRA_IIIF_URL } from '../../settings'
import { Button, Dropdown, DropdownButton, ButtonToolbar } from "react-bootstrap"
import { useTranslation } from 'react-i18next'
import OpenSeadragon from 'openseadragon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo, faSearchMinus, faSearchPlus, faDesktop } from '@fortawesome/free-solid-svg-icons'
import * as actions from '../../actions'
import { isPending, getOutput } from "../../reducers";
import { DOWNLOADIMAGE_REQUEST_ID, DLIBRA_IMAGE_JPG_MAX_WIDTH } from '../../settings'
import { addPictureOne, addPictureTwo } from '../../actions/comparator'
import RedirectLoginWithReturn from '../access/RedirectLoginWithReturn'

const SingleDlibraImage = props => {
    const { t } = useTranslation(['common', 'icondb'])
    
    useEffect(() => {
        const urls = [DLIBRA_IIIF_URL + props.data.edi_id + '-' + props.data.edi_ver_id + '/info.json']
        console.log("Image URLs:", urls)

        // see: https://openseadragon.github.io/docs/OpenSeadragon.html#.Options
        OpenSeadragon({
            id: 'iiif-' + props.requestId,
            prefixUrl: "/openseadragon/images/",
            showNavigator: true,
            preserveViewport: true,
            //visibilityRatio: 1,
            sequenceMode: true,
            crossOriginPolicy: false,
            ajaxWithCredentials: false,
            tileSources: urls.map(tileSource => ({
                tileSource,
                ajaxHeaders: { "Authorization": "Bearer " + localStorage.getItem('access') }
            })),
            homeButton: 'reset' + props.requestId,
            zoomInButton: 'zoom-in' + props.requestId,
            zoomOutButton: 'zoom-out' + props.requestId,
            fullPageButton: 'full-screen' + props.requestId,
            nextButton: "next-picture" + props.requestId,
            previousButton: "previous-picture" + props.requestId,
            loadTilesWithAjax: true,
        }).addHandler('page', function (event) {
            // setCurrentImage(event.page + 1);
        })
    }, [props.requestId, props.data.edi_id, props.data.edi_ver_id])

    const [isRedirectLoginWithReturn, setRedirectLoginWithReturn] = useState(false)

    return (
        <div className="mb-1">
            {isRedirectLoginWithReturn &&
                <RedirectLoginWithReturn lang={localStorage.lang} returnUrl={window.location.pathname} />
            }
            <div>
                <h1 className="ml-1">
                    {props.data.Title && props.data.Title.length > 0 &&
                        <>
                            {props.data.Title}
                        </>
                    }
                    {(!props.data.Title || props.data.Title.length === 0) &&
                        <>
                            {props.data.external_id}
                        </>
                    }
                </h1>
                <div>
                    <ButtonToolbar className="text-center">
                        <DropdownButton size="sm" className="ml-1" id="dropdown-basic-button" title={t('download_image')} disabled={props.downloading}>
                            <Dropdown.Item onClick={() =>
                                props.user ?
                                    props.downloadImage(
                                        `${DLIBRA_IIIF_URL}${props.data.edi_id}-${props.data.edi_ver_id}/full//${DLIBRA_IMAGE_JPG_MAX_WIDTH},/0/default.jpg`,
                                        "jpg", props.data.FileName
                                    ) : setRedirectLoginWithReturn(true)
                            }
                            >JPG</Dropdown.Item>
                            <Dropdown.Item onClick={() =>
                                props.user ?
                                    props.downloadImage(
                                        `${DLIBRA_IIIF_URL}${props.data.edi_id}-${props.data.edi_ver_id}/full//full/0/default.tif`,
                                        "tif", props.data.FileName
                                    ) : setRedirectLoginWithReturn(true)
                            }
                            >TIF</Dropdown.Item>
                        </DropdownButton>
                        <Button size="sm" className="ml-1" variant="dark" id={"reset" + props.requestId} disabled={props.loading}>
                            <FontAwesomeIcon icon={faUndo} title={t('Home')} />
                        </Button>
                        <Button size="sm" className="ml-1" variant="dark" id={"zoom-in" + props.requestId} disabled={props.loading}>
                            <FontAwesomeIcon icon={faSearchPlus} title={t('ZoomIn')} />
                        </Button>
                        <Button size="sm" className="ml-1" variant="dark" id={"zoom-out" + props.requestId} disabled={props.loading}>
                            <FontAwesomeIcon icon={faSearchMinus} title={t('ZoomOut')} />
                        </Button>
                        <Button size="sm" className="ml-1" variant="dark" id={"full-screen" + props.requestId} disabled={props.loading}>
                            <FontAwesomeIcon icon={faDesktop} title={t('FullPage')} />
                        </Button>
                    </ButtonToolbar>
                </div>
            </div>
            <div id={'iiif-' + props.requestId} className="icondb-openseadragon-container mt-1"></div>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    data: getOutput(ownProps.requestId, state),
    comparator: state.comparator,
    user: state.user ? state.user.data : null,
    downloading: isPending(DOWNLOADIMAGE_REQUEST_ID, state),
})


const mapDispatchToProps = dispatch => ({
    downloadImage: (link, imgType, picturename) => dispatch(actions.getDlibraImage(DOWNLOADIMAGE_REQUEST_ID, link, imgType, picturename)),
    addPictureOneToComparator: (data) => dispatch(addPictureOne(data)),
    addPictureTwoToComparator: (data) => dispatch(addPictureTwo(data)),

})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SingleDlibraImage)
