import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setMapKeyValue, clearMapKeyValue } from '../actions/map';

const useMapRedux = (id, defaultValue) => {
  const [ initValue, setInitValue ] = useState(defaultValue);
  const value = useSelector(state => state.map[id]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (value === undefined && initValue !== undefined) {
      dispatch(setMapKeyValue(id, defaultValue));
    }
  }, [ value, dispatch, id, defaultValue, initValue ]);

  const setValue = useCallback(val => {
    dispatch(setMapKeyValue(id, val));
  }, [ id, dispatch ]);

  const clear = useCallback(() => {
    setInitValue(undefined);
    return dispatch(clearMapKeyValue(id));
  }, [ dispatch, id ]);

  return { value, setValue, clear };
};
export default useMapRedux;
