import React from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { getField, isPending, getItems } from "../../reducers";
import { Tabs, Tab, Container } from "react-bootstrap"
import GenusList from './GenusList'
import SpeciesList from './SpeciesList'
import ResultsList from './ResultsList'
import IconsList from './IconsList'
import IconsListOther from './IconsListOther'
import { setField } from '../../actions/forms'

const SimpleResultsContainer = props => {
    const { t } = useTranslation([ 'taxondb', 'taxondb-groups' ])

    return (
        <Container className={(!props.loading && !props.items) ? "d-none" : ""}>
            <div className="search-results">{t('common:search-results')}</div>
            <Tabs defaultActiveKey={props.activeTab} id={props.searchRequestId} activeKey={props.activeTab} onSelect={props.setActiveTab}>
                <Tab eventKey="0" title={t('rodzaje')}>
                    <GenusList
                        requestId={props.genusRequestId}
                        speciesRequestId={props.speciesRequestId}
                        searchRequestId={props.searchRequestId}
                        tabId={props.tabId}
                    ></GenusList>
                </Tab>
                <Tab eventKey="1" title={t('gatunki')}>
                    <SpeciesList
                        requestId={props.speciesRequestId}
                        searchRequestId={props.searchRequestId}
                        tabId={props.tabId}
                        genusRequestId={props.genusRequestId}
                    ></SpeciesList>
                </Tab>
                <Tab eventKey="2" title={t('okazy')}>
                    {props.activeTab === "2" &&
                        <ResultsList
                            requestId={props.searchRequestId}
                            speciesRequestId={props.speciesRequestId}
                            genusRequestId={props.genusRequestId}
                        ></ResultsList>
                    }
                </Tab>
                <Tab eventKey="3" title={t('multimedia-specimen')}>
                    {props.activeTab === "3" &&
                        <IconsList
                            requestId={props.searchRequestId}
                            speciesRequestId={props.speciesRequestId}
                            genusRequestId={props.genusRequestId}
                            iconsRequestId={props.iconsRequestId}
                        ></IconsList>
                    }
                </Tab>
                {props.hasIconsListOther &&
                    <Tab eventKey="4" title={t('multimedia-other')}>
                        {props.activeTab === "4" &&
                            <IconsListOther
                                requestId={props.searchRequestId}
                                speciesRequestId={props.speciesRequestId}
                                genusRequestId={props.genusRequestId}
                            ></IconsListOther>
                        }
                    </Tab>
                }
            </Tabs>
        </Container>
    )
}

const mapStateToProps = (state, ownProps) => ({
    items: getItems(ownProps.genusRequestId, state),
    activeTab: (getField(ownProps.tabId, ownProps.tabId, state) || '0'),
    loading: isPending(ownProps.genusRequestId, state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    setActiveTab: value => dispatch(setField(ownProps.tabId, ownProps.tabId, value)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SimpleResultsContainer)

