import React from "react";
import { useTranslation } from 'react-i18next'
import ExternalLinkPopover from "../ExternalLinkPopover";

const SynonymsInfo = props => {
    const { t } = useTranslation(['taxondb'])


    return <div className="d-flex flex-column">
        <div>{t('taxondb:synonyms-info-at')}:</div>
        <div><ExternalLinkPopover link='http://www.theplantlist.org/' title={t("common:go-to") + "The Plant List"}>http://www.theplantlist.org/</ExternalLinkPopover></div>
        <div><ExternalLinkPopover link='https://www.tropicos.org/home' title={t("common:go-to") + "Tropicos"}>https://www.tropicos.org/home</ExternalLinkPopover></div>
        <div><ExternalLinkPopover link='https://www.gbif.org/' title={t("common:go-to") + "GBIF"}>https://www.gbif.org/</ExternalLinkPopover></div>
    </div>
}

export default SynonymsInfo
