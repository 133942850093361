import React, { forwardRef } from 'react'
import { connect } from 'react-redux'
import { Form } from  "react-bootstrap"; 
import { useTranslation } from 'react-i18next'
import L from 'leaflet';
import omnivore from '@mapbox/leaflet-omnivore';
import * as notify from '../../utils/notify'
import {
    MAP_LAYER_CLASTERED_TAXONS_CONST,
    MAP_LAYER_UNCLASTERED_TAXONS_CONST_REF,
    MAP_LAYER_TAXONS_WITH_LEGEND_CONST,
    MAP_LAYER_ANIMATION_CONST,
    MAP_LAYER_CARTOGRAM_CONST,
    USER_DRAWINGS_POINTS_CONST,
    USER_DRAWINGS_LINES_CONST, 
    USER_DRAWINGS_POLYGONS_CONST,
    MAP_LAYER_OBSERVATIONS_CONST,
} from '../../settings'

const LayersPanel  = forwardRef((props, ref) => {
    const { t } = useTranslation(['map'])

    const handleUserDrawingsLayer = (e) => {
        if(e.target.checked){
            if (e.target.name === USER_DRAWINGS_POINTS_CONST){
                ref.userDrawingsRef.current.eachLayer(
                    function(layer){
                        if (layer.name === USER_DRAWINGS_POINTS_CONST){
                            layer.setOpacity(1)
                            layer.options.visible = true
                        }
                    }
                )
            }
            else if (e.target.name === USER_DRAWINGS_LINES_CONST){
                ref.userDrawingsRef.current.eachLayer(
                    function(layer){
                        if (layer.name === USER_DRAWINGS_LINES_CONST){
                            if (layer.style){
                                layer.options.visible = true
                                layer.options.weight = layer.style.weight
                                layer.options.opacity = layer.style.opacity
                                layer.options.fill = layer.style.fill
                                layer.options.color = layer.style.color
                                layer.options.fillColor = layer.style.fillColor
                                layer.options.fillOpacity = layer.style.fillOpacity
                                layer.options.stroke = layer.style.stroke
                                layer.editing.enable()
                                layer.editing.disable()
                            }
                            else{
                                layer.options.visible = true
                                layer.options.opacity = 1
                                layer.options.fill = true
                                layer.options.color = "#3388ff"
                                layer.options.fillColor = 'blue'
                                layer.options.fillOpacity = 0.1
                                layer.options.stroke = true
                                layer.editing.enable()
                                layer.editing.disable()
                            }
                        }
                    }
                )
            }
            else if (e.target.name === USER_DRAWINGS_POLYGONS_CONST){
                ref.userDrawingsRef.current.eachLayer(
                    function(layer){
                        if (layer.name === USER_DRAWINGS_POLYGONS_CONST){
                            if (layer.style){
                                layer.options.visible = true
                                layer.options.weight = layer.style.weight
                                layer.options.opacity = layer.style.opacity
                                layer.options.fill = layer.style.fill
                                layer.options.color = layer.style.color
                                layer.options.fillColor = layer.style.fillColor
                                layer.options.fillOpacity = layer.style.fillOpacity
                                layer.options.stroke = layer.style.stroke
                                layer.editing.enable()
                                layer.editing.disable()
                            }
                            else{
                                layer.options.visible = true
                                layer.options.weight = 5
                                layer.options.opacity = 0.5
                                layer.options.fill = true
                                layer.options.color = 'blue'
                                layer.options.fillColor = 'blue'
                                layer.options.fillOpacity = 0.5
                                layer.options.stroke = true
                                layer.editing.enable()
                                layer.editing.disable()
                            }

                        }
                    }
                )
            }
        }
        else{
            ref.userDrawingsRef.current.eachLayer(function(layer){
                if (layer.name === e.target.name){
                    if (layer.name === USER_DRAWINGS_POINTS_CONST){
                        layer.setOpacity(0)
                        layer.options.visible = false
                    }
                    else{
                        layer.options.visible = false
                        layer.options.opacity = 0
                        layer.options.fill = false
                        layer.options.fillColor = null
                        layer.options.fillOpacity = 0
                        layer.options.stroke = false
                        layer.editing.enable()
                        layer.editing.disable()
                    }
                }
            });
        }
    }

    const handleAncTaxonsLayer = (e) => {
        if (e.target.checked){
            if (e.target.name === MAP_LAYER_CLASTERED_TAXONS_CONST){
                ref.mapRef.current.addLayer(ref.ancClasteredTaxons.current)
            }
            else if (e.target.name === MAP_LAYER_UNCLASTERED_TAXONS_CONST_REF){
                ref.mapRef.current.addLayer(ref.unclasteredAncTaxonsRef.current)
            }
            else if (e.target.name === MAP_LAYER_TAXONS_WITH_LEGEND_CONST){
                ref.mapRef.current.addLayer(ref.ancTaxonsWithLegend.current)
            }
            else if (e.target.name === MAP_LAYER_ANIMATION_CONST){
                ref.mapRef.current.addLayer(ref.animationLayerRef.current)
            }
            else if (e.target.name === MAP_LAYER_CARTOGRAM_CONST){
                ref.mapRef.current.addLayer(ref.cartogramRef.current)
            }
        }
        else{
            ref.mapRef.current.eachLayer(function(layer){
                if (layer.name === e.target.name){
                    ref.mapRef.current.removeLayer(layer);
                }
            });
        }
    }

    const mapHasLayer = (name) => {
        let response = false
        try{
            ref.mapRef.current.eachLayer(function(layer){
                if (layer.name === name){
                    response = true;
                }
            })
        }
        catch(e){
            //mapRef not rendered yet
        }
        return response;
    }

    const mapHasDrawingsLayer = (name) => {
        let response = true
        try{
            ref.userDrawingsRef.current.eachLayer(function(layer){
                if (layer.name === name){
                    if (layer.options.visible === false ){
                        response = false;
                    }
                }
            })
        }
        catch(e){
            //mapRef not rendered yet
        }
        return response;
    }

    const handleKmlForm = (e) => {
        if (e.target.checked){
            try{
                const file_content = omnivore.kml.parse(props.kmlFiles[parseInt(e.target.name)].content)
                file_content.name = e.target.id
                ref.mapRef.current.addLayer(file_content)

                let group = new L.FeatureGroup()
                group.addLayer(file_content)
                ref.mapRef.current.flyToBounds(group.getBounds());
            }
            catch(e){
                notify.error(t('map:kml-load-fail'))
            }   
        }
        else{
            try{
                ref.mapRef.current.eachLayer(function(layer){
                    if (layer.name === e.target.id){
                        ref.mapRef.current.removeLayer(layer);
                    }
                });
            }
            catch(e){
                console.log(e)
            }   
        }
    }

    const handleObservations = (e) => {
        if (e.target.checked){
            ref.observationsRef.current.addTo(ref.mapRef.current)
        }
        else{
            ref.mapRef.current.eachLayer(function(layer){
                if (layer.name === MAP_LAYER_OBSERVATIONS_CONST){
                    ref.mapRef.current.removeLayer(layer);
                }
            });
        }
    }
    
    const handleWmsLayer = (e, element, index) => {
        if(e.target.checked){
           try {
            new URL(element.url);
            let wms = L.tileLayer.wms(element.url, {
                layers: element.layer,
                name: element.name,
                transparency: true,
                format: 'image/png',
                opacity: element.opacity
            })
            wms.name = element.name
            wms.addTo(ref.mapRef.current)   
          }
          catch (e) {
            // invalid URL 
          }
        }
        else {
            ref.mapRef.current.eachLayer(function(layer){
                if (layer.name === element.name){
                    ref.mapRef.current.removeLayer(layer);
                }
            });
        }
    }

    const mapHasWmsLayer = (element) => {
        let response = false
        try{
            ref.mapRef.current.eachLayer(function(layer){
                if (layer.name === element.name){
                    response = true
                }
            });
        }
        catch(e){
            console.log(e)
        }  
        return response
    }

    return(
        <div style={{width: "300px"}}>
            {props.kmlFiles && props.kmlFiles.length > 0 &&
                <>
                    <div><b>{t("layer-of-imported-kml")}</b></div>
                    <div className="ml-3">
                        {
                            [...props.kmlFiles].map((elm,index) => {
                                return <Form.Check id={elm.name} key={index} type="checkbox" defaultChecked={mapHasLayer(elm.name)} onChange={handleKmlForm} name={index} label={elm.name}></Form.Check>
                            })
                        }
                    </div>
                </>
            }
            {props.wmsArray && props.wmsArray.length > 0 &&
                <>
                    <div><b>{t('basemaps-button-title')}</b></div>
                    <div className="ml-3">
                    {
                        [...props.wmsArray].map((element,index) => {
                            return <Form.Check 
                                id={element.name} 
                                key={index} 
                                type="checkbox"
                                defaultChecked={mapHasWmsLayer(element.name)}
                                onChange={(e) => handleWmsLayer(e, element, index)}
                                name={index} 
                                label={element.name}
                            />
                        })

                    }
                    </div>
                </>
            }
            <div><b>{t("layers-of-user-drawings")}</b></div>
            <div className="ml-3"> 
                <Form.Check id={USER_DRAWINGS_POINTS_CONST} type="checkbox" defaultChecked={mapHasDrawingsLayer(USER_DRAWINGS_POINTS_CONST)} onChange={handleUserDrawingsLayer} name={USER_DRAWINGS_POINTS_CONST} label={t("layers-of-user-drawings-points")}></Form.Check>
                <Form.Check id={USER_DRAWINGS_LINES_CONST} type="checkbox" defaultChecked={mapHasDrawingsLayer(USER_DRAWINGS_LINES_CONST)} onChange={handleUserDrawingsLayer} name={USER_DRAWINGS_LINES_CONST} label={t("layers-of-user-drawings-lines")}></Form.Check>
                <Form.Check id={USER_DRAWINGS_POLYGONS_CONST} type="checkbox" defaultChecked={mapHasDrawingsLayer(USER_DRAWINGS_POLYGONS_CONST)} onChange={handleUserDrawingsLayer} name={USER_DRAWINGS_POLYGONS_CONST} label={t("layers-of-user-drawings-polygons")}></Form.Check>
                <Form.Check 
                    id={MAP_LAYER_OBSERVATIONS_CONST}
                    type="checkbox" 
                    defaultChecked={mapHasLayer(MAP_LAYER_OBSERVATIONS_CONST)}
                    onChange={handleObservations}
                    name={MAP_LAYER_OBSERVATIONS_CONST}
                    label={t('observations')}
                />
            </div>
            {!props.hideOptions &&
                <>
                    <div><b>{t("layers-linked-to-search-filter")}</b></div>
                    <div className="ml-3"> 
                        <Form.Check id={MAP_LAYER_CLASTERED_TAXONS_CONST} type="checkbox" defaultChecked={mapHasLayer(MAP_LAYER_CLASTERED_TAXONS_CONST)} onChange={handleAncTaxonsLayer} name={MAP_LAYER_CLASTERED_TAXONS_CONST} label={t("clusterd-anc-results")}></Form.Check>
                        <Form.Check id={MAP_LAYER_UNCLASTERED_TAXONS_CONST_REF} type="checkbox" defaultChecked={mapHasLayer(MAP_LAYER_UNCLASTERED_TAXONS_CONST_REF)} onChange={handleAncTaxonsLayer} name={MAP_LAYER_UNCLASTERED_TAXONS_CONST_REF} label={t("unclusterd-anc-results")}></Form.Check>
                        <Form.Check id={MAP_LAYER_TAXONS_WITH_LEGEND_CONST} type="checkbox" defaultChecked={mapHasLayer(MAP_LAYER_TAXONS_WITH_LEGEND_CONST)} onChange={handleAncTaxonsLayer} name={MAP_LAYER_TAXONS_WITH_LEGEND_CONST} label={t("anc-legend")}></Form.Check>
                    </div>
                    <div><b>{t("historical-data")}</b></div>
                    <div className="ml-3"> 
                        <Form.Check id={MAP_LAYER_ANIMATION_CONST} type="checkbox" defaultChecked={mapHasLayer(MAP_LAYER_ANIMATION_CONST)} onChange={handleAncTaxonsLayer} name={MAP_LAYER_ANIMATION_CONST} label={t("animation-layer")}></Form.Check>
                    </div>
                    <div><b>{t("cartogram")}</b></div>
                    <div className="ml-3"> 
                        <Form.Check id={MAP_LAYER_CARTOGRAM_CONST} type="checkbox" defaultChecked={mapHasLayer(MAP_LAYER_CARTOGRAM_CONST)} onChange={handleAncTaxonsLayer} name={MAP_LAYER_CARTOGRAM_CONST} label={t("cartogram")}></Form.Check>
                    </div>
                </>
            }
        </div>
    )

})

const mapStateToProps = state => ({
    kmlFiles: state.map.files,
    wmsArray: state.map.wmsArray,
})

const mapDispatchToProps = dispatch => ({

})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(LayersPanel)
