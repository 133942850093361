import { combineReducers } from 'redux';
import * as actions from "../actions";
import * as userActions from '../actions/user'
import * as chartActions from '../actions/chart'
import * as formsActions from '../actions/forms'
import * as comparatorActions from '../actions/comparator'
import * as mapActions from '../actions/map'
import * as howToUseActions from '../actions/howToUse'
import { reducer as toastr } from 'react-redux-toastr'
import { setElementStructure, removeEmptyElementsStructure } from '../utils/search'
import stateReducer from './state';
import _ from 'lodash';

const requests = (state = {}, action) => {
    if (!Object.values(actions).includes(action.type)) {
        return state || {};
    }
    const temp = {...state};
    if (action.id && !temp[ action.id ]) {
        temp[ action.id ] = {}
    }
    switch (action.type) {
        case actions.REQUEST_PENDING:
            delete temp[ action.id ].output
            temp[ action.id ].pending = true
            temp[ action.id ].url = action.url
            temp[ action.id ].data = action.data
            return temp
        case actions.REQUEST_SUCCESS:
            temp[ action.id ].pending = false
            temp[ action.id ].output = action.data
            return temp
        case actions.REQUEST_SUCCESS_NO_DATA:
            temp[ action.id ].pending = false
            return temp
        case actions.REQUEST_ERROR:
            temp[ action.id ].pending = false
            temp[ action.id ].error = action.error
            return temp
        case actions.REQUEST_REMOVE:
            delete temp[ action.id ]
            return temp
        case actions.REQUEST_REMOVE_ALL:
            Object.keys(temp).forEach(key => {
                if (!action.except.includes(key)) {
                    delete temp[ key ]
                }
            })
            return temp
        case actions.REQUEST_REMOVE_STARTS_WITH:
            Object.keys(temp).forEach(key => {
                if (key.startsWith(action.prefix)) {
                    delete temp[ key ]
                }
            })
            return temp
        case actions.LIST_SET_FILTER:
            if (!temp[ action.id ].output) {
                temp[ action.id ].output = {}
            }
            temp[ action.id ].output.filter = action.filter
            return temp
        default:
            return state
    }
}

export const getRequest = (id, state) => state.requests[ id ]
export const getOutput = (id, state) => !state.requests[ id ] ? null : state.requests[ id ].output
export const getOutputOrDefault = (id, state, value) => (state.requests[ id ] && state.requests[ id ].output) || value
export const isPending = (id, state) => !state.requests[ id ] ? false : state.requests[ id ].pending
export const getError = (id, state) => !state.requests[ id ] ? null : state.requests[ id ].error
export const getItems = (id, state) => (!state.requests[ id ] || !state.requests[ id ].output) ? null : state.requests[ id ].output.items
export const getFilter = (id, state) => (!state.requests[ id ] || !state.requests[ id ].output) ? null : state.requests[ id ].output.filter
export const getPagination = (id, state) => (!state.requests[ id ] || !state.requests[ id ].output) ? null : state.requests[ id ].output.pagination
export const getCustomParams = (id, state) => (!state.requests[ id ] || !state.requests[ id ].output) ? null : state.requests[ id ].output.customParams
export const getTotal = (id, state) => (!state.requests[ id ] || !state.requests[ id ].output) ? null : state.requests[ id ].output.total

//######################################################

const forms = (state = {}, action) => {
    if (!Object.values(formsActions).includes(action.type)) {
        return state || {};
    }
    const temp = _.cloneDeep(state || {});
    if (!temp[ action.id ]) {
        temp[ action.id ] = {}
    }
    switch (action.type) {
        case formsActions.SET_FIELDS:
            temp[ action.id ].fields = action.fields
            return temp
        case formsActions.SET_FIELD:
            if (!temp[ action.id ].fields) {
                temp[ action.id ].fields = {}
            }
            temp[ action.id ].fields[ action.fieldId ] = action.value
            return temp
        case formsActions.SET_FIELD_WITH_PATH:
            if (!temp[ action.id ].fields) {
                temp[ action.id ].fields = {}
            }
            temp[ action.id ].fields = setElementStructure(action.path, temp[ action.id ].fields, action.value)
            return temp
        case formsActions.SET_SEARCH_AREA_MAPREF:
            temp[ 'map' ] = action.value
            return temp
        case formsActions.SET_SEARCH_AREA_GEOJSON:
            temp.searchAreaGeoJson = action.value
            return temp
        case formsActions.DELETE_FIELD:
            if (!temp[ action.id ].fields) {
                temp[ action.id ].fields = {}
            }
            delete temp[ action.id ].fields[ action.fieldId ]
            return temp
        case formsActions.DELETE_ALL_FIELDS:
            return {}

        case formsActions.SET_MAP_ICONS_DATA:
            temp.mapIcons = action.value
            return temp
        default:
            return state
    }
}

export const getFields = (id, state) => (!state.forms[ id ] || !state.forms[ id ].fields) ? {} : state.forms[ id ].fields
export const getField = (id, fieldId, state, def) => (!state || !state.forms || !state.forms[ id ] || !state.forms[ id ].fields || state.forms[ id ].fields[ fieldId ] === undefined) ? (def ? def : "") : state.forms[ id ].fields[ fieldId ]

export const prepareFilter = (id, state, path) =>
    path ?
        removeEmptyElementsStructure(JSON.parse(getField(id, id, state, "null"))) :
        removeEmptyElementsStructure(getFields(id, state))

//######################################################

const charts = (state = {}, action) => {
    if (!Object.values(chartActions).includes(action.type)) {
        return state || {};
    }
    const temp = _.cloneDeep(state || {});
    switch (action.type) {
        // Visits
        case chartActions.CHART_INFO:
            temp.interval = action.data.interval
            temp.period = action.data.period
            temp.chartType = action.data.chartType
            temp.presentationType = action.data.presentationType
            temp.days = action.data.days
            temp.visits = action.data.visits
            temp.visitors = action.data.visitors
            temp.users = action.data.users
            temp.dayFrom = action.data.dayFrom
            temp.dayTo = action.data.dayTo
            return temp
        case chartActions.SET_DISPLAYED_DATA:
            temp.days = action.data.days
            temp.visits = action.data.visits
            temp.visitors = action.data.visitors
            temp.users = action.data.users
            temp.interval = action.data.interval
            return temp
        case chartActions.SET_MONTHLY_DATA:
            temp.months = action.data.months
            temp.visits = action.data.visits
            temp.visitors = action.data.visitors
            temp.users = action.data.users
            temp.interval = action.data.interval
            temp.chartType = action.data.chartType
            temp.period = action.data.period
            temp.monthFrom = action.data.monthFrom
            temp.monthTo = action.data.monthTo
            return temp

        // Collections
        case chartActions.SET_COLLECTIONS:
            temp.NHC_BOT = action.data.NHC_BOT
            temp.NHC_ZOO = action.data.NHC_ZOO
            temp.dates_NHC_BOT = action.data.dates_NHC_BOT
            temp.dates_NHC_ZOO = action.data.dates_NHC_ZOO
            temp.botanicalCollectionNames = action.data.botanicalCollectionNames
            temp.zoologicalCollectionNames = action.data.zoologicalCollectionNames
            temp.displayedCollection = action.data.displayedCollection
            temp.timeDistribution = action.data.timeDistribution
            temp.allZooNames = action.data.allZooNames
            temp.allBotNames = action.data.allBotNames
            temp.totalImports = action.data.totalImports
            return temp

        case chartActions.SET_DISPLAYED_COLLECTION:
            temp.displayedCollection = action.data.displayedCollection
            return temp

        case chartActions.SET_TIME_DISTRIBUTION:
            temp.timeDistribution = action.data.timeDistribution
            return temp

        case chartActions.SET_BOTANICAL_COLLECTION_NAMES:
            temp.botanicalCollectionNames = action.data.botanicalCollectionNames
            return temp

        case chartActions.SET_ZOOLOGICAL_COLLECTION_NAMES:
            temp.zoologicalCollectionNames = action.data.zoologicalCollectionNames
            return temp

        case chartActions.SET_COLLECTIONS_WITHOUT_TYPES:
            temp.collectionsWithoutTypes = action.data.collectionsWithoutTypes
            return temp

        // Icon Size
        case chartActions.SET_ICON_SIZE_DATA:
            temp.datesForSizeReport = action.data.datesForSizeReport
            temp.iconSizeData = action.data.iconSizeData
            temp.sizeDataToDisplay = action.data.iconSizeData
            temp.timeUnit = action.data.timeUnit
            temp.sizeUnit = action.data.sizeUnit
            return temp

        case chartActions.SET_SIZE_UNIT:
            temp.sizeUnit = action.data.sizeUnit
            temp.sizeDataToDisplay = action.data.sizeDataToDisplay
            return temp

        // Downloads statistics
        case chartActions.SET_MONTHLY_DOWNLOADS_DATA:
            temp.months = action.data.months
            temp.number_downloads = action.data.number_downloads
            temp.taxons = action.data.taxons
            temp.users = action.data.users
            temp.interval = action.data.interval
            temp.chartType = action.data.chartType
            temp.presentationType = action.data.presentationType
            temp.period = action.data.period
            temp.monthFrom = action.data.monthFrom
            temp.monthTo = action.data.monthTo
            return temp

        case chartActions.SET_DAILY_DOWNLOADS_DATA:
            temp.users = action.data.users
            temp.number_downloads = action.data.number_downloads
            temp.taxons = action.data.taxons
            temp.days = action.data.days
            temp.interval = action.data.interval
            temp.chartType = action.data.chartType
            temp.presentationType = action.data.presentationType
            temp.dayFrom = action.data.dayFrom
            temp.dayTo = action.data.dayTo
            return temp

        // Common
        case chartActions.SET_CHART_TYPE:
            temp.chartType = action.data
            return temp
        case chartActions.SET_PRESENTATION_TYPE:
            temp.presentationType = action.data
            return temp
        case chartActions.SET_DAYS_SCOPE:
            temp.dayFrom = action.data.dayFrom
            temp.dayTo = action.data.dayTo
            return temp
        case chartActions.SET_INTERVAL:
            temp.interval = action.data.interval
            return temp
        case chartActions.SET_TIME_UNIT:
            temp.timeUnit = action.data.timeUnit
            return temp
        default:
            return state
    }
}

//######################################################

const map = (state = {}, action) => {
    if (!Object.values(mapActions).includes(action.type)) {
        return state || {};
    }
    const temp = _.cloneDeep(state || {});
    switch (action.type) {
        case mapActions.SET_USER_MAP_FILE:
            temp.file = action.data
            return temp
        case mapActions.CLEAR_MAPFILES_ARRAY:
            temp.files = []
            return temp
        case mapActions.SET_MAPFILES_ARRAY:
            temp.files = [ ...temp.files, action.data ]
            return temp
        case mapActions.CLEAR_WMS_ARRAY:
            temp.wmsArray = []
            return temp
        case mapActions.SET_WMS_ARRAY:
            temp.wmsArray = [ ...temp.wmsArray, action.data ]
            return temp
        case mapActions.SET_MAP_MARKER_COLOR:
            temp.markerColor = action.data
            return temp
        case mapActions.SET_MAP_MARKER_ICON:
            temp.markerIcon = action.data
            return temp
        case mapActions.SET_MAP_MARKER_SIZE:
            temp.markerSize = action.data
            return temp
        case mapActions.SET_MAP_POLYGON_STYLE:
            temp.polygonStyle = action.data
            return temp
        case mapActions.SET_ADMINISTRATIVE_DIVISION_STYLE:
            temp.administrativeDivisionStyle = action.data
            return temp
        case mapActions.SET_MAP_LINE_STYLE:
            temp.lineStyle = action.data
            return temp
        case mapActions.SET_TAXONS_BY_YEAR:
            temp.taxonsByYear = action.data
            return temp
        case mapActions.SET_HISTORICAL_ANALISIS_MODE:
            temp.historicalMode = action.data
            return temp
        case mapActions.SHOW_ANIMATION_BUTTON:
            temp.animationButton = action.data
            return temp
        case mapActions.SET_ANIMATION_TIMEOUTS:
            temp.animationTimeouts = action.data
            return temp
        case mapActions.SET_CARTOGRAM_ITEMS:
            temp.cartogram = action.data
            return temp
        case mapActions.SET_ANIMATION_YEARS:
            temp.animationYears = action.data
            return temp
        case mapActions.CLEAR_MAP_LABELS:
            temp.mapLabels = []
            return temp
        case mapActions.ADD_MAP_LABEL:
            temp.mapLabels = [ ...temp.mapLabels, action.data ]
            return temp
        case mapActions.REMOVE_MAP_LABEL:
            temp.mapLabels = [ ...temp.mapLabels ].filter(element => element !== action.data);
            return temp
        case mapActions.SET_OBSERVATIONS_MARKER_COLOR:
            temp.observationsColor = action.data
            return temp
        case mapActions.SET_OBSERVATIONS_MARKER_SIZE:
            temp.observationsSize = action.data
            return temp
        case mapActions.SET_OBSERVATIONS_MARKER_ICON:
            temp.observationsIcon = action.data
            return temp
        case mapActions.SET_CARTOGRAM_CURRENT_FILTER:
            temp.cartoramCurrentFilter = action.data
            return temp
        case mapActions.SET_CARTOGRAM_MANUAL_RANGES:
            temp.cartogramManualRanges = action.data
            return temp
        case mapActions.SET_TOO_MANY_TAXONS_WARNING:
            temp.taxonsWarning = action.data
            return temp
        case mapActions.SET_TAXONS_FOUND_NUMBER:
            temp.taxonsNumber = action.data
            return temp
        case mapActions.SET_HISTORICAL_MARKER_COLOR:
            temp.historicalMarkerColor = action.data
            return temp
        case mapActions.SET_HISTORICAL_MARKER_ICON:
            temp.historicalMarkerIcon = action.data
            return temp
        case mapActions.SET_HISTORICAL_MARKER_SIZE:
            temp.historicalMarkerSize = action.data
            return temp
        case mapActions.SET_ARRAY_OF_YEARS:
            temp.arrayOfYears = action.data
            return temp
        case mapActions.SET_PIXI_MARKER_COLOR:
            temp.pixiMarkerColor = action.data
            return temp
        case mapActions.SET_PIXI_SELECTED_CIRCLECOLOR:
            temp.pixiSelectedCirclecolor = action.data
            return temp
        case mapActions.SET_PIXI_MARKER_SIZE:
            temp.pixiMarkerSize = action.data
            return temp
        case mapActions.SET_PIXI_MARKER_SHAPE:
            temp.pixiMarkerShape = action.data
            return temp
        case mapActions.SET_GROUPING_SHAPE_FIELD:
            temp.groupingShapeField = action.data
            return temp
        case mapActions.SET_GROUPING_COLOR_FIELD:
            temp.groupingColorField = action.data
            return temp
        case mapActions.SET_GROUPING_COLOR_LEGEND_SHOWN:
            temp.groupingColorLegendShown = action.data
            return temp
        case mapActions.SET_GROUPING_SHAPE_LEGEND_SHOWN:
            temp.groupingShapeLegendShown = action.data
            return temp
        case mapActions.SET_CARTOGRAM_ADMINISTRATIVE_DIVISION:
            temp.cartogramAdministrativeDivision = action.data
            return temp
        case mapActions.SET_CARTOGRAM_BORDERS_COLOR:
            temp.cartogramBordersColor = action.data
            return temp
        case mapActions.SET_CARTOGRAM_BORDERS_WIDTH:
            temp.cartogramBordersWidth = action.data
            return temp
        case mapActions.SET_CARTOGRAM_OPACITY:
            temp.cartogramOpacity = action.data
            return temp
        case mapActions.SET_CARTOGRAM_FIRST_AREA_COLOR:
            temp.cartogramFirstAreaColor = action.data
            return temp
        case mapActions.SET_CARTOGRAM_SECOND_AREA_COLOR:
            temp.cartogramSecondAreaColor = action.data
            return temp
        case mapActions.SET_CARTODIAGRAM_GROUPING_FIELD:
            temp.cartodiagramGroupingField = action.data
            return temp
        case mapActions.SET_CARTODIAGRAM_NUMBER_OF_CHART_COLORS:
            temp.numberOfChartColors = action.data
            return temp
        case mapActions.SET_CARTOGRAM_NUMBER_OF_COLORS:
            temp.numberOfColors = action.data
            return temp
        case mapActions.SET_CARTOGRAM_SEQUENCE_TYPE:
            temp.sequenceType = action.data
            return temp
        case mapActions.SET_CARTOGRAM_SEQUENCE_PRODUCT:
            temp.sequenceProduct = action.data
            return temp
        case mapActions.SET_CARTOGRAM_FIRST_SEQUENCE_NUMBER:
            temp.firstSequenceNumber = action.data
            return temp
        case mapActions.SET_OBSERVATIONS_REDIRECTED_ID:
            temp.observationsRedirectedId = action.data
            return temp
        case mapActions.SET_LOAD_OBSERVATIONS_ON_INIT:
            temp.loadObservationsOnInit = action.data
            return temp
        case mapActions.SET_OPENED_MAP_TOOL:
            temp.openedMapTool = action.data
            return temp
        case mapActions.SET_MAP_TITLE:
            temp.mapTitle = action.data
            return temp
        case mapActions.SET_MAP_ID:
            temp.mapId = action.data
            return temp
        case mapActions.SET_WMS_ADDED_TO_MAP:
            temp.wmsAddedToMap = action.data
            return temp
        case mapActions.SET_MAP_LEGEND:
            temp.mapLegend = action.data
            return temp
        case mapActions.SET_VISIBLE_TOOL_SCALE:
            temp.mapScaleVisible = action.data
            return temp
        case mapActions.SET_VISIBLE_TOOL_ZOOM_SLIDER:
            temp.zoomSliderVisible = action.data
            return temp
        case mapActions.SET_VISIBLE_TOOL_MAGNIFIER_GLASS:
            temp.magnifierGlassVisible = action.data
            return temp
        case mapActions.SET_VISIBLE_TOOL_IMAGE_EXPORT:
            temp.imageExportVisible = action.data
            return temp
        case mapActions.SET_VISIBLE_TOOL_BASEMAPS:
            temp.basemapsVisible = action.data
            return temp
        case mapActions.SET_VISIBLE_TOOL_DRAWING_TOOLS:
            temp.drawingToolsVisible = action.data
            return temp
        case mapActions.SET_KEY_VALUE:
            temp[action.key] = action.value
            return temp
        case mapActions.CLEAR_KEY_VALUE:
            delete temp[action.key]
            return temp
        default:
            return state
    }
}
//######################################################

const user = (state = null, action) => {
    if (!Object.values(userActions).includes(action.type)) {
        return state || {};
    }
    const temp = _.cloneDeep(state || {});
    switch (action.type) {
        case userActions.SET_INFO:
            temp.data = action.data
            return temp
        case userActions.SET_RETURN:
            temp.return = action.url
            return temp
        case userActions.SET_IS_LOGGING_OUT:
            temp.isLoggingOut = action.logout
            return temp
        default:
            return state
    }
}

//######################################################

const comparator = (state = null, action) => {
    if (!Object.values(comparatorActions).includes(action.type)) {
        return state || {};
    }
    const temp = _.cloneDeep(state || {});
    switch (action.type) {
        case comparatorActions.ADD_PICTURE_1:
            temp.edi_id1 = action.data.edi_id1
            temp.edi_ver_id1 = action.data.edi_ver_id1
            temp.external_id1 = action.data.external_id1
            temp.FullAuthorName1 = action.data.FullAuthorName1
            temp.requestUrl1 = action.data.requestUrl1
            return temp
        case comparatorActions.ADD_PICTURE_2:
            temp.edi_id2 = action.data.edi_id2
            temp.edi_ver_id2 = action.data.edi_ver_id2
            temp.external_id2 = action.data.external_id2
            temp.FullAuthorName2 = action.data.FullAuthorName2
            temp.requestUrl2 = action.data.requestUrl2
            return temp
        case comparatorActions.CLEAR_COMPARATOR:
            return null
        default:
            return state
    }
}

//######################################################

const howToUse = (state = null, action) => {
    if (!Object.values(howToUseActions).includes(action.type)) {
        return state || {};
    }
    const temp = _.cloneDeep(state || {});
    switch (action.type) {
        case howToUseActions.ADD_ANCHOR:
            temp.anchor = action.data.anchor
            return temp
        case comparatorActions.CLEAR_COMPARATOR:
            return null
        default:
            return state
    }
}

//######################################################

const reducers = combineReducers({
    requests,
    charts,
    user,
    toastr,
    forms,
    comparator,
    howToUse,
    map,
    state: stateReducer,
})

export default reducers
