import React, { useCallback } from 'react'
import { Dropdown } from  "react-bootstrap"; 
import { useTranslation } from 'react-i18next'
import L from 'leaflet';
import renderIconHtml from '../utils/map/renderIconHtml'

const useMarkerShapeColorSelector = ((onShapeChanged, onColorChanged, onSizeChanged) => {
    const { t } = useTranslation(['projects', 'forms', 'map'])
  
    const prepareBasicDivIcon = useCallback((s, c, x) => {
        let html
        html = renderIconHtml(`fa fa-${s} fa-${x}`, c)
        return L.divIcon({
            html: html,
            className: 'myDivIcon',
            iconSize: [20, 20]
        });
    }, [])

    const SHAPES = [
        "map-marker",
        "map-pin",
        "thumb-tack",
        "star",
        "star-o",
        "circle",
        "circle-o",
        "bullseye",
        "square",
        "square-o",
        "cube",
        "bookmark",
        "asterisk",
        "times",
        "plus",
    ]
    const COLORS = [
        "blue",
        "brown",
        "green",
        "orange",
        "red",
        "violet",
        "yellow",
        "white",
        "black",
        "gray",
    ]
    const SIZES = [
        "1x",
        "2x",
        "3x",
    ]

    const DropdownShapeItem = props =>
        <Dropdown.Item onClick={e => onShapeChanged(props.shape)}>
            <i className={`fa fa-${props.shape}`} />
        </Dropdown.Item>

    const DropdownColorItem = props =>
        <Dropdown.Item onClick={e => onColorChanged(props.color)}>
            <i className="fa fa-square" style={{color: props.color, border: props.color === 'white' ? '1px solid black' : undefined}} /> {t(`map:color-${props.color}`)}
        </Dropdown.Item>
    
    const DropdownSizeItem = props =>
        <Dropdown.Item onClick={e => onSizeChanged(props.size)}>
            <i className={`fa fa-square fa-${props.size}`} />
        </Dropdown.Item>
    
    const DropdownShapeItems = props =>
      <>
        {SHAPES.map((val, indx) =>
            <DropdownShapeItem key={indx} shape={val}></DropdownShapeItem>
        )}
      </>

    const DropdownColorItems = props =>
      <>
        {COLORS.map((val, indx) =>
            <DropdownColorItem key={indx} color={val}></DropdownColorItem>
        )}
      </>
    
    const DropdownSizeItems = props =>
      <>
        {SIZES.map((val, indx) =>
            <DropdownSizeItem key={indx} size={val}></DropdownSizeItem>
        )}
      </>

    return {
      DropdownShapeItems,
      DropdownColorItems,
      DropdownSizeItems,
      prepareBasicDivIcon,
    }

})

export default useMarkerShapeColorSelector
