import React, { forwardRef, useEffect, Suspense, useCallback } from "react";
import { connect } from 'react-redux'
import {
    CARTODIAGRAM_REQUEST_TIMEOUT,
    COLLECTION_TYPES_REQUEST_ID,
    MAP_DATA_REQUEST_TIMEOUT,
    MAP_TOOLS_ACTIVE_VISUALIZATION,
    MAP_TOOLS_VISUALIZATION_1,
    MAP_TOOLS_VISUALIZATION_2,
    MAP_TOOLS_VISUALIZATION_3,
    MAP_TOOLS_VISUALIZATION_4,
    MAP_TOOLS_VISUALIZATION_5,
    MAP_TOOLS_VISUALIZATION_6,
    MAP_TOOLS_VISUALIZATION_7,
    TAXONDB_DETAILS_REQUEST_URL,
    TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID,
    TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID,
    TAXONDB_SEARCH_GENUS_REQUEST_ID,
    TAXONDB_SEARCH_MAP_DATA_REQUEST_ID,
    TAXONDB_SEARCH_MAP_DATA_REQUEST_URL,
    TAXONDB_SEARCH_WITH_AGGREGATION_REQUEST_URL,
    TAXON_SEARCH_IS_DYNAMIC,
    MAP_OPENED_TOOL_SEARCH_FORM,
    ARRANGEMENT_TOOLS_SEARCH_ON_INIT,
} from '../../../settings'
import { useTranslation } from 'react-i18next'
import { Button, Collapse } from "react-bootstrap"
import SearchForm from "../../taxonDB/SearchForm";
import DynamicSearchForm2 from "../../taxonDB/DynamicSearchForm2";
import DynamicSearchForm from "../../taxonDB/DynamicSearchForm";
import { getField, getFields, getOutput } from "../../../reducers";
import { newRunSearchMap, runCartogramSearch, runHistoricalAnalysis, runMapGroupingSearch, runSearchMap, setField } from '../../../actions/forms'
import { getDataAnc, postDataAnc } from "../../../actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleRight, faSearchLocation } from "@fortawesome/free-solid-svg-icons";
import { clusteringSearchItems } from "./clustering/ClusteringSearch"
import { clusteringCartogramSearch } from "./clustering/ClusteringCartogramSearch"
import { arrangementSearchItems } from "./arrangement/ArrangementCartogramSearch"
import { arrangementMapSearch } from "./arrangement/ArrangementMapSearch"
import { cartodiagramSearchItems } from "./grouping/CartodiagramSearch"
import { historicalMapSearch } from "./historical/HistoricalSearch"
import { groupingMapSearch } from "./grouping/GroupingSearch"
import { setAnimationYears, setArrayOfYears, setCartodiagramGroupingField, setCartodiagramNumberOfChartColors, setCartogramAdministrativeDivision, setCartogramBordersColor, 
    setCartogramBordersWidth, setCartogramFirstAreaColor, setCartogramFirstSequenceNumber, setCartogramNumberOfColors, setCartogramOpacity, setCartogramSecondAreaColor, 
    setCartogramSequenceProduct, setCartogramSequenceType, setGroupingColorField, setGroupingColorLegendShown, setGroupingShapeField, setGroupingShapeLegendShown, setOpenedMapTool, 
    setPixiMarkerColor, setPixiMarkerShape, setPixiMarkerSize, setPixiSelectedCirclecolor, setTaxonsByYear, setTaxonsFoundNumber, setTooManyTaxonsWarning } from "../../../actions/map";
import { ROK_ZBIORU_FIELD_NAME } from "../../taxonDB/attributesStructure";
import Loader from '../../../utils/loader'
import useMapDefinition from "../../../hooks/useMapDefinition";

const MapSearchForms = forwardRef((props, ref) => {
   
    const { collapsed, isDynamic, setIsDynamic, openSlidebar, setActiveMapTool, activeMapTool } = {...props}
    const { t, i18n } = useTranslation([ 'taxondb', 'map', 'common' ])
    const { initMap } = useMapDefinition();

    useEffect(() => {
        /* Pixi.js (common for Arrangement and Grouping) */
        if (!props.pixiMarkerColor) props.setPixiMarkerColor(0x1A14D4)
        if (!props.pixiMarkerSize) props.setPixiMarkerSize(6)
        if (!props.pixiMarkerShape) props.setPixiMarkerShape("square_solid")
        if (!props.pixiSelectedCirclecolor) props.setPixiSelectedCirclecolor("blue")

        /* Grouping */
        if (!props.groupingShapeField) props.setGroupingShapeField("rodzaj")
        if (!props.groupingColorField) props.setGroupingColorField("gatunek")

        /* Common values for cartograms */
        if (!props.cartogramAdministrativeDivision) props.setCartogramAdministrativeDivision("panstwo")
        if (!props.cartogramBordersColor) props.setCartogramBordersColor("white")
        if (!props.cartogramBordersWidth) props.setCartogramBordersWidth(0.2)
        if (!props.cartogramOpacity) props.setCartogramOpacity(1)

        /* Clustering Cartogram values */
        if (!props.numberOfColors) props.setCartogramNumberOfColors(10)
        if (!props.sequenceType) props.setCartogramSequenceType("SEQUENCE_TYPE_ARYTHMETIC")
        if (!props.sequenceProduct) props.setCartogramSequenceProduct(2)
        if (!props.firstSequenceNumber) props.setCartogramFirstSequenceNumber(2)
        if (!props.cartogramFirstAreaColor) props.setCartogramFirstAreaColor("gray")
        if (!props.cartogramSecondAreaColor) props.setCartogramSecondAreaColor("blue")

        /* Cartodiagram values */
        if (!props.cartodiagramGroupingField) props.setCartodiagramGroupingField("rodzaj")
        if (!props.numberOfChartColors) props.setCartodiagramNumberOfChartColors(2)
    })

    const handleMapSearch = useCallback(() => {
        if(props.activeVisualization === MAP_TOOLS_VISUALIZATION_1){
            return arrangementMapSearch(ref, props, t, i18n)
        }
        else if(props.activeVisualization === MAP_TOOLS_VISUALIZATION_2){
            return arrangementSearchItems(ref, props, t)
        }
        else if(props.activeVisualization === MAP_TOOLS_VISUALIZATION_3){
            return clusteringSearchItems(ref, props, t, i18n)
        }
        else if(props.activeVisualization === MAP_TOOLS_VISUALIZATION_4){
            return clusteringCartogramSearch(ref, props, t)
        }
        else if(props.activeVisualization === MAP_TOOLS_VISUALIZATION_5){
            return cartodiagramSearchItems(ref, props, t)
        }
        else if(props.activeVisualization === MAP_TOOLS_VISUALIZATION_6){
            return groupingMapSearch(ref, props, i18n, t)
        }
        else if(props.activeVisualization === MAP_TOOLS_VISUALIZATION_7){
            return historicalMapSearch(ref, props, t)
        }
    }, [i18n, props, ref, t])

    useEffect(() => {
        if (props.searchOnInit && ref.mapRef.current && ref.userDrawingsRef && ref.userMapDescriptionRef) {
            handleMapSearch()
                .then(() => {
                    initMap(ref.mapRef, ref.userDrawingsRef, ref.userMapDescriptionRef, ref.observationsRef)
                })
            props.setSearchOnInit(false)
        }
    }, [props, handleMapSearch, initMap, ref ])

    return(
        <Suspense fallback={<Loader />}>
            {collapsed &&
                <div className="sidebar-single-button-container">
                    <button
                        title={t("taxondb:taxondb-form-title")}
                        className="sidebar-button-collapsed"
                        onClick={() => {
                            openSlidebar()
                            setActiveMapTool(MAP_OPENED_TOOL_SEARCH_FORM)
                        }} 
                    >
                        <FontAwesomeIcon icon={faSearchLocation} color={"white"}/>
                    </button>
                </div>
            }
            {!collapsed &&
            <>
                <div className="sidebar-opened-buttons-container"> 
                    <div className="sidebar-single-button-container">
                        <button
                            title={t("taxondb:taxondb-form-title")}
                            className="sidebar-button-collapsed"
                            onClick={() => {activeMapTool === MAP_OPENED_TOOL_SEARCH_FORM ? setActiveMapTool(null) : setActiveMapTool(MAP_OPENED_TOOL_SEARCH_FORM)}
                        }>
                            <FontAwesomeIcon icon={faSearchLocation} color={"white"}/>
                        </button>
                    </div>
                   
                    <div
                        className="sidebar-text playContentOpacityAnimation changeContentOpacityAnimation"
                        onClick={() => {activeMapTool === MAP_OPENED_TOOL_SEARCH_FORM ? setActiveMapTool(null) : setActiveMapTool(MAP_OPENED_TOOL_SEARCH_FORM)}}
                    > 
                        {t("taxondb:taxondb-form-title")} 
                    </div> 
                        
                    <div className="sidebar-items-arrow changeOpacityAnimation playOpacityAnimation">
                        <FontAwesomeIcon icon={activeMapTool === MAP_OPENED_TOOL_SEARCH_FORM ? faAngleDown : faAngleRight} color={"black"}/>
                    </div>
                </div>
                <Collapse in={activeMapTool === MAP_OPENED_TOOL_SEARCH_FORM}>
                    <div className="playContentOpacityAnimation changeContentOpacityAnimation">
                        <div className="pseudo-tabs-container">
                            <Button 
                                onClick={() => setIsDynamic("0")}
                                className={isDynamic === "0" ? "pseudo-tabs-item pseudo-tabs-item-active" : "pseudo-tabs-item"} 
                                variant="light" 
                            >
                                {t('simple')}
                            </Button>
                            <Button 
                                onClick={() => setIsDynamic("2")}
                                className={isDynamic === "2" ? "pseudo-tabs-item pseudo-tabs-item-active" : "pseudo-tabs-item"} 
                                variant="light"
                            >
                                {t('extended')}
                            </Button>
                            <Button 
                                onClick={() => setIsDynamic("1")}
                                className={isDynamic === "1" ? "pseudo-tabs-item pseudo-tabs-item-active" : "pseudo-tabs-item"} 
                                variant="light" 
                            >
                                {t('advanced')}
                            </Button>
                        </div>
                        {isDynamic === "0" &&
                            <div className="ml-2">
                                <SearchForm
                                    bioGisForm={true}
                                    handleMapSearch={handleMapSearch}
                                />
                            </div>
                        }
                        {isDynamic === "2" &&
                            <div className="ml-2">
                                <DynamicSearchForm2
                                    bioGisForm={true}
                                    handleMapSearch={handleMapSearch}
                                />
                            </div>
                        }
                        {isDynamic === "1" &&
                            <div className="ml-2">
                                <DynamicSearchForm
                                    bioGisForm={true}
                                    handleMapSearch={handleMapSearch}
                                />
                            </div>
                        }
                    </div>
                </Collapse>
            </>
            }
        </Suspense>
    )
})

const mapStateToProps = (state, ownProps) => ({
    isDynamic: getField(TAXON_SEARCH_IS_DYNAMIC, TAXON_SEARCH_IS_DYNAMIC, state, "0"),
    activeVisualization: getField(MAP_TOOLS_ACTIVE_VISUALIZATION, MAP_TOOLS_ACTIVE_VISUALIZATION, state, MAP_TOOLS_VISUALIZATION_1),
    collectionTypes: getOutput(COLLECTION_TYPES_REQUEST_ID, state),
    manualRanges: state.map.cartogramManualRanges,
    filter1: getFields(TAXONDB_SEARCH_GENUS_REQUEST_ID, state),
    filter2: JSON.parse(getField(TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID, TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID, state, "null")),
    filter3: JSON.parse(getField(TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, state, "null")),
    animationYears: state.map.animationYears,
    taxonsByYear: state.map.taxonsByYear,
    animationTimeouts: state.map.animationTimeouts,
    markerColor: state.map.historicalMarkerColor,
    markerIcon: state.map.historicalMarkerIcon,
    markerSize: state.map.historicalMarkerSize,
    taxonsWarning: state.map.taxonsWarning,
    arrayOfYears: state.map.arrayOfYears,
    pixiMarkerColor: state.map.pixiMarkerColor,
    pixiSelectedCirclecolor: state.map.pixiSelectedCirclecolor,
    pixiMarkerSize: state.map.pixiMarkerSize,
    pixiMarkerShape: state.map.pixiMarkerShape,
    groupingShapeField: state.map.groupingShapeField,
    groupingColorField: state.map.groupingColorField,
    groupingColorLegendShown: state.map.groupingColorLegendShown,
    groupingShapeLegendShown: state.map.groupingShapeLegendShown,
    cartogramAdministrativeDivision: state.map.cartogramAdministrativeDivision,
    cartogramBordersColor: state.map.cartogramBordersColor,
    cartogramBordersWidth: state.map.cartogramBordersWidth,
    cartogramOpacity: state.map.cartogramOpacity,
    cartogramFirstAreaColor: state.map.cartogramFirstAreaColor,
    cartogramSecondAreaColor: state.map.cartogramSecondAreaColor,
    cartodiagramGroupingField: state.map.cartodiagramGroupingField,
    numberOfChartColors: state.map.numberOfChartColors,
    numberOfColors: state.map.numberOfColors,
    sequenceType: state.map.sequenceType,
    sequenceProduct: state.map.sequenceProduct,
    firstSequenceNumber: state.map.firstSequenceNumber,
    activeMapTool: state.map.openedMapTool,
    mapLabels: state.map.mapLabels,
    searchOnInit: getField(ARRANGEMENT_TOOLS_SEARCH_ON_INIT, ARRANGEMENT_TOOLS_SEARCH_ON_INIT, state, false),
})

const mapDispatchToProps = dispatch => ({
    setIsDynamic: value => dispatch(setField(TAXON_SEARCH_IS_DYNAMIC, TAXON_SEARCH_IS_DYNAMIC, value)),
    runSearchMap: (id) => dispatch(runSearchMap(id, MAP_DATA_REQUEST_TIMEOUT)),
    runCartogramSearch: (id, path) => dispatch(runCartogramSearch(id, path)),
    getDetails: recordId => dispatch(getDataAnc('popup', TAXONDB_DETAILS_REQUEST_URL + recordId + '/')),
    newRunSearchMap: (id, filter) => dispatch(newRunSearchMap(id, filter, MAP_DATA_REQUEST_TIMEOUT)),
    searchWithAggregation: (field, filter, items_per_page, request_id) => {
        return dispatch(postDataAnc(request_id,
            TAXONDB_SEARCH_WITH_AGGREGATION_REQUEST_URL, {
            filter: filter,
            with_total: true,
            aggregation_field: field,
            pagination: {perPage: items_per_page},
        },
        CARTODIAGRAM_REQUEST_TIMEOUT
        ))
    },
    setTooManyTaxonsWarning: data => dispatch(setTooManyTaxonsWarning(data)),
    setTaxonsFoundNumber: data => dispatch(setTaxonsFoundNumber(data)),
    setArrayOfYears: array => dispatch(setArrayOfYears(array)),
    runHistoricalAnalysis: (requestId, displayTaxonsFunc) => dispatch(runHistoricalAnalysis(requestId, displayTaxonsFunc)),
    postDataAnc: (filter) => dispatch(
        postDataAnc(TAXONDB_SEARCH_MAP_DATA_REQUEST_ID, TAXONDB_SEARCH_MAP_DATA_REQUEST_URL, {
            filter: filter,
            field1: ROK_ZBIORU_FIELD_NAME
        },
        MAP_DATA_REQUEST_TIMEOUT)
    ),
    setTaxonsByYear: data => dispatch(setTaxonsByYear(data)),
    setAnimationYears: years => dispatch(setAnimationYears(years)),
    runMapGroupingSearch: (id, path) => dispatch(runMapGroupingSearch(id, path)),
    searchMapData: (data, source) => dispatch(postDataAnc(
        TAXONDB_SEARCH_MAP_DATA_REQUEST_ID,
        TAXONDB_SEARCH_MAP_DATA_REQUEST_URL,
        data,
        MAP_DATA_REQUEST_TIMEOUT,
        source)),
    setPixiMarkerShape: data => dispatch(setPixiMarkerShape(data)),
    setPixiMarkerSize: data => dispatch(setPixiMarkerSize(data)),
    setPixiMarkerColor: data => dispatch(setPixiMarkerColor(data)),
    setPixiSelectedCirclecolor: data => dispatch(setPixiSelectedCirclecolor(data)),
    setGroupingShapeField: data => dispatch(setGroupingShapeField(data)),
    setGroupingColorField: data => dispatch(setGroupingColorField(data)),
    setGroupingColorLegendShown: data => dispatch(setGroupingColorLegendShown(data)),
    setGroupingShapeLegendShown: data => dispatch(setGroupingShapeLegendShown(data)),
    setCartogramAdministrativeDivision: data => dispatch(setCartogramAdministrativeDivision(data)),
    setCartogramBordersColor: data => dispatch(setCartogramBordersColor(data)),
    setCartogramBordersWidth: data => dispatch(setCartogramBordersWidth(data)),
    setCartogramOpacity: data => dispatch(setCartogramOpacity(data)),
    setCartogramFirstAreaColor: data => dispatch(setCartogramFirstAreaColor(data)),
    setCartogramSecondAreaColor: data => dispatch(setCartogramSecondAreaColor(data)),
    setCartodiagramGroupingField: data => dispatch(setCartodiagramGroupingField(data)),
    setCartodiagramNumberOfChartColors: data => dispatch(setCartodiagramNumberOfChartColors(data)),
    setCartogramNumberOfColors: data => dispatch(setCartogramNumberOfColors(data)),
    setCartogramSequenceType: data => dispatch(setCartogramSequenceType(data)),
    setCartogramSequenceProduct: data => dispatch(setCartogramSequenceProduct(data)),
    setCartogramFirstSequenceNumber: data => dispatch(setCartogramFirstSequenceNumber(data)),
    setActiveMapTool: (value) => dispatch(setOpenedMapTool(value)),
    setSearchOnInit: value => dispatch(setField(ARRANGEMENT_TOOLS_SEARCH_ON_INIT, ARRANGEMENT_TOOLS_SEARCH_ON_INIT, value)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(MapSearchForms)
