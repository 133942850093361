import React from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { runSearchFlowBiblio } from '../../actions/forms'
import { requestRemove } from '../../actions'
import { isPending } from "../../reducers"
import {
    BIBLIODB_SEARCH_REQUEST_ID,
    BIBLIODB_SUGGEST_REQUEST_PREFIX,
} from '../../settings'
import {
    ROK_PUBLIKACJI_FIELD_NAME,
    TYP_PUBLIKACJI_FIELD_NAME,
    TYTUL_PUBLIKACJI_FIELD_NAME,
    SLOWA_KLUCZOWE_FIELD_NAME,
    CYTOWANIE_FIELD_NAME,
    AUTORZY_FIELD_NAME,
    TYP_PUBLIKACJI_VALUE_1,
    TYP_PUBLIKACJI_VALUE_2,
    TYP_PUBLIKACJI_VALUE_3,
} from './attributesStructure'
import InputWithAsyncTypeahead from "../InputWithAsyncTypeahead"
import { Form, Container, Row, Col } from "react-bootstrap"
import InputSimpleSelect from "../InputSimpleSelect"
import SearchButtons from './SearchButtons'
import { rokZbioruOnChange, rokZbioruShouldRenderSuggestions } from '../../utils/search'
import InfoIconPopover from "../InfoIconPopover";

const SearchForm = props => {
    const { t } = useTranslation([ 'common', 'bibliodb', 'bibliodb-attributes' ])

    const handleOnKeyUp = e => {
        if (e.keyCode === 13) { // ENTER
            e.preventDefault()
            props.runSearchFlow()
        }
    }

    return (
        <Form onSubmit={e => { e.preventDefault() }}>
            <Container className="mt-2">
                <Row>
                    <Form.Group as={Col} sm={6} md={4}>
                        <Form.Label htmlFor={CYTOWANIE_FIELD_NAME}>{t('bibliodb-attributes:' + CYTOWANIE_FIELD_NAME)}</Form.Label>
                        <InputWithAsyncTypeahead
                            url={BIBLIODB_SUGGEST_REQUEST_PREFIX + CYTOWANIE_FIELD_NAME + "/"}
                            id={CYTOWANIE_FIELD_NAME}
                            disabled={props.loading}
                            formId={BIBLIODB_SEARCH_REQUEST_ID}
                            onKeyUp={handleOnKeyUp}
                            onChange={e => props.clearResponse()}></InputWithAsyncTypeahead>
                    </Form.Group>
                    <Form.Group as={Col} sm={6} md={4}>
                        <Form.Label htmlFor={AUTORZY_FIELD_NAME}>{t('bibliodb-attributes:' + AUTORZY_FIELD_NAME)}</Form.Label>
                        <InfoIconPopover>
                            <div>{t('bibliodb:autorzy_info-1')}</div>
                            <div>{t('bibliodb:autorzy_info-2')}</div>
                        </InfoIconPopover>
                        <InputWithAsyncTypeahead
                            url={BIBLIODB_SUGGEST_REQUEST_PREFIX + AUTORZY_FIELD_NAME + "/"}
                            id={AUTORZY_FIELD_NAME}
                            disabled={props.loading}
                            formId={BIBLIODB_SEARCH_REQUEST_ID}
                            onKeyUp={handleOnKeyUp}
                            onChange={e => props.clearResponse()}></InputWithAsyncTypeahead>
                    </Form.Group>
                    <Form.Group as={Col} sm={6} md={4}>
                        <Form.Label htmlFor={ROK_PUBLIKACJI_FIELD_NAME}>{t('bibliodb-attributes:' + ROK_PUBLIKACJI_FIELD_NAME)}
                        </Form.Label>
                        <InfoIconPopover>
                            <div>{t('bibliodb:rokpublikacji_info-1')}</div>
                            <div>{t('bibliodb:rokpublikacji_info-2')}</div>
                            <div>{t('bibliodb:rokpublikacji_info-3')}</div>
                            <div>{t('bibliodb:rokpublikacji_info-4')}</div>
                            <div>{t('bibliodb:rokpublikacji_info-5')}</div>
                        </InfoIconPopover>
                        <InputWithAsyncTypeahead
                            url={BIBLIODB_SUGGEST_REQUEST_PREFIX + ROK_PUBLIKACJI_FIELD_NAME + "/"}
                            id={ROK_PUBLIKACJI_FIELD_NAME}
                            disabled={props.loading}
                            formId={BIBLIODB_SEARCH_REQUEST_ID}
                            shouldRenderSuggestions={rokZbioruShouldRenderSuggestions}
                            conditionalChange={rokZbioruOnChange}
                            onKeyUp={handleOnKeyUp}
                            onChange={e => props.clearResponse()}
                        ></InputWithAsyncTypeahead>
                    </Form.Group>
                    <Form.Group as={Col} sm={6} md={4}>
                        <Form.Label htmlFor={TYTUL_PUBLIKACJI_FIELD_NAME}>{t('bibliodb-attributes:' + TYTUL_PUBLIKACJI_FIELD_NAME)}</Form.Label>
                        <InputWithAsyncTypeahead
                            url={BIBLIODB_SUGGEST_REQUEST_PREFIX + TYTUL_PUBLIKACJI_FIELD_NAME + "/"}
                            id={TYTUL_PUBLIKACJI_FIELD_NAME}
                            disabled={props.loading}
                            formId={BIBLIODB_SEARCH_REQUEST_ID}
                            onKeyUp={handleOnKeyUp}
                            onChange={e => props.clearResponse()}></InputWithAsyncTypeahead>
                    </Form.Group>
                    <Form.Group as={Col} sm={6} md={4}>
                        <Form.Label htmlFor={SLOWA_KLUCZOWE_FIELD_NAME}>{t('bibliodb-attributes:' + SLOWA_KLUCZOWE_FIELD_NAME)}</Form.Label>
                        <InputWithAsyncTypeahead
                            url={BIBLIODB_SUGGEST_REQUEST_PREFIX + SLOWA_KLUCZOWE_FIELD_NAME + "/"}
                            id={SLOWA_KLUCZOWE_FIELD_NAME}
                            disabled={props.loading}
                            formId={BIBLIODB_SEARCH_REQUEST_ID}
                            onKeyUp={handleOnKeyUp}
                            onChange={e => props.clearResponse()}></InputWithAsyncTypeahead>
                    </Form.Group>
                    <Form.Group as={Col} sm={6} md={4}>
                        <Form.Label htmlFor={TYP_PUBLIKACJI_FIELD_NAME}>{t('bibliodb-attributes:' + TYP_PUBLIKACJI_FIELD_NAME)}</Form.Label>
                        <InputSimpleSelect
                            id={TYP_PUBLIKACJI_FIELD_NAME}
                            name={TYP_PUBLIKACJI_FIELD_NAME}
                            onChange={e => props.clearResponse()}
                            disabled={props.loading}
                            onKeyUp={handleOnKeyUp}
                            allowedValues={[
                                {
                                    value: '',
                                },
                                {
                                    value: TYP_PUBLIKACJI_VALUE_1,
                                },
                                {
                                    value: TYP_PUBLIKACJI_VALUE_2,
                                },
                                {
                                    value: TYP_PUBLIKACJI_VALUE_3,
                                },
                            ]}
                            formId={BIBLIODB_SEARCH_REQUEST_ID}
                        ></InputSimpleSelect>
                    </Form.Group>
                </Row>
              <Row><Col>{t('bibliodb:form-wildcard-info')}</Col></Row>
              <div className="d-flex justify-content-center my-3">
                  <SearchButtons requestId={BIBLIODB_SEARCH_REQUEST_ID}></SearchButtons>
              </div>
            </Container>
        </Form>
    )
}

const mapStateToProps = state => ({
    loading: isPending(BIBLIODB_SEARCH_REQUEST_ID, state),
})

const mapDispatchToProps = dispatch => ({
    runSearchFlow: () => dispatch(runSearchFlowBiblio(BIBLIODB_SEARCH_REQUEST_ID)),
    clearResponse: () => dispatch(requestRemove(BIBLIODB_SEARCH_REQUEST_ID)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchForm)
