import React, { Suspense } from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import SearchForm from "./taxonDB/SearchForm"
import DynamicSearchForm from "./taxonDB/DynamicSearchForm"
import DynamicSearchForm2 from "./taxonDB/DynamicSearchForm2"
import ResultsContainer from "./taxonDB/ResultsContainer"
import { isPending, getOutput, getField } from "../reducers";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap"
import {
    TAXONDB_COUNT_REQUEST_ID,
    TAXON_SEARCH_IS_DYNAMIC,
    TAXONDB_SEARCH_GENUS_REQUEST_ID,
    TAXONDB_SEARCH_SPECIES_REQUEST_ID,
    TAXONDB_SEARCH_REQUEST_ID,
    TAXON_RESULTS_ACTIVE_TAB_FIELD_NAME,
    TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID,
    TAXONDB_DYNAMIC_SEARCH_SPECIES_REQUEST_ID,
    TAXONDB_DYNAMIC_SEARCH_REQUEST_ID,
    TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID,
    TAXONDB_DYNAMIC2_SEARCH_SPECIES_REQUEST_ID,
    TAXONDB_DYNAMIC2_SEARCH_REQUEST_ID,
    TAXON_DYNAMIC_RESULTS_ACTIVE_TAB_FIELD_NAME,
    TAXON_DYNAMIC2_RESULTS_ACTIVE_TAB_FIELD_NAME,
    TAXONDB_PAGE_URL_EN,
    TAXONDB_PAGE_URL_PL,
    TAXONDB_SEARCH_ICONS_REQUEST_ID,
    TAXONDB_DYNAMIC2_SEARCH_ICONS_REQUEST_ID,
    TAXONDB_DYNAMIC_SEARCH_ICONS_REQUEST_ID
} from '../settings'
import Loader from '../utils/loader'
import { setField } from '../actions/forms'
import I18nPathTranslator from "./I18nPathTranslator";
import SEO from "./SEO";
import RedirectLoginWithReturn from "./access/RedirectLoginWithReturn";

const TaxonDBContainer = props => {

    const { t } = useTranslation([ 'taxondb', 'paths' ])

    return (
        <Suspense fallback={<Loader />}>
            <I18nPathTranslator {...props} pathEN={TAXONDB_PAGE_URL_EN} pathPL={TAXONDB_PAGE_URL_PL} />
            <SEO title={t('taxondb-form-title')} />
            <div>
                {props.loadingUser && <Loader />}
                {!props.loadingUser && props.user && !props.user.data &&
                    <RedirectLoginWithReturn lang={props.lang} returnUrl={props.lang ? t("paths:taxonDB", {lng: props.lang}) : t("paths:taxonDB")} />
                }
                {props.user && props.user.data &&
                        <>
                            <Container>
                                <Row>
                                    <Col className="mt-3 mb-4"><h1>{t('taxondb-form-title')}</h1></Col>
                                </Row>
                            </Container>
                            <Container>
                                <Tabs defaultActiveKey={props.isDynamic} activeKey={props.isDynamic} onSelect={props.setIsDynamic} className="nav-tabs-search">
                                    <Tab eventKey="0" title={t('simple')}>
                                        {props.isDynamic === "0" &&
                                            <>
                                                <SearchForm></SearchForm>
                                                <ResultsContainer
                                                    iconsRequestId={TAXONDB_SEARCH_ICONS_REQUEST_ID}
                                                    genusRequestId={TAXONDB_SEARCH_GENUS_REQUEST_ID}
                                                    speciesRequestId={TAXONDB_SEARCH_SPECIES_REQUEST_ID}
                                                    searchRequestId={TAXONDB_SEARCH_REQUEST_ID}
                                                    tabId={TAXON_RESULTS_ACTIVE_TAB_FIELD_NAME}
                                                ></ResultsContainer>
                                            </>
                                        }
                                    </Tab>
                                    <Tab eventKey="2" title={t('extended')}>
                                        {props.isDynamic === "2" &&
                                            <>
                                                <DynamicSearchForm2></DynamicSearchForm2>
                                                <ResultsContainer
                                                    iconsRequestId={TAXONDB_DYNAMIC2_SEARCH_ICONS_REQUEST_ID}
                                                    genusRequestId={TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID}
                                                    speciesRequestId={TAXONDB_DYNAMIC2_SEARCH_SPECIES_REQUEST_ID}
                                                    searchRequestId={TAXONDB_DYNAMIC2_SEARCH_REQUEST_ID}
                                                    tabId={TAXON_DYNAMIC2_RESULTS_ACTIVE_TAB_FIELD_NAME}
                                                ></ResultsContainer>
                                            </>
                                        }
                                    </Tab>
                                    <Tab eventKey="1" title={t('advanced')}>
                                        {props.isDynamic === "1" &&
                                            <>
                                                <DynamicSearchForm></DynamicSearchForm>
                                                <ResultsContainer
                                                    iconsRequestId={TAXONDB_DYNAMIC_SEARCH_ICONS_REQUEST_ID}
                                                    genusRequestId={TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID}
                                                    speciesRequestId={TAXONDB_DYNAMIC_SEARCH_SPECIES_REQUEST_ID}
                                                    searchRequestId={TAXONDB_DYNAMIC_SEARCH_REQUEST_ID}
                                                    tabId={TAXON_DYNAMIC_RESULTS_ACTIVE_TAB_FIELD_NAME}
                                                ></ResultsContainer>
                                            </>
                                        }
                                    </Tab>
                                </Tabs>
                            </Container>
                        </>
                    }
                    {/* {props.user && props.user.data && props.user.data.gui === USER_GUI_DEFAULT_CONST &&
                        <Container>
                            <div className="mt-2">
                                <SimpleSearchForm
                                    genusRequestId={TAXONDB_DEFAULT_SEARCH_GENUS_REQUEST_ID}
                                    speciesRequestId={TAXONDB_DEFAULT_SEARCH_SPECIES_REQUEST_ID}
                                    searchRequestId={TAXONDB_DEFAULT_SEARCH_REQUEST_ID}
                                    tabId={TAXON_DEFAULT_RESULTS_ACTIVE_TAB_FIELD_NAME}
                                ></SimpleSearchForm>
                            </div>
                            <div>
                                <SimpleResultsContainer
                                    genusRequestId={TAXONDB_DEFAULT_SEARCH_GENUS_REQUEST_ID}
                                    speciesRequestId={TAXONDB_DEFAULT_SEARCH_SPECIES_REQUEST_ID}
                                    searchRequestId={TAXONDB_DEFAULT_SEARCH_REQUEST_ID}
                                    tabId={TAXON_DEFAULT_RESULTS_ACTIVE_TAB_FIELD_NAME}
                                ></SimpleResultsContainer>
                            </div>
                        </Container>
                    } */}
            </div>
        </Suspense>
    )
}

const mapStateToProps = state => ({
    loadingCount: isPending(TAXONDB_COUNT_REQUEST_ID, state),
    count: getOutput(TAXONDB_COUNT_REQUEST_ID, state),
    user: state.user,
    loadingUser: isPending('userInfo', state),
    isDynamic: getField(TAXON_SEARCH_IS_DYNAMIC, TAXON_SEARCH_IS_DYNAMIC, state, "0")
})

const mapDispatchToProps = dispatch => ({
    setIsDynamic: value => dispatch(setField(TAXON_SEARCH_IS_DYNAMIC, TAXON_SEARCH_IS_DYNAMIC, value)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TaxonDBContainer)

