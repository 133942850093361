import React, { useEffect } from "react";
import LoginForm from "./LoginForm"
import { connect } from 'react-redux'
import { Redirect } from "react-router-dom"
import * as actions from '../actions/user'
import I18nPathTranslator from "./I18nPathTranslator";
import { LOGIN_PAGE_URL_EN, LOGIN_PAGE_URL_PL } from "../settings";
import { Suspense } from "react";
import Loader from "../utils/loader";

const LoginContainer = props => {
    useEffect(() => props.clearReturn, [ props.clearReturn ])

    return (
        <div>
            <I18nPathTranslator {...props} pathEN={LOGIN_PAGE_URL_EN} pathPL={LOGIN_PAGE_URL_PL} />
            <Suspense fallback={<Loader />}>
                <LoginForm></LoginForm>
            </Suspense>
            {props.user != null && !props.return &&
                <Redirect push to="/"></Redirect>
            }
            {props.user != null && props.return &&

                <Redirect push to={props.return}></Redirect>
            }
        </div>
    )
}

const mapStateToProps = state => ({
    user: state.user ? state.user.data : null,
    return: state.user ? state.user.return : null,
})

const mapDispatchToProps = dispatch => ({
    clearReturn: url => dispatch(actions.setReturn(null)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginContainer)

