import React from "react"
import { Spinner, Container } from "react-bootstrap";
import {
    TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID,
    TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID,
    TAXONDB_SEARCH_GENUS_REQUEST_ID,
    TAXON_MAP_GROUPING_COLOR_ARRAY,
    TAXON_MAP_GROUPING_SHAPE_ARRAY,
} from "../../../../settings"
import L from 'leaflet';
import "leaflet-pixi-overlay" 
import * as PIXI from 'pixi.js'
import ReactDOMServer from 'react-dom/server';
import MapPopupContent from './../../MapPopupContent'
import axios from "axios"
import * as notify from '../../../../utils/notify'
import { popupWithLabels } from "../popupWithLabels";


export const groupingMapSearch = (ref, props, i18n, t) => {

    const { getDetails, collectionTypes, closeSlidebar, groupingColorField, groupingShapeField, pixiMarkerSize, setGroupingShapeLegendShown, setGroupingColorLegendShown, langi18n, mapLabels } = {...props,  lang: t, langi18n: i18n}

    const source = axios.CancelToken.source()        
    const DEFAULT_SHAPE_ICON = "plus_solid"
    const DEFAULT_COLOR_ICON = 0x686868
    const TAXONS_LAYER_NAME_CONST = 'ancClasteredTaxons'


    const getFilterId = () => {
        let id
        if (props.isDynamic === "0"){
            id = TAXONDB_SEARCH_GENUS_REQUEST_ID
        }
        if (props.isDynamic === "2"){
            id = TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID
        }
        if (props.isDynamic === "1"){
            id = TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID
        }
        return id
    }

    const selectColorAndShapeIconForItem = (groupingShapeField, groupingColorField, item, respond) => {
        let itemShape, itemColor, data
        if(groupingShapeField && groupingColorField){
            itemShape = respond.shapeItems.has(item[3]) ? respond.shapeItems.get(item[3]) : DEFAULT_SHAPE_ICON
            itemColor = respond.colorItems.has(item[4]) ? respond.colorItems.get(item[4]) : DEFAULT_COLOR_ICON
            data = {
                itemShape: itemShape,
                itemColor: itemColor
            }
        }
        else if (groupingShapeField && !groupingColorField){
            itemShape = respond.shapeItems.has(item[3]) ? respond.shapeItems.get(item[3]) : DEFAULT_SHAPE_ICON
            itemColor = DEFAULT_COLOR_ICON
            data = {
                itemShape: itemShape,
                itemColor: itemColor
            }
        }
        else if (!groupingShapeField && groupingColorField){
            itemColor = respond.colorItems.has(item[3]) ? respond.colorItems.get(item[3]) : DEFAULT_COLOR_ICON
            itemShape = DEFAULT_SHAPE_ICON
            data = {
                itemShape: itemShape,
                itemColor: itemColor
            }
        }
        else if (!groupingShapeField && !groupingColorField){
            itemShape = DEFAULT_SHAPE_ICON
            itemColor = DEFAULT_COLOR_ICON
            data = {
                itemShape: itemShape,
                itemColor: itemColor
            }
        }
       return data
    }

    const resizeIcon = (scale) => {
        return 1/(scale*pixiMarkerSize)
    }

    const loadIcons = loader => {
        loader.add('star_solid', '/images/font-awesome/star-solid.png');
        loader.add('star_regular', '/images/font-awesome/star-regular.png');
        loader.add('square_solid', '/images/font-awesome/square-solid.png');
        loader.add('square_regular', '/images/font-awesome/square-regular.png');
        loader.add('times_solid', '/images/font-awesome/times-solid.png');
        loader.add('thumbtack_solid', '/images/font-awesome/thumbtack-solid.png');
        loader.add('map_marker', '/images/font-awesome/map-marker-solid.png');
        loader.add('map_pin', '/images/font-awesome/map-pin-solid.png');
        loader.add('circle_regular', '/images/font-awesome/circle-regular.png');
        loader.add('circle_solid', '/images/font-awesome/circle-solid.png');
        loader.add('cube_solid', '/images/font-awesome/cube-solid.png');
        loader.add('bookmark_solid', '/images/font-awesome/bookmark-solid.png');
        loader.add('asterisk_solid', '/images/font-awesome/asterisk-solid.png');
        loader.add('plus_solid', '/images/font-awesome/plus-solid.png');
    }

    /* Anchor values in Pixi.js
        (0,0) - left top
        (1,1) - right bottom
        (0.5,0.5) - center
    */
        const getIconTexture = (resources, textureName) => {
            let markerTexture, marker;
            switch(textureName){
                case 'star_regular':
                    markerTexture = resources.star_regular.texture
                    marker = new PIXI.Sprite(markerTexture);
                    marker.anchor.set(0.5, 0.5);
                    break
                case 'star_solid':
                    markerTexture = resources.star_solid.texture
                    marker = new PIXI.Sprite(markerTexture);
                    marker.anchor.set(0.5, 0.5);
                    break
                case 'square_solid':
                    markerTexture = resources.square_solid.texture
                    marker = new PIXI.Sprite(markerTexture);
                    marker.anchor.set(0.5, 0.5);
                    break
                case 'square_regular': 
                    markerTexture = resources.square_regular.texture
                    marker = new PIXI.Sprite(markerTexture)
                    marker.anchor.set(0.5, 0.5);
                    break
                case 'times_solid':
                    markerTexture = resources.times_solid.texture
                    marker = new PIXI.Sprite(markerTexture);
                    marker.anchor.set(0.5, 0.5);
                    break
                case 'thumbtack_solid':
                    markerTexture = resources.thumbtack_solid.texture
                    marker = new PIXI.Sprite(markerTexture);
                    marker.anchor.set(0.5, 1);
                    break
                case 'map_marker':
                    markerTexture = resources.map_marker.texture
                    marker = new PIXI.Sprite(markerTexture);
                    marker.anchor.set(0.5, 1);
                    break
                case 'map_pin':
                    markerTexture = resources.map_pin.texture
                    marker = new PIXI.Sprite(markerTexture);
                    marker.anchor.set(0.5, 1);
                    break
                case 'circle_solid':
                    markerTexture = resources.circle_solid.texture
                    marker = new PIXI.Sprite(markerTexture)
                    marker.anchor.set(0.5, 0.5);
                    break
                case 'circle_regular':
                    markerTexture = resources.circle_regular.texture
                    marker = new PIXI.Sprite(markerTexture)
                    marker.anchor.set(0.5, 0.5);
                    break
                case 'cube_solid':
                    markerTexture = resources.cube_solid.texture
                    marker = new PIXI.Sprite(markerTexture)
                    marker.anchor.set(0.5, 0.5);
                    break
                case 'bookmark_solid':
                    markerTexture = resources.bookmark_solid.texture
                    marker = new PIXI.Sprite(markerTexture)
                    marker.anchor.set(0.5, 1);
                    break
                case 'asterisk_solid':
                    markerTexture = resources.asterisk_solid.texture
                    marker = new PIXI.Sprite(markerTexture)
                    marker.anchor.set(0.5, 0.5);
                    break
                case 'plus_solid':
                    markerTexture = resources.plus_solid.texture
                    marker = new PIXI.Sprite(markerTexture)
                    marker.anchor.set(0.5, 0.5);
                    break
                default:
                    markerTexture = resources.map_marker.texture
                    marker = new PIXI.Sprite(markerTexture);
                    marker.anchor.set(0.5, 1);
                    break
            }
            return marker
        }

    const prepareNewPixiContainer = () => {
        try{
            ref.pixiContainer.current.destroy()
            ref.mapRef.current.removeLayer(ref.pixiOverlayRef.current)
        }
        catch(e){
            // not created yet
        }
        return new PIXI.Container();
    }

    const prepareLegendsForFetchedData = (respond) => {        
        if(respond.shapeItems.size > 0){
            renderShapeLegend(respond.shapeItems)
            setGroupingShapeLegendShown(true)
        }
        else{
            hideShapeLegend()
            setGroupingShapeLegendShown(false)
        }

        if(respond.colorItems.size > 0  ){
            renderColorLegend(respond.colorItems)
            setGroupingColorLegendShown(true)
        }
        else{
            hideColorLegend()
            setGroupingColorLegendShown(false)
        }
    }

    const prepareMapBeforeSearchRequest = () => {
        hideColorLegend()
        hideShapeLegend()
        try{
            ref.taxonsRef.current.eachLayer(function (layer) {
                ref.taxonsRef.current.removeLayer(layer);
            })
            ref.mapRef.current.eachLayer(function(layer){
                if (layer.name === TAXONS_LAYER_NAME_CONST){
                    ref.mapRef.current.removeLayer(layer);
                }
            });
        }
        catch(e){
            //refs undefined
        }
    }

    const renderShapeLegend = (items) => {
        hideShapeLegend()
        ref.shapeLegend.current = L.control({ position: "bottomright" });
        ref.shapeLegend.current.onAdd = function() {
            var div = L.DomUtil.create("div", "legend");
            div.style.backgroundColor = 'white';
            div.style.padding = '10px';
            div.style.width = '200px'
            let itemsArray = Array.from(items)
            itemsArray.forEach((item) => {
                div.innerHTML += ReactDOMServer.renderToString(
                    <div>
                        <i 
                            className={`fa ${translateIconName(item[1])}`}
                            style={{ color: 'black' }}
                        />
                        <i style={{background: "white", fontSize: 20, marginLeft: 10}} />
                        <span>
                            <b>{item[0]}</b>
                        </span>
                    </div>
                )
            })
            div.innerHTML += ReactDOMServer.renderToString(
                <div>
                    <i 
                        className={`fa ${translateIconName(DEFAULT_SHAPE_ICON)}`}
                        style={{ color: 'black' }}
                    />
                    <i style={{background: "white", fontSize: 20, marginLeft: 10}} />
                    <span>
                        <b>{t("map:others")}</b>
                    </span>
                </div>
            )
            return div
        }
        ref.shapeLegend.current.addTo(ref.mapRef.current);
        setGroupingShapeLegendShown(true)
    }

    const translateIconName = textureName => {
        let marker
        switch(textureName){
            case 'star_regular':
                marker = "fa-star-o"
                break
            case 'star_solid':
                marker = "fa-star"
                break
            case 'square_solid':
                marker = "fa-square"
                break
            case 'square_regular': 
                marker = "fa-square-o"
                break
            case 'times_solid':
                marker = "fa-times"
                break
            case 'thumbtack_solid':
                marker = "fa-thumb-tack"
                break
            case 'map_marker':
                marker = "fa-map-marker"
                break
            case 'map_pin':
                marker = "fa-map-pin"
                break
            case 'circle_solid':
                marker = "fa-circle"
                break
            case 'circle_regular':
                marker = "fa-circle-o"
                break
            case 'cube_solid':
                marker = "fa-cube"
                break
            case 'bookmark_solid':
                marker = "fa-bookmark"
                break
            case 'asterisk_solid':
                marker = "fa-asterisk"
                break
            case 'plus_solid':
                marker = "fa-plus"
                break
            default:
                marker = "fa-map-marker"
                break
        }
        return marker
    }

    const renderColorLegend = (items) => {
        hideColorLegend()
        ref.colorLegend.current = L.control({ position: "bottomleft" });
        ref.colorLegend.current.onAdd = function() {
            var div = L.DomUtil.create("div", "legend");
            div.style.backgroundColor = 'white';
            div.style.padding = '10px';
            div.style.width = '200px'
            let itemsArray = Array.from(items)
            itemsArray.forEach((item) => {
                div.innerHTML += ReactDOMServer.renderToString(
                    <div>
                        <div 
                            style={{ 
                                "color": "black",
                                "backgroundColor": `#${item[1].toString(16)}`,
                                "border": 'ridge',
                                "marginTop": "5px",
                                "minWidth": '18px',
                                "minHeight": "18px" ,
                                "display": "inline-block"
                            }}
                        />
                       <span style={{marginLeft: 10}}>
                            <b>{item[0]}</b>
                        </span>
                    </div>
                )
            })
            div.innerHTML += ReactDOMServer.renderToString(
                <div>
                    <div 
                        style={{ 
                            "color": "black",
                            "backgroundColor": `#${DEFAULT_COLOR_ICON.toString(16)}`,
                            "border": 'ridge',
                            "marginTop": "5px",
                            "minWidth": '18px',
                            "minHeight": "18px" ,
                            "display": "inline-block"
                        }}
                    />
                    <span style={{marginLeft: 10}}>
                        <b>{t("map:others")}</b>
                    </span>
                </div>
            )
            return div
        }
        ref.colorLegend.current.addTo(ref.mapRef.current)
        setGroupingColorLegendShown(true)
    } 

    const hideShapeLegend = () => {
        try{
            if (ref.shapeLegend.current){
                ref.shapeLegend.current.remove()
                setGroupingShapeLegendShown(false)
            }
        }
        catch(e){
            //ignore
        }
    }

    const hideColorLegend = () => {
        try{
            if (ref.colorLegend.current){
                ref.colorLegend.current.remove()
                setGroupingColorLegendShown(false)
            }
        }
        catch(e){
            //ignore
        }
    }

    closeSlidebar()
    prepareMapBeforeSearchRequest()
    let id = getFilterId()
    let aggFields = []
    if(groupingShapeField){
        aggFields.push({
            value: groupingShapeField,
            type: "SHAPE_FIELD"
        })
    }
    if(groupingColorField){
        aggFields.push({
            value: groupingColorField,
            type: "COLOR_FIELD"
        })
    }

    var mapLoadingBlur = L.control.loader()
    mapLoadingBlur.addTo(ref.mapRef.current);

    var loader = new PIXI.loaders.Loader();
    loadIcons(loader)
    ref.pixiContainer.current = prepareNewPixiContainer()

    return props.runMapGroupingSearch(id, aggFields, TAXON_MAP_GROUPING_COLOR_ARRAY, TAXON_MAP_GROUPING_SHAPE_ARRAY)
    .then(respond => {
        props.searchMapData({
            filter: respond.value.filter,
            field1: groupingShapeField,
            field2: groupingColorField
        }, source)
        .then(res => {
            if (res.data && (res.data.length > 0)){     
                loader.load(function(loader, resources) {
                    var firstDraw = true;
                    var prevZoom;
                    let minLat = 1000;
                    let minLon = 1000;
                    let maxLat = 0;
                    let maxLon = 0;
                    res.data.forEach(coordinates => {
                        let icon = selectColorAndShapeIconForItem(groupingShapeField, groupingColorField, coordinates, respond.value)
                        var marker = getIconTexture(resources, icon.itemShape)
                        marker.tint = icon.itemColor
                        marker.x0 = coordinates[0];
                        marker.y0 = coordinates[1];
                        marker.coordinates = {lon: coordinates[1], lat: coordinates[0]}    
                        marker.interactive = true;
                        marker.cursor = 'pointer'
                        marker.click = () => {
                                const popupLoading = <Container className="d-flex flex-column mt-2">
                                <div className="align-self-center">
                                    <Spinner animation="border" role="status" />
                                </div>
                            </Container>
                            const content = ReactDOMServer.renderToString(popupLoading)
                            marker.popup = L.popup()
                                .setLatLng([coordinates[0], coordinates[1]])
                                .setContent(content)
                                .openOn(ref.mapRef.current);
                            getDetails(coordinates[2])
                            .then(data => {
                                if (mapLabels.length > 0){
                                    const theLabel = ReactDOMServer.renderToString(popupWithLabels(mapLabels, data, t, collectionTypes, langi18n))
                                    marker.popup.setContent(theLabel)
                                }
                                else {
                                    const temp = <MapPopupContent requestId={""} data={data} t={t} i18n={langi18n} collectionTypes={collectionTypes}></MapPopupContent>
                                    const content = ReactDOMServer.renderToString(temp)
                                    marker.popup.setContent(content)
                                }
                            })
                        }
                        ref.pixiContainer.current.addChild(marker);
                        marker.legend = coordinates.id;
                        minLat = Math.min(minLat, coordinates[0])
                        minLon = Math.min(minLon, coordinates[1])
                        maxLat = Math.max(maxLat, coordinates[0])
                        maxLon = Math.max(maxLon, coordinates[1])
                    })
                    
                    ref.mapRef.current.fitBounds([[minLat, minLon], [maxLat, maxLon]])

                    ref.pixiOverlayRef.current = L.pixiOverlay(function(utils) {
                        var zoom = utils.getMap().getZoom();
                        var container = utils.getContainer();
                        var renderer = utils.getRenderer();
                        var project = utils.latLngToLayerPoint;
                        var scale = utils.getScale();

                        if (firstDraw) {
                            container.children.forEach(sprite => {
                                let projetctedCords = project(sprite.coordinates);
                                sprite.x = projetctedCords.x
                                sprite.y = projetctedCords.y
                            })

                            utils.getMap().on('click', function(e) {
                                var interaction = utils.getRenderer().plugins.interaction;
                                var pointerEvent = e.originalEvent;
                                var pixiPoint = new PIXI.Point();
                                interaction.mapPositionToPoint(pixiPoint, pointerEvent.clientX, pointerEvent.clientY);
                                var target = interaction.hitTest(pixiPoint, container);
                                if (target && target.popup) {
                                    target.popup.openOn(ref.mapRef.current);
                                }
                            });
                        }
        
                        if (firstDraw || prevZoom !== zoom) {
                            let markerScale = resizeIcon(scale) 
                            container.children.forEach(marker => {
                                marker.scale.set(markerScale);
                            })
                        }
        
                        firstDraw = false;
                        prevZoom = zoom;
                        renderer.render(container);
                    }, ref.pixiContainer.current, { preserveDrawingBuffer: true });

                    ref.pixiOverlayRef.current.addTo(ref.mapRef.current);
                    mapLoadingBlur.hide()
                })
                prepareLegendsForFetchedData(respond.value) 
                }
            else{
                notify.warning(t('map:no-results-notify'))
            }
        })
    })
    .catch(err  => {
        console.log(err)
    })
}
