export const countries = [
    {
        "Państwo EN": "Afganistan",
        "Państwo PL": "Afganistan"
    },
    {
        "Państwo EN": "Albania",
        "Państwo PL": "Albania"
    },
    {
        "Państwo EN": "Algeria",
        "Państwo PL": "Algieria"
    },
    {
        "Państwo EN": "Andorra",
        "Państwo PL": "Andora"
    },
    {
        "Państwo EN": "Angola",
        "Państwo PL": "Angola"
    },
    {
        "Państwo EN": "Anguilla",
        "Państwo PL": "Anguilla"
    },
    {
        "Państwo EN": "Antarctica",
        "Państwo PL": "Antarktyda"
    },
    {
        "Państwo EN": "Antigua and Barbuda",
        "Państwo PL": "Antigua i Barbuda"
    },
    {
        "Państwo EN": "Netherlands Antilles",
        "Państwo PL": "Antyle Holenderskie"
    },
    {
        "Państwo EN": "Saudi Arabia",
        "Państwo PL": "Arabia Saudyjska"
    },
    {
        "Państwo EN": "Argentina",
        "Państwo PL": "Argentyna"
    },
    {
        "Państwo EN": "Armenia",
        "Państwo PL": "Armenia"
    },
    {
        "Państwo EN": "Aruba",
        "Państwo PL": "Aruba"
    },
    {
        "Państwo EN": "Australia",
        "Państwo PL": "Australia"
    },
    {
        "Państwo EN": "Austria",
        "Państwo PL": "Austria"
    },
    {
        "Państwo EN": "Azerbaijan",
        "Państwo PL": "Azerbejdżan"
    },
    {
        "Państwo EN": "Bahamas",
        "Państwo PL": "Bahamy"
    },
    {
        "Państwo EN": "Bahrain",
        "Państwo PL": "Bahrajn"
    },
    {
        "Państwo EN": "Bangladesh",
        "Państwo PL": "Bangladesz"
    },
    {
        "Państwo EN": "Barbados",
        "Państwo PL": "Barbados"
    },
    {
        "Państwo EN": "Belgium",
        "Państwo PL": "Belgia"
    },
    {
        "Państwo EN": "Belize",
        "Państwo PL": "Belize"
    },
    {
        "Państwo EN": "Benin",
        "Państwo PL": "Benin"
    },
    {
        "Państwo EN": "Bermuda",
        "Państwo PL": "Bermudy"
    },
    {
        "Państwo EN": "Bhutan",
        "Państwo PL": "Bhutan"
    },
    {
        "Państwo EN": "Belarus",
        "Państwo PL": "Białoruś"
    },
    {
        "Państwo EN": "Burma",
        "Państwo PL": "Birma"
    },
    {
        "Państwo EN": "Bolivia",
        "Państwo PL": "Boliwia"
    },
    {
        "Państwo EN": "Bosnia and Herzegovina",
        "Państwo PL": "Bośnia i Hercegowina"
    },
    {
        "Państwo EN": "Botswana",
        "Państwo PL": "Botswana"
    },
    {
        "Państwo EN": "Brazil",
        "Państwo PL": "Brazylia"
    },
    {
        "Państwo EN": "Brunei Darussalam",
        "Państwo PL": "Brunei Darussalam"
    },
    {
        "Państwo EN": "British Indian Ocean Territory",
        "Państwo PL": "Brytyjskie Terytorium Oceanu Indyjskiego"
    },
    {
        "Państwo EN": "British Virgin Islands",
        "Państwo PL": "Brytyjskie Wyspy Dziewicze"
    },
    {
        "Państwo EN": "Bulgaria",
        "Państwo PL": "Bułgaria"
    },
    {
        "Państwo EN": "Burkina Faso",
        "Państwo PL": "Burkina Faso"
    },
    {
        "Państwo EN": "Burundi",
        "Państwo PL": "Burundi"
    },
    {
        "Państwo EN": "The former Yugoslav Republic of Macedonia",
        "Państwo PL": "Była Jugosłowiańska Republika Macedonii"
    },
    {
        "Państwo EN": "Chile",
        "Państwo PL": "Chile"
    },
    {
        "Państwo EN": "China",
        "Państwo PL": "Chiny"
    },
    {
        "Państwo EN": "Croatia",
        "Państwo PL": "Chorwacja"
    },
    {
        "Państwo EN": "Cyprus",
        "Państwo PL": "Cypr"
    },
    {
        "Państwo EN": "Chad",
        "Państwo PL": "Czad"
    },
    {
        "Państwo EN": "Montenegro",
        "Państwo PL": "Czarnogóra"
    },
    {
        "Państwo EN": "United States Minor Outlying Islands",
        "Państwo PL": "Dalekie Wyspy Mniejsze Stanów Zjednoczonych"
    },
    {
        "Państwo EN": "Denmark",
        "Państwo PL": "Dania"
    },
    {
        "Państwo EN": "Democratic Republic of the Congo",
        "Państwo PL": "Demokratyczna Republika Konga"
    },
    {
        "Państwo EN": "Dominica",
        "Państwo PL": "Dominika"
    },
    {
        "Państwo EN": "Djibouti",
        "Państwo PL": "Dżibuti"
    },
    {
        "Państwo EN": "Egypt",
        "Państwo PL": "Egipt"
    },
    {
        "Państwo EN": "Ecuador",
        "Państwo PL": "Ekwador"
    },
    {
        "Państwo EN": "Eritrea",
        "Państwo PL": "Erytrea"
    },
    {
        "Państwo EN": "Estonia",
        "Państwo PL": "Estonia"
    },
    {
        "Państwo EN": "Ethiopia",
        "Państwo PL": "Etiopia"
    },
    {
        "Państwo EN": "Falkland Islands (Malvinas)",
        "Państwo PL": "Falklandy (Malwiny)"
    },
    {
        "Państwo EN": "Fiji",
        "Państwo PL": "Fidżi"
    },
    {
        "Państwo EN": "Philippines",
        "Państwo PL": "Filipiny"
    },
    {
        "Państwo EN": "Finland",
        "Państwo PL": "Finlandia"
    },
    {
        "Państwo EN": "France",
        "Państwo PL": "Francja"
    },
    {
        "Państwo EN": "French Southern and Antarctic Lands",
        "Państwo PL": "Francuskie Ziemie Południowe i Antarktyczne"
    },
    {
        "Państwo EN": "Gabon",
        "Państwo PL": "Gabon"
    },
    {
        "Państwo EN": "Gambia",
        "Państwo PL": "Gambia"
    },
    {
        "Państwo EN": "South Georgia South Sandwich Islands",
        "Państwo PL": "Georgia Południowa i Sandwich Południowy"
    },
    {
        "Państwo EN": "Ghana",
        "Państwo PL": "Ghana"
    },
    {
        "Państwo EN": "Gibraltar",
        "Państwo PL": "Gibraltar"
    },
    {
        "Państwo EN": "Greece",
        "Państwo PL": "Grecja"
    },
    {
        "Państwo EN": "Grenada",
        "Państwo PL": "Grenada"
    },
    {
        "Państwo EN": "Greenland",
        "Państwo PL": "Grenlandia"
    },
    {
        "Państwo EN": "Georgia",
        "Państwo PL": "Gruzja"
    },
    {
        "Państwo EN": "Guam",
        "Państwo PL": "Guam"
    },
    {
        "Państwo EN": "Guernsey",
        "Państwo PL": "Guernsey"
    },
    {
        "Państwo EN": "Guyana",
        "Państwo PL": "Gujana"
    },
    {
        "Państwo EN": "French Guiana",
        "Państwo PL": "Gujana Francuska"
    },
    {
        "Państwo EN": "Guadeloupe",
        "Państwo PL": "Gwadelupa"
    },
    {
        "Państwo EN": "Guatemala",
        "Państwo PL": "Gwatemala"
    },
    {
        "Państwo EN": "Guinea",
        "Państwo PL": "Gwinea"
    },
    {
        "Państwo EN": "Guinea-Bissau",
        "Państwo PL": "Gwinea Bissau"
    },
    {
        "Państwo EN": "Equatorial Guinea",
        "Państwo PL": "Gwinea Równikowa"
    },
    {
        "Państwo EN": "Haiti",
        "Państwo PL": "Haiti"
    },
    {
        "Państwo EN": "Spain",
        "Państwo PL": "Hiszpania"
    },
    {
        "Państwo EN": "Netherlands",
        "Państwo PL": "Holandia"
    },
    {
        "Państwo EN": "Honduras",
        "Państwo PL": "Honduras"
    },
    {
        "Państwo EN": "Hong Kong",
        "Państwo PL": "Hongkong"
    },
    {
        "Państwo EN": "India",
        "Państwo PL": "Indie"
    },
    {
        "Państwo EN": "Indonesia",
        "Państwo PL": "Indonezja"
    },
    {
        "Państwo EN": "Iraq",
        "Państwo PL": "Irak"
    },
    {
        "Państwo EN": "Iran (Islamic Republic of)",
        "Państwo PL": "Iran (Islamska Republika)"
    },
    {
        "Państwo EN": "Ireland",
        "Państwo PL": "Irlandia"
    },
    {
        "Państwo EN": "Iceland",
        "Państwo PL": "Islandia"
    },
    {
        "Państwo EN": "Israel",
        "Państwo PL": "Izrael"
    },
    {
        "Państwo EN": "Jamaica",
        "Państwo PL": "Jamajka"
    },
    {
        "Państwo EN": "Japan",
        "Państwo PL": "Japonia"
    },
    {
        "Państwo EN": "Yemen",
        "Państwo PL": "Jemen"
    },
    {
        "Państwo EN": "Jersey",
        "Państwo PL": "Jersey"
    },
    {
        "Państwo EN": "Jordan",
        "Państwo PL": "Jordania"
    },
    {
        "Państwo EN": "Cayman Islands",
        "Państwo PL": "Kajmany"
    },
    {
        "Państwo EN": "Cambodia",
        "Państwo PL": "Kambodża"
    },
    {
        "Państwo EN": "Cameroon",
        "Państwo PL": "Kamerun"
    },
    {
        "Państwo EN": "Canada",
        "Państwo PL": "Kanada"
    },
    {
        "Państwo EN": "Qatar",
        "Państwo PL": "Katar"
    },
    {
        "Państwo EN": "Kazakhstan",
        "Państwo PL": "Kazachstan"
    },
    {
        "Państwo EN": "Kenya",
        "Państwo PL": "Kenia"
    },
    {
        "Państwo EN": "Kyrgyzstan",
        "Państwo PL": "Kirgistan"
    },
    {
        "Państwo EN": "Kiribati",
        "Państwo PL": "Kiribati"
    },
    {
        "Państwo EN": "Colombia",
        "Państwo PL": "Kolumbia"
    },
    {
        "Państwo EN": "Comoros",
        "Państwo PL": "Komory"
    },
    {
        "Państwo EN": "Congo",
        "Państwo PL": "Kongo"
    },
    {
        "Państwo EN": "Korea, Democratic People's Republic of",
        "Państwo PL": "Koreańska Republika Ludowo-Demokratyczna"
    },
    {
        "Państwo EN": "Costa Rica",
        "Państwo PL": "Kostaryka"
    },
    {
        "Państwo EN": "Cuba",
        "Państwo PL": "Kuba"
    },
    {
        "Państwo EN": "Kuwait",
        "Państwo PL": "Kuwejt"
    },
    {
        "Państwo EN": "Lao People's Democratic Republic",
        "Państwo PL": "Laotańska Republika Ludowo-Demokratyczna"
    },
    {
        "Państwo EN": "Lesotho",
        "Państwo PL": "Lesotho"
    },
    {
        "Państwo EN": "Lebanon",
        "Państwo PL": "Liban"
    },
    {
        "Państwo EN": "Liberia",
        "Państwo PL": "Liberia"
    },
    {
        "Państwo EN": "Libyan Arab Jamahiriya",
        "Państwo PL": "Libijska Arabska Dżamahirija"
    },
    {
        "Państwo EN": "Liechtenstein",
        "Państwo PL": "Liechtenstein"
    },
    {
        "Państwo EN": "Lithuania",
        "Państwo PL": "Litwa"
    },
    {
        "Państwo EN": "Luxembourg",
        "Państwo PL": "Luksemburg"
    },
    {
        "Państwo EN": "Latvia",
        "Państwo PL": "Łotwa"
    },
    {
        "Państwo EN": "Madagascar",
        "Państwo PL": "Madagaskar"
    },
    {
        "Państwo EN": "Mayotte",
        "Państwo PL": "Majotta"
    },
    {
        "Państwo EN": "Macau",
        "Państwo PL": "Makau"
    },
    {
        "Państwo EN": "Malawi",
        "Państwo PL": "Malawi"
    },
    {
        "Państwo EN": "Maldives",
        "Państwo PL": "Malediwy"
    },
    {
        "Państwo EN": "Malaysia",
        "Państwo PL": "Malezja"
    },
    {
        "Państwo EN": "Mali",
        "Państwo PL": "Mali"
    },
    {
        "Państwo EN": "Malta",
        "Państwo PL": "Malta"
    },
    {
        "Państwo EN": "Northern Mariana Islands",
        "Państwo PL": "Mariany Północne"
    },
    {
        "Państwo EN": "Morocco",
        "Państwo PL": "Maroko"
    },
    {
        "Państwo EN": "Martinique",
        "Państwo PL": "Martynika"
    },
    {
        "Państwo EN": "Mauritania",
        "Państwo PL": "Mauretania"
    },
    {
        "Państwo EN": "Mauritius",
        "Państwo PL": "Mauritius"
    },
    {
        "Państwo EN": "Mexico",
        "Państwo PL": "Meksyk"
    },
    {
        "Państwo EN": "Monaco",
        "Państwo PL": "Monako"
    },
    {
        "Państwo EN": "Mongolia",
        "Państwo PL": "Mongolia"
    },
    {
        "Państwo EN": "Montserrat",
        "Państwo PL": "Montserrat"
    },
    {
        "Państwo EN": "Mozambique",
        "Państwo PL": "Mozambik"
    },
    {
        "Państwo EN": "Namibia",
        "Państwo PL": "Namibia"
    },
    {
        "Państwo EN": "Nauru",
        "Państwo PL": "Nauru"
    },
    {
        "Państwo EN": "Nepal",
        "Państwo PL": "Nepal"
    },
    {
        "Państwo EN": "Germany",
        "Państwo PL": "Niemcy"
    },
    {
        "Państwo EN": "Niger",
        "Państwo PL": "Niger"
    },
    {
        "Państwo EN": "Nigeria",
        "Państwo PL": "Nigeria"
    },
    {
        "Państwo EN": "Nicaragua",
        "Państwo PL": "Nikaragua"
    },
    {
        "Państwo EN": "Niue",
        "Państwo PL": "Niue"
    },
    {
        "Państwo EN": "Norway",
        "Państwo PL": "Norwegia"
    },
    {
        "Państwo EN": "New Caledonia",
        "Państwo PL": "Nowa Kaledonia"
    },
    {
        "Państwo EN": "New Zealand",
        "Państwo PL": "Nowa Zelandia"
    },
    {
        "Państwo EN": "Oman",
        "Państwo PL": "Oman"
    },
    {
        "Państwo EN": "Pakistan",
        "Państwo PL": "Pakistan"
    },
    {
        "Państwo EN": "Palau",
        "Państwo PL": "Palau"
    },
    {
        "Państwo EN": "Palestine",
        "Państwo PL": "Palestyna"
    },
    {
        "Państwo EN": "Panama",
        "Państwo PL": "Panama"
    },
    {
        "Państwo EN": "Papua New Guinea",
        "Państwo PL": "Papua Nowa Gwinea"
    },
    {
        "Państwo EN": "Paraguay",
        "Państwo PL": "Paragwaj"
    },
    {
        "Państwo EN": "Peru",
        "Państwo PL": "Peru"
    },
    {
        "Państwo EN": "French Polynesia",
        "Państwo PL": "Polinezja Francuska"
    },
    {
        "Państwo EN": "Poland",
        "Państwo PL": "Polska"
    },
    {
        "Państwo EN": "Puerto Rico",
        "Państwo PL": "Portoryko"
    },
    {
        "Państwo EN": "Portugal",
        "Państwo PL": "Portugalia"
    },
    {
        "Państwo EN": "Czech Republic",
        "Państwo PL": "Republika Czeska"
    },
    {
        "Państwo EN": "Dominican Republic",
        "Państwo PL": "Republika Dominikany"
    },
    {
        "Państwo EN": "Korea, Republic of",
        "Państwo PL": "Republika Korei"
    },
    {
        "Państwo EN": "Republic of Moldova",
        "Państwo PL": "Republika Mołdawii"
    },
    {
        "Państwo EN": "South Africa",
        "Państwo PL": "Republika Południowej Afryki"
    },
    {
        "Państwo EN": "Syrian Arab Republic",
        "Państwo PL": "Republika Syryjsko-Arabska"
    },
    {
        "Państwo EN": "Central African Republic",
        "Państwo PL": "Republika Środkowoafrykańska"
    },
    {
        "Państwo EN": "Reunion",
        "Państwo PL": "Reunion"
    },
    {
        "Państwo EN": "Russia",
        "Państwo PL": "Rosja"
    },
    {
        "Państwo EN": "Romania",
        "Państwo PL": "Rumunia"
    },
    {
        "Państwo EN": "Rwanda",
        "Państwo PL": "Rwanda"
    },
    {
        "Państwo EN": "Western Sahara",
        "Państwo PL": "Sahara Zachodnia"
    },
    {
        "Państwo EN": "Saint Kitts and Nevis",
        "Państwo PL": "Saint Kitts i Nevis"
    },
    {
        "Państwo EN": "Saint Lucia",
        "Państwo PL": "Saint Lucia"
    },
    {
        "Państwo EN": "Saint Pierre and Miquelon",
        "Państwo PL": "Saint Pierre i Miquelon"
    },
    {
        "Państwo EN": "Saint Vincent and the Grenadines",
        "Państwo PL": "Saint Vincent i Grenadyny"
    },
    {
        "Państwo EN": "El Salvador",
        "Państwo PL": "Salwador"
    },
    {
        "Państwo EN": "Samoa",
        "Państwo PL": "Samoa"
    },
    {
        "Państwo EN": "American Samoa",
        "Państwo PL": "Samoa Amerykańskie"
    },
    {
        "Państwo EN": "San Marino",
        "Państwo PL": "San Marino"
    },
    {
        "Państwo EN": "Senegal",
        "Państwo PL": "Senegal"
    },
    {
        "Państwo EN": "Serbia",
        "Państwo PL": "Serbia"
    },
    {
        "Państwo EN": "Seychelles",
        "Państwo PL": "Seszele"
    },
    {
        "Państwo EN": "Micronesia, Federated States of",
        "Państwo PL": "Sfederowane Stany Mikronezji"
    },
    {
        "Państwo EN": "Sierra Leone",
        "Państwo PL": "Sierra Leone"
    },
    {
        "Państwo EN": "Singapore",
        "Państwo PL": "Singapur"
    },
    {
        "Państwo EN": "Slovakia",
        "Państwo PL": "Słowacja"
    },
    {
        "Państwo EN": "Slovenia",
        "Państwo PL": "Słowenia"
    },
    {
        "Państwo EN": "Somalia",
        "Państwo PL": "Somalia"
    },
    {
        "Państwo EN": "Sri Lanka",
        "Państwo PL": "Sri Lanka"
    },
    {
        "Państwo EN": "United States",
        "Państwo PL": "Stany Zjednoczone"
    },
    {
        "Państwo EN": "Swaziland",
        "Państwo PL": "Suazi"
    },
    {
        "Państwo EN": "Sudan",
        "Państwo PL": "Sudan"
    },
    {
        "Państwo EN": "Suriname",
        "Państwo PL": "Surinam"
    },
    {
        "Państwo EN": "Svalbard",
        "Państwo PL": "Svalbard"
    },
    {
        "Państwo EN": "Switzerland",
        "Państwo PL": "Szwajcaria"
    },
    {
        "Państwo EN": "Sweden",
        "Państwo PL": "Szwecja"
    },
    {
        "Państwo EN": "Saint Helena",
        "Państwo PL": "Święta Helena"
    },
    {
        "Państwo EN": "Tajikistan",
        "Państwo PL": "Tadżykistan"
    },
    {
        "Państwo EN": "Thailand",
        "Państwo PL": "Tajlandia"
    },
    {
        "Państwo EN": "Taiwan",
        "Państwo PL": "Tajwan"
    },
    {
        "Państwo EN": "Timor-Leste",
        "Państwo PL": "Timor Wschodni"
    },
    {
        "Państwo EN": "Togo",
        "Państwo PL": "Togo"
    },
    {
        "Państwo EN": "Tokelau",
        "Państwo PL": "Tokelau"
    },
    {
        "Państwo EN": "Tonga",
        "Państwo PL": "Tonga"
    },
    {
        "Państwo EN": "Trinidad and Tobago",
        "Państwo PL": "Trynidad i Tobago"
    },
    {
        "Państwo EN": "Tunisia",
        "Państwo PL": "Tunezja"
    },
    {
        "Państwo EN": "Turkey",
        "Państwo PL": "Turcja"
    },
    {
        "Państwo EN": "Turkmenistan",
        "Państwo PL": "Turkmenia"
    },
    {
        "Państwo EN": "Tuvalu",
        "Państwo PL": "Tuvalu"
    },
    {
        "Państwo EN": "Uganda",
        "Państwo PL": "Uganda"
    },
    {
        "Państwo EN": "Ukraine",
        "Państwo PL": "Ukraina"
    },
    {
        "Państwo EN": "Uruguay",
        "Państwo PL": "Urugwaj"
    },
    {
        "Państwo EN": "Uzbekistan",
        "Państwo PL": "Uzbekistan"
    },
    {
        "Państwo EN": "Vanuatu",
        "Państwo PL": "Vanuatu"
    },
    {
        "Państwo EN": "Holy See (Vatican City)",
        "Państwo PL": "Watykan"
    },
    {
        "Państwo EN": "Venezuela",
        "Państwo PL": "Wenezuela"
    },
    {
        "Państwo EN": "Hungary",
        "Państwo PL": "Węgry"
    },
    {
        "Państwo EN": "Viet Nam",
        "Państwo PL": "Wietnam"
    },
    {
        "Państwo EN": "Italy",
        "Państwo PL": "Włochy"
    },
    {
        "Państwo EN": "Cote d'Ivoire",
        "Państwo PL": "Wybrzeże Kości Słoniowej"
    },
    {
        "Państwo EN": "Bouvet Island",
        "Państwo PL": "Wyspa Bouvet"
    },
    {
        "Państwo EN": "Christmas Island",
        "Państwo PL": "Wyspa Bożego Narodzenia"
    },
    {
        "Państwo EN": "Isle of Man",
        "Państwo PL": "Wyspa Man"
    },
    {
        "Państwo EN": "Norfolk Island",
        "Państwo PL": "Wyspa Norfolk"
    },
    {
        "Państwo EN": "Aland Islands",
        "Państwo PL": "Wyspy Alandzkie"
    },
    {
        "Państwo EN": "Cook Islands",
        "Państwo PL": "Wyspy Cooka"
    },
    {
        "Państwo EN": "United States Virgin Islands",
        "Państwo PL": "Wyspy Dziewicze Stanów Zjednoczonych"
    },
    {
        "Państwo EN": "Heard Island and McDonald Islands",
        "Państwo PL": "Wyspy Heard i McDonalda"
    },
    {
        "Państwo EN": "Cocos (Keeling) Islands",
        "Państwo PL": "Wyspy Kokosowe (Keelinga)"
    },
    {
        "Państwo EN": "Marshall Islands",
        "Państwo PL": "Wyspy Marshalla"
    },
    {
        "Państwo EN": "Faroe Islands",
        "Państwo PL": "Wyspy Owcze"
    },
    {
        "Państwo EN": "Pitcairn Islands",
        "Państwo PL": "Wyspy Pitcairn"
    },
    {
        "Państwo EN": "Solomon Islands",
        "Państwo PL": "Wyspy Salomona"
    },
    {
        "Państwo EN": "Sao Tome and Principe",
        "Państwo PL": "Wyspy Świętego Tomasza i Książęca"
    },
    {
        "Państwo EN": "Turks and Caicos Islands",
        "Państwo PL": "Wyspy Turks i Caicos"
    },
    {
        "Państwo EN": "Wallis and Futuna Islands",
        "Państwo PL": "Wyspy Wallis i Futuna"
    },
    {
        "Państwo EN": "Cape Verde",
        "Państwo PL": "Wyspy Zielonego Przylądka"
    },
    {
        "Państwo EN": "Zambia",
        "Państwo PL": "Zambia"
    },
    {
        "Państwo EN": "Zimbabwe",
        "Państwo PL": "Zimbabwe"
    },
    {
        "Państwo EN": "United Republic of Tanzania",
        "Państwo PL": "Zjednoczona Republika Tanzanii"
    },
    {
        "Państwo EN": "United Arab Emirates",
        "Państwo PL": "Zjednoczone Emiraty Arabskie"
    },
    {
        "Państwo EN": "United Kingdom",
        "Państwo PL": "Zjednoczone Królestwo Wielkiej Brytanii i Irlandii Północnej"
    },
]