import React, { Suspense } from "react";
import { connect } from 'react-redux'
import { Container } from "react-bootstrap"
import { isPending, getItems } from "../reducers";
import SimpleSearchForm from "./taxonDB/SimpleSearchForm"
import SimpleResultsContainer from "./taxonDB/SimpleResultsContainer"
import {
  TAXONDB_SIMPLE2_SEARCH_GENUS_REQUEST_ID,
  TAXONDB_SIMPLE2_SEARCH_SPECIES_REQUEST_ID,
  TAXONDB_SIMPLE2_SEARCH_ICONS_REQUEST_ID,
  TAXONDB_SIMPLE2_SEARCH_REQUEST_ID,
  TAXON_SIMPLE2_RESULTS_ACTIVE_TAB_FIELD_NAME,
  SEARCH_PAGE_URL_EN,
  SEARCH_PAGE_URL_PL,
} from '../settings'
import Loader from '../utils/loader'
import { useTranslation } from 'react-i18next'
import I18nPathTranslator from "./I18nPathTranslator";
import SEO from "./SEO";

const SearchContainer = props => {
  const { t } = useTranslation('search')

  const afterSearch = () => {
    window.scrollTo(0, 0)
  }

  return (
    <Suspense fallback={<Loader />}>
      <I18nPathTranslator {...props} pathEN={SEARCH_PAGE_URL_EN} pathPL={SEARCH_PAGE_URL_PL} />
      <SEO title={t('simple-search-engine-line1')} />
      <div className="home-container search-container">
        <div className="search-form">
          <Container>
            <SimpleSearchForm
              genusRequestId={TAXONDB_SIMPLE2_SEARCH_GENUS_REQUEST_ID}
              speciesRequestId={TAXONDB_SIMPLE2_SEARCH_SPECIES_REQUEST_ID}
              searchRequestId={TAXONDB_SIMPLE2_SEARCH_REQUEST_ID}
              tabId={TAXON_SIMPLE2_RESULTS_ACTIVE_TAB_FIELD_NAME}
              afterSearch={afterSearch}
            ></SimpleSearchForm>
          </Container>
        </div>
        {!props.searchAvailable &&
          <div className="info">
            <Container>
              <div className="line1"><h1>{t('simple-search-engine-line1')}</h1></div>
              {!props.user &&
                <>
                  <div className="line2">{t('simple-search-engine-line2')}</div>
                  <div className="line3">{t('simple-search-engine-line3')}</div>
                </>
              }
            </Container>
          </div>
        }
        {props.searchAvailable &&
          <Container>
            <div>
              <SimpleResultsContainer
                genusRequestId={TAXONDB_SIMPLE2_SEARCH_GENUS_REQUEST_ID}
                speciesRequestId={TAXONDB_SIMPLE2_SEARCH_SPECIES_REQUEST_ID}
                iconsRequestId={TAXONDB_SIMPLE2_SEARCH_ICONS_REQUEST_ID}
                searchRequestId={TAXONDB_SIMPLE2_SEARCH_REQUEST_ID}
                tabId={TAXON_SIMPLE2_RESULTS_ACTIVE_TAB_FIELD_NAME}
                hasIconsListOther
              ></SimpleResultsContainer>
            </div>
          </Container>
        }
      </div>
    </Suspense >
  )
}

const mapStateToProps = state => ({
  searchAvailable: true && (isPending(TAXONDB_SIMPLE2_SEARCH_GENUS_REQUEST_ID, state) || getItems(TAXONDB_SIMPLE2_SEARCH_GENUS_REQUEST_ID, state)),
  user: state.user ? state.user.data : null,
})

const mapDispatchToProps = dispatch => ({
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchContainer)

