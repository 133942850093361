import React, { useEffect, useState } from "react"
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { isPending } from "../../reducers"
import { postDataApi, getDataApi } from '../../actions'
import RedirectLoginWithReturn from "../access/RedirectLoginWithReturn"
import { Container, Button, Card, Row, Col, Spinner } from "react-bootstrap"
import {
  API_TEAM_GET_APPLICATION_DETAILS_ID,
  API_TEAM_GET_APPLICATION_DETAILS_URL,
  TEAM_APPLICATION_PAGE,
  API_TEAM_ADD_ME_REQUEST_ID,
  API_TEAM_ADD_ME_REQUEST_URL,
} from '../../settings'
import * as notify from '../../utils/notify'
import { useParams } from 'react-router-dom';
import { Redirect } from "react-router-dom"
import NotFound from "../NotFound"

const TeamApplication = props => {
  const { t, i18n } = useTranslation([ 'common', 'team', 'paths' ])
  const { id, hash } = useParams();
  const [ info, setInfo ] = useState(null)
  const [ backHome, setBackHome ] = useState(false)
  const [ pending, setPending ] = useState(false)
  const [ notFound, setNotFound ] = useState(false)
  const [ isMember, setIsMember ] = useState(false)

  const { getApplicationDetails } = props
  useEffect(() => {
    if (!pending && props.user && props.user.data) {
      setPending(true)
      getApplicationDetails(id, hash)
        .then(res => {
          setInfo(res)
        })
        .catch(() => {
          setNotFound(true)
        })
    }
  }, [ getApplicationDetails, id, hash, pending, setPending, props.user ]);

  const join = () => {
    props.addMe(id, hash)
      .then(() => {
        notify.success(t('team:joined-team'))
        setBackHome(true)
      })
      .catch((res) => {
        if (res?.response?.status === 409) {
          notify.error(t('team:already-a-member'), res.result)
          setIsMember(true)
        } else {
          notify.error(t('team:couldnt-join-team'))
        }
      })
  }

  return (
    <Container>
      {!props.loading && props.user && !props.user.data &&
        <RedirectLoginWithReturn returnUrl={`${TEAM_APPLICATION_PAGE}${id}/${hash}/`} />
      }
      {backHome && i18n.languages[0] === 'en' &&
        <Redirect push to="/my-teams/"></Redirect>
      }
      {backHome && i18n.languages[0] === 'pl' &&
        <Redirect push to="/moje-zespoly/"></Redirect>
      }
      {notFound &&
        <NotFound></NotFound>
      }
      {props.loading &&
        <Row className="ml-4 mt-4 mb-4">
          <Col>
            <div className="text-center">
              <Spinner animation="border" role="status" />
            </div>
          </Col>
        </Row>
      }
      {!props.loading && info &&
        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '50vh' }}>
          <Card className="text-center">
            <Card.Body>
              <Card.Title>{t('team:you-will-join-team-title')}</Card.Title>
              <Card.Text className="mb-5">
                {t('team:you-will-join-team', info)}
              </Card.Text>
              <Button
                variant="primary"
                className="mr-2"
                onClick={join}
                disabled={props.joining || isMember}
              >
                {t('common:accept')}
                {props.joining &&
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="ml-1"
                  />
                }
              </Button>
              <Button
                variant="primary"
                onClick={() => setBackHome(true)}
                disabled={props.joining || isMember}
              >{t('common:reject')}</Button>
            </Card.Body>
          </Card>
        </div>
      }
    </Container>
  )
}

const mapStateToProps = state => ({
  user: state.user,
  loading: isPending(API_TEAM_GET_APPLICATION_DETAILS_ID, state),
  joining: isPending(API_TEAM_ADD_ME_REQUEST_ID, state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getApplicationDetails: (id, hash) => dispatch(postDataApi(API_TEAM_GET_APPLICATION_DETAILS_ID, `${API_TEAM_GET_APPLICATION_DETAILS_URL}${id}/${hash}/`, {})),
  addMe: (id, hash) => dispatch(getDataApi(API_TEAM_ADD_ME_REQUEST_ID, `${API_TEAM_ADD_ME_REQUEST_URL}${id}/${hash}/`))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamApplication)
