import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { isPending, getOutput } from "../../reducers"
import { Form } from "react-bootstrap"
import { Button } from "react-bootstrap"
import * as actions from '../../actions'
import * as notify from '../../utils/notify'
import {
    PROJECTS_CHANGE_PROJECT_NAME_ID,
    PROJECTS_CHANGE_PROJECT_NAME_URL,
} from '../../settings'

const ChangeProjectName = props => {
    const { t } = useTranslation(['common', 'profile', 'projects'])

    const [newProjectName, setNewProjectName] = useState(props.projectName)

    const onNewProjectNameChanged = e => {
        setNewProjectName(e.target.value)
    }

    return (
        <Form className="mt-2 common-panel-background p-2" onSubmit={e => { e.preventDefault() }}>
            <Form.Group>
                <Form.Label htmlFor="name">{t('profile:new-name')}</Form.Label>
                <Form.Control id="name" size="sm" value={newProjectName} onChange={onNewProjectNameChanged} disabled={props.querying} />
            </Form.Group>
            <div className="text-right">
                <Button size="sm"
                    disabled={!newProjectName || props.querying}
                    onClick={e => props.changeProjectName(newProjectName, props.itemsInParent, t)}
                >{t('projects:change-project-name')}</Button>
            </div>
        </Form>
    )
}

const mapStateToProps = (state, ownProps) => ({
    querying: isPending(PROJECTS_CHANGE_PROJECT_NAME_ID, state),
    itemsInParent: getOutput(ownProps.requestId, state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    changeProjectName: (newProjectName, itemsInParent, t) => {
        dispatch(actions.postDataApi(PROJECTS_CHANGE_PROJECT_NAME_ID, PROJECTS_CHANGE_PROJECT_NAME_URL, {
            project_id: ownProps.projectId,
            new_name: newProjectName
        })).then(() => {
            const newItemsInParent = {
                ...itemsInParent, items: itemsInParent.items.map(obj => {
                    if (obj.id === ownProps.projectId) {
                        return { ...obj, name: newProjectName }
                    } else {
                        return obj
                    }
                })
            }
            dispatch(actions.requestSuccess(ownProps.requestId, newItemsInParent))
            notify.success(t('projects:project-name-changed'))
            ownProps.onDone && ownProps.onDone(newProjectName)
        }).catch(res => {
            notify.error(t('projects:cannot-change-project-name'), res.result)
        })
    },
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChangeProjectName)
