import React from 'react'
import { connect } from 'react-redux'
import { Table, Button } from "react-bootstrap";
import { setCollectionsWithoutTypes } from '../../actions/chart'
import { useTranslation } from 'react-i18next';

export function CollectionsWithoutTypes(props) {

    const { t, i18n } = useTranslation(['reports'])

    const handleHideColls = () => {
        props.setCollectionsWithoutTypes({ "collectionsWithoutTypes": {} })
    }

    return (
        <>
            <Table bordered hover>
                <thead>
                    <tr>
                        <th>
                            {t('collections-without-types')}
                            <Button
                                className="ml-2"
                                type="primary"
                                size="sm"
                                onClick={handleHideColls}>
                                {t('hide')}
                            </Button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {props.charts.collectionsWithoutTypes.map((item, indx) =>
                        <tr key={indx}>
                            <td><b>{item.kolekcja}</b>: {i18n.languages[0] === 'en' ? item.opis_eng : i18n.languages[0] === 'pl' ? item.opis_pl : ''}</td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </>
    )
}

const mapStateToProps = (state) => ({
    charts: state.charts

})

const mapDispatchToProps = dispatch => ({
    setCollectionsWithoutTypes: (data) => dispatch(setCollectionsWithoutTypes(data)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CollectionsWithoutTypes)