import React, { Suspense } from "react";
import { Container } from "react-bootstrap"
import { useTranslation } from 'react-i18next'
import { PRIVACY_POLICY_PAGE_URL_EN, PRIVACY_POLICY_PAGE_URL_PL } from "../settings";
import Loader from '../utils/loader'
import ExternalLinkPopover from "./ExternalLinkPopover";
import I18nPathTranslator from "./I18nPathTranslator";
import SEO from "./SEO";

const Polityka = props => {
    const { t } = useTranslation('policy')

    return (
        <Suspense fallback={<Loader />}>
            <I18nPathTranslator {...props} pathEN={PRIVACY_POLICY_PAGE_URL_EN} pathPL={PRIVACY_POLICY_PAGE_URL_PL} />
            <SEO title={t('common:privacy-policy')} />
            <div>
                <Container>
                    <div className="policy-container">
                        <h1>{t('line-1')}</h1>
                        <p>{t('line-2')}</p>
                        <p>{t('line-3')}</p>
                        <p>{t('line-4')}</p>
                        <p>{t('line-5')}</p>
                        <h2>{t('line-6')}</h2>
                        <p>{t('line-7')}</p>
                        <ul>
                            <li>{t('line-8')}</li>
                            <li>{t('line-9')}<br />
                                {t('line-10')}</li>
                        </ul>
                        <p>{t('line-11')}</p>
                        <p className="pb-0">{t('line-12')}</p>
                        <ul>
                            <li><ExternalLinkPopover btnClass="p-font-size" link='https://www.google.com/policies/privacy/' title={t('line-13')} placement="bottom">{t('line-13')}</ExternalLinkPopover></li>
                            <li><ExternalLinkPopover btnClass="p-font-size" link='https://firebase.google.com/support/privacy/' title={t('line-14')} placement="bottom">{t('line-14')}</ExternalLinkPopover></li>
                        </ul>
                        <h2>{t('line-15')}</h2>
                        <p className="pb-0">{t('line-16')}</p>
                        <ul>
                            <li>{t('line-17')}</li>
                            <li>{t('line-18')}</li>
                            <li>{t('line-19')}</li>
                            <li>{t('line-20')}</li>
                            <li>{t('line-21')}</li>
                        </ul>
                        <h2>{t('line-22')}</h2>
                        <p>{t('line-23')}</p>
                        <h2>{t('line-24')}</h2>
                        <p>{t('line-25')}</p>
                        <p>{t('line-26')}</p>
                        <h2>{t('line-27')}</h2>
                        <p className="p-0">{t('line-28')}</p>
                        <ul>
                            <li>{t('line-29')}</li>
                            <li>{t('line-30')}</li>
                            <li>{t('line-31')}</li>
                            <li>{t('line-32')}</li>
                        </ul>
                        <p>{t('line-33')}</p>
                        <h2>{t('line-34')}</h2>
                        <p>{t('line-35')}</p>
                        <h2>{t('line-36')}</h2>
                        <p>{t('line-37')}</p>
                        <h2>{t('line-38')}</h2>
                        <p>{t('line-39')}</p>
                        <h2>{t('line-40')}</h2>
                        <p>{t('line-41')}</p>
                        <p>{t('line-42')}</p>
                        <h2>{t('line-43')}</h2>
                        <p>{t('line-44')} <a href="mailto:anc-dev@man.poznan.pl">anc-dev@man.poznan.pl</a>.</p>
                    </div>
                </Container>
            </div>
        </Suspense>
    )
}

export default Polityka
