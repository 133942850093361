import React, { useState } from "react";
import { Bar, Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import { isPending } from "../../reducers";
import { API_GET_VISITS_PER_DAY_ID } from '../../settings'

function MonthlyDownloadsChart(props) {

    const { t } = useTranslation(['reports', 'common'])
    const [mobileDevice] = useState(window.screen.width < 700 ? true : false)
    const CHART_TYPE_LINE = 'line' 

    const options = {
        animation: {
            duration: 0
        },
        plugins: [ChartDataLabels],
        elements: {
            line: {
                tension: 0, 
            }
        },
        scales: {
            xAxes: [{
                type: 'time',
                distribution: 'linear',
                offset: true,
                ticks:{
                    source: 'auto',
                    maxTicksLimit: mobileDevice ? 4 : 15,
                },
                time: {
                   unit: props.charts.interval,
                   displayFormats: {
                        hour: 'YYYY-MM-DD HH:MM:SS',
                        day: 'YYYY-MM-DD',
                        week: 'YYYY-MM-DD',
                        month: 'YYYY-MM',
                        year: 'YYYY'
                    }
                }
            }],
            yAxes: [{
                     display: true,
                     stacked: false,
                     ticks: {
                         min: 0,
                         maxTicksLimit: mobileDevice ? 8 : 15,
                     }
            }]
         }
    };

    var dataChart = {
        labels: props.charts.months,
        datasets: [
            {
                label: t('downloads'),
                backgroundColor: '#2970FF',
                borderColor: '#000066',
                fill: props.charts.chartType === CHART_TYPE_LINE ? false : true,
                data: props.charts.number_downloads,
            },
            {
                label: t('taxons'),
                backgroundColor: '#009933',
                borderColor: '#009900',
                fill: props.charts.chartType === CHART_TYPE_LINE ? false : true,
                data: props.charts.taxons
            },
            {
                label: t('users'),
                backgroundColor: '#C76300',
                borderColor: '#cc6600',
                fill: props.charts.chartType === CHART_TYPE_LINE ? false : true,
                data: props.charts.users
            },
        ]
    }

    let chart = props.charts.chartType === CHART_TYPE_LINE ? <Line data={dataChart} options={options}/> : <Bar data={dataChart} options={options}/>
    
    return(
        <div className="chart-container">
            {chart}
        </div>  
    )
}


const mapStateToProps = state => ({
    charts : state.charts,
    chartLoading: isPending(API_GET_VISITS_PER_DAY_ID, state)
  })
  
  const mapDispatchToProps = dispatch => ({

  })
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MonthlyDownloadsChart)