import React, { useState, useEffect, Suspense } from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Container, Button, Row, Col, Spinner } from "react-bootstrap"
import Autosuggest from 'react-autosuggest'
import DOMPurify from "dompurify"

import { isPending, getOutput } from "../../reducers";
import RedirectLoginWithReturn from "../access/RedirectLoginWithReturn"
import { postDataApi, getDataDlibra, getDataAnc, postDataAnc, postDownloadDataApi } from '../../actions'
import {
    API_ALBUM_PRESENTATION_ID_PREFIX,
    API_ALBUM_PRESENTATION_ITEMS_URL,
    API_ALBUM_PRESENTATION_URL,
    TAXONDB_DETAILS_REQUEST_ID_PREFIX,
    ICONDB_DETAILS_REQUEST_ID_PREFIX,
    TAXONDB_DETAILS_REQUEST_URL,
    ICONDB_DETAILS_REQUEST_URL,
    API_ALBUM_DOWNLOAD_PDF_ID,
    API_ALBUM_DOWNLOAD_PDF_URL,
    SAMPLESDB_DETAILS_REQUEST_ID_PREFIX,
    SAMPLESDB_DETAILS_REQUEST_URL
} from '../../settings'
import SingleDlibraImage from '../iconDB/DlibraImage'
import RecordDetails from '../iconDB/RecordDetails'
import TaxonSingleDlibraImage from '../taxonDB/DlibraImage'
import TaxonRecordDetails from '../taxonDB/RecordDetails'
import SampleRecordDetails from '../samplesDB/RecordDetails'
import "../../styles/react-autosuggest.scss"
import * as notify from '../../utils/notify'
import SEO from "../SEO";
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NotFound from '../NotFound'

const MainContainer = props => {
    const { t } = useTranslation([ 'common', 'profile' ])
    const [ isFound, setIsFound ] = useState(true)
    const { recordId, viewedBy } = useParams();

    const loadingItems = useSelector(state => state.requests[API_ALBUM_PRESENTATION_ID_PREFIX + recordId]?.pending)
    const items = useSelector(state => state.requests[API_ALBUM_PRESENTATION_ID_PREFIX + recordId]?.output?.items)
    const album = useSelector(state => state.requests[API_ALBUM_PRESENTATION_ID_PREFIX + recordId]?.output)

    const loadDetails = item => {
        if (item.kind === 'taxon') {
            props.getTaxonDetails(item.name)
        } else if (item.kind === 'icon') {
            props.getIconDetails(item.name)
        } else if (item.kind === 'sample') {
            props.getSampleDetails(item.name)
        }
    }

    const { getAlbumItems, getTaxonDetails, getIconDetails, getSampleDetails } = props
    useEffect(() => {
        getAlbumItems(recordId, viewedBy).then(data => {
            if (data.items.length > 0) {
                const item = data.items[0]
                if (item.kind === 'taxon') {
                    getTaxonDetails(item.name)
                }
                else if (item.kind === 'icon') {
                    getIconDetails(item.name)
                } else if (item.kind === 'sample') {
                    getSampleDetails(item.name)
                }
            }
        }).catch(() => {
            setIsFound(false)
        })
    }, [getAlbumItems, getTaxonDetails, getIconDetails, getSampleDetails, setIsFound, recordId, viewedBy])

    const [itemNr, setItemNr] = useState(1)
    const [itemText, setItemText] = useState(1)

    const onSlideChanged = e => {
        let val = parseInt(e.target.value)
        if (isNaN(val) || val < 1 || val > items.length) {
            val = 1
        }
        if (val !== itemNr) {
            setItemNr(val)
            loadDetails(items[val - 1])
        }
        setItemText(val)
    }

    const onSlideKeyUp = e => {
        if (e.keyCode === 13) { // ENTER
            e.preventDefault()
            onSlideChanged(e)
        }
    }

    const canSlideChange = e => {
        const val = e.target.value
        if (/^\d*$/.test(val)) {
            setItemText(val)
        }
    }

    const goNextSlide = () => {
        if (itemNr < items.length) {
            loadDetails(items[itemNr])
            setItemNr(itemNr + 1)
            setItemText(itemNr + 1)
        }
    }

    const goPreviousSlide = () => {
        if (itemNr > 1) {
            loadDetails(items[itemNr - 2])
            setItemNr(itemNr - 1)
            setItemText(itemNr - 1)
        }
    }

    const [suggestText, setSuggestText] = useState('')
    const [suggestions, setSuggestions] = useState([])
    const onSuggestionsFetchRequested = query => {
        setSuggestions(items.filter(obj =>
            obj.custom_name ? obj.custom_name.toLowerCase().includes(query.value.toLowerCase())
                : obj.name.toLowerCase().includes(query.value.toLowerCase())))
    }
    const onSuggestionsClearRequested = () => { }
    const getSuggestionValue = suggestion => {
        return (suggestion.custom_name ? suggestion.custom_name : suggestion.name)
    }
    const renderSuggestion = suggestion => {
        const txt = suggestion.custom_name ? suggestion.custom_name : suggestion.name
        const indx = txt.toLowerCase().indexOf(suggestText.toLowerCase())
        const normal1 = txt.substring(0, indx)
        const bold = txt.substring(indx, indx + suggestText.length)
        const normal2 = txt.substring(indx + suggestText.length)
        return (
            <span>
                {normal1}<b>{bold}</b>{normal2}
            </span>
        )
    }
    const inputProps = {
        value: suggestText,
        onChange: e => {
            setSuggestText(e.target.value)
        },
        className: 'form-control',
        autoComplete: "no",
        id: "searchf",
    }
    const onSuggestionSelected = (e, val) => {
        setSuggestText(val.suggestionValue)
        const indx = items.findIndex(el => el.id === val.suggestion.id) + 1
        setItemNr(indx)
        setItemText(indx)
        loadDetails(items[indx - 1])
    }

    const onExportToPdf = () => {
        props.exportToFile(recordId).catch(err => {
            notify.error(t('cannot_download_pdf_file'))
        })
    }

    return (
        <Container>
            {!isFound &&
                <NotFound/>
            }
            {!props.loading && props.user && !props.user.data &&
                <RedirectLoginWithReturn returnUrl={API_ALBUM_PRESENTATION_URL + recordId} />
            }
            <Row className="d-flex flex-column" style={{ flexWrap: "nowrap" }}>
                {(loadingItems || props.loading) &&
                    <div className="align-self-center m-5">
                        <Spinner animation="border" role="status" />
                    </div>
                }
                {items && items.length === 0 &&
                    <Row className="align-self-center"><Col>{t('profile:album-is-empty')}</Col></Row>
                }
                {!loadingItems && items && items.length > 0 &&
                    <>
                        <SEO title={album.album_custom_name ? album.album_custom_name : album.album_name} />
                        <div className="d-flex justify-content-between mt-1 mb-1">
                            <div><Button disabled={loadingItems || itemNr === 1} onClick={goPreviousSlide}>{t('previous')}</Button></div>
                            <div className="d-flex">
                                <div className="d-flex align-items-center mr-4">
                                    <label className="mr-1" htmlFor="slideNr">{t('slide')}:</label>
                                    <div>
                                        <input
                                            className="form-control"
                                            style={{ width: "4rem" }}
                                            value={itemText}
                                            type="text"
                                            onBlur={onSlideChanged}
                                            onKeyUp={onSlideKeyUp}
                                            id="slideNr"
                                            autoComplete="off"
                                            onChange={canSlideChange}></input>
                                    </div>
                                    <div className="ml-1" style={{ whiteSpace: "nowrap" }}>/ {items.length}</div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <label className="mr-1" htmlFor="searchf">{t('search')}:</label>
                                    <Autosuggest
                                        suggestions={suggestions}
                                        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                        onSuggestionsClearRequested={onSuggestionsClearRequested}
                                        getSuggestionValue={getSuggestionValue}
                                        renderSuggestion={renderSuggestion}
                                        inputProps={inputProps}
                                        onSuggestionSelected={onSuggestionSelected}
                                    >
                                    </Autosuggest>
                                </div>
                            </div>
                            <div><Button disabled={loadingItems || itemNr >= items.length} onClick={goNextSlide}>{t('next')}</Button></div>
                        </div>
                        <div className="d-flex justify-content-center">
                            <Button disabled={props.loadingPdf} onClick={onExportToPdf}>
                                {t('profile:export-presentation-to-pdf')}
                                {props.loadingPdf &&
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        className="ml-1"
                                    />
                                }
                            </Button>
                        </div>
                        <h1 className="align-self-center">{album.album_custom_name ? album.album_custom_name : album.album_name}</h1>
                        {items[itemNr - 1].custom_name &&
                            <h2 className="align-self-center">{items[itemNr - 1].custom_name}</h2>
                        }
                        <Suspense fallback={<div className="align-self-center m-3"><Spinner animation="border" role="status" /></div>}>
                            <Row>
                                {items[itemNr - 1].description &&
                                    <Col md={6} style={{ overflow: "hidden" }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(items[itemNr - 1].description) }}></Col>
                                }
                                <Col md={items[itemNr - 1].description ? 6 : 12}>
                                    {items[itemNr - 1].kind === 'taxon' &&
                                        <div className="d-flex flex-column pt-1 common-panel-background">
                                            {props.loadingSlide &&
                                                <div className="align-self-center m-5">
                                                    <Spinner animation="border" role="status" />
                                                </div>
                                            }
                                            {!props.loadingSlide && props.dataTaxon &&
                                                <div className="d-flex flex-column">
                                                    {props.dataTaxon && props.dataTaxon.images.length > 0 &&
                                                        <TaxonSingleDlibraImage requestId={TAXONDB_DETAILS_REQUEST_ID_PREFIX}></TaxonSingleDlibraImage>
                                                    }
                                                    <TaxonRecordDetails requestId={TAXONDB_DETAILS_REQUEST_ID_PREFIX} recordId={items[itemNr - 1].name}></TaxonRecordDetails>
                                                </div>
                                            }
                                        </div>
                                    }
                                    {items[itemNr - 1].kind === 'icon' &&
                                        <div className="d-flex flex-column pt-1 common-panel-background">
                                            {props.loadingSlide &&
                                                <div className="align-self-center m-5">
                                                    <Spinner animation="border" role="status" />
                                                </div>
                                            }
                                            {!props.loadingSlide && props.dataIcon &&
                                                <div className="d-flex flex-column">
                                                    <SingleDlibraImage requestId={ICONDB_DETAILS_REQUEST_ID_PREFIX}></SingleDlibraImage>
                                                    <RecordDetails requestId={ICONDB_DETAILS_REQUEST_ID_PREFIX}></RecordDetails>
                                                </div>
                                            }
                                        </div>
                                    }
                                    {items[itemNr - 1].kind === 'sample' &&
                                        <div className="d-flex flex-column pt-1 common-panel-background">
                                            {props.loadingSlide &&
                                                <div className="align-self-center m-5">
                                                    <Spinner animation="border" role="status" />
                                                </div>
                                            }
                                            {!props.loadingSlide && props.dataSample &&
                                                <div className="d-flex flex-column">
                                                    <SampleRecordDetails requestId={SAMPLESDB_DETAILS_REQUEST_ID_PREFIX} recordId={items[itemNr - 1].name}></SampleRecordDetails>
                                                </div>
                                            }
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </Suspense>
                    </>
                }
            </Row>
        </Container>
    )
}

const mapStateToProps = (state, ownProps) => ({
    user: state.user,
    loading: isPending('userInfo', state),
    loadingPdf: isPending(API_ALBUM_DOWNLOAD_PDF_ID, state),
    loadingSlide: isPending(ICONDB_DETAILS_REQUEST_ID_PREFIX, state) || isPending(TAXONDB_DETAILS_REQUEST_ID_PREFIX, state),
    dataTaxon: getOutput(TAXONDB_DETAILS_REQUEST_ID_PREFIX, state),
    dataIcon: getOutput(ICONDB_DETAILS_REQUEST_ID_PREFIX, state),
    dataSample: getOutput(SAMPLESDB_DETAILS_REQUEST_ID_PREFIX, state),
})

const mapDispatchToProps = dispatch => ({
    getAlbumItems: (recordId, viewedBy) => dispatch(postDataApi(API_ALBUM_PRESENTATION_ID_PREFIX + recordId, API_ALBUM_PRESENTATION_ITEMS_URL, {
        album_id: recordId,
        someone_id: viewedBy
    })),
    getTaxonDetails: (recordId) => dispatch(getDataAnc(TAXONDB_DETAILS_REQUEST_ID_PREFIX, TAXONDB_DETAILS_REQUEST_URL + recordId + '/')),
    getIconDetails: (recordId) => dispatch(getDataDlibra(ICONDB_DETAILS_REQUEST_ID_PREFIX, ICONDB_DETAILS_REQUEST_URL + recordId + '/')),
    getSampleDetails: (recordId) => dispatch(postDataAnc(SAMPLESDB_DETAILS_REQUEST_ID_PREFIX, SAMPLESDB_DETAILS_REQUEST_URL, { id: recordId })),
    exportToFile: album_id => dispatch(postDownloadDataApi(
        API_ALBUM_DOWNLOAD_PDF_ID,
        API_ALBUM_DOWNLOAD_PDF_URL,
        { album_id },
        'Presentation',
        'pdf'
    )),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MainContainer)
