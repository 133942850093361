import React, { Suspense } from "react";
import { connect } from 'react-redux'
import { Container } from "react-bootstrap"
import Loader from '../utils/loader'
import SEO from "./SEO";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import { getOutput, isPending } from "../reducers";
import { 
    BIBLIOGRAPHY_SEARCH_PAGE_URL_PL, 
    EDUCATION_MATERIALS_PAGE_URL_PL, 
    ICONDB_PAGE_URL_PL, 
    INFORMATIONS_PAGE_URL_EN, 
    INFORMATIONS_PAGE_URL_PL,
    SAMPLES_SEARCH_PAGE_URL_PL,
    SEARCH_PAGE_URL_PL, 
    TAXONDB_COUNT_REQUEST_ID, 
    TAXONDB_PAGE_URL_PL 
} from "../settings";
import { Pie } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import I18nPathTranslator from "./I18nPathTranslator";

const SmoothHashLink = props => {
    const scrollWithOffset = el =>{
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -140; 
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
    }
    return (
        <HashLink to={props.to} scroll={el=> scrollWithOffset(el)}>
                {props.children}
        </HashLink>
    )
}

const AboutPortalPL = props => {
    const { count } = props
    const { i18n } = useTranslation()

    let chartData = {}
    let chartOptions = {}
    let animalia, fungi, plantae
    if(count){
        const allTaxons = count.taxons["TAXON"]
        animalia = (100*count.taxons["TAXON-ANIMALIA"]/allTaxons).toFixed(1)
        fungi = (100*count.taxons["TAXON-FUNGI"]/allTaxons).toFixed(1)
        plantae = (100*count.taxons["TAXON-PLANTAE"]/allTaxons).toFixed(1)
        chartData = {
            labels: ['Zwierzęta', 'Glony i rośliny', 'Grzyby i porosty'],
            datasets: [{
                data: [animalia, plantae, fungi],
                backgroundColor: ['#a5a5a5','#4472c4','#ed7d31'],
                color: ["#000", "#000", "#000"],
                fontColor: "#000",
            }],
        }
        chartOptions = {
            plugins:{
                datalabels:{
                    display: true,
                    color: "#000",
                    anchor: 'end',
                    align: 'start',
                    offset: 6,
                    font: {
                        size: 16,
                        weight: 'bold',
                        family: 'Montserrat'
                    }
                },
            },
            legend:{
                position: "bottom",
                labels:{
                    fontFamily: 'Montserrat'
                },
            },
            title:{
                display: true,
                text: [ `Ryc. 1. Udział procentowy danych o okazach i obserwacjach`, `z trzech głównych kolekcji: botanicznej, mikologicznej i zoologicznej`, `(N=${count.taxons[ "TAXON" ].toLocaleString(i18n.languages[0])})`],
                fontSize: 20,
                fontFamily: "Montserrat"
            },
            locale: i18n.languages[0] === 'pl' ? "pl-PL" : "en-US",
        }
    }
  return (
    <Suspense fallback={<Loader />}>
      <I18nPathTranslator {...props} pathEN={INFORMATIONS_PAGE_URL_EN} pathPL={INFORMATIONS_PAGE_URL_PL} />
      <SEO title="Informacje o portalu" />
      <Container className="about-portal" id="spis-tresci">
        <section className="py-4 mb-3">
            <h1>Portal</h1>
            <p>Misja i cele systemu AMUNatColl IT realizowane są przez portal i aplikację mobilną. Portal jest oknem na świat dla okazów i innych dokumentów zdeponowanych w Zbiorach Historii Naturalnej Uniwersytetu im. Adama Mickiewicza w Poznaniu, które przeszły proces digitalizacji. Architektura portalu uwzględnia zarówno obfitość i złożoność cyfrowych informacji o bioróżnorodności zgromadzonych w bazie AMUNatColl jak i zróżnicowanie potrzeb jego potencjalnych użytkowników.</p>
            <p className="p-0"><b>Portal udostępnia: </b></p>
            <ul>
                <li><b><SmoothHashLink to="#cyfrowe-dane">cyfrowe dane o różnorodności biologicznej</SmoothHashLink></b></li>
                <li><b><SmoothHashLink to="#wyszukiwanie-i-przegladanie">narzędzia do wyszukiwania i przeglądania danych</SmoothHashLink></b></li>
                <li><b><SmoothHashLink to="#analiza-danych">narzędzia do analizy statystycznej i przestrzennej danych</SmoothHashLink></b></li>
                <li><b><SmoothHashLink to="#tworzenie-bazy-danych">narzędzia do tworzenia bazy danych przesłanych z aplikacji mobilnej</SmoothHashLink></b></li>
                <li><b><SmoothHashLink to="#komunikacja">kanały komunikacji użytkowników z administratorem systemu</SmoothHashLink></b></li>
                <li><b><SmoothHashLink to="#zarzadzanie-systemem">narzędzia do zarządzania systemem AMUNatColl IT.</SmoothHashLink></b></li>
            </ul>
        </section>
        <section className="py-4 my-3" id="cyfrowe-dane">
            <h2>Portal udostępnia cyfrowe dane o różnorodności biologicznej udokumentowane w formie:</h2>
            <ul>
                <li>okazów odpowiednio zakonserwowanych oraz dokumentów ikonograficznych okazów (skany, zdjęcia)</li>
                <li>fotografii organizmów wykonanych w środowisku ich życia</li>
                <li>notatek z terenowych obserwacji florystycznych, mikologicznych, faunistycznych, opublikowanych lub dotąd niepublikowanych (dalej: obserwacji terenowych)</li>
                <li>próbek terenowych oczekujących na opracowanie taksonomiczne (próby glebowe, przesiewki, gniazda ptaków, itp.)</li>
                <li>fotografii i filmów dokumentacyjnych ilustrujących siedliska i krajobrazy przyrodnicze</li>
                <li>katalogu źródeł bibliograficznych, które są cytowane w bazie AMUNatColl oraz skanów trudno dostępnych pozycji bibliograficznych.</li>
            </ul>
            <p>Poprzez portal dostępne są dane o {count ? count.taxons['TAXON'].toLocaleString(i18n.languages[0]) : "1 962 309"} okazach i obserwacjach terenowych, zgrupowanych w trzech głównych kolekcjach: botanicznej (glony i rośliny), mikologicznej (grzyby i porosty) oraz zoologicznej (zwierzęta) (ryc. 1). Okazy zdeponowane w Zbiorach Przyrodniczych UAM pochodzą ze wszystkich kontynentów (ryc. 2 – mapa), a najstarsze z nich zbierano 200 lat temu. </p>
            <p>Lista metadanych opisujących okazy i notowania terenowe obejmuje odpowiednio 85 i 68 pól, w tym metadane wymagane przez standardy ABCD 2,6 oraz Darwin Core. Systematycznie wzrasta liczba udostępnionych danych ikonograficznych, dokumentujących okazy w formie skanów i fotografii, dołączonych do opisowej części rekordu. Aktualnie wynosi {count ? count.icons['ICON'].toLocaleString(i18n.languages[0]) : "144 687"} ({count ? count.icons['TAXON-PLANTAE'].toLocaleString(i18n.languages[0]) : "125 353"} kolekcja botaniczna + {count ? count.icons['TAXON-FUNGI'].toLocaleString(i18n.languages[0]) : "15 413"} kolekcja mikologiczna + {count ? count.icons['TAXON-ANIMALIA'].toLocaleString(i18n.languages[0]) : "3 200"} kolekcja zoologiczna). </p>
            <p>Portal otwiera też dostęp do nieopracowanych dotąd taksonomicznie próbek terenowych oraz cytowanych w bazie źródeł bibliograficznych. Do opisu próbek terenowych wykorzystuje się 50 pól natomiast metadane bibliograficzne obejmują 38 pól.</p>
            <div className="mt-1">
                { count && count.taxons && <Pie data={canvas => {
                    canvas.setAttribute('aria-label', `Ryc. 1. Udział procentowy danych o okazach i obserwacjach z trzech głównych kolekcji: botanicznej, mikologicznej i zoologicznej (N=${count.taxons[ "TAXON" ].toLocaleString(i18n.languages[0])}). Zwierzęta stanowią ${animalia} procent, glony i rośliny ${plantae} procent, a grzyby i porosty ${fungi} procent.`);
                    canvas.setAttribute('role', 'img');
                    return chartData
                }
                } options={chartOptions}/> }
            </div>
            <SmoothHashLink to="#spis-tresci">Wróć do spisu treści</SmoothHashLink>
        </section>
        <section className="py-4 my-3" id="wyszukiwanie-i-przegladanie">
            <h2>Portal udostępnia następujące narzędzia do wyszukiwania i przeglądania danych:</h2>
            <ul>
                <li>wyszukiwarki okazów i taksonów – ogólna i trzy specjalistyczne (podstawowa, rozszerzona i zaawansowana)</li>
                <li>wyszukiwarka prób biologicznych</li>
                <li>wyszukiwarka multimediów</li>
                <li>wyszukiwarka bibliograficzna</li>
                <li>wyszukiwarka materiałów edukacyjnych i szkoleniowych.</li>
            </ul>
            <p><b>Użytkownik niezalogowany</b> może korzystać z <b><i>wyszukiwarki ogólnej okazów</i></b> (notowań terenowych) znajdującej się na stronie. Po podaniu nazwy gatunkowej organizmu w języku łacińskim lub polskim ma dostęp do pełnej informacji opisowej, ikonograficznej i kartograficznej o okazach i notowaniach terenowych zdeponowanych na Wydziale Biologii. <SmoothHashLink to={"/jak-korzystac/#"+SEARCH_PAGE_URL_PL}>Więcej zobacz w: Jak korzystać.</SmoothHashLink></p>
            <p className="p-0"><b>Użytkownik zalogowany</b> może przeglądać dane o okazach i taksonach, które one reprezentują oraz notowań terenowych korzystając z trzech wyszukiwarek specjalistycznych: podstawowej, rozszerzonej i zaawansowanej.</p>
            <ul>
                <li><b>Wyszukiwarka podstawowa</b> stwarza możliwość określenia jednego z siedmiu kryteriów selekcji lub ich kombinacji oraz wyszukania informacji o okazach/notowaniach zebranych na obszarze zaznaczonym na mapie przez użytkownika. <SmoothHashLink to={"/jak-korzystac/#"+TAXONDB_PAGE_URL_PL+"_podstawowa"}>Więcej zobacz w: Jak korzystać.</SmoothHashLink></li>
                <li><b>Wyszukiwarka rozszerzona</b> umożliwia określenia jednego lub wielu spośród 37 kryteriów selekcji. Ponadto, użytkownik może zapisać w systemie formularz definiujący kryteria wyszukiwania. Wyszukiwane okazy/notowania muszą spełniać wszystkie wybrane kryteria wyboru (warunek „i”). <SmoothHashLink to={"/jak-korzystac/#"+TAXONDB_PAGE_URL_PL+"_rozszerzona"}>Więcej zobacz w: Jak korzystać.</SmoothHashLink></li>
                <li><b>Wyszukiwarka zaawansowana</b> wykorzystuje identyczne kryteria selekcji jak wyszukiwarka rozszerzona, jednak użytkownik może dodatkowo określać dwa warunki wyszukiwania: alternatywny (warunek „lub”) i wykluczający (warunek „nie”). <SmoothHashLink to={"/jak-korzystac/#"+TAXONDB_PAGE_URL_PL+"_zaawansowana"}>Więcej zobacz w: Jak korzystać.</SmoothHashLink></li>
            </ul>
            <p className="p-0"><b>Użytkownik zalogowany</b> może uzyskać dane o okazach i taksonach, do których je zaliczono oraz notowaniach terenowych, przeglądając:</p>
            <ul>
                <li>grupy systematyczne, do których sklasyfikowane zostały okazy; korzystając z tej ścieżki uzyskuje informacje o tym jakie taksony i jak licznie są reprezentowane w wyższej rangi grupie systematycznej. <Link to="/jak-korzystac/">Więcej zobacz w: Jak korzystać.</Link></li>
                <li>kolekcje okazów i innych dokumentów (notowania, fotografie, filmy), zgromadzone przez jednego lub kilku badaczy i w takiej formie przechowywane; korzystając z tej ścieżki użytkownik może dość szybko zorientować się w strukturze i zasobach kolekcji określonego badacza, jeśli taka została wyróżniona w bazie danych. <Link to="/jak-korzystac/">Więcej zobacz w: Jak korzystać.</Link></li>
            </ul>
            <p className="p-0"><b>Użytkownik zalogowany</b> może korzystać z danych opisujących:</p>
            <ul>
                <li>próbki terenowe stanowiące bogaty potencjał do badań, szczególnie zootaksonomicznych.  <SmoothHashLink to={"/jak-korzystac/#"+SAMPLES_SEARCH_PAGE_URL_PL}>Więcej zobacz w: Jak korzystać.</SmoothHashLink></li>
                <li>dokumenty multimedialne siedlisk i krajobrazów przyrodniczych, a także gatunków zobrazowanych na podstawie okazów nie ujętych w podstawowej bazie danych. <SmoothHashLink to={"/jak-korzystac/#"+ICONDB_PAGE_URL_PL}>Więcej zobacz w: Jak korzystać.</SmoothHashLink></li>
                <li>źródła bibliograficzne cytowane w opisie okazów, próbek biologicznych i dokumentów multimedialnych. <SmoothHashLink to={"/jak-korzystac/#"+BIBLIOGRAPHY_SEARCH_PAGE_URL_PL}>Więcej zobacz w: Jak korzystać.</SmoothHashLink></li>
                <li>materiały edukacyjne i szkoleniowe przygotowane dla różnych grup użytkowników na podstawie dokumentów zgromadzonych w głównej bazie AMUNatColl i na koncie Mój AMUNatColl. <SmoothHashLink to={"/jak-korzystac/#"+EDUCATION_MATERIALS_PAGE_URL_PL}>Więcej zobacz w: Jak korzystać.</SmoothHashLink></li>
            </ul>
            <SmoothHashLink to="#spis-tresci">Wróć do spisu treści</SmoothHashLink>
        </section>
        <section className="py-4 my-3" id="analiza-danych">
            <h2>Portal udostępnia następujące narzędzia do analizy statystycznej i przestrzennej danych o okazach i taksonach:</h2>
            <ul>
                <li>narzędzia analizy statystycznej <b>(NAS)</b></li>
                <li>narzędzia analizy przestrzennej <b>(NAP)</b> w tym cztery panele:  rozmieszczenia, klastrowania, grupowania i panel analizy czasoprzestrzennej.</li>
            </ul>
            <p><b>NAS</b> i <b>NAP</b> stanowią rozwinięcie wyszukiwarek specjalistycznych oferując wiele dodatkowych możliwości badań i wizualizacji wyników. Razem tworzą spójny system, który uwzględnia zróżnicowane potrzeby użytkowników. W zależności od celu zaplanowanej pracy można korzystać tylko z wyszukiwarek, przeglądając interesujące okazy i związane z nimi dane, albo od razu uruchamiać narzędzia analizy statystycznej i przestrzennej. Należy podkreślić, że wybór pierwszej ścieżki dostępu do danych nie oznacza konieczności jej zmiany jeśli w trakcie pracy użytkownik postanowi jednak przeprowadzić analizę statystyczną czy przestrzenną. Można to uczynić, korzystając z tych narzędzi, które są już dostępne z poziomu wyszukiwarek.</p>
            <p><b>NAS</b> umożliwiają nie tylko przedstawienie wyników ilościowych uzyskanych w procedurze wyszukiwania ale dodatkowo wykorzystują parametry pozwalające grupować dane, które spełniające określone przez użytkownika kryteria. Tworzone w ten sposób raporty są prezentowane w postaci tabelarycznej oraz w formie wykresów kołowych lub słupkowych. Mogę być eksportowane w formie plików XLSX, CSV, XLS. <Link to="/jak-korzystac/">Więcej zobacz w: Jak korzystać.</Link></p>
            <p><b>NAP</b> umożliwiają przedstawienie wyników analizy danych o różnorodności biologicznej w formie kartograficznej. Mapy tematyczne mogą wykorzystywać m.in. warstwy udostępniane przez OpenStreetMap (podział administracyjny świata i poszczególnych państw) oraz Esri Word Imagery (zdjęcia satelitarne różnej rozdzielczości). </p>
            <p className="p-0"><b>NAP</b> pozwalają użytkownikowi definiować m.in.: sposób prezentacji zjawiska w przestrzeni (mapa sygnaturowa-punktowa, mapa chorochromatyczna, kartogram, kartodiagram); kolor, kształt i rozmiar sygnatur; legendę sygnatur i treść etykiet; szerokość przedziałów zmienności zjawiska (w skali arytmetycznej, geometrycznej lub ustalonej przez użytkownika). Wybierając metodę prezentacji zjawiska w formie kartogramu lub kartodiagramu użytkownik ma do wyboru kilka typów warstw (podstawowych pól kartogramu – PPK), m.in.: podział administracyjny, siatka UTM, siatka Atlasu rozmieszczenia roślin naczyniowych w Polsce (ATPOL). <Link to="/jak-korzystac/">Więcej zobacz w: Jak korzystać.</Link></p>
            <ul>
                <li><b>Panel jakościowy</b> (dawniej Panel rozmieszczenia) umożliwia przedstawienie wyniku wyszukiwania w formie mapy punktowej lub mapy powierzchniowej (nazywanej też mapą chorochromatyczną lub kartogramem jakościowym). Punkt lub wypełniona powierzchnia na mapie oznaczają obecność zjawiska (np. okazu), natomiast nie informują o jego natężeniu (np. liczebności okazów).
                <ul>
                    <li>
                    <img src="/images/about-portal-map-1.png" alt="Mapa punktowa" className="mx-auto d-block"></img>
                    <p>Mapa punktowa ukazuje rozmieszczenie stanowisk poboru prób (zbioru okazów), opisanych współrzędnymi geograficznymi.</p>
                    </li>
                    <li>
                    <img src="/images/about-portal-map-2.png" alt="Mapa powierzchniowa" className="mx-auto d-block"></img>
                    <p>Mapa powierzchniowa (chorochromatyczna lub kartogram jakościowy) ukazuje rozmieszczenie państw (tu: podstawowych jednostek kartogramu administracyjnego), w których znajdują się stanowiska poboru prób (zbioru okazów).</p>
                    </li>
                </ul>
                </li>
                <li><b>Panel ilościowy</b> (dawniej Panel klastrowania i Panel grupowania) umożliwia prezentację wyniku analizy w formie kartogramu, kartodiagramu oraz mapy kropkowej opracowanej metodą klastrowania. W każdym przypadku mapa informuje o natężeniu zjawiska (np. liczbie okazów; liczbie okazów należących do określonej grupy systematycznej czy zebranych w określonym przedziale czasowym) w wybranych przez autora mapy jednostkach przestrzennych lub w punktach wyznaczonych przez współrzędne geograficzne. 
                <ul>
                    <li>
                    <img src="/images/about-portal-map-3.png" alt="Kartogram" className="mx-auto d-block"></img>
                    <p>Kartogram ukazujący zróżnicowanie liczby okazów zebranych w poszczególnych państwach.</p>
                    </li>
                    <li>
                    <img src="/images/about-portal-map-4.png" alt="Kartodiagram" className="mx-auto d-block"></img>
                    <p>Kartodiagram ukazujący liczbę okazów reprezentującej rodzaje należące do tej samej rodziny na tle państw, w których pobierano próby (na etykiecie wybranego państwa widoczne kompletne dane).</p>
                    </li>
                    <li>
                    <img src="/images/about-portal-map-5.png" alt="Mapa kropkowa" className="mx-auto d-block"></img>
                    <p>Mapa kropkowa opracowana metodą klastrowania ukazuje liczbę okazów zebranych w określonej odległości od centrum zgrupowania (klastra)</p>
                    </li>
                    <li>
                    <img src="/images/about-portal-map-6.png" alt="Mapa kropkowo-punktowa" className="mx-auto d-block"></img>
                    <p>Mapa kropkowo-punktowa powstała w wyniku zmniejszenia skali mapy i rozgrupowania jednego z klastrów.</p>
                    </li>
                </ul>
                </li>
                <li><b>Panel zmienności czasoprzestrzennej </b> pozwala prezentować na mapie zmiany zjawiska w czasie, np. przyrost liczby okazów w latach 1860-2006 z uwzględnieniem miejsca zbioru. Narzędzie animacji pozwala analizować zmiany zjawiska w czasie w sposób ciągły „rok po roku”. Możliwe jest także generowanie map ukazujący zmiany w określonych przedziałach czasowych.
                <ul>
                    <li>
                    <img src="/images/about-portal-map-7-1.png" alt="Mapa z 1904" className="mx-auto d-block"></img>
                    <img src="/images/about-portal-map-7-2.png" alt="Mapa z 1961" className="mx-auto d-block"></img>
                    <img src="/images/about-portal-map-8-1.png" alt="Mapa z 1991" className="mx-auto d-block"></img>
                    <img src="/images/about-portal-map-8-2.png" alt="Mapa z 2006" className="mx-auto d-block"></img>
                    <p>Rozmieszczenie i liczba okazów zebranych na świecie w latach 1904-2006</p>
                    </li>
                </ul>
                </li>
            </ul>
            <SmoothHashLink to="#spis-tresci">Wróć do spisu treści</SmoothHashLink>
        </section>
        <section className="py-4 my-3" id="tworzenie-bazy-danych">
            <h2>Portal udostępnia narzędzia do tworzenia bazy danych przesłanych z aplikacji mobilnej</h2>
            <p className="p-0">Użytkownik korzystający z aplikacji mobilnej może gromadzić obserwacje i dokumenty multimedialne w obszarze Mój AMUNatColl. Po zalogowaniu w systemie i utworzeniu zespołu (w skrajnym przypadku jednoosobowego) zebrane w terenie dane można:</p>
            <ul>
                <li>uzupełniać zgodnie z formatem przyjętym dla aplikacji mobilnej</li>
                <li>przeglądać i analizować dane przy wykorzystaniu narzędzi dostępnych na portalu</li>
                <li>tworzyć albumy i mapy</li>
                <li>eksportować dane w formie plików XLS, CSV, XLSX oraz jpg i tif.</li>
            </ul>
            <p>Po wyrażeniu zgody przez użytkowników i zaakceptowaniu przez administratora systemu, dane  spełniające odpowiednie warunki mogą być włączone do głównej bazy AMUNatColl. <Link to="/jak-korzystac/">Więcej zobacz w: Jak korzystać.</Link></p>
            <SmoothHashLink to="#spis-tresci">Wróć do spisu treści</SmoothHashLink>
        </section>
        <section className="py-4 my-3" id="komunikacja">
            <h2>Portal udostępnia kanały komunikacji użytkowników z administratorem systemu</h2>
            <p>System AMUNatColl IT działa zgodnie z zasadą interkatywności, czyli jest przygotowany zarówno do odbierania informacji od użytkowników jak i reagowania na nią. Formularz kontaktowy jest udostępniony nie tylko w głównym Menu portalu, ale także na różnych poziomach pracy z danymi. Oczekuje się, że zgłaszane będą uwagi dotyczące funkcjonalności systemu, a także komentarze i propozycje merytoryczne, wynikające z przeglądania i analizy danych o różnorodności biologicznej.</p>
            <SmoothHashLink to="#spis-tresci">Wróć do spisu treści</SmoothHashLink>
        </section>
        <section className="py-4 my-3" id="zarzadzanie-systemem">
            <h2>Portal jest wyposażony w narzędzia do zarządzania systemem AMUNatColl IT</h2>
            <p>Narzędzia do zarządzania systemem AMUNatColl IT dostępne są dla administratorów odpowiedzialnych za jego funkcjonalność, koordynatorów merytorycznych, specjalistów w zakresie różnorodności biologicznej oraz pracowników zatrudnionych w procesie digitalizacji danych i wprowadzających korekty informacji znajdujących się już w bazie.</p>
            <p>Narzędzia ulokowane w obszarze administracyjnym umożliwiają między innymi:</p>
            <ul>
                <li>monitorowanie aktywności użytkowników portalu i zainteresowania poszczególnymi kolekcjami;</li>
                <li>zarządzanie rolami użytkowników zarówno w odniesieniu do portalu jak i aplikacji mobilnej;</li>
                <li>zarządzanie bazą danych, w tym eksport danych do platformy GBIF;</li>
                <li>wprowadzanie i edycję danych, w tym walidacją i konwersję.</li>
            </ul>
            <SmoothHashLink to="#spis-tresci">Wróć do spisu treści</SmoothHashLink>
        </section>
      </Container>
    </Suspense>
  )
}

const mapStateToProps = state => ({
    count: getOutput(TAXONDB_COUNT_REQUEST_ID, state),
    loadingCount: isPending(TAXONDB_COUNT_REQUEST_ID, state),
})

const mapDispatchToProps = dispatch => ({
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AboutPortalPL)
