import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { isPending } from '../reducers';
import Loader from '../utils/loader';

import NotFound from './NotFound';

const ConditionalRoute = props => (
  <>
    {props.loading &&
      <Loader />
    }
    {props.isGranted &&
      <Route {...props}></Route>
    }
    {!props.isGranted && !props.loading && props.isLoggingOut &&
      <Route {...props} render={()=><><Redirect to="/" /></>}></Route>
    }
    {!props.isGranted && !props.loading && !props.isLoggingOut &&
      <Route {...props} render={(renderProps)=><NotFound {...renderProps} />}></Route>
    }
  </>
)

const mapStateToProps = state => ({
  loading: isPending('userInfo', state),
  isLoggingOut: state.user ? state.user.isLoggingOut : null,
})

export default connect(
  mapStateToProps
)(ConditionalRoute)
