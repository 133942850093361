import React, { forwardRef } from "react"
import { connect } from 'react-redux'
import { Row, Spinner, Button, Collapse, Col, Form, FormLabel } from "react-bootstrap";
import {
    MAP_COLORPICKER_LIST,
    TAXONDB_SEARCH_WITH_AGGREGATION_REQUEST_ID,
    ARRANGEMENT_TOOLS_ARRANGEMENT_PANEL,
    TAXONDB_SEARCH_GENUS_REQUEST_ID,
    TAXON_SEARCH_IS_DYNAMIC,
    TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID,
    TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID,
    MAP_OPENED_TOOL_VISUALIZATION_DETAILS,
} from "../../../../settings"
import { getField, getFields, isPending } from "../../../../reducers";
import { useTranslation } from 'react-i18next'
import { setFields, runCartogramSearch } from '../../../../actions/forms'
import * as actions from '../../../../actions'
import { CirclePicker } from "react-color";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleRight, faMapMarked } from "@fortawesome/free-solid-svg-icons";
import { arrangementSearchItems } from "./ArrangementCartogramSearch"
import { setCartogramAdministrativeDivision, setCartogramBordersColor, setCartogramBordersWidth, setCartogramFirstAreaColor, setCartogramOpacity, setCartogramSecondAreaColor, setOpenedMapTool } from "../../../../actions/map";

const ArrangementCartogram = forwardRef((props, ref) => {

    const { t } = useTranslation(['map', 'taxondb-attributes', 'taxondb-groups', 'common'])
    const { collapsed, openSlidebar, cartogramAdministrativeDivision, cartogramBordersColor, cartogramBordersWidth, cartogramOpacity, setCartogramOpacity, setCartogramBordersWidth, setCartogramBordersColor, setCartogramAdministrativeDivision, cartogramFirstAreaColor, cartogramSecondAreaColor, setCartogramFirstAreaColor, setCartogramSecondAreaColor, setActiveMapTool, activeMapTool } = { ...props }
    
    return(
        <div>
            {collapsed &&
                <div className="sidebar-single-button-container">
                    <button
                    title={t('map:visualisation-parameters') + ' - ' + t('menu:area-map')}
                    className="sidebar-button-collapsed"
                        onClick={() => {
                            openSlidebar()
                            setActiveMapTool(MAP_OPENED_TOOL_VISUALIZATION_DETAILS)
                        }} 
                    >
                    <FontAwesomeIcon icon={faMapMarked} color={"white"}/>
                    </button>
                </div>
            }
            {!collapsed &&
                <div>
                    <div className="sidebar-opened-buttons-container">
                        <div className="sidebar-single-button-container">
                            <button
                                className="sidebar-button-collapsed"
                                title={t('map:visualisation-parameters') + ' - ' + t('menu:area-map')}
                                onClick={() => {activeMapTool === MAP_OPENED_TOOL_VISUALIZATION_DETAILS ? setActiveMapTool(null) : setActiveMapTool(MAP_OPENED_TOOL_VISUALIZATION_DETAILS)}}
                            >
                                <FontAwesomeIcon icon={faMapMarked} color={"white"}/>
                            </button>
                        </div>
                        <div
                            className="sidebar-text playContentOpacityAnimation changeContentOpacityAnimation"
                            onClick={() => {activeMapTool === MAP_OPENED_TOOL_VISUALIZATION_DETAILS ? setActiveMapTool(null) : setActiveMapTool(MAP_OPENED_TOOL_VISUALIZATION_DETAILS)}}
                        >  
                            {t('map:visualisation-parameters') + ' - ' + t('menu:area-map')} 
                        </div> 

                    <div className="sidebar-items-arrow changeOpacityAnimation playOpacityAnimation">
                        <FontAwesomeIcon icon={activeMapTool === MAP_OPENED_TOOL_VISUALIZATION_DETAILS ? faAngleDown : faAngleRight} color={"black"}/>
                    </div>
                </div>
                <Collapse in={activeMapTool === MAP_OPENED_TOOL_VISUALIZATION_DETAILS}>
                    <div className="ml-4 playContentOpacityAnimation changeContentOpacityAnimation"> 
                        {props.showArrangementPanel &&
                        <>
                            <Row>
                                <Form.Group as={Col} sm={6} md={6}>
                                    <Form.Label htmlFor={'arrangementCartogramField'}>
                                        {t("menu:cartogram")} 
                                    </Form.Label>
                                    <Form.Control as="select" size="sm"  
                                        id={'arrangementCartogramField'} 
                                        name={'arrangementCartogramField'}
                                        disabled={props.loading} 
                                        onChange={(e) => {
                                                if (e.target.value){
                                                    setCartogramAdministrativeDivision(e.target.value)
                                                }
                                            }
                                        }
                                        defaultValue={cartogramAdministrativeDivision}
                                    >
                                        <option value="panstwo" id="panstwo">{t('taxondb-attributes:panstwo')}</option>
                                        <option value="wojewodztwo" id="wojewodztwo">{t('taxondb-attributes:wojewodztwo')}</option>
                                        <option value="powiat" id="powiat" >{t('taxondb-attributes:powiat')}</option>
                                        <option value="gmina" id="gmina">{t('taxondb-attributes:gmina')}</option>
                                        <option value="parknarodowy" id="parknarodowy">{t('taxondb-attributes:parknarodowy')}</option>
                                        <option value="parkkrajobrazowy" id="parkkrajobrazowy">{t('taxondb-attributes:parkkrajobrazowy')}</option>
                                        <option value="polozeniewpodzialefizjograficznym" id="polozeniewpodzialefizjograficznym">{t('taxondb-attributes:polozeniewpodzialefizjograficznym')}</option>
                                        <option value="wspolrzedneatpol" id="wspolrzedneatpol">{t('taxondb-attributes:wspolrzedneatpol')}</option>
                                        <option value="wspolrzedneutm" id="wspolrzedneutm">{t('taxondb-attributes:wspolrzedneutm')}</option>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group as={Col} sm={6} md={6}>
                                    <Form.Label htmlFor={'arrangementBorderColor'}>
                                        {t("map:borders-color")} <span className="color-picker-tip" style={{backgroundColor: cartogramBordersColor}} />
                                    </Form.Label>
                                    <CirclePicker
                                        id={'arrangementBorderColor'} 
                                        name={'arrangementBorderColor'}
                                        onChange={color => setCartogramBordersColor(color.hex)}
                                        colors={MAP_COLORPICKER_LIST}
                                        width={"100%"}
                                        circleSize={24}
                                    />
                                </Form.Group>
                            </Row>

                            <Row>
                                <Form.Group as={Col} sm={6} md={6}>
                                    <Form.Label htmlFor={'cartogramStartColor'}>
                                        {t("map:arrangement-cartogram-no-taxons-color")} <span className="color-picker-tip" style={{backgroundColor: cartogramFirstAreaColor}} />
                                    </Form.Label>
                                    <CirclePicker
                                        id={'cartogramStartColor'} 
                                        name={'cartogramStartColor'}
                                        onChange={color => setCartogramFirstAreaColor(color.hex)}
                                        colors={MAP_COLORPICKER_LIST}
                                        width={"100%"}
                                        circleSize={24}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} sm={6} md={6}>
                                    <Form.Label htmlFor={'cartogramEndColor'}>
                                        {t("map:arrangement-cartogram-with-taxons-color")} <span className="color-picker-tip" style={{backgroundColor: cartogramSecondAreaColor}} />
                                    </Form.Label>
                                    <CirclePicker              
                                        id={'cartogramEndColor'} 
                                        name={'cartogramEndColor'}
                                        onChange={color => setCartogramSecondAreaColor(color.hex)}
                                        colors={MAP_COLORPICKER_LIST}
                                        width={"100%"}
                                        circleSize={24}
                                    />
                                </Form.Group>
                            </Row>

                            <Row>
                                <Form.Group as={Col} sm={6} md={6}>
                                    <FormLabel htmlFor={"borderOpacity"}>
                                        {t("map:borders-width")}
                                    </FormLabel>
                                    <Form.Control 
                                        id={"borderOpacity"} 
                                        type="number" 
                                        name="borderOpacity"
                                        disabled={props.loading}
                                        defaultValue={cartogramBordersWidth} 
                                        min={0} max={1} step={0.1}
                                        onChange={e => {
                                            setCartogramBordersWidth(e.target.value)
                                        }
                                            }
                                    />
                                </Form.Group>
                                <Form.Group as={Col} sm={6} md={6}>
                                    <FormLabel htmlFor={"cartogramOpacity"}>
                                        {t("map:cartogram-opacity")}
                                    </FormLabel>
                                    <Form.Control 
                                        id={"cartogramOpacity"} 
                                        type="number" 
                                        name="cartogramOpacity"
                                        disabled={props.loading}
                                        defaultValue={cartogramOpacity} 
                                        min={0} max={1} step={0.1}
                                        onChange={e => setCartogramOpacity(e.target.value)}
                                    />
                                </Form.Group>
                            </Row>

                            <Row>
                                <Col md={12} sm={12} className="d-flex align-items-end" >
                                    <div className="flex-grow-1" />
                                    {props.loading &&
                                        <Spinner 
                                            animation="border"
                                            size="sm"
                                            aria-hidden="true"
                                            className="mb-3 mr-2"
                                        />
                                    }
                                    <Button
                                        className="mb-3 ml-2 mr-3"
                                        disabled={props.loading}
                                        onClick={() => arrangementSearchItems(ref, props, t)}
                                    >
                                        {t('map:show-on-map')}
                                    </Button>
                                </Col>
                            </Row>
                        </>
                        }
                    </div>
                </Collapse>
            </div>
            }
        </div>
    )
})

const mapStateToProps = state => ({
    filter1: getFields(TAXONDB_SEARCH_GENUS_REQUEST_ID, state),
    filter2: JSON.parse(getField(TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID, TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID, state, "null")),
    filter3: JSON.parse(getField(TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, state, "null")),
    isDynamic: getField(TAXON_SEARCH_IS_DYNAMIC, TAXON_SEARCH_IS_DYNAMIC, state, "0"),
    fields1: getFields(TAXONDB_SEARCH_GENUS_REQUEST_ID, state),
    fields2: getFields(TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID, state),
    fields3: getFields(TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, state),
    loading: isPending(TAXONDB_SEARCH_WITH_AGGREGATION_REQUEST_ID, state),
    showArrangementPanel: getField(ARRANGEMENT_TOOLS_ARRANGEMENT_PANEL, ARRANGEMENT_TOOLS_ARRANGEMENT_PANEL, state, true),
    cartogramAdministrativeDivision: state.map.cartogramAdministrativeDivision,
    cartogramBordersColor: state.map.cartogramBordersColor,
    cartogramBordersWidth: state.map.cartogramBordersWidth,
    cartogramOpacity: state.map.cartogramOpacity,
    cartogramFirstAreaColor: state.map.cartogramFirstAreaColor,
    cartogramSecondAreaColor: state.map.cartogramSecondAreaColor,
    activeMapTool: state.map.openedMapTool,
})

const mapDispatchToProps = dispatch => ({
    setFields: (id, val) => dispatch(setFields(id, val)),
    clearResponse: (id) => dispatch(actions.requestRemove(id)),
    runCartogramSearch: (id, path) => dispatch(runCartogramSearch(id, path)),
    setCartogramAdministrativeDivision: data => dispatch(setCartogramAdministrativeDivision(data)),
    setCartogramBordersColor: data => dispatch(setCartogramBordersColor(data)),
    setCartogramBordersWidth: data => dispatch(setCartogramBordersWidth(data)),
    setCartogramOpacity: data => dispatch(setCartogramOpacity(data)),
    setCartogramFirstAreaColor: data => dispatch(setCartogramFirstAreaColor(data)),
    setCartogramSecondAreaColor: data => dispatch(setCartogramSecondAreaColor(data)),
    setActiveMapTool: (value) => dispatch(setOpenedMapTool(value)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(ArrangementCartogram)
