import React, { useState } from "react";
import { connect } from 'react-redux'
import { Button, Spinner, Form, FormLabel, Alert } from "react-bootstrap"
import { postFileAnc } from "../../actions"
import {
  ANC_ADMIN_IMPORT_REQUEST_ID,
  ANC_ADMIN_IMPORT_REQUEST_URL,
  EDITOR_TOOLS_EDITION_TYPES,
  EDITOR_TOOLS_SHEET_TYPES,
} from '../../settings'
import { isPending } from "../../reducers";
import { useTranslation } from 'react-i18next'
import useFieldRequiredValidation from '../../hooks/validation/useFieldRequiredValidation';
import useFormValidation from '../../hooks/validation/useFormValidation';
import { Redirect } from "react-router-dom";

const Importer = props => {

  const { t } = useTranslation([ 'editor-tools' ])

  const [ file, setFile ] = useState("")
  const [ fileName, setFileName ] = useState("")
  const [ sheetType, setSheetType ] = useState(EDITOR_TOOLS_SHEET_TYPES[ 0 ])
  const [ editionType, setEditionType ] = useState(EDITOR_TOOLS_EDITION_TYPES[ 0 ])
  const [ showMsg, setShowMsg ] = useState(false)
  const [ msg, setMsg ] = useState("")
  const [ isError, setIsError ] = useState(false)

  const [ redirect, setRedirect ] = useState(false)

  const goto = url => {
    window.scrollTo(0, 0)
    setRedirect(url)
  }


  const { validProps: fileValidProps, isValidCallback: isFileValid, clearCallback: clearFileV } = useFieldRequiredValidation(file);

  const handleSubmit = () => {
    const data = new FormData()
    data.append('file', file)
    data.append('icon_type', sheetType)
    data.append('edition_type', editionType)
    props.send(data).then(res => {
      setIsError(false)
      setMsg( `${t('task-id')}: ${res.task_id}`)
      window.document.getElementById('file-validator').value = null
      setFile("")
      setFileName("")
      setShowMsg(true)
    }).catch(err => {
      if (err.response && err.response.status === 400) {
        setIsError(true)
        setMsg(t('wrong-file-format'))
        setShowMsg(true)
      } else {
        setIsError(true)
        setMsg(t('cannot-send-file'))
        setShowMsg(true)
      }
    })
  }

  const { onSubmit } = useFormValidation([ isFileValid ], handleSubmit);

  return (
    <div>
      {redirect && 
        <Redirect to={redirect}></Redirect>
      }
      <h2>{t('db-update')}</h2>
      <Form onSubmit={onSubmit}>
        <Form.Group controlId="file-validator">
          <FormLabel>{t('file')}</FormLabel>
          <Form.File
            {...fileValidProps}
            custom
            type="file"
            label={fileName}
            data-browse={t('select-file')}
            onChange={e => {
              if (e.target.files && e.target.files[ 0 ] && e.target.files[ 0 ].name) {
                setFileName(e.target.files[ 0 ].name)
                setFile(e.target.files[ 0 ]);
              } else {
                setFileName("")
                setFile("");
              }
              clearFileV();
            }}
            disabled={props.pending}
          ></Form.File>
        </Form.Group>
        <Form.Group controlId="sheet-type">
          <FormLabel>{t('sheet-type')}</FormLabel>
          <Form.Control
            onChange={e => {
              setSheetType(e.target.value);
            }}
            value={sheetType}
            disabled={props.pending}
            as="select"
          >
            {EDITOR_TOOLS_SHEET_TYPES.map((val, indx) => (
              <option value={val} key={indx}>{t(`sheet-type-${indx}`)}</option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="edition-type">
          <FormLabel>{t('edition-type')}</FormLabel>
          <Form.Control
            onChange={e => {
              setEditionType(e.target.value);
            }}
            value={editionType}
            disabled={props.pending}
            as="select"
          >
            {EDITOR_TOOLS_EDITION_TYPES.map((val, indx) => (
              <option value={val} key={indx}>{t(`edition-type-${indx}`)}</option>
            ))}
          </Form.Control>
        </Form.Group>
        <Button variant="primary" type="submit" disabled={props.pending}>
          {t('send-button')}
          {props.pending &&
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="ml-1"
            />
          }
        </Button>
      </Form>
      {showMsg && !isError &&
      <Alert variant="success" className="mt-3">
          <Alert.Heading>
            {t('task-submitted')}
          </Alert.Heading>
          <p className="my-3 p-0">{msg}</p>
          <Button className="mr-3" onClick={e => {setShowMsg(false)}}>OK</Button>
          <Button onClick={e => { goto(t('paths:task-history')) }}>{t('go-to-task-list')}</Button>
      </Alert>}
      {showMsg && isError &&
      <Alert variant="danger" className="mt-3">
          <Alert.Heading>
            {t('error')}
          </Alert.Heading>
          <p className="my-3 p-0">{msg}</p>
          <Button className="mr-3" onClick={e => {setShowMsg(false)}}>OK</Button>
          <Button onClick={e => { goto(t('paths:task-history')) }}>{t('go-to-task-list')}</Button>
      </Alert>}
    </div>
  )
}

const mapStateToProps = state => ({
  pending: isPending(ANC_ADMIN_IMPORT_REQUEST_ID, state),
})

const mapDispatchToProps = dispatch => ({
  send: data => dispatch(postFileAnc(ANC_ADMIN_IMPORT_REQUEST_ID, ANC_ADMIN_IMPORT_REQUEST_URL, data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Importer)
