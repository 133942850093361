export const ADD_PICTURE_1 = 'ADD_PICTURE_1'
export const ADD_PICTURE_2 = 'ADD_PICTURE_2'
export const CLEAR_COMPARATOR = 'CLEAR_COMPARATOR'

export const addPictureOne = data => ({
    type: ADD_PICTURE_1,
    data: data
})

export const addPictureTwo = data => ({
    type: ADD_PICTURE_2,
    data: data
})

export const clearComparator = () => ({
    type: CLEAR_COMPARATOR
})