import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Button } from "react-bootstrap"
import {
    ICONDB_DETAILS_PAGE_URL,
} from '../../settings'
import * as notify from '../../utils/notify'

function Citation(props) {

    const { t } = useTranslation([ 'citation' ])

    return (
        <>
            <div className="d-flex flex-fill justify-content-center">
                <Button variant="light" title={t('common:share')} size="sm" className="" onClick={e => {
                    navigator.clipboard.writeText(
                        t('item-id') + '\n' +
                        window.location.origin + ICONDB_DETAILS_PAGE_URL + props.data.edi_id + '\n\n' +
                        t('in-print') + '\n' +
                        t('print-content') + '\n\n' +
                        t('amunatcoll-db-website') + '\n' +
                        window.location.origin + '\n\n'
                    )
                    notify.success(t('common:copied-to-clipboard'))
                }
                }
                >{t('copy-text')}
                </Button>
            </div>
            <div className="d-flex flex-column bd-highlight">
                <div className="ml-3 bd-highlight">
                    {t('item-id')}
                </div>
                <div className="ml-3 mb-2 bd-highlight">
                    {window.location.origin + ICONDB_DETAILS_PAGE_URL + props.data.edi_id}
                </div>
                <div className="ml-3 bd-highlight">
                    {t('in-print')}
                </div>
                <div className="ml-3 mb-2 bd-highlight">
                    {t('print-content')}
                </div>
                <div className="ml-3 bd-highlight">
                    {t('amunatcoll-db-website')}
                </div>
                <div className="ml-3 mb-2 bd-highlight">
                    {window.location.origin}
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state, ownProps) => ({
})

const mapDispatchToProps = {
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Citation)
