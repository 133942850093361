import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Collapse } from  "react-bootstrap"; 
import SaveMapPanel from "./SaveMapPanel";
import SaveMapAsPanel from "./SaveMapAsPanel";
import LoadMapPanel from "./LoadMapPanel";
import ClearMapPanel from './ClearMapPanel';

const SinglePanelRow = props => {
  const { t } = useTranslation(['map']);
  const [ isOpen, setIsOpen ] = useState(false);
  const [ isMounted, setIsMounted ] = useState(false);
  return(
    <>
      <div className="d-flex flex-row album-element align-items-center border p-3"
          onClick={e => setIsOpen(!isOpen)}>
        <div className="flex-grow-1"
            aria-controls={props.label}
            aria-expanded={isOpen}>
                <b>{t(props.label)}</b>
        </div>
        <div className="d-flex">
            <Button
                variant="secondary"
                size="sm"
                className="align-self-center"
            >
                {!isOpen &&
                    <span>{t('map:more')}</span>
                }
                {isOpen &&
                    <span>{t('map:hide')}</span>
                }
            </Button>
        </div>
      </div>
      <Collapse 
        in={isOpen} // to trigger expand/collapse action
        onEnter={() => setIsMounted(true)} // to mount child component *BEFORE* expanding (smooth animation)
        onExited={() => setIsMounted(false)} // to unmount child component *AFTER* collapsing (smooth animation)
        id={props.label}
      >
        <div>
          {isMounted &&
            <div className="ml-2 p-2">
                <div className="border">
                  <div className="mx-2 mb-3">
                    {props.children}
                  </div>
                </div>
            </div>
            }
        </div>
      </Collapse>
    </>
  )
}

const SaveLoadMapContainer  = props => {

  return(
    <div className="border">
      <SinglePanelRow label="map:save-map">
        <SaveMapPanel {...props}></SaveMapPanel>
      </SinglePanelRow>
      <SinglePanelRow label="map:save-map-as">
        <SaveMapAsPanel {...props}></SaveMapAsPanel>
      </SinglePanelRow>
      <SinglePanelRow label="map:clear-map">
        <ClearMapPanel {...props}></ClearMapPanel>
      </SinglePanelRow>
      <SinglePanelRow label="map:load-map">
        <LoadMapPanel {...props}></LoadMapPanel>
      </SinglePanelRow>
    </div>
  )
}

export default SaveLoadMapContainer
