import React from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { setField } from '../../actions/forms'
import { requestRemove, requestRemoveStartsWith } from '../../actions'
import { isPending, getField } from "../../reducers"
import {
    SAMPLESDB_SEARCH_REQUEST_ID,
    SAMPLES_AGGREGATION_REPORT_REQUEST_ID_PREFIX,
} from '../../settings'
import { DropdownButton, Dropdown, ButtonGroup, NavDropdown } from "react-bootstrap"
import { NUMER_PROBY_ATTRIBUTE_NAME } from './attributesStructure'
import { setElementStructure, getElementStructure, sortNot } from '../../utils/search'
import DynamicSearchFormElement from './DynamicSearchFormElement2'
import InnerPanel from './DynamicSearchFormPanel2'

const DynamicSearchFormPanel = props => {
    const { t } = useTranslation([ 'common', 'taxondb', 'samplesdb-attributes', 'forms', 'samplesdb-groups' ])

    const addDynamicField = (id) => {
        let temp = { ...props.fields }
        const next = Object.keys(props.thisField).reduce((acc, obj) => {
            if (obj.startsWith("or_")) {
                const n = parseInt(obj.substring(3))
                return Math.max(acc, n + 1)
            } else if (obj.startsWith("not_")) {
                const n = parseInt(obj.substring(4))
                return Math.max(acc, n + 1)
            }
            return acc
        }, 0)
        if (Object.keys(props.thisField).filter(i => i === id).length > 0) {
            const output = {}
            Object.keys(temp).forEach(v => {
                if (v !== id) {
                    output[ v ] = temp[ v ]
                } else {
                    output[ "or_" + next ] = [ { [ id ]: temp[ id ] }, { [ id ]: '' } ]
                }
            })
            temp = output
        } else {
            const containingOr = Object.keys(props.thisField).filter(
                i => (
                    i.startsWith("or_") && props.thisField[ i ].find(j => (id in j))
                )
            )
            if (containingOr.length > 0) {
                temp = setElementStructure([ ...props.path, containingOr[ 0 ] ], temp, [ ...temp[ containingOr[ 0 ] ], { [ id ]: '' } ])
            } else {
                if (Object.keys(temp).filter(v => (v.startsWith('not_') && id in temp[ v ])).length) {
                    const output = {}
                    let keyFound = false
                    Object.keys(temp).forEach(v => {
                        if (v.startsWith("not_") && (id in props.thisField[ v ])) {
                            keyFound = true
                        } else {
                            if (keyFound) {
                                keyFound = false
                                output[ id ] = ""
                            }
                        }
                        output[ v ] = temp[ v ]
                    })
                    if (keyFound) {
                        keyFound = false
                        output[ id ] = ""
                    }
                    temp = output
                } else {
                    temp = setElementStructure([ ...props.path, id ], temp, "")
                }
            }
        }
        props.setFields(temp)
        props.clearResponse()
    }

    const SingleMenuItem = props =>
        <>
            {!props.label &&
                <Dropdown.Item onClick={e => addDynamicField(props.id)}>{t('samplesdb-attributes:' + props.id)}</Dropdown.Item>
            }
            {props.label &&
                <Dropdown.Item onClick={e => addDynamicField(props.id)}>{props.label}</Dropdown.Item>
            }
        </>

    return (
        <div className="form-inline">
            <div>
                {props.elementId.startsWith("or_") &&
                    <div>
                        {props.thisField && props.thisField.map((val, indx) =>
                            <div key={indx} className="d-flex justify-content-end">
                                <InnerPanel path={[ ...props.path, indx ]} elementId="and"></InnerPanel>
                            </div>
                        )}
                    </div>
                }
                {!(props.elementId.startsWith("or_")) &&
                    <div>
                        {Object.keys(props.thisField).map((val, indx) =>
                            <div key={indx} className="d-flex justify-content-end">
                                {val.startsWith("or_") &&
                                    <InnerPanel path={[ ...props.path, val ]} elementId={val}></InnerPanel>
                                }
                                {val.startsWith("not_") &&
                                    <InnerPanel
                                        path={[ ...props.path, val ]}
                                        elementId={val}
                                        previous={indx > 0 &&
                                            Object.keys(props.thisField).filter(
                                                (o, i) => (
                                                    i < indx &&
                                                    o.startsWith('not_') &&
                                                    Object.keys(props.thisField[ o ])[ 0 ] === Object.keys(props.thisField[ val ])[ 0 ]
                                                )).length
                                        }></InnerPanel>
                                }
                                {(!val.startsWith("or_") && !val.startsWith("not_")) &&
                                    <DynamicSearchFormElement path={[ ...props.path, val ]} elementId={val} previous={props.previous}></DynamicSearchFormElement>
                                }
                            </div>
                        )}
                    </div>
                }
                {(props.path.length === 0 || (props.elementId.startsWith('not_') && Object.keys(props.thisField).length === 0)) &&
                    <div className="mt-2">
                        <DropdownButton navbar as={ButtonGroup} title={t('add')} disabled={props.loading}>
                            <NavDropdown title={t('samplesdb-groups:group-1')}>
                                <SingleMenuItem id={NUMER_PROBY_ATTRIBUTE_NAME}></SingleMenuItem>
                            </NavDropdown>
                            <NavDropdown title={t('samplesdb-groups:group-2')}>
                                <SingleMenuItem id="lokalizacjastanowisko"></SingleMenuItem>
                                <SingleMenuItem id="polozeniewzgledempoziomumorza"></SingleMenuItem>
                                <SingleMenuItem id="szerokoscgeograficzna"></SingleMenuItem>
                                <SingleMenuItem id="dlugoscgeograficzna"></SingleMenuItem>
                                <SingleMenuItem id="wspolrzedneutm"></SingleMenuItem>
                                <SingleMenuItem id="wspolrzedneatpol"></SingleMenuItem>
                                <SingleMenuItem id="georeferencjakomentarze"></SingleMenuItem>
                                <SingleMenuItem id="lokalizacjakomentarze"></SingleMenuItem>
                                <SingleMenuItem id="obszarchroniony"></SingleMenuItem>
                                <SingleMenuItem id="parknarodowy"></SingleMenuItem>
                                <SingleMenuItem id="parkkrajobrazowy"></SingleMenuItem>
                                <SingleMenuItem id="rezerwatprzyrody"></SingleMenuItem>
                                <SingleMenuItem id="rodzajityprezerwatu"></SingleMenuItem>
                                <SingleMenuItem id="obszarchronionegokrajobrazu"></SingleMenuItem>
                                <SingleMenuItem id="uzytekekologiczny"></SingleMenuItem>
                                <SingleMenuItem id="zespolprzyrodniczokrajobrazowy"></SingleMenuItem>
                                <SingleMenuItem id="koditypobszarunatura2000"></SingleMenuItem>
                                <SingleMenuItem id="nazwaobszarunatura2000"></SingleMenuItem>
                                <SingleMenuItem id="kontynent"></SingleMenuItem>
                                <SingleMenuItem id="panstwo"></SingleMenuItem>
                                <SingleMenuItem id="wojewodztwo"></SingleMenuItem>
                                <SingleMenuItem id="powiat"></SingleMenuItem>
                                <SingleMenuItem id="gmina"></SingleMenuItem>
                                <SingleMenuItem id="polozeniewpodzialefizjograficznym"></SingleMenuItem>
                                <SingleMenuItem id="polozeniewpodzialebiogeograficznymeuropy"></SingleMenuItem>
                                <SingleMenuItem id="polozeniewpodzialegeograficznympotencjalnejroslinnoscinaturalne"></SingleMenuItem>
                                <SingleMenuItem id="locationsite"></SingleMenuItem>
                                <SingleMenuItem id="srodowisko"></SingleMenuItem>
                                <SingleMenuItem id="habitat"></SingleMenuItem>
                                <SingleMenuItem id="oryginalnyopis"></SingleMenuItem>
                                <SingleMenuItem id="autorzbioru"></SingleMenuItem>
                                <SingleMenuItem id="datazebrania"></SingleMenuItem>
                                <SingleMenuItem id="sprawdzonegrupyzwierzat"></SingleMenuItem>
                            </NavDropdown>
                            <NavDropdown title={t('samplesdb-groups:group-3')}>
                                <SingleMenuItem id="charakterproby"></SingleMenuItem>
                                <SingleMenuItem id="sposobpobierania"></SingleMenuItem>
                                <SingleMenuItem id="merocenoza"></SingleMenuItem>
                                <SingleMenuItem id="merocenozatyp"></SingleMenuItem>
                                <SingleMenuItem id="merocenozagospodarz"></SingleMenuItem>
                                <SingleMenuItem id="merocenozamaterial"></SingleMenuItem>
                                <SingleMenuItem id="merocenozapolozeniewzgledemgruntu"></SingleMenuItem>
                                <SingleMenuItem id="merocenozawielkosckopcakretowiska"></SingleMenuItem>
                                <SingleMenuItem id="merocenozatypgniazdaptaka"></SingleMenuItem>
                                <SingleMenuItem id="merocenozamartwegodrewnastopienrozkladu"></SingleMenuItem>
                                <SingleMenuItem id="merocenozasrednicadrzewa"></SingleMenuItem>
                                <SingleMenuItem id="merocenozadlugoscdrzewa"></SingleMenuItem>
                                <SingleMenuItem id="merocenozagatunekdrzewa"></SingleMenuItem>
                                <SingleMenuItem id="wilgotnoscprobki"></SingleMenuItem>
                                <SingleMenuItem id="dodatkowyopis"></SingleMenuItem>
                            </NavDropdown>
                            <NavDropdown title={t('samplesdb-groups:group-4')}>
                                <SingleMenuItem id="bibliografia"></SingleMenuItem>
                            </NavDropdown>
                        </DropdownButton>
                    </div>
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    loading: isPending(SAMPLESDB_SEARCH_REQUEST_ID, state),
    fields: JSON.parse(getField(SAMPLESDB_SEARCH_REQUEST_ID, SAMPLESDB_SEARCH_REQUEST_ID, state, "null")),
    thisField: getElementStructure(ownProps.path,
        JSON.parse(getField(SAMPLESDB_SEARCH_REQUEST_ID, SAMPLESDB_SEARCH_REQUEST_ID, state, "null"))),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    setFields: fields => dispatch(setField(SAMPLESDB_SEARCH_REQUEST_ID, SAMPLESDB_SEARCH_REQUEST_ID, JSON.stringify(sortNot(fields)))),
    clearResponse: () => {
        dispatch(requestRemove(SAMPLESDB_SEARCH_REQUEST_ID))
        dispatch(requestRemoveStartsWith(SAMPLES_AGGREGATION_REPORT_REQUEST_ID_PREFIX + SAMPLESDB_SEARCH_REQUEST_ID))
    },
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DynamicSearchFormPanel)
