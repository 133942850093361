import { useCallback, useRef, useState } from 'react';
import L from 'leaflet';
import shp from 'shpjs/dist/shp'
import gminy_3857 from '../shapefiles/gminy_3857.zip'
import ObszaryChronionegoKrajobrazuPolygon_3857 from '../shapefiles/ObszaryChronionegoKrajobrazuPolygon_3857.zip'
import ObszarySpecjalnejOchronyPolygon_3857 from '../shapefiles/ObszarySpecjalnejOchronyPolygon_3857.zip'
import Panstwa_3857 from '../shapefiles/Panstwa_3857.zip'
import ParkiKrajobrazowePolygon_3857 from '../shapefiles/ParkiKrajobrazowePolygon_3857.zip'
import ParkiNarodowePolygon_3857 from '../shapefiles/ParkiNarodowePolygon_3857.zip'
import powiaty_3857 from '../shapefiles/powiaty_3857.zip'
import RezerwatyPolygon_3857 from '../shapefiles/RezerwatyPolygon_3857.zip'
import SpecjalneObszaryOchronyPolygon_3857 from '../shapefiles/SpecjalneObszaryOchronyPolygon_3857.zip'
import StanowiskaDokumentacyjnePolygon_3857 from '../shapefiles/StanowiskaDokumentacyjnePolygon_3857.zip'
import UzytkiEkologicznePolygon_3857 from '../shapefiles/UzytkiEkologicznePolygon_3857.zip'
import wojewodztwa_3857 from '../shapefiles/wojewodztwa_3857.zip'
import ZespolyPrzyrodniczoKrajobrazowePolygon_3857 from '../shapefiles/ZespolyPrzyrodniczoKrajobrazowePolygon_3857.zip'
import LocationPhysiographicDivision from '../shapefiles/LocationPhysiographicDivision.zip'
import UTM33 from '../shapefiles/UTM33.zip'
import UTM34 from '../shapefiles/UTM34.zip'
import '../utils/map/leaflet.loader'
import * as notify from '../utils/notify'
import useMapRedux from './useMapRedux';
import { useTranslation } from 'react-i18next'
import { MAP_LAYERS } from '../stateIds'

const useShapefilesHandler = () => {
    const { value: mapLayers, setValue: setMapLayers } = useMapRedux(MAP_LAYERS, {});
    const { t } = useTranslation(['map'])
    const [loadingShapefile, setLoadingShapefile] = useState(false)
    const { value: administrativeDivisionStyle } = useMapRedux('administrativeDivisionStyle')

    const Panstwa_3857_ref = useRef(null)
    const wojewodztwa_3857_ref = useRef(null)
    const powiaty_3857_ref = useRef(null)
    const gminy_3857_ref = useRef(null)
    const ParkiNarodowePolygon_3857_ref = useRef(null)
    const ParkiKrajobrazowePolygon_3857_ref = useRef(null)
    const RezerwatyPolygon_3857_ref = useRef(null)
    const SpecjalneObszaryOchronyPolygon_3857_ref = useRef(null)
    const ObszarySpecjalnejOchronyPolygon_3857_ref = useRef(null)
    const UzytkiEkologicznePolygon_3857_ref = useRef(null)
    const ObszaryChronionegoKrajobrazuPolygon_3857_ref = useRef(null)
    const ZespolyPrzyrodniczoKrajobrazowePolygon_3857_ref = useRef(null)
    const StanowiskaDokumentacyjnePolygon_3857_ref = useRef(null)
    const LocationPhysiographicDivision_ref = useRef(null)
    const UTM_PL_ref = useRef(null)

    const handleShapefilesLayers = useCallback((obj, mapRef) => {

      function bindShapePopup(feature, layer) {
          if (feature.properties && feature.properties.nazwa) {
              layer.bindPopup(`<b>${feature.properties.nazwa}</b>`)
              if (administrativeDivisionStyle){
                  layer.setStyle(administrativeDivisionStyle)
              }
              else{
                  layer.setStyle({ fillColor: 'transparent', color: '#006400' })
              }
          }
      }

      function popup_kraje(feature, layer) {
          if (feature.properties && feature.properties.NAME_PL) {
              layer.bindPopup(`<b>${feature.properties.NAME_PL}</b>`)
              if (administrativeDivisionStyle){
                  layer.setStyle(administrativeDivisionStyle)
              }
              else{
                  layer.setStyle({ fillColor: 'transparent', color: '#000105' })
              }
          }
      }

      function popup_wojewodztwa(feature, layer) {
          if (feature.properties && feature.properties.jpt_nazwa_) {
              layer.bindPopup(`<b>${feature.properties.jpt_nazwa_}</b>`)
              if (administrativeDivisionStyle){
                  layer.setStyle(administrativeDivisionStyle)
              }
              else{
                  layer.setStyle({ fillColor: 'transparent', color: '#19265e' })
              }
          }
      }

      function popup_powiaty(feature, layer) {
          if (feature.properties && feature.properties.jpt_nazwa_) {
              layer.bindPopup(`<b>${feature.properties.jpt_nazwa_}</b>`)
              if (administrativeDivisionStyle){
                  layer.setStyle(administrativeDivisionStyle)
              }
              else{
                  layer.setStyle({ fillColor: 'transparent', color: '#4658a6' })
              }
          }
      }

      function popup_gminy(feature, layer) {
          if (feature.properties && feature.properties.jpt_nazwa_) {
              layer.bindPopup(`<b>${feature.properties.jpt_nazwa_}</b>`)
              //layer.setStyle({fillColor :'transparent', color: '##0029cf'})
              if (administrativeDivisionStyle){
                  layer.setStyle(administrativeDivisionStyle)
              }
              else{
                  layer.setStyle({ fillColor: 'transparent', color: '#4658a6' })
              }
          }
      }

      function popup_podzial_fizycznogeograficzny(feature, layer) {
          if (feature.properties && feature.properties["Kondr"]) {
              layer.bindPopup(`<b>${feature.properties["Kondr"]}</b>`)
              if (administrativeDivisionStyle){
                  layer.setStyle(administrativeDivisionStyle)
              }
              else{
                  layer.setStyle({ fillColor: 'transparent', color: '#19265e' })
              }
          }
      }

      function popup_UTM_PL(feature, layer) {
          if (feature.properties && feature.properties.MGRS_10km) {
              layer.bindPopup(`<b>${feature.properties.MGRS_10km}</b>`)
              if (administrativeDivisionStyle){
                  layer.setStyle(administrativeDivisionStyle)
              }
              else{
                  layer.setStyle({ fillColor: 'transparent', color: '#19265e' })
              }
          }
      }


      const shapefilesLayers = { ...mapLayers.shapefilesLayers }
      shapefilesLayers[obj.name] = obj.checked ? true : false
        if (obj.checked) {

            var loader = L.control.loader()
            loader.addTo(mapRef.current);
            setLoadingShapefile(true)

            if (obj.name === "Panstwa_3857") {
                Panstwa_3857_ref.current = new L.FeatureGroup();
                Panstwa_3857_ref.current.name = "Panstwa_3857";
                shp(Panstwa_3857)
                .then(function (geojson) {
                    L.geoJSON(geojson, {
                        onEachFeature: popup_kraje
                    }).addTo(Panstwa_3857_ref.current);
                })
                .then(() => {
                    mapRef.current.addLayer(Panstwa_3857_ref.current)
                    if (loader) {
                        loader.hide();
                    }
                    setLoadingShapefile(false)
                })
                .catch(() => {
                    notify.error(t("map:shapefile-error-msg"))
                    if (loader) {
                        loader.hide();
                    }
                    setLoadingShapefile(false)
                })
            }

            else if (obj.name === "wojewodztwa_3857") {
                wojewodztwa_3857_ref.current = new L.FeatureGroup();
                wojewodztwa_3857_ref.current.name = "wojewodztwa_3857";
                shp(wojewodztwa_3857).then(function (geojson) {
                    L.geoJSON(geojson, {
                        onEachFeature: popup_wojewodztwa
                    }).addTo(wojewodztwa_3857_ref.current);
                })
                .then(() => {
                    mapRef.current.addLayer(wojewodztwa_3857_ref.current)
                    if (loader) {
                        loader.hide();
                    }
                    setLoadingShapefile(false)
                })
                .catch(() => {
                    notify.error(t("map:shapefile-error-msg"))
                    if (loader) {
                        loader.hide();
                    }
                    setLoadingShapefile(false)
                })
            }

            else if (obj.name === "powiaty_3857") {
                powiaty_3857_ref.current = new L.FeatureGroup();
                powiaty_3857_ref.current.name = "powiaty_3857";
                shp(powiaty_3857).then(function (geojson) {
                    L.geoJSON(geojson, {
                        onEachFeature: popup_powiaty
                    }).addTo(powiaty_3857_ref.current);
                })
                .then(() => {
                    mapRef.current.addLayer(powiaty_3857_ref.current)
                    if (loader) {
                        loader.hide();
                    }
                    setLoadingShapefile(false)
                })
                .catch(() => {
                    notify.error(t("map:shapefile-error-msg"))
                    if (loader) {
                        loader.hide();
                    }
                    setLoadingShapefile(false)
                })
            }

            else if (obj.name === "gminy_3857") {
                gminy_3857_ref.current = new L.FeatureGroup();
                gminy_3857_ref.current.name = "gminy_3857";
                shp(gminy_3857).then(function (geojson) {
                    L.geoJSON(geojson, {
                        onEachFeature: popup_gminy
                    }).addTo(gminy_3857_ref.current);
                })
                .then(() => {
                    mapRef.current.addLayer(gminy_3857_ref.current)
                    if (loader) {
                        loader.hide();
                    }
                    setLoadingShapefile(false)
                })
                .catch(() => {
                    notify.error(t("map:shapefile-error-msg"))
                    if (loader) {
                        loader.hide();
                    }
                    setLoadingShapefile(false)
                })
            }

            else if (obj.name === "ParkiNarodowePolygon_3857") {
                ParkiNarodowePolygon_3857_ref.current = new L.FeatureGroup();
                ParkiNarodowePolygon_3857_ref.current.name = "ParkiNarodowePolygon_3857";
                shp(ParkiNarodowePolygon_3857).then(function (geojson) {
                    L.geoJSON(geojson, {
                        onEachFeature: bindShapePopup
                    }).addTo(ParkiNarodowePolygon_3857_ref.current);
                })
                .then(() => {
                    mapRef.current.addLayer(ParkiNarodowePolygon_3857_ref.current)
                    if (loader) {
                        loader.hide();
                    }
                    setLoadingShapefile(false)
                })
                .catch(() => {
                    notify.error(t("map:shapefile-error-msg"))
                    if (loader) {
                        loader.hide();
                    }
                    setLoadingShapefile(false)
                })
            }

            else if (obj.name === "ParkiKrajobrazowePolygon_3857") {
                ParkiKrajobrazowePolygon_3857_ref.current = new L.FeatureGroup();
                ParkiKrajobrazowePolygon_3857_ref.current.name = "ParkiKrajobrazowePolygon_3857";
                shp(ParkiKrajobrazowePolygon_3857).then(function (geojson) {
                    L.geoJSON(geojson, {
                        onEachFeature: bindShapePopup
                    }).addTo(ParkiKrajobrazowePolygon_3857_ref.current);
                })
                .then(() => {
                    mapRef.current.addLayer(ParkiKrajobrazowePolygon_3857_ref.current)
                    if (loader) {
                        loader.hide();
                    }
                    setLoadingShapefile(false)
                })
                .catch(() => {
                    notify.error(t("map:shapefile-error-msg"))
                    if (loader) {
                        loader.hide();
                    }
                    setLoadingShapefile(false)
                })
            }

            else if (obj.name === "RezerwatyPolygon_3857") {
                RezerwatyPolygon_3857_ref.current = new L.FeatureGroup();
                RezerwatyPolygon_3857_ref.current.name = "RezerwatyPolygon_3857";
                shp(RezerwatyPolygon_3857).then(function (geojson) {
                    L.geoJSON(geojson, {
                        onEachFeature: bindShapePopup
                    }).addTo(RezerwatyPolygon_3857_ref.current);
                })
                .then(() => {
                    mapRef.current.addLayer(RezerwatyPolygon_3857_ref.current)
                    if (loader) {
                        loader.hide();
                    }
                    setLoadingShapefile(false)
                })
                .catch(() => {
                    notify.error(t("map:shapefile-error-msg"))
                    if (loader) {
                        loader.hide();
                    }
                    setLoadingShapefile(false)
                })
            }

            else if (obj.name === "SpecjalneObszaryOchronyPolygon_3857") {
                SpecjalneObszaryOchronyPolygon_3857_ref.current = new L.FeatureGroup();
                SpecjalneObszaryOchronyPolygon_3857_ref.current.name = "SpecjalneObszaryOchronyPolygon_3857";
                shp(SpecjalneObszaryOchronyPolygon_3857).then(function (geojson) {
                    L.geoJSON(geojson, {
                        onEachFeature: bindShapePopup
                    }).addTo(SpecjalneObszaryOchronyPolygon_3857_ref.current);
                })
                .then(() => {
                    mapRef.current.addLayer(SpecjalneObszaryOchronyPolygon_3857_ref.current)
                    if (loader) {
                        loader.hide();
                    }
                    setLoadingShapefile(false)
                })
                .catch(() => {
                    notify.error(t("map:shapefile-error-msg"))
                    if (loader) {
                        loader.hide();
                    }
                    setLoadingShapefile(false)
                })
            }

            else if (obj.name === "ObszarySpecjalnejOchronyPolygon_3857") {
                ObszarySpecjalnejOchronyPolygon_3857_ref.current = new L.FeatureGroup();
                ObszarySpecjalnejOchronyPolygon_3857_ref.current.name = "ObszarySpecjalnejOchronyPolygon_3857";
                shp(ObszarySpecjalnejOchronyPolygon_3857).then(function (geojson) {
                    L.geoJSON(geojson, {
                        onEachFeature: bindShapePopup
                    }).addTo(ObszarySpecjalnejOchronyPolygon_3857_ref.current);
                })
                .then(() => {
                    mapRef.current.addLayer(ObszarySpecjalnejOchronyPolygon_3857_ref.current)
                    if (loader) {
                        loader.hide();
                    }
                    setLoadingShapefile(false)
                })
                .catch(() => {
                    notify.error(t("map:shapefile-error-msg"))
                    if (loader) {
                        loader.hide();
                    }
                    setLoadingShapefile(false)
                })
            }

            else if (obj.name === "UzytkiEkologicznePolygon_3857") {
                UzytkiEkologicznePolygon_3857_ref.current = new L.FeatureGroup();
                UzytkiEkologicznePolygon_3857_ref.current.name = "UzytkiEkologicznePolygon_3857";
                shp(UzytkiEkologicznePolygon_3857).then(function (geojson) {
                    L.geoJSON(geojson, {
                        onEachFeature: bindShapePopup
                    }).addTo(UzytkiEkologicznePolygon_3857_ref.current);
                })
                .then(() => {
                    mapRef.current.addLayer(UzytkiEkologicznePolygon_3857_ref.current)
                    if (loader) {
                        loader.hide();
                    }
                    setLoadingShapefile(false)
                })
                .catch(() => {
                    notify.error(t("map:shapefile-error-msg"))
                    if (loader) {
                        loader.hide();
                    }
                    setLoadingShapefile(false)
                })
            }

            else if (obj.name === "ObszaryChronionegoKrajobrazuPolygon_3857") {
                ObszaryChronionegoKrajobrazuPolygon_3857_ref.current = new L.FeatureGroup();
                ObszaryChronionegoKrajobrazuPolygon_3857_ref.current.name = "ObszaryChronionegoKrajobrazuPolygon_3857";
                shp(ObszaryChronionegoKrajobrazuPolygon_3857).then(function (geojson) {
                    L.geoJSON(geojson, {
                        onEachFeature: bindShapePopup
                    }).addTo(ObszaryChronionegoKrajobrazuPolygon_3857_ref.current);
                })
                .then(() => {
                    mapRef.current.addLayer(ObszaryChronionegoKrajobrazuPolygon_3857_ref.current)
                    if (loader) {
                        loader.hide();
                    }
                    setLoadingShapefile(false)
                })
                .catch(() => {
                    notify.error(t("map:shapefile-error-msg"))
                    if (loader) {
                        loader.hide();
                    }
                    setLoadingShapefile(false)
                })
            }

            else if (obj.name === "ZespolyPrzyrodniczoKrajobrazowePolygon_3857") {
                ZespolyPrzyrodniczoKrajobrazowePolygon_3857_ref.current = new L.FeatureGroup();
                ZespolyPrzyrodniczoKrajobrazowePolygon_3857_ref.current.name = "ZespolyPrzyrodniczoKrajobrazowePolygon_3857";
                shp(ZespolyPrzyrodniczoKrajobrazowePolygon_3857).then(function (geojson) {
                    L.geoJSON(geojson, {
                        onEachFeature: bindShapePopup
                    }).addTo(ZespolyPrzyrodniczoKrajobrazowePolygon_3857_ref.current);
                })
                .then(() => {
                    mapRef.current.addLayer(ZespolyPrzyrodniczoKrajobrazowePolygon_3857_ref.current)
                    if (loader) {
                        loader.hide();
                    }
                    setLoadingShapefile(false)
                })
                .catch(() => {
                    notify.error(t("map:shapefile-error-msg"))
                    if (loader) {
                        loader.hide();
                    }
                    setLoadingShapefile(false)
                })
            }

            else if (obj.name === "StanowiskaDokumentacyjnePolygon_3857") {
                StanowiskaDokumentacyjnePolygon_3857_ref.current = new L.FeatureGroup();
                StanowiskaDokumentacyjnePolygon_3857_ref.current.name = "StanowiskaDokumentacyjnePolygon_3857";
                shp(StanowiskaDokumentacyjnePolygon_3857).then(function (geojson) {
                    console.log(geojson)
                    L.geoJSON(geojson, {
                        onEachFeature: bindShapePopup
                    }).addTo(StanowiskaDokumentacyjnePolygon_3857_ref.current);
                })
                .then(() => {
                    mapRef.current.addLayer(StanowiskaDokumentacyjnePolygon_3857_ref.current)
                    if (loader) {
                        loader.hide();
                    }
                    setLoadingShapefile(false)
                })
                .catch(() => {
                    notify.error(t("map:shapefile-error-msg"))
                    if (loader) {
                        loader.hide();
                    }
                    setLoadingShapefile(false)
                })
            }

            else if (obj.name === "LocationPhysiographicDivision") {
                LocationPhysiographicDivision_ref.current = new L.FeatureGroup();
                LocationPhysiographicDivision_ref.current.name = "LocationPhysiographicDivision";
                shp(LocationPhysiographicDivision).then(function (geojson) {
                    L.geoJSON(geojson, {
                        onEachFeature: popup_podzial_fizycznogeograficzny
                    }).addTo(LocationPhysiographicDivision_ref.current);
                })
                .then(() => {
                    mapRef.current.addLayer(LocationPhysiographicDivision_ref.current)
                    if (loader) {
                        loader.hide();
                    }
                    setLoadingShapefile(false)
                })
                .catch(() => {
                    notify.error(t("map:shapefile-error-msg"))
                    if (loader) {
                        loader.hide();
                    }
                    setLoadingShapefile(false)
                })
            }


            else if (obj.name === "UTM_PL") {
                UTM_PL_ref.current = new L.FeatureGroup();
                UTM_PL_ref.current.name = "UTM_PL";
                shp(UTM34).then(function (geojson) {
                    L.geoJSON(geojson, {
                        onEachFeature: popup_UTM_PL
                    }).addTo(UTM_PL_ref.current);
                })
                .then(() => {
                    shp(UTM33).then(function (geojson) {
                        L.geoJSON(geojson, {
                            onEachFeature: popup_UTM_PL
                        }).addTo(UTM_PL_ref.current);
                    })
                })
                .then(() => {
                    mapRef.current.addLayer(UTM_PL_ref.current)
                    if (loader) {
                        loader.hide();
                    }
                    setLoadingShapefile(false)
                })
                .catch(() => {
                    notify.error(t("map:shapefile-error-msg"))
                    if (loader) {
                        loader.hide();
                    }
                    setLoadingShapefile(false)
                })
            }

        }
        else {
            setLoadingShapefile(true)
            mapRef.current.eachLayer(function (layer) {
                if (layer.name === obj.name) {
                    mapRef.current.removeLayer(layer);
                }
            });
            setLoadingShapefile(false)
        }
      setMapLayers({ ...mapLayers, shapefilesLayers })  
    }, [mapLayers, setMapLayers, administrativeDivisionStyle, t])

    return { handleShapefilesLayers, loadingShapefile }
}


export default useShapefilesHandler
