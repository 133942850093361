import React, { useEffect, useState } from "react"
import { Form, FormLabel, Container, Row, Col, Button, Spinner } from "react-bootstrap"
import { connect } from 'react-redux'
import * as actions from '../actions'
import { useTranslation } from 'react-i18next'
import { isPending, getOutput } from "../reducers"
import SEO from "./SEO"
import { useParams } from 'react-router-dom';

const ResetPassword = props => {

    const { clearResponse, reset, location } = props

    const [fields, setFields] = useState({ new_password: "", new_password_again: "" })
    const { user_id, token } = useParams()

    useEffect(() => {
        if (user_id && token) {
            setFields({ new_password: "", new_password_again: "", user_id, token })
        }

        return clearResponse
    }, [clearResponse, reset, location, user_id, token])

    const handleFormChange = e => {
        const temp = { ...fields }
        temp[e.target.name] = e.target.value
        setFields(temp)
        setIsValid(
            Object.keys(fields).every(field => (temp[field] && temp[field].length > 0)) &&
            temp.new_password === temp.new_password_again
        )
    }

    const [isValid, setIsValid] = useState(false)

    const { t } = useTranslation(['common', 'forms'])

    const submitForm = () => {
        props.reset(fields)
    }

    return (
        <Container className="d-flex flex-column mt-2">
            <SEO title={t('common:new_password_process')} />
            {props.loading &&
                <div className="align-self-center">
                    <Spinner animation="border" role="status" />
                </div>
            }
            {!props.loading && props.response && props.response.result === 0 &&
                <div className="pt-5 pb-5 align-self-center">{t('common:password_reset_done')}</div>
            }
            {!props.loading && props.response && props.response.result === 1 &&
                <div className="pt-5 pb-5 align-self-center">{t('common:password_reset_error')}</div>
            }
            {!props.loading && props.response && props.response.result === 2 &&
                <div className="pt-5 pb-5 align-self-center">{t('common:password_reset_wrong_pass')}</div>
            }
            {(!props.response || props.response.result !== 0) &&
                <Form>
                    <Row>
                        <Col md={{ span: 4, offset: 4 }}>
                            <h3>{t('common:new_password_process')}</h3>
                            <Form.Group>
                                <FormLabel>{t('forms:new_password')}</FormLabel>
                                <Form.Control 
                                    isInvalid={!fields.new_password} 
                                    required 
                                    onChange={handleFormChange} 
                                    value={fields.new_password} 
                                    name="new_password" 
                                    type="password"
                                    autoComplete="off"
                                    disabled={props.loading}></Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <FormLabel>{t('forms:new_password_again')}</FormLabel>
                                <Form.Control 
                                    isInvalid={!fields.new_password_again || fields.new_password !== fields.new_password_again} 
                                    required 
                                    onChange={handleFormChange} 
                                    value={fields.new_password_again} 
                                    name="new_password_again" 
                                    type="password" 
                                    autoComplete="off"
                                    disabled={props.loading}></Form.Control>
                                {fields.new_password !== fields.new_password_again &&
                                    <Form.Text>{t('forms:passwords_donot_match')}</Form.Text>
                                }
                            </Form.Group>
                            <Button variant="primary" onClick={e => { e.preventDefault(); submitForm() }} disabled={props.loading || !isValid} type="submit">
                                {t('send')}
                                {props.loading &&
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        className="ml-1"
                                    />
                                }
                            </Button>
                        </Col>
                    </Row>
                </Form>
            }
        </Container>
    )
}

const mapStateToProps = state => ({
    loading: isPending('reset', state),
    response: getOutput('reset', state)
})

const mapDispatchToProps = dispatch => ({
    reset: data => dispatch(actions.postDataApi('reset', '/reset_password/', data)),
    clearResponse: () => dispatch(actions.requestRemove('reset')),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResetPassword)
