import React from "react";
import { Container } from "react-bootstrap"
import { useTranslation } from 'react-i18next'
import { NavLink, useLocation } from "react-router-dom"
import ExternalLinkPopover from "./ExternalLinkPopover";

export default props => {
  const { t, i18n } = useTranslation([ 'home', 'common', 'paths', 'a11y' ])

  const location = useLocation();

  return (
    <div className="main-footer" id="footer">
      <Container className="align-self-center pb-3 text-center">
        <div>
          <div>
            <div>
              <img src={"/images/FE_POIS-" + i18n.languages[ 0 ] + ".png"} alt={t("a11y:ue-logo")} width="60%" />
            </div>
            <div>
              <div className="mb-2 ue-text">{t('common:footer-text')}</div>
            </div>
            <div><strong>
              2023 &copy; {t('common:footer-uam-pcss')} | <NavLink to={t("paths:site-map")} onClick={e => window.scrollTo(0, 0)}>
                {t('common:site-map')}</NavLink> | <NavLink to={t("paths:privacy-policy")} onClick={e => window.scrollTo(0, 0)}>
                {t('common:privacy-policy')}</NavLink> | <NavLink to={t("paths:regulations")} onClick={e => window.scrollTo(0, 0)}>
                {t('common:regulations')}</NavLink> | <NavLink to={t('paths:gdpr')} onClick={e => window.scrollTo(0, 0)}>{t('common:gdpr')}
              </NavLink> | <NavLink to={t('paths:accessibility')} onClick={e => window.scrollTo(0, 0)}>{t('common:accessibility')}</NavLink>
            </strong>
            </div>
          </div>
        </div>
      </Container>
      <div className={(props.activeMenu === 2 && location.pathname === "/") ? "nav-socials white-version" : "nav-socials"} id="nav-socials" aria-label={t("a11y:nav-socials")}>
        <div className="d-flex flex-lg-column flex-sm-row justify-content-center">
          <ExternalLinkPopover
            link='https://www.gbif.org/publisher/5480dea7-2a71-409b-a832-cbc5f1b5a2e6'
            title={t("common:go-to-project-page") + "GBIF"}
          >
            <img src="/images/ico-gbif.svg" width="18" height="22" alt="logo -  Global Biodiversity Information Facility"></img>
          </ExternalLinkPopover>
          <ExternalLinkPopover
            link='https://www.facebook.com/Amunatcoll'
            title={t("common:go-to-project-page") + t("common:facebook-locative")}
          >
            <img src="/images/ico-facebook.svg" width="18" height="22" alt="logo - facebook"></img>
          </ExternalLinkPopover>
        </div>
      </div>
    </div>
  )
}
