import React, { Suspense } from "react";
import { Container } from "react-bootstrap"
import { OUR_USERS_PAGE_URL_EN, OUR_USERS_PAGE_URL_PL } from "../settings";
import { useTranslation } from 'react-i18next'
import Loader from '../utils/loader'
import I18nPathTranslator from "./I18nPathTranslator";
import SEO from "./SEO";

const OurUsersInfoContainer = props => {
  const { t } = useTranslation('our-users')

  return (
    <Suspense fallback={<Loader />}>
      <I18nPathTranslator {...props} pathEN={OUR_USERS_PAGE_URL_EN} pathPL={OUR_USERS_PAGE_URL_PL} />
      <SEO title={t('title')} />
      <div>
        <Container>
          <div>
            <h1>{t('title')}</h1>
            <p>{t('section_1')}</p>
            <p>{t('section_2')}</p>
            <ul>
              <li>{t('section_3')}</li>
              <li>{t('section_4')}</li>
              <li>{t('section_5')}</li>
              <li>{t('section_6')}</li>
            </ul>
          </div>
        </Container>
      </div>
    </Suspense >
  )
}

export default OurUsersInfoContainer
