import React from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { setField, runSearchFlow } from '../../actions/forms'
import { requestRemove, requestRemoveStartsWith } from '../../actions'
import { isPending, getField } from "../../reducers"
import {
    TAXONDB_SEARCH_REQUEST_ID,
    TAXONDB_SEARCH_GENUS_REQUEST_ID,
    TAXONDB_SEARCH_SPECIES_REQUEST_ID,
    TAXON_RESULTS_ACTIVE_TAB_FIELD_NAME,
    DICTIONARY_COLLECTION_TYPES,
    COMMON_DICTIONARIES,
    AGGREGATION_REPORT_REQUEST_ID_PREFIX,
    TAXONDB_SUGGEST_REQUEST_PREFIX,
    MAP_TOOLS_ACTIVE_VISUALIZATION,
} from '../../settings'
import {
    MULTIMEDIA_FIELD_NAME,
    ROK_ZBIORU_FIELD_NAME,
    BOTANIKAZOOLOGIA_FIELD_NAME,
    RODZAJ_GATUNEK_ATTRIBUTE_NAME,
} from './attributesStructure'
import InputWithAsyncTypeahead from "../InputWithAsyncTypeahead"
import InputWithTypeahead from "../InputWithTypeahead"
import { Form, Container, Row, Col } from "react-bootstrap"
import InputSimpleSelect from "../InputSimpleSelect"
import SearchButtons from './SearchButtons'
import { rokZbioruOnChange, rokZbioruShouldRenderSuggestions } from '../../utils/search'
import SynonymsInfo from "./SynonymsInfo"
import AreaSearch from './AreaSearch'
import { setSearchAreaGeojson } from '../../actions/forms'
import InfoIconPopover from "../InfoIconPopover";

const SearchForm = props => {
    const { t } = useTranslation([ 'common', 'taxondb', 'taxondb-attributes' ])

    const handleOnKeyUp = e => {
        if (e.keyCode === 13) { // ENTER
            e.preventDefault()
            if (!props.bioGisForm){
                props.runSearchFlow()
            }
            else if (props.bioGisForm){
                props.handleMapSearch()
            }
        }
    }

    return (
        <Form onSubmit={e => { e.preventDefault() }}>
            <Container className="mt-2">
                <Row>
                    <Form.Group as={Col} sm={6} md={4}>
                        <Form.Label htmlFor={RODZAJ_GATUNEK_ATTRIBUTE_NAME}>{t('taxondb-attributes:' + RODZAJ_GATUNEK_ATTRIBUTE_NAME)}
                        </Form.Label>
                        <InfoIconPopover hasFocusable>
                            <div>{t('taxondb:rodzajgatunek_info-1')}</div>
                            <div>{t('taxondb:rodzajgatunek_info-2')}</div>
                            <div>{t('taxondb:rodzajgatunek_info-3')}</div>
                            <SynonymsInfo></SynonymsInfo>
                        </InfoIconPopover>
                        <InputWithAsyncTypeahead
                            url={TAXONDB_SUGGEST_REQUEST_PREFIX + RODZAJ_GATUNEK_ATTRIBUTE_NAME + "/"}
                            autoFocus={true}
                            id={RODZAJ_GATUNEK_ATTRIBUTE_NAME}
                            disabled={props.loading}
                            formId={props.searchRequestId ? props.searchRequestId : TAXONDB_SEARCH_GENUS_REQUEST_ID}
                            onKeyUp={handleOnKeyUp}
                            onChange={e => props.clearResponse()}></InputWithAsyncTypeahead>
                    </Form.Group>
                    <Form.Group as={Col} sm={6} md={4}>
                        <Form.Label htmlFor="jednostkanadrzedna">{t('taxondb-attributes:jednostkanadrzedna')}</Form.Label>
                        <InputWithAsyncTypeahead
                            url={TAXONDB_SUGGEST_REQUEST_PREFIX + "jednostkanadrzedna/"}
                            id="jednostkanadrzedna"
                            disabled={props.loading}
                            formId={props.searchRequestId ? props.searchRequestId : TAXONDB_SEARCH_GENUS_REQUEST_ID}
                            onKeyUp={handleOnKeyUp}
                            onChange={e => props.clearResponse()}></InputWithAsyncTypeahead>
                    </Form.Group>
                    <Form.Group as={Col} sm={6} md={4}>
                        <Form.Label htmlFor="autorzbioru">{t('taxondb-attributes:autorzbioru')}</Form.Label>
                        <InfoIconPopover>
                            <div>{t('taxondb:autorzbioru_info-1')}</div>
                            <div>{t('taxondb:autorzbioru_info-2')}</div>
                        </InfoIconPopover>  
                        <InputWithAsyncTypeahead
                            url={TAXONDB_SUGGEST_REQUEST_PREFIX + "autorzbioru/"}
                            id="autorzbioru"
                            disabled={props.loading}
                            formId={props.searchRequestId ? props.searchRequestId : TAXONDB_SEARCH_GENUS_REQUEST_ID}
                            onKeyUp={handleOnKeyUp}
                            onChange={e => props.clearResponse()}></InputWithAsyncTypeahead>
                    </Form.Group>
                    <Form.Group as={Col} sm={6} md={4}>
                        <Form.Label htmlFor={ROK_ZBIORU_FIELD_NAME}>{t('taxondb-attributes:' + ROK_ZBIORU_FIELD_NAME)}
                        </Form.Label>
                        <InfoIconPopover>
                            <div>{t('taxondb:rokzbioru_info-1')}</div>
                            <div>{t('taxondb:rokzbioru_info-2')}</div>
                            <div>{t('taxondb:rokzbioru_info-3')}</div>
                            <div>{t('taxondb:rokzbioru_info-4')}</div>
                            <div>{t('taxondb:rokzbioru_info-5')}</div>
                        </InfoIconPopover>
                        <InputWithAsyncTypeahead
                            url={TAXONDB_SUGGEST_REQUEST_PREFIX + ROK_ZBIORU_FIELD_NAME + "/"}
                            id={ROK_ZBIORU_FIELD_NAME}
                            disabled={props.loading}
                            formId={props.searchRequestId ? props.searchRequestId : TAXONDB_SEARCH_GENUS_REQUEST_ID}
                            shouldRenderSuggestions={rokZbioruShouldRenderSuggestions}
                            conditionalChange={rokZbioruOnChange}
                            onKeyUp={handleOnKeyUp}
                            onChange={e => props.clearResponse()}
                        ></InputWithAsyncTypeahead>
                    </Form.Group>
                    <Form.Group as={Col} sm={6} md={4}>
                        <Form.Label htmlFor="panstwo">{t('taxondb-attributes:panstwo')}</Form.Label>
                        <InputWithTypeahead
                            url={TAXONDB_SUGGEST_REQUEST_PREFIX + "panstwo/"}
                            id="panstwo"
                            disabled={props.loading}
                            formId={props.searchRequestId ? props.searchRequestId : TAXONDB_SEARCH_GENUS_REQUEST_ID}
                            onKeyUp={handleOnKeyUp}
                            onChange={e => props.clearResponse()}></InputWithTypeahead>
                    </Form.Group>
                    <Form.Group as={Col} sm={6} md={4}>
                        <Form.Label htmlFor={BOTANIKAZOOLOGIA_FIELD_NAME}>{t('taxondb-attributes:' + BOTANIKAZOOLOGIA_FIELD_NAME)}</Form.Label>
                        <InputSimpleSelect
                            id={BOTANIKAZOOLOGIA_FIELD_NAME}
                            name={BOTANIKAZOOLOGIA_FIELD_NAME}
                            disabled={props.loading}
                            onKeyUp={handleOnKeyUp}
                            onChange={e => props.clearResponse()}
                            allowedValues={props.collectionTypes.map(obj => ({ value: obj.value }))}
                            formId={props.searchRequestId ? props.searchRequestId : TAXONDB_SEARCH_GENUS_REQUEST_ID}
                        >
                        </InputSimpleSelect>
                    </Form.Group>
                    <Form.Group as={Col} sm={6} md={4}>
                        <Form.Label htmlFor={MULTIMEDIA_FIELD_NAME}>{t('taxondb-attributes:multimedia')}</Form.Label>
                        <InputSimpleSelect
                            id={MULTIMEDIA_FIELD_NAME}
                            name={MULTIMEDIA_FIELD_NAME}
                            onChange={e => props.clearResponse()}
                            disabled={props.loading}
                            onKeyUp={handleOnKeyUp}
                            allowedValues={[
                                {
                                    value: true,
                                },
                                {
                                    value: false,
                                },
                            ]}
                            formId={props.searchRequestId ? props.searchRequestId : TAXONDB_SEARCH_GENUS_REQUEST_ID}
                        ></InputSimpleSelect>
                    </Form.Group>
                    <Form.Group as={Col} sm={6} md={4}>
                        <Form.Label htmlFor={"areaSearch"}>{t('common:area-search-label')}</Form.Label>
                        <Form.Check
                            type="checkbox"
                            label={t('common:searchform-map-label')}
                            id="areaSearch"
                            checked={props.showAreaSearch}
                            onChange={(e) => props.setShowAreaSearch(!props.showAreaSearch)} 
                        />
                        <div className="mt-1 ml-4">{(!props.searchAreaGeoJson || (props.searchAreaGeoJson && !props.searchAreaGeoJson.length) ) ? t('common:search-area-not-selected') : ""}</div>
                        <div className="mt-1 ml-4">{(props.searchAreaGeoJson && props.searchAreaGeoJson.length > 0) ? t('common:search-area-selected') : ""}</div>
                    </Form.Group>
                </Row>
                <Row><Col>{t('taxondb:form-wildcard-info')}</Col></Row>
                <Form.Group className="d-flex justify-content-center my-3">
                    <SearchButtons {...props} requestId={TAXONDB_SEARCH_REQUEST_ID} genusRequestId={TAXONDB_SEARCH_GENUS_REQUEST_ID} speciesRequestId={TAXONDB_SEARCH_SPECIES_REQUEST_ID} tabId={TAXON_RESULTS_ACTIVE_TAB_FIELD_NAME}></SearchButtons>
                </Form.Group>
                {props.showAreaSearch &&
                    <AreaSearch showAreaSearch={props.showAreaSearch} requestId={props.searchRequestId ? props.searchRequestId : TAXONDB_SEARCH_GENUS_REQUEST_ID} />
                }
            </Container>
        </Form>
    )
}

const mapStateToProps = (state, ownProps) => ({
    loading: isPending(ownProps.searchRequestId ? ownProps.searchRequestId : TAXONDB_SEARCH_GENUS_REQUEST_ID, state),
    collectionTypes: getField(COMMON_DICTIONARIES, DICTIONARY_COLLECTION_TYPES, state, []),
    searchAreaMap: state.forms.map,
    showAreaSearch: getField((ownProps.searchRequestId ? ownProps.searchRequestId : TAXONDB_SEARCH_GENUS_REQUEST_ID) + 'set-show-area-search', 'set-show-area-search', state, false),
    searchAreaGeoJson: state.forms.searchAreaGeoJson,
    activeVisualization: getField(MAP_TOOLS_ACTIVE_VISUALIZATION, MAP_TOOLS_ACTIVE_VISUALIZATION, state),

})

const mapDispatchToProps = (dispatch, ownProps) => ({
    runSearchFlow: () => dispatch(ownProps.onSearch ? ownProps.onSearch() : runSearchFlow(TAXONDB_SEARCH_GENUS_REQUEST_ID, TAXONDB_SEARCH_SPECIES_REQUEST_ID, TAXONDB_SEARCH_REQUEST_ID, TAXON_RESULTS_ACTIVE_TAB_FIELD_NAME)),
    clearResponse: () => {
        if (ownProps.onClear) {
            dispatch(ownProps.onClear())
        } else {
            dispatch(requestRemove(TAXONDB_SEARCH_GENUS_REQUEST_ID))
            dispatch(requestRemove(TAXONDB_SEARCH_SPECIES_REQUEST_ID))
            dispatch(requestRemove(TAXONDB_SEARCH_REQUEST_ID))
            dispatch(requestRemoveStartsWith(AGGREGATION_REPORT_REQUEST_ID_PREFIX + TAXONDB_SEARCH_REQUEST_ID))
        }
    },
    setGeoJson: (field, value) => dispatch(setField(ownProps.searchRequestId ? ownProps.searchRequestId : TAXONDB_SEARCH_GENUS_REQUEST_ID, field, value)),
    setSearchAreaGeojson: (value) => dispatch(setSearchAreaGeojson(value)), 
    setShowAreaSearch: value => dispatch(setField((ownProps.searchRequestId ? ownProps.searchRequestId : TAXONDB_SEARCH_GENUS_REQUEST_ID) + 'set-show-area-search', 'set-show-area-search', value)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchForm)
