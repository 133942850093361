import React, { forwardRef, useImperativeHandle, useState } from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getPagination } from "../reducers";
import { Pagination, Dropdown, DropdownButton, Form, Button, Spinner } from "react-bootstrap";
import { getFilter, getCustomParams } from "../reducers";
import { postData } from '../actions'

export const ARIAPaginationItem = (props) => {
    const { t } = useTranslation([ 'forms', 'a11y' ])
    const {page, changePage, currentPage} = props
    const isActive = currentPage === page
    return(
        <Pagination.Item
            active={isActive}
            activeLabel={t('a11y:current-page')+t("forms:page")+" "+page} 
            aria-label={isActive ? t('a11y:current-page')+t("forms:page")+" "+page : t('a11y:goto-page')+page} 
            aria-current={isActive} 
            onClick={() => changePage(page)}>
                    {props.children}
        </Pagination.Item>
    )
}

export const ARIAControlButton = (props) => {
    const { t } = useTranslation([ 'a11y' ])
    const { type, changePage, page } = props
    let icon = ""
    switch(type){
        case "next": icon = "›"
        break
        case "prev": icon = "‹"
        break
        case "last": icon = "»"
        break
        case "first": icon = "«"
        break
        case "ellipsis": icon = "…"
        break
        default: icon = " "
    }
    return(
        <li className="page-item">
            <button className="page-link" 
                aria-label={type==="ellipsis" ? t('a11y:goto-page')+page : t(`a11y:goto-${type}`)} 
                onClick={()=>changePage(page)}>
                <span aria-hidden="true">{icon}</span>
                <span className="sr-only">{type==="ellipsis" ? t('a11y:goto-page')+page : t(`a11y:goto-${type}`)}</span>
            </button>
        </li>
    )
}

const ConnectedPagination = forwardRef((props, ref) => {
    const { t } = useTranslation([ 'common', 'forms', 'a11y' ])
    const [ formPagination, setFormPagination ] = useState(props.pagination ? props.pagination.currentPage : 1);

    useImperativeHandle(ref, () => ({
        nextPage: () => changePage(props.pagination.currentPage + 1),
        prevPage: () => changePage(props.pagination.currentPage - 1),
        reloadPage: () => reloadPage()
    }))

    const changePage = page => {
        if (props.pagination.currentPage === page)
            return
        const newPagination = { ...props.pagination }
        newPagination.currentPage = page
        props.search(props.getNewDataObj(newPagination))
    }

    const changePerPage = p => {
        const newPagination = { ...props.pagination }
        newPagination.currentPage = 1
        newPagination.perPage = parseInt(p)
        props.search(props.getNewDataObj(newPagination))
    }

    const reloadPage = () => {
        props.search(props.getNewDataObj(props.pagination))
    }

    const PAGINATION_SIZE = 2
    const ELLIPSIS_THRESHOLD = 2

    const items = []
    if (props.pagination) {
        if (props.pagination.totalPages > 1) {
            items.push(
                <ARIAPaginationItem key={1} page={1} changePage={changePage} currentPage={props.pagination.currentPage}>
                    {1}
                </ARIAPaginationItem>
            )

            if (props.pagination.currentPage - PAGINATION_SIZE - 2 >= ELLIPSIS_THRESHOLD) {
                items.push(
                    <ARIAControlButton key="ellipsis-before" type="ellipsis" changePage={changePage} page={parseInt(props.pagination.currentPage / 2)} />
                )
            } else if (props.pagination.totalPages >= 2) {
                items.push(
                    <ARIAPaginationItem key={2} page={2} changePage={changePage} currentPage={props.pagination.currentPage}>
                    {2}
                    </ARIAPaginationItem>
                )
            }

            for (let page = Math.max(3, props.pagination.currentPage - PAGINATION_SIZE);
                page <= Math.min(props.pagination.currentPage + PAGINATION_SIZE, props.pagination.totalPages - 2);
                page++) {
                items.push(
                    <ARIAPaginationItem key={page} page={page} changePage={changePage} currentPage={props.pagination.currentPage}>
                    {page}
                    </ARIAPaginationItem>
                )
            }

            if (props.pagination.totalPages - props.pagination.currentPage - PAGINATION_SIZE - 1 >= ELLIPSIS_THRESHOLD) {
                const step = parseInt((props.pagination.totalPages - props.pagination.currentPage) / 2)
                let page = props.pagination.currentPage + step
                if (step <= PAGINATION_SIZE)
                    page += 1
                items.push(
                    <ARIAControlButton key="ellipsis-after" type="ellipsis" changePage={changePage} page={page} />
                )
            } else if (props.pagination.totalPages >= 4) {
                items.push(
                    <ARIAPaginationItem key={props.pagination.totalPages - 1} page={props.pagination.totalPages - 1} changePage={changePage} currentPage={props.pagination.currentPage}>
                    {props.pagination.totalPages - 1}
                    </ARIAPaginationItem>
                )
            }

            if (props.pagination.totalPages > 2) {
                items.push(
                    <ARIAPaginationItem key={props.pagination.totalPages} page={props.pagination.totalPages} changePage={changePage} currentPage={props.pagination.currentPage}>
                    {props.pagination.totalPages}
                    </ARIAPaginationItem>
                )
            }
        }
    }

    return (
        <div>
            {props.pagination &&
                <nav role="navigation" 
                aria-label={props.last ? t('a11y:pagination-msg')+props.pagination.totalPages : t('results_per_page')+props.pagination.perPage+","} 
                className={'d-flex flex-column flex-wrap new-pagination ' + (props.last ? 'mb-3' : 'mt-3')}>
                    {props.last && props.pagination.totalPages > 1 &&
                        <>
                        <Pagination size="sm" className="flex-grow-1 justify-content-center">
                            {props.pagination.currentPage > 1 &&
                                <>
                                    <ARIAControlButton type="first" changePage={changePage} page={1} />
                                    <ARIAControlButton type="prev" changePage={changePage} page={props.pagination.currentPage - 1} />
                                </>
                            }
                            {items}
                            {props.pagination.currentPage < props.pagination.totalPages &&
                                <>
                                    <ARIAControlButton type="next" changePage={changePage} page={props.pagination.currentPage + 1} />
                                    <ARIAControlButton type="last" changePage={changePage} page={props.pagination.totalPages} />
                                </>
                            }
                        </Pagination>
                        {props.pagination.currentPage && props.last &&
                            <Form className="pagination-page-form">
                                <Form.Group controlId={`pagination-input-${props.requestId}`} className="pagination-input-group">
                                    <Form.Label>{t('forms:page')}</Form.Label>
                                    <Form.Control
                                    onChange={e => {
                                    const val = e.target.value
                                        if(!val)
                                            setFormPagination("")
                                        if(val && val > 0 && val <= props.pagination.totalPages)
                                            setFormPagination(parseInt(e.target.value))
                                    }}
                                    value={formPagination}
                                    type="number"
                                    autoComplete="off"
                                    min={1}
                                    max={props.pagination.totalPages}
                                    className="pagination-input"
                                    ></Form.Control>
                                </Form.Group>
                                <Button variant="primary" disabled={props.loading} type="submit"
                                onClick={e => {
                                    e.preventDefault()
                                    if(formPagination)
                                        changePage(formPagination)
                                    else
                                        changePage(props.pagination.currentPage)
                                }}
                                >
                                {t('forms:go')}
                                {props.loading &&
                                    <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className="ml-1"
                                    />
                                }
                                </Button>
                            </Form>
                        }
                        </>
                    }
                    {(props.pagination.totalPages > 1 || props.pagination.perPage > 20) && !props.last &&
                        <div className="d-flex justify-content-end align-items-center mb-3">
                            <div className="mr-1" >{t('results_per_page')}:</div>
                            <div>
                                <DropdownButton title={props.pagination.perPage} size="sm" variant="light" id="per-page-select">
                                    <Dropdown.Item onSelect={changePerPage} eventKey={20}>20</Dropdown.Item>
                                    <Dropdown.Item onSelect={changePerPage} eventKey={50}>50</Dropdown.Item>
                                    <Dropdown.Item onSelect={changePerPage} eventKey={100}>100</Dropdown.Item>
                                    <Dropdown.Item onSelect={changePerPage} eventKey={200}>200</Dropdown.Item>
                                    <Dropdown.Item onSelect={changePerPage} eventKey={500}>500</Dropdown.Item>
                                </DropdownButton>
                            </div>
                        </div>
                    }
                </nav>
            }
        </div>
    )
})

const mapStateToProps = (state, ownProps) => ({
    pagination: getPagination(ownProps.requestId, state),
    getNewDataObj: newPagination => {
        let out = { pagination: newPagination }
        if (ownProps.customFilter) {
            out = {
                ...out, ...ownProps.customFilter
            }
        } else {
            out[ 'filter' ] = getFilter(ownProps.requestId, state)
        }

        out = { ...out, ...(ownProps.customParams || getCustomParams(ownProps.requestId, state) || {}) }
        if (ownProps.requestInterceptor) {
            return ownProps.requestInterceptor(out)
        } else {
            return out
        }
    }
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    search: data => dispatch(postData(ownProps.requestId, ownProps.url, data)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(ConnectedPagination)
