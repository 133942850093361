import React, { useEffect, useRef } from "react";
import { Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { HashLink } from 'react-router-hash-link';

const KeyboardMenu = props => {
    let preContent, postContent
    const location = useLocation()
    const ref = useRef(null);
    const { t } = useTranslation("common")

    if(location.pathname === "/")
        preContent = ["#search-form", t("go-to")+t("search-loc")]

    let combinedMenuItems = [
        ["#app-menu",t("go-to")+"menu"],
        preContent,
        ["#content",t("go-to")+t("content-loc")],
        postContent,
        ["#footer",t("go-to")+t("footer-loc")],
    ]

    let menuItems = combinedMenuItems.filter(el=>el!=null)

    useEffect(()=>{
        ref.current.focus()
    }, [ref, location.pathname])

    return(
        <Nav as="nav" className="keyboard-menu" ref={ref} tabIndex="-1">
            {menuItems.map((item, i)=>
            <Nav.Item key={i}>
                <HashLink to={item[0]} key={i} className="nav-link btn-link btn-light" scroll={el=> el.scrollIntoView({behavior: 'smooth'})}>
                        {item[1]}
                </HashLink>
            </Nav.Item>)}
        </Nav>
        )
}


export default KeyboardMenu