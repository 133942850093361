import React, { Suspense, useRef, useEffect, useState, useCallback } from "react";
import { connect } from 'react-redux'
import { Navbar, Nav, Container, Spinner, Button, Carousel, DropdownButton, Dropdown } from "react-bootstrap"
import { isPending, getOutput, getItems } from "../reducers";
import SimpleSearchForm from "./taxonDB/SimpleSearchForm"
import SimpleResultsContainer from "./taxonDB/SimpleResultsContainer"
import {
    TAXONDB_COUNT_REQUEST_ID,
    TAXONDB_SIMPLE_SEARCH_GENUS_REQUEST_ID,
    TAXONDB_SIMPLE_SEARCH_SPECIES_REQUEST_ID,
    TAXONDB_SIMPLE_SEARCH_ICONS_REQUEST_ID,
    TAXONDB_SIMPLE_SEARCH_REQUEST_ID,
    TAXON_SIMPLE_RESULTS_ACTIVE_TAB_FIELD_NAME,
    AGGREGATION_REPORT_REQUEST_ID_PREFIX,
    SOCIAL_MENU_VERSION_ID,
} from '../settings'
import Loader from '../utils/loader'
import usePageScrollMenu from '../hooks/usePageScrollMenu'
import OpenSeadragon from 'openseadragon'
import { requestRemove, requestRemoveStartsWith } from '../actions'
import { useTranslation } from 'react-i18next'
import { Redirect, NavLink, useLocation } from "react-router-dom"
import ExternalLinkPopover from "./ExternalLinkPopover";
import { setField } from '../actions/forms'
import SEO from "./SEO";
import { MAIN_MENU, MAIN_MENU2 } from "./MenuContent";
import useUserRoles from "../hooks/useUserRoles"
import { getData } from '../actions'
import { logout, setIsLoggingOut } from '../actions/user'
import Footer from './Footer'

const HomeContainer = props => {
    const { t, i18n } = useTranslation([ 'home', 'common', 'paths', 'a11y' ])

    const location = useLocation();

    const ref1 = useRef()
    const ref2 = useRef()
    const ref3 = useRef()
    const ref4 = useRef()
    const ref5 = useRef()

    const overlay = useRef()
    const viewer = useRef()
    const paused = useRef(false)
    const resizeFlag = useRef(true)
    const ratio = useRef(0)
    const handler = useRef(null)

    const [ sideMenuName, setSideMenuName ] = useState('side_menu_section_1_1')
    const [ currentSlide, setCurrentSlide ] = useState(0)
    const [ sliderPaused, setSliderPaused ] = useState(false)
    const [ mapVisible, setMapVisible ] = useState(true)
    const [ exp, setExp ] = useState(false)

    const { isSuperleader, isCoordinator, isEditor, hasRole, shortName, isValidator, ShowWhenLoggedIn, ShowWhenNotLoggedIn } = useUserRoles()

    const handleWheel = useCallback(event => {
        if (!window.document.getElementById('info')) {
            return
        }

        window.document.getElementById('info').getElementsByClassName('inner')[ 0 ].style.opacity = "0.4"
        clearTimeout(handler.current)
        handler.current = setTimeout(() => {
            if (window.document.getElementById('info')) {
                window.document.getElementById('info').getElementsByClassName('inner')[ 0 ].style.opacity = "0"
            }
        }, 6000)
    }, [])

    const {setActiveMenu} = props
    const onSlideChange = useCallback(pos => {
        setActiveMenu(pos)
        if (pos === 0) {
            handleWheel()
        }
    },[setActiveMenu, handleWheel])

    const { MenuComponent, activeMenu } = usePageScrollMenu([ t(sideMenuName), t('side_menu_section_2'), t('side_menu_section_3'), t('side_menu_section_4'), t('side_menu_section_5') ],
        [ ref1, ref2, ref3, ref4, ref5 ], onSlideChange)

    const changeLanguage = lng => {
        i18n.changeLanguage(lng)
        localStorage.setItem('lang', lng)
    }

    const ADMIN_MENU = MAIN_MENU2.adminMenu.submenu
    const MY_AMUNATCOLL_MENU = MAIN_MENU2.myAmunatcoll.submenu
    const PROFILE_MENU = MAIN_MENU2.profile.submenu
    const SEARCH_MENU = MAIN_MENU.searchEngines.submenu
    const ABOUT_MENU = MAIN_MENU.about.submenu
    const TOOLS_MENU = MAIN_MENU.tools.submenu

    const { clearResponse, getImageInfo } = props
    useEffect(() => {
        const urls = [
            //https://rhus-16.man.poznan.pl:8000/api/project/upload_file/ // temporary solution (HPCDEV-298)
            'https://rhus-16.man.poznan.pl/iiif/image/965-972/info.json', // general slide 0
            'https://rhus-16.man.poznan.pl/iiif/image/964-971/info.json', // general slide 1
            'https://rhus-16.man.poznan.pl/iiif/image/972-979/info.json', // slide 0 point 1
            'https://rhus-16.man.poznan.pl/iiif/image/967-974/info.json', // slide 0 point 2
            'https://rhus-16.man.poznan.pl/iiif/image/968-975/info.json', // slide 0 point 3
            'https://rhus-16.man.poznan.pl/iiif/image/969-976/info.json', // slide 1 point 1
            'https://rhus-16.man.poznan.pl/iiif/image/970-977/info.json', // slide 1 point 2
            'https://rhus-16.man.poznan.pl/iiif/image/973-980/info.json', // slide 1 point 3
            //DLIBRA_IIIF_URL + '27135-27277/info.json', // general slide 1
            //DLIBRA_IIIF_URL + '27163-27305/info.json', // slide 0 point 1
            //DLIBRA_IIIF_URL + '27160-27302/info.json', // slide 0 point 2
            //DLIBRA_IIIF_URL + '27161-27303/info.json', // slide 0 point 3
            //DLIBRA_IIIF_URL + '27140-27282/info.json', // slide 1 point 1
            //DLIBRA_IIIF_URL + '27135-27277/info.json', // slide 1 point 2
            //DLIBRA_IIIF_URL + '27163-27305/info.json', // slide 1 point 3
        ]

        getImageInfo(urls[ 0 ]).then(res => {
            ratio.current = res.width / res.height
        })

        const fitMap = () => {
            if (!window.document.getElementById('iiif-main-picture')) {
                return
            }

            const currentRatio = window.document.getElementById('iiif-main-picture').getBoundingClientRect().width /
                window.document.getElementById('iiif-main-picture').getBoundingClientRect().height
            if (currentRatio > ratio.current) {
                viewer.current.viewport.fitVertically(true)
            } else {
                viewer.current.viewport.fitHorizontally(true)
            }
        }

        const initMap = () => {
            //console.log("Image URLs:", urls)
            // see: https://openseadragon.github.io/docs/OpenSeadragon.html#.Options
            if (viewer.current) {
                viewer.current.destroy()
            }

            if (window.document.getElementById('iiif-main-picture') === null) { // initMap gets fired up by setTimeout, when the element can be already scrapped
                return
            }

            viewer.current = OpenSeadragon({
                id: 'iiif-main-picture',
                prefixUrl: "/openseadragon/images/",
                //showNavigator: false,
                preserveViewport: true,
                //visibilityRatio: 1,
                sequenceMode: false,
                //crossOriginPolicy: false,
                //ajaxWithCredentials: false,
                tileSources: urls.map(tileSource => ({
                    tileSource,
                    ajaxHeaders: { "Authorization": "Bearer " + localStorage.getItem('access') }
                })),
                minZoomLevel: 0,
                maxZoomLevel: 10,
                //defaultZoomLevel: 1,
                //minZoomImageRatio: 2,
                //showNavigationControl: false,
                showHomeControl: false,
                //showZoomControl: true,
                showFullPageControl: false,
                showSequenceControl: false,
                navigationControlAnchor: OpenSeadragon.ControlAnchor.TOP_RIGHT,
                preload: true,
                loadTilesWithAjax: true,
                zoomInButton: 'zoom-in',
                zoomOutButton: 'zoom-out',
            })
            const handler = event => {
                fitMap()
                viewer.current.removeHandler('tile-drawn', handler)
            }
            viewer.current.addHandler('tile-drawn', handler)
            resizeFlag.current = true
        }
        initMap()

        const deactivateMapInteraction = () => {
            if (overlay.current) {
                overlay.current.style.display = "block"
                overlay.current.style.visibility = "visible"
            }

            if (window.document && window.document.getElementById('side-menu-container')) {
                window.document.getElementById('side-menu-container').style.display = "block"
                window.document.getElementById('side-menu-container').style.visibility = "visible"
            }

            if (window.document && window.document.getElementById('nav-socials')) {
                window.document.getElementById('nav-socials').style.display = "block"
                window.document.getElementById('nav-socials').style.visibility = "visible"
            }
            fitMap()
        }

        const activateMapInteraction = () => {
            if (overlay.current) {
                overlay.current.style.visibility = "hidden"
                overlay.current.style.display = "none"
            }

            if (window.document && window.document.getElementById('side-menu-container')) {
                window.document.getElementById('side-menu-container').style.visibility = "hidden"
                window.document.getElementById('side-menu-container').style.display = "none"
            }
            if (window.document && window.document.getElementById('nav-socials')) {
                window.document.getElementById('nav-socials').style.visibility = "hidden"
                window.document.getElementById('nav-socials').style.display = "none"
            }
        }

        const onKeyUp = event => {
            if (event.keyCode === 17) { // CTRL
                deactivateMapInteraction()
            }
        }

        const onKeyDown = event => {
            if (event.keyCode === 17 && activeMenu === 0) { // CTRL
                activateMapInteraction()
            }
        }

        const onFocusIn = event => {
            activateMapInteraction()
        }

        const onFocusOut = event => {
            deactivateMapInteraction()
        }

        const onSliderHoverIn = () => {
            if (!paused.current) {
                setSliderPaused(true)
                paused.current = true
            }
        }
        const onSliderHoverOut = () => {
            if (paused.current) {
                setSliderPaused(false)
                paused.current = false
            }
        }

        window.document.addEventListener("keydown", onKeyDown)
        window.document.addEventListener("keyup", onKeyUp)
        window.addEventListener('wheel', handleWheel);
        window.addEventListener('focus', onFocusOut)
        window.addEventListener('blur', onFocusOut)
        overlay.current.addEventListener('click', handleWheel)

        const setSliderHoverEvents = () => {
            window.document.getElementById("slider-id").addEventListener('mouseover', onSliderHoverIn)
            window.document.getElementById("slider-id").addEventListener('mouseenter', onSliderHoverIn)
            window.document.getElementById("slider-id").addEventListener('mousemove', onSliderHoverIn)
            window.document.getElementById("slider-id").addEventListener('focus', onSliderHoverIn)
            window.document.getElementById("slider-id").addEventListener('focusin', onSliderHoverIn)
            window.document.getElementById("slider-id").addEventListener('mouseleave', onSliderHoverOut)
            window.document.getElementById("slider-id").addEventListener('blur', onSliderHoverOut)
            window.document.getElementById("slider-id").addEventListener('focusout', onSliderHoverOut)

            window.document.getElementById('iiif-main-picture').getElementsByClassName('openseadragon-canvas')[ 0 ].addEventListener("focusin", onFocusIn)
            window.document.getElementById('iiif-main-picture').getElementsByClassName('openseadragon-canvas')[ 0 ].addEventListener("focusout", onFocusOut)
        }

        window.document.addEventListener("readystatechange", e => {
            setTimeout(() => {
                if (window.document.getElementById("slider-id")) {
                    setSliderHoverEvents()
                } else {
                    setTimeout(() => {
                        if (window.document.getElementById("slider-id")) {
                            setSliderHoverEvents()
                        }
                    }, 1000)
                }
            }, 500)
        })

        const handleResize = event => {
            if (resizeFlag.current) {
                resizeFlag.current = false
                setMapVisible(false)
                setTimeout(() => {
                    setMapVisible(true)
                    initMap()
                    onSliderChange(0)
                }, 200)
            }
        }
        window.addEventListener('resize', handleResize);

        return () => {
            window.document.removeEventListener("keydown", onKeyDown)
            window.document.removeEventListener("keyup", onKeyUp)
            window.removeEventListener('wheel', handleWheel);
            window.removeEventListener('focus', onFocusOut)
            window.removeEventListener('blur', onFocusOut)
            window.removeEventListener('resize', handleResize);
            clearResponse()
        }
    }, [ clearResponse, setSliderPaused, paused, getImageInfo, handleWheel, activeMenu ])

    const afterSearch = () => {
        window.scrollTo(0, 0)
    }

    const [ redirect, setRedirect ] = useState(false)

    const goto = url => {
        window.scrollTo(0, 0)
        setRedirect(url)
    }

    const onSliderChange = slide => {
        if (slide === 0) {
            setSideMenuName('side_menu_section_1_1')
        } else if (slide === 1) {
            setSideMenuName('side_menu_section_1_2')
        }
        setCurrentSlide(slide)
        viewer.current.goToPage(slide)
    }

    const tOrStr = (str) => {
        if (str.includes(':'))
            return t(str)
        else
            return str
    }
    const {setIsLoggingOut} = props
    useEffect(()=>{
        setIsLoggingOut(false);
    },[setIsLoggingOut]) 

    return (
        <Suspense fallback={<Loader />}>
            <SEO title={t("home:page_title")} desc={t("home:page_description")} />
            {redirect &&
                <Redirect push to={redirect} ></Redirect>
            }
            <div className="home-container">
                <div className={activeMenu > 0 ? "search-form scrolled" : "search-form notscrolled"}>
                    <Container className="d-flex app-menu align-items-start">
                        {activeMenu > 0 &&
                            <NavLink exact to={MAIN_MENU.home.path} onClick={() => setExp(false)}>
                                <img className="main-logo-2 home-logo" src="/logo.png" alt="Amunatcoll logo" />
                            </NavLink>
                        }
                        <div className="flex-grow-1">
                            <SimpleSearchForm
                                genusRequestId={TAXONDB_SIMPLE_SEARCH_GENUS_REQUEST_ID}
                                speciesRequestId={TAXONDB_SIMPLE_SEARCH_SPECIES_REQUEST_ID}
                                searchRequestId={TAXONDB_SIMPLE_SEARCH_REQUEST_ID}
                                tabId={TAXON_SIMPLE_RESULTS_ACTIVE_TAB_FIELD_NAME}
                                afterSearch={afterSearch}
                            ></SimpleSearchForm>
                        </div>
                        {activeMenu > 0 &&
                            <>
                            <Navbar expand={false} expanded={exp} onToggle={e => { }}>
                                <div className={"d-flex flex-grow-1 justify-content-end align-items-center home-toggler-container" + (exp ? "" : " exp")}>
                                    <Navbar.Toggle aria-controls="main-menu-2" onClick={() => setExp(!exp)}></Navbar.Toggle>
                                </div>
                                <Navbar.Collapse id="main-menu-2" className="main-menu-collapsed">
                                    <Nav>
                                        <Nav.Item>
                                            <Nav.Link as={NavLink} exact to={tOrStr(MAIN_MENU.home.path)} onClick={() => setExp(false)}>{t(MAIN_MENU.home.name)}</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="with-passive-headers">
                                           <DropdownButton variant="secondary" title={t(MAIN_MENU.searchEngines.name)}>
                                                {SEARCH_MENU.map((item, key) =>
                                                        key === 0 ?
                                                            <Dropdown.Item key={key} className={item.className}>{t(item.name)}</Dropdown.Item> :
                                                            <Dropdown.Item key={key} as={NavLink}
                                                                className={item.className ? item.className : ""} to={tOrStr(item.path)} onClick={() => {
                                                                setExp(false)
                                                            }}>{t(item.name)}</Dropdown.Item>
                                                )}
                                            </DropdownButton>
                                        </Nav.Item>
                                        <Nav.Item className="with-passive-headers">
                                            <DropdownButton variant="secondary" title={t(MAIN_MENU.tools.name)}>
                                                {TOOLS_MENU.map((arr) =>
                                                    arr.map((item, key) =>
                                                        key === 0 ?
                                                            <Dropdown.Item key={key} className={item.className}>{t(item.name)}</Dropdown.Item> :
                                                            <Dropdown.Item key={key} as={NavLink}
                                                                className={item.className ? item.className : ""} to={tOrStr(item.path)}
                                                                onClick={() => {
                                                                    setExp(false)
                                                                }}>{tOrStr(item.name)}</Dropdown.Item>)
                                                )}
                                            </DropdownButton>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <DropdownButton variant="secondary" title={t(MAIN_MENU.about.name)}>
                                                {ABOUT_MENU.map((item, key) =>
                                                    <Dropdown.Item key={key} as={NavLink} to={tOrStr(item.path)} onClick={() => {
                                                        setExp(false)
                                                    }}>{t(item.name)}</Dropdown.Item>
                                                )}
                                            </DropdownButton>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link as={NavLink} to={tOrStr(MAIN_MENU.howToUse.path)} onClick={() => setExp(false)}>{t(MAIN_MENU.howToUse.name)}</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link as={NavLink} to={tOrStr(MAIN_MENU.contact.path)} onClick={() => setExp(false)}>{t(MAIN_MENU.contact.name)}</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="first-row-menu-scrolled">
                                            <DropdownButton variant="secondary" title={t("menu:profile")}>
                                                {(isEditor || isSuperleader || isCoordinator || isValidator) &&
                                                    <Nav.Item >
                                                        <DropdownButton variant="secondary" title={t(MAIN_MENU2.adminMenu.name)}>
                                                            {ADMIN_MENU.map((item, key) => <React.Fragment key={key}>
                                                                {hasRole(item.role) &&
                                                                    <>
                                                                    {item.isHeader &&
                                                                        <Dropdown.Item className={item.className} onClick={() => {
                                                                            setExp(false)
                                                                        }}>{t(item.name)}</Dropdown.Item>
                                                                    }
                                                                    {!item.isHeader &&
                                                                        <Dropdown.Item as={NavLink} className={item.className} to={tOrStr(item.path)} onClick={() => {
                                                                            setExp(false)
                                                                        }}>{t(item.name)}</Dropdown.Item>
                                                                    }
                                                                    </>
                                                                }
                                                                </React.Fragment>
                                                            )}
                                                        </DropdownButton>
                                                    </Nav.Item>
                                                }
                                                <ShowWhenLoggedIn>
                                                    <Nav.Item>
                                                        <DropdownButton variant="secondary" title={t(MAIN_MENU2.myAmunatcoll.name)}>
                                                            {MY_AMUNATCOLL_MENU.map((item, key) =>
                                                                <Dropdown.Item key={key} as={NavLink} to={tOrStr(item.path)} onClick={() => {
                                                                    setExp(false)
                                                                }}>{t(item.name)}</Dropdown.Item>
                                                            )}
                                                        </DropdownButton>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <DropdownButton variant="secondary" title={shortName}>
                                                            <Dropdown.Item as={NavLink} to={tOrStr(PROFILE_MENU.profile.path)} onClick={() => {
                                                                setExp(false)
                                                            }}>{t(PROFILE_MENU.profile.name)}</Dropdown.Item>
                                                            <Dropdown.Item as={NavLink} to={tOrStr(PROFILE_MENU.logout.path)} onClick={() => {
                                                                props.userLogout()
                                                                setExp(false)
                                                            }}>{t(PROFILE_MENU.logout.name)}</Dropdown.Item>
                                                        </DropdownButton>
                                                    </Nav.Item>
                                                </ShowWhenLoggedIn>
                                                <ShowWhenNotLoggedIn>
                                                    <Navbar.Text>
                                                        {props.loading &&
                                                            <Spinner
                                                                as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                                className="ml-1"
                                                            />
                                                        }
                                                    </Navbar.Text>
                                                    <Nav.Item>
                                                        <Nav.Link as={NavLink} to={tOrStr(PROFILE_MENU.login.path)} onClick={() => setExp(false)}>{t(PROFILE_MENU.login.name)}</Nav.Link>
                                                    </Nav.Item>
                                                </ShowWhenNotLoggedIn>
                                                <Nav.Item>
                                                    {i18n.languages[0] === 'pl' &&
                                                        <Button onClick={() => {
                                                            changeLanguage('en')
                                                            setExp(false)
                                                        }} variant="secondary" active={i18n.languages[0] === 'en'} aria-label="Zmień język na angielski"><span aria-hidden="true">EN</span></Button>
                                                    }
                                                    {i18n.languages[0] === 'en' &&
                                                        <Button onClick={() => {
                                                            changeLanguage('pl')
                                                            setExp(false)
                                                        }} variant="secondary" active={i18n.languages[0] === 'pl'} aria-label="Change language to Polish"><span aria-hidden="true">PL</span></Button>
                                                    }
                                                </Nav.Item>
                                            </DropdownButton>
                                        </Nav.Item>
                                        <ShowWhenLoggedIn>
                                            <Nav.Item>
                                                <Button onClick={() => {
                                                    props.userLogout()
                                                    setExp(false)
                                                }} variant="secondary">{t(PROFILE_MENU.logout.name)}</Button>
                                            </Nav.Item>
                                        </ShowWhenLoggedIn>
                                    </Nav>
                                </Navbar.Collapse>
                            </Navbar >
                            <div className="home-eu-flag">
                                {i18n.languages[0] === 'pl' &&
                                    <img src="/images/ue_pl.png" alt={t('menu:eu-flag')}></img>
                                }
                                {i18n.languages[0] === 'en' &&
                                    <img src="/images/ue_en.png" alt={t('menu:eu-flag')}></img>
                                }
                            </div>
                            </>
                        }
                    </Container>
                </div>
                {props.searchAvailable &&
                    <Container>
                        <div>
                            <SimpleResultsContainer
                                genusRequestId={TAXONDB_SIMPLE_SEARCH_GENUS_REQUEST_ID}
                                speciesRequestId={TAXONDB_SIMPLE_SEARCH_SPECIES_REQUEST_ID}
                                searchRequestId={TAXONDB_SIMPLE_SEARCH_REQUEST_ID}
                                iconsRequestId={TAXONDB_SIMPLE_SEARCH_ICONS_REQUEST_ID}
                                tabId={TAXON_SIMPLE_RESULTS_ACTIVE_TAB_FIELD_NAME}
                                hasIconsListOther
                            ></SimpleResultsContainer>
                        </div>
                    </Container>
                }
                {!props.searchAvailable &&
                    <>
                        <div id="side-menu-container">
                            <MenuComponent></MenuComponent>
                        </div>
                        <div className="section first d-flex flex-column" ref={ref1}>
                            
                                <div id="iiif-main-picture" tabIndex="0" className="icondb-openseadragon-container"></div>
                                <div className="content d-flex flex-column flex-grow-1" ref={overlay}>
                                    <Container className="title">
                                        <h1>
                                            <span className="main">{t('section_1_line_1')}</span>
                                            <span>{t('section_1_line_2')}</span>
                                            <span>{t('section_1_line_3')}</span>
                                        </h1>
                                    </Container>
                                    <div className="info" id="info"><div className="inner">{t('ctrl_button_info')}</div></div>
                                    {props.loadingCount &&
                                        <Spinner animation="border" role="status" />
                                    }
                                    {mapVisible &&
                                        <div>
                                            <div className="zoom-container">
                                                <div>
                                                    <Container className="d-flex flex-row-reverse">
                                                        <div>
                                                            <div className="d-flex flex-column">
                                                                <Button size="sm" variant="link" id={"zoom-in"} className="zoom-icon" aria-label={t("a11y:zoom-in")}>+</Button>
                                                                <Button size="sm" variant="link" id={"zoom-out"} className="zoom-icon" aria-label={t("a11y:zoom-out")}>-</Button>
                                                            </div>
                                                        </div>
                                                    </Container>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {mapVisible && props.count && !props.loadingCount &&
                                    <div className="numbers-container d-flex flex-column justify-content-end flex-grow-1">
                                        {props.count && !props.loadingCount &&
                                            <div className="numbers-container" >
                                                <Container className="numbers">
                                                    {currentSlide === 0 &&
                                            <div>{t('counter_records_0')}: {props.count.taxons.TAXON.toLocaleString(i18n.languages[0])}{t('counter_icons_0')}: {props.count.icons.TAXON.toLocaleString(i18n.languages[0])}</div>
                                                    }
                                                    {currentSlide === 1 &&
                                            <div>{t('counter_records_1')}: {props.count.icons.ICON.toLocaleString(i18n.languages[0])}{t('counter_icons_1')}:</div>
                                                    }
                                                </Container>
                                                <Container>
                                                    <div>
                                                        <div className="d-flex slider-content" id="slider-id">
                                                            <div className="slider-background"></div>
                                                            <Carousel indicators={false} interval={sliderPaused === true ? null : 15000} onSelect={onSliderChange} prevLabel={t('common:previous')} nextLabel={t('common:next')}>
                                                                <Carousel.Item>
                                                                    <div className="d-flex slider-item align-content-start flex-wrap">
                                                                        <div className="element">
                                                                            <Button variant="link" onClick={() => { viewer.current.goToPage(2) }}>
                                                                                <div className="d-flex align-items-center justify-content-center element-container">
                                                                                    <div className="d-flex flex-column justify-content-start element-info">
                                                                                        <div className="slider-item-title">{t('slider-title-1')}</div>
                                                                                        <div>
                                                                                            <img src="/images/Zasób 10@2x.png" alt="" className="first-img" />
                                                                                            {t('slider-records')}: {props.count.taxons[ "TAXON-PLANTAE" ].toLocaleString(i18n.languages[0])}
                                                                                        </div>
                                                                                        <div>
                                                                                            <img src="/images/Zasób 11@2x.png" alt="" className="second-img" />
                                                                                            {t('slider-icons')}: {props.count.icons[ "TAXON-PLANTAE" ].toLocaleString(i18n.languages[0])}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="circle"><img src="/images/slide-1.1.png" alt="" /></div>
                                                                                </div>
                                                                            </Button>
                                                                        </div>
                                                                        <div className="element">
                                                                            <Button variant="link" onClick={() => { viewer.current.goToPage(3) }}>
                                                                                <div className="d-flex align-items-center justify-content-center element-container">
                                                                                    <div className="d-flex flex-column justify-content-start element-info">
                                                                                        <div className="slider-item-title">{t('slider-title-2')}</div>
                                                                                        <div>
                                                                                            <img src="/images/Zasób 10@2x.png" alt="" className="first-img" />
                                                                                            {t('slider-records')}: {props.count.taxons[ "TAXON-FUNGI" ].toLocaleString(i18n.languages[0])}
                                                                                        </div>
                                                                                        <div>
                                                                                            <img src="/images/Zasób 11@2x.png" alt="" className="second-img" />
                                                                                            {t('slider-icons')}: {props.count.icons[ "TAXON-FUNGI" ].toLocaleString(i18n.languages[0])}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="circle"><img src="/images/slide-1.2.png" alt="" /></div>
                                                                                </div>
                                                                            </Button>
                                                                        </div>
                                                                        <div className="element">
                                                                            <Button variant="link" onClick={() => { viewer.current.goToPage(4) }}>
                                                                                <div className="d-flex align-items-center justify-content-center element-container">
                                                                                    <div className="d-flex flex-column justify-content-start element-info">
                                                                                        <div className="slider-item-title">{t('slider-title-3')}</div>
                                                                                        <div>
                                                                                            <img src="/images/Zasób 10@2x.png" alt="" className="first-img" />
                                                                                            {t('slider-records')}: {props.count.taxons[ "TAXON-ANIMALIA" ].toLocaleString(i18n.languages[0])}
                                                                                        </div>
                                                                                        <div>
                                                                                            <img src="/images/Zasób 11@2x.png" alt=""className="second-img" />
                                                                                            {t('slider-icons')}: {props.count.icons[ "TAXON-ANIMALIA" ].toLocaleString(i18n.languages[0])}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="circle"><img src="/images/slide-1.3.png" alt="" /></div>
                                                                                </div>
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                </Carousel.Item>
                                                                <Carousel.Item>
                                                                    <div className="d-flex slider-item align-content-start flex-wrap">
                                                                        <div className="element">
                                                                            <Button variant="link" onClick={() => { viewer.current.goToPage(5) }}>
                                                                                <div className="d-flex align-items-center justify-content-center element-container">
                                                                                    <div className="d-flex flex-column justify-content-start element-info">
                                                                                        <div className="slider-item-title">{t('slider-title-4')}</div>
                                                                                        <div>
                                                                                            <img src="/images/Zasób 11@2x.png" alt="" className="second-img" />
                                                                                            {t('slider-icons')}: {props.count.icons[ "ICON-ORGANISM" ].toLocaleString(i18n.languages[0])}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="circle"><img src="/images/slide-2.1.png" alt=""/></div>
                                                                                </div>
                                                                            </Button>
                                                                        </div>
                                                                        <div className="element">
                                                                            <Button variant="link" onClick={() => { viewer.current.goToPage(6) }}>
                                                                                <div className="d-flex align-items-center justify-content-center element-container">
                                                                                    <div className="d-flex flex-column justify-content-start element-info">
                                                                                        <div className="slider-item-title">{t('slider-title-5')}</div>
                                                                                        <div>
                                                                                            <img src="/images/Zasób 11@2x.png" alt="" className="second-img" />
                                                                                            {t('slider-icons')}: {props.count.icons[ "ICON-HABITAT" ].toLocaleString(i18n.languages[0])}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="circle"><img src="/images/slide-2.2.png" alt="" /></div>
                                                                                </div>
                                                                            </Button>
                                                                        </div>
                                                                        <div className="element">
                                                                            <Button variant="link" onClick={() => { viewer.current.goToPage(7) }}>
                                                                                <div className="d-flex align-items-center justify-content-center element-container">
                                                                                    <div className="d-flex flex-column justify-content-start element-info">
                                                                                        <div className="slider-item-title">{t('slider-title-6')}</div>
                                                                                        <div>
                                                                                            <img src="/images/Zasób 11@2x.png" alt="" className="second-img" />
                                                                                            {t('slider-icons')}: {props.count.icons[ "ICON-LANDSCAPE" ].toLocaleString(i18n.languages[0])}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="circle"><img src="/images/slide-2.3.png" alt="" /></div>
                                                                                </div>
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                </Carousel.Item>
                                                            </Carousel>
                                                        </div>
                                                    </div>
                                                </Container>
                                            </div>
                                        }
                                    </div>
                                    }
                                </div>
                            
                        </div>
                        <div className="section second d-flex flex-column" ref={ref2}>
                            <Container className="d-flex flex-grow-1 flex-column">
                                <div className="section-content d-flex flex-column flex-grow-1">
                                    <div className="title2 w-50">{t('section_2_line_1')}</div>
                                    <div className="list w-50">
                                        <div className="list-content">
                                            <div className="d-flex align-items-start">
                                                <div className="list-dot"></div>
                                                <div>{t('section_2_line_2')}</div>
                                            </div>
                                            <div className="d-flex align-items-start">
                                                <div className="list-dot"></div>
                                                <div>{t('section_2_line_3')}</div>
                                            </div>
                                            <div className="d-flex align-items-start">
                                                <div className="list-dot"></div>
                                                <div>{t('section_2_line_4')}</div>
                                            </div>
                                            <div className="d-flex align-items-start">
                                                <div className="list-dot"></div>
                                                <div>{t('section_2_line_5')}</div>
                                            </div>
                                            <div className="d-flex align-items-start">
                                                <div className="list-dot"></div>
                                                <div>{t('section_2_line_6')}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="see-more-button-container d-flex flex-column flex-grow-1 justify-content-end">
                                        <div className=" flex-grow-1 d-flex align-items-end justify-content-start">
                                            <div>
                                                <Button className="see-more-button" onClick={e => { goto(t('paths:gis-info')) }}>{t('see_more_button')}</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Container>
                        </div>
                        <div className="section third d-flex flex-column" ref={ref3}>
                            <div className="mobile-version d-flex flex-column flex-grow-1">
                                <div className="header">
                                    <Container>
                                        <div className="title">
                                            {t('section_3_line_1')}<br />
                                            {t('section_3_line_2')}<br />
                                            {t('section_3_line_3')}<br />
                                            {t('section_3_line_4')}<br />
                                        </div>
                                    </Container>
                                </div>
                                <div className="flex-grow-1 d-flex flex-column justify-content-around px-5">
                                    <div className="d-flex justify-content-center"><img className="mobile-icons" src="/images/app-mobile-ico-1.png" alt={t("a11y:icon-app-mobile-1")} /></div>
                                    <div className="d-flex justify-content-center">{t('section_3_line_5')} {t('section_3_line_6')}</div>
                                    <div className="d-flex justify-content-center"><img className="mobile-icons" src="/images/app-mobile-ico-2.png" alt={t("a11y:icon-app-mobile-2")} /></div>
                                    <div className="d-flex justify-content-center">{t('section_3_line_7')} {t('section_3_line_8')}</div>
                                    <div className="d-flex justify-content-center"><img className="mobile-icons" src="/images/app-mobile-ico-3.png" alt={t("a11y:icon-app-mobile-3")} /></div>
                                    <div className="d-flex justify-content-center">{t('section_3_line_9')} {t('section_3_line_10')}</div>
                                    <div className="d-flex justify-content-center"><img className="mobile-icons" src="/images/app-mobile-ico-4.png" alt={t("a11y:icon-app-mobile-4")} /></div>
                                    <div className="d-flex justify-content-center">{t('section_3_line_11')} {t('section_3_line_12')}</div>
                                    <div className="d-flex justify-content-center"><img className="mobile-icons" src="/images/app-mobile-ico-5.png" alt={t("a11y:icon-app-mobile-5")} /></div>
                                    <div className="d-flex justify-content-center">{t('section_3_line_13')} {t('section_3_line_14')}</div>
                                    <div className="d-flex justify-content-center">
                                        <div className="d-flex flex-column flex-sm-row">
                                            <ExternalLinkPopover link='https://apps.apple.com/pl/app/amunatcoll/id1523442673' title={t("common:go-to") + "App Store"}><img src="/images/amu-apple-store-1.png" alt={t("common:go-to") + "App Store"} className="store-icons" /></ExternalLinkPopover>
                                            <ExternalLinkPopover link='https://play.google.com/store/apps/details?id=pl.pcss.amunatcoll.mobile' title={t("common:go-to") + "Google Play"}><img src="/images/amu-google-play-2.png" alt={t("common:go-to") + "Google Play"} className="store-icons" /></ExternalLinkPopover>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Container className="normal-version d-flex flex-grow-1">
                                <div>
                                    <div className="section-content d-flex flex-column">
                                        <div className="title">
                                            {t('section_3_line_1')}<br />
                                            {t('section_3_line_2')}<br />
                                            {t('section_3_line_3')}<br />
                                            {t('section_3_line_4')}<br />
                                        </div>
                                        <div className="buttons d-flex flex-column flex-grow-1 justify-content-end">
                                            <div className="d-flex">
                                                <ExternalLinkPopover link='https://apps.apple.com/pl/app/amunatcoll/id1523442673' placement="top" title={t("common:go-to") + "App Store"}><img src="/images/amu-apple-store-1.png" alt={t("common:go-to") + "App Store"} className="store-icons" /></ExternalLinkPopover>
                                                <ExternalLinkPopover link='https://play.google.com/store/apps/details?id=pl.pcss.amunatcoll.mobile' placement="top" title={t("common:go-to") + "Google Play"}><img src="/images/amu-google-play-2.png" alt={t("common:go-to") + "Google Play"} className="store-icons" /></ExternalLinkPopover>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="right-col">
                                <div className="d-flex flex-column align-items-start section-content">
                                    <div className=" d-flex flex-column flex-grow-1">
                                        <div className="w-75">
                                            <div className="media d-flex align-items-center">
                                                <div><img className="mobile-icons" src="/images/app-mobile-ico-1.png" alt={t("a11y:icon-app-mobile-1")} /></div>
                                                <div className="media-body">
                                                    <div>{t('section_3_line_5')} {t('section_3_line_6')}</div>
                                                </div>
                                            </div>
                                            <div className="media d-flex align-items-center">
                                                <div><img className="mobile-icons" src="/images/app-mobile-ico-2.png" alt={t("a11y:icon-app-mobile-2")} /></div>
                                                <div className="media-body">
                                                    <div>{t('section_3_line_7')} {t('section_3_line_8')}</div>
                                                </div>
                                            </div>
                                            <div className="media d-flex align-items-center">
                                                <div><img className="mobile-icons" src="/images/app-mobile-ico-3.png" alt={t("a11y:icon-app-mobile-3")} /></div>
                                                <div className="media-body">
                                                    <div>{t('section_3_line_9')} {t('section_3_line_10')}</div>
                                                </div>
                                            </div>
                                            <div className="media d-flex align-items-center">
                                                <div><img className="mobile-icons" src="/images/app-mobile-ico-4.png" alt={t("a11y:icon-app-mobile-4")} /></div>
                                                <div className="media-body">
                                                    <div>{t('section_3_line_11')} {t('section_3_line_12')}</div>
                                                </div>
                                            </div>
                                            <div className="media d-flex align-items-center">
                                                <div><img className="mobile-icons" src="/images/app-mobile-ico-5.png" alt={t("a11y:icon-app-mobile-5")} /></div>
                                                <div className="media-body">
                                                    <div>{t('section_3_line_13')} {t('section_3_line_14')}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-end flex-grow-1">
                                            <div className="d-flex  justify-content-end flex-grow-1 mr-3">
                                                <Button className="see-more-button" onClick={e => { goto(t('paths:mobile-info')) }}>{t('see_more_button')}</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </Container>
                        </div>
                        <div className="section fourth d-flex flex-column" ref={ref4}>
                            <Container className="d-flex flex-grow-1">
                                <div className="d-flex flex-column flex-md-row">
                                    <div className="pr-3">
                                        <div className="d-flex align-items-center section-content">
                                            <div className="content-background">
                                                <div className="title">{t('section_4_line_1')}</div>
                                                <div>
                                                    <div className="list-content">
                                                        <div className="d-flex align-items-start">
                                                            <div className="list-dot"></div>
                                                            <div>{t('section_4_line_2')}</div>
                                                        </div>
                                                        <div className="d-flex align-items-start">
                                                            <div className="list-dot"></div>
                                                            <div>{t('section_4_line_3')}</div>
                                                        </div>
                                                        <div className="d-flex align-items-start">
                                                            <div className="list-dot"></div>
                                                            <div>{t('section_4_line_4')}</div>
                                                        </div>
                                                        <div className="d-flex align-items-start">
                                                            <div className="list-dot"></div>
                                                            <div>{t('section_4_line_5')}</div>
                                                        </div>
                                                        <div className="d-flex align-items-start">
                                                            <div className="list-dot"></div>
                                                            <div>{t('section_4_line_5_1')}</div>
                                                        </div>
                                                        <div className="d-flex align-items-start">
                                                            <div className="list-dot"></div>
                                                            <div>{t('section_4_line_6')}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ minWidth: "230px" }}>
                                        <div className="section-content d-flex justify-content-center align-items-end">
                                            <Button className="see-more-button" onClick={e => { goto(t('paths:our-users')) }}>{t('see_more_button')}</Button>
                                        </div>
                                    </div>
                                </div>
                            </Container>
                        </div>
                        <div className="section fifth d-flex flex-column" ref={ref5}>
                            <Container className="d-flex flex-column py-4 flex-grow-1 justify-content-center">
                                <div className="px-4">
                                    <strong>{t('section_5')}</strong>
                                </div>
                                {i18n.languages[0] === 'pl' &&
                                    <div className="d-flex flex-column flex-sm-row">
                                        <div className="d-flex flex-column">
                                            <ExternalLinkPopover link='http://biologia.amu.edu.pl/' title="Przejdź do strony Wydziału Biologii UAM"><img src="images/UAM-WB-logo-pl.png" alt={`Logo - ${t("a11y:AMU-biology")}`} width="100%" /></ExternalLinkPopover>

                                            <div><img src="images/UAM-WB.png" alt={t("a11y:AMU-building")} width="100%" /></div>
                                        </div>
                                        <div className="d-flex flex-column">
                                            <ExternalLinkPopover link='https://www.pcss.pl/' title="Przejdź do strony PCSS"><img src="images/pcss-logo-pl.png" alt={`Logo - ${t("a11y:PSNC")}`} width="100%" /></ExternalLinkPopover>
                                            <div><img src="images/PCSS.png" alt={t("a11y:PSNC-building")} width="100%" /></div>
                                        </div>
                                    </div>
                                }
                                {i18n.languages[0] === 'en' &&
                                    <div className="d-flex flex-column flex-sm-row">
                                        <div className="d-flex flex-column">
                                            <ExternalLinkPopover link='http://bioleng.home.amu.edu.pl/page.php' title="Go to page of Faculty of Biology on AMU"><img src="images/UAM-WB-logo-en.png" alt={`Logo - ${t("a11y:AMU-biology")}`} width="100%" /></ExternalLinkPopover>
                                            <div><img src="images/UAM-WB.png" alt={t("a11y:AMU-building")} width="100%" /></div>
                                        </div>
                                        <div className="d-flex flex-column">
                                            <ExternalLinkPopover link='https://www.psnc.pl/' title="Go to page of PSNC"><img src="images/pcss-logo-en.png" alt={`Logo - ${t("a11y:PSNC")}`} width="100%" /></ExternalLinkPopover>
                                            <div><img src="images/PCSS.png" alt={t("a11y:PSNC-building")} width="100%" /></div>
                                        </div>
                                    </div>
                                }
                            </Container>
                            {location.pathname === "/" &&
                                <Footer activeMenu={activeMenu}></Footer>
                            }
                        </div>
                    </>
                }
            </div>
        </Suspense >
    )
}

const mapStateToProps = state => ({
    loadingCount: isPending(TAXONDB_COUNT_REQUEST_ID, state),
    count: getOutput(TAXONDB_COUNT_REQUEST_ID, state),
    searchAvailable: true && (isPending(TAXONDB_SIMPLE_SEARCH_GENUS_REQUEST_ID, state) || getItems(TAXONDB_SIMPLE_SEARCH_GENUS_REQUEST_ID, state)),
})

const mapDispatchToProps = dispatch => ({
    setActiveMenu: value => dispatch(setField(SOCIAL_MENU_VERSION_ID, SOCIAL_MENU_VERSION_ID, value)),
    clearResponse: () => {
        dispatch(requestRemove(TAXONDB_SIMPLE_SEARCH_GENUS_REQUEST_ID))
        dispatch(requestRemove(TAXONDB_SIMPLE_SEARCH_SPECIES_REQUEST_ID))
        dispatch(requestRemove(TAXONDB_SIMPLE_SEARCH_REQUEST_ID))
        dispatch(requestRemoveStartsWith(AGGREGATION_REPORT_REQUEST_ID_PREFIX + TAXONDB_SIMPLE_SEARCH_REQUEST_ID))
    },
    getImageInfo: url => dispatch(getData('image-info', url)),
    userLogout: () => dispatch(logout()),
    setIsLoggingOut: () => dispatch(setIsLoggingOut()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HomeContainer)

