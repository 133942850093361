import React, { Suspense } from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import SearchForm from "./iconDB/SearchForm"
import ResultsContainer from "./iconDB/ResultsContainer"
import { isPending, getOutput } from "../reducers";
import { Container, Row, Col } from "react-bootstrap"
import {
    ICONDB_PAGE_URL_EN,
    ICONDB_PAGE_URL_PL,
    ICONDB_SEARCH_REQUEST_ID,
    TAXONDB_COUNT_REQUEST_ID,
} from '../settings'
import Loader from '../utils/loader'
import I18nPathTranslator from "./I18nPathTranslator";
import SEO from "./SEO";
import RedirectLoginWithReturn from "./access/RedirectLoginWithReturn";

const TaxonDBContainer = props => {

    const { t } = useTranslation([ 'taxondb', 'paths' ])

    return (
        <Suspense fallback={<Loader />}>
            <I18nPathTranslator {...props} pathEN={ICONDB_PAGE_URL_EN} pathPL={ICONDB_PAGE_URL_PL} />
            <SEO title={t('icondb-form-title')} />
            {props.loading && <Loader />}
            {!props.loading && props.user && !props.user.data &&
                <RedirectLoginWithReturn lang={props.lang} returnUrl={props.lang ? t("paths:iconDB", {lng: props.lang}) : t("paths:iconDB")} />
            }
            {!props.loading && props.user && props.user.data &&
                <div>
                    <div>
                        <Container>
                            <Row>
                                <Col className="mt-3 mb-4"><h1>{t('icondb-form-title')}</h1></Col>
                            </Row>
                        </Container>
                    </div>
                    <div>
                        <SearchForm></SearchForm>
                    </div>
                    <div>
                        <ResultsContainer
                            searchRequestId={ICONDB_SEARCH_REQUEST_ID}
                        ></ResultsContainer>
                    </div>
                </div>
            }
        </Suspense>
    )
}

const mapStateToProps = state => ({
    loadingCount: isPending(TAXONDB_COUNT_REQUEST_ID, state),
    count: getOutput(TAXONDB_COUNT_REQUEST_ID, state),
    user: state.user,
    loading: isPending('userInfo', state),
})

const mapDispatchToProps = dispatch => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TaxonDBContainer)

