import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Spinner, ListGroup, Button, Row, Col, Collapse } from  "react-bootstrap"; 
import I18nPathTranslator from '../I18nPathTranslator';
import {
    MY_MAPS_PAGE_URL_EN,
    MY_MAPS_PAGE_URL_PL,
    ARRANGEMENT_TOOLS_SEARCH_ON_INIT,
    MAP_TOOLS_VISUALIZATION_1,
    MAP_TOOLS_VISUALIZATION_RELATIONS,
} from '../../settings';
import SEO from '../SEO';
import useApi from '../../hooks/useApi';
import usePagination from '../../hooks/usePagination';
import {
    URL_API_MAP_GET_ALL,
    URL_API_MAP_DELETE,
    URL_API_MAP_GET_DEFINITION,
} from "../../urls";
import { Redirect } from "react-router-dom"
import * as notify from '../../utils/notify'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkedAlt, faTrash, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import Confirm from '../Confirm'
import useMapRedux from '../../hooks/useMapRedux';
import useFieldRedux from '../../hooks/useFieldRedux';
import useMapDefinition from '../../hooks/useMapDefinition';
import ChangeMapName from './saveLoadMap/ChangeMapName';

const MyMaps = (props) => {
    const { t, i18n } = useTranslation(['map'])
    const { setValue: setMapId } = useMapRedux('mapId');
    const { setValue: setMapTitle } = useMapRedux('mapTitle');
    const { setValue: setSearchOnInit } = useFieldRedux(ARRANGEMENT_TOOLS_SEARCH_ON_INIT);
    const [ redirectToMap, setRedirectToMap ] = useState(false)
    const { response, refetch, data } = useApi('post', URL_API_MAP_GET_ALL);
    const { Pagination, currentPage, perPage } = usePagination(response, refetch, data);
    const getFilter = () => ({
        pagination: { currentPage, perPage },
    });
    const [ filter, setFilter ] = useState(getFilter());
    const { refetch: deleteMap } = useApi('post', URL_API_MAP_DELETE);
    const { refetch: getDefinition } = useApi('get', URL_API_MAP_GET_DEFINITION);
    const { setMapState } = useMapDefinition();
    const [ inProgress, setInProgress ] = useState(false);
    const [ changedNames, setChangedNames ] = useState({})

    useEffect(() => {
        setInProgress(true);
        setChangedNames({});
        refetch(filter)
            .then(() => {
                setInProgress(false);
            });
    }, [ refetch, filter ]);

    const goToMap = (item) => {
        setInProgress(true);
        getDefinition({}, item.id)
            .then(res => {
                setMapState(res.data);
                setMapTitle(item.name)
                setMapId(item.id)
                setSearchOnInit(true)
                setRedirectToMap(MAP_TOOLS_VISUALIZATION_RELATIONS[res.data.activeVisualization || MAP_TOOLS_VISUALIZATION_1].url)
            })
            .catch(err => {
                setInProgress(false);
                notify.error(t('map:cannot-load-map-definition'))
            })
    }

    const handleDeleteMap = (map_id) => {
        setInProgress(true);
        deleteMap({ map_id })
            .then(() => {
                notify.success(t('map:map-removed'))
                setFilter({ ...getFilter() });
            });
    }
	const [isChangeMapNameOpen, setChangeMapNameOpen] = useState({})
	const toggleChangeMapName = (mapId, newName) => {
		setChangeMapNameOpen({...isChangeMapNameOpen, [mapId]: !isChangeMapNameOpen[mapId]})
        if (newName) {
            setChangedNames({ ...changedNames, [mapId]: newName })
        }
	}

    return (
        <Container>
            {redirectToMap &&
                <Redirect push to={(i18n.language.startsWith('pl') ? redirectToMap.pl : redirectToMap.en) + '/'}></Redirect>
            }
            <I18nPathTranslator {...props} pathEN={MY_MAPS_PAGE_URL_EN} pathPL={MY_MAPS_PAGE_URL_PL} />
            <SEO title={t("menu:my-maps")} />
            <div>
                <div className="mt-3 mb-4">
                    <h1>
                    {t("menu:my-maps")}
                    </h1>
                </div>
                {inProgress &&
                    <div className="text-center">
                        <Spinner animation="border" role="status" />
                    </div>
                }
                {!inProgress &&
                    <>
                        <>
                            {response.data?.items?.length > 0 &&
                                <div>
                                    <Pagination></Pagination>
                                    <ListGroup>
                                    {
                                        response.data.items.map((item, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <ListGroup.Item>
                                                        <Row>
                                                            <Col className="d-flex align-items-center">
                                                                <img src="/images/list-empty.svg" alt={t("a11y:list-opened")} />
                                                                {changedNames[item.id] || item.name}
                                                            </Col>
                                                            <Col sm="auto" className="d-flex flex-row align-self-center">
                                                                <>
                                                                    <Button
                                                                        onClick={e => goToMap(item)}
                                                                        size="sm"
                                                                        variant="secondary"
                                                                        className="mr-1" 
                                                                    >
                                                                        <FontAwesomeIcon icon={faMapMarkedAlt} title={t('map:load')} />
                                                                    </Button>
                                                                    <Button variant="secondary" size="sm" className="mr-1" onClick={e => toggleChangeMapName(item.id)}>
                                                                        <FontAwesomeIcon icon={faPencilAlt} title={t('map:change-map-name')} />
                                                                    </Button>
                                                                    <Confirm
                                                                        onConfirm={() => handleDeleteMap(item.id)}
                                                                        size="md"
                                                                        buttonLabel={<FontAwesomeIcon icon={faTrash} title={t('map:delete-map')} />}
                                                                        confirmButtonLabel={t('map:delete-map')}
                                                                        confirmationText={t('map:confirm-delete-map')}
                                                                        variant="danger"
                                                                    >
                                                                        <Button size="sm" variant="danger" id={"delete-map" + props.requestId}>
                                                                            <FontAwesomeIcon icon={faTrash} title={t('map:delete')} />
                                                                        </Button>
                                                                    </Confirm>
                                                                </>
                                                            </Col>
                                                        </Row>
                                                    </ListGroup.Item>
                                                    <Collapse in={isChangeMapNameOpen[item.id]} id={"changeMapName" + props.projectId}>
                                                        <div>
                                                            <ChangeMapName mapId={item.id} mapName={changedNames[item.id] || item.name} requestId={props.requestId} onDone={toggleChangeMapName} />
                                                        </div>
                                                    </Collapse>
                                                </React.Fragment>
                                            )
                                        })
                                    }    
                                    </ListGroup>
                                    <Pagination last></Pagination>
                                </div>
                            }
                            {response.data?.items && response.data?.items?.length === 0 &&
                                <div>
                                    { t('map:no-saved-maps')}
                                </div>
                            }
                        </>
                    </>
                }
            </div>
        </Container>
    )
}

export default MyMaps
