import React, { useState, useEffect } from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import * as actions from '../../actions'
import { postDataApi } from '../../actions'
import { Container, Row, Col, Button, Collapse, ListGroup } from "react-bootstrap"
import Confirm from '../Confirm'
import ChangeProjectName from './ChangeProjectName'
import ProjectDetails from './ProjectDetails'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkedAlt, faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import {
	PROJECTS_GET_OBSERVATIONS_ID,
	PROJECTS_GET_OBSERVATIONS_URL,
	PROJECTS_DELETE_PROJECT_ID,
	PROJECTS_DELETE_PROJECT_URL,
	ARRANGEMENT_PAGE_URL_EN,
	ARRANGEMENT_PAGE_URL_PL,
	MAP_TOOLS_ACTIVE_VISUALIZATION,
	MAP_TOOLS_VISUALIZATION_1,
	TAXON_SEARCH_IS_DYNAMIC
} from '../../settings'
import { getOutput } from "../../reducers";
import * as notify from '../../utils/notify'
import { Redirect } from "react-router-dom"
import { setField } from "../../actions/forms";
import { setLoadObservationsOnInit, setObservationsRedirectedId } from "../../actions/map";

const ProjectsListItem = props => {
	const { t, i18n } = useTranslation(['projects', 'forms', 'taxondb-report'])

	const [ redirectToMap, setRedirectToMap ] = useState(false)

	useEffect(() => {
		if (props.path && props.path.length > 0 && props.projectId === props.path[0]) {
			setShowDetailsOpen(true)
			props.getObservations(Math.ceil((props.path[3] || 1) / 20))
		}
	}, [props])

	const [isShowDetailsOpen, setShowDetailsOpen] = useState(false)
	const toggleShowDetailsOpen = () => {
		setShowDetailsOpen(!isShowDetailsOpen)
		if (!isShowDetailsOpen) {
			props.getObservations(1)
		}
	}

	const [isChangeProjectNameOpen, setChangeProjectNameOpen] = useState(false)
	const toggleChangeProjectName = () => {
		setChangeProjectNameOpen(!isChangeProjectNameOpen)
	}

	const onRemoveProject = () => {
		props.deleteProject(props.projectId, props.data, t)
	}

	const goToMap = () => {
		props.setActiveVisualization(MAP_TOOLS_VISUALIZATION_1)
    	props.setIsDynamic("0")
		props.setObservationsRedirectedId(props.projectId)
		props.setLoadObservationsOnInit(true)
		setRedirectToMap(true)
	}

	return (
		<>
			{redirectToMap &&
				<Redirect push to={(i18n.language.startsWith('pl') ? ARRANGEMENT_PAGE_URL_PL : ARRANGEMENT_PAGE_URL_EN) + '/'}></Redirect>
			}
			<ListGroup.Item className="album-element" >
				<Row>
					<Col className="d-flex align-items-center" onClick={toggleShowDetailsOpen}>
						{isShowDetailsOpen &&
							<img src="/images/list-open.svg" alt={t("a11y:list-opened")} />
						}
						{!isShowDetailsOpen &&
							<img src="/images/list-closed.svg" alt={t("a11y:list-closed")} />
						}
						{props.projectName}
					</Col>
					<Col sm="auto" className="d-flex flex-row align-self-center">
						{!props.viewedBy &&
							<>
								<Button
									onClick={e => goToMap()}
									size="sm"
									variant="secondary"
									className="mr-1" 
								>
									<FontAwesomeIcon icon={faMapMarkedAlt} title={t('taxondb-report:map')} />
								</Button>
								<Button variant="secondary" size="sm" className="mr-1" onClick={toggleChangeProjectName}>
									<FontAwesomeIcon icon={faPencilAlt} title={t('projects:change-name')} />
								</Button>
								<Confirm
									onConfirm={onRemoveProject}
									size="md"
									buttonLabel={<FontAwesomeIcon icon={faTrash} title={t('projects:remove-project')} />}
									confirmButtonLabel={t('projects:remove-project')}
									confirmationText={t('projects:confirm-remove-project')}
									variant="danger"
								>
									<Button size="sm" variant="danger" id={"delete-project" + props.requestId}>
										<FontAwesomeIcon icon={faTrash} title={t('projects:remove-project')} />
									</Button>
								</Confirm>
							</>
						}
					</Col>
				</Row>
			</ListGroup.Item>
			<Collapse in={isChangeProjectNameOpen} id={"changeProjectName" + props.projectId}>
				<div>
					<ChangeProjectName projectId={props.projectId} projectName={props.projectName} requestId={props.requestId} onDone={toggleChangeProjectName} />
				</div>
			</Collapse>
			<Collapse in={isShowDetailsOpen} id={"projectDetails" + props.projectId} className="pr-0">
				<Container>
					<div className="mt-2 mb-2">
						<ProjectDetails projectId={props.projectId} path={props.path} viewedBy={props.viewedBy} />
					</div>
				</Container>
			</Collapse>
		</>
	)
}

const mapStateToProps = (state, ownProps) => ({
	data: getOutput(ownProps.requestId, state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	getObservations: page => dispatch(postDataApi(PROJECTS_GET_OBSERVATIONS_ID + ownProps.projectId, `${PROJECTS_GET_OBSERVATIONS_URL}${ownProps.projectId}/`, { pagination: { currentPage: page, perPage: 20 } })),
	deleteProject: (projectId, oldData, t) => {
		dispatch(actions.postDataApi(PROJECTS_DELETE_PROJECT_ID, PROJECTS_DELETE_PROJECT_URL, {
			project_id: projectId
		})).then(() => {
			const newData = {
				...oldData, items: oldData.items.filter(obj => {
					return obj.id !== projectId
				})
			}

			dispatch(actions.requestSuccess(ownProps.requestId, newData))
			notify.success(t('projects:project-removed'))
			ownProps.onDone && ownProps.onDone()
		}).catch((res) => {
			notify.error(t('projects:cannot-remove-project'), res.result)
		})
	},
	setActiveVisualization: value => dispatch(setField(MAP_TOOLS_ACTIVE_VISUALIZATION, MAP_TOOLS_ACTIVE_VISUALIZATION, value)),
	setIsDynamic: value => dispatch(setField(TAXON_SEARCH_IS_DYNAMIC, TAXON_SEARCH_IS_DYNAMIC, value)),
	setObservationsRedirectedId: value => dispatch(setObservationsRedirectedId(value)),
	setLoadObservationsOnInit: value => dispatch(setLoadObservationsOnInit(value)),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ProjectsListItem)
