import React, { useEffect, useCallback } from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getOutput, isPending } from "../../reducers"
import { getDataApi } from '../../actions'
import {
    API_PROJECT_GET_USER_SPACE_ID,
    API_PROJECT_GET_USER_SPACE_URL
} from '../../settings'
import { HorizontalBar } from "react-chartjs-2";
import { Spinner } from "react-bootstrap";


const UserSpace = props => {
    const { t, i18n } = useTranslation([ 'team' ])

    const formatOutput = num => {
        const KB = 1000
        const MB = KB*1000
        const GB = MB*1000
        let output = num/KB
        let unit = "KB"
        if(output > 1000){
            output = num/MB
            unit = "MB"
        }
        if(output > 1000){
            output = num/GB
            unit = "GB"
        }
        return output.toLocaleString(i18n.languages[0], { maximumFractionDigits: 2 }) + " " + unit
    }

    const getSpacesCallback = useCallback(props.getSpaces, []);
    useEffect(() => {
        getSpacesCallback()
    }, [getSpacesCallback])

    return (
            <>
            {props.spaces &&
                <div className="user-space-wrapper">
                    <div>
                        <p>{t("team:used-space")} <b>{formatOutput(props.spaces.used_space)}</b></p>
                        <p>{t("team:user-space")} <b>{formatOutput(props.spaces.user_space)}</b></p>
                    </div>
                    <div className="chart-wrapper">
                    <HorizontalBar data={{
                            datasets: [
                                {
                                backgroundColor: 'rgba(0,86,179,0.2)',
                                borderColor: 'rgba(0,86,179,1)',
                                borderWidth: 1,
                                data: [((props.spaces.used_space/props.spaces.user_space)*100).toFixed(2)]
                                }
                            ]
                            }} 
                            options={{
                                legend:{
                                    display: false
                                },
                                title:{
                                    display: true,
                                    text: t("team:chart-title")
                                },
                                tooltips: {enabled: false},
                                scales:{
                                    xAxes: [{ stacked: true, ticks: {
                                        beginAtZero:true,
                                        min: 0,
                                        max: 100    
                                    }}],
                                    yAxes: [{ display: false, stacked: true }]
                                },
                                maintainAspectRatio: false,
                                responsive: true,
                                locale: i18n.languages[0] === 'pl' ? "pl-PL" : "en-US",
                            }} />
                            </div>
                </div>
            }
            {props.loading &&
                <div className="text-center">
                    <Spinner animation="border" role="status" />
                </div>
            }
            </>
    )
}

const mapStateToProps = state => ({
    loading: isPending(API_PROJECT_GET_USER_SPACE_ID, state),
    spaces: getOutput(API_PROJECT_GET_USER_SPACE_ID, state)
})

const mapDispatchToProps = dispatch => ({
    getSpaces: () => dispatch(getDataApi(API_PROJECT_GET_USER_SPACE_ID, API_PROJECT_GET_USER_SPACE_URL)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserSpace)
