import React, { useState, useEffect } from "react"
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import * as actions from '../../actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExchangeAlt, faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import {
    PROJECTS_GET_OBSERVATION_ID,
    PROJECTS_GET_OBSERVATION_URL,
    PROJECTS_ADD_OBSERVATION_ID,
    PROJECTS_REMOVE_OBSERVATION_ID,
    PROJECTS_REMOVE_OBSERVATION_URL
} from '../../settings'
import { getOutput, getItems, getPagination, isPending } from "../../reducers"
import ConnectedPagination from "../ConnectedPagination"
import Confirm from '../Confirm'
import { Button, ListGroup, Collapse, Spinner } from "react-bootstrap"
import RecordDetailsContainer from "./RecordDetailsContainer"
import ChangeObservationName from "./ChangeObservationName"
import ChangeObservationProject from "./ChangeObservationProject"
import CreateObservation from "./CreateObservation"
import ProjectsExporter from "./ProjectsExporter"
import * as notify from '../../utils/notify'
import CopyObservation from "./CopyObservation"
import ListGroupItemDiv from "../ListGroupItemDiv"

const TaxonDBResultsList = props => {
    const { t } = useTranslation([ 'common', 'projects', 'icondb' ])

    const [ open, setOpen ] = useState((props.path && props.path.length > 0) ? { [ props.path[ 1 ] ]: true } : {})
    const [ createObservationOpen, setCreateObservationOpen ] = useState(false)
    const [ isOpened, setIsOpened ] = useState({})

    const { path, projectId, getObservation } = props
    useEffect(() => {
        if (path && path.length > 0 && projectId === path[ 0 ]) {
            getObservation(path[ 1 ])
        }
    }, [ path, projectId, getObservation ])

    const toggleOpen = observationId => {
        const temp = { ...open }
        temp[ observationId ] = !temp[ observationId ]
        setOpen(temp)
        if (temp[ observationId ]) {
            props.getObservation(observationId)
        }
    }

    const [ isChangeNameOpen, setChaneNameOpen ] = useState({})

    const toggleChangeNameOpen = id => {
        const temp = { ...isChangeNameOpen }
        temp[ id ] = !temp[ id ]
        setChaneNameOpen(temp)
    }

    const [ isChangeProjectOpen, setChangeProjectOpen ] = useState({})

    const toggleChangeProjectOpen = id => {
        const temp = { ...isChangeProjectOpen }
        temp[ id ] = !temp[ id ]
        setChangeProjectOpen(temp)
    }

    const [ isCopyOpen, setIsCopyOpen ] = useState({})
    const toggleCopyOpen = id => {
        const temp = { ...isCopyOpen }
        temp[ id ] = !temp[ id ]
        setIsCopyOpen(temp)
    }

    const onRemoveObservation = (observationId) => {
        props.removeObservation(observationId, props.data, t)
    }

    return (
        <div className="d-flex flex-column mt-2">
            {props.loading &&
                <div className="align-self-center">
                    <Spinner animation="border" role="status" />
                </div>
            }
            {!props.loading &&
                <div>
                    {props.items && props.items.length > 0 &&
                        <div>
                            <ProjectsExporter projectId={props.projectId} requestId={props.requestId} url={props.requestUrl}>
                                {!props.viewedBy &&
                                    <Button variant="primary" onClick={() => setCreateObservationOpen(!createObservationOpen)} disabled={props.loading || props.creating} type="submit" className="ml-2">
                                        {t('projects:create-observation')}
                                        {props.creating &&
                                            <Spinner className="ml-2" as="div" animation="border" role="status" aria-hidden="true" />
                                        }
                                    </Button>
                                }
                            </ProjectsExporter>
                            {createObservationOpen &&
                                <CreateObservation projectId={props.projectId} onDone={() => setCreateObservationOpen(false)} />
                            }
                            <ConnectedPagination requestId={props.requestId} url={props.requestUrl}></ConnectedPagination>
                            <ListGroup>
                                {props.items.map(val => {
                                    const requestId = PROJECTS_GET_OBSERVATION_ID + val.id;
                                    return (
                                        <div key={requestId}>
                                            <ListGroupItemDiv className="d-flex flex-row album-element align-items-center" onClick={() => toggleOpen(val.id)}>
                                                <div className="d-flex flex-grow-1">
                                                    {open[ val.id ] &&
                                                        <img src="/images/list-open.svg" alt={t("a11y:list-opened")} />
                                                    }
                                                    {!open[ val.id ] &&
                                                        <img src="/images/list-closed.svg" alt={t("a11y:list-closed")} />
                                                    }
                                                    <div className="mr-1">{val[ 'name' ]}</div>
                                                </div>
                                                <div className="d-flex align-self-center">
                                                    {!props.viewedBy &&
                                                        <>
                                                            <Button variant="secondary" size="sm" className="mr-2" onClick={() => toggleChangeNameOpen(val.id)}>
                                                                <FontAwesomeIcon icon={faPencilAlt} title={t('projects:change-name')} />
                                                            </Button>
                                                            <Button variant="secondary" size="sm" className="mr-2" onClick={() => toggleChangeProjectOpen(val.id)}>

                                                                <FontAwesomeIcon icon={faExchangeAlt} title={t('projects:change-observation-project')} />
                                                            </Button>
                                                            <Confirm
                                                                onConfirm={() => onRemoveObservation(val.id)}
                                                                size="md"
                                                                buttonLabel={t('projects:remove-observation')}
                                                                confirmationText={t('projects:confirm-remove-observation')}
                                                            >
                                                                <Button size="sm" variant="danger" id={"delete-observation" + val.id}>
                                                                    <FontAwesomeIcon icon={faTrash} title={t('projects:remove-observation')} />
                                                                </Button>
                                                            </Confirm>
                                                        </>
                                                    }
                                                    {props.viewedBy &&
                                                        <Button
                                                            variant="secondary"
                                                            size="sm"
                                                            className="mr-2"
                                                            onClick={() => toggleCopyOpen(val.id)}>
                                                            {t('projects:copy-observation')}
                                                        </Button>
                                                    }
                                                </div>
                                            </ListGroupItemDiv>
                                            <Collapse in={isChangeNameOpen[ val.id ]} id={"changeName" + val.id}>
                                                <div>
                                                    <ChangeObservationName observationId={val.id} observationName={val.name} requestId={props.requestId} onDone={() => toggleChangeNameOpen(val.id)} />
                                                </div>
                                            </Collapse>
                                            <Collapse in={isChangeProjectOpen[ val.id ]} id={"changeProject" + val.id}>
                                                <div>{isChangeProjectOpen[ val.id ] &&
                                                    <ChangeObservationProject observationId={val.id} projectId={props.projectId} requestId={props.requestId} onDone={() => toggleChangeProjectOpen(val.id)} />
                                                }</div>
                                            </Collapse>
                                            <Collapse in={isCopyOpen[ val.id ]}>
                                                <div>{isCopyOpen[ val.id ] &&
                                                    <CopyObservation observationId={val.id} onDone={() => toggleCopyOpen(val.id)} />
                                                }</div>
                                            </Collapse>

                                            <Collapse in={open[ val.id ]} id={requestId}
                                                onEntering={() => setIsOpened({ ...isOpened, [ val.id ]: true })}
                                                onExited={() => setIsOpened({ ...isOpened, [ val.id ]: false })}
                                            >
                                                <div>
                                                    {(open[ val.id ] || isOpened[ val.id ]) &&
                                                        <div className="d-flex flex-column">
                                                            <RecordDetailsContainer viewedBy={props.viewedBy} requestId={requestId} recordId={val.id}></RecordDetailsContainer>
                                                        </div>
                                                    }
                                                </div>
                                            </Collapse>
                                        </div>
                                    )
                                })}
                            </ListGroup>
                            <div>
                                <ConnectedPagination last requestId={props.requestId} url={props.requestUrl}></ConnectedPagination>
                            </div>
                        </div>
                    }
                    {props.items && !props.items.length > 0 &&
                        <div>
                            <div>
                                <Button variant="primary" onClick={() => setCreateObservationOpen(!createObservationOpen)} disabled={props.loading || props.creating} type="submit" className="ml-2">
                                    {t('projects:create-observation')}
                                    {props.creating &&
                                        <Spinner className="ml-2" as="div" animation="border" role="status" aria-hidden="true" />
                                    }
                                </Button>
                                {createObservationOpen &&
                                    <CreateObservation projectId={props.projectId} onDone={() => setCreateObservationOpen(false)} />
                                }
                            </div>
                            <div className="mt-4 ml-2">
                                <p>{t('no-records-found')}</p>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    data: getOutput(ownProps.requestId, state),
    items: getItems(ownProps.requestId, state),
    loading: isPending(ownProps.requestId, state),
    creating: isPending(PROJECTS_ADD_OBSERVATION_ID, state),
    pagination: getPagination(ownProps.requestId, state),
    user: state.user ? state.user.data : null,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    getObservation: observationId => dispatch(actions.getDataApi(PROJECTS_GET_OBSERVATION_ID + observationId, `${PROJECTS_GET_OBSERVATION_URL}${observationId}/`)),
    removeObservation: (observationId, oldData, t) => {
        dispatch(actions.postDataApi(PROJECTS_REMOVE_OBSERVATION_ID, PROJECTS_REMOVE_OBSERVATION_URL, {
            observation_id: observationId
        })).then(() => {
            const newData = {
                ...oldData, items: oldData.items.filter(obj => {
                    return obj.id !== observationId
                })
            }

            dispatch(actions.requestSuccess(ownProps.requestId, newData))
            notify.success(t('projects:observation-removed'))
            ownProps.onDone && ownProps.onDone()
        }).catch((res) => {
            notify.error(t('projects:cannot-remove-observation'), res.result)
        })
    }
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TaxonDBResultsList)
