import React, { useState } from "react";
import { connect } from 'react-redux'
import * as ajax from '../utils/ajax'
import { getField, prepareFilter } from "../reducers";
import { setField } from '../actions/forms'
import Autosuggest from 'react-autosuggest'
import { Spinner } from "react-bootstrap"
import "../styles/react-autosuggest.scss"
import axios from "axios"
import debounce from "debounce"
import { SUGGEST_DEBOUNCE_TIME_DELAY, ANC_URL_POSTFIX } from '../settings'
import { getElementStructure, setElementStructure } from "../utils/search";

const InputWithAsyncTypeahead = props => {

    const [ suggestions, setSuggestions ] = useState([])

    const [ isLoading, setIsLoading ] = useState(false)

    const [ lastSource, setLastSource ] = useState(null)
    const [ lastDebounce, setLastDebounce ] = useState(null)

    const postfix = props.postfix ? props.postfix : ANC_URL_POSTFIX

    const onSuggestionsFetchRequested = query => {
        setIsLoading(true)
        let temp = { ...props.prepareFilter }
        if (props.path) {
            // tymczasowo wyłączamy filtr dla podpowiadaczek w wyszukiwarce dynamicznej ze wględu na warunki "OR"
            temp = {}
        } else {
            delete temp[ props.id ]
        }

        lastDebounce && lastDebounce.cancel()
        lastSource && lastSource.cancel()
        const source = axios.CancelToken.source()
        setLastSource(source)
        setLastDebounce(
            debounce(() => ajax.custom_post_with_cancel(postfix, props.url, {
                filter: temp,
                query: query.value,
            }, source)
                .then(response => {
                    setSuggestions(response.data)
                    setIsLoading(false)
                })
                .catch(error => {
                    if (!axios.isCancel(error)) {
                        setSuggestions([])
                        setIsLoading(false)
                    }
                })
                , SUGGEST_DEBOUNCE_TIME_DELAY)
        )
    }

    const onSuggestionsClearRequested = () => {
        setSuggestions([])
    }

    const getSuggestionValue = suggestion => {
        if (typeof suggestion === 'object')
            return suggestion.id
        return suggestion
    }

    const inputProps = {
        value: props.selectedValue,
        onChange: e => {
            if (props.onChange) {
                props.onChange(e)
            }
            if (props.conditionalChange) {
                if (props.conditionalChange(e)) {
                    props.setSelectedValue(e.target.value, props.fields)
                }
            } else {
                props.setSelectedValue(e.target.value, props.fields)
            }
        },
        className: 'form-control',
        disabled: props.disabled,
        id: props.id,
        autoComplete: "off",
        onKeyUp: e => {
            if (e.keyCode === 13) { // ENTER
                lastSource && lastSource.cancel()
                setSuggestions([])
                setIsLoading(false)
            }
            props.onKeyUp(e)
        },
        placeholder: props.placeholder,
    }

    const renderSuggestion = suggestion => {
        let txt = suggestion
        if (suggestion.label)
            txt = suggestion.label
        const txt_lower = txt.toLowerCase()
        const query = props.selectedValue.toLowerCase()

        if (txt_lower.startsWith(query)) {
            const bold = txt.substring(0, query.length)
            const normal = txt.substring(query.length)
            return (
                <span>
                    <b>{bold}</b>{normal}
                </span>
            )
        } else {
            const words = txt.split(" ")
            const words_lower = txt_lower.split(" ")
            return words_lower.map((val, indx) => {
                if (val.startsWith(query)) {
                    const bold = words[ indx ].substring(0, query.length)
                    const normal = words[ indx ].substring(query.length)
                    return (
                        <span key={indx}>
                            <b>{bold}</b>{normal}{" "}
                        </span>
                    )
                }
                const original = words[ indx ]
                return (
                    <span key={indx}>
                        {original}{" "}
                    </span>
                )
            })
        }
    }

    const onSuggestionSelected = (e, val) => {
        props.setSelectedValue(val.suggestionValue, props.fields)
    }

    const renderInputComponent = inputProps => (
        <div>
            <input {...inputProps} />
            {isLoading &&
                <Spinner animation="border" role="status" size="sm" className="spinner-inside-input">
                </Spinner>
            }
        </div>
    );

    return (
        <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
            id={props.id}
            onSuggestionSelected={onSuggestionSelected}
            renderInputComponent={renderInputComponent}
            shouldRenderSuggestions={props.shouldRenderSuggestions}
        >
        </Autosuggest>
    )
}

const mapStateToProps = (state, ownProps) => ({
    selectedValue: ownProps.path ?
        getElementStructure(ownProps.path,
            JSON.parse(getField(ownProps.formId, ownProps.formId, state, "null"))) :
        getField(ownProps.formId, ownProps.id, state),
    fields: JSON.parse(getField(ownProps.formId, ownProps.formId, state, "null")),
    prepareFilter: prepareFilter(ownProps.formId, state, ownProps.path),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    setSelectedValue: (value, fields) =>
        ownProps.path ?
            dispatch(setField(ownProps.formId, ownProps.formId, JSON.stringify(setElementStructure(ownProps.path, fields, value)))) :
            dispatch(setField(ownProps.formId, ownProps.id, value)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InputWithAsyncTypeahead)
