import React, { useState } from "react"
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { isPending, getOutputOrDefault } from "../../reducers"
import { Form, Button, Col } from "react-bootstrap"
import {
    PROTECTION_SOURCES_GET_ALL_ID
} from '../../settings'

const SearchProtectedTaxon = props => {
    const { t } = useTranslation(['protectedTaxon', 'forms', 'common'])

    const [group, setGroup] = useState('all')

    const onGroupChanged = e => {
        setGroup(e.target.value)
    }

    const [name, setName] = useState('')

    const onNameChanged = e => {
        setName(e.target.value)
    }

    const [protectionLevel, setProtectionLevel] = useState('all')

    const onProtectionLevelChanged = e => {
        setProtectionLevel(e.target.value)
    }

    const [protectionSource, setProtectionSource] = useState('all')

    const onProtectionSourceChanged = e => {
        setProtectionSource(e.target.value)
    }

    const [orderBy, setOrderBy] = useState('name')

    const onOrderByChanged = e => {
        setOrderBy(e.target.value)
    }

    const [ignoreDates, setIgnoreDates] = useState(false);

    const onIgnoreDatesChanged = (e) => {
        setIgnoreDates(e.target.checked);
    }

    const onSubmitForm = () => {
        props.onSearch && props.onSearch(
            group === 'all'? null : group,
            name.trim() === ''? null : name.trim(),
            protectionLevel === 'all'? null : protectionLevel,
            protectionSource === 'all'? null : protectionSource,
            orderBy, ignoreDates)
    }

    const onClearForm = () => {
        setGroup('all')
        setName('')
        setProtectionLevel('all')
        setProtectionSource('all')
        setOrderBy('name')
        setIgnoreDates(false)
    }

    return (
        <Form className="mt-2 p-1 ml-4 mb-2" onSubmit={e => { e.preventDefault() }}>
            <Form.Row>
                <Col>
                    <Form.Group>
                        <Form.Label htmlFor="group">{t('protectedTaxon:group')}</Form.Label>
                        <Form.Control id="group" as="select" value={group} onChange={onGroupChanged} disabled={props.querying}>
                            <option value="all">{t('common:all')}</option>
                            <option value="okaz">{t('protectedTaxon:okaz')}</option>
                            <option value="gatunek">{t('protectedTaxon:gatunek')}</option>
                            <option value="rodzaj">{t('protectedTaxon:rodzaj')}</option>
                            <option value="rodzina">{t('protectedTaxon:rodzina')}</option>
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label htmlFor="name">{t('protectedTaxon:name')}</Form.Label>
                        <Form.Control id="name" value={name} onChange={onNameChanged} disabled={props.querying}></Form.Control>
                    </Form.Group>
                </Col>
            </Form.Row>
            <Form.Row>
                <Col>
                    <Form.Group>
                        <Form.Label htmlFor="protectionLevel">{t('protectedTaxon:protection-level')}</Form.Label>
                        <Form.Control id="protectionLevel" as="select" value={protectionLevel} onChange={onProtectionLevelChanged} disabled={props.querying}>
                            <option value="all">{t('common:all')}</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label htmlFor="protectionSource">{t('protectedTaxon:protection-source')}</Form.Label>
                        <Form.Control id="protectionSource" as="select" value={protectionSource} onChange={onProtectionSourceChanged} disabled={props.querying}>
                            <option value="all">{t('common:all')}</option>
                            {props.protectionSources.map((source, i) =>
                                <option key={i} value={source.id}>{source.nazwa}</option>
                            )}
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} xs={6}>
                    <Form.Label htmlFor="orderBy">{t('protectedTaxon:orderBy')}</Form.Label>
                    <Form.Control id="orderBy" as="select" value={orderBy} onChange={onOrderByChanged} disabled={props.querying}>
                        <option value="name">{t('protectedTaxon:name')}</option>
                        <option value="id">{t('protectedTaxon:id')}</option>
                        <option value="count">{t('protectedTaxon:count')}</option>
                        <option value="date">{t('protectedTaxon:date')}</option>
                    </Form.Control>
                </Form.Group>
                <Form.Group as={Col} xs={3} className="d-flex align-items-center">
                    <Form.Check id="ignore-dates" type="checkbox" defaultChecked={ignoreDates} onChange={onIgnoreDatesChanged} label={t('protectedTaxon:ignore-dates')} className="ml-4 mt-4" />
                </Form.Group>
                <Form.Group as={Col} xs={3} className="d-flex align-self-end">
                    <div className="flex-grow-1" />
                    <Button variant="primary" onClick={onSubmitForm} disabled={props.querying}>
                        {t('common:search')}
                    </Button>
                    <Button variant="secondary" className="ml-2" onClick={onClearForm} disabled={props.querying} >
                        {t('common:clear')}
                    </Button>
                </Form.Group>
            </Form.Row>
        </Form>
    )
}

const mapStateToProps = state => ({
    querying: isPending(PROTECTION_SOURCES_GET_ALL_ID, state),
    protectionSources: getOutputOrDefault(PROTECTION_SOURCES_GET_ALL_ID, state, { items: []})['items']
})

const mapDispatchToProps = (dispatch, ownProps) => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchProtectedTaxon)