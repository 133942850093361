import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux'
import { DLIBRA_IIIF_URL } from '../../settings'
import { Button, Dropdown, DropdownButton, ButtonToolbar, Form, Spinner } from "react-bootstrap"
import { useForm, FormProvider, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import OpenSeadragon from 'openseadragon'
import Confirm from '../Confirm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo, faArrowLeft, faArrowRight, faSearchMinus, faSearchPlus, faDesktop } from '@fortawesome/free-solid-svg-icons'
import * as actions from '../../actions'
import { isPending, getOutput, getRequest } from "../../reducers";
import {
    DOWNLOADIMAGE_REQUEST_ID,
    DLIBRA_IMAGE_JPG_MAX_WIDTH,
    GET_IMAGE_ATTRIBUTES_REQUEST_ID_PREFIX,
    GET_IMAGE_ATTRIBUTES_REQUEST_URL,
    USER_GROUPS_EDITOR_CONST,
    ICONDB_CHANGE_REQUEST_ID,
    ICONDB_CHANGE_REQUEST_URL,
    GENERAL_EXTENDED_REQUEST_TIMEOUT,
    API_URL_POSTFIX
} from '../../settings'
import * as notify from '../../utils/notify'
import RedirectLoginWithReturn from '../access/RedirectLoginWithReturn'
import useUserRoles from "../../hooks/useUserRoles"
import { get_axios } from '../../utils/ajax';

const EditAttrValue = props => {
    const { register, errors } = useFormContext();
    return (
        <Form.Group className="mb-0">
            <Form.Control name={props.name} size="sm" type="text" ref={register} isInvalid={errors[props.name]} autoComplete="off" />
            <Form.Control.Feedback type="invalid">
                {errors[props.name] ? errors[props.name][0] : ''}
            </Form.Control.Feedback>
        </Form.Group>
    )
}

const DivRow = props => {
    const { t } = useTranslation(['icondb-attributes'])
    return (<>
        {props.data[props.name] &&
            <div className="d-flex" style={{ borderTopStyle: "solid", borderTopColor: "#dee2e6", borderTopWidth: "1px" }}>
                <div className="w-50 p-1">
                    {t('icondb-attributes:' + props.name)}
                </div>
                <div className="w-50 p-1">
                    {!props.editable && props.data[props.name]}
                    {props.editable && <EditAttrValue {...props} />}
                </div>
            </div>
        }
    </>)
}

const SingleDlibraImage = props => {
    const { t, i18n } = useTranslation(['common'])

    const [currentImage, setCurrentImage] = useState(props.initialImage ? props.initialImage + 1 : 1);
    const { requestId, getImageAttributes, initialImage = 0, onImageChange = null } = props
    const images = useRef(props.data.images)
    const [paddingTop, setPaddingTop] = useState('100%')
    const [editable, setEditable] = useState(false)
    const { register, handleSubmit, errors, clearErrors, setError, reset, getValues, control } = useForm({
        defaultValues: {}
    });
    const { isLoggedIn } = useUserRoles()
    const lastPage = useRef(null)

    useEffect(() => {
        reset(Object.fromEntries(props.imageAttributes || []));
    }, [props.imageAttributes, reset]);

    const onEditableClick = () => {
        if (editable) {
            reset(getValues())
        }

        setEditable(!editable)
    }

    const onSave = (fields) => {
        clearErrors()
        props.saveRecord(fields, images.current[currentImage - 1], props.imageAttributes, t, setError, props.state)
    }

    useEffect(() => {
        const timerHandler = setTimeout(() => {
            if (images.current.length > 0) {
                // see: https://openseadragon.github.io/docs/OpenSeadragon.html#.Options
                // initialPage
                const urls = images.current.map(val => DLIBRA_IIIF_URL + val[0] + '-' + val[1] + '/info.json')
                console.log("Image URLs:", urls)
                if (isLoggedIn) {
                    getImageAttributes(requestId, images.current[currentImage - 1][0])
                }
                const viewer = OpenSeadragon({
                    initialPage: initialImage ? initialImage : 0,
                    id: 'iiif-' + requestId,
                    prefixUrl: "/openseadragon/images/",
                    showNavigator: true,
                    preserveViewport: true,
                    //visibilityRatio: 1,
                    sequenceMode: true,
                    crossOriginPolicy: false,
                    ajaxWithCredentials: false,
                    tileSources: urls.map(tileSource => ({
                        tileSource,
                        ajaxHeaders: { "Authorization": "Bearer " + localStorage.getItem('access') }
                    })),
                    homeButton: 'reset' + requestId,
                    zoomInButton: 'zoom-in' + requestId,
                    zoomOutButton: 'zoom-out' + requestId,
                    fullPageButton: 'full-screen' + requestId,
                    nextButton: "next-picture" + requestId,
                    previousButton: "previous-picture" + requestId,
                    loadTilesWithAjax: true,
                })

                viewer.addHandler('page', function (event) {
                    lastPage.current = event.page
                    setCurrentImage(event.page + 1);
                    if (isLoggedIn) {
                        getImageAttributes(requestId, images.current[event.page][0])
                    }
                    onImageChange && onImageChange(event.page + 1);
                })

                viewer.addHandler('tile-drawn', function (event) {
                    const size = event?.tiledImage?.source;
                    if (!size || size.width === 0) {
                        setPaddingTop('100%')
                    } else {
                        setPaddingTop(`calc(${size.height} / ${size.width} * 100%)`)
                    }
                })
                viewer.addHandler('open-failed', function (event) {
                    if (lastPage.current !== null) {
                        const nextTry = lastPage.current
                        lastPage.current = null
                        get_axios(API_URL_POSTFIX).get('/current_user/').then(res => {
                            viewer.open(viewer.tileSources.map(val => ({ ...val, ajaxHeaders: { "Authorization": "Bearer " + localStorage.getItem('access') }})), nextTry)
                        }).catch(err => {
                            console.log('err', err)
                        })
                    } else {
                        notify.error(i18n.t('common:cannot-load-image'))
                        viewer.open(viewer.tileSources.map(val => ({ ...val, ajaxHeaders: { "Authorization": "Bearer " + localStorage.getItem('access') }}))
                        .map(v => ((v.tileSource !== event.options.tileSource) ? v : {
                            type: 'image',
                            url: '/images/noimage.png'
                        })
                    ))
                    }
                })
            }
        }, 100);
        return () => clearTimeout(timerHandler);
    }, [requestId, initialImage, currentImage, getImageAttributes, onImageChange, isLoggedIn, i18n])

    const getAttributeValues = () => {
        if (!props.imageAttributes) {
            return {}
        }

        return Object.fromEntries(props.imageAttributes)
    }

    const onRemoveImage = (fields) => {
        // TODO
    }

    const [isRedirectLoginWithReturn, setRedirectLoginWithReturn] = useState(false)

    return (
        <div className="mb-1">
            {isRedirectLoginWithReturn &&
                <RedirectLoginWithReturn lang={localStorage.lang} returnUrl={window.location.pathname} />
            }
            {props.data &&
                <div>
                    <h1 className="ml-2"><i>{props.data.rodzaj} {props.data.gatunek}</i></h1>
                    <div>
                        <ButtonToolbar>
                            <DropdownButton size="sm" className="ml-1" id="dropdown-basic-button" title={t('download_image')} disabled={props.downloading}>
                                <Dropdown.Item onClick={() =>
                                    props.user ?
                                        props.downloadImage(
                                            `${DLIBRA_IIIF_URL}${props.data.images[currentImage - 1][0]}-${props.data.images[currentImage - 1][1]}/full//${DLIBRA_IMAGE_JPG_MAX_WIDTH},/0/default.jpg`,
                                            "jpg", props.data.kolekcjanumerokazu
                                        ) : setRedirectLoginWithReturn(true)
                                }
                                >JPG</Dropdown.Item>
                                <Dropdown.Item onClick={() =>
                                    props.user ?
                                        props.downloadImage(
                                            `${DLIBRA_IIIF_URL}${props.data.images[currentImage - 1][0]}-${props.data.images[currentImage - 1][1]}/full//full/0/default.tif`,
                                            "tif", props.data.kolekcjanumerokazu
                                        ) : setRedirectLoginWithReturn(true)
                                }
                                >TIF</Dropdown.Item>
                            </DropdownButton>
                            <Button size="sm" className="ml-2" variant="dark" id={"previous-picture" + props.requestId} disabled={props.loading}>
                                <FontAwesomeIcon icon={faArrowLeft} title={t('PreviousPage')} />
                            </Button>
                            <Button size="sm" className="ml-1" variant="dark" id={"next-picture" + props.requestId} disabled={props.loading}>
                                <FontAwesomeIcon icon={faArrowRight} title={t('NextPage')} />
                            </Button>
                            <Button size="sm" className="ml-1" variant="dark" id={"reset" + props.requestId} disabled={props.loading}>
                                <FontAwesomeIcon icon={faUndo} title={t('Home')} />
                            </Button>
                            <Button size="sm" className="ml-1" variant="dark" id={"zoom-in" + props.requestId} disabled={props.loading}>
                                <FontAwesomeIcon icon={faSearchPlus} title={t('ZoomIn')} />
                            </Button>
                            <Button size="sm" className="ml-1" variant="dark" id={"zoom-out" + props.requestId} disabled={props.loading} >
                                <FontAwesomeIcon icon={faSearchMinus} title={t('ZoomOut')} />
                            </Button>
                            <Button size="sm" className="ml-1" variant="dark" id={"full-screen" + props.requestId} disabled={props.loading} >
                                <FontAwesomeIcon icon={faDesktop} title={t('FullPage')} />
                            </Button>
                        </ButtonToolbar>
                        <div className="ml-2">
                            {t('common:picture_number')} <b>{currentImage}</b>  {t('common:of')} <b>{props.data.images.length}</b>
                        </div>
                    </div>
                </div>
            }
            <div id={'iiif-' + props.requestId} className="openseadragon-ratio-container" style={{ paddingTop: paddingTop }}></div>
            {props.imageAttributes &&
                <div>
                    <FormProvider {...{ register, errors, control }}>
                        <DivRow name="Title" editable={editable} data={getAttributeValues()} />
                        <DivRow name="Tags" editable={editable} data={getAttributeValues()} />
                    </FormProvider>
                </div>
            }
            {props.user != null && props.user.groups != null && props.user.groups.includes(USER_GROUPS_EDITOR_CONST) &&
                <div className="my-3 px-2">
                    <Form.Check
                        className="d-inline"
                        type="switch"
                        id={`editable-dlibra-image-switch-${props.requestId}`}
                        label={t('forms:edit')}
                        defaultChecked={editable}
                        onClick={onEditableClick}
                    />
                    {editable && <div className="float-right">
                        <span className="d-inline-block">
                            <Confirm
                                onConfirm={onRemoveImage}
                                size="sm"
                                buttonLabel={t('forms:remove')}
                                confirmationText={t('taxondb:confirm-remove-image')}
                                disabled={props.isSaving || true /* TODO: waiting for an endpoint */}
                                className="ml-1 mr-1"
                            ></Confirm>
                        </span>
                        <Button onClick={handleSubmit(onSave)} size="sm" disabled={props.isSaving} className="float-right">
                            {t('forms:save')}
                            {props.isSaving &&
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className="ml-1"
                                />
                            }
                        </Button>
                    </div>}
                </div>
            }
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    state: state,
    data: getOutput(ownProps.requestId, state),
    user: state.user ? state.user.data : null,
    downloading: isPending(DOWNLOADIMAGE_REQUEST_ID, state),
    isSaving: isPending(ICONDB_CHANGE_REQUEST_ID, state),
    imageAttributes: getOutput(GET_IMAGE_ATTRIBUTES_REQUEST_ID_PREFIX + ownProps.requestId, state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    downloadImage: (link, imgType, picturename) => dispatch(actions.getDlibraImage(DOWNLOADIMAGE_REQUEST_ID, link, imgType, picturename)),
    getImageAttributes: (requestId, ediId) => dispatch(actions.getDataDlibra(GET_IMAGE_ATTRIBUTES_REQUEST_ID_PREFIX + requestId, `${GET_IMAGE_ATTRIBUTES_REQUEST_URL}${ediId}/`)),
    saveRecord: (fields, image, imageAttributes, t, setError, state) => {
        console.log(fields, image, imageAttributes)
        let newFields = {}
        if (imageAttributes.find(a => a[0] === 'Title') && imageAttributes.find(a => a[0] === 'Title')[1] !== fields.Title) {
            newFields['Title'] = [fields.Title]
        }

        if (imageAttributes.find(a => a[0] === 'Tags') && imageAttributes.find(a => a[0] === 'Tags')[1] !== fields.Tags) {
            newFields['Tags'] = [fields.Tags]
        }

        dispatch(actions.postDataDlibra(
            ICONDB_CHANGE_REQUEST_ID,
            ICONDB_CHANGE_REQUEST_URL + image[0] + '/',
            { fields: newFields },
            GENERAL_EXTENDED_REQUEST_TIMEOUT
        )).then(() => {
            const request = getRequest(ownProps.requestId, state)
            dispatch(actions.getData(ownProps.requestId, request.url))
            notify.success(t('icondb:modified-icon'))
        }).catch(res => {
            const err = res?.response?.data?.error;
            if (err && err.icon) {
                Object.keys(err.icon).forEach(k => {
                    setError(k, err.icon[k]);
                })
            }

            notify.error(t('icondb:cannot-modify-icon'), res?.response?.data?.error?.error || res?.response?.data)
        })
    }
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SingleDlibraImage)
