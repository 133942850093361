import React, { useState } from "react";
import { connect } from 'react-redux'
import { Col } from "react-bootstrap";
import {
    API_GET_USERS_BY_ROLE_ID,
    API_GET_USER_DETAILS_ID,
    API_GET_USER_DETAILS_URL
} from "../settings"
import { getOutput, isPending } from "../reducers";
import { getDataApi } from "../actions"
import UserRolesDetails from './UserRolesDetails'
import SeeMoreIcon from "./SeeMoreIcon";


const UsersRolesList = props => {
    const [buttonState, setButtonState] = useState({})

    const toggleDetails = (requestId) => {
        const temp = { ...buttonState }
        temp[requestId] = !temp[requestId]
        setButtonState(temp)
        if (temp[requestId]){
            props.getUserDetails(props.userData.id)
        }
    }

    return (
        <>
            <div className="d-flex flex-row album-element align-items-center border p-3"
                tabIndex="0"
                onClick={e => toggleDetails(props.userData.id)}
                onKeyDown={e => {if(e.key === "Enter"){toggleDetails(props.userData.id)}}}
                aria-expanded={toggleDetails[props.userData.id]}
                aria-controls={buttonState[props.userData.id]}
            >               
                <Col xs={1}>
                <div className="d-flex justify-content-center align-items-center">
                    <SeeMoreIcon isOpen={buttonState[props.userData.id]} />
                </div>
                </Col>
                <Col xs={11}>
                {props.userData.first_name} {props.userData.last_name} ({props.userData.email}, {props.userData.username})
                </Col>
            </div>
            {buttonState[props.userData.id] && 
                <UserRolesDetails requestId={API_GET_USER_DETAILS_ID + props.userData.id} />
            }
        </>
    )
}


const mapStateToProps = state => ({
    user: state.user ? state.user.data : null,
    usersByRole: getOutput(API_GET_USERS_BY_ROLE_ID, state),
    loading: isPending(API_GET_USERS_BY_ROLE_ID, state),
    userDetails: getOutput('get-user-roles-details', state),
    loadingDetails: isPending('get-user-roles-details', state),
})
  
const mapDispatchToProps = dispatch => ({
    getUserDetails: id => dispatch(getDataApi(API_GET_USER_DETAILS_ID + id, `${API_GET_USER_DETAILS_URL}${id}/`))
})
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UsersRolesList)