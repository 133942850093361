import React, { useState } from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import * as actions from '../../actions'
import {
  TAXONDB_SEARCH_REQUEST_URL,
  TAXONDB_SEARCH_SPECIES_REQUEST_URL,
  ANC_URL_POSTFIX,
} from '../../settings'
import { isPending, getItems, getPagination, getFilter } from "../../reducers";
import ConnectedPagination from "../ConnectedPagination"
import { GENUS_ATTRIBUTE_NAME, SPECIES_ATTRIBUTE_NAME } from '../taxonDB/attributesStructure'
import { ListGroup, Spinner, Collapse } from "react-bootstrap"
import ValueFromDictionary from "../dictionary/ValueFromDictionary"
import ResultsList from "../taxonDB/ResultsList"

const SpeciesItemList = props => {
  const { t, i18n } = useTranslation([ 'common', 'forms', 'a11y' ])

  const [ open, setOpen ] = useState({})

  const selectSpecies = (requestId, genus, species) => {
    const temp = { ...open }
    temp[ requestId ] = !temp[ requestId ]
    setOpen(temp)
    if (temp[ requestId ]) {
      const filter = { ...props.filter }
      filter.rodzaj = genus
      filter.gatunek = species
      props.search({
        filter: filter,
        pagination: { currentPage: 1 },
      }, requestId)
    }
  }

  return (
    <div className="d-flex flex-column mt-2 ml-4">
      {props.loading &&
        <div className="align-self-center">
          <Spinner animation="border" role="status" />
        </div>
      }
      {!props.loading &&
        <div>
          {!props.items &&
            <p>{t('use-form-to-search')}</p>
          }
          {props.items && props.items.length > 0 &&
            <div>
              <div className="d-flex flex-row justify-content-center">
                <div className="p-2">
                  {t('taxondb:nr_of_species_found')}: <b>{props.paginationSpecies.totalCount ? props.paginationSpecies.totalCount.toLocaleString(i18n.languages[0]) : '-'}</b>
                </div>
              </div>
              <ConnectedPagination requestId={props.requestId} url={ANC_URL_POSTFIX + TAXONDB_SEARCH_SPECIES_REQUEST_URL}></ConnectedPagination>
              <ListGroup>
                {props.items.map((val, indx) => {
                  const requestId = props.requestId + '-' + val[ SPECIES_ATTRIBUTE_NAME ];
                  return (
                    <div key={indx}>
                      <ListGroup.Item tabIndex="0" action className="d-flex flex-row album-element align-items-center"
                        onClick={() => selectSpecies(requestId, val[ GENUS_ATTRIBUTE_NAME ], val[ SPECIES_ATTRIBUTE_NAME ])}>
                        <div className="d-flex flex-grow-1 mr-2 align-items-center"
                          aria-controls={requestId}
                          aria-expanded={open[ requestId ]}>
                          <div>
                            {open[ requestId ] &&
                              <img src="/images/list-open.svg" alt={t("a11y:list-opened")} />
                            }
                            {!open[ requestId ] &&
                              <img src="/images/list-closed.svg" alt={t("a11y:list-closed")} />
                            }
                          </div>
                          <div className="mr-1">
                            <ValueFromDictionary value={val[ GENUS_ATTRIBUTE_NAME ]} name={GENUS_ATTRIBUTE_NAME}></ValueFromDictionary>
                          </div>
                          <div className="mr-1">
                            <ValueFromDictionary value={val[ SPECIES_ATTRIBUTE_NAME ]} name={SPECIES_ATTRIBUTE_NAME}></ValueFromDictionary>
                          </div>
                          <div>
                            ({t('taxondb:nr_of_specimens')}: {val.liczba_okazow.toLocaleString(i18n.languages[0])})
                                                    </div>
                        </div>
                      </ListGroup.Item>
                      <Collapse in={open[ requestId ]} id={requestId}>
                        <div>
                          <ResultsList
                            requestId={requestId}
                          ></ResultsList>
                        </div>
                      </Collapse>
                    </div>
                  )
                })}
              </ListGroup>
              <div>
                <ConnectedPagination last requestId={props.requestId} url={ANC_URL_POSTFIX + TAXONDB_SEARCH_SPECIES_REQUEST_URL}></ConnectedPagination>
              </div>
            </div>
          }
          {props.items && !props.items.length > 0 &&
            <>
              <p>{t('no-records-found')}</p>
            </>
          }
        </div>
      }
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  loading: isPending(ownProps.requestId, state) || isPending(ownProps.genusRequestId, state),
  items: getItems(ownProps.requestId, state),
  pagination: getPagination(ownProps.searchRequestId, state),
  paginationSpecies: getPagination(ownProps.requestId, state),
  filter: getFilter(ownProps.requestId, state),
})


const mapDispatchToProps = (dispatch, ownProps) => ({
  search: (data, requestId) => dispatch(actions.postDataAnc(requestId, TAXONDB_SEARCH_REQUEST_URL, data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpeciesItemList)

