import React, { useState, useEffect } from "react"
import { Form, FormLabel, Container, Row, Col, Button, Spinner } from "react-bootstrap"
import { connect } from 'react-redux'
import * as actions from '../actions'
import { useTranslation } from 'react-i18next'
import { isPending, getOutput } from "../reducers"
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { REGISTER_PAGE_URL_EN, REGISTER_PAGE_URL_PL } from '../settings'
import * as notify from '../utils/notify'
import I18nPathTranslator from "./I18nPathTranslator"
import SEO from "./SEO"

const RegisterForm = props => {

    const { clearResponse } = props
    useEffect(() => clearResponse, [clearResponse])

    const { t } = useTranslation(['common', 'forms'])
    const { executeRecaptcha } = useGoogleReCaptcha()

    const [fields, setFields] = useState({ username: "", password: "", password_again: "", email: "", first_name: "", last_name: "" })
    const handleFormChange = e => {
        const temp = { ...fields }
        temp[e.target.name] = e.target.value
        if ((e.target.name === 'first_name' || e.target.name === 'last_name') && e.target.value !== '') {
            if (!(/^([\p{L}\s]+)$/u.test(e.target.value))) {
                return
            }
        }

        setFields(temp)
        setIsValid(
            Object.keys(fields).every(field => (temp[field] && temp[field].length > 0)) &&
            temp.password === temp.password_again
        )
    }

    const [isValid, setIsValid] = useState(false)

    const submitForm = async () => {
        const recaptcha = await executeRecaptcha()
        props.register({ ...fields, recaptcha }, t)
    }

    return (
          <Container className="d-flex flex-column mt-2">
            <SEO title={t('common:user_registration')} />
            {props.response && props.response.result === 0 &&
                <div className="pt-5 pb-5 align-self-center">{t('common:user_registered_please_confirm')}</div>
            }
            {(!props.response || props.response.result !== 0) &&
                <Form>
                    <I18nPathTranslator {...props} pathEN={REGISTER_PAGE_URL_EN} pathPL={REGISTER_PAGE_URL_PL} />
                    <Row>
                        <Col md={{ span: 4, offset: 4 }}>
                            <h1>{t('common:user_registration')}</h1>
                            <Form.Group>
                                <FormLabel htmlFor="username">{t('forms:username')}</FormLabel>
                                <Form.Control isInvalid={!fields.username} required autoFocus onChange={handleFormChange} value={fields.username} name="username" type="text" disabled={props.loading} id="username" autoComplete="off"></Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <FormLabel htmlFor="password">{t('forms:password')}</FormLabel>
                                <Form.Control isInvalid={!fields.password} required onChange={handleFormChange} value={fields.password} name="password" type="password" disabled={props.loading} id="password" autoComplete="off"></Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <FormLabel htmlFor="password_again">{t('forms:password_again')}</FormLabel>
                                <Form.Control isInvalid={!fields.password_again || fields.password !== fields.password_again} required onChange={handleFormChange} value={fields.password_again} autoComplete="off" name="password_again" type="password" disabled={props.loading} id="password_again"></Form.Control>
                                {fields.password !== fields.password_again &&
                                    <Form.Text>{t('forms:passwords_donot_match')}</Form.Text>
                                }
                            </Form.Group>
                            <Form.Group>
                                <FormLabel htmlFor="email">{t('forms:email')}</FormLabel>
                                <Form.Control isInvalid={!fields.email} required onChange={handleFormChange} value={fields.email} name="email" type="text" disabled={props.loading} id="email" autoComplete="off"></Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <FormLabel htmlFor="first_name">{t('forms:first_name')}</FormLabel>
                                <Form.Control isInvalid={!fields.first_name} required onChange={handleFormChange} value={fields.first_name} name="first_name" type="text" disabled={props.loading} id="first_name" autoComplete="off"></Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <FormLabel htmlFor="last_name">{t('forms:last_name')}</FormLabel>
                                <Form.Control isInvalid={!fields.last_name} required onChange={handleFormChange} value={fields.last_name} name="last_name" type="text" disabled={props.loading} id="last_name" autoComplete="off"></Form.Control>
                            </Form.Group>
                            <Button variant="primary" onClick={e => { e.preventDefault(); submitForm() }} disabled={props.loading || !isValid} type="submit">
                                {t('register')}
                                {props.loading &&
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        className="ml-1"
                                    />
                                }
                            </Button>
                        </Col>
                    </Row>
                </Form>
            }
            </Container>
    )
}

const mapStateToProps = state => ({
    loading: isPending('register', state),
    response: getOutput('register', state)
})

const mapDispatchToProps = dispatch => ({
    register: (data, t) => dispatch(actions.postDataApi('register', '/register/', data))
        .then(res => {
            if (res.result === 0) {
                notify.success(t('forms:register_success'))
            } else if (res.result === 1) {
                notify.error(t('register_failed'), t('bad_recaptcha'))
            } else if (res.result === 2) {
                notify.error(t('register_failed'), t('username_exists'))
            } else if (res.result === 3) {
                notify.error(t('register_failed'), t('bad_password'))
            } else {
                notify.error(t('register_failed'))
            }
        }),
    clearResponse: () => dispatch(actions.requestRemove('register')),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegisterForm)
