import React, { useState } from "react"
import { connect } from 'react-redux'
import { Container,  Spinner, Form, Button, Col } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { getOutput, isPending } from "../reducers";
import {
    API_URL_POSTFIX,
    TEAM_LEADERS_PAGE_URL_EN,
    TEAM_LEADERS_PAGE_URL_PL,
    API_GET_LEADERS_ID,
    API_GET_LEADERS_URL,
} from "../settings"
import { postDataApi } from "../actions"
import ConnectedPagination from './ConnectedPagination'
import I18nPathTranslator from "./I18nPathTranslator";
import SEO from "./SEO";
import TeamLeadersList from "./TeamLeadersList";

const TeamLeaders = props => {

    const { t } = useTranslation(['forms', 'menu', 'common'])
    const EMPTY = ""
    const IS_LEADER = "is"
    const IS_NOT_LEADER = "is not"

    const [isLeader, setIsLeader] = useState(null)
    const [leaderOption, setLeaderOption] = useState(EMPTY)
    const [filter, setFilter] = useState({})
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [login, setLogin] = useState('')

    const onLeaderChange = e => {
        setLeaderOption(e.target.value)
        if(e.target.value===EMPTY)
            setIsLeader(null)
        if(e.target.value===IS_LEADER)
            setIsLeader(true)
        if(e.target.value===IS_NOT_LEADER)
            setIsLeader(false)
    }

    const onFirstNameChanged = e => {
        setFirstName(e.target.value)
    }

    const onLastNameChanged = e => {
        setLastName(e.target.value)
    }

    const onLoginChange = e => {
        setLogin(e.target.value)
    }

    const onEmailChange = e => {
        setEmail(e.target.value)
    }

    const onClearForm = () => {
        setIsLeader(null)
        setLeaderOption(EMPTY)
        setEmail('')
        setFirstName('')
        setLastName('')
        setLogin('')
        setFilter({})
    }

    const onSubmitForm = () => {
        let filter = {}
        if (firstName){
            filter['first_name'] = firstName
        }
        if (lastName){
            filter['last_name'] = lastName
        }
        if (email){
            filter['email'] = email
        }
        if (login){
            filter['username'] = login
        }
        setFilter(filter)
        props.getLeaders(isLeader, filter)
    }

    return (
        <Container>
            <I18nPathTranslator {...props} pathEN={TEAM_LEADERS_PAGE_URL_EN} pathPL={TEAM_LEADERS_PAGE_URL_PL} />
            <SEO title={t("menu:team-leaders")} />
            <div className="mt-3 mb-4">
                <h1>
                  {t("menu:team-leaders")}
                </h1>
            </div>
            <Form className="mt-2 p-1 mb-2" onSubmit={e => { e.preventDefault() }}>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Label htmlFor="role">{t('forms:leader')}</Form.Label>
                            <Form.Control id="role" as="select" value={leaderOption} onChange={onLeaderChange} disabled={props.querying}>
                                <option value={EMPTY}>{EMPTY}</option>
                                <option value={IS_LEADER}>{t('common:true')}</option>
                                <option value={IS_NOT_LEADER}>{t('common:false')}</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label htmlFor="email">{t('forms:email')}</Form.Label>
                            <Form.Control id="email" value={email} onChange={onEmailChange} disabled={props.querying}></Form.Control>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Label htmlFor="firstName">{t('forms:first_name')}</Form.Label>
                            <Form.Control id="firstName" value={firstName} onChange={onFirstNameChanged} disabled={props.querying}></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label htmlFor="lastName">{t('forms:last_name')}</Form.Label>
                            <Form.Control id="lastName" value={lastName} onChange={onLastNameChanged} disabled={props.querying}></Form.Control>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Label htmlFor="login">{t('forms:username')}</Form.Label>
                            <Form.Control id="login" value={login} onChange={onLoginChange} disabled={props.querying}></Form.Control>
                        </Form.Group>
                    </Col>
                    <Form.Group as={Col} xs={6} className="d-flex align-self-end">
                        <div className="flex-grow-1" />
                        <Button variant="primary" onClick={onSubmitForm} disabled={props.querying}>
                            {t('common:search')}
                        </Button>
                        <Button variant="secondary" className="ml-2" onClick={onClearForm} disabled={props.querying} >
                            {t('common:clear')}
                        </Button>
                    </Form.Group>
                </Form.Row>
            </Form>
            <div className="ml-4">
                {props.loading &&
                    <Spinner animation="border" role="status" />
                }
                {props.usersByRole && props.usersByRole.items && props.usersByRole.items.length === 0 &&
                    <div>
                        {t('forms:users_not_found')}
                    </div>
                }
                {props.leaders && props.leaders.items && props.leaders.items.length > 0 &&
                <>
                    <ConnectedPagination requestId={API_GET_LEADERS_ID} url={API_URL_POSTFIX + API_GET_LEADERS_URL} customFilter={{filter: filter, is_leader: isLeader}}></ConnectedPagination>
                    {props.leaders.items.map((userData) => {
                        return(
                            <div key={userData.id}>
                                <TeamLeadersList userData={userData} />
                            </div>
                            )
                        })
                    }
                    <ConnectedPagination last requestId={API_GET_LEADERS_ID} url={API_URL_POSTFIX + API_GET_LEADERS_URL} customFilter={{filter: filter, is_leader: isLeader}}></ConnectedPagination>
                </>
                }
            </div>
        </Container>
    )
}

const mapStateToProps = state => ({
    user: state.user ? state.user.data : null,
    leaders: getOutput(API_GET_LEADERS_ID, state),
    loading: isPending(API_GET_LEADERS_ID, state),
    querying: isPending("api-role-get_leaders", state),

})
  
const mapDispatchToProps = dispatch => ({
    getLeaders: (data, filter) => dispatch(postDataApi(API_GET_LEADERS_ID, API_GET_LEADERS_URL, {
        is_leader: data,
        filter: filter,
    }))
})
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TeamLeaders)