import React, { useEffect, useState, useCallback } from "react"
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { isPending, getOutput, getOutputOrDefault } from "../../reducers"
import { postDataAnc } from '../../actions'
import { Form, Col, Button } from "react-bootstrap"
import {
    COLLECTION_TYPES_REQUEST_ID,
    TAXONDB_GET_COLLECTION_SUBTYPES_PREFIX,
    TAXONDB_GET_COLLECTION_SUBTYPES_URL,
    COLLECTION_EDIT_REQUEST_ID,
    COLLECTION_EDIT_REQUEST_URL,
    TAXONDB_GET_COLLECTIONS_PREFIX,
    TAXONDB_GET_COLLECTIONS_URL,
} from '../../settings'
import * as notify from '../../utils/notify'

const EditCollection = props => {
    const { t } = useTranslation(['collections'])
    const { typeId, parentTypeId, collection, collectionTypes, collectionSubtypes } = props
    const [kind, setKind] = useState(props.kindId || collection?.rodzaj_kolekcji)
    const [type, setType] = useState(parentTypeId || typeId)
    const [subtype, setSubtype] = useState((parentTypeId && typeId) || collection?.podtyp_kolekcji || '')
    const [category, setCategory] = useState(collection?.kategoria || '')
    const [descriptionPl, setDescriptionPl] = useState(collection?.opis_pl || '')
    const [descriptionEn, setDescriptionEn] = useState(collection?.opis_eng || '')

    const getSubtypesCallback = useCallback(props.getSubtypes, [])
    useEffect(() => {
        if (!getCollectionCategories().includes(category)) {
            setCategory(getCollectionCategories()[0])
        }

        if (parentTypeId || typeId) {
            getSubtypesCallback(parentTypeId || typeId)
        } else if ((collectionTypes || []).length > 0) {
            if (!kind) {
                setKind(collectionTypes[0].rodzaj_kolekcji)
            }

            setType(collectionTypes[0].typ_kolekcji)
            getSubtypesCallback(collectionTypes[0].typ_kolekcji)
        }
    }, [parentTypeId, typeId, collectionTypes, category, kind, getSubtypesCallback])

    const onCategoryChange = e => {
        setCategory(e.target.value)
    }

    const onKindChange = e => {
        setKind(e.target.value)
        const newCollectionTypes = (collectionTypes || []).filter(t => t.rodzaj_kolekcji === e.target.value)
        if (newCollectionTypes.length > 0) {
            setType(newCollectionTypes[0].typ_kolekcji)
            getSubtypesCallback(newCollectionTypes[0].typ_kolekcji)
        }
    }

    const onTypeChange = e => {
        setType(e.target.value)
        getSubtypesCallback(e.target.value)
    }

    const onSubtypeChange = e => {
        setSubtype(e.target.value)
    }

    const onDescriptionPlChange = e => {
        setDescriptionPl(e.target.value)
    }

    const onDescriptionEnChange = e => {
        setDescriptionEn(e.target.value)
    }

    const getCollectionCategories = () => {
        return [
            'TAXON-ANIMALIA',
            'TAXON-PLANTAE',
            'TAXON-FUNGI',
            'TAXON-INDET',
            'ICON-ORGANISM',
            'ICON-HABITAT',
            'ICON-LANDSCAPE',
            'ICON-INDET'
        ]
    }

    const getCollectionKinds = () => {
        if (collectionTypes === null) {
            return ['brak']
        } else {
            return ['brak', ...new Set(collectionTypes.map(t => t.rodzaj_kolekcji)).filter(k => k !== 'brak')]
        }
    }

    const getCollectionTypes = () => {
        if (collectionTypes === null) {
            return [{ typ_kolekcji: 'brak' }]
        } else {
            return [{ typ_kolekcji: 'brak' }, ...collectionTypes.filter(t => t.rodzaj_kolekcji === kind && t.typ_kolekcji !== 'brak')]
        }
    }

    const onSubmitForm = () => {
        props.editCollection(collection.kolekcja, category, type, subtype === '' ? 'brak' : subtype, descriptionPl, descriptionEn, t)
    }

    return (
        <Form className="ml-4 my-2 p-1" onSubmit={e => { e.preventDefault() }}>
            <Form.Row>
                <Col>
                    <Form.Group>
                        <Form.Label htmlFor={'collectionCategory' + collection.kolekcja}>{t('collections:category')}</Form.Label>
                        <Form.Control id={'collectionCategory' + collection.kolekcja} as="select" value={category} onChange={onCategoryChange} disabled={props.querying || getCollectionCategories().length <= 1}>
                            {getCollectionCategories().map((category, i) =>
                                <option key={i} value={category}>{category}</option>
                            )}
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label htmlFor="collectionKind">{t('collections:kind')}</Form.Label>
                        <Form.Control id="collectionKind" as="select" value={kind} onChange={onKindChange} disabled={props.querying}>
                            {getCollectionKinds().map((kind, i) =>
                                <option key={i} value={kind}>{kind}</option>
                            )}
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label htmlFor={'collectionType' + collection.kolekcja}>{t('collections:type')}</Form.Label>
                        <Form.Control id={'collectionType' + collection.kolekcja} as="select" value={type} onChange={onTypeChange} disabled={props.querying}>
                            {getCollectionTypes().map((type, i) =>
                                <option key={i} value={type.typ_kolekcji}>{type.typ_kolekcji}</option>
                            )}
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label htmlFor={'collectionSubtype' + collection.kolekcja}>{t('collections:subtype')}</Form.Label>
                        <Form.Control id={'collectionSubtype' + collection.kolekcja} as="select" value={subtype} onChange={onSubtypeChange} disabled={props.querying || !collectionSubtypes || collectionSubtypes.length <= 1}>
                            {collectionSubtypes && collectionSubtypes.map((type, i) =>
                                <option key={i} value={type.podtyp_kolekcji}>{type.podtyp_kolekcji}</option>
                            )}
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Form.Row>
            <Form.Row>
                <Col>
                    <Form.Group>
                        <Form.Label htmlFor={'collectionTypeDescriptionPl' + collection.kolekcja}>{t('collections:description-pl')}</Form.Label>
                        <Form.Control id={'collectionTypeDescriptionPl' + collection.kolekcja} value={descriptionPl} onChange={onDescriptionPlChange} disabled={props.querying}></Form.Control>
                    </Form.Group>
                </Col>
            </Form.Row>
            <Form.Row>
                <Col>
                    <Form.Group>
                        <Form.Label htmlFor={'collectionTypeDescriptionEn' + collection.kolekcja}>{t('collections:description-en')}</Form.Label>
                        <Form.Control id={'collectionTypeDescriptionEn' + collection.kolekcja} value={descriptionEn} onChange={onDescriptionEnChange} disabled={props.querying}></Form.Control>
                    </Form.Group>
                </Col>
            </Form.Row>
            <Form.Row className="d-flex">
                <Col className="text-right">
                    <Button size="sm" disabled={props.querying} onClick={onSubmitForm}>{t('collections:save-collection')}</Button>
                </Col>
            </Form.Row>
        </Form>
    )
}

const mapStateToProps = (state, ownProps) => ({
    collectionTypes: getOutputOrDefault(COLLECTION_TYPES_REQUEST_ID, state, []),
    collectionSubtypes: [{ podtyp_kolekcji: 'brak' }].concat(
        (getOutput(TAXONDB_GET_COLLECTION_SUBTYPES_PREFIX + ownProps.collection.kolekcja + 'edit', state) || []).filter(
        st => st.podtyp_kolekcji !== 'brak'
    )),
    querying: isPending(COLLECTION_TYPES_REQUEST_ID, state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    getSubtypes: (collectionType) => dispatch(postDataAnc(
        TAXONDB_GET_COLLECTION_SUBTYPES_PREFIX + ownProps.collection.kolekcja + 'edit',
        TAXONDB_GET_COLLECTION_SUBTYPES_URL,
        { collection_type: collectionType })),
    editCollection: (collectionId, category, collectionType, collectionSubtype, descriptionPl, descriptionEn, t) => {
        return dispatch(postDataAnc(COLLECTION_EDIT_REQUEST_ID, COLLECTION_EDIT_REQUEST_URL, {
            collection: collectionId,
            category: category,
            collection_type: collectionType,
            collection_subtype: collectionSubtype,
            description_pl: descriptionPl,
            description_eng: descriptionEn
        }))
            .then(() => dispatch(postDataAnc(
                TAXONDB_GET_COLLECTION_SUBTYPES_PREFIX + ownProps.collection.kolekcja + 'edit',
                TAXONDB_GET_COLLECTION_SUBTYPES_URL,
                { collection_type: collectionType })))
            .then(() => dispatch(postDataAnc(
                TAXONDB_GET_COLLECTIONS_PREFIX + collectionType,
                TAXONDB_GET_COLLECTIONS_URL,
                { collection_type: collectionType })))
            .then(() => {
                ownProps.onDone && ownProps.onDone()
                notify.success(t('collections:edited-collection'))
            }).catch(res => {
                notify.error(t('collections:cannot-edit-collection'), res.result)
            })
    }
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditCollection)
