import L from 'leaflet';

const WATERMARK_PATH = "/images/amunatcoll-logo.png"
const WATERMARK_WIDTH = "175px"
const WATERMARK_POSITION = "bottomright"
const WATERMARK_ALT = "Logo AMUNATCOLL"

export default function addWatermark(map) {
    L.Control.Watermark = L.Control.extend({
        onAdd: function() {
            var img = L.DomUtil.create('img');
            img.src = WATERMARK_PATH
            img.style.width = WATERMARK_WIDTH
            img.alt = WATERMARK_ALT
            return img;
        },
    });
    
    L.control.watermark = function(opts) {
        return new L.Control.Watermark(opts);
    }
    
    L.control.watermark({ position: WATERMARK_POSITION }).addTo(map);
}