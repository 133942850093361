import React, { useState, Suspense, useEffect } from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { postDataApi, getDataApi } from '../../actions'
import {
  API_EXCEL_TASK_DETAILS_REQUEST_URL,
  API_EXCEL_TASK_SEARCH_REQUEST_ID,
  API_EXCEL_TASK_SEARCH_REQUEST_URL,
  API_EXCEL_TASK_DETAILS_REQUEST_ID_PREFIX,
  API_URL_POSTFIX,
  API_EXCEL_TASK_DELETE_REQUEST_ID,
  API_EXCEL_TASK_DELETE_REQUEST_URL,
  MOMENT_DATETIME_FORMAT,
} from '../../settings'
import { getItems, getPagination, isPending } from "../../reducers";
import ConnectedPagination from "../ConnectedPagination"
import { Container, ListGroup, Collapse, Spinner, Badge } from "react-bootstrap"
import RecordDetailsContainer from "./RecordDetailsContainer";
import Loader from '../../utils/loader'
import Confirm from '../Confirm'
import * as notify from '../../utils/notify'
import moment from 'moment'
import ListGroupItemDiv from "../ListGroupItemDiv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const ResultsList = props => {
  const { t, i18n } = useTranslation([ 'editor-tools', 'a11y' ])

  const [ open, setOpen ] = useState({})
  const [ isOpened, setIsOpened ] = useState({})

  const toggleOpen = (recordId, id) => {
    const temp = { ...open }
    temp[ id ] = !temp[ id ]
    setOpen(temp)
    if (temp[ id ]) {
      props.getDetails(recordId, id)
    }
  }

  const statusVariants = {
    'CREATE': 'secondary',
    'QUEUE': 'info',
    'WORKING': 'primary',
    'DONE': 'success',
    'FAILED': 'danger',
  }

  const RELOAD_INTERVAL = 5000
  const {items, filter, search} = props

  useEffect(()=>{
    let interval
    if(items && items.length > 0){
      let statuses = []
      items.forEach((item)=>statuses.push(item.status))
      if(statuses.includes("CREATE") || statuses.includes("QUEUE") || statuses.includes("WORKING"))
        interval = setInterval(()=>{search({filter: filter})},RELOAD_INTERVAL)
    }
    if(interval)
      return () => clearInterval(interval)
    
  },[items, filter, search])

  return (
    <Suspense fallback={<Loader />}>
      <Container className="d-flex flex-column mt-2">
        {props.loading &&
          <div className="align-self-center">
            <Spinner animation="border" role="status" />
          </div>
        }
        {!props.loading &&
          <div>
            {props.items && props.items.length === 0 &&
              <p>{t('no-tasks-on-list')}</p>
            }
            {props.items && props.items.length > 0 &&
              <div>
                <div className="mb-2 d-flex flex-row flex-wrap justify-content-center">
                  <div className="p-2">
                    {t('nr-of-tasks-found')}: <b>{props.pagination.totalCount ? props.pagination.totalCount.toLocaleString(i18n.languages[0]) : '-'}</b>
                  </div>
                </div>
                <ConnectedPagination requestId={API_EXCEL_TASK_SEARCH_REQUEST_ID} url={API_URL_POSTFIX + API_EXCEL_TASK_SEARCH_REQUEST_URL} requestInterceptor={props.requestInterceptor} customFilter={{filter: props.filter}}></ConnectedPagination>
                <ListGroup>
                  {props.items.map(val => {
                    const requestId = API_EXCEL_TASK_DETAILS_REQUEST_ID_PREFIX + val.id
                    return (
                      <div key={requestId}>
                        <ListGroupItemDiv onClick={() => toggleOpen(val.id, requestId)}>
                          <div className="d-flex align-items-center">
                            <div>
                              {open[ requestId ] &&
                                <img src="/images/list-open.svg" alt={t("a11y:list-opened")} />
                              }
                              {!open[ requestId ] &&
                                <img src="/images/list-closed.svg" alt={t("a11y:list-closed")} />
                              }
                            </div>
                            <div style={{ minWidth: "40px" }} className="mr-2">{val.id}</div>
                            <div style={{ minWidth: "80px" }} className="mr-2">{val.operation}</div>
                            <div className="mr-2 flex-grow-1">{val.filename}</div>
                            <div className="mr-2">
                              <Badge pill className="p-2" style={{ minWidth: "100px" }} variant={statusVariants[ val.status ]}>
                                {val.status}
                              </Badge>
                            </div>
                            <div style={{ minWidth: "140px" }}>{moment(val.date_created).format(MOMENT_DATETIME_FORMAT)}</div>
                            <Confirm
                              onConfirm={e => {
                                props.deleteTask(val.id).then(() => {
                                  props.search()
                                  notify.success(t('task-deleted'))
                                }).catch(() => {
                                  notify.error(t('error'), t('cannot-delete-task'))
                                })
                              }}
                              size="sm"
                              pending={props.removingItems}
                              disabled={props.loadingItems || props.removingItems}
                              WCAGtext={t('delete')}
                              buttonLabel={<FontAwesomeIcon icon={faTrash} />}
                              confirmationText={t('confirm-delete')}
                            ></Confirm>
                          </div>
                        </ListGroupItemDiv>
                        <Collapse in={open[ requestId ]} id={requestId}
                          onEntering={() => setIsOpened({ ...isOpened, [ requestId ]: true })}
                          onExited={() => setIsOpened({ ...isOpened, [ requestId ]: false })}
                        >
                          <Suspense fallback={<Loader />}>
                            {(open[ requestId ] || isOpened[ requestId ]) &&
                              <div className="d-flex flex-column">
                                <RecordDetailsContainer requestId={requestId} recordId={val.id} hasFile={
                                  val.operation === 'convert' && val.status === 'DONE'
                                }></RecordDetailsContainer>
                              </div>
                            }
                          </Suspense>
                        </Collapse>
                      </div>
                    )
                  })}
                </ListGroup>
                <div>
                  <ConnectedPagination last requestId={API_EXCEL_TASK_SEARCH_REQUEST_ID} url={API_URL_POSTFIX + API_EXCEL_TASK_SEARCH_REQUEST_URL} customFilter={{filter: props.filter}}></ConnectedPagination>
                </div>
              </div>
            }
          </div>
        }
      </Container>
    </Suspense>
  )
}

const mapStateToProps = (state, ownProps) => ({
  items: getItems(API_EXCEL_TASK_SEARCH_REQUEST_ID, state),
  loading: isPending(API_EXCEL_TASK_SEARCH_REQUEST_ID, state) || isPending(API_EXCEL_TASK_DELETE_REQUEST_ID, state),
  pagination: getPagination(API_EXCEL_TASK_SEARCH_REQUEST_ID, state),
})

const mapDispatchToProps = dispatch => ({
  search: data => dispatch(postDataApi(API_EXCEL_TASK_SEARCH_REQUEST_ID, API_EXCEL_TASK_SEARCH_REQUEST_URL, data)),
  getDetails: (recordId, requestId) => dispatch(getDataApi(requestId, API_EXCEL_TASK_DETAILS_REQUEST_URL + recordId + '/')),
  deleteTask: id => dispatch(postDataApi(API_EXCEL_TASK_DELETE_REQUEST_ID, API_EXCEL_TASK_DELETE_REQUEST_URL + id + '/')),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResultsList)
