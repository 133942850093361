import React, { useEffect } from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import * as actions from '../../actions'
import { setField } from '../../actions/forms'
import {
    TAXONDB_DETAILS_REQUEST_ID_PREFIX,
    TAXONDB_DETAILS_REQUEST_URL,
    DLIBRA_IIIF_THUMBNAIL_URL,
    TAXONDB_RESULTS_ACTIVE_ICON_RECORD,
    ANC_URL_POSTFIX,
    TAXONDB_SEARCH_ICONS_REQUEST_URL
} from '../../settings'
import { getItems, getPagination, getFilter, isPending, getField } from "../../reducers";
import ConnectedPagination from "../ConnectedPagination"
import { GENUS_ATTRIBUTE_NAME, SPECIES_ATTRIBUTE_NAME } from './attributesStructure'
import { Container, Button, Col, Row, Spinner } from "react-bootstrap"
import IconDetailsContainer from "./IconDetailsContainer"
import ValueFromDictionary from "../dictionary/ValueFromDictionary"

const IconsList = props => {
    const { t, i18n } = useTranslation(['common', 'forms', 'taxondb', 'icondb'])

    const { getIcons, iconsRequestId, filter } = props

    useEffect(() => {
        if (filter && (filter.kolekcja || filter.filtr_prosty)) {
            if ('kolekcja' in filter) {
                getIcons(iconsRequestId, { kolekcja: filter?.kolekcja }, { currentPage: 1 })
            } else {
                getIcons(iconsRequestId, { filtr_prosty: filter?.filtr_prosty }, { currentPage: 1 })
            }
        } else if (filter) {
            getIcons(iconsRequestId, filter, { currentPage: 1 })
        }
    }, [getIcons, iconsRequestId, filter])

    const getFilter = () => {
        if (filter && (filter.kolekcja || filter.filtr_prosty)) {
            if ('kolekcja' in filter) {
                return { kolekcja: filter?.kolekcja }
            } else {
                return { filtr_prosty: filter?.filtr_prosty }
            }
        } else {
            return filter
        }
    }

    const toggleOpen = (record) => {
        props.setOpen(record)
        props.getDetails(record.kolekcjanumerokazu)
    }

    const hasOpen = () => {
        return props.open
    }

    return (
        <Container className="d-flex flex-column mt-2">
            {props.loading &&
                <div className="align-self-center">
                    <Spinner animation="border" role="status" />
                </div>
            }
            {!props.loading &&
                <div>
                    {!props.items &&
                        <p>{t('use-form-to-search')}</p>
                    }
                    {props.items && props.items.length > 0 &&
                        <div>
                            <div className="mb-2 d-flex flex-row justify-content-center">
                                <div className="p-2">
                                    {t('icondb:nr_of_records_found')}: <b>{props.pagination.totalCount ? props.pagination.totalCount.toLocaleString(i18n.languages[0]) : '-'}</b>
                                </div>
                            </div>
                            <div className={hasOpen() ? "hide-on-mobile" : ""}>
                                <ConnectedPagination requestId={props.iconsRequestId} url={ANC_URL_POSTFIX + TAXONDB_SEARCH_ICONS_REQUEST_URL} customFilter={{ filter: getFilter() }} />
                            </div>
                            <Row className="d-flex flex-row-reverse">
                                {hasOpen() &&
                                    <Col className="icondb-right-panel sticky-top">
                                        <IconDetailsContainer requestId={TAXONDB_DETAILS_REQUEST_ID_PREFIX + props.open.kolekcjanumerokazu} iconsRequestId={props.iconsRequestId} customFilter={{ filter: getFilter() }} />
                                    </Col>
                                }
                                <Col md={hasOpen() ? 6 : 12} className={hasOpen() ? "hide-on-mobile" : ""}>
                                    <div className="d-flex justify-content-space-between flex-wrap">
                                        {props.items.map((val, indx) =>
                                            <Button
                                                variant="link"
                                                onClick={() => toggleOpen(val)}
                                                disabled={props.loading}
                                                className={"icondb-thumbnail" + (props.open?.edi_id === val.edi_id ? " active" : "")}
                                                key={indx}
                                                size="sm"
                                                style={{ flexBasis: "25%" }}>
                                                <div>
                                                    <img alt={val.edi_id} src={DLIBRA_IIIF_THUMBNAIL_URL + val.edi_id}></img>
                                                    <div className="text-center">{val.kolekcjanumerokazu}</div>
                                                    <div className="text-center">
                                                        {val.rodzaj && val.gatunek &&
                                                            <>
                                                                [<i>
                                                                    <ValueFromDictionary value={val[GENUS_ATTRIBUTE_NAME]} name={GENUS_ATTRIBUTE_NAME} />
                                                                    &nbsp;
                                                                    <ValueFromDictionary value={val[SPECIES_ATTRIBUTE_NAME]} name={SPECIES_ATTRIBUTE_NAME}></ValueFromDictionary>
                                                                </i>]
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </Button>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                            <div className={hasOpen() ? "hide-on-mobile" : ""}>
                                <ConnectedPagination last requestId={props.iconsRequestId} url={ANC_URL_POSTFIX + TAXONDB_SEARCH_ICONS_REQUEST_URL} customFilter={{ filter: getFilter() }} />
                            </div>
                        </div>
                    }
                    {props.items && !props.items.length > 0 &&
                        <p>{t('no-records-found')}</p>
                    }
                </div>
            }
        </Container>
    )
}

const mapStateToProps = (state, ownProps) => ({
    loading: isPending(ownProps.requestId, state) || isPending(ownProps.iconsRequestId, state),
    items: getItems(ownProps.iconsRequestId, state),
    filter: getFilter(ownProps.requestId, state),
    pagination: getPagination(ownProps.iconsRequestId, state),
    user: state.user ? state.user.data : null,
    open: getField(TAXONDB_RESULTS_ACTIVE_ICON_RECORD, TAXONDB_RESULTS_ACTIVE_ICON_RECORD, state),
})

const mapDispatchToProps = dispatch => ({
    getIcons: (requestId, filter, pagination) => dispatch(actions.postDataAnc(requestId, TAXONDB_SEARCH_ICONS_REQUEST_URL, {
        filter: filter,
        pagination: pagination,
    })),
    setOpen: value => dispatch(setField(TAXONDB_RESULTS_ACTIVE_ICON_RECORD, TAXONDB_RESULTS_ACTIVE_ICON_RECORD, value)),
    getDetails: recordId => dispatch(actions.getDataAnc(TAXONDB_DETAILS_REQUEST_ID_PREFIX + recordId, TAXONDB_DETAILS_REQUEST_URL + recordId + '/'))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IconsList)
