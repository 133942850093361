import React, { Suspense } from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import SearchForm from "./samplesDB/DynamicSearchForm2"
import ResultsContainer from "./samplesDB/ResultsContainer"
import { Container, Row, Col } from "react-bootstrap"
import {
  SAMPLESDB_SEARCH_REQUEST_ID,
  SAMPLES_RESULTS_ACTIVE_TAB_FIELD_NAME,
  SAMPLES_SEARCH_PAGE_URL_PL,
  SAMPLES_SEARCH_PAGE_URL_EN,
} from '../settings'
import Loader from '../utils/loader'
import I18nPathTranslator from "./I18nPathTranslator";
import SEO from "./SEO";
import { isPending } from "../reducers";
import RedirectLoginWithReturn from "./access/RedirectLoginWithReturn";

const TaxonDBContainer = props => {

  const { t } = useTranslation([ 'samplesdb', 'paths' ])

  return (
    <Suspense fallback={<Loader />}>
      <I18nPathTranslator {...props} pathEN={SAMPLES_SEARCH_PAGE_URL_EN} pathPL={SAMPLES_SEARCH_PAGE_URL_PL} />
      <SEO title={t('form-title')} />
      <div>
        {props.loading && <Loader />}
        {!props.loading && props.user && !props.user.data &&
            <RedirectLoginWithReturn lang={props.lang} returnUrl={props.lang ? t("paths:samples-search", {lng: props.lang}) : t("paths:samples-search")} />
        }
        {!props.loading && props.user && props.user.data && 
            <>
              <Container>
                <Row>
                  <Col className="mt-3 mb-4"><h1>{t('form-title')}</h1></Col>
                </Row>
              </Container>
              <Container>
                <>
                  <SearchForm></SearchForm>
                  <ResultsContainer
                    searchRequestId={SAMPLESDB_SEARCH_REQUEST_ID}
                    tabId={SAMPLES_RESULTS_ACTIVE_TAB_FIELD_NAME}
                  ></ResultsContainer>
                </>
              </Container>
            </>
          }
      </div>
    </Suspense>
  )
}

const mapStateToProps = state => ({
  user: state.user,
  loading: isPending('userInfo', state),
})

const mapDispatchToProps = dispatch => ({
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaxonDBContainer)

