import React, { useEffect, useState, useCallback } from "react";
import { connect, useDispatch } from 'react-redux'
import { isPending, getOutputOrDefault } from "../../reducers";
import RedirectLoginWithReturn from "../access/RedirectLoginWithReturn"
import { useTranslation } from 'react-i18next'
import { Container, Row, Col, Form, FormLabel, Button, Spinner, Collapse, ListGroup } from "react-bootstrap"
import ItemsList from "./ItemsList"
import { postDataApi, requestSuccess, requestRemoveStartsWith, requestRemove } from '../../actions'
import {
    API_ALBUM_GET_ITEMS_ID_PREFIX,
    API_ALBUM_GET_ITEMS_URL,
    API_ALBUM_SEARCH_ITEMS_REQUEST_ID,
    API_ALBUM_SEARCH_ITEMS_URL,
    API_URL_POSTFIX,
    MY_ALBUM_PAGE_URL_EN,
    MY_ALBUM_PAGE_URL_PL,
} from '../../settings'
import ConnectedPagination from '../ConnectedPagination'
import I18nPathTranslator from "../I18nPathTranslator";
import SEO from "../SEO";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ListGroupItemDiv from "../ListGroupItemDiv";

const MyAlbumContainer = props => {
    const { t } = useTranslation([ 'profile', 'paths', 'home', 'a11y' ])

    const [ open, setOpen ] = useState(false)
    const [ currentFilter, setCurrentFilter ] = useState('')
    const [ searchText, setSearchText ] = useState('')
    const [ path, setPath ] = useState([])
    const dispatch = useDispatch();

    const getItems = useCallback(obj => dispatch(postDataApi(
            API_ALBUM_GET_ITEMS_ID_PREFIX + 'album',
            API_ALBUM_GET_ITEMS_URL,
            obj ? { ...obj, someone_id: props.viewedBy } : { album_id: 0, someone_id: props.viewedBy })), [ dispatch, props.viewedBy ])
    const cleanUp = useCallback(() => {
        dispatch(requestRemove(API_ALBUM_GET_ITEMS_ID_PREFIX + 'album'))
        dispatch(requestRemoveStartsWith(API_ALBUM_GET_ITEMS_ID_PREFIX + 'album-'))
    }, [ dispatch ])

    useEffect(() => {
        getItems()
        return cleanUp
    }, [ getItems, cleanUp ])

    const searchItems = () => {
        setPath([])
        setOpen(true)
        setCurrentFilter(searchText)
        cleanUp()
        props.searchItems(searchText)
    }

    const showOnTree = value => {
        getItems({ album_id: 0, pagination: { currentPage: Math.ceil((value.path.length > 0 ? value.path[ 0 ][ 2 ] : value.sort_order) / 20), perPage: 20 } }).then(res => {
            if (value.path.length > 0) {
                props.getPathItems({
                    albums: value.path.map(obj => ({ album_id: obj[ 0 ], pagination: { currentPage: Math.ceil(obj[ 2 ] / 20), perPage: 20 } }))
                }).then(res => {
                    const newPath = []
                    value.path.forEach((el, indx) => {
                        const requestId = API_ALBUM_GET_ITEMS_ID_PREFIX + 'album-' + el[ 0 ]
                        const data = res[ indx ]
                        props.setRequest(requestId, data)
                        newPath.push(el[ 0 ])
                    })
                    setPath(newPath)
                    setOpen(false)
                })
            } else {
                setOpen(false)
            }
        })
    }

    const ElementText = props => {
        if (props.match.length > 0) {
            const indx = props.text.toLowerCase().indexOf(props.match.toLowerCase())
            if (indx >= 0) {
                return <span><span>{props.text.substring(0, indx)}</span><b>{props.text.substring(indx, indx + props.match.length)}</b><span>{props.text.substring(indx + props.match.length, props.text.length)}</span></span>
            } else {
                return <span>{props.text}</span>
            }
        } else {
            return <span>{props.text}</span>
        }
    }

    return (
        <>
            {!props.viewedBy &&
                <>
                    <I18nPathTranslator {...props} pathEN={MY_ALBUM_PAGE_URL_EN} pathPL={MY_ALBUM_PAGE_URL_PL} />
                    <SEO title={t('menu:my-album')} />
                </>
            }
            {!props.loading && props.user && !props.user.data &&
                <RedirectLoginWithReturn lang={props.lang} returnUrl={props.lang ? t("paths:my-album", {lng: props.lang}) : t("paths:my-album")} />
            }
            {props.user && props.user.data &&
                <Container className="d-flex flex-column mt-2">
                    {!props.viewedBy && <h1>{t('menu:my-album')}</h1>}
                    {!props.viewedBy &&
                    <Form onSubmit={e => { e.preventDefault() }} className="my-amunatcoll-search-form">
                        <Row>
                            <Col md={8} sm={9}>
                                <Form.Group>
                                    <Form.Control autoFocus onChange={e => setSearchText(e.target.value)} value={searchText} name="search" id="search" type="text" disabled={props.loading || props.searching} autoComplete="off" placeholder={t("home:search2")}></Form.Control>
                                    <FormLabel htmlFor="search">{t('album-search-in-info')}</FormLabel>
                                </Form.Group>
                            </Col>
                            <Col md={4} sm={3} className="d-flex">
                                <Form.Group>
                                    <Button variant="primary" className="search-button" onClick={searchItems} disabled={props.loading || props.searching} type="submit">
                                        {t('common:search')}
                                        {props.loading &&
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                className="ml-1"
                                            />
                                        }
                                    </Button>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                    }
                    {props.searching &&
                        <Row>
                            <Col className="text-center">
                                <Spinner animation="border" role="status" />
                            </Col>
                        </Row>
                    }
                    <Collapse in={open}>
                        <div>
                            {!props.searching && props.items && props.items.length > 0 &&
                                <ListGroup>
                                    <ConnectedPagination requestId={API_ALBUM_SEARCH_ITEMS_REQUEST_ID} url={API_URL_POSTFIX + API_ALBUM_SEARCH_ITEMS_URL} customFilter={{ filter: currentFilter }}></ConnectedPagination>
                                    {props.items.map((value, indx) => (
                                        <ListGroupItemDiv key={indx} onClick={e => showOnTree(value)} className="d-flex album-element">
                                            <div className="w-50">
                                                <span className="pr-3">
                                                    {value.kind === 'album' &&
                                                        <img src="/images/amuZasób 4@4x-100.jpg" alt={t('a11y:icon-filter')} width="21px" />
                                                    }
                                                    {value.kind === 'icon' &&
                                                        <img src="/images/amuZasób 6@4x-100.jpg" alt={t('a11y:icon-dlibra')} width="21px" />
                                                    }
                                                    {value.kind === 'taxon' &&
                                                        <img src="/images/amuZasób 5@4x-100.jpg" alt={t('a11y:icon-taxon')} width="31px" />
                                                    }
                                                    {value.kind === 'observation' &&
                                                        <img src="/images/amuZasób 3@4x-100.jpg" alt={t('a11y:icon-observation')} width="21px" />
                                                    }
                                                    {value.kind === 'filter' &&
                                                        <img src="/images/amuZasób 1@4x-100.jpg" alt={t('a11y:icon-filter')} width="21px" />
                                                    }
                                                    {value.kind === 'sample' &&
                                                        <img src="/images/amuZasób 2@4x-100.jpg" alt={t('a11y:icon-sample')} width="21px" />
                                                    }
                                                </span>
                                                <ElementText text={value.name} match={currentFilter}></ElementText>
                                                {value.custom_name && value.custom_name.length > 0 &&
                                                    <span className="itemlist-itemlabel">{' ('}<ElementText text={value.custom_name} match={currentFilter}></ElementText>{')'}</span>
                                                }
                                            </div>
                                            <div className="flex-grow-1">
                                                /{value.path.map(obj => obj[ 1 ]).join('/')}
                                            </div>
                                            <div className="d-flex">
                                                <Button
                                                    variant="secondary"
                                                    size="sm"
                                                    className="align-self-center mr-1"
                                                >
                                                    <FontAwesomeIcon icon={faEye} title={t('common:show')} />
                                                </Button>
                                            </div>
                                        </ListGroupItemDiv>
                                    ))}
                                    <ConnectedPagination last requestId={API_ALBUM_SEARCH_ITEMS_REQUEST_ID} url={API_URL_POSTFIX + API_ALBUM_SEARCH_ITEMS_URL} customFilter={{ filter: currentFilter }}></ConnectedPagination>
                                </ListGroup>
                            }
                            {!props.searching && props.items && props.items.length === 0 &&
                                <Row>
                                    <Col>
                                        {t('no-items-found')}
                                    </Col>
                                </Row>
                            }
                        </div>
                    </Collapse>
                    <Collapse in={!open}>
                        <Row>
                            {!props.searching &&
                                <Col>
                                    <ItemsList requestId={API_ALBUM_GET_ITEMS_ID_PREFIX + 'album'} path={path} viewedBy={props.viewedBy}></ItemsList>
                                </Col>
                            }
                        </Row>
                    </Collapse>
                    {!props.viewedBy &&
                        <Row>
                            <Col className="p-1 ml-3">
                                <div className="m-2">
                                    <img src="/images/amuZasób 4@4x-100.jpg" alt={t('a11y:icon-album')} width="21px" /> - {t('profile:album-icon')}
                                </div>
                                <div className="m-2">
                                    <img src="/images/amuZasób 1@4x-100.jpg" alt={t('a11y:icon-filter')} width="21px" /> - {t('profile:filter-icon')}
                                </div>
                                <div className="m-2">
                                    <img src="/images/amuZasób 6@4x-100.jpg" alt={t('a11y:icon-dlibra')} width="21px" /> - {t('profile:image-icon')}
                                </div>
                                <div className="m-2">
                                    <span>
                                        <img src="/images/amuZasób 5@4x-100.jpg" alt={t('a11y:icon-taxon')} width="31px" />
                                    </span> - {t('profile:taxon-icon')}
                                </div>
                                <div className="m-2">
                                    <img src="/images/amuZasób 3@4x-100.jpg" alt={t('a11y:icon-observation')} width="21px" /> - {t('profile:observation-icon')}
                                </div>
                                <div className="m-2">
                                    <img src="/images/amuZasób 2@4x-100.jpg" alt={t('a11y:icon-sample')} width="21px" /> - {t('profile:sample-icon')}
                                </div>
                            </Col>
                        </Row>
                    }
                </Container>
            }
        </>
    )
}

const mapStateToProps = state => ({
    user: state.user,
    loading: isPending('userInfo', state),
    searching: isPending(API_ALBUM_SEARCH_ITEMS_REQUEST_ID, state) || isPending(API_ALBUM_GET_ITEMS_ID_PREFIX + 'path', state) || isPending(API_ALBUM_GET_ITEMS_ID_PREFIX + 'album', state),
    items: getOutputOrDefault(API_ALBUM_SEARCH_ITEMS_REQUEST_ID, state, { items: [] })[ 'items' ],
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    searchItems: filter => dispatch(postDataApi(
        API_ALBUM_SEARCH_ITEMS_REQUEST_ID,
        API_ALBUM_SEARCH_ITEMS_URL,
        { filter })),
    getPathItems: obj => dispatch(postDataApi(
        API_ALBUM_GET_ITEMS_ID_PREFIX + 'path',
        API_ALBUM_GET_ITEMS_URL,
        obj)),
    setRequest: (id, data) => dispatch(requestSuccess(id, data)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MyAlbumContainer)
