import React, { useState } from "react"
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { isPending } from "../../reducers"
import { postDataAnc } from '../../actions'
import { Form, Button } from "react-bootstrap"
import {
    PROTECTED_AREAS_CHANGE_ID,
    PROTECTED_AREAS_CHANGE_URL
} from '../../settings'
import * as notify from '../../utils/notify'

const EditProtectedArea = props => {
    const { t } = useTranslation(['protectedArea', 'forms', 'common'])

    const [type, setType] = useState(props.protectedArea.typ_obszaru || 'rezerwat przyrody')

    const onTypeChanged = e => {
        setType(e.target.value)
    }

    const [name, setName] = useState(props.protectedArea.nazwa || '')

    const onNameChanged = e => {
        setName(e.target.value)
    }

    const [id, setId] = useState(props.protectedArea.id || '')

    const onIdChanged = e => {
        setId(e.target.value)
    }


    const [dateFrom, setDateFrom] = useState(props.protectedArea.data_utworzenia || undefined)

    const onDateFromChanged = e => {
        setDateFrom(e.target.value)
    }

    const onSubmitForm = () => {
        let dateReg = /^\d{4}[.]\d{2}[.]\d{2}$/
        if (!name || !name.trim() || !id || !id.trim() || (dateFrom && !dateFrom.match(dateReg))){
            return
        }

        props.editProtectedArea(id, type, name, dateFrom, t).then(() => {
            props.onDone && props.onDone()
        })
    }

    return (
        <Form className="mt-2 p-1 ml-4 mb-2" onSubmit={e => { e.preventDefault() }}>
            <Form.Group>
                <Form.Label htmlFor="type">{t('protectedArea:type')}</Form.Label>
                <Form.Control id="type" as="select" value={type} onChange={onTypeChanged} disabled={props.querying}>
                    <option value="rezerwat przyrody">{t('protectedArea:sanctuary')}</option>
                    <option value="park narodowy">{t('protectedArea:park')}</option>
                </Form.Control>
            </Form.Group>
            <Form.Group>
                <Form.Label htmlFor="name">{t('protectedArea:name')}</Form.Label>
                <Form.Control id="name" size="sm" value={name} onChange={onNameChanged} disabled={props.querying}></Form.Control>
            </Form.Group>
            <Form.Group>
                <Form.Label htmlFor="id">{t('protectedArea:id')}</Form.Label>
                <Form.Control id="id" size="sm" value={id} onChange={onIdChanged} disabled={props.querying}></Form.Control>
            </Form.Group>
            <Form.Group>
                <Form.Label htmlFor="dateFrom">{t('protectedArea:date-from')}</Form.Label>
                <Form.Control id="dateFrom" size="sm" value={dateFrom} onChange={onDateFromChanged} disabled={props.querying} placeholder={t('protectedArea:date-format')}></Form.Control>
            </Form.Group>
            <div className="text-right">
                <Button size="sm" onClick={onSubmitForm} disabled={props.querying}>
                    {t('forms:save')}
                </Button>
            </div>
        </Form>
    )
}

const mapStateToProps = state => ({
    querying: isPending(PROTECTED_AREAS_CHANGE_ID, state),
    requests: state.requests
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    editProtectedArea: (id, typ_obszaru, nazwa, data_utworzenia, t) => {
        return dispatch(postDataAnc(PROTECTED_AREAS_CHANGE_ID, PROTECTED_AREAS_CHANGE_URL, {
            id: id,
            typ_obszaru: typ_obszaru,
            nazwa: nazwa,
            data_utworzenia: data_utworzenia
        })).then(() => {
            notify.success(t('protectedArea:edited-protected-area'))
        }).catch(res => {
            notify.error(t('protectedArea:cannot-edit-protected-area'), res.result)
        })
    }
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditProtectedArea)