import React from 'react';
import ReactDOMServer from 'react-dom/server';

export default function renderIconHtml(shape, color) {
    let html = ReactDOMServer.renderToString(
        <i 
            className={shape}
            style={{ color: color }}
        />
    )
    return html
}

export const renderIconHtmlWithSize = (shape, color, size) => {
    let html = ReactDOMServer.renderToString(
        <i 
            className={`fa ${shape} ${size}`}
            style={{ color: color }}
        />
    )
    return html
}