import React, { useState } from "react"
import { connect } from 'react-redux'
import { Button, Spinner } from "react-bootstrap"
import {
  SAMPLESDB_DETAILS_PAGE_URL,
  BIBLIODB_SAMPLES_REQUEST_ID_PREFIX,
  BIBLIODB_SAMPLES_REQUEST_URL,
  ANC_URL_POSTFIX,
  BIBLIODB_EXPORTFILE_SAMPLES_REQUEST_URL,
} from '../../settings'
import { isPending, getItems } from "../../reducers"
import { Redirect } from "react-router-dom";
import ConnectedPagination from "../ConnectedPagination"
import ReportsExporter from "../ReportsExporter"
import {
  NUMER_PROBY_ATTRIBUTE_NAME,
} from '../samplesDB/attributesStructure'

const Tab2 = props => {
  const [ redirect, setRedirect ] = useState(false)

  return (
    <div>
      {redirect &&
        <Redirect push to={SAMPLESDB_DETAILS_PAGE_URL + redirect}></Redirect>
      }
      {props.loading &&
        <div className="d-flex justify-content-center my-5">
          <Spinner animation="border" role="status" />
        </div>
      }
      {!props.loading && props.samples &&
        <>
          <ReportsExporter
            requestId={BIBLIODB_SAMPLES_REQUEST_ID_PREFIX + props.recordId}
            url={BIBLIODB_EXPORTFILE_SAMPLES_REQUEST_URL}
            customFilter={{ cytowanie: props.cit }}
          >
          </ReportsExporter>
          <ConnectedPagination requestId={BIBLIODB_SAMPLES_REQUEST_ID_PREFIX + props.recordId} url={ANC_URL_POSTFIX + BIBLIODB_SAMPLES_REQUEST_URL} customFilter={{ cytowanie: props.cit }}></ConnectedPagination>
          {props.samples.map((item, indx) =>
            <div key={indx} className="d-flex">
              <Button variant="link" onClick={e => setRedirect(item[ NUMER_PROBY_ATTRIBUTE_NAME ])} className="biblio-cite-button">{item[ NUMER_PROBY_ATTRIBUTE_NAME ]}</Button>
            </div >
          )}
          <ConnectedPagination requestId={BIBLIODB_SAMPLES_REQUEST_ID_PREFIX + props.recordId} last url={ANC_URL_POSTFIX + BIBLIODB_SAMPLES_REQUEST_URL} customFilter={{ cytowanie: props.cit }}></ConnectedPagination>
        </>
      }
    </div >
  )
}

const mapStateToPropsTab2 = (state, ownProps) => ({
  loading: isPending(BIBLIODB_SAMPLES_REQUEST_ID_PREFIX + ownProps.recordId, state),
  samples: getItems(BIBLIODB_SAMPLES_REQUEST_ID_PREFIX + ownProps.recordId, state),
})

const mapDispatchToPropsTab2 = (dispatch, ownProps) => ({
})

export default connect(
  mapStateToPropsTab2,
  mapDispatchToPropsTab2
)(Tab2)
