import React, { forwardRef } from "react";
import L from 'leaflet';
import { connect } from 'react-redux'
import ReactSlider from 'react-slider'
import styled from 'styled-components';
import {
    MAP_LAYER_ANIMATION_CONST
} from '../../../../settings'
import { setAnimationYears } from '../../../../actions/map'
import { renderIconHtmlWithSize } from '../../../../utils/map/renderIconHtml'

const RangeSlider = forwardRef((props, ref) => {

    const UNASSIGNED_DATE_STRING = 'bd'

    const { markerColor, markerIcon, markerSize } = {...props}

    const StyledSlider = styled(ReactSlider)`
        width: 100%;
        height: 25px;
    `;
    
    const StyledThumb = styled.div`
        height: 25px;
        line-height: 25px;
        width: 30px;
        font-size: 14px;
        text-align: center;
        background-color: #bfbed4;
        color: #06044d;
        cursor: grab;
    `;
    
    const Thumb = (props, state) => <StyledThumb {...props}>{state.valueNow}</StyledThumb>;
    
    const StyledTrack = styled.div`
        top: 0;
        bottom: 0;
        background: ${props => props.index === 2 ? '#9da4b0' : props.index === 1 ? '#3773d4' : '#9da4b0'};
        border-radius: 999px;
    `;
    
    const Track = (props, state) => <StyledTrack {...props} index={state.index} />;

    let arrayLength = [...props.taxonsByYear.keys()].length 
    let yearsArray = [...props.taxonsByYear.keys()]

    const earliestYear = Number(
        yearsArray[0]
    )

    const latestYear = Number(
        yearsArray[arrayLength - 1] === UNASSIGNED_DATE_STRING ?
        Number(yearsArray[arrayLength - 2]) :
        Number(yearsArray[arrayLength - 1])
    )

    const handleSliderYearsChange = (e) => {
        if (props.animationTimeouts && props.animationTimeouts.length){
            props.animationTimeouts.forEach(id => clearTimeout(id))
        }
        if (ref.animationDisplayedYearDiv.current){
            ref.animationDisplayedYearDiv.current.remove()
        }

         props.setAnimationYears({ 
            'START_YEAR': e[0],
            'END_YEAR' : e[1]
         })

        if (props.taxonsByYear){
            let response = new Map()
            props.taxonsByYear.forEach((value, key) => {  
                if ((key >= e[0]) && (key <= e[1])){
                    response.set(key, value)
                }
            })
            try{
                ref.animationLayerRef.current.eachLayer(function(layer){
                    ref.animationLayerRef.current.removeLayer(layer);
                })
            }
            catch(e){
                //mapRef not rendered yet
            }
            let dataToDisplay = Array.from(response.values())
            let icon = renderIconHtmlWithSize(markerIcon, markerColor, markerSize)
            const fontAwesomeIcon = new L.divIcon({
                html: icon,
                iconSize: [20, 20],
                className: 'myDivIcon'
            });
            dataToDisplay.forEach(year => {                                    
                year.forEach(taxonByYear => {
                    const marker = L.marker([taxonByYear[0], taxonByYear[1]], { icon: fontAwesomeIcon })
                    marker['kolekcjanumerokazu'] = taxonByYear[2]
                    marker.name = MAP_LAYER_ANIMATION_CONST
                    marker.bindPopup(`<b>${taxonByYear[2]}</b> (${taxonByYear[3]})`)
                    marker.addTo(ref.animationLayerRef.current)
                })  
            })
        }
    }

    return(
        <>
            {props.taxonsByYear &&
                <div>
                    <StyledSlider
                        value={
                            props.animationYears ?
                            [props.animationYears.START_YEAR, props.animationYears.END_YEAR] :
                            [earliestYear, latestYear]
                        }
                        renderTrack={Track}
                        renderThumb={Thumb}
                        min={earliestYear}
                        max={latestYear}
                        minDistance={0}
                        onAfterChange={e => handleSliderYearsChange(e)}
                    />
                </div>
            }
        </>
    )
})

const mapStateToProps = state => ({
    taxonsByYear: state.map.taxonsByYear,
    animationYears: state.map.animationYears,
    animationTimeouts: state.map.animationTimeouts,
    markerColor: state.map.historicalMarkerColor,
    markerIcon: state.map.historicalMarkerIcon,
    markerSize: state.map.historicalMarkerSize,
})

const mapDispatchToProps = dispatch => ({
    setAnimationYears: years => dispatch(setAnimationYears(years)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(RangeSlider)