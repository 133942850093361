import { useState, useCallback } from 'react';

export default (value) => {
  const [ isValid, setIsValid ] = useState(false);
  const [ isInvalid, setIsInvalid ] = useState(false);

  const isValidCallback = useCallback(() => {
    if (value && value.toString().length) {
      setIsInvalid(false);
      setIsValid(true);
      return true;
    }
    else {
      setIsInvalid(true);
      setIsValid(false);
      return false;
    }
  }, [ value ]);

  const clearCallback = useCallback(() => {
    setIsInvalid(false);
    setIsValid(false);
  }, []);

  return { validProps: { isValid, isInvalid }, isValidCallback, clearCallback };
};

