import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setField, deleteField } from '../actions/forms';

const useFieldRedux = (id, defaultValue) => {
  const [ initValue, setInitValue ] = useState(defaultValue);
  const value = useSelector(state => state?.forms?.[id]?.fields?.[id]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (value === undefined && initValue !== undefined) {
      dispatch(setField(id, id, defaultValue));
    }
  }, [ value, dispatch, id, defaultValue, initValue ]);

  const setValue = useCallback(val => {
    dispatch(setField(id, id, val));
  }, [ id, dispatch ]);

  const clear = useCallback(() => {
    setInitValue(undefined);
    return dispatch(deleteField(id, id));
  }, [ dispatch, id ]);

  return { value, setValue, clear };
};
export default useFieldRedux;
