const rokZbioruFormat = val => (/^\d{0,4}$/.test(val) || /^\d{0,4}-\d{0,4}$/.test(val))

export const rokZbioruShouldRenderSuggestions = val => {
    return rokZbioruFormat(val) && val && !val.includes('-')
}

export const rokZbioruOnChange = e => rokZbioruFormat(e.target.value)

const dataZbioruFormat = val => (/^\d{0,4}(\.\d{0,2}){0,1}(\.\d{0,2}){0,1}$/.test(val) || /^\d{0,4}(\.\d{0,2}){0,1}(\.\d{0,2}){0,1}-\d{0,4}(\.\d{0,2}){0,1}(\.\d{0,2}){0,1}$/.test(val))

export const dataZbioruOnChange = e => dataZbioruFormat(e.target.value)

export const setElementStructure = (p, o, val) => {
    if (o === undefined) {
        return val
    }
    let obj = { ...o }
    const path = [...p]
    if (path && path.length) {
        const path_value = path.shift()
        if (path.length === 0) {
            obj[path_value] = val
        } else {
            if (path_value.startsWith("or_")) {
                const next_path = path.shift()
                obj[path_value][next_path] = setElementStructure(path, obj[path_value][next_path], val)
            } else {
                obj[path_value] = setElementStructure(path, obj[path_value], val)
            }
        }
    }
    return obj
}

export const getElementStructure = (p, o) => {
    let obj = { ...o }
    const path = [...p]
    if (path && path.length) {
        const path_value = path.shift()
        if (path.length === 0) {
            return obj[path_value] || ""
        } else {
            if (path_value.startsWith("or_")) {
                const next_path = path.shift()
                return getElementStructure(path, obj[path_value][next_path])
            } else {
                return getElementStructure(path, obj[path_value])
            }
        }
    }
    return obj
}

export const deleteElementStructure = (p, o) => {
    let obj = { ...o }
    const path = [...p]
    if (path && path.length) {
        const path_value = path.shift()
        if (path.length === 0) {
            delete obj[path_value]
        } else {
            if (obj[path_value]) {
                if (path_value.startsWith("or_")) {
                    const next_path = path.shift()
                    if (path.length === 0) {
                        obj[path_value] = obj[path_value].filter((v, i) => i !== next_path)
                    } else {
                        obj[path_value][next_path] = deleteElementStructure(path, obj[path_value][next_path])
                    }
                } else {
                    obj[path_value] = deleteElementStructure(path, obj[path_value])
                }
            }
        }
    }
    return obj
}

export const sortNot = o => {
    if (Array.isArray(o)) {
        return o
    }
    const output = {}
    const foundKeys = []
    Object.keys(o).forEach((val, indx) => {
        const key = Object.keys(o[val])[0]
        if (val.startsWith('not_')) {
            if (!foundKeys.includes(key)) {
                foundKeys.push(key)
                const others = Object.keys(o).filter((item, i) => (i > indx && item.startsWith('not_') && Object.keys(o[item])[0] === key))
                output[val] = o[val]
                others.forEach(k => {
                    output[k] = o[k]
                })
            }
        } else {
            output[val] = o[val]
        }

    })
    return output
}

export const removeEmptyElementsStructure = o => {
    let obj = { ...o }
    Object.keys(obj).forEach(val => {
        if (!obj[val]) {
            delete obj[val]
        } else if (Array.isArray(obj[val])) {
            if (obj[val].length === 0) {
                delete obj[val]
            } else {
                obj[val].forEach((val2, indx) => {
                    const temp = removeEmptyElementsStructure(val2)
                    if (Object.keys(temp).length === 0) {
                        delete obj[val][indx]
                    } else {
                        obj[val][indx] = temp
                    }
                })
                obj[val] = obj[val].filter(Boolean)
                if (obj[val].length === 0) {
                    delete obj[val]
                }
            }
        } else if (typeof obj[val] === 'object') {
            obj[val] = removeEmptyElementsStructure(obj[val])
            if (Object.keys(obj[val]).length === 0) {
                delete obj[val]
            }
        }
    })
    return obj
}
