import React from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { isPending, getItems } from "../../reducers";
import { Button, Dropdown } from "react-bootstrap"
import * as notify from '../../utils/notify'
import {
    ICONDB_SEARCH_REQUEST_ID,
    API_ALBUM_GET_ALL_ID,
    API_ALBUM_GET_ALL_URL,
} from '../../settings'
import { addPictureOne, addPictureTwo, clearComparator } from '../../actions/comparator'
import * as actions from '../../actions'


const ShareButton = props => {
    const { t } = useTranslation([ 'common', 'forms', 'taxondb', 'icondb', 'profile', 'paths' ])
    const url = props.detailsUrl + props.recordId
    const { setAddAlbumOpen, addFirstPictureToComparator, addSecondPictureToComparator } = props


    const handleAddToComparator = (data) => {
        if (!props.comparator)
            addFirstPictureToComparator(data)
        else if (props.comparator && props.comparator.edi_id1)
            addSecondPictureToComparator(data)
    }

    const hasUrlLink = () => {
        return props.detailsUrl && props.recordId
    }


    return (
            !props.loading && props.data &&
                <div className="share-container share-container-icon-db">
                        <Button disabled={!hasUrlLink()} variant="link" size="sm" onClick={e => {
                            window.open(url, new Date().valueOf())
                        }}>{t('common:open-in-new-tab-window')}</Button>
                        <Button  disabled={!hasUrlLink()}  variant="link" size="sm" onClick={e => {
                            window.location = url
                        }}>{t('common:open-in-current-tab-window')}</Button>
                        <Button disabled={!hasUrlLink()}  variant="link" size="sm" onClick={e => {
                            navigator.clipboard.writeText(window.location.origin + url)
                            notify.success(t('common:copied-to-clipboard'))
                        }}>{t('common:copy-url-to-clipboard')}</Button>
                        <Dropdown.Divider></Dropdown.Divider>
                        <Button variant="link"
                            disabled={!setAddAlbumOpen}
                            onClick={e => {
                                if (props.user) {
                                    setAddAlbumOpen(true)
                                    props.getAlbums()
                                }
                                else {
                                    props.setRedirect(true)
                                }
                            }}
                            size="sm">{t('profile:add-to-album')}
                        </Button>
                        {!props.noImage &&
                        <>
                        <Dropdown.Divider></Dropdown.Divider>
                        <Button variant="link"
                            disabled={!addFirstPictureToComparator && !addSecondPictureToComparator}
                            onClick={e => {
                                handleAddToComparator(props.data)
                            }}
                            size="sm">
                            {props.comparator && props.comparator.external_id1 ? t('icondb:compare-image-with') + props.comparator.external_id1 : t('icondb:add-to-comparator')}
                        </Button>
                        {props.comparator && props.comparator.external_id1 &&
                            <Button disabled={!props.clearComparator} variant="link"
                                onClick={() => props.clearComparator()}
                                size="sm">
                                {t('icondb:clear-comparator')}
                            </Button>
                        }
                        </>
                        }
                </div>
    )
}
const mapStateToProps = (state, ownProps) => ({
    items: getItems(ICONDB_SEARCH_REQUEST_ID, state),
    loading: isPending(ownProps.requestId, state),
    comparator: state.comparator,
    user: state.user,
})

const mapDispatchToProps = dispatch => ({
    getAlbums: () => dispatch(actions.postDataApi(API_ALBUM_GET_ALL_ID, API_ALBUM_GET_ALL_URL)),
    addPictureOneToComparator: (data) => dispatch(addPictureOne(data)),
    addPictureTwoToComparator: (data) => dispatch(addPictureTwo(data)),
    clearComparator: () => dispatch(clearComparator())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShareButton)
