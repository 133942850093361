import React, { Suspense } from "react"
import "./styles/index.scss"
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import MainContainer from "./components/MainContainer"
import Loader from './utils/loader'
import "./styles/new_design.scss"
import "./styles/wcag.scss"
import { BrowserRouter as Router } from "react-router-dom"

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { error: error };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
    }

    render() {
        if (this.state.errorInfo) {
            // You can render any custom fallback UI
            return (
                <div className="text-center">
                    <img src="/kuku2.png" alt="Something went wrong..." />
                    <div>
                        <h5>Something went wrong...</h5>
                        <details style={{ whiteSpace: 'pre-wrap' }}>
                            {this.state.error && this.state.error.toString()}
                            <br />
                            {this.state.errorInfo.componentStack}
                        </details>
                    </div>
                </div>
            )
        }
        return this.props.children;
    }
}

const App = props =>
    <Suspense fallback={<Loader />}>
        <ErrorBoundary>
            <Router>
                <MainContainer></MainContainer>
            </Router>
        </ErrorBoundary>
    </Suspense>

export default App




