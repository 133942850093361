import React from "react";
import { connect } from 'react-redux'
import { Container } from "react-bootstrap"
import { useTranslation } from 'react-i18next'
import SEO from "../SEO";
import SubmitionPanel from "./SubmitionPanel"
import {
  EXCEL_TOOLS_PAGE_URL_EN,
  EXCEL_TOOLS_PAGE_URL_PL,
} from '../../settings'
import I18nPathTranslator from "../I18nPathTranslator";

const ExcelToolsContainer = props => {
  const { t } = useTranslation([ 'editor-tools' ])

  return (
    <Container>
      <I18nPathTranslator {...props} pathEN={EXCEL_TOOLS_PAGE_URL_EN} pathPL={EXCEL_TOOLS_PAGE_URL_PL} />
      <SEO title={t('excel-tools')} />
      <div className="mt-3 mb-4">
        <h1>
          {t('excel-tools')}
        </h1>
      </div>
      <SubmitionPanel></SubmitionPanel>
    </Container>
  )
}

const mapStateToProps = (state, ownProps) => ({
})

const mapDispatchToProps = dispatch => ({
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExcelToolsContainer)
