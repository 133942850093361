import React, { forwardRef } from "react"
import { connect } from 'react-redux'
import { Row, Spinner, Button, Col, FormLabel, Form, Collapse } from "react-bootstrap";
import {
    TAXONDB_SEARCH_WITH_AGGREGATION_REQUEST_ID,
    MAP_DATA_REQUEST_TIMEOUT,
    TAXONDB_SEARCH_WITH_AGGREGATION_REQUEST_URL,
    MAP_COLORPICKER_LIST,
    TAXONDB_SEARCH_GENUS_REQUEST_ID,
    TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID,
    TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID,
    TAXON_SEARCH_IS_DYNAMIC,
    MAP_OPENED_TOOL_VISUALIZATION_DETAILS,
} from "../../../../settings"
import { 
    handleManualRangesColorChange,
    createRangesArrayInManualSequence,
} from "../CartogramFunctions"
import { getField, isPending, getFields } from "../../../../reducers";
import { postDataAnc, } from "../../../../actions"
import { useTranslation } from 'react-i18next'
import { runCartogramSearch, setFields } from '../../../../actions/forms'
import { setCartogramAdministrativeDivision, setCartogramBordersColor, setCartogramBordersWidth, setCartogramFirstAreaColor, setCartogramFirstSequenceNumber, setCartogramManualRanges, setCartogramNumberOfColors, setCartogramOpacity, setCartogramSecondAreaColor, setCartogramSequenceProduct, setCartogramSequenceType, setOpenedMapTool } from '../../../../actions/map'
import * as actions from '../../../../actions'
import ManualRanges from './ManualRanges'
import { CirclePicker } from "react-color";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleRight, faMapMarked } from "@fortawesome/free-solid-svg-icons";
import { clusteringCartogramSearch } from "./ClusteringCartogramSearch"

const CartogramMap = forwardRef((props, ref) => {

    const { t } = useTranslation(['map', 'taxondb-attributes', 'taxondb-groups'])
    const { collapsed, openSlidebar, cartogramAdministrativeDivision, cartogramBordersColor, cartogramBordersWidth, cartogramOpacity, setCartogramOpacity, setCartogramBordersWidth, setCartogramBordersColor, setCartogramAdministrativeDivision, cartogramFirstAreaColor, cartogramSecondAreaColor, setCartogramFirstAreaColor, setCartogramSecondAreaColor, numberOfColors, sequenceType, sequenceProduct, firstSequenceNumber, setCartogramNumberOfColors, setCartogramSequenceType, setCartogramSequenceProduct, setCartogramFirstSequenceNumber, setActiveMapTool, activeMapTool } = { ...props }

    const SEQUENCE_TYPE_ARYTHMETIC = "SEQUENCE_TYPE_ARYTHMETIC"
    const SEQUENCE_TYPE_GEOMETRIC = "SEQUENCE_TYPE_GEOMETRIC"
    const SEQUENCE_TYPE_MANUAL = "SEQUENCE_TYPE_MANUAL"

    return(
        <div>
            {collapsed &&
                <div className="sidebar-single-button-container">
                    <button
                        title={t('map:visualisation-parameters') + ' - ' + t('menu:cartogram')}
                        className="sidebar-button-collapsed"
                        onClick={() => {
                            openSlidebar()
                            setActiveMapTool(MAP_OPENED_TOOL_VISUALIZATION_DETAILS)
                        }} 
                    >
                    <FontAwesomeIcon icon={faMapMarked} color={"white"}/>
                    </button>
                </div>
            }
            {!collapsed &&
                <div>
                    <div className="sidebar-opened-buttons-container">
                        <div className="sidebar-single-button-container">
                            <button
                                title={t('map:visualisation-parameters') + ' - ' + t('menu:cartogram')}
                                className="sidebar-button-collapsed"
                                onClick={() => {activeMapTool === MAP_OPENED_TOOL_VISUALIZATION_DETAILS ? setActiveMapTool(null) : setActiveMapTool(MAP_OPENED_TOOL_VISUALIZATION_DETAILS)}}
                            >
                                <FontAwesomeIcon icon={faMapMarked} color={"white"}/>
                            </button>
                        </div>
                        <div
                            className="sidebar-text playContentOpacityAnimation changeContentOpacityAnimation"
                            onClick={() => {activeMapTool === MAP_OPENED_TOOL_VISUALIZATION_DETAILS ? setActiveMapTool(null) : setActiveMapTool(MAP_OPENED_TOOL_VISUALIZATION_DETAILS)}}
                        >  
                            {t('map:visualisation-parameters') + ' - ' + t('menu:cartogram')} 
                        </div> 
                    <div className="sidebar-items-arrow changeOpacityAnimation playOpacityAnimation">
                        <FontAwesomeIcon icon={activeMapTool === MAP_OPENED_TOOL_VISUALIZATION_DETAILS ? faAngleDown : faAngleRight} color={"black"}/>
                    </div>
                </div>
                <Collapse in={activeMapTool === MAP_OPENED_TOOL_VISUALIZATION_DETAILS}>
                    <div className="ml-4 playContentOpacityAnimation changeContentOpacityAnimation"> 
                        <div>
                            <Row>
                                <Form.Group as={Col} sm={6} md={6}>
                                    <Form.Label htmlFor={'administrativeUnitField'}>
                                        {t("map:administrative-division")}
                                    </Form.Label>
                                    <Form.Control as="select" size="sm"  
                                        id={'administrativeUnitField'} 
                                        name={'administrativeUnitField'}
                                        disabled={props.loading} 
                                        onChange={(e) => {
                                                if (e.target.value){
                                                    setCartogramAdministrativeDivision(e.target.value)
                                                }
                                            }
                                        }
                                        defaultValue={cartogramAdministrativeDivision}
                                    >
                                        <option value="panstwo" id="panstwo">{t('taxondb-attributes:panstwo')}</option>
                                        <option value="wojewodztwo" id="wojewodztwo">{t('taxondb-attributes:wojewodztwo')}</option>
                                        <option value="powiat" id="powiat" >{t('taxondb-attributes:powiat')}</option>
                                        <option value="gmina" id="gmina">{t('taxondb-attributes:gmina')}</option>
                                        <option value="parknarodowy" id="parknarodowy">{t('taxondb-attributes:parknarodowy')}</option>
                                        <option value="parkkrajobrazowy" id="parkkrajobrazowy">{t('taxondb-attributes:parkkrajobrazowy')}</option>
                                        <option value="wspolrzedneatpol" id="wspolrzedneatpol">{t('taxondb-attributes:wspolrzedneatpol')}</option>
                                        <option value="wspolrzedneutm" id="wspolrzedneutm">{t('taxondb-attributes:wspolrzedneutm')}</option>
                                        <option value="polozeniewpodzialefizjograficznym" id="polozeniewpodzialefizjograficznym">{t('taxondb-attributes:polozeniewpodzialefizjograficznym')}</option>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group as={Col} sm={6} md={6}>
                                    <Form.Label htmlFor={'bordersColorField'}>
                                        {t("map:borders-color")} <span className="color-picker-tip" style={{backgroundColor: cartogramBordersColor}} />
                                    </Form.Label>
                                    <CirclePicker
                                        id={'bordersColorField'} 
                                        name={'bordersColorField'}
                                        onChange={color => setCartogramBordersColor(color.hex)}
                                        colors={MAP_COLORPICKER_LIST}
                                        width={"100%"}
                                        circleSize={24}
                                    />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} sm={6} md={6}>
                                    <Form.Label htmlFor={'cartogramStartColor'}>
                                        {t("map:empty-areas-color")} <span className="color-picker-tip" style={{backgroundColor: cartogramFirstAreaColor}} />
                                    </Form.Label>
                                    <CirclePicker
                                        id={'cartogramStartColor'} 
                                        name={'cartogramStartColor'}
                                        onChange={color => setCartogramFirstAreaColor(color.hex)}
                                        colors={MAP_COLORPICKER_LIST}
                                        width={"100%"}
                                        circleSize={24}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} sm={6} md={6}>
                                    <Form.Label htmlFor={'cartogramEndColor'}>
                                        {t("map:taxons-area-color")} <span className="color-picker-tip" style={{backgroundColor: cartogramSecondAreaColor}} />
                                    </Form.Label>
                                    <CirclePicker
                                        id={'cartogramEndColor'} 
                                        name={'cartogramEndColor'}
                                        onChange={color => setCartogramSecondAreaColor(color.hex)}
                                        colors={MAP_COLORPICKER_LIST}
                                        width={"100%"}
                                        circleSize={24}
                                    />
                                </Form.Group>
                            </Row>
                            <Row> 
                                <Form.Group as={Col} sm={6} md={6}>
                                    <FormLabel htmlFor={"borderOpacity"}>
                                        {t("map:borders-width")}
                                    </FormLabel>
                                    <Form.Control 
                                        id={"borderOpacity"} 
                                        type="number" 
                                        name="borderOpacity"
                                        disabled={props.loading}
                                        defaultValue={cartogramBordersWidth} 
                                        min={0} max={1} step={0.1}
                                        onChange={e => setCartogramBordersWidth(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} sm={6} md={6}>
                                    <FormLabel htmlFor={"cartogramOpacity"}>
                                        {t("map:cartogram-opacity")}
                                    </FormLabel>
                                    <Form.Control 
                                        id={"cartogramOpacity"} 
                                        type="number" 
                                        name="cartogramOpacity"
                                        disabled={props.loading}
                                        defaultValue={cartogramOpacity} 
                                        min={0} max={1} step={0.1}
                                        onChange={e => setCartogramOpacity(e.target.value)}
                                    />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} sm={6} md={6}>
                                    <Form.Label htmlFor={'cartogramSequenceType'}>
                                        {t('map:type-of-mathematic-sequence')}
                                    </Form.Label>
                                    <Form.Control as="select" size="sm" 
                                        id={'cartogramSequenceType'} 
                                        name={'cartogramSequenceType'}
                                        disabled={props.loading} 
                                        onChange={(e) => {
                                                if (e.target.value){
                                                    if (e.target.value === SEQUENCE_TYPE_MANUAL){
                                                        let tempArray = createRangesArrayInManualSequence(numberOfColors)
                                                        props.setCartogramManualRanges(tempArray)
                                                        setCartogramSequenceType(e.target.value)
                                                    } 
                                                    else{
                                                        setCartogramSequenceType(e.target.value)
                                                    }
                                                    
                                                }
                                            }
                                        }
                                        defaultValue={sequenceType}
                                    >
                                        <option value={SEQUENCE_TYPE_ARYTHMETIC} id={SEQUENCE_TYPE_ARYTHMETIC}> {t('map:sequence-arithmetic')} </option>
                                        <option value={SEQUENCE_TYPE_GEOMETRIC} id={SEQUENCE_TYPE_GEOMETRIC}> {t('map:sequence-geometric')} </option>
                                        <option value={SEQUENCE_TYPE_MANUAL} id={SEQUENCE_TYPE_MANUAL}> Własne przedziały </option>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group as={Col} sm={6} md={6}>
                                    <FormLabel htmlFor={"colorsNumber"}>
                                        {t("map:number-of-colors")}
                                    </FormLabel>
                                    <Form.Control 
                                        id={"colorsNumber"} 
                                        type="number" 
                                        name="colorsNumber" 
                                        disabled={props.loading}
                                        defaultValue={numberOfColors} 
                                        min={2} max={20} 
                                        onChange={e => {
                                            let currentArray = props.manualRanges
                                            let result = handleManualRangesColorChange(currentArray, e.target.value, numberOfColors)
                                            props.setCartogramManualRanges(result)
                                            setCartogramNumberOfColors(e.target.value)
                                        }}
                                    />
                                </Form.Group>
                            </Row>
                            {sequenceType === SEQUENCE_TYPE_GEOMETRIC &&
                                <Row>
                                    <Form.Group as={Col} sm={6} md={6}>
                                        <FormLabel htmlFor={"sequenceFirstNumber"}>
                                            {t('map:sequence-first-term')}
                                        </FormLabel>
                                        <Form.Control 
                                            id={"sequenceFirstNumber"} 
                                            type="number" 
                                            name="sequenceFirstNumber" 
                                            className="cartogram-button"
                                            disabled={props.loading}
                                            defaultValue={firstSequenceNumber} 
                                            min={1}
                                            onChange={e => setCartogramFirstSequenceNumber(e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} sm={6} md={6}>
                                        <FormLabel htmlFor={"sequenceProduct"}>
                                            {t('map:sequence-factor')}
                                        </FormLabel>
                                        <Form.Control 
                                            id={"sequenceProduct"} 
                                            type="number" 
                                            name="sequenceProduct"
                                            className="cartogram-button"
                                            disabled={props.loading}
                                            defaultValue={sequenceProduct} 
                                            min={1} max={100}
                                            onChange={e => setCartogramSequenceProduct(e.target.value)}
                                        />
                                    </Form.Group>
                                </Row>
                            }
                            {sequenceType === SEQUENCE_TYPE_MANUAL &&
                                <ManualRanges numberOfColors={numberOfColors} />
                            }
                        </div>
                    
                        <Row>
                            <Col md={12} sm={12} className="d-flex align-items-end" >
                                <div className="flex-grow-1" />
                                {props.loading &&
                                    <Spinner 
                                        animation="border"
                                        size="sm"
                                        aria-hidden="true"
                                        className="mb-3 mr-2"
                                    />
                                }
                                <Button
                                    className="mb-3 ml-2 mr-3"
                                    disabled={props.loading}
                                    onClick={() => clusteringCartogramSearch(ref, props, t)}
                                >
                                    {t('map:show-on-map')}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Collapse>
            </div>
            }
            </div>
    )
})

const mapStateToProps = state => ({
    filter1: getFields(TAXONDB_SEARCH_GENUS_REQUEST_ID, state),
    filter2: JSON.parse(getField(TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID, TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID, state, "null")),
    filter3: JSON.parse(getField(TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, state, "null")),
    isDynamic: getField(TAXON_SEARCH_IS_DYNAMIC, TAXON_SEARCH_IS_DYNAMIC, state, "0"),
    fields1: getFields(TAXONDB_SEARCH_GENUS_REQUEST_ID, state),
    fields2: getFields(TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID, state),
    fields3: getFields(TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, state),
    loading: isPending(TAXONDB_SEARCH_WITH_AGGREGATION_REQUEST_ID, state),
    manualRanges: state.map.cartogramManualRanges,
    cartogramAdministrativeDivision: state.map.cartogramAdministrativeDivision,
    cartogramBordersColor: state.map.cartogramBordersColor,
    cartogramBordersWidth: state.map.cartogramBordersWidth,
    cartogramOpacity: state.map.cartogramOpacity,
    cartogramFirstAreaColor: state.map.cartogramFirstAreaColor,
    cartogramSecondAreaColor: state.map.cartogramSecondAreaColor,
    numberOfColors: state.map.numberOfColors,
    sequenceType: state.map.sequenceType,
    sequenceProduct: state.map.sequenceProduct,
    firstSequenceNumber: state.map.firstSequenceNumber,
    activeMapTool: state.map.openedMapTool,
})

const mapDispatchToProps = dispatch => ({
    searchWithAggregation: (field, filter, items_per_page) => {
        return dispatch(postDataAnc(TAXONDB_SEARCH_WITH_AGGREGATION_REQUEST_ID,
            TAXONDB_SEARCH_WITH_AGGREGATION_REQUEST_URL, {
            filter: filter,
            aggregation_field: field,
            pagination: {perPage: items_per_page},
        },
        MAP_DATA_REQUEST_TIMEOUT
        ))
    },
    runCartogramSearch: (id, path) => dispatch(runCartogramSearch(id, path)),
    setFields: (id, val) => dispatch(setFields(id, val)),
    clearResponse: (id) => dispatch(actions.requestRemove(id)),
    setCartogramManualRanges: (data) => dispatch(setCartogramManualRanges(data)),
    setCartogramAdministrativeDivision: data => dispatch(setCartogramAdministrativeDivision(data)),
    setCartogramBordersColor: data => dispatch(setCartogramBordersColor(data)),
    setCartogramBordersWidth: data => dispatch(setCartogramBordersWidth(data)),
    setCartogramOpacity: data => dispatch(setCartogramOpacity(data)),
    setCartogramFirstAreaColor: data => dispatch(setCartogramFirstAreaColor(data)),
    setCartogramSecondAreaColor: data => dispatch(setCartogramSecondAreaColor(data)),
    setCartogramNumberOfColors: data => dispatch(setCartogramNumberOfColors(data)),
    setCartogramSequenceType: data => dispatch(setCartogramSequenceType(data)),
    setCartogramSequenceProduct: data => dispatch(setCartogramSequenceProduct(data)),
    setCartogramFirstSequenceNumber: data => dispatch(setCartogramFirstSequenceNumber(data)),
    setActiveMapTool: (value) => dispatch(setOpenedMapTool(value)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(CartogramMap)
