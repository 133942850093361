import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next'
import { Form } from "react-bootstrap"
import useCoordinateLayers from '../../hooks/useCoordinateLayers'

export const CoordinateLayers  = forwardRef((props, ref) => {

    const { t } = useTranslation(['map'])
    const { handleAtpolGrid, handleUtmGrid, handleCoordinatesGrid } = useCoordinateLayers()

    const mapHasLayer = (name) => {
        let response = false
        try{
            ref.mapRef.current.eachLayer(function(layer){
                if (layer.name === name){
                    response = true;
                }
            })
        }
        catch(e){
            //mapRef not rendered yet
        }
        return response;
    }

    return (
        <div>
            <div className="ml-2 mt-2">
                <div><b>{t("graticules-button-title")}</b></div>
            </div>    
            <div className="ml-3">
                <Form.Check name="atpol_1" id="atpol-1" onClick={(e) => handleAtpolGrid(e.target, ref.mapRef)} defaultChecked={mapHasLayer("atpol_1")} label={t("atpol-1")}></Form.Check>
                <Form.Check name="atpol_2" id="atpol-2" onClick={(e) => handleAtpolGrid(e.target, ref.mapRef)} defaultChecked={mapHasLayer("atpol_2")} label={t("atpol-2")}></Form.Check>
                <Form.Check name="atpol_3" id="atpol-3" onClick={(e) => handleAtpolGrid(e.target, ref.mapRef)} defaultChecked={mapHasLayer("atpol_3")} label={t("atpol-3")}></Form.Check>
                <Form.Check name="atpol_4" id="atpol-4" onClick={(e) => handleAtpolGrid(e.target, ref.mapRef)} defaultChecked={mapHasLayer("atpol_4")} label={t("atpol-4")}></Form.Check>
                <Form.Check name="coordinates-layer" id="coordinates-layer" onClick={(e) => handleCoordinatesGrid(e.target, ref.mapRef)} defaultChecked={mapHasLayer("coordinates-layer")} label={t("coordinates-layer")}></Form.Check>
                <Form.Check name="utm-poland" id="utm-poland" onClick={(e) => handleUtmGrid(e.target, ref.mapRef)} defaultChecked={mapHasLayer("utm-poland")} label={t("utm-poland")}></Form.Check>
                <Form.Check name="utm-world" id="utm-world" onClick={(e) => handleUtmGrid(e.target, ref.mapRef)} defaultChecked={mapHasLayer("utm-world")} label={t("utm-world")}></Form.Check>
            </div>
        </div>
    )
})
