import React, { useState } from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Container, Row, Col, Button, Collapse } from "react-bootstrap"
import {
    CREATE_RESOURCE_PAGE_URL_PL,
    CREATE_RESOURCE_PAGE_URL_EN
} from '../../settings'

import I18nPathTranslator from "../I18nPathTranslator"
import SEO from "../SEO"
import RedirectLoginWithReturn from "../access/RedirectLoginWithReturn"
import TaxonRecordDetails from '../taxonDB/RecordDetails'
import SampleRecordDetails from '../samplesDB/RecordDetails'
import BibliographyRecordDetails from '../bibliographyDB/RecordDetails'

const CreateResourceContainer = props => {
    const { t } = useTranslation(['admin-tools', 'paths'])
    const [isCreateTaxonOpen, setCreateTaxonOpen] = useState(false)
    const [isCreateSampleOpen, setCreateSampleOpen] = useState(false)
    const [isCreateBibliograpyOpen, setCreateBibliographyOpen] = useState(false)

    return (
        <>
            <I18nPathTranslator {...props} pathEN={CREATE_RESOURCE_PAGE_URL_EN} pathPL={CREATE_RESOURCE_PAGE_URL_PL} />
            <SEO title={t('admin-tools:create-resource')} />
            {!props.loading && props.user && !props.user.data &&
                <RedirectLoginWithReturn lang={props.lang} returnUrl={props.lang ? t("paths:create-resource", { lng: props.lang }) : t("paths:create-resource")} />
            }
            {props.user && props.user.data &&
                <Container className="mt-2">
                    <Row>
                        <Col>
                            <Button className="mr-2" onClick={() => setCreateTaxonOpen(s => !s)} >
                                {t('admin-tools:create-taxon')}
                            </Button>
                            <Button className="mr-2" onClick={() => setCreateSampleOpen(s => !s)} >
                                {t('admin-tools:create-sample')}
                            </Button>
                            <Button className="mr-2" onClick={() => setCreateBibliographyOpen(s => !s)} >
                                {t('admin-tools:create-bibliography')}
                            </Button>
                            <hr />
                        </Col>
                    </Row>
                    <Row>
                        <Collapse in={isCreateTaxonOpen}>
                            <TaxonRecordDetails />
                        </Collapse>
                        <Collapse in={isCreateSampleOpen}>
                            <SampleRecordDetails />
                        </Collapse>
                        <Collapse in={isCreateBibliograpyOpen}>
                            <BibliographyRecordDetails />
                        </Collapse>
                    </Row>
                </Container>
            }
        </>
    )
}

const mapStateToProps = (state, ownProps) => ({
    user: state.user
})

const mapDispatchToProps = dispatch => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateResourceContainer)
