import React, { Suspense } from "react";
import { Container } from "react-bootstrap"
import { useTranslation } from 'react-i18next'
import { REGULATIONS_PAGE_URL_EN, REGULATIONS_PAGE_URL_PL } from "../settings";
import Loader from '../utils/loader'
import I18nPathTranslator from "./I18nPathTranslator";
import SEO from "./SEO";

const Regulamin = props => {
    const { t } = useTranslation()

    return (
        <Suspense fallback={<Loader />}>
            <I18nPathTranslator {...props} pathEN={REGULATIONS_PAGE_URL_EN} pathPL={REGULATIONS_PAGE_URL_PL} />
            <SEO title={t('common:regulations')} />
            <div>
                <Container>
                    <div>
                        {t('regulations-content')}
                    </div>
                </Container>
            </div>
        </Suspense>
    )
}

export default Regulamin
