import React, { useState, useRef, useCallback } from "react"
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { isPending } from "../../reducers"
import { Form, Button, Col } from "react-bootstrap"
import * as actions from '../../actions'
import * as notify from '../../utils/notify'
import {
    API_ALBUM_EDIT_ITEM_ID,
    API_ALBUM_EDIT_ITEM_URL,
    API_FILTER_CHANGE_NAME_ID,
    API_FILTER_CHANGE_NAME_URL,
    API_ALBUM_CHANGE_NAME_ID,
    API_ALBUM_CHANGE_NAME_URL,
    API_ALBUM_GET_ITEMS_ID_PREFIX,
} from '../../settings'
import HtmlEditor from '../HtmlEditor'

const EditItem = props => {
    const { t } = useTranslation(['profile', 'forms', 'common'])

    const [name, setName] = useState(props.item.name || '')
    const onNameChanged = e => {
        setName(e.target.value)
    }

    const [customName, setCustomName] = useState(props.item.custom_name || '')
    const onCustomNameChanged = e => {
        setCustomName(e.target.value)
    }

    const description = useRef(props.item.description || '')
    const onDescriptionChanged = useCallback(e => {
        description.current = e
    }, []);

    const [isPublic, setIsPublic] = useState(props.item.is_public? "True" : "False")
    const onIsPublicChanged = e => {
        setIsPublic(e.target.value)
    }

    const [sortOrder, setSortOrder] = useState(props.item.sort_order)
    const onSortOrderChanged = e => {
        const value = parseInt(e.target.value)
        if (isNaN(value) || (value >= 1 && value <= getSordOrderMax())) {
            setSortOrder(isNaN(value)? null : value)            
        }
    }

    const getSordOrderMax = () => {
        const request = props.requests[props.requestId]
        const totalCount = request.output && request.output.pagination && request.output.pagination.totalCount
        return totalCount
    }

    const onSubmitForm = e => {
        let hasChange = false
        let chain = []
        if (props.item.kind === 'album' && props.item.name !== name) {
            hasChange = true
            chain.push(props.changeAlbumName(name, t))
        }

        if (props.item.kind === 'filter' && props.item.name !== name) {
            hasChange = true
            chain.push(props.changeFilterName(name, t))
        }

        const isPublicAsBoolean = isPublic === 'True'
        if (props.item.custom_name !== customName ||
            props.item.description !== description.current ||
            props.item.is_public !== isPublicAsBoolean ||
            props.item.sort_order !== sortOrder){
            hasChange = true
            chain.push(props.editItem(customName, description.current, sortOrder, isPublic, t))
        }

        if (hasChange) {
            let item = {
                name: name,
                custom_name: customName,
                description: description.current,
                sort_order: sortOrder,
                is_public: isPublicAsBoolean,
                kind: props.item.kind
            }

            if (props.item.kind === 'filter') {
                item.reference_id = props.item.reference_id
            } else {
                item.id = props.item.id
            }

            chain.push(props.updateAlbumsItems(item, props.requests))
        }

        Promise.all(chain).then((res) => {
            props.onDone && props.onDone(props.item.sort_order !== sortOrder)
        })
    }

    const onKeyDown = (e) => {
        if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(e.key)){
            e.stopPropagation()
        }
    }

    return (
        <Form as={Col} className="mt-2 p-1" onSubmit={e => { e.preventDefault() }} onKeyDown={onKeyDown}>
            {(props.item.kind === 'filter' || props.item.kind === 'album') &&
                <Form.Group>
                    <Form.Label htmlFor="name">{t('profile:name')}</Form.Label>
                    <Form.Control id="name" size="sm" value={name} onChange={onNameChanged} disabled={props.querying}></Form.Control>
                </Form.Group>
            }

            <Form.Group>
                <Form.Label htmlFor="customName">{t('profile:label')}</Form.Label>
                <Form.Control id="customName" size="sm" value={customName} onChange={onCustomNameChanged} disabled={props.querying}></Form.Control>
            </Form.Group>
            <Form.Group>
                <Form.Label htmlFor="description">{t('profile:description')}</Form.Label>
                <HtmlEditor defaultValue={description.current} onChange={onDescriptionChanged} disabled={props.querying}/>
            </Form.Group>
            <Form.Group>
                <Form.Label htmlFor="isPublic">{t('profile:is-public')}</Form.Label>
                <Form.Control id="isPublic" as="select" value={isPublic} onChange={onIsPublicChanged} disabled={props.querying}>
                    <option value="True">{t('common:true')}</option>
                    <option value="False">{t('common:false')}</option>
                </Form.Control>
            </Form.Group>
            <Form.Group>
                <Form.Label htmlFor="sortOrder">{t('profile:sort-order')} <span className="itemlist-itemlabel">(1-{getSordOrderMax()})</span></Form.Label>
                <Form.Control id="sortOrder" size="sm" value={sortOrder} onChange={onSortOrderChanged} disabled={props.querying}></Form.Control>
            </Form.Group>
            <Form.Group className="float-right">
                <Button size="sm" variant="primary" onClick={onSubmitForm} disabled={props.querying}>
                    {t('forms:save')}
                </Button>
            </Form.Group>
        </Form>
    )
}

const mapStateToProps = state => ({
    querying: isPending(API_ALBUM_EDIT_ITEM_ID, state),
    requests: state.requests
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    editItem: (customName, description, sortOrder, isPublic, t) => {
        return dispatch(actions.postDataApi(API_ALBUM_EDIT_ITEM_ID, API_ALBUM_EDIT_ITEM_URL, {
            item_id: ownProps.item.id,
            custom_name: customName,
            description: description,
            sort_order: sortOrder,
            is_public: isPublic,
        })).then(() => {
            notify.success(t('profile:item-edited'))
        }).catch(res => {
            notify.error(t('profile:cannot-edit-item'), res.result)
        })
    },
    changeAlbumName: (newAlbumName, t) => {
        return dispatch(actions.postDataApi(API_ALBUM_CHANGE_NAME_ID, API_ALBUM_CHANGE_NAME_URL, {
            album_id: ownProps.item.id,
            new_name: newAlbumName
        })).then(() => {
            notify.success(t('profile:album-name-changed'))
        }).catch(res => {
            notify.error(t('profile:cannot-change-album-name'), res.result)
        })
    },
    changeFilterName: (newFilterName, t) => {
        return dispatch(actions.postDataApi(API_FILTER_CHANGE_NAME_ID, API_FILTER_CHANGE_NAME_URL, {
            filter_id: ownProps.item.reference_id.toString(),
            new_name: newFilterName
        })).then(() => {
            notify.success(t('profile:filter-name-changed'))
        }).catch(res => {
            notify.error(t('profile:cannot-change-filter-name'), res.result)
        })
    },
    updateAlbumsItems: (item, requests) => {
        let chain = []
        for (let request in requests) {
            if (!request.startsWith(API_ALBUM_GET_ITEMS_ID_PREFIX + 'album')){
                continue
            }

            const newData = {
                ...requests[request].output, items: requests[request].output.items.map(obj => {
                    if (obj.kind !== item.kind){
                        return obj
                    }

                    if (obj.kind === 'filter' && obj.reference_id === item.reference_id) {
                        return { ...obj, ...item }
                    } else if (obj.kind !== 'filter' && obj.id === item.id){
                        return { ...obj, ...item }
                    } else {
                        return obj
                    }
                })
            }

            chain.push(dispatch(actions.requestSuccess(request, newData)))
        }

        return Promise.all(chain)
    }
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditItem)
