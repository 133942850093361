import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';

import {
  USER_GROUPS_COORDINATOR_CONST,
  USER_GROUPS_EDITOR_CONST,
  USER_GROUPS_SUPERLEADER_CONST,
  USER_GROUPS_VALIDATOR_CONST,
  USER_GROUPS_LEADER_CONST,
} from '../settings';

export default () => {
  const [ isSuperleader, setIsSuperleader ] = useState(false);
  const [ isLeader, setIsLeader ] = useState(false);
  const [ isCoordinator, setIsCoordinator ] = useState(false);
  const [ isEditor, setIsEditor ] = useState(false);
  const [ isValidator, setIsValidator ] = useState(false);
  const isLoggedIn = useRef(false);
  const [ shortName, setShortName ] = useState("...");
  const groups = useSelector(state => (state.user && state.user.data) ? state.user.data.groups : null);
  const firstName = useSelector(state => (state.user && state.user.data) ? state.user.data.first_name : null);

  const SHORT_NAME_LENGHT = 15

  useEffect(() => {
    isLoggedIn.current = false
    setIsCoordinator(false);
    setIsSuperleader(false);
    setIsLeader(false);
    setIsEditor(false);
    setIsValidator(false);
    if (groups) {
      isLoggedIn.current = true
      if (Array.isArray(groups)) {
        setIsCoordinator(groups.includes(USER_GROUPS_COORDINATOR_CONST));
        setIsSuperleader(groups.includes(USER_GROUPS_SUPERLEADER_CONST));
        setIsLeader(groups.includes(USER_GROUPS_LEADER_CONST));
        setIsEditor(groups.includes(USER_GROUPS_EDITOR_CONST));
        setIsValidator(groups.includes(USER_GROUPS_VALIDATOR_CONST));
      }
    }
    if (firstName) {
      const parts = firstName.split(" ")
      let out = parts[ 0 ]
      parts.forEach((val, indx) => {
        if (indx > 0 && out.length + val.length + 1 <= SHORT_NAME_LENGHT) {
          out += ` ${val}`
        }
      })
      if (out.length > SHORT_NAME_LENGHT) {
        out = `${out.substring(0, SHORT_NAME_LENGHT - 1)}...`
      }
      setShortName(out)
    }
  }, [ groups, firstName ]);

  const hasRole = useCallback(role => {
    if (groups) {
      if (Array.isArray(groups)) {
        if (Array.isArray(role)) {
          return role.some(val => groups.includes(val))
        } else {
          return groups.includes(role);
        }
      }
    }
    return false
  }, [ groups ]);

  const ShowWhenLoggedIn = props =>
  <>
    {isLoggedIn.current &&
    <>
      {props.children}
    </>
    }
  </>

  const ShowWhenNotLoggedIn = props =>
    <>
      {!isLoggedIn.current &&
        <>
          {props.children}
        </>
      }
    </>

  return { isSuperleader, isCoordinator, isEditor, hasRole, isValidator, shortName, isLeader, ShowWhenLoggedIn, ShowWhenNotLoggedIn, isLoggedIn: isLoggedIn.current };
};
