import React from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { setFields, runSearchFlow, setField, setSearchAreaGeojson } from '../../actions/forms'
import { requestRemove, requestRemoveStartsWith } from '../../actions'
import { isPending, getFields, getField, prepareFilter } from "../../reducers"
import { Button } from "react-bootstrap"
import {
    AGGREGATION_REPORT_REQUEST_ID_PREFIX,
    MAP_TOOLS_ACTIVE_VISUALIZATION,
    TAXONDB_SEARCH_GENUS_REQUEST_ID
} from '../../settings'

const SearchButtons = props => {
    const { t } = useTranslation([ 'common', 'taxondb', 'taxondb-attributes' ])

    const clearAllFields = obj => {
        Object.keys(obj).forEach(val => {
            if (val.startsWith('or_')) {
                obj[ val ].forEach(val2 => {
                    clearAllFields(val2)
                })
            } else if (val.startsWith('not_')) {
                clearAllFields(obj[ val ])
            } else {
                obj[ val ] = ''
            }
        })
    }

    const clearForm = e => {
        const temp = { ...props.fields }
        props.setSearchAreaGeojson({})
        clearAllFields(temp)
        props.setFields(temp)
        props.clearResponse()
        hideSearchAreaMap()
    }

    const hideSearchAreaMap = () => {
        props.setShowAreaSearch(false)
    }

    const submitForm = e => {
        e.preventDefault()
        props.runSearchFlow()
        if (props.afterSearch) {
            props.afterSearch()
        }
        hideSearchAreaMap()
    }

    const runMapSearch = e => {
        e.preventDefault()
        props.handleMapSearch()
    }

    return (
        <>
            <Button variant="primary" className="search-button" onClick={!props.bioGisForm ? submitForm : runMapSearch} disabled={props.loading || Object.keys(props.filter).length === 0}>
                {t('search')}
            </Button>
            <Button variant="secondary" className="ml-2 clear-button" onClick={clearForm} disabled={props.loading} >
                {t('clear')}
            </Button>
        </>
    )
}

const mapStateToProps = (state, ownProps) => ({
    loading: isPending(ownProps.searchRequestId ? ownProps.searchRequestId : ownProps.genusRequestId, state),
    fields: ownProps.path ?
        JSON.parse(getField(ownProps.searchRequestId ? ownProps.searchRequestId : ownProps.genusRequestId, ownProps.searchRequestId ? ownProps.searchRequestId : ownProps.genusRequestId, state, "null")) :
        getFields(ownProps.searchRequestId ? ownProps.searchRequestId : ownProps.genusRequestId, state),
    filter: prepareFilter(ownProps.searchRequestId ? ownProps.searchRequestId : ownProps.genusRequestId, state, ownProps.path),
    activeVisualization: getField(MAP_TOOLS_ACTIVE_VISUALIZATION, MAP_TOOLS_ACTIVE_VISUALIZATION, state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    //setField: (form, field, value) => dispatch(setField(form, field, value)),
    setFields: val => ownProps.path ?
        dispatch(setField(ownProps.searchRequestId ? ownProps.searchRequestId : ownProps.genusRequestId, ownProps.searchRequestId ? ownProps.searchRequestId : ownProps.genusRequestId, JSON.stringify(val))) :
        dispatch(setFields(ownProps.searchRequestId ? ownProps.searchRequestId : ownProps.genusRequestId, val)),
    runSearchFlow: () => dispatch(ownProps.onSearch ? ownProps.onSearch() : runSearchFlow(ownProps.genusRequestId, ownProps.speciesRequestId, ownProps.requestId, ownProps.tabId, ownProps.path, ownProps.searchAreaGeoJson)),
    clearResponse: () => {
        if (ownProps.onClear) {
            dispatch(ownProps.onClear())
        } else {
            dispatch(requestRemove(ownProps.genusRequestId))
            dispatch(requestRemove(ownProps.speciesRequestId))
            dispatch(requestRemove(ownProps.requestId))
            dispatch(requestRemoveStartsWith(AGGREGATION_REPORT_REQUEST_ID_PREFIX + ownProps.requestId))
        }
    },
    setShowAreaSearch: value => dispatch(setField((ownProps.searchRequestId ? ownProps.searchRequestId : TAXONDB_SEARCH_GENUS_REQUEST_ID) + 'set-show-area-search', 'set-show-area-search', value)),
    setSearchAreaGeojson: (value) => dispatch(setSearchAreaGeojson(value)), 
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchButtons)
