import React, { useState, useEffect } from "react";
import Autosuggest from 'react-autosuggest'
import "../styles/react-autosuggest.scss"
import { countries } from "./dictionary/countries"
import { useTranslation } from 'react-i18next'
import { Form } from "react-bootstrap"

const FormControlCountries = props => {
  const { i18n } = useTranslation([ 'common', 'taxondb', 'taxondb-attributes' ])

    const [ suggestions, setSuggestions ] = useState(countries.map(v => ({key: v["Państwo PL"], value: v[i18n.language.startsWith('pl') ? "Państwo PL" : "Państwo EN"]})))
    const [ selectedValue, setSelectedValue ] = useState(props.defaultValue || '')

    const { register, name } = props
    useEffect(() => {
      register(name)
    }, [register, name]);

    const onSuggestionsFetchRequested = query => {
      setSuggestions(countries.map(v => ({key: v["Państwo PL"], value: v[i18n.language.startsWith('pl') ? "Państwo PL" : "Państwo EN"]})).filter(v => v.value.toLowerCase().startsWith(query.value.toLowerCase())));
    }

    const onSuggestionsClearRequested = () => {
      setSuggestions([])
    }

    const getSuggestionValue = suggestion => suggestion.key;

    const inputProps = {
      ...props,
      register: undefined,
      set: undefined,
      defaultValue: undefined,
      value: (countries.map(v => ({key: v["Państwo PL"], value: v[i18n.language.startsWith('pl') ? "Państwo PL" : "Państwo EN"]}))
        .find(v => v.key === selectedValue)) ? (countries.map(v => ({key: v["Państwo PL"], value: v[i18n.language.startsWith('pl') ? 
        "Państwo PL" : "Państwo EN"]})).find(v => v.key === selectedValue).value) : 
        (selectedValue),
      onChange: e => {
          const newValue = (countries.map(v => ({key: v["Państwo PL"], value: v[i18n.language.startsWith('pl') ? "Państwo PL" : "Państwo EN"]}))
            .find(v => v.value === e.target.value)) ? 
            (countries.map(v => ({key: v["Państwo PL"], value: v[i18n.language.startsWith('pl') ? "Państwo PL" : "Państwo EN"]})).find(v => v.value === e.target.value).key) : (e.target.value)
          setSelectedValue(newValue)
          props.set(props.name, newValue)
        },
    }

    const renderSuggestion = suggestion => {
      const query = selectedValue.toLowerCase();
      const bold = suggestion.value.substring(0, query.length);
      const normal = suggestion.value.substring(query.length);
      return (
        <span>
          <b>{bold}</b>{normal}
        </span>
      );
    }

    const onSuggestionSelected = (e, val) => {
        setSelectedValue(val.suggestionValue)
        props.set(props.name, val.suggestionValue)
    }

    const renderInputComponent = inputProps => (
        <div>
            <Form.Control {...inputProps} />
        </div>
    );

    return (
        <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
            onSuggestionSelected={onSuggestionSelected}
            renderInputComponent={renderInputComponent}
        >
        </Autosuggest>
    )
}

export default FormControlCountries
