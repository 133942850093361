import React, { forwardRef, useState } from "react";
import { Table, Form } from "react-bootstrap"
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { setWmsAddedToMap } from '../../actions/map'
import usePredefinedWMS from "../../hooks/usePredefinedWMS";
import { MAP_LAYERS } from "../../stateIds";
import useMapRedux from "../../hooks/useMapRedux";

const PredefinedWMS = forwardRef((props, ref) => {
    const { t } = useTranslation(['map'])
    const { handleLoadWms } = usePredefinedWMS()
    const { value: mapLayers } = useMapRedux(MAP_LAYERS, {});
    const [opacity, setOpacity] = useState(
        Object.keys(mapLayers.wmsLayer || {}).reduce((acc, key) => {
            const obj = mapLayers.wmsLayer[key]
            if (obj.id) {
                acc[obj.id] = obj.opacity
            }
            else {
                acc[obj.id] = "0.6"
            }
            return acc;
        }, {})
    )

    const [ wmsAddedToMap, setWmsAddedToMap ] = useState(
        Object.keys(mapLayers.wmsLayer || {}).reduce((acc, key) => {
            const obj = mapLayers.wmsLayer[key]
            if (obj.id) {
                acc[obj.id] = true
            }
            else {
                acc[obj.id] = false
            }
            return acc;
        }, {})
    )

    const toggleButton = (ref, url, layer, name, id, opacity) => {
        const temp = {...wmsAddedToMap}
        temp[id] = !temp[id]
        setWmsAddedToMap(temp)
        handleLoadWms(ref, url, layer, name, id, temp[id], opacity)
    }

    const handleSetLayerOpacity = (e, id) => {
        let temp = { ...opacity }
        temp[id] = e.target.value
        setOpacity(temp)
    }

    const mapHasWmsLayer = (name) => {
        let response = false
        try{
            ref.current.eachLayer(function(layer){
                if (layer.name === name){
                    response = true;
                }
            })
        }
        catch(e){
            //mapRef not rendered yet
        }
      
        return response;
    }


    return (
        <div className="pr-3 ml-2">
            <div className="ml-2 mt-2">
                <b>Bank Danych o Lasach</b>
            </div>       
            <Table striped bordered size="sm" className="ml-2">
                <thead>
                    <tr>
                        <th>{t('map:name')}</th>
                        <th>{t('map:opacity')}</th>
                        <th>{t('map:load')}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Wydzielenia poza PGL LP wg PUL</td>
                        <td>
                            <Form.Control 
                                type="number"
                                min="0.1"
                                max="1" 
                                step="0.1"
                                defaultValue="0.6"
                                onChange={(e) => handleSetLayerOpacity(e, "0")}
                            />
                        </td>
                        <td>
                            <Form.Check 
                                type="checkbox"
                                label={(wmsAddedToMap && !wmsAddedToMap["0"]) ? t('map:load') : t('map:loaded')}
                                defaultChecked={mapHasWmsLayer("Wydzielenia poza PGL LP wg PUL")}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter"){
                                        e.target.checked = !wmsAddedToMap["0"]
                                        toggleButton(ref, "https://mapserver.bdl.lasy.gov.pl/ArcGIS/services/WMS_BDL/mapserver/WMSServer?", "0", "Wydzielenia poza PGL LP wg PUL", "0", opacity["0"])
                                    }
                                }}
                                onChange={() => 
                                    toggleButton(ref, "https://mapserver.bdl.lasy.gov.pl/ArcGIS/services/WMS_BDL/mapserver/WMSServer?", "0", "Wydzielenia poza PGL LP wg PUL", "0", opacity["0"])
                                }
                                id="Wydzielenia poza PGL LP wg PUL"
                                name="Wydzielenia poza PGL LP wg PUL"
                            />
                        </td>               
                    </tr>

                    <tr>
                        <td>Wydzielenia PGL LP</td>
                        <td>
                            <Form.Control 
                                type="number"
                                min="0.1"
                                max="1" 
                                step="0.1"
                                defaultValue="0.6"
                                onChange={(e) => handleSetLayerOpacity(e, "1")}
                            />
                        </td>
                        <td>
                            <Form.Check 
                                type="checkbox"
                                label={(wmsAddedToMap && !wmsAddedToMap["1"]) ? t('map:load') : t('map:loaded')}
                                defaultChecked={mapHasWmsLayer("Wydzielenia PGL LP")}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter"){
                                        e.target.checked = !wmsAddedToMap["1"]
                                        toggleButton(ref, "https://mapserver.bdl.lasy.gov.pl/ArcGIS/services/WMS_BDL/mapserver/WMSServer?", "1", "Wydzielenia PGL LP", "1", opacity["1"])
                                    }
                                }}
                                onChange={() => 
                                    toggleButton(ref, "https://mapserver.bdl.lasy.gov.pl/ArcGIS/services/WMS_BDL/mapserver/WMSServer?", "1", "Wydzielenia PGL LP", "1", opacity["1"])
                                }
                                id="Wydzielenia PGL LP"
                                name="Wydzielenia PGL LP"
                            />
                        </td>               
                    </tr>

                    <tr>
                        <td>Oddziały poza PGL LP wg PUL</td>
                        <td>
                            <Form.Control 
                                type="number"
                                min="0.1"
                                max="1" 
                                step="0.1"
                                defaultValue="0.6"
                                onChange={(e) => handleSetLayerOpacity(e, "2")}
                            />
                        </td>
                        <td>
                            <Form.Check 
                                type="checkbox"
                                label={(wmsAddedToMap && !wmsAddedToMap["2"]) ? t('map:load') : t('map:loaded')}
                                defaultChecked={mapHasWmsLayer("Oddziały poza PGL LP wg PUL")}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter"){
                                        e.target.checked = !wmsAddedToMap["2"]
                                        toggleButton(ref, "https://mapserver.bdl.lasy.gov.pl/ArcGIS/services/WMS_BDL/mapserver/WMSServer?", "2", "Oddziały poza PGL LP wg PUL", "2", opacity["2"])
                                    }
                                }}
                                onChange={() => 
                                    toggleButton(ref, "https://mapserver.bdl.lasy.gov.pl/ArcGIS/services/WMS_BDL/mapserver/WMSServer?", "2", "Oddziały poza PGL LP wg PUL", "2", opacity["2"])
                                }
                                id="Oddziały poza PGL LP wg PUL"
                                name="Oddziały poza PGL LP wg PUL"
                            />
                        </td>               
                    </tr>

                    <tr>
                        <td>Oddziały PGL LP</td>
                        <td>
                            <Form.Control 
                                type="number"
                                min="0.1"
                                max="1" 
                                step="0.1"
                                defaultValue="0.6"
                                onChange={(e) => handleSetLayerOpacity(e, "3")}
                            />
                        </td>
                        <td>
                            <Form.Check 
                                type="checkbox"
                                label={(wmsAddedToMap && !wmsAddedToMap["3"]) ? t('map:load') : t('map:loaded')}
                                defaultChecked={mapHasWmsLayer("Oddziały PGL LP")}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter"){
                                        e.target.checked = !wmsAddedToMap["3"]
                                        toggleButton(ref, "https://mapserver.bdl.lasy.gov.pl/ArcGIS/services/WMS_BDL/mapserver/WMSServer?", "3", "Oddziały PGL LP", "3", opacity["3"])
                                    }
                                }}
                                onChange={() => 
                                    toggleButton(ref, "https://mapserver.bdl.lasy.gov.pl/ArcGIS/services/WMS_BDL/mapserver/WMSServer?", "3", "Oddziały PGL LP", "3", opacity["3"])
                                }
                                id="Oddziały PGL LP"
                                name="Oddziały PGL LP"
                            />
                        </td>               
                    </tr>

                    <tr>
                        <td>Leśnictwa</td>
                        <td>
                            <Form.Control 
                                type="number"
                                min="0.1"
                                max="1" 
                                step="0.1"
                                defaultValue="0.6"
                                onChange={(e) => handleSetLayerOpacity(e, "4")}
                            />
                        </td>
                        <td>
                            <Form.Check 
                                type="checkbox"
                                label={(wmsAddedToMap && !wmsAddedToMap["4"]) ? t('map:load') : t('map:loaded')}
                                defaultChecked={mapHasWmsLayer("Leśnictwa")}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter"){
                                        e.target.checked = !wmsAddedToMap["4"]
                                        toggleButton(ref, "https://mapserver.bdl.lasy.gov.pl/ArcGIS/services/WMS_BDL/mapserver/WMSServer?", "4", "Leśnictwa", "4", opacity["4"])
                                    }
                                }}
                                onChange={() => 
                                    toggleButton(ref, "https://mapserver.bdl.lasy.gov.pl/ArcGIS/services/WMS_BDL/mapserver/WMSServer?", "4", "Leśnictwa", "4", opacity["4"])
                                }
                                id="Leśnictwa"
                                name="Leśnictwa"
                            />
                        </td>               
                    </tr>

                    <tr>
                        <td>Nadleśnictwa</td>
                        <td>
                            <Form.Control 
                                type="number"
                                min="0.1"
                                max="1" 
                                step="0.1"
                                defaultValue="0.6"
                                onChange={(e) => handleSetLayerOpacity(e, "5")}
                            />
                        </td>
                        <td>
                            <Form.Check 
                                type="checkbox"
                                label={(wmsAddedToMap && !wmsAddedToMap["5"]) ? t('map:load') : t('map:loaded')}
                                defaultChecked={mapHasWmsLayer("Nadleśnictwa")}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter"){
                                        e.target.checked = !wmsAddedToMap["5"]
                                        toggleButton(ref, "https://mapserver.bdl.lasy.gov.pl/ArcGIS/services/WMS_BDL/mapserver/WMSServer?", "5", "Nadleśnictwa", "5", opacity["5"])
                                    }
                                }}
                                onChange={() => 
                                    toggleButton(ref, "https://mapserver.bdl.lasy.gov.pl/ArcGIS/services/WMS_BDL/mapserver/WMSServer?", "5", "Nadleśnictwa", "5", opacity["5"])
                                }
                                id="Nadleśnictwa"
                                name="Nadleśnictwa"
                            />
                        </td>               
                    </tr>

                    <tr>
                        <td>RDLP</td>
                        <td>
                            <Form.Control 
                                type="number"
                                min="0.1"
                                max="1" 
                                step="0.1"
                                defaultValue="0.6"
                                onChange={(e) => handleSetLayerOpacity(e, "6")}
                            />
                        </td>
                        <td>
                            <Form.Check 
                                type="checkbox"
                                label={(wmsAddedToMap && !wmsAddedToMap["6"]) ? t('map:load') : t('map:loaded')}
                                defaultChecked={mapHasWmsLayer("RDLP")}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter"){
                                        e.target.checked = !wmsAddedToMap["6"]
                                        toggleButton(ref, "https://mapserver.bdl.lasy.gov.pl/ArcGIS/services/WMS_BDL/mapserver/WMSServer?", "6", "RDLP", "6", opacity["6"])
                                    }
                                }}
                                onChange={() => 
                                    toggleButton(ref, "https://mapserver.bdl.lasy.gov.pl/ArcGIS/services/WMS_BDL/mapserver/WMSServer?", "6", "RDLP", "6", opacity["6"])
                                }
                                id="RDLP"
                                name="RDLP"
                            />
                        </td>               
                    </tr>
                </tbody>
            </Table>

            <div className="ml-2 mt-2">
                <b>Mapa Hydrograficzna Polski</b>
            </div>       
            <Table striped bordered size="sm" className="ml-2">
                <thead>
                    <tr>
                        <th>{t('map:name')}</th>
                        <th>{t('map:opacity')}</th>
                        <th>{t('map:load')}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Mapa hydrograficzna</td>
                        <td>
                            <Form.Control 
                                type="number"
                                min="0.1"
                                max="1" 
                                step="0.1"
                                defaultValue="0.6"
                                onChange={(e) => handleSetLayerOpacity(e, "hydrograficzna_Raster")}
                            />
                        </td>
                        <td>
                            <Form.Check 
                                type="checkbox"
                                label={(wmsAddedToMap && !wmsAddedToMap["hydrograficzna_Raster"]) ? t('map:load') : t('map:loaded')}
                                defaultChecked={mapHasWmsLayer("Mapa hydrograficzna")}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter"){
                                        e.target.checked = !wmsAddedToMap["hydrograficzna_Raster"]
                                        toggleButton(ref, "https://mapy.geoportal.gov.pl/wss/service/img/guest/HYDRO/MapServer/WMSServer", "Raster", "Mapa hydrograficzna", "hydrograficzna_Raster", opacity["hydrograficzna_Raster"])
                                    }
                                }}
                                onChange={() => 
                                    toggleButton(ref, "https://mapy.geoportal.gov.pl/wss/service/img/guest/HYDRO/MapServer/WMSServer", "Raster", "Mapa hydrograficzna", "hydrograficzna_Raster", opacity["hydrograficzna_Raster"])
                                }
                                id="Mapa hydrograficzna"
                                name="Mapa hydrograficzna"
                            />
                        </td>               
                    </tr>

                    <tr>
                        <td>Mapa hydrograficzna enviDMS 10k</td>
                        <td>
                            <Form.Control 
                                type="number"
                                min="0.1"
                                max="1" 
                                step="0.1"
                                defaultValue="0.6"
                                onChange={(e) => handleSetLayerOpacity(e, "hydrograficzna_Raster_10k")}
                            />
                        </td>
                        <td>
                            <Form.Check 
                                type="checkbox"
                                label={(wmsAddedToMap && !wmsAddedToMap["hydrograficzna_Raster_10k"]) ? t('map:load') : t('map:loaded')}
                                defaultChecked={mapHasWmsLayer("Mapa hydrograficzna enviDMS 10k")}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter"){
                                        e.target.checked = !wmsAddedToMap["hydrograficzna_Raster_10k"]
                                        toggleButton(ref, "https://mapy.geoportal.gov.pl/wss/service/img/guest/HYDRO/MapServer/WMSServer", "Raster_enviDMS_10k", "Mapa hydrograficzna enviDMS 10k", "hydrograficzna_Raster_10k", opacity["hydrograficzna_Raster_10k"])
                                    }
                                }}
                                onChange={() => 
                                    toggleButton(ref, "https://mapy.geoportal.gov.pl/wss/service/img/guest/HYDRO/MapServer/WMSServer", "Raster_enviDMS_10k", "Mapa hydrograficzna enviDMS 10k", "hydrograficzna_Raster_10k", opacity["hydrograficzna_Raster_10k"])
                                }
                                id="Mapa hydrograficzna enviDMS 10k"
                                name="Mapa hydrograficzna enviDMS 10k"
                            />
                        </td>               
                    </tr>

                    <tr>
                        <td>Mapa hydrograficzna enviDMS 50k</td>
                        <td>
                            <Form.Control 
                                type="number"
                                min="0.1"
                                max="1" 
                                step="0.1"
                                defaultValue="0.6"
                                onChange={(e) => handleSetLayerOpacity(e, "hydrograficzna_Raster_50k")}
                            />
                        </td>
                        <td>
                            <Form.Check 
                                type="checkbox"
                                label={(wmsAddedToMap && !wmsAddedToMap["hydrograficzna_Raster_50k"]) ? t('map:load') : t('map:loaded')}
                                defaultChecked={mapHasWmsLayer("Mapa hydrograficzna enviDMS 50k")}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter"){
                                        e.target.checked = !wmsAddedToMap["hydrograficzna_Raster_50k"]
                                        toggleButton(ref, "https://mapy.geoportal.gov.pl/wss/service/img/guest/HYDRO/MapServer/WMSServer", "Raster_enviDMS_50k", "Mapa hydrograficzna enviDMS 50k", "hydrograficzna_Raster_50k", opacity["hydrograficzna_Raster_50k"])
                                    }
                                }}
                                onChange={() => 
                                    toggleButton(ref, "https://mapy.geoportal.gov.pl/wss/service/img/guest/HYDRO/MapServer/WMSServer", "Raster_enviDMS_50k", "Mapa hydrograficzna enviDMS 50k", "hydrograficzna_Raster_50k", opacity["hydrograficzna_Raster_50k"])
                                }
                                id="Mapa hydrograficzna enviDMS 50k"
                                name="Mapa hydrograficzna enviDMS 50k"
                            />
                        </td>               
                    </tr>
                </tbody>
            </Table>

            <div className="ml-2 mt-2">
                <b>Krajobrazowa Mapa Polski</b>
            </div>       
            <Table striped bordered size="sm" className="ml-2">
                <thead>
                    <tr>
                        <th>{t('map:name')}</th>
                        <th>{t('map:opacity')}</th>
                        <th>{t('map:load')}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Krajobrazowa Mapa Polski</td>
                        <td>
                            <Form.Control 
                                type="number"
                                min="0.1"
                                max="1" 
                                step="0.1"
                                defaultValue="0.6"
                                onChange={(e) => handleSetLayerOpacity(e, "Krajobrazowa")}
                            />
                        </td>
                        <td>
                            <Form.Check 
                                type="checkbox"
                                label={(wmsAddedToMap && !wmsAddedToMap["Krajobrazowa"]) ? t('map:load') : t('map:loaded')}
                                defaultChecked={mapHasWmsLayer("Krajobrazowa")}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter"){
                                        e.target.checked = !wmsAddedToMap["Krajobrazowa"]
                                        toggleButton(ref, "https://mapy.geoportal.gov.pl/wss/service/img/guest/Krajobrazowa/MapServer/WMSServer", "Krajobrazowa", "Krajobrazowa", "Krajobrazowa", opacity["Krajobrazowa"])
                                    }
                                }}
                                onChange={() => 
                                    toggleButton(ref, "https://mapy.geoportal.gov.pl/wss/service/img/guest/Krajobrazowa/MapServer/WMSServer", "Krajobrazowa", "Krajobrazowa", "Krajobrazowa", opacity["Krajobrazowa"])
                                }
                                id="Krajobrazowa"
                                name="Krajobrazowa"
                            />
                        </td>               
                    </tr>
                </tbody>
            </Table>

            <div className="ml-2 mt-2">
                <b>Mapa Sozologiczna Polski</b>
            </div>       
            <Table striped bordered size="sm" className="ml-2">
                <thead>
                    <tr>
                        <th>{t('map:name')}</th>
                        <th>{t('map:opacity')}</th>
                        <th>{t('map:load')}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Sozologiczna</td>
                        <td>
                            <Form.Control 
                                type="number"
                                min="0.1"
                                max="1" 
                                step="0.1"
                                defaultValue="0.6"
                                onChange={(e) => handleSetLayerOpacity(e, "Sozologiczna")}
                            />
                        </td>
                        <td>
                            <Form.Check 
                                type="checkbox"
                                label={(wmsAddedToMap && !wmsAddedToMap["Sozologiczna"]) ? t('map:load') : t('map:loaded')}
                                defaultChecked={mapHasWmsLayer("Sozologiczna")}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter"){
                                        e.target.checked = !wmsAddedToMap["Sozologiczna"]
                                        toggleButton(ref, "https://mapy.geoportal.gov.pl/wss/service/img/guest/SOZO/MapServer/WMSServer", "Raster", "Sozologiczna", "Sozologiczna", opacity["Sozologiczna"])
                                    }
                                }}
                                onChange={() => 
                                    toggleButton(ref, "https://mapy.geoportal.gov.pl/wss/service/img/guest/SOZO/MapServer/WMSServer", "Raster", "Sozologiczna", "Sozologiczna", opacity["Sozologiczna"])
                                }
                                id="Sozologiczna"
                                name="Sozologiczna"
                            />
                        </td>               
                    </tr>
                </tbody>
            </Table>

            <div className="ml-2 mt-2">
                <b>Ortofotomapa</b>
            </div>       
            <Table striped bordered size="sm" className="ml-2">
                <thead>
                    <tr>
                        <th>{t('map:name')}</th>
                        <th>{t('map:opacity')}</th>
                        <th>{t('map:load')}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Ortofotomapa</td>
                        <td>
                            <Form.Control 
                                type="number"
                                min="0.1"
                                max="1" 
                                step="0.1"
                                defaultValue="0.6"
                                onChange={(e) => handleSetLayerOpacity(e, "Ortofotomapa")}
                            />
                        </td>
                        <td>
                            <Form.Check 
                                type="checkbox"
                                label={(wmsAddedToMap && !wmsAddedToMap["Ortofotomapa"]) ? t('map:load') : t('map:loaded')}
                                defaultChecked={mapHasWmsLayer("Ortofotomapa")}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter"){
                                        e.target.checked = !wmsAddedToMap["Ortofotomapa"]
                                        toggleButton(ref, "https://mapy.geoportal.gov.pl/wss/service/img/guest/ORTO/MapServer/WMSServer", "Raster", "Ortofotomapa", "Ortofotomapa", opacity["Ortofotomapa"])
                                    }
                                }}
                                onChange={() => 
                                    toggleButton(ref, "https://mapy.geoportal.gov.pl/wss/service/img/guest/ORTO/MapServer/WMSServer", "Raster", "Ortofotomapa", "Ortofotomapa", opacity["Ortofotomapa"])
                                }
                                id="Ortofotomapa"
                                name="Ortofotomapa"
                            />
                        </td>               
                    </tr>
                </tbody>
            </Table>

            <div className="ml-2 mt-2">
                <b>Dane o charakterze katastralnym</b>
            </div>       
            <Table striped bordered size="sm" className="ml-2">
                <thead>
                    <tr>
                        <th>{t('map:name')}</th>
                        <th>{t('map:opacity')}</th>
                        <th>{t('map:load')}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Powiaty włączone do usługi</td>
                        <td>
                            <Form.Control 
                                type="number"
                                min="0.1"
                                max="1" 
                                step="0.1"
                                defaultValue="0.6"
                                onChange={(e) => handleSetLayerOpacity(e, "powiaty")}
                            />
                        </td>
                        <td>
                            <Form.Check 
                                type="checkbox"
                                label={(wmsAddedToMap && !wmsAddedToMap["powiaty"]) ? t('map:load') : t('map:loaded')}
                                defaultChecked={mapHasWmsLayer("Powiaty włączone do usługi")}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter"){
                                        e.target.checked = !wmsAddedToMap["powiaty"]
                                        toggleButton(ref, "https://mapy.geoportal.gov.pl/wss/service/pub/guest/G2_GO_WMS/MapServer/WMSServer", "powiaty", "Powiaty włączone do usługi", "powiaty", opacity["powiaty"])
                                    }
                                }}
                                onChange={() => 
                                    toggleButton(ref, "https://mapy.geoportal.gov.pl/wss/service/pub/guest/G2_GO_WMS/MapServer/WMSServer", "powiaty", "Powiaty włączone do usługi", "powiaty", opacity["powiaty"])
                                }
                                id="powiaty"
                                name="powiaty"
                            />
                        </td>               
                    </tr>

                    <tr>
                        <td>Powiaty włączone do usługi - obręby kompletne</td>
                        <td>
                            <Form.Control 
                                type="number"
                                min="0.1"
                                max="1" 
                                step="0.1"
                                defaultValue="0.6"
                                onChange={(e) => handleSetLayerOpacity(e, "powiaty_obreby")}
                            />
                        </td>
                        <td>
                            <Form.Check 
                                type="checkbox"
                                label={(wmsAddedToMap && !wmsAddedToMap["powiaty_obreby"]) ? t('map:load') : t('map:loaded')}
                                defaultChecked={mapHasWmsLayer("Powiaty włączone do usługi - obręby kompletne")}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter"){
                                        e.target.checked = !wmsAddedToMap["powiaty_obreby"]
                                        toggleButton(ref, "https://mapy.geoportal.gov.pl/wss/service/pub/guest/G2_GO_WMS/MapServer/WMSServer", "powiaty_obreby", "Powiaty włączone do usługi - obręby kompletne", "powiaty_obreby", opacity["powiaty_obreby"])
                                    }
                                }}
                                onChange={() => 
                                    toggleButton(ref, "https://mapy.geoportal.gov.pl/wss/service/pub/guest/G2_GO_WMS/MapServer/WMSServer", "powiaty_obreby", "Powiaty włączone do usługi - obręby kompletne", "powiaty_obreby", opacity["powiaty_obreby"])
                                }
                                id="powiaty_obreby"
                                name="powiaty_obreby"
                            />
                        </td>               
                    </tr>
                    
                    <tr>
                        <td>Komunikacja systemu EGiB z EKW</td>
                        <td>
                            <Form.Control 
                                type="number"
                                min="0.1"
                                max="1" 
                                step="0.1"
                                defaultValue="0.6"
                                onChange={(e) => handleSetLayerOpacity(e, "ekw")}
                            />
                        </td>
                        <td>
                            <Form.Check 
                                type="checkbox"
                                label={(wmsAddedToMap && !wmsAddedToMap["ekw"]) ? t('map:load') : t('map:loaded')}
                                defaultChecked={mapHasWmsLayer("Komunikacja systemu EGiB z EKW")}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter"){
                                        e.target.checked = !wmsAddedToMap["ekw"]
                                        toggleButton(ref, "https://mapy.geoportal.gov.pl/wss/service/pub/guest/G2_GO_WMS/MapServer/WMSServer", "ekw", "Komunikacja systemu EGiB z EKW", "ekw", opacity["ekw"])
                                    }
                                }}
                                onChange={() => 
                                    toggleButton(ref, "https://mapy.geoportal.gov.pl/wss/service/pub/guest/G2_GO_WMS/MapServer/WMSServer", "ekw", "Komunikacja systemu EGiB z EKW", "ekw", opacity["ekw"])
                                }
                                id="ekw"
                                name="ekw"
                            />
                        </td>               
                    </tr>

                    <tr>
                        <td>Komunikacja systemu EGiB z REGON</td>
                        <td>
                            <Form.Control 
                                type="number"
                                min="0.1"
                                max="1" 
                                step="0.1"
                                defaultValue="0.6"
                                onChange={(e) => handleSetLayerOpacity(e, "regon")}
                            />
                        </td>
                        <td>
                            <Form.Check 
                                type="checkbox"
                                label={(wmsAddedToMap && !wmsAddedToMap["regon"]) ? t('map:load') : t('map:loaded')}
                                defaultChecked={mapHasWmsLayer("Komunikacja systemu EGiB z REGON")}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter"){
                                        e.target.checked = !wmsAddedToMap["regon"]
                                        toggleButton(ref, "https://mapy.geoportal.gov.pl/wss/service/pub/guest/G2_GO_WMS/MapServer/WMSServer", "regon", "Komunikacja systemu EGiB z REGON", "regon", opacity["regon"])
                                    }
                                }}
                                onChange={() => 
                                    toggleButton(ref, "https://mapy.geoportal.gov.pl/wss/service/pub/guest/G2_GO_WMS/MapServer/WMSServer", "regon", "Komunikacja systemu EGiB z REGON", "regon", opacity["regon"])
                                }
                                id="regon"
                                name="regon"
                            />
                        </td>               
                    </tr>

                    <tr>
                        <td>Komunikacja systemu EGiB z PESEL</td>
                        <td>
                            <Form.Control 
                                type="number"
                                min="0.1"
                                max="1" 
                                step="0.1"
                                defaultValue="0.6"
                                onChange={(e) => handleSetLayerOpacity(e, "pesel")}
                            />
                        </td>
                        <td>
                            <Form.Check 
                                type="checkbox"
                                label={(wmsAddedToMap && !wmsAddedToMap["pesel"]) ? t('map:load') : t('map:loaded')}
                                defaultChecked={mapHasWmsLayer("Komunikacja systemu EGiB z PESEL")}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter"){
                                        e.target.checked = !wmsAddedToMap["pesel"]
                                        toggleButton(ref, "https://mapy.geoportal.gov.pl/wss/service/pub/guest/G2_GO_WMS/MapServer/WMSServer", "pesel", "Komunikacja systemu EGiB z PESEL", "pesel", opacity["pesel"])
                                    }
                                }}
                                onChange={() => 
                                    toggleButton(ref, "https://mapy.geoportal.gov.pl/wss/service/pub/guest/G2_GO_WMS/MapServer/WMSServer", "pesel", "Komunikacja systemu EGiB z PESEL", "pesel", opacity["pesel"])
                                }
                                id="pesel"
                                name="pesel"
                            />
                        </td>               
                    </tr>

                    <tr>
                        <td>Zasilenia ZSIN</td>
                        <td>
                            <Form.Control 
                                type="number"
                                min="0.1"
                                max="1" 
                                step="0.1"
                                defaultValue="0.6"
                                onChange={(e) => handleSetLayerOpacity(e, "zsin")}
                            />
                        </td>
                        <td>
                            <Form.Check 
                                type="checkbox"
                                label={(wmsAddedToMap && !wmsAddedToMap["zsin"]) ? t('map:load') : t('map:loaded')}
                                defaultChecked={mapHasWmsLayer("Zasilenia ZSIN")}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter"){
                                        e.target.checked = !wmsAddedToMap["zsin"]
                                        toggleButton(ref, "https://mapy.geoportal.gov.pl/wss/service/pub/guest/G2_GO_WMS/MapServer/WMSServer", "zsin", "Zasilenia ZSIN", "zsin", opacity["zsin"])
                                    }
                                }}
                                onChange={() => 
                                    toggleButton(ref, "https://mapy.geoportal.gov.pl/wss/service/pub/guest/G2_GO_WMS/MapServer/WMSServer", "zsin", "Zasilenia ZSIN", "zsin", opacity["zsin"])
                                }
                                id="zsin"
                                name="zsin"
                            />
                        </td>               
                    </tr>

                    <tr>
                        <td>Granice obrębów</td>
                        <td>
                            <Form.Control 
                                type="number"
                                min="0.1"
                                max="1" 
                                step="0.1"
                                defaultValue="0.6"
                                onChange={(e) => handleSetLayerOpacity(e, "obreby")}
                            />
                        </td>
                        <td>
                            <Form.Check 
                                type="checkbox"
                                label={(wmsAddedToMap && !wmsAddedToMap["obreby"]) ? t('map:load') : t('map:loaded')}
                                defaultChecked={mapHasWmsLayer("Granice obrębów")}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter"){
                                        e.target.checked = !wmsAddedToMap["obreby"]
                                        toggleButton(ref, "https://mapy.geoportal.gov.pl/wss/service/pub/guest/G2_GO_WMS/MapServer/WMSServer", "obreby", "Granice obrębów", "obreby", opacity["obreby"])
                                    }
                                }}
                                onChange={() => 
                                    toggleButton(ref, "https://mapy.geoportal.gov.pl/wss/service/pub/guest/G2_GO_WMS/MapServer/WMSServer", "obreby", "Granice obrębów", "obreby", opacity["obreby"])
                                }
                                id="obreby"
                                name="obreby"
                            />
                        </td>               
                    </tr>

                    <tr>
                        <td>Działki ewidencyjne z powiatów</td>
                        <td>
                            <Form.Control 
                                type="number"
                                min="0.1"
                                max="1" 
                                step="0.1"
                                defaultValue="0.6"
                                onChange={(e) => handleSetLayerOpacity(e, "dzialki")}
                            />
                        </td>
                        <td>
                            <Form.Check 
                                type="checkbox"
                                label={(wmsAddedToMap && !wmsAddedToMap["dzialki"]) ? t('map:load') : t('map:loaded')}
                                defaultChecked={mapHasWmsLayer("Działki ewidencyjne z powiatów")}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter"){
                                        e.target.checked = !wmsAddedToMap["dzialki"]
                                        toggleButton(ref, "https://mapy.geoportal.gov.pl/wss/service/pub/guest/G2_GO_WMS/MapServer/WMSServer", "dzialki", "Działki ewidencyjne z powiatów", "dzialki", opacity["dzialki"])
                                    }
                                }}
                                onChange={() => 
                                    toggleButton(ref, "https://mapy.geoportal.gov.pl/wss/service/pub/guest/G2_GO_WMS/MapServer/WMSServer", "dzialki", "Działki ewidencyjne z powiatów", "dzialki", opacity["dzialki"])
                                }
                                id="dzialki"
                                name="dzialki"
                            />
                        </td>               
                    </tr>

                    <tr>
                        <td>Działki - uzupełnienie z LPIS</td>
                        <td>
                            <Form.Control 
                                type="number"
                                min="0.1"
                                max="1" 
                                step="0.1"
                                defaultValue="0.6"
                                onChange={(e) => handleSetLayerOpacity(e, "geoportal")}
                            />
                        </td>
                        <td>
                            <Form.Check 
                                type="checkbox"
                                label={(wmsAddedToMap && !wmsAddedToMap["geoportal"]) ? t('map:load') : t('map:loaded')}
                                defaultChecked={mapHasWmsLayer("Działki - uzupełnienie z LPIS")}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter"){
                                        e.target.checked = !wmsAddedToMap["geoportal"]
                                        toggleButton(ref, "https://mapy.geoportal.gov.pl/wss/service/pub/guest/G2_GO_WMS/MapServer/WMSServer", "geoportal", "Działki - uzupełnienie z LPIS", "geoportal", opacity["geoportal"])
                                    }
                                }}
                                onChange={() => 
                                    toggleButton(ref, "https://mapy.geoportal.gov.pl/wss/service/pub/guest/G2_GO_WMS/MapServer/WMSServer", "geoportal", "Działki - uzupełnienie z LPIS", "geoportal", opacity["geoportal"])
                                }
                                id="geoportal"
                                name="geoportal"
                            />
                        </td>               
                    </tr>

                    <tr>
                        <td>Budynki</td>
                        <td>
                            <Form.Control 
                                type="number"
                                min="0.1"
                                max="1" 
                                step="0.1"
                                defaultValue="0.6"
                                onChange={(e) => handleSetLayerOpacity(e, "budynki")}
                            />
                        </td>
                        <td>
                            <Form.Check 
                                type="checkbox"
                                label={(wmsAddedToMap && !wmsAddedToMap["budynki"]) ? t('map:load') : t('map:loaded')}
                                defaultChecked={mapHasWmsLayer("budynki")}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter"){
                                        e.target.checked = !wmsAddedToMap["budynki"]
                                        toggleButton(ref, "https://mapy.geoportal.gov.pl/wss/service/pub/guest/G2_GO_WMS/MapServer/WMSServer", "budynki", "budynki", "budynki", opacity["budynki"])
                                    }
                                }}
                                onChange={() => 
                                    toggleButton(ref, "https://mapy.geoportal.gov.pl/wss/service/pub/guest/G2_GO_WMS/MapServer/WMSServer", "budynki", "budynki", "budynki", opacity["budynki"])
                                }
                                id="budynki"
                                name="budynki"
                            />
                        </td>               
                    </tr>

                </tbody>
            </Table>

        </div>
    )
})

const mapStateToProps = state => ({
    wmsAddedToMap: state.map.wmsAddedToMap,
})

const mapDispatchToProps = dispatch => ({
    setWmsAddedToMap: (data) => dispatch(setWmsAddedToMap(data))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(PredefinedWMS)
