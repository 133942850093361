import React, { useState } from "react";
import { useTranslation } from 'react-i18next'
import domtoimage from 'dom-to-image-more';

const MapExporter = props => {
  const { t } = useTranslation([ 'taxondb', 'map', 'common' ])
  const [ filename, setFilename ] = useState('map')

  const hideClasses = [
    "custom-content", //zoom glass
    "leaflet-control-layers", //basemaps button
    "leaflet-draw-section", //draw plugin
    "leaflet-draw-toolbar", //draw plugin
    "leaflet-draw-edit-edit", //draw plugin
    "leaflet-draw-edit-remove", //draw plugin
    "leaflet-control-zoomslider", //zoom slider
    "leaflet-zoom-box-control",
  ]

  const download = () => {
    hideClasses.forEach(c => [].forEach.call(document.querySelectorAll(`.${c}`), function (el) {
      el.style.visibility = 'hidden';
    }));
    const { width, height } = props.map._container.getBoundingClientRect();
    domtoimage.toPng(document.getElementById("gisMap"), { width, height })
      .then(function (dataUrl) {
        var element = document.createElement('a');
        element.setAttribute('href', dataUrl);
        element.setAttribute('download', `${filename}.png`);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        hideClasses.forEach(c => [].forEach.call(document.querySelectorAll(`.${c}`), function (el) {
          el.style.visibility = 'visible';
        }));
      });
  }

  return (
    <div className="d-flex align-items-center my-1">
      <div>{t('map:export-to-png')}</div>
      <input value={filename} className="map-export-input mx-1" onChange={e => setFilename(e.target.value)}></input>
      <button className="map-export-button" onClick={download} title={t('map:export')} disabled={filename.length === 0}></button>
    </div>
  )
}

export default MapExporter
