import React, {useState, useEffect}  from 'react';
import { Map, Marker, TileLayer, Circle } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import {
    MAP_MAX_ZOOM_LEVEL,
    reactMapAttributionString,
} from '../../settings'
import L from 'leaflet';


function SingleMarkerMap(props) {

    const [radiusValue, setRadiusValue] = useState({});
    const [drawCircle, setDrawCircle] = useState(false);

    const position = props.prec ? [parseFloat(props.lat).toFixed(props.prec), parseFloat(props.lon).toFixed(props.prec)] : [parseFloat(props.lat), parseFloat(props.lon)]

    const markerIcon = new L.Icon({
        iconUrl: icon,
        shadowUrl: iconShadow,
        iconSize: [25, 41],
        iconAnchor: [12, 39],
    })

    useEffect(() => {
        if (props.prec < 6 ) setDrawCircle(true);
        if (props.prec === 1) setRadiusValue(66000);
        else if (props.prec === 2) setRadiusValue(6600);
        else if (props.prec === 3) setRadiusValue(660);
        else if (props.prec === 4) setRadiusValue(66);
        else if (props.prec === 5) setRadiusValue(7);
    }, [props.prec]);

    return (
        <Map 
            center={position} 
            zoom={12} 
            className="taxondb-location-map" 
            maxZoom={MAP_MAX_ZOOM_LEVEL}
        >
            <TileLayer
                url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                attribution={reactMapAttributionString}
            />
            <Marker position={position} icon={markerIcon}>
                {/* <Popup>A pretty CSS3 popup.<br />Easily customizable.</Popup> */}
            </Marker>
            {drawCircle &&
                <Circle center={position} radius={radiusValue} />  
            }
        </Map>
    )
}

export default SingleMarkerMap