export default function updateDrawingToolbar(drawRef, polygonStyle, lineStyle){

    if (polygonStyle){
        drawRef.setDrawingOptions({
            rectangle: {
                shapeOptions: {
                    stroke: polygonStyle.stroke,
                    color: polygonStyle.color,
                    opacity: polygonStyle.opacity,
                    weight: polygonStyle.weight,
                    fill: polygonStyle.fill,
                    fillColor: polygonStyle.fillColor,
                    fillOpacity: polygonStyle.fillOpacity
                }
            },
            polygon: {
                shapeOptions: {
                    stroke: polygonStyle.stroke,
                    color: polygonStyle.color,
                    opacity: polygonStyle.opacity,
                    weight: polygonStyle.weight,
                    fill: polygonStyle.fill,
                    fillColor: polygonStyle.fillColor,
                    fillOpacity: polygonStyle.fillOpacity
                }
            },
            circle: {
                shapeOptions: {
                    stroke: polygonStyle.stroke,
                    color: polygonStyle.color,
                    opacity: polygonStyle.opacity,
                    weight: polygonStyle.weight,
                    fill: polygonStyle.fill,
                    fillColor: polygonStyle.fillColor,
                    fillOpacity: polygonStyle.fillOpacity
                }
            }
        });
    }

    if (lineStyle){
        drawRef.setDrawingOptions({
            polyline: {
                shapeOptions: {
                    stroke: lineStyle.stroke,
                    color: lineStyle.color,
                    opacity: lineStyle.opacity,
                    weight: lineStyle.weight,
                    fill: lineStyle.fill,
                    fillColor: lineStyle.fillColor,
                    fillOpacity: lineStyle.fillOpacity
                }
            }
        })
    }

}