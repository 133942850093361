import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import ReactGA from 'react-ga'
import { setInfo, clearSession } from '../actions/user'
import { getDataApi, getDataAnc } from '../actions'
import { useTranslation } from 'react-i18next'
import { loadCollectionTypes } from '../actions/forms'
import {
    TAXONDB_COUNT_REQUEST_ID,
    TAXONDB_COUNT_REQUEST_URL,
    GOOGLE_ANALYTICS_UID
} from '../settings'
import * as notify from '../utils/notify'
import { Button, Modal } from "react-bootstrap";
import * as qs from 'query-string'

ReactGA.initialize(GOOGLE_ANALYTICS_UID, {
    gaOptions: {
        siteSpeedSampleRate: 100
    }
})

const SessionTracker = props => {

    const { i18n } = useTranslation()

    const history = useHistory()
    const puser = useRef(props.user)
    const isLoading = useRef(false)
    const [ show, setShow ] = useState(false)

    const { userInfo, clearSession, clearUser, loadCollectionTypes, loadCount } = props

    useEffect(() => {
        if (window.history.scrollRestoration) {
            window.history.scrollRestoration = 'manual';
        }
        return history.listen((location) => {
            ReactGA.set({ page: location.pathname });
            ReactGA.pageview(location.pathname);
        })
    }, [ history ])

    useEffect(() => {
        localStorage.removeItem('retry')
        localStorage.removeItem('show-retry-modal')
        if (localStorage.getItem('access') !== null) {
            userInfo()
                .then(res => {
                    if (res) {
                        puser.current = JSON.stringify(res.data)
                        notify.success(i18n.t('login_success'))
                        loadCollectionTypes()
                    } else {
                        puser.current = null
                    }
                })
        } else {
            puser.current = null
            clearUser()
        }
        let tickHandler = null

        setTimeout(() => {
            tickHandler = setInterval(() => {
                setShow(localStorage.getItem('show-retry-modal') ? true : false)
                const token = localStorage.getItem('access')
                if (token !== null) {
                    const cuser = localStorage.getItem('user')
                    if (cuser !== puser.current && !isLoading.current) {
                        isLoading.current = true
                        userInfo().then(data => {
                            if (data) {
                                localStorage.setItem('user', JSON.stringify(data.data))
                                puser.current = JSON.stringify(data.data)
                                loadCollectionTypes()
                            } else {
                                //localStorage.removeItem('user')
                                puser.current = null
                            }
                            isLoading.current = false
                        }).catch(err => {
                            localStorage.removeItem('user')
                            puser.current = null
                            isLoading.current = false
                        })
                    }
                } else {
                    puser.current = null
                    clearUser()
                }
            }, 1000)
        }, 1000)

        loadCount()

        const lang = localStorage.getItem('lang')
        const parsed = qs.parse(window.location.search)
        if (parsed.lang === 'en' || parsed.lang === 'pl' || parsed.lang === 'EN' || parsed.lang === 'PL') {
            i18n.changeLanguage(parsed.lang.toLocaleLowerCase())
            localStorage.setItem('lang', parsed.lang.toLocaleLowerCase())
        } else if (lang !== null)
            i18n.changeLanguage(lang)

        return (() => {
            clearInterval(tickHandler)
        })
    }, [ userInfo, i18n, clearSession, clearUser, puser, loadCollectionTypes, loadCount ])

    const retryTimeouts = () => {
        setShow(false)
        localStorage.setItem('retry', true)
        localStorage.removeItem('show-retry-modal')
    }

    const cancelTimeouts = () => {
        setShow(false)
        localStorage.removeItem('retry')
        localStorage.removeItem('show-retry-modal')
    }

    return <Modal
        show={show}
        onHide={cancelTimeouts}
        backdrop="static"
        keyboard={false}
        centered
    >
        <Modal.Header closeButton>
            <Modal.Title>{i18n.t('retry-modal-title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{i18n.t('retry-modal-text')}</Modal.Body>
        <Modal.Footer>
            <Button variant="primary" onClick={retryTimeouts}>{i18n.t('retry-modal-button-retry')}</Button>
            <Button variant="secondary" onClick={cancelTimeouts}>{i18n.t('retry-modal-button-cancel')}</Button>
        </Modal.Footer>
    </Modal>

}

const mapStateToProps = state => ({
    user: state.user ? JSON.stringify(state.user.data) : null,
})

const mapDispatchToProps = dispatch => ({
    userInfo: () => dispatch(getDataApi('userInfo', '/current_user/'))
        .then(data => dispatch(setInfo(data.user)))
        .catch(error => dispatch(clearSession())),
    clearSession: () => dispatch(clearSession()),
    clearUser: () => dispatch(setInfo(null)),
    loadCollectionTypes: () => dispatch(loadCollectionTypes()),
    loadCount: () => dispatch(getDataAnc(TAXONDB_COUNT_REQUEST_ID, TAXONDB_COUNT_REQUEST_URL)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SessionTracker)
