import React, { useState } from "react"
import { useForm, useFieldArray } from 'react-hook-form'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Button, Form, Row, Col, Tabs, Tab, Spinner } from "react-bootstrap"
import {
    ROK_PUBLIKACJI_FIELD_NAME,
    TYP_PUBLIKACJI_FIELD_NAME,
    NUMER_PUBLIKACJI_FIELD_NAME,
    AUTORZY_FIELD_NAME,
    CYTOWANIE_FIELD_NAME,
    TYTUL_PUBLIKACJI_FIELD_NAME,
    SLOWA_KLUCZOWE_FIELD_NAME,
    TYP_PUBLIKACJI_VALUE_1,
    TYP_PUBLIKACJI_VALUE_2,
    TYP_PUBLIKACJI_VALUE_3,
    SPECIMENS_FIELD_NAME,
    SAMPLES_FIELD_NAME,
} from './attributesStructure'
import { postDataAnc } from '../../actions'
import {
    BIBLIODB_CHANGE_REQUEST_ID_PREFIX,
    BIBLIODB_CHANGE_REQUEST_URL,
    USER_GROUPS_EDITOR_CONST,
    GENERAL_EXTENDED_REQUEST_TIMEOUT,
    BIBLIODB_SPECIMENS_REQUEST_ID_PREFIX,
    BIBLIODB_SPECIMENS_REQUEST_URL,
    BIBLIODB_SAMPLES_REQUEST_ID_PREFIX,
    BIBLIODB_SAMPLES_REQUEST_URL,
} from '../../settings'
import Confirm from '../Confirm'
import ValueFromDictionary, { GetValueFromDictionary } from "../dictionary/ValueFromDictionary"
import { getOutput, isPending } from "../../reducers"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons'
import { diff } from 'deep-object-diff'
import * as notify from '../../utils/notify'
import SpecimensTab from "./SpecimensTab"
import SamplesTab from "./SamplesTab"

const AttrValueArray = props =>
    <>
        {props.values.map((value, key) =>
            <span key={key}>{value} </span>
        )}
    </>

const AttrValue = props =>
    <>
        {!props.gps &&
            <>
                {props.data[ props.name ] === true &&
                    <>{props.t('common:true')}</>
                }
                {props.data[ props.name ] === false &&
                    <>{props.t('common:false')}</>
                }
                {props.data[ props.name ] !== true && props.data[ props.name ] !== false &&
                    <>
                        {Array.isArray(props.data[ props.name ]) &&
                            <>
                                {props.data[ props.name ].map((item, indx) =>
                                    <div key={indx}>
                                        {props.directRender &&
                                            <span>{GetValueFromDictionary((props.dictionary ? props.dictionary : props.name), item, props.i18n, props.collectionTypes)}</span>
                                        }
                                        {!props.directRender &&
                                            <>
                                                {item.values && <AttrValueArray values={item.values} {...props} />}
                                                {!item.values && <ValueFromDictionary value={item} {...props} />}
                                            </>
                                        }
                                    </div>
                                )}
                            </>
                        }
                        {!Array.isArray(props.data[ props.name ]) &&
                            <>
                                {props.directRender &&
                                    <span>{GetValueFromDictionary((props.dictionary ? props.dictionary : props.name), props.data[ props.name ], props.i18n, props.collectionTypes)}</span>
                                }
                                {!props.directRender &&
                                    <ValueFromDictionary value={props.data[ props.name ]} {...props}></ValueFromDictionary>
                                }
                            </>
                        }
                    </>
                }
            </>
        }
    </>

const EditAttrValue = props => {
    const { register } = props
    const isDisabled = [ 'numerokazu', 'rodzajgatunek', 'images', 'geodokladnosc', 'kolekcja' ].includes(props.name)
    return (
        <Form.Group className="mb-0">
            <Form.Control name={props.name} size="sm" type="text" ref={register} disabled={isDisabled} isInvalid={props.errors[ props.name ]} autoComplete="off" />
            <Form.Control.Feedback type="invalid">
                {props.errors[ props.name ] ? props.errors[ props.name ][ 0 ] : ''}
            </Form.Control.Feedback>
        </Form.Group>
    )
}

const EditAttrValueArray = props => {
    const { register, control } = props
    const { fields, append, remove, insert } = useFieldArray({ control: control, name: props.name })
    return (<>
        {fields.length > 0 && fields.map((item, index) =>
            <Row key={item.id} className="mb-1" noGutters>
                <Col className="pr-1">
                    <Form.Control name={`${props.name}[${index}].values[0]`} defaultValue={item[ 'values' ][ 0 ]} size="sm" type="text" ref={register()} autoComplete="off" />
                </Col>
                <Col className="px-1">
                    <Form.Control name={`${props.name}[${index}].values[1]`} defaultValue={item[ 'values' ][ 1 ]} size="sm" type="text" ref={register()} autoComplete="off" />
                </Col>
                <Col className="px-1">
                    <Form.Control name={`${props.name}[${index}].values[2]`} defaultValue={item[ 'values' ][ 2 ]} size="sm" type="text" ref={register()} autoComplete="off" />
                </Col>
                <Col md="auto" className="pl-1">
                    <Button size="sm" onClick={() => insert(index + 1, { values: [ '', '', '' ] })} >
                        <FontAwesomeIcon icon={faPlus} title={props.t('common:append')} />
                    </Button>
                    <Button size="sm" className="ml-2" onClick={() => remove(index)}>
                        <FontAwesomeIcon icon={faTrash} title={props.t('common:delete')} />
                    </Button>
                </Col>
            </Row>
        )}
        {fields.length === 0 &&
            <Row className="mb-1" noGutters>
                {Array.from({ length: 3 }).map((_, i) =>
                    <Col className="px-1" key={i}>
                        <Form.Control size="sm" type="text" disabled />
                    </Col>
                )}
                <Col md="auto" className="pl-1">
                    <Button size="sm" onClick={() => append({ values: [ '', '', '' ] })} >
                        <FontAwesomeIcon icon={faPlus} title={props.t('common:append')} />
                    </Button>
                    <Button size="sm" className="ml-2" disabled>
                        <FontAwesomeIcon icon={faTrash} title={props.t('common:delete')} />
                    </Button>
                </Col>
            </Row>
        }
    </>)
}

const AttrName = props =>
    <b>
        {!props.label &&
            <>
                {props.t('bibliodb-attributes:' + props.name)}
            </>
        }
        {props.label &&
            <>
                {props.t('bibliodb-attributes:' + props.label)}
            </>
        }
    </b>

const DivRow = props =>
    <>
        {((!props.array && props.data[ props.name ]) ||
            (props.array && props.data[ props.name ] && props.data[ props.name ].length > 0) ||
            props.editable) &&
            <div className="d-flex" style={{ borderTopStyle: "solid", borderTopColor: "#dee2e6", borderTopWidth: "1px" }}>
                <div className="w-50 p-1">
                    <AttrName {...props}></AttrName>
                </div>
                <div className="w-50 p-1">
                    {!props.editable &&
                        <AttrValue {...props}></AttrValue>
                    }
                    {props.editable && !props.array &&
                        <EditAttrValue {...props}></EditAttrValue>
                    }
                    {props.editable && props.array &&
                        <EditAttrValueArray {...props}></EditAttrValueArray>
                    }
                </div>
            </div>
        }
    </>

const CommonTab = props =>
    <div>
        <DivRow name={TYP_PUBLIKACJI_FIELD_NAME} {...props} dictionary={TYP_PUBLIKACJI_FIELD_NAME}></DivRow>
        <DivRow name={NUMER_PUBLIKACJI_FIELD_NAME} {...props}></DivRow>
        <DivRow name={CYTOWANIE_FIELD_NAME} {...props}></DivRow>
        <DivRow name={AUTORZY_FIELD_NAME} {...props}></DivRow>
        <DivRow name={TYTUL_PUBLIKACJI_FIELD_NAME} {...props}></DivRow>
        <DivRow name={ROK_PUBLIKACJI_FIELD_NAME} {...props}></DivRow>
        {props.data[ TYP_PUBLIKACJI_FIELD_NAME ] === TYP_PUBLIKACJI_VALUE_1 &&
            <>
                <DivRow name="czasopismo_nazwa" {...props}></DivRow>
                <DivRow name="czasopismo_tom" {...props}></DivRow>
                <DivRow name="czasopismo_numer_zeszytu" {...props}></DivRow>
                <DivRow name="czasopismo_strony" {...props}></DivRow>
            </>
        }
        {props.data[ TYP_PUBLIKACJI_FIELD_NAME ] === TYP_PUBLIKACJI_VALUE_2 &&
            <>
                <DivRow name="monografia_wydawca" {...props}></DivRow>
                <DivRow name="monografia_miejsce_wydania" {...props}></DivRow>
                <DivRow name="monografia_seria_wydawnicza" {...props}></DivRow>
                <DivRow name="monografia_tom" {...props}></DivRow>
                <DivRow name="monografia_strony" {...props}></DivRow>
            </>
        }
        {props.data[ TYP_PUBLIKACJI_FIELD_NAME ] === TYP_PUBLIKACJI_VALUE_3 &&
            <>
                <DivRow name="rozdzial_tytul_publikacji" {...props}></DivRow>
                <DivRow name="redaktorzy" {...props}></DivRow>
                <DivRow name="rozdzial_wydawca" {...props}></DivRow>
                <DivRow name="rozdzial_miejsce_wydania" {...props}></DivRow>
                <DivRow name="rozdzial_seria_wydawnicza" {...props}></DivRow>
                <DivRow name="rozdzial_tom" {...props}></DivRow>
                <DivRow name="rozdzial_strony" {...props}></DivRow>
            </>
        }
        <DivRow name={SLOWA_KLUCZOWE_FIELD_NAME} {...props}></DivRow>
        <DivRow name="url" {...props}></DivRow>
        <DivRow name="doi" {...props}></DivRow>
        <DivRow name="jezyk_publikacji" {...props}></DivRow>
        <DivRow name="uwagi" {...props}></DivRow>
        {/* <DivRow name="kolekcja_numer_okazu" {...props}></DivRow> */}
    </div>

const AllTabs = props => {
    const { t, i18n } = useTranslation([ 'bibliodb', 'common', 'bibliodb-attributes', 'forms', 'bibliodb-groups' ])

    const [ key, setKey ] = useState('group-1')

    const [ editable, setEditable ] = useState(false)

    const { control, register, handleSubmit, errors, setError } = useForm({
        defaultValues: props.data
    });

    const onEditableChange = () => {
        setEditable(!editable)
    }

    const onSave = (fields) => {
        props.saveRecord(props.data[ CYTOWANIE_FIELD_NAME ], fields, props.data, t, setError)
    }

    const GROUP_1 = "group-1"
    const GROUP_2 = "group-2"
    const GROUP_3 = "group-3"

    const onTabSelected = key => {
        setKey(key)
        if (key === GROUP_2) {
            props.getSpecimens(props.data[ CYTOWANIE_FIELD_NAME ])
        } else if (key === GROUP_3) {
            props.getSamples(props.data[ CYTOWANIE_FIELD_NAME ])
        }
    }

    const onDeleteBiblio = () => {
        // TODO
    }

    return (
        <>
            {props.data &&
                <>
                    {!props.data[ SPECIMENS_FIELD_NAME ] && !props.data[ SAMPLES_FIELD_NAME ] &&
                        <CommonTab {...props} editable={editable} register={register} control={control} errors={errors} t={t} i18n={i18n}></CommonTab>
                    }
                    {(props.data[ SPECIMENS_FIELD_NAME ] || props.data[ SAMPLES_FIELD_NAME ]) &&
                        <Tabs defaultActiveKey={'group-1'} id={props.requestId} activeKey={key} onSelect={onTabSelected}>
                            <Tab eventKey={GROUP_1} title={t('bibliodb-groups:group-1')}>
                                <CommonTab {...props} editable={editable} register={register} control={control} errors={errors} t={t} i18n={i18n}></CommonTab>
                            </Tab>
                            {props.data[ SPECIMENS_FIELD_NAME ] &&
                                <Tab eventKey={GROUP_2} title={t('bibliodb-groups:group-2')}>
                                    <SpecimensTab editable={editable} register={register} control={control} errors={errors} recordId={props.recordId} cit={props.data[ CYTOWANIE_FIELD_NAME ]}></SpecimensTab>
                                </Tab>
                            }
                            {props.data[ SAMPLES_FIELD_NAME ] &&
                                <Tab eventKey={GROUP_3} title={t('bibliodb-groups:group-3')}>
                                    <SamplesTab editable={editable} register={register} control={control} errors={errors} recordId={props.recordId} cit={props.data[ CYTOWANIE_FIELD_NAME ]}></SamplesTab>
                                </Tab>
                            }
                        </Tabs>
                    }
                    {props.user != null && props.user.groups != null && props.user.groups.includes(USER_GROUPS_EDITOR_CONST) &&
                        <div className="mb-3 pl-2 pr-2">
                            <Form.Check
                                className="d-inline"
                                type="switch"
                                id={`editable-switch-${props.requestId}`}
                                label={t('forms:edit')}
                                checked={editable}
                                onChange={onEditableChange}
                            />
                            {editable && <div className="float-right">
                                <span className="d-inline-block">
                                    <Confirm
                                        onConfirm={onDeleteBiblio}
                                        size="sm"
                                        buttonLabel={t('forms:remove')}
                                        confirmationText={t('bibliodb:confirm-remove-biblio')}
                                        disabled={props.isSaving || true /* TODO: waiting for an endpoint */}
                                        className="ml-1 mr-1"
                                    ></Confirm>
                                </span>
                                <Button onClick={handleSubmit(onSave)} size="sm" disabled={props.isSaving} className="float-right">
                                    {t('forms:save')}
                                    {props.isSaving &&
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            className="ml-1"
                                        />
                                    }
                                </Button>
                            </div>}
                        </div>
                    }
                </>
            }
        </>
    )
}

const mapRecordDetailsOutput = (data) => {
    if (!data) {
        return data
    }

    let copy = { ...data }
    // delete copy[ 'rangataksonunizszegorzedu' ]
    // delete copy[ 'taksonnizszegorzedu' ]
    // delete copy[ 'autortaksonunizszegorzedu' ]

    return copy
}

const mapStateToProps = (state, ownProps) => ({
    user: state.user ? state.user.data : null,
    data: mapRecordDetailsOutput(getOutput(ownProps.requestId, state)),
    isSaving: isPending(BIBLIODB_CHANGE_REQUEST_ID_PREFIX, state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    getSpecimens: cit => dispatch(postDataAnc(
        BIBLIODB_SPECIMENS_REQUEST_ID_PREFIX + ownProps.recordId,
        BIBLIODB_SPECIMENS_REQUEST_URL,
        { cytowanie: cit }
    )),
    getSamples: cit => dispatch(postDataAnc(
        BIBLIODB_SAMPLES_REQUEST_ID_PREFIX + ownProps.recordId,
        BIBLIODB_SAMPLES_REQUEST_URL,
        { cytowanie: cit }
    )),
    saveRecord: (recordId, fields, oldFields, t, setError) => {
        const removeFields = (o, fields) => {
            fields.forEach(field => {
                delete o[ field ]
            })
        }

        // const renameField = (o, oldName, newName) => {
        //     if (oldName in o) {
        //         o[ newName ] = o[ oldName ]
        //         delete o[ oldName ]
        //     }
        // }

        const replaceEmptyStrings = (o) => {
            return JSON.parse(JSON.stringify(o, (k, v) => (typeof v === 'undefined' || v === '') ? null : v))
        }

        let newFields = Object.assign({}, fields)
        let diffFields = diff(oldFields, replaceEmptyStrings(newFields))
        removeFields(diffFields, [ 'numerokazu' ])
        // renameField(diffFields, 'szerokoscgeograficzna', 'szerokoscgeograficzna1')
        dispatch(postDataAnc(
            BIBLIODB_CHANGE_REQUEST_ID_PREFIX,
            BIBLIODB_CHANGE_REQUEST_URL,
            { id: recordId, fields: diffFields },
            GENERAL_EXTENDED_REQUEST_TIMEOUT
        )).then(() => {
            notify.success(t('bibliodb:modified-biblio'))
        }).catch(res => {
            const err = res?.response?.data?.error;
            if (err && err.bibliography) {
                Object.keys(err.bibliography).forEach(k => {
                    setError(k, err.bibliography[ k ]);
                })
            }

            notify.error(t('bibliodb:cannot-modify-biblio'), res?.response?.data?.error?.error || res?.response?.data)
        })
    }
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllTabs)
