import React, { useState } from "react";
import { connect } from 'react-redux'
import { isPending } from '../../reducers'
import { setInfo } from '../../actions/user'
import RedirectLoginWithReturn from "../access/RedirectLoginWithReturn"
import { useTranslation } from 'react-i18next'
import { Container, Table, Button, Collapse, Row, Col, Form, FormLabel, Spinner } from "react-bootstrap"
import { postDataApi } from '../../actions'
import * as notify from '../../utils/notify'
import I18nPathTranslator from "../I18nPathTranslator";
import { PROFILE_PAGE_URL_EN, PROFILE_PAGE_URL_PL } from "../../settings";
import SEO from "../SEO";

const MainContainer = props => {
    const { t } = useTranslation([ 'profile', 'forms', 'common' ])

    const [ open, setOpen ] = useState(false)
    const [ isValid, setIsValid ] = useState(false)
    const [ fields, setFields ] = useState({ new_password: "", new_password_again: "", old_password: "" })
    // const [ gui, setGui ] = useState('default')

    // useEffect(() => {
    //     setGui((props.user && props.user.data && props.user.data.gui) || 'default')
    // }, [ props.user ])

    const handleFormChange = e => {
        const temp = { ...fields }
        temp[ e.target.name ] = e.target.value
        setFields(temp)
        setIsValid(
            Object.keys(fields).every(field => (temp[ field ] && temp[ field ].length > 0)) &&
            temp.new_password === temp.new_password_again
        )
    }

    const submitForm = () => {
        props.change(fields)
            .then(res => {
                if (res.result === 0) {
                    notify.success(t('profile:password_changed'))
                    setOpen(false)
                } else if (res.result === 1) {
                    notify.error(t('profile:wrong_old_password'))
                } else if (res.result === 2) {
                    notify.error(t('profile:wrong_new_password'))
                }
            })
    }

    // const onGuiChanged = (e) => {
    //     setGui(e.target.value)
    // }

    // const onGuiSave = () => {
    //     props.changeGui({ gui: gui }, props.user)
    // }

    return (
        <>
            <I18nPathTranslator {...props} pathEN={PROFILE_PAGE_URL_EN} pathPL={PROFILE_PAGE_URL_PL} />
            <SEO title={t('menu:profile')} />
            {!props.loading && props.user && !props.user.data &&
                <RedirectLoginWithReturn lang={props.lang} returnUrl={props.lang ? t("paths:profile", {lng: props.lang}) : t("paths:profile")} />
            }
            {props.user && props.user.data &&
                <Container className="d-flex flex-column mt-2 justify-content-center">
                    <div className="align-self-center">
                        <Table responsive size="sm" className="profile-table">
                            <tbody>
                                <tr>
                                    <td>{t('forms:username')}</td><td>{props.user.data.username}</td>
                                </tr>
                                <tr>
                                    <td>{t('first-name')}</td><td>{props.user.data.first_name}</td>
                                </tr>
                                <tr>
                                    <td>{t('last-name')}</td><td>{props.user.data.last_name}</td>
                                </tr>
                                <tr>
                                    <td>{t('e-mail')}</td><td>{props.user.data.email}</td>
                                </tr>
                                <tr>
                                    <td>{t('groups')}</td><td>{props.user.data.groups.join(',')}</td>
                                </tr>
                                {/* <tr>
                                    <td>{t('gui')}</td>
                                    <td>
                                        <InputGroup>
                                            <Form.Control size="sm" id="gui" as="select" value={gui} onChange={onGuiChanged} disabled={props.loading || props.loadingChange}>
                                                <option value={USER_GUI_DEFAULT_CONST}>{t('default')}</option>
                                                <option value={USER_GUI_SCIENTIST_CONST}>{t('scientifist')}</option>
                                                <option value={USER_GUI_EDUCATION_CONST}>{t('education')}</option>
                                            </Form.Control>
                                            <InputGroup.Append>
                                                <Button size="sm" variant="primary" disabled={props.loading || props.loadingChange} onClick={onGuiSave}>
                                                    {t('forms:save')}
                                                    {props.loadingChange &&
                                                        <Spinner
                                                            as="span"
                                                            animation="border"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                            className="ml-1"
                                                        />
                                                    }
                                                </Button>
                                            </InputGroup.Append>
                                        </InputGroup>

                                    </td>
                                </tr> */}
                            </tbody>
                        </Table>
                    </div>
                    {props.user && props.user.data && props.user.data.has_usable_password &&
                        <>
                            {!open &&
                                <Button
                                    variant="primary"
                                    onClick={() => setOpen(true)}
                                    aria-controls="change-form"
                                    aria-expanded={open}
                                    size="md"
                                    className="align-self-center"
                                    disabled={props.loadingChange}
                                >{t('change_password')}</Button>
                            }
                            <Collapse in={open} id="change-form">
                                <Form>
                                    <Row>
                                        <Col md={{ span: 4, offset: 4 }}>
                                            <Form.Group>
                                                <FormLabel>{t('forms:old_password')}</FormLabel>
                                                <Form.Control 
                                                    isInvalid={!fields.old_password} 
                                                    required 
                                                    onChange={handleFormChange} 
                                                    value={fields.old_password} 
                                                    name="old_password" 
                                                    type="password" 
                                                    autoComplete="off"
                                                    disabled={props.loadingChange}></Form.Control>
                                            </Form.Group>
                                            <Form.Group>
                                                <FormLabel>{t('forms:new_password')}</FormLabel>
                                                <Form.Control isInvalid={!fields.new_password} 
                                                    required 
                                                    onChange={handleFormChange} 
                                                    value={fields.new_password} 
                                                    name="new_password" 
                                                    type="password" 
                                                    autoComplete="off"
                                                    disabled={props.loadingChange}></Form.Control>
                                            </Form.Group>
                                            <Form.Group>
                                                <FormLabel>{t('forms:new_password_again')}</FormLabel>
                                                <Form.Control 
                                                    isInvalid={!fields.new_password_again || fields.new_password !== fields.new_password_again} 
                                                    required 
                                                    onChange={handleFormChange} 
                                                    value={fields.new_password_again} 
                                                    name="new_password_again" 
                                                    type="password" 
                                                    autoComplete="off"
                                                    disabled={props.loadingChange}></Form.Control>
                                                {fields.new_password !== fields.new_password_again &&
                                                    <Form.Text>{t('forms:passwords_donot_match')}</Form.Text>
                                                }
                                            </Form.Group>
                                            <Button variant="primary" onClick={e => { e.preventDefault(); submitForm() }} disabled={props.loadingChange || !isValid} type="submit">
                                                {t('common:send')}
                                                {props.loadingChange &&
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                        className="ml-1"
                                                    />
                                                }
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Collapse>
                        </>
                    }
                </Container>
            }
        </>
    )
}

const mapStateToProps = state => ({
    user: state.user,
    loading: isPending('userInfo', state),
    loadingChange: isPending('change', state) || isPending('change-gui', state),
})

const mapDispatchToProps = dispatch => ({
    change: data => dispatch(postDataApi('change', '/change_password/', data)),
    changeGui: (data, user) => dispatch(postDataApi('change-gui', '/change_gui/', data)).then(() => {
        dispatch(setInfo({ ...user.data, gui: data.gui }))
    })
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MainContainer)
