import React from "react";
import ReCaptchaContainer from "./ReCaptchaContainer"
import HomeContainer from "./HomeContainer"
import TaxonDBContainer from "./TaxonDBContainer"
import IconDBContainer from "./IconDBContainer"
import ReportsContainer from "./ReportsContainer"
import LoginContainer from "./LoginContainer"
import RegisterForm from "./RegisterForm"
import PasswordReminderForm from "./PasswordReminderForm"
import RegistrationConfirm from "./RegistrationConfirm"
import ResetPassword from "./ResetPassword"
import TaxonSingleRecordDetails from "./taxonDB/SingleRecordDetails"
import IconSingleRecordDetails from "./iconDB/SingleRecordDetails"
import SampleSingleRecordDetails from "./samplesDB/SingleRecordDetails"
import MainProfileContainer from "./profile/MainContainer"
import TeamContainer from "./team/TeamContainer"
import InvitationAccepted from "./team/InvitationAccepted"
import TeamApplication from "./team/TeamApplication"
import MyAlbumContainer from "./profile/MyAlbumContainer"
import ProjectsContainer from "./projects/ProjectsContainer"
import CollectionContainer from "./collections/CollectionContainer"
import Comparator from "./iconDB/Comparator"
import SystematicsContainer from "./systematics/SystematicsContainer"
import ProtectedTaxonContainer from "./protectedTaxon/ProtectedTaxonContainer"
import ProtectedAreaContainer from "./protectedArea/ProtectedAreaContainer"
import MyMaps from "./map/MyMaps"
import Polityka from "./Polityka"
import GDPR from "./GDPR"
import Accessibility from "./Accessibility"
import Regulamin from "./Regulamin"
import AboutUs from "./AboutUs"
import HowToUse from "./HowToUse"
import Contact from "./Contact"
import UsersRoles from "./UsersRoles"
import TaskHistory from "./taskHistory/TaskHistory";
import { Switch, Route } from "react-router-dom"
import MainPresentationContainer from "./presentation/MainContainer"
import {
    TEAM_INVITATION_ACCEPTED_PAGE, TAXONDB_DETAILS_PAGE_URL, ICONDB_DETAILS_PAGE_URL, SAMPLESDB_DETAILS_PAGE_URL, ACCESSIBILITY_PAGE_URL_PL, ACCESSIBILITY_PAGE_URL_EN, 
    GDPR_PAGE_URL_EN, GDPR_PAGE_URL_PL, REGULATIONS_PAGE_URL_EN, PRIVACY_POLICY_PAGE_URL_EN, ABOUT_US_PAGE_URL_EN, HOW_TO_USE_PAGE_URL_EN, CONTACT_PAGE_URL_EN, 
    REGISTRATION_CONFIRM_PAGE_URL_EN, PASSWORD_REMINDER_PAGE_URL_EN, RESET_PASSWORD_PAGE_URL_EN, HEATMAP_PAGE_URL_EN, GROUPING_PAGE_URL_EN, 
    EXCEL_TOOLS_PAGE_URL_EN, STATS_PAGE_URL_EN, STATS_PAGE_URL_PL, GIS_INFO_PAGE_URL_PL, GIS_INFO_PAGE_URL_EN, INFORMATIONS_PAGE_URL_EN, INFORMATIONS_PAGE_URL_PL, 
    MISSION_PAGE_URL_EN, MISSION_PAGE_URL_PL, TAXONDB_PAGE_URL_PL, TAXONDB_PAGE_URL_EN, FILE_REPORTS_PAGE_URL_EN, FILE_REPORTS_PAGE_URL_PL, TAXON_SOURCES_PAGE_URL_EN, 
    TAXON_SOURCES_PAGE_URL_PL, OUR_USERS_PAGE_URL_EN, OUR_USERS_PAGE_URL_PL, MOBILE_INFO_PAGE_URL_PL, MOBILE_INFO_PAGE_URL_EN, SAMPLES_SEARCH_PAGE_URL_PL, 
    SAMPLES_SEARCH_PAGE_URL_EN, BIBLIOGRAPHY_SEARCH_PAGE_URL_PL, BIBLIOGRAPHY_SEARCH_PAGE_URL_EN, SEARCH_PAGE_URL_PL, SEARCH_PAGE_URL_EN, TASK_HISTORY_PAGE_URL_EN, 
    TASK_HISTORY_PAGE_URL_PL, USERS_ROLES_PAGE_URL_PL, USERS_ROLES_PAGE_URL_EN, CONTACT_PAGE_URL_PL, HOW_TO_USE_PAGE_URL_PL, ABOUT_US_PAGE_URL_PL, 
    REGULATIONS_PAGE_URL_PL, PRIVACY_POLICY_PAGE_URL_PL, MY_MAPS_PAGE_URL_PL, MY_MAPS_PAGE_URL_EN, PROTECTED_AREA_PAGE_URL_PL, PROTECTED_AREA_PAGE_URL_EN, PROTECTED_TAXON_PAGE_URL_PL, 
    PROTECTED_TAXON_PAGE_URL_EN, SYSTEMATICS_PAGE_URL_PL, SYSTEMATICS_PAGE_URL_EN, COMPARATOR_PAGE_URL_PL, COMPARATOR_PAGE_URL_EN, COLLECTION_PAGE_URL_EN, COLLECTION_PAGE_URL_PL, 
    TEAM_PAGE_URL_PL, TEAM_PAGE_URL_EN, PROJECTS_PAGE_URL_PL, PROJECTS_PAGE_URL_EN, MY_ALBUM_PAGE_URL_PL, MY_ALBUM_PAGE_URL_EN, PROFILE_PAGE_URL_PL, PROFILE_PAGE_URL_EN, 
    REPORTS_PAGE_URL_PL, REPORTS_PAGE_URL_EN, ICONDB_PAGE_URL_PL, ICONDB_PAGE_URL_EN, REGISTER_PAGE_URL_PL, REGISTER_PAGE_URL_EN, LOGIN_PAGE_URL_PL, LOGIN_PAGE_URL_EN, 
    SITEMAP_PAGE_URL_PL, SITEMAP_PAGE_URL_EN, EDUCATION_MATERIALS_PAGE_URL_PL, EDUCATION_MATERIALS_PAGE_URL_EN, TEAM_LEADERS_PAGE_URL_PL, TEAM_LEADERS_PAGE_URL_EN,
    TEAM_APPLICATION_PAGE, CREATE_RESOURCE_PAGE_URL_PL, CREATE_RESOURCE_PAGE_URL_EN,
    DB_HISTORY_PAGE_URL_PL,
    DB_HISTORY_PAGE_URL_EN,
    EXCEL_TOOLS_PAGE_URL_PL,
    ARRANGEMENT_PAGE_URL_EN, CLUSTERING_PAGE_URL_EN,
    MAP_TOOLS_VISUALIZATION_1,
    MAP_TOOLS_VISUALIZATION_2,
    MAP_TOOLS_VISUALIZATION_3,
    MAP_TOOLS_VISUALIZATION_4,
    MAP_TOOLS_VISUALIZATION_5,
    MAP_TOOLS_VISUALIZATION_6,
    MAP_TOOLS_VISUALIZATION_7,
    CHOROPLETH_PAGE_URL_EN,
    AREA_MAP_PAGE_URL_EN,
    CARTODIAGRAM_PAGE_URL_EN,
    TIMELAPSE_PAGE_URL_EN,
    ARRANGEMENT_PAGE_URL_PL,
    CHOROPLETH_PAGE_URL_PL,
    CLUSTERING_PAGE_URL_PL,
    AREA_MAP_PAGE_URL_PL,
    CARTODIAGRAM_PAGE_URL_PL,
    GROUPING_PAGE_URL_PL,
    TIMELAPSE_PAGE_URL_PL
} from "../settings"
import SearchContainer from "./SearchContainer"
import BibliographySearchContainer from "./BibliographySearchContainer"
import SamplesSearchContainer from "./SamplesSearchContainer"
import GisInfoContainer from "./GisInfoContainer"
import MobileInfoContainer from "./MobileInfoContainer"
import OurUsersInfoContainer from "./OurUsersInfoContainer"
import TaxonSourcesContainer from "./admin/TaxonSourcesContainer";
import FileReportsContainer from "./admin/FileReportsContainer";
import CreateResourceContainer from "./admin/CreateResourceContainer";
//import HistoricalTool from "./map/tools/historical/HistoricalTool"
//import InformationsContainer from "./InformationsContainer"
import MissionContainer from "./MissionContainer"
import StatsContainer from "./StatsContainer"
import ExcelToolsContainer from "./excelTools/ExcelToolsContainer"
import MapTools from "./map/tools/all/MapTools";
import SiteMap from "./SiteMap";
import EducationMaterials from "./EducationMaterials";
import TeamLeaders from "./TeamLeaders";
import NotFound from "./NotFound"
import ConditionalRoute from "./ConditionalRoute"
import useUserRoles from "../hooks/useUserRoles"
import DBHistory from "./DBHistory/DBHistory";
import AboutPortalPL from "./AboutPortalPL";

const Heatmap = React.lazy(() => import("./map/Heatmap"))

export default props => {

    const { isSuperleader, isCoordinator, isEditor } = useUserRoles()

    return (
        <Switch>
            <Route exact path="/" component={HomeContainer}></Route>
            <Route exact path={TAXONDB_DETAILS_PAGE_URL + ":recordId"} component={TaxonSingleRecordDetails}></Route>
            <Route exact path={TAXONDB_PAGE_URL_PL} render={props => <TaxonDBContainer {...props} lang="pl" />}></Route>
            <Route exact path={TAXONDB_PAGE_URL_EN} render={props => <TaxonDBContainer {...props} lang="en" />}></Route>
            <Route exact path={ICONDB_DETAILS_PAGE_URL + ":recordId"} component={IconSingleRecordDetails}></Route>
            <Route exact path={ICONDB_PAGE_URL_PL} render={props => <IconDBContainer {...props} lang="pl" />}></Route>
            <Route exact path={ICONDB_PAGE_URL_EN} render={props => <IconDBContainer {...props} lang="en" />}></Route>
            <ConditionalRoute exact path={REPORTS_PAGE_URL_PL} render={props => <ReportsContainer {...props} lang="pl" />} isGranted={isCoordinator}></ConditionalRoute>
            <ConditionalRoute exact path={REPORTS_PAGE_URL_EN} render={props => <ReportsContainer {...props} lang="en" />} isGranted={isCoordinator}></ConditionalRoute>
            <Route exact path={LOGIN_PAGE_URL_PL} render={props => <LoginContainer {...props} lang="pl" />}></Route>
            <Route exact path={LOGIN_PAGE_URL_EN} render={props => <LoginContainer {...props} lang="en" />}></Route>
            <Route exact path={REGISTER_PAGE_URL_PL} render={props => <ReCaptchaContainer><RegisterForm {...props} lang="pl" /></ReCaptchaContainer>}></Route>
            <Route exact path={REGISTER_PAGE_URL_EN} render={props => <ReCaptchaContainer><RegisterForm {...props} lang="en" /></ReCaptchaContainer>}></Route>
            <Route exact path={PASSWORD_REMINDER_PAGE_URL_EN} render={props => <ReCaptchaContainer><PasswordReminderForm {...props} lang="en" /></ReCaptchaContainer>}></Route>
            <Route exact path={`${REGISTRATION_CONFIRM_PAGE_URL_EN}/:hash`} component={RegistrationConfirm}></Route>
            <Route exact path={`${RESET_PASSWORD_PAGE_URL_EN}/:user_id/:token`} component={ResetPassword}></Route>
            <Route exact path={PROFILE_PAGE_URL_PL} render={props => <MainProfileContainer {...props} lang="pl" />}></Route>
            <Route exact path={PROFILE_PAGE_URL_EN} render={props => <MainProfileContainer {...props} lang="en" />}></Route>
            <Route exact path="/album/presentation/:recordId/:viewedBy" component={MainPresentationContainer} />
            <Route exact path="/album/presentation/:recordId" component={MainPresentationContainer} />
            <Route exact path={MY_ALBUM_PAGE_URL_PL} render={props => <MyAlbumContainer {...props} lang="pl" />}></Route>
            <Route exact path={MY_ALBUM_PAGE_URL_EN} render={props => <MyAlbumContainer {...props} lang="en" />}></Route>
            <Route exact path={PROJECTS_PAGE_URL_PL} render={props => <ProjectsContainer {...props} lang="pl" />}></Route>
            <Route exact path={PROJECTS_PAGE_URL_EN} render={props => <ProjectsContainer {...props} lang="en" />}></Route>
            <Route exact path={TEAM_PAGE_URL_PL} render={props => <TeamContainer {...props} lang="pl" />}></Route>
            <Route exact path={TEAM_PAGE_URL_EN} render={props => <TeamContainer {...props} lang="en" />}></Route>
            <Route exact path={TEAM_INVITATION_ACCEPTED_PAGE} component={InvitationAccepted} />
            <Route exact path={`${TEAM_APPLICATION_PAGE}:id/:hash`} component={TeamApplication} />
            <Route exact path={COLLECTION_PAGE_URL_PL} render={props => <CollectionContainer {...props} lang="pl" />}></Route>
            <Route exact path={COLLECTION_PAGE_URL_EN} render={props => <CollectionContainer {...props} lang="en" />}></Route>
            <Route exact path={COMPARATOR_PAGE_URL_PL} render={props => <Comparator {...props} lang="pl" />}></Route>
            <Route exact path={COMPARATOR_PAGE_URL_EN} render={props => <Comparator {...props} lang="en" />}></Route>
            <Route exact path={SYSTEMATICS_PAGE_URL_PL} render={props => <SystematicsContainer {...props} lang="pl" />}></Route>
            <Route exact path={SYSTEMATICS_PAGE_URL_EN} render={props => <SystematicsContainer {...props} lang="en" />}></Route>
            <ConditionalRoute exact path={PROTECTED_TAXON_PAGE_URL_PL} render={props => <ProtectedTaxonContainer {...props} lang="pl" />} isGranted={isEditor}></ConditionalRoute>
            <ConditionalRoute exact path={PROTECTED_TAXON_PAGE_URL_EN} render={props => <ProtectedTaxonContainer {...props} lang="en" />} isGranted={isEditor}></ConditionalRoute>
            <ConditionalRoute exact path={PROTECTED_AREA_PAGE_URL_PL} render={props => <ProtectedAreaContainer {...props} lang="pl" />} isGranted={isEditor}></ConditionalRoute>
            <ConditionalRoute exact path={PROTECTED_AREA_PAGE_URL_EN} render={props => <ProtectedAreaContainer {...props} lang="en" />} isGranted={isEditor}></ConditionalRoute>
            <ConditionalRoute exact path={CREATE_RESOURCE_PAGE_URL_PL} render={props => <CreateResourceContainer {...props} lang="pl" />} isGranted={isEditor}></ConditionalRoute>
            <ConditionalRoute exact path={CREATE_RESOURCE_PAGE_URL_EN} render={props => <CreateResourceContainer {...props} lang="en" />} isGranted={isEditor}></ConditionalRoute>
            <Route exact path={MY_MAPS_PAGE_URL_PL} render={props => <MyMaps {...props} lang="pl" />}></Route>
            <Route exact path={MY_MAPS_PAGE_URL_EN} render={props => <MyMaps {...props} lang="en" />}></Route>
            <Route exact path={PRIVACY_POLICY_PAGE_URL_PL} render={props => <Polityka {...props} lang="pl" />}></Route>
            <Route exact path={PRIVACY_POLICY_PAGE_URL_EN} render={props => <Polityka {...props} lang="en" />}></Route>
            <Route exact path={REGULATIONS_PAGE_URL_PL} render={props => <Regulamin {...props} lang="pl" />}></Route>
            <Route exact path={REGULATIONS_PAGE_URL_EN} render={props => <Regulamin {...props} lang="en" />}></Route>
            <Route exact path={GDPR_PAGE_URL_PL} render={props => <GDPR {...props} lang="pl" />}></Route>
            <Route exact path={GDPR_PAGE_URL_EN} render={props => <GDPR {...props} lang="en" />}></Route>
            <Route exact path={ACCESSIBILITY_PAGE_URL_PL} render={props => <Accessibility {...props} lang="pl" />}></Route>
            <Route exact path={ACCESSIBILITY_PAGE_URL_EN} render={props => <Accessibility {...props} lang="en" />}></Route>
            <Route exact path={ABOUT_US_PAGE_URL_PL} render={props => <AboutUs {...props} lang="pl" />}></Route>
            <Route exact path={ABOUT_US_PAGE_URL_EN} render={props => <AboutUs {...props} lang="en" />}></Route>
            <Route exact path={HOW_TO_USE_PAGE_URL_PL} render={props => <HowToUse {...props} lang="pl" />}></Route>
            <Route exact path={HOW_TO_USE_PAGE_URL_EN} render={props => <HowToUse {...props} lang="en" />}></Route>
            <Route exact path={CONTACT_PAGE_URL_PL} render={props => <ReCaptchaContainer><Contact {...props} lang="pl" /></ReCaptchaContainer>}></Route>
            <Route exact path={CONTACT_PAGE_URL_EN} render={props => <ReCaptchaContainer><Contact {...props} lang="en" /></ReCaptchaContainer>}></Route>
            <ConditionalRoute exact path={USERS_ROLES_PAGE_URL_PL} render={props => <UsersRoles {...props} lang="pl" />} isGranted={isCoordinator}></ConditionalRoute>
            <ConditionalRoute exact path={USERS_ROLES_PAGE_URL_EN} render={props => <UsersRoles {...props} lang="en" />} isGranted={isCoordinator}></ConditionalRoute>
            <ConditionalRoute exact path={TASK_HISTORY_PAGE_URL_PL} render={props => <TaskHistory {...props} lang="pl" />} isGranted={isEditor}></ConditionalRoute>
            <ConditionalRoute exact path={TASK_HISTORY_PAGE_URL_EN} render={props => <TaskHistory {...props} lang="en" />} isGranted={isEditor}></ConditionalRoute>
            <ConditionalRoute exact path={DB_HISTORY_PAGE_URL_PL} render={props => <DBHistory {...props} lang="pl" />} isGranted={isEditor}></ConditionalRoute>
            <ConditionalRoute exact path={DB_HISTORY_PAGE_URL_EN} render={props => <DBHistory {...props} lang="en" />} isGranted={isEditor}></ConditionalRoute>
            <ConditionalRoute exact path={EXCEL_TOOLS_PAGE_URL_PL} render={props => <ExcelToolsContainer {...props} lang="pl" />} isGranted={isEditor}></ConditionalRoute>
            <ConditionalRoute exact path={EXCEL_TOOLS_PAGE_URL_EN} render={props => <ExcelToolsContainer {...props} lang="en" />} isGranted={isEditor}></ConditionalRoute>
            <Route exact path={SEARCH_PAGE_URL_PL} render={props => <SearchContainer {...props} lang="pl" />}></Route>
            <Route exact path={SEARCH_PAGE_URL_EN} render={props => <SearchContainer {...props} lang="en" />}></Route>
            <Route exact path={BIBLIOGRAPHY_SEARCH_PAGE_URL_PL} render={props => <BibliographySearchContainer {...props} lang="pl" />}></Route>
            <Route exact path={BIBLIOGRAPHY_SEARCH_PAGE_URL_EN} render={props => <BibliographySearchContainer {...props} lang="en" />}></Route>
            <Route exact path={SAMPLESDB_DETAILS_PAGE_URL + ":recordId+"} component={SampleSingleRecordDetails}></Route>
            <Route exact path={SAMPLES_SEARCH_PAGE_URL_PL} render={props => <SamplesSearchContainer {...props} lang="pl" />}></Route>
            <Route exact path={SAMPLES_SEARCH_PAGE_URL_EN} render={props => <SamplesSearchContainer {...props} lang="en" />}></Route>
            <Route exact path={HEATMAP_PAGE_URL_EN} component={Heatmap}></Route>
            <Route exact path={GIS_INFO_PAGE_URL_PL} render={props => <GisInfoContainer {...props} lang="pl" />}></Route>
            <Route exact path={GIS_INFO_PAGE_URL_EN} render={props => <GisInfoContainer {...props} lang="en" />}></Route>
            <Route exact path={INFORMATIONS_PAGE_URL_PL} render={props => <AboutPortalPL {...props} lang="pl" />}></Route>
            <Route exact path={INFORMATIONS_PAGE_URL_EN} render={props => <AboutPortalPL {...props} lang="en" />}></Route>
            {/* <Route exact path={INFORMATIONS_PAGE_URL_EN} render={props => <InformationsContainer {...props} lang="en" />}></Route> */}
            <Route exact path={MISSION_PAGE_URL_PL} render={props => <MissionContainer {...props} lang="pl" />}></Route>
            <Route exact path={MISSION_PAGE_URL_EN} render={props => <MissionContainer {...props} lang="en" />}></Route>
            <Route exact path={MOBILE_INFO_PAGE_URL_PL} render={props => <MobileInfoContainer {...props} lang="pl" />}></Route>
            <Route exact path={MOBILE_INFO_PAGE_URL_EN} render={props => <MobileInfoContainer {...props} lang="en" />}></Route>
            <Route exact path={OUR_USERS_PAGE_URL_PL} render={props => <OurUsersInfoContainer {...props} lang="pl" />}></Route>
            <Route exact path={OUR_USERS_PAGE_URL_EN} render={props => <OurUsersInfoContainer {...props} lang="en" />}></Route>
            <ConditionalRoute exact path={TAXON_SOURCES_PAGE_URL_PL} render={props => <TaxonSourcesContainer {...props} lang="pl" />} isGranted={isEditor}></ConditionalRoute>
            <ConditionalRoute exact path={TAXON_SOURCES_PAGE_URL_EN} render={props => <TaxonSourcesContainer {...props} lang="en" />} isGranted={isEditor}></ConditionalRoute>
            <ConditionalRoute exact path={FILE_REPORTS_PAGE_URL_PL} render={props => <FileReportsContainer {...props} lang="pl" />} isGranted={isEditor}></ConditionalRoute>
            <ConditionalRoute exact path={FILE_REPORTS_PAGE_URL_EN} render={props => <FileReportsContainer {...props} lang="en" />} isGranted={isEditor}></ConditionalRoute>
            <ConditionalRoute exact path={FILE_REPORTS_PAGE_URL_PL} render={props => <CreateResourceContainer {...props} lang="pl" />} isGranted={isEditor}></ConditionalRoute>
            <ConditionalRoute exact path={FILE_REPORTS_PAGE_URL_EN} render={props => <CreateResourceContainer {...props} lang="en" />} isGranted={isEditor}></ConditionalRoute>
            <Route exact path={STATS_PAGE_URL_PL} render={props => <StatsContainer {...props} lang="pl" />}></Route>
            <Route exact path={STATS_PAGE_URL_EN} render={props => <StatsContainer {...props} lang="en" />}></Route>
            
            <Route exact path={ARRANGEMENT_PAGE_URL_EN} render={props => <MapTools {...props} defaultVisualization={MAP_TOOLS_VISUALIZATION_1} lang="en" />}></Route>
            <Route exact path={ARRANGEMENT_PAGE_URL_PL} render={props => <MapTools {...props} defaultVisualization={MAP_TOOLS_VISUALIZATION_1} lang="pl" />}></Route>
            <Route exact path={CHOROPLETH_PAGE_URL_EN} render={props => <MapTools {...props} defaultVisualization={MAP_TOOLS_VISUALIZATION_4} lang="en" />}></Route>
            <Route exact path={CHOROPLETH_PAGE_URL_PL} render={props => <MapTools {...props} defaultVisualization={MAP_TOOLS_VISUALIZATION_4} lang="pl" />}></Route>
            <Route exact path={CLUSTERING_PAGE_URL_EN} render={props => <MapTools {...props} defaultVisualization={MAP_TOOLS_VISUALIZATION_3} lang="en" />}></Route>
            <Route exact path={CLUSTERING_PAGE_URL_PL} render={props => <MapTools {...props} defaultVisualization={MAP_TOOLS_VISUALIZATION_3} lang="pl" />}></Route>
            <Route exact path={AREA_MAP_PAGE_URL_EN} render={props => <MapTools {...props} defaultVisualization={MAP_TOOLS_VISUALIZATION_2} lang="en" />}></Route>
            <Route exact path={AREA_MAP_PAGE_URL_PL} render={props => <MapTools {...props} defaultVisualization={MAP_TOOLS_VISUALIZATION_2} lang="pl" />}></Route>
            <Route exact path={CARTODIAGRAM_PAGE_URL_EN} render={props => <MapTools {...props} defaultVisualization={MAP_TOOLS_VISUALIZATION_5} lang="en" />} ></Route>
            <Route exact path={CARTODIAGRAM_PAGE_URL_PL} render={props => <MapTools {...props} defaultVisualization={MAP_TOOLS_VISUALIZATION_5} lang="pl" />} ></Route>
            <Route exact path={GROUPING_PAGE_URL_EN} render={props => <MapTools {...props} defaultVisualization={MAP_TOOLS_VISUALIZATION_6} lang="en" />}></Route>
            <Route exact path={GROUPING_PAGE_URL_PL} render={props => <MapTools {...props} defaultVisualization={MAP_TOOLS_VISUALIZATION_6} lang="pl" />}></Route>
            <Route exact path={TIMELAPSE_PAGE_URL_EN} render={props => <MapTools {...props} defaultVisualization={MAP_TOOLS_VISUALIZATION_7} lang="en" />}></Route>
            <Route exact path={TIMELAPSE_PAGE_URL_PL} render={props => <MapTools {...props} defaultVisualization={MAP_TOOLS_VISUALIZATION_7} lang="pl" />}></Route>

            <Route exact path={SITEMAP_PAGE_URL_PL} render={props => <SiteMap {...props} lang="pl" />}></Route>
            <Route exact path={SITEMAP_PAGE_URL_EN} render={props => <SiteMap {...props} lang="en" />}></Route>
            <Route exact path={EDUCATION_MATERIALS_PAGE_URL_PL} render={props => <EducationMaterials {...props} lang="pl" />}></Route>
            <Route exact path={EDUCATION_MATERIALS_PAGE_URL_EN} render={props => <EducationMaterials {...props} lang="en" />}></Route>
            <ConditionalRoute exact path={TEAM_LEADERS_PAGE_URL_PL} render={props => <TeamLeaders {...props} lang="pl" />} isGranted={isSuperleader}></ConditionalRoute>
            <ConditionalRoute exact path={TEAM_LEADERS_PAGE_URL_EN} render={props => <TeamLeaders {...props} lang="en" />} isGranted={isSuperleader}></ConditionalRoute>
            <Route exact path="/logout" component={HomeContainer}></Route>
            <Route exact component={NotFound} />
        </Switch>
    )
}
