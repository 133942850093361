import React, { useState } from 'react'
import { API_GET_SIZE_STATS_ID } from '../../settings'
import { isPending } from '../../reducers'
import { connect } from 'react-redux'
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

function IconSizeChart(props) {

    const { t } = useTranslation(['reports'])
    const [mobileDevice] = useState(window.screen.width < 700 ? true : false)

    const options = {
        maintainAspectRatio : true,
        responsive: true,
        elements: {
            line: {
                tension: 0, 
            }
        },
    scales: {
        xAxes: [{
            type: 'time',
            offset: true,
            barThickness: mobileDevice ? 10 : 'flex',
            distribution: 'series',
            ticks:{
                autoSkip: true,
                maxTicksLimit: mobileDevice ? 4 : 15,
                source: 'auto',
            },
            time: {
               unit: mobileDevice ? "day" : props.charts.timeUnit,
               displayFormats: {
                    hour: 'YYYY-MM-DD HH:MM:SS',
                    day: 'YYYY-MM-DD',
                    week: 'YYYY-MM-DD',
                    month: 'YYYY-MM',
                    year: 'YYYY'
                }
            }
        }],
        yAxes: [{
                 display: "auto",
                 stacked: false,
                 ticks: {
                    autoSkip: true,
                    maxTicksLimit: mobileDevice ? 4 : 15,
                    min: 0,
                 }
            }]
        }
    };

    var dataChart

    dataChart = {
        labels: props.charts.datesForSizeReport,
        datasets: [
                {
                    label: `${t('size')} [${t(props.charts.sizeUnit)}]`,
                    data: props.charts.sizeDataToDisplay,
                    backgroundColor: '#CC6600',
                    borderColor: '#613000'
                }
            ]
        };
       
  
    var chart = <Bar data={dataChart} options={options}/> 
    

    return(
        <div className="chart-container">
            {chart}
        </div>
    )
}


const mapStateToProps = state => ({
    charts : state.charts,
    chartLoading: isPending(API_GET_SIZE_STATS_ID, state)
  })
  
  const mapDispatchToProps = dispatch => ({

  })
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IconSizeChart)