//import { notifyRequestError } from './index'

export const CHART_INFO = 'CHART_INFO'
export const SET_CHART_TYPE = 'SET_CHART_TYPE'
export const SET_PRESENTATION_TYPE = 'SET_PRESENTATION_TYPE'
export const SET_INTERVAL = 'SET_INTERVAL'
export const SET_PERIOD = 'SET_PERIOD'

export const SET_MONTH_FROM = 'SET_MONTH_FROM'
export const SET_MONTH_TO = 'SET_MONTH_TO'
export const SET_DAY_FROM = 'SET_DAY_FROM'
export const SET_DAY_TO = 'SET_DAY_TO'

export const SET_DAYS_SCOPE = 'SET_DAYS_SCOPE'

export const SET_DAYS = 'SET_DAYS'
export const SET_MONTHS = 'SET_MONTHS'
export const SET_VISITS = 'SET_VISITS'
export const SET_VISITORS = 'SET_VISITORS'
export const SET_USERS = 'SET_USERS'

export const SET_DISPLAYED_DATA = 'SET_DISPLAYED_DATA'
export const SET_MONTHLY_DATA = 'SET_MONTHLY_DATA'

export const SET_COLLECTIONS = 'SET_COLLECTIONS'
export const SET_DISPLAYED_COLLECTION = 'SET_DISPLAYED_COLLECTION'
export const SET_TIME_DISTRIBUTION = 'SET_TIME_DISTRIBUTION'
export const SET_BOTANICAL_COLLECTION_NAMES = 'SET_BOTANICAL_COLLECTION_NAMES'
export const SET_ZOOLOGICAL_COLLECTION_NAMES = 'SET_ZOOLOGICAL_COLLECTION_NAMES'
export const SET_COLLECTIONS_WITHOUT_TYPES = 'SET_COLLECTIONS_WITHOUT_TYPES'

export const SET_ICON_SIZE_DATA = 'SET_ICON_SIZE_DATA'
export const SET_TIME_UNIT = 'SET_TIME_UNIT'
export const SET_SIZE_UNIT = 'SET_SIZE_UNIT'

export const SET_MONTHLY_DOWNLOADS_DATA = 'SET_MONTHLY_DOWNLOADS_DATA'
export const SET_DAILY_DOWNLOADS_DATA = 'SET_DAILY_DOWNLOADS_DATA'

export const chartInfo = data => ({
    type: CHART_INFO,
    data: data
})

export const setChartType = data => ({
    type: SET_CHART_TYPE,
    data: data
})

export const setDaysScope = data => ({
    type: SET_DAYS_SCOPE, 
    data: data
})

export const setPresentationType = data => ({
    type: SET_PRESENTATION_TYPE,
    data: data
})

export const setDisplayedData = data => ({
    type: SET_DISPLAYED_DATA,
    data: data
})

export const setInterval = data => ({
    type: SET_INTERVAL,
    data: data
})

export const setMonthlyData = data => ({
    type: SET_MONTHLY_DATA,
    data: data
})

export const setCollections = data => ({
    type: SET_COLLECTIONS,
    data: data
})

export const setDisplayedCollection = data => ({
    type: SET_DISPLAYED_COLLECTION,
    data: data
})

export const setTimeDistribution = data => ({
    type: SET_TIME_DISTRIBUTION,
    data: data
})

export const setBotanicalCollectionNames = data => ({
    type: SET_BOTANICAL_COLLECTION_NAMES,
    data: data
})

export const setZoologicalCollectionNames = data => ({
    type: SET_ZOOLOGICAL_COLLECTION_NAMES,
    data: data
})

export const setCollectionsWithoutTypes = data => ({
    type: SET_COLLECTIONS_WITHOUT_TYPES,
    data: data
})

export const setIconSizeData = data => ({
    type: SET_ICON_SIZE_DATA,
    data: data
})

export const setTimeUnit = data => ({
    type: SET_TIME_UNIT,
    data: data
})

export const setSizeUnit = data => ({
    type: SET_SIZE_UNIT,
    data: data
})

export const setMonthlyDownloadsData = data => ({
    type: SET_MONTHLY_DOWNLOADS_DATA,
    data: data
})

export const setDailyDownloadsData = data => ({
    type: SET_DAILY_DOWNLOADS_DATA,
    data: data
})

/*
export const initChart = (data) => (
    dispatch => {
        dispatch(chartInfo(data))
            .catch(error => { 
                    dispatch(notifyRequestError(error))
                    }
                )
    }
)
*/
export const calculateDaysBehind = (numberOfDaysBehind) => {
    let endDate = new Date();
    let endDateString = `${endDate.getFullYear()}-${('0' + (endDate.getMonth()+1)).slice(-2)}-${('0' + endDate.getDate()).slice(-2)}`
    
    let startDate = new Date();
    startDate.setDate(startDate.getDate() - numberOfDaysBehind ) 
    let startDateString = `${startDate.getFullYear()}-${('0' + (startDate.getMonth()+1)).slice(-2)}-${('0' + startDate.getDate()).slice(-2)}`

    return {
        "dayFrom" : startDateString,
        "dayTo" : endDateString
    } 
}


export const handleCollectionsData = (response) => {

    let botanicalCollectionNames = new Set();
    let zoologicalCollectionNames = new Set();
    let botanicalCollections = new Map();
    let zoologicalCollection = new Map();
    let datesBotanical = new Set();
    let datesZoological = new Set();
    let NHC_ZOO_total
    let NHC_BOT_total

    if (response.taxonStats["NHC-ZOO"] && response.taxonStats["NHC-ZOO"].details){
        response.taxonStats["NHC-ZOO"].details.forEach((item) => {
            zoologicalCollectionNames.add(item[0])
        })

        zoologicalCollectionNames.forEach((item => {
            zoologicalCollection.set(item, [])
        }))

        response.taxonStats["NHC-ZOO"].details.forEach((item) => {
            let temp = zoologicalCollection.get(item[0])
            if (temp){
                temp.push(item)
                zoologicalCollection.set(item[0], temp)
            }
        })

        response.taxonStats["NHC-ZOO"].total.forEach((item) => {
            datesZoological.add(item[0])
        })
        
        NHC_ZOO_total = response.taxonStats["NHC-ZOO"].total
    }

    if (response.taxonStats["NHC-BOT"] && response.taxonStats["NHC-BOT"].details){
        response.taxonStats["NHC-BOT"].details.forEach((item) => {
            botanicalCollectionNames.add(item[0])
        })
    
        botanicalCollectionNames.forEach((item => {
            botanicalCollections.set(item, [])
        }))
        
    
        response.taxonStats["NHC-BOT"].details.forEach((item) => {
            let temp = botanicalCollections.get(item[0])
            if (temp){
                temp.push(item)
                botanicalCollections.set(item[0], temp)
            }
        })

    
        response.taxonStats["NHC-BOT"].total.forEach((item) => {
            datesBotanical.add(item[0])
        })

        NHC_BOT_total = response.taxonStats["NHC-BOT"].total
    }
    
    let totalImports = {
        "NHC_ZOO_total" : NHC_ZOO_total,
        "NHC_BOT_total" : NHC_BOT_total 
    }

    return {
        "NHC_BOT" : botanicalCollections,
        "NHC_ZOO" : zoologicalCollection,
        "dates_NHC_BOT" : Array.from(datesBotanical.values()),
        "dates_NHC_ZOO" : Array.from(datesZoological.values()),
        "botanicalCollectionNames" : botanicalCollectionNames,
        "zoologicalCollectionNames" : zoologicalCollectionNames,
        "displayedCollection" : "TOTAL-IMPORTS",
        "timeDistribution" : "series",
        "allZooNames" : new Set(zoologicalCollectionNames),
        "allBotNames" : new Set(botanicalCollectionNames),
        "totalImports" : totalImports
    }
}