import React from 'react';
import {
    DATA_ZEBRANIA_ATTRIBUTE_NAME,
    RODZAJ_GATUNEK_ATTRIBUTE_NAME,
    GENUS_ATTRIBUTE_NAME,
} from '../taxonDB/attributesStructure'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Form, Col, Row } from  "react-bootstrap"; 
import { addMapLabel, removeMapLabel, clearMapLabels } from '../../actions/map'

const MarkerLabels = props => {

    const { t } = useTranslation(['map', 'taxondb-attributes'])
    const { mapLabels, removeMapLabel, addMapLabel } = {...props}

    const checkIfContains = (value) => {
        if (mapLabels) return mapLabels.includes(value)
    }

    return(
    <>
        <Row>
            <Col>
                <Form onChange={e => {
                        if(e.target.checked){
                            addMapLabel(e.target.value)
                        }
                        else{
                            removeMapLabel(e.target.value)
                        }
                    }}>
                    <Form.Check value="kolekcjanumerokazu" defaultChecked={checkIfContains("kolekcjanumerokazu")} id="kolekcjanumerokazu-label" label={t('taxondb-attributes:kolekcjanumerokazu')}/>
                    <Form.Check value={GENUS_ATTRIBUTE_NAME} defaultChecked={checkIfContains(GENUS_ATTRIBUTE_NAME)} id={`${GENUS_ATTRIBUTE_NAME}-label`} label={t('taxondb-attributes:' + GENUS_ATTRIBUTE_NAME)}/>
                    <Form.Check value={RODZAJ_GATUNEK_ATTRIBUTE_NAME} defaultChecked={checkIfContains(RODZAJ_GATUNEK_ATTRIBUTE_NAME)} id={`${RODZAJ_GATUNEK_ATTRIBUTE_NAME}-label`} label={t('taxondb-attributes:' + RODZAJ_GATUNEK_ATTRIBUTE_NAME) }/>
                    <Form.Check value="jednostkanadrzedna" defaultChecked={checkIfContains("jednostkanadrzedna")} id="jednostkanadrzedna-label" label={t('taxondb-attributes:jednostkanadrzedna')}/>
                    <Form.Check value="autorzbioru" defaultChecked={checkIfContains("autorzbioru")} id="autorzbioru-label" label={t('taxondb-attributes:autorzbioru')}/>
                    <Form.Check value={DATA_ZEBRANIA_ATTRIBUTE_NAME} defaultChecked={checkIfContains(DATA_ZEBRANIA_ATTRIBUTE_NAME)} id={`${DATA_ZEBRANIA_ATTRIBUTE_NAME}-label`} label={t('taxondb-attributes:' + DATA_ZEBRANIA_ATTRIBUTE_NAME) }/>
                    <Form.Check value="autorgatunku" defaultChecked={checkIfContains("autorgatunku")} id="autorgatunku-label" label={t('taxondb-attributes:autorgatunku')}/>
                    <Form.Check value="rangajednostkinadrzednej" defaultChecked={checkIfContains("rangajednostkinadrzednej")} id="rangajednostkinadrzednej-label" label={t('taxondb-attributes:rangajednostkinadrzednej')}/>
                    <Form.Check value="zrodlo" defaultChecked={checkIfContains("zrodlo")} id="zrodlo-label" label={t('taxondb-attributes:zrodlo')}/>
                    <Form.Check value="instytucja" defaultChecked={checkIfContains("instytucja")} id="instytucja-label" label={t('taxondb-attributes:instytucja')}/>
                    <Form.Check value="opisuwagi" defaultChecked={checkIfContains("opisuwagi")} id="opisuwagi-label" label={t('taxondb-attributes:opisuwagi')}/>
                    <Form.Check value="pochodzenieokazu" defaultChecked={checkIfContains("pochodzenieokazu")} id="pochodzenieokazu-label" label={t('taxondb-attributes:pochodzenieokazu')}/>
                    <Form.Check value="bibliografia" defaultChecked={checkIfContains("bibliografia")} id="bibliografia-label" label={t('taxondb-attributes:bibliografia')}/>
                </Form>
            </Col>
            <Col>
                    <Form onChange={e => {
                        if(e.target.checked){
                            addMapLabel(e.target.value)
                        }
                        else{
                            removeMapLabel(e.target.value)
                        }
                    }}>
                    <Form.Check value="panstwo" defaultChecked={checkIfContains("panstwo")} id="panstwo-label" label={t('taxondb-attributes:panstwo')}/>
                    <Form.Check value="georeferencjakomentarze" defaultChecked={checkIfContains("georeferencjakomentarze")} id="georeferencjakomentarze-label" label={t('taxondb-attributes:georeferencjakomentarze')} />
                    <Form.Check value="gmina" defaultChecked={checkIfContains("gmina")} id="gmina-label" label={t('taxondb-attributes:gmina')}/>
                    <Form.Check value="koditypobszarunatura2000" defaultChecked={checkIfContains("koditypobszarunatura2000")} id="koditypobszarunatura2000-label" label={t('taxondb-attributes:koditypobszarunatura2000')}/>
                    <Form.Check value="kontynent" defaultChecked={checkIfContains("kontynent")} id="kontynent-label" label={t('taxondb-attributes:kontynent')}/>
                    <Form.Check value="nazwaobszarunatura2000" defaultChecked={checkIfContains("nazwaobszarunatura2000")} id="nazwaobszarunatura2000-label" label={t('taxondb-attributes:nazwaobszarunatura2000')}/>
                    <Form.Check value="numerzbioruokreslonegoautora" defaultChecked={checkIfContains("numerzbioruokreslonegoautora")} id="numerzbioruokreslonegoautora-label" label={t('taxondb-attributes:numerzbioruokreslonegoautora')}/>
                    <Form.Check value="obszarchronionegokrajobrazu" defaultChecked={checkIfContains("obszarchronionegokrajobrazu")} id="obszarchronionegokrajobrazu-label" label={t('taxondb-attributes:obszarchronionegokrajobrazu')}/>
                    <Form.Check value="obszarchroniony" defaultChecked={checkIfContains("obszarchroniony")} id="obszarchroniony-label" label={t('taxondb-attributes:obszarchroniony')}/>
                    <Form.Check value="parkkrajobrazowy" defaultChecked={checkIfContains("parkkrajobrazowy")} id="parkkrajobrazowy-label" label={t('taxondb-attributes:parkkrajobrazowy')}/>
                    <Form.Check value="parknarodowy" defaultChecked={checkIfContains("parknarodowy")} id="parknarodowy-label" label={t('taxondb-attributes:parknarodowy')}/>
                    <Form.Check value="polozeniewpodzialebiogeograficznymeuropy" defaultChecked={checkIfContains("polozeniewpodzialebiogeograficznymeuropy")} id="polozeniewpodzialebiogeograficznymeuropy-label" label={t('taxondb-attributes:polozeniewpodzialebiogeograficznymeuropy')}/>
                    <Form.Check value="polozeniewpodzialefizjograficznym" defaultChecked={checkIfContains("polozeniewpodzialefizjograficznym")} id="polozeniewpodzialefizjograficznym-label" label={t('taxondb-attributes:polozeniewpodzialefizjograficznym')}/>
                    <Form.Check value="polozeniewpodzialegeograficznympotencjalnejroslinnoscinaturalne" defaultChecked={checkIfContains("polozeniewpodzialegeograficznympotencjalnejroslinnoscinaturalne")} id="polozeniewpodzialegeograficznympotencjalnejroslinnoscinaturalne-label" label={t('taxondb-attributes:polozeniewpodzialegeograficznympotencjalnejroslinnoscinaturalne')}/>
                    <Form.Check value="powiat" defaultChecked={checkIfContains("powiat")} id="powiat-label" label={t('taxondb-attributes:powiat')}/>
                    <Form.Check value="rezerwatprzyrody" defaultChecked={checkIfContains("rezerwatprzyrody")} id="rezerwatprzyrody-label" label={t('taxondb-attributes:rezerwatprzyrody')}/>
                    <Form.Check value="rodzajityprezerwatu" defaultChecked={checkIfContains("rodzajityprezerwatu")} id="rodzajityprezerwatu-label" label={t('taxondb-attributes:rodzajityprezerwatu')}/>
                    <Form.Check value="siedlisko" defaultChecked={checkIfContains("siedlisko")} id="siedlisko-label" label={t('taxondb-attributes:siedlisko')}/>
                    <Form.Check value="uzytekekologiczny" defaultChecked={checkIfContains("uzytekekologiczny")} id="uzytekekologiczny-label" label={t('taxondb-attributes:uzytekekologiczny')}/>
                    <Form.Check value="wojewodztwo" defaultChecked={checkIfContains("wojewodztwo")} id="wojewodztwo-label" label={t('taxondb-attributes:wojewodztwo')}/>
                    <Form.Check value="zespolprzyrodniczokrajobrazowy" defaultChecked={checkIfContains("zespolprzyrodniczokrajobrazowy")} id="zespolprzyrodniczokrajobrazowy-label" label={t('taxondb-attributes:zespolprzyrodniczokrajobrazowy')} />
                </Form>
            </Col>
        </Row>
    </>
    )
}

const mapStateToProps = (state, ownProps) => ({
    mapLabels: state.map.mapLabels
})

const mapDispatchToProps = dispatch => ({
    addMapLabel: (label) => dispatch(addMapLabel(label)),
    removeMapLabel:(label) => dispatch(removeMapLabel(label)),
    clearMapLabels: () => dispatch(clearMapLabels()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MarkerLabels)