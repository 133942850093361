import React from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getField } from "../reducers";
import { setField } from '../actions/forms'
import "../styles/react-autosuggest.scss"
import { GetValueFromDictionary } from "./dictionary/ValueFromDictionary"
import { setElementStructure, getElementStructure } from '../utils/search'
import {
    DICTIONARY_COLLECTION_TYPES,
    COMMON_DICTIONARIES,
} from '../settings'


const InputSimpleSelect = props => {
    const { i18n } = useTranslation(['common', 'taxondb', 'taxondb-attributes'])

    return (
        <select
            id={props.id}
            name={props.id}
            disabled={props.disabled}
            onChange={e => {
                if (props.onChange) {
                    props.onChange(e)
                }
                props.setSelectedValue(e.target.value, props.fields)
            }}
            onKeyUp={props.onKeyUp}
            value={props.selectedValue}
            className='form-control'
            autoComplete="off"
        >
            {!props.allowedValues.find(obj => obj.value === '') &&
                <option value=""></option>
            }
            {props.allowedValues.map((val, indx) =>
                <option key={indx} value={val.value}>
                    {GetValueFromDictionary(props.name, val.value, i18n, props.collectionTypes, true)}
                </option>
            )}
        </select>
    )
}

const mapStateToProps = (state, ownProps) => ({
    selectedValue: ownProps.path ?
        getElementStructure(ownProps.path,
            JSON.parse(getField(ownProps.formId, ownProps.fieldId ? ownProps.fieldId : ownProps.formId, state, "null"))) :
        getField(ownProps.formId, ownProps.id, state),
    fields: JSON.parse(getField(ownProps.formId, ownProps.fieldId ? ownProps.fieldId : ownProps.formId, state, "null")),
    collectionTypes: getField(COMMON_DICTIONARIES, DICTIONARY_COLLECTION_TYPES, state, []),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    setSelectedValue: (value, fields) =>
        ownProps.path ?
            dispatch(setField(ownProps.formId, ownProps.fieldId ? ownProps.fieldId : ownProps.formId, JSON.stringify(setElementStructure(ownProps.path, fields, value)))) :
            dispatch(setField(ownProps.formId, ownProps.id, value)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InputSimpleSelect)
