import _ from 'lodash';

import * as states from '../actions/state';

const stateReducer = (previousState, action) => {
  if (!Object.values(states).includes(action.type)) {
    return previousState || {};
  }
  const state = _.cloneDeep(previousState || {});
  switch (action.type) {
  case states.SET_STATE_VALUE:
    state[action.id] = action.value;
    break;
  case states.CLEAR_STATE:
    delete state[action.id];
    break;
  default:
  }
  return state;
};
export default stateReducer;
