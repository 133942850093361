import React, { useState } from "react";
import { Navbar, Nav, Button, DropdownButton, Dropdown, Spinner, Container } from "react-bootstrap";
import { connect } from 'react-redux'
import { logout } from '../actions/user'
import { useTranslation } from 'react-i18next'
import { NavLink } from "react-router-dom"
import { isPending } from "../reducers"
import {
    FONT_SIZE_SCALE_1,
    FONT_SIZE_SCALE_2,
    FONT_SIZE_SCALE_3,
    MAP_TOOLS_ACTIVE_VISUALIZATION
} from '../settings'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { MAIN_MENU, MAIN_MENU2 } from "./MenuContent";
import useUserRoles from "../hooks/useUserRoles"
import { setField } from "../actions/forms";
import { setLoadObservationsOnInit } from "../actions/map";


const Menu = props => {

    const { t, i18n } = useTranslation(['common', 'menu', 'paths'])

    const [exp, setExp] = useState(false)
    const [exp2, setExp2] = useState(false)
    const [currentFont, setCurrentFont] = useState(1)
    const [ key, setKey ] = useState(0)

    let { isSuperleader, isCoordinator, isEditor, hasRole, shortName, isValidator, ShowWhenLoggedIn, ShowWhenNotLoggedIn } = useUserRoles()

    const changeLanguage = lng => {
        i18n.changeLanguage(lng)
        localStorage.setItem('lang', lng)
    }


    const changeFont = () => {
        switch (window.document.documentElement.style.fontSize) {
            case FONT_SIZE_SCALE_2: window.document.documentElement.style.fontSize = FONT_SIZE_SCALE_3
                setCurrentFont(3)
                break
            case FONT_SIZE_SCALE_3: window.document.documentElement.style.fontSize = FONT_SIZE_SCALE_1
                setCurrentFont(1)
                break
            default: window.document.documentElement.style.fontSize = FONT_SIZE_SCALE_2
                setCurrentFont(2)
                break
        }
    }

    const tOrStr = (str) => {
        if (!str)
            return ""
        if (str.includes(':'))
            return t(str)
        else
            return str
    }

    const ADMIN_MENU = MAIN_MENU2.adminMenu.submenu
    const MY_AMUNATCOLL_MENU = MAIN_MENU2.myAmunatcoll.submenu
    const PROFILE_MENU = MAIN_MENU2.profile.submenu
    const SEARCH_MENU = MAIN_MENU.searchEngines.submenu
    const ABOUT_MENU = MAIN_MENU.about.submenu
    const TOOLS_MENU = MAIN_MENU.tools.submenu

    return (
        <div className="app-menu" id="app-menu">
            <div className="first-row-menu">
                <Container key={key}>
                    <Navbar expand="sm" expanded={exp2} onToggle={e => { }}>
                        <Navbar.Collapse id="main-menu2">
                            <Nav className="ml-auto">
                                <ShowWhenLoggedIn>
                                    {(isEditor || isSuperleader || isCoordinator || isValidator) &&
                                        <Nav.Item >
                                            <DropdownButton variant="secondary" title={t(MAIN_MENU2.adminMenu.name)}>
                                                {ADMIN_MENU.map((item, key) => <React.Fragment key={key}>
                                                    {hasRole(item.role) &&
                                                        <>
                                                            {item.isHeader &&
                                                                <Dropdown.Item className={item.className} onClick={() => {
                                                                    setExp2(false)
                                                                }}>{t(item.name)}</Dropdown.Item>
                                                            }
                                                            {!item.isHeader &&
                                                                <Dropdown.Item as={NavLink} className={item.className} to={tOrStr(item.path)} onClick={() => {
                                                                    setExp2(false)
                                                                }}>{t(item.name)}</Dropdown.Item>
                                                            }
                                                        </>
                                                    }
                                                </React.Fragment>
                                                )}
                                            </DropdownButton>
                                        </Nav.Item>
                                    }
                                    <Nav.Item className="mx-1">
                                        <DropdownButton variant="secondary" title={t(MAIN_MENU2.myAmunatcoll.name)}>
                                            {MY_AMUNATCOLL_MENU.map((item, key) =>
                                                <Dropdown.Item key={key} as={NavLink} to={tOrStr(item.path)} onClick={() => {
                                                    setExp2(false)
                                                }}>{t(item.name)}</Dropdown.Item>
                                            )}
                                        </DropdownButton>
                                    </Nav.Item>
                                    <FontAwesomeIcon icon={faUserCircle} className="user-icon ml-1" size="lg" />
                                    <Nav.Item>
                                        <DropdownButton variant="secondary" title={shortName}>
                                            <Dropdown.Item as={NavLink} to={tOrStr(PROFILE_MENU.profile.path)} onClick={() => {
                                                setExp2(false)
                                            }}>{t(PROFILE_MENU.profile.name)}</Dropdown.Item>
                                            <Dropdown.Item as={NavLink} to={tOrStr(PROFILE_MENU.logout.path)} onClick={() => {
                                                props.userLogout().then(() => {
                                                    setKey(v => ++v)
                                                })
                                                setExp2(false)
                                            }}>{t(PROFILE_MENU.logout.name)}</Dropdown.Item>
                                        </DropdownButton>
                                    </Nav.Item>
                                </ShowWhenLoggedIn>
                                <ShowWhenNotLoggedIn>
                                    <FontAwesomeIcon icon={faUserCircle} className="user-icon ml-1" size="lg" />
                                    <Navbar.Text>
                                        {props.loading &&
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                className="ml-1"
                                            />
                                        }
                                    </Navbar.Text>
                                    <Nav.Item>
                                        <Nav.Link as={NavLink} to={tOrStr(PROFILE_MENU.login.path)} onClick={() => setExp2(false)} className="dropdown-toggle btn btn-secondary">{t(PROFILE_MENU.login.name)}</Nav.Link>
                                    </Nav.Item>
                                </ShowWhenNotLoggedIn>
                                <Nav.Item>
                                    {i18n.languages[0] === 'pl' &&
                                        <Button onClick={() => {
                                            changeLanguage('en')
                                            setExp2(false)
                                        }} variant="secondary" active={i18n.languages[0] === 'en'} aria-label="Zmień język na angielski"><span aria-hidden="true">EN</span></Button>
                                    }
                                    {i18n.languages[0] === 'en' &&
                                        <Button onClick={() => {
                                            changeLanguage('pl')
                                            setExp2(false)
                                        }} variant="secondary" active={i18n.languages[0] === 'pl'} aria-label="Change language to Polish"><span aria-hidden="true">PL</span></Button>
                                    }
                                </Nav.Item>
                                <Nav.Item>
                                    <Button onClick={() => {
                                        changeFont()
                                        setExp2(false)
                                    }} variant="secondary" active={false} aria-label={t('menu:change-font-size')} className="aaa-button">
                                        <span className="font-icon-min" aria-hidden="true" style={currentFont === 1 ? { fontWeight: 800 } : { fontWeight: 400 }}>A</span>
                                        <span className="font-icon-med" aria-hidden="true" style={currentFont === 2 ? { fontWeight: 800 } : { fontWeight: 400 }}>A</span>
                                        <span className="font-icon-max" aria-hidden="true" style={currentFont === 3 ? { fontWeight: 800 } : { fontWeight: 400 }}>A</span>
                                    </Button>
                                </Nav.Item>
                                <ShowWhenLoggedIn>
                                    <Nav.Item>
                                        <Button onClick={() => {
                                            props.userLogout().then(() => {
                                                setKey(v => ++v)
                                            })
                                            setExp2(false)
                                        }} variant="secondary">{t(PROFILE_MENU.logout.name)}</Button>
                                    </Nav.Item>
                                </ShowWhenLoggedIn>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar >
                </Container>
            </div>
            <div className="second-row-menu d-flex">
                <Container>
                    <div className="d-flex align-items-start">
                        <div className="d-flex flex-column logo-title flex-grow-1">
                            <NavLink exact to={MAIN_MENU.home.path} onClick={() => setExp(false)}>
                                <img className="main-logo" src="/images/amunatcoll-logo.png" alt="logo AMUNATCOLL. Adam Mickiewicz University Nature Collections" />
                                <img className="main-logo-2" src="/logo.png" alt="Amunatcoll logo" height="66px" />
                            </NavLink>
                        </div>
                        <div className="d-flex">
                            <Navbar expand="xl" expanded={exp} onToggle={e => { }}>
                                <div className={"d-flex flex-grow-1 justify-content-end align-items-center toggler-container" + (exp ? "" : " exp")}>
                                    <Navbar.Toggle aria-controls="main-menu" onClick={() => setExp(!exp)} label={!exp ? t('menu:toggle-menu-open') : t('menu:toggle-menu-close')}></Navbar.Toggle>
                                </div>
                                <Navbar.Collapse id="main-menu" className="main-menu-collapsed">
                                    <Nav>
                                        <Nav.Item>
                                            <Nav.Link as={NavLink} exact to={tOrStr(MAIN_MENU.home.path)} onClick={() => setExp(false)} className="dropdown-toggle btn btn-secondary">{t(MAIN_MENU.home.name)}</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="with-passive-headers">
                                            <DropdownButton variant="secondary" title={t(MAIN_MENU.searchEngines.name)}>
                                                {SEARCH_MENU.map((item, key) =>
                                                        key === 0 ?
                                                            <Dropdown.Item key={key} className={item.className}>{t(item.name)}</Dropdown.Item> :
                                                            <Dropdown.Item key={key} as={NavLink} 
                                                                className={item.className ? item.className : ""} to={tOrStr(item.path)} onClick={() => {
                                                                setExp(false)
                                                            }}>{t(item.name)}</Dropdown.Item>
                                                )}
                                            </DropdownButton>
                                        </Nav.Item>
                                        <Nav.Item className="with-passive-headers">
                                            <DropdownButton variant="secondary" title={t(MAIN_MENU.tools.name)}>
                                                {TOOLS_MENU.map((arr) =>
                                                    arr.map((item, key) =>
                                                        key === 0 ?
                                                            <Dropdown.Item key={key} className={item.className}>{t(item.name)}</Dropdown.Item> :
                                                            <Dropdown.Item key={key} as={NavLink}
                                                                className={item.className ? item.className : ""} to={tOrStr(item.path)}
                                                                onClick={() => {
                                                                    props.setActiveVisualization(item.visualizationType)
                                                                    props.setLoadObservationsOnInit(false)
                                                                    setExp(false)
                                                                }}>{tOrStr(item.name)}</Dropdown.Item>)
                                                )}
                                            </DropdownButton>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <DropdownButton variant="secondary" title={t(MAIN_MENU.about.name)}>
                                                {ABOUT_MENU.map((item, key) =>
                                                    <Dropdown.Item key={key} as={NavLink} to={tOrStr(item.path)} onClick={() => {
                                                        setExp(false)
                                                    }}>{t(item.name)}</Dropdown.Item>
                                                )}
                                            </DropdownButton>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link as={NavLink} to={tOrStr(MAIN_MENU.howToUse.path)} onClick={() => setExp(false)} className="dropdown-toggle btn btn-secondary">{t(MAIN_MENU.howToUse.name)}</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link as={NavLink} to={tOrStr(MAIN_MENU.contact.path)} onClick={() => setExp(false)} className="dropdown-toggle btn btn-secondary">{t(MAIN_MENU.contact.name)}</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="first-row-menu-collapsed">
                                            <DropdownButton variant="secondary" title={t("menu:profile")}>
                                                {(isEditor || isSuperleader || isCoordinator || isValidator) &&
                                                    <Nav.Item >
                                                        <DropdownButton variant="secondary" title={t(MAIN_MENU2.adminMenu.name)}>
                                                            {ADMIN_MENU.map((item, key) => <React.Fragment key={key}>
                                                                {hasRole(item.role) &&
                                                                    <>
                                                                        {item.isHeader &&
                                                                            <Dropdown.Item className={item.className} onClick={() => {
                                                                                setExp(false)
                                                                            }}>{t(item.name)}</Dropdown.Item>
                                                                        }
                                                                        {!item.isHeader &&
                                                                            <Dropdown.Item as={NavLink} className={item.className} to={tOrStr(item.path)} onClick={() => {
                                                                                setExp(false)
                                                                            }}>{t(item.name)}</Dropdown.Item>
                                                                        }
                                                                    </>
                                                                }
                                                            </React.Fragment>
                                                            )}
                                                        </DropdownButton>
                                                    </Nav.Item>
                                                }
                                                <ShowWhenLoggedIn>
                                                    <Nav.Item>
                                                        <DropdownButton variant="secondary" title={t(MAIN_MENU2.myAmunatcoll.name)}>
                                                            {MY_AMUNATCOLL_MENU.map((item, key) =>
                                                                <Dropdown.Item key={key} as={NavLink} to={tOrStr(item.path)} onClick={() => {
                                                                    setExp(false)
                                                                }}>{t(item.name)}</Dropdown.Item>
                                                            )}
                                                        </DropdownButton>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <DropdownButton variant="secondary" title={shortName}>
                                                            <Dropdown.Item as={NavLink} to={tOrStr(PROFILE_MENU.profile.path)} onClick={() => {
                                                                setExp(false)
                                                            }}>{t(PROFILE_MENU.profile.name)}</Dropdown.Item>
                                                            <Dropdown.Item as={NavLink} to={tOrStr(PROFILE_MENU.logout.path)} onClick={() => {
                                                                props.userLogout().then(() => {
                                                                    setKey(v => ++v)
                                                                })
                                                                setExp(false)
                                                            }}>{t(PROFILE_MENU.logout.name)}</Dropdown.Item>
                                                        </DropdownButton>
                                                    </Nav.Item>
                                                </ShowWhenLoggedIn>
                                                <ShowWhenNotLoggedIn>
                                                    <Navbar.Text>
                                                        {props.loading &&
                                                            <Spinner
                                                                as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                                className="ml-1"
                                                            />
                                                        }
                                                    </Navbar.Text>
                                                    <Nav.Item>
                                                        <Nav.Link as={NavLink} to={tOrStr(PROFILE_MENU.login.path)} onClick={() => setExp(false)} className="dropdown-toggle btn btn-secondary">{t(PROFILE_MENU.login.name)}</Nav.Link>
                                                    </Nav.Item>
                                                </ShowWhenNotLoggedIn>
                                                <Nav.Item>
                                                    {i18n.languages[0] === 'pl' &&
                                                        <Button onClick={() => {
                                                            changeLanguage('en')
                                                            setExp(false)
                                                        }} variant="secondary" active={i18n.languages[0] === 'en'} aria-label="Zmień język na angielski"><span aria-hidden="true">EN</span></Button>
                                                    }
                                                    {i18n.languages[0] === 'en' &&
                                                        <Button onClick={() => {
                                                            changeLanguage('pl')
                                                            setExp(false)
                                                        }} variant="secondary" active={i18n.languages[0] === 'pl'} aria-label="Change language to Polish"><span aria-hidden="true">PL</span></Button>
                                                    }
                                                </Nav.Item>
                                            </DropdownButton>
                                        </Nav.Item>
                                        <ShowWhenLoggedIn>
                                            <Nav.Item className="first-row-menu-collapsed">
                                                <Button onClick={() => {
                                                    props.userLogout().then(() => {
                                                        setKey(v => ++v)
                                                    })
                                                    setExp(false)
                                                }} variant="secondary">{t(PROFILE_MENU.logout.name)}</Button>
                                            </Nav.Item>
                                        </ShowWhenLoggedIn>
                                    </Nav>
                                </Navbar.Collapse>
                            </Navbar >
                            <div className="ue-flag">
                                {i18n.languages[0] === 'pl' &&
                                    <img src="/images/ue_pl.png" alt={t('menu:eu-flag')}></img>
                                }
                                {i18n.languages[0] === 'en' &&
                                    <img src="/images/ue_en.png" alt={t('menu:eu-flag')}></img>
                                }
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    loading: isPending('userInfo', state),
})

const mapDispatchToProps = dispatch => ({
    userLogout: () => dispatch(logout()),
    setActiveVisualization: value => dispatch(setField(MAP_TOOLS_ACTIVE_VISUALIZATION, MAP_TOOLS_ACTIVE_VISUALIZATION, value)),
    setLoadObservationsOnInit: value => dispatch(setLoadObservationsOnInit(value)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Menu)
