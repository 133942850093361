import React from "react";
import { useTranslation } from 'react-i18next'

const SynonymsInfo = props => {
    const { t } = useTranslation(['taxondb'])


    return <div className="d-flex flex-column">
        <div>{t('taxondb:synonyms-info-at')}:</div>
        <div><a href="http://www.theplantlist.org/" target="_blank" rel="noopener noreferrer">http://www.theplantlist.org/</a></div>
        <div><a href="https://www.tropicos.org/home" target="_blank" rel="noopener noreferrer">https://www.tropicos.org/home</a></div>
        <div><a href="https://www.gbif.org/" target="_blank" rel="noopener noreferrer">https://www.gbif.org/</a></div>
    </div>
}

export default SynonymsInfo
