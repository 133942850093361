import React, { useState } from "react"
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { isPending } from "../../reducers"
import { getDataApi, postDataApi } from '../../actions'
import { Form, Button } from "react-bootstrap"
import {
    API_TEAM_GET_TEAMS_ID,
    API_TEAM_GET_TEAMS_URL,
    API_TEAM_ADD_ME_ID,
    API_TEAM_ADD_ME_REQUEST_URL
} from '../../settings'
import * as notify from '../../utils/notify'

const JoinTeam = props => {
    const { t } = useTranslation([ 'team' ])

    const [ shareLink, setShareLink ] = useState('')

    const onShareLinkChange = e => {
        setShareLink(e.target.value)
    }

    const onSubmitForm = () => {
        props.joinTeam(shareLink, t).then(() => {
            setShareLink('')
            props.onDone && props.onDone()
        })
    }

    return (
        <Form className="mt-2 common-panel-background p-2" onSubmit={e => { e.preventDefault() }}>
            <Form.Group>
                <Form.Label htmlFor="shareLink">{t('team:share_link')}</Form.Label>
                <Form.Control id="shareLink" size="sm" value={shareLink} onChange={onShareLinkChange} disabled={props.querying}></Form.Control>
            </Form.Group>
            <div className="text-right">
                <Button size="sm" onClick={onSubmitForm} disabled={props.querying}>
                    {t('team:join-team')}
                </Button>
            </div>
        </Form>
    )
}

const mapStateToProps = state => ({
    querying: isPending(API_TEAM_ADD_ME_ID, state),
    requests: state.requests
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    joinTeam: (shareLink, t) => {
        const m = [...shareLink.matchAll(/team\/application\/(?<id>\d+)\/(?<hash>\d+)/gm)]
        if (m.length > 0) {
            const id = m[0].groups['id']
            const hash = m[0].groups['hash']
            return dispatch(getDataApi(API_TEAM_ADD_ME_ID, `${API_TEAM_ADD_ME_REQUEST_URL}${id}/${hash}/`)).then(() => {
                notify.success(t('team:joined-team'))
                return dispatch(postDataApi(API_TEAM_GET_TEAMS_ID, API_TEAM_GET_TEAMS_URL))
            }).catch(res => {
                notify.error(t('team:couldnt-join-team'), res.result)
            })    
        }
    }
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(JoinTeam)
