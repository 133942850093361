import React, { useState, useEffect } from "react"
import { Form, FormLabel, Container, Row, Col, Button, Spinner } from "react-bootstrap"
import { connect } from 'react-redux'
import * as actions from '../actions'
import { useTranslation } from 'react-i18next'
import { isPending, getOutput } from "../reducers"
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import * as notify from '../utils/notify'
import SEO from "./SEO"

const PasswordReminderForm = props => {

    const { clearResponse } = props
    useEffect(() => clearResponse, [clearResponse])

    const { t } = useTranslation(['common', 'forms'])
    const { executeRecaptcha } = useGoogleReCaptcha()

    const [fields, setFields] = useState({ username_or_email: "" })
    const handleFormChange = e => {
        const temp = { ...fields }
        temp[e.target.name] = e.target.value
        setFields(temp)
        setIsValid(
            Object.keys(fields).every(field => (temp[field] && temp[field].length > 0))
        )
    }

    const [isValid, setIsValid] = useState(false)

    const submitForm = async () => {
        const recaptcha = await executeRecaptcha()
        props.remindMe({ ...fields, recaptcha }, t)
    }

    return (
          <Container className="d-flex flex-column mt-2">
            <SEO title={t('common:password_reminder_process')} />
            {props.response && props.response.result === 0 &&
                <div className="pt-5 pb-5 align-self-center">{t('common:password_reminder_please_confirm')}</div>
            }
            {(!props.response || props.response.result !== 0) &&
                <Form>
                    <Row>
                        <Col md={{ span: 4, offset: 4 }}>
                            <h1>{t('common:password_reminder_process')}</h1>
                            <Form.Group>
                                <FormLabel htmlFor="username_or_email">{t('forms:username_or_email')}</FormLabel>
                                <Form.Control isInvalid={!fields.username_or_email} required autoFocus onChange={handleFormChange} value={fields.username_or_email} name="username_or_email" type="text" disabled={props.loading} id="username_or_email" autoComplete="off"></Form.Control>
                            </Form.Group>
                            <Button variant="primary" onClick={e => { e.preventDefault(); submitForm() }} disabled={props.loading || !isValid} type="submit">
                                {t('send')}
                                {props.loading &&
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        className="ml-1"
                                    />
                                }
                            </Button>
                        </Col>
                    </Row>
                </Form>
            }
            </Container>
    )
}

const mapStateToProps = state => ({
    loading: isPending('password-reminder', state),
    response: getOutput('password-reminder', state)
})

const mapDispatchToProps = dispatch => ({
    remindMe: (data, t) => dispatch(actions.postDataApi('password-reminder', '/ask_password_reset/', data))
        .then(res => {
            if (res.result === 1) {
                notify.error(t('reminder_failed'), t('bad_recaptcha'))
            } else if (res.result !== 0) {
                notify.error(t('reminder_failed'))
            }
        }),
    clearResponse: () => dispatch(actions.requestRemove('password-reminder')),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PasswordReminderForm)
