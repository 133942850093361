import React from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import MultilabelMap from './MultilabelMap'
import { Spinner, Container, Nav } from "react-bootstrap"
import {
    ARRANGEMENT_TOOLS_SEARCH_ON_INIT,
    TAXONDB_SEARCH_MAP_DATA_REQUEST_ID,
    ARRANGEMENT_PAGE_URL_EN,
    ARRANGEMENT_PAGE_URL_PL,
} from '../../settings'
import { isPending, getOutput, getItems } from "../../reducers"
import { NavLink } from "react-router-dom"
import { setMapFile } from '../../actions/map'
import { setField } from "../../actions/forms";

const MapContainer = props => {

    const { t, i18n } = useTranslation(['common', 'taxondb'])

    return (
        <Container className="d-flex flex-column mt-2">
            {!props.loading && !props.loadingRecords && !props.recordsFound &&
                <p>{t('use-form-to-search')}</p>
            }
            {(props.loading || props.loadingRecords) &&
                <div className="align-self-center">
                    <Spinner animation="border" role="status" />
                </div>
            }
            {!props.loadingRecords && props.itemsRecords &&
            <>
                <Nav.Link as={NavLink} to={
                        {
                            pathname: `${(i18n.language.startsWith('pl') ? ARRANGEMENT_PAGE_URL_PL : ARRANGEMENT_PAGE_URL_EN)}/`,
                            state: {
                                requestId: props.requestId,
                                genusRequestId: props.genusRequestId
                            }
                        }
                    }
                    onClick={() => {
                        props.clearMapfileBuffer()
                        props.setSearchOnInit(true)
                    }}
                >
                   {t('common:gis-forward')}
                </Nav.Link>
                <MultilabelMap
                    requestId={props.requestId}
                    genusRequestId={props.genusRequestId}
                    searchId={props.searchId}
                    searchUrl={props.searchUrl}
                    detailsDispatch={props.detailsDispatch}></MultilabelMap>
            </>
            }
        </Container>
    )
}

const mapStateToProps = (state, ownProps) => ({
    loading: isPending(ownProps.searchId || TAXONDB_SEARCH_MAP_DATA_REQUEST_ID, state),
    items: getOutput(ownProps.searchId || TAXONDB_SEARCH_MAP_DATA_REQUEST_ID, state),
    recordsFound: getItems(ownProps.requestId, state),
    loadingRecords: isPending(ownProps.genusRequestId, state) || isPending(ownProps.speciesRequestId, state) || isPending(ownProps.requestId, state),
    itemsRecords: getItems(ownProps.requestId, state),
})

const mapDispatchToProps = dispatch => ({
    clearMapfileBuffer: () => dispatch(setMapFile()),
    setSearchOnInit: value => dispatch(setField(ARRANGEMENT_TOOLS_SEARCH_ON_INIT, ARRANGEMENT_TOOLS_SEARCH_ON_INIT, value)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MapContainer)
