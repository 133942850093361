import React from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { setFields, setField, runSearchFlowSamples } from '../../actions/forms'
import { requestRemove, requestRemoveStartsWith } from '../../actions'
import { isPending, getFields, getField, prepareFilter } from "../../reducers"
import { Button } from "react-bootstrap"
import {
    SAMPLES_AGGREGATION_REPORT_REQUEST_ID_PREFIX,
} from '../../settings'

const SearchButtons = props => {
    const { t } = useTranslation([ 'common', 'taxondb', 'taxondb-attributes' ])

    const clearAllFields = obj => {
        Object.keys(obj).forEach(val => {
            if (val.startsWith('or_')) {
                obj[ val ].forEach(val2 => {
                    clearAllFields(val2)
                })
            } else if (val.startsWith('not_')) {
                clearAllFields(obj[ val ])
            } else {
                obj[ val ] = ''
            }
        })
    }

    const clearForm = e => {
        const temp = { ...props.fields }
        clearAllFields(temp)
        props.setFields(temp)
        props.clearResponse()
        try {
            if (props.mapRef) {
                props.mapRef.clearLayers()
            }
        }
        catch (e) {
            console.log(e)
        }
    }

    const submitForm = e => {
        e.preventDefault()
        props.runSearchFlow()
        if (props.onSearch) {
            props.onSearch()
        }
    }

    return (
        <>
            <Button variant="primary" className="search-button" onClick={submitForm} disabled={props.loading || Object.keys(props.filter).length === 0}>
                {t('search')}
            </Button>
            <Button variant="secondary" className="ml-2 clear-button" onClick={clearForm} disabled={props.loading} >
                {t('clear')}
            </Button>
        </>
    )
}

const mapStateToProps = (state, ownProps) => ({
    loading: isPending(ownProps.requestId, state),
    fields: ownProps.path ?
        JSON.parse(getField(ownProps.requestId, ownProps.requestId, state, "null")) :
        getFields(ownProps.requestId, state),
    filter: prepareFilter(ownProps.requestId, state, ownProps.path),
    mapRef: state.forms.map
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    setFields: val => ownProps.path ?
        dispatch(setField(ownProps.requestId, ownProps.requestId, JSON.stringify(val))) :
        dispatch(setFields(ownProps.requestId, val)),
    runSearchFlow: () => dispatch(runSearchFlowSamples(ownProps.requestId, ownProps.tabId, ownProps.path)),
    clearResponse: () => {
        dispatch(requestRemove(ownProps.requestId))
        dispatch(requestRemoveStartsWith(SAMPLES_AGGREGATION_REPORT_REQUEST_ID_PREFIX + ownProps.requestId))
    },
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchButtons)
