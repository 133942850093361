import * as ajax from '../utils/ajax'
import * as notify from '../utils/notify'
import i18n from '../utils/i18n'
import {
    API_URL_POSTFIX,
    ANC_URL_POSTFIX,
    DLIBRA_URL_POSTFIX,
} from '../settings'

export const REQUEST_PENDING = 'REQUEST_PENDING'
export const REQUEST_SUCCESS = 'REQUEST_SUCCESS'
export const REQUEST_SUCCESS_NO_DATA = 'REQUEST_SUCCESS_NO_DATA'
export const REQUEST_ERROR = 'REQUEST_ERROR'
export const REQUEST_REMOVE = 'REQUEST_REMOVE'
export const REQUEST_REMOVE_ALL = 'REQUEST_REMOVE_ALL'
export const REQUEST_REMOVE_STARTS_WITH = 'REQUEST_REMOVE_STARTS_WITH'
export const LIST_SET_FILTER = 'LIST_SET_FILTER'


export const setFilter = (id, filter) => ({
    type: LIST_SET_FILTER,
    id: id,
    filter: filter,
})

export const requestPending = (id, url, data) => ({
    type: REQUEST_PENDING,
    id: id,
    url: url,
    data: data
})

export const requestSuccess = (id, data) => ({
    type: REQUEST_SUCCESS,
    id: id,
    data: data,
})

export const requestSuccessNoData = id => ({
    type: REQUEST_SUCCESS_NO_DATA,
    id: id,
})

export const requestError = (id, error) => ({
    type: REQUEST_ERROR,
    id: id,
    error: error,
})

export const requestRemove = id => ({
    type: REQUEST_REMOVE,
    id: id,
})

export const requestRemoveStartsWith = prefix => ({
    type: REQUEST_REMOVE_STARTS_WITH,
    prefix: prefix,
})

export const requestRemoveAll = except => ({
    type: REQUEST_REMOVE_ALL,
    except: except,
})

export const postData = (id, url, data, timeout, source) =>
    dispatch => {
        dispatch(requestPending(id, url, data))
        return ajax.post(url, data, timeout, source)
            .then(response => {
                dispatch(requestSuccess(id, response.data))
                return response.data
            })
            .catch(error => {
                dispatch(notifyRequestError(error))
                dispatch(requestError(id, error))
                throw error
            })
    }

export const postFile = (id, url, data) =>
    dispatch => {
        dispatch(requestPending(id, url, data))
        return ajax.post_upload_file(url, data)
            .then(response => {
                dispatch(requestSuccess(id, response.data))
                return response.data
            })
            .catch(error => {
                dispatch(notifyRequestError(error))
                dispatch(requestError(id, error))
                throw error
            })
    }

export const postDownloadData = (id, url, data, file_name, file_ext) => (
    dispatch => {
        dispatch(requestPending(id))
        const ext = file_ext || data.reportType;
        delete data.reportType
        return ajax.post_report(url, data)
            .then(response => {
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(new Blob([ response.data ]), file_name + '.' + ext);
                }
                else {
                    const url = window.URL.createObjectURL(new Blob([ response.data ]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', file_name + '.' + ext);
                    document.body.appendChild(link);
                    link.click();
                }
                return dispatch(requestSuccessNoData(id))
            })
            .catch(error => {
                dispatch(notifyRequestError(error))
                dispatch(requestError(id, error))
                throw error
            })
    }
)

export const postReportDataObservations = (id, url, data, postfix) => (
    postReportData(id, postfix + url, data)
)

export const postReportData = (id, url, data) => postDownloadData(id, url, data, 'Report');

export const getReportData = (id, url) => (
    dispatch => {
        dispatch(requestPending(id))
        return ajax.get_report(url)
          .then(response => {
                const fileName = decodeURIComponent(response.headers[ 'content-disposition' ].split("filename*=UTF-8''")[ 1 ])
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(new Blob([ response.data ]), fileName);
                }
                else {
                    const url = window.URL.createObjectURL(new Blob([ response.data ]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                }
                return dispatch(requestSuccessNoData(id))
            })
            .catch(error => {
                dispatch(notifyRequestError(error))
                dispatch(requestError(id, error))
                throw error
            })
    }
)

export const postDataApi = (id, url, data) => (
    postData(id, API_URL_POSTFIX + url, data)
)

export const postDataAnc = (id, url, data, timeout, source) => (
    postData(id, ANC_URL_POSTFIX + url, data, timeout, source)
)

export const postFileAnc = (id, url, data) => (
    postFile(id, ANC_URL_POSTFIX + url, data)
)

export const postDataDlibra = (id, url, data, timeout, source) => (
    postData(id, DLIBRA_URL_POSTFIX + url, data, timeout, source)
)

export const postReportDataAnc = (id, url, data) => (
    postReportData(id, ANC_URL_POSTFIX + url, data)
)

export const postDownloadDataApi = (id, url, data, file_name, file_ext) => postDownloadData(id, API_URL_POSTFIX + url, data, file_name, file_ext);

export const getReportDataApi = (id, url) => (
    getReportData(id, API_URL_POSTFIX + url)
)

export const getData = (id, url) => (
    dispatch => {
        dispatch(requestPending(id, url))
        return ajax.get(url)
            .then(response => {
                dispatch(requestSuccess(id, response.data))
                return response.data
            })
            .catch(error => {
                dispatch(notifyRequestError(error))
                dispatch(requestError(id, error))
                throw error
            })
    }
)

export const getDataApi = (id, url) => (
    getData(id, API_URL_POSTFIX + url)
)

export const getDataAnc = (id, url) => (
    getData(id, ANC_URL_POSTFIX + url)
)

export const getDataDlibra = (id, url) => (
    getData(id, DLIBRA_URL_POSTFIX + url)
)

export const notifyRequestError = error =>
    dispatch => {
        notify.error(i18n.t('request_error'), error.message)
    }

export const getDlibraImage = (id, url, imgType, picturename) => (
    dispatch => {
        dispatch(requestPending(id))
        const file = `${picturename}.${imgType}`
        return ajax.get_dlibra_image(url, imgType)
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([ response.data ]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', file);
                document.body.appendChild(link);
                link.click();
                dispatch(requestSuccess(id))
            })
            .catch(error => {
                dispatch(notifyRequestError(error))
                dispatch(requestError(id, error))
                throw error
            })
    }
)

export const getBlob = (id, url) => (
    dispatch => {
        dispatch(requestPending(id, url))
        return ajax.get_blob(url)
            .then(response => {
                dispatch(requestSuccess(id, response.data))
                return response.data
            })
            .catch(error => {
                dispatch(notifyRequestError(error))
                dispatch(requestError(id, error))
                throw error
            })
    }
)
