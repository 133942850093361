import React, { useState } from "react";
import { connect } from 'react-redux'
import { Col } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import SeeMoreIcon from "../SeeMoreIcon";

const FileReportsListItem = props => {

    const { t } = useTranslation(['common', 'admin-tools'])
    const [buttonState, setButtonState] = useState({})

    const toggleDetails = (requestId) => {
        const temp = { ...buttonState }
        temp[requestId] = !temp[requestId]
        setButtonState(temp)
    }

    return (
        <>
            <div className="d-flex border p-xs-1 p-md-3 flex-wrap"
                tabIndex="0"
                onClick={e => toggleDetails(props.source.id)}
                onKeyDown={e => {if(e.key === "Enter"){toggleDetails(props.source.id)}}}
                aria-expanded={setButtonState[props.source.id]}
                aria-controls={buttonState[props.source.id]}
            >
                <Col xs={2} sm={1} className="admin-tools-item-row d-flex align-items-center" >
                    <SeeMoreIcon isOpen={buttonState[props.source.id]} />
                </Col>
                <Col xs={10} sm={6} className="admin-tools-item-row"  >
                    {props.source.plik}
                </Col>
                <Col xs={6} sm={2} className="admin-tools-item-row text-center text-md-left" >
                    {props.source.data &&
                        props.source.data.slice(0, 10)
                    }
                </Col>
                <Col xs={6} sm={3} className="admin-tools-item-row text-center text-md-left" >
                    {props.source.wszystkie} 
                    <span style={{color: "red", marginLeft: "0.25em"}}>
                        ({props.source.niepoprawne})
                    </span>
                </Col>
            </div>
            {buttonState[props.source.id] &&
                <div className="mt-2 ml-2">
                    <div><b>{t('admin-tools:id')}</b></div>
                    <div className="ml-2">{props.source.id}</div>
                    
                    <div><b>{t('admin-tools:file-name')}</b></div>
                    <div className="ml-2">{props.source.plik}</div>

                    <div><b>{t('admin-tools:date')}</b></div>
                    <div className="ml-2">{props.source.data}</div>

                    <div><b>{t('admin-tools:tasked-imports')}</b></div>
                    <div className="ml-2">{props.source.wszystkie}</div>

                    <div><b>{t('admin-tools:success-imports')}</b></div>
                    <div className="ml-2">{props.source.poprawne}</div>

                    <div><b>{t('admin-tools:failed-imports')}</b></div>
                    <div className="ml-2">{props.source.niepoprawne}</div>
                </div>
            }
        </>
    )
}


const mapStateToProps = state => ({

})
  
const mapDispatchToProps = dispatch => ({

})
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FileReportsListItem)