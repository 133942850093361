import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { Spinner } from "react-bootstrap"
import { getBlob } from '../../actions'
import ReactPlayer from 'react-player'

const CustomReactPlayer = props => {
  const [ loading, setLoading ] = useState(true);
  const [ currentBlob, setCurrentBlob ] = useState(null);
  const { url, getBlob } = props

  useEffect(() => {
    setLoading(true)
    getBlob(url).then(blob => {
      setCurrentBlob(URL.createObjectURL(blob))
      setLoading(false)
    })
  }, [ url, getBlob ])

  const onPlayerError = () => {
    console.log("Cannot play file: " + props.url);
  }

  return (
    <>
      {loading &&
        <Spinner animation="border" role="status"></Spinner>
      }
      {!loading &&
        <ReactPlayer
          className='react-player'
          url={currentBlob}
          controls={true}
          width='100%'
          height={props.type === 'video' ? '300px' : '30px'}
          onError={onPlayerError}
        ></ReactPlayer>
      }
    </>
  )
}

const mapStateToProps = (state, ownProps) => ({
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getBlob: url => dispatch(getBlob('blob', url)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomReactPlayer)
