import shp from 'shpjs/dist/shp'
import L from 'leaflet';
import Panstwa_3857 from '../../../shapefiles/Panstwa_3857.zip'
import gminy_3857 from '../../../shapefiles/gminy_3857.zip'
import powiaty_3857 from '../../../shapefiles/powiaty_3857.zip'
import wojewodztwa_3857 from '../../../shapefiles/wojewodztwa_3857.zip'
import ParkiKrajobrazowePolygon_3857 from '../../../shapefiles/ParkiKrajobrazowePolygon_3857.zip'
import ParkiNarodowePolygon_3857 from '../../../shapefiles/ParkiNarodowePolygon_3857.zip'
import LocationPhysiographicDivision from '../../../shapefiles/LocationPhysiographicDivision.zip'
import UTM33 from '../../../shapefiles/UTM33.zip'
import UTM34 from '../../../shapefiles/UTM34.zip'
import '../../../utils/map/leaflet.loader'
import * as notify from '../../../utils/notify'
import atpol from '../../../utils/atpol/atpol_male.json'

/* === ARITHMETIC SEQUENCE === */

export function defineRangesForSelectedNumberOfTaxonsAndColors(numberOfColors, maximalNumberOfTaxons){  
    let colors = numberOfColors - 1
    let differenceInPercentage = 1/colors;
    let jump = differenceInPercentage * maximalNumberOfTaxons
    const rangesArray = [{ min: 0, max: 0 }]
    
    for (let iterator = 1; iterator <= maximalNumberOfTaxons; iterator += jump){
        let rangeLowerBorder = Math.ceil(iterator)
        let rangeUpperBorder
        if ((jump % 2) === 0){
            rangeUpperBorder = (iterator + jump) > maximalNumberOfTaxons ? maximalNumberOfTaxons : Math.floor(iterator+jump)-1
        }
        else {
            rangeUpperBorder = (iterator + jump) > maximalNumberOfTaxons ? maximalNumberOfTaxons : Math.floor(iterator+jump)
        }    
        rangesArray.push({ min: rangeLowerBorder, max: rangeUpperBorder })
    }

    return rangesArray
}


export function findRangeForSelectedNumberOfTaxons(numberOfTaxons, rangesArray){
    let value = undefined
    let rangeIndex = undefined

    if (rangesArray && rangesArray.length){
        rangesArray.forEach((element, index) => {
            if((element.min <=  numberOfTaxons) && (element.max >= numberOfTaxons)){
                value = element
                rangeIndex = index
            }
        });
    }

    return {
        range: value,
        index: rangeIndex
    }
}


export function findColorMatchingToCartogramRange(range, colorsArray){
    return colorsArray[range.index]
}

/* === GEOMETRIC SEQUENCE === */

export function generateGeometricSequenceForNumberOfTaxons(firstValueOfSequence, product, numberOfTaxons, maximalNumberOfColors){

    let result = []

    for (let i = 0; i < maximalNumberOfColors; i++){
        let temp = firstValueOfSequence * Math.pow(product, i)
        if (temp <= numberOfTaxons) result.push(temp) 
    }

    return result
}


export function defineRangesForGeometricSequence(sequence, maxTaxons){

    const rangesArray = [{ min: 0, max: 0 }]
    if (sequence[0] > 1) rangesArray.push({min: 1, max: sequence[0]})
    
    for (let i = 0; i < sequence.length-1; i++){
        rangesArray.push({min: sequence[i], max: sequence[i+1]})
    }
    
    const [last] = sequence.slice(-1);
    rangesArray.push({min: last, max: maxTaxons})

    return rangesArray
}

/* === MANUAL SEQUENCE === */

export function handleManualRangesColorChange(currentArray, newNumberOfColors, oldNumberOfColors){
    let result
    if (currentArray && currentArray.length){
        if (newNumberOfColors < oldNumberOfColors){
            result = removeRangesFromManualSequence(currentArray, newNumberOfColors)
        }
        else{
            result = addRangesToManualSequence(currentArray, newNumberOfColors)
        }
    }
    else{
        result = createRangesArrayInManualSequence(newNumberOfColors)
    }
    return result
}

export function createRangesArrayInManualSequence(numberOfColors){
    let tempArray = new Array(numberOfColors)
    tempArray[0] = {min: 0, max: 0}
    for (let i = 1; i < numberOfColors; i++){
        tempArray[i] = {min: undefined, max: undefined}
    }

    return tempArray
}

export function addRangesToManualSequence(rangesArray, newNumberOfColors){
    let tempArr = rangesArray
    for (let i = rangesArray.length; i < newNumberOfColors; i++){
        tempArr.push({
            min: rangesArray[i-1].max ? parseInt(rangesArray[i-1].max + 1) : undefined, 
            max: undefined  
        })
    }
    return tempArr
}

export function removeRangesFromManualSequence(rangesArray, newNumberOfColors){
    let tempArr = rangesArray.slice(0, newNumberOfColors)
    return tempArr
}

export function modifyManualSequenceMinItem(value, currentArray, index){
    if (currentArray[index]){
        currentArray[index].min = parseInt(value)
        /*
        if (index > 0){
            if (currentArray[index-1]){
                currentArray[index-1].max = parseInt(value) - 1
            }
        }
        */
    }
    return currentArray
}

export function modifyManualSequenceMaxItem(value, currentArray, index){
    if (currentArray[index]){
        currentArray[index].max = parseInt(value)
        /*
        if(currentArray[index+1]){
            currentArray[index+1].min = parseInt(value) + 1
        }
        */
    }
    return currentArray
}

export function generate_cartogram_of_countries(items, cartogramRef, renderPopup, cartogramParams, loader, t){
    shp(Panstwa_3857)
    .then(function(geojson){
        L.geoJSON(geojson, {
            onEachFeature: (feature, layer) => {
                if (feature.properties && feature.properties.NAME_PL) {
                    layer.bindPopup(
                        renderPopup(feature.properties.NAME_PL, 0)
                    )
                    layer.setStyle({
                        fillColor : cartogramParams.areaWithoutTaxons,
                        fillOpacity: cartogramParams.areaFillOpacity,
                        opacity: cartogramParams.borderOpacity,
                        color: cartogramParams.administrativeBorderColor
                    }) 
                    if (items.has(feature.properties.NAME_PL)){
                        layer.bindPopup(
                            renderPopup(feature.properties.NAME_PL, items.get(feature.properties.NAME_PL).numberOfTaxons)
                        )
                        layer.setStyle({
                            fillColor : items.get(feature.properties.NAME_PL).color
                        }) 
                    }
                }
            }
        }).addTo(cartogramRef).bringToBack();
    })
    .then(() => {
        notify.success(t("map:cartogram-success-msg"))
        loader.hide();
    })
    .catch(
        function (error) {
            notify.error(t("map:cartogram-error-msg"))
            loader.hide();
            return Promise.reject(error)
        }
    )
}


export function generateCartogramOfVoivodeships(items, cartogramRef, renderPopup, cartogramParams, loader, t){
    shp(wojewodztwa_3857)
    .then(function(geojson){
        L.geoJSON(geojson, {
            onEachFeature: (feature, layer) => {
                if (feature.properties && feature.properties.jpt_nazwa_) {
                    layer.bindPopup(
                        renderPopup(feature.properties.jpt_nazwa_, 0)
                    )
                    layer.setStyle({
                        fillColor : cartogramParams.areaWithoutTaxons,
                        fillOpacity: cartogramParams.areaFillOpacity,
                        opacity: cartogramParams.borderOpacity,
                        color: cartogramParams.administrativeBorderColor
                    }) 
                    if (items.has(feature.properties.jpt_nazwa_)){
                        layer.bindPopup(
                            renderPopup(feature.properties.jpt_nazwa_, items.get(feature.properties.jpt_nazwa_).numberOfTaxons)
                        )
                        layer.setStyle({
                            fillColor : items.get(feature.properties.jpt_nazwa_).color
                        }) 
                    }
                }
            }
        }).addTo(cartogramRef).bringToBack();
    })
    .then(() => {
        notify.success(t("map:cartogram-success-msg"))
        loader.hide();
    })
    .catch(
        function (error) {
            notify.error(t("map:cartogram-error-msg"))
            loader.hide();
            return Promise.reject(error)
        }
    )
}


export function generateCartogramOfDistricts(items, cartogramRef, renderPopup, cartogramParams, loader, t){
    shp(powiaty_3857)
    .then(function(geojson){
        L.geoJSON(geojson, {
            onEachFeature: (feature, layer) => {
                if (feature.properties && feature.properties.jpt_nazwa_) {
                    layer.bindPopup(
                        renderPopup(feature.properties.jpt_nazwa_, 0)
                    )
                    layer.setStyle({
                        fillColor : cartogramParams.areaWithoutTaxons,
                        fillOpacity: cartogramParams.areaFillOpacity,
                        opacity: cartogramParams.borderOpacity,
                        color: cartogramParams.administrativeBorderColor
                    }) 
                    if (items.has("powiat " + feature.properties.jpt_nazwa_)){
                        layer.bindPopup(
                            renderPopup(feature.properties.jpt_nazwa_, items.get("powiat " + feature.properties.jpt_nazwa_).numberOfTaxons)
                        )
                        layer.setStyle({
                            fillColor : items.get("powiat " + feature.properties.jpt_nazwa_).color
                        }) 
                    }
                }
            }
        }).addTo(cartogramRef).bringToBack();
    })
    .then(() => {
        notify.success(t("map:cartogram-success-msg"))
        loader.hide();
    })
    .catch(
        function (error) {
            notify.error(t("map:cartogram-error-msg"))
            loader.hide();
            return Promise.reject(error)
        }
    )
}


export function generateCartogramOfCommunes(items, cartogramRef, renderPopup, cartogramParams, loader, t){
    shp(gminy_3857)
    .then(function(geojson){
        L.geoJSON(geojson, {
            onEachFeature: (feature, layer) => {
                if (feature.properties && feature.properties.jpt_nazwa_) {
                    layer.bindPopup(
                        renderPopup(feature.properties.jpt_nazwa_, 0)
                    )
                    layer.setStyle({
                        fillColor : cartogramParams.areaWithoutTaxons,
                        fillOpacity: cartogramParams.areaFillOpacity,
                        opacity: cartogramParams.borderOpacity,
                        color: cartogramParams.administrativeBorderColor
                    }) 
                    if (items.has(feature.properties.jpt_nazwa_)){
                        layer.bindPopup(
                            renderPopup(feature.properties.jpt_nazwa_, items.get(feature.properties.jpt_nazwa_).numberOfTaxons)
                        )
                        layer.setStyle({
                            fillColor : items.get(feature.properties.jpt_nazwa_).color
                        }) 
                    }
                }
            }
        }).addTo(cartogramRef).bringToBack();
    })
    .then(() => {
        notify.success(t("map:cartogram-success-msg"))
        loader.hide();
    })
    .catch(
        function (error) {
            notify.error(t("map:cartogram-error-msg"))
            loader.hide();
            return Promise.reject(error)
        }
    )
}


export function generateCartogramOfNationalParks(items, cartogramRef, renderPopup, cartogramParams, loader, t){
    shp(ParkiNarodowePolygon_3857)
    .then(function(geojson){
        L.geoJSON(geojson, {
            onEachFeature: (feature, layer) => {
                if (feature.properties && feature.properties.nazwa) {
                    layer.bindPopup(
                        renderPopup(feature.properties.nazwa, 0)
                    )
                    layer.setStyle({
                        fillColor : cartogramParams.areaWithoutTaxons,
                        fillOpacity: cartogramParams.areaFillOpacity,
                        opacity: cartogramParams.borderOpacity,
                        color: cartogramParams.administrativeBorderColor
                    }) 
                    if (items.has(feature.properties.nazwa)){
                        layer.bindPopup(
                            renderPopup(feature.properties.nazwa, items.get(feature.properties.nazwa).numberOfTaxons)
                        )
                        layer.setStyle({
                            fillColor : items.get(feature.properties.nazwa).color
                        }) 
                    }
                }
            }
        }).addTo(cartogramRef).bringToBack();
    })
    .then(() => {
        notify.success(t("map:cartogram-success-msg"))
        loader.hide();
    })
    .catch(
        function (error) {
            notify.error(t("map:cartogram-error-msg"))
            loader.hide();
            return Promise.reject(error)
        }
    )
}


export function generateCartogramOfLandscapeParks(items, cartogramRef, renderPopup, cartogramParams, loader, t){
    shp(ParkiKrajobrazowePolygon_3857)
    .then(function(geojson){
        L.geoJSON(geojson, {
            onEachFeature: (feature, layer) => {
                if (feature.properties && feature.properties.nazwa) {
                    layer.bindPopup(
                        renderPopup(feature.properties.nazwa, 0)
                    )
                    layer.setStyle({
                        fillColor : cartogramParams.areaWithoutTaxons,
                        fillOpacity: cartogramParams.areaFillOpacity,
                        opacity: cartogramParams.borderOpacity,
                        color: cartogramParams.administrativeBorderColor
                    }) 
                    if (items.has(feature.properties.nazwa)){
                        layer.bindPopup(
                            renderPopup(feature.properties.nazwa, items.get(feature.properties.nazwa).numberOfTaxons)
                        )
                        layer.setStyle({
                            fillColor : items.get(feature.properties.nazwa).color
                        }) 
                    }
                }
            }
        }).addTo(cartogramRef).bringToBack();
    })
    .then(() => {
        notify.success(t("map:cartogram-success-msg"))
        loader.hide();
    })
    .catch(
        function (error) {
            notify.error(t("map:cartogram-error-msg"))
            loader.hide();
            return Promise.reject(error)
        }
    )
}


export function generateCartogramOfAtpolGrid(items, cartogramRef, renderPopup, cartogramParams, loader, t){
    L.geoJSON(atpol, {
        onEachFeature: (feature, layer) => {
            if (feature.properties && feature.properties.Name) {
                layer.bindPopup(
                    renderPopup(feature.properties.Name, 0)
                )
                layer.setStyle({
                    fillColor : cartogramParams.areaWithoutTaxons,
                    fillOpacity: cartogramParams.areaFillOpacity,
                    opacity: cartogramParams.borderOpacity,
                    color: cartogramParams.administrativeBorderColor
                }) 
                if (items.has(feature.properties.Name)){
                    layer.bindPopup(
                        renderPopup(feature.properties.Name, items.get(feature.properties.Name).numberOfTaxons)
                    )
                    layer.setStyle({
                        fillColor : items.get(feature.properties.Name).color
                    }) 
                }
            }
        }
    }).addTo(cartogramRef).bringToBack();
    notify.success(t("map:cartogram-success-msg"))
    loader.hide();
}


export function generateCartogramOfUTMGridPoland(items, cartogramRef, renderPopup, cartogramParams, loader, t){
    shp(UTM34)
    .then(function(geojson){
        L.geoJSON(geojson, {
            onEachFeature: (feature, layer) => {
                if (feature.properties && feature.properties.MGRS_10km) {
                    layer.bindPopup(
                        renderPopup(feature.properties.MGRS_10km, 0)
                    )
                    layer.setStyle({
                        fillColor : cartogramParams.areaWithoutTaxons,
                        fillOpacity: cartogramParams.areaFillOpacity,
                        opacity: cartogramParams.borderOpacity,
                        color: cartogramParams.administrativeBorderColor
                    }) 
                    if (items.has(feature.properties.MGRS_10km)){
                        layer.bindPopup(
                            renderPopup(feature.properties.MGRS_10km, items.get(feature.properties.MGRS_10km).numberOfTaxons)
                        )
                        layer.setStyle({
                            fillColor : items.get(feature.properties.MGRS_10km).color
                        }) 
                    }
                }
            }
        }).addTo(cartogramRef).bringToBack();
    })
    .then(() => { return shp(UTM33) })
    .then(function(geojson){
        L.geoJSON(geojson, {
            onEachFeature: (feature, layer) => {
                if (feature.properties && feature.properties.MGRS_10km) {
                    layer.bindPopup(
                        renderPopup(feature.properties.MGRS_10km, 0)
                    )
                    layer.setStyle({
                        fillColor : cartogramParams.areaWithoutTaxons,
                        fillOpacity: cartogramParams.areaFillOpacity,
                        opacity: cartogramParams.borderOpacity,
                        color: cartogramParams.administrativeBorderColor
                    }) 
                    if (items.has(feature.properties.MGRS_10km)){
                        layer.bindPopup(
                            renderPopup(feature.properties.MGRS_10km, items.get(feature.properties.MGRS_10km).numberOfTaxons)
                        )
                        layer.setStyle({
                            fillColor : items.get(feature.properties.MGRS_10km).color
                        }) 
                    }
                }
            }
        }).addTo(cartogramRef).bringToBack();
    })
    .then(() => {
        notify.success(t("map:cartogram-success-msg"))
        loader.hide();
    })
    .catch(
        function (error) {
            notify.error(t("map:cartogram-error-msg"))
            loader.hide();
            return Promise.reject(error)
        }
    )
}


export function generateCartogramOfLocationPhysiographicDivision(items, cartogramRef, renderPopup, cartogramParams, loader, t){
    shp(LocationPhysiographicDivision)
    .then(function(geojson){
        L.geoJSON(geojson, {
            onEachFeature: (feature, layer) => {
                if (feature.properties && feature.properties["Kondr"]) {
                    layer.bindPopup(
                        renderPopup(feature.properties["Kondr"], 0)
                    )
                    layer.setStyle({
                        fillColor : cartogramParams.areaWithoutTaxons,
                        fillOpacity: cartogramParams.areaFillOpacity,
                        opacity: cartogramParams.borderOpacity,
                        color: cartogramParams.administrativeBorderColor
                    }) 
                    if (items.has(feature.properties["Kondr"])){
                        layer.bindPopup(
                            renderPopup(feature.properties["Kondr"], items.get(feature.properties["Kondr"]).numberOfTaxons)
                        )
                        layer.setStyle({
                            fillColor : items.get(feature.properties["Kondr"]).color
                        }) 
                    }
                }
            }
        }).addTo(cartogramRef).bringToBack();
    })
    .then(() => {
        notify.success(t("map:cartogram-success-msg"))
        loader.hide();
    })
    .catch(
        function (error) {
            notify.error(t("map:cartogram-error-msg"))
            loader.hide();
            return Promise.reject(error)
        }
    )
}
