import React, { useState, useEffect } from "react"
import { connect } from 'react-redux'
import { Container,  Spinner, Form, Button, Col, FormLabel } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import {
    API_GET_EDITORS_NAMES_ID,
    API_GET_EDITORS_NAMES_URL,
    API_GET_EDITIONS_LIST_ID,
    API_GET_EDITIONS_LIST_URL,
    API_TASK_REQUEST_PREFIX,
    API_TASK_REQUEST_URL,
    API_URL_POSTFIX,
} from "../../settings"
import { getOutput, getPagination, isPending, getItems } from "../../reducers";
import { postDataApi, getDataApi } from "../../actions"
import ConnectedPagination from '../ConnectedPagination'
import EditionListItem from './EditionListItem'

const GroupEditionPanel = props => {

    const { t } = useTranslation(['forms', 'menu', 'editions-panel'])

    const [user, setUser] = useState('')
    const [status, setStatus] = useState('')
    const [dateStart, setDateStart] = useState('')
    const [dateEnd, setDateEnd]  = useState('')
    const [taxonId, setTaxonId] = useState('')
    const [ipAddress, setIpAddress] = useState('')
    const [filter, setFilter] = useState('')

    const EMPTY_STRING = ""
    
    /* status types */
    const STATUS_QUEUE = 'QUEUE'
    const STATUS_INIT = 'INIT'
    const STATUS_WORKING = 'WORKING'
    const STATUS_DONE = 'DONE'
    const STATUS_FAILED = 'FAILED'
    const STATUS_ERRORS = 'ERRORS'


    const onUserChange = e => {
        setUser(e.target.value)
    }

    const onStatusChange = e => {
        setStatus(e.target.value)
    }


    const onDateStartChange = e => {
        setDateStart(e.target.value)
    }

    const onDateEndChange = e => {
        setDateEnd(e.target.value)
    }

    const onTaxonIdChange = e => {
        setTaxonId(e.target.value)
    }

    const onIpAddressChange = e => {
        setIpAddress(e.target.value)
    }

    const onClearForm = () => {
        setUser('')
        setStatus('')
        setTaxonId('')
        setIpAddress('')
        getCurrentDate()
        getMonthAgoDate()
        setFilter({})
    }

    const onSubmitForm = () => {
        let filter = {}
        if (user){
            filter['username'] = user
        }
        if (status){
            filter['status'] = status
        }
        if (taxonId){
            filter['kolekcjanumerokazu'] = taxonId
        }
        if (ipAddress){
            filter['ip_address'] = ipAddress
        }
        filter["od_daty"] = dateStart
        filter["do_daty"] = dateEnd
        setFilter(filter)
        props.getEditionsList(filter)
    }

    const getCurrentDate = () => {
        let date = new Date()
        date = date.toJSON().slice(0, 10);
        setDateEnd(date)
    }

    const getMonthAgoDate = () => {
        let date = new Date()
        let currentMonth = date.getMonth()
        date.setMonth(currentMonth - 1)
        date = date.toJSON().slice(0, 10);
        setDateStart(date)
    }

    const getUsersData = () => {
        props.getEditorsNames() 
    }

    useEffect(getUsersData, [])

    useEffect(() => {
        getCurrentDate()
        getMonthAgoDate()
    }, [])

    const RELOAD_INTERVAL = 5000
    const {editionsList, getEditionsList} =  props
    
    useEffect(()=>{
        let interval
        if(editionsList && editionsList.length > 0){
          let statuses = []
          editionsList.forEach((item)=>statuses.push(item.status))
          if(statuses.includes("CREATE") || statuses.includes("QUEUE") || statuses.includes("WORKING"))
            interval = setInterval(()=>{getEditionsList(filter)},RELOAD_INTERVAL)
        }
        if(interval)
          return () => clearInterval(interval)
        
      },[editionsList, filter, getEditionsList])

    const handleOnKeyUp = e => {
        if (e.keyCode === 13) { // ENTER
            e.preventDefault()
            onSubmitForm()
        }
    }

    return (
        <Container>
            <Form className="mt-2 p-1 mb-2" onSubmit={e => { e.preventDefault() }}>
            <Form.Row>
                    <Col>
                        <Form.Group>
                            <FormLabel htmlFor={"dateFrom"}>{t('editions-panel:from-date')}</FormLabel>
                            <Form.Control
                                id={"dateFrom"} 
                                type="date" 
                                name="dateFrom"
                                onChange={onDateStartChange}
                                onKeyUp={handleOnKeyUp} 
                                value={dateStart}
                                required
                            >
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <FormLabel htmlFor={"dateTo"}>{t('editions-panel:to-date')}</FormLabel>
                            <Form.Control 
                                id={"dateTo"} 
                                type="date" 
                                name="dateTo" 
                                onChange={onDateEndChange}
                                onKeyUp={handleOnKeyUp} 
                                value={dateEnd}
                                required
                            >
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Label htmlFor="status">{t('editions-panel:edition-status')}</Form.Label>
                            <Form.Control id="role" as="select" value={status} onChange={onStatusChange} onKeyUp={handleOnKeyUp} disabled={props.querying}>
                                <option value={EMPTY_STRING}>{t('editions-panel:value-any')}</option>
                                <option value={STATUS_QUEUE}>{t('editions-panel:status-queue')}</option>
                                <option value={STATUS_INIT}>{t('editions-panel:status-init')}</option>
                                <option value={STATUS_WORKING}>{t('editions-panel:status-working')}</option>
                                <option value={STATUS_DONE}>{t('editions-panel:status-done')}</option>
                                <option value={STATUS_FAILED}>{t('editions-panel:status-failed')}</option>
                                <option value={STATUS_ERRORS}>{t('editions-panel:status-errors')}</option>    
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label htmlFor="taxonId">{t('editions-panel:taxon-id')}</Form.Label>
                            <Form.Control id="taxonId" value={taxonId} onChange={onTaxonIdChange} onKeyUp={handleOnKeyUp} disabled={props.querying}></Form.Control>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Label htmlFor="user" > 
                                {t('editions-panel:username')}
                                {props.loadingEditorsNames && 
                                    <Spinner animation="border" role="status" size="sm" className="ml-2" />
                                }
                            </Form.Label>
                            <Form.Control id="user" as="select" value={user} onChange={onUserChange} onKeyUp={handleOnKeyUp} disabled={props.querying} >
                                <option value={EMPTY_STRING}>{t('editions-panel:value-any')}</option>
                                {props.editorsNames && props.editorsNames.length && props.editorsNames.length > 0 &&
                                    props.editorsNames.map(user => {
                                        return <option value={user} key={user}>{user}</option>
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <FormLabel htmlFor={"ipAddress"}>{t('editions-panel:ip')}</FormLabel>
                            <Form.Control
                                id={"ipAddress"} 
                                name="ipAddress"
                                onChange={onIpAddressChange}
                                onKeyUp={handleOnKeyUp} 
                                value={ipAddress}
                            >
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} className="d-flex align-self-end">
                        <div className="flex-grow-1" />
                        <Button variant="primary" onClick={onSubmitForm} disabled={props.querying}>
                            {t('common:search')}
                        </Button>
                        <Button variant="secondary" className="ml-2" onClick={onClearForm} disabled={props.querying} >
                            {t('common:clear')}
                        </Button>
                    </Form.Group>
                </Form.Row>
            </Form>
            <div className="ml-4">
                {props.loadingEditionsList &&
                    <Spinner animation="border" role="status" />
                }
                {props.editionsList && props.editionsList.length === 0 &&
                    <div>
                        {t('editions-panel:no-results')}
                    </div>
                }
                {props.editionsList && props.editionsList.length > 0 &&
                <div>
                    <ConnectedPagination requestId={API_TASK_REQUEST_PREFIX + API_GET_EDITIONS_LIST_ID} url={API_URL_POSTFIX + API_TASK_REQUEST_URL + API_GET_EDITIONS_LIST_URL} customFilter={{filter: filter}}></ConnectedPagination>
                    {props.editionsList.map((edition) => {
                        return(
                                <EditionListItem key={edition.id} edition={edition} />
                            )
                        })
                    }
                    <ConnectedPagination last requestId={API_TASK_REQUEST_PREFIX + API_GET_EDITIONS_LIST_ID} url={API_URL_POSTFIX + API_TASK_REQUEST_URL + API_GET_EDITIONS_LIST_URL} customFilter={{filter: filter}}></ConnectedPagination>
                </div>
                }
            </div>
        </Container>
    )
}

const mapStateToProps = state => ({
    user: state.user ? state.user.data : null,
    editionsList: getItems(API_TASK_REQUEST_PREFIX + API_GET_EDITIONS_LIST_ID, state),
    editorsNames: getOutput(API_TASK_REQUEST_PREFIX + API_GET_EDITORS_NAMES_ID, state),
    loadingEditionsList: isPending(API_TASK_REQUEST_PREFIX + API_GET_EDITIONS_LIST_ID, state),
    loadingEditorsNames: isPending(API_TASK_REQUEST_PREFIX + API_GET_EDITORS_NAMES_ID, state),
    pagination: getPagination(API_TASK_REQUEST_PREFIX + API_GET_EDITIONS_LIST_ID, state),
})
  
const mapDispatchToProps = dispatch => ({
    getEditionsList: filter => dispatch(
            postDataApi(API_TASK_REQUEST_PREFIX + API_GET_EDITIONS_LIST_ID, API_TASK_REQUEST_URL + API_GET_EDITIONS_LIST_URL, {
            filter: filter
        })
    ),
    getEditorsNames: () => dispatch(
            getDataApi(API_TASK_REQUEST_PREFIX + API_GET_EDITORS_NAMES_ID, API_TASK_REQUEST_URL + API_GET_EDITORS_NAMES_URL)
    )
})
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GroupEditionPanel)