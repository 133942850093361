import React, { Suspense } from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { getField, isPending, getItems } from "../../reducers";
import { Tabs, Tab, Container } from "react-bootstrap"
import GenusList from './GenusList'
import SpeciesList from './SpeciesList'
import IconsList from './IconsList'
import ResultsList from './ResultsList'
import { setField } from '../../actions/forms'
import * as actions from '../../actions'
import {
    TAXONDB_SEARCH_MAP_DATA_REQUEST_ID,
    TAXONDB_SEARCH_MAP_DATA_REQUEST_URL,
} from '../../settings'
import MapContainer from '../map/MapContainer'
import NewReportList from '../taxonDBReport/MainContainer'
import Loader from '../../utils/loader'

const ResultsContainer = props => {
    const { t } = useTranslation(['taxondb', 'taxondb-groups', 'icondb'])

    return (
        <Container className={(!props.loading && !props.items) ? "mt-2 d-none" : "mt-2"}>
            <Suspense fallback={<Loader />}>
                <div className="search-results">{t('common:search-results')}</div>
                <Tabs defaultActiveKey={props.activeTab} id={props.searchRequestId} activeKey={props.activeTab} onSelect={props.setActiveTab}>
                    <Tab eventKey="0" title={t('rodzaje')}>
                        <GenusList
                            requestId={props.genusRequestId}
                            speciesRequestId={props.speciesRequestId}
                            searchRequestId={props.searchRequestId}
                            tabId={props.tabId}
                        ></GenusList>
                    </Tab>
                    <Tab eventKey="1" title={t('gatunki')}>
                        <SpeciesList
                            requestId={props.speciesRequestId}
                            searchRequestId={props.searchRequestId}
                            tabId={props.tabId}
                            genusRequestId={props.genusRequestId}
                        ></SpeciesList>
                    </Tab>
                    <Tab eventKey="2" title={t('okazy')}>
                        {props.activeTab === "2" &&
                            <ResultsList
                                requestId={props.searchRequestId}
                                speciesRequestId={props.speciesRequestId}
                                genusRequestId={props.genusRequestId}
                            ></ResultsList>
                        }
                    </Tab>
                    <Tab eventKey="3" title={t('icondb:gallery')}>
                        {props.activeTab === "3" &&
                            <IconsList
                                requestId={props.searchRequestId}
                                speciesRequestId={props.speciesRequestId}
                                genusRequestId={props.genusRequestId}
                                iconsRequestId={props.iconsRequestId}
                            ></IconsList>
                        }
                    </Tab>
                    <Tab eventKey="4" title={t('map')}>
                        {props.activeTab === "4" &&
                            <MapContainer
                                requestId={props.searchRequestId}
                                speciesRequestId={props.speciesRequestId}
                                genusRequestId={props.genusRequestId}></MapContainer>
                        }

                    </Tab>
                    <Tab eventKey="5" title={t('report')}>
                        {props.activeTab === "5" &&
                            <NewReportList
                                requestId={props.searchRequestId}
                                speciesRequestId={props.speciesRequestId}
                                genusRequestId={props.genusRequestId}></NewReportList>
                        }

                    </Tab>
                </Tabs>
            </Suspense>
        </Container>
    )
}

const mapStateToProps = (state, ownProps) => ({
    items: getItems(ownProps.genusRequestId, state),
    activeTab: (getField(ownProps.tabId, ownProps.tabId, state) || '0'),
    loading: isPending(ownProps.genusRequestId, state),
})


const mapDispatchToProps = (dispatch, ownProps) => ({
    setActiveTab: value => dispatch(setField(ownProps.tabId, ownProps.tabId, value)),
    searchMapData: data => dispatch(actions.postDataAnc(TAXONDB_SEARCH_MAP_DATA_REQUEST_ID, TAXONDB_SEARCH_MAP_DATA_REQUEST_URL, data)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResultsContainer)

