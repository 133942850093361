import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Button, FormLabel, Spinner } from  "react-bootstrap"; 
import useMapRedux from '../../../hooks/useMapRedux';
import useFieldRedux from '../../../hooks/useFieldRedux';
import useMapDefinition from '../../../hooks/useMapDefinition';
import {
  ARRANGEMENT_TOOLS_SEARCH_ON_INIT,
  MAP_TOOLS_VISUALIZATION_2,
  MAP_TOOLS_ACTIVE_VISUALIZATION,
} from '../../../settings';

const ClearMapPanel = props => {
  const { t } = useTranslation(['map']);
  const { setMapState } = useMapDefinition();
  const { setValue: setMapId } = useMapRedux('mapId');
  const { setValue: setMapTitle } = useMapRedux('mapTitle');
  const { setValue: setSearchOnInit } = useFieldRedux(ARRANGEMENT_TOOLS_SEARCH_ON_INIT);
  const { setValue: setActiveVisualization } = useFieldRedux(MAP_TOOLS_ACTIVE_VISUALIZATION);
  const [ loading, setLoading ] = useState(false)

  const clearMap = () => {
    setLoading(true)
    setActiveVisualization(MAP_TOOLS_VISUALIZATION_2)
    setTimeout(() => {
      setMapState({});
      setMapTitle(undefined)
      setMapId(undefined)
      setSearchOnInit(true)
    }, 50)
  }
  
  return(
    <>
      <Form.Group controlId='map-name'>
        <FormLabel>{t('map:clear-map-info')}</FormLabel>
      </Form.Group>
      <Form.Group>
        <Button
          onClick={clearMap}
        >{t('map:clear-map-confirm')}
          {loading &&
              <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="ml-1"
              />
          }
        </Button>
      </Form.Group>
    </>
  )
}
export default ClearMapPanel
