import React from "react"
import { Alert, Spinner } from 'react-bootstrap'

const Loader = () => (
    <div className="App text-center">
        <Alert variant="light">
            <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
                className="m-5"
            />
        </Alert>
    </div>
)

export default Loader