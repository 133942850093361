import React from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { runSearchFlow } from '../../actions/forms'
import { requestRemove } from '../../actions'
import { isPending } from "../../reducers";
import {
    TAXONDB_SUGGEST_REQUEST_PREFIX,
} from '../../settings'
import InputWithAsyncTypeahead from "../InputWithAsyncTypeahead"
import { Form } from "react-bootstrap"
import SearchButtons from './SearchButtons'
import SynonymsInfo from "./SynonymsInfo"
import InfoIconPopover from "../InfoIconPopover";

const SimpleSearchForm = props => {
    const { t } = useTranslation([ 'common', 'taxondb', 'taxondb-attributes', 'home' ])

    const handleOnKeyUp = e => {
        if (e.keyCode === 13) { // ENTER
            e.preventDefault()
            props.runSearchFlow()
            if (props.afterSearch) {
                props.afterSearch()
            }
        }
    }

    const onChange = e => {
        props.clearResponse()
    }

    return (
        <Form onSubmit={e => { e.preventDefault() }}>
            <div className="d-flex">
                <Form.Group className="flex-grow-1 mr-3 flex-fill" id="search-form">
                    <InputWithAsyncTypeahead
                        placeholder={t("home:search2")}
                        url={TAXONDB_SUGGEST_REQUEST_PREFIX + "filtr_prosty/"}
                        autoFocus={true}
                        id="filtr_prosty"
                        disabled={props.loading}
                        formId={props.genusRequestId}
                        onChange={onChange}
                        onKeyUp={handleOnKeyUp}></InputWithAsyncTypeahead>
                    <Form.Label htmlFor="filtr_prosty">{t('home:home_search_field_info')}
                    </Form.Label>
                    <InfoIconPopover>
                        <div>{t('taxondb:rodzajgatunek_info-1')}</div>
                        <div>{t('taxondb:rodzajgatunek_info-2')}</div>
                        <div>{t('taxondb:rodzajgatunek_info-3')}</div>
                        <SynonymsInfo></SynonymsInfo>
                    </InfoIconPopover>
                </Form.Group>
                <Form.Group className="d-flex align-items-start">
                    <div className="flex-grow-1" />
                    <SearchButtons
                        requestId={props.searchRequestId}
                        genusRequestId={props.genusRequestId}
                        speciesRequestId={props.speciesRequestId}
                        tabId={props.tabId}
                        afterSearch={props.afterSearch}
                    ></SearchButtons>
                </Form.Group>
            </div>
        </Form>
    )
}

const mapStateToProps = (state, ownProps) => ({
    loading: isPending(ownProps.genusRequestId, state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    runSearchFlow: () => dispatch(runSearchFlow(ownProps.genusRequestId, ownProps.speciesRequestId, ownProps.searchRequestId, ownProps.tabId)),
    clearResponse: () => {
        dispatch(requestRemove(ownProps.genusRequestId))
        dispatch(requestRemove(ownProps.speciesRequestId))
        dispatch(requestRemove(ownProps.searchRequestId))
    },
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SimpleSearchForm)
