import React, { forwardRef, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { Form, Button } from  "react-bootstrap";
import { setMapLineStyle } from '../../actions/map'
import { useTranslation } from 'react-i18next'
import { CirclePicker } from 'react-color';

const LineStyleCustomizer  = forwardRef((props, ref) => {

    const { lineStyle } = {...props}

    const checkDefaultValue = (field, defaultValue) => {
        let value = defaultValue
        if (lineStyle && lineStyle[field] !== undefined){
            value = lineStyle[field]
        }
        return value
    }

    const defaultCheckField = (field) => {
        let checked = true
        if (lineStyle && lineStyle[field] !== undefined){
            if (!lineStyle[field]){
                checked=false
            }
        }
        return checked
    }

    const checkIfPolylineStyleContainsField = (field) => {
        /* Used for line's fill attribute, in most cases prefered behaviour is to leave fill empty */
        let contains = false
        if (lineStyle && lineStyle[field] !== undefined){
            if (lineStyle[field]){
                contains=true
            }
        }
        return contains
    }

    const [strokeColor, setStrokeColor] = useState(checkDefaultValue("color", "#673ab7"))
    const [fillColor, setFillColor] = useState(checkDefaultValue("fillColor", "#00bcd4"))

    const strokeVisibleRef = useRef(null)
    const fillVisibleRef = useRef(null)
    const strokeRef = useRef(null)
    const strokeOpacityRef = useRef(null)
    const fillOpacityRef = useRef(null)
  
    const { t } = useTranslation(['map'])

    const handleFillColorChange = (e) => {
        setFillColor(e)
    }

    const handleStrokeColorChange = (e) => {
        setStrokeColor(e)
    }

    const updateChanges = () => {
        props.setLineStyle({
            stroke: strokeVisibleRef.current.checked,
            color: strokeColor,
            opacity: strokeOpacityRef.current.value,
            weight: strokeRef.current.value,
            fill: fillVisibleRef.current.checked,
            fillColor: fillColor,
            fillOpacity: fillOpacityRef.current.value
        })
    }

    return (
        <div>
            <div className="d-flex flex-column justify-content-between flex-md-row mt-2 mb-1">
                <b>{t('map:polyline-customizer-title')}</b>
            </div>
            <div className="ml-3 mb-2">                
                <div className="mt-3 mb-3">
                    <Form.Group className='fluid' >
                        <Form.Label htmlFor={"polyline-customizer-stroke-visible"} className="mb-1">
                            {t('map:polyline-customizer-stroke-visible')}
                        </Form.Label>
                        <Form.Check id={"polyline-customizer-stroke-visible"} ref={strokeVisibleRef} type="checkbox" defaultChecked={defaultCheckField("stroke")} />
                    </Form.Group>
                </div>
                <div className="mt-3 mb-3">
                    <div className="mb-1">
                        {t('map:polyline-customizer-stroke-color')} <span className="color-picker-tip" style={{backgroundColor: strokeColor}} />
                    </div>
                    <CirclePicker onChange={color => handleStrokeColorChange(color.hex)} />
                </div>
                <div className="mt-3 mb-3">
                    <Form.Group className='fluid' >
                        <Form.Label htmlFor={"polyline-customizer-stroke-weight"} className="mb-1">
                            {t('map:polyline-customizer-stroke-weight')}
                        </Form.Label>
                        <Form.Control id={"polyline-customizer-stroke-weight"} ref={strokeRef} type="number" min="1" max="30" step="1" defaultValue={checkDefaultValue("weight", 5)} />
                    </Form.Group>
                </div>
                <div className="mt-3 mb-3">                    
                    <Form.Group className='fluid' >
                        <Form.Label htmlFor={"polyline-customizer-stroke-opacity"} className="mb-1">
                            {t('map:polyline-customizer-stroke-opacity')}
                        </Form.Label>
                        <Form.Control id={"polyline-customizer-stroke-opacity"} ref={strokeOpacityRef} type="number" min="0.05" max="1" step="0.05" defaultValue={checkDefaultValue("opacity", 0.5)} />
                    </Form.Group>
                </div>
                <div className="mt-3 mb-3">
                    <Form.Group >
                        <Form.Label htmlFor={"polyline-customizer-fill-visible"} className="mb-1">
                            {t('map:polyline-customizer-fill-visible')}
                        </Form.Label>
                        <Form.Check id={"polyline-customizer-fill-visible"} ref={fillVisibleRef} type="checkbox" defaultChecked={checkIfPolylineStyleContainsField("fill")} />
                    </Form.Group>
                </div>
                <div className="mt-3 mb-3">
                    <div className="mb-1">
                        {t('map:polyline-customizer-fill-color')} <span className="color-picker-tip" style={{backgroundColor: fillColor}} />
                    </div>
                    <CirclePicker onChange={color => handleFillColorChange(color.hex)} />
                </div>
                <div className="mt-3 mb-3">
                    <Form.Group className='fluid' >
                        <Form.Label htmlFor={"polyline-customizer-fill-opacity"} className="mb-1">
                            {t('map:polyline-customizer-fill-opacity')}
                        </Form.Label>
                        <Form.Control id={"polyline-customizer-fill-opacity"} ref={fillOpacityRef} type="number" min="0.05" max="1" step="0.05" defaultValue={checkDefaultValue("fillOpacity", 0.5)} />
                    </Form.Group>
                </div>
                <Button onClick={() => updateChanges()} className="w-100 mb-2 mt-2">
                    {t('map:customizer-save-changes')}
                </Button>
            </div>
        </div>
    )

})


const mapStateToProps = state => ({
    lineStyle: state.map.lineStyle
})

const mapDispatchToProps = dispatch => ({
    setLineStyle: (data) => dispatch(setMapLineStyle(data))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(LineStyleCustomizer)