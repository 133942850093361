import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Button, FormLabel, Spinner } from  "react-bootstrap"; 
import * as notify from '../../../utils/notify'
import useApi from '../../../hooks/useApi';
import useMapRedux from '../../../hooks/useMapRedux';
import useMapDefinition from '../../../hooks/useMapDefinition';
import { URL_API_MAP_CREATE } from '../../../urls'

const SaveMapAsPanel = props => {
  const { t } = useTranslation(['map']);
  const { setValue: setMapId } = useMapRedux('mapId');
  const { value: mapTitle, setValue: setMapTitle } = useMapRedux('mapTitle');
  const { getCurrentMapDefinition } = useMapDefinition();
  const [ name, setName ] = useState('')
  const { refetch, loading } = useApi('post', URL_API_MAP_CREATE);

  useEffect(() => {
    setName(mapTitle || '')
  }, [mapTitle]);

  const saveMap = () => {
    refetch({
      name,
      definition: getCurrentMapDefinition(props.drawingsLayer),
    })
      .then(res => {
        notify.success(t('map:map-saved'))
        setMapId(res?.data?.id)
        setMapTitle(name)
      })
      .catch(err => {
        if (err?.response?.status === 409) {
          notify.error(t('map:map-name-exists'))
        }
        else {
          notify.error(t('map:cannot-save-map'))
        }
      })
  }
  return(
    <>
      <Form.Group controlId='map-name'>
        <FormLabel>{t('map:map-name')}</FormLabel>
        <Form.Control
          value={name}
          onChange={e => setName(e.target.value)}
          disabled={loading}
        ></Form.Control>
      </Form.Group>
      <Form.Group>
        <Button
          onClick={saveMap}
          disabled={loading || !name}
        >{t('map:save')}
          {loading &&
              <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="ml-1"
              />
          }
        </Button>
      </Form.Group>
    </>
  )
}
export default SaveMapAsPanel
