import React, { useState } from "react"
import { Form, FormLabel, Container, Row, Col, Button, Spinner } from "react-bootstrap"
import { connect } from 'react-redux'
import { loginSuccess, loginFailed } from '../actions/user'
import * as actions from '../actions'
import { useTranslation } from 'react-i18next'
import { isPending } from "../reducers";
import { Link } from "react-router-dom"
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import "../styles/fb.scss"
import {
    GOOGLE_LOGIN_CLIENT_ID,
    FACEBOOK_LOGIN_APP_ID,
    GOOGLE_OAUTH2_REDIRECT_URL,
    PORTAL_BASE_URL,
    PASSWORD_REMINDER_PAGE_URL_EN,
    APPLE_LOGIN_CLIENT_ID,
    API_BASE_URL,
} from '../settings'
import SEO from "./SEO"
import AppleLogin from 'react-apple-login'

const LoginForm = props => {
    const { t } = useTranslation([])
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const onUsernameChange = e => {
        setUsername(e.target.value)
    }

    const onPasswordChange = e => {
        setPassword(e.target.value)
    }

    const onSubmit = e => {
        e.preventDefault()
        props.userLogin({username: username, password: password}, t('common:wrong-user-pass'))
    }

    const redirectGoogle = () => {
        // redirect to google OAuth2 
        window.location.href = "https://accounts.google.com/o/oauth2/v2/auth" +
            "?scope=" + encodeURI("openid profile email") +
            "&access_type=offline" +
            "&include_granted_scopes=true" +
            "&prompt=consent" +
            "&response_type=code" +
            "&state=" + encodeURI(props.return ? props.return : "") +
            "&redirect_uri=" + PORTAL_BASE_URL + GOOGLE_OAUTH2_REDIRECT_URL +
            "&client_id=" + GOOGLE_LOGIN_CLIENT_ID
    }

    const responseFacebook = response => {
        if (response.accessToken) {
            props.userLoginWithFacebook({ token: response.accessToken }, t('common:wrong-user-pass'))
        }
    }

    return (
        <Form>
            <SEO title={t('login')} />
            <Container>
                <Row>
                    <Col md={{ span: 4, offset: 4 }}>
                        <h1>{t('login')}</h1>
                        <Form.Group>
                            <FormLabel htmlFor="username">{t('common:username')}</FormLabel> 
                            <Form.Control autoFocus onChange={onUsernameChange} value={username} name="username" id="username" type="text" disabled={props.loading} autoComplete="off"></Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <FormLabel htmlFor="password">{t('common:password')}</FormLabel>
                            <Form.Control onChange={onPasswordChange} value={password} name="password" id="password" type="password" disabled={props.loading} autoComplete="off"></Form.Control>
                        </Form.Group>
                        <Button variant="primary" onClick={onSubmit} disabled={props.loading} type="submit">
                            {t('common:login')}
                            {props.loading &&
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className="ml-1"
                                />
                            }
                        </Button>
                        <Form.Group>
                            <div className="mt-2">{t('common:no_account_yet')} <Link to={t('paths:register')}>{t('common:please_register')}</Link></div>
                        </Form.Group>
                        <Form.Group>
                            <div className="mt-2">{t('common:not_remember_password')} <Link to={PASSWORD_REMINDER_PAGE_URL_EN+"/"}>{t('common:please_remind_me')}</Link></div>
                        </Form.Group>
                        <Form.Group>
                            <button onClick={redirectGoogle} type="button" className="my-facebook-button-class" disabled={props.loading}>
                                <div className="my-facebook-icon-class">
                                    <img src="/google.png" width="18" alt="Google Logo" />
                                </div>
                                <span className="my-facebook-text-class">{t('common:login_with_google')}</span>
                            </button>
                        </Form.Group>
                        <Form.Group>
                            <FacebookLogin
                                appId={FACEBOOK_LOGIN_APP_ID}
                                fields="name,email,picture"
                                callback={responseFacebook}
                                isDisabled={props.loading}
                                render={renderProps => (
                                    <button onClick={renderProps.onClick} type="button" className="my-facebook-button-class" disabled={renderProps.isDisabled}>
                                        <div className="my-facebook-icon-class">
                                            <img src="/facebook.png" width="18" alt="Facebook Login" />
                                        </div>
                                        <span className="my-facebook-text-class">{t('login_with_facebook')}</span>
                                    </button>
                                )}
                            //onClick={() => console.log('FB clicked')}
                            />
                        </Form.Group>
                        <Form.Group>
                            <AppleLogin
                                clientId={APPLE_LOGIN_CLIENT_ID}
                                redirectURI={API_BASE_URL + '/apple_callback/'}
                                scope="email name"
                                responseMode="form_post"
                                state={encodeURI(props.return ? props.return : "")}
                                render={renderProps => (
                                    <button onClick={renderProps.onClick} type="button" className="my-facebook-button-class" disabled={props.loading}>
                                        <div className="my-facebook-icon-class">
                                            <img src="/apple.jpg" width="18" alt="Apple Login" />
                                        </div>
                                        <span className="my-facebook-text-class">{t('common:login_with_apple')}</span>
                                    </button>
                                )}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Container>
        </Form >
    )
}

const mapStateToProps = state => ({
    loading: (isPending('login', state) || isPending('login-with-google', state) || isPending('login-with-facebook', state)),
    return: state.user ? state.user.return : null,
})

const mapDispatchToProps = dispatch => ({
    userLogin: (data, msg) => dispatch(actions.postData('login', '/login/', data))
        .then(data => dispatch(loginSuccess(data)))
        .catch(() => dispatch(loginFailed(msg))),
    userLoginWithFacebook: (data, t) => dispatch(actions.postData('login-with-facebook', '/login_with_facebook/', data))
        .then(data => dispatch(loginSuccess(data)))
        .catch(() => dispatch(loginFailed(t('wrong-user-pass')))),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginForm)
