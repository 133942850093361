import React, { useState, forwardRef } from "react"
import { connect } from 'react-redux'
import { Row, Spinner, Button, Col, Form, Collapse } from "react-bootstrap";
import {
    COLLECTION_TYPES_REQUEST_ID,
    TAXONDB_DETAILS_REQUEST_URL,
    MAP_DATA_REQUEST_TIMEOUT,
    MAP_COLORPICKER_LIST,
    TAXONDB_SEARCH_GENUS_REQUEST_ID,
    TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID,
    TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID,
    TAXON_SEARCH_IS_DYNAMIC,
    MAP_OPENED_TOOL_VISUALIZATION_DETAILS,
} from "../../../../settings"
import MarkerLabels from '../../MarkerLabels'
import { clearMapLabels, setHistoricalMarkerColor, setHistoricalMarkerIcon, setHistoricalMarkerSize, setOpenedMapTool } from '../../../../actions/map'
import { getField, isPending, getFields } from "../../../../reducers";
import { useTranslation } from 'react-i18next'
import { runSearchMap, setFields } from '../../../../actions/forms'
import { getOutput } from "../../../../reducers/"
import * as actions from '../../../../actions'
import { CirclePicker } from 'react-color';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleRight, faMapMarked } from "@fortawesome/free-solid-svg-icons";
import { clusteringSearchItems } from "./ClusteringSearch"

const ClusteringMap = forwardRef((props, ref) => {

    const { t, i18n } = useTranslation(['map', 'taxondb-attributes', 'taxondb-groups', 'common'])
    const { collapsed, openSlidebar, closeSlidebar, markerColor, markerIcon, markerSize, setActiveMapTool, activeMapTool } = { ...props }
    const [showLabels, setShowLabels] = useState(false)

    return(
        <div>
             {collapsed &&
                <div className="sidebar-single-button-container">
                    <button
                    title={t('map:visualisation-parameters') + ' - ' + t('menu:clustering')}
                    className="sidebar-button-collapsed"
                        onClick={() => {
                            openSlidebar()
                            setActiveMapTool(MAP_OPENED_TOOL_VISUALIZATION_DETAILS)
                        }} 
                    >
                    <FontAwesomeIcon icon={faMapMarked} color={"white"}/>
                    </button>
                </div>
            }
            {!collapsed &&
                <div>
                    <div className="sidebar-opened-buttons-container">
                        <div className="sidebar-single-button-container">
                            <button
                                title={t('map:visualisation-parameters') + ' - ' + t('menu:clustering')}
                                className="sidebar-button-collapsed"
                                onClick={() => {activeMapTool === MAP_OPENED_TOOL_VISUALIZATION_DETAILS ? setActiveMapTool(null) : setActiveMapTool(MAP_OPENED_TOOL_VISUALIZATION_DETAILS)}}
                            >
                                <FontAwesomeIcon icon={faMapMarked} color={"white"}/>
                            </button>
                        </div>
                        <div
                            className="sidebar-text playContentOpacityAnimation changeContentOpacityAnimation"
                            onClick={() => {activeMapTool === MAP_OPENED_TOOL_VISUALIZATION_DETAILS ? setActiveMapTool(null) : setActiveMapTool(MAP_OPENED_TOOL_VISUALIZATION_DETAILS)}}
                        >  
                            {t('map:visualisation-parameters') + ' - ' + t('menu:clustering')}
                        </div> 

                    <div className="sidebar-items-arrow changeOpacityAnimation playOpacityAnimation">
                        <FontAwesomeIcon icon={activeMapTool === MAP_OPENED_TOOL_VISUALIZATION_DETAILS ? faAngleDown : faAngleRight} color={"black"}/>
                    </div>
                </div>
                <Collapse in={activeMapTool === MAP_OPENED_TOOL_VISUALIZATION_DETAILS}>
                    <div className="ml-4 playContentOpacityAnimation changeContentOpacityAnimation"> 
                        <div>
                            <Row>
                                <Form.Group as={Col} sm={6} md={6}>
                                    <Form.Label htmlFor={'markerIcon'}>
                                        {t("map:select-icon-shape")}
                                    </Form.Label>
                                    <Form.Control as="select" size="sm" custom multiple
                                        defaultValue={[markerIcon]}
                                        id={'markerIcon'} 
                                        name={'markerIcon'}
                                        className='fa form-control'
                                        disabled={props.loading1 || props.loading2 || props.loading3}
                                        onChange={(e) => {
                                                if (e.target.value){
                                                    props.setHistoricalMarkerIcon(e.target.value)
                                                }
                                            }
                                        }
                                    >
                                        <option value="fa fa-map-marker">&#xf041;</option>
                                        <option value="fa fa-map-pin">&#xf276;</option>
                                        <option value="fa fa-thumb-tack">&#xf08d;</option>
                                        <option value="fa fa-star">&#xf005;</option>
                                        <option value="fa fa-star-o">&#xf006;</option>
                                        <option value="fa fa-circle">&#xf111;</option>
                                        <option value="fa fa-circle-o">&#xf10c;</option>
                                        <option value="fa fa-bullseye">&#xf140;</option>
                                        <option value="fa fa-square">&#xf0c8;</option>
                                        <option value="fa fa-square-o">&#xf096;</option>
                                        <option value="fa fa-cube">&#xf1b2;</option>
                                        <option value="fa fa-bookmark">&#xf02e;</option>
                                        <option value="fa fa-asterisk">&#xf069;</option>
                                        <option value="fa fa-times">&#xf00d;</option>
                                        <option value="fa fa-plus">&#xf067;</option>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group as={Col} sm={6} md={6}>
                                    <Form.Label htmlFor="markerColor">
                                        {t("map:select-icon-color")} <span className="color-picker-tip" style={{backgroundColor: markerColor}} />
                                    </Form.Label>
                                    <CirclePicker 
                                        id={"markerColor"}
                                        name={'markerColor'}
                                        onChange={color => props.setHistoricalMarkerColor(color.hex)}
                                        colors={MAP_COLORPICKER_LIST}
                                        width={"100%"}
                                        circleSize={24}
                                    />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} sm={6} md={6}>
                                    <Form.Label htmlFor={'iconSize'}>
                                        {t("map:select-icon-size")}
                                    </Form.Label>
                                    <Form.Control as="select" size="sm" custom
                                        defaultValue={markerSize}
                                        id={'iconSize'} 
                                        name={'iconSize'}
                                        className="form-control"
                                        disabled={props.loading1 || props.loading2 || props.loading3}
                                        onChange={(e) => {
                                                if (e.target.value){
                                                    props.setHistoricalMarkerSize(e.target.value)
                                                }
                                            }
                                        }
                                    >
                                        <option value="fa-sm">{t("map:map-icon-small")}</option>
                                        <option value="fa-lg">{t("map:map-icon-medium")}</option>
                                        <option value="fa-2x">{t("map:map-icon-large")}</option>
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group as={Col} sm={6} md={6}>
                                    <Form.Label htmlFor={'showLabels'}>
                                        <div>{t("map:edit-attributes-label")}</div>
                                    </Form.Label>
                                    <Form.Check
                                        type="checkbox" 
                                        id="showLabels"
                                        defaultChecked={showLabels}
                                        onChange={() => setShowLabels(!showLabels)}
                                        disabled={props.loading1 || props.loading2 || props.loading3}
                                        label={t("map:show-labels-panel")}
                                        name={"show-map-labels"}
                                    /> 
                                </Form.Group>
                            </Row>

                            <Row>
                                <Col>
                                    {showLabels &&
                                        <Form.Group as={Col}>
                                            <div className="mt-1 mb-1">
                                                {t("map:select-labels-info")}
                                            </div>
                                            <MarkerLabels closeSlidebar={closeSlidebar} />
                                        </Form.Group>
                                    }
                                </Col>
                            </Row>
                        </div>
                        <Row>
                            <Col md={12} sm={12} className="d-flex align-items-end" >
                                <div className="flex-grow-1" />
                                {(props.loading1 || props.loading2 || props.loading3) &&
                                    <Spinner 
                                        animation="border"
                                        size="sm"
                                        aria-hidden="true"
                                        className="cartogram-button mb-3 mr-2"
                                    />
                                }
                                <Button
                                    className="cartogram-button mb-3 ml-2 mr-3"
                                    onClick={() => clusteringSearchItems(ref, props, t, i18n)}
                                    disabled={props.loading1 || props.loading2 || props.loading3}
                                >
                                    {t("map:show-on-map")}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Collapse>
            </div>
            }
        </div>
    )
})

const mapStateToProps = state => ({
    filter1: getFields(TAXONDB_SEARCH_GENUS_REQUEST_ID, state),
    filter2: JSON.parse(getField(TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID, TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID, state, "null")),
    filter3: JSON.parse(getField(TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, state, "null")),
    isDynamic: getField(TAXON_SEARCH_IS_DYNAMIC, TAXON_SEARCH_IS_DYNAMIC, state, "0"),
    loading1: isPending(TAXONDB_SEARCH_GENUS_REQUEST_ID, state),
    loading2: isPending(TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID, state),
    loading3: isPending(TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, state),
    fields1: getFields(TAXONDB_SEARCH_GENUS_REQUEST_ID, state),
    fields2: getFields(TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID, state),
    fields3: getFields(TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, state),
    mapLabels: state.map.mapLabels,
    collectionTypes: getOutput(COLLECTION_TYPES_REQUEST_ID, state),
    markerColor: state.map.historicalMarkerColor,
    markerIcon: state.map.historicalMarkerIcon,
    markerSize: state.map.historicalMarkerSize,
    activeMapTool: state.map.openedMapTool,
})

const mapDispatchToProps = dispatch => ({
    clearMapLabels: () => dispatch(clearMapLabels()),
    runSearchMap: (id) => dispatch(runSearchMap(id, MAP_DATA_REQUEST_TIMEOUT)),
    getDetails: recordId => dispatch(actions.getDataAnc('popup', TAXONDB_DETAILS_REQUEST_URL + recordId + '/')),
    setFields: (id, val) => dispatch(setFields(id, val)),
    clearResponse: (id) => dispatch(actions.requestRemove(id)),
    setHistoricalMarkerIcon: data => dispatch(setHistoricalMarkerIcon(data)), 
    setHistoricalMarkerColor: data => dispatch(setHistoricalMarkerColor(data)),
    setHistoricalMarkerSize: data => dispatch(setHistoricalMarkerSize(data)),
    setActiveMapTool: (value) => dispatch(setOpenedMapTool(value)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(ClusteringMap)
