import React, { Suspense, useState } from 'react'
import { Collapse, Container, ListGroup, ListGroupItem, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { getDataAnc, postDataAnc } from '../../actions'
import { getItems, getPagination, isPending } from '../../reducers'
import { ANC_URL_POSTFIX } from '../../settings'
import Loader from '../../utils/loader'
import ConnectedPagination from '../ConnectedPagination'
import RecordDetailsContainer from './RecordDetailsContainer'

const ResultList = props => {
    const { t } = useTranslation([ 'common', 'db-history', 'a11y' ])

    const [open, setOpen] = useState({})
    const [isOpened, setIsOpened] = useState({})

    const toggleOpen = (recordId, id) => {
        const temp = { ...open }
        temp[ id ] = !temp[ id ]
        setOpen(temp)
        if(temp[id])
            props.getDetails(recordId, id)
    }

    return(
        <Suspense fallback={<Loader />}>
            <Container className="d-flex flex-column mt-2">
                {props.loading && <div className="align-self-center"><Spinner animation="border" role="status" /></div>}
                {!props.loading && 
                <div>
                    {props.items && props.items.length === 0 &&
                    <div><p>{t('no-records-found')}</p></div>
                    }
                    {props.items && props.items.length > 0 &&
                    <div>
                        <ConnectedPagination requestId={props.requestId} url={ANC_URL_POSTFIX + props.requestUrl}  customFilter={{filter: props.filter}}></ConnectedPagination>
                        <ListGroup>
                            {
                                props.items.map((item)=>{
                                    const requestId = props.detailsId+item.id
                                    let label = item.kolekcjanumerokazu
                                    if(!label)
                                        label = item.numer_proby
                                    if(!label)
                                        label = item.cytowanie
                                    return (
                                        <div key={requestId}>
                                            <ListGroupItem tabIndex={0} action onClick={() => toggleOpen(item.id, requestId)}>
                                            <div className="d-flex">
                                                <div className="w-75">
                                                    {open[ requestId ] &&
                                                        <img src="/images/list-open.svg" alt={t("a11y:list-opened")} />
                                                    }
                                                    {!open[ requestId ] &&
                                                        <img src="/images/list-closed.svg" alt={t("a11y:list-closed")} />
                                                    }
                                                    {label}: {t('db-history:et-'+item.edition_type)}
                                                </div>
                                                <div className="w-25 text-right">
                                                    {item.data_godzina}
                                                </div>
                                            </div>
                                            
                                            </ListGroupItem>
                                            <Collapse in={open[ requestId ]} id={requestId}
                                            onEntering={() => setIsOpened({ ...isOpened, [ requestId ]: true })}
                                            onExited={() => setIsOpened({ ...isOpened, [ requestId ]: false })}
                                            >
                                            <Suspense fallback={<Loader />}>
                                                {(open[ requestId ] || isOpened[ requestId ]) &&
                                                <div className="d-flex flex-column">
                                                    <RecordDetailsContainer requestId={requestId} recordId={item.id} atrTrans={props.atrTrans}editData={item}></RecordDetailsContainer>
                                                </div>
                                                }
                                            </Suspense>
                                            </Collapse>
                                        </div>
                                    )
                                })
                            }
                        </ListGroup>
                        <ConnectedPagination last requestId={props.requestId} url={ANC_URL_POSTFIX + props.requestUrl} customFilter={{filter: props.filter}}></ConnectedPagination>
                    </div>
                    }
                </div>
                }
            </Container>
        </Suspense>
    )
}

const mapStateToProps = (state, ownProps) => ({
    items: getItems(ownProps.requestId, state),
    loading: isPending(ownProps.requestId, state),
    pagination: getPagination(ownProps.requestId, state),
})
  
const mapDispatchToProps = (dispatch, ownProps) => ({
    search: data => dispatch(postDataAnc(ownProps.requestId, ownProps.requestUrl, data)),
    getDetails: (recordId, requestId) => dispatch(getDataAnc(requestId, ownProps.detailsUrl + recordId + '/')),
})
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResultList)