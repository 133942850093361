import React, { useEffect,  } from "react"
import { Container, Spinner } from "react-bootstrap"
import { connect } from 'react-redux'
import * as actions from '../actions'
import { useTranslation } from 'react-i18next'
import { isPending, getOutput } from "../reducers"
import SEO from "./SEO"
import { useParams } from 'react-router-dom';

const RegistrationConfirm = props => {

    const { clearResponse, confirm, location } = props
    const { hash } = useParams()

    useEffect(() => {
        confirm({ confirm: hash })

        return clearResponse
    }, [clearResponse, confirm, location, hash])

    const { t } = useTranslation(['common'])

    return (
        <Container className="d-flex flex-column mt-2">
            {props.loading &&
                <div className="align-self-center">
                    <Spinner animation="border" role="status" />
                </div>
            }
            {!props.loading && props.response && props.response.result === 0 &&
                <>
                <SEO title={t('common:user_confirmed')} />
                <div className="pt-5 pb-5 align-self-center">{t('common:user_confirmed')}</div>
                </>
            }
            {!props.loading && props.response && props.response.result === 1 &&
                <>
                <SEO title={t('common:user_not_confirmed')} />
                <div className="pt-5 pb-5 align-self-center">{t('common:user_not_confirmed')}</div>
                </>
            }
            {!props.loading && (!props.response || props.response.result === 2) &&
                <>
                <SEO title={t('common:user_confirmation_expired')} />
                <div className="pt-5 pb-5 align-self-center">{t('common:user_confirmation_expired')}</div>
                </>
            }
        </Container>
    )
}

const mapStateToProps = state => ({
    loading: isPending('confirm', state),
    response: getOutput('confirm', state)
})

const mapDispatchToProps = dispatch => ({
    confirm: data => dispatch(actions.postDataApi('confirm', '/confirm/', data)),
    clearResponse: () => dispatch(actions.requestRemove('confirm')),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegistrationConfirm)
