import React, { useEffect, useState, useCallback } from "react"
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { isPending, getOutputOrDefault } from "../../reducers"
import { postDataAnc } from '../../actions'
import { Form, Col, Button } from "react-bootstrap"
import {
    COLLECTION_TYPES_REQUEST_ID,
    TAXONDB_GET_COLLECTION_SUBTYPES_PREFIX,
    TAXONDB_GET_COLLECTION_SUBTYPES_URL,
    COLLECTION_SUBTYPES_EDIT_REQUEST_ID,
    COLLECTION_SUBTYPES_EDIT_REQUEST_URL,
    TAXONDB_GET_COLLECTIONS_PREFIX,
    TAXONDB_GET_COLLECTIONS_URL,
} from '../../settings'
import * as notify from '../../utils/notify'

const EditSubtypes = props => {
    const { t } = useTranslation([ 'collections' ])
    const { collectionTypes, collectionSubtypes, parentTypeId, typeId } = props
    const [ type, setType ] = useState(parentTypeId)
    const [ kind, setKind ] = useState('')
    const [ descriptionPl, setDescriptionPl ] = useState('')
    const [ descriptionEn, setDescriptionEn ] = useState('')

    const getKindForTypeCallback = useCallback((type) => {
        if ((collectionTypes === null || collectionTypes.length === 0) || type === '') {
            return ''
        } else {
            return collectionTypes.filter(t => t.typ_kolekcji === type)[ 0 ]?.rodzaj_kolekcji || ''
        }
    }, [collectionTypes]);

    const getDescriptionPlForTypeCallback = useCallback((type) => {
        if ((collectionSubtypes === null || collectionSubtypes.length === 0) || type === '') {
            return descriptionPl
        } else {
            return collectionSubtypes.filter(t => t.podtyp_kolekcji === type)[ 0 ]?.opis_pl || descriptionPl
        }
    }, [collectionSubtypes, descriptionPl]);

    const getDescriptionEnForTypeCallback = useCallback((type) => {
        if ((collectionSubtypes === null || collectionSubtypes.length === 0) || type === '') {
            return descriptionEn
        } else {
            return collectionSubtypes.filter(t => t.podtyp_kolekcji === type)[ 0 ]?.opis_eng || descriptionEn
        }
    }, [collectionSubtypes, descriptionEn]);

    const getSubtypesCallback = useCallback(props.getSubtypes, [])
    useEffect(() => {
        if (collectionTypes && collectionTypes.length > 0 && kind === '') {
            setKind(getKindForTypeCallback(parentTypeId))
            getSubtypesCallback(parentTypeId)
        }

    }, [ collectionTypes, kind, parentTypeId, getKindForTypeCallback, getSubtypesCallback])

    useEffect(() => {
        if (collectionSubtypes && collectionSubtypes.length > 0 && descriptionPl === '' && descriptionEn === '') {
            setDescriptionPl(getDescriptionPlForTypeCallback(typeId))
            setDescriptionEn(getDescriptionEnForTypeCallback(typeId))
        }
    }, [ collectionSubtypes, descriptionPl, descriptionEn, typeId, getDescriptionPlForTypeCallback, getDescriptionEnForTypeCallback])

    const onTypeChange = e => {
        setType(e.target.value)
        setKind(getKindForTypeCallback(e.target.value))
        getSubtypesCallback(e.target.value)
    }

    const onDescriptionPlChange = e => {
        setDescriptionPl(e.target.value)
    }

    const onDescriptionEnChange = e => {
        setDescriptionEn(e.target.value)
    }

    const getCollectionTypes = () => {
        if (collectionTypes === null) {
            return []
        } else {
            return collectionTypes.filter(t => t.rodzaj_kolekcji === kind)
        }
    }

    const onSubmitForm = () => {
        props.editCollectionSubtype(type, typeId, descriptionPl, descriptionEn, t)
    }

    return (
        <Form className="mt-2 p-1 mb-2" onSubmit={e => { e.preventDefault() }}>
            <Form.Row>
                <Col xs={6}>
                    <Form.Group>
                        <Form.Label htmlFor="collectionSubtypeType">{t('collections:type')}</Form.Label>
                        <Form.Control id="collectionSubtypeType" as="select" value={type} onChange={onTypeChange} disabled={props.querying}>
                            {getCollectionTypes().map((type, i) =>
                                <option key={i} value={type.typ_kolekcji}>{type.typ_kolekcji}</option>
                            )}
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col xs={6}></Col>
            </Form.Row>
            <Form.Row>
                <Col>
                    <Form.Group>
                        <Form.Label htmlFor="collectionSubtypeDescriptionPl">{t('collections:description-pl')}</Form.Label>
                        <Form.Control id="collectionSubtypeDescriptionPl" value={descriptionPl} onChange={onDescriptionPlChange} disabled={props.querying}></Form.Control>
                    </Form.Group>
                </Col>
            </Form.Row>
            <Form.Row>
                <Col>
                    <Form.Group>
                        <Form.Label htmlFor="collectionSubtypeDescriptionEn">{t('collections:description-en')}</Form.Label>
                        <Form.Control id="collectionSubtypeDescriptionEn" value={descriptionEn} onChange={onDescriptionEnChange} disabled={props.querying}></Form.Control>
                    </Form.Group>
                </Col>
            </Form.Row>
            <Form.Row className="d-flex">
                <Col className="text-right">
                    <Button size="sm" disabled={props.querying} onClick={onSubmitForm}>{t('collections:save-collection-subtype')}</Button>
                </Col>
            </Form.Row>
        </Form>
    )
}

const mapStateToProps = state => ({
    collectionTypes: getOutputOrDefault(COLLECTION_TYPES_REQUEST_ID, state, []),
    collectionSubtypes: getOutputOrDefault(TAXONDB_GET_COLLECTION_SUBTYPES_PREFIX, state, []),
    querying: isPending(COLLECTION_TYPES_REQUEST_ID, state) || isPending(COLLECTION_SUBTYPES_EDIT_REQUEST_ID, state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    getSubtypes: type => dispatch(postDataAnc(
        TAXONDB_GET_COLLECTION_SUBTYPES_PREFIX,
        TAXONDB_GET_COLLECTION_SUBTYPES_URL,
        { collection_type: type })),
    editCollectionSubtype: (collectionType, collectionSubtype, descriptionPl, descriptionEn, t) => {
        return dispatch(postDataAnc(COLLECTION_SUBTYPES_EDIT_REQUEST_ID, COLLECTION_SUBTYPES_EDIT_REQUEST_URL, {
            collection_type: collectionType,
            collection_subtype: collectionSubtype,
            description_pl: descriptionPl,
            description_eng: descriptionEn
        }))
            .then(() => dispatch(postDataAnc(
                TAXONDB_GET_COLLECTION_SUBTYPES_PREFIX + collectionType,
                TAXONDB_GET_COLLECTION_SUBTYPES_URL,
                { collection_type: collectionType })))
            .then(() => dispatch(postDataAnc(
                TAXONDB_GET_COLLECTIONS_PREFIX + collectionType,
                TAXONDB_GET_COLLECTIONS_URL,
                { collection_type: collectionType })))
            .then(() => {
                ownProps.onDone && ownProps.onDone()
                notify.success(t('collections:edited-collection-subtype'))
            }).catch(res => {
                notify.error(t('collections:cannot-edit-collection-subtype'), res.result)
            })
    }
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditSubtypes)
