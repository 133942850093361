import React, { useState, useRef } from "react";
import { useTranslation } from 'react-i18next'
import { Button, Popover, Overlay, Spinner } from "react-bootstrap"

const Confirm = props => {
    const { t } = useTranslation(['forms'])

    const [show, setShow] = useState(false)
    const target = useRef(null)

    return (
        <div>
            <span
                className={!props.children ? (props.className + ' btn btn-danger button-cursor btn-' + props.size + (props.disabled ? ' disabled' : '')) : ''}
                ref={target}
                tabIndex="0"
                aria-label={props.WCAGtext}
                onKeyPress={e => {
                    if (!props.disabled && e.key === ' ') {
                        setShow(!show)
                    }
                    e.stopPropagation()
                }}
                onClick={e => {
                    if (!props.disabled) {
                        setShow(!show)
                    }
                    e.stopPropagation()
                }}>
                {props.children &&
                    <>{props.children}</>
                }
                {!props.children &&
                    <>{props.buttonLabel}</>
                }
                {props.pending &&
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="ml-1"
                    />
                }
            </span>
            <Overlay target={target.current} show={show} placement="auto">
                <Popover>
                    <Popover.Content>
                        <div>{props.confirmationText}</div>
                        <div>
                            <span
                                className={'m-2 btn btn-danger btn-sm button-cursor ' + (props.disabled ? ' disabled' : '')}
                                disabled={props.disabled}
                                tabIndex="0"
                                onKeyPress={e => {
                                    if (!props.disabled && e.key === ' ') {
                                        props.onConfirm()
                                        setShow(false)
                                    }
                                    e.stopPropagation()
                                }}
                                onClick={e => {
                                    if (!props.disabled) {
                                        props.onConfirm()
                                        setShow(false)
                                    }
                                    e.stopPropagation()
                                }}>{props.confirmButtonLabel || props.buttonLabel}</span>
                            <Button variant="secondary" size="sm" className="m-2" onClick={e => {
                                if (!props.disabled) {
                                    setShow(false)
                                }
                                e.stopPropagation()
                            }}>{t('cancel')}</Button>
                        </div>
                    </Popover.Content>
                </Popover>
            </Overlay>
        </div >
    )
}

export default Confirm
