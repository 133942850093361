import React, { useState } from "react"
import { useForm, useFieldArray, FormProvider, useFormContext } from 'react-hook-form'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Tab, Tabs, Button, Form, Spinner, Row } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons'
import { diff } from 'deep-object-diff'
import {
    GPS_COORDINATES_ATTRIBUTE_NAME,
    LATITUDE_ATTRIBUTE_NAME,
    LONGITUDE_ATTRIBUTE_NAME,
    COORDINATES_PRECISION,
} from './attributesStructure'
import { decodeLatitude, decodeLongitude } from '../../utils/gps'
import { dateToString } from '../../utils/date'
import { handleTabsA11y } from '../../utils/handleTabsA11y'
import MarkerMap from './MarkerMap'
import Confirm from '../Confirm'
import ValueFromDictionary, { GetValueFromDictionary } from "../dictionary/ValueFromDictionary"
import {
    ICONDB_SEARCH_REQUEST_ID,
    ICONDB_SEARCH_REQUEST_URL,
    ICON_RESULTS_ACTIVE_RECORD,
    USER_GROUPS_EDITOR_CONST,
    ICONDB_CHANGE_REQUEST_ID,
    ICONDB_CHANGE_REQUEST_URL,
    GENERAL_EXTENDED_REQUEST_TIMEOUT
} from '../../settings'
import { postDataDlibra, getData } from '../../actions'
import { setFields, setField } from '../../actions/forms'
import { Redirect } from "react-router-dom"
import Citation from './Citation'
import { getRequest, getOutputOrDefault } from "../../reducers";
import * as notify from '../../utils/notify'
import ShareButton from "./ShareButton";
import FormControlCountries from "../FormControlCountries"

const AttrValue = props => {
    const { t } = useTranslation()
    return (
    <>
        {props.gps &&
            <span>
                {props.data[COORDINATES_PRECISION] > 0 &&
		<>
                <span style={{ whiteSpace: 'nowrap' }}>
                    {decodeLatitude(props.data[LATITUDE_ATTRIBUTE_NAME])}
                </span> <span style={{ whiteSpace: 'nowrap' }}>
                    {decodeLongitude(props.data[LONGITUDE_ATTRIBUTE_NAME])}
                </span>
		</>
		}
                {props.data[COORDINATES_PRECISION] === 2 &&
                <span> ({t('precision-2')})</span>
                }
                {props.data[COORDINATES_PRECISION] === 1 &&
                <span> ({t('precision-1')})</span>
                }
                {props.data[COORDINATES_PRECISION] === 0 &&
                <span> {t('precision-0')}</span>
                }
            </span>
       }
        {!props.gps &&
            <>
                {Array.isArray(props.data[props.name]) &&
                    <>
                        {props.data[props.name].map((val, indx) =>
                            <div key={indx}>
                                {val === true &&
                                    <div>{props.t('common:true')}</div>
                                }
                                {val === false &&
                                    <div>{props.t('common:false')}</div>
                                }
                                {val !== true && val !== false &&
                                    <div>
                                        {props.renderDirect &&
                                            <span>{GetValueFromDictionary((props.dictionary ? props.dictionary : props.name), val, props.i18n, props.collectionTypes)}</span>
                                        }

                                        {!props.link && props.tags &&
                                            <Button
                                                className="p-0"
                                                variant="link"
                                                onClick={() => props.goToTag(val, props.setRedirect)}>
                                                {props.renderDirect &&
                                                    <span>{GetValueFromDictionary((props.dictionary ? props.dictionary : props.name), val, props.i18n, props.collectionTypes)}</span>
                                                }
                                                {!props.renderDirect &&
                                                    <><ValueFromDictionary name={props.name} value={val} ></ValueFromDictionary> {props.meters ? "m" : ""}</>
                                                }
                                            </Button>
                                        }
                                        {!props.link && !props.tags &&
                                            <>
                                                {props.date &&
                                                    <>
                                                        {dateToString(val)}
                                                    </>
                                                }
                                                {props.directRender && !props.date &&
                                                    <span>{GetValueFromDictionary((props.dictionary ? props.dictionary : props.name), val, props.i18n, props.collectionTypes)}</span>
                                                }
                                                {!props.directRender && !props.date &&
                                                    <>
                                                        <ValueFromDictionary name={props.name} value={val} ></ValueFromDictionary> {props.meters ? "m" : ""}
                                                    </>
                                                }
                                            </>
                                        }
                                        {props.directRender && props.link &&
                                            <Button
                                                className="p-0 btn-link-map-popup"
                                                variant="link"
                                                onClick={() => window.open(props.link + val, new Date().valueOf())}>
                                                <span>{GetValueFromDictionary((props.dictionary ? props.dictionary : props.name), val, props.i18n, props.collectionTypes)}</span>
                                            </Button>
                                        }
                                        {!props.directRender && props.link &&
                                            <Button
                                                className="p-0"
                                                variant="link"
                                                onClick={() => window.open(props.link + val, new Date().valueOf())}>
                                                <ValueFromDictionary name={props.name} value={val} ></ValueFromDictionary> {props.meters ? "m" : ""}
                                            </Button>
                                        }
                                    </div>
                                }
                            </div>
                        )}
                    </>
                }
                {!Array.isArray(props.data[props.name]) &&
                    <>
                        {props.data[props.name] === true &&
                            <>{props.t('common:true')}</>
                        }
                        {props.data[props.name] === false &&
                            <>{props.t('common:false')}</>
                        }
                        {props.data[props.name] !== true && props.data[props.name] !== false &&
                            <>
                                {props.date &&
                                    <>
                                        {dateToString(props.data[props.name])}
                                    </>
                                }
                                {props.directRender && !props.date &&
                                    <span>{GetValueFromDictionary((props.dictionary ? props.dictionary : props.name), props.data[props.name], props.i18n, props.collectionTypes)}</span>
                                }
                                {!props.directRender && !props.date &&
                                    <>
                                        <ValueFromDictionary name={props.name} value={props.data[props.name]} ></ValueFromDictionary> {props.meters ? "m" : ""}
                                    </>
                                }
                            </>
                        }
                    </>
                }
            </>
        }
    </>)
}

const EditAttrValue = props => {
    const { register, errors } = useFormContext();
    const isDisabled = ['numerokazu', 'rodzajgatunek', 'images', 'geodokladnosc', 'kolekcja'].includes(props.name)
    return (
        <Form.Group className="mb-0">
            <Form.Control name={props.name} size="sm" type="text" ref={register} disabled={isDisabled} isInvalid={errors[props.name]} autoComplete="off" />
            <Form.Control.Feedback type="invalid">
                {errors[props.name] ? errors[props.name][0] : ''}
            </Form.Control.Feedback>
        </Form.Group>
    )
}

const EditAttrValueArray = props => {
    const { t } = useTranslation(['taxondb-attributes'])
    const { register, control } = useFormContext();
    const { fields, append, remove, insert } = useFieldArray({ name: props.name, control })
    return (<>
        {fields.length > 0 && fields.map((item, index) =>
            <Row noGutters key={item.id} className="mb-1">
                <div className="revisions-row">
                    <div className="ml-1">
                        <Form.Group className="mb-0">
                            <div className="revisions-lower-taxons-row">
                                <>
                                    {props.name === 'Country' &&
                                        <FormControlCountries 
                                            name={`${props.name}.${index}.value`} 
                                            className="revision-form"
                                            size="sm" 
                                            type="text" 
                                            register={register}
                                            set={props.setValue}
                                            defaultValue={item.value} 
                                            autoComplete="off" />
                                    }
                                    {props.name !== 'Country' &&
                                        <Form.Control name={`${props.name}.${index}.value`} className="revision-form" defaultValue={item.value} size="sm" type="text" ref={register} autoComplete="off" />
                                    }
                                </>
                            </div>
                        </Form.Group>
                    </div>
                    <Button size="sm" className="ml-2 pr-1" onClick={() => insert(index + 1, { value: '' })} variant="link">
                        <FontAwesomeIcon icon={faPlus} title={t('common:append')} size="xs" />
                    </Button>
                    <Button size="sm" className="ml-1" onClick={() => remove(index)} variant="link">
                        <FontAwesomeIcon icon={faTrash} title={t('common:delete')} size="xs" />
                    </Button>
                </div>
            </Row>
        )}
        {fields.length === 0 &&
            <Row noGutters className="mb-1">
                <div className="revisions-lower-taxons-row">
                    <div className="ml-1">
                        <Form.Control className="revision-form" size="sm" type="text" disabled />
                    </div>
                    <Button size="sm" className="ml-2 pr-1" onClick={() => append({ value: '' })} variant="link">
                        <FontAwesomeIcon icon={faPlus} title={t('common:append')} size="xs" />
                    </Button>
                    <Button size="sm" className="ml-1" disabled variant="link">
                        <FontAwesomeIcon icon={faTrash} title={t('common:delete')} size="xs" />
                    </Button>
                </div>
            </Row>
        }
    </>)
}

const AttrName = props =>
    <b>
        {!props.label &&
            <>
                {props.t('icondb-attributes:' + props.name)}
            </>
        }
        {props.label &&
            <>
                {props.t('icondb-attributes:' + props.label)}
            </>
        }
    </b>

const DivRow = props =>
    <>
        {( (typeof(props.data[props.name]) != "undefined" && props.data[props.name] !== null) || props.editable) &&
            <div className="d-flex" style={{ borderTopStyle: "solid", borderTopColor: "#dee2e6", borderTopWidth: "1px" }}>
                <div className="w-50 p-1">
                    <AttrName {...props}></AttrName>
                </div>
                <div className="w-50 p-1">
                    {!props.editable &&
                        <AttrValue {...props}></AttrValue>
                    }
                    {props.editable && !props.array &&
                        <EditAttrValue {...props}></EditAttrValue>
                    }
                    {props.editable && props.array &&
                        <EditAttrValueArray {...props}></EditAttrValueArray>
                    }
                </div>
            </div>
        }
    </>

export const Tab1 = props =>
    <div>
        <DivRow name="CollectionSpecimenNumber" {...props} array></DivRow>
        <DivRow name="SourceMaterial" {...props} array></DivRow>
        <DivRow name="FullAuthorName" {...props} array></DivRow>
        <DivRow name="Persons" {...props} array></DivRow>
        <DivRow name="OtherObjects" {...props} array></DivRow>
        <DivRow name="DateOfInformationPreservation" {...props} array></DivRow>
    </div>

const HiddenDivRows = props => {
    const [open, setOpen] = useState(false)
    return (
        <>
            {(!open && !props.editable) && ([
                "ProtectedArea",
                "NationalPark",
                "LandscapePark",
                "NatureReserve",
                "KindAndTypeOfReserve",
                "AreaOfLandscapeProtection",
                "EcologicalArea",
                "NaturalLandscapeAssociation",
                "CodeTypeNatura2000Area",
                "Natura2000AreaName",
                "LocationPhysiographicDivision",
                "LocationEuropeanBiogeobotanicalDivision",
            ].reduce((acc, obj) => (
                acc || props.data[obj]
            ), false)) &&
                <div className="d-flex" style={{ borderTopStyle: "solid", borderTopColor: "#dee2e6", borderTopWidth: "1px" }}>
                    <div className="w-50 p-1">
                    </div>
                    <div className="w-50 p-1">
                        <Button
                            variant="secondary"
                            onClick={() => setOpen(!open)}
                            size="sm">{props.t('common:more')}</Button>
                    </div>
                </div>
            }
            {(open || props.editable) &&
                <>
                    <DivRow name="ProtectedArea" {...props} array></DivRow>
                    <DivRow name="NationalPark" {...props} array></DivRow>
                    <DivRow name="LandscapePark" {...props} array></DivRow>
                    <DivRow name="NatureReserve" {...props} array></DivRow>
                    <DivRow name="KindAndTypeOfReserve" {...props} array></DivRow>
                    <DivRow name="AreaOfLandscapeProtection" {...props} array></DivRow>
                    <DivRow name="EcologicalArea" {...props} array></DivRow>
                    <DivRow name="NaturalLandscapeAssociation" {...props} array></DivRow>
                    <DivRow name="CodeTypeNatura2000Area" {...props} array></DivRow>
                    <DivRow name="Natura2000AreaName" {...props} array></DivRow>
                    <DivRow name="LocationPhysiographicDivision" {...props} array></DivRow>
                    <DivRow name="LocationEuropeanBiogeobotanicalDivision" {...props} array></DivRow>
                </>
            }
        </>
    )
}

const Tab2 = props =>
    <div>
        <DivRow name="Location" {...props} array></DivRow>
        <DivRow name="TypeOfSiteBiotope" {...props} array></DivRow>
        <DivRow name="GeoreferenceComments" {...props} array></DivRow>
        <DivRow name="Continent" {...props} array></DivRow>
        <DivRow name="Country" {...props} array></DivRow>
        <DivRow name="Voivodeship" {...props} array></DivRow>
        <DivRow name="County" {...props} array></DivRow>
        <DivRow name="Commune" {...props} array></DivRow>
        {!props.editable && <DivRow name={COORDINATES_PRECISION} label={GPS_COORDINATES_ATTRIBUTE_NAME} gps {...props}></DivRow>}
        {props.editable && <DivRow name={LATITUDE_ATTRIBUTE_NAME} {...props} array></DivRow>}
        {props.editable && <DivRow name={LONGITUDE_ATTRIBUTE_NAME} {...props} array></DivRow>}
        <DivRow name="PositionInRelationToSeaLevel" {...props} meters array></DivRow>
        <DivRow name="ATPOLcoordinates" {...props} array></DivRow>
        <DivRow name="UTMcoordinates" {...props} array></DivRow>
        <HiddenDivRows {...props}></HiddenDivRows>
    </div>


const Tab3 = props =>
    <div>
        <DivRow name="Title" {...props} array></DivRow>
        <DivRow name="Tags" tags {...props} array></DivRow>
        <DivRow name="Comments" {...props} array></DivRow>
        <DivRow name="LocationComments" {...props} array></DivRow>
        <DivRow name="Genus" {...props} array></DivRow>
        <DivRow name="Species" {...props} array></DivRow>
        <DivRow name="SpeciesAuthor" {...props} array></DivRow>
        <DivRow name="Subgenus" {...props} array></DivRow>
        <DivRow name="RankOfSuperiorUnit" {...props} array></DivRow>
        <DivRow name="SuperiorUnit" {...props} array></DivRow>
        <DivRow name="Age" {...props} array></DivRow>
        <DivRow name="Sex" {...props} array></DivRow>
        <DivRow name="Stage" {...props} array></DivRow>
    </div>

const Tab4 = props =>
    <div>
        <DivRow name="Bibliography" {...props} array></DivRow>
    </div>

const Tab5 = props =>
    <div>
        <DivRow name="PersonIdentifyingPhotoFile" {...props} array></DivRow>
        <DivRow name="NonGovernmentOrSociety" tags {...props} array></DivRow>
        <DivRow name="Education" {...props} array></DivRow>
        <DivRow name="ScientificWorkersStudents" {...props} array></DivRow>
        <DivRow name="StateAndLocalAdministration" {...props} array></DivRow>
        <DivRow name="StateServicesAndOfficials" {...props} array></DivRow>
        <DivRow name="TargetGroupsUsefulness" {...props} array></DivRow>
        <DivRow name="FileName" {...props} array></DivRow>
        <DivRow name="PhotoNumber" {...props} array></DivRow>
        <DivRow name="ImageType" {...props} array></DivRow>
        <DivRow name="VariaType" {...props} array></DivRow>
        <DivRow name="SampleNumber" {...props} array></DivRow>
        <DivRow name="LocationPotentialNaturalVegetation" {...props} array></DivRow>
    </div>

const AllTabs = props => {
    const { t, i18n } = useTranslation(['taxondb-groups', 'taxondb', 'common', 'taxondb-attributes', 'icondb-attributes', 'citation', 'paths'])

    const [key, setKey] = useState('group-1')
    const [editable, setEditable] = useState(false)
    const [redirect, setRedirect] = useState(false)

    const { register, handleSubmit, errors, clearErrors, setError, reset, getValues, control, setValue } = useForm({
        defaultValues: mapRecordDetailsOutput(props.data)
    });

    const onEditableClick = () => {
        if (editable) {
            reset(getValues())
        }

        setEditable(!editable)
    }

    const onSave = (fields) => {
        clearErrors()
        props.saveRecord(props?.data?.edi_id, fields, props.data, t, setError, props.state)
    }

    const onRemoveIcon = () => {
        // TODO
    }

    return (
        <div className="icon-content">
            {redirect &&
                <Redirect push to={t("paths:iconDB")} ></Redirect>
            }
            <FormProvider {...{register, errors, control}}>
                <Tabs className="citation-tab-pulled-right" defaultActiveKey={'group-1'} id={props.requestId} activeKey={key}
                    onSelect={(k, e) => { handleTabsA11y(k, e, setKey, 'details-panel') }}>
                    <Tab eventKey="group-1" title={t('group-1')}>
                        <Tab1 {...props} editable={editable} t={t} i18n={i18n}></Tab1>
                    </Tab>
                    {([
                        "Location",
                        "TypeOfSiteBiotope",
                        "GeoreferenceComments",
                        "Continent",
                        "Country",
                        "Voivodeship",
                        "County",
                        "Commune",
                        LATITUDE_ATTRIBUTE_NAME,
                        "PositionInRelationToSeaLevel",
                        "ATPOLcoordinates",
                        "UTMcoordinates",
                        "ProtectedArea",
                        "NationalPark",
                        "LandscapePark",
                        "NatureReserve",
                        "KindAndTypeOfReserve",
                        "AreaOfLandscapeProtection",
                        "EcologicalArea",
                        "NaturalLandscapeAssociation",
                        "CodeTypeNatura2000Area",
                        "Natura2000AreaName",
                        "LocationPhysiographicDivision",
                        "LocationEuropeanBiogeobotanicalDivision",
                    ].reduce((acc, obj) => (
                        acc || props.data[obj]
                    ), false)) &&
                        <Tab eventKey="group-2" title={t('group-2')}>
                            <Tab2 {...props} editable={editable} t={t} i18n={i18n} setValue={setValue}></Tab2>
                        </Tab>
                    }
                    {([
                        "Title",
                        "Tags",
                        "Comments",
                        "LocationComments",
                        "Species",
                        "Genus",
                        "SpeciesAuthor",
                        "Subgenus",
                        "RankOfSuperiorUnit",
                        "SuperiorUnit",
                        "Age",
                        "Sex",
                        "Stage"
                    ].reduce((acc, obj) => (
                        acc || props.data[obj]
                    ), false)) &&
                        <Tab eventKey="group-3" title={t('group-3')}>
                            <Tab3 {...props} editable={editable} t={t} i18n={i18n} setRedirect={setRedirect}></Tab3>
                        </Tab>
                    }
                    {([
                        "PersonIdentifyingPhotoFile",
                        "NonGovernmentOrSociety",
                        "Education",
                        "ScientificWorkersStudents",
                        "StateAndLocalAdministration",
                        "StateServicesAndOfficials",
                        "TargetGroupsUsefulness",
                        "FileName",
                        "PhotoNumber",
                        "ImageType",
                        "VariaType",
                        "SampleNumber",
                        "LocationPotentialNaturalVegetation"
                    ].reduce((acc, obj) => (
                        acc || props.data[obj]
                    ), editable)) &&
                        <Tab eventKey="group-5" title={t('group-5')}>
                            <Tab5 {...props} editable={editable} t={t} i18n={i18n} setRedirect={setRedirect}></Tab5>
                        </Tab>
                    }
                    {props.data['Bibliography'] &&
                        <Tab eventKey="group-4" title={t('group-4')}>
                            <Tab4 {...props} editable={editable} t={t} i18n={i18n}></Tab4>
                        </Tab>
                    }
                    {props.data[LATITUDE_ATTRIBUTE_NAME] && props.data[LONGITUDE_ATTRIBUTE_NAME] &&
                        <Tab eventKey="map" title={t('taxondb:map')} className="d-flex flex-column mt-2">
                            {key === 'map' &&
                                <MarkerMap lat={props.data[LATITUDE_ATTRIBUTE_NAME]} lon={props.data[LONGITUDE_ATTRIBUTE_NAME]}></MarkerMap>
                            }
                        </Tab>
                    }
                    <Tab eventKey="citation" title={t('citation:how-to-cite')} tabClassName="citation-tab">
                        <Citation {...props}></Citation>
                    </Tab>
                    <Tab eventKey="share" title={t('common:share')} tabClassName="share-button">
                        <ShareButton {...props}></ShareButton>
                    </Tab>
                </Tabs>
                {props.user != null && props.user.groups != null && props.user.groups.includes(USER_GROUPS_EDITOR_CONST) &&
                    <div className="mb-3 pl-2 pr-2">
                        <Form.Check
                            className="d-inline"
                            type="switch"
                            id={`editable-switch-${props.requestId}`}
                            label={t('forms:edit')}
                            defaultChecked={editable}
                            onClick={onEditableClick}
                        />
                        {editable && <div className="float-right">
                            <span className="d-inline-block">
                                <Confirm
                                    onConfirm={onRemoveIcon}
                                    size="sm"
                                    buttonLabel={t('forms:remove')}
                                    confirmationText={t('icondb:confirm-remove-icon')}
                                    disabled={props.isSaving || true /* TODO: waiting for an endpoint */}
                                    className="ml-1 mr-1"
                                ></Confirm>
                            </span>
                            <Button onClick={handleSubmit(onSave)} size="sm" disabled={props.isSaving} className="float-right">
                                {t('forms:save')}
                                {props.isSaving &&
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        className="ml-1"
                                    />
                                }
                            </Button>
                        </div>}
                    </div>
                }
            </FormProvider>
        </div>
    )
}

const mapRecordDetailsOutput = (data) => {
    if (!data) {
        return data
    }

    let copy = { ...data }
    for (const key in copy) {
        if (!['edi_id', 'edi_ver_id', 'external_id', 'first_publication', 'wspolrzedne'].includes(key)) {
            copy[key] = copy[key].map(v => ({ value: v }))        
        }
    }

    return copy
}

const mapOutputRecordDetails = (data) => {
    let copy = { ...data }
    for (const key in copy) {
        if (!['edi_id', 'edi_ver_id', 'external_id', 'first_publication', 'wspolrzedne'].includes(key)) {
            copy[key] = Object.keys(copy[key]).map(index => copy[key][index].value)
        }
    }

    return copy
}

const mapStateToProps = (state, ownProps) => ({
    state: state,
    user: state.user ? state.user.data : null,
    data: getOutputOrDefault(ownProps.requestId, state, {}),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    goToTag: (value, setRedirect) => {
        // set form value
        dispatch(setFields(ICONDB_SEARCH_REQUEST_ID, { keyword: value }))
        // redirect
        setRedirect(true)
        // close previous open details
        dispatch(setField(ICON_RESULTS_ACTIVE_RECORD, ICON_RESULTS_ACTIVE_RECORD, null))
        // search
        dispatch(postDataDlibra(ICONDB_SEARCH_REQUEST_ID, ICONDB_SEARCH_REQUEST_URL, { filter: { keyword: value } }))
        // scroll to top
        window.scrollTo(0, 0)
    },
    saveRecord: (recordId, fields, oldFields, t, setError, state) => {
        let newFields = Object.assign({}, fields)
        for (const key in oldFields) {
            if (!(key in newFields)) {
                newFields[key] = oldFields[key];
            }
        }

        for (const key in newFields) {
            if (!(key in oldFields) && newFields[key] === '') {
                delete newFields[key]
            }
        }

        let diffFields = diff(oldFields, mapOutputRecordDetails(newFields))
        for (const key in diffFields) {
            diffFields[key] = (newFields[key] || []).map(f => f.value)
        }

        dispatch(postDataDlibra(
            ICONDB_CHANGE_REQUEST_ID,
            ICONDB_CHANGE_REQUEST_URL + recordId + '/',
            { fields: diffFields },
            GENERAL_EXTENDED_REQUEST_TIMEOUT
        )).then(() => {
            const request = getRequest(ownProps.requestId, state)
            dispatch(getData(ownProps.requestId, request.url))
            notify.success(t('icondb:modified-icon'))
        }).catch(res => {
            const err = res?.response?.data?.error;
            if (err && err.icon) {
                Object.keys(err.icon).forEach(k => {
                    setError(k, err.icon[k]);
                })
            }

            notify.error(t('icondb:cannot-modify-icon'), res?.response?.data?.error?.error || res?.response?.data)
        })
    }
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllTabs)

