import React, { useState } from "react"
import { connect } from 'react-redux'
import { Button, Row, Col, Spinner } from "react-bootstrap"
import {
  GENUS_ATTRIBUTE_NAME,
  SPECIES_ATTRIBUTE_NAME,
} from '../taxonDB/attributesStructure'
import {
  TAXONDB_DETAILS_PAGE_URL,
  BIBLIODB_SPECIMENS_REQUEST_ID_PREFIX,
  BIBLIODB_SPECIMENS_REQUEST_URL,
  ANC_URL_POSTFIX,
  BIBLIODB_EXPORTFILE_SPECIMENS_REQUEST_URL,
} from '../../settings'
import ValueFromDictionary from "../dictionary/ValueFromDictionary"
import { isPending, getItems } from "../../reducers"
import { Redirect } from "react-router-dom";
import ConnectedPagination from "../ConnectedPagination"
import ReportsExporter from "../ReportsExporter"

const Tab2 = props => {
  const [ redirect, setRedirect ] = useState(false)

  return (
    <div>
      {redirect &&
        <Redirect push to={TAXONDB_DETAILS_PAGE_URL + redirect}></Redirect>
      }
      {props.loadingSpecimens &&
        <div className="d-flex justify-content-center my-5">
          <Spinner animation="border" role="status" />
        </div>
      }
      {!props.loadingSpecimens && props.specimens &&
        <>
          <ReportsExporter
            requestId={BIBLIODB_SPECIMENS_REQUEST_ID_PREFIX + props.recordId}
            url={BIBLIODB_EXPORTFILE_SPECIMENS_REQUEST_URL}
            customFilter={{ cytowanie: props.cit }}
          >
          </ReportsExporter>
          <ConnectedPagination requestId={BIBLIODB_SPECIMENS_REQUEST_ID_PREFIX + props.recordId} url={ANC_URL_POSTFIX + BIBLIODB_SPECIMENS_REQUEST_URL} customFilter={{ cytowanie: props.cit }}></ConnectedPagination>
          {props.specimens.map((item, indx) =>
            <div key={indx} className="d-flex">
              <Button variant="link" onClick={e => setRedirect(item.kolekcjanumerokazu)} className="biblio-cite-button">
                <Row className="biblio-cite-row">
                  <Col md={5}>{item.kolekcjanumerokazu} -</Col>
                  <Col md={3}><ValueFromDictionary name={GENUS_ATTRIBUTE_NAME} value={item[ GENUS_ATTRIBUTE_NAME ]}></ValueFromDictionary></Col>
                  <Col md={4}><ValueFromDictionary name={SPECIES_ATTRIBUTE_NAME} value={item[ SPECIES_ATTRIBUTE_NAME ]}></ValueFromDictionary></Col>
                </Row>
              </Button>
            </div >
          )}
          <ConnectedPagination requestId={BIBLIODB_SPECIMENS_REQUEST_ID_PREFIX + props.recordId} last url={ANC_URL_POSTFIX + BIBLIODB_SPECIMENS_REQUEST_URL} customFilter={{ cytowanie: props.cit }}></ConnectedPagination>
        </>
      }
    </div >
  )
}

const mapStateToPropsTab2 = (state, ownProps) => ({
  loadingSpecimens: isPending(BIBLIODB_SPECIMENS_REQUEST_ID_PREFIX + ownProps.recordId, state),
  specimens: getItems(BIBLIODB_SPECIMENS_REQUEST_ID_PREFIX + ownProps.recordId, state),
})

const mapDispatchToPropsTab2 = (dispatch, ownProps) => ({
})

export default connect(
  mapStateToPropsTab2,
  mapDispatchToPropsTab2
)(Tab2)
