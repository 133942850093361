import React, { useState, Suspense } from 'react'
import { connect } from 'react-redux'
import { isPending, getOutput } from "../../reducers";
import { ListGroup, Collapse, Spinner } from "react-bootstrap"
import { getDataAnc, requestRemove, postDataAnc } from '../../actions'
import {
    TAXONDB_GET_TAXON_UNITS_REQUEST_URL,
    STATISTICS_GENUS_SEARCH_REQUEST_PREFIX,
    TAXONDB_SEARCH_GENUS_REQUEST_URL,
} from '../../settings'
import InnerSystematicsItemList from "./SystematicsItemList"
import GenusItemList from "./GenusItemList"
import Loader from '../../utils/loader'
import { useTranslation } from 'react-i18next';

const SystematicsItemList = props => {
    const { t, i18n } = useTranslation(['a11y'])
    const [ open, setOpen ] = useState({})
    const [ isOpened, setIsOpened ] = useState({})

    const toggleOpen = (requestId, val) => {
        const temp = { ...open }
        temp[ requestId ] = !temp[ requestId ]
        setOpen(temp)
        if (val.has_childs && temp[ requestId ]) {
            props.getItems(requestId, val.id)
        }
        if (!val.has_childs && temp[ requestId ]) {
            props.searchGenus(requestId, val.range_lat, val.takson_lat)
        }
    }

    return (
        <Suspense fallback={<Loader />}>
            <div className="d-flex flex-column">
                {(props.loadingItems || (!props.items && props.isOpened)) &&
                    <div className="align-self-center m-5">
                        <Spinner animation="border" role="status" />
                    </div>
                }
                {!props.loadingItems && props.items && props.items.length > 0 &&
                    <div className={props.path ? 'ml-4' : ''}>
                        <ListGroup>
                            {props.items.map(val => {
                                let requestId = TAXONDB_GET_TAXON_UNITS_REQUEST_URL + val.id;
                                if (!val.has_childs) {
                                    requestId = STATISTICS_GENUS_SEARCH_REQUEST_PREFIX + val.id;
                                }
                                return (
                                    <div key={requestId}>
                                        <ListGroup.Item tabIndex="0" action className="d-flex flex-row align-items-center album-element" onClick={() => toggleOpen(requestId, val)}>
                                            <div>
                                                {open[ requestId ] &&
                                                    <img src="/images/list-open.svg" alt={t("a11y:list-opened")} />
                                                }
                                                {!open[ requestId ] &&
                                                    <img src="/images/list-closed.svg" alt={t("a11y:list-closed")} />
                                                }
                                            </div>
                                            <div className="flex-grow-1 mr-2"
                                                aria-controls={requestId}
                                                aria-expanded={open[ requestId ]}>
                                                {val.takson_lat + " (" + val.range_lat + ")"}
                                                <strong className="ml-2">{val.specimens.toLocaleString(i18n.languages[0])}</strong>
                                            </div>
                                        </ListGroup.Item>
                                        <Collapse in={open[ requestId ]} id={requestId}
                                            onEntering={() => setIsOpened({ ...isOpened, [ requestId ]: true })}
                                            onExited={() => setIsOpened({ ...isOpened, [ requestId ]: false })}
                                        >
                                            <div>
                                                {val.has_childs &&
                                                    <InnerSystematicsItemList requestId={requestId} id={val.id} path={(props.path ? (props.path + '/') : '') + val.id} excludeZeros={props.excludeZeros} isOpened={isOpened}></InnerSystematicsItemList>
                                                }
                                                {!val.has_childs &&
                                                    <GenusItemList requestId={requestId} id={val.id} path={(props.path ? (props.path + '/') : '') + val.id}></GenusItemList>
                                                }
                                            </div>
                                        </Collapse>
                                    </div>
                                )
                            })}
                        </ListGroup>
                    </div>
                }
            </div>
        </Suspense>
    )
}
const mapStateToProps = (state, ownProps) => ({
    loadingItems: isPending(ownProps.requestId, state),
    items: getOutput(ownProps.requestId, state) ? getOutput(ownProps.requestId, state).filter(obj => !ownProps.excludeZeros || obj.specimens > 0) : getOutput(ownProps.requestId, state),
})


const mapDispatchToProps = dispatch => ({
    getItems: (requestId, id) => dispatch(getDataAnc(
        requestId,
        TAXONDB_GET_TAXON_UNITS_REQUEST_URL + id + '/'
    )),
    searchGenus: (requestId, range, takson) => {
        dispatch(requestRemove(requestId))
        return dispatch(postDataAnc(requestId, TAXONDB_SEARCH_GENUS_REQUEST_URL,
            {
                filter: {
                    rangajednostkinadrzednej: range,
                    jednostkanadrzedna: takson,
                },
                pagination: {
                    currentPage: 1,
                },
            }))
    },
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SystematicsItemList)
