import React, { Suspense } from "react";
import { Container } from "react-bootstrap"
import { useTranslation } from 'react-i18next'
import { ACCESSIBILITY_PAGE_URL_EN, ACCESSIBILITY_PAGE_URL_PL } from "../settings";
import Loader from '../utils/loader'
import I18nPathTranslator from "./I18nPathTranslator";
import SEO from "./SEO";



const Accessibility = props => {
    const { t } = useTranslation('accessibility')
    
    return (
        <Suspense fallback={<Loader />}>
            <I18nPathTranslator {...props} pathEN={ACCESSIBILITY_PAGE_URL_EN} pathPL={ACCESSIBILITY_PAGE_URL_PL}/>
            <SEO title={t('common:accessibility')} />
            <div>
                <Container>
                    <div>
                        <h1>{t('line-1')}</h1>
                        <h2>{t('line-2')}</h2>
                        <p>{t('line-3')}<a href={t('link-1')}>{t('link-1')}</a>.</p>
                        <p>{t('line-4')}</p>
                        <p>{t('line-5')}</p>
                        <ul><li>{t('line-6')}</li></ul>
                        <p>{t('line-7')}</p>
                        <p>{t('line-8')}</p>
                        <h3>{t('line-9')}</h3>
                        <p>{t('line-10')}</p>
                        <p>{t('line-11')}</p>
                        <h2>{t('line-12')}</h2>
                        <h3>{t('line-13')}</h3>
                        <p>{t('line-14')}</p>
                        <ul>
                            <li>{t('line-15')}</li>
                            <li>{t('line-16')}</li>
                            <li>{t('line-17')}</li>
                            <li>{t('line-18')}</li>
                            <li>{t('line-19')}</li>
                            <li>{t('line-20')}</li>
                            <li>{t('line-21')}</li>
                        </ul>
                        <p>{t('line-22')}</p>
                        <ul>
                            <li>{t('line-23')}</li>
                            <li>{t('line-24')}</li>
                            <li>{t('line-25')}</li>
                            <li>{t('line-26')}</li>
                            <li>{t('line-27')}</li>
                        </ul>
                        <p>{t('line-28')}</p>
                        <ul>
                            <li>{t('line-29')}</li>
                            <li>{t('line-30')}</li>
                            <li>{t('line-31')}</li>
                            <li>{t('line-32')}</li>
                            <li>{t('line-33')}</li>
                            <li>{t('line-34')}</li>
                            <li>{t('line-35')}</li>
                            <li>{t('line-36')}</li>
                        </ul>
                        <p>{t('line-37')}</p>
                        <ul>
                            <li>{t('line-38')}</li>
                            <li>{t('line-39')}</li>
                            <li>{t('line-40')}</li>
                            <li>{t('line-41')}</li>
                            <li>{t('line-42')}</li>
                            <li>{t('line-43')}</li>
                            <li>{t('line-44')}</li>
                            <li>{t('line-45')}</li>
                        </ul>
                        <p>{t('line-46')}</p>
                        <h3>{t('line-47')}</h3>
                        <p>{t('line-48')}</p>
                        <h3>{t('line-49')}</h3>
                        <p>{t('line-50')}</p>
                        <h3>{t('line-51')}</h3>
                        <p>{t('line-52')}<a href={t('link-2-href')}>{t('link-2-text')}</a>.</p>
                        <h3>{t('line-53')}</h3>
                        <p>{t('line-54')}</p>
                        <h3>{t('line-55')}</h3>
                        <p>{t('line-56')}</p>
                        <h3>{t('line-57')}</h3>
                        <p>{t('line-58')}</p>
                        <h3>{t('line-59')}</h3>
                        <p>{t('line-60')}</p>
                        <h3>{t('line-61')}</h3>
                        <p>{t('line-62')}</p>
                        <ul>
                            <li>{t('line-63')}<a href={t('link-3')}>{t('link-3')}</a></li>
                            <li>{t('line-64')}<a href={t('link-4')}>{t('link-4')}</a></li>
                        </ul>
                        <p>{t('line-65')}</p>
                        <ul>
                            <li>{t('line-66')}</li>
                            <li>{t('line-67')}</li>
                            <li>{t('line-68')}</li>
                            <li>{t('line-69')}</li>
                            <li>{t('line-70')}</li>
                            <li>{t('line-71')}</li>
                            <li>{t('line-72')}</li>
                            <li>{t('line-73')}</li>
                            <li>{t('line-74')}</li>
                            <li>{t('line-75')}</li>
                            <li>{t('line-76')}</li>
                            <li>{t('line-77')}</li>
                        </ul>
                        <h2>{t('line-78')}</h2>
                        <p>{t('line-79')}</p>
                        <p>{t('line-80')}</p>
                        <p>{t('line-81')}</p>
                        <p>{t('line-82')}</p>
                        <h2>{t('line-83')}</h2>
                        <p>{t('line-84')}</p>
                        <ul>
                            <li>{t('line-85')}</li>
                            <li>{t('line-86')}</li>
                            <li>{t('line-87')}</li>
                            <li>{t('line-88')}</li>
                            <li>{t('line-89')}</li>
                        </ul>
                        <p>{t('line-90')}</p>
                        <p>{t('line-91')}</p>
                        <p>{t('line-92')}</p>
                        <ul>
                            <li>{t('line-93')}</li>
                            <li>{t('line-94')}</li>
                            <li>{t('line-95')}</li>
                        </ul>
                        <p>{t('line-96')}</p>
                        <ul>
                            <li>{t('line-97')}</li>
                            <li>{t('line-98')}</li>
                            <li>{t('line-99')}</li>
                            <li>{t('line-100')}</li>
                        </ul>
                        <p>{t('line-101')}</p>
                        <p>{t('line-102')}</p>
                        <p>{t('line-103')}<a href={t('link-5')}>{t('link-5')}</a>.</p>
                        <p>{t('line-104')}</p>
                    </div>
                </Container>
            </div>
        </Suspense>
    )
}

export default Accessibility
