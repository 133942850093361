import React, { useState } from "react";
import { connect } from 'react-redux'
import { Container, Collapse, ListGroup } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { getDataApi } from "../actions"
import { isPending } from "../reducers";
import {
  chartInfo,
  calculateDaysBehind,
  setDaysScope,
  handleCollectionsData,
  setCollections,
  setIconSizeData,
  setDailyDownloadsData
} from '../actions/chart'
import VisitsReportsHandler from "./reports/VisitsReportsHandler"
import CollectionReportsHandler from "./reports/CollectionReportsHandler"
import IconSizeReportsHandler from "./reports/IconSizeReportsHandler"
import DownloadsReportsHandler from "./reports/DownloadsReportsHandler"
import RedirectLoginWithReturn from "./access/RedirectLoginWithReturn"

import {
  API_GET_VISITS_PER_DAY_ID,
  API_GET_VISITS_PER_DAY_URL,
  API_GET_COLLECTIONS_STATS_ID,
  API_GET_COLLECTIONS_STATS_URL,
  API_GET_SIZE_STATS_ID,
  API_GET_SIZE_STATS_URL,
  API_GET_DOWNLOADS_STATS_PER_DAY_ID,
  API_GET_DOWNLOADS_STATS_PER_DAY_URL,
  REPORTS_PAGE_URL_EN,
  REPORTS_PAGE_URL_PL
} from '../settings'
import I18nPathTranslator from "./I18nPathTranslator";
import SEO from "./SEO";
import SeeMoreIcon from "./SeeMoreIcon";


function ReportsContainer(props) {

  const { t } = useTranslation([ 'reports', 'common', 'menu', 'paths' ])
  const [ open, setOpen ] = useState({})
  const VISITS_CONST = 'visits'
  const SIZEDB_CONST = 'sizedb'
  const COLLECTIONS_CONST = 'collections'
  const DOWNLOADS_CONST = 'downloads'
  const DAY_CONST = 'day'
  const CHART_TYPE_NORMAL = 'normal'
  const CHART_TYPE_BAR = 'bar'

  const toggleOpen = (requestId) => {
    const temp = { ...open }
    temp[ requestId ] = !temp[ requestId ]
    setOpen(temp)
  }

  return (
    <Container className="d-flex flex-column">
      <I18nPathTranslator {...props} pathEN={REPORTS_PAGE_URL_EN} pathPL={REPORTS_PAGE_URL_PL} />
      <SEO title={t("menu:reports")} />
      {!props.user &&
        <RedirectLoginWithReturn lang={props.lang} returnUrl={props.lang ? t("paths:reports", {lng: props.lang}) : t("paths:reports")} />
      }
      {props.user &&
        <>
          <div>
            <div className="mt-3 mb-4">
              <h1>
                {t("menu:reports")}
              </h1>
            </div>
            <ListGroup.Item action tabIndex={0} className="d-flex flex-row album-element align-items-center border p-3"
              onClick={e => {
                e.preventDefault();
                toggleOpen(VISITS_CONST)

                if (!open[ VISITS_CONST ]) {
                  const dates = calculateDaysBehind(6)
                  props.getVisitsData(
                    API_GET_VISITS_PER_DAY_ID, API_GET_VISITS_PER_DAY_URL +
                    dates.dayFrom + "/" + dates.dayTo + "/"
                  )
                    .then((response) => {
                      let _users = []
                      let _visits = []
                      let _visitors = []
                      let _days = []

                      for (let i = 0; i < response.length; i++) {
                        _users.push(response[ i ].users)
                        _visits.push(response[ i ].visits)
                        _visitors.push(response[ i ].visitors)
                        _days.push(response[ i ].day)
                      }

                      props.setChartInfo({
                        interval: DAY_CONST,
                        period: DAY_CONST,
                        presentationType: CHART_TYPE_NORMAL,
                        chartType: CHART_TYPE_BAR,
                        users: _users,
                        visitors: _visitors,
                        visits: _visits,
                        days: _days,
                        dayFrom: dates.dayFrom,
                        dayTo: dates.dayTo
                      })
                    }
                    )
                }
              }
              }
            >
              <div className="d-flex flex-grow-1 flex-row align-items-center"
                aria-controls={VISITS_CONST}
                aria-expanded={open[ VISITS_CONST ]}>
                <SeeMoreIcon isOpen={open[ VISITS_CONST ]} className="mr-3"/>
                <b>{t('visits-statistics-header')}</b>
              </div>
            </ListGroup.Item>
            <Collapse in={open[ VISITS_CONST ]} id={VISITS_CONST}>
              <span className="pr-3">
                <VisitsReportsHandler />
              </span>
            </Collapse>
          </div>

          <div>
            <ListGroup.Item action tabIndex={0} className="d-flex flex-row album-element align-items-center border p-3"
              onClick={e => {
                toggleOpen(COLLECTIONS_CONST)

                if (!open[ COLLECTIONS_CONST ]) {
                  const dates = calculateDaysBehind(14)
                  props.setDaysScope(dates)

                  props.getCollectionsData(
                    API_GET_COLLECTIONS_STATS_ID, API_GET_COLLECTIONS_STATS_URL +
                    dates.dayFrom + "/" + dates.dayTo + "/"
                  )
                    .then((response) => {
                      let myData = handleCollectionsData(response)
                      props.setCollections(myData)
                    }
                    )
                }
              }
              }>
              <div className="d-flex flex-grow-1 flex-row align-items-center"
                aria-controls={COLLECTIONS_CONST}
                aria-expanded={open[ COLLECTIONS_CONST ]}>
                <SeeMoreIcon isOpen={open[ COLLECTIONS_CONST ]} className="mr-3"/>
                <b>{t('collcections-statistics-header')}</b>
              </div>
            </ListGroup.Item>
            <Collapse in={open[ COLLECTIONS_CONST ]} id={COLLECTIONS_CONST}>
              <span className="pr-3">
                <CollectionReportsHandler />
              </span>
            </Collapse>
          </div>

          <div>
            <ListGroup.Item action tabIndex={0} className="d-flex flex-row album-element align-items-center border p-3"
              onClick={e => {
                toggleOpen(SIZEDB_CONST)

                if (!open[ SIZEDB_CONST ]) {
                  const dates = calculateDaysBehind(14)
                  props.setDaysScope(dates)

                  props.getIconSizeData(
                    API_GET_SIZE_STATS_ID, API_GET_SIZE_STATS_URL +
                    dates.dayFrom + '/' + dates.dayTo + "/"

                  )
                    .then((response) => {
                      let datesForSizeReport = []
                      let iconSizeData = []
                      let timeUnit = "hour"

                      response.forEach((item) => {
                        datesForSizeReport.push(item[ "data" ])
                        iconSizeData.push(item[ "rozmiar" ] / 1000000000) // convert byte to gigabyte
                      })

                      props.setIconSizeData({
                        "datesForSizeReport": datesForSizeReport,
                        "iconSizeData": iconSizeData,
                        "timeUnit": timeUnit,
                        "sizeUnit": "gigabyte"
                      })
                    })
                }
              }
              }
            >
              <div className="d-flex flex-grow-1 flex-row align-items-center"
                aria-controls={SIZEDB_CONST}
                aria-expanded={open[ SIZEDB_CONST ]}>
                <SeeMoreIcon isOpen={open[ SIZEDB_CONST ]} className="mr-3"/>
                <b>{t('size-statistics-header')}</b>
              </div>
            </ListGroup.Item>
            <Collapse in={open[ SIZEDB_CONST ]} id={SIZEDB_CONST}>
              <span className="pr-3">
                <IconSizeReportsHandler />
              </span>
            </Collapse>
          </div>


          <div>
            <ListGroup.Item action tabIndex={0} className="d-flex flex-row album-element align-items-center border p-3"
              onClick={e => {
                toggleOpen(DOWNLOADS_CONST)

                if (!open[ DOWNLOADS_CONST ]) {
                  const dates = calculateDaysBehind(7)
                  props.setDaysScope(dates)

                  props.getDownloadsData(
                    API_GET_DOWNLOADS_STATS_PER_DAY_ID, API_GET_DOWNLOADS_STATS_PER_DAY_URL +
                    dates.dayFrom + "/" + dates.dayTo + "/"
                  )
                    .then((response) => {
                      let _users = []
                      let _number_downloads = []
                      let _taxons = []
                      let _days = []

                      for (let i = 0; i < response.length; i++) {
                        _users.push(response[ i ].users)
                        _number_downloads.push(response[ i ].number_downloads)
                        _taxons.push(response[ i ].taxons)
                        _days.push(response[ i ].day)
                      }

                      const data = {
                        "users": _users,
                        "number_downloads": _number_downloads,
                        "taxons": _taxons,
                        "days": _days,
                        "interval": DAY_CONST,
                        "presentationType": CHART_TYPE_NORMAL,
                        "chartType": CHART_TYPE_BAR,
                        "dayFrom": dates.dayFrom,
                        "dayTo": dates.dayTo
                      }
                      props.setDailyDownloadsData(data)
                    })
                }
              }
              }>
              <div className="d-flex flex-grow-1 flex-row align-items-center"
                aria-controls={DOWNLOADS_CONST}
                aria-expanded={open[ DOWNLOADS_CONST ]}>
                <SeeMoreIcon isOpen={open[ DOWNLOADS_CONST ]} className="mr-3"/>
                <b>{t('downloads-statistics-header')}</b>
              </div>
            </ListGroup.Item>
            <Collapse in={open[ DOWNLOADS_CONST ]} id={DOWNLOADS_CONST}>
              <span className="pr-3">
                <DownloadsReportsHandler />
              </span>
            </Collapse>
          </div>
        </>
      }
    </Container>
  )
}

const mapStateToProps = state => ({
  user: state.user ? state.user.data : null,
  charts: state.charts,
  getVisitsPerDay: isPending(API_GET_VISITS_PER_DAY_ID, state),
  getCollections: isPending(API_GET_COLLECTIONS_STATS_ID, state),
  getIconSize: isPending(API_GET_SIZE_STATS_ID, state)
})

const mapDispatchToProps = dispatch => ({
  getVisitsData: (id, url) => dispatch(getDataApi(id, url)),
  setChartInfo: (data) => dispatch(chartInfo(data)),
  setDaysScope: (days) => dispatch(setDaysScope(days)),
  getCollectionsData: (id, url) => dispatch(getDataApi(id, url)),
  setCollections: (data) => dispatch(setCollections(data)),
  setIconSizeData: (data) => dispatch(setIconSizeData(data)),
  getIconSizeData: (id, url) => dispatch(getDataApi(id, url)),
  getDownloadsData: (id, url) => dispatch(getDataApi(id, url)),
  setDailyDownloadsData: (data) => dispatch(setDailyDownloadsData(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportsContainer)
