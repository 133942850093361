import React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next'
import * as qs from 'query-string'
import { Redirect } from "react-router";
import { Helmet } from 'react-helmet';
import { PORTAL_BASE_URL } from '../settings'

const I18nPathTranslator = props => {
    const { i18n } = useTranslation()
    const [lang, setLang] = useState(props.lang)
    const parsed = qs.parse(props.location.search)

    useEffect(()=>{
        if (parsed.lang === 'en' || parsed.lang === 'pl' || parsed.lang === 'EN' || parsed.lang === 'PL') {
            setLang(parsed.lang.toLocaleLowerCase())
            i18n.changeLanguage(parsed.lang.toLocaleLowerCase())
            localStorage.setItem('lang', parsed.lang.toLocaleLowerCase())
        } else if (lang !== null){
            i18n.changeLanguage(lang)
            localStorage.setItem('lang', lang)
        }
    },[lang, i18n, parsed.lang])
    
    return (
        <>
            {i18n.languages[0] !== props.lang && i18n.languages[0] === "pl" && 
                <Redirect from={props.location.path} to={props.pathPL+"/"}></Redirect>
            }
            {i18n.languages[0] !== props.lang && i18n.languages[0] === "en" && 
                <Redirect from={props.location.path} to={props.pathEN+"/"}></Redirect>
            }
            <Helmet>
                <link rel="alternate" hreflang="pl" href={`${PORTAL_BASE_URL}${props.pathPL}/`} />
                <link rel="alternate" hreflang="en" href={`${PORTAL_BASE_URL}${props.pathEN}/`} />
            </Helmet>
        </>
    )
}

export default I18nPathTranslator
