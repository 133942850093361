import React, { useState, forwardRef, Suspense } from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getField, isPending, getOutput, prepareFilter } from "../../../reducers";
import {
    ARRANGEMENT_TOOLS_ARRANGEMENT_PANEL,
    COLLECTION_TYPES_REQUEST_ID,
    MAP_OPENED_TOOL_DRAWINGS_STYLE,
    MAP_OPENED_TOOL_SAVE_AND_LOAD,
    MAP_OPENED_TOOL_PREDEFINED_RESOURCES,
    MAP_OPENED_TOOL_OBSERVATIONS,
    MAP_OPENED_MANAGE_MAPTOOLS,
    MAP_OPENED_MANAGE_QUICKSAVE,
    MAP_OPENED_MANAGE_PERSONALIZATION,
    TAXONDB_SEARCH_GENUS_REQUEST_ID,
    TAXON_SEARCH_IS_DYNAMIC,
    TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID,
    TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID,
    PROJECTS_GET_ALL_ID,
    PROJECTS_GET_ALL_URL,
} from '../../../settings'
import IconStyleHandler from "../IconStyleHandler";
import MapDropzone from "../MapDropzone";
import FileExporter from "../FileExporter";
import UserMapsHandler from "../UserMapsHandler";
import PredefinedWMS from "../PredefinedWMS";
import ShapefilesHandler from "../ShapefilesHandler";
import { CoordinateLayers } from "../CoordinateLayers";
import LayersPanel from "../LayersPanel";
import MapObservations from "../MapObservations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTools, faEdit, faSave, faPalette, faCloudDownloadAlt, faLayerGroup, faLeaf, faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import Toolkit from "../Toolkit";
import UserLegendCustomizer from "../UserLegendCustomizer";
import "../../../styles/map-sidebar.scss"
import { Collapse } from "react-bootstrap"
import { setOpenedMapTool } from "../../../actions/map";
import SaveLoadMapContainer from "../saveLoadMap/SaveLoadMapContainer";
import Loader from '../../../utils/loader'
import { postDataApi } from '../../../actions'

const ControlPanel = forwardRef((props, ref) => {

    const { t } = useTranslation([ 'taxondb', 'map', 'common' ])
    const { isDynamic, filter1, filter2, filter3, openSlidebar, closeSlidebar, collapsed, activeMapTool, setActiveMapTool } = {...props}

    const [mapLabelsToDisplayOnClick] = useState(new Set())
    const [mapName, setMapName] = useState("Nowa mapa")

    const changeMapName = (value) => {
        setMapName(value)
        ref.exportImageLayer.current.options.filename = value
    }

    const getFilter = () => {
        let filter
        if (isDynamic === "0"){
            filter = filter1
        }
        if (isDynamic === "2"){
            filter = filter2
        }
        if (isDynamic === "1"){
            filter = filter3
        }
        return filter
    }

    return(
        <Suspense fallback={<Loader />}>
        {collapsed &&
        <div className="sidebar-collapsed-buttons-container">
            <div className="sidebar-single-button-container">
                <button
                    title={t('map:customizer-personalize-map')}
                    className="sidebar-button-collapsed"
                    onClick={() => {
                        openSlidebar()
                        setActiveMapTool(MAP_OPENED_MANAGE_PERSONALIZATION)
                    }
                }>
                    <FontAwesomeIcon icon={faEdit} color={"white"}/>
                </button>
            </div>
            <div className="sidebar-single-button-container">
                <button
                    title={t("map:tab-observations")}
                    className="sidebar-button-collapsed"
                    onClick={() => {
                        openSlidebar()
                        setActiveMapTool(MAP_OPENED_TOOL_OBSERVATIONS)
                        props.getProjects()
                    }} 
                >
                    <FontAwesomeIcon icon={faLeaf} color={"white"}/>
                </button>
            </div>
            <div className="sidebar-single-button-container">
                <button
                    title={t('map:tab-layers-panel')}
                    className="sidebar-button-collapsed"
                    onClick={() => {
                        openSlidebar()
                        setActiveMapTool(MAP_OPENED_TOOL_PREDEFINED_RESOURCES)
                    }} 
                >
                    <FontAwesomeIcon icon={faLayerGroup} color={"white"}/>
                </button>
            </div>
            <div className="sidebar-single-button-container">
                <button
                    title={t("map:map-icon-style-anc-markers")} 
                    className="sidebar-button-collapsed"
                    onClick={() => {
                        openSlidebar()
                        setActiveMapTool(MAP_OPENED_TOOL_DRAWINGS_STYLE)
                    }} 
                >
                    <FontAwesomeIcon icon={faPalette} color={"white"}/>
                </button>
            </div>
            <div className="sidebar-single-button-container">
                <button
                    title={t("map:tab-save-load")} 
                    className="sidebar-button-collapsed"
                    onClick={() => {
                        openSlidebar()
                        setActiveMapTool(MAP_OPENED_TOOL_SAVE_AND_LOAD)
                    }} 
                >
                    <FontAwesomeIcon icon={faCloudDownloadAlt} color={"white"}/>
                </button>
            </div>
            <div className="sidebar-single-button-container">
                <button
                    title={t('map:quicksave-button')}
                    className="sidebar-button-collapsed"
                    onClick={() => {
                        openSlidebar()
                        setActiveMapTool(MAP_OPENED_MANAGE_QUICKSAVE)
                    }} 
                >
                    <FontAwesomeIcon icon={faSave} color={"white"}/>
                </button>
            </div>
            <div className="sidebar-single-button-container">
                <button
                    title={t("map:toolkit-manage")}
                    className="sidebar-button-collapsed"
                    onClick={() => {
                        openSlidebar()
                        setActiveMapTool(MAP_OPENED_MANAGE_MAPTOOLS)
                    }
                }>
                    <FontAwesomeIcon icon={faTools} color={"white"}/>
                </button>
            </div>
        </div>
        }
        {!collapsed &&
        <div>

            <div className="sidebar-opened-buttons-container" onBlur={e=>{if(!(activeMapTool === MAP_OPENED_MANAGE_PERSONALIZATION)){closeSlidebar()}}}>
                <div className="sidebar-single-button-container">
                    <button
                        title={t('map:customizer-personalize-map')}
                        className="sidebar-button-collapsed"
                        onClick={() => {activeMapTool === MAP_OPENED_MANAGE_PERSONALIZATION ? setActiveMapTool(null) : setActiveMapTool(MAP_OPENED_MANAGE_PERSONALIZATION)}}
                    >
                        <FontAwesomeIcon icon={faEdit} color={"white"}/>
                    </button>
                </div>
                <div
                    className="sidebar-text playContentOpacityAnimation changeContentOpacityAnimation"
                    onClick={() => {activeMapTool === MAP_OPENED_MANAGE_PERSONALIZATION ? setActiveMapTool(null) : setActiveMapTool(MAP_OPENED_MANAGE_PERSONALIZATION)}}
                >
                    {t('map:customizer-personalize-map')}
                </div>
                <div className="sidebar-items-arrow changeOpacityAnimation playOpacityAnimation">
                    <FontAwesomeIcon icon={activeMapTool === MAP_OPENED_MANAGE_PERSONALIZATION ? faAngleDown : faAngleRight} />
                </div>
            </div>
            <Collapse in={activeMapTool === MAP_OPENED_MANAGE_PERSONALIZATION}>
                <div className="ml-4 playContentOpacityAnimation changeContentOpacityAnimation">
                    <UserLegendCustomizer mapName={mapName} changeMapName={changeMapName} closeSlidebar={closeSlidebar} ref={{"mapRef": ref.mapRef, "userMapDescriptionRef": ref.userMapDescriptionRef}} />
                </div>
            </Collapse>

            <div className="sidebar-opened-buttons-container">
                <div className="sidebar-single-button-container">
                    <button
                        title={t("map:tab-observations")}
                        className="sidebar-button-collapsed"
                        onClick={() => {
                            if (activeMapTool === MAP_OPENED_TOOL_OBSERVATIONS) {
                                setActiveMapTool(null)
                            }
                            else {
                                setActiveMapTool(MAP_OPENED_TOOL_OBSERVATIONS)
                                props.getProjects()
                            }
                        }}
                    >
                        <FontAwesomeIcon icon={faLeaf} color={"white"}/>
                    </button>
                </div>
                <div
                    className="sidebar-text playContentOpacityAnimation changeContentOpacityAnimation"
                    onClick={() => {
                        if (activeMapTool === MAP_OPENED_TOOL_OBSERVATIONS) {
                            setActiveMapTool(null)
                        }
                        else {
                            setActiveMapTool(MAP_OPENED_TOOL_OBSERVATIONS)
                            props.getProjects()
                        }
                    }}
                >
                    {t("map:tab-observations")}
                </div>
                <div className="sidebar-items-arrow changeOpacityAnimation playOpacityAnimation">
                        <FontAwesomeIcon icon={activeMapTool === MAP_OPENED_TOOL_OBSERVATIONS ? faAngleDown : faAngleRight} />
                </div>
            </div>
            <Collapse in={activeMapTool === MAP_OPENED_TOOL_OBSERVATIONS}>
                <div title={t('map:tab-observations')} className="ml-4 playContentOpacityAnimation changeContentOpacityAnimation">
                    <MapObservations
                        ref={{ 
                            "mapRef" : ref.mapRef,
                            "observationsRef" : ref.observationsRef,
                        }}
                    />
                </div>
            </Collapse>

            <div className="sidebar-opened-buttons-container">
                <div className="sidebar-single-button-container">
                    <button
                        title={t("map:tab-layers-panel")}
                        className="sidebar-button-collapsed"
                        onClick={() => {activeMapTool === MAP_OPENED_TOOL_PREDEFINED_RESOURCES ? setActiveMapTool(null) : setActiveMapTool(MAP_OPENED_TOOL_PREDEFINED_RESOURCES)}
                    }>
                        <FontAwesomeIcon icon={faLayerGroup} color={"white"}/>
                    </button>
                </div>
                <div
                    className="sidebar-text playContentOpacityAnimation changeContentOpacityAnimation"
                    onClick={() => {activeMapTool === MAP_OPENED_TOOL_PREDEFINED_RESOURCES ? setActiveMapTool(null) : setActiveMapTool(MAP_OPENED_TOOL_PREDEFINED_RESOURCES)}}
                >
                    {t("map:tab-layers-panel")}
                </div>
                <div className="sidebar-items-arrow changeOpacityAnimation playOpacityAnimation">
                    <FontAwesomeIcon icon={activeMapTool === MAP_OPENED_TOOL_PREDEFINED_RESOURCES ? faAngleDown : faAngleRight} />
                </div>
            </div>
            <Collapse in={activeMapTool === MAP_OPENED_TOOL_PREDEFINED_RESOURCES}>
                <div className="predefined-layers-container playContentOpacityAnimation changeContentOpacityAnimation"
                    title={t("map:tab-layers-panel")}
                    >
                    <div className=" ml-4">
                        <div className="flex-grow-1">
                            <LayersPanel
                                hideOptions={true}
                                ref={{
                                    "mapRef" : ref.mapRef,
                                    "userDrawingsRef": ref.userDrawingsRef,
                                    "drawRef": ref.drawRef,
                                    "ancClasteredTaxons": ref.ancRef,
                                    "unclasteredAncTaxonsRef": ref.unclasteredAncTaxonsRef,
                                    "ancTaxonsWithLegend": ref.ancTaxonsWithLegend,
                                    "historicalAnalysisRef": ref.historicalAnalysisRef,
                                    "animationLayerRef": ref.animationLayerRef,
                                    "cartogramRef": ref.cartogramRef,
                                    "observationsRef": ref.observationsRef
                                }}
                            />
                        </div>
                        <CoordinateLayers ref={{"mapRef": ref.mapRef}}/>
                        <ShapefilesHandler ref={{ "mapRef": ref.mapRef}} />
                        <PredefinedWMS ref={ref.mapRef} />
                    </div>
                </div>
            </Collapse>

            <div className="sidebar-opened-buttons-container">
                <div className="sidebar-single-button-container"> 
                    <button
                        title={t("map:map-icon-style-anc-markers")}
                        className="sidebar-button-collapsed"
                        onClick={() => { activeMapTool === MAP_OPENED_TOOL_DRAWINGS_STYLE ? setActiveMapTool(null) : setActiveMapTool(MAP_OPENED_TOOL_DRAWINGS_STYLE)}
                    }>
                        <FontAwesomeIcon icon={faPalette} color={"white"}/>
                    </button>
                </div>
                <div 
                    className="sidebar-text playContentOpacityAnimation changeContentOpacityAnimation"
                    onClick={() => { activeMapTool === MAP_OPENED_TOOL_DRAWINGS_STYLE ? setActiveMapTool(null) : setActiveMapTool(MAP_OPENED_TOOL_DRAWINGS_STYLE)}
                }> 
                    {t("map:map-icon-style-anc-markers")} 
                </div> 
                <div className="sidebar-items-arrow changeOpacityAnimation playOpacityAnimation">
                    <FontAwesomeIcon icon={activeMapTool === MAP_OPENED_TOOL_DRAWINGS_STYLE ? faAngleDown : faAngleRight} />
                </div>
            </div>
            <Collapse in={activeMapTool === MAP_OPENED_TOOL_DRAWINGS_STYLE}>
                <div
                    className="ml-4 playContentOpacityAnimation changeContentOpacityAnimation"
                    title={t('map:tab-map-style')}>
                    <IconStyleHandler
                        hideFoundTaxonsCustomizer={true}
                        filter={getFilter()} 
                        mapLabelsToDisplayOnClick={mapLabelsToDisplayOnClick} 
                        ref={{"mapRef" : ref.mapRef, 
                        "ancTaxonsWithLegend": ref.ancTaxonsWithLegend, "userDrawingsRef" : ref.userDrawingsRef, "drawRef": ref.drawRef
                    }} 
                    />     
                </div>                
            </Collapse>
        
            <div className="sidebar-opened-buttons-container">
                <div className="sidebar-single-button-container"> 
                    <button
                        title={t("map:tab-save-load")} 
                        className="sidebar-button-collapsed"
                        onClick={() => {activeMapTool === MAP_OPENED_TOOL_SAVE_AND_LOAD ? setActiveMapTool(null) : setActiveMapTool(MAP_OPENED_TOOL_SAVE_AND_LOAD)}
                    }>
                        <FontAwesomeIcon icon={faCloudDownloadAlt} color={"white"}/>
                    </button>
                </div>
                <div 
                    className="sidebar-text playContentOpacityAnimation changeContentOpacityAnimation"
                    onClick={() => { activeMapTool === MAP_OPENED_TOOL_SAVE_AND_LOAD ? setActiveMapTool(null) : setActiveMapTool(MAP_OPENED_TOOL_SAVE_AND_LOAD)}
                }> 
                    {t("map:tab-save-load")} 
                </div> 
                <div className="sidebar-items-arrow changeOpacityAnimation playOpacityAnimation">
                    <FontAwesomeIcon icon={activeMapTool === MAP_OPENED_TOOL_SAVE_AND_LOAD ? faAngleDown : faAngleRight} />
                </div>
            </div>
            <Collapse in={activeMapTool === MAP_OPENED_TOOL_SAVE_AND_LOAD}>
                <div className="ml-4 playContentOpacityAnimation changeContentOpacityAnimation">
                    <div style={{border:"solid", borderWidth:"1px", padding: "10px", borderColor: "#c9c9c9"}}>
                        <MapDropzone ref={{"mapRef" : ref.mapRef}}></MapDropzone>
                        <div style={{marginRight: "15px", marginLeft: "15px", marginBottom: "5px", marginTop: "5px"}}>
                            <FileExporter style={{marginRight: "100px"}} ref={{"userDrawingsRef": ref.userDrawingsRef}}></FileExporter>
                        </div>
                        <UserMapsHandler
                            ref={{"mapRef" : ref.mapRef, "userDrawingsRef" : ref.userDrawingsRef}} 
                            filter={getFilter()} 
                        /> 
                    </div>
                </div>
            </Collapse>

            <div className="sidebar-opened-buttons-container">
                <div className="sidebar-single-button-container">
                    <button
                        title={t('map:quicksave-button')}
                        className="sidebar-button-collapsed"
                        onClick={() => {activeMapTool === MAP_OPENED_MANAGE_QUICKSAVE ? setActiveMapTool(null) : setActiveMapTool(MAP_OPENED_MANAGE_QUICKSAVE)}}
                    >
                        <FontAwesomeIcon icon={faSave} color={"white"}/>
                    </button>
                </div>
                <div
                    className="sidebar-text playContentOpacityAnimation changeContentOpacityAnimation"
                    onClick={() => {activeMapTool === MAP_OPENED_MANAGE_QUICKSAVE ? setActiveMapTool(null) : setActiveMapTool(MAP_OPENED_MANAGE_QUICKSAVE)}}
                >
                    {t("map:quicksave-button")}
                </div>
                <div className="sidebar-items-arrow changeOpacityAnimation playOpacityAnimation">
                    <FontAwesomeIcon icon={activeMapTool === MAP_OPENED_MANAGE_QUICKSAVE ? faAngleDown : faAngleRight} />
                </div>
            </div>
            <Collapse in={activeMapTool === MAP_OPENED_MANAGE_QUICKSAVE}>
                <div className="ml-4 playContentOpacityAnimation changeContentOpacityAnimation">
                    <SaveLoadMapContainer mapLayer={ref.mapRef} drawingsLayer={ref.userDrawingsRef} />
                </div>
            </Collapse>

            <div className="sidebar-opened-buttons-container"> 
                <div className="sidebar-single-button-container"> 
                    <button
                        title={t("map:toolkit-manage")}
                        className="sidebar-button-collapsed"
                        onClick={() => {activeMapTool === MAP_OPENED_MANAGE_MAPTOOLS ? setActiveMapTool(null) : setActiveMapTool(MAP_OPENED_MANAGE_MAPTOOLS)}}
                    >
                        <FontAwesomeIcon icon={faTools} color={"white"}/>
                    </button>
                </div>
                    <div
                        className="sidebar-text playContentOpacityAnimation changeContentOpacityAnimation"
                        onClick={() => {activeMapTool === MAP_OPENED_MANAGE_MAPTOOLS ? setActiveMapTool(null) : setActiveMapTool(MAP_OPENED_MANAGE_MAPTOOLS)}}
                    > 
                    {t("map:toolkit-manage")} 
                    </div> 
                <div className="sidebar-items-arrow changeOpacityAnimation playOpacityAnimation">
                    <FontAwesomeIcon icon={activeMapTool === MAP_OPENED_MANAGE_MAPTOOLS ? faAngleDown : faAngleRight} />
                </div>
            </div>
            <Collapse in={activeMapTool === MAP_OPENED_MANAGE_MAPTOOLS}> 
                <div className="ml-4 playContentOpacityAnimation changeContentOpacityAnimation">
                    <Toolkit preferences={ref.toolkitPreferencesRef.current} ref={{"mapRef": ref.mapRef, "drawRef": ref.drawRef, "exportImageLayer": ref.exportImageLayer}} />
                </div>
            </Collapse>
        
        </div>
        }
        </Suspense> 
    )
})

const mapStateToProps = (state, ownProps) => ({
    loading1: isPending(TAXONDB_SEARCH_GENUS_REQUEST_ID, state),
    loading2: isPending(TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID, state),
    loading3: isPending(TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, state),
    collectionTypes: getOutput(COLLECTION_TYPES_REQUEST_ID, state),
    showArrangementPanel: getField(ARRANGEMENT_TOOLS_ARRANGEMENT_PANEL, ARRANGEMENT_TOOLS_ARRANGEMENT_PANEL, state, true),
    filter1: prepareFilter(TAXONDB_SEARCH_GENUS_REQUEST_ID, state, ownProps.path),
    filter2: JSON.parse(getField(TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID, TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID, state, "null")),
    filter3: JSON.parse(getField(TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, state, "null")),
    isDynamic: getField(TAXON_SEARCH_IS_DYNAMIC, TAXON_SEARCH_IS_DYNAMIC, state, "0"),
    activeMapTool: state.map.openedMapTool,
})

const mapDispatchToProps = dispatch => ({
    setActiveMapTool: (value) => dispatch(setOpenedMapTool(value)),
    getProjects: () => dispatch(postDataApi(PROJECTS_GET_ALL_ID, PROJECTS_GET_ALL_URL)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(ControlPanel)
