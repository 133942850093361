import React from 'react'
import ComparedPicture from './ComparedPicture'
import { connect } from 'react-redux'
import { Container } from "react-bootstrap"
import { useTranslation } from 'react-i18next'
import I18nPathTranslator from '../I18nPathTranslator'
import { COMPARATOR_PAGE_URL_EN, COMPARATOR_PAGE_URL_PL } from '../../settings'
import SEO from '../SEO'

const Comparator = (props) => {
    const { t } = useTranslation(['icondb'])

    return (
        <Container>
            <I18nPathTranslator {...props} pathEN={COMPARATOR_PAGE_URL_EN} pathPL={COMPARATOR_PAGE_URL_PL} />
            <SEO title={t('comparator-choose-pictures-info')} />
            {!props.comparator &&
            <>
                {t('comparator-choose-pictures-info')}
            </>
            }
            {
            props.comparator &&
            <ComparedPicture />
            }
        </Container>
    )
}

const mapStateToProps = state => ({
    user: state.user ? state.user.data : null,
    comparator: state.comparator,
  })
  
const mapDispatchToProps = dispatch => ({
})
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Comparator)

