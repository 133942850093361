import React from "react";
import { connect } from 'react-redux'
import { getField } from "../reducers";
import { setField } from '../actions/forms'
import "../styles/react-autosuggest.scss"
import { setElementStructure, getElementStructure } from '../utils/search'


const InputSimpleText = props =>
    <input
        id={props.id}
        disabled={props.disabled}
        onChange={e => {
            if (props.onChange) {
                props.onChange(e)
            }
            if (props.conditionalChange) {
                if (props.conditionalChange(e)) {
                    props.setSelectedValue(e.target.value, props.fields)
                }
            } else {
                props.setSelectedValue(e.target.value, props.fields)
            }
        }
        }
        onKeyUp={props.onKeyUp}
        value={props.selectedValue}
        className='form-control'
        autoComplete="off"
    >
    </input>

const mapStateToProps = (state, ownProps) => ({
    selectedValue: ownProps.path ?
        getElementStructure(ownProps.path,
            JSON.parse(getField(ownProps.formId, ownProps.fieldId ? ownProps.fieldId : ownProps.formId, state, "null"))) :
        getField(ownProps.formId, ownProps.id, state),
    fields: JSON.parse(getField(ownProps.formId, ownProps.fieldId ? ownProps.fieldId : ownProps.formId, state, "null")),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    setSelectedValue: (value, fields) =>
        ownProps.path ?
            dispatch(setField(ownProps.formId, ownProps.fieldId ? ownProps.fieldId : ownProps.formId, JSON.stringify(setElementStructure(ownProps.path, fields, value)))) :
            dispatch(setField(ownProps.formId, ownProps.id, value)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InputSimpleText)
