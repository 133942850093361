import React, { Suspense, useState } from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import SearchForm from "./taxonDB/SearchForm"
import DynamicSearchForm from "./taxonDB/DynamicSearchForm"
import DynamicSearchForm2 from "./taxonDB/DynamicSearchForm2"
import ResultsContainer from "./stats/ResultsContainer"
import { Container, Button, Row, Col, Tabs, Tab } from "react-bootstrap"
import {
  STATS_SEARCH_REQUEST_ID,
  STATS_DYNAMIC_SEARCH_REQUEST_ID,
  STATS_DYNAMIC2_SEARCH_REQUEST_ID,
  STATS_SEARCH_IS_DYNAMIC,
  AGGREGATION_REPORT_REQUEST_ID_PREFIX,
  STATS_PAGE_URL_EN,
  STATS_PAGE_URL_PL,
} from '../settings'
import Loader from '../utils/loader'
import { getField, isPending } from "../reducers";
import { setField, runSearchFlowStats } from '../actions/forms'
import { requestRemove, requestRemoveStartsWith } from '../actions'
import I18nPathTranslator from "./I18nPathTranslator";
import SEO from "./SEO";
import RedirectLoginWithReturn from "./access/RedirectLoginWithReturn";

const StatsContainer = props => {

  const { t } = useTranslation([ 'taxondb', 'stats', 'map', 'paths' ])
  const [ showSearchPanel, setShowSearchPanel ] = useState(true)

  const onSearch = (id, path) => (dispatch, getState) => {
    dispatch(runSearchFlowStats(id, path))
  }

  const onClear = id => (dispatch, getState) => {
    dispatch(requestRemove(id))
    dispatch(requestRemoveStartsWith(AGGREGATION_REPORT_REQUEST_ID_PREFIX + id))
  }

  return (
    <Suspense fallback={<Loader />}>
      <I18nPathTranslator {...props} pathEN={STATS_PAGE_URL_EN} pathPL={STATS_PAGE_URL_PL} />
      <SEO title={t('stats:form-title')} />
      <div>
        {props.loading && <Loader />}
        {!props.loading && props.user && !props.user.data &&
            <RedirectLoginWithReturn lang={props.lang} returnUrl={props.lang ? t("paths:stats-page", {lng: props.lang}) : t("paths:stats-page")} />
        }
          {!props.loading && props.user && props.user.data &&
            <>
              <Container>
                <div className="mt-3 mb-4">
                  <h1>
                    {t('stats:form-title')}
                  </h1>
                </div>
                <Row>
                  <Col>
                    <Button
                      variant="primary"
                      onClick={() => setShowSearchPanel(!showSearchPanel)}
                      aria-controls="search-panel"
                      aria-expanded={showSearchPanel}
                    >
                      {showSearchPanel ? t('map:hide-search-panel') : t('map:show-search-panel')}
                    </Button>
                  </Col>
                </Row>
                {showSearchPanel &&
                  <Tabs defaultActiveKey={props.isDynamic} activeKey={props.isDynamic} onSelect={props.setIsDynamic} className="nav-tabs-search">
                    <Tab eventKey="0" title={t('simple')}>
                      {props.isDynamic === "0" &&
                        <>
                          <SearchForm
                            searchRequestId={STATS_SEARCH_REQUEST_ID}
                            onSearch={() => onSearch(STATS_SEARCH_REQUEST_ID)}
                            onClear={() => onClear(STATS_SEARCH_REQUEST_ID)}
                          ></SearchForm>
                        </>
                      }
                    </Tab>
                    <Tab eventKey="2" title={t('extended')}>
                      {props.isDynamic === "2" &&
                        <>
                          <DynamicSearchForm2
                            searchRequestId={STATS_DYNAMIC2_SEARCH_REQUEST_ID}
                            onSearch={() => onSearch(STATS_DYNAMIC2_SEARCH_REQUEST_ID, [])}
                            onClear={() => onClear(STATS_DYNAMIC2_SEARCH_REQUEST_ID)}
                            isDynamicFlag={STATS_SEARCH_IS_DYNAMIC}
                          ></DynamicSearchForm2>
                        </>
                      }
                    </Tab>
                    <Tab eventKey="1" title={t('advanced')}>
                      {props.isDynamic === "1" &&
                        <>
                          <DynamicSearchForm
                            searchRequestId={STATS_DYNAMIC_SEARCH_REQUEST_ID}
                            onSearch={() => onSearch(STATS_DYNAMIC_SEARCH_REQUEST_ID, [])}
                            onClear={() => onClear(STATS_DYNAMIC_SEARCH_REQUEST_ID)}
                          ></DynamicSearchForm>
                        </>
                      }
                    </Tab>
                  </Tabs>
                }
                {props.isDynamic === "0" &&
                  <ResultsContainer
                    searchRequestId={STATS_SEARCH_REQUEST_ID}
                  ></ResultsContainer>
                }
                {props.isDynamic === "2" &&
                  <ResultsContainer
                    searchRequestId={STATS_DYNAMIC2_SEARCH_REQUEST_ID}
                  ></ResultsContainer>
                }
                {props.isDynamic === "1" &&
                  <ResultsContainer
                    searchRequestId={STATS_DYNAMIC_SEARCH_REQUEST_ID}
                  ></ResultsContainer>
                }
              </Container>
            </>
          }
      </div>
    </Suspense>
  )
}

const mapStateToProps = state => ({
  user: state.user,
  loading: isPending('userInfo', state),
  isDynamic: getField(STATS_SEARCH_IS_DYNAMIC, STATS_SEARCH_IS_DYNAMIC, state, "0")
})

const mapDispatchToProps = dispatch => ({
  setIsDynamic: value => dispatch(setField(STATS_SEARCH_IS_DYNAMIC, STATS_SEARCH_IS_DYNAMIC, value)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StatsContainer)

