import React, { useEffect, useState } from "react"
import { Button, Col, Container, Form, FormLabel, Spinner } from "react-bootstrap"
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';
import ResultsList from "./ResultsList";
import { 
    ANC_SAMPLE_HISTORY_DETAILS_REQUEST_ID, 
    ANC_SAMPLE_HISTORY_DETAILS_REQUEST_URL, 
    ANC_SAMPLE_HISTORY_REQUEST_ID, 
    ANC_SAMPLE_HISTORY_REQUEST_URL, 
    EDITION_TYPE_CREATE, 
    EDITION_TYPE_MERGE, 
    EDITION_TYPE_SINGLE_EDIT, 
    EDITION_TYPE_UPDATE,
    ANC_SAMPLE_EDIT_USERS_REQUEST_ID,
    ANC_SAMPLE_EDIT_USERS_REQUEST_URL,
} from "../../settings";
import { getDataAnc, postDataAnc } from "../../actions";
import { getOutput, isPending } from "../../reducers";


const SamplesHistory = props => {

    const { t } = useTranslation(['forms', 'menu', 'editions-panel', 'db-history' ])
    const [user, setUser] = useState('')
    const [editionType, setEditionType] = useState('')
    const [dateStart, setDateStart] = useState('')
    const [dateEnd, setDateEnd]  = useState('')
    const [sampleId, setSampleId] = useState('')
    const [ipAddress, setIpAddress] = useState('')
    const [filter, setFilter] = useState('')
    
    const EMPTY_STRING = ""


    const onUserChange = e => {
        setUser(e.target.value)
    }

    const onEditionTypeChange = e => {
        setEditionType(e.target.value)
    }


    const onDateStartChange = e => {
        setDateStart(e.target.value)
    }

    const onDateEndChange = e => {
        setDateEnd(e.target.value)
    }

    const onSampleIdChange = e => {
        setSampleId(e.target.value)
    }

    const onIpAddressChange = e => {
        setIpAddress(e.target.value)
    }

    const onClearForm = () => {
        setUser('')
        setEditionType('')
        setSampleId('')
        setIpAddress('')
        getCurrentDate()
        getMonthAgoDate()
        setFilter({})
    }

    const onSubmitForm = () => {
        let filter = {}
        if (user){
            filter['username'] = user
        }
        if (editionType){
            filter['edition_type'] = editionType
        }
        if (sampleId){
            filter['numer_proby'] = sampleId
        }
        if (ipAddress){
            filter['user_ip'] = ipAddress
        }
        filter["od_daty"] = dateStart
        filter["do_daty"] = dateEnd
        setFilter(filter)
        props.search({filter: filter})
    }

    const getCurrentDate = () => {
        let date = new Date()
        date = date.toJSON().slice(0, 10);
        setDateEnd(date)
    }

    const getMonthAgoDate = () => {
        let date = new Date()
        let currentMonth = date.getMonth()
        date.setMonth(currentMonth - 1)
        date = date.toJSON().slice(0, 10);
        setDateStart(date)
    }

    const getUsersData = () => {
        props.getEditorsNames() 
    }

    useEffect(getUsersData, [])

    useEffect(() => {
        getCurrentDate()
        getMonthAgoDate()
    }, [])

    const handleOnKeyUp = e => {
        if (e.keyCode === 13) { // ENTER
            e.preventDefault()
            onSubmitForm()
        }
    }

    return (
        <Container>
            <Form className="mt-2 p-1 mb-2" onSubmit={e => { e.preventDefault() }}>
            <Form.Row>
                    <Col>
                        <Form.Group>
                            <FormLabel htmlFor={"dateFrom"}>{t('editions-panel:from-date')}</FormLabel>
                            <Form.Control
                                id={"dateFrom"} 
                                type="date" 
                                name="dateFrom"
                                onChange={onDateStartChange}
                                onKeyUp={handleOnKeyUp} 
                                value={dateStart}
                                required
                            >
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <FormLabel htmlFor={"dateTo"}>{t('editions-panel:to-date')}</FormLabel>
                            <Form.Control 
                                id={"dateTo"} 
                                type="date" 
                                name="dateTo" 
                                onChange={onDateEndChange}
                                onKeyUp={handleOnKeyUp} 
                                value={dateEnd}
                                required
                            >
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Label htmlFor="sampleId">{t('editions-panel:sample-id')}</Form.Label>
                            <Form.Control id="sampleId" value={sampleId} onChange={onSampleIdChange} onKeyUp={handleOnKeyUp} disabled={props.querying}></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label htmlFor="status">{t('db-history:edition-type')}</Form.Label>
                            <Form.Control id="role" as="select" value={editionType} onChange={onEditionTypeChange} onKeyUp={handleOnKeyUp} disabled={props.querying}>
                                <option value={EMPTY_STRING}>{t('editions-panel:value-any')}</option>
                                <option value={EDITION_TYPE_CREATE}>{t('db-history:et-create')}</option>
                                <option value={EDITION_TYPE_UPDATE}>{t('db-history:et-update')}</option>
                                <option value={EDITION_TYPE_MERGE}>{t('db-history:et-merge')}</option>
                                <option value={EDITION_TYPE_SINGLE_EDIT}>{t('db-history:et-single_edit')}</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                    <Form.Group>
                            <Form.Label htmlFor="user" > 
                                {t('editions-panel:username')}
                                {props.loadingEditorsNames && 
                                    <Spinner animation="border" role="status" size="sm" className="ml-2" />
                                }
                            </Form.Label>
                            <Form.Control id="user" as="select" value={user} onChange={onUserChange} onKeyUp={handleOnKeyUp} disabled={props.querying} >
                                <option value={EMPTY_STRING}>{t('editions-panel:value-any')}</option>
                                {props.editorsNames && props.editorsNames.length && props.editorsNames.length > 0 &&
                                    props.editorsNames.map(user => {
                                        return <option value={user} key={user}>{user}</option>
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <FormLabel htmlFor={"ipAddress"}>{t('editions-panel:ip')}</FormLabel>
                            <Form.Control
                                id={"ipAddress"} 
                                name="ipAddress"
                                onChange={onIpAddressChange}
                                onKeyUp={handleOnKeyUp} 
                                value={ipAddress}
                            >
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} className="d-flex align-self-end">
                        <div className="flex-grow-1" />
                        <Button variant="primary" onClick={onSubmitForm} disabled={props.querying}>
                            {t('common:search')}
                        </Button>
                        <Button variant="secondary" className="ml-2" onClick={onClearForm} disabled={props.querying} >
                            {t('common:clear')}
                        </Button>
                    </Form.Group>
                </Form.Row>
            </Form>
            <ResultsList requestId={ANC_SAMPLE_HISTORY_REQUEST_ID} requestUrl={ANC_SAMPLE_HISTORY_REQUEST_URL} 
            detailsId={ANC_SAMPLE_HISTORY_DETAILS_REQUEST_ID} detailsUrl={ANC_SAMPLE_HISTORY_DETAILS_REQUEST_URL}
            atrTrans="samplesdb-attributes" filter={filter}/>
        </Container>
    )
}

const mapStateToProps = state => ({
    editorsNames: getOutput(ANC_SAMPLE_EDIT_USERS_REQUEST_ID, state),
    loadingEditorsNames: isPending(ANC_SAMPLE_EDIT_USERS_REQUEST_ID, state),
})
  
const mapDispatchToProps = dispatch => ({
    search: data => dispatch(postDataAnc(ANC_SAMPLE_HISTORY_REQUEST_ID, ANC_SAMPLE_HISTORY_REQUEST_URL, data)),
    getEditorsNames: () => dispatch(
        getDataAnc(ANC_SAMPLE_EDIT_USERS_REQUEST_ID, ANC_SAMPLE_EDIT_USERS_REQUEST_URL)
)
})
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SamplesHistory)
