import React, { useState, Suspense } from "react"
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { isPending, getOutputOrDefault } from "../../reducers"
import { postDataApi } from '../../actions'
import { Spinner, Form } from "react-bootstrap"
import { Button } from "react-bootstrap"
import * as actions from '../../actions'
import * as notify from '../../utils/notify'
import {
    API_ALBUM_GET_ALL_ID,
    API_ALBUM_GET_ALL_URL,
    API_ALBUM_ADD_ITEM_ID,
    API_ALBUM_ADD_ITEM_URL,
    API_ALBUM_GET_ITEMS_ID_PREFIX,
    API_ALBUM_GET_ITEMS_URL,
} from '../../settings'
import Loader from '../../utils/loader'

const AddToAlbum = props => {
    const { t } = useTranslation([ 'common', 'forms', 'taxondb', 'icondb', 'profile' ])

    const [ selectedAlbumId, setSelectedAlbumId ] = useState(0)
    const [ newAlbumName, setNewAlbumName ] = useState('')

    const onSelectedAlbumIdChanged = e => {
        setSelectedAlbumId(e.target.value)
    }

    const onNewAlbumNameChanged = e => {
        setNewAlbumName(e.target.value)
    }

    return (
        <Suspense fallback={<Loader />}>
            <div className=" p-2 common-panel-background col">
                <div className="d-flex flex-wrap">
                    {(props.loadingAlbums || props.querying) &&
                        <div className="align-self-center m-1">
                            <Spinner animation="border" role="status" />
                        </div>
                    }
                    {!props.loadingAlbums && !props.querying && props.albums.length > 0 &&
                        <>
                            <label className="m-1" htmlFor={props.kind + "-" + props.itemId.toString() + "-selectedAlbum"}>{t('profile:existing-album')}:</label>
                            <div className="m-1">
                                <Form.Control size="sm" as="select" id={props.kind + "-" + props.itemId.toString() + "-selectedAlbum"} onChange={onSelectedAlbumIdChanged} value={selectedAlbumId} disabled={props.querying}>
                                    {props.albums.map((album, i) =>
                                        <option key={i} value={album.id}>{album.name === null ? t('profile:main-album') : album.name}</option>
                                    )}
                                </Form.Control>
                            </div>
                            <div className="m-1">
                                <Button size="sm" disabled={props.loadingAlbums || props.querying} onClick={e => props.addToExistingAlbum(selectedAlbumId, t)}>{t('profile:add')}</Button>
                            </div>
                        </>
                    }
                </div>
                <div className="d-flex flex-wrap mt-2">
                    <label className="m-1" htmlFor={props.kind + "-" + props.itemId.toString() + "-newAlbum"}>{t('profile:new-album')}:</label>
                    <div className="m-1">
                        <Form.Control size="sm" id={props.kind + "-" + props.itemId.toString() + "-newAlbum"} value={newAlbumName} onChange={onNewAlbumNameChanged} disabled={props.querying}></Form.Control>
                    </div>
                    <div className="m-1">
                        <Button size="sm" disabled={props.loadingAlbums || !newAlbumName || props.querying} onClick={e => props.addToNewAlbum(selectedAlbumId, newAlbumName, t)}>{t('profile:add')}</Button>
                    </div>
                </div>
            </div>
        </Suspense>
    )
}

const mapStateToProps = state => ({
    loadingAlbums: isPending(API_ALBUM_GET_ALL_ID, state),
    albums: [ { id: 0, name: null } ].concat(getOutputOrDefault(API_ALBUM_GET_ALL_ID, state, { "user_albums": [] })[ "user_albums" ]),
    querying: isPending(API_ALBUM_ADD_ITEM_ID, state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    getAlbums: () => dispatch(postDataApi(API_ALBUM_GET_ALL_ID, API_ALBUM_GET_ALL_URL)),
    addToNewAlbum: (selectedAlbum, newAlbum, t) => {
        dispatch(actions.postDataApi(API_ALBUM_ADD_ITEM_ID, API_ALBUM_ADD_ITEM_URL, {
            album_id: parseInt(selectedAlbum),
            kind: 'album',
            name: newAlbum,
        })).then(res => {
            const createdAlbumId = res.id
            dispatch(actions.postDataApi(API_ALBUM_ADD_ITEM_ID, API_ALBUM_ADD_ITEM_URL, {
                album_id: parseInt(createdAlbumId),
                kind: ownProps.kind,
                name: ownProps.itemId.toString(),
            })).then(() => {
                notify.success(t('profile:item-added-to-album'))
                dispatch(actions.postDataApi(
                    API_ALBUM_GET_ITEMS_ID_PREFIX + createdAlbumId,
                    API_ALBUM_GET_ITEMS_URL,
                    { album: createdAlbumId }))
                ownProps.onDone()
            }).catch(res => {
                let temp = t('profile:cannot-add-item-to-album')
                if (res.result === 2) {
                    temp += " " + t('profile:element-already-exists')
                }
                notify.error(temp, res.result)
            })
        }).catch(res => {
            let temp = t('profile:cannot-add-new-album')
            if (res.result === 2) {
                temp += " " + t('profile:element-already-exists')
            }
            notify.error(temp, res.result)
        })
    },
    addToExistingAlbum: (selectedAlbumId, t) => {
        dispatch(actions.postDataApi(API_ALBUM_ADD_ITEM_ID, API_ALBUM_ADD_ITEM_URL, {
            album_id: parseInt(selectedAlbumId),
            kind: ownProps.kind,
            name: ownProps.itemId.toString(),
        })).then(res => {
            notify.success(t('profile:item-added-to-album'))
            dispatch(actions.postDataApi(
                API_ALBUM_GET_ITEMS_ID_PREFIX + selectedAlbumId,
                API_ALBUM_GET_ITEMS_URL,
                { album: selectedAlbumId }))
            ownProps.onDone()
        }).catch(res => {
            let temp = t('profile:cannot-add-item-to-album')
            if (res.result === 2) {
                temp += " " + t('profile:element-already-exists')
            }
            notify.error(temp, res.result)
        })
    },
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddToAlbum)
