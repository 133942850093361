import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = props => {
    return(
        <Helmet>
            <title>{props.title ? `${props.title} | AMUNATCOLL` : 'AMUNATCOLL'}</title>
            {props.desc &&
                <meta name="description" content={props.desc} />
            }
        </Helmet>
    )
}

export default SEO
