import React, { useState } from "react"
import { connect } from 'react-redux'
import { Container,  Spinner, Form, Button, Col } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { getOutput, isPending } from "../reducers";
import UsersRolesList from './UsersRolesList'
import {
    USER_GROUPS_COORDINATOR_CONST,
    USER_GROUPS_EDITOR_CONST,
    USER_GROUPS_CONFIRMED_CONST,
    USER_GROUPS_TRUSTED_CONST,
    USER_GROUPS_LEADER_CONST,
    USER_GROUPS_SUPERLEADER_CONST,
    USER_GROUPS_VALIDATOR_CONST,
    API_GET_USERS_BY_ROLE_ID,
    API_GET_USERS_BY_ROLE_URL,
    API_URL_POSTFIX,
    USERS_ROLES_PAGE_URL_EN,
    USERS_ROLES_PAGE_URL_PL,
} from "../settings"
import { postDataApi } from "../actions"
import ConnectedPagination from './ConnectedPagination'
import I18nPathTranslator from "./I18nPathTranslator";
import SEO from "./SEO";

const UsersRoles = props => {

    const { t } = useTranslation(['forms', 'menu'])
    const EMPTY_ROLE = ""

    const [role, setRole] = useState(EMPTY_ROLE)
    const [filter, setFilter] = useState({})
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [login, setLogin] = useState('')

    const onRoleChange = e => {
        setRole(e.target.value)
    }

    const onFirstNameChanged = e => {
        setFirstName(e.target.value)
    }

    const onLastNameChanged = e => {
        setLastName(e.target.value)
    }

    const onLoginChange = e => {
        setLogin(e.target.value)
    }

    const onEmailChange = e => {
        setEmail(e.target.value)
    }

    const onClearForm = () => {
        setRole(EMPTY_ROLE)
        setEmail('')
        setFirstName('')
        setLastName('')
        setLogin('')
        setFilter({})
    }

    const onSubmitForm = () => {
        let filter = {}
        if (firstName){
            filter['first_name'] = firstName
        }
        if (lastName){
            filter['last_name'] = lastName
        }
        if (email){
            filter['email'] = email
        }
        if (login){
            filter['username'] = login
        }
        setFilter(filter)
        props.getUsersByRole(role, filter)
    }

    return (
        <Container>
            <I18nPathTranslator {...props} pathEN={USERS_ROLES_PAGE_URL_EN} pathPL={USERS_ROLES_PAGE_URL_PL} />
            <SEO title={t("menu:users-roles")} />
            <div className="mt-3 mb-4">
                <h1>
                  {t("menu:users-roles")}
                </h1>
            </div>
            <Form className="mt-2 p-1 mb-2" onSubmit={e => { e.preventDefault() }}>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Label htmlFor="role">{t('forms:user_role')}</Form.Label>
                            <Form.Control id="role" as="select" value={role} onChange={onRoleChange} disabled={props.querying}>
                                <option value={EMPTY_ROLE}>{EMPTY_ROLE}</option>
                                <option value={USER_GROUPS_COORDINATOR_CONST}>{USER_GROUPS_COORDINATOR_CONST}</option>
                                <option value={USER_GROUPS_EDITOR_CONST}>{USER_GROUPS_EDITOR_CONST}</option>
                                <option value={USER_GROUPS_VALIDATOR_CONST}>{USER_GROUPS_VALIDATOR_CONST}</option>
                                <option value={USER_GROUPS_TRUSTED_CONST}>{USER_GROUPS_TRUSTED_CONST}</option>
                                <option value={USER_GROUPS_SUPERLEADER_CONST}>{USER_GROUPS_SUPERLEADER_CONST}</option>
                                <option value={USER_GROUPS_LEADER_CONST}>{USER_GROUPS_LEADER_CONST}</option>
                                <option value={USER_GROUPS_CONFIRMED_CONST}>{USER_GROUPS_CONFIRMED_CONST}</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label htmlFor="email">{t('forms:email')}</Form.Label>
                            <Form.Control id="email" value={email} onChange={onEmailChange} disabled={props.querying}></Form.Control>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Label htmlFor="firstName">{t('forms:first_name')}</Form.Label>
                            <Form.Control id="firstName" value={firstName} onChange={onFirstNameChanged} disabled={props.querying}></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label htmlFor="lastName">{t('forms:last_name')}</Form.Label>
                            <Form.Control id="lastName" value={lastName} onChange={onLastNameChanged} disabled={props.querying}></Form.Control>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Label htmlFor="login">{t('forms:username')}</Form.Label>
                            <Form.Control id="login" value={login} onChange={onLoginChange} disabled={props.querying}></Form.Control>
                        </Form.Group>
                    </Col>
                    <Form.Group as={Col} xs={6} className="d-flex align-self-end">
                        <div className="flex-grow-1" />
                        <Button variant="primary" onClick={onSubmitForm} disabled={props.querying}>
                            {t('common:search')}
                        </Button>
                        <Button variant="secondary" className="ml-2" onClick={onClearForm} disabled={props.querying} >
                            {t('common:clear')}
                        </Button>
                    </Form.Group>
                </Form.Row>
            </Form>
            <div className="ml-4">
                {props.loading &&
                    <Spinner animation="border" role="status" />
                }
                {props.usersByRole && props.usersByRole.items && props.usersByRole.items.length === 0 &&
                    <div>
                        {t('forms:users_not_found')}
                    </div>
                }
                {props.usersByRole && props.usersByRole.items && props.usersByRole.items.length > 0 &&
                <>
                    <ConnectedPagination requestId={API_GET_USERS_BY_ROLE_ID} url={API_URL_POSTFIX + API_GET_USERS_BY_ROLE_URL} customFilter={{filter: filter, role: role}}></ConnectedPagination>
                    {props.usersByRole.items.map((userData) => {
                        return(
                            <div key={userData.id}>
                                <UsersRolesList userData={userData} />
                            </div>
                            )
                        })
                    }
                    <ConnectedPagination last requestId={API_GET_USERS_BY_ROLE_ID} url={API_URL_POSTFIX + API_GET_USERS_BY_ROLE_URL} customFilter={{filter: filter, role: role}}></ConnectedPagination>
                </>
                }
            </div>
        </Container>
    )
}

const mapStateToProps = state => ({
    user: state.user ? state.user.data : null,
    usersByRole: getOutput(API_GET_USERS_BY_ROLE_ID, state),
    loading: isPending(API_GET_USERS_BY_ROLE_ID, state),
    querying: isPending("api-role-get_users", state),

})
  
const mapDispatchToProps = dispatch => ({
    getUsersByRole: (data, filter) => dispatch(postDataApi(API_GET_USERS_BY_ROLE_ID, API_GET_USERS_BY_ROLE_URL, {
        role: data,
        filter: filter
    }))
})
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UsersRoles)