import { useState, useCallback } from 'react';

export default (value) => {
  const [ isValid, setIsValid ] = useState(false);
  const [ isInvalid, setIsInvalid ] = useState(false);

  const validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const isValidCallback = useCallback(() => {
    if (validateEmail(value)) {
      setIsInvalid(false);
      setIsValid(true);
      return true;
    }
    else {
      setIsInvalid(true);
      setIsValid(false);
      return false;
    }
  }, [ value ]);

  const clearCallback = useCallback(() => {
    setIsInvalid(false);
    setIsValid(false);
  }, []);

  return { validProps: { isValid, isInvalid }, isValidCallback, clearCallback };
};

