import React, { forwardRef } from "react"
import { connect } from 'react-redux'
import { Row, Spinner, Button, Col, Form, FormLabel, Collapse } from "react-bootstrap";
import {
    TAXONDB_SEARCH_WITH_AGGREGATION_REQUEST_ID,
    TAXONDB_SEARCH_WITH_AGGREGATION_REQUEST_URL,
    CARTODIAGRAM_REQUEST_TIMEOUT,
    MAP_COLORPICKER_LIST,
    TAXONDB_SEARCH_GENUS_REQUEST_ID,
    TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID,
    TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID,
    TAXON_SEARCH_IS_DYNAMIC,
    MAP_OPENED_TOOL_VISUALIZATION_DETAILS,
} from "../../../../settings"
import { getField, getFields, isPending } from "../../../../reducers";
import { useTranslation } from 'react-i18next'
import { setFields, runCartogramSearch } from '../../../../actions/forms'
import * as actions from '../../../../actions'
import { GENUS_ATTRIBUTE_NAME, SPECIES_ATTRIBUTE_NAME, ROK_ZBIORU_FIELD_NAME } from '../../../taxonDB/attributesStructure'
import { CirclePicker } from "react-color";
import { faAngleDown, faAngleRight, faMapMarked } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cartodiagramSearchItems } from "./CartodiagramSearch"
import { setCartodiagramGroupingField, setCartodiagramNumberOfChartColors, setCartogramAdministrativeDivision, setCartogramBordersColor, setCartogramBordersWidth, setCartogramFirstAreaColor, setCartogramOpacity, setCartogramSecondAreaColor, setOpenedMapTool } from "../../../../actions/map";

const Cartodiagram = forwardRef((props, ref) => {

    const { t } = useTranslation(['map', 'taxondb-attributes', 'taxondb-groups', 'common'])
    const { collapsed, openSlidebar, cartogramAdministrativeDivision, cartogramBordersColor, cartogramBordersWidth, cartogramOpacity, setCartogramOpacity, setCartogramBordersWidth, setCartogramBordersColor, setCartogramAdministrativeDivision, cartogramFirstAreaColor, cartogramSecondAreaColor, setCartogramFirstAreaColor, setCartogramSecondAreaColor, cartodiagramGroupingField, setCartodiagramGroupingField, numberOfChartColors, setCartodiagramNumberOfChartColors, setActiveMapTool, activeMapTool } = { ...props }

    return(
        <div>
            {collapsed &&
                <div className="sidebar-single-button-container">
                    <button
                        title={t('map:visualisation-parameters') + ' - ' + t("menu:cartodiagram")}
                        className="sidebar-button-collapsed"
                            onClick={() => {
                                openSlidebar()
                                setActiveMapTool(MAP_OPENED_TOOL_VISUALIZATION_DETAILS)
                            }} 
                    >
                        <FontAwesomeIcon icon={faMapMarked} color={"white"}/>
                    </button>
                </div>
            }
            {!collapsed &&
                <div>
                    <div className="sidebar-opened-buttons-container">
                        <div className="sidebar-single-button-container">
                            <button
                                title={t('map:visualisation-parameters') + ' - ' + t("menu:cartodiagram")}
                                className="sidebar-button-collapsed"
                                onClick={() => {activeMapTool === MAP_OPENED_TOOL_VISUALIZATION_DETAILS ? setActiveMapTool(null) : setActiveMapTool(MAP_OPENED_TOOL_VISUALIZATION_DETAILS)}}
                            >
                                <FontAwesomeIcon icon={faMapMarked} color={"white"}/>
                            </button>
                        </div>
                        <div
                            className="sidebar-text playContentOpacityAnimation changeContentOpacityAnimation"
                            onClick={() => {activeMapTool === MAP_OPENED_TOOL_VISUALIZATION_DETAILS ? setActiveMapTool(null) : setActiveMapTool(MAP_OPENED_TOOL_VISUALIZATION_DETAILS)}}
                        >  
                            {t('map:visualisation-parameters') + ' - ' + t("menu:cartodiagram")}
                        </div>
                        <div className="sidebar-items-arrow changeOpacityAnimation playOpacityAnimation">
                            <FontAwesomeIcon icon={activeMapTool === MAP_OPENED_TOOL_VISUALIZATION_DETAILS ? faAngleDown : faAngleRight} color={"black"}/>
                        </div>
                    </div>
                    <Collapse in={activeMapTool === MAP_OPENED_TOOL_VISUALIZATION_DETAILS}>
                        <div className="ml-4 playContentOpacityAnimation changeContentOpacityAnimation">
                            <Row>
                                <Form.Group as={Col} sm={6} md={6}>
                                    <Form.Label htmlFor={'arrangementCartogramField'}>
                                        {t("map:administrative-division")} 
                                    </Form.Label>
                                    <Form.Control as="select" size="sm"  
                                        id={'arrangementCartogramField'} 
                                        name={'arrangementCartogramField'}
                                        disabled={props.loading} 
                                        onChange={(e) => {
                                                if (e.target.value){
                                                    setCartogramAdministrativeDivision(e.target.value)
                                                }
                                            }
                                        }
                                        defaultValue={cartogramAdministrativeDivision}
                                    >
                                        <option value="panstwo" id="panstwo">{t('taxondb-attributes:panstwo')}</option>
                                        <option value="wojewodztwo" id="wojewodztwo">{t('taxondb-attributes:wojewodztwo')}</option>
                                        <option value="powiat" id="powiat" >{t('taxondb-attributes:powiat')}</option>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group as={Col} sm={6} md={6}>
                                    <Form.Label htmlFor={'groupingField'}>
                                        {t('map:cartodiagram-grouping-field')}
                                    </Form.Label>
                                    <Form.Control as="select" size="sm" 
                                        id={'groupingField'} 
                                        name={'groupingField'}
                                        disabled={props.loading} 
                                        onChange={(e) => {
                                                if (e.target.value){
                                                    setCartodiagramGroupingField(e.target.value)
                                                }
                                            }
                                        }
                                        defaultValue={cartodiagramGroupingField}
                                    >
                                        <optgroup label={t('taxondb-groups:group-1')}>
                                            <option value="kolekcjanumerokazu" id="kolekcjanumerokazu"> {t('taxondb-attributes:kolekcjanumerokazu')} </option>
                                            <option value={GENUS_ATTRIBUTE_NAME} id={GENUS_ATTRIBUTE_NAME}> {t(`taxondb-attributes:${GENUS_ATTRIBUTE_NAME}`)} </option>
                                            <option value={SPECIES_ATTRIBUTE_NAME} id={SPECIES_ATTRIBUTE_NAME}> {t(`taxondb-attributes:${SPECIES_ATTRIBUTE_NAME}`)} </option>
                                            <option value="jednostkanadrzedna" id="jednostkanadrzedna"> {t('taxondb-attributes:jednostkanadrzedna')} </option>
                                            <option value="autorzbioru" id="autorzbioru"> {t('taxondb-attributes:autorzbioru')} </option>
                                            <option value="autorgatunku" id="autorgatunku"> {t('taxondb-attributes:autorgatunku')} </option>
                                            <option value={ROK_ZBIORU_FIELD_NAME} id={ROK_ZBIORU_FIELD_NAME}> {t(`taxondb-attributes:${ROK_ZBIORU_FIELD_NAME}`)} </option>
                                            <option value="rangajednostkinadrzednej" id="rangajednostkinadrzednej"> {t('taxondb-attributes:rangajednostkinadrzednej')} </option>
                                            <option value="zrodlo" id="zrodlo"> {t('taxondb-attributes:zrodlo')} </option>
                                        </optgroup>
                                        <optgroup label={t('taxondb-groups:group-2')}>
                                            <option value="panstwo" id="panstwo"> {t('taxondb-attributes:panstwo')}  </option>
                                            <option value="georeferencjakomentarze" id="georeferencjakomentarze"> {t('taxondb-attributes:georeferencjakomentarze')}  </option>
                                            <option value="gmina" id="gmina"> {t('taxondb-attributes:gmina')} </option>
                                            <option value="koditypobszarunatura2000" id="koditypobszarunatura2000"> {t('taxondb-attributes:koditypobszarunatura2000')} </option>
                                            <option value="kontynent" id="kontynent"> {t('taxondb-attributes:kontynent')} </option>
                                            <option value="nazwaobszarunatura2000" id="nazwaobszarunatura2000"> {t('taxondb-attributes:nazwaobszarunatura2000')} </option>
                                            <option value="numerzbioruokreslonegoautora" id="numerzbioruokreslonegoautora"> {t('taxondb-attributes:numerzbioruokreslonegoautora')} </option>
                                            <option value="obszarchronionegokrajobrazu" id="obszarchronionegokrajobrazu"> {t('taxondb-attributes:obszarchronionegokrajobrazu')} </option>
                                            <option value="obszarchroniony" id="obszarchroniony"> {t('taxondb-attributes:obszarchroniony')} </option>
                                            <option value="parkkrajobrazowy" id="parkkrajobrazowy"> {t('taxondb-attributes:parkkrajobrazowy')} </option>
                                            <option value="parknarodowy" id="parknarodowy"> {t('taxondb-attributes:parknarodowy')} </option>
                                            <option value="polozeniewpodzialebiogeograficznymeuropy" id="polozeniewpodzialebiogeograficznymeuropy"> {t('taxondb-attributes:polozeniewpodzialebiogeograficznymeuropy')} </option>
                                            <option value="polozeniewpodzialefizjograficznym" id="polozeniewpodzialefizjograficznym"> {t('taxondb-attributes:polozeniewpodzialefizjograficznym')} </option>
                                            <option value="polozeniewpodzialegeograficznympotencjalnejroslinnoscinaturalne" id="polozeniewpodzialegeograficznympotencjalnejroslinnoscinaturalne"> {t('taxondb-attributes:polozeniewpodzialegeograficznympotencjalnejroslinnoscinaturalne')} </option>
                                            <option value="powiat" id="powiat"> {t('taxondb-attributes:powiat')} </option>
                                            <option value="rezerwatprzyrody" id="rezerwatprzyrody"> {t('taxondb-attributes:rezerwatprzyrody')} </option>
                                            <option value="rodzajityprezerwatu" id="rodzajityprezerwatu"> {t('taxondb-attributes:rodzajityprezerwatu')}  </option>
                                            <option value="siedlisko" id="siedlisko"> {t('taxondb-attributes:siedlisko')}  </option>
                                            <option value="uzytekekologiczny" id="uzytekekologiczny"> {t('taxondb-attributes:uzytekekologiczny')} </option>
                                            <option value="wojewodztwo" id="wojewodztwo"> {t('taxondb-attributes:wojewodztwo')} </option>
                                            <option value="zespolprzyrodniczokrajobrazowy" id="zespolprzyrodniczokrajobrazowy"> {t('taxondb-attributes:zespolprzyrodniczokrajobrazowy')} </option>
                                        </optgroup>
                                        <optgroup label={t('taxondb-groups:group-3')}>
                                            <option value="instytucja" id="instytucja"> {t('taxondb-attributes:instytucja')}  </option>
                                            <option value="opisuwagi" id="opisuwagi"> {t('taxondb-attributes:opisuwagi')} </option>
                                            <option value="pochodzenieokazu" id="pochodzenieokazu"> {t('taxondb-attributes:pochodzenieokazu')} </option>
                                        </optgroup>
                                        <optgroup label={t('taxondb-groups:group-4')}>
                                            <option value="bibliografia" id="bibliografia">{t('taxondb-attributes:bibliografia')}</option>
                                        </optgroup>
                                    </Form.Control>
                                </Form.Group>
                            </Row>

                            <Row>
                                <Form.Group as={Col} sm={6} md={6}>
                                    <Form.Label htmlFor={'numberOfChartColors'}>
                                        {t('map:cartodiagram-colors-number')}
                                    </Form.Label>
                                    <Form.Control as="select" size="sm" 
                                        id={'numberOfChartColors'} 
                                        name={'numberOfChartColors'}
                                        defaultValue={numberOfChartColors}
                                        disabled={props.loading} 
                                        onChange={(e) => {
                                                if (e.target.value){
                                                    let valueToInt = parseInt(e.target.value)
                                                    setCartodiagramNumberOfChartColors(valueToInt)
                                                }
                                            }
                                        }
                                    >
                                        <option value="1" id="chart-colors-1">1</option>
                                        <option value="2" id="chart-colors-2">2</option>
                                        <option value="3" id="chart-colors-3">3</option>
                                        <option value="4" id="chart-colors-4">4</option>
                                        <option value="5" id="chart-colors-5">5</option>
                                        <option value="6" id="chart-colors-6">6</option>
                                        <option value="7" id="chart-colors-7">7</option>
                                        <option value="8" id="chart-colors-8">8</option>
                                        <option value="9" id="chart-colors-9">9</option>
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group as={Col} sm={6} md={6}>
                                    <Form.Label htmlFor={'arrangementBorderColor'}>
                                        {t("map:borders-color")} <span className="color-picker-tip" style={{backgroundColor: cartogramBordersColor}} />
                                    </Form.Label>
                                    <CirclePicker
                                        id={'arrangementBorderColor'} 
                                        name={'arrangementBorderColor'}
                                        onChange={color => setCartogramBordersColor(color.hex)}
                                        colors={MAP_COLORPICKER_LIST}
                                        width={"100%"}
                                        circleSize={24}
                                    />
                                </Form.Group>
                            </Row>

                            <Row>
                                <Form.Group as={Col} sm={6} md={6}>
                                    <Form.Label htmlFor={'cartogramStartColor'}>
                                        {t("map:arrangement-cartogram-no-taxons-color")} <span className="color-picker-tip" style={{backgroundColor: cartogramFirstAreaColor}} />
                                    </Form.Label>
                                    <CirclePicker
                                        id={'cartogramStartColor'} 
                                        name={'cartogramStartColor'}
                                        onChange={color => setCartogramFirstAreaColor(color.hex)}
                                        colors={MAP_COLORPICKER_LIST}
                                        width={"100%"}
                                        circleSize={24}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} sm={6} md={6}>
                                    <Form.Label htmlFor={'cartogramEndColor'}>
                                        {t("map:arrangement-cartogram-with-taxons-color")} <span className="color-picker-tip" style={{backgroundColor: cartogramSecondAreaColor}} />
                                    </Form.Label>
                                    <CirclePicker
                                        id={'cartogramEndColor'} 
                                        name={'cartogramEndColor'}
                                        onChange={color => setCartogramSecondAreaColor(color.hex)}
                                        colors={MAP_COLORPICKER_LIST}
                                        width={"100%"}
                                        circleSize={24}
                                    />
                                </Form.Group>
                            </Row>

                            <Row>
                                <Form.Group as={Col} sm={6} md={6}>
                                    <FormLabel htmlFor={"borderOpacity"}>
                                        {t("map:borders-width")}
                                    </FormLabel>
                                    <Form.Control 
                                        id={"borderOpacity"} 
                                        type="number" 
                                        name="borderOpacity"
                                        disabled={props.loading}
                                        defaultValue={cartogramBordersWidth} 
                                        min={0} max={1} step={0.1}
                                        onChange={e => setCartogramBordersWidth(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} sm={6} md={6}>
                                    <FormLabel htmlFor={"cartogramOpacity"}>
                                        {t("map:cartogram-opacity")}
                                    </FormLabel>
                                    <Form.Control 
                                        id={"cartogramOpacity"} 
                                        type="number" 
                                        name="cartogramOpacity"
                                        disabled={props.loading}
                                        defaultValue={cartogramOpacity} 
                                        min={0} max={1} step={0.1}
                                        onChange={e => setCartogramOpacity(e.target.value)}
                                    />
                                </Form.Group>
                            </Row>

                            <Row>
                                <Col md={12} sm={12} className="d-flex align-items-end" >
                                    <div className="flex-grow-1" />
                                    {props.loading &&
                                        <Spinner 
                                            animation="border"
                                            size="sm"
                                            aria-hidden="true"
                                            className="mb-3 mr-2"
                                        />
                                    }
                                    <Button
                                        className="mb-3 ml-2 mr-3"
                                        disabled={props.loading}
                                        onClick={() => cartodiagramSearchItems(ref, props, t)}
                                    >
                                        {t("show-on-map")}
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Collapse>
                </div>
            }
        </div>
    )
})

const mapStateToProps = state => ({
    filter1: getFields(TAXONDB_SEARCH_GENUS_REQUEST_ID, state),
    filter2: JSON.parse(getField(TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID, TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID, state, "null")),
    filter3: JSON.parse(getField(TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, state, "null")),
    fields1: getFields(TAXONDB_SEARCH_GENUS_REQUEST_ID, state),
    fields2: getFields(TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID, state),
    fields3: getFields(TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, state),
    isDynamic: getField(TAXON_SEARCH_IS_DYNAMIC, TAXON_SEARCH_IS_DYNAMIC, state, "0"),
    loading: isPending(TAXONDB_SEARCH_WITH_AGGREGATION_REQUEST_ID, state),
    cartogramAdministrativeDivision: state.map.cartogramAdministrativeDivision,
    cartogramBordersColor: state.map.cartogramBordersColor,
    cartogramBordersWidth: state.map.cartogramBordersWidth,
    cartogramOpacity: state.map.cartogramOpacity,
    cartogramFirstAreaColor: state.map.cartogramFirstAreaColor,
    cartogramSecondAreaColor: state.map.cartogramSecondAreaColor,
    cartodiagramGroupingField: state.map.cartodiagramGroupingField,
    numberOfChartColors: state.map.numberOfChartColors,
    activeMapTool: state.map.openedMapTool,
})

const mapDispatchToProps = dispatch => ({
    setFields: (id, val) => dispatch(setFields(id, val)),
    clearResponse: (id) => dispatch(actions.requestRemove(id)),
    runCartogramSearch: (id, path) => dispatch(runCartogramSearch(id, path)),
    searchWithAggregation: (field, filter, items_per_page, request_id) => {
        return dispatch(actions.postDataAnc(request_id,
            TAXONDB_SEARCH_WITH_AGGREGATION_REQUEST_URL, {
            filter: filter,
            with_total: true,
            aggregation_field: field,
            pagination: {perPage: items_per_page},
        },
        CARTODIAGRAM_REQUEST_TIMEOUT
        ))
    },
    setCartogramAdministrativeDivision: data => dispatch(setCartogramAdministrativeDivision(data)),
    setCartogramBordersColor: data => dispatch(setCartogramBordersColor(data)),
    setCartogramBordersWidth: data => dispatch(setCartogramBordersWidth(data)),
    setCartogramOpacity: data => dispatch(setCartogramOpacity(data)),
    setCartogramFirstAreaColor: data => dispatch(setCartogramFirstAreaColor(data)),
    setCartogramSecondAreaColor: data => dispatch(setCartogramSecondAreaColor(data)),
    setCartodiagramGroupingField: data => dispatch(setCartodiagramGroupingField(data)),
    setCartodiagramNumberOfChartColors: data => dispatch(setCartodiagramNumberOfChartColors(data)),
    setActiveMapTool: (value) => dispatch(setOpenedMapTool(value)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(Cartodiagram)
