import React from "react"
import { Row, Spinner, Col } from "react-bootstrap"
import { connect } from 'react-redux'
import { isPending, getOutput } from "../../reducers"
import RecordDetails from './RecordDetails'
import { useTranslation } from 'react-i18next'

const SingleRecordDetailsContainer = props => {
    const { t } = useTranslation()

    return (
        <div className="d-flex flex-column pt-2">
            {props.loading &&
                <div className="align-self-center m-3">
                    <Spinner animation="border" role="status" />
                </div>
            }
            <div>
                <Row>
                    <Col md={(!props.loading && props.data && props.data[ "images" ] && props.data.images.length > 0) ? 6 : 12}>
                        {!props.loading && props.data &&
                            <RecordDetails requestId={props.requestId} recordId={props.recordId} ></RecordDetails>
                        }
                        {!props.loading && !props.data &&
                            <div className="d-flex justify-content-center">
                                <p>{t('no-records-found')}</p>
                            </div>
                        }
                    </Col>
                </Row>
            </div>
        </div>
    )
}


const mapStateToProps = (state, ownProps) => ({
    loading: isPending(ownProps.requestId, state),
    data: getOutput(ownProps.requestId, state),
})

const mapDispatchToProps = dispatch => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SingleRecordDetailsContainer)
