import axios from "axios"
import { API_URL_POSTFIX, ANC_URL_POSTFIX } from '../settings'
import {
    API_BASE_URL,
    GENERAL_DEFAULT_REQUEST_TIMEOUT,
    GENERAL_REPORT_REQUEST_TIMEOUT,
    DLIBRA_TIF_IMAGE_REQUEST_TIMEOUT,
} from '../settings'

const baseURL = API_BASE_URL

export const get_axios = (prefix = '') => {
    const instance = axios.create({ baseURL: baseURL + prefix, withCredentials: true })
    instance.defaults.timeout = GENERAL_DEFAULT_REQUEST_TIMEOUT

    const lang = localStorage.getItem('lang')
    instance.defaults.headers.common[ 'Language' ] = lang ? lang : 'en'

    const token = localStorage.getItem('access')
    if (token !== null) {
        instance.interceptors.request.use(config => {
            config.headers.Authorization = 'Bearer ' + token
            return config
        }, err => Promise.reject(err)
        )
    }

    const refresh = localStorage.getItem('refresh')
    if (refresh !== null) {
        instance.interceptors.response.use(response => response, error => {
            const originalRequest = error.config
            if (error && error.response && error.response.status === 401 && !originalRequest._retry) {
                // w need to refresh token
                originalRequest._retry = true
                return axios.post(baseURL + '/refresh_token/', { refresh }, { withCredentials: true })
                    .then(response => {
                        localStorage.setItem('access', response.data.access)
                        localStorage.setItem('refresh', response.data.refresh)
                        originalRequest.headers.Authorization = 'Bearer ' + response.data.access
                        return axios(originalRequest)
                    })
                    .catch(err => {
                        if (err && err.response && err.response.status === 401) {
                            // refresh token also expired
                            localStorage.removeItem('access')
                            localStorage.removeItem('refresh')
                            localStorage.removeItem('user')
                            window.location.reload(true) // to remove all application context
                        }
                        return Promise.reject(err)
                    });
            }
            return Promise.reject(error);
        });
    }
    return instance
}

const postWithRetry = (axios, url, data, resolve, reject) => {
    axios.post(url, data)
        .then(res => resolve(res))
        .catch(error => {
            if (error.code === 'ECONNABORTED' || (error.response && error.response.status === 408)) {
                if (!localStorage.getItem('show-retry-modal')) {
                    document.activeElement.blur()
                    localStorage.setItem('show-retry-modal', true)
                    const handler = setInterval(() => {
                        if (!localStorage.getItem('show-retry-modal')) {
                            clearInterval(handler)
                            if (localStorage.getItem('retry')) {
                                postWithRetry(axios, url, data, resolve, reject)
                            } else {
                                reject(error)
                            }
                        }
                    }, 500)
                }
            } else {
                reject(error)
            }
        })
}

const getWithRetry = (axios, url, resolve, reject) => {
    axios.get(url)
        .then(res => resolve(res))
        .catch(error => {
            if (error.code === 'ECONNABORTED' || (error.response && error.response.status === 408)) {
                if (!localStorage.getItem('show-retry-modal')) {
                    document.activeElement.blur()
                    localStorage.setItem('show-retry-modal', true)
                    const handler = setInterval(() => {
                        if (!localStorage.getItem('show-retry-modal')) {
                            clearInterval(handler)
                            if (localStorage.getItem('retry')) {
                                getWithRetry(axios, url, resolve, reject)
                            } else {
                                reject(error)
                            }
                        }
                    }, 500)
                }
            } else {
                reject(error)
            }
        })
}

export const get = url => {
    const prom = new Promise((resolve, reject) => {
        getWithRetry(get_axios(), url, resolve, reject)
    })
    return prom
}

export const post = (url, data, timeout, source) => {
    const prom = new Promise((resolve, reject) => {
        const axios = get_axios()
        if (timeout) {
            axios.defaults.timeout = timeout
        }
        if (source) {
            axios.defaults.cancelToken = source.token
        }
        postWithRetry(axios, url, data, resolve, reject)
    })
    return prom
}

export const api_get = url => {
    const prom = new Promise((resolve, reject) => {
        getWithRetry(get_axios(API_URL_POSTFIX), url, resolve, reject)
    })
    return prom
}

export const anc_get = url => {
    const prom = new Promise((resolve, reject) => {
        getWithRetry(get_axios(ANC_URL_POSTFIX), url, resolve, reject)
    })
    return prom
}

export const api_post = (url, data) => {
    const prom = new Promise((resolve, reject) => {
        postWithRetry(get_axios(API_URL_POSTFIX), url, data, resolve, reject)
    })
    return prom
}

export const anc_post = (url, data) => {
    const prom = new Promise((resolve, reject) => {
        postWithRetry(get_axios(ANC_URL_POSTFIX), url, data, resolve, reject)
    })
    return prom
}

export const post_report = (url, data) => {
    const prom = new Promise((resolve, reject) => {
        const axios = get_axios()
        axios.defaults.responseType = 'blob'
        axios.defaults.timeout = GENERAL_REPORT_REQUEST_TIMEOUT
        postWithRetry(axios, url, data, resolve, reject)
    })
    return prom
}

export const get_report = (url) => {
    const prom = new Promise((resolve, reject) => {
        const axios = get_axios()
        axios.defaults.responseType = 'blob'
        axios.defaults.timeout = GENERAL_REPORT_REQUEST_TIMEOUT
        getWithRetry(axios, url, resolve, reject)
    })
    return prom
}

export const custom_post_with_cancel = (postfix, url, data, source) => {
    const prom = new Promise((resolve, reject) => {
        const axios = get_axios(postfix)
        axios.defaults.cancelToken = source.token
        postWithRetry(axios, url, data, resolve, reject)
    })
    return prom
}

export const get_dlibra_image = (url, imgType) => {
    const prom = new Promise((resolve, reject) => {
        const axios = get_axios()
        axios.defaults.responseType = 'blob'
        if (imgType === 'jpg') axios.defaults.timeout = GENERAL_REPORT_REQUEST_TIMEOUT;
        if (imgType === 'tif') axios.defaults.timeout = DLIBRA_TIF_IMAGE_REQUEST_TIMEOUT;
        getWithRetry(axios, url, resolve, reject)
    })
    return prom
}

export const post_upload_file = (url, data) => {
    const prom = new Promise((resolve, reject) => {
        const axios = get_axios()
        axios.contentType = 'multipart/form-data'
        axios.defaults.timeout = undefined
        postWithRetry(axios, url, data, resolve, reject)
    })
    return prom
}

export const get_blob = (url) => {
    const prom = new Promise((resolve, reject) => {
        const axios = get_axios()
        axios.defaults.responseType = 'blob'
        getWithRetry(axios, url, resolve, reject)
    })
    return prom
}

