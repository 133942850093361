import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import RedirectLoginWithReturn from "../access/RedirectLoginWithReturn"
import { isPending } from "../../reducers";
import { Container, Row, Col, Spinner, Form } from "react-bootstrap"
import { getDataAnc, requestRemoveStartsWith } from '../../actions'
import {
    TAXONDB_GET_TAXON_UNITS_REQUEST_ID,
    TAXONDB_GET_TAXON_UNITS_REQUEST_URL,
    STATISTICS_GENUS_SEARCH_REQUEST_PREFIX,
    SYSTEMATICS_PAGE_URL_EN,
    SYSTEMATICS_PAGE_URL_PL,
} from '../../settings'
import SystematicsItemList from "./SystematicsItemList"
import { useTranslation } from 'react-i18next'
import I18nPathTranslator from '../I18nPathTranslator';
import SEO from '../SEO';

const SystematicsContainer = props => {

    const { t } = useTranslation(['common', 'paths'])
    const [ excludeZeros, setExcludeZeros ] = useState(false)

    const { getItems, clearRequests } = props
    useEffect(() => {
        getItems(0)
        return clearRequests
    }, [ getItems, clearRequests ])

    return (
        <>
            <I18nPathTranslator {...props} pathEN={SYSTEMATICS_PAGE_URL_EN} pathPL={SYSTEMATICS_PAGE_URL_PL} />
            <SEO title={t('menu:systematics')} />
            {!props.loading && props.user && !props.user.data &&
                <RedirectLoginWithReturn lang={props.lang} returnUrl={props.lang ? t("paths:systematics", {lng: props.lang}) : t("paths:systematics")} />
            }
            {props.loading &&
                <div className="d-flex flex-column">
                    <div className="align-self-center m-3">
                        <Spinner animation="border" role="status" />
                    </div>
                </div>
            }
            {!props.loading && props.user && props.user.data &&
                <Container className="d-flex flex-column">      
                    <div className="mt-3 mb-4">
                        <h1>
                            {t('systematics-title')}
                        </h1>
                    </div>
                    <Form.Check
                        className="d-inline"
                        type="switch"
                        id="hide-empty-taxons-switch"
                        label={t('exclude_zeros')}
                        checked={excludeZeros}
                        onChange={() => setExcludeZeros(!excludeZeros)}
                    />
                    <Row>
                        <Col>
                            <SystematicsItemList requestId={TAXONDB_GET_TAXON_UNITS_REQUEST_ID + '0'} id='0' excludeZeros={excludeZeros}></SystematicsItemList>
                        </Col>
                    </Row>
                </Container>
            }
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.user,
    loading: isPending('userInfo', state) || isPending(TAXONDB_GET_TAXON_UNITS_REQUEST_ID, state),
})

const mapDispatchToProps = dispatch => ({
    getItems: id => dispatch(getDataAnc(
        TAXONDB_GET_TAXON_UNITS_REQUEST_ID + id,
        TAXONDB_GET_TAXON_UNITS_REQUEST_URL + id + '/'
    )),
    clearRequests: () => dispatch(requestRemoveStartsWith(STATISTICS_GENUS_SEARCH_REQUEST_PREFIX))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SystematicsContainer)
