export const configureMapDrawings = (L, t, userDrawings, MyCustomMarker) => {
    
    var options = {
        position: 'topright',
        draw: {
            polyline: {
                shapeOptions: {
                    color: '#f357a1',
                    weight: 10
                }
            },
            polygon: {
                allowIntersection: false, // Restricts shapes to simple polygons
                drawError: {
                    color: '#f357a1', // Color the shape will turn when intersects
                    message: '<strong>Oh snap!<strong> you can\'t draw that!' // Message that will show when intersect
                }
            },
            circle: true,
            circlemarker: false,
            rectangle: {
                shapeOptions: {
                    clickable: false
                }
            },
            marker: {
                icon: MyCustomMarker
            }
        },
        edit: {
            featureGroup: userDrawings, //REQUIRED!!
            remove: true
        }
    };

    L.drawLocal.draw.toolbar.buttons.polygon = t('map:polygon-draw');
    L.drawLocal.draw.toolbar.buttons.polyline = t('map:polyline-draw');
    L.drawLocal.draw.toolbar.buttons.rectangle = t('map:rectangle-draw');
    L.drawLocal.draw.toolbar.buttons.circle = t('map:circle-draw');
    L.drawLocal.draw.toolbar.buttons.marker = t('map:marker-draw');
    L.drawLocal.draw.toolbar.actions.title = t('map:cancel-draw-title');
    L.drawLocal.draw.toolbar.actions.text = t('map:cancel-draw-text');
    L.drawLocal.draw.toolbar.finish.title = t('map:finish-draw-title');
    L.drawLocal.draw.toolbar.finish.text = t('map:finish-draw-text');
    L.drawLocal.draw.toolbar.undo.title = t('map:undo-last-point-title');
    L.drawLocal.draw.toolbar.undo.text = t('map:undo-last-point-text');
    L.drawLocal.draw.handlers.circle.radius = t('map:radius');
    L.drawLocal.draw.handlers.circle.tooltip.start = t('map:circle-start');
    L.drawLocal.draw.handlers.marker.tooltip.start = t('map:marker-start');
    L.drawLocal.draw.handlers.polygon.tooltip.start = t('map:polygon-start');
    L.drawLocal.draw.handlers.polygon.tooltip.cont = t('map:polygon-cont');
    L.drawLocal.draw.handlers.polygon.tooltip.end = t('map:polygon-end');
    L.drawLocal.draw.handlers.polyline.error = t('map:polyline-error');
    L.drawLocal.draw.handlers.polyline.tooltip.start = t('map:polyline-start');
    L.drawLocal.draw.handlers.polyline.tooltip.cont = t('map:polyline-cont');
    L.drawLocal.draw.handlers.polyline.tooltip.end = t('map:polyline-end');
    L.drawLocal.draw.handlers.rectangle.tooltip.start = t('map:rectangle-draw-start');
    L.drawLocal.draw.handlers.simpleshape.tooltip.end = t('map:simpleshape-end');
    L.drawLocal.edit.toolbar.actions.save.title = t('map:edit-save-title');
    L.drawLocal.edit.toolbar.actions.save.text = t('map:edit-save-text');
    L.drawLocal.edit.toolbar.actions.cancel.title = t('map:edit-cancel-title');
    L.drawLocal.edit.toolbar.actions.cancel.text = t('map:edit-cancel-text');
    L.drawLocal.edit.toolbar.actions.clearAll.title = t('map:edit-clearAll-title');
    L.drawLocal.edit.toolbar.actions.clearAll.text = t('map:edit-clearAll-text');
    L.drawLocal.edit.toolbar.buttons.edit = t('map:btn-edit');
    L.drawLocal.edit.toolbar.buttons.editDisabled = t('map:btn-editDisabled');
    L.drawLocal.edit.toolbar.buttons.remove = t('map:btn-remove');
    L.drawLocal.edit.toolbar.buttons.removeDisabled = t('map:btn-removeDisabled');
    L.drawLocal.edit.handlers.edit.tooltip.text = t('map:handler-edit-text');
    L.drawLocal.edit.handlers.edit.tooltip.subtext = t('map:handler-edit-subtext');
    L.drawLocal.edit.handlers.remove.tooltip.text = t('map:remove-text');

    return options
}