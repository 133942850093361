import React, { useState } from "react"
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { isPending, getOutput } from "../../reducers"
import { postDataAnc } from '../../actions'
import { Form, Col, Button } from "react-bootstrap"
import {
	COLLECTION_TYPES_REQUEST_ID,
	COLLECTION_SUBTYPES_CREATE_REQUEST_ID,
	COLLECTION_SUBTYPES_CREATE_REQUEST_URL
} from '../../settings'
import * as notify from '../../utils/notify'

const CreateSubtype = props => {
    const { t } = useTranslation(['collections'])
    const { typeId } = props
    const [subtype, setSubtype] = useState('')
    const [descriptionPl, setDescriptionPl] = useState('')
    const [descriptionEn, setDescriptionEn] = useState('')

    const onSubtypeChange = e => {
        setSubtype(e.target.value)
    }

    const onDescriptionPlChange = e => {
        setDescriptionPl(e.target.value)
    }

    const onDescriptionEnChange = e => {
        setDescriptionEn(e.target.value)
    }

    const onSubmitForm = () => {
        props.createCollecticonSubtype(typeId, subtype, descriptionPl, descriptionEn, t)
    }

    return (
        <Form className="mt-2 p-1 mb-2" onSubmit={e => { e.preventDefault() }}>
            <Form.Row>
                <Col xs={6}>
                    <Form.Group>
                        <Form.Label htmlFor="createSubtype">{t('collections:subtype')}</Form.Label>
                        <Form.Control id="createSubtype" size="sm" value={subtype} onChange={onSubtypeChange} disabled={props.querying} required="required"></Form.Control>
                    </Form.Group>
                </Col>
                <Col xs={6}></Col>
            </Form.Row>
            <Form.Row>
                <Col>
                    <Form.Group>
                        <Form.Label htmlFor="createSubtypeDescriptionPl">{t('collections:description-pl')}</Form.Label>
                        <Form.Control id="createSubtypeDescriptionPl" value={descriptionPl} onChange={onDescriptionPlChange} disabled={props.querying}></Form.Control>
                    </Form.Group>
                </Col>
            </Form.Row>
            <Form.Row>
                <Col>
                    <Form.Group>
                        <Form.Label htmlFor="createSubtypeDescriptionEn">{t('collections:description-en')}</Form.Label>
                        <Form.Control id="createSubtypeDescriptionEn" value={descriptionEn} onChange={onDescriptionEnChange} disabled={props.querying}></Form.Control>
                    </Form.Group>
                </Col>
            </Form.Row>
            <Form.Row className="d-flex">
                <Col className="text-right">
                    <Button size="sm" disabled={props.querying} onClick={onSubmitForm}>{t('collections:save-collection-subtype')}</Button>
                </Col>
            </Form.Row>
        </Form>
    )
}

const mapStateToProps = state => ({
    collectionTypes: getOutput(COLLECTION_TYPES_REQUEST_ID, state),
    querying: isPending(COLLECTION_TYPES_REQUEST_ID, state) || isPending(COLLECTION_SUBTYPES_CREATE_REQUEST_ID, state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    createCollecticonSubtype: (collectionType, collectionSubtype, descriptionPl, descriptionEn, t) => {
        return dispatch(postDataAnc(COLLECTION_SUBTYPES_CREATE_REQUEST_ID, COLLECTION_SUBTYPES_CREATE_REQUEST_URL, {
            collection_type: collectionType,
            collection_subtype: collectionSubtype,
            description_pl: descriptionPl,
            description_eng: descriptionEn
        })).then(() => {
            ownProps.onDone && ownProps.onDone()
            notify.success(t('collections:created-collection-subtype'))
        }).catch(res => {
            notify.error(t('collections:cannot-create-collection-subtype'), res.result)
        })
    }
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateSubtype)