import React, { Suspense } from "react";
import { Container } from "react-bootstrap"
import { useTranslation } from 'react-i18next'
import { GDPR_PAGE_URL_EN, GDPR_PAGE_URL_PL } from "../settings";
import Loader from '../utils/loader'
import I18nPathTranslator from "./I18nPathTranslator";
import SEO from "./SEO";



const GDPR = props => {
    const { t } = useTranslation('gdpr')
    
    return (
        <Suspense fallback={<Loader />}>
            <I18nPathTranslator {...props} pathEN={GDPR_PAGE_URL_EN} pathPL={GDPR_PAGE_URL_PL} />
            <SEO title={t('common:gdpr')} />
            <div>
                <Container>
                    <div>
                        <p><strong>{t('line-1')}</strong></p>
                        <p>{t('line-2')}</p>
                        
                    </div>
                </Container>
            </div>
        </Suspense>
    )
}

export default GDPR
