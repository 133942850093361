import React from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { setField, runSearchFlow } from '../../actions/forms'
import { requestRemove, requestRemoveStartsWith } from '../../actions'
import { isPending, getField } from "../../reducers"
import {
    TAXON_RESULTS_ACTIVE_TAB_FIELD_NAME,
    TAXONDB_DYNAMIC_SEARCH_REQUEST_ID,
    TAXONDB_DYNAMIC_SEARCH_SPECIES_REQUEST_ID,
    TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID,
    DICTIONARY_COLLECTION_TYPES,
    COMMON_DICTIONARIES,
    AGGREGATION_REPORT_REQUEST_ID_PREFIX,
    TAXONDB_SUGGEST_REQUEST_PREFIX,
} from '../../settings'
import {
    MULTIMEDIA_FIELD_NAME,
    BOTANIKAZOOLOGIA_FIELD_NAME,
    ROK_ZBIORU_FIELD_NAME,
    LOCATION_ATTRIBUTE_NAME,
    COORDINATES_PRECISION,
} from './attributesStructure'
import InputWithAsyncTypeahead from "../InputWithAsyncTypeahead"
import InputWithTypeahead from "../InputWithTypeahead"
import { Form, Button } from "react-bootstrap"
import InputSimpleText from "../InputSimpleText"
import InputSimpleSelect from "../InputSimpleSelect"
import { DATAZBIORU_FIELD_NAME } from './attributesStructure'
import { rokZbioruOnChange, rokZbioruShouldRenderSuggestions, deleteElementStructure, dataZbioruOnChange } from '../../utils/search'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

const DynamicSearchFormElement = props => {
    const { t } = useTranslation([ 'common', 'taxondb', 'taxondb-attributes' ])

    const removeDynamicField = () => {
        const temp = deleteElementStructure(props.path, props.fields)
        props.setFields(temp)
        props.clearResponse()
    }

    const handleOnKeyUp = e => {
        if (e.keyCode === 13) { // ENTER
            e.preventDefault()
            if (!props.bioGisForm){
                props.runSearchFlow()
            }
            else if (props.bioGisForm){
                props.handleMapSearch()
            }
        }
    }

    return (
        <Form.Group>
            <Form.Label htmlFor={props.path.join('-')}>{t('taxondb-attributes:' + props.elementId)}</Form.Label>
            <div className="d-flex">
                <div className="flex-grow-1 flex-fill no-radius-right">
                    {props.elementId === MULTIMEDIA_FIELD_NAME &&
                        <InputSimpleSelect
                            id={props.path.join('-')}
                            name={props.elementId}
                            disabled={props.loading}
                            onChange={e => props.clearResponse()}
                            onKeyUp={handleOnKeyUp}
                            allowedValues={[
                                {
                                    value: true,
                                },
                                {
                                    value: false,
                                },
                            ]}
                            formId={props.searchRequestId ? props.searchRequestId : TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID}
                            path={props.path}
                        >
                        </InputSimpleSelect>
                    }
                    {props.elementId === COORDINATES_PRECISION &&
                        <InputSimpleSelect
                            id={props.path.join('-')}
                            name={props.elementId}
                            disabled={props.loading}
                            onChange={e => props.clearResponse()}
                            onKeyUp={handleOnKeyUp}
                            allowedValues={[
                                {
                                    value: 0,
                                },
                                {
                                    value: 1,
                                },
                                {
                                    value: 2,
                                },
                            ]}
                            formId={props.searchRequestId ? props.searchRequestId : TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID}
                            path={props.path}
                        >
                        </InputSimpleSelect>
                    }
                    {props.elementId === LOCATION_ATTRIBUTE_NAME &&
                        <InputSimpleText
                            id={props.path.join('-')}
                            name={props.elementId}
                            disabled={props.loading}
                            onChange={e => props.clearResponse()}
                            onKeyUp={handleOnKeyUp}
                            formId={props.searchRequestId ? props.searchRequestId : TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID}
                            path={props.path}
                        ></InputSimpleText>
                    }
                    {props.elementId === DATAZBIORU_FIELD_NAME &&
                        <InputSimpleText
                            id={props.path.join('-')}
                            name={props.elementId}
                            disabled={props.loading}
                            onChange={e => props.clearResponse()}
                            conditionalChange={dataZbioruOnChange}
                            onKeyUp={handleOnKeyUp}
                            formId={props.searchRequestId ? props.searchRequestId : TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID}
                            path={props.path}
                        ></InputSimpleText>
                    }
                    {props.elementId === ROK_ZBIORU_FIELD_NAME &&
                        <InputWithAsyncTypeahead
                            url={TAXONDB_SUGGEST_REQUEST_PREFIX + props.elementId + "/"}
                            id={props.path.join('-')}
                            disabled={props.loading}
                            formId={props.searchRequestId ? props.searchRequestId : TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID}
                            shouldRenderSuggestions={rokZbioruShouldRenderSuggestions}
                            conditionalChange={rokZbioruOnChange}
                            onChange={e => props.clearResponse()}
                            onKeyUp={handleOnKeyUp}
                            path={props.path}
                        ></InputWithAsyncTypeahead>
                    }
                    {props.elementId === 'panstwo' &&
                        <InputWithTypeahead
                            url={TAXONDB_SUGGEST_REQUEST_PREFIX + props.elementId + "/"}
                            id={props.path.join('-')}
                            disabled={props.loading}
                            formId={props.searchRequestId ? props.searchRequestId : TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID}
                            onChange={e => props.clearResponse()}
                            onKeyUp={handleOnKeyUp}
                            path={props.path}
                        ></InputWithTypeahead>
                    }
                    {props.elementId === 'instytucja' &&
                        <InputSimpleSelect
                            id={props.path.join('-')}
                            name={props.elementId}
                            disabled={props.loading}
                            onChange={e => props.clearResponse()}
                            onKeyUp={handleOnKeyUp}
                            allowedValues={[
                                {
                                    value: 'AMU',
                                },
                            ]}
                            formId={props.searchRequestId ? props.searchRequestId : TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID}
                            path={props.path}
                        >
                        </InputSimpleSelect>
                    }
                    {props.elementId === 'rangajednostkinadrzednej' &&
                        <InputSimpleSelect
                            id={props.path.join('-')}
                            name={props.elementId}
                            disabled={props.loading}
                            onChange={e => props.clearResponse()}
                            onKeyUp={handleOnKeyUp}
                            allowedValues={[
                                {
                                    value: 'regnum',
                                },
                                {
                                    value: 'subregnum',
                                },
                                {
                                    value: 'superphylum',
                                },
                                {
                                    value: 'phylum',
                                },
                                {
                                    value: 'subphylum',
                                },
                                {
                                    value: 'superclassis',
                                },
                                {
                                    value: 'classis',
                                },
                                {
                                    value: 'subclassis',
                                },
                                {
                                    value: 'superordo',
                                },
                                {
                                    value: 'ordo',
                                },
                                {
                                    value: 'subordo',
                                },
                                {
                                    value: 'superfamilia',
                                },
                                {
                                    value: 'familia',
                                },
                                {
                                    value: 'subfamilia',
                                },
                                {
                                    value: 'tribus',
                                },
                                {
                                    value: 'genusgroup',
                                },
                                {
                                    value: 'unranked',
                                },
                            ]}
                            formId={props.searchRequestId ? props.searchRequestId : TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID}
                            path={props.path}
                        >
                        </InputSimpleSelect>
                    }
                    {props.elementId === 'zrodlo' &&
                        <InputSimpleSelect
                            id={props.path.join('-')}
                            name={props.elementId}
                            disabled={props.loading}
                            onChange={e => props.clearResponse()}
                            onKeyUp={handleOnKeyUp}
                            allowedValues={[
                                {
                                    value: 'PreservedSpecimen',
                                },
                                {
                                    value: 'LivingSpecimen',
                                },
                                {
                                    value: 'FossileSpecimen',
                                },
                                {
                                    value: 'OtherSpecimen',
                                },
                                {
                                    value: 'HumanObservation',
                                },
                                {
                                    value: 'MachineObservation',
                                },
                                {
                                    value: 'DrawingOrPhotograph',
                                },
                                {
                                    value: 'MultimediaObject',
                                },
                            ]}
                            formId={props.searchRequestId ? props.searchRequestId : TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID}
                            path={props.path}
                        >
                        </InputSimpleSelect>
                    }
                    {props.elementId === BOTANIKAZOOLOGIA_FIELD_NAME &&
                        <InputSimpleSelect
                            id={props.path.join('-')}
                            name={props.elementId}
                            disabled={props.loading}
                            onChange={e => props.clearResponse()}
                            onKeyUp={handleOnKeyUp}
                            allowedValues={props.collectionTypes.map(obj => ({ value: obj.value }))}
                            formId={props.searchRequestId ? props.searchRequestId : TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID}
                            path={props.path}
                        >
                        </InputSimpleSelect>
                    }
                    {!([
                        MULTIMEDIA_FIELD_NAME,
                        DATAZBIORU_FIELD_NAME,
                        LOCATION_ATTRIBUTE_NAME,
                        ROK_ZBIORU_FIELD_NAME,
                        'instytucja',
                        'rangajednostkinadrzednej',
                        'zrodlo',
                        BOTANIKAZOOLOGIA_FIELD_NAME,
                        COORDINATES_PRECISION,
                        'panstwo',
                    ].includes(props.elementId)) &&
                        <InputWithAsyncTypeahead
                            url={TAXONDB_SUGGEST_REQUEST_PREFIX + props.elementId + "/"}
                            id={props.path.join('-')}
                            disabled={props.loading}
                            formId={props.searchRequestId ? props.searchRequestId : TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID}
                            onChange={e => props.clearResponse()}
                            onKeyUp={handleOnKeyUp}
                            path={props.path}
                        ></InputWithAsyncTypeahead>
                    }
                </div>
                <Button variant="light" onClick={removeDynamicField} disabled={props.loading} title={t('forms:remove')} className="no-radius-left">
                    <FontAwesomeIcon icon={faTrashAlt} size="lg" />
                </Button>
            </div>
        </Form.Group>
    )
}

const mapStateToProps = (state, ownProps) => ({
    fields: JSON.parse(getField(ownProps.searchRequestId ? ownProps.searchRequestId : TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, ownProps.searchRequestId ? ownProps.searchRequestId : TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, state, "null")),
    loading: isPending(ownProps.searchRequestId ? ownProps.searchRequestId : TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, state),
    collectionTypes: getField(COMMON_DICTIONARIES, DICTIONARY_COLLECTION_TYPES, state, []),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    setFields: fields => dispatch(setField(ownProps.searchRequestId ? ownProps.searchRequestId : TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, ownProps.searchRequestId ? ownProps.searchRequestId : TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, JSON.stringify(fields))),
    runSearchFlow: () => dispatch(ownProps.onSearch ? ownProps.onSearch() : runSearchFlow(TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, TAXONDB_DYNAMIC_SEARCH_SPECIES_REQUEST_ID, TAXONDB_DYNAMIC_SEARCH_REQUEST_ID, TAXON_RESULTS_ACTIVE_TAB_FIELD_NAME, ownProps.path)),
    clearResponse: () => {
        if (ownProps.onClear) {
            dispatch(ownProps.onClear())
        } else {
            dispatch(requestRemove(TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID))
            dispatch(requestRemove(TAXONDB_DYNAMIC_SEARCH_SPECIES_REQUEST_ID))
            dispatch(requestRemove(TAXONDB_DYNAMIC_SEARCH_REQUEST_ID))
            dispatch(requestRemoveStartsWith(AGGREGATION_REPORT_REQUEST_ID_PREFIX + TAXONDB_DYNAMIC_SEARCH_REQUEST_ID))
        }
    },
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DynamicSearchFormElement)
