import React, { useRef, useEffect, useCallback, useState } from "react";
import { connect } from 'react-redux'
import { Row, DropdownButton } from "react-bootstrap"
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css'
import 'leaflet-defaulticon-compatibility'
import 'leaflet/dist/leaflet.css'
import 'font-awesome/css/font-awesome.css'
import 'leaflet-draw/dist/leaflet.draw.css'
import '../../../../utils/map/leaflet.loader'
import 'leaflet-draw'
import 'leaflet-graticule'
import 'leaflet.geodesic'
import "../../../../styles/maps.scss"
import { getField, getFields, isPending } from "../../../../reducers";
import { setField } from '../../../../actions/forms'
import {
    MAP_MAX_ZOOM_LEVEL,
    ARRANGEMENT_TOOLS_ARRANGEMENT_PANEL,
    ARRANGEMENT_TOOLS_SEARCH_PANEL,
    ARRANGEMENT_TOOLS_SEARCH_ON_INIT,
    MAP_LAYER_OBSERVATIONS_CONST,
    MAP_LAYER_CARTOGRAM_CONST,
    MAP_LAYER_TAXONS_WITH_LEGEND_CONST,
    MAP_TOOLS_ACTIVE_VISUALIZATION,
    MAP_TOOLS_VISUALIZATION_1,
    MAP_TOOLS_VISUALIZATION_2,
    MAP_TOOLS_VISUALIZATION_3,
    MAP_TOOLS_VISUALIZATION_4,
    MAP_TOOLS_VISUALIZATION_5,
    MAP_TOOLS_VISUALIZATION_6,
    MAP_TOOLS_VISUALIZATION_7,
    TAXON_SEARCH_IS_DYNAMIC,
    MAP_LAYER_ANIMATION_CONST,
    TAXONDB_SEARCH_GENUS_REQUEST_ID,
    TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID,
    TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID,
    MAP_TOOLS_VISUALIZATION_RELATIONS,
} from '../../../../settings'
import useTranslationMemo from '../../../../hooks/useTranslationMemo';
import L from "leaflet"
import SEO from "../../../SEO";
import ControlPanel from "../ControlPanel";
import { configureMapDrawings } from "../../../../utils/mapDrawings";
import setupMapControlls from '../../setupMapControlls'
import drawingHandle from '../..//drawingHandle'
import { clearWmsArray, clearMapLabels, clearMapfileArray, setTooManyTaxonsWarning, setAnimationYears, setHistoricalMarkerSize, setHistoricalMarkerColor, setHistoricalMarkerIcon, 
    setTaxonsByYear, setMapTitle, setMapId, setWmsAddedToMap, setVisibleToolScale, setVisibleToolZoomslider, 
    setVisibleToolMagnifierGlass, setVisibleToolImageExport, setVisibleToolBasemaps, setVisibleToolDrawingTools
} from '../../../../actions/map'
import updateDrawingToolbar from '../../updateDrawingToolbar'
import "../../../../styles/map-sidebar.scss"
import MapSearchForms from "../MapSearchForms"
import { faBars, faSyncAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import ArrangementMap from "../arrangement/ArrangementMap";
import ArrangementCartogram from "../arrangement/ArrangementCartogram";
import ClusteringMap from "../clustering/ClusteringMap";
import ClusteringCartogramMap from "../clustering/ClusteringCartogramMap";
import Cartodiagram from "../grouping/Cartodiagram";
import GroupingMap from "../grouping/GroupingMap";
import addWatermark from '../../../../utils/map/createWatermark'
import { NavLink } from "react-router-dom";
import HistoricalMap from "../historical/HistoricalMap";
import RangeSlider from '../historical/RangeSlider'
import TooManyTaxonsWarning from "../TooManyTaxonsWarning";
import RedirectLoginWithReturn from "../../../access/RedirectLoginWithReturn";
import Timeline from "../historical/Timeline";
import I18nPathTranslator from "../../../I18nPathTranslator";
import MapExporter from "./MapExporter";
import useMarkerShapeColorSelector from "../../../../hooks/useMarkerShapeColorSelector";

const MapTools = props => {
    const { t, i18n } = useTranslationMemo([ 'taxondb', 'map', 'common' ])
    const { setSearchOnInit, markerIcon, markerColor, polygonStyle, lineStyle, clearWmsArray, clearMapfileArray, clearMapLabels, activeVisualization, setActiveVisualization, 
        historicalMarkerColor, historicalMarkerIcon, historicalMarkerSize, setHistoricalMarkerIcon, setHistoricalMarkerColor, 
        setHistoricalMarkerSize, mapTitle, setMapTitle, setMapId, setWmsAddedToMap, 
        setVisibleToolScale, setVisibleToolZoomslider, setVisibleToolMagnifierGlass, setVisibleToolImageExport, setVisibleToolBasemaps, setVisibleToolDrawingTools  } = {...props}
    const [collapsed, setCollapsed] = useState(true)
    const pointsLayer = useRef(null)
    const mapRef = useRef(null)
    const cartogramRef = useRef(null)
    const cartogramLegendDiv = useRef(null)
    const userDrawingsRef = useRef(null)
    const drawRef = useRef(null)
    const unclasteredAncTaxonsRef = useRef(null)
    const ancTaxonsWithLegend = useRef(null)
    const shapefilesRef = useRef(null)
    const observationsRef = useRef(null)
    const userMapDescriptionRef = useRef(null)
    const toolkitPreferencesRef = useRef(null)
    const taxonsRef = useRef(null)
    const historicalMapRef = useRef(null)
    const animationLayerRef = useRef(null)
    const animationDisplayedYearDiv = useRef(null)
    const pixiOverlayRef = useRef(null)
    const shapeLegend = useRef(null)
    const colorLegend = useRef(null)
    const pixiContainer = useRef(null)
    const allMapTools = useRef(null)

    const getCurrentFilter = () => {
        let filter 
        if (props.isDynamic === "0"){
            filter = props.filter1
        }
        if (props.isDynamic === "2"){
            filter = props.filter2
        }
        if (props.isDynamic === "1"){
            filter = props.filter3
        }
        return filter
    }

    const closeSlidebar = () => {
        setCollapsed(true)
    }

    const openSlidebar = () => {
        setCollapsed(false)
    }

    const fixMapInitialization = useCallback(() => {
        setTimeout(() => {
            if(mapRef.current){
                mapRef.current.invalidateSize()
                setSearchOnInit(false)
            }
        }, 500)
    }, [setSearchOnInit])

    const refreshMap = () => {
        if(mapRef.current){
            mapRef.current.invalidateSize()
        }
    }

    const {prepareBasicDivIcon} = useMarkerShapeColorSelector()
    useEffect(() => {
        const addToolsToMap = (toolkitPreferences, mapRef) => {
            toolkitPreferences.attributionString.addTo(mapRef.current)
            toolkitPreferences.baseMaps.addTo(mapRef.current);
            toolkitPreferences.scale.addTo(mapRef.current);
            mapRef.current.addControl(toolkitPreferences.zoomSlider)
            mapRef.current.addControl(toolkitPreferences.zoomGlass);
        }

        const clearStoredMapSettings = () => {
            clearWmsArray()
            clearMapfileArray()
            setWmsAddedToMap({})
            /*
            props.setHistoricalAnalisisMode(false)
            props.showAnimationButton(false)
            props.setTaxonsByYear(new Map())
            props.setAnimationYears(null)
            props.clearMapLabels()
            */
        }

        const prepareLayersRefs = () => {
            unclasteredAncTaxonsRef.current = new L.FeatureGroup();
            userDrawingsRef.current = new L.FeatureGroup();
            let icon = prepareBasicDivIcon('square', 'blue', '2x')
            var drawingOptions = configureMapDrawings(L, t, userDrawingsRef.current, icon)
            drawRef.current = new L.Control.Draw(drawingOptions);
            ancTaxonsWithLegend.current = new L.FeatureGroup();
            shapefilesRef.current = new L.FeatureGroup(); 
            cartogramRef.current = new L.FeatureGroup();
            observationsRef.current = new L.FeatureGroup();
            taxonsRef.current = new L.FeatureGroup();
            userMapDescriptionRef.current = new L.FeatureGroup();
            historicalMapRef.current = new L.FeatureGroup();
            animationLayerRef.current =  new L.FeatureGroup();
            ancTaxonsWithLegend.current.name = MAP_LAYER_TAXONS_WITH_LEGEND_CONST
            cartogramRef.current.name = MAP_LAYER_CARTOGRAM_CONST
            observationsRef.current.name = MAP_LAYER_OBSERVATIONS_CONST
            historicalMapRef.current.addTo(mapRef.current);
            animationLayerRef.current.addTo(mapRef.current);
            animationDisplayedYearDiv.current = L.control({ position: "bottomleft" })
            mapRef.current.addLayer(taxonsRef.current);                            
            mapRef.current.addLayer(ancTaxonsWithLegend.current);                            
            mapRef.current.addLayer(shapefilesRef.current);
            mapRef.current.addLayer(cartogramRef.current)
            mapRef.current.addLayer(observationsRef.current)
            mapRef.current.addLayer(userMapDescriptionRef.current)
            if (drawRef.current){
                mapRef.current.addControl(drawRef.current);
            }
        }

        const fitSidebarWidth = () => {
            if (window.matchMedia("(max-width: 991px)").matches) { 
                setCollapsed(false)
            }
            if (window.matchMedia("(min-width: 992px)").matches) { 
                setCollapsed(true)
            }
        }

        const prepareTheMap = () => {
                clearMapLabels()
                mapRef.current = L.map('gisMap', {
                    center: [0.0, 0.0],
                    crs: L.CRS.EPSG3857,
                    zoom: 1.5,
                    maxZoom: MAP_MAX_ZOOM_LEVEL,
                    minZoom: 1,
                    maxBounds: [[-90, -200],[90, 200]],
                    zoomControl: false,
                    preferCanvas: false, /* set to true crashes Cartodiagram! */
                    attributionControl: false
                })
                if (mapRef.current){
                    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {noWrap: true}).addTo(mapRef.current)
                    addWatermark(mapRef.current)
                    pointsLayer.current = new L.FeatureGroup();
                    pointsLayer.current.addTo(mapRef.current);
                    cartogramRef.current = new L.FeatureGroup();
                    cartogramLegendDiv.current = new L.FeatureGroup();
                    cartogramRef.current.addTo(mapRef.current);
                    cartogramLegendDiv.current.addTo(mapRef.current);
                    clearStoredMapSettings()
                    fixMapInitialization()
                    fitSidebarWidth()
                    prepareLayersRefs()
                    let toolkitPreferences = setupMapControlls(t, L)
                    toolkitPreferencesRef.current = toolkitPreferences
                    addToolsToMap(toolkitPreferences, mapRef)
                    setVisibleToolScale(true)
                    setVisibleToolZoomslider(true)
                    setVisibleToolMagnifierGlass(true)
                    setVisibleToolImageExport(true)
                    setVisibleToolBasemaps(true)
                    setVisibleToolDrawingTools(true)
                    mapRef.current.on(L.Draw.Event.CREATED, (e) => drawingHandle(e, polygonStyle, lineStyle, markerColor, markerIcon, userDrawingsRef.current));
                    /*ugly Leaflet.zoomsilder fix*/
                    mapRef.current._controlContainer.children[0].children[0].children[0].title = t('map:zoom-in')
                    mapRef.current._controlContainer.children[0].children[0].children[2].title = t('map:zoom-out')
                    mapRef.current.addLayer(userDrawingsRef.current)
            }
        }

        if (!mapRef.current) {
            prepareTheMap()
        }

        if (mapRef.current && drawRef.current){
            mapRef.current.off(L.Draw.Event.CREATED);
            mapRef.current.on(L.Draw.Event.CREATED, (e) => drawingHandle(e, polygonStyle, lineStyle, markerColor, markerIcon, userDrawingsRef.current));
            updateDrawingToolbar(drawRef.current, polygonStyle, lineStyle)
        }
    }, [clearMapLabels, t, fixMapInitialization, markerIcon, markerColor, polygonStyle, lineStyle, clearWmsArray, clearMapfileArray, setWmsAddedToMap, setVisibleToolScale, 
        setVisibleToolZoomslider, setVisibleToolMagnifierGlass, setVisibleToolImageExport, setVisibleToolBasemaps, setVisibleToolDrawingTools, prepareBasicDivIcon])

    const cleanUpMapLayers = useCallback(() => {            
        /* Remove Pixi.js Overlay from map (ArrangementMap, GroupingMap)*/
        if (mapRef.current && pixiOverlayRef.current){
            if (mapRef.current.hasLayer(pixiOverlayRef.current)){
                mapRef.current.removeLayer(pixiOverlayRef.current)
            }
        }
        
        /* Remove Cartogram from map */
        if (cartogramRef.current){
            cartogramRef.current.eachLayer(function(layer){
                cartogramRef.current.removeLayer(layer);
            });
        }

        /* Clear ClusteringMap Tool */
        if (pointsLayer.current){
            pointsLayer.current.clearLayers()
        }

        /* Remove Grouping Tool legends */
        if (shapeLegend.current){
            shapeLegend.current.remove()
        }
        if (colorLegend.current){
            colorLegend.current.remove()
        }

        /* Clear Cartogram legend */
        if (cartogramLegendDiv.current){
            cartogramLegendDiv.current.remove()
            cartogramLegendDiv.current = L.control({ position: "bottomleft" })
        }
        
        /* Clear HistoricalMap tool */
        if (mapRef.current){
            try{
                mapRef.current.eachLayer(function(layer){
                    if (layer.name === (MAP_LAYER_ANIMATION_CONST)){
                        mapRef.current.removeLayer(layer);
                    }
                });
            }
            catch (e){
                console.log("Map undefined")
            }
        }
        if (animationLayerRef.current){
            try{
                animationLayerRef.current.eachLayer(function (layer) {
                    animationLayerRef.current.removeLayer(layer);
                })
            }
            catch (e){
                console.log("Animation layer undefined")
            }
        }
        if (animationDisplayedYearDiv.current){
            animationDisplayedYearDiv.current.remove()
        }

        if (pixiContainer.current) {
            pixiContainer.current.destroy()
        }
    }, [])
    useEffect(() => {
        return () => {
            cleanUpMapLayers()
            if (mapRef.current) {
                mapRef.current.eachLayer(function(layer){
                    mapRef.current.removeLayer(layer);
                });                
            }
        }
    }, [cleanUpMapLayers])

    useEffect(() => {
        return (() => {
            cleanUpMapLayers()
        })
    }, [activeVisualization, cleanUpMapLayers])

    /* Cleanup loaded map on component leave */
    useEffect(() => {   
        return () => {
            setMapTitle(undefined)
            setMapId(undefined) 
        }
    }, [setMapTitle, setMapId]);
    
    useEffect(()=> {
        if(!historicalMarkerColor) setHistoricalMarkerColor("#000000")
        if(!historicalMarkerIcon)  setHistoricalMarkerIcon("fa fa-map-marker") 
        if(!historicalMarkerSize)  setHistoricalMarkerSize("fa-lg") 
    }, [historicalMarkerColor, historicalMarkerIcon, historicalMarkerSize, setHistoricalMarkerIcon, setHistoricalMarkerColor, setHistoricalMarkerSize])
    
    const lang = i18n.languages[0]
    useEffect(()=>{
        //ugly Leaflet.zoomsilder fix
        if(mapRef.current){
            mapRef.current._controlContainer.children[0].children[0].children[0].title = t('map:zoom-in')
            mapRef.current._controlContainer.children[0].children[0].children[2].title = t('map:zoom-out')
        }
    }, [t, lang])

    /* Handle sidebar accessibility events */
    useEffect(() => {
        const closeSidebarOnEspaceDown = (e) => {
            if (e.key === "Escape"){
                if (!collapsed){
                    setCollapsed(true)
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }
            }
        }

        const closeSidebarOnClickOutsideIt = e => {
            if (!collapsed && allMapTools.current && !allMapTools.current.contains(e.target) && (e.offsetX < e.target.clientWidth) ) {
                setCollapsed(true)
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        }

        window.addEventListener('keydown', closeSidebarOnEspaceDown);
        window.addEventListener("mousedown", closeSidebarOnClickOutsideIt);

        return () => {
            window.removeEventListener('keydown', closeSidebarOnEspaceDown);
            window.removeEventListener("mousedown", closeSidebarOnClickOutsideIt);
        };
    }, [collapsed]);

    return (
    <div>
        {((activeVisualization !== props.defaultVisualization) || !props?.user?.data)&&
            <RedirectLoginWithReturn returnUrl={t(`paths:${MAP_TOOLS_VISUALIZATION_RELATIONS[activeVisualization].name}`)} />
        }
        <>
            <SEO title={t(`menu:${MAP_TOOLS_VISUALIZATION_RELATIONS[activeVisualization].name}`)} />
            <I18nPathTranslator {...props} 
                pathEN={MAP_TOOLS_VISUALIZATION_RELATIONS[activeVisualization].url.en} 
                pathPL={MAP_TOOLS_VISUALIZATION_RELATIONS[activeVisualization].url.pl} />
            <div className="mt-3 mb-4">
                <h1 className="text-center">
                    {mapTitle ? mapTitle : "BioGIS"} 
                </h1>
                <div>
                    <div className="d-flex justify-content-center align-content-center">
                        <p className="map-type-title">
                            {t(`menu:${MAP_TOOLS_VISUALIZATION_RELATIONS[activeVisualization].name}`)}
                        </p>
                            <DropdownButton
                                variant="outline-dark"
                                style={{display:"inline-block"}}
                                className="ml-2 visualization-button"
                                title={t("map:change-visualization-button")}
                                onSelect={(e) => {
                                    setActiveVisualization(e)
                                }}
                            >
                                <DropdownItem eventKey={MAP_TOOLS_VISUALIZATION_1} as={NavLink} to={t('paths:position-map')}>
                                    {t('menu:position-map')}
                                </DropdownItem>
                                <DropdownItem eventKey={MAP_TOOLS_VISUALIZATION_2} as={NavLink} to={t('paths:area-map')} >
                                    {t('menu:area-map')}
                                </DropdownItem> 
                                <DropdownItem eventKey={MAP_TOOLS_VISUALIZATION_4}  as={NavLink} to={t('paths:cartogram')}>
                                    {t('menu:cartogram')}
                                </DropdownItem>
                                <DropdownItem eventKey={MAP_TOOLS_VISUALIZATION_5} as={NavLink} to={t('paths:cartodiagram')} >
                                    {t('menu:cartodiagram')}
                                </DropdownItem>
                                <DropdownItem eventKey={MAP_TOOLS_VISUALIZATION_3} as={NavLink} to={t('paths:clustering')} >
                                    {t('menu:clustering')}
                                </DropdownItem>
                                <DropdownItem eventKey={MAP_TOOLS_VISUALIZATION_6} as={NavLink} to={t('paths:taxons-grouping')} >
                                    {t('menu:taxons-grouping')}
                                </DropdownItem>
                                <DropdownItem eventKey={MAP_TOOLS_VISUALIZATION_7} as={NavLink} to={t('paths:historical-analysis-map')} >
                                    {t('menu:historical-analysis-map')}
                                </DropdownItem>
                            </DropdownButton>
                    </div>
                    <div className="refresh-map-button">
                        <button
                            className="sidebar-button-collapsed"
                            title={t("map:refresh-map-button")}
                            onClick={() => refreshMap()}
                        >
                            <FontAwesomeIcon icon={faSyncAlt} color={"white"}/>
                        </button>
                    </div>
                </div>
            </div>
            <Row className="d-flex justify-content-center">
                <div ref={allMapTools} className={!collapsed ? "resizeAnimation playResizeAnimation sidebar-container" : "sidebar-container"}>
                    <div className="playContentOpacityAnimation changeContentOpacityAnimation">
                        <div className={!collapsed ? "sidebar-container-open" : "sidebar-container-collapsed"}>
                            <div className="sidebar-single-button-container"> 
                                <button
                                    title={t('menu:toggle-menu-'+(collapsed ? "open" : "close"))}
                                    className="sidebar-button-collapsed"
                                    onClick={ collapsed ? () => openSlidebar() : () => closeSlidebar() }>
                                    {collapsed && 
                                        <FontAwesomeIcon icon={faBars} color={"white"}/>
                                    }
                                    {!collapsed &&
                                        <FontAwesomeIcon icon={faTimes} color={"white"}/>
                                    }
                                </button>
                            </div>
                            <MapSearchForms ref={{
                                "pointsLayer": pointsLayer,
                                "observationsRef": observationsRef,
                                "mapRef": mapRef, 
                                "cartogramRef": cartogramRef, 
                                "cartogramLegendDiv": cartogramLegendDiv, 
                                "pixiContainer": pixiContainer, 
                                "pixiOverlayRef": pixiOverlayRef, 
                                "animationLayerRef": animationLayerRef, 
                                "shapeLegend": shapeLegend, 
                                "userMapDescriptionRef": userMapDescriptionRef,
                                "userDrawingsRef": userDrawingsRef,
                                "colorLegend": colorLegend }} collapsed={collapsed} openSlidebar={openSlidebar} closeSlidebar={closeSlidebar} />
                            {activeVisualization === MAP_TOOLS_VISUALIZATION_1 &&
                            <ArrangementMap 
                                collapsed={collapsed} openSlidebar={openSlidebar} closeSlidebar={closeSlidebar}
                                ref={{"pointsLayer" : pointsLayer, "mapRef": mapRef, "pixiOverlayRef": pixiOverlayRef, "pixiContainer": pixiContainer, "observationsRef": observationsRef,}} 
                            />   
                            }
                            {activeVisualization === MAP_TOOLS_VISUALIZATION_4 &&
                            <ClusteringCartogramMap 
                                collapsed={collapsed} openSlidebar={openSlidebar} closeSlidebar={closeSlidebar}
                                ref={{"cartogramRef" : cartogramRef, "cartogramLegendDiv": cartogramLegendDiv, "mapRef": mapRef}} 
                            />
                            }
                            {activeVisualization === MAP_TOOLS_VISUALIZATION_3 &&
                            <ClusteringMap 
                                collapsed={collapsed} openSlidebar={openSlidebar} closeSlidebar={closeSlidebar}
                                ref={{"pointsLayer" : pointsLayer, "mapRef": mapRef}} 
                            />     
                            }
                            {activeVisualization === MAP_TOOLS_VISUALIZATION_2 &&
                            <ArrangementCartogram 
                                collapsed={collapsed} openSlidebar={openSlidebar} closeSlidebar={closeSlidebar}
                                ref={{"pointsLayer" : pointsLayer, "mapRef": mapRef, "cartogramRef": cartogramRef, "cartogramLegendDiv": cartogramLegendDiv}} 
                            />                            
                            }
                            {activeVisualization === MAP_TOOLS_VISUALIZATION_5 &&
                            <Cartodiagram 
                                collapsed={collapsed} openSlidebar={openSlidebar} closeSlidebar={closeSlidebar}
                                ref={{"pointsLayer" : pointsLayer, "mapRef": mapRef, "cartogramRef": cartogramRef, "cartogramLegendDiv": cartogramLegendDiv}} 
                            />
                            }
                            {activeVisualization === MAP_TOOLS_VISUALIZATION_6 &&
                            <GroupingMap 
                                collapsed={collapsed} openSlidebar={openSlidebar} closeSlidebar={closeSlidebar}
                                ref={{"taxonsRef": taxonsRef, "mapRef": mapRef, "pixiOverlayRef": pixiOverlayRef, "pixiContainer": pixiContainer, "shapeLegend": shapeLegend, "colorLegend": colorLegend}} 
                            />
                            }
                            {activeVisualization === MAP_TOOLS_VISUALIZATION_7 &&
                            <HistoricalMap
                                collapsed={collapsed} openSlidebar={openSlidebar} closeSlidebar={closeSlidebar}
                                ref={{"mapRef": mapRef, "historicalMapRef": historicalMapRef, "animationLayerRef": animationLayerRef, "animationDisplayedYearDiv": animationDisplayedYearDiv}}
                            />
                            }
                            <ControlPanel
                                collapsed={collapsed}
                                openSlidebar={openSlidebar} 
                                closeSlidebar={closeSlidebar}
                                ref={{
                                    "mapRef": mapRef,
                                    "pointsLayer" : pointsLayer,
                                    "userDrawingsRef": userDrawingsRef,
                                    "unclasteredAncTaxonsRef": unclasteredAncTaxonsRef,
                                    "drawRef": drawRef,
                                    "ancTaxonsWithLegend": ancTaxonsWithLegend,
                                    "shapefilesRef": shapefilesRef,
                                    "cartogramRef": cartogramRef,
                                    "observationsRef": observationsRef,
                                    "userMapDescriptionRef": userMapDescriptionRef,
                                    "toolkitPreferencesRef": toolkitPreferencesRef,
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    {activeVisualization === MAP_TOOLS_VISUALIZATION_7 && props.taxonsByYear && props.taxonsByYear.size > 0 && !props.taxonsWarning &&
                        <div>
                            <Timeline
                                ref={{
                                    "mapRef" : mapRef, 
                                    "animationLayerRef": animationLayerRef, 
                                    "animationDisplayedYearDiv":animationDisplayedYearDiv
                                }}
                                filter={getCurrentFilter()}
                            />
                            <div className="range-slider-container">
                                <RangeSlider
                                    ref={{
                                        "animationLayerRef": animationLayerRef,
                                        "animationDisplayedYearDiv": animationDisplayedYearDiv
                                    }}
                                />
                            </div>
                        </div>
                    }
                    {activeVisualization === MAP_TOOLS_VISUALIZATION_7 && props.taxonsWarning &&
                        <TooManyTaxonsWarning />
                    }
                        <MapExporter map={mapRef.current}></MapExporter>
                        <div
                            id='gisMap'
                        >
                        </div>
                </div>
            </Row>
        </>
    </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    showArrangementPanel: getField(ARRANGEMENT_TOOLS_ARRANGEMENT_PANEL, ARRANGEMENT_TOOLS_ARRANGEMENT_PANEL, state, true),
    showSearchPanel: getField(ARRANGEMENT_TOOLS_SEARCH_PANEL, ARRANGEMENT_TOOLS_SEARCH_PANEL, state, true),
    markerIcon: state.map.markerIcon,
    markerColor: state.map.markerColor,
    lineStyle: state.map.lineStyle,
    polygonStyle: state.map.polygonStyle,
    activeVisualization: getField(MAP_TOOLS_ACTIVE_VISUALIZATION, MAP_TOOLS_ACTIVE_VISUALIZATION, state, ownProps.defaultVisualization),
    isDynamic: getField(TAXON_SEARCH_IS_DYNAMIC, TAXON_SEARCH_IS_DYNAMIC, state, "0"),
    taxonsByYear: state.map.taxonsByYear,
    taxonsWarning: state.map.taxonsWarning,
    loadingUser: isPending('userInfo', state),
    user: state.user,
    animationTimeouts: state.map.animationTimeouts,
    historicalMarkerColor: state.map.historicalMarkerColor,
    historicalMarkerIcon: state.map.historicalMarkerIcon,
    historicalMarkerSize: state.map.historicalMarkerSize,
    filter1: getFields(TAXONDB_SEARCH_GENUS_REQUEST_ID, state),
    filter2: JSON.parse(getField(TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID, TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID, state, "null")),
    filter3: JSON.parse(getField(TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, state, "null")),
    mapTitle: state.map.mapTitle,
})

const mapDispatchToProps = dispatch => ({
    setIsDynamic: value => dispatch(setField(TAXON_SEARCH_IS_DYNAMIC, TAXON_SEARCH_IS_DYNAMIC, value)),
    clearMapfileArray: () => dispatch(clearMapfileArray()),
    clearMapLabels: () => dispatch(clearMapLabels()),
    setShowArrangementPanel: value => dispatch(setField(ARRANGEMENT_TOOLS_ARRANGEMENT_PANEL, ARRANGEMENT_TOOLS_ARRANGEMENT_PANEL, value)),
    setShowSearchPanel: value => dispatch(setField(ARRANGEMENT_TOOLS_SEARCH_PANEL, ARRANGEMENT_TOOLS_SEARCH_PANEL, value)),
    setSearchOnInit: value => dispatch(setField(ARRANGEMENT_TOOLS_SEARCH_ON_INIT, ARRANGEMENT_TOOLS_SEARCH_ON_INIT, value)),
    clearWmsArray: () => dispatch(clearWmsArray()),
    setActiveVisualization: value => dispatch(setField(MAP_TOOLS_ACTIVE_VISUALIZATION, MAP_TOOLS_ACTIVE_VISUALIZATION, value)),
    setAnimationYears: years => dispatch(setAnimationYears(years)),
    setTooManyTaxonsWarning: data => dispatch(setTooManyTaxonsWarning(data)),
    setHistoricalMarkerIcon: data => dispatch(setHistoricalMarkerIcon(data)), 
    setHistoricalMarkerColor: data => dispatch(setHistoricalMarkerColor(data)),
    setHistoricalMarkerSize: data => dispatch(setHistoricalMarkerSize(data)),
    setTaxonsByYear: data => dispatch(setTaxonsByYear(data)),
    setMapTitle: (value) => dispatch(setMapTitle(value)),
    setMapId: (value) => dispatch(setMapId(value)),
    setWmsAddedToMap: (data) => dispatch(setWmsAddedToMap(data)),
    setVisibleToolScale: data => dispatch(setVisibleToolScale(data)),
    setVisibleToolZoomslider: data => dispatch(setVisibleToolZoomslider(data)),
    setVisibleToolMagnifierGlass: data => dispatch(setVisibleToolMagnifierGlass(data)),
    setVisibleToolImageExport: data => dispatch(setVisibleToolImageExport(data)),
    setVisibleToolBasemaps: data => dispatch(setVisibleToolBasemaps(data)),
    setVisibleToolDrawingTools: data => dispatch(setVisibleToolDrawingTools(data)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MapTools)
