import { useCallback } from 'react';
import L from 'leaflet';
import atpol_duze from '../utils/atpol/atpol_duze'
import atpol_male from '../utils/atpol/atpol_male'
import { UTM, lat2UTM } from '../utils/UTM'
import  WorldUtm from '../shapefiles/World_UTM_Grid.zip'
import shp from 'shpjs/dist/shp'
import { MAP_LAYERS } from '../stateIds'
import useMapRedux from './useMapRedux'

const useCoordinateLayers  = (() => {
    const { value: mapLayers, setValue: setMapLayers } = useMapRedux(MAP_LAYERS, {});

    function bindAtpolPopup(feature, layer) {
        if (feature.properties && feature.properties.Name) {
            layer.bindPopup(`<b>${feature.properties.Name}</b>`)
        }
    }

    function bindAtpolLabel(feature, layer) {
        if (feature.properties && feature.properties.Name) {
            layer.bindPopup(
                `<b>${feature.properties.Name}</b>`,
                    {className: "atpol-popup", closeOnClick: false,  autoClose: false, closeButton: false}, 
                )
        }
    }

    function bindAtpolDetailedLabel(feature, layer) {
        if (feature.properties && feature.properties.Name) {
            layer.bindPopup(
                `<b>${feature.properties.Name}</b>`,
                    {className: "atpol-popup-detailed", closeOnClick: false,  autoClose: false, closeButton: false}, 
                )
        }
    }

    const handleAtpolGrid = useCallback((obj, mapRef) => {
      const atpolGrid = { ...mapLayers.atpolGrid }
      atpolGrid[obj.name] = obj.checked ? true : false
      if (obj.name === "atpol_1"){
          if(obj.checked){
              let atpol = new L.FeatureGroup();
              atpol.name = obj.name
              L.geoJSON(atpol_duze, {
                  onEachFeature: bindAtpolPopup
              }).addTo(atpol);
              mapRef.current.addLayer(atpol)
          }
          else{
              mapRef.current.eachLayer(function (layer) {
                  if (layer.name === obj.name) {
                      mapRef.current.removeLayer(layer);
                  }
              });
              mapRef.current.off('click')
          }
      }

      if (obj.name === "atpol_2"){
        if(obj.checked){
              let atpol = new L.FeatureGroup();
              atpol.name = obj.name
              L.geoJSON(atpol_male, {
                  onEachFeature: bindAtpolPopup
              }).addTo(atpol);
              mapRef.current.addLayer(atpol)      
          }
          else{
              mapRef.current.eachLayer(function (layer) {
                  if (layer.name === obj.name) {
                      mapRef.current.removeLayer(layer);
                  }
              });
              mapRef.current.off('click')
          }
      }

      if (obj.name === "atpol_3"){
          if(obj.checked){
              let atpol = new L.FeatureGroup();
              atpol.name = obj.name
              L.geoJSON(atpol_duze, {
                  onEachFeature: bindAtpolLabel
              }).addTo(atpol);
              mapRef.current.addLayer(atpol)
              atpol.eachLayer(function (layer) {
                  for (const property in layer._layers) {  
                      layer._layers[property].fire("click")
                  }
              })
          }
          else{
              mapRef.current.eachLayer(function (layer) {
                  if (layer.name === obj.name) {
                      mapRef.current.removeLayer(layer);
                  }
              });
              mapRef.current.off('click')
          }
      }

      if (obj.name === "atpol_4"){
          if (obj.checked){
              let atpol = new L.FeatureGroup();
              atpol.name = obj.name
              L.geoJSON(atpol_male, {
                  onEachFeature: bindAtpolDetailedLabel
              }).addTo(atpol);
              mapRef.current.addLayer(atpol)
          }
          else{
              mapRef.current.eachLayer(function (layer) {
                  if (layer.name === obj.name) {
                      mapRef.current.removeLayer(layer);
                  }
              });
              mapRef.current.off('click')
          }
      }
      setMapLayers({ ...mapLayers, atpolGrid })  
    }, [mapLayers, setMapLayers]);

    const handleCoordinatesGrid = useCallback((obj, mapRef) => {
        if (obj.name === "coordinates-layer"){
            if(obj.checked){
                let graticule = new L.FeatureGroup();
                graticule.name = obj.name
                L.latlngGraticule({
                    showLabel: true,
                    color: '#222',
                    zoomInterval: [
                        {start: 1, end: 2, interval: 30},
                        {start: 2, end: 3, interval: 10},
                        {start: 3, end: 5, interval: 5},
                        {start: 5, end: 7, interval: 1},
                        {start: 7, end: 12, interval: 0.5},
                        {start: 13, end: 15, interval: 0.05},
                        {start: 15, end: 18, interval: 0.005},
                        {start: 18, end: 20, interval: 0.001}
                    ]
                }).addTo(graticule);
                mapRef.current.addLayer(graticule)
              setMapLayers({ ...mapLayers, "coordinates-layer": true })  
            }
            else{
                mapRef.current.eachLayer(function (layer) {
                    if (layer.name === obj.name) {
                        mapRef.current.removeLayer(layer);
                    }
                });
              setMapLayers({ ...mapLayers, "coordinates-layer": false })  
            }
        }
    }, [mapLayers, setMapLayers]);

    const handleUtmGrid = useCallback((obj, mapRef) => {
      const utmGrid = { ...mapLayers.utmGrid }
      utmGrid[obj.name] = obj.checked ? true : false
        if (obj.name === "utm-poland"){
            if(obj.checked){
                let utm = new L.FeatureGroup();
                utm.name = obj.name
                let polyLayer = UTM();
                let pathCoordinates = [];
                for (let i = 0; i < polyLayer.length; i++){
                    for (let i2 = 0; i2 < polyLayer[i][0].length - 1; i2++){
                        if (i>5 || i===1 || i===2){
                            pathCoordinates = [];
                            pathCoordinates.push({lat: parseFloat(polyLayer[i][0][i2][1]), lng: parseFloat(polyLayer[i][0][i2][0])}); 
                            pathCoordinates.push({lat: parseFloat(polyLayer[i][0][i2 + 1][1]), lng: parseFloat(polyLayer[i][0][i2 + 1][0])});
                            if (i > 5) {
                                new L.Geodesic(pathCoordinates, {color: polyLayer[i][2], weight: polyLayer[i][1]}).addTo(utm);  
                            }
                            else{ 
                                L.polyline(pathCoordinates, {color: polyLayer[i][2], weight: polyLayer[i][1]}).addTo(utm);
                            }
                        }
                    }
                }
                mapRef.current.on('click', e => {
                    L.popup().setLatLng(e.latlng).setContent(lat2UTM(e.latlng.lat, e.latlng.lng)).openOn(mapRef.current);
                })
                mapRef.current.addLayer(utm)
            }
            else{
                mapRef.current.eachLayer(function (layer) {
                    if (layer.name === obj.name) {
                        mapRef.current.removeLayer(layer);
                    }
                });        
            }
        }

        if (obj.name === "utm-world"){
            if(obj.checked){
                let utm = new L.FeatureGroup();
                utm.name = obj.name
                shp(WorldUtm).then(function(geojson){
                    L.geoJSON(geojson, {
                        onEachFeature: popupUtm
                    }).addTo(utm);
                });
                mapRef.current.addLayer(utm)
            }
            else{
                mapRef.current.eachLayer(function (layer) {
                    if (layer.name === obj.name) {
                        mapRef.current.removeLayer(layer);
                    }
                });
            }
        }
      setMapLayers({ ...mapLayers, utmGrid })  
    }, [mapLayers, setMapLayers]);

    function popupUtm(feature, layer) {
        if (feature.properties && feature.properties.ZONE && feature.properties.ROW_) {
            layer.setStyle({fillColor :'transparent', color: '#000105' }) 
            layer.bindPopup(`<b>${feature.properties.ZONE + feature.properties.ROW_}</b>`, {
                    maxWidth: "300px",
                    maxHeight: "100px",
                    closeButton: true,
                }
            )
            layer.bindTooltip(`<b>${feature.properties.ZONE + feature.properties.ROW_}</b>`)
        }
    }

    return {
      handleAtpolGrid,
      handleCoordinatesGrid,
      handleUtmGrid,
    }
})

export default useCoordinateLayers
