import i18n from "i18next";
import XHR from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next  } from "react-i18next";

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next) 
  .init({
    //lng: 'en',
    fallbackLng: ['en', 'pl'],
    load: 'languageOnly',
    nonExplicitSupportedLngs: true,
    supportedLngs: ['en', 'pl'],
    preload: [ 'en', 'pl' ],
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    },
    // have a common namespace used around the full app
    ns: [ 'common' ],
    defaultNS: 'common',
    saveMissing: true,
    missingKeyHandler: (lng, ns, key, fallbackValue) => {
      console.log('Missing locales: key = [' + key + '] lang = [' + lng + '] namespace = [' + ns + ']');
      return fallbackValue = 'error'
    },

    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
      format: (value, format, lng) => {
        if (format === 'uppercase') return value.toUpperCase();
        return value;
      }
    },
    react: {
      useSuspense: true,
      bindI18n: 'languageChanged loaded', // remove loaded?
    },
    appendNamespaceToMissingKey: 'common'
  });
i18n.on('languageChanged', function (lng) {
  document.documentElement.lang = lng;
});

export default i18n;
