import React from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ValuesDictionary } from "./ValuesDictionary"
import {
    DICTIONARY_COLLECTION_TYPES,
    COMMON_DICTIONARIES,
    GENUS_SPECIES_ITALIC_EXCLUDES,
} from '../../settings'
import { getField } from "../../reducers"
import { Badge } from "react-bootstrap"
import { GENUS_ATTRIBUTE_NAME, SPECIES_ATTRIBUTE_NAME, BOTANIKAZOOLOGIA_FIELD_NAME, RODZAJ_GATUNEK_ATTRIBUTE_NAME } from '../taxonDB/attributesStructure'

const GetPrefixFromDictionary = (name, value, dictionary) =>
    dictionary.find(
        dic =>
        (dic.name.indexOf(name) > -1 && dic.values.find(
            val2 =>
                (val2.value === value && val2['prefix'])
        ))
    ) ? (dictionary.find(
        dic =>
            dic.name.indexOf(name) > -1
    ).values.find(
        val2 =>
            val2.value === value)
    )['prefix'] : ''

export const GetValueFromDictionary = (name, value, i18n, collectionTypes, prefix) => {
    const mainDictionary = [
        ...ValuesDictionary,
        {
            name: [BOTANIKAZOOLOGIA_FIELD_NAME],
            values: [
                ...collectionTypes,
                {
                    value: 'VARIA',
                    en: 'Multimedia',
                    pl: 'Multimedia'
                },
            ]
        }]
    if (typeof(value) != "undefined" && value !== null) {
        return (
            mainDictionary.find(
                dic =>
                (dic.name.indexOf(name) > -1 && dic.values.find(
                    val2 =>
                        (val2.value === value && val2[i18n.languages[0]])
                ))
            ) ? ((prefix ? GetPrefixFromDictionary(name, value, mainDictionary) : '') + (mainDictionary.find(
                dic =>
                    dic.name.indexOf(name) > -1
            ).values.find(
                val2 =>
                    val2.value === value)
            )[i18n.languages[0]])
                : ((prefix ? GetPrefixFromDictionary(name, value, mainDictionary) : '') + value)
        )
    } else {
        return i18n.t('null_value')
    }
}

const ValueFromDictionary = props => {
    const { i18n, t } = useTranslation()

    const isGenusOrSpecies = (name) => {
        return [SPECIES_ATTRIBUTE_NAME, GENUS_ATTRIBUTE_NAME, RODZAJ_GATUNEK_ATTRIBUTE_NAME, 'Genus', 'Species'].includes(name)
    }

    return (
        <>
            {typeof(props.value) != "undefined" && props.value !== null && !isGenusOrSpecies(props.name) &&
                <>
                    {GetValueFromDictionary((props.dictionary ? props.dictionary : props.name), props.value, i18n, props.collectionTypes)}
                </>
            }
            {typeof(props.value) != "undefined" && props.value !== null && isGenusOrSpecies(props.name) && GENUS_SPECIES_ITALIC_EXCLUDES.includes(props.value) &&
                <>
                    {GetValueFromDictionary((props.dictionary ? props.dictionary : props.name), props.value, i18n, props.collectionTypes)}
                </>
            }
            {typeof(props.value) != "undefined" && props.value !== null && isGenusOrSpecies(props.name) && !GENUS_SPECIES_ITALIC_EXCLUDES.includes(props.value) &&
                <i>
                    {GetValueFromDictionary((props.dictionary ? props.dictionary : props.name), props.value, i18n, props.collectionTypes)}
                </i>
            }
            {(typeof(props.value) == "undefined" || props.value === null) &&
                <Badge variant="secondary">
                    {t('null_value')}
                </Badge>
            }
        </>
    )
}

const mapStateToProps = state => ({
    collectionTypes: getField(COMMON_DICTIONARIES, DICTIONARY_COLLECTION_TYPES, state, []),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ValueFromDictionary)
