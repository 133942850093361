import React, { useEffect, useState, useRef, useCallback } from 'react';
import { connect } from 'react-redux'
import { DLIBRA_IIIF2_URL } from '../../settings'
import { Button, Dropdown, DropdownButton, ButtonToolbar, Badge } from "react-bootstrap"
import { useTranslation } from 'react-i18next'
import OpenSeadragon from 'openseadragon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo, faArrowLeft, faArrowRight, faSearchMinus, faSearchPlus, faDesktop, faUpload, faTrash } from '@fortawesome/free-solid-svg-icons'
import * as actions from '../../actions'
import { isPending, getOutput } from "../../reducers";
import {
    DOWNLOADIMAGE_REQUEST_ID,
    DLIBRA_IMAGE_JPG_MAX_WIDTH,
    DLIBRA_CONTENT_URL,
    PROJECTS_UPLOAD_OBSERVATION_IMAGE_ID,
    PROJECTS_UPLOAD_OBSERVATION_IMAGE_URL,
    PROJECTS_DELETE_OBSERVATION_IMAGE_ID,
    PROJECTS_DELETE_OBSERVATION_IMAGE_URL,
    API_URL_POSTFIX
} from '../../settings'
import * as notify from '../../utils/notify'
import Confirm from '../Confirm'
import CustomReactPlayer from './CustomReactPlayer'
import RedirectLoginWithReturn from '../access/RedirectLoginWithReturn'
import { get_axios } from '../../utils/ajax';

const FileUploadButton = props => {
    const { t } = useTranslation([ 'common' ])

    const inputFileRef = useRef(null)

    const onOpenUpload = () => {
        inputFileRef.current.click()
    }

    const onInputFileChanged = (e) => {
        const file = e.target.files[ 0 ]
        if (file) {
            props.postImage(props.data.id, file, t)
        }
    }

    return (
        <>
            <form hidden>
                <input type="file" name="file" ref={inputFileRef} onChange={onInputFileChanged} />
            </form>
            <Button size="sm" className="ml-1" variant="dark" id={"upload" + props.requestId} disabled={props.loading} onClick={onOpenUpload} >
                <FontAwesomeIcon icon={faUpload} title={t('upload-file')} />
            </Button>
        </>
    )
}

const Buttons = props => {
    const { t } = useTranslation([ 'common', 'projects' ])

    const { currentImage } = props

    const onDeleteImage = () => {
        if (currentImage - 1 >= 0) {
            props.deleteImage(props.data.id, props.fileUrls[ currentImage - 1 ].file_number, t)
        }
    }

    const isEmpty = useCallback(() => {
        return props.fileUrls.length === 0;
    }, [ props.fileUrls ]);

    const isError = () => {
        return props.fileUrls[ currentImage - 1 ]?.state !== 'SAVED'
    }

    const [isRedirectLoginWithReturn, setRedirectLoginWithReturn] = useState(false)

    return (
        <div>
            {isRedirectLoginWithReturn &&
                <RedirectLoginWithReturn lang={localStorage.lang} returnUrl={window.location.pathname} />
            }
            <ButtonToolbar>
                <DropdownButton size="sm" className="ml-1" id="dropdown-basic-button" title={t('download_image')} disabled={props.downloading || isEmpty() || isError()}>
                    <Dropdown.Item onClick={() =>
                        props.user ?
                            props.downloadImage(
                                `${DLIBRA_IIIF2_URL}${props.fileUrls[ currentImage - 1 ].edi_id}-${props.fileUrls[ currentImage - 1 ].ver_id}/full//${DLIBRA_IMAGE_JPG_MAX_WIDTH},/0/default.jpg`,
                                "jpg", props.data.kolekcjanumerokazu
                            ) : setRedirectLoginWithReturn(true)
                    }
                    >JPG</Dropdown.Item>
                    <Dropdown.Item onClick={() =>
                        props.user ?
                            props.downloadImage(
                                `${DLIBRA_IIIF2_URL}${props.fileUrls[ currentImage - 1 ].edi_id}-${props.fileUrls[ currentImage - 1 ].ver_id}/full//full/0/default.tif`,
                                "tif", props.data.kolekcjanumerokazu
                            ) : setRedirectLoginWithReturn(true)
                    }
                    >TIF</Dropdown.Item>
                </DropdownButton>
                <Button size="sm" className="ml-2" variant="dark" id={"previous-picture" + props.requestId} disabled={props.loading || isEmpty()}>
                    <FontAwesomeIcon icon={faArrowLeft} title={t('PreviousPage')} />
                </Button>
                <Button size="sm" className="ml-1" variant="dark" id={"next-picture" + props.requestId} disabled={props.loading || isEmpty()}>
                    <FontAwesomeIcon icon={faArrowRight} title={t('NextPage')} />
                </Button>
                <Button size="sm" className="ml-1" variant="dark" id={"reset" + props.requestId} disabled={props.loading || isEmpty() || isError()}>
                    <FontAwesomeIcon icon={faUndo} title={t('Home')} />
                </Button>
                <Button size="sm" className="ml-1" variant="dark" id={"zoom-in" + props.requestId} disabled={props.loading || isEmpty() || isError()}>
                    <FontAwesomeIcon icon={faSearchPlus} title={t('ZoomIn')} />
                </Button>
                <Button size="sm" className="ml-1" variant="dark" id={"zoom-out" + props.requestId} disabled={props.loading || isEmpty() || isError()} >
                    <FontAwesomeIcon icon={faSearchMinus} title={t('ZoomOut')} />
                </Button>
                <Button size="sm" className="ml-1" variant="dark" id={"full-screen" + props.requestId} disabled={props.loading || isEmpty() || isError()} >
                    <FontAwesomeIcon icon={faDesktop} title={t('FullPage')} />
                </Button>
                {!props.viewedBy &&
                    <>
                        <FileUploadButton {...props} disabled={props.loading} />
                        <Confirm
                            onConfirm={onDeleteImage}
                            size="md"
                            buttonLabel={t('DeleteImage')}
                            confirmationText={t('forms:confirm-remove-filterset')}
                            disabled={props.loading || isEmpty()}
                            className="ml-1"
                        >
                            <Button size="sm" className="ml-1" variant="dark" id={"delete-image" + props.requestId} disabled={props.loading || isEmpty()}>
                                <FontAwesomeIcon icon={faTrash} title={t('DeleteImage')} />
                            </Button>
                        </Confirm>
                    </>
                }
                {props.fileUrls.length > 0 &&
                    <Button size="sm" className="ml-1" variant="info" disabled={true} >
                        <Badge>{t(`projects:${props.fileUrls[ currentImage - 1 ]?.state}`)}</Badge>
                    </Button>
                }
            </ButtonToolbar>
            <div className="ml-2">
                {t('common:picture_number')} <b>{props.fileUrls.length > 0 ? currentImage : 0}</b>  {t('common:of')} <b>{props.fileUrls.length}</b>
            </div>
        </div>
    )
}

const SingleDlibraImage = props => {
    const { t, i18n } = useTranslation([ 'common' ])
    const [ currentImage, setCurrentImage ] = useState(1);
    const [ currentSound, setCurrentSound ] = useState(1);
    const { requestId } = props
    const fileUrls = JSON.stringify(props.fileUrls)
    const lastPage = useRef(null)

    useEffect(() => {
        if (JSON.parse(fileUrls).length > 0) {
            // see: https://openseadragon.github.io/docs/OpenSeadragon.html#.Options
            const osd = OpenSeadragon({
                id: 'iiif-' + requestId,
                prefixUrl: "/openseadragon/images/",
                showNavigator: true,
                preserveViewport: true,
                //visibilityRatio: 1,
                sequenceMode: true,
                crossOriginPolicy: false,
                ajaxWithCredentials: false,
                tileSources: JSON.parse(fileUrls).map(val => {
                    if (val.state === 'SAVED') {
                        return {
                            tileSource: DLIBRA_IIIF2_URL + val.edi_id + '-' + val.ver_id + '/info.json',
                            ajaxHeaders: { "Authorization": "Bearer " + localStorage.getItem('access') },
                        }
                    } else {
                        return {
                            type: 'image',
                            url: '/images/noimage.png'
                        }
                    }
                }),
                homeButton: 'reset' + requestId,
                zoomInButton: 'zoom-in' + requestId,
                zoomOutButton: 'zoom-out' + requestId,
                fullPageButton: 'full-screen' + requestId,
                nextButton: "next-picture" + requestId,
                previousButton: "previous-picture" + requestId,
                loadTilesWithAjax: true,
            })
            osd.addHandler('page', function (event) {
                lastPage.current = event.page
                setCurrentImage(event.page + 1);
            })
            osd.addHandler('open-failed', function (event) {
                if (lastPage.current !== null) {
                    const nextTry = lastPage.current
                    lastPage.current = null
                    get_axios(API_URL_POSTFIX).get('/current_user/').then(res => {
                        osd.open(osd.tileSources.map(val => ({ ...val, ajaxHeaders: { "Authorization": "Bearer " + localStorage.getItem('access') }})), nextTry)
                    }).catch(err => {
                        console.log('err', err)
                    })
                } else {
                    notify.error(i18n.t('common:cannot-load-image'))
                    osd.open(osd.tileSources.map(val => ({ ...val, ajaxHeaders: { "Authorization": "Bearer " + localStorage.getItem('access') }}))
                        .map(v => ((v.tileSource !== event.options.tileSource) ? v : {
                            type: 'image',
                            url: '/images/noimage.png'
                        })
                    ))
                }
            })
        }
    }, [ requestId, fileUrls, i18n ])

    const exportFile = () => {
        const link = (DLIBRA_CONTENT_URL + props.sounds[ currentSound - 1 ].edi_id + props.sounds[ currentSound - 1 ].path);
        const imgType = props.sounds[ currentSound - 1 ].path.split(/[\s.]+/).pop()
        const picturename = props.sounds[ currentSound - 1 ].path.split(/[\s/]+/).pop().split('.')[ 0 ]
        props.downloadImage(link, imgType, picturename)
    }

    return (
        <div className="mb-1">
            {props.data &&
                <div>
                    <h1 className="ml-2">{props.data[ 'name' ]}</h1>
                    {props.sounds.length > 0 && props.fileUrls.length > 0 &&
                        <h2 className="mt-2 ml-2">{t('pictures')}:</h2>
                    }
                    <Buttons {...props} currentImage={currentImage} />
                </div>
            }
            {props.fileUrls.length > 0 &&
                <div id={'iiif-' + props.requestId} className="openseadragon-container"></div>
            }
            {props.fileUrls.length > 0 && props.sounds.length > 0 &&
                <h2 className="mt-2 ml-2">{t('sounds')}:</h2>
            }
            <div className="d-flex">
                {props.sounds.length > 0 &&
                    <Button size="sm" className="ml-1" disabled={props.downloading} onClick={exportFile}>
                        {t('download_file')}
                    </Button>
                }
                {props.sounds.length > 1 &&
                    <div className="ml-2">
                        <ButtonToolbar>
                            <Button size="sm" variant="dark" id={"previous-sound" + props.requestId} disabled={props.loading || currentSound === 1}
                                onClick={e => {
                                    setCurrentSound(currentSound - 1)
                                }}
                            >
                                <FontAwesomeIcon icon={faArrowLeft} title={t('PreviousPage')} />
                            </Button>
                            <Button size="sm" className="ml-1" variant="dark" id={"next-sound" + props.requestId} disabled={props.loading || currentSound >= props.sounds.length}
                                onClick={e => {
                                    setCurrentSound(currentSound + 1)
                                }}
                            >
                                <FontAwesomeIcon icon={faArrowRight} title={t('NextPage')} />
                            </Button>
                        </ButtonToolbar>
                    </div>
                }
            </div>
            {props.sounds.length > 1 &&
                <div className="ml-2">
                    {t('common:sound_number')} <b>{props.sounds.length > 0 ? currentSound : 0}</b>  {t('common:of')} <b>{props.sounds.length}</b>
                </div>
            }
            {props.sounds.length > 0 &&
                <div className='player-wrapper'>
                    <CustomReactPlayer
                        url={DLIBRA_CONTENT_URL + props.sounds[ currentSound - 1 ].edi_id + props.sounds[ currentSound - 1 ].path}
                        type={props.sounds[ currentSound - 1 ].file_type}
                    ></CustomReactPlayer>
                </div>
            }
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    data: getOutput(ownProps.requestId, state),
    user: state.user ? state.user.data : null,
    downloading: isPending(DOWNLOADIMAGE_REQUEST_ID, state),
    fileUrls: getOutput(ownProps.requestId, state)?.files?.filter(val => val.file_type === "image") || [],
    sounds: getOutput(ownProps.requestId, state)?.files?.filter(val => (val.file_type === "audio" || val.file_type === "video") && val.state === "SAVED") || [],
    loading: isPending("PROJECTS_UPLOAD_OBSERVATION_IMAGE_ID", state) || isPending("PROJECTS_DELETE_OBSERVATION_IMAGE_ID", state)
})


const mapDispatchToProps = (dispatch, ownProps) => ({
    downloadImage: (link, imgType, picturename) => dispatch(actions.getDlibraImage(DOWNLOADIMAGE_REQUEST_ID, link, imgType, picturename)),
    postImage: (observationId, file, t) => {
        let formData = new FormData()
        formData.append('file', file)
        formData.append('observation_id', observationId)
        dispatch(actions.postData(PROJECTS_UPLOAD_OBSERVATION_IMAGE_ID, PROJECTS_UPLOAD_OBSERVATION_IMAGE_URL, formData)).then((res) => {
            if (res.ok) {
                notify.success(t('projects:observation-image-added'))
                setTimeout(function () {
                    ownProps.onDone && ownProps.onDone()
                }, 1000)
            } else {
                notify.error(t('projects:cannot-add-observation-image'), res.error)
            }
        }).catch((res) => {
            notify.error(t('projects:cannot-add-observation-image'), res.result)
        })
    },
    deleteImage: (observationId, fileNumber, t) => {
        dispatch(actions.postData(PROJECTS_DELETE_OBSERVATION_IMAGE_ID, PROJECTS_DELETE_OBSERVATION_IMAGE_URL, {
            observation_id: observationId,
            file_number: fileNumber
        })).then(() => {
            notify.success(t('projects:observation-image-deleted'))
            ownProps.onDone && ownProps.onDone()
        }).catch((res) => {
            notify.error(t('projects:cannot-delete-observation-image'), res.result)
        })
    }
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SingleDlibraImage)
