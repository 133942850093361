import React from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { isPending, getField } from "../../reducers";
import {
    SAMPLESDB_SEARCH_REQUEST_ID,
    SAMPLES_RESULTS_ACTIVE_TAB_FIELD_NAME,
} from '../../settings'
import { Form, Container, Row, Col } from "react-bootstrap"
import "../../styles/submenu.scss"
import DynamicSearchFormPanel from './DynamicSearchFormPanel2'
import SearchButtons from './SearchButtons'

const DynamicSearchForm = props => {
    const { t } = useTranslation([ 'common', 'taxondb', 'taxondb-attributes', 'profile', 'forms' ])

    return (
        <Form onSubmit={e => { e.preventDefault() }}>
            <Container className="mt-2">
                <Row className="dynamic-form-content d-flex flex-column">
                    <Container>
                        <div className="panel-border-bottom pb-2">
                            <DynamicSearchFormPanel path={[]} elementId="and"></DynamicSearchFormPanel>
                        </div>
                        <Form.Group as={Col} md={12} className="d-flex align-items-end mt-1">
                            <div className="flex-grow-1 mr-3 flex-fill">{t('taxondb:form-wildcard-info')}</div>
                        </Form.Group>
                        <div className="d-flex justify-content-center my-3">
                          <SearchButtons {...props} path requestId={SAMPLESDB_SEARCH_REQUEST_ID} tabId={SAMPLES_RESULTS_ACTIVE_TAB_FIELD_NAME}></SearchButtons>
                        </div>
                    </Container>
                </Row>
            </Container>
        </Form>
    )
}

const mapStateToProps = state => ({
    loading: isPending(SAMPLESDB_SEARCH_REQUEST_ID, state),
    fields: JSON.parse(getField(SAMPLESDB_SEARCH_REQUEST_ID, SAMPLESDB_SEARCH_REQUEST_ID, state, "null")),
})

const mapDispatchToProps = dispatch => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DynamicSearchForm)
