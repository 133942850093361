import React, { useState, useEffect, useCallback } from "react";
import { connect } from 'react-redux'
import { getField, prepareFilter } from "../reducers";
import { setField } from '../actions/forms'
import Autosuggest from 'react-autosuggest'
import "../styles/react-autosuggest.scss"
import { getElementStructure, setElementStructure } from "../utils/search";
import { useTranslation } from 'react-i18next'
import { countries } from "./dictionary/countries"
import { SUGGEST_DEBOUNCE_TIME_DELAY, ANC_URL_POSTFIX } from '../settings'
import * as ajax from '../utils/ajax'
import axios from "axios"
import debounce from "debounce"
import { Spinner } from "react-bootstrap"

const InputWithTypeahead = props => {
    const { i18n } = useTranslation()

    const [ suggestions, setSuggestions ] = useState([])
    const [ isLoading, setIsLoading ] = useState(false)
    const [ lastSource, setLastSource ] = useState(null)
    const [ lastDebounce, setLastDebounce ] = useState(null)
    const [ countriesObj, setCountriesObj ] = useState(countries.map(v => ({
        id: v["Państwo PL"],
        label: v[i18n.language.startsWith('pl') ? "Państwo PL" : "Państwo EN"]
    })))
    const [ query, setQuery ] = useState('')

    const WILDCARD_LIMIT = 20

    const getSuggestionsOnFocus = useCallback(() => {
        setIsLoading(true)
        let temp = { ...props.prepareFilter }
        if (props.path) {
            // tymczasowo wyłączamy filtr dla podpowiadaczek w wyszukiwarce dynamicznej ze wględu na warunki "OR"
            temp = {}
        } else {
            delete temp[ props.id ]
        }

        lastDebounce && lastDebounce.cancel()
        lastSource && lastSource.cancel()
        const source = axios.CancelToken.source()
        setLastSource(source)
        setLastDebounce(
            debounce(() => ajax.custom_post_with_cancel(props.postfix || ANC_URL_POSTFIX, props.url, {
                filter: temp,
            }, source)
                .then(response => {
                    const temp = countries.map(v => ({
                        id: v["Państwo PL"],
                        label: v[i18n.language.startsWith('pl') ? "Państwo PL" : "Państwo EN"]
                    }))
                    const newObj = response.data.map(v => (
                        {
                            id: v,
                            label: (
                                temp.find(c => c.id === v) ?
                                temp.find(c => c.id === v).label : v
                            )
                        }
                    )).sort((a, b) => {
                        if (a.label < b.label) {
                            return -1
                        }
                        if (a.label > b.label) {
                            return 1
                        }
                        return 0
                    })
                    setCountriesObj(newObj)
                    if (query === "*") {
                        setSuggestions(newObj.slice(0, WILDCARD_LIMIT));
                    } else {
                        setSuggestions(newObj.filter(v => v.label.toLowerCase().startsWith(query.toLowerCase())));
                    }
                    setIsLoading(false)
                })
                .catch(error => {
                    if (!axios.isCancel(error)) {
                        setCountriesObj([])
                        setIsLoading(false)
                    }
                })
                , SUGGEST_DEBOUNCE_TIME_DELAY)
        )
    }, [i18n.language, lastDebounce, lastSource, props.id, props.path, props.postfix, props.prepareFilter, props.url, query])

    useEffect(() => {
        const temp = window.document.getElementById(props.id)
        if (temp) {
            temp.addEventListener('focus', getSuggestionsOnFocus)
        }
        return () => {
            const temp = window.document.getElementById(props.id)
            if (temp) {
                temp.removeEventListener('focus', getSuggestionsOnFocus)
            }
        }
    }, [props.id, getSuggestionsOnFocus])


    const onSuggestionsFetchRequested = query => {
        setQuery(query.value)
        if (query.value === "*") {
            setSuggestions(countriesObj.slice(0, WILDCARD_LIMIT));
        } else {
            setSuggestions(countriesObj.filter(v => v.label.toLowerCase().startsWith(query.value.toLowerCase())));
        }
    }

    const onSuggestionsClearRequested = () => {
        setSuggestions([])
    }

    const getSuggestionValue = suggestion => suggestion.id;

    const inputProps = {
        value: (countriesObj.find(v => v.id === props.selectedValue)) ? (countriesObj.find(v => v.id === props.selectedValue).label) : (props.selectedValue),
        onChange: e => {
            if (props.onChange) {
                props.onChange(e)
            }
            const newValue = (countriesObj.find(v => v.label === e.target.value)) ? (countriesObj.find(v => v.label === e.target.value).id) : (e.target.value)
            props.setSelectedValue(newValue, props.fields)
          },
        className: 'form-control',
        disabled: props.disabled,
        id: props.id,
        autoComplete: "off",
        onKeyUp: e => {
            if (e.keyCode === 13) { // ENTER
                setSuggestions([])
                setIsLoading(false)
            }
            props.onKeyUp(e)
        },
        placeholder: props.placeholder,
    }

    const renderSuggestion = suggestion => {
      const query = props.selectedValue.toLowerCase();
      const bold = suggestion.label.substring(0, query.length);
      const normal = suggestion.label.substring(query.length);
      return (
        <span>
          <b>{bold}</b>{normal}
        </span>
      );
    }

    const onSuggestionSelected = (e, val) => {
        props.setSelectedValue(val.suggestionValue, props.fields)
    }

    const renderInputComponent = inputProps => (
        <div>
            <input {...inputProps} />
            {isLoading &&
                <Spinner animation="border" role="status" size="sm" className="spinner-inside-input">
                </Spinner>
            }
        </div>
    );

    return (
        <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
            id={props.id}
            onSuggestionSelected={onSuggestionSelected}
            renderInputComponent={renderInputComponent}
        >
        </Autosuggest>
    )
}

const mapStateToProps = (state, ownProps) => ({
    selectedValue: ownProps.path ?
        getElementStructure(ownProps.path,
            JSON.parse(getField(ownProps.formId, ownProps.formId, state, "null"))) :
        getField(ownProps.formId, ownProps.id, state),
    fields: JSON.parse(getField(ownProps.formId, ownProps.formId, state, "null")),
    prepareFilter: prepareFilter(ownProps.formId, state, ownProps.path),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    setSelectedValue: (value, fields) =>
        ownProps.path ?
            dispatch(setField(ownProps.formId, ownProps.formId, JSON.stringify(setElementStructure(ownProps.path, fields, value)))) :
            dispatch(setField(ownProps.formId, ownProps.id, value)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InputWithTypeahead)
