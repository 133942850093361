import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { isPending, getOutput } from "../../reducers"
import { Form } from "react-bootstrap"
import { Button } from "react-bootstrap"
import * as actions from '../../actions'
import * as notify from '../../utils/notify'
import {
    PROJECTS_CHANGE_OBSERVATION_NAME_ID,
    PROJECTS_CHANGE_OBSERVATION_NAME_URL,
} from '../../settings'

const ChangeObservationName = props => {
    const { t } = useTranslation(['common', 'profile', 'projects'])

    const [newObservationName, setNewObservationName] = useState(props.observationName)

    const onNewObservationNameChanged = e => {
        setNewObservationName(e.target.value)
    }

    return (
        <Form className="mt-2 common-panel-background p-2" onSubmit={e => { e.preventDefault() }}>
            <Form.Group>
                <Form.Label htmlFor="name">{t('profile:new-name')}</Form.Label>
                <Form.Control id="name" size="sm" value={newObservationName} onChange={onNewObservationNameChanged} disabled={props.querying}></Form.Control>
            </Form.Group>
            <div className="text-right">
            <Button size="sm"
                    disabled={!newObservationName || props.querying}
                    onClick={e => props.changeObservationName(newObservationName, props.itemsInParent, t)}
                >{t('projects:change-observation-name')}</Button>
            </div>
        </Form>
    )
}

const mapStateToProps = (state, ownProps) => ({
    querying: isPending(PROJECTS_CHANGE_OBSERVATION_NAME_ID, state),
    itemsInParent: getOutput(ownProps.requestId, state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    changeObservationName: (newObservationName, itemsInParent, t) => {
        dispatch(actions.postDataApi(PROJECTS_CHANGE_OBSERVATION_NAME_ID, PROJECTS_CHANGE_OBSERVATION_NAME_URL, {
            observation_id: ownProps.observationId,
            new_name: newObservationName
        })).then(() => {
            const newItemsInParent = {
                ...itemsInParent, items: itemsInParent.items.map(obj => {
                    if (obj.id === ownProps.observationId) {
                        return { ...obj, name: newObservationName }
                    } else {
                        return obj
                    }
                })
            }

            dispatch(actions.requestSuccess(ownProps.requestId, newItemsInParent))
            notify.success(t('projects:observation-name-changed'))
            ownProps.onDone && ownProps.onDone(newObservationName)
        }).catch(res => {
            notify.error(t('projects:cannot-change-observation-name'), res.result)
        })
    },
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChangeObservationName)
