import React from "react";
import { connect } from 'react-redux'
import { isPending, getOutput } from "../../reducers"
import { Row, Col, Spinner } from "react-bootstrap"
import SingleDlibraImage from './DlibraImage'
import RecordDetails from './RecordDetails'

const SingleRecordDetailsContainer = props => (
    <div className="d-flex flex-column pt-2">
        {props.loading &&
            <div className="align-self-center m-3">
                <Spinner animation="border" role="status" />
            </div>
        }
        <div>
            <Row>
                <Col md={6}>
                    {!props.loading && props.data &&
                        <SingleDlibraImage requestId={props.requestId}></SingleDlibraImage>
                    }
                </Col>
                <Col md={6}>
                    {!props.loading && props.data &&
                        <RecordDetails requestId={props.requestId}></RecordDetails>
                    }
                </Col>
            </Row>
        </div>
    </div>
)

const mapStateToProps = (state, ownProps) => ({
    loading: isPending(ownProps.requestId, state),
    data: getOutput(ownProps.requestId, state),
})

const mapDispatchToProps = dispatch => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SingleRecordDetailsContainer)
