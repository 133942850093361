import React, { Suspense } from "react";
import { connect } from 'react-redux'
import { Spinner } from "react-bootstrap"
import ResultsList from './ResultsList'
import {
    API_URL_POSTFIX,
    PROJECTS_GET_OBSERVATIONS_ID,
    PROJECTS_GET_OBSERVATIONS_URL
} from '../../settings'
import Loader from '../../utils/loader'
import { isPending } from "../../reducers"


const ProjectDetails = props => {

    return (
        <Suspense fallback={<Loader />}>
            {props.loading &&
                <div className="text-center">
                    <Spinner animation="border" role="status" />
                </div>
            }
            {!props.loading &&
                <div>
                    <ResultsList
                        viewedBy={props.viewedBy}
                        projectId={props.projectId}
                        requestId={PROJECTS_GET_OBSERVATIONS_ID + props.projectId}
                        requestUrl={`${API_URL_POSTFIX}${PROJECTS_GET_OBSERVATIONS_URL}${props.projectId}/`}
                        path={props.path} />
                </div>
            }
        </Suspense>
    )
}

const mapStateToProps = (state, ownProps) => ({
    loading: isPending(PROJECTS_GET_OBSERVATIONS_ID + ownProps.projectId, state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectDetails)
