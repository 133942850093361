import React, { Suspense, useState } from "react";
import { Container, Button } from "react-bootstrap"
import { useTranslation } from 'react-i18next'
import Loader from '../utils/loader'
import { Redirect } from "react-router-dom"
import I18nPathTranslator from "./I18nPathTranslator";
import { GIS_INFO_PAGE_URL_EN, GIS_INFO_PAGE_URL_PL, ARRANGEMENT_PAGE_URL_EN, ARRANGEMENT_PAGE_URL_PL } from "../settings";
import SEO from "./SEO";

const GisInfoContainer = props => {
  const { t, i18n } = useTranslation('gis', "paths", "a11y")

  const [ redirect, setRedirect ] = useState(false)

  const goto = url => {
    window.scrollTo(0, 0)
    setRedirect(url)
  }

  return (
    <Suspense fallback={<Loader />}>
      <I18nPathTranslator {...props} pathEN={GIS_INFO_PAGE_URL_EN} pathPL={GIS_INFO_PAGE_URL_PL} />
      <SEO title="BioGIS" />
      <div>
        {redirect &&
          <Redirect push to={redirect} ></Redirect>
        }
        <Container className="biogis">
          <div>
            <h1>{t('title')}</h1>
            <p><b>{t('section_1')}</b></p>
            <p>{t('section_2')}</p>
            <p><img src="/images/single-portfolio2.jpg" alt={t("a11y:BioGIS-map")} className="mx-auto d-block mb-3"></img></p>
            <p className="last-par">{t('section_3')}</p>
            <div className="d-flex">
              <Button className="see-more-button mr-3" onClick={e => { goto((i18n.language.startsWith('pl') ? ARRANGEMENT_PAGE_URL_PL : ARRANGEMENT_PAGE_URL_EN) + '/') }}>{t('go-to-tools')}</Button>
              <Button className="see-more-button" onClick={e => { goto(t('paths:how-to-use')) }}>{t('how-to-use')}</Button>
            </div>
          </div>
        </Container>
      </div>
    </Suspense>
  )
}

export default GisInfoContainer
