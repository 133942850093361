const decodeGPS = (value) => {

    let abs = Math.abs(value);

    let deg = Math.floor(abs);
    let min = Math.floor((abs-deg) * 60);
    let sec = (abs - deg - min / 60) * 3600;
    sec = sec.toFixed(0);
    if (sec === 60) {
        min = min + 1;
        sec = sec - 60;
    }


    let result = ""
    result += deg;
    result += "\u00B0";
    result += "\u0020"; // separator
    result += min;
    result += "\u0027";
    result += "\u0020"; // separator
    result += sec;
    result += "\u0022";
//    result += "\u0020"; // separator

    return result;
}



export const decodeLatitude = (value) => {

    if (Math.abs(value) > 90 ) {
        return NaN;
    }


    return decodeGPS(value) +  (value < 0 ? 'S' : 'N');
}

export const decodeLongitude = (value) => {


    if (Math.abs(value) > 180 ) {
        return NaN;
    }

    return decodeGPS(value) + (value <0 ? 'W' : 'E');
}

export const formatStringToLatitude = (val) => {
    let output = val?.replace(',', '.');
    const lat = parseFloat(output)
    if (isNaN(lat)) {
        return "0"
    }
    else {
        if (lat < -90.0 || lat > 90.0) {
            return "0"
        }
        return lat.toString()
    }
}

export const formatStringToLongitude = (val) => {
    let output = val?.replace(',', '.');
    const lat = parseFloat(output)
    if (isNaN(lat)) {
        return "0"
    }
    else {
        if (lat < -180.0 || lat > 180.0) {
            return "0"
        }
        return lat.toString()
    }
}

export const formatStringToAltititude = (val) => {
    let output = val?.replace(',', '.');
    const lat = parseFloat(output)
    if (isNaN(lat)) {
        return "0"
    }
    else {
        return lat.toString()
    }
}

