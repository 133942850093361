export const SET_USER_MAP_FILE = 'SET_USER_MAP_FILE'
export const SET_MAPFILES_ARRAY = 'SET_MAPFILES_ARRAY'
export const CLEAR_MAPFILES_ARRAY = 'CLEAR_MAPFILES_ARRAY'
export const SET_WMS_ARRAY = 'SET_WMS_ARRAY'
export const CLEAR_WMS_ARRAY = 'CLEAR_WMS_ARRAY'
export const SET_MAP_MARKER_COLOR = 'SET_MAP_MARKER_COLOR'
export const SET_MAP_MARKER_ICON = 'SET_MAP_MARKER_ICON'
export const SET_MAP_MARKER_SIZE = 'SET_MAP_MARKER_SIZE'
export const SET_MAP_POLYGON_STYLE = 'SET_MAP_POLYGON_STYLE'
export const SET_MAP_LINE_STYLE = 'SET_MAP_LINE_STYLE'
export const SET_TAXONS_BY_YEAR = 'SET_TAXONS_BY_YEAR'
export const SET_HISTORICAL_ANALISIS_MODE = 'SET_HISTORICAL_ANALISIS_MODE'
export const SHOW_ANIMATION_BUTTON = 'SHOW_ANIMATION_BUTTON'
export const SET_ANIMATION_TIMEOUTS = 'SET_ANIMATION_TIMEOUTS'
export const SET_CARTOGRAM_ITEMS = 'SET_CARTOGRAM_ITEMS'
export const SET_ANIMATION_YEARS = 'SET_ANIMATION_YEARS'
export const SET_ANIMATION_IS_PLAYED = 'SET_ANIMATION_IS_PLAYED'
export const SET_OBSERVATIONS_ARRAY = 'SET_OBSERVATIONS_ARRAY'
export const CLEAR_OBSERVATIONS_ARRAY = 'CLEAR_OBSERVATIONS_ARRAY'
export const CLEAR_MAP_LABELS = 'CLEAR_MAP_LABELS'
export const ADD_MAP_LABEL = 'ADD_MAP_LABEL'
export const REMOVE_MAP_LABEL = 'REMOVE_MAP_LABEL'
export const SET_OBSERVATIONS_MARKER_COLOR = 'SET_OBSERVATIONS_MARKER_COLOR'
export const SET_OBSERVATIONS_MARKER_SIZE = 'SET_OBSERVATIONS_MARKER_SIZE'
export const SET_OBSERVATIONS_MARKER_ICON = 'SET_OBSERVATIONS_MARKER_ICON'
export const SET_CARTOGRAM_CURRENT_FILTER = 'SET_CARTOGRAM_CURRENT_FILTER'
export const SET_CARTOGRAM_MANUAL_RANGES = 'SET_CARTOGRAM_MANUAL_RANGES'
export const SET_TOO_MANY_TAXONS_WARNING = 'SET_TOO_MANY_TAXONS_WARNING'
export const SET_TAXONS_FOUND_NUMBER = 'SET_TAXONS_FOUND_NUMBER'
export const SET_HISTORICAL_MARKER_COLOR = 'SET_HISTORICAL_MARKER_COLOR'
export const SET_HISTORICAL_MARKER_ICON = 'SET_HISTORICAL_MARKER_ICON'
export const SET_HISTORICAL_MARKER_SIZE = 'SET_HISTORICAL_MARKER_SIZE'
export const SET_ARRAY_OF_YEARS = 'SET_ARRAY_OF_YEARS'
export const SET_PIXI_MARKER_COLOR = 'SET_PIXI_MARKER_COLOR'
export const SET_PIXI_SELECTED_CIRCLECOLOR = 'SET_PIXI_SELECTED_CIRCLECOLOR'
export const SET_PIXI_MARKER_SIZE = 'SET_PIXI_MARKER_SIZE'
export const SET_PIXI_MARKER_SHAPE = 'SET_PIXI_MARKER_SHAPE'
export const SET_GROUPING_SHAPE_FIELD = 'SET_GROUPING_SHAPE_FIELD'
export const SET_GROUPING_COLOR_FIELD = 'SET_GROUPING_COLOR_FIELD'
export const SET_GROUPING_COLOR_LEGEND_SHOWN = 'SET_GROUPING_COLOR_LEGEND_SHOWN'
export const SET_GROUPING_SHAPE_LEGEND_SHOWN = 'SET_GROUPING_SHAPE_LEGEND_SHOWN'
export const SET_CARTOGRAM_ADMINISTRATIVE_DIVISION = 'SET_CARTOGRAM_ADMINISTRATIVE_DIVISION'
export const SET_CARTOGRAM_BORDERS_COLOR = 'SET_CARTOGRAM_BORDERS_COLOR'
export const SET_CARTOGRAM_BORDERS_WIDTH = 'SET_CARTOGRAM_BORDERS_WIDTH'
export const SET_CARTOGRAM_OPACITY = 'SET_CARTOGRAM_OPACITY'
export const SET_CARTOGRAM_FIRST_AREA_COLOR = 'SET_CARTOGRAM_FIRST_AREA_COLOR'
export const SET_CARTOGRAM_SECOND_AREA_COLOR = 'SET_CARTOGRAM_SECOND_AREA_COLOR'
export const SET_CARTODIAGRAM_GROUPING_FIELD = 'SET_CARTODIAGRAM_GROUPING_FIELD'
export const SET_CARTODIAGRAM_NUMBER_OF_CHART_COLORS = 'SET_CARTODIAGRAM_NUMBER_OF_CHART_COLORS'
export const SET_CARTOGRAM_NUMBER_OF_COLORS = 'SET_CARTOGRAM_NUMBER_OF_COLORS'
export const SET_CARTOGRAM_SEQUENCE_TYPE = 'SET_CARTOGRAM_SEQUENCE_TYPE'
export const SET_CARTOGRAM_SEQUENCE_PRODUCT = 'SET_CARTOGRAM_SEQUENCE_PRODUCT'
export const SET_CARTOGRAM_FIRST_SEQUENCE_NUMBER = 'SET_CARTOGRAM_FIRST_SEQUENCE_NUMBER'
export const SET_OBSERVATIONS_REDIRECTED_ID = 'SET_OBSERVATIONS_REDIRECTED_ID'
export const SET_LOAD_OBSERVATIONS_ON_INIT = 'SET_LOAD_OBSERVATIONS_ON_INIT'
export const SET_OPENED_MAP_TOOL = 'SET_OPENED_MAP_TOOL'
export const SET_MAP_TITLE = 'SET_MAP_TITLE'
export const SET_MAP_ID = 'SET_MAP_ID'
export const SET_SAVED_MAP_FILTER = 'SET_SAVED_MAP_FILTER'
export const SET_WMS_ADDED_TO_MAP = 'SET_WMS_ADDED_TO_MAP'
export const SET_ADMINISTRATIVE_DIVISION_STYLE = 'SET_ADMINISTRATIVE_DIVISION_STYLE'
export const SET_MAP_LEGEND = 'SET_MAP_LEGEND'
export const SET_VISIBLE_TOOL_SCALE = 'SET_VISIBLE_TOOL_SCALE'
export const SET_VISIBLE_TOOL_ZOOM_SLIDER = 'SET_VISIBLE_TOOL_ZOOM_SLIDER'
export const SET_VISIBLE_TOOL_MAGNIFIER_GLASS = 'SET_VISIBLE_TOOL_MAGNIFIER_GLASS'
export const SET_VISIBLE_TOOL_IMAGE_EXPORT = 'SET_VISIBLE_TOOL_IMAGE_EXPORT'
export const SET_VISIBLE_TOOL_BASEMAPS = 'SET_VISIBLE_TOOL_BASEMAPS'
export const SET_VISIBLE_TOOL_DRAWING_TOOLS = 'SET_VISIBLE_TOOL_DRAWING_TOOLS'

export const SET_KEY_VALUE = 'SET_KEY_VALUE'
export const CLEAR_KEY_VALUE = 'CLEAR_KEY_VALUE'

export const setMapKeyValue = (key, value) => ({
    type: SET_KEY_VALUE,
    key,
    value,
})
export const clearMapKeyValue = (key) => ({
    type: CLEAR_KEY_VALUE,
    key,
})

export const setMapFile = data => ({
    type: SET_USER_MAP_FILE,
    data: data
})

export const setMapfileArray = data => ({
    type: SET_MAPFILES_ARRAY,
    data: data
})

export const clearMapfileArray = () => ({
    type: CLEAR_MAPFILES_ARRAY
})

export const setWmsArray = data => ({
    type: SET_WMS_ARRAY,
    data: data
})

export const clearWmsArray = () => ({
    type: CLEAR_WMS_ARRAY
})

export const setMapMarkerColor = data => ({
    type: SET_MAP_MARKER_COLOR,
    data: data
})

export const setMapMarkerIcon = data => ({
    type: SET_MAP_MARKER_ICON,
    data: data
})

export const setMapMarkerSize = data => ({
    type: SET_MAP_MARKER_SIZE,
    data: data
})

export const setMapPolygonStyle = data => ({
    type: SET_MAP_POLYGON_STYLE,
    data: data
})

export const setAdministrativeDivisionStyle = data => ({
    type: SET_ADMINISTRATIVE_DIVISION_STYLE,
    data: data
})

export const setMapLineStyle = data => ({
    type: SET_MAP_LINE_STYLE,
    data: data
})

export const setTaxonsByYear = data  => ({
    type: SET_TAXONS_BY_YEAR,
    data: data
})

export const setHistoricalAnalisisMode = data => ({
    type: SET_HISTORICAL_ANALISIS_MODE,
    data: data
})

export const showAnimationButton = data => ({
    type: SHOW_ANIMATION_BUTTON,
    data: data
})

export const setAnimationTimeout = data => ({
    type: SET_ANIMATION_TIMEOUTS,
    data: data
})

export const setCartogramItems = data => ({
    type: SET_CARTOGRAM_ITEMS,
    data: data
})

export const setAnimationYears = data => ({
    type: SET_ANIMATION_YEARS,
    data: data
})

export const clearMapLabels = () => ({
    type: CLEAR_MAP_LABELS
})

export const addMapLabel = data => ({
    type: ADD_MAP_LABEL,
    data: data
})

export const removeMapLabel = data => ({
    type: REMOVE_MAP_LABEL,
    data: data
})

export const setObservationsMarkerColor = data => ({
    type: SET_OBSERVATIONS_MARKER_COLOR,
    data: data
})

export const setObservationsMarkerSize = data => ({
    type: SET_OBSERVATIONS_MARKER_SIZE,
    data: data
})

export const setObservationsMarkerIcon = data => ({
    type: SET_OBSERVATIONS_MARKER_ICON,
    data: data
})

export const setCartogramCurrentFilter = data => ({
    type: SET_CARTOGRAM_CURRENT_FILTER,
    data: data
})

export const setCartogramManualRanges = data => ({
    type: SET_CARTOGRAM_MANUAL_RANGES,
    data: data
})

export const setTooManyTaxonsWarning = data => ({
    type: SET_TOO_MANY_TAXONS_WARNING,
    data: data
})

export const setTaxonsFoundNumber = data => ({
    type: SET_TAXONS_FOUND_NUMBER,
    data: data
})

export const setHistoricalMarkerColor = data => ({
    type: SET_HISTORICAL_MARKER_COLOR,
    data: data
})

export const setHistoricalMarkerIcon = data => ({
    type: SET_HISTORICAL_MARKER_ICON,
    data: data
})

export const setHistoricalMarkerSize = data => ({
    type: SET_HISTORICAL_MARKER_SIZE,
    data: data
})

export const setArrayOfYears = data => ({
    type: SET_ARRAY_OF_YEARS,
    data: data
})

export const setPixiMarkerColor = data => ({
    type: SET_PIXI_MARKER_COLOR,
    data: data
})

export const setPixiSelectedCirclecolor = data => ({
    type: SET_PIXI_SELECTED_CIRCLECOLOR,
    data: data
})

export const setPixiMarkerSize = data => ({
    type: SET_PIXI_MARKER_SIZE,
    data: data
})

export const setPixiMarkerShape = data => ({
    type: SET_PIXI_MARKER_SHAPE,
    data: data
})

export const setGroupingShapeField = data => ({
    type: SET_GROUPING_SHAPE_FIELD,
    data: data
})

export const setGroupingColorField = data => ({
    type: SET_GROUPING_COLOR_FIELD,
    data: data
})

export const setGroupingColorLegendShown = data => ({
    type: SET_GROUPING_COLOR_LEGEND_SHOWN,
    data: data
})

export const setGroupingShapeLegendShown = data => ({
    type: SET_GROUPING_SHAPE_LEGEND_SHOWN,
    data: data
})

export const setCartogramAdministrativeDivision = data => ({
    type: SET_CARTOGRAM_ADMINISTRATIVE_DIVISION,
    data: data
})

export const setCartogramBordersColor = data => ({
    type: SET_CARTOGRAM_BORDERS_COLOR,
    data: data
})

export const setCartogramBordersWidth = data => ({
    type: SET_CARTOGRAM_BORDERS_WIDTH,
    data: data
})

export const setCartogramOpacity = data => ({
    type: SET_CARTOGRAM_OPACITY,
    data: data
})

export const setCartogramFirstAreaColor = data => ({
    type: SET_CARTOGRAM_FIRST_AREA_COLOR,
    data: data
})

export const setCartogramSecondAreaColor = data => ({
    type: SET_CARTOGRAM_SECOND_AREA_COLOR,
    data: data
})

export const setCartodiagramGroupingField = data => ({
    type: SET_CARTODIAGRAM_GROUPING_FIELD,
    data: data
})

export const setCartodiagramNumberOfChartColors = data => ({
    type: SET_CARTODIAGRAM_NUMBER_OF_CHART_COLORS,
    data: data
})

export const setCartogramNumberOfColors = data => ({
    type: SET_CARTOGRAM_NUMBER_OF_COLORS,
    data: data
})

export const setCartogramSequenceType = data => ({
    type: SET_CARTOGRAM_SEQUENCE_TYPE,
    data: data
})

export const setCartogramSequenceProduct = data => ({
    type: SET_CARTOGRAM_SEQUENCE_PRODUCT,
    data: data
})

export const setCartogramFirstSequenceNumber = data => ({
    type: SET_CARTOGRAM_FIRST_SEQUENCE_NUMBER,
    data: data
})

export const setObservationsRedirectedId = data => ({
    type: SET_OBSERVATIONS_REDIRECTED_ID,
    data: data
})

export const setLoadObservationsOnInit = data => ({
    type: SET_LOAD_OBSERVATIONS_ON_INIT,
    data: data
})

export const setOpenedMapTool = data => ({
    type: SET_OPENED_MAP_TOOL,
    data: data
})

export const setMapTitle = data => ({
    type: SET_MAP_TITLE,
    data: data
})

export const setMapId = data => ({
    type: SET_MAP_ID,
    data: data
})

export const setWmsAddedToMap = data => ({
    type: SET_WMS_ADDED_TO_MAP,
    data: data
})

export const setMapLegend = data => ({
    type: SET_MAP_LEGEND,
    data: data
})

export const setVisibleToolScale = data => ({
    type: SET_VISIBLE_TOOL_SCALE,
    data: data
})

export const setVisibleToolZoomslider = data => ({
    type: SET_VISIBLE_TOOL_ZOOM_SLIDER,
    data: data
})

export const setVisibleToolMagnifierGlass = data => ({
    type: SET_VISIBLE_TOOL_MAGNIFIER_GLASS,
    data: data
})

export const setVisibleToolImageExport = data => ({
    type: SET_VISIBLE_TOOL_IMAGE_EXPORT,
    data: data
})

export const setVisibleToolBasemaps = data => ({
    type: SET_VISIBLE_TOOL_BASEMAPS,
    data: data
})

export const setVisibleToolDrawingTools = data => ({
    type: SET_VISIBLE_TOOL_DRAWING_TOOLS,
    data: data
})
