import React, { Suspense } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Button, Form, FormLabel, Spinner } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

import { getDataApi } from "../../actions"
import { isPending } from "../../reducers";
import {
    API_GET_SIZE_STATS_ID,
    API_GET_SIZE_STATS_URL,
} from '../../settings'
import { setIconSizeData, setDaysScope, setTimeUnit, setSizeUnit } from '../../actions/chart'
import Loader from '../../utils/loader'
import IconSizeChart from './IconSizeChart'
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


function IconSizeReportsHandler(props) {

    const { t } = useTranslation(['reports'])

    const handleDateFetch = (event) => {
        event.preventDefault()

        let dateStart = event.target.elements.dateFrom.value;
        let dateEnd = event.target.elements.dateTo.value;

        let startDate = new Date(dateStart);
        let endDate = new Date(dateEnd);

        let endDateString = `${endDate.getFullYear()}-${('0' + (endDate.getMonth() + 1)).slice(-2)}-${('0' + endDate.getDate()).slice(-2)}`
        let startDateString = `${startDate.getFullYear()}-${('0' + (startDate.getMonth() + 1)).slice(-2)}-${('0' + startDate.getDate()).slice(-2)}`

        fetchChartsData(startDateString, endDateString)
        props.setDaysScope({ "dayFrom": startDateString, "dayTo": endDateString })
    }

    const fetchChartsData = (dayFrom, dayTo, type) => {
        props.getCollectionsData(
            API_GET_SIZE_STATS_ID, API_GET_SIZE_STATS_URL +
            dayFrom + "/" + dayTo + "/"
        )
            .then((response) => {
                if (response && response.length) {
                    let datesForSizeReport = []
                    let iconSizeData = []
                    let timeUnit = "hour"

                    response.forEach((item) => {
                        datesForSizeReport.push(item["data"])
                        iconSizeData.push(item["rozmiar"] / 1000000000) // convert byte to gigabyte
                    })

                    if (datesForSizeReport && datesForSizeReport.length) {
                        /*
                        let firstDate = new Date(datesForSizeReport[0])
                        let lastDate = new Date(datesForSizeReport[datesForSizeReport.length-1])
                        let timeDiff = (lastDate - firstDate) / 1000
                        if (timeDiff > 604800) timeUnit = "week"
                        else if (timeDiff > 86400) timeUnit = "day"
                        */
                    }
                    props.setIconSizeData({
                        "datesForSizeReport": datesForSizeReport,
                        "iconSizeData": iconSizeData,
                        "timeUnit": timeUnit,
                        "sizeUnit": "gigabyte"
                    })
                }
                else {
                    props.setIconSizeData({
                        "datesForSizeReport": [],
                        "iconSizeData": [],
                        "timeUnit": "month",
                        "sizeUnit": "gigabyte"
                    })
                }
            }
            )
    }

    const handleTimeUnitChange = (event) => {
        props.setTimeUnit({ "timeUnit": event.target.value })
    }

    const handleInformationUnitChange = (event) => {
        let data = [...props.charts.iconSizeData]

        if (event.target.value === "gigabyte") {
            data.forEach((item, index) => {
                data[index] = item;
            })
        }
        else if (event.target.value === "terabyte") {
            data.forEach((item, index) => {
                data[index] = (item / 1000).toFixed(4);
            })
        }

        props.setSizeUnit({ "sizeUnit": event.target.value, "sizeDataToDisplay": data })

    }


    return (
        <Suspense fallback={<Loader />}>
            <div className="ml-1">
                <Row>
                    {props.chartLoading &&
                        <Spinner
                            as="span"
                            animation="border"
                            size="lg"
                            role="status"
                            aria-hidden="true"
                            className="ml-3 mb-3"
                        />
                    }
                    {!props.chartLoading &&
                        <Col className="d-flex flex-column h-100 bd-highlight mb-3">
                            <div className="p-2 bd-highlight">
                                <IconSizeChart />
                            </div>
                        </Col>
                    }
                </Row>
                <Row>
                    <Col md={{ span: 4 }}>
                        <div className="chart-container p-2">
                            <Form onSubmit={handleDateFetch}>
                                <div><h4>{t('daily-interval')}</h4></div>
                                <Form.Group>
                                    <FormLabel htmlFor={"iconsizeDateFrom"}><b>{t('from-day')}</b></FormLabel>
                                    <span className="datepicker-toggle">
                                        <Form.Control id={"iconsizeDateFrom"} type="date" name="dateFrom" defaultValue={props.charts.dayFrom} required>
                                        </Form.Control>
                                        <FontAwesomeIcon icon={faCalendar} className="datepicker-toggle-button" alt=""/>
                                    </span>
                                </Form.Group>
                                <Form.Group>
                                    <FormLabel htmlFor={"iconsizeDateTo"}><b>{t('to-day')}</b></FormLabel>
                                    <span className="datepicker-toggle">
                                        <Form.Control id={"iconsizeDateTo"} type="date" name="dateTo" defaultValue={props.charts.dayTo} required>
                                        </Form.Control>
                                        <FontAwesomeIcon icon={faCalendar} className="datepicker-toggle-button" alt=""/>
                                    </span>
                                </Form.Group>
                                <Button type="submit" className="mb-2">
                                    {t('display-data')}
                                </Button>
                            </Form>
                        </div>
                    </Col>
                    <Col md={{ span: 4 }}>
                        <div className="chart-container p-2">
                            <Form>
                                <Form.Group>
                                    <FormLabel htmlFor={"iconsizeTimeUnit"}><b>{t('time-unit')}</b></FormLabel>
                                    <Form.Control id={"iconsizeTimeUnit"} as="select" value={props.charts.timeUnit} onChange={handleTimeUnitChange}>
                                        <option value="hour">{t('hour')}</option>
                                        <option value="day">{t('day')}</option>
                                        <option value="week">{t('week')}</option>
                                        <option value="month">{t('month')}</option>
                                    </Form.Control>
                                </Form.Group>
                            </Form>
                            <Form>
                                <Form.Group>
                                    <FormLabel htmlFor={"iconsizeSizeUnit"}><b>{t('size-unit')}</b></FormLabel>
                                    <Form.Control id={"iconsizeSizeUnit"} as="select"
                                        value={props.charts.sizeUnit}
                                        onChange={handleInformationUnitChange}
                                    >
                                        <option value="gigabyte">{t('gigabyte')}</option>
                                        <option value="terabyte">{t('terabyte')}</option>
                                    </Form.Control>
                                </Form.Group>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </div>
        </Suspense>
    )

}

const mapStateToProps = (state) => ({
    charts: state.charts,
    chartLoading: isPending(API_GET_SIZE_STATS_ID, state),
})

const mapDispatchToProps = dispatch => ({
    getCollectionsData: (id, url) => dispatch(getDataApi(id, url)),
    setDaysScope: (days) => dispatch(setDaysScope(days)),
    setIconSizeData: (data) => dispatch(setIconSizeData(data)),
    setTimeUnit: (data) => dispatch(setTimeUnit(data)),
    setSizeUnit: (data) => dispatch(setSizeUnit(data)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IconSizeReportsHandler)