import React, { useEffect, useState, useRef, useCallback } from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getOutputOrDefault, getPagination, isPending } from "../../reducers"
import { postDataAnc, requestSuccess } from '../../actions'
import RedirectLoginWithReturn from "../access/RedirectLoginWithReturn"
import SearchProtectedTaxon from "../protectedTaxon/SearchProtectedTaxon"
import CreateProtectedTaxon from "../protectedTaxon/CreateProtectedTaxon"
import EditProtectedTaxon from "../protectedTaxon/EditProtectedTaxon"
import ResultsList from '../taxonDB/ResultsList'
import { Container, Row, Col, ListGroup, Button, Collapse, Spinner, Badge } from "react-bootstrap"
import ListGroupItemDiv from '../ListGroupItemDiv'
import ConnectedPagination from '../ConnectedPagination'
import Confirm from "../Confirm"
import {
    ANC_URL_POSTFIX,
    PROTECTED_TAXONS_SEARCH_ID,
    PROTECTED_TAXONS_SEARCH_URL,
    PROTECTED_TAXONS_DELETE_ID,
    PROTECTED_TAXONS_DELETE_URL,
    PROTECTED_TAXONS_GET_SPECIMENS_ID,
    PROTECTED_TAXONS_GET_SPECIMENS_URL,
    GENERAL_EXTENDED_REQUEST_TIMEOUT,
    PROTECTED_TAXON_PAGE_URL_EN,
    PROTECTED_TAXON_PAGE_URL_PL
} from '../../settings'
import I18nPathTranslator from "../I18nPathTranslator";
import SEO from "../SEO";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import SeeMoreIcon from "../SeeMoreIcon";

const ProtectedTaxonListItem = props => {
    const { t, i18n } = useTranslation(['protectedTaxon', 'forms'])
    const { protectedTaxon, ignoreDates, parentProps } = props

    const [isShowDetailsOpen, setShowDetailsOpen] = useState(false)
    const toggleShowDetailsOpen = () => {
        setShowDetailsOpen(!isShowDetailsOpen)
        parentProps.getSpecimens(protectedTaxon.id, ignoreDates)
    }

    const [isEditProtectedTaxonOpen, setEditProtectedTaxonOpen] = useState(false)
    const toggleEditProtectedTaxon = () => {
        setEditProtectedTaxonOpen(!isEditProtectedTaxonOpen)
    }

    const requestInterceptor = (request) => {
        return {...request, ignore_dates: ignoreDates }
    }

    const onEditDone = () => {
        props.onListContentChanged && props.onListContentChanged()
    }

    return (
        <>
            <ListGroupItemDiv onClick={e => {if(protectedTaxon.liczba_okazow > 0){toggleShowDetailsOpen()}}}>
                <Row>
                    <div className="d-flex justify-content-center align-items-center" style={{width: '5%'}}>
                        {protectedTaxon.liczba_okazow > 0 && <SeeMoreIcon isOpen={isShowDetailsOpen} />}
                    </div>
                    <Col onClick={() => protectedTaxon.liczba_okazow > 0 && toggleShowDetailsOpen}>
                        {['rodzaj', 'gatunek', 'podgatunek'].includes(protectedTaxon.grupa) &&
                            <i>{ protectedTaxon.nazwa }</i>
                        }
                        {!['rodzaj', 'gatunek', 'podgatunek'].includes(protectedTaxon.grupa) &&
                            <span>{ protectedTaxon.nazwa }</span>
                        }
                        &nbsp;
                        <Badge variant="secondary">
                            { protectedTaxon.grupa }
                        </Badge>
                        &nbsp;
                        {protectedTaxon.liczba_okazow > 0 &&
                            <Badge variant="secondary">
                                { protectedTaxon.liczba_okazow.toLocaleString(i18n.languages[0]) }
                            </Badge>
                        }
                        <br />
                        <span className="itemlist-itemlabel-plain">
                            { protectedTaxon.zrodlo_ochrony__nazwa }
                        </span>
                        &nbsp;
                        {(protectedTaxon.od_daty || protectedTaxon.do_daty) &&
                            <span className="itemlist-itemlabel-plain-small">
                                ({ protectedTaxon.od_daty} - { protectedTaxon.do_daty })
                            </span>
                        }
                    </Col>
                    <Col sm="auto" className="d-flex flex-row align-self-center">
                        <Button variant="secondary" title={t('forms:edit')} size="sm" className="mr-1" onClick={toggleEditProtectedTaxon}>
                            <FontAwesomeIcon icon={faPencilAlt} />
                        </Button>
                        <Confirm
                            onConfirm={e => parentProps.removeProtectedTaxon(protectedTaxon.id, parentProps.requests)}
                            size="sm"
                            pending={parentProps.removingItems}
                            disabled={parentProps.removingItems}
                            WCAGtext={t('forms:remove')}
                            buttonLabel={<FontAwesomeIcon icon={faTrash} />}
                            confirmButtonLabel={t('forms:remove')}
                            confirmationText={t('protectedTaxon:confirm-remove-protected-taxon')}
                        ></Confirm>
                    </Col>
                </Row>
            </ListGroupItemDiv>
            <Collapse in={isShowDetailsOpen} id={`detailsProtectedTaxon${protectedTaxon.id}`}>
                <Container>
                    <div className="mt-2 mb-2">
                        <ResultsList
                            requestId={PROTECTED_TAXONS_GET_SPECIMENS_ID + protectedTaxon.id}
                            requestUrl={`${ANC_URL_POSTFIX}${PROTECTED_TAXONS_GET_SPECIMENS_URL}${protectedTaxon.id}/`}
                            requestInterceptor={requestInterceptor}
                            protectedRecordId={protectedTaxon.id}
                            ignoreDates={props.ignoreDates}>
                        </ResultsList>
                    </div>
                </Container>
            </Collapse>
            <Collapse in={isEditProtectedTaxonOpen} id={`editProtectedTaxon${protectedTaxon.id}`}>
                <div>
                    <EditProtectedTaxon protectedTaxon={protectedTaxon} onDone={onEditDone} />
                </div>
            </Collapse>
        </>
    )
}

const ProtectedTaxonContainer = props => {
    const { t } = useTranslation(['protectedTaxon', 'paths'])

    const paginationRef = useRef()
    const [searchFilter, setSearchFilter] = useState({})
    const [searchParams, setSearchParams] = useState({})

    const [isCreateProtectedTaxonOpen, setCreateProtectedTaxonOpen] = useState(false)
    const toggleCreateProtectedTaxonOpen = () => {
        setCreateProtectedTaxonOpen(!isCreateProtectedTaxonOpen)
    }

    const getProtectedTaxonsCallback = useCallback(props.getProtectedTaxons, []);
    useEffect(() => {
        getProtectedTaxonsCallback()
    }, [getProtectedTaxonsCallback])

    const onSearch = (group, name, protectionLevel, protectionSource, orderBy, ignoreDates) => {
        let filter = {
            ...props.filter,
            ...group && { grupa: group },
            ...name && { nazwa: name },
            ...protectionLevel && { poziom_ochrony: protectionLevel },
            ...protectionSource && { zrodlo_ochrony_id: protectionSource }
        }

        setSearchFilter({filter})
        setSearchParams({
            sort: orderBy,
            ignore_dates: ignoreDates
        })
        getProtectedTaxonsCallback({
            filter: filter,
            sort: orderBy,
            ignore_dates: ignoreDates,
            pagination: {}
        })
    }

    const onListContentChanged = () => {
        paginationRef.current.reloadPage()
    }

    return (
        <>
            <I18nPathTranslator {...props} pathEN={PROTECTED_TAXON_PAGE_URL_EN} pathPL={PROTECTED_TAXON_PAGE_URL_PL} />
            <SEO title={t('menu:protected-taxons')} />
            {!props.loading && props.user && !props.user.data &&
                <RedirectLoginWithReturn lang={props.lang} returnUrl={props.lang ? t("paths:protected-taxon", {lng: props.lang}) : t("paths:protected-taxon")} />
            }
            {props.user && props.user.data &&
                <Container className="mt-2">
                    <Row>
                        <Col>
                            <Button className="mr-2" onClick={toggleCreateProtectedTaxonOpen}>
                                {t('protectedTaxon:add-protected-taxon')}
                            </Button>
                            <Collapse in={isCreateProtectedTaxonOpen}>
                                <div>
                                    <CreateProtectedTaxon onDone={() => {setCreateProtectedTaxonOpen(false)}} />
                                </div>
                            </Collapse>
                            <hr />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SearchProtectedTaxon onSearch={onSearch}></SearchProtectedTaxon>
                            {props.loading && !props.pagination &&
                                <div className="text-center">
                                    <Spinner animation="border" role="status" />
                                </div>
                            }
                            {!props.loading && props.pagination &&
                                <div>
                                    <div>
                                        <ConnectedPagination customFilter={searchFilter} customParams={searchParams} requestId={PROTECTED_TAXONS_SEARCH_ID} url={ANC_URL_POSTFIX + PROTECTED_TAXONS_SEARCH_URL} ref={paginationRef}></ConnectedPagination>
                                    </div>
                                    <ListGroup>
                                        {props.protectedTaxons.map((protectedTaxon, i) => {
                                            return (
                                                <ProtectedTaxonListItem protectedTaxon={protectedTaxon} ignoreDates={searchParams.ignore_dates || false} onListContentChanged={onListContentChanged} key={protectedTaxon.id} parentProps={props} />
                                            )
                                        })}
                                    </ListGroup>
                                    <div>
                                        <ConnectedPagination customFilter={searchFilter} customParams={searchParams} last requestId={PROTECTED_TAXONS_SEARCH_ID} url={ANC_URL_POSTFIX + PROTECTED_TAXONS_SEARCH_URL}></ConnectedPagination>
                                    </div>
                                </div>
                            }
                        </Col>
                    </Row>
                </Container>
        }
        </>
    )
}

const mapStateToProps = (state, ownProps) => ({
    user: state.user,
    pagination: getPagination(PROTECTED_TAXONS_SEARCH_ID, state),
    loading: isPending(PROTECTED_TAXONS_SEARCH_ID, state),
    removingItems: isPending(PROTECTED_TAXONS_DELETE_ID, state),
    protectedTaxons: getOutputOrDefault(PROTECTED_TAXONS_SEARCH_ID, state, { items: [] })['items'],
    requests: state.requests
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    getProtectedTaxons: (data) => dispatch(postDataAnc(PROTECTED_TAXONS_SEARCH_ID, PROTECTED_TAXONS_SEARCH_URL, data, GENERAL_EXTENDED_REQUEST_TIMEOUT)),
    removeProtectedTaxon: (protectedTaxonId, requests) => dispatch(postDataAnc(PROTECTED_TAXONS_DELETE_ID, PROTECTED_TAXONS_DELETE_URL, {
        id: protectedTaxonId
    })).then(() => {
        const oldData = requests[PROTECTED_TAXONS_SEARCH_ID].output;
        const newData = { ...oldData, items: oldData.items.filter((t) => t.id !== protectedTaxonId) }
        dispatch(requestSuccess(PROTECTED_TAXONS_SEARCH_ID, newData))
    }),
    getSpecimens: (id, isIgnoreDates) => dispatch(postDataAnc(PROTECTED_TAXONS_GET_SPECIMENS_ID + id, `${PROTECTED_TAXONS_GET_SPECIMENS_URL}${id}/`, { ignore_dates: isIgnoreDates }))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProtectedTaxonContainer)
