import React, { forwardRef } from 'react';
import { Dropdown, DropdownButton } from  "react-bootstrap"; 
import {
    USER_DRAWINGS_ALL_LAYERS_CONST,
    USER_DRAWINGS_POINTS_CONST,
    USER_DRAWINGS_LINES_CONST, 
    USER_DRAWINGS_POLYGONS_CONST,
} from '../../settings'
import L from 'leaflet';
import { useTranslation } from 'react-i18next'
import tokml from "geojson-to-kml";
import { connect } from 'react-redux'

const FileExporter = forwardRef((props, ref) => {

    const { t } = useTranslation(['map'])

    const handleExportToKML = (layer) => {
        let layers = new L.FeatureGroup();
        switch(layer) {
            case USER_DRAWINGS_ALL_LAYERS_CONST:
                download("MapLabels.kml", tokml(ref.userDrawingsRef.current.toGeoJSON()));
                break;
            case USER_DRAWINGS_POINTS_CONST:
                ref.userDrawingsRef.current.eachLayer(
                    function(layer){
                        if (layer.name === USER_DRAWINGS_POINTS_CONST){
                            layers.addLayer(layer)
                        }
                    }
                )
                download("MapLabels.kml", tokml(layers.toGeoJSON()));
                break;
            case USER_DRAWINGS_LINES_CONST:
                ref.userDrawingsRef.current.eachLayer(
                    function(layer){
                        if (layer.name === USER_DRAWINGS_LINES_CONST){
                            layers.addLayer(layer)
                        }
                    }
                )
                download("MapLabels.kml", tokml(layers.toGeoJSON()));
                break;
            case USER_DRAWINGS_POLYGONS_CONST:
                ref.userDrawingsRef.current.eachLayer(
                    function(layer){
                        if (layer.name === USER_DRAWINGS_POLYGONS_CONST){
                            layers.addLayer(layer)
                        }
                    }
                )
                download("MapLabels.kml", tokml(layers.toGeoJSON()));
                break;
            default:
                console.log('error')
        }
    }

    const handleExportToGeoJSON = (layer) => {
        let layers = new L.FeatureGroup();
        switch(layer) {
            case USER_DRAWINGS_ALL_LAYERS_CONST:
                download("MapLabels.json", JSON.stringify(ref.userDrawingsRef.current.toGeoJSON()));
                break;
            case USER_DRAWINGS_POINTS_CONST:
                ref.userDrawingsRef.current.eachLayer(
                    function(layer){
                        if (layer.name === USER_DRAWINGS_POINTS_CONST){
                            layers.addLayer(layer)
                        }
                    }
                )
                download("MapLabels.json", JSON.stringify(layers.toGeoJSON()));
                break;
            case USER_DRAWINGS_LINES_CONST:
                ref.userDrawingsRef.current.eachLayer(
                    function(layer){
                        if (layer.name === USER_DRAWINGS_LINES_CONST){
                            layers.addLayer(layer)
                        }
                    }
                )
                download("MapLabels.json", JSON.stringify(layers.toGeoJSON()));
                break;
            case USER_DRAWINGS_POLYGONS_CONST:
                ref.userDrawingsRef.current.eachLayer(
                    function(layer){
                        if (layer.name === USER_DRAWINGS_POLYGONS_CONST){
                            layers.addLayer(layer)
                        }
                    }
                )
                download("MapLabels.json", JSON.stringify(layers.toGeoJSON()));
                break;
            default:
                console.log('error')
        }
    }


    function download(filename, text) {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    return(
        <>
            <DropdownButton className="mr-3 btn-group btn-block" id="dropdown-basic-button" title={t('map:export-as-file')} size="lg">
                <Dropdown.Item onClick={(e) => handleExportToKML(e.target.name)} name={USER_DRAWINGS_ALL_LAYERS_CONST} >{t('map:export-to-kml-all')}</Dropdown.Item>
                <Dropdown.Item onClick={(e) => handleExportToKML(e.target.name)} name={USER_DRAWINGS_POINTS_CONST} >{t('map:export-to-kml-points')}</Dropdown.Item>
                <Dropdown.Item onClick={(e) => handleExportToKML(e.target.name)} name={USER_DRAWINGS_LINES_CONST} >{t('map:export-to-kml-lines')}</Dropdown.Item>
                <Dropdown.Item onClick={(e) => handleExportToKML(e.target.name)} name={USER_DRAWINGS_POLYGONS_CONST} >{t('map:export-to-kml-polygons')}</Dropdown.Item>
            <Dropdown.Divider />
                <Dropdown.Item onClick={(e) => handleExportToGeoJSON(e.target.name)} name={USER_DRAWINGS_ALL_LAYERS_CONST} >{t('map:export-to-geojson-all')}</Dropdown.Item>
                <Dropdown.Item onClick={(e) => handleExportToGeoJSON(e.target.name)} name={USER_DRAWINGS_POINTS_CONST} >{t('map:export-to-geojson-points')}</Dropdown.Item>
                <Dropdown.Item onClick={(e) => handleExportToGeoJSON(e.target.name)} name={USER_DRAWINGS_LINES_CONST} >{t('map:export-to-geojson-lines')}</Dropdown.Item>
                <Dropdown.Item onClick={(e) => handleExportToGeoJSON(e.target.name)} name={USER_DRAWINGS_POLYGONS_CONST} >{t('map:export-to-geojson-polygons')}</Dropdown.Item>
            </DropdownButton>
        </>
    )
    
})

const mapStateToProps = (state, ownProps) => ({
  
})

const mapDispatchToProps = dispatch => ({
   
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(FileExporter)