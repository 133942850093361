import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import * as actions from '../../actions'
import { getOutput, getOutputOrDefault, isPending } from "../../reducers"
import { postDataApi } from '../../actions'
import { Form, Button } from "react-bootstrap"
import {
    PROJECTS_GET_ALL_1000_ID,
    PROJECTS_GET_ALL_URL,
    PROJECTS_CHANGE_OBSERVATION_PROJECT_ID,
    PROJECTS_CHANGE_OBSERVATION_PROJECT_URL
} from '../../settings'
import * as notify from '../../utils/notify'

const ChangeObservationProject = props => {
    const { t } = useTranslation([])

    const { getProjects } = props

    useEffect(() => {
        getProjects()
    }, [getProjects])

    const [newObservationProject, setNewObservationProject] = useState(props.projectId)

    const onNewObservationProjectChanged = e => {
        setNewObservationProject(e.target.value)
    }

    const onChangeObservationProject = () => {
        props.changeObservationProject(newObservationProject, props.itemsInParent, t)
    }

    return (
        <Form className="mt-2 common-panel-background p-2" onSubmit={e => { e.preventDefault() }}>
            <Form.Group>
                <Form.Label htmlFor="project">{t('projects:new-observation-project')}</Form.Label>
                <Form.Control id="project" as="select" value={newObservationProject} onChange={onNewObservationProjectChanged} disabled={props.querying}>
                    {props.projects && props.projects.map((p, i) =>
                        <option key={i} value={p.id}>{p.name}</option>
                    )};
                </Form.Control>
            </Form.Group>
            <div className="text-right">
                <Button size="sm"
                    disabled={!newObservationProject || props.querying}
                    onClick={onChangeObservationProject}
                >{t('projects:change-observation-project')}</Button>
            </div>
        </Form>
    )
}

const mapStateToProps = (state, ownProps) => ({
    quering: isPending(PROJECTS_GET_ALL_1000_ID, state),
    projects: getOutputOrDefault(PROJECTS_GET_ALL_1000_ID, state, [])['items'],
    itemsInParent: getOutput(ownProps.requestId, state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    getProjects: () => dispatch(postDataApi(PROJECTS_GET_ALL_1000_ID, PROJECTS_GET_ALL_URL, { pagination: { currentPage: 1, perPage: 1000 } })),
    changeObservationProject: (newObservationProject, itemsInParent, t) => {
        dispatch(actions.postDataApi(PROJECTS_CHANGE_OBSERVATION_PROJECT_ID, PROJECTS_CHANGE_OBSERVATION_PROJECT_URL, {
            project_id: newObservationProject,
            observation_id: ownProps.observationId
        })).then(() => {
            const newItemsInParent = {
                ...itemsInParent, items: itemsInParent.items.filter(obj => {
                    return obj.id !== ownProps.observationId
                })
            }

            dispatch(actions.requestSuccess(ownProps.requestId, newItemsInParent))
            notify.success(t('projects:observation-project-changed'))
            ownProps.onDone && ownProps.onDone(newObservationProject)
        }).catch(res => {
            notify.error(t('projects:cannot-change-observation-project'), res.result)
        })
    }
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChangeObservationProject)
