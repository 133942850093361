import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { diff } from 'deep-object-diff'
import { Tab, Tabs, Form, Button, Container, Row, Col } from 'react-bootstrap'
import Autosuggest from 'react-autosuggest'
import ValueFromDictionary from '../dictionary/ValueFromDictionary'
import { getOutput, isPending } from '../../reducers'
import { postDataApi, getDataApi } from '../../actions'
import * as notify from '../../utils/notify'
import {
    PROJECTS_CHANGE_OBSERVATION_ID_PREFIX,
    PROJECTS_CHANGE_OBSERVATION_URL,
    PROJECTS_GET_OBSERVATION_ID,
    PROJECTS_GET_OBSERVATION_URL,
} from '../../settings'
import {
    fieldSampleMerocenosisDeadWood,
    fieldSampleMerocenosisHost,
    fieldSampleMerocenosisTreeSpecies,
    fieldSampleMerocenosisType,
    fieldSampleMerocenosis,
    fieldSampleMethodTaken,
    fieldSampleType,
    fieldSource,
    floraNames
} from '../../components/dictionary/Observations'
import ShareButton from "../iconDB/ShareButton"
import AddToAlbum from './../profile/AddToAlbum'
import { formatStringToLongitude, formatStringToLatitude, formatStringToAltititude } from '../../utils/gps'

const AttrValue = props =>
    <>
        <ValueFromDictionary value={props.data[ props.name ]} {...props}></ValueFromDictionary>
    </>

const EditAttrValue = props => {
    const [ attrValue, setAttrValue ] = useState(!props.array ? props.data[ props.name ] : [ ...props.data[ props.name ], '' ])

    const onChangeAttrValue = (e, index = 0) => {
        if (!props.array) {
            setAttrValue(e.target.value)
            props.onFieldsChange(props.name, e.target.value === '' ? null : e.target.value)
        } else {
            let newAttrValue = [ ...attrValue ]
            newAttrValue[ index ] = e.target.value.trim()
            newAttrValue = newAttrValue.filter((a) => a !== '')
            setAttrValue([ ...newAttrValue, '' ])
            props.onFieldsChange(props.name, newAttrValue)
        }
    }

    const onChangeAutosuggestAttrValue = (newValue) => {
        setAttrValue(newValue)
        props.onFieldsChange(props.name, newValue === '' ? null : newValue)
    }

    const inputProps = {
        'value': attrValue || '',
        'onChange': (e, { newValue }) => onChangeAutosuggestAttrValue(newValue),
        'className': 'form-control form-control-sm'
    };

    const [ suggestions, setSuggestions ] = useState(props.suggestions || null)

    const getSuggestions = (value) => {
        const escapedValue = value.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        if (escapedValue === '') {
            return [];
        }

        const regex = new RegExp('^' + escapedValue, 'i');
        return floraNames.filter(name => regex.test(name));
    }

    const getSuggestionValue = (suggestion) => {
        return suggestion
    }

    const renderSuggestion = (suggestion) => {
        return <span>{suggestion}</span>
    }

    const onSuggestionsFetchRequested = ({ value }) => {
        setSuggestions(getSuggestions(value))
    }

    const onSuggestionsClearRequested = () => {
        setSuggestions([])
    }

    return (<>
        {!props.array && props.suggestions &&
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps} />
        }
        {!props.array && !props.suggestions &&
            <Form.Control size="sm" type="text" value={attrValue || ''} onChange={(e) => onChangeAttrValue(e)} autoComplete="off" />
        }
        {props.array && attrValue.map((v, i) =>
            <Form.Control size="sm" type="text" key={i} value={v || ''} onChange={(e) => onChangeAttrValue(e, i)} autoComplete="off" />)
        }
    </>)
}

const getTranslatedAttrName = (t, name) => {
    const key = name.replace(/\./g, '_')
    const translation = t(key)
    return translation.startsWith('projects:') ? name : translation
}

const AttrName = props =>
    <b>
        <>
            {getTranslatedAttrName(props.t, props.name)}
        </>
    </b>

const DivRow = props =>
    <>
        {(props.data[ props.name ] || props.editable) &&
            <div className="d-flex" style={{ borderTopStyle: "solid", borderTopColor: "#dee2e6", borderTopWidth: "1px" }}>
                <div className="w-50 p-1">
                    <AttrName {...props}></AttrName>
                </div>
                <div className="w-50 p-1">
                    {!props.editable &&
                        <AttrValue {...props}></AttrValue>
                    }
                    {props.editable &&
                        <EditAttrValue {...props}></EditAttrValue>
                    }
                </div>
            </div>
        }
    </>

export const Tab1 = props =>
    <div>
        <DivRow name="field.source" {...props} suggestions={fieldSource}></DivRow>
        <DivRow name="field.settlement" {...props}></DivRow>
        <DivRow name="field.location" {...props}></DivRow>
        <DivRow name="field.latitude" {...props}></DivRow>
        <DivRow name="field.longitude" {...props}></DivRow>
        <DivRow name="field.altitude" {...props}></DivRow>
        <DivRow name="field.collection.date" {...props}></DivRow>
        <DivRow name="field.collection.author" {...props}></DivRow>
        <DivRow name="field.collection.id" {...props}></DivRow>
        <DivRow name="field.flora.cover" {...props}></DivRow>
        <DivRow name="field.area" {...props}></DivRow>
        <DivRow name="field.note" {...props}></DivRow>
    </div>

export const Tab2 = props =>
    <div>
        <DivRow name="field.specimen" {...props} suggestions={floraNames}></DivRow>
        <DivRow name="field.quantity" {...props}></DivRow>
        <DivRow name="field.gender" {...props}></DivRow>
        <DivRow name="field.age" {...props}></DivRow>
        <DivRow name="field.stage" {...props}></DivRow>
        <DivRow name="field.dimensions" {...props}></DivRow>
    </div>

export const Tab3 = props =>
    <div>
        <DivRow name="field.settlement.icon" {...props}></DivRow>
        <DivRow name="field.people.icon" {...props}></DivRow>
        <DivRow name="field.other.objects.icon" {...props}></DivRow>
        <DivRow name="field.description.icon" {...props}></DivRow>
    </div>

export const Tab4 = props =>
    <div>
        <DivRow name="field.sample.id" {...props}></DivRow>
        <DivRow name="field.sample.location" {...props}></DivRow>
        <DivRow name="field.sample.type" {...props} suggestions={fieldSampleType}></DivRow>
        <DivRow name="field.sample.method.taken" {...props} suggestions={fieldSampleMethodTaken}></DivRow>
        <DivRow name="field.sample.merocenosis" {...props} suggestions={fieldSampleMerocenosis}></DivRow>
        <DivRow name="field.sample.merocenosis.type" {...props} suggestions={fieldSampleMerocenosisType}></DivRow>
        <DivRow name="field.sample.merocenosis.host" {...props} suggestions={fieldSampleMerocenosisHost}></DivRow>
        <DivRow name="field.sample.merocenosis.ground.relative.position" {...props}></DivRow>
        <DivRow name="field.sample.merocenosis.bird.nest.type" {...props} suggestions={fieldSampleMerocenosisType}></DivRow>
        <DivRow name="field.sample.merocenosis.dead.wood" {...props} suggestions={fieldSampleMerocenosisDeadWood}></DivRow>
        <DivRow name="field.sample.merocenosis.tree.diameter" {...props}></DivRow>
        <DivRow name="field.sample.merocenosis.tree.length" {...props}></DivRow>
        <DivRow name="field.sample.merocenosis.tree.species" {...props} suggestions={fieldSampleMerocenosisTreeSpecies}></DivRow>
        <DivRow name="field.sample.humidity" {...props}></DivRow>
        <DivRow name="field.sample.additional.description" {...props}></DivRow>
    </div>

const AllTabs = props => {
    const { t, i18n } = useTranslation([ 'projects' ])

    const [ key, setKey ] = useState('group-1')
    const [ editable, setEditable ] = useState(false)
    const [ fields, setFields ] = useState(props.data?.fields)
    const [ addAlbumOpen, setAddAlbumOpen ] = useState(false)

    const onEditableChange = () => {
        setEditable(!editable)
    }

    const onFieldsChange = (name, value) => {
        if (!editable) {
            return
        }

        const temp = { ...fields }
        temp[ name ] = value
        setFields(temp)
    }

    const onSave = () => {
        const diffFields = diff(props.data.fields, fields)
        if (diffFields["field.latitude"]) {
            diffFields["field.latitude"] = formatStringToLatitude(diffFields["field.latitude"])
        }
        if (diffFields["field.longitude"]) {
            diffFields["field.longitude"] = formatStringToLongitude(diffFields["field.longitude"])
        }
        if (diffFields["field.altitude"]) {
            diffFields["field.altitude"] = formatStringToAltititude(diffFields["field.altitude"])
        }
        props.saveRecord(props.data.id, diffFields, t)
        setEditable(!editable)
    }

    const onKeyDown = (e) => {
        if (['ArrowLeft', 'ArrowRight'].includes(e.key)){
            e.stopPropagation()
        }
    }

    return (
        <>
            {props.data &&
                <Container>
                    <Tabs defaultActiveKey={'group-1'} id={props.requestId} activeKey={key} onSelect={k => setKey(k)} className="position-relative">
                        <Tab eventKey="group-1" title={t('group-1')} onKeyDown={onKeyDown}>
                            <Tab1 data={props.data.fields} editable={editable} onFieldsChange={onFieldsChange} t={t} i18n={i18n}></Tab1>
                        </Tab>
                        <Tab eventKey="group-2" title={t('group-2')} onKeyDown={onKeyDown}>
                            <Tab2 data={props.data.fields} editable={editable} onFieldsChange={onFieldsChange} t={t} i18n={i18n}></Tab2>
                        </Tab>
                        <Tab eventKey="group-3" title={t('group-3')} onKeyDown={onKeyDown}>
                            <Tab3 data={props.data.fields} editable={editable} onFieldsChange={onFieldsChange} t={t} i18n={i18n}></Tab3>
                        </Tab>
                        <Tab eventKey="group-4" title={t('group-4')} onKeyDown={onKeyDown}>
                            <Tab4 data={props.data.fields} editable={editable} onFieldsChange={onFieldsChange} t={t} i18n={i18n}></Tab4>
                        </Tab>
                        <Tab eventKey="share" title={t('common:share')} tabClassName="share-button" onKeyDown={onKeyDown}>
                            <Row>
                                {addAlbumOpen &&
                                    <Col md={6}>
                                        <AddToAlbum kind="observation" itemId={props.recordId} onDone={e => setAddAlbumOpen(false)}></AddToAlbum>
                                    </Col>
                                }

                                <Col md={addAlbumOpen? 6 : 12}>
                                    <ShareButton {...props} setAddAlbumOpen={setAddAlbumOpen}></ShareButton>
                                </Col>
                            </Row>
                        </Tab>
                    </Tabs>
                    {!props.viewedBy &&
                        <div className="mb-3 pl-2 pr-2">
                            <Form.Check
                                className="d-inline"
                                type="switch"
                                id={`editable-switch-${props.requestId}`}
                                label={t('forms:edit')}
                                checked={editable}
                                onChange={onEditableChange}
                            />
                            {editable &&
                                <Button onClick={onSave} size="sm" disabled={props.isSaving} className="float-right">{t('forms:save')}</Button>
                            }
                        </div>
                    }
                </Container>
            }
        </>
    )
}

const mapStateToProps = (state, ownProps) => ({
    data: getOutput(ownProps.requestId, state),
    isSaving: isPending(PROJECTS_CHANGE_OBSERVATION_ID_PREFIX + ownProps.recordId, state)
})

const mapDispatchToProps = dispatch => ({
    saveRecord: (recordId, fields, t) => dispatch(postDataApi(
        PROJECTS_CHANGE_OBSERVATION_ID_PREFIX + recordId,
        PROJECTS_CHANGE_OBSERVATION_URL,
        { observation_id: recordId, fields: fields }))
        .then(() => {
            dispatch(getDataApi(PROJECTS_GET_OBSERVATION_ID + recordId, `${PROJECTS_GET_OBSERVATION_URL}${recordId}/`))
            notify.success(t('projects:modified-observation'))
        }).catch(res => {
            notify.error(t('projects:cannot-modify-observation'), res.result)
        })
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllTabs)
