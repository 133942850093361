import React from "react";
import { connect } from 'react-redux'
import { Row, Col, Form, FormLabel } from "react-bootstrap"
import { setCartogramManualRanges } from '../../../../actions/map'
import { 
    modifyManualSequenceMaxItem,
    modifyManualSequenceMinItem,
} from "../CartogramFunctions"
import { useTranslation } from 'react-i18next'

const ManualRanges = props => {

    const { t } = useTranslation(['map'])

    const modifyMaxItem = (index, value) => {
        let currentArray = modifyManualSequenceMaxItem(value, [...props.manualRanges], index)
        props.setCartogramManualRanges(currentArray)
    }

    const modifyMinItem = (index, value) => {
        let currentArray = modifyManualSequenceMinItem(value, [...props.manualRanges], index)
        props.setCartogramManualRanges(currentArray)
    }

    return(
        <>
            {props.manualRanges && props.manualRanges.length &&
            props.manualRanges
            .filter((item, index) => index < 20) 
            .map((item, index) => {
                return(
                    <Row key={index}>
                        <Col sm={3} md={3} className="ml-2">
                            <FormLabel htmlFor={"manualSequenceItem-min-1"}>
                                {t('map:manual-range-from')}
                            </FormLabel>
                            <Form.Control 
                                id={"manualSequenceItem-min-1"} 
                                type="number" 
                                name="manualSequenceItem-min-1"
                                //defaultValue={props.manualRanges[index].min}
                                onChange={e => modifyMinItem(index, e.target.value)}
                            />
                        </Col>
                        <Col sm={3} md={3}>
                            <FormLabel htmlFor={"manualSequenceItem-max-1"}>
                                {t('map:manual-range-to')}
                            </FormLabel>
                            <Form.Control 
                                id={"manualSequenceItem-max-1"} 
                                type="number" 
                                name="manualSequenceItem-max-1"
                                disabled={props.loading}
                                //defaultValue={props.manualRanges[index].max}
                                onChange={e => modifyMaxItem(index, e.target.value)}
                            />
                        </Col>
                    </Row>
                    )
                }) 
            }
        </>
    )
}

const mapStateToProps = state => ({
    manualRanges: state.map.cartogramManualRanges,
})

const mapDispatchToProps = dispatch => ({
    setCartogramManualRanges: (data) => dispatch(setCartogramManualRanges(data)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ManualRanges)