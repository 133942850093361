export const handleTabsA11y = (key, event, setKey, scrollId="") => { //Adding accessibility to Bootstrap's Tabs
        if(event.type==="click"){
            setKey(key)
            return null
        }
        const SCROLL_OFFSET = 40;
        //const TIME_OFFSET = 200;
        
        let scrollablePanel
        if(scrollId)
            scrollablePanel = window.document.getElementById(scrollId)
        else
            scrollablePanel = window

        /*
        const smoothScrolling = (offset) => {
            let available = true
            if(available){
                available = false
                scrollablePanel.scrollBy({top:offset, behavior:"smooth"})
                setTimeout(()=>{
                    available = true
                }, TIME_OFFSET)
            }
        }*/

        if(event.keyCode===38) //arrow up
            scrollablePanel.scrollBy({top:-SCROLL_OFFSET, behavior:"smooth"})
        if(event.keyCode===40) //arrow down
            scrollablePanel.scrollBy({top:SCROLL_OFFSET, behavior:"smooth"})
        if(event.keyCode===39) //arrow right
            setKey(key)
        if(event.keyCode===37) //arrow left
            setKey(key)
    }