import React, { useState, forwardRef } from 'react'
import { connect } from 'react-redux'
import { Button, Dropdown, DropdownButton, Container, Spinner } from  "react-bootstrap"; 
import L from 'leaflet';
import axios from "axios"
import { useTranslation } from 'react-i18next'
import ReactDOMServer from 'react-dom/server';
import { GENUS_ATTRIBUTE_NAME, SPECIES_ATTRIBUTE_NAME } from '../taxonDB/attributesStructure'
import {
    TAXONDB_SEARCH_WITH_AGGREGATION_REQUEST_URL,
    MAP_DATA_REQUEST_TIMEOUT,
    TAXONDB_SEARCH_MAP_DATA_REQUEST_ID,
    TAXONDB_SEARCH_MAP_DATA_REQUEST_URL,
    TAXONDB_DETAILS_REQUEST_URL,
} from '../../settings'
import {
    RODZAJ_GATUNEK_ATTRIBUTE_NAME,
} from '../taxonDB/attributesStructure'
import { isPending } from "../../reducers";
import { postDataAnc } from '../../actions'
import '../../utils/map/leaflet.loader'
import * as actions from '../../actions'
import { GetValueFromDictionary } from "../dictionary/ValueFromDictionary"

const AggregatedTaxonsIcons  = forwardRef((props, ref) => {

    const { t, i18n } = useTranslation(['map', 'taxondb', 'taxondb-attributes', 'taxondb-groups'])
    const source = axios.CancelToken.source()        
    const { collectionTypes, getDetails, MapPopupContent, lang, langi18n } = { ...props, lang: t, langi18n: i18n }

    const DEFAULT_SHAPE_ICON = "fa-square"
    const DEFAULT_COLOR_ICON = 'blue'
    const TAXONS_LAYER_NAME_CONST = 'ancClasteredTaxons'

    const [color, setColor] = useState(null)
    const [shape, setShape] = useState(null)
    const [iconSize, setIconSize] = useState("fa-lg")

    var loader =  L.control.loader()

    const reloadAggregatedMap = () => {
        prepareMapBeforeSearchRequest()
        searchMapDetails()
    }

    const searchMapDetails = () => {
      
        props.searchMapData({ 
            filter: props.filter
        }, source)
        .then(res => {
            res.data.forEach(item => {
                let icon = renderIconHtml(
                    shape ? shape : DEFAULT_SHAPE_ICON, 
                    color ? color : DEFAULT_COLOR_ICON, 
                    iconSize
                )
                if (ref.ancTaxonsWithLegend.current){
                    const fontAwesomeIcon = new L.divIcon({
                        html: icon,
                        className: 'myDivIcon'
                    });
                    const marker = L.marker([item[0], item[1]], { icon: fontAwesomeIcon })
                    marker['kolekcjanumerokazu'] = item[2]
                    marker.addTo(ref.ancTaxonsWithLegend.current)
                    let tooltip = ReactDOMServer.renderToString(
                        <div>
                            <div><b>{item[2]}</b></div>
                            <div>{item[3]}</div>
                            <div>{item[4]}</div>
                        </div>
                    )
                    marker.bindTooltip(tooltip)
                    marker.on('click', markerOnClick)
                }
            })
            loader.hide();
        })
        .catch(err => {
            loader.hide();
        })
    }

    const markerOnClick = m => {
        const popupLoading = <Container className="d-flex flex-column mt-2">
            <div className="align-self-center">
                <Spinner animation="border" role="status" />
            </div>
        </Container>
        const content = ReactDOMServer.renderToString(popupLoading)
        const popup = L.popup()
            .setLatLng(m.target.getLatLng())
            .setContent(content)
            .openOn(ref.mapRef.current);
        getDetails(m.target.kolekcjanumerokazu)
            .then(data => {
                if ([...props.labels].length > 0){
                    let text=``;
                    [...props.labels].forEach(label => {
                        if (label === RODZAJ_GATUNEK_ATTRIBUTE_NAME){
                            text += `<div><b>${t('taxondb-attributes:' + label)}</b>:  ${GetValueFromDictionary(GENUS_ATTRIBUTE_NAME, data[GENUS_ATTRIBUTE_NAME], langi18n, collectionTypes)} ${GetValueFromDictionary(SPECIES_ATTRIBUTE_NAME, data[SPECIES_ATTRIBUTE_NAME], langi18n, collectionTypes)}</div>`
                        }
                        else{
                            text += `<div><b>${t('taxondb-attributes:' + label)}</b>:  ${GetValueFromDictionary(label, data[label], langi18n, collectionTypes)}</div>`
                        }
                    })
                    popup.setContent(text)
                }
                else {
                    const temp = <MapPopupContent requestId={""} manySpecies={m.manySpecies} data={data} t={lang} i18n={langi18n} collectionTypes={collectionTypes}></MapPopupContent>
                    const content = ReactDOMServer.renderToString(temp)
                    popup.setContent(content)
                }
            })     
    }

    const prepareMapBeforeSearchRequest = () => {
        loader.addTo(ref.mapRef.current);
        ref.ancTaxonsWithLegend.current.eachLayer(function (layer) {
            ref.ancTaxonsWithLegend.current.removeLayer(layer);
        })
        ref.mapRef.current.eachLayer(function(layer){
            if (layer.name === TAXONS_LAYER_NAME_CONST){
                ref.mapRef.current.removeLayer(layer);
            }
        });
    }

    const renderIconHtml = (shape, color, size) => {
        let html = ReactDOMServer.renderToString(
            <i 
                className={`fa ${shape} ${size}`}
                style={{ color: color }}
            />
        )
        return html
    }
   
    return (
        <div>
            <div className="mt-2 mb-4">
                <b>{t("map:change-icon-style")}</b>
            </div>

            <div className="mt-3 mb-1">
                <DropdownButton title={t("map:marker-icon")}
                    className="mt-1"
                    variant="info"
                    disabled={props.loading}
                    onClick={(e) => {
                        if(e.target.id){
                                setShape(e.target.id)
                            }
                        }
                    }>
                    <Dropdown.Item id="fa-map-marker"><i className="fa fa-map-marker"></i></Dropdown.Item>
                    <Dropdown.Item id="fa-map-pin"><i className="fa fa-map-pin"></i></Dropdown.Item>
                    <Dropdown.Item id="fa-thumb-tack"><i className="fa fa-thumb-tack"></i></Dropdown.Item>
                    <Dropdown.Item id="fa-star"><i className="fa fa-star"></i></Dropdown.Item>
                    <Dropdown.Item id="fa-star-o"><i className="fa fa-star-o"></i></Dropdown.Item>
                    <Dropdown.Item id="fa-circle"><i className="fa fa-circle"></i></Dropdown.Item>
                    <Dropdown.Item id="fa-circle-o"><i className="fa fa-circle-o"></i></Dropdown.Item>
                    <Dropdown.Item id="fa-bullseye"><i className="fa fa-bullseye"></i></Dropdown.Item>
                    <Dropdown.Item id="fa-square"><i className="fa fa-square"></i></Dropdown.Item>
                    <Dropdown.Item id="fa-square-o"><i className="fa fa-square-o"></i></Dropdown.Item>
                    <Dropdown.Item id="fa-cube"><i className="fa fa-cube"></i></Dropdown.Item>
                    <Dropdown.Item id="fa-bookmark"><i className="fa fa-bookmark"></i></Dropdown.Item>
                    <Dropdown.Item id="fa-asterisk"><i className="fa fa-asterisk"></i></Dropdown.Item>
                    <Dropdown.Item id="fa-times"><i className="fa fa-times"></i></Dropdown.Item>
                    <Dropdown.Item id="fa-plus"><i className="fa fa-plus"></i></Dropdown.Item>
                </DropdownButton>
                <div className="mt-1">
                    {t("map:select-icon-shape")}
                </div>
            </div>

            <div className="mt-3 mb-1">
                <DropdownButton title={t("map:marker-color")}  
                    className="mt-1"
                    variant="info"
                    disabled={props.loading} 
                    onClick={(e) => {
                        if (e.target.id){
                                setColor(e.target.id)
                            }
                        }
                    }>
                        <Dropdown.Item id="blue"><i className="fa fa-square" style={{color:'blue'}} /> {t("map:color-blue")}</Dropdown.Item>
                        <Dropdown.Item id="brown"><i className="fa fa-square" style={{color:'brown'}} /> {t("map:color-brown")}</Dropdown.Item>
                        <Dropdown.Item id="green"><i className="fa fa-square" style={{color:'green'}} /> {t("map:color-green")}</Dropdown.Item>
                        <Dropdown.Item id="orange"><i className="fa fa-square" style={{color:'orange'}} /> {t("map:color-orange")}</Dropdown.Item>
                        <Dropdown.Item id="red"><i className="fa fa-square" style={{color:'red'}} /> {t("map:color-red")}</Dropdown.Item> 
                        <Dropdown.Item id="violet"><i className="fa fa-square" style={{color:'violet'}} /> {t("map:color-violet")}</Dropdown.Item>
                        <Dropdown.Item id="yellow"><i className="fa fa-square" style={{color:'yellow'}} /> {t("map:color-yellow")}</Dropdown.Item>
                        <Dropdown.Item id="white"><i className="fa fa-square" style={{color:'white', border: '1px solid black'}} /> {t("map:color-white")}</Dropdown.Item>
                        <Dropdown.Item id="black"><i className="fa fa-square" style={{color:'black'}} /> {t("map:color-black")}</Dropdown.Item>
                        <Dropdown.Item id="gray"><i className="fa fa-square" style={{color:'gray'}} /> {t("map:color-gray")}</Dropdown.Item> 
                </DropdownButton>
                <div className="mt-1">
                    {t("map:select-icon-color")}
                </div>
            </div>

            <div className="mt-3 mb-1">
                <DropdownButton
                    className="mt-1"
                    variant="info"
                    title={t("map:map-icon-title")}
                    onClick={(e) => {setIconSize(e.target.id)}}
                    disabled={props.loading}
                >
                    <Dropdown.Item id="fa-sm">{t("map:map-icon-small")}</Dropdown.Item>
                    <Dropdown.Item id="fa-lg">{t("map:map-icon-medium")}</Dropdown.Item>
                    <Dropdown.Item id="fa-2x">{t("map:map-icon-large")}</Dropdown.Item>
                </DropdownButton>
                <div className="mt-1">
                    {t("map:select-icon-size")}
                </div>
            </div>

            <div className="mt-2">
                <Button onClick={() => reloadAggregatedMap()} disabled={props.loading || props.loading1 || props.loading2} className="mt-1">
                    {t("map:load-parameters")}
                </Button>
                <div className="mt-1">
                    {t("map:update-icons-on-map")}
                </div>
            </div>
        </div>
    )

})

const mapStateToProps = state => ({
    loading: isPending(TAXONDB_SEARCH_MAP_DATA_REQUEST_ID, state),
    loading1: isPending('MAP_SEARCH_BY_SHAPE', state),
    loading2: isPending('MAP_SEARCH_BY_COLOR', state)
})

const mapDispatchToProps = dispatch => ({
    searchWithAggregation: (field, filter, items_per_page, request_id) => {
        return dispatch(postDataAnc(request_id,
            TAXONDB_SEARCH_WITH_AGGREGATION_REQUEST_URL, {
            filter: filter,
            aggregation_field: field,
            pagination: {perPage: items_per_page},
        },
        MAP_DATA_REQUEST_TIMEOUT
        ))
    },
    searchMapData: (data, source) => dispatch(postDataAnc(
        TAXONDB_SEARCH_MAP_DATA_REQUEST_ID,
        TAXONDB_SEARCH_MAP_DATA_REQUEST_URL,
        data,
        MAP_DATA_REQUEST_TIMEOUT,
        source)),
    getDetails: recordId => dispatch(actions.getDataAnc('popup', TAXONDB_DETAILS_REQUEST_URL + recordId + '/')),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(AggregatedTaxonsIcons)
