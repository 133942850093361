import React from 'react'
import { connect } from 'react-redux'
import { DropdownButton } from  "react-bootstrap"; 
import { useTranslation } from 'react-i18next'
import { setObservationsMarkerIcon, setObservationsMarkerColor, setObservationsMarkerSize } from '../../actions/map'
import useMarkerShapeColorSelector from '../../hooks/useMarkerShapeColorSelector';

const ObservationsStyleToolbar = (props) => {
    const { t } = useTranslation(['map', 'taxondb', 'taxondb-attributes'])
    const handleChangeColor = val => {
        props.setObservationsMarkerColor(val)
    }
    const handleChangeShape = val => {
        props.setObservationsMarkerIcon(val)
    }
    const handleChangeSize = val => {
        props.setObservationsMarkerSize(val)
    }
    const {DropdownShapeItems, DropdownColorItems, DropdownSizeItems} = useMarkerShapeColorSelector(handleChangeShape, handleChangeColor, handleChangeSize)

    return (
        <>
            <div className="mt-3">
                {t('map:observations-style')}
            </div>
            <DropdownButton
                title={t("map:marker-icon")} 
                disabled={props.loading}
                className="mt-3"
            >
                <DropdownShapeItems></DropdownShapeItems>
            </DropdownButton>
            <DropdownButton
                title={t("map:marker-color")}  
                disabled={props.loading}
                className="mt-3"
            >
                <DropdownColorItems></DropdownColorItems>
            </DropdownButton>
            <DropdownButton
                title={t("map:marker-size")}  
                disabled={props.loading}
                className="mt-3"
            >
                <DropdownSizeItems></DropdownSizeItems>
            </DropdownButton>
        </>
    )
}

const mapStateToProps = (state, ownProps) => ({

})

const mapDispatchToProps = dispatch => ({
    setObservationsMarkerIcon: icon => dispatch(setObservationsMarkerIcon( icon)),
    setObservationsMarkerColor: icon => dispatch(setObservationsMarkerColor(icon)),
    setObservationsMarkerSize: icon => dispatch(setObservationsMarkerSize(icon)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ObservationsStyleToolbar)
