import React, { Suspense, useState, useRef } from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { postDataAnc, requestRemoveStartsWith } from '../../actions'
import {
    TAXONDB_SEARCH_WITH_AGGREGATION_REQUEST_URL,
    AGGREGATION_REPORT_REQUEST_ID_PREFIX,
} from '../../settings'
import {
    BOTANIKAZOOLOGIA_FIELD_NAME,
    ROK_ZBIORU_FIELD_NAME,
    GENUS_ATTRIBUTE_NAME,
    RODZAJ_GATUNEK_ATTRIBUTE_NAME,
    SPECIES_ATTRIBUTE_NAME,
} from '../taxonDB/attributesStructure'
import { getItems, getFilter, isPending, getField } from "../../reducers";
import { Container, Spinner, Dropdown, NavDropdown, Button, Overlay, Popover } from "react-bootstrap"
import { setField } from "../../actions/forms";
import ReportList from "./ReportList";
import Loader from '../../utils/loader'

const MainContainer = props => {
    const { t } = useTranslation([ 'common', 'forms', 'taxondb-report', 'profile', 'taxondb-groups' ])

    const [ unmount, setUnmount ] = useState(false)
    const [showPopover, setShowPopover] = useState(false)
    const target = useRef()
    const ref = useRef()

    const showReport = () => {
        setUnmount(true)
        props.clearReport()
        props.searchWithAggregation({ ...props.formPanels[ 0 ], filter: props.filter })
            .finally(() => {
                setUnmount(false)
            })
    }

    const addPanel = id => {
        //props.clearReport()
        const temp = [ ...props.formPanels ]
        temp.push({
            aggregation_field: id,
            limit: "",
            skip_null: false,
            with_total: true,
            filter: props.filter,
        })
        props.setFormPanels(temp)
    }

    const removePanel = indx => {
        props.clearReport()
        const temp = props.formPanels.filter((val, i) => i !== indx)
        props.setFormPanels(temp)
    }

    const SingleMenuItem = props =>
        <>
            <Dropdown.Item onClick={e => {addPanel(props.id); setShowPopover(false)}}>{t('taxondb-attributes:' + props.id)}</Dropdown.Item>
        </>

    const onLimitChanged = (e, i) => {
        if (/^\d*$/.test(e.target.value)) {
            props.clearReport()
            const temp = [ ...props.formPanels ]
            temp[ i ].limit = e.target.value.length > 0 ? parseInt(e.target.value) : e.target.value
            props.setFormPanels(temp)
        }
    }

    const onSkipNullChanged = (e, i) => {
        props.clearReport()
        const temp = [ ...props.formPanels ]
        temp[ i ].skip_null = e.target.value === 'true' ? true : false
        props.setFormPanels(temp)
    }

    return (
        <Suspense fallback={<Loader />}>
            <div>
                <Container className="d-flex flex-column my-2">
                    {!props.loadingRecords && !props.recordsFound &&
                        <p><b>{t('use-form-to-search')}</b></p>
                    }
                    {props.loadingRecords &&
                        <div className="align-self-center">
                            <Spinner animation="border" role="status" />
                        </div>
                    }
                    {!props.loadingRecords && //props.recordsFound &&
                        <div className="d-flex flex-column">
                            {props.formPanels.map((val, indx) =>
                                <div key={indx} className="d-flex align-items-center justify-content-between p-2 report-table mb-2">
                                    <label className="flex-grow-1 mr-2"><b>{t('taxondb-attributes:' + val.aggregation_field)}</b></label>
                                    <div className="d-flex align-items-center mr-2">
                                        <label className="mr-1" htmlFor={"limit-" + indx}>{t('taxondb-report:limit_report_to')}:</label>
                                        <div>
                                            <input
                                                disabled={!props.recordsFound}
                                                className="form-control"
                                                style={{ width: "4rem" }}
                                                value={val.limit}
                                                type="text"
                                                id={"limit-" + indx}
                                                autoComplete="off"
                                                onChange={e => onLimitChanged(e, indx)}></input>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center mr-2">
                                        <label className="mr-1" htmlFor={"skip_null-" + indx}>{t('taxondb-report:skip_null_values')}:</label>
                                        <div>
                                            <select
                                                id={"skip_null-" + indx}
                                                disabled={props.disabled}
                                                onChange={e => onSkipNullChanged(e, indx)}
                                                value={val.skip_null}
                                                className='form-control'
                                            >
                                                <option value={false}>{t('common:false')}</option>
                                                <option value={true}>{t('common:true')}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div>
                                        <Button variant="danger" onClick={e => removePanel(indx)}>{t('forms:remove')}</Button>
                                    </div>
                                </div>
                            )}
                            <div className="d-flex flex-column justify-content-between flex-md-row">
                                <div className="d-flex align-items-center">
                                  <div ref={ref}>
                                      <Overlay 
                                          target={target.current} 
                                          show={showPopover} 
                                          container={ref.current} containerPadding={20} 
                                          rootClose={true} trigger="click" 
                                          flip placement="auto"
                                          onHide={e => setShowPopover(false)} >
                                      <Popover>
                                      <Popover.Content>
                                      <NavDropdown title={t('taxondb-groups:group-1')}>
                                          <SingleMenuItem {...props} id={GENUS_ATTRIBUTE_NAME}></SingleMenuItem>
                                          <SingleMenuItem {...props} id={SPECIES_ATTRIBUTE_NAME}></SingleMenuItem>
                                          <SingleMenuItem {...props} id={RODZAJ_GATUNEK_ATTRIBUTE_NAME}></SingleMenuItem>
                                          <SingleMenuItem {...props} id="jednostkanadrzedna"></SingleMenuItem>
                                          <SingleMenuItem {...props} id="autorzbioru"></SingleMenuItem>
                                          <SingleMenuItem {...props} id="dekada_zbioru"></SingleMenuItem>
                                          <SingleMenuItem {...props} id={ROK_ZBIORU_FIELD_NAME}></SingleMenuItem>
                                          <SingleMenuItem {...props} id="datazebraniaokazuproby"></SingleMenuItem>
                                          <SingleMenuItem {...props} id="rok_miesiac_zbioru"></SingleMenuItem>
                                          <SingleMenuItem {...props} id="miesiac_zbioru"></SingleMenuItem>
                                          <SingleMenuItem {...props} id="autorgatunku"></SingleMenuItem>
                                          <SingleMenuItem {...props} id="rangajednostkinadrzednej"></SingleMenuItem>
                                          <SingleMenuItem {...props} id="zrodlo"></SingleMenuItem>
                                          <SingleMenuItem {...props} id={BOTANIKAZOOLOGIA_FIELD_NAME}></SingleMenuItem>
                                      </NavDropdown>
                                      <NavDropdown title={t('taxondb-groups:group-2')}>
                                          <SingleMenuItem {...props} id="panstwo"></SingleMenuItem>
                                          <SingleMenuItem {...props} id="georeferencjakomentarze"></SingleMenuItem>
                                          <SingleMenuItem {...props} id="gmina"></SingleMenuItem>
                                          <SingleMenuItem {...props} id="koditypobszarunatura2000"></SingleMenuItem>
                                          <SingleMenuItem {...props} id="kontynent"></SingleMenuItem>
                                          <SingleMenuItem {...props} id="nazwaobszarunatura2000"></SingleMenuItem>
                                          <SingleMenuItem {...props} id="numerzbioruokreslonegoautora"></SingleMenuItem>
                                          <SingleMenuItem {...props} id="obszarchronionegokrajobrazu"></SingleMenuItem>
                                          <SingleMenuItem {...props} id="obszarchroniony"></SingleMenuItem>
                                          <SingleMenuItem {...props} id="parkkrajobrazowy"></SingleMenuItem>
                                          <SingleMenuItem {...props} id="parknarodowy"></SingleMenuItem>
                                          <SingleMenuItem {...props} id="polozeniewpodzialebiogeograficznymeuropy"></SingleMenuItem>
                                          <SingleMenuItem {...props} id="polozeniewpodzialefizjograficznym"></SingleMenuItem>
                                          <SingleMenuItem {...props} id="polozeniewpodzialegeograficznympotencjalnejroslinnoscinaturalne"></SingleMenuItem>
                                          <SingleMenuItem {...props} id="powiat"></SingleMenuItem>
                                          <SingleMenuItem {...props} id="rezerwatprzyrody"></SingleMenuItem>
                                          <SingleMenuItem {...props} id="rodzajityprezerwatu"></SingleMenuItem>
                                          <SingleMenuItem {...props} id="siedlisko"></SingleMenuItem>
                                          <SingleMenuItem {...props} id="uzytekekologiczny"></SingleMenuItem>
                                          <SingleMenuItem {...props} id="wojewodztwo"></SingleMenuItem>
                                          <SingleMenuItem {...props} id="zespolprzyrodniczokrajobrazowy"></SingleMenuItem>
                                      </NavDropdown>
                                      <NavDropdown title={t('taxondb-groups:group-3')}>
                                          <SingleMenuItem {...props} id="instytucja"></SingleMenuItem>
                                          <SingleMenuItem {...props} id="opisuwagi"></SingleMenuItem>
                                          <SingleMenuItem {...props} id="pochodzenieokazu"></SingleMenuItem>
                                      </NavDropdown>
                                      <NavDropdown title={t('taxondb-groups:group-4')}>
                                          <SingleMenuItem {...props} id="bibliografia"></SingleMenuItem>
                                      </NavDropdown>
                                  </Popover.Content>
                                  </Popover>
                                  </Overlay>
                                  <Button className="dropdown-toggle" ref={target} onClick={e => setShowPopover(!showPopover)} disabled={props.loadingRecords}>
                                      {t('taxondb-report:add_aggregation_parameter')}
                                  </Button>
                                  </div>
                                </div>
                                <div>
                                    <Button
                                        className="my-2 my-md-0"
                                        disabled={props.loadingRecords || !props.recordsFound || !props.formPanels.length}
                                        onClick={e => showReport()}
                                    >
                                        {t('taxondb-report:show-report')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    }
                    {unmount &&
                        <div className="align-self-center p-5">
                            <Spinner animation="border" role="status" />
                        </div>
                    }
                    {!unmount &&
                        <ReportList
                            indx={0}
                            requestId={props.requestId}
                            path={'0'}
                        ></ReportList>
                    }
                </Container>
            </div>
        </Suspense>
    )
}

const mapStateToProps = (state, ownProps) => ({
    filter: getFilter(ownProps.requestId, state),
    recordsFound: getItems(ownProps.requestId, state),
    loadingRecords: isPending(ownProps.genusRequestId, state) || isPending(ownProps.speciesRequestId, state) || isPending(ownProps.requestId, state),
    formPanels: getField(
        AGGREGATION_REPORT_REQUEST_ID_PREFIX + ownProps.requestId + '-form',
        'panels', state, ownProps.initialPanels || []),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    searchWithAggregation: obj => {
        const data = { ...obj }
        if (data.limit > 0) {
            data.pagination = { perPage: parseInt(obj.limit) }
        }
        delete data.limit
        const requestId = AGGREGATION_REPORT_REQUEST_ID_PREFIX + ownProps.requestId + '-search-0-0';
        dispatch(setField(requestId, 'aggregation-filter', data.filter));
        return dispatch(postDataAnc(requestId,
            TAXONDB_SEARCH_WITH_AGGREGATION_REQUEST_URL, data))
    },
    setFormPanels: data => dispatch(setField(AGGREGATION_REPORT_REQUEST_ID_PREFIX + ownProps.requestId + '-form',
        'panels', data)),
    clearReport: () => dispatch(requestRemoveStartsWith(AGGREGATION_REPORT_REQUEST_ID_PREFIX + ownProps.requestId)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MainContainer)
