import React, { useState, Suspense } from "react";
import { connect } from 'react-redux'
import { isPending, getOutput, getField, getRequest } from "../../reducers";
import { useTranslation } from 'react-i18next'
import { ListGroup, Spinner, Collapse } from "react-bootstrap"
import {
    USER_GROUPS_EDITOR_CONST,
    TAXONDB_GET_COLLECTIONS_PREFIX,
    COLLECTION_GENUS_RESULTS_REQUEST_PREFIX,
    COLLECTION_SPECIES_RESULTS_REQUEST_PREFIX,
    COLLECTION_ICONS_RESULTS_REQUEST_PREFIX,
    COLLECTION_RESULTS_REQUEST_PREFIX,
    COLLECTION_RESULTS_TAB_REQUEST_PREFIX,
    ICONDB_SEARCH_REQUEST_URL,
} from '../../settings'
import { requestRemove, postDataDlibra, postData } from '../../actions'
import { setField, runSearchFlow } from '../../actions/forms'
import SimpleResultsContainer from '../taxonDB/ResultsContainer'
import ResultsContainer from '../iconDB/ResultsContainer'
import EditCollection from './EditCollection'
import Loader from '../../utils/loader'
import ListGroupItemDiv from "../ListGroupItemDiv";

const CollectionSubtypeItems = props => {
    const { t, i18n } = useTranslation([ 'a11y' ])
    const [ editOpen, setEditOpen ] = useState({})

    const toggleOpen = (requestId, type, val) => {
        const temp = { ...props.open }
        temp[ requestId ] = !temp[ requestId ]
        if (type === 'collection' && temp[ requestId ]) {
            if (val.liczba_okazow > 0) {
                props.setOpen(temp)
                props.setSearchFilterAndGo(val.kolekcja)
            } else if (val.liczba_zdjec > 0) {
                props.setOpen(temp)
                props.setIconSearchFilterAndGo(val.kolekcja)
            }
        } else {
            props.setOpen(temp)
            if (type === 'subtype' && temp[ requestId ]) {
                props.getCollectionsForSubType(requestId)
            }
        }

        if (!temp[ requestId ]) {
            setEditOpen({})
        }
    }

    const toggleEditOpen = (e, collectionId) => {
        let temp = { ...editOpen }
        temp[ collectionId ] = !temp[ collectionId ]
        setEditOpen(temp)
        e.stopPropagation()
    }

    const onEditDone = () => {
        props.reloadCollectionSubtypeItems(props.state)
        setEditOpen({})
    }

    const getTitle = val => {
        return t('collections:specimens-images-no', {
            specimens: val.liczba_okazow.toLocaleString(i18n.languages[0]),
            photos: val.liczba_zdjec.toLocaleString(i18n.languages[0])
        })
    }

    return (
        <>
            {props.loading &&
                <div className="d-flex flex-column">
                    <div className="align-self-center m-3">
                        <Spinner animation="border" role="status" />
                    </div>
                </div>
            }
            {!props.loading &&
                <ListGroup className="ml-4">
                    {props.items && props.items.map((val, indx) =>
                        <div key={indx}>
                            <ListGroupItemDiv className="d-flex flex-row album-element ml-4 align-items-center" onClick={() => toggleOpen('collection-' + val.kolekcja, 'collection', val)}>
                                {(val.liczba_okazow > 0 || val.liczba_zdjec > 0) &&
                                    <div>
                                        {props.open[ 'collection-' + val.kolekcja ] &&
                                            <img src="/images/list-open.svg" alt={t("a11y:list-opened")} />
                                        }
                                        {!props.open[ 'collection-' + val.kolekcja ] &&
                                            <img src="/images/list-closed.svg" alt={t("a11y:list-closed")} />
                                        }
                                    </div>
                                }
                                {!(val.liczba_okazow > 0) && !(val.liczba_zdjec > 0) &&
                                    <div>
                                        <img src="/images/list-empty.svg" alt={t("a11y:list-opened")} />
                                    </div>
                                }
                                <div className="flex-grow-1"
                                    aria-controls={'collection-' + val.kolekcja}
                                    aria-expanded={props.open[ 'collection-' + val.kolekcja ]}>
                                    <span className="mr-2">
                                        <b>{val.kolekcja}</b>: {i18n.languages[0] === 'en' ? val.opis_eng : i18n.languages[0] === 'pl' ? val.opis_pl : ''}
                                    </span>
                                    <strong className="ml-2" title={getTitle(val)}>{val.liczba_okazow.toLocaleString(i18n.languages[0])} / {val.liczba_zdjec.toLocaleString(i18n.languages[0])}</strong>
                                </div>
                                {props.user.data.groups.includes(USER_GROUPS_EDITOR_CONST) &&
                                    <div>
                                        <button className="btn btn-primary" tabIndex="0" size="sm" onClick={(e) => toggleEditOpen(e, val.kolekcja)} style={{minWidth: '8rem'}}>{t('collections:edit-collection')}</button>
                                    </div>
                                }
                            </ListGroupItemDiv>
                            {editOpen[ val.kolekcja ] && <EditCollection {...props} collection={val} onDone={onEditDone} />}
                            <Collapse in={props.open[ 'collection-' + val.kolekcja ]} id={'collection-' + val.kolekcja}>
                                <div>
                                    <Suspense fallback={<Loader />}>
                                        {props.open[ 'collection-' + val.kolekcja ] && (val.liczba_okazow > 0) &&
                                            <div className="ml-4">
                                                <SimpleResultsContainer
                                                    genusRequestId={COLLECTION_GENUS_RESULTS_REQUEST_PREFIX + val.kolekcja}
                                                    speciesRequestId={COLLECTION_SPECIES_RESULTS_REQUEST_PREFIX + val.kolekcja}
                                                    iconsRequestId={COLLECTION_ICONS_RESULTS_REQUEST_PREFIX + val.kolekcja}
                                                    searchRequestId={COLLECTION_RESULTS_REQUEST_PREFIX + val.kolekcja}
                                                    tabId={COLLECTION_RESULTS_TAB_REQUEST_PREFIX + val.kolekcja}
                                                ></SimpleResultsContainer>
                                            </div>
                                        }
                                        {props.open[ 'collection-' + val.kolekcja ] && !(val.liczba_okazow > 0) && (val.liczba_zdjec > 0) &&
                                            <div className="ml-4">
                                                <ResultsContainer
                                                    searchRequestId={COLLECTION_ICONS_RESULTS_REQUEST_PREFIX + val.kolekcja}
                                                ></ResultsContainer>
                                            </div>
                                        }
                                    </Suspense>
                                </div>
                            </Collapse>
                        </div>
                    )}
                    {props.items && !props.items.length &&
                        <div className="m-4">
                            {t('common:no-collections')}
                        </div>
                    }
                </ListGroup>
            }
        </>
    )
}

const mapStateToProps = (state, ownProps) => ({
    user: state.user,
    loading: isPending(TAXONDB_GET_COLLECTIONS_PREFIX + 'sub-' + ownProps.typeId, state),
    items: getOutput(TAXONDB_GET_COLLECTIONS_PREFIX + 'sub-' + ownProps.typeId, state),
    open: getField('CollectionSubTypeItems', 'open', state, {}),
    state: state
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    reloadCollectionSubtypeItems: (state) => {
        const itemsRequest = getRequest(TAXONDB_GET_COLLECTIONS_PREFIX + 'sub-' + ownProps.typeId, state)
        dispatch(postData(TAXONDB_GET_COLLECTIONS_PREFIX + 'sub-' + ownProps.typeId, itemsRequest.url, itemsRequest.data))
    },
    setOpen: value => dispatch(setField('CollectionSubTypeItems', 'open', value)),
    setSearchFilterAndGo: id => {
        dispatch(requestRemove(COLLECTION_GENUS_RESULTS_REQUEST_PREFIX + id))
        dispatch(requestRemove(COLLECTION_SPECIES_RESULTS_REQUEST_PREFIX + id))
        dispatch(requestRemove(COLLECTION_RESULTS_REQUEST_PREFIX + id))
        dispatch(setField(COLLECTION_GENUS_RESULTS_REQUEST_PREFIX + id, "kolekcja", id))
        dispatch(runSearchFlow(
            COLLECTION_GENUS_RESULTS_REQUEST_PREFIX + id,
            COLLECTION_SPECIES_RESULTS_REQUEST_PREFIX + id,
            COLLECTION_RESULTS_REQUEST_PREFIX + id,
            COLLECTION_RESULTS_TAB_REQUEST_PREFIX + id))
    },
    setIconSearchFilterAndGo: id => {
        dispatch(requestRemove(COLLECTION_ICONS_RESULTS_REQUEST_PREFIX + id))
        dispatch(postDataDlibra(COLLECTION_ICONS_RESULTS_REQUEST_PREFIX + id, ICONDB_SEARCH_REQUEST_URL, { filter: { "kolekcja": id } }))
    },
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CollectionSubtypeItems)
