import 'leaflet-zoombox/L.Control.ZoomBox'
import 'leaflet.zoomslider/src/L.Control.Zoomslider'
import 'leaflet.zoomslider/src/L.Control.Zoomslider.css'
import 'leaflet-draw'
import 'leaflet-draw/dist/leaflet.draw.css'
import { configureBasemaps } from '../../utils/basemaps'
import {
    mapAttributionString,
} from '../../settings'

export default function setupMapControlls(t, L){

    let zoomslider = new L.Control.Zoomslider();

    let zoomOptions = {
        modal: false,
        title: t('map:area-zoom'),
        className: "custom-content",
        content: '<i class="fa fa-search"></i>'
    };
    let zoomBoxControl = L.control.zoomBox(zoomOptions);

    let scale = L.control.scale({
        maxWidth: 150,
        metric: true,
        imperial: false,
    })

    let attributionString = L.control.attribution({prefix: mapAttributionString, position: "bottomleft"})

    var mapOverlaysConfiguration = configureBasemaps(L)
    let baseMaps = L.control.layers(mapOverlaysConfiguration.baseMaps, mapOverlaysConfiguration.overlayMaps, {position: 'bottomright'})

    return {
        zoomSlider: zoomslider,
        zoomGlass: zoomBoxControl,
        scale: scale,
        attributionString: attributionString,
        baseMaps: baseMaps,
    }

}
