import React from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { requestRemove, postDataDlibra, requestRemoveStartsWith } from '../../actions'
import { setFilter } from '../../actions'
import { setFields, setField } from '../../actions/forms'
import { isPending, getPagination, getFields, prepareFilter } from "../../reducers"
import {
    ICONDB_SEARCH_REQUEST_ID,
    ICONDB_SEARCH_REQUEST_URL,
    DLIBRA_URL_POSTFIX,
    ICONDB_DETAILS_REQUEST_ID_PREFIX,
    ICON_RESULTS_ACTIVE_RECORD,
    ICONDB_SUGGEST_REQUEST_PREFIX,
} from '../../settings'
import {
    RODZAJ_GATUNEK_ATTRIBUTE_NAME,
    DLIBRA_RODZAJ_GATUNEK_ATTRIBUTE_NAME,
} from '../taxonDB/attributesStructure'
import InputWithAsyncTypeahead from "../InputWithAsyncTypeahead"
import InputWithTypeahead from "../InputWithTypeahead"
import { Form, Container, Row, Col, Button } from "react-bootstrap"

const SearchForm = props => {
    const { t } = useTranslation([ 'common', 'taxondb', 'taxondb-attributes', 'icondb-attributes' ])

    const submitForm = e => {
        e.preventDefault()
        props.setOpen(null)

        // remove previous item details to get updated version from server on demand
        props.removeDetails(ICONDB_DETAILS_REQUEST_ID_PREFIX)

        props.saveFilter(props.prepareFilter)
        const newPagination = { ...props.pagination }
        newPagination.currentPage = 1
        props.search({
            filter: props.prepareFilter,
            pagination: newPagination,
        })
    }

    const handleOnKeyUp = e => {
        if (e.keyCode === 13) { // ENTER
            submitForm(e)
        }
    }

    const clearForm = () => {
        props.setFields({})
        props.clearResponse()
    }

    return (
        <Form onSubmit={e => { e.preventDefault() }}>
            <Container>
                <Row>
                    <Form.Group as={Col} sm={6} md={4}>
                        <Form.Label htmlFor="Title">{t('icondb-attributes:Title')}
                        </Form.Label>
                        <InputWithAsyncTypeahead
                            postfix={DLIBRA_URL_POSTFIX}
                            url={ICONDB_SUGGEST_REQUEST_PREFIX + "Title/"}
                            id="Title"
                            disabled={props.loading}
                            formId={ICONDB_SEARCH_REQUEST_ID}
                            onKeyUp={handleOnKeyUp}
                            onChange={e => props.clearResponse()}></InputWithAsyncTypeahead>
                    </Form.Group>
                    <Form.Group as={Col} sm={6} md={4}>
                        <Form.Label htmlFor="keyword">{t('icondb-attributes:keyword')}
                        </Form.Label>
                        <InputWithAsyncTypeahead
                            postfix={DLIBRA_URL_POSTFIX}
                            url={ICONDB_SUGGEST_REQUEST_PREFIX + "keyword/"}
                            id="keyword"
                            disabled={props.loading}
                            formId={ICONDB_SEARCH_REQUEST_ID}
                            onKeyUp={handleOnKeyUp}
                            onChange={e => props.clearResponse()}></InputWithAsyncTypeahead>
                    </Form.Group>
                    <Form.Group as={Col} sm={6} md={4}>
                        <Form.Label htmlFor="Country">{t('icondb-attributes:Country')}
                        </Form.Label>
                        <InputWithTypeahead
                            postfix={DLIBRA_URL_POSTFIX}
                            url={ICONDB_SUGGEST_REQUEST_PREFIX + "Country/"}
                            id="Country"
                            disabled={props.loading}
                            formId={ICONDB_SEARCH_REQUEST_ID}
                            onKeyUp={handleOnKeyUp}
                            onChange={e => props.clearResponse()}></InputWithTypeahead>
                    </Form.Group>
                    <Form.Group as={Col} sm={6} md={4}>
                        <Form.Label htmlFor="DateOfInformationPreservation">{t('icondb-attributes:DateOfInformationPreservation')}
                        </Form.Label>
                        <InputWithAsyncTypeahead
                            postfix={DLIBRA_URL_POSTFIX}
                            url={ICONDB_SUGGEST_REQUEST_PREFIX + "DateOfInformationPreservation/"}
                            id="DateOfInformationPreservation"
                            disabled={props.loading}
                            formId={ICONDB_SEARCH_REQUEST_ID}
                            onKeyUp={handleOnKeyUp}
                            onChange={e => props.clearResponse()}></InputWithAsyncTypeahead>
                    </Form.Group>
                    <Form.Group as={Col} sm={6} md={4}>
                        <Form.Label htmlFor="FullAuthorName">{t('icondb-attributes:FullAuthorName')}
                        </Form.Label>
                        <InputWithAsyncTypeahead
                            postfix={DLIBRA_URL_POSTFIX}
                            url={ICONDB_SUGGEST_REQUEST_PREFIX + "FullAuthorName/"}
                            id="FullAuthorName"
                            disabled={props.loading}
                            formId={ICONDB_SEARCH_REQUEST_ID}
                            onKeyUp={handleOnKeyUp}
                            onChange={e => props.clearResponse()}></InputWithAsyncTypeahead>
                    </Form.Group>
                    <Form.Group as={Col} sm={6} md={4}>
                        <Form.Label htmlFor="protected_area">{t('icondb-attributes:protected_area')}</Form.Label>
                        <InputWithAsyncTypeahead
                            postfix={DLIBRA_URL_POSTFIX}
                            url={ICONDB_SUGGEST_REQUEST_PREFIX + "protected_area/"}
                            id="protected_area"
                            disabled={props.loading}
                            formId={ICONDB_SEARCH_REQUEST_ID}
                            onKeyUp={handleOnKeyUp}
                            onChange={e => props.clearResponse()}></InputWithAsyncTypeahead>
                    </Form.Group>
                    <Form.Group as={Col} sm={6} md={4}>
                        <Form.Label htmlFor={DLIBRA_RODZAJ_GATUNEK_ATTRIBUTE_NAME}>{t('taxondb-attributes:' + RODZAJ_GATUNEK_ATTRIBUTE_NAME)}
                        </Form.Label>
                        <InputWithAsyncTypeahead
                            postfix={DLIBRA_URL_POSTFIX}
                            url={ICONDB_SUGGEST_REQUEST_PREFIX + DLIBRA_RODZAJ_GATUNEK_ATTRIBUTE_NAME + "/"}
                            id={DLIBRA_RODZAJ_GATUNEK_ATTRIBUTE_NAME}
                            disabled={props.loading}
                            formId={ICONDB_SEARCH_REQUEST_ID}
                            onKeyUp={handleOnKeyUp}
                            onChange={e => props.clearResponse()}></InputWithAsyncTypeahead>
                    </Form.Group>
                    <Form.Group as={Col} sm={6} md={4}>
                        <Form.Label htmlFor="external_id">{t('icondb-attributes:CollectionSpecimenNumber')}
                        </Form.Label>
                        <InputWithAsyncTypeahead
                            postfix={DLIBRA_URL_POSTFIX}
                            url={ICONDB_SUGGEST_REQUEST_PREFIX + "external_id/"}
                            autoFocus={true} id="external_id"
                            disabled={props.loading}
                            formId={ICONDB_SEARCH_REQUEST_ID}
                            onKeyUp={handleOnKeyUp}
                            onChange={e => props.clearResponse()}></InputWithAsyncTypeahead>
                    </Form.Group>
                </Row>
                <Row><Col>{t('taxondb:form-wildcard-info')}</Col></Row>
                <div className="d-flex justify-content-center my-3">
                    <Button variant="primary" className="search-button" onClick={submitForm} disabled={props.loading || Object.keys(props.prepareFilter).length === 0}>
                        {t('search')}
                    </Button>
                    <Button variant="secondary" className="ml-2 clear-button" onClick={clearForm} disabled={props.loading} >
                        {t('clear')}
                    </Button>
                </div>
            </Container>
        </Form >
    )
}

const mapStateToProps = state => ({
    loading: isPending(ICONDB_SEARCH_REQUEST_ID, state),
    fields: getFields(ICONDB_SEARCH_REQUEST_ID, state),
    pagination: getPagination(ICONDB_SEARCH_REQUEST_ID, state),
    prepareFilter: prepareFilter(ICONDB_SEARCH_REQUEST_ID, state),
})

const mapDispatchToProps = dispatch => ({
    search: data => dispatch(postDataDlibra(ICONDB_SEARCH_REQUEST_ID, ICONDB_SEARCH_REQUEST_URL, data)),
    saveFilter: filter => dispatch(setFilter(ICONDB_SEARCH_REQUEST_ID, filter)),
    removeDetails: prefix => dispatch(requestRemoveStartsWith(prefix)),
    setFields: fields => dispatch(setFields(ICONDB_SEARCH_REQUEST_ID, fields)),
    setSelectedValue: (id, value) => dispatch(setField(ICONDB_SEARCH_REQUEST_ID, id, value)),
    setOpen: value => dispatch(setField(ICON_RESULTS_ACTIVE_RECORD, ICON_RESULTS_ACTIVE_RECORD, value)),
    clearResponse: () => dispatch(requestRemove(ICONDB_SEARCH_REQUEST_ID)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchForm)
