import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { isPending } from "../../reducers"
import { postDataApi } from '../../actions'
import { Form, Button } from 'react-bootstrap'
import {
    PROJECTS_ADD_OBSERVATION_ID,
    PROJECTS_ADD_OBSERVATION_URL,
    PROJECTS_GET_OBSERVATIONS_ID,
    PROJECTS_GET_OBSERVATIONS_URL
} from '../../settings'
import * as notify from '../../utils/notify'

const CreateObservation = props => {
    const { t } = useTranslation(['projects', 'forms'])

    const [name, setName] = useState('')

    const onNameChanged = e => {
        setName(e.target.value)
    }

    const onSubmitForm = () => {
        if (!name || !name.trim()) {
            return
        }
        
        props.createObservation(name, {}, t).then(() => {
            setName('')
            props.onDone && props.onDone()
        })
    }

    return (
        <Form className="mt-2 common-panel-background p-2" onSubmit={e => { e.preventDefault() }}>
            <Form.Group>
                <Form.Label htmlFor="name">{t('projects:observation-name')}</Form.Label>
                <Form.Control id="name" size="sm" value={name} onChange={onNameChanged} disabled={props.querying}></Form.Control>
            </Form.Group>
            <div className="text-right">
                <Button size="sm" onClick={onSubmitForm} disabled={props.querying}>
                    {t('forms:create')}
                </Button>
            </div>
        </Form>
    )
}

const mapStateToProps = state => ({
    querying: isPending(PROJECTS_ADD_OBSERVATION_ID, state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    createObservation: (name, fields, t) => {
        return dispatch(postDataApi(PROJECTS_ADD_OBSERVATION_ID, PROJECTS_ADD_OBSERVATION_URL, {
            project_id: ownProps.projectId,
            name: name,
            fields: fields
        })).then(() => {
            notify.success(t('projects:created-observation'))
            ownProps.onDone && ownProps.onDone()
            return dispatch(postDataApi(PROJECTS_GET_OBSERVATIONS_ID + ownProps.projectId, `${PROJECTS_GET_OBSERVATIONS_URL}${ownProps.projectId}/`))
        }).catch(res => {
            notify.error(t('projects:cannot-create-observation'), res.result)
        })
    }
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateObservation)