import L from 'leaflet';
import circleToPolygon from 'circle-to-polygon';
import {
    USER_DRAWINGS_POINTS_CONST,
    USER_DRAWINGS_LINES_CONST, 
    USER_DRAWINGS_POLYGONS_CONST,
} from '../../settings'

export default function drawingHandle(e, polygonStyle, lineStyle, markerColor, markerIcon, userDrawingsRef){
    if (e.layerType === 'circle') {
        const coordinates = [e.layer._latlng.lng, e.layer._latlng.lat]; 
        const radius = e.layer._mRadius;
        const numberOfEdges = 180;

        if (e.layer._latlng.lng <= 180 && 
            e.layer._latlng.lng >= -180 &&
            e.layer._latlng.lat <= 90 &&
            e.layer._latlng.lat >= -90){
                try{
                    let polygon = circleToPolygon(coordinates, radius, numberOfEdges);
                    let circle = L.geoJSON(polygon)
                    for (const property in circle._layers) {
                        circle._layers[property].name = USER_DRAWINGS_POLYGONS_CONST
                        if (polygonStyle && polygonStyle.color) {circle._layers[property].options.color = polygonStyle.color}
                        if (polygonStyle && polygonStyle.fillColor) {circle._layers[property].options.fillColor = polygonStyle.fillColor}
                        if (polygonStyle && polygonStyle.stroke) {circle._layers[property].options.stroke = polygonStyle.stroke}
                        if (polygonStyle && polygonStyle.opacity) {circle._layers[property].options.opacity =polygonStyle.opacity}
                        if (polygonStyle && polygonStyle.weight) {circle._layers[property].options.weight = polygonStyle.weight}
                        if (polygonStyle && polygonStyle.fill) {circle._layers[property].options.fill = polygonStyle.fill}
                        if (polygonStyle && polygonStyle.fillOpacity) {circle._layers[property].options.fillOpacity = polygonStyle.fillOpacity}
                        circle._layers[property].addTo(userDrawingsRef);
                    }
                }
                catch (e){
                    console.log(e)
                }
            }                    
    }
    else if (e.layerType === 'marker'){
        e.layer.name = USER_DRAWINGS_POINTS_CONST
        let style = {
            color: markerColor ? markerColor : 'blue',
            markerShape: markerIcon ? markerIcon : 'fa fa-square fa-3x'
        }
        e.layer.style = style
        userDrawingsRef.addLayer(e.layer)
    }
    else if (e.layerType === 'polyline'){
        e.layer.name = USER_DRAWINGS_LINES_CONST
        e.layer.style = lineStyle
        userDrawingsRef.addLayer(e.layer)
    }
    else if (e.layerType === 'polygon' || e.layerType === 'rectangle'){
        e.layer.name = USER_DRAWINGS_POLYGONS_CONST
        e.layer.style = polygonStyle
        userDrawingsRef.addLayer(e.layer)
    }
}