import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormLabel, Form, Spinner } from "react-bootstrap"
import { Button } from "react-bootstrap"
import * as notify from '../../../utils/notify'
import useApi from '../../../hooks/useApi'
import { URL_API_MAP_CHANGE_NAME } from '../../../urls'

const ChangeMapName = props => {
    const { t } = useTranslation(['map'])
    const [ name, setName ] = useState(props.mapName)
    const { refetch, loading } = useApi('post', URL_API_MAP_CHANGE_NAME);
  
    const changeMapName = () => {
      refetch({
        map_id: props.mapId,
        new_name: name,
      })
        .then(res => {
          notify.success(t('map:map-name-changed'))
          props.onDone && props.onDone(props.mapId, name)
        })
        .catch(err => {
          if (err?.response?.status === 409) {
            notify.error(t('map:map-name-exists'))
          }
          else {
            notify.error(t('map:cannot-change-map-name'))
          }
        })
    }
  
    return (
      <div className="mt-2 common-panel-background p-2">
        <Form.Group controlId='new-map-name'>
          <FormLabel>{t('map:new-map-name')}</FormLabel>
          <Form.Control
            value={name}
            onChange={e => setName(e.target.value)}
            disabled={loading}
          ></Form.Control>
        </Form.Group>
        <Form.Group className="text-right">
          <Button
            onClick={changeMapName}
            disabled={loading || !name}
          >{t('map:change-map-name')}
            {loading &&
                <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="ml-1"
                />
            }
          </Button>
        </Form.Group>
      </div>
    )
}
export default ChangeMapName
