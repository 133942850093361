import React from "react"
import { Spinner, Container } from "react-bootstrap";
import {
    TAXONDB_SEARCH_GENUS_REQUEST_ID,
    TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID,
    TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID,
} from "../../../../settings"
import L from 'leaflet';
import "leaflet-pixi-overlay" 
import * as PIXI from 'pixi.js'
import ReactDOMServer from 'react-dom/server';
import MapPopupContent from './../../MapPopupContent'
import { popupWithLabels } from "../popupWithLabels";

export const arrangementMapSearch = (ref, props, t, i18n) => {
    
    const { collectionTypes, getDetails, isDynamic, newRunSearchMap, filter1, filter2, filter3, langi18n, closeSlidebar, pixiMarkerColor, pixiMarkerSize, pixiMarkerShape, mapLabels } = { ...props, lang: t, langi18n: i18n }

    const prepareNewPixiContainer = () => {
        try{
            ref.pixiContainer.current.destroy()
            ref.mapRef.current.removeLayer(ref.pixiOverlayRef.current)
        }
        catch(e){
            // not created yet
        }
        return new PIXI.Container();
    }
    
    const loadIcons = loader => {
        loader.add('star_solid', '/images/font-awesome/star-solid.png');
        loader.add('star_regular', '/images/font-awesome/star-regular.png');
        loader.add('square_solid', '/images/font-awesome/square-solid.png');
        loader.add('square_regular', '/images/font-awesome/square-regular.png');
        loader.add('times_solid', '/images/font-awesome/times-solid.png');
        loader.add('thumbtack_solid', '/images/font-awesome/thumbtack-solid.png');
        loader.add('map_marker', '/images/font-awesome/map-marker-solid.png');
        loader.add('map_pin', '/images/font-awesome/map-pin-solid.png');
        loader.add('circle_regular', '/images/font-awesome/circle-regular.png');
        loader.add('circle_solid', '/images/font-awesome/circle-solid.png');
        loader.add('cube_solid', '/images/font-awesome/cube-solid.png');
        loader.add('bookmark_solid', '/images/font-awesome/bookmark-solid.png');
        loader.add('asterisk_solid', '/images/font-awesome/asterisk-solid.png');
        loader.add('plus_solid', '/images/font-awesome/plus-solid.png');
    }
    
    /* Anchor values in Pixi.js
        (0,0) - left top
        (1,1) - right bottom
        (0.5,0.5) - center
    */
    const getIconTexture = (resources, textureName) => {
        let markerTexture, marker;
        switch(textureName){
            case 'star_regular':
                markerTexture = resources.star_regular.texture
                marker = new PIXI.Sprite(markerTexture);
                marker.anchor.set(0.5, 0.5);
                break
            case 'star_solid':
                markerTexture = resources.star_solid.texture
                marker = new PIXI.Sprite(markerTexture);
                marker.anchor.set(0.5, 0.5);
                break
            case 'square_solid':
                markerTexture = resources.square_solid.texture
                marker = new PIXI.Sprite(markerTexture);
                marker.anchor.set(0.5, 0.5);
                break
            case 'square_regular': 
                markerTexture = resources.square_regular.texture
                marker = new PIXI.Sprite(markerTexture)
                marker.anchor.set(0.5, 0.5);
                break
            case 'times_solid':
                markerTexture = resources.times_solid.texture
                marker = new PIXI.Sprite(markerTexture);
                marker.anchor.set(0.5, 0.5);
                break
            case 'thumbtack_solid':
                markerTexture = resources.thumbtack_solid.texture
                marker = new PIXI.Sprite(markerTexture);
                marker.anchor.set(0.5, 1);
                break
            case 'map_marker':
                markerTexture = resources.map_marker.texture
                marker = new PIXI.Sprite(markerTexture);
                marker.anchor.set(0.5, 1);
                break
            case 'map_pin':
                markerTexture = resources.map_pin.texture
                marker = new PIXI.Sprite(markerTexture);
                marker.anchor.set(0.5, 1);
                break
            case 'circle_solid':
                markerTexture = resources.circle_solid.texture
                marker = new PIXI.Sprite(markerTexture)
                marker.anchor.set(0.5, 0.5);
                break
            case 'circle_regular':
                markerTexture = resources.circle_regular.texture
                marker = new PIXI.Sprite(markerTexture)
                marker.anchor.set(0.5, 0.5);
                break
            case 'cube_solid':
                markerTexture = resources.cube_solid.texture
                marker = new PIXI.Sprite(markerTexture)
                marker.anchor.set(0.5, 0.5);
                break
            case 'bookmark_solid':
                markerTexture = resources.bookmark_solid.texture
                marker = new PIXI.Sprite(markerTexture)
                marker.anchor.set(0.5, 1);
                break
            case 'asterisk_solid':
                markerTexture = resources.asterisk_solid.texture
                marker = new PIXI.Sprite(markerTexture)
                marker.anchor.set(0.5, 0.5);
                break
            case 'plus_solid':
                markerTexture = resources.plus_solid.texture
                marker = new PIXI.Sprite(markerTexture)
                marker.anchor.set(0.5, 0.5);
                break
            default:
                markerTexture = resources.map_marker.texture
                marker = new PIXI.Sprite(markerTexture);
                marker.anchor.set(0.5, 1);
                break
        }
        return marker
    }
    
    const resizeIcon = (scale) => {
        return 1/(scale*pixiMarkerSize)
    }
    const getFilterId = () => {
        let id
        if (isDynamic === "0"){
            id = TAXONDB_SEARCH_GENUS_REQUEST_ID
        }
        if (isDynamic === "2"){
            id = TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID
        }
        if (isDynamic === "1"){
            id = TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID
        }
        return id
    }

    const getFilter = () => {
        let id
        if (isDynamic === "0"){
            id = filter1
        }
        if (isDynamic === "2"){
            id = filter2
        }
        if (isDynamic === "1"){
            id = filter3
        }
        return id
    }

    const addLoadingBlurToMap = (mapLoadingBlur) => {
        try{
            mapLoadingBlur.addTo(ref.mapRef.current);
        }
        catch(e){
            console.log(e)
        }
        
    }

    const removeLoadingBlurFromMap = (mapLoadingBlur) => {
        try {
            mapLoadingBlur.hide()
        }
        catch(e){
            console.log(e)
        }
    }

    var loader = new PIXI.loaders.Loader();
    loadIcons(loader)
    ref.pixiContainer.current = prepareNewPixiContainer()
    
    const filterId = getFilterId()
    const filter = getFilter()
    
    closeSlidebar()

    if (filter && Object.keys(filter).length > 0) { // do not search with empty filter
        var mapLoadingBlur = L.control.loader()
        setTimeout(() => { // delay to allow map to load
            addLoadingBlurToMap(mapLoadingBlur)
        }, 50);
        return newRunSearchMap(filterId, filter)
        .then(res => {
            if (res.data && res.data.length && res.data.length > 0){
                loader.load(function(loader, resources) {
                    var firstDraw = true;
                    var prevZoom;
                    let minLat = 1000;
                    let minLon = 1000;
                    let maxLat = 0;
                    let maxLon = 0;
                    res.data.forEach(item => {
                        const coordinates = {
                            lon: item[1], 
                            lat: item[0],
                            id: item[2]
                        }
                        var marker = getIconTexture(resources, pixiMarkerShape)
                        marker.tint = pixiMarkerColor
                        marker.x0 = coordinates.lat;
						marker.y0 = coordinates.lon;
                        marker.coordinates = {lon: coordinates.lon, lat: coordinates.lat}

                        marker.interactive = true;
                        marker.cursor = 'pointer'
                        marker.click = () => {
                                const popupLoading = <Container className="d-flex flex-column mt-2">
                                <div className="align-self-center">
                                    <Spinner animation="border" role="status" />
                                </div>
                            </Container>
                            const content = ReactDOMServer.renderToString(popupLoading)
                            marker.popup = L.popup()
                                .setLatLng([coordinates.lat, coordinates.lon])
                                .setContent(content)
                                .openOn(ref.mapRef.current);

                            getDetails(coordinates.id)
                            .then(data => {
                                if (mapLabels.length > 0){
                                    const theLabel = ReactDOMServer.renderToString(popupWithLabels(mapLabels, data, t, collectionTypes, langi18n))
                                    marker.popup.setContent(theLabel)
                                }
                                else {
                                    const temp = <MapPopupContent requestId={""} manySpecies={coordinates.id.manySpecies} data={data} t={t} i18n={langi18n} collectionTypes={collectionTypes}></MapPopupContent>
                                    const content = ReactDOMServer.renderToString(temp)
                                    marker.popup.setContent(content)
                                }
                            })
                        }
                        ref.pixiContainer.current.addChild(marker);
                        marker.legend = coordinates.id;
                
                        minLat = Math.min(minLat, coordinates.lat)
                        minLon = Math.min(minLon, coordinates.lon)
                        maxLat = Math.max(maxLat, coordinates.lat)
                        maxLon = Math.max(maxLon, coordinates.lon)
                    })
        
                    ref.mapRef.current.fitBounds([[minLat, minLon], [maxLat, maxLon]])

                    ref.pixiOverlayRef.current = L.pixiOverlay(function(utils) {
                        var zoom = utils.getMap().getZoom();
                        var container = utils.getContainer();
                        var renderer = utils.getRenderer();
                        var project = utils.latLngToLayerPoint;
                        var scale = utils.getScale();

                        if (firstDraw) {
                            container.children.forEach(sprite => {
                                let projetctedCords = project(sprite.coordinates);
                                sprite.x = projetctedCords.x
                                sprite.y = projetctedCords.y
                            })

                            utils.getMap().on('click', function(e) {
                                var interaction = utils.getRenderer().plugins.interaction;
                                var pointerEvent = e.originalEvent;
                                var pixiPoint = new PIXI.Point();
                                interaction.mapPositionToPoint(pixiPoint, pointerEvent.clientX, pointerEvent.clientY);
                                var target = interaction.hitTest(pixiPoint, container);
                                if (target && target.popup) {
                                    target.popup.openOn(ref.mapRef.current);
                                }
                            });

                        }
        
                        if (firstDraw || prevZoom !== zoom) {
                            let markerScale = resizeIcon(scale) 
                            container.children.forEach(marker => {
                                marker.scale.set(markerScale);
                            })
                        }
        
                        firstDraw = false;
                        prevZoom = zoom;
                        renderer.render(container);
                    }, ref.pixiContainer.current, { preserveDrawingBuffer: true });

                    ref.pixiOverlayRef.current.addTo(ref.mapRef.current);
                    removeLoadingBlurFromMap(mapLoadingBlur)
                })
            }
            else {
                removeLoadingBlurFromMap(mapLoadingBlur)
            }
        })
    }
    else {
        console.log('EMPTY FILTER!!! will not search...')
        return Promise.resolve()
    }
}
