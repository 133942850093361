import React, { Suspense, useCallback, useEffect, useState } from "react";
import { connect, useDispatch } from 'react-redux'
import Menu from "./Menu";
import SessionTracker from "./SessionTracker"
import MainContentSelector from "./MainContentSelector";
import { Switch, Route, useLocation } from "react-router-dom"
import Loader from '../utils/loader'
import { Container, Button, Modal } from "react-bootstrap";
import { useTranslation } from 'react-i18next'
import GoogleCallback from "./GoogleCallback"
import AppleCallback from "./AppleCallback"
import {
    GOOGLE_OAUTH2_REDIRECT_URL, 
    HOW_TO_USE_PAGE_URL_EN, 
    HOW_TO_USE_PAGE_URL_PL, 
    SOCIAL_MENU_VERSION_ID,
    APPLE_OAUTH2_REDIRECT_URL,
} from "../settings"
import { getField } from "../reducers"
import KeyboardMenu from "./KeyboardMenu";
import { Helmet } from "react-helmet";
import { addAnchor } from "../actions/howToUse";
import Footer from './Footer'

const MainContainer = props => {
    const { t } = useTranslation([ 'common', 'paths', 'a11y' ])

    const [ cookiesAccepted, setCookiesAccepted ] = useState((localStorage.getItem('amunatcoll-cookies') !== null))

    const location = useLocation();
    const dispatch = useDispatch();
    const setAnchor = useCallback(anchor => dispatch(addAnchor({ anchor: anchor })), [ dispatch ])

    useEffect(()=>{
        if(location && location.pathname && !location.pathname.includes(HOW_TO_USE_PAGE_URL_EN) && !location.pathname.includes(HOW_TO_USE_PAGE_URL_PL))
            setAnchor(location.pathname.replace(/\//g, ''))
    }, [ location, setAnchor])
    
    if (location.pathname === GOOGLE_OAUTH2_REDIRECT_URL) {
        return (
            <Switch>
                <Route path={GOOGLE_OAUTH2_REDIRECT_URL} component={GoogleCallback}></Route>
            </Switch>
        )
    }
    else if (location.pathname === APPLE_OAUTH2_REDIRECT_URL) {
        return (
            <Switch>
                <Route path={APPLE_OAUTH2_REDIRECT_URL} component={AppleCallback}></Route>
            </Switch>
        )
    } else {
        return (
            <div className={`d-flex flex-column flex-grow-1 ${t('common:language-class')}`}>
                <Helmet>
                    <html lang={localStorage.getItem('lang')} />
                    <title>AMUNATCOLL</title>
                </Helmet>
                <div>
                    <KeyboardMenu></KeyboardMenu>
                </div>
                <div className={!props.activeMenu ? "main-menu-notscrolled" : "main-menu-scrolled"}>
                    <Menu></Menu>
                </div>
                <Suspense fallback={<Loader />}>
                    <div className="d-flex flex-column flex-grow-1 main-content-container" id="content">
                        {!cookiesAccepted && location.pathname !== t('paths:privacy-policy') && location.pathname !== t('paths:regulations') &&
                            <>
                                <Modal
                                    show={true}
                                    //onHide={cancelTimeouts}
                                    backdrop="static"
                                    keyboard={false}
                                    centered
                                >
                                    <Modal.Body>
                                        <div className="cookies-info">
                                            <Container className="p-2">
                                                <div className="d-flex flex-column align-items-center">
                                                    <div>{t('cookies-info')}</div>
                                                    <Button className="m-2" onClick={e => {
                                                        localStorage.setItem('amunatcoll-cookies', 'true')
                                                        setCookiesAccepted(true)
                                                    }}>{t('cookies-info-button')}</Button>
                                                    <Button variant="link" className="m-2" onClick={e => {
                                                        window.open(t("paths:privacy-policy"), new Date().valueOf())
                                                    }}>{t('common:privacy-policy')}</Button>
                                                    <Button variant="link" className="m-2" onClick={e => {
                                                        window.open(t("paths:regulations"), new Date().valueOf())
                                                    }}>{t('common:regulations')}</Button>
                                                </div>
                                            </Container>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                            </>
                        }
                        <SessionTracker></SessionTracker>
                        <>
                            <div className=" flex-grow-1">
                                <MainContentSelector></MainContentSelector>
                            </div>
                            {location.pathname !== "/" &&
                                <Footer activeMenu={props.activeMenu}></Footer>
                            }
                        </>
                    </div>
                </Suspense>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    state: state,
    activeMenu: getField(SOCIAL_MENU_VERSION_ID, SOCIAL_MENU_VERSION_ID, state),
})

const mapDispatchToProps = dispatch => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MainContainer)

