import { useCallback } from "react";
import L from 'leaflet';
import useMapRedux from "./useMapRedux";
import { MAP_LAYERS } from "../stateIds";

const usePredefinedWMS = () => {
    const DEFAULT_OPACITY = "0.6"
    const { value: mapLayers, setValue: setMapLayers } = useMapRedux(MAP_LAYERS, {});

    const handleLoadWms = useCallback((ref, url, layer, name, id, temp, opacity) => {
      const wmsLayer = { ...mapLayers.wmsLayer }
        if (temp){
            wmsLayer[id] = {url, layer, name, id, opacity: (opacity || DEFAULT_OPACITY)}
            try {
                new URL(url);
                let wms = L.tileLayer.wms(url, {
                    name: name,
                    layers: layer,
                    transparency: true,
                    format: 'image/png',
                    opacity:  opacity || DEFAULT_OPACITY
                })
                wms.name = name
                wms.addTo(ref.current)
            } catch (e) {
                // invalid URL 
            }
        }
        else{
            wmsLayer[id] = {}
            ref.current.eachLayer(function (layer) {
                if (layer.name === name) {
                    ref.current.removeLayer(layer);
                }
            });
        }
      setMapLayers({ ...mapLayers, wmsLayer })  
    }, [mapLayers, setMapLayers])

    return { handleLoadWms }
}

export default usePredefinedWMS
