import React, { useState, useEffect } from "react"
import { connect } from 'react-redux'
import { Container,  Spinner, Form, Button, Col, Row, FormLabel } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import {
    ANC_TAXON_FILE_REPORTS_ID,
    ANC_TAXON_FILE_REPORTS_URL,
    ANC_URL_POSTFIX,
    FILE_REPORTS_PAGE_URL_EN,
    FILE_REPORTS_PAGE_URL_PL
} from "../../settings"
import { getPagination, isPending, getItems } from "../../reducers";
import { postDataAnc } from "../../actions"
import ConnectedPagination from '../ConnectedPagination'
import FileReportsListItem from './FileReportsListItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import I18nPathTranslator from "../I18nPathTranslator";
import SEO from "../SEO";

const FileReportsContainer = props => {

    const { t } = useTranslation(['forms', 'menu', 'admin-tools'])

    const [dateStart, setDateStart] = useState('')
    const [dateEnd, setDateEnd]  = useState('')
    const [fileName, setFileName] = useState('')
    const [sortType, setSortType] = useState('data')
    const [filter, setFilter] = useState({})

    const SORT_TYPES = [
        {
            label: t('admin-tools:label-date'),
            value: 'data'
        },
        {
            label: t('admin-tools:label-date-descending'),
            value: '-data'
        },
        {
            label: t('admin-tools:label-file'),
            value: 'plik'
        },
        {
            label: t('admin-tools:label-file-descending'),
            value: '-plik'
        },
        {
            label: t('admin-tools:label-all'),
            value: 'wszystkie'
        },
        {
            label: t('admin-tools:label-all-descending'),
            value: '-wszystkie'
        },
        {
            label: t('admin-tools:label-correct'),
            value: 'poprawne'
        },
        {
            label: t('admin-tools:label-correct-descending'),
            value: '-poprawne'
        },
        {
            label: t('admin-tools:label-incorrect'),
            value: 'niepoprawne'
        },
        {
            label: t('admin-tools:label-incorrect-descending'),
            value: '-niepoprawne'
        }
    ]

    const onDateStartChange = e => {
        setDateStart(e.target.value)
    }

    const onDateEndChange = e => {
        setDateEnd(e.target.value)
    }

    const onFileNameChange = e => {
        setFileName(e.target.value)
    }

    const onSortTypeChange = e => {
        setSortType(e.target.value)
    }

    const onClearForm = () => {
        setFileName('')
        setSortType('data')
        clearDateFrom()
        clearDateTo()
        setFilter({})
    }

    const onSubmitForm = () => {
        let filter = {}
        if (fileName){
            filter['file_name'] = fileName
        }
        if (dateStart){
            filter['from_date'] = dateStart
        }
        if (dateEnd){
            filter['to_date'] = dateEnd
        }
        setFilter(filter)
        props.getFileReport(filter, sortType)
    }

    const getCurrentDate = () => {
        let date = new Date()
        date = date.toJSON().slice(0, 10);
        setDateEnd(date)
    }

    const getMonthAgoDate = () => {
        let date = new Date()
        let currentMonth = date.getMonth()
        date.setMonth(currentMonth - 1)
        date = date.toJSON().slice(0, 10);
        setDateStart(date)
    }

    const clearDateFrom = () => {
        setDateStart('')
    }

    const clearDateTo = () => {
        setDateEnd('')
    }

    useEffect(() => {
        getCurrentDate()
        getMonthAgoDate()
    }, [])

    const handleEnter = (e) => {
		if (e.key === "Enter") 
            onSubmitForm()
	}

    return (
        <Container>
            <SEO title={t('menu:files_reports')} />
            <I18nPathTranslator {...props} pathEN={FILE_REPORTS_PAGE_URL_EN} pathPL={FILE_REPORTS_PAGE_URL_PL} />
                <div className="mt-3 mb-4">
                    <h1>
                        {t('menu:files_reports')}
                    </h1>
               </div>
            <Row>
                <Col md={6}>
                    <Form.Group>
                        <Form.Label htmlFor="fileName">{t('admin-tools:file-name')}</Form.Label>
                        <Form.Control id="fileName" value={fileName} onChange={onFileNameChange} onKeyDown={e => handleEnter(e)}></Form.Control>
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group>
                        <Form.Label htmlFor="sortType">{t('admin-tools:sort-type')}</Form.Label>
                        <Form.Control id="sortType" as="select" value={sortType} onChange={onSortTypeChange}>
                        {
                        SORT_TYPES.map(type => {
                            return (
                                <option key={type.value} value={type.value}>{type.label}</option>
                            )
                        })}
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
            <Col>
                <Form.Row>
                    <Form.Group as={Col}>
                            <FormLabel htmlFor={"dateFrom"}>{t('admin-tools:date-from')}</FormLabel>
                            <Form.Control
                                id="dateFrom"
                                type="date" 
                                name="dateFrom"
                                onChange={onDateStartChange}
                                onKeyDown={e => handleEnter(e)}
                                value={dateStart}
                                required
                            >
                            </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <FormLabel htmlFor={"clearDateFrom"}>{t('admin-tools:clear-date')}</FormLabel>
                        <Button as={Col} onClick={clearDateFrom} onKeyDown={(e)=>{if(e.key==="Enter"){clearDateFrom()}}} tabIndex="0" variant="danger" size="sm" id="clearDateFrom">
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    </Form.Group>
                </Form.Row>
                </Col>
                <Col>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <FormLabel htmlFor={"dateTo"}>{t('admin-tools:date-to')}</FormLabel>
                            <Form.Control 
                                id="dateTo"
                                type="date" 
                                name="dateTo" 
                                onChange={onDateEndChange}
                                onKeyDown={e => handleEnter(e)}
                                value={dateEnd}
                                required
                            >
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                        <FormLabel htmlFor={"clearDateTo"}>{t('admin-tools:clear-date')}</FormLabel>
                            <Button as={Col} onClick={clearDateTo} onKeyDown={(e)=>{if(e.key==="Enter"){clearDateTo()}}} tabIndex="0" variant="danger" size="sm" id="clearDateTo">
                                <FontAwesomeIcon icon={faTrash}  />
                            </Button>
                        </Form.Group>
                    </Form.Row>
                </Col>
            </Row>
            <Form.Group as={Col} xs={12} className="d-flex align-self-end">
                    <div className="flex-grow-1" />
                    <Button variant="primary" onClick={onSubmitForm} disabled={props.loadingSourceFiles}>
                        {t('common:search')}
                    </Button>
                    <Button variant="secondary" className="ml-2" onClick={onClearForm} disabled={props.loadingSourceFiles} >
                        {t('common:clear')}
                    </Button>
            </Form.Group>
            <div className="ml-md-4">
                {props.loadingFileReports &&
                    <Spinner animation="border" role="status" />
                }
                {props.fileReports && props.fileReports.length === 0 &&
                    <div>
                        <b>{t('admin-tools:no-results')}</b>
                    </div>
                }
                {props.fileReports && props.fileReports.length > 0 &&
                <div>
                    <ConnectedPagination requestId={ANC_TAXON_FILE_REPORTS_ID} url={ANC_URL_POSTFIX + ANC_TAXON_FILE_REPORTS_URL} customFilter={{ filter: filter, sort: sortType }}></ConnectedPagination>
                    <Row className="ml-3 mr-3 mb-2">
                        <Col xs={2} sm={1} className="admin-tools-item-row"  >
                        </Col>
                        <Col xs={10} sm={6} className="admin-tools-item-row"  >
                            <b>{t('admin-tools:file-name')}</b>
                        </Col>
                        <Col xs={6} sm={2} className="admin-tools-item-row text-center text-md-left" >
                            <b>{t('admin-tools:date')}</b>
                        </Col>
                        <Col xs={6} sm={3} className="admin-tools-item-row text-center text-md-left" >
                            <b>{t('admin-tools:ordered-imports')} <span style={{color: "red"}}>{t('admin-tools:failed-imports-short')}</span></b>
                        </Col>
                    </Row>
                    {props.fileReports.map((source) => {
                        return(
                                <FileReportsListItem key={source.id} source={source} />
                            )
                        })
                    }
                    <ConnectedPagination last requestId={ANC_TAXON_FILE_REPORTS_ID} url={ANC_URL_POSTFIX + ANC_TAXON_FILE_REPORTS_URL} customFilter={{ filter: filter, sort: sortType }}></ConnectedPagination>
                </div>
                }
            </div>
        </Container>
    )
}



const mapStateToProps = state => ({
    loadingFileReports: isPending(ANC_TAXON_FILE_REPORTS_ID, state),
    fileReports: getItems(ANC_TAXON_FILE_REPORTS_ID, state),
    pagination: getPagination(ANC_TAXON_FILE_REPORTS_ID, state)
})

const mapDispatchToProps = dispatch => ({
    getFileReport: (filter, sortType) => dispatch(
        postDataAnc(ANC_TAXON_FILE_REPORTS_ID, ANC_TAXON_FILE_REPORTS_URL, {
            filter: filter,
            sort: sortType
        })
    )
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FileReportsContainer)
