import { countries } from "./countries"
import {
    TYP_PUBLIKACJI_FIELD_NAME,
    TYP_PUBLIKACJI_VALUE_1,
    TYP_PUBLIKACJI_VALUE_2,
    TYP_PUBLIKACJI_VALUE_3,
} from "../../components/bibliographyDB/attributesStructure"
import { COORDINATES_PRECISION } from "../../components/taxonDB/attributesStructure"

export const ValuesDictionary = [
    {
        name: [ 'zrodlo' ],
        values: [
            {
                value: 'HumanObservation',
                en: 'Human observation',
                pl: 'Obserwacja (naukowa, terenowa)',
            },
            {
                value: 'OtherSpecimen',
                en: 'Other specimen',
                pl: 'Inne okazy',
            },
            {
                value: 'PreservedSpecimen',
                en: 'Preserved specimen',
                pl: 'Zachowany okaz',
            },
            {
                value: 'LivingSpecimen',
                en: 'Living specimen',
                pl: 'Żywy okaz',
            },
            {
                value: 'FossileSpecimen',
                en: 'Fossile specimen',
                pl: 'Kopalny okaz',
            },
            {
                value: 'MachineObservation',
                en: 'Machine observation',
                pl: 'Obserwacja maszynowa (automatyczna)',
            },
            {
                value: 'DrawingOrPhotograph',
                en: 'Drawing or photograph',
                pl: 'Rysunek lub zdjęcie',
            },
            {
                value: 'MultimediaObject',
                en: 'Multimedia Object',
                pl: 'Obiekt multimedialny',
            },
        ]
    },
    {
        name: [ 'rangajednostkinadrzednej' ],
        values: [
            {
                value: 'regnum',
                en: 'kingdom',
                pl: 'królestwo',
            },
            {
                value: 'subregnum',
                en: 'subkingdom',
                pl: 'podkrólestwo',
            },
            {
                value: 'superphylum',
                en: 'superphylum',
                pl: 'nadtyp',
            },
            {
                value: 'phylum',
                en: 'phylum',
                pl: 'typ',
            },
            {
                value: 'subphylum',
                en: 'subphylum',
                pl: 'podtyp',
            },
            {
                value: 'superclassis',
                en: 'superclass',
                pl: 'nadgromada',
            },
            {
                value: 'classis',
                en: 'class',
                pl: 'gromada',
            },
            {
                value: 'subclassis',
                en: 'subclass',
                pl: 'podgromada ',
            },
            {
                value: 'superordo',
                en: 'superorder',
                pl: 'nadrząd ',
            },
            {
                value: 'ordo',
                en: 'order',
                pl: 'rząd',
            },
            {
                value: 'subordo',
                en: 'suborder',
                pl: 'podrząd',
            },
            {
                value: 'superfamilia',
                en: 'superfamily',
                pl: 'nadrodzina',
            },
            {
                value: 'familia',
                en: 'family',
                pl: 'rodzina',
            },
            {
                value: 'subfamilia',
                en: 'subfamily',
                pl: 'podrodzina',
            },
            {
                value: 'tribus',
                en: 'tribe',
                pl: 'plemię',
            },
            {
                value: 'genusgroup',
                en: 'genus group',
                pl: 'grupa rodzajów',
            },
            {
                value: 'unranked',
                en: 'unranked',
                pl: 'nieokreślone',
            },
        ]
    },
    {
        name: [ 'rangataksonunizszegorzedu' ],
        values: [
            {
                value: 'subsp.',
                en: 'subspecies',
                pl: 'podgatunek',
            },
            {
                value: 'var.',
                en: 'variety',
                pl: 'odmiana',
            },
            {
                value: 'subvar.',
                en: 'subvariety',
                pl: 'pododmiana',
            },
            {
                value: 'f.',
                en: 'form',
                pl: 'forma',
            },
            {
                value: 'subf.',
                en: 'subform',
                pl: 'podforma',
            },
        ]
    },
    {
        name: [ 'panstwo', 'Country' ],
        values: countries.map(obj => ({ value: obj[ "Państwo PL" ], pl: obj[ "Państwo PL" ], en: obj[ "Państwo EN" ] }))
    },
    {
        name: [ 'kontynent', 'Continent' ],
        values: [
            {
                value: 'Afryka',
                en: 'Africa',
                pl: 'Afryka',
            },
            {
                value: 'Azja',
                en: 'Asia',
                pl: 'Azja',
            },
            {
                value: 'Australia',
                en: 'Australia',
                pl: 'Australia',
            },
            {
                value: 'Europa',
                en: 'Europe',
                pl: 'Europa',
            },
            {
                value: 'Ameryka Północna',
                en: 'North America',
                pl: 'Ameryka Północna',
            },
            {
                value: 'Oceania',
                en: 'Oceania',
                pl: 'Oceania',
            },
            {
                value: 'Ameryka Południowa',
                en: 'South America',
                pl: 'Ameryka Południowa',
            },
            {
                value: 'Antarktyda',
                en: 'Antarctica',
                pl: 'Antarktyda',
            },
        ]
    },
    {
        name: [ 'multimedia', 'skip_null' ],
        values: [
            {
                value: true,
                en: 'Yes',
                pl: 'Tak',
            },
            {
                value: false,
                en: 'No',
                pl: 'Nie',
            }
        ]
    },
    {
        name: [ TYP_PUBLIKACJI_FIELD_NAME ],
        values: [
            {
                value: '',
                en: 'Any',
                pl: 'Dowolny',
            },
            {
                value: TYP_PUBLIKACJI_VALUE_1,
                en: 'Journal',
                pl: 'Czasopismo',
            },
            {
                value: TYP_PUBLIKACJI_VALUE_2,
                en: 'Monograph',
                pl: 'Monografia',
            },
            {
                value: TYP_PUBLIKACJI_VALUE_3,
                en: 'Chapter',
                pl: 'Rozdział',
            }
        ]
    },
    {
        name: [ COORDINATES_PRECISION ],
        values: [
            {
                value: 0,
                en: 'Unspecified',
                pl: 'Nieokreślone',
            },
            {
                value: 1,
                en: 'Approximate',
                pl: 'Przybliżone',
            },
            {
                value: 2,
                en: 'Exact',
                pl: 'Dokładne',
            }
        ]
    }
]
