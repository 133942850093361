import React, { Suspense } from "react";
import { getDataApi } from "../../actions"
import { connect } from 'react-redux'
import { isPending } from "../../reducers";
import { useTranslation } from 'react-i18next';
import {
    API_GET_COLLECTIONS_STATS_ID,
    API_GET_COLLECTIONS_STATS_URL,
    API_GET_COLLECTIONS_WITHOUT_TYPES_ID,
    API_GET_COLLECTIONS_WITHOUT_TYPES_URL,
    API_EXPORT_COLLECTIONS_REPORT_ID,
    API_EXPORT_COLLECTIONS_REPORT_URL,
} from '../../settings'
import { Row, Col, Button, Form, FormLabel, Spinner, DropdownButton, Dropdown } from "react-bootstrap";
import * as actions from '../../actions'
import CollectionChart from "./CollectionChart";
import CollectionsWithoutTypes from "./CollectionsWithoutTypes"
import {
    setChartType, setPresentationType, setDaysScope, setDisplayedData, setCollectionsWithoutTypes,
    setInterval, setMonthlyData, handleCollectionsData, setBotanicalCollectionNames,
    setZoologicalCollectionNames, setTimeDistribution, setCollections, setDisplayedCollection
} from '../../actions/chart'
import Loader from '../../utils/loader'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";


function CollectionReportsHandler(props) {

    const CHART_TYPE_BAR = 'bar'
    const CHART_TYPE_LINE = 'line'
    const NHC_BOT_CONST = 'NHC-BOT'
    const NHC_ZOO_CONST = 'NHC-ZOO'
    const TOTAL_IMPORTS_CONST = 'TOTAL-IMPORTS'
    const TIME_DISTRIBUTION_CONST_SERIES = 'series'
    const TIME_DISTRIBUTION_CONST_LINEAR = 'linear'
    const COLLECTION_WITHOUT_TYPE_CONST = 'brak'

    const { t } = useTranslation([ 'reports', 'common' ])

    const handleFormPresentationTypeChange = e => {
        props.setChartType(e.target.value)
    }


    const handleDateFetch = (event) => {
        event.preventDefault()

        let dateStart = event.target.elements.dateFrom.value;
        let dateEnd = event.target.elements.dateTo.value;

        let startDate = new Date(dateStart);
        let endDate = new Date(dateEnd);

        let endDateString = `${endDate.getFullYear()}-${('0' + (endDate.getMonth() + 1)).slice(-2)}-${('0' + endDate.getDate()).slice(-2)}`
        let startDateString = `${startDate.getFullYear()}-${('0' + (startDate.getMonth() + 1)).slice(-2)}-${('0' + startDate.getDate()).slice(-2)}`

        fetchChartsData(startDateString, endDateString)
        props.setDaysScope({ "dayFrom": startDateString, "dayTo": endDateString })
    }



    const fetchChartsData = (dayFrom, dayTo, type) => {
        props.getCollectionsData(
            API_GET_COLLECTIONS_STATS_ID, API_GET_COLLECTIONS_STATS_URL +
            dayFrom + "/" + dayTo + "/"
        )
            .then((response) => {
                let myData = handleCollectionsData(response)
                props.setCollections(myData)
            }
            )
    }

    const handleCollectionsWithoutType = () => {

        props.getCollectionsData(
            API_GET_COLLECTIONS_WITHOUT_TYPES_ID, API_GET_COLLECTIONS_WITHOUT_TYPES_URL
        )
            .then((response) => {
                props.setCollectionsWithoutTypes({ "collectionsWithoutTypes": response })
            }
            )
    }

    const collectionTypeHandle = (event) => {
        props.setDisplayedCollection({ "displayedCollection": event.target.value })
    }

    const timeDistributionHandle = (event) => {
        props.setTimeDistribution({ "timeDistribution": event.target.value })
    }

    const botCollectionHandle = (event) => {
        if (!event.target.checked) {
            let names = props.charts.botanicalCollectionNames;
            names.delete(event.target.value)
            props.setBotanicalCollectionNames({ "botanicalCollectionNames": names })
        }
        else {
            let names = props.charts.botanicalCollectionNames;
            names.add(event.target.value)
            props.setBotanicalCollectionNames({ "botanicalCollectionNames": names })
        }
    }

    const zooCollectionHandle = (event) => {
        if (!event.target.checked) {
            let names = props.charts.zoologicalCollectionNames;
            names.delete(event.target.value)
            props.setZoologicalCollectionNames({ "zoologicalCollectionNames": names })
        }
        else {
            let names = props.charts.zoologicalCollectionNames;
            names.add(event.target.value)
            props.setZoologicalCollectionNames({ "zoologicalCollectionNames": names })
        }
    }

    const allBotCollectionHandle = (event) => {
        if (event.target.checked) {
            props.setBotanicalCollectionNames({ "botanicalCollectionNames": new Set(props.charts.allBotNames) })
        }
        else {
            props.setBotanicalCollectionNames({ "botanicalCollectionNames": new Set() })
        }
    }

    const allZooCollectionHandle = (event) => {
        if (event.target.checked) {
            props.setZoologicalCollectionNames({ "zoologicalCollectionNames": new Set(props.charts.allZooNames) })
        }
        else {
            props.setZoologicalCollectionNames({ "zoologicalCollectionNames": new Set() })
        }
    }

    const handleFileExport = (fileFormat) => {
        let data = {
            dayFrom: props.charts.dayFrom,
            dayTo: props.charts.dayTo,
            fileFormat: fileFormat
        }
        props.exportFile(data)
    }


    return (
        <Suspense fallback={<Loader />}>
            <div className="ml-1">
                <Row>
                    {props.chartLoading &&
                        <Spinner
                            as="span"
                            animation="border"
                            size="lg"
                            role="status"
                            aria-hidden="true"
                            className="ml-3 mb-3"
                        />
                    }
                    {!props.chartLoading &&
                        <Col md={{ span: 12 }}>
                            <CollectionChart />
                        </Col>
                    }
                </Row>
                <Row>
                    <Col md={{ span: 4 }}>
                        <div className="chart-container p-2">
                            <Form onSubmit={handleDateFetch}>
                                <FormLabel><h4>{t('daily-interval')}</h4></FormLabel>
                                <Form.Group>
                                    <Form.Label htmlFor={"collectionsDateFrom"}><b>{t('from-day')}</b></Form.Label>
                                    <span className="datepicker-toggle">
                                        <Form.Control id={"collectionsDateFrom"} type="date" name="dateFrom" defaultValue={props.charts.dayFrom} required />
                                        <FontAwesomeIcon icon={faCalendar} className="datepicker-toggle-button" alt=""/>
                                    </span>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label htmlFor={"collectionsDateTo"}><b>{t('to-day')}</b></Form.Label>
                                    <span className="datepicker-toggle">
                                        <Form.Control id={"collectionsDateTo"} type="date" name="dateTo" defaultValue={props.charts.dayTo} required />
                                        <FontAwesomeIcon icon={faCalendar} className="datepicker-toggle-button" alt=""/>
                                    </span>
                                </Form.Group>
                                <Button type="submit" className="mb-2">
                                    {t('display-data')}
                                </Button>
                            </Form>
                            <Form>
                                <Form.Group>
                                    <Form.Label htmlFor={"collectionsChartTypeControl"}><b>{t("chart-type")}</b></Form.Label>
                                    <Form.Control id={"collectionsChartTypeControl"} as="select" value={props.charts.chartType} onChange={handleFormPresentationTypeChange}>
                                        <option value={CHART_TYPE_BAR}>{t(CHART_TYPE_BAR)}</option>
                                        <option value={CHART_TYPE_LINE}>{t(CHART_TYPE_LINE)}</option>
                                    </Form.Control>
                                </Form.Group>
                            </Form>
                            <Form>
                                <Form.Group>
                                    <Form.Label htmlFor={"collectionsTimeDistribution"}><b>{t("time-distribution-header")}</b></Form.Label>
                                    <Form.Control id={"collectionsTimeDistribution"} as="select" value={props.charts.timeDistribution} onChange={timeDistributionHandle}>
                                        <option value={TIME_DISTRIBUTION_CONST_SERIES}>{t(TIME_DISTRIBUTION_CONST_SERIES)}</option>
                                        <option value={TIME_DISTRIBUTION_CONST_LINEAR}>{t(TIME_DISTRIBUTION_CONST_LINEAR)}</option>
                                    </Form.Control>
                                </Form.Group>
                            </Form>
                            <div className="d-flex align-items-center mb-4">
                                <DropdownButton onSelect={handleFileExport} id="collection-export-button" title={t('common:exportfile')} disabled={props.reportDownloading}>
                                    <Dropdown.Item eventKey="xlsx">XLSX</Dropdown.Item>
                                    <Dropdown.Item eventKey="xls">XLS</Dropdown.Item>
                                </DropdownButton>
                            </div>
                        </div>
                    </Col>
                    <Col md={{ span: 4 }}>
                        <div className="chart-container p-2">
                            <h4>{t('collection-name')}</h4>
                            <Form>
                                <Form.Group as={Col} >
                                    <Form.Check
                                        onChange={allBotCollectionHandle}
                                        checked={props.charts.allBotNames && props.charts.allBotNames.size === props.charts.botanicalCollectionNames.size ? true : false}
                                        type="checkbox"
                                        label={
                                            <div>
                                                <b>{NHC_BOT_CONST}</b>
                                                <Button
                                                    id="botCollectionsAllBtn"
                                                    onClick={collectionTypeHandle}
                                                    variant="primary"
                                                    value={NHC_BOT_CONST}
                                                    className="ml-2 mb-2"
                                                >
                                                    {t("display-collection")}
                                                </Button>
                                            </div>
                                        }
                                        value={NHC_BOT_CONST}
                                        name="botCollectionsAll"
                                        id="botCollectionsAll"
                                    />
                                    {
                                        props.charts.allBotNames &&
                                        props.charts.allBotNames.map((item, indx) => {

                                            return (
                                                <div className="ml-2" key={indx}>
                                                    <Form.Check
                                                        key={indx}
                                                        checked={props.charts.botanicalCollectionNames.has(item) ? true : false}
                                                        onChange={botCollectionHandle}
                                                        type="checkbox"
                                                        label={
                                                            item === COLLECTION_WITHOUT_TYPE_CONST ?
                                                                <div>
                                                                    {item}
                                                                    <Button variant="secondary" size="sm" className="ml-2" onClick={handleCollectionsWithoutType}>
                                                                        {t('more')}
                                                                    </Button>
                                                                </div> :
                                                                item
                                                        }
                                                        value={item}
                                                        name="collectionButton"
                                                        id={'botCollectionButton' + indx}
                                                    />
                                                </div>
                                            )
                                        })
                                    }
                                </Form.Group>
                                <Form.Group as={Col} >
                                    <Form.Check
                                        onChange={allZooCollectionHandle}
                                        checked={props.charts.allZooNames && props.charts.allZooNames.size === props.charts.zoologicalCollectionNames.size ? true : false}
                                        type="checkbox"
                                        label={
                                            <div>
                                                <b>{NHC_ZOO_CONST}</b>
                                                <Button
                                                    onClick={collectionTypeHandle}
                                                    variant="primary"
                                                    value={NHC_ZOO_CONST}
                                                    className="ml-2 mb-2"
                                                >
                                                    {t("display-collection")}
                                                </Button>
                                            </div>
                                        }
                                        value={NHC_ZOO_CONST}
                                        name="zooCollectionsAll"
                                        id="zooCollectionsAll"
                                    />
                                    {
                                        props.charts.allZooNames &&
                                        props.charts.allZooNames.map((item, indx) => {

                                            return (
                                                <div className="ml-2" key={indx}>
                                                    <Form.Check
                                                        onChange={zooCollectionHandle}
                                                        checked={props.charts.zoologicalCollectionNames.has(item) ? true : false}
                                                        type="checkbox"
                                                        label={
                                                            item === COLLECTION_WITHOUT_TYPE_CONST ?
                                                                <div>
                                                                    {item}
                                                                    <Button variant="secondary" size="sm" className="ml-2" onClick={handleCollectionsWithoutType}>
                                                                        {t('more')}
                                                                    </Button>
                                                                </div> :
                                                                item
                                                        }
                                                        value={item}
                                                        name="collectionButton"
                                                        id={'zooCollectionButton' + indx}
                                                    />
                                                </div>
                                            )
                                        }
                                        )
                                    }
                                </Form.Group>
                                <Col>
                                    <b>{t("all-imports")}</b>
                                    <Button
                                        as={Row}
                                        onClick={() => props.setDisplayedCollection({ "displayedCollection": TOTAL_IMPORTS_CONST })}
                                        variant="primary"
                                        className="ml-2"
                                    >
                                        {t("display")}
                                    </Button>
                                </Col>
                            </Form>
                        </div>
                    </Col>
                    <Col md={{ span: 4 }}>
                        {
                            props.noTypesCollectionsLoading &&
                            <Spinner
                                as="span"
                                animation="border"
                                size="lg"
                                role="status"
                                aria-hidden="true"
                                className="ml-3 mb-3"
                            />
                        }
                        {!props.noTypesCollectionsLoading &&
                            props.charts.collectionsWithoutTypes &&
                            props.charts.collectionsWithoutTypes.length &&
                            <div className="chart-container p-2">
                                <CollectionsWithoutTypes />
                            </div>
                        }
                    </Col>
                </Row>
            </div>
        </Suspense>
    )
}

const mapStateToProps = state => ({
    user: state.user ? state.user.data : null,
    charts: state.charts,
    chartLoading: isPending(API_GET_COLLECTIONS_STATS_ID, state),
    reportDownloading: isPending(API_EXPORT_COLLECTIONS_REPORT_ID, state),
    noTypesCollectionsLoading: isPending(API_GET_COLLECTIONS_WITHOUT_TYPES_ID, state)
})

const mapDispatchToProps = dispatch => ({
    getCollectionsData: (id, url) => dispatch(getDataApi(id, url)),
    setTimeDistribution: (data) => dispatch(setTimeDistribution(data)),
    setDisplayedCollection: data => dispatch(setDisplayedCollection(data)),
    setCollections: (data) => dispatch(setCollections(data)),
    setBotanicalCollectionNames: (data) => dispatch(setBotanicalCollectionNames(data)),
    setZoologicalCollectionNames: (data) => dispatch(setZoologicalCollectionNames(data)),
    setChartType: (data) => dispatch(setChartType(data)),
    setPresentationType: (data) => dispatch(setPresentationType(data)),
    setDaysScope: (days) => dispatch(setDaysScope(days)),
    setDisplayedData: (data) => dispatch(setDisplayedData(data)),
    setInterval: (data) => dispatch(setInterval(data)),
    setMonthlyData: (data) => dispatch(setMonthlyData(data)),
    setCollectionsWithoutTypes: (data) => dispatch(setCollectionsWithoutTypes(data)),
    exportFile: (data) => dispatch(actions.getReportDataApi(API_EXPORT_COLLECTIONS_REPORT_ID, API_EXPORT_COLLECTIONS_REPORT_URL + data.dayFrom + '/' + data.dayTo + '/' + data.fileFormat + '/'))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CollectionReportsHandler)
