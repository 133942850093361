import React from "react";
import Validator from './Validator'
import Converter from './Converter'
import Importer from './Importer'
import useUserRoles from '../../hooks/useUserRoles'

export default () => {
  const { isEditor } = useUserRoles()

  return (
    <div>
      <div className="mt-2">
        <Validator></Validator>
      </div>
      <div className="mt-5">
        <Converter></Converter>
      </div>
      {isEditor &&
        <div className="mt-5">
          <Importer></Importer>
        </div>
      }
    </div>
  )
}
