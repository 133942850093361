import React, { useState, forwardRef } from "react"
import { connect } from 'react-redux'
import { Spinner, Button, Form, Col, Row, Collapse } from "react-bootstrap";
import L from 'leaflet';
import {
    TAXONDB_SEARCH_MAP_DATA_REQUEST_ID,
    TAXONDB_SEARCH_MAP_DATA_REQUEST_URL,
    MAP_DATA_REQUEST_TIMEOUT,
    TAXONDB_SEARCH_WITH_AGGREGATION_REQUEST_URL,
    TAXONDB_DETAILS_REQUEST_URL,
    COLLECTION_TYPES_REQUEST_ID,
    TAXONDB_SEARCH_GENUS_REQUEST_ID,
    TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID,
    TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID,
    TAXON_SEARCH_IS_DYNAMIC,
    MAP_OPENED_TOOL_VISUALIZATION_DETAILS,
} from "../../../../settings"
import { getField, isPending, getFields, getOutput } from "../../../../reducers";
import { useTranslation } from 'react-i18next'
import ReactDOMServer from 'react-dom/server';
import * as actions from '../../../../actions'
import { GENUS_ATTRIBUTE_NAME, SPECIES_ATTRIBUTE_NAME, ROK_ZBIORU_FIELD_NAME } from '../../../taxonDB/attributesStructure'
import MarkerLabels from '../../MarkerLabels'
import { faAngleDown, faAngleRight, faMapMarked } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { groupingMapSearch } from "./GroupingSearch"
import { runMapGroupingSearch } from "../../../../actions/forms";
import { setGroupingColorField, setGroupingColorLegendShown, setGroupingShapeField, setGroupingShapeLegendShown, setOpenedMapTool, setPixiMarkerSize } from "../../../../actions/map";

const GroupingMap = forwardRef((props, ref) => {
    
    const { t, i18n } = useTranslation(['map', 'taxondb-attributes', 'taxondb-groups'])
    const { collapsed, openSlidebar, markerSize, setMapMarkerSize, groupingShapeField, groupingColorField, setGroupingShapeField, setGroupingColorField, groupingColorLegendShown, groupingShapeLegendShown, setGroupingColorLegendShown, setGroupingShapeLegendShown, setActiveMapTool, activeMapTool } = { ...props }

    const DEFAULT_SHAPE_ICON = "plus_solid"
    const DEFAULT_COLOR_ICON = 0x686868
    const UNDEFINED_FIELD_CONST = "UNDEFINED_FIELD"

    const [showLabels, setShowLabels] = useState(false)

    const translateIconName = textureName => {
        let marker
        switch(textureName){
            case 'star_regular':
                marker = "fa-star-o"
                break
            case 'star_solid':
                marker = "fa-star"
                break
            case 'square_solid':
                marker = "fa-square"
                break
            case 'square_regular': 
                marker = "fa-square-o"
                break
            case 'times_solid':
                marker = "fa-times"
                break
            case 'thumbtack_solid':
                marker = "fa-thumb-tack"
                break
            case 'map_marker':
                marker = "fa-map-marker"
                break
            case 'map_pin':
                marker = "fa-map-pin"
                break
            case 'circle_solid':
                marker = "fa-circle"
                break
            case 'circle_regular':
                marker = "fa-circle-o"
                break
            case 'cube_solid':
                marker = "fa-cube"
                break
            case 'bookmark_solid':
                marker = "fa-bookmark"
                break
            case 'asterisk_solid':
                marker = "fa-asterisk"
                break
            case 'plus_solid':
                marker = "fa-plus"
                break
            default:
                marker = "fa-map-marker"
                break
        }
        return marker
    }

    const renderShapeLegend = (items) => {
        hideShapeLegend()
        ref.shapeLegend.current = L.control({ position: "bottomright" });
        ref.shapeLegend.current.onAdd = function() {
            var div = L.DomUtil.create("div", "legend");
            div.style.backgroundColor = 'white';
            div.style.padding = '10px';
            div.style.width = '200px'
            let itemsArray = Array.from(items)
            itemsArray.forEach((item) => {
                div.innerHTML += ReactDOMServer.renderToString(
                    <div>
                        <i 
                            className={`fa ${translateIconName(item[1])}`}
                            style={{ color: 'black' }}
                        />
                        <i style={{background: "white", fontSize: 20, marginLeft: 10}} />
                        <span>
                            <b>{item[0]}</b>
                        </span>
                    </div>
                )
            })
            div.innerHTML += ReactDOMServer.renderToString(
                <div>
                    <i 
                        className={`fa ${translateIconName(DEFAULT_SHAPE_ICON)}`}
                        style={{ color: 'black' }}
                    />
                    <i style={{background: "white", fontSize: 20, marginLeft: 10}} />
                    <span>
                        <b>{t("map:others")}</b>
                    </span>
                </div>
            )
            return div
        }
        ref.shapeLegend.current.addTo(ref.mapRef.current);
        setGroupingShapeLegendShown(true)
    }

    const renderColorLegend = (items) => {
        hideColorLegend()
        ref.colorLegend.current = L.control({ position: "bottomleft" });
        ref.colorLegend.current.onAdd = function() {
            var div = L.DomUtil.create("div", "legend");
            div.style.backgroundColor = 'white';
            div.style.padding = '10px';
            div.style.width = '200px'
            let itemsArray = Array.from(items)
            itemsArray.forEach((item) => {
                div.innerHTML += ReactDOMServer.renderToString(
                    <div>
                        <i 
                            className={`fa fa-square`}
                            style={{ color: `#${item[1].toString(16)}` }}
                        />
                        <i style={{background: "white", fontSize: 20, marginLeft: 10}} />
                        <span>
                            <b>{item[0]}</b>
                        </span>
                    </div>
                )
            })
            div.innerHTML += ReactDOMServer.renderToString(
                <div>
                    <i 
                        className={`fa fa-square`}
                        style={{ color: `#${DEFAULT_COLOR_ICON.toString(16)}`}}
                    />
                    <i style={{background: "white", fontSize: 20, marginLeft: 10}} />
                    <span>
                        <b>{t("map:others")}</b>
                    </span>
                </div>
            )
            return div
        }
        ref.colorLegend.current.addTo(ref.mapRef.current)
        setGroupingColorLegendShown(true)
    } 

    const hideShapeLegend = () => {
        try{
            if (ref.shapeLegend.current){
                ref.shapeLegend.current.remove()
                setGroupingShapeLegendShown(false)
            }
        }
        catch(e){
            //ignore
        }
    }

    const hideColorLegend = () => {
        try{
            if (ref.colorLegend.current){
                ref.colorLegend.current.remove()
                setGroupingColorLegendShown(false)
            }
        }
        catch(e){
            //ignore
        }
    }

    const disableButton = () => {
        if (!groupingColorField && !groupingShapeField){
            return true
        }
        if (props.loadingMapData || props.loading1 || props.loading2 || props.loading3 ){
            return true
        }
        return false
    }

    return(
        <div> 
            {collapsed &&
                <div className="sidebar-single-button-container">
                    <button
                    title={t('map:visualisation-parameters') + ' - ' + t('menu:taxons-grouping')}
                    className="sidebar-button-collapsed"
                        onClick={() => {
                            openSlidebar()
                            setActiveMapTool(MAP_OPENED_TOOL_VISUALIZATION_DETAILS)
                        }} 
                    >
                        <FontAwesomeIcon icon={faMapMarked} color={"white"}/>
                    </button>
                </div>
            }
            {!collapsed &&
            <div>
                <div className="sidebar-opened-buttons-container">
                    <div className="sidebar-single-button-container">
                        <button
                            title={t('map:visualisation-parameters') + ' - ' + t('menu:taxons-grouping')}
                            className="sidebar-button-collapsed"
                            onClick={() => {activeMapTool === MAP_OPENED_TOOL_VISUALIZATION_DETAILS ? setActiveMapTool(null) : setActiveMapTool(MAP_OPENED_TOOL_VISUALIZATION_DETAILS)}}
                        >
                            <FontAwesomeIcon icon={faMapMarked} color={"white"}/>
                        </button>
                    </div>
                    <div
                        className="sidebar-text playContentOpacityAnimation changeContentOpacityAnimation"
                        onClick={() => {activeMapTool === MAP_OPENED_TOOL_VISUALIZATION_DETAILS ? setActiveMapTool(null) : setActiveMapTool(MAP_OPENED_TOOL_VISUALIZATION_DETAILS)}}
                    >  
                        {t('map:visualisation-parameters') + ' - ' + t('menu:taxons-grouping')}
                    </div>
                    <div className="sidebar-items-arrow changeOpacityAnimation playOpacityAnimation">
                        <FontAwesomeIcon icon={activeMapTool === MAP_OPENED_TOOL_VISUALIZATION_DETAILS ? faAngleDown : faAngleRight} color={"black"}/>
                    </div>
                </div>
                <Collapse in={activeMapTool === MAP_OPENED_TOOL_VISUALIZATION_DETAILS}>
                    <div className="ml-4 playContentOpacityAnimation changeContentOpacityAnimation">
                        <Row>
                            <Form.Group as={Col} sm={12} md={12}>
                                <Form.Label htmlFor={'markerColor'}>
                                    {t("map:marker-color")}
                                </Form.Label>
                                <select 
                                    id={'markerColor'} 
                                    name={'markerColor'}
                                    defaultValue={groupingColorField}
                                    disabled={props.loading1 || props.loading2 || props.loading3} 
                                    className='form-control'
                                    onChange={(e) => {
                                            if(e.target.value !== UNDEFINED_FIELD_CONST){
                                                setGroupingColorField(e.target.value)
                                            }
                                            else{
                                                setGroupingColorField(null)
                                            }
                                        }
                                    }
                                >
                                    <option value={UNDEFINED_FIELD_CONST} id="brak">{t("map:none")}</option>
                                    <optgroup label={t('taxondb-groups:group-1')}>
                                        <option value="kolekcjanumerokazu" id="kolekcjanumerokazu"> {t('taxondb-attributes:kolekcjanumerokazu')} </option>
                                        <option value={GENUS_ATTRIBUTE_NAME} id={GENUS_ATTRIBUTE_NAME}> {t(`taxondb-attributes:${GENUS_ATTRIBUTE_NAME}`)} </option>
                                        <option value={SPECIES_ATTRIBUTE_NAME} id={SPECIES_ATTRIBUTE_NAME}> {t(`taxondb-attributes:${SPECIES_ATTRIBUTE_NAME}`)} </option>
                                        <option value="jednostkanadrzedna" id="jednostkanadrzedna"> {t('taxondb-attributes:jednostkanadrzedna')} </option>
                                        <option value="autorzbioru" id="autorzbioru"> {t('taxondb-attributes:autorzbioru')} </option>
                                        <option value="autorgatunku" id="autorgatunku"> {t('taxondb-attributes:autorgatunku')} </option>
                                        <option value={ROK_ZBIORU_FIELD_NAME} id={ROK_ZBIORU_FIELD_NAME}> {t(`taxondb-attributes:${ROK_ZBIORU_FIELD_NAME}`)} </option>
                                        <option value="rangajednostkinadrzednej" id="rangajednostkinadrzednej"> {t('taxondb-attributes:rangajednostkinadrzednej')} </option>
                                        <option value="zrodlo" id="zrodlo"> {t('taxondb-attributes:zrodlo')} </option>
                                    </optgroup>
                                    <optgroup label={t('taxondb-groups:group-2')}>
                                        <option value="panstwo" id="panstwo"> {t('taxondb-attributes:panstwo')}  </option>
                                        <option value="georeferencjakomentarze" id="georeferencjakomentarze"> {t('taxondb-attributes:georeferencjakomentarze')}  </option>
                                        <option value="gmina" id="gmina"> {t('taxondb-attributes:gmina')} </option>
                                        <option value="koditypobszarunatura2000" id="koditypobszarunatura2000"> {t('taxondb-attributes:koditypobszarunatura2000')} </option>
                                        <option value="kontynent" id="kontynent"> {t('taxondb-attributes:kontynent')} </option>
                                        <option value="nazwaobszarunatura2000" id="nazwaobszarunatura2000"> {t('taxondb-attributes:nazwaobszarunatura2000')} </option>
                                        <option value="numerzbioruokreslonegoautora" id="numerzbioruokreslonegoautora"> {t('taxondb-attributes:numerzbioruokreslonegoautora')} </option>
                                        <option value="obszarchronionegokrajobrazu" id="obszarchronionegokrajobrazu"> {t('taxondb-attributes:obszarchronionegokrajobrazu')} </option>
                                        <option value="obszarchroniony" id="obszarchroniony"> {t('taxondb-attributes:obszarchroniony')} </option>
                                        <option value="parkkrajobrazowy" id="parkkrajobrazowy"> {t('taxondb-attributes:parkkrajobrazowy')} </option>
                                        <option value="parknarodowy" id="parknarodowy"> {t('taxondb-attributes:parknarodowy')} </option>
                                        <option value="polozeniewpodzialebiogeograficznymeuropy" id="polozeniewpodzialebiogeograficznymeuropy"> {t('taxondb-attributes:polozeniewpodzialebiogeograficznymeuropy')} </option>
                                        <option value="polozeniewpodzialefizjograficznym" id="polozeniewpodzialefizjograficznym"> {t('taxondb-attributes:polozeniewpodzialefizjograficznym')} </option>
                                        <option value="polozeniewpodzialegeograficznympotencjalnejroslinnoscinaturalne" id="polozeniewpodzialegeograficznympotencjalnejroslinnoscinaturalne"> {t('taxondb-attributes:polozeniewpodzialegeograficznympotencjalnejroslinnoscinaturalne')} </option>
                                        <option value="powiat" id="powiat"> {t('taxondb-attributes:powiat')} </option>
                                        <option value="rezerwatprzyrody" id="rezerwatprzyrody"> {t('taxondb-attributes:rezerwatprzyrody')} </option>
                                        <option value="rodzajityprezerwatu" id="rodzajityprezerwatu"> {t('taxondb-attributes:rodzajityprezerwatu')}  </option>
                                        <option value="siedlisko" id="siedlisko"> {t('taxondb-attributes:siedlisko')}  </option>
                                        <option value="uzytekekologiczny" id="uzytekekologiczny"> {t('taxondb-attributes:uzytekekologiczny')} </option>
                                        <option value="wojewodztwo" id="wojewodztwo"> {t('taxondb-attributes:wojewodztwo')} </option>
                                        <option value="zespolprzyrodniczokrajobrazowy" id="zespolprzyrodniczokrajobrazowy"> {t('taxondb-attributes:zespolprzyrodniczokrajobrazowy')} </option>
                                    </optgroup>
                                    <optgroup label={t('taxondb-groups:group-3')}>
                                        <option value="instytucja" id="instytucja"> {t('taxondb-attributes:instytucja')}  </option>
                                        <option value="opisuwagi" id="opisuwagi"> {t('taxondb-attributes:opisuwagi')} </option>
                                        <option value="pochodzenieokazu" id="pochodzenieokazu"> {t('taxondb-attributes:pochodzenieokazu')} </option>
                                    </optgroup>
                                    <optgroup label={t('taxondb-groups:group-4')}>
                                        <option value="bibliografia" id="bibliografia">{t('taxondb-attributes:bibliografia')}</option>
                                    </optgroup>
                                </select>
                                <div className="form-inline-wrapper">
                                    <div className="markerIconLegendSwitch">
                                        <Form.Label htmlFor={'legendColorSwitch'}>{t("map:color-legend")}</Form.Label> 
                                        <Form.Check 
                                            type="switch"
                                            id="legendColorSwitch"
                                            inline
                                            className="ml-2"
                                            checked={groupingColorLegendShown}
                                            onChange={e => (e.target.checked && props.mapGroupedIcons) ? renderColorLegend(props.mapGroupedIcons.colorItems) : hideColorLegend()}
                                        />            
                                    </div>
                                </div>
                            </Form.Group>
                            <Form.Group as={Col} sm={12} md={12}>
                                <Form.Label htmlFor={'markerIcon'}> 
                                    {t("map:marker-icon")}
                                </Form.Label>
                                <select 
                                    id={'markerIcon'} 
                                    name={'markerIcon'}
                                    defaultValue={groupingShapeField}
                                    disabled={props.loading1 || props.loading2 || props.loading3} 
                                    className='form-control'
                                    onChange={(e) => {
                                            if(e.target.value !== UNDEFINED_FIELD_CONST){
                                                setGroupingShapeField(e.target.value)
                                            }
                                            else{
                                                setGroupingShapeField(null)
                                            }
                                        }
                                    }
                                >
                                    <option value={UNDEFINED_FIELD_CONST} id="brak">{t("map:none")}</option>
                                    <optgroup label={t('taxondb-groups:group-1')}>
                                        <option value="kolekcjanumerokazu" id="kolekcjanumerokazu"> {t('taxondb-attributes:kolekcjanumerokazu')} </option>
                                        <option value={GENUS_ATTRIBUTE_NAME} id={GENUS_ATTRIBUTE_NAME}> {t(`taxondb-attributes:${GENUS_ATTRIBUTE_NAME}`)} </option>
                                        <option value={SPECIES_ATTRIBUTE_NAME} id={SPECIES_ATTRIBUTE_NAME}> {t(`taxondb-attributes:${SPECIES_ATTRIBUTE_NAME}`)} </option>
                                        <option value="jednostkanadrzedna" id="jednostkanadrzedna"> {t('taxondb-attributes:jednostkanadrzedna')} </option>
                                        <option value="autorzbioru" id="autorzbioru"> {t('taxondb-attributes:autorzbioru')} </option>
                                        <option value="autorgatunku" id="autorgatunku"> {t('taxondb-attributes:autorgatunku')} </option>
                                        <option value={ROK_ZBIORU_FIELD_NAME} id={ROK_ZBIORU_FIELD_NAME}> {t(`taxondb-attributes:${ROK_ZBIORU_FIELD_NAME}`)} </option>
                                        <option value="rangajednostkinadrzednej" id="rangajednostkinadrzednej"> {t('taxondb-attributes:rangajednostkinadrzednej')} </option>
                                        <option value="zrodlo" id="zrodlo"> {t('taxondb-attributes:zrodlo')} </option>
                                    </optgroup>
                                    <optgroup label={t('taxondb-groups:group-2')}>
                                        <option value="panstwo" id="panstwo"> {t('taxondb-attributes:panstwo')}  </option>
                                        <option value="georeferencjakomentarze" id="georeferencjakomentarze"> {t('taxondb-attributes:georeferencjakomentarze')}  </option>
                                        <option value="gmina" id="gmina"> {t('taxondb-attributes:gmina')} </option>
                                        <option value="koditypobszarunatura2000" id="koditypobszarunatura2000"> {t('taxondb-attributes:koditypobszarunatura2000')} </option>
                                        <option value="kontynent" id="kontynent"> {t('taxondb-attributes:kontynent')} </option>
                                        <option value="nazwaobszarunatura2000" id="nazwaobszarunatura2000"> {t('taxondb-attributes:nazwaobszarunatura2000')} </option>
                                        <option value="numerzbioruokreslonegoautora" id="numerzbioruokreslonegoautora"> {t('taxondb-attributes:numerzbioruokreslonegoautora')} </option>
                                        <option value="obszarchronionegokrajobrazu" id="obszarchronionegokrajobrazu"> {t('taxondb-attributes:obszarchronionegokrajobrazu')} </option>
                                        <option value="obszarchroniony" id="obszarchroniony"> {t('taxondb-attributes:obszarchroniony')} </option>
                                        <option value="parkkrajobrazowy" id="parkkrajobrazowy"> {t('taxondb-attributes:parkkrajobrazowy')} </option>
                                        <option value="parknarodowy" id="parknarodowy"> {t('taxondb-attributes:parknarodowy')} </option>
                                        <option value="polozeniewpodzialebiogeograficznymeuropy" id="polozeniewpodzialebiogeograficznymeuropy"> {t('taxondb-attributes:polozeniewpodzialebiogeograficznymeuropy')} </option>
                                        <option value="polozeniewpodzialefizjograficznym" id="polozeniewpodzialefizjograficznym"> {t('taxondb-attributes:polozeniewpodzialefizjograficznym')} </option>
                                        <option value="polozeniewpodzialegeograficznympotencjalnejroslinnoscinaturalne" id="polozeniewpodzialegeograficznympotencjalnejroslinnoscinaturalne"> {t('taxondb-attributes:polozeniewpodzialegeograficznympotencjalnejroslinnoscinaturalne')} </option>
                                        <option value="powiat" id="powiat"> {t('taxondb-attributes:powiat')} </option>
                                        <option value="rezerwatprzyrody" id="rezerwatprzyrody"> {t('taxondb-attributes:rezerwatprzyrody')} </option>
                                        <option value="rodzajityprezerwatu" id="rodzajityprezerwatu"> {t('taxondb-attributes:rodzajityprezerwatu')}  </option>
                                        <option value="siedlisko" id="siedlisko"> {t('taxondb-attributes:siedlisko')}  </option>
                                        <option value="uzytekekologiczny" id="uzytekekologiczny"> {t('taxondb-attributes:uzytekekologiczny')} </option>
                                        <option value="wojewodztwo" id="wojewodztwo"> {t('taxondb-attributes:wojewodztwo')} </option>
                                        <option value="zespolprzyrodniczokrajobrazowy" id="zespolprzyrodniczokrajobrazowy"> {t('taxondb-attributes:zespolprzyrodniczokrajobrazowy')} </option>
                                    </optgroup>
                                    <optgroup label={t('taxondb-groups:group-3')}>
                                        <option value="instytucja" id="instytucja"> {t('taxondb-attributes:instytucja')}  </option>
                                        <option value="opisuwagi" id="opisuwagi"> {t('taxondb-attributes:opisuwagi')} </option>
                                        <option value="pochodzenieokazu" id="pochodzenieokazu"> {t('taxondb-attributes:pochodzenieokazu')} </option>
                                    </optgroup>
                                    <optgroup label={t('taxondb-groups:group-4')}>
                                        <option value="bibliografia" id="bibliografia">{t('taxondb-attributes:bibliografia')}</option>
                                    </optgroup>
                                </select>
                                <div className="form-inline-wrapper">
                                    <div className="markerIconLegendSwitch">
                                        <Form.Label htmlFor={'legendShapeSwitch'}>{t("map:shape-legend")}</Form.Label> 
                                        <Form.Check 
                                            type="switch"
                                            id="legendShapeSwitch"
                                            inline
                                            className="ml-2"
                                            checked={groupingShapeLegendShown}                                    
                                            onChange={e => (e.target.checked && props.mapGroupedIcons) ? renderShapeLegend(props.mapGroupedIcons.shapeItems) : hideShapeLegend()}
                                        />
                                    </div>
                                </div>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} sm={12} md={12}>
                                <Form.Label htmlFor={'iconSize'}>
                                    {t("map:map-icon-title")}
                                </Form.Label>
                                <select 
                                    id={'iconSize'} 
                                    name={'iconSize'}
                                    defaultValue={markerSize}
                                    disabled={props.loading1 || props.loading2 || props.loading3} 
                                    className='form-control'
                                    onChange={(e) => setMapMarkerSize(e.target.value)}
                                >
                                    <option value={10} id="fa-sm">{t("map:map-icon-small")}</option>
                                    <option value={8} id="fa-lg">{t("map:map-icon-medium")}</option>
                                    <option value={6} id="fa-2x">{t("map:map-icon-large")}</option>
                                </select>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} sm={12} md={12}>
                                <Form.Label htmlFor={'showLabels'}>
                                    <div>{t("map:edit-attributes-label")}</div>
                                </Form.Label>
                                <Form.Check
                                    type="checkbox" 
                                    id="showLabels"
                                    defaultChecked={showLabels}
                                    onChange={() => setShowLabels(!showLabels)}
                                    disabled={props.loading1 || props.loading2 || props.loading3}
                                    label={t("map:show-labels-panel")}
                                    name={"show-map-labels"}
                                /> 
                            </Form.Group>
                        </Row>
                        <Row>
                            <Col>
                                {showLabels &&
                                    <Form.Group as={Col}>
                                        <div className="mt-1 mb-1">
                                            {t("map:select-labels-info")}
                                        </div>
                                        <MarkerLabels />
                                    </Form.Group>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Form.Group as={Col} md={12} sm={12} className="d-flex align-items-end" >
                                <div className="flex-grow-1" />
                                {(props.loadingMapData || props.loading1 || props.loading2 || props.loading3 ) &&
                                    <Spinner animation="border" role="status" className="mr-2 mt-1" />
                                }
                                <Button 
                                    onClick={() => groupingMapSearch(ref, props, i18n, t) }
                                    disabled={disableButton()}
                                    className="mb-3 ml-2 mr-3"
                                >
                                    {t("show-on-map")}
                                </Button>
                            </Form.Group>
                        </Row>
                    </div>
                </Collapse>
            </div>
            }
        </div>
    )
})

const mapStateToProps = state => ({
    filter1: getFields(TAXONDB_SEARCH_GENUS_REQUEST_ID, state),
    filter2: JSON.parse(getField(TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID, TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID, state, "null")),
    filter3: JSON.parse(getField(TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, state, "null")),
    fields1: getFields(TAXONDB_SEARCH_GENUS_REQUEST_ID, state),
    fields2: getFields(TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID, state),
    fields3: getFields(TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, state),
    isDynamic: getField(TAXON_SEARCH_IS_DYNAMIC, TAXON_SEARCH_IS_DYNAMIC, state, "0"),
    loadingMapData: isPending(TAXONDB_SEARCH_MAP_DATA_REQUEST_ID, state),
    loading1: isPending(TAXONDB_SEARCH_GENUS_REQUEST_ID ,state),
    loading2: isPending(TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID ,state),
    loading3: isPending(TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID, state),
    mapGroupedIcons: state.forms.mapIcons,
    mapLabels: state.map.mapLabels,
    collectionTypes: getOutput(COLLECTION_TYPES_REQUEST_ID, state),
    markerSize: state.map.pixiMarkerSize,
    groupingShapeField: state.map.groupingShapeField,
    groupingColorField: state.map.groupingColorField,
    groupingColorLegendShown: state.map.groupingColorLegendShown,
    groupingShapeLegendShown: state.map.groupingShapeLegendShown,
    pixiMarkerColor: state.map.pixiMarkerColor,
    pixiMarkerSize: state.map.pixiMarkerSize,
    pixiMarkerShape: state.map.pixiMarkerShape,
    activeMapTool: state.map.openedMapTool,
})

const mapDispatchToProps = dispatch => ({
    searchWithAggregation: (field, filter, items_per_page, request_id) => {
        return dispatch(actions.postDataAnc(request_id,
            TAXONDB_SEARCH_WITH_AGGREGATION_REQUEST_URL, {
            filter: filter,
            aggregation_field: field,
            pagination: {perPage: items_per_page},
        },
        MAP_DATA_REQUEST_TIMEOUT
        ))
    },
    getDetails: recordId => dispatch(actions.getDataAnc('popup', TAXONDB_DETAILS_REQUEST_URL + recordId + '/')),
    searchMapData: (data, source) => dispatch(actions.postDataAnc(
        TAXONDB_SEARCH_MAP_DATA_REQUEST_ID,
        TAXONDB_SEARCH_MAP_DATA_REQUEST_URL,
        data,
        MAP_DATA_REQUEST_TIMEOUT,
        source)),
    runMapGroupingSearch: (id, path) => dispatch(runMapGroupingSearch(id, path)),
    clearResponse: (id) => dispatch(actions.requestRemove(id)),
    setMapMarkerSize: data => dispatch(setPixiMarkerSize(data)),
    setGroupingShapeField: data => dispatch(setGroupingShapeField(data)),
    setGroupingColorField: data => dispatch(setGroupingColorField(data)),
    setGroupingColorLegendShown: data => dispatch(setGroupingColorLegendShown(data)),
    setGroupingShapeLegendShown: data => dispatch(setGroupingShapeLegendShown(data)),
    setActiveMapTool: (value) => dispatch(setOpenedMapTool(value)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(GroupingMap)
