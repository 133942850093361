import React, { Suspense } from "react";
import { connect } from 'react-redux'
import { isPending, getItems } from "../../reducers";
import { Container, Spinner } from "react-bootstrap"
import ResultsList from './ResultsList'
import Loader from '../../utils/loader'

const ResultsContainer = props => {
    return (
        <Container className={(!props.loading && !props.items) ? "mt-2 d-none" : "mt-2"}>
            <Suspense fallback={<Loader />}>
                {props.loading &&
                    <div className="align-self-center">
                        <Spinner animation="border" role="status" />
                    </div>
                }
                {!props.loading &&
                    <ResultsList
                        requestId={props.searchRequestId}
                    ></ResultsList>
                }
            </Suspense>
        </Container>
    )
}

const mapStateToProps = (state, ownProps) => ({
    items: getItems(ownProps.searchRequestId, state),
    loading: isPending(ownProps.searchRequestId, state),
})


const mapDispatchToProps = (dispatch, ownProps) => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResultsContainer)

