import React from "react"
import { Spinner, Container } from "react-bootstrap";
import {
    MAP_CLUSTER_MARKER_COUNT_LIMIT,
    MAP_MAX_ZOOM_LEVEL,
    TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID,
    TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID,
    TAXONDB_SEARCH_GENUS_REQUEST_ID,
} from "../../../../settings"
import MapPopupContent from './../../MapPopupContent'
import L from 'leaflet';
import ReactDOMServer from 'react-dom/server';
import renderIconHtml from '../../../../utils/map/renderIconHtml'
import { popupWithLabels } from "../popupWithLabels"

export const clusteringSearchItems = (ref, props, t, langi18n) => {

    const { collectionTypes, getDetails, isDynamic, closeSlidebar, markerColor, markerIcon, markerSize, mapLabels } = { ...props }

    const markerOnClick = m => {
        const popupLoading = <Container className="d-flex flex-column mt-2">
            <div className="align-self-center">
                <Spinner animation="border" role="status" />
            </div>
        </Container>
        const content = ReactDOMServer.renderToString(popupLoading)
        const popup = L.popup()
            .setLatLng(m.target.getLatLng())
            .setContent(content)
            .openOn(ref.mapRef.current);
    
        getDetails(m.target.kolekcjanumerokazu)
            .then(data => {
                if (mapLabels.length > 0){
                    const theLabel = ReactDOMServer.renderToString(popupWithLabels(mapLabels, data, t, collectionTypes, langi18n))
                    popup.setContent(theLabel)
                }
                else {
                    const temp = <MapPopupContent requestId={""} manySpecies={m.manySpecies} data={data} t={t} i18n={langi18n} collectionTypes={collectionTypes}></MapPopupContent>
                    const content = ReactDOMServer.renderToString(temp)
                    popup.setContent(content)
                }
            })
    }
    
    const applyMarkerClickEvents = (m, points) => {
        let limit = MAP_CLUSTER_MARKER_COUNT_LIMIT
        for (let i = 0; i < points.length; i++) {
            if (ref.mapRef.current && ref.mapRef.current.hasLayer(points[i]) && limit > 0) {
                limit--
                points[i].on('click', markerOnClick)
            }
        }
    }

    const getFilterId = () => {
        let id
        if (isDynamic === "0"){
            id = TAXONDB_SEARCH_GENUS_REQUEST_ID
        }
        if (isDynamic === "2"){
            id = TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID
        }
        if (isDynamic === "1"){
            id = TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID
        }
        return id
    }

    closeSlidebar()
    let loader = L.control.loader()
    loader.addTo(ref.mapRef.current);
    ref.pointsLayer.current.clearLayers()
    let filterId = getFilterId()
    return props.runSearchMap(filterId)
    .then(res => {
        if (res.data && res.data.length){

            ref.pointsLayer.current = L.markerClusterGroup({
                chunkedLoading: false,
                spiderfyOnMaxZoom: false,
            }); 
            let points = []

            res.data.forEach(item => {
                let icon = renderIconHtml(`fa ${markerIcon} ${markerSize}`, markerColor)
                const fontAwesomeIcon = new L.divIcon({
                    html: icon,
                    className: 'myDivIcon'
                });
                const marker = L.marker([item[0], item[1]], { icon: fontAwesomeIcon })
                marker['kolekcjanumerokazu'] = item[2]
                marker.on('click', markerOnClick)
                points.push(marker)

                ref.pointsLayer.current.addLayer(marker)
            })
            ref.mapRef.current.flyToBounds(ref.pointsLayer.current.getBounds())
            ref.mapRef.current.on('moveend zoomend', m => applyMarkerClickEvents(m, points))
            ref.mapRef.current.on('spiderfied', m => applyMarkerClickEvents(m, points))
            ref.pointsLayer.current.on('clusterclick', e => {
                if (ref.mapRef.current.getZoom() >= MAP_MAX_ZOOM_LEVEL) {
                    if (e.layer.getChildCount() <= MAP_CLUSTER_MARKER_COUNT_LIMIT) {
                        e.layer.spiderfy()
                    } else {
                        const temp = e.layer.getAllChildMarkers()
                        markerOnClick({ target: temp[0], manySpecies: true })
                    }
                }
            })

            ref.mapRef.current.addLayer(ref.pointsLayer.current);
        }
        loader.hide();
    })
}
