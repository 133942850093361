import React, { useState, forwardRef } from 'react'
import { connect } from 'react-redux'
import { getOutput, isPending } from "../../reducers"
import { useTranslation } from 'react-i18next'
import { postDataApi, getDataApi } from '../../actions'
import { setWmsArray } from '../../actions/map'
import { Button, Form, Spinner, Collapse } from  "react-bootstrap"; 
import UserMapsWMSTable from './UserMapsWMSTable'
import L from 'leaflet';
import * as notify from '../../utils/notify'
const UserMapsHandler = forwardRef((props, ref)  => {
    const { t } = useTranslation(['map'])
    const [buttonState, setButtonState] = useState({})
    const [link, setLink] = useState();
    const [WMSName, setWMSName] = useState();
    const [loadingWMS, setLoadingWMS] = useState(false);
    const [queryingWMS, setQueryingWMS] = useState(false);
    const WMS_LIST = "WMS-LIST"
    const ADD_WMS = "ADD-WMS"
    const DEFAULT_OPACITY = "0.6"
    const [layers, setLayers] = useState([])
    const [selectedLayers, setSelectedLayers] = useState([])

    const toggleButton = (requestId) => {
        const temp = { ...buttonState }
        temp[requestId] = !temp[requestId]
        setButtonState(temp)
    }

    const queryLayers = e => {
        e.preventDefault()
        if (ref.mapRef) {
            // cannot fetch mixed-content! (mixed active content)
            // https://developer.mozilla.org/en-US/docs/Web/Security/Mixed_content
            setQueryingWMS(true)
            fetch(`${link.split('?')[0]}?REQUEST=getCapabilities&SERVICE=WMS`).then(res => {
                res.text().then(xmlTxt => {
                    const parser = new DOMParser()
                    const xml = parser.parseFromString(xmlTxt, 'text/xml')
                    const errorNode = xml.querySelector("parsererror");
                    if (errorNode) {
                        // parsing failed
                        notify.error(t('map:cannot-read-wms-info'))
                    } else {
                        // parsing succeeded
                        const layersArray = Array.from(xml.querySelectorAll('Layer > Name')).filter(n => n.textContent).map(v => v.textContent)
                        if (layersArray.length) {
                            setLayers(layersArray)
                            setSelectedLayers(layersArray)
                        } else {
                            notify.error(t('map:cannot-read-wms-info'))
                        }
                    }
                    setQueryingWMS(false)
                })
            }).catch(err => {
                notify.error(t('map:cannot-read-wms-info'))
                setQueryingWMS(false)
            })
        }
    }

    const handleAddLayer = (e) => {
        e.preventDefault()
        if (ref.mapRef) {
            selectedLayers.forEach(layerName => {
                const name = `${WMSName} (${layerName})`
                // https://leafletjs.com/reference.html#tilelayer-wms
                let wms = L.tileLayer.wms(link, {
                    name,
                    layers: layerName,
                    transparency: true,
                    format: 'image/png',
                    opacity:  DEFAULT_OPACITY
                });
                // https://stackoverflow.com/questions/24342683/leaflet-tile-loading-error-event
                let anyLoadingError = false
                wms.on('tileerror', function(err) {
                    // Fired when there is an error loading a tile.
                    anyLoadingError = true
                });
                wms.on('loading', function(err) {
                    // Fired when the grid layer starts loading tiles.
                    setLoadingWMS(true)
                });
                wms.on('load', function(err) {
                    setLoadingWMS(false)
                    // Fired when the grid layer loaded all visible tiles.
                    if (!anyLoadingError) {
                        props.setWmsArray({
                            name,
                            "layer": layerName,
                            "opacity": DEFAULT_OPACITY,
                            "url": link
                        })
                        notify.info(t('map:add-wms-ok'))
                    } else {
                        notify.error(t('map:cannot-add-wms'))
                    }
                    // clear event handlers
                    ref.mapRef.current.eachLayer(layer => {
                        if (layer === wms) {
                            wms.off('tileerror')
                            wms.off('loading')
                            wms.off('load')
                        }
                    })
                });
                wms.name = name
                // to start loading tiles
                wms.addTo(ref.mapRef.current)
            })
        }
      
    }

    const handleGetAllWms = () => {
        props.getAllWms()
    }

    const handleCreateWms = () => {
        selectedLayers.forEach(layerName => {
            const name = `${WMSName} (${layerName})`
            props.createWms(layerName, link, name).then(res => {
                notify.success(t('map:wms-saved'))
                props.getAllWms()
            }).catch(err => {
                notify.error(t('map:cannot-create-wms'))
            })
        })
    }

    return (
        <>
            <span className="pr-3">
                <div className="ml-2">  
                    <div>  
                        <div className="d-flex flex-row album-element align-items-center border p-3 ml-2"
                            onClick={e => {
                                toggleButton(WMS_LIST)
                                if(!buttonState[WMS_LIST]){
                                    handleGetAllWms()
                                }
                            }
                            }>
                            <div className="flex-grow-1"
                                aria-controls={WMS_LIST}
                                aria-expanded={setButtonState[WMS_LIST]}>
                                <b>{t('map:show-saved-wms')}</b>
                                {props.loadingWMS &&
                                <div className="align-self-center">
                                    <Spinner animation="border" role="status" />
                                </div>
                                }
                            </div>
                            <div className="d-flex">
                                <Button
                                    variant="secondary"
                                    size="sm"
                                    className="align-self-center"
                                    disabled={props.loadingWMS}
                                    onClick={() => handleGetAllWms()}
                                >
                                    {!buttonState[WMS_LIST] &&
                                        <span>{t('map:more')}</span>
                                    }
                                    {buttonState[WMS_LIST] &&
                                        <span>{t('map:hide')}</span>
                                    }
                                </Button>
                            </div>
                        </div>
                        <Collapse in={buttonState[WMS_LIST]} id={WMS_LIST}>
                            <span className="pr-3">
                                <div className="ml-2">
                                    {!props.loadingWMS &&
                                        <UserMapsWMSTable ref={ref} wms={props.wms} />                                
                                    }
                                </div>
                            </span>
                        </Collapse>
                    </div>

                    <div>  
                        <div className="d-flex flex-row album-element align-items-center border p-3 ml-2"
                                onClick={e => {
                                    toggleButton(ADD_WMS)
                                }
                            }>
                            <div className="flex-grow-1"
                                aria-controls={ADD_WMS}
                                aria-expanded={setButtonState[ADD_WMS]}>
                                <b>{t('map:add-wms-info')}</b>
                            </div>
                            <div className="d-flex">
                                <Button
                                    variant="secondary"
                                    size="sm"
                                    className="align-self-center"
                                    disabled={props.savingWMS}
                                    onClick={() => toggleButton(ADD_WMS)}
                                >
                                    {!buttonState[ADD_WMS] &&
                                        <span>{t('map:more')}</span>
                                    }
                                    {buttonState[ADD_WMS] &&
                                        <span>{t('map:hide')}</span>
                                    }
                                </Button>
                            </div>
                        </div>
                        <Collapse in={buttonState[ADD_WMS]} id={ADD_WMS}>
                            <span className="pr-3">
                                <div className="ml-2">
                                    <Form onSubmit={queryLayers}>
                                        <div className="d-flex flex-row mr-3"> 
                                            <div className="p-2 flex-fill">
                                                <Form.Label htmlFor="user-wms-link">{t('map:wms-link-label')}</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    id="user-wms-link"
                                                    autoComplete="off"
                                                    onChange={(e) => setLink(e.target.value)}
                                                />
                                                <div style={{marginTop: "10px"}}>
                                                    <Button type="submit">
                                                        {t('map:query-wms-button')}
                                                    </Button>
                                                    {queryingWMS &&
                                                        <Spinner animation="border" role="status" size="sm" className="ml-2"/>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                                {(layers.length > 0) &&
                                    <div className="ml-2">
                                        <Form onSubmit={handleAddLayer}>
                                            <div className="d-flex flex-row mr-3"> 
                                                <div className="p-2 flex-fill">
                                                    <Form.Label htmlFor="user-wms-layername">{t('map:wms-layer-label')}</Form.Label>
                                                    <div className='mb-2'>
                                                        {layers.map(l => (
                                                            <Form.Check
                                                                key={l}
                                                                type="checkbox" 
                                                                checked={selectedLayers.includes(l)}
                                                                onChange={e => {
                                                                    const other = selectedLayers.filter(v => v !== l)
                                                                    if (e.target.checked) {
                                                                        setSelectedLayers([...other, l])
                                                                    } else {
                                                                        setSelectedLayers([...other])
                                                                    }
                                                                }}
                                                                label={l}
                                                            /> 
                                                        ))}
                                                    </div>
                                                    <Form.Label htmlFor="user-wms-name">{t('map:name')}</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        id="user-wms-name"
                                                        autoComplete="off"
                                                        onChange={(e) => setWMSName(e.target.value)}
                                                    />
                                                    <div style={{marginTop: "10px"}}>
                                                        <Button type="submit" disabled={!(selectedLayers.length > 0) || !WMSName}>
                                                            {t('map:wms-add-button')}
                                                        </Button>
                                                        {loadingWMS &&
                                                            <Spinner animation="border" role="status" size="sm" className="ml-2"/>
                                                        }
                                                    </div>
                                                    <div style={{marginTop: "10px"}}>
                                                        <Button onClick={handleCreateWms} variant="outline-primary" disabled={!(selectedLayers.length > 0) || !WMSName}> 
                                                            <b>{t('map:save-new-wms')}</b> 
                                                        </Button>       
                                                        {props.savingWMS &&
                                                            <Spinner animation="border" role="status" size="sm" className="ml-2"/>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                }
                            </span>
                        </Collapse>
                    </div>
                </div>

            </span>
        </>
    )
})

const mapStateToProps = state => ({
    user: state.user,
    wms: getOutput("GET_BACKGROUND_MAP_ID", state),
    templates: getOutput("GET_MAP_TEMPLATES_ID", state),
    loadingWMS: isPending("GET_BACKGROUND_MAP_ID", state),
    savingWMS: isPending("CREATE_BACKGROUND_MAP_ID", state),
    loadingTemplates: isPending("GET_MAP_TEMPLATES_ID", state),
    savingTemplate: isPending("CREATE_MAP_TEMPLATE_ID", state),
    loadingMaps: isPending("GET_MAPS_ID", state),
    savingMaps: isPending("CREATE_MAP_ID", state),
    mapLegend: state.map.mapLegend,
})

const mapDispatchToProps = dispatch => ({
    /* WMS  */
    getAllWms: () => dispatch(getDataApi(
        "GET_BACKGROUND_MAP_ID",
        "/background_map/get_all/")
    ),

    createWms: (layer, url, WMSName) => dispatch(postDataApi(
        "CREATE_BACKGROUND_MAP_ID",
        "/background_map/create/",
        {
            "layer": layer,
            "url": url,
            "name": WMSName
        }
    )),

    setWmsArray: (data) => dispatch(setWmsArray(data)),

    /* MAP TEMPLATE  */
    getAllTemplates: () => dispatch(getDataApi(
        "GET_MAP_TEMPLATES_ID",
        "/template/get_all/"
    )),

    createTemplate: (name, definition) => dispatch(postDataApi(
        "CREATE_MAP_TEMPLATE_ID",
        "/template/create/",
        {
            "name": name,
            "definition": definition
        }
    )),

    /* MAP STYLE */
    createMap: (name, filter, template_id) => dispatch(postDataApi(
        "CREATE_MAP_ID",
        "/map/create/",
        {
            "name": name,
            "filter": filter,
            "template_id": template_id
        }
    )),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(UserMapsHandler)
