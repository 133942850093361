import React, { useState, Suspense } from "react";
import { connect } from 'react-redux'
import { isPending, getOutput, getField } from "../../reducers";
import RedirectLoginWithReturn from "../access/RedirectLoginWithReturn"
import { useTranslation } from 'react-i18next'
import { Container, Row, Col, ListGroup, Collapse, Button, Spinner } from "react-bootstrap"
import { postDataAnc, getDataApi, requestRemove, postDataDlibra } from '../../actions'
import { setField, runSearchFlow, loadCollectionTypes } from '../../actions/forms'
import {
    USER_GROUPS_EDITOR_CONST,
    TAXONDB_GET_COLLECTION_SUBTYPES_URL,
    TAXONDB_GET_COLLECTION_SUBTYPES_PREFIX,
    TAXONDB_GET_COLLECTIONS_PREFIX,
    TAXONDB_GET_COLLECTIONS_URL,
    COLLECTION_TYPES_REQUEST_ID,
    API_GET_COLLECTIONS_WITHOUT_TYPES_ID,
    API_GET_COLLECTIONS_WITHOUT_TYPES_URL,
    COLLECTION_GENUS_RESULTS_REQUEST_PREFIX,
    COLLECTION_SPECIES_RESULTS_REQUEST_PREFIX,
    COLLECTION_ICONS_RESULTS_REQUEST_PREFIX,
    COLLECTION_RESULTS_REQUEST_PREFIX,
    COLLECTION_RESULTS_TAB_REQUEST_PREFIX,
    ICONDB_SEARCH_REQUEST_URL,
    COLLECTION_PAGE_URL_EN,
    COLLECTION_PAGE_URL_PL,
} from '../../settings'
import CollectionTypeItems from './CollectionTypeItems'
import ValueFromDictionary from "../dictionary/ValueFromDictionary"
import SimpleResultsContainer from '../taxonDB/ResultsContainer'
import ResultsContainer from '../iconDB/ResultsContainer'
import CreateType from './CreateType'
import EditTypes from './EditTypes'
import EditCollection from './EditCollection'
import I18nPathTranslator from "../I18nPathTranslator";
import SEO from "../SEO";
import Loader from '../../utils/loader'
import ListGroupItemDiv from "../ListGroupItemDiv";


const CollectionContainer = props => {
    const { t, i18n } = useTranslation([ 'common', 'collections', 'paths', 'a11y' ])
    const [ editOpen, setEditOpen ] = useState({})
    const [ createTypeOpen, setCreateTypeOpen ] = useState(false)
    const [ editTypesOpen, setEditTypesOpen ] = useState({})

    const toggleOpen = (requestId, type, val) => {
        const temp = { ...props.open }
        temp[ requestId ] = !temp[ requestId ]
        if (type === 'collection' && temp[ requestId ]) {
            if (val.liczba_okazow > 0) {
                props.setOpen(temp)
                props.setSearchFilterAndGo(val.kolekcja)
            } else if (val.liczba_zdjec > 0) {
                props.setOpen(temp)
                props.setIconSearchFilterAndGo(val.kolekcja)
            }
        } else {
            props.setOpen(temp)
            if (type === 'other' && temp[ requestId ]) {
                props.getCollectionWithoutType()
            }
            if (type === 'rodzaj_kolekcji' && temp[ requestId ]) {
                props.getSubtypes(val.typ_kolekcji)
                props.getCollectionsForType(val.typ_kolekcji)
            }
        }

        if (!temp[ requestId ]) {
            setCreateTypeOpen({})
            setEditTypesOpen({})
        }
    }

    const onEditDone = () => props.reloadCollectionTypes().then(() => {
        setCreateTypeOpen({})
        setEditTypesOpen({})
        setEditOpen({})
    })

    const toggleEditOpen = (e, collectionId) => {
        let temp = { ...editOpen }
        temp[ collectionId ] = !temp[ collectionId ]
        setEditOpen(temp)
        e.stopPropagation()
    }

    const toggleCreateTypeOpen = (e, typeId) => {
        let temp = { ...createTypeOpen }
        temp[ typeId ] = !temp[ typeId ]
        setCreateTypeOpen(temp)
        e.stopPropagation()
    }

    const toggleEditTypesOpen = (e, typeId) => {
        let temp = { ...editTypesOpen }
        temp[ typeId ] = !temp[ typeId ]
        setEditTypesOpen(temp)
        e.stopPropagation()
    }

    const getTitle = val => {
        return t('collections:specimens-images-no', {
            specimens: val.liczba_okazow.toLocaleString(i18n.languages[0]),
            photos: val.liczba_zdjec.toLocaleString(i18n.languages[0])
        })
    }

    return (
        <>
            <I18nPathTranslator {...props} pathEN={COLLECTION_PAGE_URL_EN} pathPL={COLLECTION_PAGE_URL_PL} />
            <SEO title={t('collections-title')} />
            {!props.loading && props.user && !props.user.data &&
                <RedirectLoginWithReturn lang={props.lang} returnUrl={props.lang ? t("paths:collections", {lng: props.lang}) : t("paths:collections")} />
            }
            {props.loading &&
                <div className="d-flex flex-column">
                    <div className="align-self-center m-3">
                        <Spinner animation="border" role="status" />
                    </div>
                </div>
            }
            {!props.loading && props.user && props.user.data &&
                <Container className="d-flex flex-column">
                    <div className="mt-3 mb-4">
                        <h1>
                            {t('collections-title')}
                        </h1>
                    </div>
                    <Row>
                        <Col>
                            <ListGroup>
                                <ListGroup.Item tabIndex="0" action className="d-flex flex-row album-element align-items-center" onClick={() => toggleOpen('collections-bot')}>
                                    <div>
                                        {props.open[ 'collections-bot' ] &&
                                            <img src="/images/list-open.svg" alt={t("a11y:list-opened")} />
                                        }
                                        {!props.open[ 'collections-bot' ] &&
                                            <img src="/images/list-closed.svg" alt={t("a11y:list-closed")} />
                                        }
                                    </div>
                                    <div className="flex-grow-1"
                                        aria-controls={'collections-bot'}
                                        aria-expanded={props.open[ 'collections-bot' ]}>
                                        <ValueFromDictionary name="typ_kolekcji" value="NHC-BOT"></ValueFromDictionary>
                                    </div>
                                </ListGroup.Item>
                                <Collapse in={props.open[ 'collections-bot' ]} id={'collections-bot'}>
                                    <div>
                                        {props.user.data.groups.includes(USER_GROUPS_EDITOR_CONST) &&
                                            <div className="ml-4 mt-2 mb-2">
                                                <Button variant="primary" onClick={(e) => toggleCreateTypeOpen(e, 'NHC-BOT')} disabled={props.loading}>
                                                    {t('collections:create-type')}
                                                </Button>
                                            </div>
                                        }
                                        <div className="ml-4 mb-2">
                                            {createTypeOpen[ 'NHC-BOT' ] && <CreateType kindId={'NHC-BOT'} onDone={onEditDone} />}
                                        </div>
                                        {props.loadingCollectionTypes &&
                                            <div className="d-flex flex-column">
                                                <div className="align-self-center m-3">
                                                    <Spinner animation="border" role="status" />
                                                </div>
                                            </div>
                                        }
                                        {!props.loadingCollectionTypes && props.collectionTypes &&
                                            <ListGroup>
                                                {props.collectionTypes.filter(i => i.rodzaj_kolekcji === 'NHC-BOT').map((val, indx) =>
                                                    <div key={indx}>
                                                        <ListGroup.Item tabIndex="0" action className="d-flex flex-row album-element ml-4 align-items-center" onClick={() => toggleOpen('collections-bot-' + val.typ_kolekcji, 'rodzaj_kolekcji', val)}>
                                                            <div>
                                                                {props.open[ 'collections-bot-' + val.typ_kolekcji ] &&
                                                                    <img src="/images/list-open.svg" alt={t("a11y:list-opened")} />
                                                                }
                                                                {!props.open[ 'collections-bot-' + val.typ_kolekcji ] &&
                                                                    <img src="/images/list-closed.svg" alt={t("a11y:list-closed")} />
                                                                }
                                                            </div>
                                                            <div className="flex-grow-1"
                                                                aria-controls={'collections-bot-' + val.typ_kolekcji}
                                                                aria-expanded={props.open[ 'collections-bot-' + val.typ_kolekcji ]}>
                                                                <span className="mr-2">
                                                                    <b>{val.typ_kolekcji}</b>: {i18n.languages[0] === 'en' ? val.opis_eng : i18n.languages[0] === 'pl' ? val.opis_pl : ''}
                                                                </span>
                                                                <strong className="ml-2" title={getTitle(val)}>{val.liczba_okazow.toLocaleString(i18n.languages[0])} / {val.liczba_zdjec.toLocaleString(i18n.languages[0])}</strong>
                                                            </div>
                                                            {props.user.data.groups.includes(USER_GROUPS_EDITOR_CONST) &&
                                                                <div>
                                                                    <div className="btn btn-primary" tabIndex="0" size="sm" onClick={(e) => toggleEditTypesOpen(e, val.typ_kolekcji)} style={{minWidth: '6rem'}}>{t('collections:edit-type')}</div>
                                                                </div>
                                                            }
                                                        </ListGroup.Item>
                                                        {editTypesOpen[ val.typ_kolekcji ] &&
                                                            <div className="ml-4">
                                                                <EditTypes typeId={val.typ_kolekcji} onDone={onEditDone} />
                                                            </div>
                                                        }
                                                        <Collapse in={props.open[ 'collections-bot-' + val.typ_kolekcji ]} id={'collections-bot-' + val.typ_kolekcji}>
                                                            <div>
                                                                <CollectionTypeItems kindId={'NHC-BOT'} typeId={val.typ_kolekcji}></CollectionTypeItems>
                                                            </div>
                                                        </Collapse>
                                                    </div>
                                                )}
                                            </ListGroup>
                                        }
                                    </div>
                                </Collapse>
                                <ListGroupItemDiv className="d-flex flex-row album-element align-items-center" onClick={() => toggleOpen('collections-zoo')}>
                                    <div>
                                        {props.open[ 'collections-zoo' ] &&
                                            <img src="/images/list-open.svg" alt={t("a11y:list-opened")} />
                                        }
                                        {!props.open[ 'collections-zoo' ] &&
                                            <img src="/images/list-closed.svg" alt={t("a11y:list-closed")} />
                                        }
                                    </div>
                                    <div className="flex-grow-1"
                                        aria-controls={'collections-zoo'}
                                        aria-expanded={props.open[ 'collections-zoo' ]}>
                                        <ValueFromDictionary name="typ_kolekcji" value="NHC-ZOO"></ValueFromDictionary>
                                    </div>
                                </ListGroupItemDiv>
                                <Collapse in={props.open[ 'collections-zoo' ]} id={'collections-zoo'}>
                                    <div>
                                        {props.user.data.groups.includes(USER_GROUPS_EDITOR_CONST) &&
                                            <div className="ml-4 mt-2 mb-2">
                                                <Button variant="primary" onClick={(e) => toggleCreateTypeOpen(e, 'NHC-ZOO')} disabled={props.loading}>
                                                    {t('collections:create-type')}
                                                </Button>
                                            </div>
                                        }
                                        <div className="ml-4 mb-2">
                                            {createTypeOpen[ 'NHC-ZOO' ] && <CreateType kindId={'NHC-ZOO'} onDone={onEditDone} />}
                                        </div>
                                        {props.loadingCollectionTypes &&
                                            <div className="d-flex flex-column">
                                                <div className="align-self-center m-3">
                                                    <Spinner animation="border" role="status" />
                                                </div>
                                            </div>
                                        }
                                        {!props.loadingCollectionTypes && props.collectionTypes &&
                                            <ListGroup>
                                                {props.collectionTypes.filter(i => i.rodzaj_kolekcji === 'NHC-ZOO').map((val, indx) =>
                                                    <div key={indx}>
                                                        <ListGroupItemDiv tabIndex="0" action className="d-flex flex-row album-element ml-4 align-items-center" onClick={() => toggleOpen('collections-zoo-' + val.typ_kolekcji, 'rodzaj_kolekcji', val)}>
                                                            <div>
                                                                {props.open[ 'collections-zoo-' + val.typ_kolekcji ] &&
                                                                    <img src="/images/list-open.svg" alt={t("a11y:list-opened")} />
                                                                }
                                                                {!props.open[ 'collections-zoo-' + val.typ_kolekcji ] &&
                                                                    <img src="/images/list-closed.svg" alt={t("a11y:list-closed")} />
                                                                }
                                                            </div>
                                                            <div className="flex-grow-1"
                                                                aria-controls={'collections-zoo-' + val.typ_kolekcji}
                                                                aria-expanded={props.open[ 'collections-zoo-' + val.typ_kolekcji ]}>
                                                                <span className="mr-2">
                                                                    <b>{val.typ_kolekcji}</b>: {i18n.languages[0] === 'en' ? val.opis_eng : i18n.languages[0] === 'pl' ? val.opis_pl : ''}
                                                                </span>
                                                                <strong className="ml-2"  title={getTitle(val)}>{val.liczba_okazow.toLocaleString(i18n.languages[0])} / {val.liczba_zdjec.toLocaleString(i18n.languages[0])}</strong>
                                                            </div>
                                                            {props.user.data.groups.includes(USER_GROUPS_EDITOR_CONST) &&
                                                                <div>
                                                                    <button className="btn btn-primary" tabIndex="0" size="sm" onClick={(e) => toggleEditTypesOpen(e, val.typ_kolekcji)} style={{minWidth: '6rem'}}>{t('collections:edit-type')}</button>
                                                                </div>
                                                            }
                                                        </ListGroupItemDiv>
                                                        {editTypesOpen[ val.typ_kolekcji ] &&
                                                            <div className="ml-4">
                                                                <EditTypes typeId={val.typ_kolekcji} onDone={onEditDone} />
                                                            </div>
                                                        }
                                                        <Collapse in={props.open[ 'collections-zoo-' + val.typ_kolekcji ]} id={'collections-zoo-' + val.typ_kolekcji}>
                                                            <div>
                                                                <CollectionTypeItems kindId={'NHC-ZOO'} typeId={val.typ_kolekcji}></CollectionTypeItems>
                                                            </div>
                                                        </Collapse>
                                                    </div>
                                                )}
                                            </ListGroup>
                                        }
                                    </div>
                                </Collapse>
                                <ListGroupItemDiv className="d-flex flex-row album-element align-items-center" onClick={() => toggleOpen('collections-varia')}>
                                    <div>
                                        {props.open[ 'collections-varia' ] &&
                                            <img src="/images/list-open.svg" alt={t("a11y:list-opened")} />
                                        }
                                        {!props.open[ 'collections-varia' ] &&
                                            <img src="/images/list-closed.svg" alt={t("a11y:list-closed")} />
                                        }
                                    </div>
                                    <div className="flex-grow-1"
                                        aria-controls={'collections-varia'}
                                        aria-expanded={props.open[ 'collections-varia' ]}>
                                        <ValueFromDictionary name="typ_kolekcji" value="VARIA"></ValueFromDictionary>
                                    </div>
                                </ListGroupItemDiv>
                                <Collapse in={props.open[ 'collections-varia' ]} id={'collections-varia'}>
                                    <div>
                                        {props.user.data.groups.includes(USER_GROUPS_EDITOR_CONST) &&
                                            <div className="ml-4 mt-2 mb-2">
                                                <Button variant="primary" onClick={(e) => toggleCreateTypeOpen(e, 'VARIA')} disabled={props.loading}>
                                                    {t('collections:create-type')}
                                                </Button>
                                            </div>
                                        }
                                        <div className="ml-4 mb-2">
                                            {createTypeOpen[ 'VARIA' ] && <CreateType kindId={'VARIA'} onDone={onEditDone} />}
                                        </div>
                                        {props.loadingCollectionTypes &&
                                            <div className="d-flex flex-column">
                                                <div className="align-self-center m-3">
                                                    <Spinner animation="border" role="status" />
                                                </div>
                                            </div>
                                        }
                                        {!props.loadingCollectionTypes && props.collectionTypes &&
                                            <ListGroup>
                                                {props.collectionTypes.filter(i => i.rodzaj_kolekcji === 'VARIA').map((val, indx) =>
                                                    <div key={indx}>
                                                        <ListGroupItemDiv tabIndex="0" action className="d-flex flex-row album-element ml-4 align-items-center" onClick={() => toggleOpen('collections-varia-' + val.typ_kolekcji, 'rodzaj_kolekcji', val)}>
                                                            <div>
                                                                {props.open[ 'collections-varia-' + val.typ_kolekcji ] &&
                                                                    <img src="/images/list-open.svg" alt={t("a11y:list-opened")} />
                                                                }
                                                                {!props.open[ 'collections-varia-' + val.typ_kolekcji ] &&
                                                                    <img src="/images/list-closed.svg" alt={t("a11y:list-closed")} />
                                                                }
                                                            </div>
                                                            <div className="flex-grow-1"
                                                                aria-controls={'collections-varia-' + val.typ_kolekcji}
                                                                aria-expanded={props.open[ 'collections-varia-' + val.typ_kolekcji ]}>
                                                                <span className="mr-2">
                                                                    <b>{val.typ_kolekcji}</b>: {i18n.languages[0] === 'en' ? val.opis_eng : i18n.languages[0] === 'pl' ? val.opis_pl : ''}
                                                                </span>
                                                                <strong className="ml-2">{val.liczba_zdjec.toLocaleString(i18n.languages[0])}</strong>
                                                            </div>
                                                            {props.user.data.groups.includes(USER_GROUPS_EDITOR_CONST) &&
                                                                <div>
                                                                    <button className="btn btn-primary" tabIndex="0" size="sm" onClick={(e) => toggleEditTypesOpen(e, val.typ_kolekcji)} style={{minWidth: '6rem'}}>{t('collections:edit-type')}</button>
                                                                </div>
                                                            }
                                                        </ListGroupItemDiv>
                                                        {editTypesOpen[ val.typ_kolekcji ] &&
                                                            <div className="ml-4">
                                                                <EditTypes typeId={val.typ_kolekcji} onDone={onEditDone} />
                                                            </div>
                                                        }
                                                        <Collapse in={props.open[ 'collections-varia-' + val.typ_kolekcji ]} id={'collections-varia-' + val.typ_kolekcji}>
                                                            <div>
                                                                <CollectionTypeItems kindId={'VARIA'} typeId={val.typ_kolekcji}></CollectionTypeItems>
                                                            </div>
                                                        </Collapse>
                                                    </div>
                                                )}
                                            </ListGroup>
                                        }
                                    </div>
                                </Collapse>
                                <ListGroupItemDiv className="d-flex flex-row album-element align-items-center" onClick={() => toggleOpen('collections-other', 'other')}>
                                    <div>
                                        {props.open[ 'collections-other' ] &&
                                            <img src="/images/list-open.svg" alt={t("a11y:list-opened")} />
                                        }
                                        {!props.open[ 'collections-other' ] &&
                                            <img src="/images/list-closed.svg" alt={t("a11y:list-closed")} />
                                        }
                                    </div>
                                    <div className="flex-grow-1"
                                        aria-controls={'collections-other'}
                                        aria-expanded={props.open[ 'collections-other' ]}>
                                        {t('other')}
                                    </div>
                                </ListGroupItemDiv>
                                <Collapse in={props.open[ 'collections-other' ]} id={'collections-other'}>
                                    <div>
                                        {props.loadingCollectionsWithoutType &&
                                            <div className="d-flex flex-column">
                                                <div className="align-self-center m-3">
                                                    <Spinner animation="border" role="status" />
                                                </div>
                                            </div>
                                        }
                                        {!props.loadingCollectionsWithoutType && props.collectionsWithoutType &&
                                            <ListGroup>
                                                {props.collectionsWithoutType.map((val, indx) =>
                                                    <div key={indx}>
                                                        <ListGroupItemDiv className="d-flex flex-row album-element ml-4 align-items-center" onClick={() => toggleOpen('collection-' + val.kolekcja, 'collection', val)}>
                                                            {(val.liczba_okazow > 0 || val.liczba_zdjec > 0) &&
                                                                <div>
                                                                    {props.open[ 'collection-' + val.kolekcja ] &&
                                                                        <img src="/images/list-open.svg" alt={t("a11y:list-opened")} />
                                                                    }
                                                                    {!props.open[ 'collection-' + val.kolekcja ] &&
                                                                        <img src="/images/list-closed.svg" alt={t("a11y:list-closed")} />
                                                                    }
                                                                </div>
                                                            }
                                                            {!(val.liczba_okazow > 0) && !(val.liczba_zdjec > 0) &&
                                                                <div>
                                                                    <img src="/images/list-empty.svg" alt={t("a11y:list-opened")} />
                                                                </div>
                                                            }
                                                            <div className="flex-grow-1"
                                                                aria-controls={'collection-' + val.kolekcja}
                                                                aria-expanded={props.open[ 'collection-' + val.kolekcja ]}>
                                                                <span className="mr-2">
                                                                    <b>{val.kolekcja}</b>: {i18n.languages[0] === 'en' ? val.opis_eng : i18n.languages[0] === 'pl' ? val.opis_pl : ''}
                                                                </span>
                                                                <strong className="ml-2" title={getTitle(val)}>{val.liczba_okazow.toLocaleString(i18n.languages[0])} / {val.liczba_zdjec.toLocaleString(i18n.languages[0])}</strong>
                                                            </div>
                                                            {props.user.data.groups.includes(USER_GROUPS_EDITOR_CONST) &&
                                                                <div>
                                                                    <button className="btn btn-primary" size="sm" onClick={(e) => toggleEditOpen(e, val.kolekcja)} style={{minWidth: '8rem'}}>{t('collections:edit-collection')}</button>
                                                                </div>
                                                            }
                                                        </ListGroupItemDiv>
                                                        {editOpen[ val.kolekcja ] && <EditCollection {...props} collection={val} onDone={onEditDone} />}
                                                        <Collapse in={props.open[ 'collection-' + val.kolekcja ]} id={'collection-' + val.kolekcja}>
                                                            <div>
                                                                <Suspense fallback={<Loader />}>
                                                                    {props.open[ 'collection-' + val.kolekcja ] && (val.liczba_okazow > 0) &&
                                                                        <div className="ml-4">
                                                                            <SimpleResultsContainer
                                                                                genusRequestId={COLLECTION_GENUS_RESULTS_REQUEST_PREFIX + val.kolekcja}
                                                                                speciesRequestId={COLLECTION_SPECIES_RESULTS_REQUEST_PREFIX + val.kolekcja}
                                                                                iconsRequestId={COLLECTION_ICONS_RESULTS_REQUEST_PREFIX + val.kolekcja}
                                                                                searchRequestId={COLLECTION_RESULTS_REQUEST_PREFIX + val.kolekcja}
                                                                                tabId={COLLECTION_RESULTS_TAB_REQUEST_PREFIX + val.kolekcja}
                                                                            ></SimpleResultsContainer>
                                                                        </div>
                                                                    }
                                                                    {props.open[ 'collection-' + val.kolekcja ] && !(val.liczba_okazow > 0) && (val.liczba_zdjec > 0) &&
                                                                        <div className="ml-4">
                                                                            <ResultsContainer
                                                                                searchRequestId={COLLECTION_ICONS_RESULTS_REQUEST_PREFIX + val.kolekcja}
                                                                            ></ResultsContainer>
                                                                        </div>
                                                                    }
                                                                </Suspense>
                                                            </div>
                                                        </Collapse>
                                                    </div>
                                                )}
                                            </ListGroup>
                                        }
                                    </div>
                                </Collapse>
                            </ListGroup>
                        </Col>
                    </Row>
                </Container>
            }
        </>
    )
}

const mapStateToProps = state => ({
    user: state.user,
    loading: isPending('userInfo', state) || isPending(COLLECTION_TYPES_REQUEST_ID, state),
    collectionTypes: getOutput(COLLECTION_TYPES_REQUEST_ID, state),
    loadingCollectionTypes: isPending(COLLECTION_TYPES_REQUEST_ID, state),
    collectionsWithoutType: getOutput(API_GET_COLLECTIONS_WITHOUT_TYPES_ID, state),
    loadingCollectionsWithoutType: isPending(API_GET_COLLECTIONS_WITHOUT_TYPES_ID, state),
    open: getField('CollectionContainer', 'open', state, {}),
    state: state
})

const mapDispatchToProps = dispatch => ({
    reloadCollectionTypes: () =>
        dispatch(getDataApi(API_GET_COLLECTIONS_WITHOUT_TYPES_ID, API_GET_COLLECTIONS_WITHOUT_TYPES_URL))
            .then(() => dispatch(loadCollectionTypes())),
    getCollectionWithoutType: () => dispatch(getDataApi(
        API_GET_COLLECTIONS_WITHOUT_TYPES_ID,
        API_GET_COLLECTIONS_WITHOUT_TYPES_URL)),
    getSubtypes: type => dispatch(postDataAnc(
        TAXONDB_GET_COLLECTION_SUBTYPES_PREFIX + type,
        TAXONDB_GET_COLLECTION_SUBTYPES_URL,
        { collection_type: type })),
    getCollectionsForType: type => dispatch(postDataAnc(
        TAXONDB_GET_COLLECTIONS_PREFIX + type,
        TAXONDB_GET_COLLECTIONS_URL,
        { collection_type: type })),
    setOpen: value => dispatch(setField('CollectionContainer', 'open', value)),
    setSearchFilterAndGo: id => {
        dispatch(requestRemove(COLLECTION_GENUS_RESULTS_REQUEST_PREFIX + id))
        dispatch(requestRemove(COLLECTION_SPECIES_RESULTS_REQUEST_PREFIX + id))
        dispatch(requestRemove(COLLECTION_RESULTS_REQUEST_PREFIX + id))
        dispatch(setField(COLLECTION_GENUS_RESULTS_REQUEST_PREFIX + id, "kolekcja", id))
        dispatch(runSearchFlow(
            COLLECTION_GENUS_RESULTS_REQUEST_PREFIX + id,
            COLLECTION_SPECIES_RESULTS_REQUEST_PREFIX + id,
            COLLECTION_RESULTS_REQUEST_PREFIX + id,
            COLLECTION_RESULTS_TAB_REQUEST_PREFIX + id))
    },
    setIconSearchFilterAndGo: id => {
        dispatch(requestRemove(COLLECTION_ICONS_RESULTS_REQUEST_PREFIX + id))
        dispatch(postDataDlibra(COLLECTION_ICONS_RESULTS_REQUEST_PREFIX + id, ICONDB_SEARCH_REQUEST_URL, { filter: { "kolekcja": id } }))
    },
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CollectionContainer)
