import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux'
import { Redirect } from "react-router-dom"
import * as actions from '../../actions/user'
import { isPending } from "../../reducers";
import Loader from "../../utils/loader";



const RedirectLoginWithReturn = props => {
    const { setReturn, returnUrl, lang } = props
    const {i18n} = useTranslation()
    const language = lang ? lang : i18n.languages[0]

    useEffect(() => {
        i18n.changeLanguage(language)
        localStorage.setItem('lang', language)
        setReturn(returnUrl)
    }, [ setReturn, returnUrl, i18n, language ])

    return (
        <div>
            {props.isLoggingOut && <Redirect push to="/"></Redirect>}
            {props.loading && <Loader />}
            {!props.loading && !props.isLoggingOut &&
            <>
                {language === "pl" && <Redirect push to="/logowanie/"></Redirect>}
                {language === "en" && <Redirect push to="/login/"></Redirect>}
            </>
            }
        </div>
    )
}

const mapStateToProps = state => ({
    user: state.user,
    loading: isPending('userInfo', state),
    isLoggingOut: state.user ? state.user.isLoggingOut : null,
})

const mapDispatchToProps = dispatch => ({
    setReturn: url => dispatch(actions.setReturn(url)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RedirectLoginWithReturn)

