import React  from 'react';
import { Map, Marker, TileLayer, Circle } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import {
    MAP_MAX_ZOOM_LEVEL,
} from '../../settings'
import L from 'leaflet';


function SingleMarkerMap(props) {

    const position = props.prec ? [parseFloat(props.lat).toFixed(props.prec), parseFloat(props.lon).toFixed(props.prec)] : [parseFloat(props.lat), parseFloat(props.lon)]

    const markerIcon = new L.Icon({
        iconUrl: icon,
        shadowUrl: iconShadow,
        iconSize: [25, 41],
        iconAnchor: [12, 39],
    })

    return (
        <Map center={position} zoom={12} className="taxondb-location-map" maxZoom={MAP_MAX_ZOOM_LEVEL}>
            <TileLayer
                url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                attribution="&copy; <a href=&quot;https://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
            />
            <Marker position={position} icon={markerIcon}>
                {/* <Popup>A pretty CSS3 popup.<br />Easily customizable.</Popup> */}
            </Marker>
            {(props.prec < 6) &&
                <Circle 
                center={position} 
                radius={props.prec === 1 ? 66000 : props.prec === 2 ? 6600 : props.prec === 3 ? 660 : props.prec === 4 ? 66 : 7} />  
            }
        </Map>
    )
}

export default SingleMarkerMap