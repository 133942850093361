import React, { useState } from "react";
import { connect } from 'react-redux'
import { Button, Col } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

const TaxonSourcesListItem = props => {

    const { t } = useTranslation(['common', 'admin-tools'])
    const [buttonState, setButtonState] = useState({})

    const toggleDetails = (requestId) => {
        const temp = { ...buttonState }
        temp[requestId] = !temp[requestId]
        setButtonState(temp)
    }

    return (
        <>
            <div className="d-flex flex-row album-element align-items-center border p-3"
                onClick={e => toggleDetails(props.source.collectionspecimen_id)}
                aria-expanded={setButtonState[props.source.collectionspecimen_id]}
                aria-controls={buttonState[props.source.collectionspecimen_id]}
            >
                <Col xs={4}>
                    {props.source.collectionspecimen_id}
                </Col>
                <Col xs={2}>
                    {props.source.date &&
                        props.source.date.slice(0, 10)
                    }
                </Col>
                <Col xs={6}>
                    <Button onClick={() => toggleDetails(props.source.collectionspecimen_id)} className="float-right">
                        {!buttonState[props.source.collectionspecimen_id] &&
                            t('common:show_details')
                        }
                        {buttonState[props.source.collectionspecimen_id] &&
                            t('common:hide_details')
                        }
                    </Button>
                </Col>
            </div>
            {buttonState[props.source.collectionspecimen_id] &&
                <div className="mt-2 ml-2">
                    <div><b>{t('admin-tools:taxon-id')}</b></div>
                    <div className="ml-2">{props.source.collectionspecimen_id}</div>
                    
                    <div><b>{t('admin-tools:file-name')}</b></div>
                    <div className="ml-2">{props.source.filename}</div>

                    <div><b>{t('admin-tools:path')}</b></div>
                    <div className="ml-2">{props.source.path}</div>

                    <div><b>{t('admin-tools:row')}</b></div>
                    <div className="ml-2">{props.source.row}</div>

                    <div><b>{t('admin-tools:date')}</b></div>
                    <div className="ml-2">{props.source.date}</div>
                </div>
            }
        </>
    )
}


const mapStateToProps = state => ({

})
  
const mapDispatchToProps = dispatch => ({

})
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TaxonSourcesListItem)