import React, { useState, useEffect, useCallback } from "react"
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { isPending, getOutputOrDefault } from "../../reducers"
import { postDataAnc, getDataAnc } from '../../actions'
import { Form, Button } from "react-bootstrap"
import {
    PROTECTED_TAXONS_CREATE_ID,
    PROTECTED_TAXONS_CREATE_URL,
    PROTECTED_TAXONS_SEARCH_ID,
    PROTECTED_TAXONS_SEARCH_URL,
    PROTECTION_SOURCES_GET_ALL_ID,
    PROTECTION_SOURCES_GET_ALL_URL
} from '../../settings'
import * as notify from '../../utils/notify'

const CreateProtectedTaxon = props => {
    const { t } = useTranslation(['protectedTaxon', 'forms', 'common'])

    const getProtectionSourcesCallback = useCallback(props.getProtectionSources, []);
    useEffect(() => {
        getProtectionSourcesCallback((items) => {
            setProtectionSource(items.length > 0? items[0].id : null)
        })
    }, [getProtectionSourcesCallback])

    const [group, setGroup] = useState('okaz')

    const onGroupChanged = e => {
        setGroup(e.target.value)
    }

    const [name, setName] = useState('')

    const onNameChanged = e => {
        setName(e.target.value)
    }

    const [protectionLevel, setProtectionLevel] = useState(1)

    const onProtectionLevelChanged = e => {
        setProtectionLevel(e.target.value)
    }

    const [dateFrom, setDateFrom] = useState(undefined)

    const onDateFromChanged = e => {
        setDateFrom(e.target.value)
    }

    const [dateTo, setDateTo] = useState(undefined)

    const onDateToChanged = e => {
        setDateTo(e.target.value)
    }

    const [protectionSource, setProtectionSource] = useState(undefined)

    const onProtectionSourceChanged = e => {
        setProtectionSource(e.target.value)
    }

    const onSubmitForm = () => {
        let dateReg = /^\d{4}[.]\d{2}[.]\d{2}$/
        if(!name || !name.trim() || (dateFrom && !dateFrom.match(dateReg)) || (dateTo && !dateTo.match(dateReg))){
            return
        }

        props.createProtectedTaxon(group, name, protectionLevel, dateFrom, dateTo, protectionSource, t).then(() => {
            setGroup('okaz')
            setName('')
            setProtectionLevel(1)
            setDateFrom(undefined)
            setDateTo(undefined)
            props.onDone && props.onDone()
        })
    }

    return (
        <Form className="mt-2 common-panel-background p-2" onSubmit={e => { e.preventDefault() }}>
            <Form.Group>
                <Form.Label htmlFor="group">{t('protectedTaxon:group')}</Form.Label>
                <Form.Control id="group" as="select" value={group} onChange={onGroupChanged} disabled={props.querying}>
                    <option value="okaz">{t('protectedTaxon:okaz')}</option>
                    <option value="gatunek">{t('protectedTaxon:gatunek')}</option>
                    <option value="rodzaj">{t('protectedTaxon:rodzaj')}</option>
                    <option value="rodzina">{t('protectedTaxon:rodzina')}</option>
                </Form.Control>
            </Form.Group>
            <Form.Group>
                <Form.Label htmlFor="name">{t('protectedTaxon:name')}</Form.Label>
                <Form.Control id="name" size="sm" value={name} onChange={onNameChanged} disabled={props.querying}></Form.Control>
            </Form.Group>
            <Form.Group>
                <Form.Label htmlFor="protectionLevel">{t('protectedTaxon:protection-level')}</Form.Label>
                <Form.Control id="protectionLevel" as="select" value={protectionLevel} onChange={onProtectionLevelChanged} disabled={props.querying}>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                </Form.Control>
            </Form.Group>
            <Form.Group>
                <Form.Label htmlFor="dateFrom">{t('protectedTaxon:date-from')}</Form.Label>
                <Form.Control id="dateFrom" size="sm" value={dateFrom} onChange={onDateFromChanged} disabled={props.querying} placeholder={t('protectedTaxon:date-format')}></Form.Control>
            </Form.Group>
            <Form.Group>
                <Form.Label htmlFor="dateTo">{t('protectedTaxon:date-to')}</Form.Label>
                <Form.Control id="dateTo" size="sm" value={dateTo} onChange={onDateToChanged} disabled={props.querying}  placeholder={t('protectedTaxon:date-format')}></Form.Control>
            </Form.Group>
            <Form.Group>
                <Form.Label htmlFor="protectionSource">{t('protectedTaxon:protection-source')}</Form.Label>
                <Form.Control id="protectionSource" as="select" value={protectionSource} onChange={onProtectionSourceChanged} disabled={props.querying}>
                    {props.protectionSources.map((source, i) =>
                        <option key={i} value={source.id}>{source.nazwa}</option>
                    )}
                </Form.Control>
            </Form.Group>
            <div className="text-right">
                <Button size="sm" onClick={onSubmitForm} disabled={props.querying}>
                    {t('forms:save')}
                </Button>
            </div>
        </Form>
    )
}

const mapStateToProps = state => ({
    querying: isPending(PROTECTED_TAXONS_CREATE_ID, state) || isPending(PROTECTION_SOURCES_GET_ALL_ID, state),
    protectionSources: getOutputOrDefault(PROTECTION_SOURCES_GET_ALL_ID, state, { items: []})['items'],
    requests: state.requests
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    createProtectedTaxon: (grupa, nazwa, poziom_ochrony, od_daty, do_daty, zrodlo_ochrony_id, t) => {
        return dispatch(postDataAnc(PROTECTED_TAXONS_CREATE_ID, PROTECTED_TAXONS_CREATE_URL, {
            grupa: grupa,
            nazwa: nazwa,
            poziom_ochrony: poziom_ochrony,
            od_daty: od_daty,
            do_daty: do_daty,
            zrodlo_ochrony_id: zrodlo_ochrony_id
        })).then(() => {
            notify.success(t('protectedTaxon:added-protected-taxon'))
            return dispatch(postDataAnc(PROTECTED_TAXONS_SEARCH_ID, PROTECTED_TAXONS_SEARCH_URL))
        }).catch(res => {
            notify.error(t('protectedTaxon:cannot-add-protected-taxon'), res.result)
        })
    },
    getProtectionSources: (onProtectionSourcesLoaded) => {
        return dispatch(getDataAnc(PROTECTION_SOURCES_GET_ALL_ID, PROTECTION_SOURCES_GET_ALL_URL)).then((res) => {
            onProtectionSourcesLoaded(res.items)
        })
    }
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateProtectedTaxon)