import React, { useState, useEffect } from "react";
import { connect } from 'react-redux'
import ProgressBar from 'react-bootstrap/ProgressBar'

import { requestSuccess, getDataApi } from '../../actions'
import {
  AGGREGATION_REPORT_REQUEST_ID_PREFIX,
  API_TASK_REQUEST_PREFIX,
  API_TASK_REQUEST_URL,
} from '../../settings'
import { isPending, getOutput } from "../../reducers";

const InProgress = props => {

  const [ newValue ] = useState(props.newValue)
  const [ progress, setProgress ] = useState(0)
  const [ hasError, setHasError ] = useState(false)
  const [ isDone, setIsDone ] = useState(false)

  const { gettingProgress, getProgress, currentOutput, editingItem, formPanels, indx, setRequest, inProgress, onDone } = props

  useEffect(() => {
    const updateProgress = () => {
      if (!gettingProgress && !isDone) {
        getProgress().then(res => {
          if (res.incorrect > 0) {
            setHasError(true)
          }
          setProgress(res.done)
          if (res.status === 'DONE' && res.done >= res.total) {
            setIsDone(true)
            // save new value locally
            const temp = { items: [ ...currentOutput.items ], pagination: { ...currentOutput.pagination } }
            temp.items[ editingItem ][ formPanels[ indx ].aggregation_field ] = newValue
            setRequest(temp)
            if (!hasError && res.incorrect === 0) {
              const temp2 = { ...inProgress }
              delete temp2[ editingItem ]
              onDone(temp2)
            }
          } else {
            if (handler) {
              handler = setTimeout(updateProgress, 500)
            }
          }
        })
      }
    }
    let handler = setTimeout(updateProgress, 500)

    return () => {
      clearTimeout(handler)
      handler = null
    }
  }, [ gettingProgress, getProgress, currentOutput, editingItem, formPanels, indx, newValue, setRequest, inProgress, onDone, setProgress, hasError, isDone ])


  return <>
    {props.inProgress[ props.editingItem ] &&
      <ProgressBar max={props.max} now={progress} style={{ width: '100%' }} className="mx-2" variant={hasError ? "danger" : ""}></ProgressBar>
    }
  </>
}

const mapStateToProps = (state, ownProps) => ({
  gettingProgress: isPending(API_TASK_REQUEST_PREFIX + ownProps.task_id, state),
  currentOutput: getOutput(AGGREGATION_REPORT_REQUEST_ID_PREFIX + ownProps.requestId + '-search-' + ownProps.indx + '-' + ownProps.path, state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getProgress: () => dispatch(getDataApi(API_TASK_REQUEST_PREFIX + ownProps.task_id, API_TASK_REQUEST_URL + ownProps.task_id + '/')),
  setRequest: data => dispatch(requestSuccess(AGGREGATION_REPORT_REQUEST_ID_PREFIX + ownProps.requestId + '-search-' + ownProps.indx + '-' + ownProps.path, data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InProgress)
