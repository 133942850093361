import React from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux'
import { getOutput, isPending } from "../../reducers";
import ValueFromDictionary from "../dictionary/ValueFromDictionary";

const DivRow = props => {
  const { t } = useTranslation([props.atrTrans])
  return (
    <div className="d-flex separator-before">
      <div className="w-50 p-1">
        <b>{t(props.atrTrans + ':' + props.name)}</b>
      </div>
      <div className="w-50 p-1 text-break">
        <ValueFromDictionary name={props.name} value={props.value}></ValueFromDictionary>
      </div>
    </div>
  )
}

const ChangesHeader = props => {
  const { t } = useTranslation(['db-history'])
  return(
    <div className="d-flex separator-before">
      <div className="w-50 p-1">
        <b>{t("db-history:field")}</b>
      </div>
      <div className="w-25 p-1">
        <b>{t("db-history:old_value")}</b>
      </div>
      <div className="w-25 p-1">
        <b>{t("db-history:new_value")}</b>
      </div>
    </div>)
}

const ChangeRow = props => {
  const { t } = useTranslation([props.atrTrans])
  const formatObject = str => {
    let temp = str.replace(/{/g, "{\n")
    temp = temp.replace(/,/g, ",\n")
    temp = temp.replace(/}/g, "\n}")
    temp = temp.replace(/"/g, "")
    temp = temp.replace(/:/g, ": ")
    return temp
  }

  let {name, oldValue, newValue} = props
  if(name && oldValue && newValue && (name.search(/^(grupa\.)/) > -1)){
    name="group"
    oldValue = formatObject(oldValue)
    newValue = formatObject(newValue)
  }

  return (
    <div className="d-flex separator-before">
      <div className="w-50 p-1">
        {t(props.atrTrans + ':' + name)}
      </div>
      <div className="w-25 p-1 text-break" style={{whiteSpace: "pre-wrap"}}>
        <ValueFromDictionary name={name} value={oldValue}></ValueFromDictionary>
      </div>
      <div className="w-25 p-1 text-break" style={{whiteSpace: "pre-wrap"}}>
        <ValueFromDictionary name={name} value={newValue}></ValueFromDictionary>
      </div>
    </div>
  )
}

const RecordDetails = props => {
  const { t } = useTranslation(['db-history'])
  return (
    <>
    <Container className="pb-2">
      <div className="p-1">
        <h5 className="pt-2 pb-1 text-center">{t("db-history:edition")}</h5>
        <ChangesHeader></ChangesHeader>
        {props.data.items.map((item,k)=><div key={k}>
          <ChangeRow name={item.pole} oldValue={item.stara_wartosc} newValue={item.nowa_wartosc} atrTrans={props.atrTrans}></ChangeRow>
        </div>)}
      </div>
      <div className="p-1">
        <h5 className="pt-3 pb-1 text-center">{t("db-history:details")}</h5>
        {Object.keys(props.editData).map((key)=>
            <div key={key}>{key!=="id" && <DivRow name={key} value={props.editData[key]} atrTrans={"db-history"}></DivRow>}</div>
        )}
      </div>
    </Container>
    </>
  )
}

const mapStateToProps = (state, ownProps) => ({
  loading: isPending(ownProps.requestId, state),
  data: getOutput(ownProps.requestId, state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({  
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecordDetails)
