import React, { useState } from "react";
import { connect } from 'react-redux'
import { Button, Col } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import {
    API_GET_LEADERS_ID,
    API_ROLE_ADD_USER_ID,
    API_ROLE_ADD_USER_URL,
    API_ROLE_REMOVE_USER_ID,
    API_ROLE_REMOVE_USER_URL,
    USER_GROUPS_LEADER_CONST
} from "../settings"
import { isPending } from "../reducers";
import { postDataApi } from "../actions"
import * as notify from '../utils/notify'


const TeamLeadersList = props => {

    const { t } = useTranslation(['common', 'forms'])
    const [isLeader, setIsLeader] = useState(props.userData.is_leader)

    const changeLeader = () =>{
        if(isLeader){
            props.removeRoleFromUser(props.userData.id, USER_GROUPS_LEADER_CONST, t, setIsLeader)
            setIsLeader(false)
        }
        else{
            props.grantRoleToUser(props.userData.id, USER_GROUPS_LEADER_CONST, t, setIsLeader)
            setIsLeader(true)
        }
    }

    return (
        <>
            <div className="d-flex flex-row album-element align-items-center border p-3">
                <Col xs={10}>
                    {props.userData.first_name} {props.userData.last_name} ({props.userData.email})
                </Col>
                <Col xs={2}>
                    <Button className="float-right" variant="secondary" onClick={e => changeLeader()}>
                        {!isLeader &&
                            t('forms:grant-leader-role')
                        }
                        {isLeader &&
                            t('forms:remove-leader-role')
                        }
                    </Button>
                </Col>
            </div>
        </>
    )
}


const mapStateToProps = state => ({
    user: state.user ? state.user.data : null,
    loading: isPending(API_GET_LEADERS_ID, state),
})
  
const mapDispatchToProps = dispatch => ({
    grantRoleToUser: (userId, role, t, setIsLeader) => dispatch(postDataApi(`${API_ROLE_ADD_USER_ID}-${role}-${userId}`, API_ROLE_ADD_USER_URL, {
        "user_id": userId,
        "role": role
    })).then(res => {
            if (res === 0){
                notify.success(t('common:role-succesfully-saved', {role: role}))
                setIsLeader(true)
            }
            if (res === "not allowed"){
                notify.error(t('common:not-allowed-info')) 
            }
            if (res === null){
                notify.error(t('common:not-allowed-info')) 
            }
        }
    ).catch(err => notify.error(t('common:not-allowed-info'))),
    removeRoleFromUser: (userId, role, t, setIsLeader) => dispatch(postDataApi(`${API_ROLE_REMOVE_USER_ID}-${role}-${userId}`, API_ROLE_REMOVE_USER_URL, {
        "user_id": userId,
        "role": role
    })).then(res => {
            if (res === 0){
                notify.success(t('common:role-succesfully-removed', {role: role}))
                setIsLeader(false)
            }
            if (res === "not allowed"){
                notify.error(t('common:not-allowed-info')) 
            }
            if (res === null){
                notify.error(t('common:not-allowed-info')) 
            }
        }
    ).catch(err => notify.error(t('common:not-allowed-info'))),
})
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TeamLeadersList)