import React, { Suspense } from "react";
import { Carousel, CarouselItem, Container } from "react-bootstrap"
import { HOW_TO_USE_PAGE_URL_EN, HOW_TO_USE_PAGE_URL_PL, ICONDB_PAGE_URL_PL, SEARCH_PAGE_URL_PL, TAXONDB_PAGE_URL_PL, SAMPLES_SEARCH_PAGE_URL_PL, EDUCATION_MATERIALS_PAGE_URL_PL, BIBLIOGRAPHY_SEARCH_PAGE_URL_PL } from "../settings";
import { useTranslation } from 'react-i18next'
import Loader from '../utils/loader'
import I18nPathTranslator from "./I18nPathTranslator";
import SEO from "./SEO";
import { connect } from "react-redux";
import { HashLink } from "react-router-hash-link";

const SmoothHashLink = props => {
  const scrollWithOffset = el =>{
      const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
      const yOffset = -140; 
      window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
  }
  return (
      <HashLink to={props.to} scroll={el=> scrollWithOffset(el)}>
              {props.children}
      </HashLink>
  )
}

const HowToUse = props => {
  const { t } = useTranslation(['common', 'a11y'])

  return (
    <Suspense fallback={<Loader />}>
      <I18nPathTranslator {...props} pathEN={HOW_TO_USE_PAGE_URL_EN} pathPL={HOW_TO_USE_PAGE_URL_PL} />
      <SEO title={t('menu:how-to-use')} />
      <div>
        <Container className="how-to-use">
          <section>
            <h1>Jak korzystać</h1>
            <p className="py-0">opis</p>
            <h2>Wyszukiwarki okazów i taksonów</h2>
            <ul>
              <li><b><SmoothHashLink to={'#'+SEARCH_PAGE_URL_PL}>wyszukiwarka ogólna</SmoothHashLink></b></li>
            </ul>
            <h3>Wyszukiwarki specjalistyczne</h3>
            <ul>
              <li><b><SmoothHashLink to={'#'+TAXONDB_PAGE_URL_PL+"_podstawowa"}>wyszukiwarka okazów - podstawowa</SmoothHashLink></b></li>
              <li><b><SmoothHashLink to={'#'+TAXONDB_PAGE_URL_PL+"_rozszerzona"}>wyszukiwarka okazów - rozszerzona</SmoothHashLink></b></li>
              <li><b><SmoothHashLink to={'#'+TAXONDB_PAGE_URL_PL+"_zaawansowana"}>wyszukiwarka okazów - zaawansowana</SmoothHashLink></b></li>
              <li><b><SmoothHashLink to={'#'+SAMPLES_SEARCH_PAGE_URL_PL}>wyszukiwarka prób</SmoothHashLink></b></li>
              <li><b><SmoothHashLink to={'#'+ICONDB_PAGE_URL_PL}>wyszukiwarka multimediów</SmoothHashLink></b></li>
              <li><b><SmoothHashLink to={'#'+BIBLIOGRAPHY_SEARCH_PAGE_URL_PL}>wyszukiwarka bibliografii</SmoothHashLink></b></li>
              <li><b><SmoothHashLink to={'#'+EDUCATION_MATERIALS_PAGE_URL_PL}>wyszukiwarka edukacyjna</SmoothHashLink></b></li>
            </ul>
          </section>
          <section id={SEARCH_PAGE_URL_PL} className="my-5">
            <h2>Wyszukiwarka ogólna</h2>
            <div className="d-flex slider-content">
              <Carousel indicators={true} interval={7000} variant="dark" prevLabel={t('common:previous')} nextLabel={t('common:next')}>
                <CarouselItem>
                  <div className="d-flex slider-item">
                        <div className="d-flex justify-content-center slider-img-container">
                          <img src="/images/how-to-use/Wyszukiwarka_ogolna_1.png" alt={"Zrzut ekranu przedstawiający stronę z wynikami wyszukiwania wyszukiwarki ogólnej."}></img>
                        </div>
                        <div className="d-flex flex-column text-container">
                          <h3>1. Wpisz nazwę rodzaju lub gatunku języku polskim lub łacińskim (np. mak polny lub Papaver rhoeas).</h3>
                          <p className="effect">W zależności od zadanego pytania wyświetla się liczba i lista gatunków lub liczba i lista okazów.</p>
                          <p className="comment">Zwróć uwagę, że nie znając dokładnej nazwy możesz ją wybrać z rozwijającej się listy.</p>
                        </div>
                  </div>
                </CarouselItem>
                <CarouselItem>
                  <div className="d-flex slider-item">
                        <div className="d-flex justify-content-center slider-img-container">
                          <img src="/images/how-to-use/Wyszukiwarka_ogolna_2.png" alt={"Zrzut ekranu przedstawiający szczegóły okazu."}></img>
                        </div>
                        <div className="d-flex flex-column text-container">
                          <h3>2. Wybierz okaz (rekord), który chcesz zobaczyć (np. z dołączonym skanem).</h3>
                          <p className="effect">Wyświetla się skan arkusza zielnikowego oraz metryka z danymi taksonomicznymi i autorze zbioru.</p>
                          <p className="comment">Zwróć uwagę, że na kolejnych ekranach można zobaczyć informacje o lokalizacji, bliższy opis okazu, jeśli został dołączony oraz informację o kolekcji, do której należy.</p>
                        </div>
                  </div>
                </CarouselItem>
                <CarouselItem>
                  <div className="d-flex slider-item">
                        <div className="d-flex justify-content-center slider-img-container">
                          <img src="/images/how-to-use/Wyszukiwarka_ogolna_3.png" alt={"Zrzut ekranu przedstawiający szczegóły okazu z otwartą mapą"}></img>
                        </div>
                        <div className="d-flex flex-column text-container">
                          <h3>3. Wybierz mapę.</h3>
                          <p className="effect">Wyświetla się mapa z zaznaczonym stanowiskiem okazu.</p>
                          <p className="comment pb-0">Zwróć uwagę na:</p>
                          <ul className="comment-list">
                            <li>funkcje pozwalające operować skanem okazu oraz zmieniać skalę mapy</li>
                            <li>funkcję eksportu danych do pliku</li>
                            <li>możliwości udostępniania danych</li>
                            <li>zasady cytowania danych.</li>
                          </ul>
                        </div>
                  </div>
                </CarouselItem>
                <CarouselItem>
                  <div className="d-flex slider-item">
                        <div className="d-flex justify-content-center slider-img-container">
                          <img src="/images/how-to-use/Wyszukiwarka_ogolna_4.png" alt={"Zrzut ekranu przedstawiający stronę ze zdjęciami przedstawiającymi dany takson"}></img>
                        </div>
                        <div className="d-flex flex-column text-container">
                          <h3>4. Wybierz "Zdjęcia (okazy)".</h3>
                          <p className="effect">Wyświetla się liczba ilistracji okazów reprezentujących przeglądany takson (w tym przypadku mak polny).</p>
                          <p className="comment">Zwróć uwagę, że Wybierając z galerii ikonę możesz uzyskać pełną informację o okazie – podobnie jak w kroku 2 i 3.</p>
                        </div>
                  </div>
                </CarouselItem>
                <CarouselItem>
                  <div className="d-flex slider-item">
                        <div className="d-flex justify-content-center slider-img-container">
                          <img src="/images/how-to-use/Wyszukiwarka_ogolna_5.png" alt={"Zrzut ekranu przedstawiający stronę ze zdjęciem maku"}></img>
                        </div>
                        <div className="d-flex flex-column text-container">
                          <h3>5. Wpisz nazwę Mak, a następnie wybierz „Zdjęcia (inne)”</h3>
                          <p className="effect">Wyświetla się zdjęcie maków wykonane w terenie i zlokalizowane w bazie „Multimedia”.</p>
                        </div>
                  </div>
                </CarouselItem>
            </Carousel>
            </div>
          </section>
          <section id={TAXONDB_PAGE_URL_PL+"_podstawowa"} className="my-5">
            <h2>Wyszukiwarka okazów - podstawowa</h2>
            <div className="d-flex slider-content">
              <Carousel indicators={true} interval={7000} variant="dark" prevLabel={t('common:previous')} nextLabel={t('common:next')}>
                <CarouselItem>
                  <div className="d-flex slider-item">
                        <div className="d-flex justify-content-center slider-img-container">
                          <img src="/images/how-to-use/Wyszukiwarka_podstawowa_0.png" alt={"Zrzut ekranu przedstawiający rozwinięte menu wyszukiwarek."}></img>
                        </div>
                        <div className="d-flex flex-column text-container">
                          <h3>Wyszukiwarki specjalistyczne</h3>
                          <p className="effect">We wszystkich wyszukiwarkach specjalistycznych nazwy taksonów (gatunków, rodzajów, jednostek nadrzędnych) określa się w języku łacińskim.</p>
                          <h3>Wyszukiwarka podstawowa</h3>
                          <p className="effect">Umożliwia określenie jednego z siedmiu kryteriów selekcji lub ich kombinacji oraz wyszukania informacji o okazach/notowaniach zebranych na obszarze wyznaczonym przez użytkownika na mapie. </p>
                          <p className="effect">W kolejnych krokach pokażemy trzy przykłady, w których użytkownik określi dwa (kroki 1-11), trzy (krok 12) lub cztery warunki (krok 13).</p>
                        </div>
                  </div>
                </CarouselItem>
                <CarouselItem>
                  <div className="d-flex slider-item">
                        <div className="d-flex justify-content-center slider-img-container">
                          <img src="/images/how-to-use/Wyszukiwarka_podstawowa_1.png" alt={"Zrzut ekranu przedstawiający stronę z formularzem wyszukiwarki specjalistycznej podstawowej"}></img>
                        </div>
                        <div className="d-flex flex-column text-container">
                          <h3>1. Wpisz nazwę jednostki nadrzędnej i wybierz polecenie „SZUKAJ”</h3>
                          <p className="effect">Wyświetlają się warunki wyboru okazów, w tym jednostka nadrzędna, którą jest przykładowo rodzina Papaveraceae.</p>
                          <p className="effect">Po wybraniu polecania „Szukaj” ukazuje się wynik wyszukiwania, w tym liczba i wykaz rodzajów należących do rodziny Papaveraceae z liczbą gatunków i okazów (w nawiasie).</p>
                          <p className="comment">Zwróć uwagę na Menu: Rodzaje (aktywne na tym etapie), Gatunki, Okazy, Galeria, Mapa, Raport, z którego skorzystamy w dalszych krokach.</p>
                        </div>
                  </div>
                </CarouselItem>
                <CarouselItem>
                  <div className="d-flex slider-item">
                        <div className="d-flex justify-content-center slider-img-container">
                          <img src="/images/how-to-use/Wyszukiwarka_podstawowa_2.png" alt={"Zrzut ekranu przedstawiający listę wyszukanych gatunków"}></img>
                        </div>
                        <div className="d-flex flex-column text-container">
                          <h3>2. Wybierz rodzaj Papaver i przycisk <img src="/images/list-closed.svg" alt={t("a11y:list-closed")} className="closed-icon-img"/></h3>
                          <p className="effect">Ukazuje się liczba i wykaz gatunków z rodzaju Papaver wraz z liczbą okazów (w nawiasie).</p>
                          <p className="comment">Zwróć uwagę na aktywną zakładkę „Gatunki” w Menu „Wyniki wyszukiwania”.</p>
                        </div>
                  </div>
                </CarouselItem>
                <CarouselItem>
                  <div className="d-flex slider-item">
                        <div className="d-flex justify-content-center slider-img-container">
                          <img src="/images/how-to-use/Wyszukiwarka_podstawowa_3.png" alt={"Zrzut ekranu przedstawiający listę wyszukanych okazów"}></img>
                        </div>
                        <div className="d-flex flex-column text-container">
                          <h3>3. Wybierz gatunek Papaver  argemone i przycisk <img src="/images/list-closed.svg" alt={t("a11y:list-closed")} className="closed-icon-img"/> </h3>
                          <p className="effect">Ukazuje się liczba i wykaz rekordów (okazów i notowań) tego gatunku.</p>
                          <p className="comment">Zwróć uwagę na aktywną zakładkę „Okazy” w Menu „Wyniki wyszukiwania”.</p>
                        </div>
                  </div>
                </CarouselItem>
                <CarouselItem>
                  <div className="d-flex slider-item">
                        <div className="d-flex justify-content-center slider-img-container">
                          <img src="/images/how-to-use/Wyszukiwarka_podstawowa_4.png" alt={"Zrzut ekranu przedstawiający stronę ze szczegółami wybranego okazu"}></img>
                        </div>
                        <div className="d-flex flex-column text-container">
                          <h3>4. Przejdź do wybranej strony a następnie wybierz jeden z rekordów </h3>
                          <p className="effect">Ukazuje się liczba i wykaz rekordów (okazów i notowań) tego gatunku.</p>
                          <p className="comment">Zwróć uwagę, że kroki 2-4 można wykonać korzystając z odpowiednich poleceń w MENU, które ukazuje się wraz z „Wynikami wyszukiwania”.</p>
                        </div>
                  </div>
                </CarouselItem>
                <CarouselItem>
                  <div className="d-flex slider-item">
                        <div className="d-flex justify-content-center slider-img-container">
                          <img src="/images/how-to-use/Wyszukiwarka_podstawowa_5.png" alt={"Zrzut ekranu przedstawiający stronę z formularzem wyszukiwarki specjalistycznej podstawowej"}></img>
                        </div>
                        <div className="d-flex flex-column text-container">
                          <h3>5. Z MENU wybierz zakładkę „Galeria”</h3>
                          <p className="effect">Ukazuje liczba rekordów ikonograficznych dla Papaver argemone oraz galeria obrazów wraz z ID okazów</p>
                          <p className="comment">Zwróć uwagę, że „Galeria” pozwala szybko zorientować się w zasobach ikonograficznych dowolnego taksonu i dokonać dalszego wyboru rekordów do analizy.</p>
                        </div>
                  </div>
                </CarouselItem>
                <CarouselItem>
                  <div className="d-flex slider-item">
                        <div className="d-flex justify-content-center slider-img-container">
                          <img src="/images/how-to-use/Wyszukiwarka_podstawowa_6.png" alt={"Zrzut ekranu przedstawiający galerię wyszukanych obrazów"}></img>
                        </div>
                        <div className="d-flex flex-column text-container">
                          <h3>6. Wybierz z Galerii jedną z ikon</h3>
                          <p className="effect">Ukazuje się ikonografia wybranego okazu oraz możliwość przeglądu pełnej informacji tekstowej i wyświetlenia stanowiska okazu na mapie</p>
                          <p className="comment">Zwróć uwagę na zakładki znajdujące się pod obrazem okazu.</p>
                        </div>
                  </div>
                </CarouselItem>
                <CarouselItem>
                  <div className="d-flex slider-item">
                        <div className="d-flex justify-content-center slider-img-container">
                          <img src="/images/how-to-use/Wyszukiwarka_podstawowa_7.png" alt={"Zrzut ekranu przedstawiający mapę z zaznaczonym miejscem wyszukania okazu"}></img>
                        </div>
                        <div className="d-flex flex-column text-container">
                          <h3>7. Wybierz zakładkę „Mapa” z MENU analizowanego okazu</h3>
                          <p className="effect">Ukazuje się miejsce zbioru okazu. </p>
                          <p className="comment">Zwróć uwagę, że korzystając z tej ścieżki wyszukiwania można  przeglądać informacje o okazie, analizować położenie stanowiska na mapie i obserwować obraz okazu.</p>
                        </div>
                  </div>
                </CarouselItem>
                <CarouselItem>
                  <div className="d-flex slider-item">
                        <div className="d-flex justify-content-center slider-img-container">
                          <img src="/images/how-to-use/Wyszukiwarka_podstawowa_8.png" alt={"Zrzut ekranu przedstawiający mapę kropkowo-punktową z widocznymi skupiskami okazów i pojedynczymi okazami"}></img>
                        </div>
                        <div className="d-flex flex-column text-container">
                          <h3>8. Wybierz zakładkę „Mapa” z MENU „Wyniki wyszukiwania”</h3>
                          <p className="effect">Na mapie kropkowo-punktowej widoczne są skupienia stanowisk okazów i pojedyncze stanowisko jednego z okazów należących do analizowanego gatunku Papaver argemone. </p>
                          <p className="effect">Zwiększając skalę mapy można przejść do mapy punktowej, ukazującej stanowiska wszystkich okazów.</p>
                          <p className="comment">Zwróć uwagę, że z tego poziomu możliwe jest uruchomienie bogatego zestawu narzędzi BioGIS, omówionych w innym miejscu. </p>
                        </div>
                  </div>
                </CarouselItem>
                <CarouselItem>
                  <div className="d-flex slider-item">
                        <div className="d-flex justify-content-center slider-img-container">
                          <img src="/images/how-to-use/Wyszukiwarka_podstawowa_9.png" alt={"Zrzut ekranu przedstawiający stronę z formularzem definiowania raportu"}></img>
                        </div>
                        <div className="d-flex flex-column text-container">
                          <h3>9. Wybierz zakładkę „Raport” z MENU „Wyniki wyszukiwania” i wykonaj polecenie „Dodaj parametr grupujący”</h3>
                          <p className="effect">Ukazują się cztery grupy parametrów, według których można zestawiać dane ilościowe w tabelach oraz prezentować na wykresach i mapach.</p>
                          <p className="comment">Zwróć uwagę, że szczegółowe parametry są ukryte w tych czterech grupach.</p>
                        </div>
                  </div>
                </CarouselItem>
                <CarouselItem>
                  <div className="d-flex slider-item">
                        <div className="d-flex justify-content-center slider-img-container">
                          <img src="/images/how-to-use/Wyszukiwarka_podstawowa_10.png" alt={"Zrzut ekranu przedstawiający raport okazów z podziałem na dekady"}></img>
                        </div>
                        <div className="d-flex flex-column text-container">
                          <h3>10. Wybierz parametr „Dekada” z grupy „Metryka”</h3>
                          <p className="effect">Ukazuje się tabela z liczbą okazów zbieranych w kolejnych dekadach i ich udziałem procentowym.</p>
                          <p className="comment">Zwróć uwagę na możliwość:</p>
			  <ul className="comment-list">
                            <li>zmiany kolejności dekad;</li>
                            <li>ustawiania tabeli według liczby okazów;</li>
                            <li>przeglądania danych w poszczególnych dekadach (przycisk <img src="/images/list-closed.svg" alt={t("a11y:list-closed")} className="closed-icon-img"/>) </li>
                            <li>prezentowania danych z poszczególnych dekad na mapach z wykorzystaniem narzędzi BioGIS .</li>
                          </ul>

                        </div>
                  </div>
                </CarouselItem>
                <CarouselItem>
                  <div className="d-flex slider-item">
                        <div className="d-flex justify-content-center slider-img-container">
                          <img src="/images/how-to-use/Wyszukiwarka_podstawowa_11.png" alt={"Zrzut ekranu przedstawiający raport statystyczny wraz z wykresem słupkowym"}></img>
                        </div>
                        <div className="d-flex flex-column text-container">
                          <h3>11. Wybierz jeden z dwóch typów wykresów</h3>
                          <p className="effect">Obok zestawienia tabelarycznego ukazuje się wykres w wybranej formie.</p>
                          <p className="comment">Zwróć uwagę na możliwość eksportu danych i wykresu do plików.</p>
                        </div>
                  </div>
                </CarouselItem>
                <CarouselItem>
                  <div className="d-flex slider-item">
                        <div className="d-flex justify-content-center slider-img-container">
                          <img src="/images/how-to-use/Wyszukiwarka_podstawowa_12.png" alt={"Zrzut ekranu przedstawiający stronę z formularzem wyszukiwania z trzema zdefiniowanymi warunkami"}></img>
                        </div>
                        <div className="d-flex flex-column text-container">
                          <h3>12. Wybierz trzy warunki wyszukiwania okazów (np. jednostka nadrzędna, państwo, wszystkie kolekcje)</h3>
                          <p className="effect">Ukazujący się „wynik wyszukiwania” ma identyczną postać jak w przypadku mniejszej liczby kryteriów selekcji.</p>
                          <p className="comment">Zwróć uwagę, że dalsze procedury analityczne są analogiczne do przedstawionych w krokach 2-11.</p>
                        </div>
                  </div>
                </CarouselItem>
                <CarouselItem>
                  <div className="d-flex slider-item">
                        <div className="d-flex justify-content-center slider-img-container">
                          <img src="/images/how-to-use/Wyszukiwarka_podstawowa_13.png" alt={"Zrzut ekranu przedstawiający stronę z formularzem wyszukiwania według obszaru zazanaczonego na mapie"}></img>
                        </div>
                        <div className="d-flex flex-column text-container">
                          <h3>13. Wybierz cztery warunki, które powinny spełniać okazy, w tym opcję wskazywania obszaru na mapie</h3>
                          <p className="effect">Ukazujący się „wynik wyszukiwania” ma identyczną postać jak w przypadku mniejszej liczby kryteriów selekcji.</p>
                          <p className="comment">Zwróć uwagę, że dalsze procedury analityczne są analogiczne do przedstawionych w krokach 2-12.</p>
                        </div>
                  </div>
                </CarouselItem>
            </Carousel>
            </div>
          </section>
          <section id={TAXONDB_PAGE_URL_PL+"_rozszerzona"} className="my-5">
            <h2>Wyszukiwarka okazów - rozszerzona</h2>
            <div className="d-flex slider-content">
              <Carousel indicators={true} interval={7000} variant="dark" prevLabel={t('common:previous')} nextLabel={t('common:next')}>
                <CarouselItem>
                  <div className="d-flex slider-item">
                        <div className="d-flex justify-content-center slider-img-container">
                          <img src="/images/how-to-use/Wyszukiwarka_rozszerzona_0.png" alt={"Zrzut ekranu przedstawiający pusty formularz wyszukiwarki rozszerzonej."}></img>
                        </div>
                        <div className="d-flex flex-column text-container">
                          <h3>Wyszukiwarki specjalistyczne</h3>
                          <p className="effect">We wszystkich wyszukiwarkach specjalistycznych nazwy taksonów (gatunków, rodzajów, jednostek nadrzędnych) określa się w języku łacińskim.</p>
                          <h3>Wyszukiwarka rozszerzona</h3>
                          <p className="effect">Umożliwia wyszukiwanie okazów spełniających wiele spośród 37 kryteriów, które uporządkowane są w cztery grupy: Metryka, Lokalizacja, Opis i Bibliografia.</p>
                          <p className="effect">Wyszukane okazy/notowania muszą spełniać wszystkie wybrane przez użytkownika kryteria wyboru (warunek „i”).</p>
                        </div>
                  </div>
                </CarouselItem>
                <CarouselItem>
                  <div className="d-flex slider-item">
                        <div className="d-flex justify-content-center slider-img-container">
                          <img src="/images/how-to-use/Wyszukiwarka_rozszerzona_1.png" alt={"Zrzut ekranu przedstawiający stronę z przykładowym formularzem wyszukiwania rozszerzonego"}></img>
                        </div>
                        <div className="d-flex flex-column text-container">
                          <h3>1. Wybierz kryteria wyszukiwania, określ i zapisz nazwę formularza.</h3>
                          <p className="effect">Wyświetlają się warunki wyboru okazów, w tym jednostka nadrzędna, którą jest przykładowo rodzina Papaveraceae.</p>
                          <p className="comment">Zwróć uwagę, że tak zdefiniowany formularz będzie można używać w dalszych analizach, zmieniając wartości w kryteriach wyszukiwania. </p>
                        </div>
                  </div>
                </CarouselItem>
                <CarouselItem>
                  <div className="d-flex slider-item">
                        <div className="d-flex justify-content-center slider-img-container">
                          <img src="/images/how-to-use/Wyszukiwarka_rozszerzona_2.png" alt={"Zrzut ekranu przedstawiający stronę z wynikiem wyszukiwania rozszerzonego"}></img>
                        </div>
                        <div className="d-flex flex-column text-container">
                          <h3>2. Wpisz wartości zgodnie z wybranymi kryteriami wyszukiwania i wybierz polecenie „Szukaj”.</h3>
                          <p className="effect">Ukazuje się wynik w formie identycznej jak w wyszukiwarce podstawowej (por. Krok 1).</p>
                          <p className="comment">Zwróć uwagę, że dalsze możliwości i procedury analityczne są podobne jak w wyszukiwarce podstawowej (por. Kroki 2-11).</p>
                        </div>
                  </div>
                </CarouselItem>
                <CarouselItem>
                  <div className="d-flex slider-item">
                        <div className="d-flex justify-content-center slider-img-container">
                          <img src="/images/how-to-use/Wyszukiwarka_rozszerzona_3.png" alt={"Zrzut ekranu przedstawiający stronę z formularzem wyszukiwania i panelem zarządzania zapisanymi zapytaniami"}></img>
                        </div>
                        <div className="d-flex flex-column text-container">
                          <h3>3. Wybierz polecenie „Wczytaj” i wpisz nowe zmienne do zapisanego formularza.</h3>
                          <p className="effect">Forma prezentacji wyniku wyszukiwania i dalsze procedury analityczne identyczne jak w Kroku 2.</p>
                          <p className="comment">Zwróć uwagę, że zapisany formularz można usunąć z listy lub dodać do albumu.</p>
                        </div>
                  </div>
                </CarouselItem>
                <CarouselItem>
                  <div className="d-flex slider-item">
                        <div className="d-flex justify-content-center slider-img-container">
                          <img src="/images/how-to-use/Wyszukiwarka_rozszerzona_4.png" alt={"Zrzut ekranu przedstawiający stronę z formularzem dodawania wyszukanego okazu do albumu "}></img>
                        </div>
                        <div className="d-flex flex-column text-container">
                          <h3>4. Wybierz polecenie „Dodaj do albumu”, a następnie utwórz nazwę nowego albumu.</h3>
                          <p className="effect">Album to zbiór danych użytkownika, zapisywanych w obszarze „Mój AMUNATCOLL”. </p>
                          <p className="effect">W tym przykładzie nadano mu nazwę „Flora parków narodowych”.</p>
                        </div>
                  </div>
                </CarouselItem>
                <CarouselItem>
                  <div className="d-flex slider-item">
                        <div className="d-flex justify-content-center slider-img-container">
                          <img src="/images/how-to-use/Wyszukiwarka_rozszerzona_5.png" alt={"Zrzut ekranu przedstawiający stronę z listą własnych albumów "}></img>
                        </div>
                        <div className="d-flex flex-column text-container">
                          <h3>5. Wybierz polecenie „Dodaj” przy nazwie nowego albumu, a następnie przejdź do obszaru „Mój AMUNATCOLL/Moje albumy”.</h3>
                          <p className="effect">Ukazuje się wykaz Twoich albumów, w tym ostatnio utworzony.</p>
                          <p className="comment"><b>UWAGA!</b> Wskazówki do korzystania z albumu znajdują się w „Jak korzystać/Mój AMUNATCOOL/Album.</p>
                        </div>
                  </div>
                </CarouselItem>
            </Carousel>
            </div>
          </section>
          <section id={TAXONDB_PAGE_URL_PL+"_zaawansowana"} className="my-5">
            <h2>Wyszukiwarka okazów - zaawansowana</h2>
            <div className="d-flex slider-content">
              <Carousel indicators={true} interval={7000} variant="dark" prevLabel={t('common:previous')} nextLabel={t('common:next')}>
                <CarouselItem>
                  <div className="d-flex slider-item">
                        <div className="d-flex justify-content-center slider-img-container">
                          <img src="/images/how-to-use/Wyszukiwarka_zaawansowana_0.png" alt={"Zrzut ekranu przedstawiający pusty formularz wyszukiwarki zaawansowanej."}></img>
                        </div>
                        <div className="d-flex flex-column text-container">
                          <h3>Wyszukiwarki specjalistyczne</h3>
                          <p className="effect">We wszystkich wyszukiwarkach specjalistycznych nazwy taksonów (gatunków, rodzajów, jednostek nadrzędnych) określa się w języku łacińskim.</p>
                          <h3>Wyszukiwarka zaawansowana</h3>
                          <p className="effect">Wykorzystuje identyczny zestaw 37 kryteriów selekcji jak wyszukiwarka rozszerzona.</p>
                          <p className="effect">Użytkownik może dodatkowo określać dwa warunki wyszukiwania: alternatywny (warunek „lub”) i wykluczający (warunek „nie”).</p>
                        </div>
                  </div>
                </CarouselItem>
                <CarouselItem>
                  <div className="d-flex slider-item">
                        <div className="d-flex justify-content-center slider-img-container">
                          <img src="/images/how-to-use/Wyszukiwarka_zaawansowana_1.png" alt={"Zrzut ekranu przedstawiający stronę z przykładowym formularzem wyszukiwania zaawansowanego"}></img>
                        </div>
                        <div className="d-flex flex-column text-container">
                          <h3>1. Wprowadź kryteria z jednym  warunkiem wykluczającym.</h3>
                          <p className="effect">Ukazuje się lista kryteriów selekcji i warunki. </p>
                          <p className="comment">Zwróć uwagę, że z jednostek nadrzędnych (np. rodzin) w tej procedurze można będzie wykluczać wskazane gatunki lub rodzaje (ostatni warunek). </p>
                        </div>
                  </div>
                </CarouselItem>
                <CarouselItem>
                  <div className="d-flex slider-item">
                        <div className="d-flex justify-content-center slider-img-container">
                          <img src="/images/how-to-use/Wyszukiwarka_zaawansowana_2.png" alt={"Zrzut ekranu przedstawiający stronę z rozbudowanymformularzem wyszukiwania zaawansowanego"}></img>
                        </div>
                        <div className="d-flex flex-column text-container">
                          <h3>2. Wprowadź przykładowe wartości i wybierz polecenie „Szukaj”.</h3>
                          <p className="effect">Ukazują się wpisane wartości i wynik wyszukiwania.</p>
                          <p className="comment">Zwróć uwagę na możliwość formułowania wielu kryteriów w ramach trzech warunków: „oraz”, „lub”, „nie”.</p>
                        </div>
                  </div>
                </CarouselItem>
                <CarouselItem>
                  <div className="d-flex slider-item">
                        <div className="d-flex justify-content-center slider-img-container">
                          <img src="/images/how-to-use/Wyszukiwarka_zaawansowana_3.png" alt={"Zrzut ekranu przedstawiający stronę z wynikiem wyszukiwania zaawansowanego"}></img>
                        </div>
                        <div className="d-flex flex-column text-container">
                          <h3>3. Przewiń ekran, sprawdź wyniki wyszukiwania, a następnie zapisz nazwę formularza.</h3>
                          <p className="effect">Ukazuje się wynik wyszukiwania w formie identycznej jak w wyszukiwarce podstawowej (por. Krok 1).</p>
                          <p className="comment">Zwróć uwagę, że w wynikach wyszukiwania brak rodzaju Papaver, który chociaż należy do rodziny Papaveraceae i występuje w Wielkopolskim Parku Narodowym to został wykluczony na etapie formułowania warunków selekcji.</p>
                        </div>
                  </div>
                </CarouselItem>
            </Carousel>
            </div>
          </section>
          <section id={SAMPLES_SEARCH_PAGE_URL_PL} className="my-5">
            <h2>Wyszukiwarka prób</h2>
            <div className="d-flex slider-content">
              <Carousel indicators={true} interval={7000} variant="dark" prevLabel={t('common:previous')} nextLabel={t('common:next')}>
                <CarouselItem>
                  <div className="d-flex slider-item">
                        <div className="d-flex justify-content-center slider-img-container">
                          <img src="/images/how-to-use/Wyszukiwarka_prob_0.png" alt={"Zrzut ekranu przedstawiający pusty formularz wyszukiwarki prób."}></img>
                        </div>
                        <div className="d-flex flex-column text-container">
                          <h3>Wyszkukiwarka prób</h3>
                          <p className="effect">Umożliwia wyszukiwanie próbek zebranych w terenie, zawierających głównie nieopracowane grupy zwierząt bezkręgowych.</p>
                          <p className="effect">Do dyspozycji użytkownika są cztery grupy kryteriów wyszukiwania.</p>
                          <p className="effect">W poniższym przykładzie pokażemy w jaki sposób można uzyskać informację o próbie zebranej na wybranym stanowisku.</p>
                        </div>
                  </div>
                </CarouselItem>
                <CarouselItem>
                  <div className="d-flex slider-item">
                        <div className="d-flex justify-content-center slider-img-container">
                          <img src="/images/how-to-use/Wyszukiwarka_prob_1.png" alt={"Zrzut ekranu przedstawiający dodawanie pola do formularza wyszukiwania prób"}></img>
                        </div>
                        <div className="d-flex flex-column text-container">
                          <h3>1. Wybierz polecenie „Dodaj” a następnie „Lokalizacja próby”.</h3>
                          <p className="effect">Ukazuje się wykaz cech lokalizacji, które można wykorzystać do selekcji prób.</p>
                          <p className="comment">Zwróć uwagę na cechę:  Lokalizacja/Stanowisko.</p>
                        </div>
                  </div>
                </CarouselItem>
                <CarouselItem>
                  <div className="d-flex slider-item">
                        <div className="d-flex justify-content-center slider-img-container">
                          <img src="/images/how-to-use/Wyszukiwarka_prob_2.png" alt={"Zrzut ekranu przedstawiający wybów wartości dla pola lokalizacji"}></img>
                        </div>
                        <div className="d-flex flex-column text-container">
                          <h3>2. Wybierz cechę „Lokalizacja/Stanowisko”, a następnie w polu „Stanowisko” wpisz znak *</h3>
                          <p className="effect">Ukazuje się wykaz stanowisk, z których pochodzą próby.</p>
                        </div>
                  </div>
                </CarouselItem>
                <CarouselItem>
                  <div className="d-flex slider-item">
                        <div className="d-flex justify-content-center slider-img-container">
                          <img src="/images/how-to-use/Wyszukiwarka_prob_3.png" alt={"Zrzut ekranu przedstawiający listę wyszukanych prób"}></img>
                        </div>
                        <div className="d-flex flex-column text-container">
                          <h3>3. Wybierz pierwsze stanowisko z wykazu, a następnie polecenie „Szukaj”.</h3>
                          <p className="effect">Wynik wyszukiwania wyświetla się w formie wykazu prób, których rozmieszczenie można zobaczyć na mapie.</p>
                        </div>
                  </div>
                </CarouselItem>
                <CarouselItem>
                  <div className="d-flex slider-item">
                        <div className="d-flex justify-content-center slider-img-container">
                          <img src="/images/how-to-use/Wyszukiwarka_prob_4.png" alt={"Zrzut ekranu przedstawiający szczegóły wybranej próby"}></img>
                        </div>
                        <div className="d-flex flex-column text-container">
                          <h3>4. Wybierz dowolną próbę z wykazu przyciskając znak <img src="/images/list-closed.svg" alt={t("a11y:list-closed")} className="closed-icon-img"/>.</h3>
                          <p className="effect">W wyniku wyszukiwania można uzyskać informacje o zawartości i lokalizacji próby, a także sprawdzić lokalizację na mapie.</p>
                        </div>
                  </div>
                </CarouselItem>
                <CarouselItem>
                  <div className="d-flex slider-item">
                        <div className="d-flex justify-content-center slider-img-container">
                          <img src="/images/how-to-use/Wyszukiwarka_prob_5.png" alt={"Zrzut ekranu przedstawiający lokalizacje wybranej próby na mapie"}></img>
                        </div>
                        <div className="d-flex flex-column text-container">
                          <h3>5. Wybierz zakładkę „Mapa”.</h3>
                          <p className="effect">Ukazuje się mapa z lokalizacją próby.</p>
                        </div>
                  </div>
                </CarouselItem>
            </Carousel>
            </div>
          </section>
          <section id={ICONDB_PAGE_URL_PL} className="my-5">
            <h2>Wyszukiwarka multimediów</h2>
            <div className="d-flex slider-content">
              <Carousel indicators={true} interval={7000} variant="dark" prevLabel={t('common:previous')} nextLabel={t('common:next')}>
                <CarouselItem>
                  <div className="d-flex slider-item">
                        <div className="d-flex justify-content-center slider-img-container">
                          <img src="/images/how-to-use/Wyszukiwarka_multimediow_0.png" alt={"Zrzut ekranu przedstawiający pusty formularz wyszukiwarki multimediów."}></img>
                        </div>
                        <div className="d-flex flex-column text-container">
                          <h3>Wyszukiwarka multimediów</h3>
                          <p className="effect">Umożliwia wyszukiwanie i przeglądanie dokumentów multimedialnych ukazujących siedliska i krajobrazy przyrodnicze, a także gatunki zobrazowane na podstawie okazów nie ujętych w podstawowej bazie danych.</p>
                          <p className="effect">W bazie „Multimedia” znajdują się również mapy rozmieszczenia gatunków.</p>
                          <p className="effect">Do wyszukania obiektu można wykorzystać od 1 do 8 kryteriów selekcji.</p>
                        </div>
                  </div>
                </CarouselItem>
                <CarouselItem>
                  <div className="d-flex slider-item">
                        <div className="d-flex justify-content-center slider-img-container">
                          <img src="/images/how-to-use/Wyszukiwarka_multimediow_1.png" alt={"Zrzut ekranu przedstawiający stronę z galerią wyszukanych multimediów"}></img>
                        </div>
                        <div className="d-flex flex-column text-container">
                          <h3>1. Wprowadź znak * do pola „Nazwa obszaru chronionego” i wybierz dowolną nazwę, a następnie polecenie „Szukaj”</h3>
                          <p className="effect">Ukazuje się galeria zdjęć gatunków, siedlisk i krajobrazów wykonanych na terenie wybranego obszaru chronionego.</p>
                        </div>
                  </div>
                </CarouselItem>
                <CarouselItem>
                  <div className="d-flex slider-item">
                        <div className="d-flex justify-content-center slider-img-container">
                          <img src="/images/how-to-use/Wyszukiwarka_multimediow_2.png" alt={"Zrzut ekranu przedstawiający stronę z opisem wybranego zdjęcia"}></img>
                        </div>
                        <div className="d-flex flex-column text-container">
                          <h3>2. Wybierz dowolne zdjęcie z galerii.</h3>
                          <p className="effect">Ukazuje się wybrane zdjęcie wraz z opisem.</p>
                          <p className="comment">Zwróć uwagę, że lokalizację zdjęć można sprawdzić na mapie.</p>
                        </div>
                  </div>
                </CarouselItem>
            </Carousel>
            </div>
          </section>
          <section id={BIBLIOGRAPHY_SEARCH_PAGE_URL_PL} className="my-5">
            <h2>Wyszukiwarka bibliografii</h2>
            <div className="d-flex slider-content">
              <Carousel indicators={true} interval={7000} variant="dark" prevLabel={t('common:previous')} nextLabel={t('common:next')}>
                <CarouselItem>
                  <div className="d-flex slider-item">
                        <div className="d-flex justify-content-center slider-img-container">
                          <img src="/images/how-to-use/Wyszukiwarka_bibliografii_0.png" alt={"Zrzut ekranu przedstawiający pusty formularz wyszukiwarki bibliografii."}></img>
                        </div>
                        <div className="d-flex flex-column text-container">
                          <h3>Wyszukiwarka bibliografii</h3>
                          <p className="effect">Umożliwia wyszukiwanie i przeglądanie źródeł bibliograficznych cytowanych w opisie okazów, próbek biologicznych i dokumentów multimedialnych.</p>
                          <p className="effect">Do wyszukania źródła bibliograficznego można wykorzystać 6 kryteriów selekcji.</p>
                        </div>
                  </div>
                </CarouselItem>
                <CarouselItem>
                  <div className="d-flex slider-item">
                        <div className="d-flex justify-content-center slider-img-container">
                          <img src="/images/how-to-use/Wyszukiwarka_bibliografii_1.png" alt={"Zrzut ekranu przedstawiający podpowiadane wartości pola cytowanie"}></img>
                        </div>
                        <div className="d-flex flex-column text-container">
                          <h3>1. Wpisz znak * w pole „Cytowanie” i wybierz dowolny cytat.</h3>
                          <p className="effect">Ukazuje się wykaz autorów prac cytowanych w opisie okazów, próbek lub dokumentów multimedialnych wraz z datą opublikowania.</p>
                        </div>
                  </div>
                </CarouselItem>
                <CarouselItem>
                  <div className="d-flex slider-item">
                        <div className="d-flex justify-content-center slider-img-container">
                          <img src="/images/how-to-use/Wyszukiwarka_bibliografii_2.png" alt={"Zrzut ekranu przedstawiający listę wyników wyszukiwania bibliografii"}></img>
                        </div>
                        <div className="d-flex flex-column text-container">
                          <h3>2. Wybierz dowolną pozycję z wykazu i polecenie „Szukaj”.</h3>
                          <p className="effect">Ukazuje się liczba okazów, przy których znajduje się wybrane cytowanie oraz skrócony opis bibliograficzny cytowanego źródła.</p>
                        </div>
                  </div>
                </CarouselItem>
                <CarouselItem>
                  <div className="d-flex slider-item">
                        <div className="d-flex justify-content-center slider-img-container">
                          <img src="/images/how-to-use/Wyszukiwarka_bibliografii_3.png" alt={"Zrzut ekranu przedstawiający szczegóły wybranej bibliografii"}></img>
                        </div>
                        <div className="d-flex flex-column text-container">
                          <h3>3. Wybierz przycisk <img src="/images/list-closed.svg" alt={t("a11y:list-closed")} className="closed-icon-img"/> </h3>
                          <p className="effect">Wyświetla się pełen opis  bibliograficzny cytowanego źródła.</p>
                        </div>
                  </div>
                </CarouselItem>
            </Carousel>
            </div>
          </section>
          <section id={EDUCATION_MATERIALS_PAGE_URL_PL} className="my-5">
            <h2>Wyszukiwarka edukacyjna</h2>
            <div className="d-flex slider-content">
              <Carousel indicators={true} interval={7000} variant="dark" prevLabel={t('common:previous')} nextLabel={t('common:next')}>
                <CarouselItem>
                  <div className="d-flex slider-item">
                        <div className="d-flex justify-content-center slider-img-container">
                          <img src="/images/how-to-use/Wyszukiwarka_edukacyjna_0.png" alt={"Zrzut ekranu przedstawiający pusty formularz wyszukiwarki zaawansowanej."}></img>
                        </div>
                        <div className="d-flex flex-column text-container">
                          <h3>Wyszukiwarka edukacyjna</h3>
                          <p className="effect">Umożliwia wyszukiwanie i przeglądanie materiałów edukacyjnych i szkoleniowych przygotowanych dla różnych grup użytkowników na podstawie dokumentów zgromadzonych w głównej bazie AMUNATCOLL i na koncie Mój AMUNATCOLL.</p>
                        </div>
                  </div>
                </CarouselItem>
                <CarouselItem>
                  <div className="d-flex slider-item">
                        <div className="d-flex justify-content-center slider-img-container">
                          <img src="/images/how-to-use/Wyszukiwarka_edukacyjna_1.png" alt={"Zrzut ekranu przedstawiający stronę z listą materiałów edukacyjnych"}></img>
                        </div>
                        <div className="d-flex flex-column text-container">
                          <h3>1. Wybierz przykładowy materiał edukacyjny z wykazu tematów.</h3>
                          <p className="effect">Ukazuje się materiał edukacyjny w formie zbioru slajdów.</p>
                          <p className="comment">Zwróć uwagę na możliwość eksportowania prezentacji do pliku pdf.</p>
                        </div>
                  </div>
                </CarouselItem>
                <CarouselItem>
                  <div className="d-flex slider-item">
                        <div className="d-flex justify-content-center slider-img-container">
                          <img src="/images/how-to-use/Wyszukiwarka_edukacyjna_2.png" alt={"Zrzut ekranu przedstawiający stronę z przykładowym slajdem prezentacji edukacyjnej"}></img>
                        </div>
                        <div className="d-flex flex-column text-container">
                          <h3>2. Przewiń ekran i zobacz zestaw informacji związanych ze slajdem.</h3>
                          <p className="effect">Ukazuje się MENU z szerokim zakresem informacji do wyboru.</p>
                        </div>
                  </div>
                </CarouselItem>
                <CarouselItem>
                  <div className="d-flex slider-item">
                        <div className="d-flex justify-content-center slider-img-container">
                          <img src="/images/how-to-use/Wyszukiwarka_edukacyjna_3.png" alt={"Zrzut ekranu przedstawiający szczegóły okazu zamieszczonego w prezentacji edukacyjnej"}></img>
                        </div>
                        <div className="d-flex flex-column text-container">
                          <h3>3. Wybierz zakładkę „Mapa”</h3>
                          <p className="effect">Ukazuje się lokalizacja prezentowanego obiektu na mapie.</p>
                        </div>
                  </div>
                </CarouselItem>
            </Carousel>
            </div>
          </section>

        </Container>
      </div>
    </Suspense>
  )
}
const mapStateToProps = state => ({
  anchor: state.howToUse ? state.howToUse.anchor : null,
})

const mapDispatchToProps = dispatch => ({
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HowToUse)
