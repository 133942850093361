import React, { forwardRef } from 'react';
import { connect } from 'react-redux'
import { Form } from "react-bootstrap"
import { useTranslation } from 'react-i18next'
import '../../utils/map/leaflet.loader'
import useShapefilesHandler from '../../hooks/useShapefilesHandler';

const ShapefilesHandler = forwardRef((props, ref) => {

    const {handleShapefilesLayers, loadingShapefile} = useShapefilesHandler()

    const { t } = useTranslation(['map'])

    const mapHasLayer = (name) => {
        let response = false
        try {
            ref.mapRef.current.eachLayer(function (layer) {
                if (layer.name === name) {
                    response = true;
                }
            })
        }
        catch (e) {
            //mapRef not rendered yet
        }
        return response;
    }


    return (
        <div>
            <div className="ml-2 mt-2">
                <div><b>{t("shapefiles-button-title")}</b></div>
            </div>
            <div className="ml-3">
                <Form.Check id="Panstwa_3857" type="checkbox" defaultChecked={mapHasLayer("Panstwa_3857")} onChange={e => handleShapefilesLayers(e.target, ref.mapRef)} name="Panstwa_3857" label={t("countries")} disabled={loadingShapefile}></Form.Check>
                <Form.Check id="wojewodztwa_3857" type="checkbox" defaultChecked={mapHasLayer("wojewodztwa_3857")} onChange={e => handleShapefilesLayers(e.target, ref.mapRef)} name="wojewodztwa_3857" label={t("voivodeships")} disabled={loadingShapefile}></Form.Check>
                <Form.Check id="powiaty_3857" type="checkbox" defaultChecked={mapHasLayer("powiaty_3857")} onChange={e => handleShapefilesLayers(e.target, ref.mapRef)} name="powiaty_3857" label={t("powiaty")} disabled={loadingShapefile}></Form.Check>
                <Form.Check id="gminy_3857" type="checkbox" defaultChecked={mapHasLayer("gminy_3857")} onChange={e => handleShapefilesLayers(e.target, ref.mapRef)} name="gminy_3857" label={t("gminy")} disabled={loadingShapefile}></Form.Check>
                <Form.Check id="ParkiNarodowePolygon_3857" type="checkbox" defaultChecked={mapHasLayer("ParkiNarodowePolygon_3857")} onChange={e => handleShapefilesLayers(e.target, ref.mapRef)} name="ParkiNarodowePolygon_3857" label={t("national-parks")} disabled={loadingShapefile}></Form.Check>
                <Form.Check id="ParkiKrajobrazowePolygon_3857" type="checkbox" defaultChecked={mapHasLayer("ParkiKrajobrazowePolygon_3857")} onChange={e => handleShapefilesLayers(e.target, ref.mapRef)} name="ParkiKrajobrazowePolygon_3857" label={t("landscape-park")} disabled={loadingShapefile}></Form.Check>
                <Form.Check id="RezerwatyPolygon_3857" type="checkbox" defaultChecked={mapHasLayer("RezerwatyPolygon_3857")} onChange={e => handleShapefilesLayers(e.target, ref.mapRef)} name="RezerwatyPolygon_3857" label={t("nature-reserves")} disabled={loadingShapefile}></Form.Check>
                <Form.Check id="SpecjalneObszaryOchronyPolygon_3857" type="checkbox" defaultChecked={mapHasLayer("SpecjalneObszaryOchronyPolygon_3857")} onChange={e => handleShapefilesLayers(e.target, ref.mapRef)} name="SpecjalneObszaryOchronyPolygon_3857" label={t("sac")} disabled={loadingShapefile}></Form.Check>
                <Form.Check id="ObszarySpecjalnejOchronyPolygon_3857" type="checkbox" defaultChecked={mapHasLayer("ObszarySpecjalnejOchronyPolygon_3857")} onChange={e => handleShapefilesLayers(e.target, ref.mapRef)} name="ObszarySpecjalnejOchronyPolygon_3857" label={t("spa")} disabled={loadingShapefile}></Form.Check>
                <Form.Check id="UzytkiEkologicznePolygon_3857" type="checkbox" defaultChecked={mapHasLayer("UzytkiEkologicznePolygon_3857")} onChange={e => handleShapefilesLayers(e.target, ref.mapRef)} name="UzytkiEkologicznePolygon_3857" label={t("uzytki-ekologiczne")} disabled={loadingShapefile}></Form.Check>
                <Form.Check id="ObszaryChronionegoKrajobrazuPolygon_3857" type="checkbox" defaultChecked={mapHasLayer("ObszaryChronionegoKrajobrazuPolygon_3857")} onChange={e => handleShapefilesLayers(e.target, ref.mapRef)} name="ObszaryChronionegoKrajobrazuPolygon_3857" label={t("protected-areas")} disabled={loadingShapefile}></Form.Check>
                <Form.Check id="ZespolyPrzyrodniczoKrajobrazowePolygon_3857" type="checkbox" defaultChecked={mapHasLayer("ZespolyPrzyrodniczoKrajobrazowePolygon_3857")} onChange={e => handleShapefilesLayers(e.target, ref.mapRef)} name="ZespolyPrzyrodniczoKrajobrazowePolygon_3857" label={t("zespoly-przyrodniczo-krajobrazowe")} disabled={loadingShapefile}></Form.Check>
                <Form.Check id="StanowiskaDokumentacyjnePolygon_3857" type="checkbox" defaultChecked={mapHasLayer("StanowiskaDokumentacyjnePolygon_3857")} onChange={e => handleShapefilesLayers(e.target, ref.mapRef)} name="StanowiskaDokumentacyjnePolygon_3857" label={t("stanowiska-dokumentacyjne")} disabled={loadingShapefile}></Form.Check>
                <Form.Check id="LocationPhysiographicDivision" type="checkbox" defaultChecked={mapHasLayer("LocationPhysiographicDivision")} onChange={e => handleShapefilesLayers(e.target, ref.mapRef)} name="LocationPhysiographicDivision" label={t("podzial-fizycznogeograficzny")} disabled={loadingShapefile}></Form.Check>
                <Form.Check id="UTM_PL" type="checkbox" defaultChecked={mapHasLayer("UTM_PL")} onChange={e => handleShapefilesLayers(e.target, ref.mapRef)} name="UTM_PL" label={t("utm-grid-pl")} disabled={loadingShapefile}></Form.Check>
            </div>
        </div>
    )
})

const mapStateToProps = state => ({
    administrativeDivisionStyle: state.map.administrativeDivisionStyle
})

const mapDispatchToProps = dispatch => ({

})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(ShapefilesHandler)
