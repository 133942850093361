import React, { useState, Suspense } from "react";
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { isPending, getOutput } from "../../reducers";
import { Row, Col, Spinner } from "react-bootstrap"
import RecordDetails from './RecordDetails'
import AddToAlbum from '../profile/AddToAlbum'
import {
    API_ALBUM_GET_ALL_ID,
    API_ALBUM_GET_ALL_URL,
    SAMPLESDB_DETAILS_PAGE_URL,
} from '../../settings'
import { postDataApi } from '../../actions'
import { Redirect } from "react-router-dom";
import { addPictureOne, addPictureTwo, clearComparator } from '../../actions/comparator'
import Loader from '../../utils/loader'

const RecordDetailsContainer = props => {
    const { t } = useTranslation([ 'common', 'forms', 'taxondb', 'profile', 'icondb', 'paths' ])

    const [ addAlbumOpen, setAddAlbumOpen ] = useState(false)
    const [ redirect, setRedirect ] = useState(false)

    if (redirect) {
        return <Redirect push to={t('paths:taxonDB')} />
    }

    const shareProps = {
        recordId: props.recordId,
        detailsUrl: SAMPLESDB_DETAILS_PAGE_URL,
        setAddAlbumOpen: setAddAlbumOpen,
        setRedirect: setRedirect
    }

    return (<div>
        <Suspense fallback={<Loader />}>
            <div className="d-flex flex-column pt-2 common-panel-background">
                {addAlbumOpen &&
                    <AddToAlbum kind="sample" itemId={props.recordId} onDone={e => setAddAlbumOpen(false)}></AddToAlbum>
                }
                {props.loading &&
                    <div className="align-self-center m-3">
                        <Spinner animation="border" role="status" />
                    </div>
                }
                <div>
                    <Row>
                        <Col md={(!props.loading && props.data && props.data[ "images" ] && props.data.images.length > 0) ? 6 : 12}>
                            {!props.loading && props.data &&
                                <RecordDetails requestId={props.requestId} recordId={props.recordId} {...shareProps}></RecordDetails>
                            }
                            {!props.loading && !props.data &&
                                <div className="d-flex justify-content-center">
                                    <p>{t('no-records-found')}</p>
                                </div>
                            }
                        </Col>
                    </Row>
                </div>
            </div>
        </Suspense>
    </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    loading: isPending(ownProps.requestId, state),
    data: getOutput(ownProps.requestId, state),
    user: state.user,
    comparator: state.comparator,
})

const mapDispatchToProps = dispatch => ({
    getAlbums: () => dispatch(postDataApi(API_ALBUM_GET_ALL_ID, API_ALBUM_GET_ALL_URL)),
    addPictureOneToComparator: (data) => dispatch(addPictureOne(data)),
    addPictureTwoToComparator: (data) => dispatch(addPictureTwo(data)),
    clearComparator: () => dispatch(clearComparator())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RecordDetailsContainer)
