import React from "react";
import { Container } from "react-bootstrap"
import {
    SAMPLESDB_SEARCH_MAP_DATA_REQUEST_ID
} from "../../settings"
import { Tab1 as Tab1Taxons } from "../taxonDB/RecordDetails"
import { Tab2 as Tab2Samples } from "../samplesDB/RecordDetails"

const MapPopupContent = props => {
    console.log(props)
    return <Container className="d-flex flex-column mt-2">
        {props.manySpecies &&
            <div className="mb-3">
                {props.t('taxondb:map_to_many_labels_on_zoom')}
            </div>
        }
        {props.searchId === SAMPLESDB_SEARCH_MAP_DATA_REQUEST_ID
            ? <Tab2Samples {...props} directRender></Tab2Samples>
            : <Tab1Taxons {...props} directRender></Tab1Taxons>}
    </Container>
}

export default MapPopupContent