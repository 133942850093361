import React, { Suspense } from "react";
import { connect } from 'react-redux'
import { isPending, getFilter, getItems, getOutput, getField } from "../../reducers";
import { Container, Spinner, Tab, Tabs } from "react-bootstrap"
import ResultsList from './ResultsList'
import Loader from '../../utils/loader'
import { useTranslation } from "react-i18next";
import MultilabelMap from "./MultilabelMap";
import * as actions from '../../actions'
import { setField } from '../../actions/forms'
import {
    ICONDB_SEARCH_MAP_DATA_REQUEST_ID,
    ICONDB_SEARCH_MAP_DATA_REQUEST_URL,
} from '../../settings'

const ResultsContainer = props => {
    const {t} = useTranslation(["icondb"])

return(
    <Suspense fallback={<Loader />}>
        <Container className="d-flex flex-column mt-3">
            {props.loading &&
                <div className="align-self-center" style={{ height: "100vh" }}>
                    <Spinner animation="border" role="status" />
                </div>
            }
            {!props.loading && props.items &&
            <>
                <div className="search-results">{t('common:search-results')}</div>
                <Tabs defaultActiveKey={props.activeTab} id={props.searchRequestId} activeKey={props.activeTab} onSelect={props.setActiveTab}>
                    <Tab eventKey="0" title={t("gallery")}>
                    {props.activeTab === "0" &&
                        <ResultsList requestId={props.searchRequestId}></ResultsList>
                    }
                    </Tab>
                    <Tab eventKey="1" title={t("map")}>
                        {props.activeTab === "1" &&
                        <MultilabelMap requestId={props.searchRequestId}/>
                        }
                    </Tab>
                </Tabs>
            </>
            }
        </Container>
    </Suspense>
    )
}

const mapStateToProps = (state, ownProps) => ({
    items: getItems(ownProps.searchRequestId, state),
    loading: isPending(ownProps.searchRequestId, state),
    filter: getFilter(ownProps.searchRequestId, state),
    data: getOutput(ownProps.requestId, state),
    activeTab: (getField(ownProps.tabId, ownProps.tabId, state) || '0'),
})


const mapDispatchToProps = (dispatch, ownProps) => ({
    setActiveTab: value => dispatch(setField(ownProps.tabId, ownProps.tabId, value)),
    searchMapData: data => dispatch(actions.postDataAnc(ICONDB_SEARCH_MAP_DATA_REQUEST_ID, ICONDB_SEARCH_MAP_DATA_REQUEST_URL, data)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResultsContainer)

