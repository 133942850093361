import L from 'leaflet';
import {
    MAP_LAYER_ANIMATION_CONST,
    MAP_UNCLASTERED_MAXIMUM_MARKERS,
    TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID,
    TAXONDB_SEARCH_GENUS_REQUEST_ID,
    TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID,
} from '../../../../settings'
import { renderIconHtmlWithSize } from '../../../../utils/map/renderIconHtml'
import * as notify from '../../../../utils/notify'
import { ROK_ZBIORU_FIELD_NAME } from '../../../taxonDB/attributesStructure';

export const historicalMapSearch = (ref, props, t) => {

    const UNASSIGNED_DATE_STRING = 'bd'

    const getFilterId = () => {
        let id
        if (props.isDynamic === "0"){
            id = TAXONDB_SEARCH_GENUS_REQUEST_ID
        }
        if (props.isDynamic === "2"){
            id = TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID
        }
        if (props.isDynamic === "1"){
            id = TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID
        }
        return id
    }

    const getCurrentFilter = () => {
        let filter 
        if (props.isDynamic === "0"){
            filter = props.filter1
        }
        if (props.isDynamic === "2"){
            filter = props.filter2
        }
        if (props.isDynamic === "1"){
            filter = props.filter3
        }
        return filter
    }

    const displayTaxons = (dataToDisplay) => {
        ref.mapRef.current.addLayer(ref.animationLayerRef.current)
        if (dataToDisplay){
            let icon = renderIconHtmlWithSize(props.markerIcon, props.markerColor, props.markerSize)
            const fontAwesomeIcon = new L.divIcon({
                html: icon,
                iconSize: [20, 20],
                className: 'myDivIcon'
            });
            dataToDisplay.forEach(year => {
                year.forEach(taxonByYear => {
                    const marker = L.marker([taxonByYear[0], taxonByYear[1]], { icon: fontAwesomeIcon })
                    marker['kolekcjanumerokazu'] = taxonByYear[2]
                    marker.name = MAP_LAYER_ANIMATION_CONST
                    marker.bindPopup(`<b>${taxonByYear[2]}</b> (${taxonByYear[3]})`)
                    marker.addTo(ref.animationLayerRef.current)
                })
            })
            ref.mapRef.current.flyToBounds(ref.animationLayerRef.current.getBounds())
        }
    }

    const clearMapLayers = () => {
        try{
            if (props.animationTimeouts && props.animationTimeouts.length){
                props.animationTimeouts.forEach(id => clearTimeout(id))
            }
            ref.mapRef.current.eachLayer(function(layer){
                if (layer.name === (MAP_LAYER_ANIMATION_CONST)){
                    ref.mapRef.current.removeLayer(layer);
                }
            });
            ref.animationLayerRef.current.eachLayer(function (layer) {
                ref.animationLayerRef.current.removeLayer(layer);
            })
            if (ref.animationDisplayedYearDiv.current){
                ref.animationDisplayedYearDiv.current.remove()
            }
        }
        catch(e){
            
        }
    }

    props.closeSlidebar()
    let loader = L.control.loader()
    setTimeout(() => { // delay to allow map to load
        loader.addTo(ref.mapRef.current);
    }, 50);
    clearMapLayers()
    props.setArrayOfYears([])        
    props.setTooManyTaxonsWarning(false)        
    props.setTaxonsByYear(new Map())
    return props.runHistoricalAnalysis(getFilterId(), getCurrentFilter(), displayTaxons)
    .then(res => {
        let arrayOfYears = []
        res.items.forEach(item => arrayOfYears.push(item[ROK_ZBIORU_FIELD_NAME]))
        arrayOfYears.sort()
        props.setArrayOfYears(arrayOfYears)
        props.postDataAnc(getCurrentFilter())
        .then(response => {
            let mapOfTaxonsByYears = new Map()
            if (response.data && response.data.length && response.data.length <= MAP_UNCLASTERED_MAXIMUM_MARKERS){
                if(arrayOfYears){
                    arrayOfYears.forEach(item => {
                        response.data.forEach(taxon => {
                            if (taxon[3] === item){
                                mapOfTaxonsByYears.get(item) ? mapOfTaxonsByYears.get(item).push(taxon) : mapOfTaxonsByYears.set(item, [taxon])
                            }
                        })
                    })
                    let dataToDisplay = Array.from(mapOfTaxonsByYears.values())
                    props.setTaxonsByYear(mapOfTaxonsByYears)
                    let arrayLength = [...mapOfTaxonsByYears.keys()].length 
                    let yearsArray = [...mapOfTaxonsByYears.keys()]
                    const earliestYear = Number(yearsArray[0])
                    const latestYear = Number(
                        yearsArray[arrayLength - 1] === UNASSIGNED_DATE_STRING ?
                        Number(yearsArray[arrayLength - 2]) :
                        Number(yearsArray[arrayLength - 1])
                    )
                    displayTaxons(dataToDisplay)
                    props.setAnimationYears({ 
                        'START_YEAR': earliestYear,
                        'END_YEAR' : latestYear
                    })
                }
            }
            else if(response.data && response.data.length && response.data.length !== 0){
                props.setTooManyTaxonsWarning(true)
                props.setTaxonsFoundNumber(response.data.length)
                notify.warning(t("map:arrangement-too-many-taxons-info"))
            }
            else{
                notify.warning(t('map:no-results-notify'))
            }
            loader.hide();
        })
    })    
    

}
