import React, { useMemo, useCallback, forwardRef } from "react";
import {useDropzone} from 'react-dropzone';
import { connect } from 'react-redux'
import {
    setMapfileArray
} from '../../actions/map'
import { useTranslation } from 'react-i18next'
import omnivore from '@mapbox/leaflet-omnivore';
import L from 'leaflet';
import * as notify from '../../utils/notify'

const MapDropzone = forwardRef((props, ref) => {

    const { t } = useTranslation(['map'])

    // Style dropzone
    const baseStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '4px',
        borderWidth: 4,
        borderRadius: 2,
        borderColor: '#383838',
        borderStyle: 'solid',
        backgroundColor: '#f5fbfc',
        color: '#383838',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    };
    
    const activeStyle = {
        borderColor: '#2196f3'
    };
    
    const acceptStyle = {
        borderColor: '#00e676'
    };
    
    const rejectStyle = {
        borderColor: '#ff1744'
    };
    
    const onDrop = useCallback(acceptedFiles => {
        try{
            if (acceptedFiles.length){
                if (acceptedFiles[0]) {
                    var reader = new FileReader();
                    reader.readAsText(acceptedFiles[0], "UTF-8");
                    reader.onload = function (e) {
                        try{
                            const file_content = omnivore.kml.parse(e.target.result)
                            file_content.name = acceptedFiles[0].name
                            let group = new L.FeatureGroup()
                            group.addLayer(file_content)
                            group.name = acceptedFiles[0].name
                            ref.mapRef.current.addLayer(group)
                            ref.mapRef.current.flyToBounds(group.getBounds());
                            props.setMapfileArray({
                                name: acceptedFiles[0].name,
                                content: e.target.result
                            })
                            notify.success(t('map:kml-import-success'))
                        }
                        catch(e){
                            notify.error(t('map:kml-import-fail'))
                        }
                    }
                }
            }
        }
        catch(e){
            notify.error(t('map:kml-import-fail'))
        }
      }, [props, ref.mapRef, t])

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({onDrop});

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive, isDragReject, isDragAccept,
        acceptStyle, activeStyle, baseStyle, rejectStyle
    ]);
   

    return (
        <div className="container">
            <div {...getRootProps({style})}>
                <input {...getInputProps()} />
                  <p>
                    {t('map:import-kml')}
                  </p>
            </div>
        </div>
    )
})

const mapStateToProps = state => ({
    kmlFiles: state.map.files
})

const mapDispatchToProps = dispatch => ({
    setMapfileArray: (data) => dispatch(setMapfileArray(data))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(MapDropzone)