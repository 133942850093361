import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import * as actions from '../../actions'
import { getOutput, getOutputOrDefault, isPending } from "../../reducers"
import { postDataApi } from '../../actions'
import { Form, Button } from "react-bootstrap"
import {
    API_ALBUM_GET_ALL_ID,
    API_ALBUM_GET_ALL_URL,
    API_ALBUM_MOVE_ITEM_ID,
    API_ALBUM_MOVE_ITEM_URL
} from '../../settings'
import * as notify from '../../utils/notify'

const ChangeItemAlbum = props => {
    const { t } = useTranslation([])
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(postDataApi(API_ALBUM_GET_ALL_ID, API_ALBUM_GET_ALL_URL))
    }, [dispatch])

    const [newItemAlbum, setNewItemAlbum] = useState(props.item.id)

    const onNewItemAlbumChanged = e => {
        setNewItemAlbum(e.target.value)
    }

    const onChangeItemAlbum = () => {
        props.changeItemAlbum(newItemAlbum, props.itemsInParent, t)
    }

    return (
        <Form className="mt-2 common-panel-background p-2" onSubmit={e => { e.preventDefault() }}>
            <Form.Group>
                <Form.Label htmlFor={`album-${props.requestId}`}>{t('profile:new-item-album')}</Form.Label>
                <Form.Control id={`album-${props.requestId}`} as="select" value={newItemAlbum} onChange={onNewItemAlbumChanged} disabled={props.querying}>
                    {props.albums && props.albums.map((p, i) =>
                        <option key={i} value={p.id}>{p.name}</option>
                    )};
                </Form.Control>
            </Form.Group>
            <div className="text-right">
                <Button size="sm"
                    disabled={!newItemAlbum || props.querying}
                    onClick={onChangeItemAlbum}
                >{t('profile:change-item-album')}</Button>
            </div>
        </Form>
    )
}

const mapStateToProps = (state, ownProps) => ({
    quering: isPending(API_ALBUM_GET_ALL_ID, state),
    albums: getOutputOrDefault(API_ALBUM_GET_ALL_ID, state, { "user_albums": [] })["user_albums"],
    itemsInParent: getOutput(ownProps.requestId, state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    changeItemAlbum: (newItemAlbumId, itemsInParent, t) => {
        dispatch(actions.postDataApi(API_ALBUM_MOVE_ITEM_ID, API_ALBUM_MOVE_ITEM_URL, {
            item_id: ownProps.item.id,
            album_id: newItemAlbumId,
        })).then(() => {
            const newItemsInParent = {
                ...itemsInParent, items: itemsInParent.items.filter(obj => {
                    return obj.id !== ownProps.observationId
                })
            }

            dispatch(actions.requestSuccess(ownProps.requestId, newItemsInParent))
            notify.success(t('profile:item-album-changed'))
            ownProps.onDone && ownProps.onDone(newItemAlbumId)
        }).catch(res => {
            notify.error(t('profile:cannot-change-item-album'), res.result)
        })
    }
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChangeItemAlbum)
