import React, { useState, Suspense } from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import * as actions from '../../actions'
import {
    TAXONDB_DETAILS_REQUEST_ID_PREFIX,
    TAXONDB_SEARCH_REQUEST_URL,
    TAXONDB_DETAILS_REQUEST_URL,
    ANC_URL_POSTFIX,
    TAXONDB_EXPORTFILE_REQUEST_URL,
    TAXONDB_EXPORTFILE_REQUEST_PREFIX,
    TAXONDB_EXPORTTEMPLATEFILE_REQUEST_PREFIX,
    TAXONDB_EXPORTTEMPLATEFILE_REQUEST_URL,
} from '../../settings'
import { getItems, getPagination, isPending } from "../../reducers";
import ConnectedPagination from "../ConnectedPagination"
import { GENUS_ATTRIBUTE_NAME, SPECIES_ATTRIBUTE_NAME } from './attributesStructure'
import { Container, ListGroup, Collapse, Spinner } from "react-bootstrap"
import TaxonDBRecordDetailsContainer from "./RecordDetailsContainer";
import ReportsExporter from "../ReportsExporter";
import SynonymsInfo from "./SynonymsInfo"
import ValueFromDictionary from "../dictionary/ValueFromDictionary"
import Loader from '../../utils/loader'

const TaxonDBResultsList = props => {
    const { t, i18n } = useTranslation([ 'common', 'forms', 'taxondb', 'profile', 'a11y' ])

    const [ open, setOpen ] = useState({})
    const [ isOpened, setIsOpened ] = useState({})
    const [ requestUrl ] = useState(props.requestUrl || ANC_URL_POSTFIX + TAXONDB_SEARCH_REQUEST_URL)

    const toggleOpen = (recordId, id) => {
        const temp = { ...open }
        temp[ id ] = !temp[ id ]
        setOpen(temp)
        if (temp[ id ]) {
            props.getDetails(recordId, id)
        }
    }

    return (
        <Suspense fallback={<Loader />}>
            <Container className="d-flex flex-column mt-2">
                {props.loading &&
                    <div className="align-self-center">
                        <Spinner animation="border" role="status" />
                    </div>
                }
                {!props.loading &&
                    <div>
                        {!props.items &&
                            <p>{t('use-form-to-search')}</p>
                        }
                        {props.items && props.items.length > 0 &&
                            <div>
                                <div className="mb-2 d-flex flex-row flex-wrap justify-content-center">
                                    <div className="p-2">
                                        {t('taxondb:nr_of_specimens_found')}: <b>{props.pagination.totalCount ? props.pagination.totalCount.toLocaleString(i18n.languages[0]) : '-'}</b>
                                    </div>
                                </div>
                                <div className="mb-2 d-flex flex-row flex-wrap">
                                    <div className="ml-3">
                                        <ReportsExporter
                                            prefix={TAXONDB_EXPORTFILE_REQUEST_PREFIX}
                                            templatePrefix={TAXONDB_EXPORTTEMPLATEFILE_REQUEST_PREFIX}
                                            requestId={props.requestId}
                                            url={TAXONDB_EXPORTFILE_REQUEST_URL}
                                            protectedRecordId={props.protectedRecordId}
                                            ignoreDates={props.ignoreDates}
                                            templateUrl={TAXONDB_EXPORTTEMPLATEFILE_REQUEST_URL}>
                                        </ReportsExporter>
                                    </div>
                                </div>
                                <ConnectedPagination requestId={props.requestId} url={requestUrl} requestInterceptor={props.requestInterceptor}></ConnectedPagination>
                                <ListGroup>
                                    {props.items.map(val => {
                                        const requestId = TAXONDB_DETAILS_REQUEST_ID_PREFIX + val.kolekcjanumerokazu
                                        return (
                                            <div key={requestId}>
                                                <ListGroup.Item tabIndex="0" action className="d-flex flex-row album-element align-items-center" onClick={() => toggleOpen(val.kolekcjanumerokazu, requestId)}>
                                                    <div className="d-flex flex-grow-1 align-items-center">
                                                        <div>
                                                            {open[ requestId ] &&
                                                                <img src="/images/list-open.svg" alt={t("a11y:list-opened")} />
                                                            }
                                                            {!open[ requestId ] &&
                                                                <img src="/images/list-closed.svg" alt={t("a11y:list-closed")} />
                                                            }
                                                        </div>
                                                        {val[ 'images' ] &&
                                                            <div className="mx-2">
                                                                <img src="/images/amuZasób 6@4x-100.jpg" alt={t('a11y:icon-dlibra')} width="21px" />
                                                            </div>
                                                        }
                                                        <div className="mr-1">{val.kolekcjanumerokazu}: </div>
                                                        <div className="mr-1">
                                                            <ValueFromDictionary value={val[ GENUS_ATTRIBUTE_NAME ]} name={GENUS_ATTRIBUTE_NAME}></ValueFromDictionary>
                                                        </div>
                                                        <div className="mr-1">
                                                            <ValueFromDictionary value={val[ SPECIES_ATTRIBUTE_NAME ]} name={SPECIES_ATTRIBUTE_NAME}></ValueFromDictionary>
                                                        </div>
                                                        <div>
                                                            {val.autorgatunku}
                                                        </div>
                                                    </div>
                                                </ListGroup.Item>
                                                <Collapse in={open[ requestId ]} id={requestId}
                                                    onEntering={() => setIsOpened({ ...isOpened, [ requestId ]: true })}
                                                    onExited={() => setIsOpened({ ...isOpened, [ requestId ]: false })}
                                                >
                                                    <div>
                                                        {(open[ requestId ] || isOpened[ requestId ]) &&
                                                            <div className="d-flex flex-column">
                                                                <TaxonDBRecordDetailsContainer requestId={requestId} recordId={val.kolekcjanumerokazu}></TaxonDBRecordDetailsContainer>
                                                            </div>
                                                        }
                                                    </div>
                                                </Collapse>
                                            </div>
                                        )
                                    })}
                                </ListGroup>
                                <div>
                                    <ConnectedPagination last requestId={props.requestId} url={requestUrl} requestInterceptor={props.requestInterceptor}></ConnectedPagination>
                                </div>
                            </div>
                        }
                        {props.items && !props.items.length > 0 &&
                            <>
                                <p>{t('no-records-found')}</p>
                                <SynonymsInfo></SynonymsInfo>
                            </>
                        }
                    </div>
                }
            </Container>
        </Suspense>
    )
}

const mapStateToProps = (state, ownProps) => ({
    items: getItems(ownProps.requestId, state),
    loading: isPending(ownProps.requestId, state) || isPending(ownProps.genusRequestId, state) || isPending(ownProps.speciesRequestId, state),
    pagination: getPagination(ownProps.requestId, state),
    user: state.user ? state.user.data : null,
})

const mapDispatchToProps = dispatch => ({
    getDetails: (recordId, requestId) => dispatch(actions.getDataAnc(requestId, TAXONDB_DETAILS_REQUEST_URL + recordId + '/')),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TaxonDBResultsList)
