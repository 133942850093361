import React, { useState, useEffect } from "react"
import { connect } from 'react-redux'
import { Spinner, Form, Button, Col, Row } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { getOutput, isPending } from "../reducers";
import {
    USER_GROUPS_COORDINATOR_CONST,
    USER_GROUPS_EDITOR_CONST,
    USER_GROUPS_CONFIRMED_CONST,
    USER_GROUPS_TRUSTED_CONST,
    USER_GROUPS_LEADER_CONST,
    USER_GROUPS_SUPERLEADER_CONST,
    USER_GROUPS_VALIDATOR_CONST,
    API_ROLE_REMOVE_USER_ID,
    API_ROLE_REMOVE_USER_URL,
    API_ROLE_ADD_USER_ID,
    API_ROLE_ADD_USER_URL,
} from '../settings'
import { getDataApi, postDataApi } from "../actions"
import { setInfo, clearSession } from '../actions/user'
//import ConnectedPagination from './ConnectedPagination'
import * as notify from '../utils/notify'

const UserRolesDetails = props => {
    const { t } = useTranslation(['common', 'forms'])

    const { user } = props
    const [coordinatorRole, setCoordinatorRole] = useState(false)
    const [editorRole, setEditorRole] = useState(false)
    const [confirmedRole, setConfirmedRole] = useState(false)
    const [trustedRole, setTrustedRole] = useState(false)
    const [leaderRole, setLeaderRole] = useState(false)
    const [superleaderRole, setSuperleaderRole] = useState(false)
    const [validatorRole, setValidatorRole] = useState(false)

    useEffect(() => {
        if (props.userDetails && props.userDetails.user && props.userDetails.user.groups){
            let userGroups = props.userDetails.user.groups
            if (userGroups.includes(USER_GROUPS_EDITOR_CONST)){
                setEditorRole(true)
            }
            if (userGroups.includes(USER_GROUPS_COORDINATOR_CONST)){
                setCoordinatorRole(true)
            }
            if (userGroups.includes(USER_GROUPS_CONFIRMED_CONST)){
                setConfirmedRole(true)
            }
            if (userGroups.includes(USER_GROUPS_TRUSTED_CONST)){
                setTrustedRole(true)
            }
            if (userGroups.includes(USER_GROUPS_LEADER_CONST)){
                setLeaderRole(true)
            }
            if (userGroups.includes(USER_GROUPS_SUPERLEADER_CONST)){
                setSuperleaderRole(true)
            }
            if (userGroups.includes(USER_GROUPS_VALIDATOR_CONST)){
                setValidatorRole(true)
            }
        }
    }, [props.userDetails])

    const saveCoordinatorRole = () => {
        coordinatorRole ?
        props.grantRoleToUser(props.userDetails.user.id, USER_GROUPS_COORDINATOR_CONST, user && user?.id, t) : 
        props.removeRoleFromUser(props.userDetails.user.id, USER_GROUPS_COORDINATOR_CONST, user && user?.id, t)
    }

    const saveEditorRole = () => {
        editorRole ? 
        props.grantRoleToUser(props.userDetails.user.id, USER_GROUPS_EDITOR_CONST, user && user?.id, t) : 
        props.removeRoleFromUser(props.userDetails.user.id, USER_GROUPS_EDITOR_CONST, user && user?.id, t)
    }

    const saveLeaderRole = () => {
        leaderRole ? 
        props.grantRoleToUser(props.userDetails.user.id, USER_GROUPS_LEADER_CONST, user && user?.id, t) : 
        props.removeRoleFromUser(props.userDetails.user.id, USER_GROUPS_LEADER_CONST, user && user?.id, t)
    }

    const saveSuperleaderRole = () => {
        superleaderRole ? 
        props.grantRoleToUser(props.userDetails.user.id, USER_GROUPS_SUPERLEADER_CONST, user && user?.id, t) : 
        props.removeRoleFromUser(props.userDetails.user.id, USER_GROUPS_SUPERLEADER_CONST, user && user?.id, t)
    }

    const saveTrustedRole = () => {
        trustedRole ? 
        props.grantRoleToUser(props.userDetails.user.id, USER_GROUPS_TRUSTED_CONST, user && user?.id, t) : 
        props.removeRoleFromUser(props.userDetails.user.id, USER_GROUPS_TRUSTED_CONST, user && user?.id, t)
    }

    const saveConfirmedRole = () => {
        confirmedRole ? 
        props.grantRoleToUser(props.userDetails.user.id, USER_GROUPS_CONFIRMED_CONST, user && user?.id, t) : 
        props.removeRoleFromUser(props.userDetails.user.id, USER_GROUPS_CONFIRMED_CONST, user && user?.id, t)
    }

    const saveValidatorRole = () => {
        validatorRole ? 
        props.grantRoleToUser(props.userDetails.user.id, USER_GROUPS_VALIDATOR_CONST, user && user?.id, t) : 
        props.removeRoleFromUser(props.userDetails.user.id, USER_GROUPS_VALIDATOR_CONST, user && user?.id, t)
    }

    const isAddRemovePending = (requestId) => {
        const isAddPending = !props.requests[ `${API_ROLE_ADD_USER_ID}-${requestId}` ] ? false : props.requests[ `${API_ROLE_ADD_USER_ID}-${requestId}` ].pending
        const isRemovePending = !props.requests[ `${API_ROLE_REMOVE_USER_ID}-${requestId}` ] ? false : props.requests[ `${API_ROLE_REMOVE_USER_ID}-${requestId}` ].pending
        return isAddPending || isRemovePending
    }

    const RequestPendingSpinner = (ownProps) => {
        return (<>{isAddRemovePending(ownProps.requestId) && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="ml-1" />}</>)
    }

    return (
        <div className="bg-light p-4">
            {props.loadingDetails &&
                <Spinner animation="border" role="status" /> 
            }
            {props.userDetails && props.userDetails.user &&
            <Row>
                <Col>
                    <div className="p-1">{props.userDetails.user.first_name} {props.userDetails.user.last_name}</div>
                    <div className="p-1">{props.userDetails.user.email}</div>
                    <div className="p-1">{props.userDetails.user.username}</div>
                </Col>
                <Col>
                    <div className="p-1">{t('common:user_roles')}</div>
                        {props.userDetails && props.userDetails.user && props.userDetails.user.groups &&
                            <div>
                                <Form.Group controlId="group-users">
                                    <Row>
                                        <Col xs={3}>
                                            <Form.Check 
                                                defaultChecked={props.userDetails.user.groups.includes(USER_GROUPS_COORDINATOR_CONST) ? true : false}
                                                onChange={() => setCoordinatorRole(!coordinatorRole)}
                                                id={props.userDetails.user.id+USER_GROUPS_COORDINATOR_CONST} 
                                                name={USER_GROUPS_COORDINATOR_CONST} 
                                                type="checkbox" label={USER_GROUPS_COORDINATOR_CONST} 
                                            /> 
                                        </Col>
                                        <Col>
                                            <Button onClick={saveCoordinatorRole} className='ml-2' type="submit" size="sm" >
                                                {t('forms:save')}
                                                <RequestPendingSpinner requestId={`${USER_GROUPS_COORDINATOR_CONST}-${props.userDetails.user.id}`}/>
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form.Group>

                                <Form.Group controlId="group-users">
                                    <Row>
                                        <Col xs={3}>
                                            <Form.Check 
                                                defaultChecked={props.userDetails.user.groups.includes(USER_GROUPS_EDITOR_CONST) ? true : false}
                                                onChange={() => setEditorRole(!editorRole)}
                                                id={props.userDetails.user.id+USER_GROUPS_EDITOR_CONST} 
                                                name={USER_GROUPS_EDITOR_CONST} 
                                                type="checkbox" label={USER_GROUPS_EDITOR_CONST}
                                            /> 
                                        </Col>
                                        <Col>
                                            <Button onClick={saveEditorRole} className='ml-2' size="sm">
                                                {t('forms:save')}
                                                <RequestPendingSpinner requestId={`${USER_GROUPS_EDITOR_CONST}-${props.userDetails.user.id}`}/>
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form.Group>

                                <Form.Group controlId="group-users">
                                    <Row>
                                        <Col xs={3}>
                                            <Form.Check 
                                                defaultChecked={props.userDetails.user.groups.includes(USER_GROUPS_VALIDATOR_CONST) ? true : false}
                                                onChange={() => setValidatorRole(!validatorRole)}
                                                id={props.userDetails.user.id+USER_GROUPS_VALIDATOR_CONST} 
                                                name={USER_GROUPS_VALIDATOR_CONST} 
                                                type="checkbox" label={USER_GROUPS_VALIDATOR_CONST} 
                                            /> 
                                        </Col>
                                        <Col>
                                            <Button onClick={saveValidatorRole} className='ml-2 mb-2' size="sm">
                                                {t('forms:save')}
                                                <RequestPendingSpinner requestId={`${USER_GROUPS_VALIDATOR_CONST}-${props.userDetails.user.id}`}/>
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form.Group>

                                <Form.Group controlId="group-users">
                                    <Row>
                                        <Col xs={3}>
                                            <Form.Check 
                                                defaultChecked={props.userDetails.user.groups.includes(USER_GROUPS_TRUSTED_CONST) ? true : false}
                                                onChange={() => setTrustedRole(!trustedRole)}
                                                id={props.userDetails.user.id+USER_GROUPS_TRUSTED_CONST} 
                                                name={USER_GROUPS_TRUSTED_CONST} 
                                                type="checkbox" label={USER_GROUPS_TRUSTED_CONST} 
                                            /> 
                                        </Col>
                                        <Col>
                                            <Button onClick={saveTrustedRole} className='ml-2 mb-2' size="sm">
                                                {t('forms:save')}
                                                <RequestPendingSpinner requestId={`${USER_GROUPS_TRUSTED_CONST}-${props.userDetails.user.id}`}/>
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form.Group>

                                <Form.Group controlId="group-users">
                                    <Row>
                                        <Col xs={3}>
                                            <Form.Check 
                                                defaultChecked={props.userDetails.user.groups.includes(USER_GROUPS_SUPERLEADER_CONST) ? true : false}
                                                onChange={() => setSuperleaderRole(!superleaderRole)}
                                                id={props.userDetails.user.id+USER_GROUPS_SUPERLEADER_CONST} 
                                                name={USER_GROUPS_SUPERLEADER_CONST} 
                                                type="checkbox" label={USER_GROUPS_SUPERLEADER_CONST} 
                                            /> 
                                        </Col>
                                        <Col>
                                            <Button onClick={saveSuperleaderRole} className='ml-2 mb-2' size="sm">
                                                {t('forms:save')}
                                                <RequestPendingSpinner requestId={`${USER_GROUPS_SUPERLEADER_CONST}-${props.userDetails.user.id}`}/>
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form.Group>

                                <Form.Group controlId="group-users">
                                    <Row>
                                        <Col xs={3}>
                                            <Form.Check 
                                                defaultChecked={props.userDetails.user.groups.includes(USER_GROUPS_LEADER_CONST) ? true : false}
                                                onChange={() => setLeaderRole(!leaderRole)}
                                                id={props.userDetails.user.id+USER_GROUPS_LEADER_CONST} 
                                                name={USER_GROUPS_LEADER_CONST} 
                                                type="checkbox" label={USER_GROUPS_LEADER_CONST} 
                                            /> 
                                        </Col>
                                        <Col>
                                            <Button onClick={saveLeaderRole} className='ml-2 mb-2' size="sm">
                                                {t('forms:save')}
                                                <RequestPendingSpinner requestId={`${USER_GROUPS_LEADER_CONST}-${props.userDetails.user.id}`}/>
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form.Group>

                                <Form.Group controlId="group-users">
                                    <Row>
                                        <Col xs={3}>
                                            <Form.Check 
                                                defaultChecked={props.userDetails.user.groups.includes(USER_GROUPS_CONFIRMED_CONST) ? true : false}
                                                onChange={() => setConfirmedRole(!confirmedRole)}
                                                id={props.userDetails.user.id+USER_GROUPS_CONFIRMED_CONST} 
                                                name={USER_GROUPS_CONFIRMED_CONST} 
                                                type="checkbox" label={USER_GROUPS_CONFIRMED_CONST} 
                                            /> 
                                        </Col>
                                        <Col>
                                            <Button onClick={saveConfirmedRole} className='ml-2 mb-2' size="sm">
                                                {t('forms:save')}
                                                <RequestPendingSpinner requestId={`${USER_GROUPS_CONFIRMED_CONST}-${props.userDetails.user.id}`}/>
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </div>
                        }
                    </Col>
                </Row>
            }
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    user: state.user ? state.user.data : null,
    userDetails: getOutput(ownProps.requestId, state),
    loadingDetails: isPending(ownProps.requestId, state),
    requests: state.requests
})
  
const mapDispatchToProps = (dispatch, ownProps) => ({
    getUserDetails: () => dispatch(getDataApi(ownProps.requestId, '/user/' + ownProps.requestId)),
    grantRoleToUser: (userId, role, currentUserId, t) => dispatch(postDataApi(`${API_ROLE_ADD_USER_ID}-${role}-${userId}`, API_ROLE_ADD_USER_URL, {
        "user_id": userId,
        "role": role
    })).then(res => {
            if (res === 0){
                notify.success(t('common:role-succesfully-saved', {role: role}))
                if (userId === currentUserId) {
                    dispatch(getDataApi('userInfo', '/current_user/'))
                        .then(data => dispatch(setInfo(data.user)))
                        .catch(error => dispatch(clearSession()))
                }
            }
            if (res === "not allowed"){
                notify.error(t('common:not-allowed-info')) 
            }
            if (res === null){
                notify.error(t('common:not-allowed-info')) 
            }
        }
    ).catch(err => notify.error(t('common:not-allowed-info'))),
    removeRoleFromUser: (userId, role, currentUserId, t) => dispatch(postDataApi(`${API_ROLE_REMOVE_USER_ID}-${role}-${userId}`, API_ROLE_REMOVE_USER_URL, {
        "user_id": userId,
        "role": role
    })).then(res => {
            if (res === 0){
                notify.success(t('common:role-succesfully-removed', {role: role}))
                if (userId === currentUserId) {
                    dispatch(getDataApi('userInfo', '/current_user/'))
                        .then(data => dispatch(setInfo(data.user)))
                        .catch(error => dispatch(clearSession()))
                }
            }
            if (res === "not allowed"){
                notify.error(t('common:not-allowed-info')) 
            }
            if (res === null){
                notify.error(t('common:not-allowed-info')) 
            }
        }
    ).catch(err => notify.error(t('common:not-allowed-info'))),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserRolesDetails)