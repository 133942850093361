import React, { useEffect, useState } from "react";
import { Button, Col, Form, FormLabel } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { postDataApi } from "../../actions";
import { API_EXCEL_TASK_SEARCH_REQUEST_ID, API_EXCEL_TASK_SEARCH_REQUEST_URL } from "../../settings";
import ResultsList from './ResultsList'

const ExcelPanel = (props) => { 
  const { t } = useTranslation([ 'editor-tools', 'editions-panel' ])

  const [status, setStatus] = useState('')
  const [dateStart, setDateStart] = useState('')
  const [dateEnd, setDateEnd]  = useState('')
  const [filter, setFilter] = useState('')

  const EMPTY_STRING = ""
  
  /* status types */
  const STATUS_QUEUE = 'QUEUE'
  const STATUS_INIT = 'INIT'
  const STATUS_WORKING = 'WORKING'
  const STATUS_DONE = 'DONE'
  const STATUS_FAILED = 'FAILED'
  const STATUS_ERRORS = 'ERRORS'


  const onStatusChange = e => {
      setStatus(e.target.value)
  }


  const onDateStartChange = e => {
      setDateStart(e.target.value)
  }

  const onDateEndChange = e => {
      setDateEnd(e.target.value)
  }

  const onClearForm = () => {
      setStatus('')
      getCurrentDate()
      getMonthAgoDate()
      setFilter({})
  }

  const onSubmitForm = () => {
      let filter = {}
      if (status){
          filter['status'] = status
      }
      filter["od_daty"] = dateStart
      filter["do_daty"] = dateEnd
      setFilter(filter)
      props.search(filter)
  }

  const getCurrentDate = () => {
      let date = new Date()
      date = date.toJSON().slice(0, 10);
      setDateEnd(date)
  }

  const getMonthAgoDate = () => {
      let date = new Date()
      let currentMonth = date.getMonth()
      date.setMonth(currentMonth - 1)
      date = date.toJSON().slice(0, 10);
      setDateStart(date)
  }

  useEffect(() => {
      getCurrentDate()
      getMonthAgoDate()
  }, [])

  const handleOnKeyUp = e => {
      if (e.keyCode === 13) { // ENTER
          e.preventDefault()
          onSubmitForm()
      }
  }

  return (
  <div>
    <Form className="mt-2 p-1 mb-2" onSubmit={e => { e.preventDefault() }}>
            <Form.Row>
                    <Col>
                        <Form.Group>
                            <FormLabel htmlFor={"dateFrom"}>{t('editions-panel:from-date')}</FormLabel>
                            <Form.Control
                                id={"dateFrom"} 
                                type="date" 
                                name="dateFrom"
                                onChange={onDateStartChange}
                                onKeyUp={handleOnKeyUp} 
                                value={dateStart}
                                required
                            >
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <FormLabel htmlFor={"dateTo"}>{t('editions-panel:to-date')}</FormLabel>
                            <Form.Control 
                                id={"dateTo"} 
                                type="date" 
                                name="dateTo" 
                                onChange={onDateEndChange}
                                onKeyUp={handleOnKeyUp} 
                                value={dateEnd}
                                required
                            >
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Label htmlFor="status">{t('editions-panel:edition-status')}</Form.Label>
                            <Form.Control id="role" as="select" value={status} onChange={onStatusChange} onKeyUp={handleOnKeyUp} disabled={props.querying}>
                                <option value={EMPTY_STRING}>{t('editions-panel:value-any')}</option>
                                <option value={STATUS_QUEUE}>{t('editions-panel:status-queue')}</option>
                                <option value={STATUS_INIT}>{t('editions-panel:status-init')}</option>
                                <option value={STATUS_WORKING}>{t('editions-panel:status-working')}</option>
                                <option value={STATUS_DONE}>{t('editions-panel:status-done')}</option>
                                <option value={STATUS_FAILED}>{t('editions-panel:status-failed')}</option>
                                <option value={STATUS_ERRORS}>{t('editions-panel:status-errors')}</option>    
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} className="d-flex align-self-end">
                        <div className="flex-grow-1" />
                        <Button variant="primary" onClick={onSubmitForm} disabled={props.querying}>
                            {t('common:search')}
                        </Button>
                        <Button variant="secondary" className="ml-2" onClick={onClearForm} disabled={props.querying} >
                            {t('common:clear')}
                        </Button>
                    </Form.Group>
                </Form.Row>
            </Form>
    <div className="mt-2">
      <ResultsList filter={filter}></ResultsList>
    </div>
  </div>
)
}

const mapStateToProps = (state, ownProps) => ({
})

const mapDispatchToProps = dispatch => ({
  search: filter => dispatch(postDataApi(API_EXCEL_TASK_SEARCH_REQUEST_ID, API_EXCEL_TASK_SEARCH_REQUEST_URL, {filter: filter})),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExcelPanel) 