
export const ROK_ZBIORU_FIELD_NAME = 'rok_zbioru'
export const MULTIMEDIA_FIELD_NAME = 'multimedia'
export const BOTANIKAZOOLOGIA_FIELD_NAME = 'typ_kolekcji'
export const DATAZBIORU_FIELD_NAME = 'data_zbioru'
export const LATITUDE_ATTRIBUTE_NAME = "szerokoscgeograficzna"
export const LONGITUDE_ATTRIBUTE_NAME = "dlugoscgeograficzna"
export const GPS_COORDINATES_ATTRIBUTE_NAME = "wspolrzednegeograficzne"
export const GEOPRECISION_ATTRIBUTE_NAME = "geodokladnosc"
export const GENUS_ATTRIBUTE_NAME = "rodzaj"
export const SPECIES_ATTRIBUTE_NAME = "gatunek"
export const DATA_ZEBRANIA_ATTRIBUTE_NAME = "datazebraniaokazuproby"
export const DATA_ZEBRANIA_KONIEC_ATTRIBUTE_NAME = "datazebraniaprobykoniec"
export const RODZAJ_GATUNEK_ATTRIBUTE_NAME = "rodzaj_gatunek"
export const LOCATION_ATTRIBUTE_NAME = "stanowisko"
export const TRANSLATIONS_ATTRIBUTE_NAME = "translations"
export const DLIBRA_RODZAJ_GATUNEK_ATTRIBUTE_NAME = "genus_species"
export const COORDINATES_PRECISION = "wspolrzedne"
export const ATPOL_COORDINATES = "wspolrzedneatpol"
export const UTM_COORDINATES = "wspolrzedneutm"
