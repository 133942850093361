import {
    USER_GROUPS_COORDINATOR_CONST,
    USER_GROUPS_EDITOR_CONST,
    USER_GROUPS_SUPERLEADER_CONST,
    USER_GROUPS_VALIDATOR_CONST,
    MAP_TOOLS_VISUALIZATION_1,
    MAP_TOOLS_VISUALIZATION_2,
    MAP_TOOLS_VISUALIZATION_3,
    MAP_TOOLS_VISUALIZATION_4,
    MAP_TOOLS_VISUALIZATION_5,
    MAP_TOOLS_VISUALIZATION_6,
    MAP_TOOLS_VISUALIZATION_7
} from '../settings'


export const ADMIN_MENU = [
    {
        name: 'menu:reports',
        path: 'paths:reports',
        role: USER_GROUPS_COORDINATOR_CONST
    },
    {
        name: 'menu:protected-taxons',
        path: 'paths:protected-taxon',
        role: USER_GROUPS_EDITOR_CONST

    },
    {
        name: 'menu:protected-areas',
        path: 'paths:protected-area',
        role: USER_GROUPS_EDITOR_CONST
    },
    {
        name: 'menu:users-roles',
        path: 'paths:user-roles',
        role: USER_GROUPS_COORDINATOR_CONST

    },
    {
        name: 'menu:files_reports',
        path: 'paths:files-reports',
        role: USER_GROUPS_EDITOR_CONST
    },
    {
        name: 'menu:taxon_sources_files',
        path: 'paths:taxon-source',
        role: USER_GROUPS_EDITOR_CONST
    },
    {
        name: 'menu:team-leaders',
        path: 'paths:team-leaders',
        role: USER_GROUPS_SUPERLEADER_CONST
    },
    {
        name: 'menu:editor-tools-header',
        role: USER_GROUPS_EDITOR_CONST,
        className: "separator-before separator-after passive-header",
        isHeader: true
    },
    {
        name: 'menu:editor-tools',
        path: 'paths:excel-tools',
        role: [ USER_GROUPS_EDITOR_CONST, USER_GROUPS_VALIDATOR_CONST ]
    },
    {
        name: 'menu:task-history',
        path: 'paths:task-history',
        role: USER_GROUPS_EDITOR_CONST,
    },
    {
        name: 'menu:db-history',
        path: 'paths:db-history',
        role: USER_GROUPS_EDITOR_CONST,
    },
]

export const MY_AMUNATCOLL_MENU = [
    {
        name: 'menu:my-projects',
        path: 'paths:projects'
    },
    {
        name: 'menu:my-album',
        path: 'paths:my-album'
    },
    {
        name: 'menu:my-maps',
        path: 'paths:my-maps'
    },
    {
        name: 'menu:my-teams',
        path: 'paths:team'
    }
]
export const PROFILE_MENU = {
    profile: {
        name: 'common:my-profile',
        path: 'paths:profile'
    },
    logout: {
        name: 'common:logout',
        path: "/logout/"
    },
    login: {
        name: 'common:login',
        path: 'paths:login'
    }
}

export const SEARCH_MENU = [
    {
        name: 'menu:taxons-search-header',
        className: "passive-header separator-before separator-after"
    },
    {
        name: 'menu:search',
        path: 'paths:search',
	className: "indented"
    },
    {
        name: 'menu:taxon_db',
        path: 'paths:taxonDB',
	className: "indented"
    },
    {
        name: 'menu:collections',
        path: 'paths:collections',
	className: "indented"
    },
    {
        name: 'menu:systematics',
        path: 'paths:systematics',
	className: "indented"
    },
    {
        name: 'menu:samples-search',
        path: 'paths:samples-search'
    },
    {
        name: 'menu:icon_db',
        path: 'paths:iconDB'
    },
    {
        name: 'menu:biblio-search',
        path: 'paths:bibliography-search'
    },
    {
        name: 'menu:edu-materials',
        path: 'paths:edu-materials'
    }
]

export const ABOUT_MENU = [
    {
        name: 'menu:mission',
        path: 'paths:mission'
    },
    {
        name: 'menu:informations',
        path: 'paths:informations-page'
    },
    {
        name: 'menu:mobile',
        path: 'paths:mobile-info'
    },
    {
        name: 'menu:biogis',
        path: 'paths:gis-info'
    },
    {
        name: 'menu:our_users_info',
        path: 'paths:our-users'
    },
    {
        name: 'menu:about-us',
        path: 'paths:about-us'
    }
]


export const TOOLS_MENU = [
    [
        {
            name: 'menu:tools-header-2',
            className: "separator-before separator-after passive-header"
        },
        {
            name: 'menu:analysis-stats',
            path: 'paths:stats-page',
	    className: "indented"
        }
    ],
    [
        {
            name: 'menu:tools-header-1',
            className: "separator-before separator-after passive-header"
        },
        // {
        //     name: "GIS",
        //     path: MAP_MENU_PAGE_URL_EN + '/',
        //     className: "separator-before"
        // },
        { 
            name: 'menu:position-map', 
            path: 'paths:position-map',
	        className: "indented",
            visualizationType: MAP_TOOLS_VISUALIZATION_1,
        },
        {
            name: 'menu:area-map',
            path: 'paths:area-map',
	        className: "indented",
            visualizationType: MAP_TOOLS_VISUALIZATION_2,
        },
        {
            name: 'menu:cartogram',
            path: 'paths:cartogram',
	        className: "indented",
            visualizationType: MAP_TOOLS_VISUALIZATION_4,
        },
        {
            name: 'menu:cartodiagram',
            path: 'paths:cartodiagram',
	        className: "indented",
            visualizationType: MAP_TOOLS_VISUALIZATION_5,
        },
        {
            name: 'menu:clustering',
            path: 'paths:clustering',
	        className: "indented",
            visualizationType: MAP_TOOLS_VISUALIZATION_3,
        },
        {
            name: 'menu:taxons-grouping',
            path: 'paths:taxons-grouping',
	        className: "indented",
            visualizationType: MAP_TOOLS_VISUALIZATION_6,
        },
        {
            name: 'menu:historical-analysis-map',
            path: 'paths:historical-analysis-map',
	        className: "indented",
            visualizationType: MAP_TOOLS_VISUALIZATION_7,
        },
        // {
        //     name: 'menu:heatmap',
        //     path: HEATMAP_PAGE_URL_EN + '/',
        // }
    ]
]

export const MAIN_MENU = {
    home: {
        name: 'menu:home',
        path: "/"
    },
    searchEngines: {
        name: 'menu:search-engines',
        submenu: SEARCH_MENU
    },
    tools: {
        name: 'menu:tools',
        submenu: TOOLS_MENU
    },
    about: {
        name: 'menu:about-portal',
        submenu: ABOUT_MENU
    },
    howToUse: {
        name: 'menu:how-to-use',
        path: 'paths:how-to-use'
    },
    contact: {
        name: 'menu:contact',
        path: 'paths:contact'
    }
}

export const MAIN_MENU2 = {
    adminMenu: {
        name: 'menu:administration',
        submenu: ADMIN_MENU
    },
    myAmunatcoll: {
        name: 'menu:my-amunatcoll',
        submenu: MY_AMUNATCOLL_MENU
    },
    profile: {
        submenu: PROFILE_MENU
    },
}

