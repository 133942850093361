import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle, faWindowClose } from '@fortawesome/free-solid-svg-icons'
import { Button } from  "react-bootstrap"; 
import { MAP_UNCLASTERED_MAXIMUM_MARKERS } from '../../../settings'
import { setTooManyTaxonsWarning } from '../../../actions/map'

const TooManyTaxonsWarning = props => {

    const { t } = useTranslation(['map'])

    const hideTooManyTaxonsWarning = () => {
        props.setTooManyTaxonsWarning(false)
    }

    return(
        <>
        {(props.taxonsNumber > 0) && props.taxonsWarning &&
            <div className="too-many-taxons-container">
                <div className="too-many-taxons-info-badge">
                    <FontAwesomeIcon icon={faExclamationCircle} />
                </div>
                <div className="too-many-taxons-info-text-container">
                    <div className="too-many-taxons-info-text-box"> 
                        <b>{t('map:too-many-taxons-warning-1')}</b>
                    </div>
                    <div className="too-many-taxons-info-text-box">
                        {t('map:too-many-taxons-warning-2')}
                    </div>
                    <div className="too-many-taxons-info-text-box">
                        {t('map:too-many-taxons-warning-3')} <b>{props.taxonsNumber}</b>
                    </div>
                    <div className="too-many-taxons-info-text-box">
                        {t('map:too-many-taxons-warning-4')} <b>{MAP_UNCLASTERED_MAXIMUM_MARKERS}</b>
                    </div>
                </div>
                <div className="too-many-taxons-close-button">
                    <Button 
                        variant="danger"
                        onClick={() => hideTooManyTaxonsWarning()}
                        title={t('map:too-many-taxons-close-button')}
                    >
                        <FontAwesomeIcon 
                            icon={faWindowClose} 
                            id={'too-many-taxons-close-button'} 
                            title={t('map:too-many-taxons-close-button')}
                        />
                    </Button>
                </div>
            </div>
        }
        </>
    )

}


const mapStateToProps = state => ({
   taxonsWarning: state.map.taxonsWarning,
   taxonsNumber: state.map.taxonsNumber,
})

const mapDispatchToProps = dispatch => ({
    setTooManyTaxonsWarning: data => dispatch(setTooManyTaxonsWarning(data))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TooManyTaxonsWarning)