import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Container } from 'react-bootstrap';

import * as actions from '../../actions';
import SingleRecordDetailsContainer from './SingleRecordDetailsContainer';
import {
    SAMPLESDB_DETAILS_REQUEST_ID_PREFIX,
    SAMPLESDB_DETAILS_REQUEST_URL,
} from '../../settings';
import SEO from '../SEO';

export default props => {
	const dispatch = useDispatch();
	const getDetails = useCallback(() => dispatch(actions.postDataAnc(
		SAMPLESDB_DETAILS_REQUEST_ID_PREFIX + props.match.params.recordId,
		SAMPLESDB_DETAILS_REQUEST_URL,
		{ id: props.match.params.recordId })
	), [dispatch, props.match.params.recordId]);

	useEffect(() => {
		getDetails()
	}, [getDetails])

	return (
		<Container className="d-flex flex-column">
			<SEO title={props.match.params.recordId} />
			<SingleRecordDetailsContainer requestId={SAMPLESDB_DETAILS_REQUEST_ID_PREFIX + props.match.params.recordId} recordId={props.match.params.recordId}></SingleRecordDetailsContainer>
		</Container>
	)
}
