import React, { useState, useEffect } from "react"
import { useForm, useFieldArray } from 'react-hook-form'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Table, OverlayTrigger, Popover, Tab, Tabs, Button, Form, Row, Col, Spinner } from "react-bootstrap"
import {
    GPS_COORDINATES_ATTRIBUTE_NAME,
    LATITUDE_ATTRIBUTE_NAME,
    GENUS_ATTRIBUTE_NAME,
    SPECIES_ATTRIBUTE_NAME,
    DATA_ZEBRANIA_ATTRIBUTE_NAME,
    DATA_ZEBRANIA_KONIEC_ATTRIBUTE_NAME,
    LONGITUDE_ATTRIBUTE_NAME,
    GEOPRECISION_ATTRIBUTE_NAME,
    BOTANIKAZOOLOGIA_FIELD_NAME,
    TRANSLATIONS_ATTRIBUTE_NAME,
    COORDINATES_PRECISION,
    UTM_COORDINATES,
    ATPOL_COORDINATES
} from './attributesStructure'
import { postDataAnc, getData } from '../../actions'
import {
    RECORD_DETAILS_LOCATION_STATE_DATE_POPUP,
    TAXONDB_CHANGE_REQUEST_ID_PREFIX,
    TAXONDB_CHANGE_REQUEST_URL,
    USER_GROUPS_EDITOR_CONST,
    GENERAL_EXTENDED_REQUEST_TIMEOUT
} from '../../settings'
import { decodeLatitude, decodeLongitude, formatStringToLatitude, formatStringToLongitude } from '../../utils/gps'
import MarkerMap from './MarkerMap'
import RevisionsTable from './RevisionsTable'
import ValueFromDictionary, { GetValueFromDictionary } from "../dictionary/ValueFromDictionary"
import Citation from './Citation'
import Confirm from '../Confirm'
import { getRequest, getOutput, isPending } from "../../reducers"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfo, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons'
import { diff } from 'deep-object-diff'
import * as notify from '../../utils/notify'
import InfoIconPopover from "../InfoIconPopover"
import { handleTabsA11y } from "../../utils/handleTabsA11y"
import ShareButton from "../iconDB/ShareButton"
import FormControlCountries from "../FormControlCountries"

const REVISIONS_COLUMNS_MAP = {
    'rodzaj': 'rodzaj',
    'gatunek': 'gatunek',
    'autorgatunku': 'autorgatunku',
    'autoroznaczenia': 'autoroznaczenia',
    'dataoznaczeniazbioru': 'dataoznaczeniazbioru',
    'rangajednostkinadrzednej': 'rangajednostkinadrzędnej',
    'jednostkanadrzedna': 'jednostkanadrzędna'
}

const LOWERTAXONS_COLUMNS_MAP = {
    'rangataksonuniższegorzędu': 0,
    'taksonniższegorzędu': 1,
    'autortaksonuniższegorzędu': 2
}

const AttrValueSpecial = props => {
    const values = props.data[ props.name ].split(' ')

    return (
        <>
            <span>{values[ 0 ]} </span>
            <i>{values.slice(1).join(' ')}</i>
        </>
    )
}

const AttrValueArray = props =>
    <>
        {props.name === 'lower_taxons' &&
            <>
                <span><ValueFromDictionary value={props.values[ 0 ]} {...props} name='rangataksonunizszegorzedu' /> </span>
                <span><i><ValueFromDictionary value={props.values[ 1 ]} {...props} name='taksonnizszegorzedu' /></i> </span>
                <span><ValueFromDictionary value={props.values[ 2 ]} {...props} name='autortaksonunizszegorzedu' /></span>
            </>
        }
        {props.name !== 'lower_taxons' && props.values.map((value, key) =>
            <span key={key}>{value} </span>
        )}
    </>

const AttrValue = props => {
    const { t } = useTranslation()
    return (
    <>
        {props.gps &&
            <span>
                {props.data[COORDINATES_PRECISION] > 0 &&
		<>
                <span style={{ whiteSpace: 'nowrap' }}>
                    {decodeLatitude(props.data[ LATITUDE_ATTRIBUTE_NAME ])}
                </span> <span style={{ whiteSpace: 'nowrap' }}>
                    {decodeLongitude(props.data[ LONGITUDE_ATTRIBUTE_NAME ])}
                </span>
		</>
		}
                {props.data[COORDINATES_PRECISION] === 2 &&
                <span> ({t('precision-2')})</span>
                }
                {props.data[COORDINATES_PRECISION] === 1 &&
                <span> ({t('precision-1')})</span>
                }
                {props.data[COORDINATES_PRECISION] === 0 &&
                <span> {t('precision-0')}</span>
                }
            </span>
        }
        {!props.gps &&
            <>
                {props.data[ props.name ] === true &&
                    <>{props.t('common:true')}</>
                }
                {props.data[ props.name ] === false &&
                    <>{props.t('common:false')}</>
                }
                {props.data[ props.name ] !== true && props.data[ props.name ] !== false &&
                    <>
                        {Array.isArray(props.data[ props.name ]) &&
                            <>
                                {props.data[ props.name ].map((item, indx) =>
                                    <div key={indx}>
                                        {props.name === TRANSLATIONS_ATTRIBUTE_NAME &&
                                            <div>
                                                {item[ 1 ] ? "(" + item[ 1 ].toUpperCase() + ")" : ""} {item[ 0 ]}
                                            </div>
                                        }
                                        {props.name !== TRANSLATIONS_ATTRIBUTE_NAME && props.directRender &&
                                            <span>{GetValueFromDictionary((props.dictionary ? props.dictionary : props.name), item, props.i18n, props.collectionTypes)}</span>
                                        }
                                        {props.name !== TRANSLATIONS_ATTRIBUTE_NAME && !props.directRender &&
                                            <>
                                                {item.values && <AttrValueArray values={item.values} {...props} />}
                                                {!item.values && <ValueFromDictionary value={item} {...props} />}
                                            </>
                                        }
                                    </div>
                                )}
                            </>
                        }
                        {!Array.isArray(props.data[ props.name ]) &&
                            <>
                                {props.special &&
                                    <AttrValueSpecial {...props}></AttrValueSpecial>
                                }
                                {!props.special &&
                                    <>
                                        {props.directRender &&
                                            <span>{GetValueFromDictionary((props.dictionary ? props.dictionary : props.name), props.data[ props.name ], props.i18n, props.collectionTypes)}</span>
                                        }
                                        {!props.directRender &&
                                            <ValueFromDictionary value={props.data[ props.name ]} {...props}></ValueFromDictionary>
                                        }
                                    </>
                                }
                            </>
                        }
                    </>
                }
                {(props.data.revisions && props.data.revisions.reduce((acc, obj) => (acc || (props.name in obj)), false)) &&
                    <> *</>
                }
                {props.dateEnd && props.data[ DATA_ZEBRANIA_KONIEC_ATTRIBUTE_NAME ] &&
                    <>-{props.data[ DATA_ZEBRANIA_KONIEC_ATTRIBUTE_NAME ]}</>
                }
                {props.meters &&
                    <> m</>
                }
            </>
        }
    </>)
}

const EditAttrValue = props => {
    const { register } = props
    const isDisabled = [ 'kolekcjanumerokazu', 'numerokazu', 'rodzajgatunek', BOTANIKAZOOLOGIA_FIELD_NAME, 'images', 'geodokladnosc', 'kolekcja' ].includes(props.name)
    return (
        <Form.Group className="mb-0">
            {!props.isBoolean && 
                <>
                    {props.name === 'panstwo' &&
                        <FormControlCountries 
                            name={props.name} 
                            size="sm" 
                            type="text" 
                            register={register}
                            set={props.setValue}
                            defaultValue={props.data[props.name]} 
                            readOnly={isDisabled} 
                            isInvalid={props.errors[ props.name ]} 
                            autoComplete="off" />
                    }
                    {props.name !== 'panstwo' &&
                        <Form.Control name={props.name} size="sm" type="text" ref={register} readOnly={isDisabled} isInvalid={props.errors[ props.name ]} autoComplete="off" />
                    }
                </>
            }
            {props.isBoolean &&
                <Form.Control as="select" name={props.name} size="sm" ref={register} disabled={isDisabled} isInvalid={props.errors[ props.name ]}>
                    <option value={null}></option>
                    <option value={true}>{props.t('common:true')}</option>
                    <option value={false}>{props.t('common:false')}</option>
                </Form.Control>
            }
            <Form.Control.Feedback type="invalid">
                {props.errors[ props.name ] ? props.errors[ props.name ][ 0 ] : ''}
            </Form.Control.Feedback>
        </Form.Group>
    )
}

const EditAttrValueArray = props => {
    const { register, control } = props
    const { fields, append, remove, insert } = useFieldArray({ control: control, name: props.name })

    const getError = (name, rowIndex, colIndex) => {
        return props.errors && props.errors[ name ] && props.errors[ name ][ rowIndex ] && props.errors[ name ][ rowIndex ].values[ colIndex ]?.message;
    }

    return (<>
        {fields.length > 0 && fields.map((item, index) =>
            <Row key={item.id} className="mb-1" noGutters>
                <Col className="pr-1">
                    <Form.Group className="mb-0">
                        <Form.Control name={`${props.name}[${index}].values[0]`} defaultValue={item[ 'values' ][ 0 ]} size="sm" type="text" ref={register()} autoComplete="off" />
                        <Form.Control.Feedback type="invalid" className="d-block">
                            {getError(props.name, index, 0)}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col className="px-1">
                    <Form.Group className="mb-0">
                        <Form.Control name={`${props.name}[${index}].values[1]`} defaultValue={item[ 'values' ][ 1 ]} size="sm" type="text" ref={register()} autoComplete="off" />
                        <Form.Control.Feedback type="invalid" className="d-block">
                            {getError(props.name, index, 1)}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col className="px-1">
                    <Form.Group className="mb-0">
                        <Form.Control name={`${props.name}[${index}].values[2]`} defaultValue={item[ 'values' ][ 2 ]} size="sm" type="text" ref={register()} autoComplete="off" />
                        <Form.Control.Feedback type="invalid" className="d-block">
                            {getError(props.name, index, 2)}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md="auto" className="pl-1">
                    <Button size="sm" onClick={() => insert(index + 1, { values: [ '', '', '' ] })} >
                        <FontAwesomeIcon icon={faPlus} title={props.t('common:append')} />
                    </Button>
                    <Button size="sm" className="ml-2" onClick={() => remove(index)}>
                        <FontAwesomeIcon icon={faTrash} title={props.t('common:delete')} />
                    </Button>
                </Col>
            </Row>
        )}
        {fields.length === 0 &&
            <Row className="mb-1" noGutters>
                {Array.from({ length: 3 }).map((_, i) =>
                    <Col className="px-1" key={i}>
                        <Form.Control size="sm" type="text" disabled />
                    </Col>
                )}
                <Col md="auto" className="pl-1">
                    <Button size="sm" onClick={() => append({ values: [ '', '', '' ] })} >
                        <FontAwesomeIcon icon={faPlus} title={props.t('common:append')} />
                    </Button>
                    <Button size="sm" className="ml-2" disabled>
                        <FontAwesomeIcon icon={faTrash} title={props.t('common:delete')} />
                    </Button>
                </Col>
            </Row>
        }
    </>)
}

const EditGpsValue = props => {
    const { register } = props

    return (<>{!props.array &&
        <Row noGutters>
            <Col>
                <Form.Group className="mb-0">
                    <Form.Control name={LATITUDE_ATTRIBUTE_NAME} size="sm" type="text" ref={register} isInvalid={props.errors[ props.name ]} autoComplete="off" />
                    <Form.Control.Feedback type="invalid">
                        {props.errors[ props.name ] ? props.errors[ props.name ][ 0 ] : ''}
                    </Form.Control.Feedback>
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className="mb-0">
                    <Form.Control name={LONGITUDE_ATTRIBUTE_NAME} size="sm" type="text" ref={register} isInvalid={props.errors[ props.name ]} autoComplete="off" />
                    <Form.Control.Feedback type="invalid">
                        {props.errors[ props.name ] ? props.errors[ props.name ][ 0 ] : ''}
                    </Form.Control.Feedback>
                </Form.Group>
            </Col>
        </Row>
    }</>)
}

const AttrName = props =>
    <b>
        {!props.label &&
            <>
                {props.t('taxondb-attributes:' + props.name)}
            </>
        }
        {props.label &&
            <>
                {props.t('taxondb-attributes:' + props.label)}
            </>
        }
    </b>

const AttrNameWithBadge = props =>
    <>
        <AttrName {...props}></AttrName>
        <InfoIconPopover placement="bottom">
            <div>{props.badge}</div>
        </InfoIconPopover>
    </>

const SystematicsPopover = props =>
    <>
        <OverlayTrigger className="info-icon" rootClose={true} trigger="click" placement="auto" overlay={
            <Popover>
                <Table>
                    <tbody>
                        {[ ...props.data.taxon_units ].reverse().map((element, index) => {
                            return (
                                <tr key={index}>
                                    <td><b>{element.range_lat}</b></td>
                                    <td>{element.unit_lat}</td>
                                </tr>
                            )
                        })
                        }
                    </tbody>
                </Table>
            </Popover>
        }>
            <Button size="sm" className="ml-2" style={{ padding: "0 .5rem" }}>
                <FontAwesomeIcon icon={faInfo} title={props.t('common:additional-info')} />
            </Button>
        </OverlayTrigger>
    </>

const DivRow = props =>
    <>
        {((!props.array && typeof (props.data[ props.name ]) != "undefined" && props.data[ props.name ] !== null) ||
            (props.array && props.data[ props.name ] && props.data[ props.name ].length > 0) ||
            props.editable) &&
            <div className="d-flex" style={{ borderTopStyle: "solid", borderTopColor: "#dee2e6", borderTopWidth: "1px" }}>
                <div className="w-50 p-1">
                    {!props.badge &&
                        <AttrName {...props}></AttrName>
                    }
                    {props.badge &&
                        <AttrNameWithBadge {...props}></AttrNameWithBadge>
                    }
                </div>
                <div className="w-50 p-1">
                    {(!props.editable || props.name === TRANSLATIONS_ATTRIBUTE_NAME) &&
                        <AttrValue {...props}></AttrValue>
                    }
                    {props.editable && !props.gps && props.name !== TRANSLATIONS_ATTRIBUTE_NAME && !props.array &&
                        <EditAttrValue {...props}></EditAttrValue>
                    }
                    {props.editable && !props.gps && props.name !== TRANSLATIONS_ATTRIBUTE_NAME && props.array &&
                        <EditAttrValueArray {...props}></EditAttrValueArray>
                    }
                    {props.editable && props.gps &&
                        <EditGpsValue {...props}></EditGpsValue>
                    }
                    {props.data.taxon_units &&
                        props.data.taxon_units.length > 0 &&
                        props.name === 'jednostkanadrzedna' &&
                        <SystematicsPopover {...props} />
                    }
                </div>
            </div>
        }
    </>

const DivRowSwitch = props =>
    <>
        {props.i18n.languages[0] === 'en' && props.data[ props.name ] &&
            <DivRow {...props}></DivRow>
        }
        {props.i18n.languages[0] === 'en' && !props.data[ props.name ] &&
            <DivRow {...props} name={props.switch}></DivRow>
        }
        {props.i18n.languages[0] === 'pl' && props.data[ props.switch ] &&
            <DivRow {...props} name={props.switch}></DivRow>
        }
        {props.i18n.languages[0] === 'pl' && !props.data[ props.switch ] &&
            <DivRow {...props}></DivRow>
        }
    </>

export const Tab1 = props =>
    <div>
        <DivRow name="kolekcjanumerokazu" {...props}></DivRow>
        <DivRow name="zrodlo" {...props}></DivRow>
        <DivRow name={GENUS_ATTRIBUTE_NAME} {...props}></DivRow>
        <DivRow name={SPECIES_ATTRIBUTE_NAME} {...props}></DivRow>
        <DivRow name="autorgatunku" {...props}></DivRow>
        <DivRow name="lower_taxons" array {...props}></DivRow>
        <DivRow name="jednostkanadrzedna" {...props}></DivRow>
        <DivRow name="rangajednostkinadrzednej" {...props}></DivRow>
        <DivRow name="autorzbioru" {...props}></DivRow>
        <DivRow name="numerzbioruokreslonegoautora" {...props}></DivRow>
        <DivRow name={DATA_ZEBRANIA_ATTRIBUTE_NAME} dateEnd {...props}></DivRow>
        <DivRow name={TRANSLATIONS_ATTRIBUTE_NAME} array {...props}></DivRow>
    </div>

const HiddenDivRows = props => {
    const [ open, setOpen ] = useState(false)
    useEffect(() => {
        setOpen(props.editable)
    }, [ props.editable ])

    return (
        <>
            {!open && ([
                "obszarchroniony",
                "parknarodowy",
                "parkkrajobrazowy",
                "rezerwatprzyrody",
                "rodzajityprezerwatu",
                "obszarchronionegokrajobrazu",
                "uzytekekologiczny",
                "zespolprzyrodniczokrajobrazowy",
                "koditypobszarunatura2000",
                "nazwaobszarunatura2000",
                "polozeniewpodzialefizjograficznym",
                "polozeniewpodzialebiogeograficznymeuropy",
                "polozeniewpodzialegeograficznympotencjalnejroslinnoscinaturalne",
            ].reduce((acc, obj) => (
                acc || props.data[ obj ]
            ), false) || props.editable) &&
                <div className="d-flex" style={{ borderTopStyle: "solid", borderTopColor: "#dee2e6", borderTopWidth: "1px" }}>
                    <div className="w-50 p-1">
                    </div>
                    <div className="w-50 p-1">
                        <Button
                            variant="secondary"
                            onClick={() => setOpen(!open)}
                            size="sm">{props.t('common:more')}</Button>
                    </div>
                </div>
            }
            {open &&
                <>
                    <DivRow name="obszarchroniony" {...props}></DivRow>
                    <DivRow name="parknarodowy" {...props}></DivRow>
                    <DivRow name="parkkrajobrazowy" {...props}></DivRow>
                    <DivRow name="rezerwatprzyrody" {...props}></DivRow>
                    <DivRow name="rodzajityprezerwatu" {...props}></DivRow>
                    <DivRow name="obszarchronionegokrajobrazu" {...props}></DivRow>
                    <DivRow name="uzytekekologiczny" {...props}></DivRow>
                    <DivRow name="zespolprzyrodniczokrajobrazowy" {...props}></DivRow>
                    <DivRow name="koditypobszarunatura2000" {...props}></DivRow>
                    <DivRow name="nazwaobszarunatura2000" {...props}></DivRow>
                    <DivRow name="polozeniewpodzialefizjograficznym" {...props}></DivRow>
                    <DivRow name="polozeniewpodzialebiogeograficznymeuropy" {...props}></DivRow>
                    <DivRow name="polozeniewpodzialegeograficznympotencjalnejroslinnoscinaturalne" special {...props}></DivRow>
                </>
            }
        </>
    )
}

const Tab2 = props =>
    <div>
        {!props.editable &&
            <>
                <DivRowSwitch name="locationsite" switch="lokalizacjastanowisko" {...props}
                    badge={props.t('taxondb:przepisane_z_etykiety')}></DivRowSwitch>
                <DivRowSwitch name="habitat" switch="siedlisko" {...props}></DivRowSwitch>
            </>
        }
        {props.editable &&
            <>
                <DivRow name="lokalizacjastanowisko" {...props} label="lokalizacjastanowisko_edit" badge={props.t('taxondb:przepisane_z_etykiety')}></DivRow>
                <DivRow name="locationsite" {...props} label="locationsite_edit" badge={props.t('taxondb:przepisane_z_etykiety')}></DivRow>
                <DivRow name="siedlisko0" {...props} label="siedlisko0_edit"></DivRow>
                <DivRow name="siedlisko1" {...props} label="siedlisko1_edit"></DivRow>
                <DivRow name="habitat" {...props} label="habitat_edit"></DivRow>
            </>
        }
        <DivRow name="georeferencjakomentarze" {...props}></DivRow>
        <DivRow name="kontynent" {...props}></DivRow>
        <DivRow name="panstwo" {...props}
            badge={props.t('taxondb:na_podstawie_stanu', { date: RECORD_DETAILS_LOCATION_STATE_DATE_POPUP })}></DivRow>
        <DivRow name="wojewodztwo" {...props}
            badge={props.t('taxondb:na_podstawie_stanu', { date: RECORD_DETAILS_LOCATION_STATE_DATE_POPUP })}></DivRow>
        <DivRow name="powiat" {...props}
            badge={props.t('taxondb:na_podstawie_stanu', { date: RECORD_DETAILS_LOCATION_STATE_DATE_POPUP })}></DivRow>
        <DivRow name="gmina" {...props}
            badge={props.t('taxondb:na_podstawie_stanu', { date: RECORD_DETAILS_LOCATION_STATE_DATE_POPUP })}></DivRow>
        <DivRow name={COORDINATES_PRECISION} label={GPS_COORDINATES_ATTRIBUTE_NAME} gps {...props}></DivRow>
        <DivRow name="polozeniewzgledempoziomumorza" {...props} meters></DivRow>
        <DivRow name={ATPOL_COORDINATES} {...props}></DivRow>
        <DivRow name={UTM_COORDINATES} {...props}></DivRow>
        <HiddenDivRows {...props}></HiddenDivRows>
    </div>


const Tab3 = props =>
    <div>
        <DivRow name="opisuwagi" {...props}></DivRow>
        <DivRow name="pochodzenieokazu" {...props}></DivRow>
    </div>

const Tab35 = props =>
    <div>
        <DivRow name="instytucja" {...props}></DivRow>
        <DivRow name="botanikazoologia" dictionary={BOTANIKAZOOLOGIA_FIELD_NAME} {...props}></DivRow>
        <DivRow name={BOTANIKAZOOLOGIA_FIELD_NAME} {...props}></DivRow>
    </div>

const Tab4 = props =>
    <div>
        <DivRow name="bibliografia" {...props}></DivRow>
    </div>

const Tab5 = props =>
    <div>
        <DivRow name="administracjapanstwowaisamorzadowa" {...props} isBoolean></DivRow>
        <DivRow name="autoroznaczenia" {...props}></DivRow>
        <DivRow name="autorzbiorudodatkowyopis" {...props}></DivRow>
        <DivRow name="datainne" {...props}></DivRow>
        <DivRow name="dataoznaczeniazbioru" {...props}></DivRow>
        <DivRow name="datazebraniaprobykoniec" {...props}></DivRow>
        <DivRow name="grupydoceloweopisprzydatnosci" {...props}></DivRow>
        <DivRow name="kwalifikatorhybrydylubchimery" {...props}></DivRow>
        <DivRow name="lokalizacjakomentarze" {...props}></DivRow>
        <DivRow name="materialdowodowy" {...props}></DivRow>
        <DivRow name="metodazbioru" {...props}></DivRow>
        <DivRow name="numerproby" {...props}></DivRow>
        <DivRow name="opakowanienajnizszegorzedu" {...props}></DivRow>
        <DivRow name="opakowaniezbiorcze" {...props}></DivRow>
        <DivRow name="organizacjepozarzadowespoleczenstwo" {...props} isBoolean></DivRow>
        <DivRow name="oswiatanauczycielestudenciuczniowie" {...props} isBoolean></DivRow>
        <DivRow name="oznaczeniehybrydy" {...props}></DivRow>
        <DivRow name="plec" {...props}></DivRow>
        <DivRow name="podloze" {...props}></DivRow>
        <DivRow name="podrodzaj" {...props}></DivRow>
        <DivRow name="polkaszafaentomologiczna" {...props}></DivRow>
        <DivRow name="pomieszczenie" {...props}></DivRow>
        <DivRow name="pracownicynaukowidoktoranci" {...props} isBoolean></DivRow>
        <DivRow name="regalszafa" {...props}></DivRow>
        <DivRow name="rodzajtypunomenklatorycznego" {...props}></DivRow>
        <DivRow name="sluzbyifunkcjonariuszepanstwowi" {...props} isBoolean></DivRow>
        <DivRow name="sposobprzechowywania" {...props}></DivRow>
        <DivRow name="sposobyprzechowywaniasposobkonserwacjiizabezpieczenia" {...props}></DivRow>
        <DivRow name="stadium" {...props}></DivRow>
        <DivRow name="stanopracowaniakolekcji" {...props}></DivRow>
        <DivRow name="wiek" {...props}></DivRow>
        <DivRow name="wymiary" {...props}></DivRow>
        <DivRow name="wypozyczony" {...props}></DivRow>
    </div>

const AllTabs = props => {
    const { t, i18n } = useTranslation([ 'taxondb-groups', 'taxondb', 'common', 'taxondb-attributes', 'citation', 'forms' ])

    const [ key, setKey ] = useState('group-1')

    const [ editable, setEditable ] = useState(false)

    const { control, register, handleSubmit, errors, setError, clearErrors, reset, getValues, setValue } = useForm({
        defaultValues: props.data
    });

    const onEditableClick = () => {
        if (editable) {
            reset(getValues())    
        }

        setEditable(!editable)
    }

    const onSave = (fields) => {
        if (fields[LATITUDE_ATTRIBUTE_NAME]) {
            fields[LATITUDE_ATTRIBUTE_NAME] = formatStringToLatitude(fields[LATITUDE_ATTRIBUTE_NAME])
        }
        if (fields[LONGITUDE_ATTRIBUTE_NAME]) {
            fields[LONGITUDE_ATTRIBUTE_NAME] = formatStringToLongitude(fields[LONGITUDE_ATTRIBUTE_NAME])
        }
        clearErrors()
        props.saveRecord(props.recordId, fields, props.data, t, setError, props.state)
    }

    const onRemoveTaxon = (fields) => {
        // TODO
    }

    const onKeyDown = (e) => {
        if (['ArrowLeft', 'ArrowRight'].includes(e.key)){
            e.stopPropagation()
        }
    }

    return (
        <>
            {props.data &&
                <>
                    <Tabs className="citation-tab-pulled-right" defaultActiveKey={'group-1'} id={props.requestId} activeKey={key} onSelect={(k,e) => handleTabsA11y(k, e, setKey)}>
                        <Tab eventKey="group-1" title={t('group-1')} onKeyDown={onKeyDown}>
                            <Tab1 {...props} editable={editable} register={register} control={control} errors={errors} t={t} i18n={i18n}></Tab1>
                        </Tab>
                        <Tab eventKey="group-2" title={t('group-2')} onKeyDown={onKeyDown}>
                            <Tab2 {...props} editable={editable} register={register} control={control} errors={errors} t={t} i18n={i18n} setValue={setValue}></Tab2>
                        </Tab>
                        {([
                            "opisuwagi",
                            "pochodzenieokazu",
                        ].reduce((acc, obj) => (
                            acc || props.data[ obj ]
                        ), false) || editable) &&
                            <Tab eventKey="group-3" title={t('group-3')} onKeyDown={onKeyDown}>
                                <Tab3 {...props} editable={editable} register={register} control={control} errors={errors} t={t} i18n={i18n}></Tab3>
                            </Tab>
                        }
                        {([
                            "instytucja",
                            "botanikazoologia",
                            BOTANIKAZOOLOGIA_FIELD_NAME,
                        ].reduce((acc, obj) => (
                            acc || props.data[ obj ]
                        ), false) || editable) &&
                            <Tab eventKey="group-3-5" title={t('group-3-5')} onKeyDown={onKeyDown}>
                                <Tab35 {...props} editable={editable} register={register} control={control} errors={errors} t={t} i18n={i18n}></Tab35>
                            </Tab>
                        }
                        {(props.data[ 'bibliografia' ] || editable) &&
                            <Tab eventKey="group-4" title={t('group-4')} onKeyDown={onKeyDown}>
                                <Tab4 {...props} editable={editable} register={register} control={control} errors={errors} t={t} i18n={i18n}></Tab4>
                            </Tab>
                        }
                        {editable &&
                            <Tab eventKey="group-5" title={t('group-5')} onKeyDown={onKeyDown}>
                                <Tab5 {...props} editable={editable} register={register} control={control} errors={errors} t={t} i18n={i18n}></Tab5>
                            </Tab>
                        }
                        {props.data[ LATITUDE_ATTRIBUTE_NAME ] && props.data[ LONGITUDE_ATTRIBUTE_NAME ] &&
                            <Tab eventKey="map" title={t('taxondb:map')} className="d-flex flex-column mt-2" onKeyDown={onKeyDown}>
                                {key === 'map' &&
                                    <MarkerMap lat={props.data[ LATITUDE_ATTRIBUTE_NAME ]} lon={props.data[ LONGITUDE_ATTRIBUTE_NAME ]} prec={props.data[ GEOPRECISION_ATTRIBUTE_NAME ]}></MarkerMap>
                                }
                            </Tab>
                        }
                        {((Array.isArray(props.data.revisions) && props.data.revisions.length > 0) || editable) &&
                            <Tab eventKey="history" title={t('taxondb:history')} mountOnEnter={false} onKeyDown={onKeyDown}>
                                {(key === 'history' || editable) &&
                                    <RevisionsTable rev={props.data.revisions} name="revisions" editable={editable} register={register} control={control} errors={errors}></RevisionsTable>
                                }
                            </Tab>
                        }
                        <Tab eventKey="citation" title={t('citation:how-to-cite')} tabClassName="citation-tab" onKeyDown={onKeyDown}>
                            <Citation dictionary={BOTANIKAZOOLOGIA_FIELD_NAME} t={t} i18n={i18n} {...props}></Citation>
                        </Tab>
                        <Tab eventKey="share" title={t('common:share')} tabClassName="share-button" onKeyDown={onKeyDown}>
                            <ShareButton {...props}></ShareButton>
                        </Tab>
                    </Tabs>
                    {props.user != null && props.user.groups != null && props.user.groups.includes(USER_GROUPS_EDITOR_CONST) &&
                        <div className="mb-3 pl-2 pr-2">
                            <Form.Check
                                className="d-inline"
                                type="switch"
                                id={`editable-switch-${props.requestId}`}
                                label={t('forms:edit')}
                                defaultChecked={editable}
                                onClick={onEditableClick}
                            />
                            {editable && <div className="float-right">
                                <span className="d-inline-block">
                                    <Confirm
                                        onConfirm={onRemoveTaxon}
                                        size="sm"
                                        buttonLabel={t('forms:remove')}
                                        confirmationText={t('taxondb:confirm-remove-taxon')}
                                        disabled={props.isSaving || true /* TODO: waiting for an endpoint */}
                                        className="ml-1 mr-1"
                                    ></Confirm>
                                </span>
                                <Button onClick={handleSubmit(onSave)} size="sm" disabled={props.isSaving}>
                                    {t('forms:save')}
                                    {props.isSaving &&
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            className="ml-1"
                                        />
                                    }
                                </Button>
                            </div>}
                        </div>
                    }
                </>
            }
        </>
    )
}

const mapRecordDetailsOutput = (data) => {
    if (!data) {
        return data
    }

    let copy = { ...data }
    const toFixedPrecision = (x) => {
        return x === null ? null : parseFloat(x).toFixed(copy[ 'geodokladnosc' ] || 6)
    }

    copy[ LATITUDE_ATTRIBUTE_NAME ] = toFixedPrecision(copy[ LATITUDE_ATTRIBUTE_NAME ])
    copy[ LONGITUDE_ATTRIBUTE_NAME ] = toFixedPrecision(copy[ LONGITUDE_ATTRIBUTE_NAME ])
    delete copy[ 'rangataksonunizszegorzedu' ]
    delete copy[ 'taksonnizszegorzedu' ]
    delete copy[ 'autortaksonunizszegorzedu' ]
    if (data[ 'rangataksonunizszegorzedu' ] && data[ 'rangataksonunizszegorzedu' ].length > 0) {
        copy[ 'lower_taxons' ] = Array.from({ length: (data[ 'rangataksonunizszegorzedu' ] || []).length }).map((_, i) => {
            return {
                values: [ data[ 'rangataksonunizszegorzedu' ][ i ], data[ 'taksonnizszegorzedu' ][ i ], data[ 'autortaksonunizszegorzedu' ][ i ] ]
            }
        })
    }

    if (data[ 'revisions' ] && data[ 'revisions' ].length > 0) {
        copy[ 'revisions' ] = copy.revisions.map(revision => {
            let rcopy = { ...revision }
            delete rcopy[ 'rangataksonunizszegorzedu' ]
            delete rcopy[ 'taksonnizszegorzedu' ]
            delete rcopy[ 'autortaksonunizszegorzedu' ]
            rcopy[ 'lower_taxons' ] = Array.from({ length: (revision[ 'rangataksonunizszegorzedu' ] || []).length }).map((_, i) => {
                return {
                    values: [ revision[ 'rangataksonunizszegorzedu' ][ i ], revision[ 'taksonnizszegorzedu' ][ i ], revision[ 'autortaksonunizszegorzedu' ][ i ] ]
                }
            })

            return rcopy
        })
    } else {
        delete copy[ 'revisions' ]
    }

    return copy
}

const mapStateToProps = (state, ownProps) => ({
    state: state,
    user: state.user ? state.user.data : null,
    data: mapRecordDetailsOutput(getOutput(ownProps.requestId, state)),
    request: getRequest(ownProps.requestId, state),
    isSaving: isPending(TAXONDB_CHANGE_REQUEST_ID_PREFIX + ownProps.recordId, state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    saveRecord: (recordId, fields, oldFields, t, setError, state) => {
        const removeFields = (o, fields) => {
            fields.forEach(field => {
                delete o[ field ]
            })
        }

        const renameField = (o, oldName, newName) => {
            if (oldName in o) {
                o[ newName ] = o[ oldName ]
                delete o[ oldName ]
            }
        }

        const replaceEmptyStrings = (o) => {
            return JSON.parse(JSON.stringify(o, (k, v) => (typeof v === 'undefined' || v === '') ? null : v))
        }

        const replaceBooleans = (o, oldFields, includeFields) => {
            for (const field of includeFields) {
                if (!(field in o)) {
                    continue
                }

                if (o[field] !== null && o[field].toString() === oldFields[field].toString()) {
                    delete o[field];
                } else if (o[field] === 'true') {
                    o[field] = true
                } else if (o[field] === 'false') {
                    o[field] = false
                } else if (o[field] === null || o[field] === 'null') {
                    if (oldFields[field] === null || oldFields[field] === 'null') {
                        delete o[field]
                    } else {
                        o[field] = null;
                    }
                }
            }

            return o
        }

        const setLatituteLongitude = (o) => {
            const toFixedPrecision = (x, y) => {
                let re = /[+-]?(\d+)(\.(\d+))?/gm
                let [ a, b ] = [ [ ...(x || '').matchAll(re) ], [ ...(y || '').matchAll(re) ] ]
                let alen = (a.length > 0 && a[ 0 ].length >= 4 && a[ 0 ][ 3 ]) ? a[ 0 ][ 3 ].length : 0
                let blen = (b.length > 0 && b[ 0 ].length >= 4 && b[ 0 ][ 3 ]) ? b[ 0 ][ 3 ].length : 0
                if (isNaN(parseFloat(x))) {
                    return x;
                } else {
                    return parseFloat(x).toFixed(Math.max(alen, blen))
                }
            }

            if (o[ 'dlugoscgeograficzna' ]) {
                o[ 'dlugoscgeograficzna' ] = toFixedPrecision(o[ 'dlugoscgeograficzna' ], o[ 'szerokoscgeograficzna' ])
            }

            if (o[ 'szerokoscgeograficzna' ]) {
                o[ 'szerokoscgeograficzna' ] = toFixedPrecision(o[ 'szerokoscgeograficzna' ], o[ 'dlugoscgeograficzna' ])
            }
        }

        const setAltitude = (o) => {
            if (o[ 'polozeniewzgledempoziomumorza' ]) {
                o[ 'polozeniewzgledempoziomumorza' ] = parseInt(o[ 'polozeniewzgledempoziomumorza' ])
            }
        }

        const lowerTaxonsToArray = (o) => {
            for (let k in o[ 'lower_taxons' ]) {
                if (o[ 'lower_taxons' ][ k ]) {
                    o[ 'lower_taxons' ][ k ] = o[ 'lower_taxons' ][ k ][ 'values' ]
                }
            }

            for (let r in o[ 'revisions' ]) {
                if (o[ 'revisions' ][ r ]) {
                    for (let k in o[ 'revisions' ][ r ][ 'lower_taxons' ]) {
                        if (o[ 'revisions' ][ r ][ 'lower_taxons' ][ k ]) {
                            o[ 'revisions' ][ r ][ 'lower_taxons' ][ k ] = o[ 'revisions' ][ r ][ 'lower_taxons' ][ k ][ 'values' ]
                        }
                    }
                }
            }
        }

        const addEmptyArraysToRevisions = (o) => {
            for (let r in o[ 'revisions' ]) {
                if (o[ 'revisions' ][ r ]) {
                    if (!o[ 'revisions' ][ r ][ 'lower_taxons' ]) {
                        o[ 'revisions' ][ r ][ 'lower_taxons' ] = []
                    }
                }
            }
        }

        let newFields = Object.assign({}, fields)
        setAltitude(newFields)
        let diffFields = diff(oldFields, replaceEmptyStrings(newFields))
        diffFields = replaceBooleans(diffFields, oldFields, [
            'administracjapanstwowaisamorzadowa',
            'organizacjepozarzadowespoleczenstwo',
            'oswiatanauczycielestudenciuczniowie',
            'pracownicynaukowidoktoranci',
            'sluzbyifunkcjonariuszepanstwowi'
        ])
        setLatituteLongitude(diffFields)
        lowerTaxonsToArray(diffFields)
        lowerTaxonsToArray(newFields)
        if (diffFields[ 'lower_taxons' ]) {
            diffFields[ 'lower_taxons' ] = newFields[ 'lower_taxons' ]
        } else if (oldFields[ 'lower_taxons' ] && !newFields[ 'lower_taxons' ]) {
            diffFields[ 'lower_taxons' ] = []
        }

        if (diffFields[ 'revisions' ]) {
            diffFields[ 'revisions' ] = newFields[ 'revisions' ]
            addEmptyArraysToRevisions(diffFields)
        } else if (oldFields[ 'revisions' ] && !newFields[ 'revisions' ]) {
            diffFields[ 'revisions' ] = []
        }

        removeFields(diffFields, [ 'numerokazu', 'rodzajgatunek', BOTANIKAZOOLOGIA_FIELD_NAME, 'autortaksonunizszegorzedu', 'rangataksonunizszegorzedu',
            'taksonnizszegorzedu', 'taxon_units', 'images', 'translations', 'geodokladnosc', 'kolekcja', 'siedlisko' ])
        renameField(diffFields, 'dlugoscgeograficzna', 'dlugoscgeograficzna1')
        renameField(diffFields, 'szerokoscgeograficzna', 'szerokoscgeograficzna1')
        dispatch(postDataAnc(
            TAXONDB_CHANGE_REQUEST_ID_PREFIX + recordId,
            TAXONDB_CHANGE_REQUEST_URL + recordId + '/',
            { fields: diffFields },
            GENERAL_EXTENDED_REQUEST_TIMEOUT
        )).then(() => {
            const request = getRequest(ownProps.requestId, state)
            dispatch(getData(ownProps.requestId, request.url))
            notify.success(t('taxondb:modified-taxon'))
        }).catch(res => {
            const err = res?.response?.data?.error;
            if (err && err.taxon) {
                Object.keys(err.taxon).forEach(k => {
                    if (k === 'szerokośćgeograficzna') {
                        setError(LATITUDE_ATTRIBUTE_NAME, err.taxon[ k ]);
                    } else if (k === 'długośćgeograficzna') {
                        setError(LONGITUDE_ATTRIBUTE_NAME, err.taxon[ k ]);
                    } else {
                        setError(k, err.taxon[ k ]);
                    }
                })
            }

            if (err && err.lower_taxons && err.lower_taxons.length > 0) {
                for (const e of err.lower_taxons) {
                    for (const [ k, v ] of Object.entries(LOWERTAXONS_COLUMNS_MAP)) {
                        if (k in e && e[ k ].length > 0) {
                            setError(`lower_taxons[${parseInt(e.order)}].values[${v}]`, {
                                type: 'manual',
                                message: e[ k ][ 0 ]
                            });
                        }
                    }
                }
            }

            if (err && err.revisions && err.revisions.length > 0) {
                for (const r of err.revisions) {
                    for (const [ k, v ] of Object.entries(REVISIONS_COLUMNS_MAP)) {
                        if (v in r && r[ v ].length > 0) {
                            setError(`revisions[${parseInt(r.order)}].${k}`, {
                                type: 'manual',
                                message: r[ v ][ 0 ]
                            });
                        }
                    }

                    if ('lower_taxons' in r && r[ 'lower_taxons' ].length > 0) {
                        for (const e of r[ 'lower_taxons' ]) {
                            for (const [ k, v ] of Object.entries(LOWERTAXONS_COLUMNS_MAP)) {
                                if (k in e && e[ k ].length > 0) {
                                    setError(`revisions[${parseInt(r.order)}].lower_taxons[${parseInt(e.order)}].values[${v}]`, {
                                        type: 'manual',
                                        message: e[ k ][ 0 ]
                                    });
                                }
                            }
                        }
                    }
                }
            }

            if (res?.response?.data?.error?.taxon) {
                notify.error(t('taxondb:cannot-modify-taxon'), t('taxondb:cannot-modify-taxon-verify-fields'))
            } else {
                notify.error(t('taxondb:cannot-modify-taxon'), res?.response?.data?.error?.error || res?.response?.data)
            }
        })
    }
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllTabs)
