import React, { Suspense } from "react";
import { connect } from 'react-redux'
import { Container } from "react-bootstrap";
import { useTranslation } from 'react-i18next'
import { EDUCATION_MATERIALS_PAGE_URL_EN, EDUCATION_MATERIALS_PAGE_URL_PL } from '../settings'
import I18nPathTranslator from "./I18nPathTranslator";
import Loader from "../utils/loader";
import { Link } from "react-router-dom";
import SEO from "./SEO";


const EducationMaterials = props => {

    const { t } = useTranslation([ 'common', 'menu', 'paths' ])

    const album_ids = [
//        '411',
//        '1851',
	'1908',
        '1879'
    ]

    const album_titles = [
//        'Przykładowa prezentacja',
 //       'Zboża (autor - Paweł Wolniewicz, teksty na podstawie Wikipedii)',
        'Chwasty polne (autor - Piotr Szkudlarz)',
        'Rośliny łąkowe (autor - Piotr Szkudlarz)'
    ]

    return (
        <Suspense fallback={<Loader />}>
        <SEO title={t('menu:edu-materials')} />
        <I18nPathTranslator {...props} pathEN={EDUCATION_MATERIALS_PAGE_URL_EN} pathPL={EDUCATION_MATERIALS_PAGE_URL_PL} />
        <div>
            <Container>
                <div>
                    <h1>{t('menu:edu-materials')}</h1>
                    <ul className="site-map-list">
                        {album_ids.map((id,n)=>
                            <li key={id}><Link to={`/album/presentation/${id}`}>{album_titles[n]}</Link></li>
                        )} 
                    </ul>
                </div>
            </Container>
        </div>
    </Suspense>
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EducationMaterials)
