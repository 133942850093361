import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import OpenSeadragon from 'openseadragon'
import { Row, Col, Button, ButtonToolbar } from "react-bootstrap"
import { clearComparator } from '../../actions/comparator'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo, faSearchMinus, faSearchPlus, faDesktop } from '@fortawesome/free-solid-svg-icons'

const ComparedImage = props => {
    const { t } = useTranslation(['common', 'icondb'])

    OpenSeadragon.setString("Tooltips.Home", t('common:Home'));
    OpenSeadragon.setString("Tooltips.FullPage", t('common:FullPage'));
    OpenSeadragon.setString("Tooltips.ZoomIn", t('common:ZoomIn'));
    OpenSeadragon.setString("Tooltips.ZoomOut", t('common:ZoomOut'));

    useEffect(() => {
        const urls = [props.comparator.requestUrl1 + props.comparator.edi_id1 + '-' + props.comparator.edi_ver_id1 + '/info.json']
        if (props.comparator && props.comparator.edi_id1) {
            OpenSeadragon({
                id: 'openseadragon1',
                prefixUrl: "/openseadragon/images/",
                showNavigator: true,
                preserveViewport: true,
                sequenceMode: false,
                crossOriginPolicy: false,
                ajaxWithCredentials: false,
                tileSources: urls.map(tileSource => ({
                    tileSource,
                    ajaxHeaders: { "Authorization": "Bearer " + localStorage.getItem('access') }
                })),
                showNavigationControl: true,
                homeButton: 'reset-1',
                zoomInButton: 'zoom-in-1',
                zoomOutButton: 'zoom-out-1',
                fullPageButton: 'full-screen-1',
                loadTilesWithAjax: true,
            })
        }
    }, [props.comparator, props.comparator.edi_id1, props.comparator.edi_ver_id1])

    useEffect(() => {
        const urls = [props.comparator.requestUrl2 + props.comparator.edi_id2 + '-' + props.comparator.edi_ver_id2 + '/info.json']
        if (props.comparator && props.comparator.edi_id2) {
            OpenSeadragon({
                id: 'openseadragon2',
                prefixUrl: "/openseadragon/images/",
                showNavigator: true,
                preserveViewport: true,
                sequenceMode: false,
                crossOriginPolicy: false,
                ajaxWithCredentials: false,
                tileSources: urls.map(tileSource => ({
                    tileSource,
                    ajaxHeaders: { "Authorization": "Bearer " + localStorage.getItem('access') }
                })),
                showNavigationControl: true,
                homeButton: 'reset-2',
                zoomInButton: 'zoom-in-2',
                zoomOutButton: 'zoom-out-2',
                fullPageButton: 'full-screen-2',
                loadTilesWithAjax: true,
            })
        }
    }, [props.comparator, props.comparator.edi_id2, props.comparator.edi_ver_id2])

    return (
        <div className="mb-1">
            <Row>
                {
                    props.comparator &&
                    props.comparator.edi_id1 &&
                    <Col lg={6} md={12}>
                        <ButtonToolbar className="ml-2 mt-2">
                            <h5 className="text-center"><b>{props.comparator.external_id1}</b></h5>
                            <Button size="sm" className="ml-3" variant="dark" id='reset-1' disabled={props.loading}>
                                <FontAwesomeIcon icon={faUndo} />
                            </Button>
                            <Button size="sm" className="ml-1" variant="dark" id='zoom-in-1' disabled={props.loading}>
                                <FontAwesomeIcon icon={faSearchPlus} />
                            </Button>
                            <Button size="sm" className="ml-1" variant="dark" id="zoom-out-1" disabled={props.loading}>
                                <FontAwesomeIcon icon={faSearchMinus} />
                            </Button>
                            <Button size="sm" className="ml-1" variant="dark" id="full-screen-1" disabled={props.loading}>
                                <FontAwesomeIcon icon={faDesktop} />
                            </Button>
                        </ButtonToolbar>
                        <div id={'openseadragon1'} className="icondb-comparator-openseadragon-container mt-1"></div>
                    </Col>
                }
                {
                    props.comparator &&
                    props.comparator.edi_id2 &&
                    <Col lg={6} md={12}>
                        <ButtonToolbar className="ml-2 mt-2">
                            <h5><b>{props.comparator.external_id2}</b></h5>
                            <Button size="sm" className="ml-3" variant="dark" id="reset-2" disabled={props.loading}>
                                <FontAwesomeIcon icon={faUndo} />
                            </Button>
                            <Button size="sm" className="ml-1" variant="dark" id="zoom-in-2" disabled={props.loading}>
                                <FontAwesomeIcon icon={faSearchPlus} />
                            </Button>
                            <Button size="sm" className="ml-1" variant="dark" id="zoom-out-2" disabled={props.loading}>
                                <FontAwesomeIcon icon={faSearchMinus} />
                            </Button>
                            <Button size="sm" className="ml-1" variant="dark" id="full-screen-2" disabled={props.loading}>
                                <FontAwesomeIcon icon={faDesktop} />
                            </Button>
                        </ButtonToolbar>
                        <div id={'openseadragon2'} className="icondb-comparator-openseadragon-container mt-1"></div>
                    </Col>
                }
            </Row>
        </div>
    )
}

const mapStateToProps = state => ({
    comparator: state.comparator,
    user: state.user ? state.user.data : null,
})


const mapDispatchToProps = dispatch => ({
    clearComparator: () => dispatch(clearComparator())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ComparedImage)
