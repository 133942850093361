import React from "react"
import {
    TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID,
    TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID,
    TAXONDB_SEARCH_GENUS_REQUEST_ID,
} from "../../../../settings"
import L from 'leaflet';
import ReactDOMServer from 'react-dom/server';
import chroma from "chroma-js"
import * as notify from '../../../../utils/notify'
import { 
    defineRangesForSelectedNumberOfTaxonsAndColors, 
    findRangeForSelectedNumberOfTaxons, 
    findColorMatchingToCartogramRange,
    generate_cartogram_of_countries, 
    generateCartogramOfVoivodeships, 
    generateCartogramOfCommunes, 
    generateCartogramOfDistricts, 
    generateCartogramOfNationalParks, 
    generateCartogramOfLandscapeParks,
    generateGeometricSequenceForNumberOfTaxons,
    defineRangesForGeometricSequence,
    generateCartogramOfAtpolGrid,
    generateCartogramOfUTMGridPoland,
    generateCartogramOfLocationPhysiographicDivision,
} from "../CartogramFunctions"

export const clusteringCartogramSearch = (ref, props, t) => {

        const { closeSlidebar, isDynamic, runCartogramSearch, manualRanges, sequenceType, cartogramAdministrativeDivision,
             numberOfColors, cartogramFirstAreaColor, cartogramSecondAreaColor, cartogramBordersColor, cartogramBordersWidth,
             cartogramOpacity, sequenceProduct, firstSequenceNumber } = { ...props }

        /* Clear Cartogram */
        if (ref.cartogramRef.current){
            ref.cartogramRef.current.eachLayer(function(layer){
                ref.cartogramRef.current.removeLayer(layer);
            });
        }

        const SEQUENCE_TYPE_ARYTHMETIC = "SEQUENCE_TYPE_ARYTHMETIC"
        const SEQUENCE_TYPE_GEOMETRIC = "SEQUENCE_TYPE_GEOMETRIC"
        const SEQUENCE_TYPE_MANUAL = "SEQUENCE_TYPE_MANUAL"

        const prepareCartogramLegend = (rangesOfColors, colorsArray) => {        
            ref.cartogramLegendDiv.current.onAdd = function() {
                var div = L.DomUtil.create("div", "legend");
                div.style.backgroundColor = 'white';
                div.style.padding = '10px';
                div.style.width = '260px'
                div.innerHTML = ReactDOMServer.renderToString(
                    <div style={{fontSize: 10, float: 'center'}}>
                        {rangesOfColors.map((range, index) => {
                            return (
                                <div key={index} >
                                    <div style={{width:'300px'}}>
                                        {prepareLegendColorBar(colorsArray, index, range)}
                                        {prepareLegendText(index, range)}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )
                return div
            }
            ref.cartogramLegendDiv.current.addTo(ref.mapRef.current)  
        }

        const prepareLegendText = (index, range) => {
            if (sequenceType === SEQUENCE_TYPE_MANUAL){
                if (range.min >= 0 && range.max >= 0){
                    return(
                        <span style={{ backgroundColor: 'white', width:'90px', marginLeft:'5px' }} >
                            {(range.min === 0 && range.max ===  0) && 
                                t('map:cartogram-legend-3')
                            }
                            {!(range.min === 0 && range.max ===  0) &&
                                t('map:cartogram-legend-1', {from: range.min, to: range.max} )
                            }
                        </span>
                    )
                }
            }
            else{
                return(
                    <span style={{ backgroundColor: 'white', width:'90px', marginLeft:'5px' }} >
                        {index === 0 && 
                            t('map:cartogram-legend-3')
                        }
                        {index > 0 &&
                            t('map:cartogram-legend-1', {from: range.min, to: range.max} )
                        }
                    </span>
                )
            }
        }

        const prepareLegendColorBar = (colorsArray, index, range) => {
            if (sequenceType === SEQUENCE_TYPE_MANUAL){
                if (range.min >= 0 && range.max >= 0){
                    return (
                        <span style={{height:'20px', backgroundColor: colorsArray[index], paddingRight:'95px', border: 'ridge' }} />
                    )
                }
                else {
                    return null
                }
            }
            else {
                return (
                    <span style={{height:'20px', backgroundColor: colorsArray[index], paddingRight:'95px', border: 'ridge' }} />
                )
            }
        }

        const prepareCartogramCallback = (items, AREA_WITHOUT_TAXONS, loader) => {
            return Promise.resolve(
                prepareCartogram(items, AREA_WITHOUT_TAXONS, loader)
            )
        }

        const prepareCartogram = (items, AREA_WITHOUT_TAXONS, loader) => {
            
            let cartogramParams = {
                areaWithoutTaxons: AREA_WITHOUT_TAXONS, 
                areaFillOpacity: cartogramOpacity, 
                borderOpacity: cartogramBordersWidth, 
                administrativeBorderColor: cartogramBordersColor
            }

            switch(cartogramAdministrativeDivision){
                case "panstwo":
                    generate_cartogram_of_countries(items, ref.cartogramRef.current, renderPopup, cartogramParams, loader, t)
                    break
                case "wojewodztwo":
                    generateCartogramOfVoivodeships(items, ref.cartogramRef.current, renderPopup, cartogramParams, loader, t)
                    break
                case "powiat":
                    generateCartogramOfDistricts(items, ref.cartogramRef.current, renderPopup, cartogramParams, loader, t)
                    break
                case "gmina":
                    generateCartogramOfCommunes(items, ref.cartogramRef.current, renderPopup, cartogramParams, loader, t)
                    break
                case "parknarodowy":
                    generateCartogramOfNationalParks(items, ref.cartogramRef.current, renderPopup, cartogramParams, loader, t)
                    break
                case "parkkrajobrazowy":
                    generateCartogramOfLandscapeParks(items, ref.cartogramRef.current, renderPopup, cartogramParams, loader, t)
                    break
                case "wspolrzedneatpol":
                    generateCartogramOfAtpolGrid(items, ref.cartogramRef.current, renderPopup, cartogramParams, loader, t)
                    break
                case "wspolrzedneutm":
                    generateCartogramOfUTMGridPoland(items, ref.cartogramRef.current, renderPopup, cartogramParams, loader, t)
                    break
                case "polozeniewpodzialefizjograficznym":
                    generateCartogramOfLocationPhysiographicDivision(items, ref.cartogramRef.current, renderPopup, cartogramParams, loader, t)
                    break
                default:
                    notify.error(t('map:administrative-division-undefined'))
                    break
            }
        }

        const renderPopup = (text, taxons) => {
            return ReactDOMServer.renderToString(
                <div>
                    <div>{t(`taxondb-attributes:${cartogramAdministrativeDivision}`)}: {text}</div>
                    <div>{t('map:number-of-taxons-popup')} {taxons}</div>
                </div>
            )
        }

        const removeCartogramLegend = () => {
            if (ref.cartogramLegendDiv.current){
                ref.cartogramLegendDiv.current.remove()
            }
            ref.cartogramLegendDiv.current = L.control({ position: "bottomleft" })   
        }

        const getFilterId = () => {
            let id

            if (isDynamic === "0"){
                id = TAXONDB_SEARCH_GENUS_REQUEST_ID
            }
            if (isDynamic === "2"){
                id = TAXONDB_DYNAMIC2_SEARCH_GENUS_REQUEST_ID
            }
            if (isDynamic === "1"){
                id = TAXONDB_DYNAMIC_SEARCH_GENUS_REQUEST_ID
            }
            return id
        }


        const confirmUndefinedRangeItem = (item) => {
            return ( item  === undefined || Number.isNaN(item) || item  === null )
        }

        closeSlidebar()
        let loader = L.control.loader()
        loader.addTo(ref.mapRef.current);
        removeCartogramLegend()
        let id = getFilterId()

        return runCartogramSearch(id, cartogramAdministrativeDivision)
        .then(res => {
            if (res.items && res.items.length){
                let items = new Map()
                let maximalNumOfTaxons = res.items[0].liczba_okazow
                let arrayOfRanges
                let colors = []
                if (sequenceType === SEQUENCE_TYPE_ARYTHMETIC){
                    arrayOfRanges = defineRangesForSelectedNumberOfTaxonsAndColors(numberOfColors, maximalNumOfTaxons)
                    colors = chroma.scale([cartogramFirstAreaColor, cartogramSecondAreaColor]).mode('lch').colors(numberOfColors)
                }
                else if (sequenceType === SEQUENCE_TYPE_GEOMETRIC){
                    let sequence = generateGeometricSequenceForNumberOfTaxons(firstSequenceNumber, sequenceProduct, maximalNumOfTaxons, parseInt(numberOfColors))
                    arrayOfRanges = defineRangesForGeometricSequence(sequence, maximalNumOfTaxons)
                    colors = chroma.scale([cartogramFirstAreaColor, cartogramSecondAreaColor]).mode('lch').colors(parseInt(numberOfColors) + 2)
                }
                else if (sequenceType === SEQUENCE_TYPE_MANUAL){
                    arrayOfRanges = manualRanges
                    colors = chroma.scale([cartogramFirstAreaColor, cartogramSecondAreaColor]).mode('lch').colors(parseInt(numberOfColors))
                    let lastItem
                    let lastItemIndex 
                    arrayOfRanges.forEach( (item, index) => {
                        if (item.min && confirmUndefinedRangeItem(item.max)) {
                            lastItem = item.min
                            lastItemIndex = index
                        }
                    })
                    if (lastItem < maximalNumOfTaxons){
                        arrayOfRanges[lastItemIndex].max = maximalNumOfTaxons
                    }
                }

                let AREA_WITHOUT_TAXONS = colors[0]
            
                res.items.forEach((item) => {   
                    let taxonRange = findRangeForSelectedNumberOfTaxons(item.liczba_okazow, arrayOfRanges)
                    let matchingColor = findColorMatchingToCartogramRange(taxonRange, colors)
                    items.set(item[cartogramAdministrativeDivision], {
                        color: matchingColor,
                        numberOfTaxons: item.liczba_okazow
                    })
                })

                return {
                    items : items, 
                    areaWithoutTaxons: AREA_WITHOUT_TAXONS,
                    maximalValue: maximalNumOfTaxons,
                    colorsRanges: arrayOfRanges,
                    colors: colors
                }
            }
            else {
                return "NO_TAXONS"
            }
        })
        .then((response) => {
            
            if (response.items && response.items.size){
                prepareCartogramCallback(response.items, response.areaWithoutTaxons, loader)
                .then(() => {
                    prepareCartogramLegend(response.colorsRanges, response.colors)
                })
            }
            else{
                notify.error(t("map:cartogram-error-msg"))
                loader.hide();
            }
        })
        .catch(
            function (error) {
                notify.error(t("map:cartogram-error-msg"))
                loader.hide();
                return Promise.reject(error)
            }
        )
}
