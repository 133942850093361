import React, { useState } from "react";
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { isPending, getItems, getPagination, getOutput } from "../../reducers";
import { Spinner } from "react-bootstrap"
import SingleDlibraImage from './DlibraImage'
import RecordDetails from './RecordDetails'
import { Button, DropdownButton, Dropdown, ButtonGroup } from "react-bootstrap"
import * as actions from '../../actions'
import * as notify from '../../utils/notify'
import { setField } from '../../actions/forms'
import { getField } from '../../reducers'
import {
    TAXONDB_DETAILS_REQUEST_URL,
    TAXONDB_DETAILS_REQUEST_ID_PREFIX,
    TAXONDB_SEARCH_ICONS_REQUEST_URL,
    TAXONDB_RESULTS_ACTIVE_ICON_RECORD,
    API_ALBUM_GET_ALL_ID,
    API_ALBUM_GET_ALL_URL,
    DLIBRA_IIIF_URL,
    TAXONDB_DETAILS_PAGE_URL,
} from '../../settings'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faTimes } from '@fortawesome/free-solid-svg-icons'
import AddToAlbum from '../profile/AddToAlbum'
import { addPictureOne, addPictureTwo, clearComparator } from '../../actions/comparator'
import { Redirect } from "react-router-dom"

const IconDetailsContainer = props => {
    const { t } = useTranslation([ 'common', 'forms', 'taxondb', 'icondb', 'profile', 'paths' ])
    const [ addAlbumOpen, setAddAlbumOpen ] = useState(false)
    const [ redirect, setRedirect ] = useState(false)
    const [ redirectToComparator, setRedirectToComparator ] = useState(false)

    const getInitialImage = () => {
        let index = 0;
        if (props.open && props.data) {
            index = props.data.images.findIndex(val => val[ 0 ] === props.open.edi_id)
        }

        return index >= 0 ? index : 0
    }

    const [ activeImage, setActiveImage ] = useState(getInitialImage())

    if (redirect) {
        return <Redirect push to={t('paths:taxonDB')} />
    }

    const getTaxonUrl = () => {
        return TAXONDB_DETAILS_PAGE_URL + props.data?.kolekcjanumerokazu
    }

    const getTaxonId = () => {
        return props.data?.kolekcjanumerokazu
    }

    const goPrevious = () => {
        const currentIndx = props.items.findIndex(obj => obj.edi_id === props.open?.edi_id)
        if (currentIndx <= 0) {
            const newPagination = { ...props.pagination }
            newPagination.currentPage = props.pagination.currentPage - 1
            props.search({
                filter: props.customFilter.filter,
                pagination: newPagination,
            }).then(data => {
                if (data[ "items" ] && data.items.length > 0) {
                    const record = data.items[ data.items.length - 1 ]
                    props.setOpen(record)
                    props.getDetails(record.kolekcjanumerokazu)
                }
            })
        } else {
            const record = props.items[ currentIndx - 1 ]
            props.setOpen(record)
            props.getDetails(record.kolekcjanumerokazu)
        }
    }

    const goNext = () => {
        const currentIndx = props.items.findIndex(obj => obj.edi_id === props.open?.edi_id)
        if (currentIndx >= props.items.length - 1) {
            const newPagination = { ...props.pagination }
            newPagination.currentPage = props.pagination.currentPage + 1
            props.search({
                filter: props.customFilter.filter,
                pagination: newPagination,
            }).then(data => {
                if (data[ "items" ] && data.items.length > 0) {
                    const record = data.items[ 0 ]
                    props.setOpen(record)
                    props.getDetails(record.kolekcjanumerokazu)
                }
            })
        } else {
            const record = props.items[ currentIndx + 1 ]
            props.setOpen(record)
            props.getDetails(record.kolekcjanumerokazu)
        }
    }

    const handleAddToComparator = (data) => {
        if (!props.comparator)
            addFirstPictureToComparator(data)
        else if (props.comparator && props.comparator.edi_id1)
            addSecondPictureToComparator(data)
    }

    const addFirstPictureToComparator = (data) => {
        props.addPictureOneToComparator({
            edi_id1: data.images[ activeImage ][ 0 ],
            edi_ver_id1: data.images[ activeImage ][ 1 ],
            external_id1: data.kolekcjanumerokazu,
            FullAuthorName1: data.autoroznaczenia,
            requestUrl1: DLIBRA_IIIF_URL
        })
    }

    const addSecondPictureToComparator = (data) => {
        props.addPictureTwoToComparator({
            edi_id2: data.images[ activeImage ][ 0 ],
            edi_ver_id2: data.images[ activeImage ][ 1 ],
            external_id2: data.kolekcjanumerokazu,
            FullAuthorName2: data.autoroznaczenia,
            requestUrl2: DLIBRA_IIIF_URL
        })
        setRedirectToComparator(true)
    }

    const hasPrevious = () => {
        return !(props.loading || !props.items || !props.data || (
            props.items.findIndex(obj => obj.edi_id === props.open?.edi_id) === 0 &&
            props.pagination.currentPage <= 1))
    }

    const hasNext = () => {
        return !(props.loading || !props.items || !props.data || (
            props.items.findIndex(obj => obj.edi_id === props.open?.edi_id) === props.items.length - 1 &&
            props.pagination.currentPage === props.pagination.totalPages))
    }

    const onActiveImageChange = (page) => {
        const newActiveImage = page - 1
        if (newActiveImage >= 0 && newActiveImage < props.data?.images?.length) {
            setActiveImage(newActiveImage)
        }
    }

    const shareProps = {
        recordId: props?.data?.kolekcjanumerokazu,
        detailsUrl: TAXONDB_DETAILS_PAGE_URL,
        addFirstPictureToComparator: addFirstPictureToComparator,
        addSecondPictureToComparator: addSecondPictureToComparator,
        setAddAlbumOpen: setAddAlbumOpen,
        setRedirect: setRedirect
    }

    return (
        <div className="d-flex flex-column pt-1 common-panel-background">
            <div className="d-flex">
                {!props.noheader &&
                    <Button variant="light" size="sm" disabled={props.loading} onClick={() => props.setOpen(null)}>
                        <FontAwesomeIcon icon={faTimes} title={t('close')} />
                    </Button>
                }
                <div className="d-flex flex-fill justify-content-center">
                </div>
                {!props.noheader &&
                    <>
                        <Button size="sm" variant="light" disabled={!hasPrevious()} onClick={goPrevious}>
                            <FontAwesomeIcon icon={faArrowLeft} title={t('PreviousPage')} />
                        </Button>
                        <Button size="sm" variant="light" disabled={!hasNext()} onClick={goNext}>
                            <FontAwesomeIcon icon={faArrowRight} title={t('NextPage')} />
                        </Button>
                    </>
                }
            </div>
            <div className="d-flex flex-column pt-2 icon-container">
                {props.loading &&
                    <div className="align-self-center m-5">
                        <Spinner animation="border" role="status" />
                    </div>
                }
                {!props.loading && props.data &&
                    <div className="d-flex flex-column">
                        <SingleDlibraImage requestId={props.requestId} onImageChange={onActiveImageChange} initialImage={getInitialImage()}></SingleDlibraImage>
                    </div>
                }
                {addAlbumOpen && !props.loading && props.data &&
                    <AddToAlbum kind="taxon" itemId={getTaxonId()} onDone={e => setAddAlbumOpen(false)}></AddToAlbum>
                }
                {!props.loading &&
                    <div className="share-container">
                        <DropdownButton as={ButtonGroup} variant="light" title={t('common:share')} size="sm" className="share-button">
                            <Dropdown.Item size="sm" onClick={e => {
                                window.open(getTaxonUrl(), new Date().valueOf())
                            }}>{t('common:open-in-new-tab-window')}</Dropdown.Item>
                            <Dropdown.Item size="sm" onClick={e => {
                                window.location = getTaxonUrl()
                            }}>{t('common:open-in-current-tab-window')}</Dropdown.Item>
                            <Dropdown.Item size="sm" onClick={e => {
                                navigator.clipboard.writeText(window.location.origin + getTaxonUrl())
                                notify.success(t('common:copied-to-clipboard'))
                            }}>{t('common:copy-url-to-clipboard')}</Dropdown.Item>
                            <Dropdown.Divider></Dropdown.Divider>
                            <Dropdown.Item
                                onClick={e => {
                                    setAddAlbumOpen(true)
                                    props.getAlbums()
                                }}
                                size="sm">{t('profile:add-to-album')}
                            </Dropdown.Item>
                            <Dropdown.Divider></Dropdown.Divider>
                            <Dropdown.Item
                                onClick={e => {
                                    handleAddToComparator(props.data)
                                }}
                                size="sm">
                                {props.comparator && props.comparator.external_id1 ? t('icondb:compare-image-with') + props.comparator.external_id1 : t('icondb:add-to-comparator')}
                            </Dropdown.Item>
                            {props.comparator && props.comparator.external_id1 &&
                                <Dropdown.Item
                                    onClick={() => props.clearComparator()}
                                    size="sm">
                                    {t('icondb:clear-comparator')}
                                </Dropdown.Item>
                            }
                        </DropdownButton>
                    </div>
                }
                {!props.loading && props.data &&
                    <div className="d-flex flex-column">
                        <RecordDetails requestId={props.requestId} {...shareProps}></RecordDetails>
                    </div>
                }
                {!props.loading && !props.data &&
                    <div className="d-flex justify-content-center">
                        <p>{t('no-records-found')}</p>
                    </div>
                }
            </div>
            {redirectToComparator &&
                <Redirect push to={t('paths:comparator')} />
            }
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    open: getField(TAXONDB_RESULTS_ACTIVE_ICON_RECORD, TAXONDB_RESULTS_ACTIVE_ICON_RECORD, state, null),
    items: getItems(ownProps.iconsRequestId, state),
    loading: isPending(ownProps.requestId, state),
    data: getOutput(ownProps.requestId, state),
    pagination: getPagination(ownProps.iconsRequestId, state),
    comparator: state.comparator
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    getDetails: recordId => dispatch(actions.getDataAnc(TAXONDB_DETAILS_REQUEST_ID_PREFIX + recordId, TAXONDB_DETAILS_REQUEST_URL + recordId + '/')),
    setOpen: record => dispatch(setField(TAXONDB_RESULTS_ACTIVE_ICON_RECORD, TAXONDB_RESULTS_ACTIVE_ICON_RECORD, record)),
    search: data => dispatch(actions.postDataAnc(ownProps.iconsRequestId, TAXONDB_SEARCH_ICONS_REQUEST_URL, data)),
    getAlbums: () => dispatch(actions.postDataApi(API_ALBUM_GET_ALL_ID, API_ALBUM_GET_ALL_URL)),
    addPictureOneToComparator: (data) => dispatch(addPictureOne(data)),
    addPictureTwoToComparator: (data) => dispatch(addPictureTwo(data)),
    clearComparator: () => dispatch(clearComparator())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IconDetailsContainer)
