export function UTM(){
    var polyLayer = []
    polyLayer = [
    // path, color
    // Zone: 33U
        [[[12, 48], [12, 50], [12, 52], [12, 54], [12, 56]], 2, '#000000'],
    // Zone: 34U
        [[[17.999998927116394, 49.61365751594338], [18, 50], [18, 52], [18, 54], [17.999982833862305, 55.00986465212557]], 2, '#000000'],
   
    // Zone: 35U
        [[[24.00000125169754, 49.61365751594338], [24, 50], [24, 52], [24, 54], [23.999998569488525, 55.009815433740705]], 2, '#000000'],
    

    // Zone: 36U
        [[[30, 48], [30, 50], [30, 52], [30, 54], [30, 56]], 2, '#000000'],
    // Band: 33U, 34U, 35U
        [[[12, 48], [12.5, 48], [13, 48], [13.5, 48], [14, 48], [14.5, 48], [15, 48], [15.5, 48], [16, 48], [16.5, 48], [17, 48], [17.5, 48], [18, 48], [18.5, 48], [19, 48], [19.5, 48], [20, 48], [20.5, 48], [21, 48], [21.5, 48], [22, 48], [22.5, 48], [23, 48], [23.5, 48], [24, 48], [24.5, 48], [25, 48], [25.5, 48], [26, 48], [26.5, 48], [27, 48], [27.5, 48], [28, 48], [28.5, 48], [29, 48], [29.5, 48], [30, 48]], 2, '#000000'],
    // Band: 33V, 34V, 35V
        [[[12, 56], [12.5, 56], [13, 56], [13.5, 56], [14, 56], [14.5, 56], [15, 56], [15.5, 56], [16, 56], [16.5, 56], [17, 56], [17.5, 56], [18, 56], [18.5, 56], [19, 56], [19.5, 56], [20, 56], [20.5, 56], [21, 56], [21.5, 56], [22, 56], [22.5, 56], [23, 56], [23.5, 56], [24, 56], [24.5, 56], [25, 56], [25.5, 56], [26, 56], [26.5, 56], [27, 56], [27.5, 56], [28, 56], [28.5, 56], [29, 56], [29.5, 56], [30, 56]], 2, '#000000'],
    // strefa 33, linie poziome
        [[[15,49.65254292], [15.69266437,49.65047112], [16.38516863,49.64425686], [17.07735289,49.63390363], [17.76905775,49.61941719], [18,49.61365805]], 1, '#000000'],
        [[[15,49.74248818], [15.6939439,49.74040981], [16.38772663,49.73417588], [17.08118729,49.72378988], [17.77416541,49.70925762], [18,49.70362382]], 1, '#909090'],
        [[[15,49.83243203], [15.69522987,49.83034708], [16.39029752,49.8240934], [17.08504099,49.81367451], [17.77929876,49.79909626], [18,49.79358857]], 1, '#909090'],
        [[[15,49.92237447], [15.69652233,49.92028292], [16.39288138,49.91400942], [17.08891411,49.90355753], [17.78445798,49.88893311], [18,49.8835523]], 1, '#909090'],
        [[[15,50.01231552], [15.69782132,50.01021733], [16.39547829,50.00392394], [17.0928068,49.99343891], [17.78964323,49.97876817], [18,49.973515]], 1, '#909090'],
        [[[15,50.10225516], [15.69912689,50.10015031], [16.39808835,50.09383696], [17.09671917,50.08331868], [17.79485468,50.06860143], [18,50.06347669]], 1, '#909090'],
        [[[15,50.1921934], [15.70043908,50.19008187], [16.40071162,50.18374848], [17.10065136,50.17319682], [17.8000925,50.1584329], [18,50.15343736]], 1, '#909090'],
        [[[15,50.28213025], [15.70175793,50.28001201], [16.40334822,50.2736585], [17.10460349,50.26307334], [17.80535688,50.24826256], [18,50.24339701]], 1, '#909090'],
        [[[15,50.37206569], [15.70308348,50.36994072], [16.40599821,50.36356701], [17.10857571,50.35294823], [17.81064797,50.33809043], [18,50.33335564]], 1, '#909090'],
        [[[15,50.46199973], [15.70441579,50.459868], [16.4086617,50.45347403], [17.11256813,50.44282149], [17.81596597,50.42791649], [18,50.42331326]], 1, '#909090'],
        [[[13.58866123,50.54337955], [14.29424511,50.54979387], [15,50.55193238], [15.70575489,50.54979387], [16.41133877,50.54337955], [17.1165809,50.53269313], [17.82131105,50.51774074], [18,50.51326985]], 1, '#000000'],
        [[[13.58597049,50.63328357], [14.29289917,50.63971831], [15,50.64186363], [15.70710083,50.63971831], [16.41402951,50.63328357], [17.12061415,50.62256314], [17.82668339,50.60756319], [18,50.60322543]], 1, '#909090'],
        [[[13.58326598,50.7231861], [14.29154634,50.72964133], [15,50.73179349], [15.70845366,50.72964133], [16.41673402,50.7231861], [17.12466802,50.71243152], [17.83208317,50.69738383], [18,50.69318]], 1, '#909090'],
        [[[13.58054762,50.81308712], [14.29018658,50.81956293], [15,50.82172195], [15.70981342,50.81956293], [16.41945238,50.81308712], [17.12874265,50.80229828], [17.83751058,50.78720266], [18,50.78313355]], 1, '#909090'],
        [[[13.57781532,50.90298664], [14.28881983,50.90948311], [15,50.91164902], [15.71118017,50.90948311], [16.42218468,50.90298664], [17.13283817,50.8921634], [17.8429658,50.87701968], [18,50.87308608]], 1, '#909090'],
        [[[13.57506896,50.99288466], [14.28744606,50.99940187], [15,51.00157469], [15.71255394,50.99940187], [16.42493104,50.99288466], [17.13695473,50.98202689], [17.84844902,50.96683488], [18,50.9630376]], 1, '#909090'],
        [[[13.57230847,51.08278119], [14.28606522,51.08931921], [15,51.09149897], [15.71393478,51.08931921], [16.42769153,51.08278119], [17.14109247,51.07188875], [17.85396043,51.05664826], [18,51.05298811]], 1, '#909090'],
        [[[13.56953375,51.17267622], [14.28467725,51.17923513], [15,51.18142187], [15.71532275,51.17923513], [16.43046625,51.17267622], [17.14525154,51.16174898], [17.85950021,51.14645983], [18,51.14293761]], 1, '#909090'],
        [[[13.5667447,51.26256975], [14.28328211,51.26914964], [15,51.27134337], [15.71671789,51.26914964], [16.4332553,51.26256975], [17.14943207,51.25160757], [17.86506857,51.23626957], [18,51.23288609]], 1, '#909090'],
        [[[13.56394121,51.35246178], [14.28187976,51.35906273], [15,51.36126348], [15.71812024,51.35906273], [16.43605879,51.35246178], [17.15363421,51.34146454], [17.87066569,51.32607749], [18,51.32283356]], 1, '#909090'],
        [[[13.56112321,51.44235232], [14.28047013,51.4489744], [15,51.4511822], [15.71952987,51.4489744], [16.43887679,51.44235232], [17.15785812,51.43131986], [17.87629177,51.41588359], [18,51.41278003]], 1, '#000000'],
        [[[13.55829057,51.53224135], [14.27905318,51.53888467], [15,51.54109954], [15.72094682,51.53888467], [16.44170943,51.53224135], [17.16210393,51.52117356], [17.88194701,51.50568786], [18,51.50272548]], 1, '#909090'],
        [[[13.55544321,51.62212889], [14.27762887,51.62879351], [15,51.63101549], [15.72237113,51.62879351], [16.44455679,51.62212889], [17.16637181,51.61102561], [17.88763161,51.59549029], [18,51.59266992]], 1, '#909090'],
        [[[13.55258102,51.71201494], [14.27619713,51.71870095], [15,51.72093006], [15.72380287,51.71870095], [16.44741898,51.71201494], [17.17066189,51.70087603], [17.89334577,51.6852909], [18,51.68261336]], 1, '#909090'],
        [[[13.5497039,51.80189948], [14.27475793,51.80860697], [15,51.81084325], [15.72524207,51.80860697], [16.4502961,51.80189948], [17.17497435,51.79072481], [17.89908969,51.77508967], [18,51.77255579]], 1, '#909090'],
        [[[13.54681175,51.89178253], [14.2733112,51.89851158], [15,51.90075505], [15.7266888,51.89851158], [16.45318825,51.89178253], [17.17930932,51.88057196], [17.90486358,51.86488661], [18,51.86249721]], 1, '#909090'],
        [[[13.54390446,51.98166409], [14.2718569,51.98841478], [15,51.99066547], [15.7281431,51.98841478], [16.45609554,51.98166409], [17.18366696,51.97041746], [17.91066765,51.9546817], [18,51.95243762]], 1, '#909090'],
        [[[13.54098192,52.07154414], [14.27039496,52.07831657], [15,52.0805745], [15.72960504,52.07831657], [16.45901808,52.07154414], [17.18804744,52.06026133], [17.9165021,52.04447496], [18,52.04237703]], 1, '#909090'],
        [[[13.53804404,52.16142271], [14.26892535,52.16821695], [15,52.17048216], [15.73107465,52.16821695], [16.46195596,52.16142271], [17.19245091,52.15010355], [17.92236715,52.13426637], [18,52.13231544]], 1, '#909090'],
        [[[13.5350907,52.25129977], [14.26744801,52.25811593], [15,52.26038844], [15.73255199,52.25811593], [16.4649093,52.25129977], [17.19687754,52.23994414], [17.92826301,52.22405594], [18,52.22225284]], 1, '#909090'],
        [[[13.5321218,52.34117535], [14.26596287,52.3480135], [15,52.35029335], [15.73403713,52.3480135], [16.4678782,52.34117535], [17.20132748,52.32978308], [17.93418989,52.31384366], [18,52.31218924]], 1, '#000000'],
        [[[13.52913722,52.43104942], [14.26446989,52.43790966], [15,52.44019688], [15.73553011,52.43790966], [16.47086278,52.43104942], [17.20580089,52.41962038], [17.94014802,52.40362953], [18,52.40212464]], 1, '#909090'],
        [[[13.52613686,52.52092201], [14.26296901,52.52780442], [15,52.53009903], [15.73703099,52.52780442], [16.47386314,52.52092201], [17.21029795,52.50945603], [17.94613761,52.49341355], [18,52.49205903]], 1, '#909090'],
        [[[13.5231206,52.6107931], [14.26146018,52.61769777], [15,52.61999981], [15.73853982,52.61769777], [16.4768794,52.6107931], [17.21481882,52.59929004], [17.95215888,52.58319572], [18,52.58199243]], 1, '#909090'],
        [[[13.52008834,52.70066269], [14.25994334,52.70758973], [15,52.70989922], [15.74005666,52.70758973], [16.47991166,52.70066269], [17.21936367,52.68912241], [17.95821206,52.67297603], [18,52.67192482]], 1, '#909090'],
        [[[13.51703995,52.79053079], [14.25841842,52.79748028], [15,52.79979725], [15.74158158,52.79748028], [16.48296005,52.79053079], [17.22393267,52.77895313], [17.96429738,52.76275448], [18,52.76185622]], 1, '#909090'],
        [[[13.51397533,52.8803974], [14.25688539,52.88736942], [15,52.88969392], [15.74311461,52.88736942], [16.48602467,52.8803974], [17.22852598,52.8687822], [17.97041505,52.85253106], [18,52.85178662]], 1, '#909090'],
        [[[13.51089435,52.97026252], [14.25534417,52.97725717], [15,52.97958921], [15.74465583,52.97725717], [16.48910565,52.97026252], [17.23314379,52.95860962], [17.97656532,52.94230579], [18,52.94171603]], 1, '#909090'],
        [[[13.5077969,53.06012614], [14.2537947,53.06714352], [15,53.06948314], [15.7462053,53.06714352], [16.4922031,53.06012614], [17.23778627,53.0484354], [17.98274841,53.03207865], [18,53.03164443]], 1, '#909090'],
        [[[13.50468287,53.14998827], [14.25223694,53.15702848], [15,53.15937571], [15.74776306,53.15702848], [16.49531713,53.14998827], [17.24245359,53.13825953], [17.98896457,53.12184963], [18,53.12157184]], 1, '#909090'],
        [[[13.50155213,53.23984891], [14.25067082,53.24691203], [15,53.2492669], [15.74932918,53.24691203], [16.49844787,53.23984891], [17.24714594,53.228082], [17.99521402,53.21161875], [18,53.21149826]], 1, '#000000'],
        [[[13.49840456,53.32970806], [14.24909627,53.33679419], [15,53.33915674], [15.75090373,53.33679419], [16.50159544,53.32970806], [17.25186349,53.31790283], [18.001497,53.301386], [18,53.30142369]], 1, '#909090'],
        [[[13.49524004,53.41956571], [14.24751324,53.42667496], [15,53.42904521], [15.75248676,53.42667496], [16.50475996,53.41956571], [17.25660643,53.407722], [18,53.39134812]], 1, '#909090'],
        [[[13.49205845,53.50942188], [14.24592167,53.51655433], [15,53.51893232], [15.75407833,53.51655433], [16.50794155,53.50942188], [17.26137494,53.49753952], [18,53.48127156]], 1, '#909090'],
        [[[13.48885967,53.59927655], [14.2443215,53.60643231], [15,53.60881807], [15.7556785,53.60643231], [16.51114033,53.59927655], [17.2661692,53.58735539], [18,53.57119401]], 1, '#909090'],
        [[[13.48564356,53.68912974], [14.24271265,53.6963089], [15,53.69870247], [15.75728735,53.6963089], [16.51435644,53.68912974], [17.2709894,53.6771696], [18,53.66111547]], 1, '#909090'],
        [[[13.48241001,53.77898143], [14.24109508,53.7861841], [15,53.7885855], [15.75890492,53.7861841], [16.51758999,53.77898143], [17.27583574,53.76698216], [18,53.75103595]], 1, '#909090'],
        [[[13.47915888,53.86883163], [14.2394687,53.8760579], [15,53.87846718], [15.7605313,53.8760579], [16.52084112,53.86883163], [17.2807084,53.85679306], [18,53.84095543]], 1, '#909090'],
        [[[13.47589005,53.95868035], [14.23783347,53.96593033], [15,53.96834751], [15.76216653,53.96593033], [16.52410995,53.95868035], [17.28560758,53.9466023], [18,53.93087393]], 1, '#909090'],
        [[[13.47260338,54.04852758], [14.23618931,54.05580136], [15,54.05822648], [15.76381069,54.05580136], [16.52739662,54.04852758], [17.29053346,54.03640989], [18,54.02079144]], 1, '#909090'],
        [[[13.46929874,54.13837332], [14.23453616,54.14567101], [15,54.1481041], [15.76546384,54.14567101], [16.53070126,54.13837332], [17.29548626,54.12621581], [18,54.11070797]], 1, '#000000'],
        [[[15,54.23798037], [15.76712605,54.23553927], [16.53402399,54.22821757], [17.30046616,54.21602008], [18,54.20062352]], 1, '#909090'],
        [[[15,54.3278553], [15.76879738,54.32540615], [16.53736496,54.31806033], [17.30547337,54.30582268], [18,54.29053808]], 1, '#909090'],
        [[[15,54.41772887], [15.77047791,54.41527165], [16.5407243,54.40790161], [17.3105081,54.39562362], [18,54.38045166]], 1, '#909090'],
        [[[15,54.5076011], [15.7721677,54.50513577], [16.54410214,54.4977414], [17.31557054,54.4854229], [18,54.47036426]], 1, '#909090'],
        [[[15,54.59747199], [15.77386682,54.5949985], [16.54749863,54.5875797], [17.3206609,54.57522052], [18,54.56027589]], 1, '#909090'],
        [[[15,54.68734153], [15.77557534,54.68485986], [16.55091391,54.67741651], [17.3257794,54.66501647], [18,54.65018653]], 1, '#909090'],
        [[[15,54.77720973], [15.77729333,54.77471984], [16.55434811,54.76725184], [17.33092624,54.75481076], [18,54.74009619]], 1, '#909090'],
        [[[15,54.86707659], [15.77902087,54.86457845], [16.55780138,54.85708569], [17.33610164,54.84460338], [18,54.83000488]], 1, '#909090'],
        [[[15,54.95694212], [15.78075802,54.95443568], [16.56127387,54.94691805], [17.34130581,54.93439433], [18,54.9199126]], 1, '#909090'],
        [[[15,55.0468063], [15.78250487,55.04429153], [16.56476571,55.03674893], [17.34653897,55.02418361], [18,55.00981934]], 1, '#000000'],

    // strefa 34, linie poziome
        [[[18.2806415,48.72091487], [18.95995631,48.73495208], [19.63972075,48.74498411], [20.3197853,48.75100551], [21,48.753013], [21.6802147,48.75100551], [22.36027925,48.74498411], [23.04004369,48.73495208], [23.7193585,48.72091487]], 1, '#000000'],
        [[[18.27578188,48.81077309], [18.95630826,48.82485451], [19.63728712,48.83491814], [20.31856801,48.84095851], [21,48.84297234], [21.68143199,48.84095851], [22.36271288,48.83491814], [23.04369174,48.82485451], [23.72421812,48.81077309]], 1, '#909090'],
        [[[18.27089817,48.90062954], [18.9526421,48.91475532], [19.63484141,48.92485067], [20.31734467,48.93091008], [21,48.93293026], [21.68265533,48.93091008], [22.36515859,48.92485067], [23.0473579,48.91475532], [23.72910183,48.90062954]], 1, '#909090'],
        [[[18.2659902,48.99048422], [18.94895773,49.00465452], [19.63238354,49.0147817], [20.31611525,49.02086022], [21,49.02288677], [21.68388475,49.02086022], [22.36761646,49.0147817], [23.05104227,49.00465452], [23.7340098,48.99048422]], 1, '#909090'],
        [[[18.26105783,49.08033713], [18.94525502,49.0945521], [19.62991344,49.10471122], [20.3148797,49.11080893], [21,49.11284187], [21.6851203,49.11080893], [22.37008656,49.10471122], [23.05474498,49.0945521], [23.73894217,49.08033713]], 1, '#909090'],
        [[[18.2561009,49.17018826], [18.94153386,49.18444806], [19.62743101,49.19463925], [20.31363799,49.20075621], [21,49.20279557], [21.68636201,49.20075621], [22.37256899,49.19463925], [23.05846614,49.18444806], [23.7438991,49.17018826]], 1, '#909090'],
        [[[18.25111924,49.26003761], [18.93779413,49.27434241], [19.6249362,49.28456577], [20.31239009,49.29070205], [21,49.29274785], [21.68760991,49.29070205], [22.3750638,49.28456577], [23.06220587,49.27434241], [23.74888076,49.26003761]], 1, '#909090'],
        [[[18.24611272,49.34988518], [18.93403571,49.36423514], [19.6224289,49.3744908], [20.31113594,49.38064646], [21,49.38269873], [21.68886406,49.38064646], [22.3775711,49.3744908], [23.06596429,49.36423514], [23.75388728,49.34988518]], 1, '#909090'],
        [[[18.24108115,49.43973097], [18.93025848,49.45412626], [19.61990906,49.46441432], [20.3098755,49.47058944], [21,49.4726482], [21.6901245,49.47058944], [22.38009094,49.46441432], [23.06974152,49.45412626], [23.75891885,49.43973097]], 1, '#909090'],
        [[[18.23602438,49.52957497], [18.92646232,49.54401575], [19.61737657,49.55433634], [20.30860875,49.56053099], [21,49.56259626], [21.69139125,49.56053099], [22.38262343,49.55433634], [23.07353768,49.54401575], [23.76397562,49.52957497]], 1, '#909090'],
        [[[18,49.61365805], [18.23094225,49.61941719], [18.92264711,49.63390363], [19.61483137,49.64425686], [20.30733563,49.65047112], [21,49.65254292], [21.69266437,49.65047112], [22.38516863,49.64425686], [23.07735289,49.63390363], [23.76905775,49.61941719], [24,49.61365805]], 1, '#000000'],
        [[[18,49.70362382], [18.22583459,49.70925762], [18.91881271,49.72378988], [19.61227337,49.73417588], [20.3060561,49.74040981], [21,49.74248818], [21.6939439,49.74040981], [22.38772663,49.73417588], [23.08118729,49.72378988], [23.77416541,49.70925762], [24,49.70362382]], 1, '#909090'],
        [[[18,49.79358857], [18.22070124,49.79909626], [18.91495901,49.81367451], [19.60970248,49.8240934], [20.30477013,49.83034708], [21,49.83243203], [21.69522987,49.83034708], [22.39029752,49.8240934], [23.08504099,49.81367451], [23.77929876,49.79909626], [24,49.79358857]], 1, '#909090'],
        [[[18,49.8835523], [18.21554202,49.88893311], [18.91108589,49.90355753], [19.60711862,49.91400942], [20.30347767,49.92028292], [21,49.92237447], [21.69652233,49.92028292], [22.39288138,49.91400942], [23.08891411,49.90355753], [23.78445798,49.88893311], [24,49.8835523]], 1, '#909090'],
        [[[18,49.973515], [18.21035677,49.97876817], [18.9071932,49.99343891], [19.60452171,50.00392394], [20.30217868,50.01021733], [21,50.01231552], [21.69782132,50.01021733], [22.39547829,50.00392394], [23.0928068,49.99343891], [23.78964323,49.97876817], [24,49.973515]], 1, '#909090'],
        [[[18,50.06347669], [18.20514532,50.06860143], [18.90328083,50.08331868], [19.60191165,50.09383696], [20.30087311,50.10015031], [21,50.10225516], [21.69912689,50.10015031], [22.39808835,50.09383696], [23.09671917,50.08331868], [23.79485468,50.06860143], [24,50.06347669]], 1, '#909090'],
        [[[18,50.15343736], [18.1999075,50.1584329], [18.89934864,50.17319682], [19.59928838,50.18374848], [20.29956092,50.19008187], [21,50.1921934], [21.70043908,50.19008187], [22.40071162,50.18374848], [23.10065136,50.17319682], [23.8000925,50.1584329], [24,50.15343736]], 1, '#909090'],
        [[[18,50.24339701], [18.19464312,50.24826256], [18.89539651,50.26307334], [19.59665178,50.2736585], [20.29824207,50.28001201], [21,50.28213025], [21.70175793,50.28001201], [22.40334822,50.2736585], [23.10460349,50.26307334], [23.80535688,50.24826256], [24,50.24339701]], 1, '#909090'],
        [[[18,50.33335564], [18.18935203,50.33809043], [18.89142429,50.35294823], [19.59400179,50.36356701], [20.29691652,50.36994072], [21,50.37206569], [21.70308348,50.36994072], [22.40599821,50.36356701], [23.10857571,50.35294823], [23.81064797,50.33809043], [24,50.33335564]], 1, '#909090'],
        [[[18,50.42331326], [18.18403403,50.42791649], [18.88743187,50.44282149], [19.5913383,50.45347403], [20.29558421,50.459868], [21,50.46199973], [21.70441579,50.459868], [22.4086617,50.45347403], [23.11256813,50.44282149], [23.81596597,50.42791649], [24,50.42331326]], 1, '#909090'],
        [[[18,50.51326985], [18.17868895,50.51774074], [18.8834191,50.53269313], [19.58866123,50.54337955], [20.29424511,50.54979387], [21,50.55193238], [21.70575489,50.54979387], [22.41133877,50.54337955], [23.1165809,50.53269313], [23.82131105,50.51774074], [24,50.51326985]], 1, '#000000'],
        [[[18,50.60322543], [18.17331661,50.60756319], [18.87938585,50.62256314], [19.58597049,50.63328357], [20.29289917,50.63971831], [21,50.64186363], [21.70710083,50.63971831], [22.41402951,50.63328357], [23.12061415,50.62256314], [23.82668339,50.60756319], [24,50.60322543]], 1, '#909090'],
        [[[18,50.69318], [18.16791683,50.69738383], [18.87533198,50.71243152], [19.58326598,50.7231861], [20.29154634,50.72964133], [21,50.73179349], [21.70845366,50.72964133], [22.41673402,50.7231861], [23.12466802,50.71243152], [23.83208317,50.69738383], [24,50.69318]], 1, '#909090'],
        [[[18,50.78313355], [18.16248942,50.78720266], [18.87125735,50.80229828], [19.58054762,50.81308712], [20.29018658,50.81956293], [21,50.82172195], [21.70981342,50.81956293], [22.41945238,50.81308712], [23.12874265,50.80229828], [23.83751058,50.78720266], [24,50.78313355]], 1, '#909090'],
        [[[18,50.87308608], [18.1570342,50.87701968], [18.86716183,50.8921634], [19.57781532,50.90298664], [20.28881983,50.90948311], [21,50.91164902], [21.71118017,50.90948311], [22.42218468,50.90298664], [23.13283817,50.8921634], [23.8429658,50.87701968], [24,50.87308608]], 1, '#909090'],
        [[[18,50.9630376], [18.15155098,50.96683488], [18.86304527,50.98202689], [19.57506896,50.99288466], [20.28744606,50.99940187], [21,51.00157469], [21.71255394,50.99940187], [22.42493104,50.99288466], [23.13695473,50.98202689], [23.84844902,50.96683488], [24,50.9630376]], 1, '#909090'],
        [[[18,51.05298811], [18.14603957,51.05664826], [18.85890753,51.07188875], [19.57230847,51.08278119], [20.28606522,51.08931921], [21,51.09149897], [21.71393478,51.08931921], [22.42769153,51.08278119], [23.14109247,51.07188875], [23.85396043,51.05664826], [24,51.05298811]], 1, '#909090'],
        [[[18,51.14293761], [18.14049979,51.14645983], [18.85474846,51.16174898], [19.56953375,51.17267622], [20.28467725,51.17923513], [21,51.18142187], [21.71532275,51.17923513], [22.43046625,51.17267622], [23.14525154,51.16174898], [23.85950021,51.14645983], [24,51.14293761]], 1, '#909090'],
        [[[18,51.23288609], [18.13493143,51.23626957], [18.85056793,51.25160757], [19.5667447,51.26256975], [20.28328211,51.26914964], [21,51.27134337], [21.71671789,51.26914964], [22.4332553,51.26256975], [23.14943207,51.25160757], [23.86506857,51.23626957], [24,51.23288609]], 1, '#909090'],
        [[[18,51.32283356], [18.12933431,51.32607749], [18.84636579,51.34146454], [19.56394121,51.35246178], [20.28187976,51.35906273], [21,51.36126348], [21.71812024,51.35906273], [22.43605879,51.35246178], [23.15363421,51.34146454], [23.87066569,51.32607749], [24,51.32283356]], 1, '#909090'],
        [[[18,51.41278003], [18.12370823,51.41588359], [18.84214188,51.43131986], [19.56112321,51.44235232], [20.28047013,51.4489744], [21,51.4511822], [21.71952987,51.4489744], [22.43887679,51.44235232], [23.15785812,51.43131986], [23.87629177,51.41588359], [24,51.41278003]], 1, '#000000'],
        [[[18,51.50272548], [18.11805299,51.50568786], [18.83789607,51.52117356], [19.55829057,51.53224135], [20.27905318,51.53888467], [21,51.54109954], [21.72094682,51.53888467], [22.44170943,51.53224135], [23.16210393,51.52117356], [23.88194701,51.50568786], [24,51.50272548]], 1, '#909090'],
        [[[18,51.59266992], [18.11236839,51.59549029], [18.83362819,51.61102561], [19.55544321,51.62212889], [20.27762887,51.62879351], [21,51.63101549], [21.72237113,51.62879351], [22.44455679,51.62212889], [23.16637181,51.61102561], [23.88763161,51.59549029], [24,51.59266992]], 1, '#909090'],
        [[[18,51.68261336], [18.10665423,51.6852909], [18.82933811,51.70087603], [19.55258102,51.71201494], [20.27619713,51.71870095], [21,51.72093006], [21.72380287,51.71870095], [22.44741898,51.71201494], [23.17066189,51.70087603], [23.89334577,51.6852909], [24,51.68261336]], 1, '#909090'],
        [[[18,51.77255579], [18.10091031,51.77508967], [18.82502565,51.79072481], [19.5497039,51.80189948], [20.27475793,51.80860697], [21,51.81084325], [21.72524207,51.80860697], [22.4502961,51.80189948], [23.17497435,51.79072481], [23.89908969,51.77508967], [24,51.77255579]], 1, '#909090'],
        [[[18,51.86249721], [18.09513642,51.86488661], [18.82069068,51.88057196], [19.54681175,51.89178253], [20.2733112,51.89851158], [21,51.90075505], [21.7266888,51.89851158], [22.45318825,51.89178253], [23.17930932,51.88057196], [23.90486358,51.86488661], [24,51.86249721]], 1, '#909090'],
        [[[18,51.95243762], [18.08933235,51.9546817], [18.81633304,51.97041746], [19.54390446,51.98166409], [20.2718569,51.98841478], [21,51.99066547], [21.7281431,51.98841478], [22.45609554,51.98166409], [23.18366696,51.97041746], [23.91066765,51.9546817], [24,51.95243762]], 1, '#909090'],
        [[[18,52.04237703], [18.0834979,52.04447496], [18.81195256,52.06026133], [19.54098192,52.07154414], [20.27039496,52.07831657], [21,52.0805745], [21.72960504,52.07831657], [22.45901808,52.07154414], [23.18804744,52.06026133], [23.9165021,52.04447496], [24,52.04237703]], 1, '#909090'],
        [[[18,52.13231544], [18.07763285,52.13426637], [18.80754909,52.15010355], [19.53804404,52.16142271], [20.26892535,52.16821695], [21,52.17048216], [21.73107465,52.16821695], [22.46195596,52.16142271], [23.19245091,52.15010355], [23.92236715,52.13426637], [24,52.13231544]], 1, '#909090'],
        [[[18,52.22225284], [18.07173699,52.22405594], [18.80312246,52.23994414], [19.5350907,52.25129977], [20.26744801,52.25811593], [21,52.26038844], [21.73255199,52.25811593], [22.4649093,52.25129977], [23.19687754,52.23994414], [23.92826301,52.22405594], [24,52.22225284]], 1, '#909090'],
        [[[18,52.31218924], [18.06581011,52.31384366], [18.79867252,52.32978308], [19.5321218,52.34117535], [20.26596287,52.3480135], [21,52.35029335], [21.73403713,52.3480135], [22.4678782,52.34117535], [23.20132748,52.32978308], [23.93418989,52.31384366], [24,52.31218924]], 1, '#000000'],
        [[[18,52.40212464], [18.05985198,52.40362953], [18.79419911,52.41962038], [19.52913722,52.43104942], [20.26446989,52.43790966], [21,52.44019688], [21.73553011,52.43790966], [22.47086278,52.43104942], [23.20580089,52.41962038], [23.94014802,52.40362953], [24,52.40212464]], 1, '#909090'],
        [[[18,52.49205903], [18.05386239,52.49341355], [18.78970205,52.50945603], [19.52613686,52.52092201], [20.26296901,52.52780442], [21,52.53009903], [21.73703099,52.52780442], [22.47386314,52.52092201], [23.21029795,52.50945603], [23.94613761,52.49341355], [24,52.49205903]], 1, '#909090'],
        [[[18,52.58199243], [18.04784112,52.58319572], [18.78518118,52.59929004], [19.5231206,52.6107931], [20.26146018,52.61769777], [21,52.61999981], [21.73853982,52.61769777], [22.4768794,52.6107931], [23.21481882,52.59929004], [23.95215888,52.58319572], [24,52.58199243]], 1, '#909090'],
        [[[18,52.67192482], [18.04178794,52.67297603], [18.78063633,52.68912241], [19.52008834,52.70066269], [20.25994334,52.70758973], [21,52.70989922], [21.74005666,52.70758973], [22.47991166,52.70066269], [23.21936367,52.68912241], [23.95821206,52.67297603], [24,52.67192482]], 1, '#909090'],
        [[[18,52.76185622], [18.03570262,52.76275448], [18.77606733,52.77895313], [19.51703995,52.79053079], [20.25841842,52.79748028], [21,52.79979725], [21.74158158,52.79748028], [22.48296005,52.79053079], [23.22393267,52.77895313], [23.96429738,52.76275448], [24,52.76185622]], 1, '#909090'],
        [[[18,52.85178662], [18.02958495,52.85253106], [18.77147402,52.8687822], [19.51397533,52.8803974], [20.25688539,52.88736942], [21,52.88969392], [21.74311461,52.88736942], [22.48602467,52.8803974], [23.22852598,52.8687822], [23.97041505,52.85253106], [24,52.85178662]], 1, '#909090'],
        [[[18,52.94171603], [18.02343468,52.94230579], [18.76685621,52.95860962], [19.51089435,52.97026252], [20.25534417,52.97725717], [21,52.97958921], [21.74465583,52.97725717], [22.48910565,52.97026252], [23.23314379,52.95860962], [23.97656532,52.94230579], [24,52.94171603]], 1, '#909090'],
        [[[18,53.03164443], [18.01725159,53.03207865], [18.76221373,53.0484354], [19.5077969,53.06012614], [20.2537947,53.06714352], [21,53.06948314], [21.7462053,53.06714352], [22.4922031,53.06012614], [23.23778627,53.0484354], [23.98274841,53.03207865], [24,53.03164443]], 1, '#909090'],
        [[[18,53.12157184], [18.01103543,53.12184963], [18.75754641,53.13825953], [19.50468287,53.14998827], [20.25223694,53.15702848], [21,53.15937571], [21.74776306,53.15702848], [22.49531713,53.14998827], [23.24245359,53.13825953], [23.98896457,53.12184963], [24,53.12157184]], 1, '#909090'],
        [[[18,53.21149826], [18.00478598,53.21161875], [18.75285406,53.228082], [19.50155213,53.23984891], [20.25067082,53.24691203], [21,53.2492669], [21.74932918,53.24691203], [22.49844787,53.23984891], [23.24714594,53.228082], [23.99521402,53.21161875], [24,53.21149826]], 1, '#000000'],
        [[[18,53.30142369], [17.998503,53.301386], [18.74813651,53.31790283], [19.49840456,53.32970806], [20.24909627,53.33679419], [21,53.33915674], [21.75090373,53.33679419], [22.50159544,53.32970806], [23.25186349,53.31790283], [24.001497,53.301386], [24,53.30142369]], 1, '#909090'],
        [[[18,53.39134812], [18.74339357,53.407722], [19.49524004,53.41956571], [20.24751324,53.42667496], [21,53.42904521], [21.75248676,53.42667496], [22.50475996,53.41956571], [23.25660643,53.407722], [24,53.39134812]], 1, '#909090'],
        [[[18,53.48127156], [18.73862506,53.49753952], [19.49205845,53.50942188], [20.24592167,53.51655433], [21,53.51893232], [21.75407833,53.51655433], [22.50794155,53.50942188], [23.26137494,53.49753952], [24,53.48127156]], 1, '#909090'],
        [[[18,53.57119401], [18.7338308,53.58735539], [19.48885967,53.59927655], [20.2443215,53.60643231], [21,53.60881807], [21.7556785,53.60643231], [22.51114033,53.59927655], [23.2661692,53.58735539], [24,53.57119401]], 1, '#909090'],
        [[[18,53.66111547], [18.7290106,53.6771696], [19.48564356,53.68912974], [20.24271265,53.6963089], [21,53.69870247], [21.75728735,53.6963089], [22.51435644,53.68912974], [23.2709894,53.6771696], [24,53.66111547]], 1, '#909090'],
        [[[18,53.75103595], [18.72416426,53.76698216], [19.48241001,53.77898143], [20.24109508,53.7861841], [21,53.7885855], [21.75890492,53.7861841], [22.51758999,53.77898143], [23.27583574,53.76698216], [24,53.75103595]], 1, '#909090'],
        [[[18,53.84095543], [18.7192916,53.85679306], [19.47915888,53.86883163], [20.2394687,53.8760579], [21,53.87846718], [21.7605313,53.8760579], [22.52084112,53.86883163], [23.2807084,53.85679306], [24,53.84095543]], 1, '#909090'],
        [[[18,53.93087393], [18.71439242,53.9466023], [19.47589005,53.95868035], [20.23783347,53.96593033], [21,53.96834751], [21.76216653,53.96593033], [22.52410995,53.95868035], [23.28560758,53.9466023], [24,53.93087393]], 1, '#909090'],
        [[[18,54.02079144], [18.70946654,54.03640989], [19.47260338,54.04852758], [20.23618931,54.05580136], [21,54.05822648], [21.76381069,54.05580136], [22.52739662,54.04852758], [23.29053346,54.03640989], [24,54.02079144]], 1, '#909090'],
        [[[18,54.11070797], [18.70451374,54.12621581], [19.46929874,54.13837332], [20.23453616,54.14567101], [21,54.1481041], [21.76546384,54.14567101], [22.53070126,54.13837332], [23.29548626,54.12621581], [24,54.11070797]], 1, '#000000'],
        [[[18,54.20062352], [18.69953384,54.21602008], [19.46597601,54.22821757], [20.23287395,54.23553927], [21,54.23798037], [21.76712605,54.23553927], [22.53402399,54.22821757], [23.30046616,54.21602008], [24,54.20062352]], 1, '#909090'],
        [[[18,54.29053808], [18.69452663,54.30582268], [19.46263504,54.31806033], [20.23120262,54.32540615], [21,54.3278553], [21.76879738,54.32540615], [22.53736496,54.31806033], [23.30547337,54.30582268], [24,54.29053808]], 1, '#909090'],
        [[[18,54.38045166], [18.6894919,54.39562362], [19.4592757,54.40790161], [20.22952209,54.41527165], [21,54.41772887], [21.77047791,54.41527165], [22.5407243,54.40790161], [23.3105081,54.39562362], [24,54.38045166]], 1, '#909090'],
        [[[18,54.47036426], [18.68442946,54.4854229], [19.45589786,54.4977414], [20.2278323,54.50513577], [21,54.5076011], [21.7721677,54.50513577], [22.54410214,54.4977414], [23.31557054,54.4854229], [24,54.47036426]], 1, '#909090'],
        [[[18,54.56027589], [18.6793391,54.57522052], [19.45250137,54.5875797], [20.22613318,54.5949985], [21,54.59747199], [21.77386682,54.5949985], [22.54749863,54.5875797], [23.3206609,54.57522052], [24,54.56027589]], 1, '#909090'],
        [[[18,54.65018653], [18.6742206,54.66501647], [19.44908609,54.67741651], [20.22442466,54.68485986], [21,54.68734153], [21.77557534,54.68485986], [22.55091391,54.67741651], [23.3257794,54.66501647], [24,54.65018653]], 1, '#909090'],
        [[[18,54.74009619], [18.66907376,54.75481076], [19.44565189,54.76725184], [20.22270667,54.77471984], [21,54.77720973], [21.77729333,54.77471984], [22.55434811,54.76725184], [23.33092624,54.75481076], [24,54.74009619]], 1, '#909090'],
        [[[18,54.83000488], [18.66389836,54.84460338], [19.44219862,54.85708569], [20.22097913,54.86457845], [21,54.86707659], [21.77902087,54.86457845], [22.55780138,54.85708569], [23.33610164,54.84460338], [24,54.83000488]], 1, '#909090'],
        [[[18,54.9199126], [18.65869419,54.93439433], [19.43872613,54.94691805], [20.21924198,54.95443568], [21,54.95694212], [21.78075802,54.95443568], [22.56127387,54.94691805], [23.34130581,54.93439433], [24,54.9199126]], 1, '#909090'],
        [[[18,55.00981934], [18.65346103,55.02418361], [19.43523429,55.03674893], [20.21749513,55.04429153], [21,55.0468063], [21.78250487,55.04429153], [22.56476571,55.03674893], [23.34653897,55.02418361], [24,55.00981934]], 1, '#000000'],
    // strefa 35, linie poziome
        [[[24,49.61365805], [24.23094225,49.61941719]], 1, '#000000'],
        [[[24,49.70362382], [24.22583459,49.70925762]], 1, '#909090'],
        [[[24,49.79358857], [24.22070124,49.79909626]], 1, '#909090'],
        [[[24,49.8835523], [24.21554202,49.88893311]], 1, '#909090'],
        [[[24,49.973515], [24.21035677,49.97876817]], 1, '#909090'],
        [[[24,50.06347669], [24.20514532,50.06860143]], 1, '#909090'],
        [[[24,50.15343736], [24.1999075,50.1584329]], 1, '#909090'],
        [[[24,50.24339701], [24.19464312,50.24826256]], 1, '#909090'],
        [[[24,50.33335564], [24.18935203,50.33809043]], 1, '#909090'],
        [[[24,50.42331326], [24.18403403,50.42791649]], 1, '#909090'],
        [[[24,50.51326985], [24.17868895,50.51774074]], 1, '#000000'],
        [[[24,50.60322543], [24.17331661,50.60756319]], 1, '#909090'],
        [[[24,50.69318], [24.16791683,50.69738383]], 1, '#909090'],
        [[[24,50.78313355], [24.16248942,50.78720266]], 1, '#909090'],
        [[[24,50.87308608], [24.1570342,50.87701968]], 1, '#909090'],
        [[[24,50.9630376], [24.15155098,50.96683488]], 1, '#909090'],
        [[[24,51.05298811], [24.14603957,51.05664826]], 1, '#909090'],
        [[[24,51.14293761], [24.14049979,51.14645983]], 1, '#909090'],
        [[[24,51.23288609], [24.13493143,51.23626957]], 1, '#909090'],
        [[[24,51.32283356], [24.12933431,51.32607749]], 1, '#909090'],
        [[[24,51.41278003], [24.12370823,51.41588359]], 1, '#000000'],
    // strefa 33, linie pionowe

        [[[13.58866123, 50.54337955], [13.58597049, 50.63328357], [13.58326598, 50.7231861], [13.58054762, 50.81308712], [13.57781532, 50.90298664], [13.57506896, 50.99288466], [13.57230847, 51.08278119], [13.56953375, 51.17267622], [13.5667447, 51.26256975], [13.56394121, 51.35246178], [13.56112321, 51.44235232], [13.55829057, 51.53224135], [13.55544321, 51.62212889], [13.55258102, 51.71201494], [13.5497039, 51.80189948], [13.54681175, 51.89178253], [13.54390446, 51.98166409], [13.54098192, 52.07154414], [13.53804404, 52.16142271], [13.5350907, 52.25129977], [13.5321218, 52.34117535], [13.52913722, 52.43104942], [13.52613686, 52.52092201], [13.5231206, 52.6107931], [13.52008834, 52.70066269], [13.51703995, 52.79053079], [13.51397533, 52.8803974],
          [13.51089435, 52.97026252], [13.5077969, 53.06012614], [13.50468287, 53.14998827], [13.50155213, 53.23984891], [13.49840456, 53.32970806], [13.49524004, 53.41956571], [13.49205845, 53.50942188], [13.48885967, 53.59927655], [13.48564356, 53.68912974], [13.48241001, 53.77898143], [13.47915888, 53.86883163], [13.47589005, 53.95868035], [13.47260338, 54.04852758], [13.46929874, 54.13837332]], 1, '#000000'],
        [[[13.72975612, 50.54500434], [13.7273342, 50.63491353], [13.72489988, 50.72482124], [13.72245309, 50.81472748], [13.71999374, 50.90463223], [13.71752176, 50.99453551], [13.71503704, 51.08443731], [13.71253951, 51.17433763], [13.71002909, 51.26423647], [13.70750567, 51.35413384], [13.70496918, 51.44402973], [13.70241952, 51.53392414], [13.69985661, 51.62381707], [13.69728035, 51.71370854], [13.69469064, 51.80359852], [13.69208741, 51.89348703], [13.68947055, 51.98337407], [13.68683997, 52.07325963], [13.68419557, 52.16314372], [13.68153725, 52.25302634], [13.67886493, 52.34290748], [13.67617849, 52.43278715], [13.67347785, 52.52266535], [13.6707629, 52.61254208], [13.66803353, 52.70241734], [13.66528966, 52.79229112], [13.66253116, 52.88216344],
          [13.65975795, 52.97203429], [13.65696991, 53.06190367], [13.65416694, 53.15177158], [13.65134893, 53.24163802], [13.64851577, 53.331503], [13.64566736, 53.42136651], [13.64280357, 53.51122855], [13.63992431, 53.60108913], [13.63702945, 53.69094824], [13.63411889, 53.78080589], [13.63119251, 53.87066207], [13.62825018, 53.96051679], [13.62529181, 54.05037004], [13.62231726, 54.14022184]], 1, '#909090'],
        [[[13.87086332, 50.54645819], [13.86871029, 50.63637201], [13.86654625, 50.72628437], [13.86437111, 50.81619527], [13.86218481, 50.90610471], [13.85998727, 50.99601268], [13.85777842, 51.0859192], [13.85555818, 51.17582426], [13.85332647, 51.26572785], [13.85108321, 51.35562999], [13.84882832, 51.44553067], [13.84656173, 51.5354299], [13.84428336, 51.62532766], [13.84199311, 51.71522397], [13.83969092, 51.80511882], [13.8373767, 51.89501222], [13.83505036, 51.98490416], [13.83271182, 52.07479465], [13.830361, 52.16468369], [13.82799781, 52.25457127], [13.82562216, 52.3444574], [13.82323397, 52.43434207], [13.82083315, 52.5242253], [13.8184196, 52.61410707], [13.81599324, 52.7039874], [13.81355398, 52.79386627], [13.81110172, 52.8837437],
          [13.80863638, 52.97361968], [13.80615786, 53.06349421], [13.80366606, 53.15336729], [13.80116088, 53.24323893], [13.79864224, 53.33310912], [13.79611004, 53.42297787], [13.79356417, 53.51284517], [13.79100454, 53.60271103], [13.78843105, 53.69257544], [13.78584359, 53.78243842], [13.78324207, 53.87229995], [13.78062638, 53.96216004], [13.77799641, 54.0520187], [13.77535206, 54.14187591]], 1, '#909090'],
        [[[14.01198147, 50.54774109], [14.0100974, 50.63765899], [14.0082037, 50.72757545], [14.00630029, 50.81749046], [14.00438712, 50.90740403], [14.00246411, 50.99731616], [14.00053119, 51.08722683], [13.99858831, 51.17713607], [13.99663539, 51.26704386], [13.99467237, 51.35695021], [13.99269917, 51.44685512], [13.99071573, 51.53675859], [13.98872197, 51.62666062], [13.98671783, 51.7165612], [13.98470323, 51.80646035], [13.9826781, 51.89635806], [13.98064237, 51.98625434], [13.97859596, 52.07614917], [13.97653881, 52.16604257], [13.97447083, 52.25593453], [13.97239194, 52.34582506], [13.97030208, 52.43571416], [13.96820116, 52.52560182], [13.96608911, 52.61548804], [13.96396585, 52.70537284], [13.9618313, 52.79525621], [13.95968537, 52.88513814],
          [13.95752799, 52.97501864], [13.95535907, 53.06489772], [13.95317854, 53.15477537], [13.9509863, 53.24465159], [13.94878228, 53.33452638], [13.94656639, 53.42439975], [13.94433854, 53.5142717], [13.94209864, 53.60414222], [13.93984661, 53.69401131], [13.93758236, 53.78387899], [13.9353058, 53.87374524], [13.93301684, 53.96361008], [13.93071539, 54.05347349], [13.92840135, 54.14333549]], 1, '#909090'],
        [[[14.15310918, 50.54885299], [14.15149415, 50.63877443], [14.14987086, 50.72869444], [14.14823925, 50.81861302], [14.14659926, 50.90853017], [14.14495084, 50.99844589], [14.14329393, 51.08836018], [14.14162848, 51.17827304], [14.13995442, 51.26818447], [14.1382717, 51.35809447], [14.13658026, 51.44800304], [14.13488003, 51.53791019], [14.13317097, 51.62781591], [14.131453, 51.71772021], [14.12972607, 51.80762308], [14.12799011, 51.89752453], [14.12624506, 51.98742455], [14.12449086, 52.07732315], [14.12272744, 52.16722034], [14.12095474, 52.2571161], [14.1191727, 52.34701044], [14.11738125, 52.43690336], [14.11558032, 52.52679487], [14.11376984, 52.61668495], [14.11194975, 52.70657363], [14.11011999, 52.79646088], [14.10828047, 52.88634672],
          [14.10643113, 52.97623115], [14.10457191, 53.06611417], [14.10270272, 53.15599577], [14.10082351, 53.24587597], [14.09893419, 53.33575475], [14.09703469, 53.42563213], [14.09512494, 53.51550809], [14.09320487, 53.60538265], [14.09127439, 53.69525581], [14.08933344, 53.78512756], [14.08738194, 53.87499791], [14.0854198, 53.96486685], [14.08344696, 54.05473439], [14.08146332, 54.14460053]], 1, '#909090'],
        [[[14.29424511, 50.54979387], [14.29289917, 50.63971831], [14.29154634, 50.72964133], [14.29018658, 50.81956293], [14.28881983, 50.90948311], [14.28744606, 50.99940187], [14.28606522, 51.08931921], [14.28467725, 51.17923513], [14.28328211, 51.26914964], [14.28187976, 51.35906273], [14.28047013, 51.4489744], [14.27905318, 51.53888467], [14.27762887, 51.62879351], [14.27619713, 51.71870095], [14.27475793, 51.80860697], [14.2733112, 51.89851158], [14.2718569, 51.98841478], [14.27039496, 52.07831657], [14.26892535, 52.16821695], [14.26744801, 52.25811593], [14.26596287, 52.3480135], [14.26446989, 52.43790966], [14.26296901, 52.52780442], [14.26146018, 52.61769777], [14.25994334, 52.70758973], [14.25841842, 52.79748028], [14.25688539, 52.88736942],
          [14.25534417, 52.97725717], [14.2537947, 53.06714352], [14.25223694, 53.15702848], [14.25067082, 53.24691203], [14.24909627, 53.33679419], [14.24751324, 53.42667496], [14.24592167, 53.51655433], [14.2443215, 53.60643231], [14.24271265, 53.6963089], [14.24109508, 53.7861841], [14.2394687, 53.8760579], [14.23783347, 53.96593033], [14.23618931, 54.05580136], [14.23453616, 54.14567101]], 1, '#909090'],
        [[[14.43538788, 50.55056371], [14.43431107, 50.6404906], [14.43322875, 50.73041608], [14.43214088, 50.82034015], [14.43104743, 50.91026281], [14.42994836, 51.00018406], [14.42884363, 51.0901039], [14.42773319, 51.18002233], [14.42661703, 51.26993936], [14.42549508, 51.35985497], [14.42436732, 51.44976919], [14.4232337, 51.539682], [14.42209419, 51.6295934], [14.42094874, 51.7195034], [14.41979731, 51.809412], [14.41863987, 51.8993192], [14.41747636, 51.989225], [14.41630675, 52.0791294], [14.415131, 52.1690324], [14.41394906, 52.25893401], [14.41276088, 52.34883422], [14.41156643, 52.43873303], [14.41036566, 52.52863045], [14.40915853, 52.61852648], [14.40794499, 52.70842111], [14.40672499, 52.79831436], [14.40549849, 52.88820621],
          [14.40426544, 52.97809668], [14.4030258, 53.06798576], [14.40177952, 53.15787345], [14.40052655, 53.24775976], [14.39926684, 53.33764468], [14.39800034, 53.42752822], [14.39672701, 53.51741038], [14.39544679, 53.60729116], [14.39415964, 53.69717055], [14.3928655, 53.78704857], [14.39156433, 53.87692521], [14.39025606, 53.96680048], [14.38894066, 54.05667437], [14.38761806, 54.14654689]], 1, '#909090'],
        [[[14.57653612, 50.55116249], [14.57572848, 50.64109129], [14.57491671, 50.73101869], [14.57410078, 50.82094468], [14.57328065, 50.91086927], [14.57245632, 51.00079245], [14.57162773, 51.09071423], [14.57079488, 51.18063462], [14.56995771, 51.2705536], [14.56911622, 51.36047118], [14.56827037, 51.45038737], [14.56742012, 51.54030216], [14.56656545, 51.63021556], [14.56570632, 51.72012755], [14.56484272, 51.81003816], [14.5639746, 51.89994737], [14.56310193, 51.98985519], [14.56222469, 52.07976162], [14.56134283, 52.16966666], [14.56045634, 52.25957031], [14.55956517, 52.34947258], [14.55866929, 52.43937345], [14.55776868, 52.52927294], [14.55686329, 52.61917105], [14.55595309, 52.70906777], [14.55503805, 52.79896311], [14.55411814, 52.88885707],
          [14.55319331, 52.97874965], [14.55226354, 53.06864085], [14.55132878, 53.15853068], [14.55038901, 53.24841912], [14.54944419, 53.33830619], [14.54849427, 53.42819189], [14.54753923, 53.51807622], [14.54657903, 53.60795917], [14.54561362, 53.69784075], [14.54464297, 53.78772097], [14.54366704, 53.87759981], [14.5426858, 53.96747729], [14.5416992, 54.05735341], [14.5407072, 54.14722816]], 1, '#909090'],
        [[[14.71768847, 50.55159021], [14.71715002, 50.64152037], [14.71660883, 50.73144913], [14.71606486, 50.82137649], [14.71551809, 50.91130246], [14.71496852, 51.00122703], [14.71441611, 51.0911502], [14.71386086, 51.18107197], [14.71330274, 51.27099236], [14.71274172, 51.36091135], [14.7121778, 51.45082894], [14.71161095, 51.54074515], [14.71104116, 51.63065996], [14.71046839, 51.72057339], [14.70989264, 51.81048543], [14.70931387, 51.90039608], [14.70873208, 51.99030534], [14.70814723, 52.08021322], [14.70755931, 52.17011971], [14.7069683, 52.26002483], [14.70637416, 52.34992856], [14.7057769, 52.43983091], [14.70517647, 52.52973188], [14.70457285, 52.61963147], [14.70396604, 52.70952968], [14.70335599, 52.79942652], [14.7027427, 52.88932198],
          [14.70212613, 52.97921607], [14.70150626, 53.06910879], [14.70088307, 53.15900013], [14.70025653, 53.24889011], [14.69962663, 53.33877871], [14.69899333, 53.42866595], [14.69835662, 53.51855183], [14.69771646, 53.60843633], [14.69707283, 53.69831948], [14.69642571, 53.78820126], [14.69577507, 53.87808168], [14.69512089, 53.96796074], [14.69446313, 54.05783844], [14.69380178, 54.14771479]], 1, '#909090'],
        [[[14.85884355, 50.55184684], [14.85857432, 50.64177782], [14.85830372, 50.7317074], [14.85803173, 50.82163558], [14.85775834, 50.91156238], [14.85748355, 51.00148777], [14.85720734, 51.09141178], [14.85692971, 51.18133439], [14.85665065, 51.27125561], [14.85637013, 51.36117545], [14.85608817, 51.45109389], [14.85580474, 51.54101094], [14.85551984, 51.63092661], [14.85523345, 51.72084089], [14.85494557, 51.81075379], [14.85465618, 51.9006653], [14.85436528, 51.99057543], [14.85407285, 52.08048418], [14.85377888, 52.17039155], [14.85348337, 52.26029754], [14.8531863, 52.35020215], [14.85288766, 52.44010538], [14.85258744, 52.53000724], [14.85228563, 52.61990772], [14.85198221, 52.70980683], [14.85167718, 52.79970457], [14.85137053, 52.88960093],
          [14.85106224, 52.97949593], [14.8507523, 53.06938955], [14.8504407, 53.15928181], [14.85012743, 53.2491727], [14.84981247, 53.33906223], [14.84949581, 53.4289504], [14.84917745, 53.5188372], [14.84885736, 53.60872264], [14.84853554, 53.69860672], [14.84821198, 53.78848944], [14.84788665, 53.87837081], [14.84755955, 53.96825082], [14.84723067, 54.05812947], [14.84689998, 54.14800677]], 1, '#909090'],
        


        [[[15, 49.65254292], [15, 49.74248818], [15, 49.83243203], [15, 49.92237447], [15, 50.01231552], [15, 50.10225516], [15, 50.1921934], [15, 50.28213025], [15, 50.37206569], [15, 50.46199973], [15, 50.55193238], [15, 50.64186363], [15, 50.73179349], [15, 50.82172195], [15, 50.91164902], [15, 51.00157469], [15, 51.09149897], [15, 51.18142187], [15, 51.27134337], [15, 51.36126348], [15, 51.4511822], [15, 51.54109954], [15, 51.63101549], [15, 51.72093006], [15, 51.81084325], [15, 51.90075505], [15, 51.99066547],
          [15, 52.0805745], [15, 52.17048216], [15, 52.26038844], [15, 52.35029335], [15, 52.44019688], [15, 52.53009903], [15, 52.61999981], [15, 52.70989922], [15, 52.79979725], [15, 52.88969392], [15, 52.97958921], [15, 53.06948314], [15, 53.15937571], [15, 53.2492669], [15, 53.33915674], [15, 53.42904521], [15, 53.51893232], [15, 53.60881807], [15, 53.69870247], [15, 53.7885855], [15, 53.87846718], [15, 53.96834751], [15, 54.05822648], [15, 54.1481041], [15, 54.23798037], [15, 54.3278553], [15, 54.41772887],
          [15, 54.5076011], [15, 54.59747199], [15, 54.68734153], [15, 54.77720973], [15, 54.86707659], [15, 54.95694212], [15, 55.0468063]], 1, '#000000'],
        
        
        [[[15.138538, 49.65246005], [15.13879394, 49.74240504], [15.13905117, 49.83234862], [15.13930969, 49.92229081], [15.13956953, 50.01223159], [15.13983067, 50.10217096], [15.14009315, 50.19210894], [15.14035695, 50.28204551], [15.1406221, 50.37198069], [15.1408886, 50.46191446], [15.14115645, 50.55184684], [15.14142568, 50.64177782], [15.14169628, 50.7317074], [15.14196827, 50.82163558], [15.14224166, 50.91156238], [15.14251645, 51.00148777], [15.14279266, 51.09141178], [15.14307029, 51.18133439], [15.14334935, 51.27125561], [15.14362987, 51.36117545], [15.14391183, 51.45109389], [15.14419526, 51.54101094], [15.14448016, 51.63092661], [15.14476655, 51.72084089], [15.14505443, 51.81075379], [15.14534382, 51.9006653], [15.14563472, 51.99057543],
          [15.14592715, 52.08048418], [15.14622112, 52.17039155], [15.14651663, 52.26029754], [15.1468137, 52.35020215], [15.14711234, 52.44010538], [15.14741256, 52.53000724], [15.14771437, 52.61990772], [15.14801779, 52.70980683], [15.14832282, 52.79970457], [15.14862947, 52.88960093], [15.14893776, 52.97949593], [15.1492477, 53.06938955], [15.1495593, 53.15928181], [15.14987257, 53.2491727], [15.15018753, 53.33906223], [15.15050419, 53.4289504], [15.15082255, 53.5188372], [15.15114264, 53.60872264], [15.15146446, 53.69860672], [15.15178802, 53.78848944], [15.15211335, 53.87837081], [15.15244045, 53.96825082], [15.15276933, 54.05812947], [15.15310002, 54.14800677], [15.15343251, 54.23788272], [15.15376683, 54.32775733], [15.15410299, 54.41763058],
          [15.15444101, 54.50750248], [15.15478089, 54.59737304], [15.15512265, 54.68724226], [15.1554663, 54.77711013], [15.15581187, 54.86697666], [15.15615936, 54.95684185], [15.15650879, 55.04670571]], 1, '#909090'],
          
          
        [[[15.27707472, 49.65221142], [15.27758659, 49.74215562], [15.27810103, 49.83209842], [15.27861808, 49.92203981], [15.27913774, 50.01197979], [15.27966002, 50.10191837], [15.28018496, 50.19185554], [15.28071256, 50.28179131], [15.28124285, 50.37172568], [15.28177583, 50.46165864], [15.28231153, 50.55159021], [15.28284998, 50.64152037], [15.28339117, 50.73144913], [15.28393514, 50.82137649], [15.28448191, 50.91130246], [15.28503148, 51.00122703], [15.28558389, 51.0911502], [15.28613914, 51.18107197], [15.28669726, 51.27099236], [15.28725828, 51.36091135], [15.2878222, 51.45082894], [15.28838905, 51.54074515], [15.28895884, 51.63065996], [15.28953161, 51.72057339], [15.29010736, 51.81048543], [15.29068613, 51.90039608], [15.29126792, 51.99030534],
          [15.29185277, 52.08021322], [15.29244069, 52.17011971], [15.2930317, 52.26002483], [15.29362584, 52.34992856], [15.2942231, 52.43983091], [15.29482353, 52.52973188], [15.29542715, 52.61963147], [15.29603396, 52.70952968], [15.29664401, 52.79942652], [15.2972573, 52.88932198], [15.29787387, 52.97921607], [15.29849374, 53.06910879], [15.29911693, 53.15900013], [15.29974347, 53.24889011], [15.30037337, 53.33877871], [15.30100667, 53.42866595], [15.30164338, 53.51855183], [15.30228354, 53.60843633], [15.30292717, 53.69831948], [15.30357429, 53.78820126], [15.30422493, 53.87808168], [15.30487911, 53.96796074], [15.30553687, 54.05783844], [15.30619822, 54.14771479], [15.3068632, 54.23758978], [15.30753183, 54.32746342], [15.30820413, 54.4173357],
          [15.30888014, 54.50720663], [15.30955989, 54.59707621], [15.3102434, 54.68694444], [15.3109307, 54.77681133], [15.31162181, 54.86667687], [15.31231678, 54.95654107], [15.31301562, 55.04640392]], 1, '#909090'],
          
        [[[15.41560887, 49.65179705], [15.41637665, 49.74173994], [15.41714831, 49.83168142], [15.41792385, 49.92162149], [15.41870332, 50.01156015], [15.41948673, 50.10149739], [15.42027411, 50.19143323], [15.42106549, 50.28136766], [15.42186089, 50.37130068], [15.42266035, 50.46123229], [15.42346388, 50.55116249], [15.42427152, 50.64109129], [15.42508329, 50.73101869], [15.42589922, 50.82094468], [15.42671935, 50.91086927], [15.42754368, 51.00079245], [15.42837227, 51.09071423], [15.42920512, 51.18063462], [15.43004229, 51.2705536], [15.43088378, 51.36047118], [15.43172963, 51.45038737], [15.43257988, 51.54030216], [15.43343455, 51.63021556], [15.43429368, 51.72012755], [15.43515728, 51.81003816], [15.4360254, 51.89994737], [15.43689807, 51.98985519],
          [15.43777531, 52.07976162], [15.43865717, 52.16966666], [15.43954366, 52.25957031], [15.44043483, 52.34947258], [15.44133071, 52.43937345], [15.44223132, 52.52927294], [15.44313671, 52.61917105], [15.44404691, 52.70906777], [15.44496195, 52.79896311], [15.44588186, 52.88885707], [15.44680669, 52.97874965], [15.44773646, 53.06864085], [15.44867122, 53.15853068], [15.44961099, 53.24841912], [15.45055581, 53.33830619], [15.45150573, 53.42819189], [15.45246077, 53.51807622], [15.45342097, 53.60795917], [15.45438638, 53.69784075], [15.45535703, 53.78772097], [15.45633296, 53.87759981], [15.4573142, 53.96747729], [15.4583008, 54.05735341], [15.4592928, 54.14722816], [15.46029023, 54.23710155], [15.46129314, 54.32697357], [15.46230157, 54.41684424],
          [15.46331555, 54.50671355], [15.46433513, 54.5965815], [15.46536036, 54.6864481], [15.46639127, 54.77631334], [15.46742791, 54.86617723], [15.46847032, 54.95603977], [15.46951854, 55.04590095]], 1, '#909090'],
          
        [[[15.55413918, 49.65121694], [15.55516285, 49.741158], [15.55619168, 49.83109764], [15.5572257, 49.92103585], [15.55826495, 50.01097265], [15.55930946, 50.10090803], [15.56035926, 50.190842], [15.56141439, 50.28077455], [15.56247489, 50.37070568], [15.56354079, 50.4606354], [15.56461212, 50.55056371], [15.56568893, 50.6404906], [15.56677125, 50.73041608], [15.56785912, 50.82034015], [15.56895257, 50.91026281], [15.57005164, 51.00018406], [15.57115637, 51.0901039], [15.57226681, 51.18002233], [15.57338297, 51.26993936], [15.57450492, 51.35985497], [15.57563268, 51.44976919], [15.5767663, 51.539682], [15.57790581, 51.6295934], [15.57905126, 51.7195034], [15.58020269, 51.809412], [15.58136013, 51.8993192], [15.58252364, 51.989225],
          [15.58369325, 52.0791294], [15.584869, 52.1690324], [15.58605094, 52.25893401], [15.58723912, 52.34883422], [15.58843357, 52.43873303], [15.58963434, 52.52863045], [15.59084147, 52.61852648], [15.59205501, 52.70842111], [15.59327501, 52.79831436], [15.59450151, 52.88820621], [15.59573456, 52.97809668], [15.5969742, 53.06798576], [15.59822048, 53.15787345], [15.59947345, 53.24775976], [15.60073316, 53.33764468], [15.60199966, 53.42752822], [15.60327299, 53.51741038], [15.60455321, 53.60729116], [15.60584036, 53.69717055], [15.6071345, 53.78704857], [15.60843567, 53.87692521], [15.60974394, 53.96680048], [15.61105934, 54.05667437], [15.61238194, 54.14654689], [15.61371179, 54.23641804], [15.61504894, 54.32628781], [15.61639344, 54.41615622],
          [15.61774536, 54.50602326], [15.61910474, 54.59588893], [15.62047164, 54.68575323], [15.62184612, 54.77561617], [15.62322824, 54.86547775], [15.62461805, 54.95533796], [15.62601561, 55.04519682]], 1, '#909090'],
          
        [[[15.69266437, 49.65047112], [15.6939439, 49.74040981], [15.69522987, 49.83034708], [15.69652233, 49.92028292], [15.69782132, 50.01021733], [15.69912689, 50.10015031], [15.70043908, 50.19008187], [15.70175793, 50.28001201], [15.70308348, 50.36994072], [15.70441579, 50.459868], [15.70575489, 50.54979387], [15.70710083, 50.63971831], [15.70845366, 50.72964133], [15.70981342, 50.81956293], [15.71118017, 50.90948311], [15.71255394, 50.99940187], [15.71393478, 51.08931921], [15.71532275, 51.17923513], [15.71671789, 51.26914964], [15.71812024, 51.35906273], [15.71952987, 51.4489744], [15.72094682, 51.53888467], [15.72237113, 51.62879351], [15.72380287, 51.71870095], [15.72524207, 51.80860697], [15.7266888, 51.89851158], [15.7281431, 51.98841478],
          [15.72960504, 52.07831657], [15.73107465, 52.16821695], [15.73255199, 52.25811593], [15.73403713, 52.3480135], [15.73553011, 52.43790966], [15.73703099, 52.52780442], [15.73853982, 52.61769777], [15.74005666, 52.70758973], [15.74158158, 52.79748028], [15.74311461, 52.88736942], [15.74465583, 52.97725717], [15.7462053, 53.06714352], [15.74776306, 53.15702848], [15.74932918, 53.24691203], [15.75090373, 53.33679419], [15.75248676, 53.42667496], [15.75407833, 53.51655433], [15.7556785, 53.60643231], [15.75728735, 53.6963089], [15.75890492, 53.7861841], [15.7605313, 53.8760579], [15.76216653, 53.96593033], [15.76381069, 54.05580136], [15.76546384, 54.14567101], [15.76712605, 54.23553927], [15.76879738, 54.32540615], [15.77047791, 54.41527165],
          [15.7721677, 54.50513577], [15.77386682, 54.5949985], [15.77557534, 54.68485986], [15.77729333, 54.77471984], [15.77902087, 54.86457845], [15.78075802, 54.95443568], [15.78250487, 55.04429153]], 1, '#909090'],
          
        [[[15.83118315, 49.64955958], [15.83271849, 49.73949539], [15.83426156, 49.82942976], [15.83581242, 49.91936269], [15.83737112, 50.00929419], [15.83893771, 50.09922424], [15.84051224, 50.18915286], [15.84209476, 50.27908004], [15.84368532, 50.36900579], [15.84528399, 50.45893011], [15.84689082, 50.54885299], [15.84850585, 50.63877443], [15.85012914, 50.72869444], [15.85176075, 50.81861302], [15.85340074, 50.90853017], [15.85504916, 50.99844589], [15.85670607, 51.08836018], [15.85837152, 51.17827304], [15.86004558, 51.26818447], [15.8617283, 51.35809447], [15.86341974, 51.44800304], [15.86511997, 51.53791019], [15.86682903, 51.62781591], [15.868547, 51.71772021], [15.87027393, 51.80762308], [15.87200989, 51.89752453], [15.87375494, 51.98742455],
          [15.87550914, 52.07732315], [15.87727256, 52.16722034], [15.87904526, 52.2571161], [15.8808273, 52.34701044], [15.88261875, 52.43690336], [15.88441968, 52.52679487], [15.88623016, 52.61668495], [15.88805025, 52.70657363], [15.88988001, 52.79646088], [15.89171953, 52.88634672], [15.89356887, 52.97623115], [15.89542809, 53.06611417], [15.89729728, 53.15599577], [15.89917649, 53.24587597], [15.90106581, 53.33575475], [15.90296531, 53.42563213], [15.90487506, 53.51550809], [15.90679513, 53.60538265], [15.90872561, 53.69525581], [15.91066656, 53.78512756], [15.91261806, 53.87499791], [15.9145802, 53.96486685], [15.91655304, 54.05473439], [15.91853668, 54.14460053], [15.92053118, 54.23446527], [15.92253663, 54.32432861], [15.92455311, 54.41419056],
          [15.9265807, 54.5040511], [15.92861949, 54.59391026], [15.93066957, 54.68376801], [15.932731, 54.77362438], [15.93480389, 54.86347935], [15.93688831, 54.95333293], [15.93898436, 55.04318512]], 1, '#909090'],

        [[[15.96969425, 49.64848237], [15.97148535, 49.73841476], [15.97328547, 49.82834571], [15.97509468, 49.9182752], [15.97691303, 50.00820325], [15.97874058, 50.09812984], [15.9805774, 50.18805499], [15.98242354, 50.27797869], [15.98427907, 50.36790093], [15.98614404, 50.45782173], [15.98801853, 50.54774109], [15.9899026, 50.63765899], [15.9917963, 50.72757545], [15.99369971, 50.81749046], [15.99561288, 50.90740403], [15.99753589, 50.99731616], [15.99946881, 51.08722683], [16.00141169, 51.17713607], [16.00336461, 51.26704386], [16.00532763, 51.35695021], [16.00730083, 51.44685512], [16.00928427, 51.53675859], [16.01127803, 51.62666062], [16.01328217, 51.7165612], [16.01529677, 51.80646035], [16.0173219, 51.89635806], [16.01935763, 51.98625434],
          [16.02140404, 52.07614917], [16.02346119, 52.16604257], [16.02552917, 52.25593453], [16.02760806, 52.34582506], [16.02969792, 52.43571416], [16.03179884, 52.52560182], [16.03391089, 52.61548804], [16.03603415, 52.70537284], [16.0381687, 52.79525621], [16.04031463, 52.88513814], [16.04247201, 52.97501864], [16.04464093, 53.06489772], [16.04682146, 53.15477537], [16.0490137, 53.24465159], [16.05121772, 53.33452638], [16.05343361, 53.42439975], [16.05566146, 53.5142717], [16.05790136, 53.60414222], [16.06015339, 53.69401131], [16.06241764, 53.78387899], [16.0646942, 53.87374524], [16.06698316, 53.96361008], [16.06928461, 54.05347349], [16.07159865, 54.14333549], [16.07392536, 54.23319606], [16.07626485, 54.32305522], [16.0786172, 54.41291297],
          [16.08098252, 54.5027693], [16.08336089, 54.59262422], [16.08575243, 54.68247772], [16.08815722, 54.77232981], [16.09057537, 54.86218049], [16.09300697, 54.95202976], [16.09545214, 55.04187762]], 1, '#909090'],
        
        [[[16.10819638, 49.64723949], [16.11024319, 49.73716795], [16.1123003, 49.82709495], [16.1143678, 49.91702048], [16.11644574, 50.00694454], [16.1185342, 50.09686714], [16.12063324, 50.18678828], [16.12274294, 50.27670795], [16.12486336, 50.36662616], [16.12699458, 50.45654291], [16.12913668, 50.54645819], [16.13128971, 50.63637201], [16.13345375, 50.72628437], [16.13562889, 50.81619527], [16.13781519, 50.90610471], [16.14001273, 50.99601268], [16.14222158, 51.0859192], [16.14444182, 51.17582426], [16.14667353, 51.26572785], [16.14891679, 51.35562999], [16.15117168, 51.44553067], [16.15343827, 51.5354299], [16.15571664, 51.62532766], [16.15800689, 51.71522397], [16.16030908, 51.80511882], [16.1626233, 51.89501222], [16.16494964, 51.98490416],
          [16.16728818, 52.07479465], [16.169639, 52.16468369], [16.17200219, 52.25457127], [16.17437784, 52.3444574], [16.17676603, 52.43434207], [16.17916685, 52.5242253], [16.1815804, 52.61410707], [16.18400676, 52.7039874], [16.18644602, 52.79386627], [16.18889828, 52.8837437], [16.19136362, 52.97361968], [16.19384214, 53.06349421], [16.19633394, 53.15336729], [16.19883912, 53.24323893], [16.20135776, 53.33310912], [16.20388996, 53.42297787], [16.20643583, 53.51284517], [16.20899546, 53.60271103], [16.21156895, 53.69257544], [16.21415641, 53.78243842], [16.21675793, 53.87229995], [16.21937362, 53.96216004], [16.22200359, 54.0520187], [16.22464794, 54.14187591], [16.22730677, 54.23173168], [16.2299802, 54.32158602], [16.23266833, 54.41143892],
          [16.23537127, 54.50129039], [16.23808913, 54.59114042], [16.24082203, 54.68098901], [16.24357008, 54.77083617], [16.24633339, 54.8606819], [16.24911208, 54.95052619], [16.25190626, 55.04036906]], 1, '#909090'],
        
        [[[16.24668827, 49.64583098], [16.24899071, 49.73575498], [16.25130475, 49.8256775], [16.25363046, 49.91559854], [16.25596793, 50.0055181], [16.25831722, 50.09543617], [16.26067842, 50.18535277], [16.26305161, 50.27526788], [16.26543686, 50.36518151], [16.26783426, 50.45509367], [16.27024388, 50.54500434], [16.2726658, 50.63491353], [16.27510012, 50.72482124], [16.27754691, 50.81472748], [16.28000626, 50.90463223], [16.28247824, 50.99453551], [16.28496296, 51.08443731], [16.28746049, 51.17433763], [16.28997091, 51.26423647], [16.29249433, 51.35413384], [16.29503082, 51.44402973], [16.29758048, 51.53392414], [16.30014339, 51.62381707], [16.30271965, 51.71370854], [16.30530936, 51.80359852], [16.30791259, 51.89348703], [16.31052945, 51.98337407],
          [16.31316003, 52.07325963], [16.31580443, 52.16314372], [16.31846275, 52.25302634], [16.32113507, 52.34290748], [16.32382151, 52.43278715], [16.32652215, 52.52266535], [16.3292371, 52.61254208], [16.33196647, 52.70241734], [16.33471034, 52.79229112], [16.33746884, 52.88216344], [16.34024205, 52.97203429], [16.34303009, 53.06190367], [16.34583306, 53.15177158], [16.34865107, 53.24163802], [16.35148423, 53.331503], [16.35433264, 53.42136651], [16.35719643, 53.51122855], [16.36007569, 53.60108913], [16.36297055, 53.69094824], [16.36588111, 53.78080589], [16.36880749, 53.87066207], [16.37174982, 53.96051679], [16.37470819, 54.05037004], [16.37768274, 54.14022184], [16.38067359, 54.23007217], [16.38368085, 54.31992104], [16.38670464, 54.40976845],
          [16.3897451, 54.4996144], [16.39280234, 54.58945889], [16.39587649, 54.67930193], [16.39896768, 54.7691435], [16.40207603, 54.85898362], [16.40520168, 54.94882227], [16.40834476, 55.03865948]], 1, '#909090'],
          
          
      [[[16.38516863, 49.64425686], [16.38772663, 49.73417588], [16.39029752, 49.8240934], [16.39288138, 49.91400942], [16.39547829, 50.00392394], [16.39808835, 50.09383696], [16.40071162, 50.18374848], [16.40334822, 50.2736585], [16.40599821, 50.36356701], [16.4086617, 50.45347403], [16.41133877, 50.54337955], [16.41402951, 50.63328357], [16.41673402, 50.7231861], [16.41945238, 50.81308712], [16.42218468, 50.90298664], [16.42493104, 50.99288466], [16.42769153, 51.08278119], [16.43046625, 51.17267622], [16.4332553, 51.26256975], [16.43605879, 51.35246178], [16.43887679, 51.44235232], [16.44170943, 51.53224135], [16.44455679, 51.62212889], [16.44741898, 51.71201494], [16.4502961, 51.80189948], [16.45318825, 51.89178253], [16.45609554, 51.98166409],
          [16.45901808, 52.07154414], [16.46195596, 52.16142271], [16.4649093, 52.25129977], [16.4678782, 52.34117535], [16.47086278, 52.43104942], [16.47386314, 52.52092201], [16.4768794, 52.6107931], [16.47991166, 52.70066269], [16.48296005, 52.79053079], [16.48602467, 52.8803974], [16.48910565, 52.97026252], [16.4922031, 53.06012614], [16.49531713, 53.14998827], [16.49844787, 53.23984891], [16.50159544, 53.32970806], [16.50475996, 53.41956571], [16.50794155, 53.50942188], [16.51114033, 53.59927655], [16.51435644, 53.68912974], [16.51758999, 53.77898143], [16.52084112, 53.86883163], [16.52410995, 53.95868035], [16.52739662, 54.04852758], [16.53070126, 54.13837332], [16.53402399, 54.22821757], [16.53736496, 54.31806033], [16.5407243, 54.40790161],
          [16.54410214, 54.4977414], [16.54749863, 54.5875797], [16.55091391, 54.67741651], [16.55434811, 54.76725184], [16.55780138, 54.85708569], [16.56127387, 54.94691805], [16.56476571, 55.03674893]], 1, '#000000'],
          
      [[[16.52363619, 49.64251718], [16.52644967, 49.73243069], [16.52927733, 49.82234269], [16.53211924, 49.91225316], [16.53497552, 50.00216211], [16.53784624, 50.09206954], [16.54073151, 50.18197545], [16.54363142, 50.27187984], [16.54654608, 50.36178271], [16.54947557, 50.45168405], [16.55241999, 50.54158388], [16.55537945, 50.63148218], [16.55835405, 50.72137896], [16.56134389, 50.81127423], [16.56434907, 50.90116797], [16.56736969, 50.99106019], [16.57040586, 51.08095089], [16.57345768, 51.17084007], [16.57652527, 51.26072773], [16.57960872, 51.35061386], [16.58270814, 51.44049848], [16.58582366, 51.53038158], [16.58895536, 51.62026316], [16.59210338, 51.71014321], [16.59526781, 51.80002175], [16.59844878, 51.88989876], [16.60164639, 51.97977426],
          [16.60486077, 52.06964823], [16.60809202, 52.15952069], [16.61134028, 52.24939162], [16.61460565, 52.33926104], [16.61788826, 52.42912893], [16.62118823, 52.51899531], [16.62450568, 52.60886017], [16.62784073, 52.69872351], [16.63119352, 52.78858532], [16.63456416, 52.87844562], [16.63795278, 52.9683044], [16.64135952, 53.05816167], [16.6447845, 53.14801741], [16.64822785, 53.23787163], [16.6516897, 53.32772434], [16.6551702, 53.41757553], [16.65866947, 53.5074252], [16.66218765, 53.59727335], [16.66572488, 53.68711998], [16.6692813, 53.7769651], [16.67285704, 53.8668087], [16.67645226, 53.95665078], [16.68006708, 54.04649134], [16.68370167, 54.13633039], [16.68735616, 54.22616792], [16.6910307, 54.31600394], [16.69472545, 54.40583843],
          [16.69844054, 54.49567141], [16.70217614, 54.58550288], [16.7059324, 54.67533282], [16.70970947, 54.76516126], [16.71350752, 54.85498817], [16.71732669, 54.94481357], [16.72116715, 55.03463746]], 1, '#909090'],

      [[[16.66208967, 49.64061197], [16.66515854, 49.73051946], [16.66824287, 49.8204254], [16.67134275, 49.91032979], [16.67445829, 50.00023265], [16.67758959, 50.09013396], [16.68073675, 50.18003372], [16.68389989, 50.26993195], [16.6870791, 50.35982863], [16.69027449, 50.44972376], [16.69348618, 50.53961735], [16.69671426, 50.62950939], [16.69995885, 50.71939989], [16.70322007, 50.80928885], [16.70649801, 50.89917626], [16.70979279, 50.98906213], [16.71310454, 51.07894645], [16.71643336, 51.16882922], [16.71977936, 51.25871045], [16.72314267, 51.34859013], [16.72652341, 51.43846827], [16.72992169, 51.52834486], [16.73333763, 51.61821991], [16.73677135, 51.70809341], [16.74022299, 51.79796536], [16.74369265, 51.88783577], [16.74718047, 51.97770463],
          [16.75068658, 52.06757194], [16.75421109, 52.15743771], [16.75775415, 52.24730193], [16.76131587, 52.3371646], [16.76489639, 52.42702573], [16.76849584, 52.51688531], [16.77211436, 52.60674334], [16.77575208, 52.69659983], [16.77940913, 52.78645477], [16.78308566, 52.87630816], [16.7867818, 52.96616], [16.7904977, 53.0560103], [16.79423349, 53.14585905], [16.79798932, 53.23570625], [16.80176533, 53.3255519], [16.80556167, 53.41539601], [16.80937848, 53.50523857], [16.81321592, 53.59507957], [16.81707414, 53.68491904], [16.82095327, 53.77475695], [16.82485349, 53.86459331], [16.82877495, 53.95442813], [16.83271779, 54.0442614], [16.83668218, 54.13409312], [16.84066828, 54.22392329], [16.84467625, 54.31375191], [16.84870624, 54.40357898],
          [16.85275844, 54.49340451], [16.856833, 54.58322848], [16.86093008, 54.67305091], [16.86504987, 54.76287179], [16.86919252, 54.85269112], [16.87335822, 54.94250889], [16.87754714, 55.03232512]], 1, '#909090'],

      [[[16.8005278, 49.63854128], [16.80385196, 49.72844221], [16.80719285, 49.81834157], [16.8105506, 49.90823937], [16.8139253, 49.9981356], [16.81731707, 50.08803025], [16.82072603, 50.17792335], [16.82415228, 50.26781487], [16.82759594, 50.35770482], [16.83105713, 50.4475932], [16.83453597, 50.53748002], [16.83803256, 50.62736526], [16.84154704, 50.71724893], [16.84507951, 50.80713103], [16.8486301, 50.89701156], [16.85219894, 50.98689052], [16.85578615, 51.07676791], [16.85939184, 51.16664372], [16.86301615, 51.25651796], [16.8666592, 51.34639063], [16.87032113, 51.43626173], [16.87400205, 51.52613125], [16.87770211, 51.6159992], [16.88142142, 51.70586557], [16.88516013, 51.79573037], [16.88891837, 51.8855936], [16.89269628, 51.97545525],
          [16.89649398, 52.06531532], [16.90031162, 52.15517382], [16.90414934, 52.24503075], [16.90800728, 52.3348861], [16.91188558, 52.42473987], [16.91578439, 52.51459206], [16.91970384, 52.60444268], [16.92364409, 52.69429172], [16.92760527, 52.78413918], [16.93158755, 52.87398506], [16.93559107, 52.96382937], [16.93961599, 53.05367209], [16.94366245, 53.14351324], [16.94773061, 53.23335281], [16.95182063, 53.3231908], [16.95593266, 53.41302721], [16.96006687, 53.50286203], [16.96422342, 53.59269528], [16.96840247, 53.68252695], [16.97260417, 53.77235703], [16.97682871, 53.86218554], [16.98107625, 53.95201246], [16.98534695, 54.0418378], [16.98964098, 54.13166155], [16.99395853, 54.22148373], [16.99829976, 54.31130432], [17.00266485, 54.40112332],
          [17.00705397, 54.49094074], [17.01146732, 54.58075658], [17.01590506, 54.67057084], [17.02036739, 54.7603835], [17.02485449, 54.85019459], [17.02936654, 54.94000408], [17.03390373, 55.02981199]], 1, '#909090'],

      [[[16.9389493, 49.63630515], [16.94252863, 49.726199], [16.94612599, 49.81609126], [16.94974148, 49.90598193], [16.95337524, 49.995871], [16.95702737, 50.08575848], [16.960698, 50.17564436], [16.96438726, 50.26552865], [16.96809526, 50.35541134], [16.97182213, 50.44529243], [16.97556799, 50.53517193], [16.97933298, 50.62504982], [16.98311722, 50.71492612], [16.98692083, 50.80480082], [16.99074396, 50.89467392], [16.99458672, 50.98454542], [16.99844926, 51.07441532], [17.00233171, 51.16428362], [17.0062342, 51.25415032], [17.01015687, 51.34401541], [17.01409985, 51.43387891], [17.01806329, 51.5237408], [17.02204733, 51.61360108], [17.0260521, 51.70345976], [17.03007775, 51.79331684], [17.03412443, 51.88317231], [17.03819228, 51.97302617],
          [17.04228145, 52.06287843], [17.04639208, 52.15272909], [17.05052433, 52.24257813], [17.05467834, 52.33242557], [17.05885428, 52.42227139], [17.06305228, 52.51211561], [17.06727252, 52.60195822], [17.07151515, 52.69179922], [17.07578032, 52.78163861], [17.0800682, 52.87147639], [17.08437895, 52.96131255], [17.08871273, 53.0511471], [17.0930697, 53.14098004], [17.09745004, 53.23081137], [17.10185391, 53.32064108], [17.10628148, 53.41046918], [17.11073293, 53.50029566], [17.11520842, 53.59012053], [17.11970813, 53.67994378], [17.12423224, 53.76976541], [17.12878093, 53.85958543], [17.13335438, 53.94940382], [17.13795277, 54.0392206], [17.14257627, 54.12903576], [17.14722509, 54.2188493], [17.15189941, 54.30866122], [17.15659941, 54.39847151],
          [17.16132529, 54.48828019], [17.16607724, 54.57808724], [17.17085546, 54.66789267], [17.17566015, 54.75769647], [17.18049149, 54.84749865], [17.18534971, 54.9372992], [17.19023499, 55.02709813]], 1, '#909090'],

      [[[17.07735289, 49.63390363], [17.08118729, 49.72378988], [17.08504099, 49.81367451], [17.08891411, 49.90355753], [17.0928068, 49.99343891], [17.09671917, 50.08331868], [17.10065136, 50.17319682], [17.10460349, 50.26307334], [17.10857571, 50.35294823], [17.11256813, 50.44282149], [17.1165809, 50.53269313], [17.12061415, 50.62256314], [17.12466802, 50.71243152], [17.12874265, 50.80229828], [17.13283817, 50.8921634], [17.13695473, 50.98202689], [17.14109247, 51.07188875], [17.14525154, 51.16174898], [17.14943207, 51.25160757], [17.15363421, 51.34146454], [17.15785812, 51.43131986], [17.16210393, 51.52117356], [17.16637181, 51.61102561], [17.17066189, 51.70087603], [17.17497435, 51.79072481], [17.17930932, 51.88057196], [17.18366696, 51.97041746],
          [17.18804744, 52.06026133], [17.19245091, 52.15010355], [17.19687754, 52.23994414], [17.20132748, 52.32978308], [17.20580089, 52.41962038], [17.21029795, 52.50945603], [17.21481882, 52.59929004], [17.21936367, 52.68912241], [17.22393267, 52.77895313], [17.22852598, 52.8687822], [17.23314379, 52.95860962], [17.23778627, 53.0484354], [17.24245359, 53.13825953], [17.24714594, 53.228082], [17.25186349, 53.31790283], [17.25660643, 53.407722], [17.26137494, 53.49753952], [17.2661692, 53.58735539], [17.2709894, 53.6771696], [17.27583574, 53.76698216], [17.2807084, 53.85679306], [17.28560758, 53.9466023], [17.29053346, 54.03640989], [17.29548626, 54.12621581], [17.30046616, 54.21602008], [17.30547337, 54.30582268], [17.3105081, 54.39562362],
          [17.31557054, 54.4854229], [17.3206609, 54.57522052], [17.3257794, 54.66501647], [17.33092624, 54.75481076], [17.33610164, 54.84460338], [17.34130581, 54.93439433], [17.34653897, 55.02418361]], 1, '#909090'],

      [[[17.21573731, 49.63133677], [17.21982664, 49.7212149], [17.22393655, 49.81109138], [17.22806719, 49.90096621], [17.23221868, 49.99083939], [17.23639116, 50.08071092], [17.24058477, 50.17058078], [17.24479965, 50.260449], [17.24903594, 50.35031555], [17.25329378, 50.44018045], [17.25757332, 50.53004369], [17.2618747, 50.61990527], [17.26619807, 50.70976519], [17.27054357, 50.79962345], [17.27491135, 50.88948005], [17.27930156, 50.97933498], [17.28371436, 51.06918825], [17.2881499, 51.15903985], [17.29260832, 51.24888979], [17.2970898, 51.33873806], [17.30159447, 51.42858466], [17.30612251, 51.51842959], [17.31067408, 51.60827285], [17.31524933, 51.69811444], [17.31984842, 51.78795436], [17.32447153, 51.87779261], [17.32911882, 51.96762918],
          [17.33379045, 52.05746407], [17.3384866, 52.14729729], [17.34320744, 52.23712883], [17.34795314, 52.3269587], [17.35272387, 52.41678688], [17.35751981, 52.50661338], [17.36234114, 52.5964382], [17.36718804, 52.68626134], [17.37206069, 52.77608279], [17.37695927, 52.86590256], [17.38188397, 52.95572065], [17.38683497, 53.04553704], [17.39181246, 53.13535175], [17.39681664, 53.22516477], [17.40184769, 53.3149761], [17.40690581, 53.40478573], [17.41199119, 53.49459368], [17.41710403, 53.58439992], [17.42224454, 53.67420448], [17.42741291, 53.76400734], [17.43260935, 53.8538085], [17.43783406, 53.94360796], [17.44308725, 54.03340572], [17.44836914, 54.12320178], [17.45367992, 54.21299613], [17.45901983, 54.30278879], [17.46438907, 54.39257973],
          [17.46978786, 54.48236897], [17.47521643, 54.57215651], [17.48067499, 54.66194233], [17.48616377, 54.75172644], [17.49168301, 54.84150885], [17.49723292, 54.93128953], [17.50281375, 55.02106851]], 1, '#909090'],

      [[[17.35410127, 49.62860464], [17.3584454, 49.71847412], [17.3628114, 49.80834193], [17.36719941, 49.89820805], [17.37160956, 49.98807249], [17.37604201, 50.07793524], [17.38049691, 50.16779631], [17.3849744, 50.25765568], [17.38947462, 50.34751337], [17.39399774, 50.43736936], [17.39854391, 50.52722367], [17.40311327, 50.61707628], [17.40770598, 50.70692719], [17.4123222, 50.79677641], [17.41696209, 50.88662393], [17.42162581, 50.97646976], [17.42631351, 51.06631388], [17.43102537, 51.1561563], [17.43576153, 51.24599703], [17.44052218, 51.33583604], [17.44530747, 51.42567336], [17.45011757, 51.51550897], [17.45495266, 51.60534287], [17.45981291, 51.69517506], [17.46469848, 51.78500555], [17.46960956, 51.87483432], [17.47454632, 51.96466138],
          [17.47950894, 52.05448673], [17.4844976, 52.14431037], [17.48951248, 52.23413229], [17.49455376, 52.32395249], [17.49962163, 52.41377097], [17.50471628, 52.50358773], [17.5098379, 52.59340277], [17.51498666, 52.68321609], [17.52016278, 52.77302768], [17.52536644, 52.86283755], [17.53059784, 52.9526457], [17.53585718, 53.04245211], [17.54114465, 53.13225679], [17.54646046, 53.22205975], [17.55180482, 53.31186097], [17.55717792, 53.40166045], [17.56257998, 53.4914582], [17.56801121, 53.58125422], [17.57347181, 53.67104849], [17.57896201, 53.76084103], [17.58448202, 53.85063182], [17.59003206, 53.94042087], [17.59561235, 54.03020818], [17.60122311, 54.11999374], [17.60686457, 54.20977755], [17.61253695, 54.29955961], [17.61824049, 54.38933992],
          [17.62397541, 54.47911848], [17.62974196, 54.56889528], [17.63554036, 54.65867033], [17.64137086, 54.74844362], [17.6472337, 54.83821514], [17.65312912, 54.92798491], [17.65905737, 55.01775292]], 1, '#909090'],

      [[[17.49244351, 49.62570728], [17.49704231, 49.71556761], [17.50166424, 49.80542622], [17.50630947, 49.89528311], [17.51097815, 49.98513828], [17.51567043, 50.07499173], [17.52038646, 50.16484346], [17.5251264, 50.25469346], [17.52989041, 50.34454174], [17.53467866, 50.43438829], [17.53949129, 50.52423312], [17.54432848, 50.61407622], [17.54919039, 50.70391758], [17.55407717, 50.79375722], [17.55898901, 50.88359512], [17.56392607, 50.97343128], [17.56888852, 51.06326571], [17.57387652, 51.1530984], [17.57889026, 51.24292935], [17.58392991, 51.33275856], [17.58899565, 51.42258603], [17.59408765, 51.51241175], [17.59920609, 51.60223573], [17.60435116, 51.69205796], [17.60952304, 51.78187844], [17.61472192, 51.87169718], [17.61994797, 51.96151416],
          [17.62520139, 52.05132938], [17.63048238, 52.14114285], [17.63579111, 52.23095457], [17.6411278, 52.32076453], [17.64649262, 52.41057272], [17.65188579, 52.50037916], [17.65730749, 52.59018383], [17.66275794, 52.67998673], [17.66823733, 52.76978787], [17.67374588, 52.85958725], [17.67928378, 52.94938485], [17.68485125, 53.03918068], [17.6904485, 53.12897473], [17.69607574, 53.21876701], [17.7017332, 53.30855752], [17.70742108, 53.39834624], [17.71313961, 53.48813319], [17.718889, 53.57791835], [17.7246695, 53.66770172], [17.73048131, 53.75748331], [17.73632467, 53.84726312], [17.74219982, 53.93704113], [17.74810698, 54.02681735], [17.75404639, 54.11659177], [17.76001829, 54.2063644], [17.76602292, 54.29613524], [17.77206052, 54.38590427],
          [17.77813134, 54.4756715], [17.78423563, 54.56543692], [17.79037363, 54.65520054], [17.79654561, 54.74496236], [17.80275181, 54.83472236], [17.80899249, 54.92448055], [17.81526792, 55.01423692]], 1, '#909090'],
        
      [[[17.63076276, 49.62264478], [17.63561607, 49.71249542], [17.64049379, 49.8023443], [17.6453961, 49.89219144], [17.65032314, 49.98203681], [17.65527508, 50.07188043], [17.66025209, 50.1617223], [17.66525434, 50.2515624], [17.67028198, 50.34140074], [17.67533518, 50.43123731], [17.68041413, 50.52107212], [17.68551898, 50.61090516], [17.69064991, 50.70073644], [17.69580709, 50.79056594], [17.70099071, 50.88039367], [17.70620094, 50.97021963], [17.71143796, 51.06004381], [17.71670194, 51.14986622], [17.72199309, 51.23968684], [17.72731156, 51.32950569], [17.73265757, 51.41932275], [17.73803128, 51.50913802], [17.7434329, 51.59895151], [17.74886261, 51.68876321], [17.75432061, 51.77857313], [17.75980709, 51.86838124], [17.76532225, 51.95818757],
          [17.77086629, 52.0479921], [17.7764394, 52.13779483], [17.7820418, 52.22759576], [17.78767369, 52.31739489], [17.79333527, 52.40719222], [17.79902675, 52.49698774], [17.80474835, 52.58678145], [17.81050027, 52.67657335], [17.81628273, 52.76636344], [17.82209595, 52.85615172], [17.82794015, 52.94593818], [17.83381554, 53.03572283], [17.83972236, 53.12550565], [17.84566082, 53.21528665], [17.85163115, 53.30506583], [17.85763359, 53.39484318], [17.86366836, 53.48461871], [17.86973571, 53.5743924], [17.87583586, 53.66416426], [17.88196906, 53.75393428], [17.88813554, 53.84370247], [17.89433556, 53.93346881], [17.90056935, 54.02323332], [17.90683718, 54.11299598], [17.91313928, 54.20275679], [17.91947591, 54.29251576], [17.92584733, 54.38227287],
          [17.9322538, 54.47202813], [17.93869558, 54.56178153], [17.94517294, 54.65153308], [17.95168613, 54.74128276], [17.95823543, 54.83103058], [17.96482112, 54.92077653], [17.97144346, 55.01052061]], 1, '#909090'],
    
      [[[17.76905775, 49.61941719], [17.77416541, 49.70925762], [17.77929876, 49.79909626], [17.78445798, 49.88893311], [17.78964323, 49.97876817], [17.79485468, 50.06860143], [17.8000925, 50.1584329], [17.80535688, 50.24826256], [17.81064797, 50.33809043], [17.81596597, 50.42791649], [17.82131105, 50.51774074], [17.82668339, 50.60756319], [17.83208317, 50.69738383], [17.83751058, 50.78720266], [17.8429658, 50.87701968], [17.84844902, 50.96683488], [17.85396043, 51.05664826], [17.85950021, 51.14645983], [17.86506857, 51.23626957], [17.87066569, 51.32607749], [17.87629177, 51.41588359], [17.88194701, 51.50568786], [17.88763161, 51.59549029], [17.89334577, 51.6852909], [17.89908969, 51.77508967], [17.90486358, 51.86488661], [17.91066765, 51.9546817],
          [17.9165021, 52.04447496], [17.92236715, 52.13426637], [17.92826301, 52.22405594], [17.93418989, 52.31384366], [17.94014802, 52.40362953], [17.94613761, 52.49341355], [17.95215888, 52.58319572], [17.95821206, 52.67297603], [17.96429738, 52.76275448], [17.97041505, 52.85253106], [17.97656532, 52.94230579], [17.98274841, 53.03207865], [17.98896457, 53.12184963], [17.99521402, 53.21161875], [18, 53.28004159]], 1, '#000000'],
        
      [[[17.90732721, 49.61602459], [17.91268906, 49.70585429], [17.91807787, 49.79568217], [17.92349383, 49.88550821], [17.92893711, 49.97533242], [17.9344079, 50.0651548], [17.93990636, 50.15497533], [17.94543269, 50.24479403], [17.95098707, 50.33461089], [17.95656968, 50.4244259], [17.96218072, 50.51423906], [17.96782037, 50.60405038], [17.97348882, 50.69385984], [17.97918626, 50.78366745], [17.9849129, 50.87347321], [17.99066892, 50.9632771], [17.99645452, 51.05307914], [18, 51.10788284]], 1, '#909090'],
    
    
    // strefa 34, linie pionowe
      
        [[[18.09267279, 49.61602459], [18.08731094, 49.70585429], [18.08192213, 49.79568217], [18.07650617, 49.88550821], [18.07106289, 49.97533242], [18.0655921, 50.0651548], [18.06009364, 50.15497533], [18.05456731, 50.24479403], [18.04901293, 50.33461089], [18.04343032, 50.4244259], [18.03781928, 50.51423906], [18.03217963, 50.60405038], [18.02651118, 50.69385984], [18.02081374, 50.78366745], [18.0150871, 50.87347321], [18.00933108, 50.9632771], [18.00354548, 51.05307914], [18, 51.10788284]], 1, '#909090'],
        
        [[[18.2806415, 48.72091487], [18.27578188, 48.81077309], [18.27089817, 48.90062954], [18.2659902, 48.99048422], [18.26105783, 49.08033713], [18.2561009, 49.17018826], [18.25111924, 49.26003761], [18.24611272, 49.34988518], [18.24108115, 49.43973097], [18.23602438, 49.52957497], [18.23094225, 49.61941719], [18.22583459, 49.70925762], [18.22070124, 49.79909626], [18.21554202, 49.88893311], [18.21035677, 49.97876817], [18.20514532, 50.06860143], [18.1999075, 50.1584329], [18.19464312, 50.24826256], [18.18935203, 50.33809043], [18.18403403, 50.42791649], [18.17868895, 50.51774074], [18.17331661, 50.60756319], [18.16791683, 50.69738383], [18.16248942, 50.78720266], [18.1570342, 50.87701968], [18.15155098, 50.96683488], [18.14603957, 51.05664826],
          [18.14049979, 51.14645983], [18.13493143, 51.23626957], [18.12933431, 51.32607749], [18.12370823, 51.41588359], [18.11805299, 51.50568786], [18.11236839, 51.59549029], [18.10665423, 51.6852909], [18.10091031, 51.77508967], [18.09513642, 51.86488661], [18.08933235, 51.9546817], [18.0834979, 52.04447496], [18.07763285, 52.13426637], [18.07173699, 52.22405594], [18.06581011, 52.31384366], [18.05985198, 52.40362953], [18.05386239, 52.49341355], [18.04784112, 52.58319572], [18.04178794, 52.67297603], [18.03570262, 52.76275448], [18.02958495, 52.85253106], [18.02343468, 52.94230579], [18.01725159, 53.03207865], [18.01103543, 53.12184963], [18.00478598, 53.21161875], [18, 53.28004159]], 1, '#000000'],

      [[[18.41646134, 48.72404239], [18.41184376, 48.81391046], [18.40720327, 48.9037768], [18.40253975, 48.99364139], [18.39785303, 49.08350425], [18.39314296, 49.17336536], [18.38840941, 49.26322474], [18.38365222, 49.35308237], [18.37887122, 49.44293825], [18.37406628, 49.53279239], [18.36923724, 49.62264478], [18.36438393, 49.71249542], [18.35950621, 49.8023443], [18.3546039, 49.89219144], [18.34967686, 49.98203681], [18.34472492, 50.07188043], [18.33974791, 50.1617223], [18.33474566, 50.2515624], [18.32971802, 50.34140074], [18.32466482, 50.43123731], [18.31958587, 50.52107212], [18.31448102, 50.61090516], [18.30935009, 50.70073644], [18.30419291, 50.79056594], [18.29900929, 50.88039367], [18.29379906, 50.97021963], [18.28856204, 51.06004381],
          [18.28329806, 51.14986622], [18.27800691, 51.23968684], [18.27268844, 51.32950569], [18.26734243, 51.41932275], [18.26196872, 51.50913802], [18.2565671, 51.59895151], [18.25113739, 51.68876321], [18.24567939, 51.77857313], [18.24019291, 51.86838124], [18.23467775, 51.95818757], [18.22913371, 52.0479921], [18.2235606, 52.13779483], [18.2179582, 52.22759576], [18.21232631, 52.31739489], [18.20666473, 52.40719222], [18.20097325, 52.49698774], [18.19525165, 52.58678145], [18.18949973, 52.67657335], [18.18371727, 52.76636344], [18.17790405, 52.85615172], [18.17205985, 52.94593818], [18.16618446, 53.03572283], [18.16027764, 53.12550565], [18.15433918, 53.21528665], [18.14836885, 53.30506583], [18.14236641, 53.39484318], [18.13633164, 53.48461871],
          [18.13026429, 53.5743924], [18.12416414, 53.66416426], [18.11803094, 53.75393428], [18.11186446, 53.84370247], [18.10566444, 53.93346881], [18.09943065, 54.02323332], [18.09316282, 54.11299598], [18.08686072, 54.20275679], [18.08052409, 54.29251576], [18.07415267, 54.38227287], [18.0677462, 54.47202813], [18.06130442, 54.56178153], [18.05482706, 54.65153308], [18.04831387, 54.74128276], [18.04176457, 54.83103058], [18.03517888, 54.92077653], [18.02855654, 55.01052061]], 1, '#909090'],

      [[[18.55230392, 48.72700994], [18.54792853, 48.81688735], [18.54353143, 48.90676306], [18.53911249, 48.99663707], [18.53467157, 49.08650937], [18.53020852, 49.17637996], [18.52572322, 49.26624885], [18.52121551, 49.35611603], [18.51668525, 49.44598149], [18.51213229, 49.53584524], [18.50755649, 49.62570728], [18.50295769, 49.71556761], [18.49833576, 49.80542622], [18.49369053, 49.89528311], [18.48902185, 49.98513828], [18.48432957, 50.07499173], [18.47961354, 50.16484346], [18.4748736, 50.25469346], [18.47010959, 50.34454174], [18.46532134, 50.43438829], [18.46050871, 50.52423312], [18.45567152, 50.61407622], [18.45080961, 50.70391758], [18.44592283, 50.79375722], [18.44101099, 50.88359512], [18.43607393, 50.97343128], [18.43111148, 51.06326571],
          [18.42612348, 51.1530984], [18.42110974, 51.24292935], [18.41607009, 51.33275856], [18.41100435, 51.42258603], [18.40591235, 51.51241175], [18.40079391, 51.60223573], [18.39564884, 51.69205796], [18.39047696, 51.78187844], [18.38527808, 51.87169718], [18.38005203, 51.96151416], [18.37479861, 52.05132938], [18.36951762, 52.14114285], [18.36420889, 52.23095457], [18.3588722, 52.32076453], [18.35350738, 52.41057272], [18.34811421, 52.50037916], [18.34269251, 52.59018383], [18.33724206, 52.67998673], [18.33176267, 52.76978787], [18.32625412, 52.85958725], [18.32071622, 52.94938485], [18.31514875, 53.03918068], [18.3095515, 53.12897473], [18.30392426, 53.21876701], [18.2982668, 53.30855752], [18.29257892, 53.39834624], [18.28686039, 53.48813319],
          [18.281111, 53.57791835], [18.2753305, 53.66770172], [18.26951869, 53.75748331], [18.26367533, 53.84726312], [18.25780018, 53.93704113], [18.25189302, 54.02681735], [18.24595361, 54.11659177], [18.23998171, 54.2063644], [18.23397708, 54.29613524], [18.22793948, 54.38590427], [18.22186866, 54.4756715], [18.21576437, 54.56543692], [18.20962637, 54.65520054], [18.20345439, 54.74496236], [18.19724819, 54.83472236], [18.19100751, 54.92448055], [18.18473208, 55.01423692]], 1, '#909090'],

      [[[18.68816808, 48.72981744], [18.684035, 48.8197037], [18.67988142, 48.90958828], [18.67570721, 48.99947119], [18.67151223, 49.08935242], [18.66729635, 49.17923199], [18.66305944, 49.26910987], [18.65880135, 49.35898608], [18.65452197, 49.44886061], [18.65022114, 49.53873346], [18.64589873, 49.62860464], [18.6415546, 49.71847412], [18.6371886, 49.80834193], [18.63280059, 49.89820805], [18.62839044, 49.98807249], [18.62395799, 50.07793524], [18.61950309, 50.16779631], [18.6150256, 50.25765568], [18.61052538, 50.34751337], [18.60600226, 50.43736936], [18.60145609, 50.52722367], [18.59688673, 50.61707628], [18.59229402, 50.70692719], [18.5876778, 50.79677641], [18.58303791, 50.88662393], [18.57837419, 50.97646976], [18.57368649, 51.06631388],
          [18.56897463, 51.1561563], [18.56423847, 51.24599703], [18.55947782, 51.33583604], [18.55469253, 51.42567336], [18.54988243, 51.51550897], [18.54504734, 51.60534287], [18.54018709, 51.69517506], [18.53530152, 51.78500555], [18.53039044, 51.87483432], [18.52545368, 51.96466138], [18.52049106, 52.05448673], [18.5155024, 52.14431037], [18.51048752, 52.23413229], [18.50544624, 52.32395249], [18.50037837, 52.41377097], [18.49528372, 52.50358773], [18.4901621, 52.59340277], [18.48501334, 52.68321609], [18.47983722, 52.77302768], [18.47463356, 52.86283755], [18.46940216, 52.9526457], [18.46414282, 53.04245211], [18.45885535, 53.13225679], [18.45353954, 53.22205975], [18.44819518, 53.31186097], [18.44282208, 53.40166045], [18.43742002, 53.4914582],
          [18.43198879, 53.58125422], [18.42652819, 53.67104849], [18.42103799, 53.76084103], [18.41551798, 53.85063182], [18.40996794, 53.94042087], [18.40438765, 54.03020818], [18.39877689, 54.11999374], [18.39313543, 54.20977755], [18.38746305, 54.29955961], [18.38175951, 54.38933992], [18.37602459, 54.47911848], [18.37025804, 54.56889528], [18.36445964, 54.65867033], [18.35862914, 54.74844362], [18.3527663, 54.83821514], [18.34687088, 54.92798491], [18.34094263, 55.01775292]], 1, '#909090'],

      [[[18.8240526, 48.73246484], [18.82016198, 48.82235943], [18.81625205, 48.91225238], [18.81232269, 49.00214369], [18.80837379, 49.09203335], [18.8044052, 49.18192137], [18.80041682, 49.27180775], [18.7964085, 49.36169247], [18.79238013, 49.45157555], [18.78833157, 49.54145699], [18.78426269, 49.63133677], [18.78017336, 49.7212149], [18.77606345, 49.81109138], [18.77193281, 49.90096621], [18.76778132, 49.99083939], [18.76360884, 50.08071092], [18.75941523, 50.17058078], [18.75520035, 50.260449], [18.75096406, 50.35031555], [18.74670622, 50.44018045], [18.74242668, 50.53004369], [18.7381253, 50.61990527], [18.73380193, 50.70976519], [18.72945643, 50.79962345], [18.72508865, 50.88948005], [18.72069844, 50.97933498], [18.71628564, 51.06918825],
          [18.7118501, 51.15903985], [18.70739168, 51.24888979], [18.7029102, 51.33873806], [18.69840553, 51.42858466], [18.69387749, 51.51842959], [18.68932592, 51.60827285], [18.68475067, 51.69811444], [18.68015158, 51.78795436], [18.67552847, 51.87779261], [18.67088118, 51.96762918], [18.66620955, 52.05746407], [18.6615134, 52.14729729], [18.65679256, 52.23712883], [18.65204686, 52.3269587], [18.64727613, 52.41678688], [18.64248019, 52.50661338], [18.63765886, 52.5964382], [18.63281196, 52.68626134], [18.62793931, 52.77608279], [18.62304073, 52.86590256], [18.61811603, 52.95572065], [18.61316503, 53.04553704], [18.60818754, 53.13535175], [18.60318336, 53.22516477], [18.59815231, 53.3149761], [18.59309419, 53.40478573], [18.58800881, 53.49459368],
          [18.58289597, 53.58439992], [18.57775546, 53.67420448], [18.57258709, 53.76400734], [18.56739065, 53.8538085], [18.56216594, 53.94360796], [18.55691275, 54.03340572], [18.55163086, 54.12320178], [18.54632008, 54.21299613], [18.54098017, 54.30278879], [18.53561093, 54.39257973], [18.53021214, 54.48236897], [18.52478357, 54.57215651], [18.51932501, 54.66194233], [18.51383623, 54.75172644], [18.50831699, 54.84150885], [18.50276708, 54.93128953], [18.49718625, 55.02106851]], 1, '#909090'],

      [[[18.95995631, 48.73495208], [18.95630826, 48.82485451], [18.9526421, 48.91475532], [18.94895773, 49.00465452], [18.94525502, 49.0945521], [18.94153386, 49.18444806], [18.93779413, 49.27434241], [18.93403571, 49.36423514], [18.93025848, 49.45412626], [18.92646232, 49.54401575], [18.92264711, 49.63390363], [18.91881271, 49.72378988], [18.91495901, 49.81367451], [18.91108589, 49.90355753], [18.9071932, 49.99343891], [18.90328083, 50.08331868], [18.89934864, 50.17319682], [18.89539651, 50.26307334], [18.89142429, 50.35294823], [18.88743187, 50.44282149], [18.8834191, 50.53269313], [18.87938585, 50.62256314], [18.87533198, 50.71243152], [18.87125735, 50.80229828], [18.86716183, 50.8921634], [18.86304527, 50.98202689], [18.85890753, 51.07188875],
          [18.85474846, 51.16174898], [18.85056793, 51.25160757], [18.84636579, 51.34146454], [18.84214188, 51.43131986], [18.83789607, 51.52117356], [18.83362819, 51.61102561], [18.82933811, 51.70087603], [18.82502565, 51.79072481], [18.82069068, 51.88057196], [18.81633304, 51.97041746], [18.81195256, 52.06026133], [18.80754909, 52.15010355], [18.80312246, 52.23994414], [18.79867252, 52.32978308], [18.79419911, 52.41962038], [18.78970205, 52.50945603], [18.78518118, 52.59929004], [18.78063633, 52.68912241], [18.77606733, 52.77895313], [18.77147402, 52.8687822], [18.76685621, 52.95860962], [18.76221373, 53.0484354], [18.75754641, 53.13825953], [18.75285406, 53.228082], [18.74813651, 53.31790283], [18.74339357, 53.407722], [18.73862506, 53.49753952],
          [18.7338308, 53.58735539], [18.7290106, 53.6771696], [18.72416426, 53.76698216], [18.7192916, 53.85679306], [18.71439242, 53.9466023], [18.70946654, 54.03640989], [18.70451374, 54.12621581], [18.69953384, 54.21602008], [18.69452663, 54.30582268], [18.6894919, 54.39562362], [18.68442946, 54.4854229], [18.6793391, 54.57522052], [18.6742206, 54.66501647], [18.66907376, 54.75481076], [18.66389836, 54.84460338], [18.65869419, 54.93439433], [18.65346103, 55.02418361]], 1, '#909090'],

      [[[19.09587801, 48.73727911], [19.09247264, 48.82718886], [19.08905037, 48.91709703], [19.0856111, 49.00700361], [19.0821547, 49.0969086], [19.07868109, 49.186812], [19.07519013, 49.27671381], [19.07168173, 49.36661403], [19.06815577, 49.45651266], [19.06461213, 49.5464097], [19.0610507, 49.63630515], [19.05747137, 49.726199], [19.05387401, 49.81609126], [19.05025852, 49.90598193], [19.04662476, 49.995871], [19.04297263, 50.08575848], [19.039302, 50.17564436], [19.03561274, 50.26552865], [19.03190474, 50.35541134], [19.02817787, 50.44529243], [19.02443201, 50.53517193], [19.02066702, 50.62504982], [19.01688278, 50.71492612], [19.01307917, 50.80480082], [19.00925604, 50.89467392], [19.00541328, 50.98454542], [19.00155074, 51.07441532],
          [18.99766829, 51.16428362], [18.9937658, 51.25415032], [18.98984313, 51.34401541], [18.98590015, 51.43387891], [18.98193671, 51.5237408], [18.97795267, 51.61360108], [18.9739479, 51.70345976], [18.96992225, 51.79331684], [18.96587557, 51.88317231], [18.96180772, 51.97302617], [18.95771855, 52.06287843], [18.95360792, 52.15272909], [18.94947567, 52.24257813], [18.94532166, 52.33242557], [18.94114572, 52.42227139], [18.93694772, 52.51211561], [18.93272748, 52.60195822], [18.92848485, 52.69179922], [18.92421968, 52.78163861], [18.9199318, 52.87147639], [18.91562105, 52.96131255], [18.91128727, 53.0511471], [18.9069303, 53.14098004], [18.90254996, 53.23081137], [18.89814609, 53.32064108], [18.89371852, 53.41046918], [18.88926707, 53.50029566],
          [18.88479158, 53.59012053], [18.88029187, 53.67994378], [18.87576776, 53.76976541], [18.87121907, 53.85958543], [18.86664562, 53.94940382], [18.86204723, 54.0392206], [18.85742373, 54.12903576], [18.85277491, 54.2188493], [18.84810059, 54.30866122], [18.84340059, 54.39847151], [18.83867471, 54.48828019], [18.83392276, 54.57808724], [18.82914454, 54.66789267], [18.82433985, 54.75769647], [18.81950851, 54.84749865], [18.81465029, 54.9372992], [18.80976501, 55.02709813]], 1, '#909090'],

      [[[19.23181649, 48.73944587], [19.22865392, 48.82936245], [19.22547565, 48.91927747], [19.22228158, 49.00919092], [19.21907162, 49.09910281], [19.21584565, 49.18901314], [19.21260358, 49.2789219], [19.20934531, 49.36882909], [19.20607072, 49.45873472], [19.20277972, 49.54863878], [19.1994722, 49.63854128], [19.19614804, 49.72844221], [19.19280715, 49.81834157], [19.1894494, 49.90823937], [19.1860747, 49.9981356], [19.18268293, 50.08803025], [19.17927397, 50.17792335], [19.17584772, 50.26781487], [19.17240406, 50.35770482], [19.16894287, 50.4475932], [19.16546403, 50.53748002], [19.16196744, 50.62736526], [19.15845296, 50.71724893], [19.15492049, 50.80713103], [19.1513699, 50.89701156], [19.14780106, 50.98689052], [19.14421385, 51.07676791],
          [19.14060816, 51.16664372], [19.13698385, 51.25651796], [19.1333408, 51.34639063], [19.12967887, 51.43626173], [19.12599795, 51.52613125], [19.12229789, 51.6159992], [19.11857858, 51.70586557], [19.11483987, 51.79573037], [19.11108163, 51.8855936], [19.10730372, 51.97545525], [19.10350602, 52.06531532], [19.09968838, 52.15517382], [19.09585066, 52.24503075], [19.09199272, 52.3348861], [19.08811442, 52.42473987], [19.08421561, 52.51459206], [19.08029616, 52.60444268], [19.07635591, 52.69429172], [19.07239473, 52.78413918], [19.06841245, 52.87398506], [19.06440893, 52.96382937], [19.06038401, 53.05367209], [19.05633755, 53.14351324], [19.05226939, 53.23335281], [19.04817937, 53.3231908], [19.04406734, 53.41302721], [19.03993313, 53.50286203],
          [19.03577658, 53.59269528], [19.03159753, 53.68252695], [19.02739583, 53.77235703], [19.02317129, 53.86218554], [19.01892375, 53.95201246], [19.01465305, 54.0418378], [19.01035902, 54.13166155], [19.00604147, 54.22148373], [19.00170024, 54.31130432], [18.99733515, 54.40112332], [18.99294603, 54.49094074], [18.98853268, 54.58075658], [18.98409494, 54.67057084], [18.97963261, 54.7603835], [18.97514551, 54.85019459], [18.97063346, 54.94000408], [18.96609627, 55.02981199]], 1, '#909090'],

      [[[19.36777058, 48.74145232], [19.3648509, 48.83137522], [19.36191672, 48.92129658], [19.35896796, 49.0112164], [19.35600453, 49.10113468], [19.35302632, 49.19105142], [19.35003324, 49.28096661], [19.3470252, 49.37088027], [19.3440021, 49.46079238], [19.34096384, 49.55070295], [19.33791033, 49.64061197], [19.33484146, 49.73051946], [19.33175713, 49.8204254], [19.32865725, 49.91032979], [19.32554171, 50.00023265], [19.32241041, 50.09013396], [19.31926325, 50.18003372], [19.31610011, 50.26993195], [19.3129209, 50.35982863], [19.30972551, 50.44972376], [19.30651382, 50.53961735], [19.30328574, 50.62950939], [19.30004115, 50.71939989], [19.29677993, 50.80928885], [19.29350199, 50.89917626], [19.29020721, 50.98906213], [19.28689546, 51.07894645],
          [19.28356664, 51.16882922], [19.28022064, 51.25871045], [19.27685733, 51.34859013], [19.27347659, 51.43846827], [19.27007831, 51.52834486], [19.26666237, 51.61821991], [19.26322865, 51.70809341], [19.25977701, 51.79796536], [19.25630735, 51.88783577], [19.25281953, 51.97770463], [19.24931342, 52.06757194], [19.24578891, 52.15743771], [19.24224585, 52.24730193], [19.23868413, 52.3371646], [19.23510361, 52.42702573], [19.23150416, 52.51688531], [19.22788564, 52.60674334], [19.22424792, 52.69659983], [19.22059087, 52.78645477], [19.21691434, 52.87630816], [19.2132182, 52.96616], [19.2095023, 53.0560103], [19.20576651, 53.14585905], [19.20201068, 53.23570625], [19.19823467, 53.3255519], [19.19443833, 53.41539601], [19.19062152, 53.50523857],
          [19.18678408, 53.59507957], [19.18292586, 53.68491904], [19.17904673, 53.77475695], [19.17514651, 53.86459331], [19.17122505, 53.95442813], [19.16728221, 54.0442614], [19.16331782, 54.13409312], [19.15933172, 54.22392329], [19.15532375, 54.31375191], [19.15129376, 54.40357898], [19.14724156, 54.49340451], [19.143167, 54.58322848], [19.13906992, 54.67305091], [19.13495013, 54.76287179], [19.13080748, 54.85269112], [19.12664178, 54.94250889], [19.12245286, 55.03232512]], 1, '#909090'],

      [[[19.50373906, 48.74329841], [19.50106237, 48.83322713], [19.49837238, 48.92315433], [19.49566902, 49.01308001], [19.49295221, 49.10300416], [19.49022185, 49.1929268], [19.48747786, 49.28284792], [19.48472015, 49.37276752], [19.48194863, 49.46268559], [19.47916321, 49.55260215], [19.47636381, 49.64251718], [19.47355033, 49.73243069], [19.47072267, 49.82234269], [19.46788076, 49.91225316], [19.46502448, 50.00216211], [19.46215376, 50.09206954], [19.45926849, 50.18197545], [19.45636858, 50.27187984], [19.45345392, 50.36178271], [19.45052443, 50.45168405], [19.44758001, 50.54158388], [19.44462055, 50.63148218], [19.44164595, 50.72137896], [19.43865611, 50.81127423], [19.43565093, 50.90116797], [19.43263031, 50.99106019], [19.42959414, 51.08095089],
          [19.42654232, 51.17084007], [19.42347473, 51.26072773], [19.42039128, 51.35061386], [19.41729186, 51.44049848], [19.41417634, 51.53038158], [19.41104464, 51.62026316], [19.40789662, 51.71014321], [19.40473219, 51.80002175], [19.40155122, 51.88989876], [19.39835361, 51.97977426], [19.39513923, 52.06964823], [19.39190798, 52.15952069], [19.38865972, 52.24939162], [19.38539435, 52.33926104], [19.38211174, 52.42912893], [19.37881177, 52.51899531], [19.37549432, 52.60886017], [19.37215927, 52.69872351], [19.36880648, 52.78858532], [19.36543584, 52.87844562], [19.36204722, 52.9683044], [19.35864048, 53.05816167], [19.3552155, 53.14801741], [19.35177215, 53.23787163], [19.3483103, 53.32772434], [19.3448298, 53.41757553], [19.34133053, 53.5074252],
          [19.33781235, 53.59727335], [19.33427512, 53.68711998], [19.3307187, 53.7769651], [19.32714296, 53.8668087], [19.32354774, 53.95665078], [19.31993292, 54.04649134], [19.31629833, 54.13633039], [19.31264384, 54.22616792], [19.3089693, 54.31600394], [19.30527455, 54.40583843], [19.30155946, 54.49567141], [19.29782386, 54.58550288], [19.2940676, 54.67533282], [19.29029053, 54.76516126], [19.28649248, 54.85498817], [19.28267331, 54.94481357], [19.27883285, 55.03463746]], 1, '#909090'],

      [[[19.63972075, 48.74498411], [19.63728712, 48.83491814], [19.63484141, 48.92485067], [19.63238354, 49.0147817], [19.62991344, 49.10471122], [19.62743101, 49.19463925], [19.6249362, 49.28456577], [19.6224289, 49.3744908], [19.61990906, 49.46441432], [19.61737657, 49.55433634], [19.61483137, 49.64425686], [19.61227337, 49.73417588], [19.60970248, 49.8240934], [19.60711862, 49.91400942], [19.60452171, 50.00392394], [19.60191165, 50.09383696], [19.59928838, 50.18374848], [19.59665178, 50.2736585], [19.59400179, 50.36356701], [19.5913383, 50.45347403], [19.58866123, 50.54337955], [19.58597049, 50.63328357], [19.58326598, 50.7231861], [19.58054762, 50.81308712], [19.57781532, 50.90298664], [19.57506896, 50.99288466], [19.57230847, 51.08278119],
          [19.56953375, 51.17267622], [19.5667447, 51.26256975], [19.56394121, 51.35246178], [19.56112321, 51.44235232], [19.55829057, 51.53224135], [19.55544321, 51.62212889], [19.55258102, 51.71201494], [19.5497039, 51.80189948], [19.54681175, 51.89178253], [19.54390446, 51.98166409], [19.54098192, 52.07154414], [19.53804404, 52.16142271], [19.5350907, 52.25129977], [19.5321218, 52.34117535], [19.52913722, 52.43104942], [19.52613686, 52.52092201], [19.5231206, 52.6107931], [19.52008834, 52.70066269], [19.51703995, 52.79053079], [19.51397533, 52.8803974], [19.51089435, 52.97026252], [19.5077969, 53.06012614], [19.50468287, 53.14998827], [19.50155213, 53.23984891], [19.49840456, 53.32970806], [19.49524004, 53.41956571], [19.49205845, 53.50942188],
          [19.48885967, 53.59927655], [19.48564356, 53.68912974], [19.48241001, 53.77898143], [19.47915888, 53.86883163], [19.47589005, 53.95868035], [19.47260338, 54.04852758], [19.46929874, 54.13837332], [19.46597601, 54.22821757], [19.46263504, 54.31806033], [19.4592757, 54.40790161], [19.45589786, 54.4977414], [19.45250137, 54.5875797], [19.44908609, 54.67741651], [19.44565189, 54.76725184], [19.44219862, 54.85708569], [19.43872613, 54.94691805], [19.43523429, 55.03674893]], 1, '#000000'],
          
      [[[19.77571445, 48.74650938], [19.77352396, 48.83644821], [19.7713226, 48.92638556], [19.7691103, 49.01632143], [19.76688698, 49.10625582], [19.76465257, 49.19618872], [19.76240701, 49.28612014], [19.76015022, 49.37605007], [19.75788212, 49.46597852], [19.75560265, 49.55590549], [19.75331173, 49.64583098], [19.75100929, 49.73575498], [19.74869525, 49.8256775], [19.74636954, 49.91559854], [19.74403207, 50.0055181], [19.74168278, 50.09543617], [19.73932158, 50.18535277], [19.73694839, 50.27526788], [19.73456314, 50.36518151], [19.73216574, 50.45509367], [19.72975612, 50.54500434], [19.7273342, 50.63491353], [19.72489988, 50.72482124], [19.72245309, 50.81472748], [19.71999374, 50.90463223], [19.71752176, 50.99453551], [19.71503704, 51.08443731],
          [19.71253951, 51.17433763], [19.71002909, 51.26423647], [19.70750567, 51.35413384], [19.70496918, 51.44402973], [19.70241952, 51.53392414], [19.69985661, 51.62381707], [19.69728035, 51.71370854], [19.69469064, 51.80359852], [19.69208741, 51.89348703], [19.68947055, 51.98337407], [19.68683997, 52.07325963], [19.68419557, 52.16314372], [19.68153725, 52.25302634], [19.67886493, 52.34290748], [19.67617849, 52.43278715], [19.67347785, 52.52266535], [19.6707629, 52.61254208], [19.66803353, 52.70241734], [19.66528966, 52.79229112], [19.66253116, 52.88216344], [19.65975795, 52.97203429], [19.65696991, 53.06190367], [19.65416694, 53.15177158], [19.65134893, 53.24163802], [19.64851577, 53.331503], [19.64566736, 53.42136651], [19.64280357, 53.51122855],
          [19.63992431, 53.60108913], [19.63702945, 53.69094824], [19.63411889, 53.78080589], [19.63119251, 53.87066207], [19.62825018, 53.96051679], [19.62529181, 54.05037004], [19.62231726, 54.14022184], [19.61932641, 54.23007217], [19.61631915, 54.31992104], [19.61329536, 54.40976845], [19.6102549, 54.4996144], [19.60719766, 54.58945889], [19.60412351, 54.67930193], [19.60103232, 54.7691435], [19.59792397, 54.85898362], [19.59479832, 54.94882227], [19.59165524, 55.03865948]], 1, '#909090'],

      [[[19.91171895, 48.74787418], [19.90977168, 48.83781731], [19.90781474, 48.92775898], [19.90584807, 49.01769918], [19.90387161, 49.10763791], [19.90188529, 49.19757518], [19.89988905, 49.28751097], [19.89788283, 49.3774453], [19.89586656, 49.46737816], [19.89384018, 49.55730956], [19.89180362, 49.64723949], [19.88975681, 49.73716795], [19.8876997, 49.82709495], [19.8856322, 49.91702048], [19.88355426, 50.00694454], [19.8814658, 50.09686714], [19.87936676, 50.18678828], [19.87725706, 50.27670795], [19.87513664, 50.36662616], [19.87300542, 50.45654291], [19.87086332, 50.54645819], [19.86871029, 50.63637201], [19.86654625, 50.72628437], [19.86437111, 50.81619527], [19.86218481, 50.90610471], [19.85998727, 50.99601268], [19.85777842, 51.0859192],
          [19.85555818, 51.17582426], [19.85332647, 51.26572785], [19.85108321, 51.35562999], [19.84882832, 51.44553067], [19.84656173, 51.5354299], [19.84428336, 51.62532766], [19.84199311, 51.71522397], [19.83969092, 51.80511882], [19.8373767, 51.89501222], [19.83505036, 51.98490416], [19.83271182, 52.07479465], [19.830361, 52.16468369], [19.82799781, 52.25457127], [19.82562216, 52.3444574], [19.82323397, 52.43434207], [19.82083315, 52.5242253], [19.8184196, 52.61410707], [19.81599324, 52.7039874], [19.81355398, 52.79386627], [19.81110172, 52.8837437], [19.80863638, 52.97361968], [19.80615786, 53.06349421], [19.80366606, 53.15336729], [19.80116088, 53.24323893], [19.79864224, 53.33310912], [19.79611004, 53.42297787], [19.79356417, 53.51284517],
          [19.79100454, 53.60271103], [19.78843105, 53.69257544], [19.78584359, 53.78243842], [19.78324207, 53.87229995], [19.78062638, 53.96216004], [19.77799641, 54.0520187], [19.77535206, 54.14187591], [19.77269323, 54.23173168], [19.7700198, 54.32158602], [19.76733167, 54.41143892], [19.76462873, 54.50129039], [19.76191087, 54.59114042], [19.75917797, 54.68098901], [19.75642992, 54.77083617], [19.75366661, 54.8606819], [19.75088792, 54.95052619], [19.74809374, 55.04036906]], 1, '#909090'],

      [[[20.04773306, 48.74907848], [20.04602906, 48.83902541], [20.04431661, 48.92897089], [20.04259563, 49.01891491], [20.04086609, 49.10885748], [20.03912792, 49.1987986], [20.03738107, 49.28873826], [20.03562549, 49.37867646], [20.03386111, 49.46861322], [20.03208788, 49.55854852], [20.03030575, 49.64848237], [20.02851465, 49.73841476], [20.02671453, 49.82834571], [20.02490532, 49.9182752], [20.02308697, 50.00820325], [20.02125942, 50.09812984], [20.0194226, 50.18805499], [20.01757646, 50.27797869], [20.01572093, 50.36790093], [20.01385596, 50.45782173], [20.01198147, 50.54774109], [20.0100974, 50.63765899], [20.0082037, 50.72757545], [20.00630029, 50.81749046], [20.00438712, 50.90740403], [20.00246411, 50.99731616], [20.00053119, 51.08722683],
          [19.99858831, 51.17713607], [19.99663539, 51.26704386], [19.99467237, 51.35695021], [19.99269917, 51.44685512], [19.99071573, 51.53675859], [19.98872197, 51.62666062], [19.98671783, 51.7165612], [19.98470323, 51.80646035], [19.9826781, 51.89635806], [19.98064237, 51.98625434], [19.97859596, 52.07614917], [19.97653881, 52.16604257], [19.97447083, 52.25593453], [19.97239194, 52.34582506], [19.97030208, 52.43571416], [19.96820116, 52.52560182], [19.96608911, 52.61548804], [19.96396585, 52.70537284], [19.9618313, 52.79525621], [19.95968537, 52.88513814], [19.95752799, 52.97501864], [19.95535907, 53.06489772], [19.95317854, 53.15477537], [19.9509863, 53.24465159], [19.94878228, 53.33452638], [19.94656639, 53.42439975], [19.94433854, 53.5142717],
          [19.94209864, 53.60414222], [19.93984661, 53.69401131], [19.93758236, 53.78387899], [19.9353058, 53.87374524], [19.93301684, 53.96361008], [19.93071539, 54.05347349], [19.92840135, 54.14333549], [19.92607464, 54.23319606], [19.92373515, 54.32305522], [19.9213828, 54.41291297], [19.91901748, 54.5027693], [19.91663911, 54.59262422], [19.91424757, 54.68247772], [19.91184278, 54.77232981], [19.90942463, 54.86218049], [19.90699303, 54.95202976], [19.90454786, 55.04187762]], 1, '#909090'],

      [[[20.18375557, 48.75012227], [20.18229491, 48.84007249], [20.18082699, 48.93002127], [20.17935177, 49.0199686], [20.1778692, 49.1099145], [20.17637924, 49.19985895], [20.17488184, 49.28980196], [20.17337694, 49.37974352], [20.17186451, 49.46968365], [20.1703445, 49.55962234], [20.16881685, 49.64955958], [20.16728151, 49.73949539], [20.16573844, 49.82942976], [20.16418758, 49.91936269], [20.16262888, 50.00929419], [20.16106229, 50.09922424], [20.15948776, 50.18915286], [20.15790524, 50.27908004], [20.15631468, 50.36900579], [20.15471601, 50.45893011], [20.15310918, 50.54885299], [20.15149415, 50.63877443], [20.14987086, 50.72869444], [20.14823925, 50.81861302], [20.14659926, 50.90853017], [20.14495084, 50.99844589], [20.14329393, 51.08836018],
          [20.14162848, 51.17827304], [20.13995442, 51.26818447], [20.1382717, 51.35809447], [20.13658026, 51.44800304], [20.13488003, 51.53791019], [20.13317097, 51.62781591], [20.131453, 51.71772021], [20.12972607, 51.80762308], [20.12799011, 51.89752453], [20.12624506, 51.98742455], [20.12449086, 52.07732315], [20.12272744, 52.16722034], [20.12095474, 52.2571161], [20.1191727, 52.34701044], [20.11738125, 52.43690336], [20.11558032, 52.52679487], [20.11376984, 52.61668495], [20.11194975, 52.70657363], [20.11011999, 52.79646088], [20.10828047, 52.88634672], [20.10643113, 52.97623115], [20.10457191, 53.06611417], [20.10270272, 53.15599577], [20.10082351, 53.24587597], [20.09893419, 53.33575475], [20.09703469, 53.42563213], [20.09512494, 53.51550809],
          [20.09320487, 53.60538265], [20.09127439, 53.69525581], [20.08933344, 53.78512756], [20.08738194, 53.87499791], [20.0854198, 53.96486685], [20.08344696, 54.05473439], [20.08146332, 54.14460053], [20.07946882, 54.23446527], [20.07746337, 54.32432861], [20.07544689, 54.41419056], [20.0734193, 54.5040511], [20.07138051, 54.59391026], [20.06933043, 54.68376801], [20.067269, 54.77362438], [20.06519611, 54.86347935], [20.06311169, 54.95333293], [20.06101564, 55.04318512]], 1, '#909090'],

      [[[20.3197853, 48.75100551], [20.31856801, 48.84095851], [20.31734467, 48.93091008], [20.31611525, 49.02086022], [20.3148797, 49.11080893], [20.31363799, 49.20075621], [20.31239009, 49.29070205], [20.31113594, 49.38064646], [20.3098755, 49.47058944], [20.30860875, 49.56053099], [20.30733563, 49.65047112], [20.3060561, 49.74040981], [20.30477013, 49.83034708], [20.30347767, 49.92028292], [20.30217868, 50.01021733], [20.30087311, 50.10015031], [20.29956092, 50.19008187], [20.29824207, 50.28001201], [20.29691652, 50.36994072], [20.29558421, 50.459868], [20.29424511, 50.54979387], [20.29289917, 50.63971831], [20.29154634, 50.72964133], [20.29018658, 50.81956293], [20.28881983, 50.90948311], [20.28744606, 50.99940187], [20.28606522, 51.08931921],
          [20.28467725, 51.17923513], [20.28328211, 51.26914964], [20.28187976, 51.35906273], [20.28047013, 51.4489744], [20.27905318, 51.53888467], [20.27762887, 51.62879351], [20.27619713, 51.71870095], [20.27475793, 51.80860697], [20.2733112, 51.89851158], [20.2718569, 51.98841478], [20.27039496, 52.07831657], [20.26892535, 52.16821695], [20.26744801, 52.25811593], [20.26596287, 52.3480135], [20.26446989, 52.43790966], [20.26296901, 52.52780442], [20.26146018, 52.61769777], [20.25994334, 52.70758973], [20.25841842, 52.79748028], [20.25688539, 52.88736942], [20.25534417, 52.97725717], [20.2537947, 53.06714352], [20.25223694, 53.15702848], [20.25067082, 53.24691203], [20.24909627, 53.33679419], [20.24751324, 53.42667496], [20.24592167, 53.51655433],
          [20.2443215, 53.60643231], [20.24271265, 53.6963089], [20.24109508, 53.7861841], [20.2394687, 53.8760579], [20.23783347, 53.96593033], [20.23618931, 54.05580136], [20.23453616, 54.14567101], [20.23287395, 54.23553927], [20.23120262, 54.32540615], [20.22952209, 54.41527165], [20.2278323, 54.50513577], [20.22613318, 54.5949985], [20.22442466, 54.68485986], [20.22270667, 54.77471984], [20.22097913, 54.86457845], [20.21924198, 54.95443568], [20.21749513, 55.04429153]], 1, '#909090'],

      [[[20.45582103, 48.75172818], [20.45484715, 48.84168347], [20.45386843, 48.93163733], [20.45288485, 49.02158976], [20.45189637, 49.11154077], [20.45090295, 49.20149035], [20.44990458, 49.29143852], [20.44890121, 49.38138525], [20.44789281, 49.47133057], [20.44687936, 49.56127447], [20.44586082, 49.65121694], [20.44483715, 49.741158], [20.44380832, 49.83109764], [20.4427743, 49.92103585], [20.44173505, 50.01097265], [20.44069054, 50.10090803], [20.43964074, 50.190842], [20.43858561, 50.28077455], [20.43752511, 50.37070568], [20.43645921, 50.4606354], [20.43538788, 50.55056371], [20.43431107, 50.6404906], [20.43322875, 50.73041608], [20.43214088, 50.82034015], [20.43104743, 50.91026281], [20.42994836, 51.00018406], [20.42884363, 51.0901039],
          [20.42773319, 51.18002233], [20.42661703, 51.26993936], [20.42549508, 51.35985497], [20.42436732, 51.44976919], [20.4232337, 51.539682], [20.42209419, 51.6295934], [20.42094874, 51.7195034], [20.41979731, 51.809412], [20.41863987, 51.8993192], [20.41747636, 51.989225], [20.41630675, 52.0791294], [20.415131, 52.1690324], [20.41394906, 52.25893401], [20.41276088, 52.34883422], [20.41156643, 52.43873303], [20.41036566, 52.52863045], [20.40915853, 52.61852648], [20.40794499, 52.70842111], [20.40672499, 52.79831436], [20.40549849, 52.88820621], [20.40426544, 52.97809668], [20.4030258, 53.06798576], [20.40177952, 53.15787345], [20.40052655, 53.24775976], [20.39926684, 53.33764468], [20.39800034, 53.42752822], [20.39672701, 53.51741038],
          [20.39544679, 53.60729116], [20.39415964, 53.69717055], [20.3928655, 53.78704857], [20.39156433, 53.87692521], [20.39025606, 53.96680048], [20.38894066, 54.05667437], [20.38761806, 54.14654689], [20.38628821, 54.23641804], [20.38495106, 54.32628781], [20.38360656, 54.41615622], [20.38225464, 54.50602326], [20.38089526, 54.59588893], [20.37952836, 54.68575323], [20.37815388, 54.77561617], [20.37677176, 54.86547775], [20.37538195, 54.95533796], [20.37398439, 55.04519682]], 1, '#909090'],

      [[[20.59186157, 48.75229028], [20.59113113, 48.84224734], [20.59039707, 48.93220297], [20.58965935, 49.02215719], [20.58891796, 49.11210999], [20.58817287, 49.20206137], [20.58742406, 49.29201134], [20.58667151, 49.38195989], [20.58591518, 49.47190702], [20.58515506, 49.56185274], [20.58439113, 49.65179705], [20.58362335, 49.74173994], [20.58285169, 49.83168142], [20.58207615, 49.92162149], [20.58129668, 50.01156015], [20.58051327, 50.10149739], [20.57972589, 50.19143323], [20.57893451, 50.28136766], [20.57813911, 50.37130068], [20.57733965, 50.46123229], [20.57653612, 50.55116249], [20.57572848, 50.64109129], [20.57491671, 50.73101869], [20.57410078, 50.82094468], [20.57328065, 50.91086927], [20.57245632, 51.00079245], [20.57162773, 51.09071423],
          [20.57079488, 51.18063462], [20.56995771, 51.2705536], [20.56911622, 51.36047118], [20.56827037, 51.45038737], [20.56742012, 51.54030216], [20.56656545, 51.63021556], [20.56570632, 51.72012755], [20.56484272, 51.81003816], [20.5639746, 51.89994737], [20.56310193, 51.98985519], [20.56222469, 52.07976162], [20.56134283, 52.16966666], [20.56045634, 52.25957031], [20.55956517, 52.34947258], [20.55866929, 52.43937345], [20.55776868, 52.52927294], [20.55686329, 52.61917105], [20.55595309, 52.70906777], [20.55503805, 52.79896311], [20.55411814, 52.88885707], [20.55319331, 52.97874965], [20.55226354, 53.06864085], [20.55132878, 53.15853068], [20.55038901, 53.24841912], [20.54944419, 53.33830619], [20.54849427, 53.42819189], [20.54753923, 53.51807622],
          [20.54657903, 53.60795917], [20.54561362, 53.69784075], [20.54464297, 53.78772097], [20.54366704, 53.87759981], [20.5426858, 53.96747729], [20.5416992, 54.05735341], [20.5407072, 54.14722816], [20.53970977, 54.23710155], [20.53870686, 54.32697357], [20.53769843, 54.41684424], [20.53668445, 54.50671355], [20.53566487, 54.5965815], [20.53463964, 54.6864481], [20.53360873, 54.77631334], [20.53257209, 54.86617723], [20.53152968, 54.95603977], [20.53048146, 55.04590095]], 1, '#909090'],

      [[[20.72790571, 48.75269179], [20.72741874, 48.84265011], [20.72692935, 48.93260702], [20.72643753, 49.02256251], [20.72594325, 49.11251659], [20.72544651, 49.20246926], [20.72494729, 49.29242051], [20.72444558, 49.38237035], [20.72394135, 49.47231878], [20.72343459, 49.56226581], [20.72292528, 49.65221142], [20.72241341, 49.74215562], [20.72189897, 49.83209842], [20.72138192, 49.92203981], [20.72086226, 50.01197979], [20.72033998, 50.10191837], [20.71981504, 50.19185554], [20.71928744, 50.28179131], [20.71875715, 50.37172568], [20.71822417, 50.46165864], [20.71768847, 50.55159021], [20.71715002, 50.64152037], [20.71660883, 50.73144913], [20.71606486, 50.82137649], [20.71551809, 50.91130246], [20.71496852, 51.00122703], [20.71441611, 51.0911502],
          [20.71386086, 51.18107197], [20.71330274, 51.27099236], [20.71274172, 51.36091135], [20.7121778, 51.45082894], [20.71161095, 51.54074515], [20.71104116, 51.63065996], [20.71046839, 51.72057339], [20.70989264, 51.81048543], [20.70931387, 51.90039608], [20.70873208, 51.99030534], [20.70814723, 52.08021322], [20.70755931, 52.17011971], [20.7069683, 52.26002483], [20.70637416, 52.34992856], [20.7057769, 52.43983091], [20.70517647, 52.52973188], [20.70457285, 52.61963147], [20.70396604, 52.70952968], [20.70335599, 52.79942652], [20.7027427, 52.88932198], [20.70212613, 52.97921607], [20.70150626, 53.06910879], [20.70088307, 53.15900013], [20.70025653, 53.24889011], [20.69962663, 53.33877871], [20.69899333, 53.42866595], [20.69835662, 53.51855183],
          [20.69771646, 53.60843633], [20.69707283, 53.69831948], [20.69642571, 53.78820126], [20.69577507, 53.87808168], [20.69512089, 53.96796074], [20.69446313, 54.05783844], [20.69380178, 54.14771479], [20.6931368, 54.23758978], [20.69246817, 54.32746342], [20.69179587, 54.4173357], [20.69111986, 54.50720663], [20.69044011, 54.59707621], [20.6897566, 54.68694444], [20.6890693, 54.77681133], [20.68837819, 54.86667687], [20.68768322, 54.95654107], [20.68698438, 55.04640392]], 1, '#909090'],

      [[[20.86395225, 48.7529327], [20.86370876, 48.84289178], [20.86346407, 48.93284945], [20.86321815, 49.0228057], [20.86297101, 49.11276055], [20.86272264, 49.20271399], [20.86247302, 49.29266601], [20.86222216, 49.38261663], [20.86197004, 49.47256584], [20.86171666, 49.56251365], [20.861462, 49.65246005], [20.86120606, 49.74240504], [20.86094883, 49.83234862], [20.86069031, 49.92229081], [20.86043047, 50.01223159], [20.86016933, 50.10217096], [20.85990685, 50.19210894], [20.85964305, 50.28204551], [20.8593779, 50.37198069], [20.8591114, 50.46191446], [20.85884355, 50.55184684], [20.85857432, 50.64177782], [20.85830372, 50.7317074], [20.85803173, 50.82163558], [20.85775834, 50.91156238], [20.85748355, 51.00148777], [20.85720734, 51.09141178],
          [20.85692971, 51.18133439], [20.85665065, 51.27125561], [20.85637013, 51.36117545], [20.85608817, 51.45109389], [20.85580474, 51.54101094], [20.85551984, 51.63092661], [20.85523345, 51.72084089], [20.85494557, 51.81075379], [20.85465618, 51.9006653], [20.85436528, 51.99057543], [20.85407285, 52.08048418], [20.85377888, 52.17039155], [20.85348337, 52.26029754], [20.8531863, 52.35020215], [20.85288766, 52.44010538], [20.85258744, 52.53000724], [20.85228563, 52.61990772], [20.85198221, 52.70980683], [20.85167718, 52.79970457], [20.85137053, 52.88960093], [20.85106224, 52.97949593], [20.8507523, 53.06938955], [20.8504407, 53.15928181], [20.85012743, 53.2491727], [20.84981247, 53.33906223], [20.84949581, 53.4289504], [20.84917745, 53.5188372],
          [20.84885736, 53.60872264], [20.84853554, 53.69860672], [20.84821198, 53.78848944], [20.84788665, 53.87837081], [20.84755955, 53.96825082], [20.84723067, 54.05812947], [20.84689998, 54.14800677], [20.84656749, 54.23788272], [20.84623317, 54.32775733], [20.84589701, 54.41763058], [20.84555899, 54.50750248], [20.84521911, 54.59737304], [20.84487735, 54.68724226], [20.8445337, 54.77711013], [20.84418813, 54.86697666], [20.84384064, 54.95684185], [20.84349121, 55.04670571]], 1, '#909090'],

      [[[21, 48.753013], [21, 48.84297234], [21, 48.93293026], [21, 49.02288677], [21, 49.11284187], [21, 49.20279557], [21, 49.29274785], [21, 49.38269873], [21, 49.4726482], [21, 49.56259626], [21, 49.65254292], [21, 49.74248818], [21, 49.83243203], [21, 49.92237447], [21, 50.01231552], [21, 50.10225516], [21, 50.1921934], [21, 50.28213025], [21, 50.37206569], [21, 50.46199973], [21, 50.55193238], [21, 50.64186363], [21, 50.73179349], [21, 50.82172195], [21, 50.91164902], [21, 51.00157469], [21, 51.09149897],
          [21, 51.18142187], [21, 51.27134337], [21, 51.36126348], [21, 51.4511822], [21, 51.54109954], [21, 51.63101549], [21, 51.72093006], [21, 51.81084325], [21, 51.90075505], [21, 51.99066547], [21, 52.0805745], [21, 52.17048216], [21, 52.26038844], [21, 52.35029335], [21, 52.44019688], [21, 52.53009903], [21, 52.61999981], [21, 52.70989922], [21, 52.79979725], [21, 52.88969392], [21, 52.97958921], [21, 53.06948314], [21, 53.15937571], [21, 53.2492669], [21, 53.33915674], [21, 53.42904521], [21, 53.51893232],
          [21, 53.60881807], [21, 53.69870247], [21, 53.7885855], [21, 53.87846718], [21, 53.96834751], [21, 54.05822648], [21, 54.1481041], [21, 54.23798037], [21, 54.3278553], [21, 54.41772887], [21, 54.5076011], [21, 54.59747199], [21, 54.68734153], [21, 54.77720973], [21, 54.86707659], [21, 54.95694212], [21, 55.0468063]], 1, '#000000'],

      [[[21.13604775, 48.7529327], [21.13629124, 48.84289178], [21.13653593, 48.93284945], [21.13678185, 49.0228057], [21.13702899, 49.11276055], [21.13727736, 49.20271399], [21.13752698, 49.29266601], [21.13777784, 49.38261663], [21.13802996, 49.47256584], [21.13828334, 49.56251365], [21.138538, 49.65246005], [21.13879394, 49.74240504], [21.13905117, 49.83234862], [21.13930969, 49.92229081], [21.13956953, 50.01223159], [21.13983067, 50.10217096], [21.14009315, 50.19210894], [21.14035695, 50.28204551], [21.1406221, 50.37198069], [21.1408886, 50.46191446], [21.14115645, 50.55184684], [21.14142568, 50.64177782], [21.14169628, 50.7317074], [21.14196827, 50.82163558], [21.14224166, 50.91156238], [21.14251645, 51.00148777], [21.14279266, 51.09141178],
          [21.14307029, 51.18133439], [21.14334935, 51.27125561], [21.14362987, 51.36117545], [21.14391183, 51.45109389], [21.14419526, 51.54101094], [21.14448016, 51.63092661], [21.14476655, 51.72084089], [21.14505443, 51.81075379], [21.14534382, 51.9006653], [21.14563472, 51.99057543], [21.14592715, 52.08048418], [21.14622112, 52.17039155], [21.14651663, 52.26029754], [21.1468137, 52.35020215], [21.14711234, 52.44010538], [21.14741256, 52.53000724], [21.14771437, 52.61990772], [21.14801779, 52.70980683], [21.14832282, 52.79970457], [21.14862947, 52.88960093], [21.14893776, 52.97949593], [21.1492477, 53.06938955], [21.1495593, 53.15928181], [21.14987257, 53.2491727], [21.15018753, 53.33906223], [21.15050419, 53.4289504], [21.15082255, 53.5188372],
          [21.15114264, 53.60872264], [21.15146446, 53.69860672], [21.15178802, 53.78848944], [21.15211335, 53.87837081], [21.15244045, 53.96825082], [21.15276933, 54.05812947], [21.15310002, 54.14800677], [21.15343251, 54.23788272], [21.15376683, 54.32775733], [21.15410299, 54.41763058], [21.15444101, 54.50750248], [21.15478089, 54.59737304], [21.15512265, 54.68724226], [21.1554663, 54.77711013], [21.15581187, 54.86697666], [21.15615936, 54.95684185], [21.15650879, 55.04670571]], 1, '#909090'],
        [[[21.27209429, 48.75269179], [21.27258126, 48.84265011], [21.27307065, 48.93260702], [21.27356247, 49.02256251], [21.27405675, 49.11251659], [21.27455349, 49.20246926], [21.27505271, 49.29242051], [21.27555442, 49.38237035], [21.27605865, 49.47231878], [21.27656541, 49.56226581], [21.27707472, 49.65221142], [21.27758659, 49.74215562], [21.27810103, 49.83209842], [21.27861808, 49.92203981], [21.27913774, 50.01197979], [21.27966002, 50.10191837], [21.28018496, 50.19185554], [21.28071256, 50.28179131], [21.28124285, 50.37172568], [21.28177583, 50.46165864], [21.28231153, 50.55159021], [21.28284998, 50.64152037], [21.28339117, 50.73144913], [21.28393514, 50.82137649], [21.28448191, 50.91130246], [21.28503148, 51.00122703], [21.28558389, 51.0911502],
          [21.28613914, 51.18107197], [21.28669726, 51.27099236], [21.28725828, 51.36091135], [21.2878222, 51.45082894], [21.28838905, 51.54074515], [21.28895884, 51.63065996], [21.28953161, 51.72057339], [21.29010736, 51.81048543], [21.29068613, 51.90039608], [21.29126792, 51.99030534], [21.29185277, 52.08021322], [21.29244069, 52.17011971], [21.2930317, 52.26002483], [21.29362584, 52.34992856], [21.2942231, 52.43983091], [21.29482353, 52.52973188], [21.29542715, 52.61963147], [21.29603396, 52.70952968], [21.29664401, 52.79942652], [21.2972573, 52.88932198], [21.29787387, 52.97921607], [21.29849374, 53.06910879], [21.29911693, 53.15900013], [21.29974347, 53.24889011], [21.30037337, 53.33877871], [21.30100667, 53.42866595], [21.30164338, 53.51855183],
          [21.30228354, 53.60843633], [21.30292717, 53.69831948], [21.30357429, 53.78820126], [21.30422493, 53.87808168], [21.30487911, 53.96796074], [21.30553687, 54.05783844], [21.30619822, 54.14771479], [21.3068632, 54.23758978], [21.30753183, 54.32746342], [21.30820413, 54.4173357], [21.30888014, 54.50720663], [21.30955989, 54.59707621], [21.3102434, 54.68694444], [21.3109307, 54.77681133], [21.31162181, 54.86667687], [21.31231678, 54.95654107], [21.31301562, 55.04640392]], 1, '#909090'],
        [[[21.40813843, 48.75229028], [21.40886887, 48.84224734], [21.40960293, 48.93220297], [21.41034065, 49.02215719], [21.41108204, 49.11210999], [21.41182713, 49.20206137], [21.41257594, 49.29201134], [21.41332849, 49.38195989], [21.41408482, 49.47190702], [21.41484494, 49.56185274], [21.41560887, 49.65179705], [21.41637665, 49.74173994], [21.41714831, 49.83168142], [21.41792385, 49.92162149], [21.41870332, 50.01156015], [21.41948673, 50.10149739], [21.42027411, 50.19143323], [21.42106549, 50.28136766], [21.42186089, 50.37130068], [21.42266035, 50.46123229], [21.42346388, 50.55116249], [21.42427152, 50.64109129], [21.42508329, 50.73101869], [21.42589922, 50.82094468], [21.42671935, 50.91086927], [21.42754368, 51.00079245], [21.42837227, 51.09071423],
          [21.42920512, 51.18063462], [21.43004229, 51.2705536], [21.43088378, 51.36047118], [21.43172963, 51.45038737], [21.43257988, 51.54030216], [21.43343455, 51.63021556], [21.43429368, 51.72012755], [21.43515728, 51.81003816], [21.4360254, 51.89994737], [21.43689807, 51.98985519], [21.43777531, 52.07976162], [21.43865717, 52.16966666], [21.43954366, 52.25957031], [21.44043483, 52.34947258], [21.44133071, 52.43937345], [21.44223132, 52.52927294], [21.44313671, 52.61917105], [21.44404691, 52.70906777], [21.44496195, 52.79896311], [21.44588186, 52.88885707], [21.44680669, 52.97874965], [21.44773646, 53.06864085], [21.44867122, 53.15853068], [21.44961099, 53.24841912], [21.45055581, 53.33830619], [21.45150573, 53.42819189], [21.45246077, 53.51807622],
          [21.45342097, 53.60795917], [21.45438638, 53.69784075], [21.45535703, 53.78772097], [21.45633296, 53.87759981], [21.4573142, 53.96747729], [21.4583008, 54.05735341], [21.4592928, 54.14722816], [21.46029023, 54.23710155], [21.46129314, 54.32697357], [21.46230157, 54.41684424], [21.46331555, 54.50671355], [21.46433513, 54.5965815], [21.46536036, 54.6864481], [21.46639127, 54.77631334], [21.46742791, 54.86617723], [21.46847032, 54.95603977], [21.46951854, 55.04590095]], 1, '#909090'],
        [[[21.54417897, 48.75172818], [21.54515285, 48.84168347], [21.54613157, 48.93163733], [21.54711515, 49.02158976], [21.54810363, 49.11154077], [21.54909705, 49.20149035], [21.55009542, 49.29143852], [21.55109879, 49.38138525], [21.55210719, 49.47133057], [21.55312064, 49.56127447], [21.55413918, 49.65121694], [21.55516285, 49.741158], [21.55619168, 49.83109764], [21.5572257, 49.92103585], [21.55826495, 50.01097265], [21.55930946, 50.10090803], [21.56035926, 50.190842], [21.56141439, 50.28077455], [21.56247489, 50.37070568], [21.56354079, 50.4606354], [21.56461212, 50.55056371], [21.56568893, 50.6404906], [21.56677125, 50.73041608], [21.56785912, 50.82034015], [21.56895257, 50.91026281], [21.57005164, 51.00018406], [21.57115637, 51.0901039],
          [21.57226681, 51.18002233], [21.57338297, 51.26993936], [21.57450492, 51.35985497], [21.57563268, 51.44976919], [21.5767663, 51.539682], [21.57790581, 51.6295934], [21.57905126, 51.7195034], [21.58020269, 51.809412], [21.58136013, 51.8993192], [21.58252364, 51.989225], [21.58369325, 52.0791294], [21.584869, 52.1690324], [21.58605094, 52.25893401], [21.58723912, 52.34883422], [21.58843357, 52.43873303], [21.58963434, 52.52863045], [21.59084147, 52.61852648], [21.59205501, 52.70842111], [21.59327501, 52.79831436], [21.59450151, 52.88820621], [21.59573456, 52.97809668], [21.5969742, 53.06798576], [21.59822048, 53.15787345], [21.59947345, 53.24775976], [21.60073316, 53.33764468], [21.60199966, 53.42752822], [21.60327299, 53.51741038],
          [21.60455321, 53.60729116], [21.60584036, 53.69717055], [21.6071345, 53.78704857], [21.60843567, 53.87692521], [21.60974394, 53.96680048], [21.61105934, 54.05667437], [21.61238194, 54.14654689], [21.61371179, 54.23641804], [21.61504894, 54.32628781], [21.61639344, 54.41615622], [21.61774536, 54.50602326], [21.61910474, 54.59588893], [21.62047164, 54.68575323], [21.62184612, 54.77561617], [21.62322824, 54.86547775], [21.62461805, 54.95533796], [21.62601561, 55.04519682]], 1, '#909090'],
        [[[21.6802147, 48.75100551], [21.68143199, 48.84095851], [21.68265533, 48.93091008], [21.68388475, 49.02086022], [21.6851203, 49.11080893], [21.68636201, 49.20075621], [21.68760991, 49.29070205], [21.68886406, 49.38064646], [21.6901245, 49.47058944], [21.69139125, 49.56053099], [21.69266437, 49.65047112], [21.6939439, 49.74040981], [21.69522987, 49.83034708], [21.69652233, 49.92028292], [21.69782132, 50.01021733], [21.69912689, 50.10015031], [21.70043908, 50.19008187], [21.70175793, 50.28001201], [21.70308348, 50.36994072], [21.70441579, 50.459868], [21.70575489, 50.54979387], [21.70710083, 50.63971831], [21.70845366, 50.72964133], [21.70981342, 50.81956293], [21.71118017, 50.90948311], [21.71255394, 50.99940187], [21.71393478, 51.08931921],
          [21.71532275, 51.17923513], [21.71671789, 51.26914964], [21.71812024, 51.35906273], [21.71952987, 51.4489744], [21.72094682, 51.53888467], [21.72237113, 51.62879351], [21.72380287, 51.71870095], [21.72524207, 51.80860697], [21.7266888, 51.89851158], [21.7281431, 51.98841478], [21.72960504, 52.07831657], [21.73107465, 52.16821695], [21.73255199, 52.25811593], [21.73403713, 52.3480135], [21.73553011, 52.43790966], [21.73703099, 52.52780442], [21.73853982, 52.61769777], [21.74005666, 52.70758973], [21.74158158, 52.79748028], [21.74311461, 52.88736942], [21.74465583, 52.97725717], [21.7462053, 53.06714352], [21.74776306, 53.15702848], [21.74932918, 53.24691203], [21.75090373, 53.33679419], [21.75248676, 53.42667496], [21.75407833, 53.51655433],
          [21.7556785, 53.60643231], [21.75728735, 53.6963089], [21.75890492, 53.7861841], [21.7605313, 53.8760579], [21.76216653, 53.96593033], [21.76381069, 54.05580136], [21.76546384, 54.14567101], [21.76712605, 54.23553927], [21.76879738, 54.32540615], [21.77047791, 54.41527165], [21.7721677, 54.50513577], [21.77386682, 54.5949985], [21.77557534, 54.68485986], [21.77729333, 54.77471984], [21.77902087, 54.86457845], [21.78075802, 54.95443568], [21.78250487, 55.04429153]], 1, '#909090'],
        [[[21.81624443, 48.75012227], [21.81770509, 48.84007249], [21.81917301, 48.93002127], [21.82064823, 49.0199686], [21.8221308, 49.1099145], [21.82362076, 49.19985895], [21.82511816, 49.28980196], [21.82662306, 49.37974352], [21.82813549, 49.46968365], [21.8296555, 49.55962234], [21.83118315, 49.64955958], [21.83271849, 49.73949539], [21.83426156, 49.82942976], [21.83581242, 49.91936269], [21.83737112, 50.00929419], [21.83893771, 50.09922424], [21.84051224, 50.18915286], [21.84209476, 50.27908004], [21.84368532, 50.36900579], [21.84528399, 50.45893011], [21.84689082, 50.54885299], [21.84850585, 50.63877443], [21.85012914, 50.72869444], [21.85176075, 50.81861302], [21.85340074, 50.90853017], [21.85504916, 50.99844589], [21.85670607, 51.08836018],
          [21.85837152, 51.17827304], [21.86004558, 51.26818447], [21.8617283, 51.35809447], [21.86341974, 51.44800304], [21.86511997, 51.53791019], [21.86682903, 51.62781591], [21.868547, 51.71772021], [21.87027393, 51.80762308], [21.87200989, 51.89752453], [21.87375494, 51.98742455], [21.87550914, 52.07732315], [21.87727256, 52.16722034], [21.87904526, 52.2571161], [21.8808273, 52.34701044], [21.88261875, 52.43690336], [21.88441968, 52.52679487], [21.88623016, 52.61668495], [21.88805025, 52.70657363], [21.88988001, 52.79646088], [21.89171953, 52.88634672], [21.89356887, 52.97623115], [21.89542809, 53.06611417], [21.89729728, 53.15599577], [21.89917649, 53.24587597], [21.90106581, 53.33575475], [21.90296531, 53.42563213], [21.90487506, 53.51550809],
          [21.90679513, 53.60538265], [21.90872561, 53.69525581], [21.91066656, 53.78512756], [21.91261806, 53.87499791], [21.9145802, 53.96486685], [21.91655304, 54.05473439], [21.91853668, 54.14460053], [21.92053118, 54.23446527], [21.92253663, 54.32432861], [21.92455311, 54.41419056], [21.9265807, 54.5040511], [21.92861949, 54.59391026], [21.93066957, 54.68376801], [21.932731, 54.77362438], [21.93480389, 54.86347935], [21.93688831, 54.95333293], [21.93898436, 55.04318512]], 1, '#909090'],
        [[[21.95226694, 48.74907848], [21.95397094, 48.83902541], [21.95568339, 48.92897089], [21.95740437, 49.01891491], [21.95913391, 49.10885748], [21.96087208, 49.1987986], [21.96261893, 49.28873826], [21.96437451, 49.37867646], [21.96613889, 49.46861322], [21.96791212, 49.55854852], [21.96969425, 49.64848237], [21.97148535, 49.73841476], [21.97328547, 49.82834571], [21.97509468, 49.9182752], [21.97691303, 50.00820325], [21.97874058, 50.09812984], [21.9805774, 50.18805499], [21.98242354, 50.27797869], [21.98427907, 50.36790093], [21.98614404, 50.45782173], [21.98801853, 50.54774109], [21.9899026, 50.63765899], [21.9917963, 50.72757545], [21.99369971, 50.81749046], [21.99561288, 50.90740403], [21.99753589, 50.99731616], [21.99946881, 51.08722683],
          [22.00141169, 51.17713607], [22.00336461, 51.26704386], [22.00532763, 51.35695021], [22.00730083, 51.44685512], [22.00928427, 51.53675859], [22.01127803, 51.62666062], [22.01328217, 51.7165612], [22.01529677, 51.80646035], [22.0173219, 51.89635806], [22.01935763, 51.98625434], [22.02140404, 52.07614917], [22.02346119, 52.16604257], [22.02552917, 52.25593453], [22.02760806, 52.34582506], [22.02969792, 52.43571416], [22.03179884, 52.52560182], [22.03391089, 52.61548804], [22.03603415, 52.70537284], [22.0381687, 52.79525621], [22.04031463, 52.88513814], [22.04247201, 52.97501864], [22.04464093, 53.06489772], [22.04682146, 53.15477537], [22.0490137, 53.24465159], [22.05121772, 53.33452638], [22.05343361, 53.42439975], [22.05566146, 53.5142717],
          [22.05790136, 53.60414222], [22.06015339, 53.69401131], [22.06241764, 53.78387899], [22.0646942, 53.87374524], [22.06698316, 53.96361008], [22.06928461, 54.05347349], [22.07159865, 54.14333549], [22.07392536, 54.23319606], [22.07626485, 54.32305522], [22.0786172, 54.41291297], [22.08098252, 54.5027693], [22.08336089, 54.59262422], [22.08575243, 54.68247772], [22.08815722, 54.77232981], [22.09057537, 54.86218049], [22.09300697, 54.95202976], [22.09545214, 55.04187762]], 1, '#909090'],
        [[[22.08828105, 48.74787418], [22.09022832, 48.83781731], [22.09218526, 48.92775898], [22.09415193, 49.01769918], [22.09612839, 49.10763791], [22.09811471, 49.19757518], [22.10011095, 49.28751097], [22.10211717, 49.3774453], [22.10413344, 49.46737816], [22.10615982, 49.55730956], [22.10819638, 49.64723949], [22.11024319, 49.73716795], [22.1123003, 49.82709495], [22.1143678, 49.91702048], [22.11644574, 50.00694454], [22.1185342, 50.09686714], [22.12063324, 50.18678828], [22.12274294, 50.27670795], [22.12486336, 50.36662616], [22.12699458, 50.45654291], [22.12913668, 50.54645819], [22.13128971, 50.63637201], [22.13345375, 50.72628437], [22.13562889, 50.81619527], [22.13781519, 50.90610471], [22.14001273, 50.99601268], [22.14222158, 51.0859192],
          [22.14444182, 51.17582426], [22.14667353, 51.26572785], [22.14891679, 51.35562999], [22.15117168, 51.44553067], [22.15343827, 51.5354299], [22.15571664, 51.62532766], [22.15800689, 51.71522397], [22.16030908, 51.80511882], [22.1626233, 51.89501222], [22.16494964, 51.98490416], [22.16728818, 52.07479465], [22.169639, 52.16468369], [22.17200219, 52.25457127], [22.17437784, 52.3444574], [22.17676603, 52.43434207], [22.17916685, 52.5242253], [22.1815804, 52.61410707], [22.18400676, 52.7039874], [22.18644602, 52.79386627], [22.18889828, 52.8837437], [22.19136362, 52.97361968], [22.19384214, 53.06349421], [22.19633394, 53.15336729], [22.19883912, 53.24323893], [22.20135776, 53.33310912], [22.20388996, 53.42297787], [22.20643583, 53.51284517],
          [22.20899546, 53.60271103], [22.21156895, 53.69257544], [22.21415641, 53.78243842], [22.21675793, 53.87229995], [22.21937362, 53.96216004], [22.22200359, 54.0520187], [22.22464794, 54.14187591], [22.22730677, 54.23173168], [22.2299802, 54.32158602], [22.23266833, 54.41143892], [22.23537127, 54.50129039], [22.23808913, 54.59114042], [22.24082203, 54.68098901], [22.24357008, 54.77083617], [22.24633339, 54.8606819], [22.24911208, 54.95052619], [22.25190626, 55.04036906]], 1, '#909090'],
        [[[22.22428555, 48.74650938], [22.22647604, 48.83644821], [22.2286774, 48.92638556], [22.2308897, 49.01632143], [22.23311302, 49.10625582], [22.23534743, 49.19618872], [22.23759299, 49.28612014], [22.23984978, 49.37605007], [22.24211788, 49.46597852], [22.24439735, 49.55590549], [22.24668827, 49.64583098], [22.24899071, 49.73575498], [22.25130475, 49.8256775], [22.25363046, 49.91559854], [22.25596793, 50.0055181], [22.25831722, 50.09543617], [22.26067842, 50.18535277], [22.26305161, 50.27526788], [22.26543686, 50.36518151], [22.26783426, 50.45509367], [22.27024388, 50.54500434], [22.2726658, 50.63491353], [22.27510012, 50.72482124], [22.27754691, 50.81472748], [22.28000626, 50.90463223], [22.28247824, 50.99453551], [22.28496296, 51.08443731],
          [22.28746049, 51.17433763], [22.28997091, 51.26423647], [22.29249433, 51.35413384], [22.29503082, 51.44402973], [22.29758048, 51.53392414], [22.30014339, 51.62381707], [22.30271965, 51.71370854], [22.30530936, 51.80359852], [22.30791259, 51.89348703], [22.31052945, 51.98337407], [22.31316003, 52.07325963], [22.31580443, 52.16314372], [22.31846275, 52.25302634], [22.32113507, 52.34290748], [22.32382151, 52.43278715], [22.32652215, 52.52266535], [22.3292371, 52.61254208], [22.33196647, 52.70241734], [22.33471034, 52.79229112], [22.33746884, 52.88216344], [22.34024205, 52.97203429], [22.34303009, 53.06190367], [22.34583306, 53.15177158], [22.34865107, 53.24163802], [22.35148423, 53.331503], [22.35433264, 53.42136651], [22.35719643, 53.51122855],
          [22.36007569, 53.60108913], [22.36297055, 53.69094824], [22.36588111, 53.78080589], [22.36880749, 53.87066207], [22.37174982, 53.96051679], [22.37470819, 54.05037004], [22.37768274, 54.14022184], [22.38067359, 54.23007217], [22.38368085, 54.31992104], [22.38670464, 54.40976845], [22.3897451, 54.4996144], [22.39280234, 54.58945889], [22.39587649, 54.67930193], [22.39896768, 54.7691435], [22.40207603, 54.85898362], [22.40520168, 54.94882227], [22.40834476, 55.03865948]], 1, '#909090'],
        [[[22.36027925, 48.74498411], [22.36271288, 48.83491814], [22.36515859, 48.92485067], [22.36761646, 49.0147817], [22.37008656, 49.10471122], [22.37256899, 49.19463925], [22.3750638, 49.28456577], [22.3775711, 49.3744908], [22.38009094, 49.46441432], [22.38262343, 49.55433634], [22.38516863, 49.64425686], [22.38772663, 49.73417588], [22.39029752, 49.8240934], [22.39288138, 49.91400942], [22.39547829, 50.00392394], [22.39808835, 50.09383696], [22.40071162, 50.18374848], [22.40334822, 50.2736585], [22.40599821, 50.36356701], [22.4086617, 50.45347403], [22.41133877, 50.54337955], [22.41402951, 50.63328357], [22.41673402, 50.7231861], [22.41945238, 50.81308712], [22.42218468, 50.90298664], [22.42493104, 50.99288466], [22.42769153, 51.08278119],
          [22.43046625, 51.17267622], [22.4332553, 51.26256975], [22.43605879, 51.35246178], [22.43887679, 51.44235232], [22.44170943, 51.53224135], [22.44455679, 51.62212889], [22.44741898, 51.71201494], [22.4502961, 51.80189948], [22.45318825, 51.89178253], [22.45609554, 51.98166409], [22.45901808, 52.07154414], [22.46195596, 52.16142271], [22.4649093, 52.25129977], [22.4678782, 52.34117535], [22.47086278, 52.43104942], [22.47386314, 52.52092201], [22.4768794, 52.6107931], [22.47991166, 52.70066269], [22.48296005, 52.79053079], [22.48602467, 52.8803974], [22.48910565, 52.97026252], [22.4922031, 53.06012614], [22.49531713, 53.14998827], [22.49844787, 53.23984891], [22.50159544, 53.32970806], [22.50475996, 53.41956571], [22.50794155, 53.50942188],
          [22.51114033, 53.59927655], [22.51435644, 53.68912974], [22.51758999, 53.77898143], [22.52084112, 53.86883163], [22.52410995, 53.95868035], [22.52739662, 54.04852758], [22.53070126, 54.13837332], [22.53402399, 54.22821757], [22.53736496, 54.31806033], [22.5407243, 54.40790161], [22.54410214, 54.4977414], [22.54749863, 54.5875797], [22.55091391, 54.67741651], [22.55434811, 54.76725184], [22.55780138, 54.85708569], [22.56127387, 54.94691805], [22.56476571, 55.03674893]], 1, '#000000'],
        [[[22.49626094, 48.74329841], [22.49893763, 48.83322713], [22.50162762, 48.92315433], [22.50433098, 49.01308001], [22.50704779, 49.10300416], [22.50977815, 49.1929268], [22.51252214, 49.28284792], [22.51527985, 49.37276752], [22.51805137, 49.46268559], [22.52083679, 49.55260215], [22.52363619, 49.64251718], [22.52644967, 49.73243069], [22.52927733, 49.82234269], [22.53211924, 49.91225316], [22.53497552, 50.00216211], [22.53784624, 50.09206954], [22.54073151, 50.18197545], [22.54363142, 50.27187984], [22.54654608, 50.36178271], [22.54947557, 50.45168405], [22.55241999, 50.54158388], [22.55537945, 50.63148218], [22.55835405, 50.72137896], [22.56134389, 50.81127423], [22.56434907, 50.90116797], [22.56736969, 50.99106019], [22.57040586, 51.08095089],
          [22.57345768, 51.17084007], [22.57652527, 51.26072773], [22.57960872, 51.35061386], [22.58270814, 51.44049848], [22.58582366, 51.53038158], [22.58895536, 51.62026316], [22.59210338, 51.71014321], [22.59526781, 51.80002175], [22.59844878, 51.88989876], [22.60164639, 51.97977426], [22.60486077, 52.06964823], [22.60809202, 52.15952069], [22.61134028, 52.24939162], [22.61460565, 52.33926104], [22.61788826, 52.42912893], [22.62118823, 52.51899531], [22.62450568, 52.60886017], [22.62784073, 52.69872351], [22.63119352, 52.78858532], [22.63456416, 52.87844562], [22.63795278, 52.9683044], [22.64135952, 53.05816167], [22.6447845, 53.14801741], [22.64822785, 53.23787163], [22.6516897, 53.32772434], [22.6551702, 53.41757553], [22.65866947, 53.5074252],
          [22.66218765, 53.59727335], [22.66572488, 53.68711998], [22.6692813, 53.7769651], [22.67285704, 53.8668087], [22.67645226, 53.95665078], [22.68006708, 54.04649134], [22.68370167, 54.13633039], [22.68735616, 54.22616792], [22.6910307, 54.31600394], [22.69472545, 54.40583843], [22.69844054, 54.49567141], [22.70217614, 54.58550288], [22.7059324, 54.67533282], [22.70970947, 54.76516126], [22.71350752, 54.85498817], [22.71732669, 54.94481357], [22.72116715, 55.03463746]], 1, '#909090'],
        [[[22.63222942, 48.74145232], [22.6351491, 48.83137522], [22.63808328, 48.92129658], [22.64103204, 49.0112164], [22.64399547, 49.10113468], [22.64697368, 49.19105142], [22.64996676, 49.28096661], [22.6529748, 49.37088027], [22.6559979, 49.46079238], [22.65903616, 49.55070295], [22.66208967, 49.64061197], [22.66515854, 49.73051946], [22.66824287, 49.8204254], [22.67134275, 49.91032979], [22.67445829, 50.00023265], [22.67758959, 50.09013396], [22.68073675, 50.18003372], [22.68389989, 50.26993195], [22.6870791, 50.35982863], [22.69027449, 50.44972376], [22.69348618, 50.53961735], [22.69671426, 50.62950939], [22.69995885, 50.71939989], [22.70322007, 50.80928885], [22.70649801, 50.89917626], [22.70979279, 50.98906213], [22.71310454, 51.07894645],
          [22.71643336, 51.16882922], [22.71977936, 51.25871045], [22.72314267, 51.34859013], [22.72652341, 51.43846827], [22.72992169, 51.52834486], [22.73333763, 51.61821991], [22.73677135, 51.70809341], [22.74022299, 51.79796536], [22.74369265, 51.88783577], [22.74718047, 51.97770463], [22.75068658, 52.06757194], [22.75421109, 52.15743771], [22.75775415, 52.24730193], [22.76131587, 52.3371646], [22.76489639, 52.42702573], [22.76849584, 52.51688531], [22.77211436, 52.60674334], [22.77575208, 52.69659983], [22.77940913, 52.78645477], [22.78308566, 52.87630816], [22.7867818, 52.96616], [22.7904977, 53.0560103], [22.79423349, 53.14585905], [22.79798932, 53.23570625], [22.80176533, 53.3255519], [22.80556167, 53.41539601], [22.80937848, 53.50523857],
          [22.81321592, 53.59507957], [22.81707414, 53.68491904], [22.82095327, 53.77475695], [22.82485349, 53.86459331], [22.82877495, 53.95442813], [22.83271779, 54.0442614], [22.83668218, 54.13409312], [22.84066828, 54.22392329], [22.84467625, 54.31375191], [22.84870624, 54.40357898], [22.85275844, 54.49340451], [22.856833, 54.58322848], [22.86093008, 54.67305091], [22.86504987, 54.76287179], [22.86919252, 54.85269112], [22.87335822, 54.94250889], [22.87754714, 55.03232512]], 1, '#909090'],
        [[[22.76818351, 48.73944587], [22.77134608, 48.82936245], [22.77452435, 48.91927747], [22.77771842, 49.00919092], [22.78092838, 49.09910281], [22.78415435, 49.18901314], [22.78739642, 49.2789219], [22.79065469, 49.36882909], [22.79392928, 49.45873472], [22.79722028, 49.54863878], [22.8005278, 49.63854128], [22.80385196, 49.72844221], [22.80719285, 49.81834157], [22.8105506, 49.90823937], [22.8139253, 49.9981356], [22.81731707, 50.08803025], [22.82072603, 50.17792335], [22.82415228, 50.26781487], [22.82759594, 50.35770482], [22.83105713, 50.4475932], [22.83453597, 50.53748002], [22.83803256, 50.62736526], [22.84154704, 50.71724893], [22.84507951, 50.80713103], [22.8486301, 50.89701156], [22.85219894, 50.98689052], [22.85578615, 51.07676791],
          [22.85939184, 51.16664372], [22.86301615, 51.25651796], [22.8666592, 51.34639063], [22.87032113, 51.43626173], [22.87400205, 51.52613125], [22.87770211, 51.6159992], [22.88142142, 51.70586557], [22.88516013, 51.79573037], [22.88891837, 51.8855936], [22.89269628, 51.97545525], [22.89649398, 52.06531532], [22.90031162, 52.15517382], [22.90414934, 52.24503075], [22.90800728, 52.3348861], [22.91188558, 52.42473987], [22.91578439, 52.51459206], [22.91970384, 52.60444268], [22.92364409, 52.69429172], [22.92760527, 52.78413918], [22.93158755, 52.87398506], [22.93559107, 52.96382937], [22.93961599, 53.05367209], [22.94366245, 53.14351324], [22.94773061, 53.23335281], [22.95182063, 53.3231908], [22.95593266, 53.41302721], [22.96006687, 53.50286203],
          [22.96422342, 53.59269528], [22.96840247, 53.68252695], [22.97260417, 53.77235703], [22.97682871, 53.86218554], [22.98107625, 53.95201246], [22.98534695, 54.0418378], [22.98964098, 54.13166155], [22.99395853, 54.22148373], [22.99829976, 54.31130432], [23.00266485, 54.40112332], [23.00705397, 54.49094074], [23.01146732, 54.58075658], [23.01590506, 54.67057084], [23.02036739, 54.7603835], [23.02485449, 54.85019459], [23.02936654, 54.94000408], [23.03390373, 55.02981199]], 1, '#909090'],
        [[[22.90412199, 48.73727911], [22.90752736, 48.82718886], [22.91094963, 48.91709703], [22.9143889, 49.00700361], [22.9178453, 49.0969086], [22.92131891, 49.186812], [22.92480987, 49.27671381], [22.92831827, 49.36661403], [22.93184423, 49.45651266], [22.93538787, 49.5464097], [22.9389493, 49.63630515], [22.94252863, 49.726199], [22.94612599, 49.81609126], [22.94974148, 49.90598193], [22.95337524, 49.995871], [22.95702737, 50.08575848], [22.960698, 50.17564436], [22.96438726, 50.26552865], [22.96809526, 50.35541134], [22.97182213, 50.44529243], [22.97556799, 50.53517193], [22.97933298, 50.62504982], [22.98311722, 50.71492612], [22.98692083, 50.80480082], [22.99074396, 50.89467392], [22.99458672, 50.98454542], [22.99844926, 51.07441532],
          [23.00233171, 51.16428362], [23.0062342, 51.25415032], [23.01015687, 51.34401541], [23.01409985, 51.43387891], [23.01806329, 51.5237408], [23.02204733, 51.61360108], [23.0260521, 51.70345976], [23.03007775, 51.79331684], [23.03412443, 51.88317231], [23.03819228, 51.97302617], [23.04228145, 52.06287843], [23.04639208, 52.15272909], [23.05052433, 52.24257813], [23.05467834, 52.33242557], [23.05885428, 52.42227139], [23.06305228, 52.51211561], [23.06727252, 52.60195822], [23.07151515, 52.69179922], [23.07578032, 52.78163861], [23.0800682, 52.87147639], [23.08437895, 52.96131255], [23.08871273, 53.0511471], [23.0930697, 53.14098004], [23.09745004, 53.23081137], [23.10185391, 53.32064108], [23.10628148, 53.41046918], [23.11073293, 53.50029566],
          [23.11520842, 53.59012053], [23.11970813, 53.67994378], [23.12423224, 53.76976541], [23.12878093, 53.85958543], [23.13335438, 53.94940382], [23.13795277, 54.0392206], [23.14257627, 54.12903576], [23.14722509, 54.2188493], [23.15189941, 54.30866122], [23.15659941, 54.39847151], [23.16132529, 54.48828019], [23.16607724, 54.57808724], [23.17085546, 54.66789267], [23.17566015, 54.75769647], [23.18049149, 54.84749865], [23.18534971, 54.9372992], [23.19023499, 55.02709813]], 1, '#909090'],
        [[[23.04004369, 48.73495208], [23.04369174, 48.82485451], [23.0473579, 48.91475532], [23.05104227, 49.00465452], [23.05474498, 49.0945521], [23.05846614, 49.18444806], [23.06220587, 49.27434241], [23.06596429, 49.36423514], [23.06974152, 49.45412626], [23.07353768, 49.54401575], [23.07735289, 49.63390363], [23.08118729, 49.72378988], [23.08504099, 49.81367451], [23.08891411, 49.90355753], [23.0928068, 49.99343891], [23.09671917, 50.08331868], [23.10065136, 50.17319682], [23.10460349, 50.26307334], [23.10857571, 50.35294823], [23.11256813, 50.44282149], [23.1165809, 50.53269313], [23.12061415, 50.62256314], [23.12466802, 50.71243152], [23.12874265, 50.80229828], [23.13283817, 50.8921634], [23.13695473, 50.98202689], [23.14109247, 51.07188875],
          [23.14525154, 51.16174898], [23.14943207, 51.25160757], [23.15363421, 51.34146454], [23.15785812, 51.43131986], [23.16210393, 51.52117356], [23.16637181, 51.61102561], [23.17066189, 51.70087603], [23.17497435, 51.79072481], [23.17930932, 51.88057196], [23.18366696, 51.97041746], [23.18804744, 52.06026133], [23.19245091, 52.15010355], [23.19687754, 52.23994414], [23.20132748, 52.32978308], [23.20580089, 52.41962038], [23.21029795, 52.50945603], [23.21481882, 52.59929004], [23.21936367, 52.68912241], [23.22393267, 52.77895313], [23.22852598, 52.8687822], [23.23314379, 52.95860962], [23.23778627, 53.0484354], [23.24245359, 53.13825953], [23.24714594, 53.228082], [23.25186349, 53.31790283], [23.25660643, 53.407722], [23.26137494, 53.49753952],
          [23.2661692, 53.58735539], [23.2709894, 53.6771696], [23.27583574, 53.76698216], [23.2807084, 53.85679306], [23.28560758, 53.9466023], [23.29053346, 54.03640989], [23.29548626, 54.12621581], [23.30046616, 54.21602008], [23.30547337, 54.30582268], [23.3105081, 54.39562362], [23.31557054, 54.4854229], [23.3206609, 54.57522052], [23.3257794, 54.66501647], [23.33092624, 54.75481076], [23.33610164, 54.84460338], [23.34130581, 54.93439433], [23.34653897, 55.02418361]], 1, '#909090'],
        [[[23.1759474, 48.73246484], [23.17983802, 48.82235943], [23.18374795, 48.91225238], [23.18767731, 49.00214369], [23.19162621, 49.09203335], [23.1955948, 49.18192137], [23.19958318, 49.27180775], [23.2035915, 49.36169247], [23.20761987, 49.45157555], [23.21166843, 49.54145699], [23.21573731, 49.63133677], [23.21982664, 49.7212149], [23.22393655, 49.81109138], [23.22806719, 49.90096621], [23.23221868, 49.99083939], [23.23639116, 50.08071092], [23.24058477, 50.17058078], [23.24479965, 50.260449], [23.24903594, 50.35031555], [23.25329378, 50.44018045], [23.25757332, 50.53004369], [23.2618747, 50.61990527], [23.26619807, 50.70976519], [23.27054357, 50.79962345], [23.27491135, 50.88948005], [23.27930156, 50.97933498], [23.28371436, 51.06918825],
          [23.2881499, 51.15903985], [23.29260832, 51.24888979], [23.2970898, 51.33873806], [23.30159447, 51.42858466], [23.30612251, 51.51842959], [23.31067408, 51.60827285], [23.31524933, 51.69811444], [23.31984842, 51.78795436], [23.32447153, 51.87779261], [23.32911882, 51.96762918], [23.33379045, 52.05746407], [23.3384866, 52.14729729], [23.34320744, 52.23712883], [23.34795314, 52.3269587], [23.35272387, 52.41678688], [23.35751981, 52.50661338], [23.36234114, 52.5964382], [23.36718804, 52.68626134], [23.37206069, 52.77608279], [23.37695927, 52.86590256], [23.38188397, 52.95572065], [23.38683497, 53.04553704], [23.39181246, 53.13535175], [23.39681664, 53.22516477], [23.40184769, 53.3149761], [23.40690581, 53.40478573], [23.41199119, 53.49459368],
          [23.41710403, 53.58439992], [23.42224454, 53.67420448], [23.42741291, 53.76400734], [23.43260935, 53.8538085], [23.43783406, 53.94360796], [23.44308725, 54.03340572], [23.44836914, 54.12320178], [23.45367992, 54.21299613], [23.45901983, 54.30278879], [23.46438907, 54.39257973], [23.46978786, 54.48236897], [23.47521643, 54.57215651], [23.48067499, 54.66194233], [23.48616377, 54.75172644], [23.49168301, 54.84150885], [23.49723292, 54.93128953], [23.50281375, 55.02106851]], 1, '#909090'],
        [[[23.31183192, 48.72981744], [23.315965, 48.8197037], [23.32011858, 48.90958828], [23.32429279, 48.99947119], [23.32848777, 49.08935242], [23.33270365, 49.17923199], [23.33694056, 49.26910987], [23.34119865, 49.35898608], [23.34547803, 49.44886061], [23.34977886, 49.53873346], [23.35410127, 49.62860464], [23.3584454, 49.71847412], [23.3628114, 49.80834193], [23.36719941, 49.89820805], [23.37160956, 49.98807249], [23.37604201, 50.07793524], [23.38049691, 50.16779631], [23.3849744, 50.25765568], [23.38947462, 50.34751337], [23.39399774, 50.43736936], [23.39854391, 50.52722367], [23.40311327, 50.61707628], [23.40770598, 50.70692719], [23.4123222, 50.79677641], [23.41696209, 50.88662393], [23.42162581, 50.97646976], [23.42631351, 51.06631388],
          [23.43102537, 51.1561563], [23.43576153, 51.24599703], [23.44052218, 51.33583604], [23.44530747, 51.42567336], [23.45011757, 51.51550897], [23.45495266, 51.60534287], [23.45981291, 51.69517506], [23.46469848, 51.78500555], [23.46960956, 51.87483432], [23.47454632, 51.96466138], [23.47950894, 52.05448673], [23.4844976, 52.14431037], [23.48951248, 52.23413229], [23.49455376, 52.32395249], [23.49962163, 52.41377097], [23.50471628, 52.50358773], [23.5098379, 52.59340277], [23.51498666, 52.68321609], [23.52016278, 52.77302768], [23.52536644, 52.86283755], [23.53059784, 52.9526457], [23.53585718, 53.04245211], [23.54114465, 53.13225679], [23.54646046, 53.22205975], [23.55180482, 53.31186097], [23.55717792, 53.40166045], [23.56257998, 53.4914582],
          [23.56801121, 53.58125422], [23.57347181, 53.67104849], [23.57896201, 53.76084103], [23.58448202, 53.85063182], [23.59003206, 53.94042087], [23.59561235, 54.03020818], [23.60122311, 54.11999374], [23.60686457, 54.20977755], [23.61253695, 54.29955961], [23.61824049, 54.38933992], [23.62397541, 54.47911848], [23.62974196, 54.56889528], [23.63554036, 54.65867033], [23.64137086, 54.74844362], [23.6472337, 54.83821514], [23.65312912, 54.92798491], [23.65905737, 55.01775292]], 1, '#909090'],
        [[[23.44769608, 48.72700994], [23.45207147, 48.81688735], [23.45646857, 48.90676306], [23.46088751, 48.99663707], [23.46532843, 49.08650937], [23.46979148, 49.17637996], [23.47427678, 49.26624885], [23.47878449, 49.35611603], [23.48331475, 49.44598149], [23.48786771, 49.53584524], [23.49244351, 49.62570728], [23.49704231, 49.71556761], [23.50166424, 49.80542622], [23.50630947, 49.89528311], [23.51097815, 49.98513828], [23.51567043, 50.07499173], [23.52038646, 50.16484346], [23.5251264, 50.25469346], [23.52989041, 50.34454174], [23.53467866, 50.43438829], [23.53949129, 50.52423312], [23.54432848, 50.61407622], [23.54919039, 50.70391758], [23.55407717, 50.79375722], [23.55898901, 50.88359512], [23.56392607, 50.97343128], [23.56888852, 51.06326571],
          [23.57387652, 51.1530984], [23.57889026, 51.24292935], [23.58392991, 51.33275856], [23.58899565, 51.42258603], [23.59408765, 51.51241175], [23.59920609, 51.60223573], [23.60435116, 51.69205796], [23.60952304, 51.78187844], [23.61472192, 51.87169718], [23.61994797, 51.96151416], [23.62520139, 52.05132938], [23.63048238, 52.14114285], [23.63579111, 52.23095457], [23.6411278, 52.32076453], [23.64649262, 52.41057272], [23.65188579, 52.50037916], [23.65730749, 52.59018383], [23.66275794, 52.67998673], [23.66823733, 52.76978787], [23.67374588, 52.85958725], [23.67928378, 52.94938485], [23.68485125, 53.03918068], [23.6904485, 53.12897473], [23.69607574, 53.21876701], [23.7017332, 53.30855752], [23.70742108, 53.39834624], [23.71313961, 53.48813319],
          [23.718889, 53.57791835], [23.7246695, 53.66770172], [23.73048131, 53.75748331], [23.73632467, 53.84726312], [23.74219982, 53.93704113], [23.74810698, 54.02681735], [23.75404639, 54.11659177], [23.76001829, 54.2063644], [23.76602292, 54.29613524], [23.77206052, 54.38590427], [23.77813134, 54.4756715], [23.78423563, 54.56543692], [23.79037363, 54.65520054], [23.79654561, 54.74496236], [23.80275181, 54.83472236], [23.80899249, 54.92448055], [23.81526792, 55.01423692]], 1, '#909090'],
        [[[23.58353866, 48.72404239], [23.58815624, 48.81391046], [23.59279673, 48.9037768], [23.59746025, 48.99364139], [23.60214697, 49.08350425], [23.60685704, 49.17336536], [23.61159059, 49.26322474], [23.61634778, 49.35308237], [23.62112878, 49.44293825], [23.62593372, 49.53279239], [23.63076276, 49.62264478], [23.63561607, 49.71249542], [23.64049379, 49.8023443], [23.6453961, 49.89219144], [23.65032314, 49.98203681], [23.65527508, 50.07188043], [23.66025209, 50.1617223], [23.66525434, 50.2515624], [23.67028198, 50.34140074], [23.67533518, 50.43123731], [23.68041413, 50.52107212], [23.68551898, 50.61090516], [23.69064991, 50.70073644], [23.69580709, 50.79056594], [23.70099071, 50.88039367], [23.70620094, 50.97021963], [23.71143796, 51.06004381],
          [23.71670194, 51.14986622], [23.72199309, 51.23968684], [23.72731156, 51.32950569], [23.73265757, 51.41932275], [23.73803128, 51.50913802], [23.7434329, 51.59895151], [23.74886261, 51.68876321], [23.75432061, 51.77857313], [23.75980709, 51.86838124], [23.76532225, 51.95818757], [23.77086629, 52.0479921], [23.7764394, 52.13779483], [23.7820418, 52.22759576], [23.78767369, 52.31739489], [23.79333527, 52.40719222], [23.79902675, 52.49698774], [23.80474835, 52.58678145], [23.81050027, 52.67657335], [23.81628273, 52.76636344], [23.82209595, 52.85615172], [23.82794015, 52.94593818], [23.83381554, 53.03572283], [23.83972236, 53.12550565], [23.84566082, 53.21528665], [23.85163115, 53.30506583], [23.85763359, 53.39484318], [23.86366836, 53.48461871],
          [23.86973571, 53.5743924], [23.87583586, 53.66416426], [23.88196906, 53.75393428], [23.88813554, 53.84370247], [23.89433556, 53.93346881], [23.90056935, 54.02323332], [23.90683718, 54.11299598], [23.91313928, 54.20275679], [23.91947591, 54.29251576], [23.92584733, 54.38227287], [23.9322538, 54.47202813], [23.93869558, 54.56178153], [23.94517294, 54.65153308], [23.95168613, 54.74128276], [23.95823543, 54.83103058], [23.96482112, 54.92077653], [23.97144346, 55.01052061], [23.97144346, 55.01052061]], 1, '#909090'],
        [[[23.7193585, 48.72091487], [23.72421812, 48.81077309], [23.72910183, 48.90062954], [23.7340098, 48.99048422], [23.73894217, 49.08033713], [23.7438991, 49.17018826], [23.74888076, 49.26003761], [23.75388728, 49.34988518], [23.75891885, 49.43973097], [23.76397562, 49.52957497], [23.76905775, 49.61941719], [23.77416541, 49.70925762], [23.77929876, 49.79909626], [23.78445798, 49.88893311], [23.78964323, 49.97876817], [23.79485468, 50.06860143], [23.8000925, 50.1584329], [23.80535688, 50.24826256], [23.81064797, 50.33809043], [23.81596597, 50.42791649], [23.82131105, 50.51774074], [23.82668339, 50.60756319], [23.83208317, 50.69738383], [23.83751058, 50.78720266], [23.8429658, 50.87701968], [23.84844902, 50.96683488], [23.85396043, 51.05664826],
          [23.85950021, 51.14645983], [23.86506857, 51.23626957], [23.87066569, 51.32607749], [23.87629177, 51.41588359], [23.88194701, 51.50568786], [23.88763161, 51.59549029], [23.89334577, 51.6852909], [23.89908969, 51.77508967], [23.90486358, 51.86488661], [23.91066765, 51.9546817], [23.9165021, 52.04447496], [23.92236715, 52.13426637], [23.92826301, 52.22405594], [23.93418989, 52.31384366], [23.94014802, 52.40362953], [23.94613761, 52.49341355], [23.95215888, 52.58319572], [23.95821206, 52.67297603], [23.96429738, 52.76275448], [23.97041505, 52.85253106], [23.97656532, 52.94230579], [23.98274841, 53.03207865], [23.98896457, 53.12184963], [23.99521402, 53.21161875], [24, 53.28004159]], 1, '#000000'],
        [[[23.90732721, 49.61602459], [23.91268906, 49.70585429], [23.91807787, 49.79568217], [23.92349383, 49.88550821], [23.92893711, 49.97533242], [23.9344079, 50.0651548], [23.93990636, 50.15497533], [23.94543269, 50.24479403], [23.95098707, 50.33461089], [23.95656968, 50.4244259], [23.96218072, 50.51423906], [23.96782037, 50.60405038], [23.97348882, 50.69385984], [23.97918626, 50.78366745], [23.9849129, 50.87347321], [23.99066892, 50.9632771], [23.99645452, 51.05307914], [24, 51.10788284]], 1, '#909090'],
    // strefa 35, linie pionowe
        [[[24.09267279, 49.61602459], [24.08731094, 49.70585429], [24.08192213, 49.79568217], [24.07650617, 49.88550821], [24.07106289, 49.97533242], [24.0655921, 50.0651548], [24.06009364, 50.15497533], [24.05456731, 50.24479403], [24.04901293, 50.33461089], [24.04343032, 50.4244259], [24.03781928, 50.51423906], [24.03217963, 50.60405038], [24.02651118, 50.69385984], [24.02081374, 50.78366745], [24.0150871, 50.87347321], [24.00933108, 50.9632771], [24.00354548, 51.05307914], [24, 51.10788284]], 1, '#909090'],
        [[[24.23094225, 49.61941719], [24.22583459, 49.70925762], [24.22070124, 49.79909626], [24.21554202, 49.88893311], [24.21035677, 49.97876817], [24.20514532, 50.06860143], [24.1999075, 50.1584329], [24.19464312, 50.24826256], [24.18935203, 50.33809043], [24.18403403, 50.42791649], [24.17868895, 50.51774074], [24.17331661, 50.60756319], [24.16791683, 50.69738383], [24.16248942, 50.78720266], [24.1570342, 50.87701968], [24.15155098, 50.96683488], [24.14603957, 51.05664826], [24.14049979, 51.14645983], [24.13493143, 51.23626957], [24.12933431, 51.32607749], [24.12370823, 51.41588359], [24.12370823, 51.41588359]], 1, '#000000']
    ];
 
  return polyLayer;
}


export function lat2UTM(lat_dec,lon_dec) {
    let lat, lon, strefa, LongLtr0, LatLtr0, letterE, letterN, digraLON, digraLAT, litek1, litek2, H11, lit;
    //let Nx, Ex
	var system = 0;
	var tab_a  = [6378137, 6378135, 6378388, 6377397.2, 6378160, 6378245, 6378206.4, 6378388, 6378249.1, 6378206.4, 6377563.4, 6377276.3];
	var tab_b = [6356752.314, 6356750.5, 6356911.9, 6356079, 6356774.7, 6356863, 6356583.8, 6356911.9, 6356514.9, 6356583.8, 6356256.9, 6356075.4];
	var a = tab_a[system];
	var b = tab_b[system];
	//var f = (a - b) / a;
	//var f_1 = 1 / f;
	//var rm = Math.pow((a * b), (1 / 2));
	var k0 = 0.9996;
	var e = Math.sqrt(1 - Math.pow((b / a), 2));
	var e2 = e * e / (1 - e * e);
	//e1sq=0.006738531;
	var n = (a - b) / (a + b);

	if (lat_dec>0) {
		lat = "N";
	} else {
		lat = "S";
	}
	if (lon_dec>0) {
		lon = "E";
	} else {
		lon = "W";
	}

	var lat_r = lat_dec * Math.PI / 180;
	//var rho = a * (1 - e * e) / (Math.pow(1 - Math.pow(e * Math.sin(lat_r), 2), (3 / 2)));
	var nu = a / (Math.pow(1 - Math.pow(e * Math.sin(lat_r), 2) , (1 / 2)));
	var E0 = (315 * a * Math.pow(n, 4) / 51) * (1 - n);
	var D0 = (35 * a * Math.pow(n, 3) / 48) * (1 - n + 11 * n * n / 16);
	var C0 = (15 * a * n * n / 16) * (1 - n + (3 * n * n / 4) * (1 - n));
	var B0 = (3 * a * n / 2) * (1 - n - (7 * n * n / 8) * (1 - n) + 55 * Math.pow(n, 4) / 64);
	var A0 = a * (1 - n + (5 * n * n / 4) * (1 - n) + (81 * Math.pow(n, 4) / 64) * (1 - n));
	var s = A0 * lat_r - B0 * Math.sin(2 * lat_r) + C0 * Math.sin(4 * lat_r) - D0 * Math.sin(6 * lat_r) + E0 * Math.sin(8 * lat_r);

	if (lon === "W") {
		H11 = Math.floor((180 + lon_dec) / 6) + 1;
	} else {
		H11 = Math.floor(lon_dec / 6) + 31;
	}

	var ZONE = H11;
	var ZONE_CM = 6 * H11 - 183;
	var delta_lon = lon_dec - ZONE_CM;

	if (lat === "N") {
		if (lat_dec >=84) {
			 lit = "Z";
		} else if (lat_dec >= 72 && lat_dec < 84) {
			 lit = "X";
		} else if (lat_dec >= 64 && lat_dec < 72) {
			 lit = "W"
		} else if (lat_dec >= 56 && lat_dec < 64) {
			 lit = "V"
		} else if (lat_dec >= 48 && lat_dec < 56) {
			 lit = "U"
		} else if (lat_dec >= 40 && lat_dec < 48) {
			 lit = "T"
		} else if (lat_dec >= 32 && lat_dec < 40) {
			 lit = "S"
		} else if (lat_dec >= 24 && lat_dec < 32) {
			 lit = "R"
		} else if (lat_dec >= 16 && lat_dec < 24) {
			 lit = "Q"
		} else if (lat_dec >= 8 && lat_dec < 16) {
			 lit = "P"
		} else if (lat_dec >= 0 && lat_dec < 8) {
			 lit = "N"
		}
	} else {
		if ((-1 * lat_dec) >= 84) {
		 lit = "A"
		} else if ((-1 * lat_dec) >= 72 && (-1 * lat_dec) < 84) {
			 lit = "C"
		} else if ((-1 * lat_dec) >= 64 && (-1 * lat_dec) < 72) {
			 lit = "D"
		} else if ((-1 * lat_dec) >= 56 && (-1 * lat_dec) < 64) {
			 lit = "E"
		} else if ((-1 * lat_dec) >= 48 && (-1 * lat_dec) < 56) {
			 lit = "F"
		} else if ((-1 * lat_dec) >= 40 && (-1 * lat_dec) < 48) {
			 lit = "G"
		} else if ((-1 * lat_dec) >= 32 && (-1 * lat_dec) < 40) {
			 lit = "H"
		} else if ((-1 * lat_dec) >= 24 && (-1 * lat_dec) < 32) {
			 lit = "J"
		} else if ((-1 * lat_dec) >= 16 && (-1 * lat_dec) < 24) {
			 lit = "K"
		} else if ((-1 * lat_dec) >= 8 && (-1 * lat_dec) < 16) {
			 lit = "L"
		} else if ((-1 * lat_dec) >= 0 && (-1 * lat_dec) < 8) {
			 lit = "M"
		}
	}

	strefa=lit;

	var p_sec = (delta_lon) * 3600 / 10000;
	var Sin1 = Math.PI / (180 * 3600);

	var e1sq=0;
	var ki = s * k0;
	var Kii = nu * Math.sin(lat_r) * Math.cos(lat_r) * Math.pow(Sin1, 2) * k0 * (100000000) / 2;
	var Kiii = ((Math.pow(Sin1, 4) * nu * Math.sin(lat_r) * Math.pow(Math.cos(lat_r), 3)) / 24)  * (5 - Math.pow(Math.tan(lat_r), 2) + 9 * e2 * Math.pow(Math.cos(lat_r), 2) + 4 * Math.pow(e1sq, 2) * Math.pow(Math.cos(lat_r), 4) )  * k0 * 10000000000000000;
	var Kiv = nu * Math.cos(lat_r) * Sin1 * k0 * 10000;
	var Kv = Math.pow(Sin1 * Math.cos(lat_r), 3) * (nu / 6) * (1 - Math.pow(Math.tan(lat_r), 2) + e2 * Math.pow(Math.cos(lat_r), 2)) * k0 * 1000000000000;
	//var A6 = (Math.pow(p_sec * Sin1, 6) * nu * Math.sin(lat_r) * Math.pow(Math.cos(lat_r), 5) / 720) * (61 - 58 * Math.pow(Math.tan(lat_r), 2) + Math.pow(Math.tan(lat_r), 4) + 270 * e2 * Math.pow(Math.cos(lat_r), 2) - 330 * e2 * Math.pow(Math.sin(lat_r), 2)) * k0 * 1000000000000000000000000;

	var North = (ki + Kii * p_sec * p_sec + Kiii * Math.pow(p_sec, 4));
	var EAST = 500000 + (Kiv * p_sec + Kv * Math.pow(p_sec, 3));

	if (lat === "N") {
	   //North = North;
	} else {
		North = 10000000 + North;
	}

	//Nx=North;

	LongLtr0=8*((ZONE-1)%3)+1;
	LatLtr0=1+5*((ZONE-1)%2);

	letterE= ["", "A", "B", "C", "D", "E", "F", "G", "H", "J", "K", "L", "M", "N", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
	letterN= ["V", "A", "B", "C", "D", "E", "F", "G", "H", "J", "K", "L", "M", "N", "P", "Q", "R", "S", "T", "U", "V"];

	digraLON=LongLtr0+Math.floor(EAST/100000)-1;
	digraLAT=(LatLtr0+Math.floor(North/100000))%20;

	litek1=letterE[digraLON];
	litek2=letterN[digraLAT];

	//Ex=EAST;
	//xy[0]=Math.floor((Nx-5400000)/10000)+1;
	//xy[1]=Math.floor(((Ex-400000)/10000))+1;

	EAST = Math.floor(EAST);

	var EAST_L = String(EAST);
	var iLen = EAST_L.length;
	var cyf1=EAST_L.substring(iLen, iLen - 5);

	North = Math.floor(North);
	var NORTH_L = String(North);

	iLen = NORTH_L.length;
	var cyf2=NORTH_L.substring(iLen, iLen - 5);

	return ZONE+strefa+" "+litek1+litek2+" "+cyf1.substr(0, 1) + cyf2.substr(0, 1)+" "+cyf1.substr(1, 1) + cyf2.substr(1, 1)+" "+cyf1.substr(2, 1) + cyf2.substr(2, 1);
}	