import React, { forwardRef, useState } from "react";
import { Table, Button } from "react-bootstrap"
import { connect } from 'react-redux'
import { getOutput } from "../../reducers"
import { postDataApi, getDataApi } from '../../actions'
import L from 'leaflet';
import * as notify from '../../utils/notify'
import { useTranslation } from 'react-i18next'
import { setWmsArray } from '../../actions/map'

const UserMapsWMSTable = forwardRef((props, ref) => {
    const { t } = useTranslation(['map'])

    const [buttonState, setButtonState] = useState({})
    const DEFAULT_OPACITY = "0.6"

    const handleDeleteWms = (id) => {
        props.deleteWms(id)
            .then((res) => {
                notify.success(t('map:wms-removed'))
                props.getAllWms()
        })
    }

    const toggleButton = (id, map, ref) => {
        const temp = { ...buttonState }
        temp[id] = !temp[id]
        setButtonState(temp)
        if (temp[id]){
            handleLoadWms(map, ref)
        }
    }

    const handleLoadWms = (map, ref) => {
        if (ref){
            try {
                new URL(map.url);
                let wms = L.tileLayer.wms(map.url, {
                    layers: map.layer,
                    name: map.name,
                    transparency: true,
                    format: 'image/png',
                    opacity: DEFAULT_OPACITY
                })
                wms.name = map.name
                wms.addTo(ref.mapRef.current)
                                
                props.setWmsArray({
                    "name": map.name,
                    "layer": map.layer,
                    "opacity": DEFAULT_OPACITY,
                    "url": map.url
                })

              } catch (e) {
                // invalid URL 
              }
        }
    }

    return (
        <>
            {props.wms && props.wms.background_maps &&
            props.wms.background_maps.length > 0 &&
                <Table striped bordered hover responsive size="sm">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>{t('map:name')}</th>
                            <th>{t('map:layer')}</th>
                            <th>URL</th>
                            <th>{t('map:load')}</th>
                            <th>{t('map:delete')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.wms.background_maps.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{index+1}</td>
                                        <td>{item.name}</td>
                                        <td>{item.layer}</td>
                                        <td>{item.url}</td>
                                        <td><Button disabled={buttonState[item.id]} onClick={() => toggleButton(item.id, item, ref)}>
                                                {!buttonState[item.id] &&
                                                    t('map:load')
                                                }
                                                {buttonState[item.id] &&
                                                    t('map:loaded')
                                                } 
                                            </Button></td>
                                        <td><Button onClick={() => handleDeleteWms(item.id)} variant="danger"> {t('map:delete')} </Button></td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            }
        </>
    )
}
)

const mapStateToProps = state => ({
    user: state.user,
    wms: getOutput("GET_BACKGROUND_MAP_ID", state)
})

const mapDispatchToProps = dispatch => ({
    /* WMS */
    getAllWms: () => dispatch(getDataApi(
        "GET_BACKGROUND_MAP_ID",
        "/background_map/get_all/")
    ),

    deleteWms: id => dispatch(postDataApi(
        "DELETE-WMS-ID",
        "/background_map/delete/",
        { "map_id": id })
    ),

    setWmsArray: (data) => dispatch(setWmsArray(data)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(UserMapsWMSTable)
